import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Draggable from 'react-draggable';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	// Typography,
	IconButton,
	Paper,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

// redux
import { connect } from 'react-redux';
import { resetGlobalDialog } from 'redux/actions'; // actions

// lang
import { injectIntl } from 'react-intl';

const globalDialogStyle = (theme) => ({
	title: {
		margin: 0,
		padding: theme.spacing(2),
		cursor: 'move',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const titleId = 'global-dialog-title';
function PaperComponent(props) {
	return (
		<Draggable handle={`[id="${titleId}"]`}>
			<Paper {...props} />
		</Draggable>
	);
}

class GlobalDialog extends React.Component {
	// constructor(props) {
	// 	super(props);
	// 	// this.state={
	// 	// };
	// }
	render() {
		const { classes, globalDialog, resetGlobalDialog, intl } = this.props;
		let { open, size, title, content, confirmCB, onClose } = globalDialog;
		return (
			<Dialog
				disableEscapeKeyDown
				open={open}
				maxWidth={size}
				fullWidth={true}
				scroll="paper"
				aria-labelledby={titleId}
				PaperComponent={PaperComponent}
			>
				<DialogTitle id={titleId} className={classes.title}>
					{title}
					<IconButton
						aria-label="Close"
						className={classes.closeButton}
						onClick={
							onClose
								? () => {
										onClose();
										resetGlobalDialog();
								  }
								: resetGlobalDialog
						}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>{content}</DialogContent>
				<DialogActions>
					<Button
						onClick={
							onClose
								? () => {
										onClose();
										resetGlobalDialog();
								  }
								: resetGlobalDialog
						}
						size="small"
						variant="contained"
						// color="primary"
					>
						{confirmCB
							? intl.formatMessage({ id: 'GENERAL.Cancel' })
							: intl.formatMessage({ id: 'GENERAL.Close' })}
					</Button>
					{confirmCB && (
						<Button
							onClick={() => {
								confirmCB();
								resetGlobalDialog();
							}}
							size="small"
							variant="contained"
							color="primary"
						>
							{intl.formatMessage({ id: 'GENERAL.Confirm' })}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

GlobalDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	resetGlobalDialog: PropTypes.func.isRequired,
	// openGlobalDialog: PropTypes.func.isRequired,
	onClose: PropTypes.func,
	globalDialog: PropTypes.object.isRequired,
};

GlobalDialog.defaultProps = {
	classes: {},
	globalDialog: {},
};

const mapStateToProps = (state) => {
	return {
		globalDialog: state.globalDialog,
	};
};

export default connect(mapStateToProps, { resetGlobalDialog })(
	injectIntl(withStyles(globalDialogStyle)(GlobalDialog))
);
