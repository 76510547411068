import React from 'react';

import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Link, IconButton } from '@mui/material';

import { Edit as EditIcon } from '@mui/icons-material';

// custom components
import { InputBox } from 'components';

import { readableFileSize } from 'utils/libHelper';
import { toLocaleDate } from 'utils/generalHelper';

// intl lang
import { useIntl } from 'react-intl';

const useStylesEditableCell = makeStyles((theme) => ({
	cellWrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		'& a': {
			cursor: 'pointer',
		},
	},
	editableIcon: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
}));
function EditableCell({ allowEdit = false, render }) {
	const classes = useStylesEditableCell();
	const [isEditing, setIsEditing] = React.useState(false);
	return (
		<div className={classes.cellWrapper}>
			{!isEditing && allowEdit && (
				<div title="Edit" className={classes.editableIcon} onClick={() => setIsEditing(true)}>
					<IconButton size="small">
						<EditIcon fontSize="small" />
					</IconButton>
				</div>
			)}
			{render({ isEditing, setIsEditing })}
		</div>
	);
}

// CSS
const useStyles = makeStyles((theme) => ({
	imageCell: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1, 0),
		'& img': {
			maxWidth: 120,
			maxHeight: 120,
		},
	},
	rowNotLive: {
		backgroundColor: 'rgb(247 116 116)',
	},
}));
function useColumnsConfig({
	handleShowLargePreview,
	handlePatchMediafile,
	handleGotoDetail,
	allowEdit,
	showRedBorder,
}) {
	const classes = useStyles();
	const intl = useIntl();
	const columnsConfig = React.useMemo(() => {
		return [
			{
				Header: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileTableView.TableHeaderPreview',
				}),
				accessor: 'previewUrl',
				id: `previewUrl`,
				disableSortBy: true,
				Cell: function renderCell({ value, row }) {
					return (
						<div
							className={cx(classes.imageCell, {
								[classes.rowNotLive]: row.original.status !== 'live' && showRedBorder,
							})}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							<img src={value} alt={row.original.title} />
						</div>
					);
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileTableView.TableHeaderTitle',
				}),
				accessor: 'title',
				id: 'title',
				Cell: function renderCell({ value, row }) {
					return (
						<EditableCell
							allowEdit={allowEdit}
							render={({ isEditing, setIsEditing }) => {
								return isEditing ? (
									<InputBox
										label=""
										value={value}
										handleCancel={() => setIsEditing(false)}
										handleConfirm={(newVal) => {
											handlePatchMediafile(row.original.id, { title: newVal }).finally(() => {
												setIsEditing(false);
											});
										}}
									/>
								) : (
									<Link underline="always" onClick={() => handleGotoDetail(row.original)}>
										{value || ''}
									</Link>
								);
							}}
						/>
					);
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileTableView.TableHeaderLastModified',
				}),
				accessor: 'lastModifiedDate',
				id: 'lastModifiedDate',
				Cell: function renderCell({ value }) {
					return (
						<Typography variant="inherit">{value ? toLocaleDate(new Date(value)) : ''}</Typography>
					);
				},
			},
			// {
			// 	Header: intl.formatMessage({
			// 		id: 'pages.MediaFiles.components.MediafileTableView.TableHeaderCategory',
			// 	}),
			// 	accessor: 'category',
			// 	id: 'category',
			// 	disableSortBy: true,
			// 	Cell: function renderCell() {
			// 		return <Typography variant="inherit">{''}</Typography>;
			// 	},
			// },
			{
				Header: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileTableView.TableHeaderSize',
				}),
				accessor: 'fileSize',
				id: 'fileSize',
				Cell: function renderCell({ value }) {
					return <Typography variant="inherit">{value ? readableFileSize(value) : ''}</Typography>;
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileTableView.TableHeaderFiletype',
				}),
				accessor: 'fileType',
				id: 'fileType',
				Cell: function renderCell({ value }) {
					return <Typography variant="inherit">{value ? value.toUpperCase() : ''}</Typography>;
				},
			},
			// {
			// 	Header: intl.formatMessage({
			// 		id: 'pages.MediaFiles.components.MediafileTableView.TableHeaderGroups',
			// 	}),
			// 	accessor: 'groups',
			// 	id: 'groups',
			// 	disableSortBy: true,
			// 	Cell: function renderCell() {
			// 		return <Typography variant="inherit">{''}</Typography>;
			// 	},
			// },
		];
	}, [
		allowEdit,
		classes.imageCell,
		classes.rowNotLive,
		showRedBorder,
		handleGotoDetail,
		handlePatchMediafile,
		handleShowLargePreview,
		intl,
	]);

	return columnsConfig;
}
// parameters passed must be memorized
export default useColumnsConfig;
