import config from 'config';
import reduxStore from 'redux/store';
import { axiosRequest } from 'utils/libHelper';
import { createS3Client } from 'utils/appHelper';

const userManagerAPIBaseUrl = config.usermanagerAPI.baseUrl;
const authHeaderKey = config.usermanagerAPI.authHeaderKey;

/**
 * fetch aws credential to access centain resource
 *
 * 	@param {object} opts. Request options. Format:
	 {
			domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
	 }.
 * @returns {Promise} s3Client. See detail at https://github.com/axios/axios#response-schema
 */
export const awsCreateS3Client = (opts = {}) => {
	let { domain } = opts;
	let reqUrl = `${userManagerAPIBaseUrl}/awscred/s3`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig).then((result) => {
		let cred = result.data.credential;
		let s3Client = createS3Client({
			accesskey: cred.AccessKeyId, // required
			secretkey: cred.SecretAccessKey, // required
			sessionToken: cred.SessionToken, // optional
			domain: domain || authState.domainName, // optional
		});
		return s3Client;
	});
};
