import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import Modal from '@mui/material/Modal';

let useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	datetime: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		maxWidth: 180,
	},
	time: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		maxWidth: 100,
	},
}));

function MUIDatetimeNTimePickerPop({
	isOpenDatetime = false,
	isOpenTime = false,
	initValue,
	onClose,
	onChange,
	disablePast,
}) {
	const classes = useStyles();
	const [pickerValue, setPickerValue] = React.useState(initValue);

	return (
		<Modal open={isOpenDatetime || isOpenTime} className={classes.modal} onClose={onClose}>
			<div>
				{isOpenDatetime && (
					<StaticDateTimePicker
						ampm={false}
						className={classes.datetime}
						disablePast={disablePast}
						onChange={setPickerValue}
						onAccept={onChange}
						onClose={onClose}
						value={pickerValue}
					/>
				)}
				{isOpenTime && (
					<StaticTimePicker
						ampm={false}
						className={classes.time}
						onClose={onClose}
						onChange={setPickerValue}
						onAccept={onChange}
						value={pickerValue}
					/>
				)}
			</div>
		</Modal>
	);
}

MUIDatetimeNTimePickerPop.propTypes = {
	isOpenDatetime: PropTypes.bool,
	isOpenTime: PropTypes.bool,
	initValue: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	disablePast: PropTypes.bool, // only for DateTimePicker
};

MUIDatetimeNTimePickerPop.defaultProps = {
	isOpenDatetime: false,
	isOpenTime: false,
	disablePast: false,
	initValue: new Date(),
};

export default MUIDatetimeNTimePickerPop;
