import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

// MUI components
import { Divider /* Switch */ } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

import { blueGrey } from '@mui/material/colors';
import { convertPxToMm } from 'utils/artwork/artUtilsCommon';
// import { OutlinedNumberTextField } from '../../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

const DividerHor = withStyles((theme) => ({
	fullWidth: {
		width: '100%',
	},
}))(({ classes, ...rest }) => {
	return <Divider className={classes.fullWidth} {...rest} />;
});
const DividerVer = withStyles((theme) => ({
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(0.25, 0.5),
	},
}))(({ classes, ...rest }) => {
	return <Divider orientation="vertical" className={classes.dividerVertical} {...rest} />;
});

const titleHeight = 74;
const borderColor = blueGrey[300];
const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
		width: '100%',
		height: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: 'inherit',
	},
	title: {
		width: '100%',
		position: 'relative',
		color: 'inherit',
		backgroundColor: 'inherit',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(16),
		// textTransform: 'capitalize',
		userSelect: 'none',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		height: titleHeight,
		padding: '10px 2px',
		margin: 'auto',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'&:after': {
			content: '""',
			margin: '0 auto',
			width: '100%',
			// padding: '4px 0',
			opacity: 0.8,
			borderBottom: `1px solid ${borderColor}`,
		},
	},
	titleText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
	},

	contentWrapper: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		height: `calc(100% - ${titleHeight}px)`,
		color: 'inherit',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	groupRowDiv: {
		width: '100%',
		// padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	infoName: {
		width: '25%',
		textAlign: 'right',
	},
	infoValue: {
		width: '50%',
		// textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

function SidePanelTemplateInfo({ designerTemplate, /* handleTemplateInfoUpdate, */ ...rest }) {
	const classes = useStyles();
	const intl = useIntl();
	// const page1 = designerTemplate.pages[0]; // TODO: change it when we want to support multiple pages
	return (
		<div className={classes.wrapper}>
			<div className={classes.title}>
				<div className={classes.titleText}>
					<InfoIcon style={{ marginRight: 8 }} fontSize="small" />
					{intl.formatMessage({
						id: 'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.titleText',
					})}
				</div>
			</div>
			<div className={classes.contentWrapper}>
				<div className={classes.groupRowDiv}>
					<span className={classes.infoName}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.nameText',
						})}
					</span>
					<DividerVer />
					<span className={classes.infoValue}>{designerTemplate.name}</span>
				</div>
				<DividerHor />
				<div className={classes.groupRowDiv}>
					<span className={classes.infoName}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.mediaIDText',
						})}
					</span>
					<DividerVer />
					<span className={classes.infoValue}>{designerTemplate.mediaId}</span>
				</div>
				<DividerHor />
				<div className={classes.groupRowDiv}>
					<span className={classes.infoName}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.widthText',
						})}
					</span>
					<DividerVer />
					<span className={classes.infoValue}>{`${
						designerTemplate.dimension.width
					}px (${convertPxToMm(designerTemplate.dimension.width)}mm)`}</span>
				</div>
				<DividerHor />
				<div className={classes.groupRowDiv}>
					<span className={classes.infoName}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.Sidemenu.SidePanelTemplateInfo.heightText',
						})}
					</span>
					<DividerVer />
					<span className={classes.infoValue}>{`${
						designerTemplate.dimension.height
					}px (${convertPxToMm(designerTemplate.dimension.height)}mm)`}</span>
				</div>
				<DividerHor />
				{
					// 	<div className={classes.groupRowDiv}>
					// 	<span className={classes.infoName}>HTML Artwork</span>
					// 	<DividerVer />
					// 	<span className={classes.infoValue}>
					// 		<Switch
					// 			color="secondary"
					// 			size="small"
					// 			checked={designerTemplate.htmlArtwork}
					// 			onMouseDown={e => e.preventDefault()}
					// 			onChange={(e, checked) => handleTemplateInfoUpdate({ htmlArtwork: checked })}
					// 		/>
					// 	</span>
					// </div>
					// <DividerHor />
					// <div className={classes.groupRowDiv}>
					// 	<span className={classes.infoName}>Esign Template</span>
					// 	<DividerVer />
					// 	<span className={classes.infoValue}>
					// 		<Switch
					// 			color="secondary"
					// 			size="small"
					// 			checked={designerTemplate.isEsignTemplate}
					// 			onMouseDown={e => e.preventDefault()}
					// 			onChange={(e, checked) => handleTemplateInfoUpdate({ isEsignTemplate: checked })}
					// 		/>
					// 	</span>
					// </div>
					// <DividerHor />
					// <div className={classes.groupRowDiv}>
					// 	<span className={classes.infoName}>Esign Only</span>
					// 	<DividerVer />
					// 	<span className={classes.infoValue}>
					// 		<Switch
					// 			color="secondary"
					// 			size="small"
					// 			checked={designerTemplate.isEsignOnly}
					// 			onMouseDown={e => e.preventDefault()}
					// 			onChange={(e, checked) => handleTemplateInfoUpdate({ isEsignOnly: checked })}
					// 		/>
					// 	</span>
					// </div>
					// <DividerHor />
					// <div className={classes.groupRowDiv}>
					// 	<span className={classes.infoName}>Animation Duration</span>
					// 	<DividerVer />
					// 	<span className={classes.infoValue}>
					// 		{designerTemplate.fields.filter(f => f.animation.entrance).length > 0 ? (
					// 			<div
					// 				style={{
					// 					display: 'flex',
					// 					alignItems: 'center',
					// 					justifyContent: 'center',
					// 					width: '100%',
					// 				}}
					// 			>
					// 				<OutlinedNumberTextField
					// 					// className={classes.denseTextbox}
					// 					style={{ margin: 0, marginRight: 8 }}
					// 					label=""
					// 					value={designerTemplate.templateDuration}
					// 					step={1}
					// 					min={0}
					// 					max={30}
					// 					handleOnChange={num => handleTemplateInfoUpdate({ templateDuration: num })}
					// 				/>
					// 				s
					// 			</div>
					// 		) : (
					// 			<span>No Animation</span>
					// 		)}
					// 	</span>
					// </div>
					// <DividerHor />
				}
			</div>
		</div>
	);
}

SidePanelTemplateInfo.propTypes = {
	designerTemplate: PropTypes.object.isRequired,
	// handleTemplateInfoUpdate: PropTypes.func.isRequired,
};

SidePanelTemplateInfo.defaultProps = {};
export default SidePanelTemplateInfo;
