import { createStore, applyMiddleware } from 'redux';
// import combineReducers from 'reducers';
import rootReducer from './reducers';
import promiseMiddleware from './middleware/promiseMiddleware';
import { createLogger } from 'redux-logger';

const configureDevStore = (preloadedState) => {
	const store = createStore(
		rootReducer,
		preloadedState,
		applyMiddleware(promiseMiddleware, createLogger())
	);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const reducers = require('./reducers/index').default;
			store.replaceReducer(reducers);
		});
	}

	return store;
};

const configureProdStore = (preloadedState) =>
	createStore(rootReducer, preloadedState, applyMiddleware(promiseMiddleware));

// let store = createStore(combineReducers, applyMiddleware(promiseMiddleware));

// export default store;

var configureStore = configureDevStore;
if (process.env.REACT_APP_ENV === 'production') {
	configureStore = configureProdStore;
}

export default configureStore;
