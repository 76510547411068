import {
	FETCH_AWS_RESOURCE_CREDENTIAL,
	FETCH_AWS_RESOURCE_CREDENTIAL_SUCCESS,
	FETCH_AWS_RESOURCE_CREDENTIAL_FAIL,
	RESET_AWS_RESOURCE_CREDENTIAL,
} from 'redux/actions';
const initState = {
	fetchCredentialsStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	/**
	 * credential by aws resource, e.g.
	{
		s3: {
			"AccessKeyId": "string",
			"SecretAccessKey": "string",
			"SessionToken": "string",
			"Expiration": "2019-01-01T10:05:10.874Z"
		},
		cloudfront: {}
	}
	 */
	credentials: {},
};
// export const awsResource = (state = initState, action) => {
function awsResource(state = initState, action) {
	switch (action.type) {
		case FETCH_AWS_RESOURCE_CREDENTIAL:
			return {
				...state,
				fetchCredentialsStatus: 'PROCESSING',
			};
		case FETCH_AWS_RESOURCE_CREDENTIAL_SUCCESS:
			return {
				...state,
				fetchCredentialsStatus: 'OK',
				credentials: { ...state.credentials, [action.resource]: action.result.data.credential },
			};
		case FETCH_AWS_RESOURCE_CREDENTIAL_FAIL:
			return {
				...state,
				fetchCredentialsStatus: 'FAILED',
			};
		case RESET_AWS_RESOURCE_CREDENTIAL: {
			let currentCred = { ...state.credentials };
			if (action.resource) {
				delete currentCred[action.resource];
			} else {
				currentCred = {};
			}
			return {
				...state,
				fetchCredentialsStatus: '',
				credentials: currentCred,
			};
		}
		default:
			return state;
	}
}

export default awsResource;
