import {
	POST_PROCESS_UPLOADED_FILES,
	POST_PROCESS_UPLOADED_FILES_SUCCESS,
	POST_PROCESS_UPLOADED_FILES_FAIL,
	RESET_POST_PROCESS_UPLOADED_FILES,
	FETCH_PROGRESS_UPLOADED_FILES,
	FETCH_PROGRESS_UPLOADED_SUCCESS,
	FETCH_PROGRESS_UPLOADED_FAIL,
	RESET_PROGRESS_UPLOADED_FILES,
	FETCH_FILE_CATEGORIES,
	FETCH_FILE_CATEGORIES_SUCCESS,
	FETCH_FILE_CATEGORIES_FAIL,
	RESET_FILE_CATEGORIES,
	FILEMANAGER_SEARCH_MEDIAFILES,
	FILEMANAGER_SEARCH_MEDIAFILES_SUCCESS,
	FILEMANAGER_SEARCH_MEDIAFILES_FAIL,
	FILEMANAGER_RESET_SEARCHED_MEDIAFILES,
	FILEMANAGER_FETCH_LIGHTBOXES,
	FILEMANAGER_FETCH_LIGHTBOXES_SUCCESS,
	FILEMANAGER_FETCH_LIGHTBOXES_FAIL,
	FILEMANAGER_RESET_LIGHTBOXES,
	FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID,
	FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_SUCCESS,
	FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_FAIL,
	FILEMANAGER_RESET_SPREADSHEET_CONTENT_BYID,
} from 'redux/actions';
const initState = {
	postProcessUploadedFilesStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	postProcessUploadedFilesErrMsg: '',
	postProcessQueueId: 0,

	fetchProgressUploadedFilesStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	progressOfUploadedFiles: {}, // {status: 'queueing' || 'processing' || 'completed' || 'failed', sequenceNO: Number, uploadedFiles: [ { "originalName": "string" }, ... ]}

	fetchingFileCategoriesStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	categories: [],

	searchingMediafilesStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	/**
	 * searchingFoundMediafiles format:
	[
    {
			"id": 266083,
			"title": "24 Scallions Bunch 5099323096185",
			"isOwnfile": true,
			"originalFilePath": "spar/20200408/91MeswFQnb486k3GxrAcj1GOu1t6/dv4Bt3nRPJUN.tif",
			"optimisedUrl": "https://images.visualid.com/spar/20200408/91MeswFQnb486k3GxrAcj1GOu1t6/dv4Bt3nRPJUN.mp4",
			"originalUrl": "https://images.visualid.com/spar/20200402/rXDNeoFViSzXf12zklqdcOUoAI1S/ySDRCdINR4BK.mp4",
			"previewUrl": "https://apigw.visualid.com/preview/v1/preview/?redirect=true&prekey=XTEh1eydHiEv6nKxfj1u4HjCHw1Y5X16&fileurl=s3://visualid-mediafiles/spar/20200402/rXDNeoFViSzXf12zklqdcOUoAI1S/ySDRCdINR4BK.mp4&size=small",
			"fileType": "TIF",
			"fileSize": 7098068
		},
		...
	]
	 */
	searchingFoundMediafiles: [],
	totalFoundMediafiles: 0,

	// lightboxes (list)
	fetchLightboxesStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	lightboxes: [],
	totalFoundLightboxes: 0,

	// spreadsheet content by ID
	fetchSSContentByIdStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	/**
	 * format of spreadsheetContentById. see detail in spec of "/spreadsheets/{spreadsheetId}" endpoint in filemanager api
	 	{
			"id": 32,
			"spreadsheetName": "Confectionery Master File.xls",
			"spreadsheetTable": "zss_11_2019_04_16_08_58_32_confectionery_master_file_xls",
			"recordsTotal": 1025,
			"totalFound": 12,
			"columnHeaders": [],
			"rows": []
		}
	 */
	spreadsheetContentById: {},
};

function filemanager(state = initState, action) {
	switch (action.type) {
		case POST_PROCESS_UPLOADED_FILES:
			return {
				...state,
				postProcessUploadedFilesStatus: 'PROCESSING',
				postProcessUploadedFilesErrMsg: '',
				postProcessQueueId: 0,
				fetchProgressUploadedFilesStatus: '',
				progressOfUploadedFiles: {},
			};
		case POST_PROCESS_UPLOADED_FILES_SUCCESS:
			return {
				...state,
				postProcessUploadedFilesStatus: 'OK',
				postProcessQueueId: action.result.data.queueID,
				progressOfUploadedFiles: {
					status: action.result.data.status,
					sequenceNO: action.result.data.sequenceNO,
					uploadedFiles: action.result.data.files,
				},
			};
		case POST_PROCESS_UPLOADED_FILES_FAIL:
			return {
				...state,
				postProcessUploadedFilesStatus: 'FAILED',
				postProcessUploadedFilesErrMsg: action.error.response
					? action.error.response.data.message || 'unknown'
					: action.error.message || 'unknown',
				postProcessQueueId: 0,
			};
		case RESET_POST_PROCESS_UPLOADED_FILES:
			return {
				...state,
				postProcessUploadedFilesStatus: '',
				postProcessUploadedFilesErrMsg: '',
				postProcessQueueId: 0,
				progressOfUploadedFiles: {},
			};

		case FETCH_PROGRESS_UPLOADED_FILES:
			return {
				...state,
				fetchProgressUploadedFilesStatus: 'PROCESSING',
			};
		case FETCH_PROGRESS_UPLOADED_SUCCESS:
			return {
				...state,
				fetchProgressUploadedFilesStatus: 'OK',
				postProcessQueueId: action.result.data.queueID,
				progressOfUploadedFiles: {
					status: action.result.data.status,
					sequenceNO: action.result.data.sequenceNO,
					uploadedFiles: action.result.data.files,
				},
			};
		case FETCH_PROGRESS_UPLOADED_FAIL:
			return {
				...state,
				fetchProgressUploadedFilesStatus: 'FAILED',
			};
		case RESET_PROGRESS_UPLOADED_FILES:
			return {
				...state,
				fetchProgressUploadedFilesStatus: '',
				postProcessQueueId: 0,
				progressOfUploadedFiles: {},
			};
		case FETCH_FILE_CATEGORIES:
			return {
				...state,
				fetchingFileCategoriesStatus: 'PROCESSING',
			};
		case FETCH_FILE_CATEGORIES_SUCCESS:
			return {
				...state,
				fetchingFileCategoriesStatus: 'OK',
				categories: action.result.data.categories,
			};
		case FETCH_FILE_CATEGORIES_FAIL:
			// this failure is ignored from notifying user at the moment on 11/01/2021.
			return {
				...state,
				fetchingFileCategoriesStatus: 'FAILED',
				categories: [],
			};
		case RESET_FILE_CATEGORIES:
			return {
				...state,
				fetchingFileCategoriesStatus: '',
				categories: [],
			};
		case FILEMANAGER_SEARCH_MEDIAFILES:
			return {
				...state,
				searchingMediafilesStatus: 'PROCESSING',
				// searchingFoundMediafiles: [], // do we reset it? maybe not, this new searching could be failed
			};
		case FILEMANAGER_SEARCH_MEDIAFILES_SUCCESS:
			return {
				...state,
				searchingMediafilesStatus: 'OK',
				searchingFoundMediafiles: action.result.data.mediafiles,
				totalFoundMediafiles: action.result.data.totalFound,
			};
		case FILEMANAGER_SEARCH_MEDIAFILES_FAIL:
			return {
				...state,
				searchingMediafilesStatus: 'FAILED',
				// searchingFoundMediafiles: [], // do we reset it? maybe not, at least the previous search result can be displayed
			};
		case FILEMANAGER_RESET_SEARCHED_MEDIAFILES:
			return {
				...state,
				searchingMediafilesStatus: '',
				searchingFoundMediafiles: [],
				totalFoundMediafiles: 0,
			};

		case FILEMANAGER_FETCH_LIGHTBOXES:
			return {
				...state,
				fetchLightboxesStatus: 'PROCESSING',
			};
		case FILEMANAGER_FETCH_LIGHTBOXES_SUCCESS:
			return {
				...state,
				fetchLightboxesStatus: 'OK',
				lightboxes: action.result.data.lightboxes,
				totalFoundLightboxes: action.result.data.totalFound,
			};
		case FILEMANAGER_FETCH_LIGHTBOXES_FAIL:
			return {
				...state,
				fetchLightboxesStatus: 'FAILED',
				// lightboxes: [],
				// totalFoundLightboxes: 0,
			};
		case FILEMANAGER_RESET_LIGHTBOXES:
			return {
				...state,
				fetchLightboxesStatus: '',
				lightboxes: [],
				totalFoundLightboxes: 0,
			};

		case FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID:
			return {
				...state,
				fetchSSContentByIdStatus: 'PROCESSING',
			};
		case FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_SUCCESS:
			return {
				...state,
				fetchSSContentByIdStatus: 'OK',
				spreadsheetContentById: {
					...action.result.data,
					rows: action.append
						? state.spreadsheetContentById.rows.concat(action.result.data.rows)
						: action.result.data.rows,
				},
			};
		case FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_FAIL:
			return {
				...state,
				fetchSSContentByIdStatus: 'FAILED',
				// spreadsheetContentById: {},
			};
		case FILEMANAGER_RESET_SPREADSHEET_CONTENT_BYID:
			return {
				...state,
				fetchSSContentByIdStatus: '',
				spreadsheetContentById: {},
			};
		default:
			return state;
	}
}

export default filemanager;
