// ##############################
// // // Home styles
// #############################

const HomeStyle = (theme) => ({
	wrapper: {
		position: 'relative',
		top: '0',
		height: '100vh',
	},
});

export default HomeStyle;
