import React from 'react';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Croppie from 'croppie';
import { Button } from '@mui/material';
// MUI ICONs
import { Done as DoneIcon, Close as CloseIcon } from '@mui/icons-material';
import 'croppie/croppie.css';

// intl lang
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	buttons: {
		position: 'absolute',
		left: '50%',
		transform: 'translateX(-50%)',
		bottom: -80,
		zIndex: 10,
		display: 'flex',
		'& button': {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
	},
	sliderThumb: {
		'& .cr-slider::-webkit-slider-thumb': {
			backgroundColor: theme.palette.primary.main,
		},
		'& .cr-slider::-moz-range-thumb': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));

function ImageCropper({
	// field,
	fieldPosition,
	imageUrl,
	zoom,
	maxBoundaryWidth,
	maxBoundaryHeight,
	handleCroppedImage,
	handleClose,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	// const rect = {
	// 	x: fieldPosition.left * zoom,
	// 	y: fieldPosition.top * zoom,
	// 	width: fieldPosition.width * zoom,
	// 	height: fieldPosition.height * zoom,
	// 	centerX: fieldPosition.left * zoom + (fieldPosition.width * zoom) / 2,
	// 	centerY: fieldPosition.top * zoom + (fieldPosition.height * zoom) / 2,
	// 	rotateAngle: fieldPosition.angle,
	// };
	const rect = React.useMemo(
		() => ({
			x: fieldPosition.left * zoom,
			y: fieldPosition.top * zoom,
			width: fieldPosition.width * zoom,
			height: fieldPosition.height * zoom,
			centerX: fieldPosition.left * zoom + (fieldPosition.width * zoom) / 2,
			centerY: fieldPosition.top * zoom + (fieldPosition.height * zoom) / 2,
			rotateAngle: fieldPosition.angle,
		}),
		[
			fieldPosition.angle,
			fieldPosition.height,
			fieldPosition.left,
			fieldPosition.top,
			fieldPosition.width,
			zoom,
		]
	);
	let boundaryExtendRatio = 2,
		boundaryWidth = maxBoundaryWidth,
		boundaryHeight = maxBoundaryHeight;
	if (maxBoundaryWidth / maxBoundaryHeight < rect.width / rect.height) {
		boundaryWidth = Math.min(maxBoundaryWidth, rect.width * boundaryExtendRatio);
		boundaryHeight = (rect.height * boundaryWidth) / rect.width;
	} else {
		boundaryHeight = Math.min(maxBoundaryHeight, rect.height * boundaryExtendRatio);
		boundaryWidth = (rect.width * boundaryHeight) / rect.height;
	}
	const cropperRef = React.useRef(null);
	let cropperInst = React.useRef(null);
	React.useEffect(() => {
		if (!cropperRef.current) return null;
		if (cropperInst.current) cropperInst.current.destroy();

		cropperInst.current = new Croppie(cropperRef.current, {
			viewport: {
				width: rect.width,
				height: rect.height,
				type: 'square',
			},
			boundary: { width: boundaryWidth, height: boundaryHeight },
			showZoomer: true,
			enforceBoundary: true,
			mouseWheelZoom: 'ctrl',
		});
		cropperInst.current.bind({
			url: imageUrl,
		});

		// let childNodes = cropperRef.current.childNodes;
		// for (let i = 0; i < childNodes.length; i++) {
		// 	if (childNodes[i].className.indexOf('cr-boundary') !== -1) {
		// 		childNodes[i].style.transform = `rotate(${rect.rotateAngle}deg)`;
		// 	}

		// 	if (childNodes[i].className.indexOf('cr-slider-wrap') !== -1) {
		// 		childNodes[i].style.position = `relative`;
		// 		childNodes[i].style['z-index'] = 10;
		// 	}
		// }
	}, [fieldPosition, imageUrl, rect, boundaryWidth, boundaryHeight]);

	React.useEffect(() => {
		return () => {
			if (cropperInst.current) cropperInst.current.destroy();
		};
	}, []);

	return (
		<div
			ref={cropperRef}
			className={classes.sliderThumb}
			style={{
				position: 'absolute',
				zIndex: ART_VARIABLES.ZINDEX_RESIZEBOX + 1,
				width: boundaryWidth, //rect.width,
				height: boundaryHeight, //rect.height,
				left: rect.x - (boundaryWidth - rect.width) / 2,
				top: rect.y - (boundaryHeight - rect.height) / 2,
			}}
		>
			<div className={classes.buttons}>
				<Button
					size="small"
					startIcon={<DoneIcon />}
					color="primary"
					variant="contained"
					onClick={() => {
						cropperInst.current.result({ type: 'blob', size: 'original' }).then((blob) => {
							handleCroppedImage(blob);
						});
					}}
				>
					{intl.formatMessage({
						id: 'pages.Artwork.components.PreviewControlPanel.buttonImageCrop',
					})}
				</Button>
				<Button
					size="small"
					startIcon={<CloseIcon />}
					variant="contained"
					color="secondary"
					onClick={() => handleClose()}
				>
					{intl.formatMessage({ id: 'GENERAL.Cancel' })}
				</Button>
			</div>
		</div>
	);
}

ImageCropper.propTypes = {
	// field: PropTypes.object.isRequired, // it is text field
	fieldPosition: PropTypes.object.isRequired, // it is text field position
	imageUrl: PropTypes.string.isRequired, // image url, this should be the high quality one that you want it to be in generated PDF
	zoom: PropTypes.number.isRequired, // double/float. the current zoom. e.g. 0.1, 0.25, 1, 1.35, 2, etc.
	maxBoundaryWidth: PropTypes.number.isRequired, //  // double/float.
	maxBoundaryHeight: PropTypes.number.isRequired, //  // double/float.
	handleCroppedImage: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
};

ImageCropper.defaultProps = {};
export default React.memo(ImageCropper, (prevProps, nextProps) => {
	return (
		prevProps.zoom === nextProps.zoom &&
		prevProps.maxBoundaryWidth === nextProps.maxBoundaryWidth &&
		prevProps.maxBoundaryHeight === nextProps.maxBoundaryHeight &&
		prevProps.imageUrl === nextProps.imageUrl &&
		JSON.stringify(prevProps.fieldPosition) === JSON.stringify(nextProps.fieldPosition)
	);
});
