import React from 'react';

import { /*  DesignContext, */ ART_VARIABLES } from '../../Constants';
// import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { prepareBarcodeField } from 'utils/artwork/artFieldsCreator';
import { default as JsBarcode } from 'jsbarcode';

const useStyles = makeStyles((theme) => ({
	pauseAnimation: {
		animationPlayState: 'paused !important',
	},
}));

function Barcode({
	field,
	fieldPreviewData,
	animationDelay,
	allowAnimation,
	pausingAnimation,
	...rest
}) {
	// const designContext = React.useContext(DesignContext);
	const classes = useStyles();
	// const intl = useIntl();

	const barcodeData = prepareBarcodeField({
		mode: 'preview',
		inputData: fieldPreviewData,
		field,
	});
	const {
		attrs,
		EAN13Value,
		EAN5Value,
		jsBarcodeOpts,
		jsBarcodeEan13Opts,
		jsBarcodeBlank,
		jsBarcodeEan5Opts,
	} = barcodeData || {};
	// const barcodeValue = fieldPreviewData.value || '1234567890128';
	const barcodeRef = React.useRef(null);
	React.useEffect(() => {
		if (barcodeRef.current) {
			let jsBarcodeInst = null;
			if (EAN13Value) {
				jsBarcodeInst = JsBarcode(barcodeRef.current)
					.options(jsBarcodeOpts) // Will affect all barcodes
					.EAN13(EAN13Value, jsBarcodeEan13Opts);
				if (EAN5Value && field.EAN5Addon) {
					jsBarcodeInst = jsBarcodeInst
						.blank(jsBarcodeBlank) // Create space between the barcodes
						.EAN5(EAN5Value, jsBarcodeEan5Opts);
				}
			}

			if (jsBarcodeInst) {
				jsBarcodeInst.render();
			} else {
				barcodeRef.current.innerHTML = '';
			}
			// JsBarcode changes the width/height to absolute value (e.g. 215px), we need to change it back to "100%"
			barcodeRef.current.setAttribute('width', '100%');
			barcodeRef.current.setAttribute('height', '100%');
			// JsBarcode(barcodeRef.current)
			// 	.options(jsBarcodeOpts) // Will affect all barcodes
			// 	.EAN13(EAN13Value, jsBarcodeEan13Opts)
			// 	.blank(jsBarcodeBlank) // Create space between the barcodes
			// 	.EAN5(EAN5Value, jsBarcodeEan5Opts)
			// 	.render();
			// JsBarcode(barcodeRef.current)
			// 	.options({ font: 'OCR-B', lineColor: field.color.hex || '#000000' }) // Will affect all barcodes
			// 	.EAN13(barcodeValue, { fontSize: 18, textMargin: 0 })
			// 	.blank(20) // Create space between the barcodes
			// 	.EAN5('12345', { height: 85, textPosition: 'top', fontSize: 16, marginTop: 15 })
			// 	.render();
			// barcodeRef.current.setAttribute('width', '100%');
			// barcodeRef.current.setAttribute('height', '100%');
		}
	}, [
		EAN13Value,
		EAN5Value,
		field.EAN5Addon,
		jsBarcodeBlank,
		jsBarcodeEan13Opts,
		jsBarcodeEan5Opts,
		jsBarcodeOpts,
	]);
	if (!barcodeData) return null;

	const noAnimationStyle = `0s ease 0s 1 normal none running none`;
	const animationEntranceStyle = field.animation.entrance
		? `${field.animation.entrance} ${
				field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
		  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
				animationDelay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
		  }s both`
		: noAnimationStyle;
	// const animationExitStyle = field.animation.exit
	// 	? `${field.animation.exit} ${field.animation.duration ||
	// 			1}s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${0}s both`
	// 	: noAnimationStyle;

	return (
		<svg
			x={field.position.left}
			y={field.position.top}
			width={field.position.width}
			height={field.position.height}
			overflow={'visible'}
		>
			<g
				className={cx({ [classes.pauseAnimation]: pausingAnimation })}
				style={{
					animation:
						allowAnimation && field.animation.entrance ? animationEntranceStyle : noAnimationStyle,
				}}
			>
				{/* Somehow, "transform" not working when together with "style" in the same <g> */}
				<g
					transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
						field.position.height / 2
					})`}
				>
					<svg ref={barcodeRef} width="100%" height="100%" {...attrs}></svg>
				</g>
			</g>
		</svg>
	);
}

Barcode.propTypes = {
	field: PropTypes.object.isRequired, // it is text field
	fieldPreviewData: PropTypes.object.isRequired, // the preview data of a field
	animationDelay: PropTypes.number.isRequired, // animation delay. Relative to previous fields
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is aninimation paused (on video end in videoArtwork)
};

Barcode.defaultProps = {};
export default React.memo(Barcode);
