import React from 'react';

// import config from 'config';

import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import {
	MenuItem,
	Paper,
	Select,
	Link,
	Typography,
	TextField,
	Button,
	IconButton,
	Tooltip,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { moment, _ } from 'utils/libHelper';
import { toLocaleDate } from 'utils/generalHelper.js';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
	Search,
	RTTrComp,
	ScreenManagerGridView,
	// Dialog
} from 'components';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

// icons
import {
	Report as InactiveIcon,
	Check as ActiveIcon,
	StayCurrentPortraitOutlined as PortraitIcon,
	StayCurrentLandscapeOutlined as LandscapeIcon,
	Save as SaveIcon,
	Visibility as PreviewIcon,
	DeleteOutline as DeleteIcon,
	Refresh as ResetIcon,
} from '@mui/icons-material';

import AddCommentIcon from '@mui/icons-material/AddComment';

// CSS
import PlayerUniverseStyle from './PlayerUniverseStyle.jsx';

import { getRouteScreenPlayerUniverse } from 'pages/ScreenManager/Constants';

// redux
import { connect } from 'react-redux';
import {
	openGlobalDialog,
	resetGlobalDialog,
	fetchScreenManagerPlaylistUniverse,
	notifyError,
	notifyGeneral,
	editPlaylerUniverseComment,
} from 'redux/actions'; // actions

import { setCommandsToPlayer } from 'restful';

class PlayerUniverse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filtered: [],
			isResetDisable: false,
			resetScreenId: '',
			editing: {}, // the editing data of a player universe
			adding: {}, // the adding data of a player universe
			isDesktop: false,
		};
		this.updateScreenResolution = this.updateScreenResolution.bind(this);
		this.columns = [
			{
				accessor: (d) => d.createdBy.company || '',
				id: 'company',
				Header: 'Site', // props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.NameCol' }), //'Site Name',
				resizable: true,
				sortable: true,
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => (
					<Link
						variant="body1"
						// className={this.props.classes.hoverCursor}
						underline="always"
						target="_blank"
						rel="noopener"
						href={getRouteScreenPlayerUniverse(
							cellInfo.original.domain,
							cellInfo.original.createdBy.email,
							cellInfo.original.createdBy.uid
						)}
					>
						<Typography title={cellInfo.original.createdBy.email}>{cellInfo.value}</Typography>
					</Link>
				),
			},
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.NameCol',
				}), //'Screen Name',
				resizable: true,
				sortable: true,
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => cellInfo.value,
			},
			{
				accessor: (d) => d.domain || '',
				id: 'domain',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.DomainCol',
				}), //'domain Name',
				resizable: true,
				sortable: true,
				filterable: true,
				filterMethod: (filter, row) => {
					if (filter.value === 'Show all') {
						return true;
					}
					if (filter.value === row.domain) {
						return row.domain;
					}
					return row.domain === '';
				},
				// eslint-disable-next-line react/prop-types
				Filter: ({ filter, onChange }) => (
					<Select
						variant="standard"
						onChange={(event) => {
							onChange(event.target.value);
							this.setState({
								filtered: [{ id: 'domain', value: event.target.value }],
							});
						}}
						classes={{ select: props.classes.dropdown }}
						style={{ width: '100%', textAlign: 'left' }}
						// eslint-disable-next-line react/prop-types
						value={filter ? filter.value : 'Show all'}
					>
						{this.renderDomainArr()}
					</Select>
				),
				Cell: (cellInfo) => cellInfo.value,
			},
			{
				accessor: (d) => (d.comment ? d.comment.message : ''),
				id: 'comment',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.CommentCol',
				}), //'comment',
				resizable: true,
				sortable: false,
				Cell: (cellInfo) => {
					if (cellInfo.value) {
						return (
							<Tooltip title={this.props.intl.formatMessage({ id: 'GENERAL.Preview' })}>
								<IconButton
									classes={{ root: this.props.classes.previewButton }}
									aria-label="preview"
									onClick={() => {
										this.setState({ editing: { original: cellInfo.original } });
										this.openCommentDialog(cellInfo.original);
									}}
								>
									<PreviewIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						);
					} else {
						return (
							<Tooltip
								title={this.props.intl.formatMessage({
									id: 'pages.ScreenManager.components.PlayerUniverse.AddCommentDialogLabel',
								})}
							>
								<IconButton
									color="primary"
									aria-label="add"
									onClick={() => {
										this.setState({ adding: { original: cellInfo.original } });
										this.openCommentDialog(cellInfo.original);
									}}
								>
									<AddCommentIcon color="primary" />
								</IconButton>
							</Tooltip>
						);
					}
				},
			},
			{
				accessor: (d) => d.orientation || '',
				id: 'orientation',
				Header: (
					<div
						title={props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Screens.OrientationCol',
						})}
					>
						<PortraitIcon className={this.props.classes.portraitIcon} />
						<LandscapeIcon className={this.props.classes.landscapeIcon} />
					</div>
				),
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => (cellInfo.value === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />),
			},
			{
				accessor: (d) => d.playerCode,
				id: 'playerCode',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.PlayCodeCol',
				}), //'Player code',
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => cellInfo.value || '',
			},
			{
				accessor: (d) => d.status || '',
				id: 'status',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentStatusCol',
				}), //'Status',
				resizable: true,
				sortable: true,
				filterable: true,
				filterMethod: (filter, row) => {
					if (filter.value === 'all') {
						return true;
					}
					if (filter.value === 'active') {
						return row.status === 'ACTIVE';
					}
					if (filter.value === 'never') {
						return row.status === 'NEVER';
					}
					// Status === 1 means Inactive
					return row.status === 'INACTIVE';
				},
				// eslint-disable-next-line react/prop-types
				Filter: ({ filter, onChange }) => (
					<Select
						variant="standard"
						onChange={(event) => {
							onChange(event.target.value);
							this.setState({
								filtered: [{ id: 'status', value: event.target.value }],
							});
						}}
						classes={{ select: props.classes.dropdown }}
						style={{ width: '100%', textAlign: 'left' }}
						// eslint-disable-next-line react/prop-types
						value={filter ? filter.value : 'all'}
					>
						<MenuItem value="all">Show All</MenuItem>
						<MenuItem value="active">Active</MenuItem>
						<MenuItem value="inactive">Not Active</MenuItem>
						<MenuItem value="never">Not Yet Activated</MenuItem>
					</Select>
				),
				Cell: (cellInfo) =>
					cellInfo.value === 'ACTIVE' ? (
						<ActiveIcon className={props.classes.success} style={{ fontSize: 30 }} />
					) : (
						<InactiveIcon color="error" className={props.classes.shake} style={{ fontSize: 30 }} />
					),
			},
			{
				accessor: (d) => d.lastConnectedAt || '',
				id: 'lastConnectedAt',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.lastConnectedAtCol',
				}), //'Last Seen',
				resizable: true,
				sortable: true,
				Cell: (cellInfo) => {
					return cellInfo.original.lastConnectedAt
						? moment(cellInfo.original.lastConnectedAt).fromNow()
						: 'Never';
				},
			},
			{
				// NOTE: hidden column used just for default sorting
				accessor: (d) => d.createdAt || '',
				id: 'createdAt',
				Header: 'Created At',
				sortable: true,
				getProps: () => Object.create({ style: { display: 'none' } }),
				getHeaderProps: () => Object.create({ style: { display: 'none' } }),
			},
			{
				// NOTE: hidden column used just for default sorting by Status(Inactive, active, never activated).
				accessor: (d) => d.sortByStatus || '',
				id: 'sortByStatus',
				Header: 'Sort by status',
				sortable: true,
				getProps: () => Object.create({ style: { display: 'none' } }),
				getHeaderProps: () => Object.create({ style: { display: 'none' } }),
			},
			{
				// NOTE - this is a "filter all" DUMMY column
				// you can't HIDE it because then it wont FILTER
				// but it has a size of ZERO with no RESIZE and the
				// FILTER component is NULL (it adds a little to the front)
				// You may possibly move it to the end of columns
				Header: 'All',
				id: 'all',
				width: 0,
				resizable: false,
				sortable: false,
				Filter: () => {},
				// eslint-disable-next-line no-unused-vars
				getProps: (state, rowInfo, column) => {
					return {
						style: { display: 'none' },
					};
				},
				getHeaderProps: () => {
					return {
						style: { display: 'none' },
					};
				},
				filterMethod: (filter, rows) => {
					// using match-sorter
					// it will take the content entered into the "filter"
					// and search for it in keys
					const result = matchSorter(rows, filter.value, {
						keys: ['name', 'domain', 'orientation', 'status', 'company', 'playerCode'],
						threshold: matchSorter.rankings.CONTAINS,
					});
					// console.log('row[0]:', result[0]);
					return result;
				},
				filterAll: true,
			},
		];

		this.screenActions = [
			{
				shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given screen
				icon: <ResetIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.RestartPlayerDialogTitle',
				}),
				isDisabled: (screen) => {
					let isPlayerInActive =
						screen.status === 'INACTIVE' ||
						screen.status === 'NEVER' ||
						screen.sysExecCommands.some((item) => item.name === 'REBOOT_SYS')
							? true
							: false;
					let isResetStateDisabled =
						this.state.isResetDisable === true && screen.id === this.state.resetScreenId
							? this.state.isResetDisable
							: isPlayerInActive;
					return isResetStateDisabled;
				},
				clickHandler: this.editScreen,
			},
		];
		this.searchColumnIdForCardView = [
			'name',
			'domain',
			'orientation',
			'status',
			'createdBy.company',
			'playerCode',
		];
		this.columnsGridView = [
			{
				accessor: (d) => d.createdBy.company || '',
				id: 'company',
				Header: 'Site',
				editable: false,
				CustomCell: (cellInfo) => {
					return (
						<Link
							variant="body1"
							// className={this.props.classes.hoverCursor}
							underline="always"
							target="_blank"
							rel="noopener"
							href={getRouteScreenPlayerUniverse(
								cellInfo.domain,
								cellInfo.createdBy.email,
								cellInfo.createdBy.uid
							)}
						>
							<Typography title={cellInfo.createdBy.email}>{cellInfo.createdBy.company}</Typography>
						</Link>
					);
				},
			},
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.NameCol' }), //'Screen Name',
				editable: false,
				// editableComp: 'text',
			},
			{
				accessor: (d) => d.domain || '',
				id: 'domain',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.DomainCol',
				}), //'domain Name',
				editable: false,
			},
			{
				accessor: (d) => (d.comment ? d.comment.message : ''),
				id: 'comment',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.CommentCol',
				}), //'comment',
				editable: false,
				CustomCell: (cellInfo) => {
					if (cellInfo.comment) {
						return (
							<Tooltip
								title={this.props.intl.formatMessage({ id: 'GENERAL.Preview' })}
								// className={this.props.classes.addIconC}
							>
								<IconButton
									classes={{ root: this.props.classes.previewButtonCard }}
									aria-label="preview"
									onClick={() => {
										this.setState({ editing: { original: cellInfo } });
										this.openCommentDialog(cellInfo);
									}}
								>
									<PreviewIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						);
					} else {
						return (
							<Tooltip
								title={this.props.intl.formatMessage({
									id: 'pages.ScreenManager.components.PlayerUniverse.AddCommentDialogLabel',
								})}
								className={this.props.classes.addIconCard}
							>
								<IconButton
									color="primary"
									aria-label="add"
									onClick={() => {
										this.setState({ adding: { original: cellInfo } });
										this.openCommentDialog(cellInfo);
									}}
								>
									<AddCommentIcon color="primary" />
								</IconButton>
							</Tooltip>
						);
					}
				},
			},
			{
				accessor: (d) => d.orientation || '',
				id: 'orientation',
				Header: (
					<div
						title={props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Screens.OrientationCol',
						})}
					>
						<PortraitIcon className={this.props.classes.portraitIcon} />
						<LandscapeIcon className={this.props.classes.landscapeIcon} />
					</div>
				),
				editable: false,
				CustomCell: (cellInfo) =>
					cellInfo.orientation === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />,
			},
			{
				accessor: (d) => d.playerCode,
				id: 'playerCode',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.PlayCodeCol',
				}), //'Player code',
				editable: false,
				CustomCell: (cellInfo) => cellInfo.playerCode || '',
			},
			{
				accessor: (d) => d.status || '',
				id: 'status',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentStatusCol',
				}), //'Status',
				editable: false,
				CustomCell: (cellInfo) =>
					cellInfo.status === 'ACTIVE' ? (
						<ActiveIcon className={props.classes.success} style={{ fontSize: 30 }} />
					) : (
						<InactiveIcon color="error" className={props.classes.shake} style={{ fontSize: 30 }} />
					),
			},
			{
				accessor: (d) => d.lastConnectedAt || '',
				id: 'lastConnectedAt',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.lastConnectedAtCol',
				}), //'Last Seen',
				editable: false,
				CustomCell: (cellInfo) => {
					return cellInfo.lastConnectedAt ? moment(cellInfo.lastConnectedAt).fromNow() : 'Never';
				},
			},
		];
	}

	renderDomainArr = () => {
		let result = _.sortBy(
			[...new Map(this.props.screenManagerUniverse.screens.map((m) => [m.domain, m])).values()],
			[(screen) => screen.domain.toLowerCase()]
		);
		result.unshift({
			name: 'Show all',
			domain: 'Show all',
			id: 'showAll',
		});
		const items = result.map((screenName) => {
			return (
				<MenuItem key={screenName.id} value={screenName.domain}>
					{screenName.domain}
				</MenuItem>
			);
		});
		return items;
	};

	filterAll = (e) => {
		const { value } = e.target;
		// const filterAll = value;
		const filtered = [{ id: 'all', value: value }];
		// NOTE: this completely clears any COLUMN filters
		this.setState({ filtered });
	};

	getTrComp = (props) => <RTTrComp {...props} />;

	// componentDidMount() {
	// 	var masterUserIds = [];
	// 	this.props.fetchScreenManagerPlaylistUniverse({}, ({ result, error }) => {
	// 		if (!error) {
	// 			if (result.data.length > 0) {
	// 				result.data.forEach((items) => {
	// 					if (items.createdBy) {
	// 						let exists = masterUserIds.includes(items.createdBy.uid);
	// 						if (!exists) {
	// 							masterUserIds.push(items.createdBy.uid);
	// 						}
	// 					}
	// 				});
	// 				this.props.fetchUserDataByIdGroup({
	// 					queryParams: {
	// 						userIds: masterUserIds.join(','),
	// 					},
	// 				});
	// 			}
	// 		}
	// 	});
	// }
	componentDidMount() {
		this.updateScreenResolution();
		window.addEventListener('resize', this.updateScreenResolution);
		this.props.fetchScreenManagerPlaylistUniverse();
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateScreenResolution);
	}
	updateScreenResolution() {
		this.setState({ isDesktop: window.innerWidth > 955 });
	}
	// Restart the player confirmDialog
	restartPlayer = (screenboardData) => {
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Screens.RestartPlayerDialogTitle',
			}),
			content: (
				<Typography variant="body1" gutterBottom style={{ minHeight: 50, marginTop: 25 }}>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Screens.RestartPlayerDialogMessage"
							values={{
								playerName: (
									<Typography variant="h6" component="span" color="secondary">
										{screenboardData.name}
									</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				let reqData = {
					commands: [
						{
							name: 'REBOOT_SYS',
						},
					],
				};
				let playerId = screenboardData.id;
				setCommandsToPlayer({ playerId, bodyParams: reqData })
					.then(() => {
						this.setState({ isResetDisable: true });
						this.setState({ resetScreenId: playerId });
						notifyGeneral(`Player has been restarted successfully`, 'success');
					})
					.catch((err) => {
						notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
					});
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};
	saveEditing = (val) => {
		let { comment } = { ...this.state.editing };
		if (val === undefined) {
			let newCommentData = { ...this.state.editing };
			delete newCommentData.original;
			if (Object.keys(newCommentData).length === 0) {
				return this.props.notifyError(
					new Error(
						this.props.intl.formatMessage({
							id: 'pages.ScreenManager.components.PlayerUniverse.NotifyErrorNoDataChange',
						})
					)
				);
			}
		}
		comment = val === '' ? val : comment;
		let reqCommentData = {
			comment: comment,
		};
		return this.props.editPlaylerUniverseComment(
			{ playerId: this.state.editing.original.id, reqData: reqCommentData },
			({ error }) => {
				if (!error) {
					this.setState({ editing: {} });
					this.props.resetGlobalDialog();
				}
			}
		);
	};

	addComment = () => {
		let { comment } = { ...this.state.adding };
		if (!comment) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.PlayerUniverse.NotifyErrorMissingRequiredData',
					})
				)
			);
		}
		let reqCommentData = {
			comment: comment,
		};
		return this.props.editPlaylerUniverseComment(
			{ playerId: this.state.adding.original.id, reqData: reqCommentData },
			({ error }) => {
				if (!error) {
					this.setState({ adding: {} });
					this.props.resetGlobalDialog();
				}
			}
		);
	};

	// Common dialog for Add, Edit and Preview is used
	openCommentDialog = (rowValue) => {
		const { classes, intl } = this.props;
		let commentDialogTitle = '';
		const commentDialogLableIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.PlayerUniverse.CommentCol',
		});
		const addCommentDialogNameTitle = intl.formatMessage({
			id: 'pages.ScreenManager.components.PlayerUniverse.AddCommentDialogLabel',
		});
		const previewCommentDialogNameTitle = intl.formatMessage({
			id: 'pages.ScreenManager.components.PlayerUniverse.PreviewCommentDialogLabel',
		});
		let commentValue = '';
		let commentTimeStamp = '';
		if (rowValue.comment) {
			commentDialogTitle = previewCommentDialogNameTitle;
			commentValue = rowValue.comment.message;
			commentTimeStamp = rowValue.comment.timestamp;
		} else {
			commentDialogTitle = addCommentDialogNameTitle;
		}
		let content = (
			<div style={{ minWidth: 250 }}>
				<TextField
					required
					id="departmentName"
					label={commentDialogLableIntl}
					className={classes.addingTextField}
					variant="outlined"
					multiline
					rows={4}
					size="small"
					inputProps={{ style: { resize: 'both' } }}
					InputProps={{
						classes: {
							input: classes.resize,
						},
					}}
					defaultValue={commentValue || ''}
					onChange={(event) => {
						commentValue
							? this.setState({ editing: { ...this.state.editing, comment: event.target.value } })
							: this.setState({ adding: { ...this.state.adding, comment: event.target.value } });
					}}
				/>
				{commentValue && (
					<div className={classes.commentTimeStamp}>
						<Typography variant="body1" style={{ fontSize: 13 }}>
							{intl.formatMessage({
								id: 'pages.ScreenManager.components.PlayerUniverse.CommentEditedLabel',
							})}
						</Typography>
						<Typography
							style={{ fontSize: 12, fontStyle: 'italic', paddingLeft: 4, lineHeight: 'normal' }}
						>
							{commentTimeStamp
								? toLocaleDate(new Date(commentTimeStamp)) +
								  ' at ' +
								  moment(commentTimeStamp).format('HH:mm:00')
								: ''}
						</Typography>
					</div>
				)}
				<div className={classes.addingButtonContainer}>
					<Button
						variant="contained"
						color="primary"
						className={classes.addingButton}
						onClick={() => {
							let currentEditing = this.state.editing;
							let currentAdding = this.state.adding;
							commentValue ? this.saveEditing() : this.addComment();
							commentValue
								? this.setState({ editing: { ...currentEditing, comment: commentValue } })
								: this.setState({ adding: { ...currentAdding, comment: commentValue } });
						}}
					>
						{intl.formatMessage({ id: 'GENERAL.Save' })}
						<SaveIcon className={classes.rightIconInButton} />
					</Button>
					{commentValue && (
						<Button
							variant="contained"
							color="secondary"
							// className={classes.addingButton}
							onClick={() => {
								let confirmDialog = {
									size: 'sm',
									title: this.props.intl.formatMessage({
										id: 'pages.ScreenManager.components.PlayerUniverse.DeleteCommentDialogTitle',
									}),
									content: (
										<Typography
											variant="body1"
											gutterBottom
											style={{
												minHeight: 50,
												marginTop: 25,
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											{
												<FormattedMessage id="pages.ScreenManager.components.PlayerUniverse.DeleteCommentDialogMessage" />
											}
										</Typography>
									),
									confirmCB: () => {
										let currentEditing = this.state.editing;
										this.setState({ editing: { ...currentEditing, comment: '' } });
										this.saveEditing('');
									},
								};
								this.props.openGlobalDialog(confirmDialog);
							}}
						>
							{intl.formatMessage({ id: 'GENERAL.Delete' })}
							<DeleteIcon className={classes.rightIconInButton} />
						</Button>
					)}
				</div>
			</div>
		);
		let addingPlaylistDialog = {
			size: 'sm',
			title: commentDialogTitle,
			content: content,
		};
		this.props.openGlobalDialog(addingPlaylistDialog);
	};

	render() {
		const { classes, screenManagerUniverse, intl } = this.props;
		const { isFetching, screens } = screenManagerUniverse;
		screens.forEach((item) => {
			item.status = item.lastConnectedAt === undefined ? 'NEVER' : item.status;
			// For sortByStatus -> Active is 2, Inactive is 1, and Never is 3. It will sort in this order.
			item.sortByStatus = item.status === 'ACTIVE' ? 2 : item.status === 'INACTIVE' ? 1 : 3;
		});
		const isDesktop = this.state.isDesktop;
		return (
			<div className={classes.contentWrapper}>
				<Paper className={classes.header}>
					<Typography variant="h6" gutterBottom className={classes.title}>
						{intl.formatMessage({
							id: 'pages.ScreenManager.components.PlayerUniverse.TableTitle',
						})}
					</Typography>
					<Search
						placeholder={intl.formatMessage({ id: 'GENERAL.Search' })}
						className={classes.search}
						onChange={this.filterAll.bind(this)}
						onClearClick={() => {
							if (this.state.filtered.length === 0) return;
							this.setState({ filtered: [] });
						}}
						// OnEnterKeyPressed={this.filterAll.bind(this)}
					/>
				</Paper>
				{!isDesktop ? (
					<ScreenManagerGridView
						cardViewData={screens}
						columns={this.columnsGridView}
						previewHeight={130}
						screenActions={this.screenActions}
						searchFilter={this.state.filtered}
						searchColumnId={this.searchColumnIdForCardView}
						noDataMsg={
							isFetching
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'ReactTable.NoDataText',
								  })
						}
					/>
				) : (
					<ReactTable
						// ref="screensTable"
						// International Lang
						previousText={intl.formatMessage({ id: 'ReactTable.PreviousText' })}
						nextText={intl.formatMessage({ id: 'ReactTable.NextText' })}
						loadingText={intl.formatMessage({ id: 'ReactTable.LoadingText' })}
						noDataText={intl.formatMessage({ id: 'ReactTable.NoDataText' })}
						pageText={intl.formatMessage({ id: 'ReactTable.PageText' })}
						ofText={intl.formatMessage({ id: 'ReactTable.OfText' })}
						rowsText={intl.formatMessage({ id: 'ReactTable.RowsText' })}
						defaultFiltered={[]}
						defaultSorted={[
							{ id: 'sortByStatus' },
							{ id: 'lastConnectedAt' },
							{ id: 'createdAt', desc: true },
						]}
						filtered={this.state.filtered}
						// onFilteredChange={filtered => {
						// 	this.setState({ filtered });
						// }}
						TrComponent={this.getTrComp}
						// TdComponent={TdComponent}
						// manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={screens}
						// pages={pages} // Display the total number of pages
						loading={isFetching} // Display the loading overlay when we need it
						// onFetchData={this.fetchBrandsData.bind(this)} // Request new data when things change
						// data={this.props.getProductsDataReducerState.productsData}
						multiSort={false}
						columns={this.columns}
						defaultPageSize={10}
						style={{
							height: '600px', // This will force the table body to overflow and scroll, since there is not enough room
						}}
						className="-striped -highlight"
						// eslint-disable-next-line no-unused-vars
						defaultFilterMethod={(filter, row, column) => {
							const id = filter.pivotId || filter.id;
							return row[id] !== undefined
								? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
								: true;
						}}
						// eslint-disable-next-line no-unused-vars
						getTheadTrProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.tableHeadCell + ' ' + state.className,
								style: { alignItems: 'center' },
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTableProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.table + ' ' + state.className,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTrProps={(state, rowInfo, column, instance) => {
							if (!rowInfo) return {};
							// Disable the reset player button if Inactive or it has command - REBOOT_SYS
							let isPlayerInActive =
								rowInfo.original.status === 'INACTIVE' ||
								rowInfo.original.status === 'NEVER' ||
								rowInfo.original.sysExecCommands.some((item) => item.name === 'REBOOT_SYS')
									? true
									: false;
							// let isResetStateDisabled =
							// 	this.state.isResetDisable === false ? isPlayerInActive : this.state.isResetDisable;
							let isResetStateDisabled =
								this.state.isResetDisable === true &&
								rowInfo.original.id === this.state.resetScreenId
									? this.state.isResetDisable
									: isPlayerInActive;
							return {
								style: {
									alignItems: 'center',
								},
								resetAction: this.restartPlayer.bind(this, rowInfo.original),
								isResetDisabled: isResetStateDisabled,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTdProps={(state, rowInfo, column, instance) => {
							// get TD (column) props
							// let editAction, editingUI;
							// if (rowInfo) {
							// 	switch (column.id) {
							// 		case 'name':
							// 		case 'restricted_to':
							// 		case 'metadata':
							// 			editingUI = <EditBrandMeta brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		case 'brand_logo':
							// 			editingUI = <EditingLogo brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		default:
							// 			break;
							// 	}
							// 	if (editingUI) editAction = () => this.setState({ editingUI: editingUI });
							// }
							return {
								// style: { whiteSpace: 'unset' },
								// className: classes.tableCell + ' ' + state.className,
								// editAction: editAction,
							};
						}}
					/>
				)}

				{
					// <Dialog open={this.state.addingScreenOpen} size='md' title='Enter the details for the new Screen:' content={this.state.addingScreenUI} onClose={this.onCloseAddingScreenDialog} />
				}
			</div>
		);
	}
}

PlayerUniverse.propTypes = {
	classes: PropTypes.object.isRequired,
	screenManagerUniverse: PropTypes.object.isRequired,
	fetchScreenManagerPlaylistUniverse: PropTypes.func.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	resetGlobalDialog: PropTypes.func.isRequired,
	editPlaylerUniverseComment: PropTypes.func.isRequired,
	notifyError: PropTypes.func.isRequired,
};

PlayerUniverse.defaultProps = {
	// multiple: false,
	// allowZip: false,
	// allowPreview: true,
	// disableClick: false,
};

const mapStateToProps = (state) => {
	return {
		screenManagerUniverse: state.screenManagerUniverse,
	};
};

export default connect(mapStateToProps, {
	openGlobalDialog,
	resetGlobalDialog,
	fetchScreenManagerPlaylistUniverse,
	notifyError,
	notifyGeneral,
	editPlaylerUniverseComment,
})(injectIntl(withStyles(PlayerUniverseStyle)(PlayerUniverse)));
