import React from 'react';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { convertHtmlAttrToReact } from 'utils/libHelper';
import { prepareConcatTextField } from 'utils/artwork/artFieldsCreator';
import { createAnimationOfFieldInVideoArtwork } from 'utils/artwork/artUtilsWebUI';

const useStyles = makeStyles((theme) => ({
	pauseAnimation: {
		animationPlayState: 'paused !important',
	},
}));

function ConcatText({
	templateFields,
	fieldOutputData,
	field,
	// fieldPreviewData,
	animationDelay,
	allowAnimation,
	pausingAnimation,
	isVideoArtwork,
	...rest
}) {
	const classes = useStyles();

	const textData = prepareConcatTextField({
		mode: 'preview',
		// inputData: fieldPreviewData,
		field,
		templateFields,
		fieldOutputData,
		dataForExport: {},
		CONSTANTS: { placeholderSameAsText: ART_VARIABLES.placeholderSameAsText },
	});
	if (!textData) return null;
	let {
		tspansPerLine,
		textAttrs,
		lineHeight,
		attrsPerLine,
		dyFirstLine,
		strikeLinesAttrs,
		gContentScale,
		hasShadow,
	} = textData;

	const noAnimationStyle = `0s ease 0s 1 normal none running none`;
	const animationEntranceStyle = isVideoArtwork
		? createAnimationOfFieldInVideoArtwork(field)
		: field.animation.entrance
		? `${field.animation.entrance} ${
				field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
		  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
				animationDelay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
		  }s both`
		: noAnimationStyle;
	// const animationExitStyle = field.animation.exit
	// 	? `${field.animation.exit} ${field.animation.duration ||
	// 			1}s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${0}s both`
	// 	: noAnimationStyle;
	const TextComp = ({ hasShadow, ...rest }) => (
		<text
			{...convertHtmlAttrToReact(textAttrs)}
			{...(hasShadow ? { fill: field.textShadowColor.hex || '#000000' } : {})}
			{...rest}
		>
			{Object.keys(tspansPerLine).map((lineKey, arrayIdx) => {
				// we use nested <tspan> per line, each line is a <tspan>, string in a line is in nested <tspan>
				let lineTspans = tspansPerLine[lineKey];
				let lineAttrs = attrsPerLine[lineKey];
				// tspansPerLine = {
				// 	LINE_INDEX: [
				// 		{
				// 			tspans: [tspanObj, tspanObj, ...], // it is tspanObj if non-numric field, otherwise it is array of tspanObj for number field, and we use nested <tspan> to render it, so that we can calculate its bBox in case for strikeThrough
				// 			strikeThroughStyle: {
				// 				type: '' | 'strike' | 'strikeup' | 'strikedown',
				// 				lineWidth: 1,
				// 			},
				// 		},
				// 	],
				// };
				return (
					<tspan
						key={`concatField-${field.id}-${lineKey}`}
						dy={`${arrayIdx === 0 ? dyFirstLine : lineHeight}em`} // note: lineAttrs has x & dy, have it here is only as default value
						x={'0'}
						{...convertHtmlAttrToReact(lineAttrs)}
						{...(hasShadow ? { fill: field.textShadowColor.hex || '#000000' } : {})}
					>
						{lineTspans.map((tspanItem, tspanIdx) => {
							// let isFirstItemInTheLine = tspanIdx === 0;
							if (tspanItem.tspans.length === 1) {
								let tspanObj = tspanItem.tspans[0];
								return (
									<tspan
										key={`concatField-${field.id}-${lineKey}-${tspanIdx}`}
										{...convertHtmlAttrToReact(tspanObj.attrs)}
										{...(hasShadow ? { fill: field.textShadowColor.hex || '#000000' } : {})}
									>
										{tspanObj.value}
									</tspan>
								);
							} else if (tspanItem.tspans.length > 1) {
								// we use nested <tspan>
								return (
									<tspan key={`concatField-${field.id}-${lineKey}-${tspanIdx}`}>
										{tspanItem.tspans.map((tspanObj, nestedTspanIdx) => {
											return (
												<tspan
													key={`concatField-${field.id}-${lineKey}-${tspanIdx}-${nestedTspanIdx}`}
													{...convertHtmlAttrToReact(tspanObj.attrs)}
													{...(hasShadow ? { fill: field.textShadowColor.hex || '#000000' } : {})}
												>
													{tspanObj.value}
												</tspan>
											);
										})}
									</tspan>
								);
							} else {
								return null;
							}
						})}
					</tspan>
				);
			})}
		</text>
	);

	return (
		<svg
			x={field.position.left}
			y={field.position.top}
			width={field.position.width}
			height={field.position.height}
			overflow={'visible'}
		>
			{/** To prevent the filter shadow gets cut off, we extent filter region by 50% hor & ver */}
			{hasShadow && (
				<filter
					id={'shadow-' + field.id}
					x="-50%"
					y="-50%"
					width="200%"
					height="200%"
					filterUnits="userSpaceOnUse"
				>
					<feGaussianBlur stdDeviation={field.shadowBlurRadius || 0} result="shadow" />
					<feOffset dx={field.shadowHorOffset || 0} dy={field.shadowVerOffset || 0} />
				</filter>
			)}
			<g
				className={cx({ [classes.pauseAnimation]: pausingAnimation })}
				style={{
					animation: allowAnimation ? animationEntranceStyle : noAnimationStyle,
					opacity: isVideoArtwork && !allowAnimation ? 0 : undefined, // allowAnimation is used to control replay of animation/video, there is about 100ms delay when replay animation/video, so for videoArtwork, we hide text preview when replay was just clicked but before replay actually begins
				}}
			>
				{/* Somehow, "transform" not working when together with "style" in the same <g> */}
				{
					/** shadow on whole <g> content */
					// hasShadow && (
					// 	<g
					// 		transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
					// 			field.position.height / 2
					// 		}) scale(${gContentScale})`}
					// 		filter={`url(#${'shadow-' + field.id})`}
					// 	>
					// 		<TextComp hasShadow={true} />
					// 		{strikeLinesAttrs.map((strikeAttrs, idx) => {
					// 			return (
					// 				<line
					// 					key={`concatField-${field.id}-${idx}`}
					// 					{...convertHtmlAttrToReact(strikeAttrs)}
					// 					stroke={field.textShadowColor.hex || '#000000'}
					// 				/>
					// 			);
					// 		})}
					// 	</g>
					// )
				}
				<g
					transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
						field.position.height / 2
					}) scale(${gContentScale})`}
				>
					{hasShadow && <TextComp hasShadow={true} filter={`url(#${'shadow-' + field.id})`} />}
					<TextComp />
					{strikeLinesAttrs.map((strikeAttrs, idx) => {
						return (
							<line
								key={`concatField-${field.id}-${idx}`}
								{...convertHtmlAttrToReact(strikeAttrs)}
							/>
						);
					})}
				</g>
			</g>
		</svg>
	);
}

ConcatText.propTypes = {
	templateFields: PropTypes.array.isRequired,
	/**
	 * field output data of all tempalte fields, combination of user input data & field default data
	 * guaranteed any value is not null|undefined, it will be '', true/false or "actual value"
	 */
	fieldOutputData: PropTypes.object.isRequired,
	field: PropTypes.object.isRequired, // it is text field
	// fieldPreviewData: PropTypes.object.isRequired, // the preview data of a field
	animationDelay: PropTypes.number.isRequired, // animation delay. Relative to previous fields
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is aninimation paused (on video end in videoArtwork)
	isVideoArtwork: PropTypes.bool.isRequired,
};

ConcatText.defaultProps = {};
export default React.memo(ConcatText);
