import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
// import config from 'config';

// import RSelect from 'react-select';
import ReactResizeDetector from 'react-resize-detector';
import { DataGrid } from '@mui/x-data-grid';

// import Draggable from 'react-draggable';

// Constants
import { ART_VARIABLES, DesignContext } from '../../Constants';

// API
import { artworkFetchSimilarTemplates, artworkFetchTemplateById, fetchClipImage } from 'restful';

// MUI components
import { Typography, Link } from '@mui/material';

// custom components
import { PerfectScrollWrapper /* Loading */ } from 'components';

// Artwork components
import Sidemenu from '../Sidemenu/Sidemenu';
import ElementPalette from '../ElementPalette/ElementPalette';
// import DesignPreview from '../DesignPreview/DesignPreview';
import SVGPreview from '../SVGPreview/SVGPreview';
import PreviewControlPanel from '../PreviewControlPanel/PreviewControlPanel';
import KeyboardListener from '../KeyboardListener/KeyboardListener';
import ArtLoading from '../ArtLoading/ArtLoading';
import DesignOverlay from '../DesignOverlay/DesignOverlay';
import DesignSetting from '../DesignSetting/DesignSetting';
import BottomActionRibbon from '../BottomActionRibbon/BottomActionRibbon';
import ImageCropper from '../SVGPreview/ImageCropper';
import InvalidFieldsMUIPopper from '../InvalidFieldsMUIPopper/InvalidFieldsMUIPopper';

import {
	Player,
	ControlBar,
	BigPlayButton,
	LoadingSpinner,
	Shortcut,
	VolumeMenuButton,
} from 'video-react';
import 'video-react/dist/video-react.css'; // import video-react css

import { genUUID, genRandomStr, roundDecimals, isBlobUrl } from 'utils/generalHelper';
import { immutableUpdate, _, deepClone } from 'utils/libHelper';
import { convertRemoteFieldsToLocal } from 'utils/artwork/artTemplateConverter';
import {
	geFontListInTemplateFields,
	loadFontfaceToDocument,
	getFieldOutputData,
} from 'utils/artwork/artUtilsCommon';
import {
	deleteCroppedImgUrl,
	cloneField,
	findEffectFields,
	calcRectToFitScreen,
	fitPosition,
	renewFieldInputDataBySelectedSpreadsheet,
	validateField,
	validateTemplateSettings,
	hasAnimation,
} from 'utils/artwork/artUtilsWebUI';
// intl lang
import { useIntl } from 'react-intl';

// CSS
import { default as useStyles } from './DesignStyle.jsx';

import { getRouteMediafileDetail, getRouteArtworkCreator, getRouteArtworkEsign } from 'routes';

// redux
import { connect } from 'react-redux';
import {
	turnOnSidebar,
	turnOffSidebar,
	updateLocalDesignerTemplate,
	setArtDesignState,
	notifyGeneral,
	getDesignerTemplate,
	importArtworkTemplate,
	resetDesignerTemplate,
	resetArtworkFonts,
	fetchArtworkFonts,
	fetchArtworkSpreadsheets,
	resetArtworkSpreadsheets,
	fetchArtworkLists,
	resetArtworkLists,
	fetchArtworkAutoImport,
	resetArtworkAutoImport,
	fetchArtworkOutputTemplates,
	resetArtworkOutputTemplates,
	fetchArtworkCategories,
	resetArtworkCategories,
	saveDesignerTemplate,
	// retrieveDraftDesignTemplate,
	fetchLightboxes,
	resetLightboxes,
	fetchSpreadsheetContentById,
	resetSpreadsheetContentById,
	undoDesignTemplate,
	redoDesignTemplate,
	openGlobalDialog,
	resetGlobalDialog,
} from 'redux/actions'; // actions

function Design({
	history,
	location,
	turnOnSidebar,
	turnOffSidebar,
	artworkDesignerTemplate,
	updateLocalDesignerTemplate,
	setArtDesignState,
	notifyGeneral,
	getDesignerTemplate,
	importArtworkTemplate,
	resetDesignerTemplate,
	resetArtworkFonts,
	artworkExtra,
	spreadsheetContentById,
	userData,
	fetchArtworkFonts,
	fetchArtworkSpreadsheets,
	resetArtworkSpreadsheets,
	fetchArtworkLists,
	resetArtworkLists,
	fetchArtworkAutoImport,
	resetArtworkAutoImport,
	fetchArtworkOutputTemplates,
	resetArtworkOutputTemplates,
	fetchArtworkCategories,
	resetArtworkCategories,
	saveDesignerTemplate,
	// retrieveDraftDesignTemplate,
	fetchLightboxes,
	resetLightboxes,
	fetchSpreadsheetContentById,
	resetSpreadsheetContentById,
	canUndo,
	canRedo,
	undoDesignTemplate,
	redoDesignTemplate,
	openGlobalDialog,
	resetGlobalDialog,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	// Ref
	const workspaceContainerRef = useRef();
	const WSPreviewRef = useRef();
	const [psWrapper, setPsWrapper] = useState({});
	const userInputDataRef = React.useRef(); // for croppedImgUrl (blobUrl) cleanup on unmounted purpose
	const videoArtworkVideoRef = useRef(); // ref to the video element in videoArtwork
	// state
	const [openInvalidFields, setOpenInvalidFields] = React.useState(false);
	const [allowAnimation, setAllowAnimation] = useState(true); // to enable|disable|reset preview animation
	const [pausingAnimation, setPausingAnimation] = useState(false);
	const [contextualPaletteOpen, setContextualPaletteOpen] = useState(true);
	const [zoomControls, setZoomControls] = useState({ isFit: true });
	const [initStatus, setInitStatus] = useState({ status: 'OK', message: '' }); // state of artwork data initialization at page loading
	// progress status of any async process. {status: 'OK'|'DONE'|'FAILED', message: 'xxx'} or {} (to reset it)
	const [progressStatus, setProgressStatus] = useState({});
	// state of similar templates of the loaded one. Used for importing template
	const [similarTemplates, setSimilarTemplates] = useState({ status: '', templateList: [] }); // possible value of status: 'PROCESSING', 'OK', 'FAILED', ''
	// controls of UI. e.g. display preview, display BG image, etc.
	const [UIControls, setUIControls] = useState({
		isPreviewEnabled: false,
		isBGImageEnabled: true,
		isFieldHighlightEnabled: true,
		// isPreviewControlPanelOpen: false,
	});

	const [cropImage, setCropImage] = useState({});

	// const [fieldsPreviewValue, setFieldsPreviewValue] = useState({});
	/**
	 * input data of each field. Called fieldInputData
	 * Format: {FIELD_ID: {}}
	 * NOTE-1: fieldInputData only contains user's input data through preview panel. Initial value is null/undefined; set to '' when it is cleared
	 * 				 We will have a merged dataset to merge fieldInputData & field default data, then use the merged dataset to output
	 * NOTE-2: When the value in a field is cleared by user, no data will be used for output the field (preview or pdf generation), hence the following rules:
	 * 				 - if field data in this fieldInputData is null/undefined, we will use field default data (in the merged dataset)
	 * 				 - if field data in this fieldInputData is '', we will leave it empty (in the merged dataset), even there is default data for the field
	 fieldInputData: {
		TEXT_FIELD_ID: {
			// TEXT Preview Data
			value: 'xxxx', // value of this text field. Possible values: null/undefined, '', 'text'
			fontsize: NUMBER | undefined,
			horizontalAlign: 'left' | 'center' | 'right' | 'justified' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		},
		IMAGE_FIELD_ID: {
			// IMAGE Preview Data. NB: priority to use image: croppedImgUrl > clippedImgUrl > highResUrl | optimisedUrl
			mediafileId: 'xxxxxx', // user selected mediafile ID. Possible values: null/undefined, '', '32345', 'external:brankbank:sser1234ji';
			previewUrl: 'xxxxxxx', // preview url of this image. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

			// NB: once cropped image is saved in s3, we don't delete it as saved mediafiles may still use it
			// croppedImgUrl could be blob object url, to prevent memory leak, we clean it in following cases:
			// 	- any place that updates it by setFieldInputDataById
			//	- input data updated by spreadsheet product picker in handleProductPickerSelection
			//	- a new cropped image is created in handleCroppedImage
			//	- field input data is reset by resetFieldInputData
			croppedImgUrl: 'xxx', // web url or blob url of the cropped image (png format). Possible values: null/undefined, '', 'blob:https://xxx | https://xxxx'
			clippedImgUrl: 'https://xxxx', // web url of the clipped image. Possible values: null/undefined, '', 'https://xxx'
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		},
		BARCODE_FIELD_ID: {
			// BARCODE Preview Data
			value: 'xxxxxxx', // value of this barcode field. Possible values: null/undefined, '', 'barcode'
			EAN13Value: 'xxx', // processed data from "value", only in output data
			EAN5Value: 'xxx', // processed data from "value", only in output data
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		},
		PDF_FIELD_ID:{
			// Artwork field Preview Data
			mediafileId: 'xxxxxx', // user selected mediafile ID (not used for output, but used to save content). Possible values: null/undefined, '', '32345';
			previewUrl: 'xxxxxxx', // svg url of this pdf/svg mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // svg url of the pdf/svg mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // svg url of the pdf/svg mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			
			//svgUrl: 'xxxx',
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
			
		},
		VIDEO_FIELD_ID: {
			// VIDEO Preview Data
			mediafileId: 'xxxxxx', // user selected mediafile ID (not used for output, but used to save content). Possible values: null/undefined, '', '32345';
			previewUrl: 'xxxxxxx', // preview url of this video. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // transcoded video url of the mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // transcoded video url of the mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			
			// videoUrl: 'https://xxxxx', // undefined or original video url???
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
			videoLoop: true | false | undefined,
		},
		GRID_FIELD_ID: {
			// tableData format: [RowData, RowData, ...]; RowData: [cellText, cellText, ...]; cellText may be empty string, may contain multiple lines by '\n'. Get non-empty lines by `.split(/\r?\n|\r/g).filter((s) => s)`
			// sample data of tableData: [["Product","Small","Large"],["Cappuccino\n\nsubtitle","€1.99\n\nsubtitle","€2.99"],["Mocha","€2.49","€3.99"]]
			// possible value: null/undefined (no input data), [['xxx', 'xxx'], ...]
			// when its value is null/undefined, the code logic in "getFieldOutputData" to decide using field.defaultEditorHtml to render or not
			tableData: [["Product","Small","Large"],["Cappuccino\n\ngghgh","€1.99\n\nnew line","€2.99"],["Mocha","€2.49","€3.99"]],
			hasHeader: true/false, // indicate if there is header in the table
			// editorHtml possible values: null/undefined, 'xxxx'
			editorHtml, // the html string in the editor.
			fontsize, // possible values: null/undefined, number. user defined font size (only available when user-defined font size is allowed)
		}
	}
	 */
	const [fieldInputData, setFieldInputData] = useState({});

	/**
	 * the template background from user's selection
	 * the value references to one single mediafile. The value is either null/undefined, "", or real value
	 * NB: in case the value is "", it means no background will be used, the default is ignored too.
	 {
		 	mediafileId: 'xxx', // mediafile id.
			mediafilePreviewUrl: 'https://xxx', //  preview url.
			mediafileHighResUrl: 'https://xxx', // high-resolution url of mediafile (for generating high quality pdf)
			mediafileOptimisedUrl: 'https://xxx', // optimised url of mediafile (fast loading & good quality for webpage)
			mediafilePdfUrl: 'https://xxx', // pdf url of the background mediafile
			mediafileSvgUrl: 'https://xxx' // svg url of the background mediafile
	 }
	 */
	const [templateBGInputData, setTemplateBGInputData] = useState({});

	// const svgRef = React.useRef(null);
	/****
	 * WSRect.borderRect is the outer box of the design preview area, with ART_VARIABLES.WSBorderH & ART_VARIABLES.WSBorderV as the border (or margin) of the design preview area
	 * WSRect.previewRect is the design preview area, with the template media as its background image
	 * format of WSRect:
	  	{
				previewRect: { width: NUMBER_in_Pixel, height: NUMBER_in_Pixel, x: NUMBER_in_Pixel, y: NUMBER_in_Pixel},
				borderRect:{ width: NUMBER_in_Pixel, height: NUMBER_in_Pixel, x: NUMBER_in_Pixel, y: NUMBER_in_Pixel},
				zoom: NUMBER,	// e.g. 0.1, 0.20, 0.45, 1, 1.35, 2
			}
	 * */
	const [WSRect, setWSRect] = useState({ previewRect: {}, borderRect: {}, zoom: 1 });
	// const [renderElements, setRenderElements] = useState({
	// 	hoverElement: null,
	// 	// selectedElements: [],
	// });

	// variables
	const workspaceWrapperId = 'WSWrapper-WorkZone'; // + genUUID();
	// const currentPageIdx = 0; // TODO: When support multiple pages, we use the local state to store the page index, e.g.: let [currentPageIdx, setCurrentPageIdx] = useState(0);	// page index, 0-indexed
	// const templatePage = artworkDesignerTemplate; // artworkDesignerTemplate.pages[currentPageIdx];
	// const groupsInCurrentPage = /* artworkDesignerTemplate.pages[currentPageIdx] */ artworkDesignerTemplate.groups.map(
	const groupsInCurrentPage = artworkDesignerTemplate.groups.map((item) => item.name);

	// output template background data
	const templateBGOutputDataWithSettings = React.useMemo(() => {
		// NB: templateBGInputData overrides default when the value is ""
		return { ...artworkDesignerTemplate.templateBG, ...templateBGInputData };
		// return {
		// 	...artworkDesignerTemplate.templateBG,
		// 	mediafileId:
		// 		templateBGInputData.mediafileId || artworkDesignerTemplate.templateBG.mediafileId, // mediafile id
		// 	mediafilePreviewUrl:
		// 		templateBGInputData.mediafilePreviewUrl ||
		// 		artworkDesignerTemplate.templateBG.mediafilePreviewUrl, //  preview url
		// 	mediafileHighResUrl:
		// 		templateBGInputData.mediafileHighResUrl ||
		// 		artworkDesignerTemplate.templateBG.mediafileHighResUrl, // high-resolution url of mediafile (for generating high quality pdf)
		// 	mediafileOptimisedUrl:
		// 		templateBGInputData.mediafileOptimisedUrl ||
		// 		artworkDesignerTemplate.templateBG.mediafileOptimisedUrl, // optimised url of mediafile (fast loading & good quality for webpage)
		// 	mediafilePdfUrl:
		// 		templateBGInputData.mediafilePdfUrl || artworkDesignerTemplate.templateBG.mediafilePdfUrl, // pdf url of the mediafile background
		// 	mediafileSvgUrl:
		// 		templateBGInputData.mediafileSvgUrl || artworkDesignerTemplate.templateBG.mediafileSvgUrl, // svg url of the background mediafile
		// };
	}, [artworkDesignerTemplate.templateBG, templateBGInputData]);

	const replayAnimationAndVideo = React.useCallback(() => {
		setAllowAnimation(false);
		setArtDesignState({
			selectedFieldIds: [],
		});
		setTimeout(() => {
			setAllowAnimation(true);
			setPausingAnimation(false);
		}, 150);
	}, [setArtDesignState]);

	// ##################
	//	helper functions
	// ##################
	const cleanCroppedImgUrls = (userInputData) => {
		Object.values(userInputData).forEach((fieldInput) => {
			deleteCroppedImgUrl(fieldInput.croppedImgUrl);
		});
	};

	const initFontface = React.useCallback(
		(fields, fontList) => {
			// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
			let fontlistInTemplate = geFontListInTemplateFields(fields, fontList, ART_VARIABLES);

			Promise.all(fontlistInTemplate.map((fontOption) => loadFontfaceToDocument(fontOption))).then(
				(results) => {
					let errorResults = results.filter((item) => item.error);
					if (errorResults.length > 0) {
						let msg = (
							<div>
								<Typography variant="body1" component={'div'} gutterBottom>
									{intl.formatMessage({
										id: 'pages.Artwork.components.Design.loadingFontFailureMsg',
									})}
									<Typography
										variant="body2"
										component={'div'}
										style={{ /* color: '#ff9800',  fontStyle: 'italic',*/ fontSize: '0.8rem' }}
									>
										<ul>
											{errorResults.map((result) => (
												<li key={result.error.font.name}>{`${result.error.font.name}`}</li>
											))}
										</ul>
									</Typography>
								</Typography>
							</div>
						);
						notifyGeneral(msg, 'error', { autoHideDuration: null });
					}
				}
			);
		},
		[intl, notifyGeneral]
	);
	// ##################
	//	side effects
	// ##################
	useEffect(() => {
		// control video playback in videoArtwork
		// (mainly controlled by allowAnimation, because css animation should be sync with video playback)
		if (UIControls.isBGImageEnabled && videoArtworkVideoRef.current) {
			// NB: videoArtworkVideoRef is video-react instance, not <video> element
			if (!allowAnimation || !UIControls.isPreviewEnabled) {
				videoArtworkVideoRef.current.pause();
				videoArtworkVideoRef.current.seek(0);
			} else if (allowAnimation || UIControls.isPreviewEnabled) {
				videoArtworkVideoRef.current.seek(0);
				videoArtworkVideoRef.current.play();
			}
		}
	}, [UIControls.isBGImageEnabled, UIControls.isPreviewEnabled, allowAnimation]);

	// Notification of unsaved changes
	useEffect(() => {
		if (
			artworkDesignerTemplate.hasUnsavedData &&
			artworkDesignerTemplate.saveTemplateStatus === ''
		) {
			const beforeunloadHandler = (e) => {
				e.preventDefault();
				e.returnValue = '';
			};
			window.addEventListener('beforeunload', beforeunloadHandler);
			// eslint-disable-next-line no-unused-vars
			const historyUnblock = history.block((newLocation) => {
				if (
					newLocation.pathname !== location.pathname ||
					newLocation.search !== location.search ||
					newLocation.hash !== location.hash
				) {
					return intl.formatMessage({
						id: 'pages.Artwork.components.General.unsavedChangesMsg',
					});
				}
			});
			return () => {
				window.removeEventListener('beforeunload', beforeunloadHandler);
				historyUnblock();
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [artworkDesignerTemplate.hasUnsavedData, artworkDesignerTemplate.saveTemplateStatus]);

	/* init required data */
	useEffect(() => {
		// componentDidMount event

		// add font to document so that svg preview can be rendered probably

		const getArtwork = (cb) => {
			const query = new URLSearchParams(location.search);
			// const retrieveDraft = query.get('retrieveDraft');
			const templateId = query.get('t');
			if (!templateId) {
				return cb({ error: new Error('Missing artwork template ID') });
			}
			// if (retrieveDraft === 'no') {
			getDesignerTemplate({ mediaId: templateId }, cb);
			// } else if (retrieveDraft === 'yes') {
			// 	// call redux action to use draft template
			// 	retrieveDraftDesignTemplate(cb);
			// } else if (!retrieveDraft) {
			// 	// check if there is unsaved draft template
			// 	artworkStorage
			// 		.getItem(config.artworkOfflineStorage.designTemplateKey)
			// 		.then((draftTemplate) => {
			// 			if (draftTemplate && draftTemplate.hasUnsavedData) {
			// 				// prompt user to choose whether or not to use the draft template
			// 				let confirmDialog = {
			// 					size: 'sm',
			// 					title: intl.formatMessage({
			// 						id: 'pages.Artwork.components.Design.draftTemplateDialogTitle',
			// 					}),
			// 					content: (
			// 						<div>
			// 							<Typography variant="body1" gutterBottom>
			// 								{intl.formatMessage({
			// 									id: 'pages.Artwork.components.Design.draftTemplateDialogMsg1',
			// 								})}
			// 							</Typography>
			// 							<Typography style={{ fontSize: 13, fontStyle: 'italic', color: '#ff0000' }}>
			// 								{intl.formatMessage({
			// 									id: 'pages.Artwork.components.Design.draftTemplateDialogMsg2',
			// 								})}
			// 							</Typography>
			// 						</div>
			// 					),
			// 					confirmCB: () => retrieveDraftDesignTemplate(cb),
			// 					onClose: () => {
			// 						// TODO: Shall we delete the draft if user selects 'Not Use Draft'?
			// 						artworkStorage.removeItem(config.artworkOfflineStorage.designTemplateKey);
			// 						getDesignerTemplate({ mediaId: templateId }, cb);
			// 					},
			// 				};
			// 				openGlobalDialog(confirmDialog);
			// 			} else {
			// 				// no draft template available or the draft doesn't have unsaved data. Continue with the queried artwork (mediaId)
			// 				// query.get('mediaId');
			// 				getDesignerTemplate({ mediaId: templateId }, cb);
			// 			}
			// 		})
			// 		.catch((err) => {
			// 			// can't get draft template. Continue with the queried artwork (mediaId). Very very rare to happen
			// 			// query.get('mediaId');
			// 			getDesignerTemplate({ mediaId: templateId }, cb);
			// 			console.debug(err);
			// 		});
			// }
		};

		// // Don't need to keep "devInitData"
		// // load extra data in dev env to make init loading quicker
		// const devInitData = () => {
		// 	// fetchArtworkFonts(); // fetch artwork font list
		// 	fetchLightboxes({ queryParams: { accessibleScope: 'global' } });
		// 	fetchArtworkSpreadsheets();
		// 	fetchArtworkLists();
		// 	fetchArtworkAutoImport();
		// 	fetchArtworkOutputTemplates();
		// 	fetchArtworkFonts({}, ({ error }) => {
		// 		if (error)
		// 			setInitStatus({
		// 				status: 'FAILED',
		// 				message: 'Can not load fonts. Please try again later...',
		// 			});
		// 		else
		// 			fetchArtworkCategories({}, ({ error }) => {
		// 				if (error)
		// 					setInitStatus({
		// 						status: 'FAILED',
		// 						message: 'Can not load category data. Please try again later...',
		// 					});
		// 				else
		// 					getArtwork(({ getState, error }) => {
		// 						if (error)
		// 							setInitStatus({
		// 								status: 'FAILED',
		// 								message: 'Can not load the design template. Please try again later...',
		// 							});
		// 						else {
		// 							let state = getState();
		// 							initFontface(
		// 								state.artworkDesignerTemplate.present.fields,
		// 								state.artworkExtra.fonts
		// 							);
		// 						}
		// 					});
		// 			});
		// 	});
		// 	// turnOffSidebar();
		// };

		// progressively load extra data
		const initData = async () => {
			try {
				// fetch artwork font list
				setInitStatus({ status: 'OK', message: 'Loading fonts...' });
				await new Promise((res, rej) =>
					fetchArtworkFonts({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load fonts. Please try again later...'),
							});
						else res();
					})
				);
				// fetch lightbox list
				setInitStatus({ status: 'OK', message: 'Loading lightboxes data...' });
				await new Promise((res, rej) =>
					fetchLightboxes({ queryParams: { accessibleScope: 'global' } }, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load lightboxes data. Please try again later...'),
							});
						else res();
					})
				);
				// fetch spreadsheets
				setInitStatus({ status: 'OK', message: 'Loading spreadsheet data...' });
				await new Promise((res, rej) =>
					fetchArtworkSpreadsheets({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load spreadsheet data. Please try again later...'),
							});
						else res();
					})
				);
				setInitStatus({ status: 'OK', message: 'Loading list data...' });
				await new Promise((res, rej) =>
					fetchArtworkLists({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load list data. Please try again later...'),
							});
						else res();
					})
				);
				setInitStatus({ status: 'OK', message: 'Loading user data...' });
				await new Promise((res, rej) =>
					fetchArtworkAutoImport({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load user data. Please try again later...'),
							});
						else res();
					})
				);
				setInitStatus({ status: 'OK', message: 'Loading output template...' });
				await new Promise((res, rej) =>
					fetchArtworkOutputTemplates({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load output template. Please try again later...'),
							});
						else res();
					})
				);
				setInitStatus({ status: 'OK', message: 'Loading categories...' });
				await new Promise((res, rej) =>
					fetchArtworkCategories({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load category data. Please try again later...'),
							});
						else res();
					})
				);
				setInitStatus({ status: 'OK', message: 'Loading template data...' });
				await new Promise((res, rej) =>
					getArtwork(({ getState, error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load the design template. Please try again later...'),
							});
						else {
							let state = getState();
							initFontface(state.artworkDesignerTemplate.present.fields, state.artworkExtra.fonts);
							res();
						}
					})
				);
			} catch (err) {
				setInitStatus({
					status: 'FAILED',
					message: err.error.message,
				});
			}

			// fetchArtworkFonts();
			// fetchArtworkSpreadsheets();
			// fetchArtworkLists();
			// fetchArtworkAutoImport();
			// fetchArtworkOutputTemplates();
			// fetchArtworkCategories({}, getArtwork);
		};
		turnOffSidebar();
		// reset spreadsheet content in case there was uncleaned data
		resetSpreadsheetContentById();

		initData();

		return () => {
			// componentWillUnmount event
			resetDesignerTemplate();
			resetArtworkFonts();
			resetLightboxes();
			resetArtworkSpreadsheets();
			resetArtworkLists();
			resetArtworkAutoImport();
			resetArtworkOutputTemplates();
			resetArtworkCategories();
			turnOnSidebar();
			resetSpreadsheetContentById();
			resetGlobalDialog();
			if (userInputDataRef.current) cleanCroppedImgUrls(userInputDataRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateWorkspaceRect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workspaceContainerRef]);

	// Effect by calculated workspace position
	useEffect(() => {
		// update perfect scrollbar
		if (psWrapper.ps) psWrapper.ps.update();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [WSRect]);

	useEffect(() => {
		if (
			cropImage.fieldId &&
			(artworkDesignerTemplate.selectedFieldIds.length > 1 ||
				(artworkDesignerTemplate.selectedFieldIds.length === 1 &&
					cropImage.fieldId !== artworkDesignerTemplate.selectedFieldIds[0]))
		) {
			notifyGeneral(
				intl.formatMessage({
					id: 'pages.Artwork.SHAREABLE.finishCroppingImageWarning',
				}),
				'error'
			);
		}
	}, [artworkDesignerTemplate.selectedFieldIds, cropImage.fieldId, intl, notifyGeneral]);

	// update userInputDataRef on fieldInputData change
	React.useEffect(() => {
		userInputDataRef.current = fieldInputData;
	}, [fieldInputData]);
	// ##############################
	// API Requests
	// #############################
	/**
	 * get a list of template for user to import
	 */
	const getSimilarTemplates = () => {
		artworkFetchSimilarTemplates({ templateId: artworkDesignerTemplate.mediaId })
			.then((result) => {
				setSimilarTemplates({ status: 'OK', templateList: result.data.results });
			})
			.catch((err) => {
				notifyGeneral(
					`Failed to fetch template list. ${
						err.response ? err.response.data.message : err.message
					}`,
					'error'
				);
				setSimilarTemplates({ ...similarTemplates, status: 'FAILED' });
			});
		setSimilarTemplates({ ...similarTemplates, status: 'PROCESSING' });
	};

	/**
	 * import artwork template by template id
	 * @param {string} templateId
	 */
	const importArtworkTemplateById = (templateId) => {
		artworkFetchTemplateById({ templateId })
			.then(async (result) => {
				const formatedTemplate = await convertRemoteFieldsToLocal(result.data, artworkExtra);
				// update field with new fieldId and the associations between fields
				let fieldIdMap = formatedTemplate.fields.reduce((accu, f) => {
					// field old ID and new ID map (object)
					accu[f.id] = genUUID();
					return accu;
				}, {});
				let updatedFields = formatedTemplate.fields.map((f) => {
					let clonedField = cloneField(f, fieldIdMap);
					return clonedField;
				});
				importArtworkTemplate({
					importTemplate: {
						groups: formatedTemplate.groups,
						lastUsedGroupName: formatedTemplate.lastUsedGroupName,
						fields: updatedFields,
						templateDuration: formatedTemplate.templateDuration,
					},
				});
				initFontface(updatedFields, artworkExtra.fonts);
			})
			.catch((err) => {
				// error while fetching template
				notifyGeneral(
					`Failed to import template. ${err.response ? err.response.data.message : err.message}`,
					'error'
				);
			});
	};
	// handler of Goback ( go to template detail page)
	const handleGoback = () => {
		// if (location.state && location.state.fromPath) {
		// 	history.push(location.state.fromPath);
		// } else {
		// 	history.go(-1);
		// }
		window.location.assign(getRouteMediafileDetail(artworkDesignerTemplate.mediaId));
	};

	// handle test template
	const handleTestTemplate = () => {
		window.location.assign(getRouteArtworkCreator(artworkDesignerTemplate.mediaId));
	};

	// handle esign template
	const handleEsignTemplate = () => {
		let productPickerField = artworkDesignerTemplate.fields.filter(
			(f) =>
				f.predefinedValue &&
				f.predefinedValue.type === 'spreadsheet' &&
				Boolean(f.predefinedValue.from) &&
				Boolean(f.predefinedValue.fromColumn)
		)[0];
		if (productPickerField)
			window.location.assign(
				getRouteArtworkEsign(
					artworkDesignerTemplate.mediaId,
					productPickerField.predefinedValue.from,
					productPickerField.predefinedValue.fromColumn
				)
			);
	};

	const getEffectFieldsWarnComp = (effectFields) => (
		<div>
			{effectFields.map((affected) => {
				if (affected.fields.length === 0) return null;
				let warnMsg = '';
				switch (affected.type) {
					case 'AUTO_IMPORT':
						warnMsg = intl.formatMessage({
							id: 'pages.Artwork.components.Design.delFieldsAutoImportWarnMsg',
						});
						break;
					case 'OUTPUT_DEPEND_ON':
						warnMsg = intl.formatMessage({
							id: 'pages.Artwork.components.Design.delFieldsOutputDependsOnWarnMsg',
						});
						break;
					case 'CALC_VALUE':
						warnMsg = intl.formatMessage({
							id: 'pages.Artwork.components.Design.delFieldsAffectCalcValueWarnMsg',
						});
						break;
					case 'BARCODE_APPEND':
						warnMsg = intl.formatMessage({
							id: 'pages.Artwork.components.Design.delFieldsAffectBarcodeAppendWarnMsg',
						});
						break;
					case 'CONCAT_FIELD':
						warnMsg = intl.formatMessage({
							id: 'pages.Artwork.components.Design.delFieldsAffectConcatFieldWarnMsg',
						});
						break;
					case 'PRE_SEARCH_FIELD':
						warnMsg = intl.formatMessage({
							id: 'pages.Artwork.components.Design.delFieldsAffectPreSearchFieldWarnMsg',
						});
						break;
					default:
						break;
				}
				return (
					<Typography
						key={`delFieldsWarn-${affected.type}`}
						variant="body2"
						component={'div'}
						className={classes.warnText}
						// style={{ color: '#ff9800', /* fontStyle: 'italic', */ fontSize: '0.8rem' }}
					>
						{warnMsg}
						<ul>
							{affected.fields.map((f) => (
								<li key={f.id}>{`${f.name} (${f.groupName})`}</li>
							))}
						</ul>
					</Typography>
				);
			})}
		</div>
	);
	// ##############################
	// template data handler
	// #############################
	// handler of Group
	let handleNewGroup = (newGroupName) => {
		updateLocalDesignerTemplate(
			immutableUpdate(artworkDesignerTemplate, {
				groups: {
					$push: [{ name: newGroupName }],
				},
			})
		);
	};
	// let handleDelGroup = delGroupName => {
	// 	if (
	// 		// artworkDesignerTemplate.pages[currentPageIdx].fields.filter(
	// 		artworkDesignerTemplate.fields.filter(field => field.groupName === delGroupName).length > 0
	// 	) {
	// 		return notifyGeneral('The group is not empty', 'error');
	// 	}
	// 	let newTemplate = { ...artworkDesignerTemplate };
	// 	// newTemplate.pages[currentPageIdx].groups = newTemplate.pages[currentPageIdx].groups.filter(
	// 	newTemplate.groups = newTemplate.groups.filter(item => item.name !== delGroupName);
	// 	updateLocalDesignerTemplate(newTemplate);
	// };
	const handleDelGroupWithFields = (groupName) => {
		let fieldIdsToBeDeleted = artworkDesignerTemplate.fields
			.filter((f) => f.groupName === groupName)
			.map((f) => f.id);
		let effectFields = findEffectFields(fieldIdsToBeDeleted, artworkDesignerTemplate.fields);
		let confirmDialog = {
			size: 'sm',
			title: intl.formatMessage({
				id: 'pages.Artwork.components.Design.delGroupWithFieldsTitle',
			}),
			content: (
				<div>
					<Typography variant="body1" gutterBottom>
						{intl.formatMessage(
							{
								id: 'pages.Artwork.components.Design.delGroupWithFieldsContentMsg',
							},
							{ groupName }
						)}
					</Typography>
					{getEffectFieldsWarnComp(effectFields)}
				</div>
			),
			confirmCB: () => {
				let groupsAfterDelete = artworkDesignerTemplate.groups.filter((g) => g.name !== groupName);
				if (groupsAfterDelete.length === 0) groupsAfterDelete = [{ name: 'Group 1' }]; // make a default group 'Group 1' if no group at all
				let newLastUsedGroupName =
					groupName === artworkDesignerTemplate.lastUsedGroupName
						? groupsAfterDelete[groupsAfterDelete.length - 1].name
						: artworkDesignerTemplate.lastUsedGroupName;
				updateLocalDesignerTemplate(
					immutableUpdate(artworkDesignerTemplate, {
						fields: {
							$set: artworkDesignerTemplate.fields
								.map((f) => {
									if (fieldIdsToBeDeleted.includes(f.id)) return f;

									effectFields
										.map((item) => ({ ...item, fieldIds: item.fields.map((f) => f.id) }))
										.forEach((affected) => {
											switch (affected.type) {
												case 'AUTO_IMPORT':
													if (affected.fieldIds.includes(f.id)) {
														// reset the "autoImport" in the field
														f.autoImport = '';
														f.autoImportMeta = f.defaultAutoImportMeta;
													}
													break;
												case 'OUTPUT_DEPEND_ON':
													if (affected.fieldIds.includes(f.id)) {
														// reset the "output dependson" in the field
														f.outputDependsOn = '';
													}
													break;
												case 'CALC_VALUE':
													if (f.type === 'text' && affected.fieldIds.includes(f.id)) {
														// reset the "calcValue" in the text field
														f.calcValue = ART_VARIABLES.DEFAULT_CALC_VALUE;
													}
													break;
												case 'BARCODE_APPEND':
													if (f.type === 'barcode' && affected.fieldIds.includes(f.id)) {
														// reset the "append" in the barcode field
														f.append = '';
													}
													break;
												case 'CONCAT_FIELD':
													if (affected.fieldIds.includes(f.id)) {
														// reset the "default value" in the concatenation field
														f.defaultValue = '';
													}
													break;
												case 'PRE_SEARCH_FIELD':
													if (affected.fieldIds.includes(f.id)) {
														// reset the "preSearchFieldId" in the image field
														f.preSearchFieldId = '';
													}
													break;
												default:
													break;
											}
										});
									return f;
								})
								.filter((f) => f.groupName !== groupName),
						},
						groups: {
							$set: groupsAfterDelete,
						},
						lastUsedGroupName: { $set: newLastUsedGroupName },
					}),
					() => {
						// setRenderElements({ hoverElement: null /* selectedElements: [] */ });
						setArtDesignState({ selectedFieldIds: [] });
					}
				);
			},
		};
		openGlobalDialog(confirmDialog);
	};

	const handleRenameGroup = (oldGroupName, newGroupName) => {
		if (artworkDesignerTemplate.groups.filter((g) => g.name === newGroupName).length > 0) {
			return notifyGeneral('The group name is already used.', 'error');
		}

		updateLocalDesignerTemplate(
			immutableUpdate(artworkDesignerTemplate, {
				fields: {
					$set: artworkDesignerTemplate.fields.map((f) => {
						if (f.groupName === oldGroupName) {
							f.groupName = newGroupName;
						}
						return f;
					}),
				},
				groups: {
					$set: artworkDesignerTemplate.groups.map((g) => {
						if (g.name === oldGroupName) {
							g.name = newGroupName;
						}
						return g;
					}),
				},
				lastUsedGroupName: {
					$set:
						artworkDesignerTemplate.lastUsedGroupName === oldGroupName
							? newGroupName
							: artworkDesignerTemplate.lastUsedGroupName,
				},
			})
		);
	};

	const handleDuplicateGroupWithFields = (groupName) => {
		let duplicateGroupName = `${groupName}-copy-${genRandomStr(4)}`;
		let fieldsInGroup = artworkDesignerTemplate.fields.filter((f) => f.groupName === groupName);
		let duplicateFieldIds = [];
		fieldsInGroup = deepClone(fieldsInGroup);
		// generate new field IDs
		let fieldNewIdMap = fieldsInGroup.reduce((accu, f) => {
			// field old ID and new ID map (object)
			accu[f.id] = genUUID();
			return accu;
		}, {});
		let duplicateFields = fieldsInGroup.map((f) => {
			// clone the field with new field id and update its associated fields
			let clonedField = cloneField(f, fieldNewIdMap);
			clonedField.groupName = duplicateGroupName;
			// shift duplicated field a little bit so that user can see them
			clonedField.position = {
				...clonedField.position,
				left: roundDecimals(clonedField.position.left + Math.max(10, 10 / WSRect.zoom), 2),
				top: roundDecimals(clonedField.position.top + Math.max(10, 10 / WSRect.zoom), 2),
			};
			// insert the new field id
			duplicateFieldIds.push(clonedField.id);
			return clonedField;

			// f.groupName = duplicateGroupName;
			// // f.name = `${f.name} - Copy`;
			// f.id = fieldNewIdMap[f.id]; // replace old ID with the new ID
			// f.position = {
			// 	left: roundDecimals(f.position.left + Math.max(10, 10 / WSRect.zoom), 2),
			// 	top: roundDecimals(f.position.top + Math.max(10, 10 / WSRect.zoom), 2),
			// 	width: f.position.width,
			// 	height: f.position.height,
			// 	angle: f.position.angle,
			// };
			// // update auto import basedOn with the new field ID
			// // ONLY when the basedOn field is in this duplicate group, if basedOn field is in different group, its field ID is not changed, no need to update
			// // apply to text, image, barcode field types. These fields have same autoImportMeta data structure, hence following code works for these three field types
			// // (don't need to consider if they are in the same group or not, because the field ID is unique cross all groups)
			// if (f.autoImportMeta && f.autoImportMeta.basedOn && fieldNewIdMap[f.autoImportMeta.basedOn]) {
			// 	f.autoImportMeta.basedOn = fieldNewIdMap[f.autoImportMeta.basedOn];
			// }

			// // update outputDependsOn with the new field ID,
			// // ONLY when the dependOn field is in this duplicate group, if dependOn field is in different group, its field ID is not changed, no need to update
			// if (f.outputDependsOn && fieldNewIdMap[f.outputDependsOn]) {
			// 	f.outputDependsOn = fieldNewIdMap[f.outputDependsOn];
			// }

			// // update field IDs used for calculating value (text field only)
			// // ONLY when the field is in this duplicate group, if the field is in different group, the field ID is not changed, no need to update
			// if (f.type === 'text') {
			// 	if (f.calcValue.price && fieldNewIdMap[f.calcValue.price]) {
			// 		f.calcValue.price = fieldNewIdMap[f.calcValue.price];
			// 	}
			// 	if (f.calcValue.unit && fieldNewIdMap[f.calcValue.unit]) {
			// 		f.calcValue.unit = fieldNewIdMap[f.calcValue.unit];
			// 	}
			// 	if (f.calcValue.qty && fieldNewIdMap[f.calcValue.qty]) {
			// 		f.calcValue.qty = fieldNewIdMap[f.calcValue.qty];
			// 	}
			// }

			// // update append (field ID) in barcode field
			// // ONLY when the append field is in this duplicate group, if append field is in different group, its field ID is not changed, no need to update
			// if (f.type === 'barcode' && f.append && fieldNewIdMap[f.append]) {
			// 	f.append = fieldNewIdMap[f.append];
			// }

			// // update the embedded fields in concatenation field
			// // ONLY when the embedded field is in this duplicate group, if embedded field is in different group, its field ID is not changed, no need to update
			// let regexToFindConcatField = /<field:\s*(.*?)\s*>/gm;
			// if (f.type === 'text' && regexToFindConcatField.test(f.defaultValue)) {
			// 	let matches = f.defaultValue.match(regexToFindConcatField);
			// 	matches.forEach((match) => {
			// 		let embeddedFieldId = match.substring(match.lastIndexOf('_') + 1).slice(0, -1);
			// 		// match
			// 		// 	.split('_')
			// 		// 	.pop()
			// 		// 	.slice(0, -1);
			// 		if (fieldNewIdMap[embeddedFieldId]) {
			// 			let embeddedFieldNewStr = match.replace(
			// 				embeddedFieldId,
			// 				fieldNewIdMap[embeddedFieldId]
			// 			);
			// 			f.defaultValue = f.defaultValue.replaceAll(match, embeddedFieldNewStr);
			// 		} else {
			// 			// the embedded field may be in different group, the field id is not changed, so do nothing
			// 		}
			// 	});
			// }

			// // insert the new field id
			// duplicateFieldIds.push(f.id);
			// return f;
		});
		updateLocalDesignerTemplate(
			immutableUpdate(artworkDesignerTemplate, {
				fields: {
					$push: duplicateFields,
				},
				groups: {
					$push: [{ name: duplicateGroupName }],
				},
			}),
			() => {
				// setRenderElements({ hoverElement: null /* selectedElements: duplicateFieldIds */ });
				setArtDesignState({ selectedFieldIds: duplicateFieldIds });
			}
		);
	};

	/**
	 Handler of adding new field
	 format of field: {
		 type: 'text|image|video|pdf|barcode',
		 name: 'field name',
		 groupName: 'GROUPNAME',
		 helperText: 'helper text', 	// optional
		 position: {
			 left: NUMBER,
			 top: NUMBER,
			 width: NUMBER,
			 height: NUMBER,
		 }
	 }
	*/
	const handleNewField = (field) => {
		// let newTemplate = { ...artworkDesignerTemplate };
		// convert the field position to original position in 100% zoom
		field.position = {
			left: roundDecimals(field.position.left / WSRect.zoom, 2),
			top: roundDecimals(field.position.top / WSRect.zoom, 2),
			width: roundDecimals(field.position.width / WSRect.zoom, 2),
			height: roundDecimals(field.position.height / WSRect.zoom, 2),
			angle: 0,
		};
		// field.ref = React.createRef();	// create its DOM ref and use it when render
		// get new field z-index (order in its group)
		// field.zIndex = newTemplate.pages[currentPageIdx].fields.filter(
		// field.zIndex = newTemplate.fields.filter(f => f.groupName === field.groupName).length;
		field.id = genUUID(); // generating field id (time-based uuid). Only use it locally to uniquely identify a field.

		let updateData = {
			fields: {
				$push: [
					{
						...ART_VARIABLES.defaultFields[field.type],
						...field,
					},
				],
			},
		};
		if (!artworkDesignerTemplate.groups.find((g) => g.name === field.groupName)) {
			// the group name in the new field is not existing, create it
			updateData.groups = {
				$push: [{ name: field.groupName }],
			};
		}

		let newTemplate = immutableUpdate(artworkDesignerTemplate, updateData);
		newTemplate.lastUsedGroupName = field.groupName;
		// // newTemplate.pages[currentPageIdx].fields.push({
		// newTemplate.fields = immutableUpdate(newTemplate.fields, {
		// 	$push: [
		// 		{
		// 			...ART_VARIABLES.defaultFields[field.type],
		// 			...field,
		// 		},
		// 	],
		// });
		// // newTemplate.fields.push({
		// // 	...ART_VARIABLES.defaultFields[field.type],
		// // 	...field,
		// // });
		updateLocalDesignerTemplate(newTemplate, () => {
			// setRenderElements({ hoverElement: null /* selectedElements: [field.id] */ });
			setArtDesignState({ selectedFieldIds: [field.id] });
		});
	};

	const handleReorderFields = (fields) => {
		updateLocalDesignerTemplate({ fields });
	};
	const handleReorderGroups = (groups) => {
		updateLocalDesignerTemplate({ groups });
	};

	const handleDuplicateFields = (fields) => {
		// let confirmDialog = {
		// 	size: 'xs',
		// 	title: 'Duplicate Fields',
		// 	content: (
		// 		<Typography variant="body1" gutterBottom>
		// 			Do you want to duplicate the selected fields?
		// 		</Typography>
		// 	),
		// 	confirmCB: () => {
		let newTemplate = { ...artworkDesignerTemplate };
		// let newFields = newTemplate.pages[currentPageIdx].fields.map(f => ({ ...f }));
		let newFields = newTemplate.fields.map((f) => ({ ...f }));
		let newFieldIds = [];
		for (let i = 0; i < fields.length; i++) {
			// convert the field position to original position in 100% zoom
			let field = { ...fields[i] };
			field.position = {
				left: roundDecimals(field.position.left + Math.max(10, 10 / WSRect.zoom), 2),
				top: roundDecimals(field.position.top + Math.max(10, 10 / WSRect.zoom), 2),
				width: field.position.width,
				height: field.position.height,
				angle: field.position.angle,
			};
			// get new field z-index (order in its group)
			// field.zIndex = newFields.filter(f => f.groupName === field.groupName).length;
			field.id = genUUID(); // generating field id (time-based uuid). Only use it locally to uniquely identify a field.
			field.name = field.name + ' - Copy'; // `Untitled-${genRandomStr(6)}`; // generate random name
			newFields.push(field);
			newFieldIds.push(field.id);
		}

		// newTemplate.pages[currentPageIdx].fields = newFields; //.push(field);
		newTemplate.fields = newFields; //.push(field);
		updateLocalDesignerTemplate(newTemplate, () => {
			// setRenderElements({ hoverElement: null /* selectedElements: newFieldIds */ });
			setArtDesignState({ selectedFieldIds: newFieldIds });
		});
		// 	},
		// };
		// openGlobalDialog(confirmDialog);
	};

	const handleDelFields = (fields) => {
		let fieldIdsToBeDeleted = fields.map((field) => field.id);
		let effectFields = findEffectFields(fieldIdsToBeDeleted, artworkDesignerTemplate.fields);
		let confirmDialog = {
			size: 'sm',
			title: intl.formatMessage({
				id: 'pages.Artwork.components.Design.delFieldsTitle',
			}),
			content: (
				<div>
					<Typography variant="body1" gutterBottom>
						{intl.formatMessage(
							{
								id: 'pages.Artwork.components.Design.delFieldsContentMsg',
							},
							{ fieldsCount: fields.length }
						)}
					</Typography>
					{getEffectFieldsWarnComp(effectFields)}
				</div>
			),
			confirmCB: () => {
				// let newTemplate = { ...artworkDesignerTemplate };
				// // let templateFields = newTemplate.pages[currentPageIdx].fields.map(f => ({ ...f })); //.concat([]);
				// let templateFields = newTemplate.fields.map(f => ({ ...f })); //.concat([]);

				// let newTemplateFields = templateFields.filter(f => !fieldIdsToBeDeleted.includes(f.id));
				// create reference array of "fields", so that any change in "templateFields" will effect to "fieldsToBeDeleted"
				// let fieldsToBeDeleted = templateFields.filter(f => fieldIdsToBeDeleted.includes(f.id));
				// for (let i = 0; i < fieldsToBeDeleted.length; i++) {
				// 	// re-order the template fields (zIndex)
				// 	templateFields = templateFields
				// 		.map(f => {
				// 			if (f.id !== fieldsToBeDeleted[i].id) {
				// 				if (
				// 					f.groupName === fieldsToBeDeleted[i].groupName &&
				// 					f.zIndex > fieldsToBeDeleted[i].zIndex
				// 				) {
				// 					f.zIndex -= 1; // NOTE: the re-ordering works because the change in this line also applies to "fieldsToBeDeleted"
				// 				}
				// 				return f;
				// 			} else {
				// 				return null;
				// 			}
				// 		})
				// 		.filter(f => Boolean(f));
				// }
				// newTemplate.pages[currentPageIdx].fields = templateFields;
				// newTemplate.fields = newTemplateFields; // templateFields;

				// ...templateFields
				// 								.filter(
				// 									f =>
				// 										f.id !== field.id &&
				// 										f.autoImportMeta &&
				// 										f.autoImportMeta.basedOn === field.id
				// 								)
				// 								.map(f => ({ ...f, autoImportMeta: f.defaultAutoImportMeta })),

				updateLocalDesignerTemplate(
					immutableUpdate(artworkDesignerTemplate, {
						fields: {
							$set: artworkDesignerTemplate.fields
								.map((f) => {
									if (fieldIdsToBeDeleted.includes(f.id)) return f;

									effectFields
										.map((item) => ({ ...item, fieldIds: item.fields.map((f) => f.id) }))
										.forEach((affected) => {
											switch (affected.type) {
												case 'AUTO_IMPORT':
													if (affected.fieldIds.includes(f.id)) {
														// reset the "autoImport" in the field
														f.autoImport = '';
														f.autoImportMeta = f.defaultAutoImportMeta;
													}
													break;
												case 'OUTPUT_DEPEND_ON':
													if (affected.fieldIds.includes(f.id)) {
														// reset the "output dependson" in the field
														f.outputDependsOn = '';
													}
													break;
												case 'CALC_VALUE':
													if (f.type === 'text' && affected.fieldIds.includes(f.id)) {
														// reset the "calcValue" in the text field
														f.calcValue = ART_VARIABLES.DEFAULT_CALC_VALUE;
													}
													break;
												case 'BARCODE_APPEND':
													if (f.type === 'barcode' && affected.fieldIds.includes(f.id)) {
														// reset the "append" in the barcode field
														f.append = '';
													}
													break;
												case 'CONCAT_FIELD':
													if (affected.fieldIds.includes(f.id)) {
														// reset the "default value" in the concatenation field
														f.defaultValue = '';
													}
													break;
												case 'PRE_SEARCH_FIELD':
													if (affected.fieldIds.includes(f.id)) {
														// reset the "preSearchFieldId" in the image field
														f.preSearchFieldId = '';
													}
													break;
												default:
													break;
											}
										});
									return f;
								})
								.filter((f) => !fieldIdsToBeDeleted.includes(f.id)),
							// $apply: fields =>
							// 	fields.map(f => {
							// 		if (renderElements.selectedElements.includes(f.id)) {
							// 			f.position.left += offsetX;
							// 			f.position.top += offsetY;
							// 		}
							// 		return f;
							// 	}),
						},
					}),
					() => {
						// setRenderElements({ hoverElement: null /* selectedElements: [] */ });
						setArtDesignState({ selectedFieldIds: [] });
					}
				);
			},
		};
		openGlobalDialog(confirmDialog);
	};

	const bumpSelection = _.throttle(
		(e) => {
			if (artworkDesignerTemplate.selectedFieldIds.length === 0) return;
			const { keyCodes } = ART_VARIABLES;
			const { keyCode, shiftKey } = e;

			let offsetX = 0,
				offsetY = 0;

			let step = shiftKey
				? ART_VARIABLES.bumpSelectionShiftValue / WSRect.zoom
				: ART_VARIABLES.bumpSelectionValue / WSRect.zoom;

			switch (keyCode) {
				case keyCodes.upKey:
					offsetY = -step;
					break;
				case keyCodes.downKey:
					offsetY = step;
					break;
				case keyCodes.leftKey:
					offsetX = -step;
					break;
				case keyCodes.rightKey:
					offsetX = step;
					break;
				default:
					return;
			}
			updateLocalDesignerTemplate({
				fields: artworkDesignerTemplate.fields.map((f) => {
					if (artworkDesignerTemplate.selectedFieldIds.includes(f.id)) {
						return {
							...f,
							position: {
								...f.position,
								left: f.position.left + offsetX,
								top: f.position.top + offsetY,
							},
						};
					}
					return f;
				}),
			});
		},
		100,
		{ leading: false, trailing: true }
	);

	const displayOutputTemplate = (templateId) => {
		let tableTitle = null,
			columns = [],
			tableData = [];
		// if (!templateId) {
		// 	// ok, we display the template list
		// 	tableTitle = 'Output Templates';
		// 	columns = [
		// 		{ title: 'No.', render: (rowData) => rowData.tableData.id + 1 },
		// 		{
		// 			title: 'Title',
		// 			field: 'title',
		// 			render: function templateTitle(rowData) {
		// 				// use normal function to remove ESLint "mising display name" warning
		// 				return (
		// 					<Link
		// 						className={classes.outputTemplateLink}
		// 						component="button"
		// 						variant="body2"
		// 						onClick={() => {
		// 							displayOutputTemplate(rowData.id);
		// 						}}
		// 					>
		// 						{rowData.title}
		// 					</Link>
		// 				);
		// 			},
		// 		},
		// 		{ title: 'Width (mm)', field: 'page_width' },
		// 		{ title: 'Height (mm)', field: 'page_height' },
		// 	];
		// 	tableData = artworkExtra.outputTemplates.map((item) => ({ ...item }));
		// } else {
		// 	// ok, we display the detail of the template
		// 	let template = artworkExtra.outputTemplates.filter((item) => item.id === templateId)[0];
		// 	tableTitle = `Output Template - ${template.title}`;
		// 	tableData = Object.keys(template).map((key) => ({ name: key, value: template[key] }));
		// 	columns = [
		// 		{ title: 'Name', field: 'name' },
		// 		{ title: 'Value', field: 'value' },
		// 	];
		// }

		if (!templateId) {
			// ok, we display the template list
			tableTitle = 'Output Templates';
			columns = [
				{ headerName: 'No.', field: 'id', renderCell: (rowData) => rowData.row.id + 1 },
				{
					headerName: 'Title',
					field: 'title',
					renderCell: function templateTitle(rowData) {
						// use normal function to remove ESLint "mising display name" warning
						return (
							<Link
								className={classes.outputTemplateLink}
								component="button"
								variant="body2"
								onClick={() => {
									displayOutputTemplate(rowData.row.outputTemplateId);
								}}
							>
								{rowData.row.title}
							</Link>
						);
					},
				},
				{ headerName: 'Width (mm)', field: 'page_width' },
				{ headerName: 'Height (mm)', field: 'page_height' },
			].map((item) => ({
				...item,
				flex: 1,
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
			}));
			tableData = artworkExtra.outputTemplates.map((item, idx) => ({
				...item,
				outputTemplateId: item.id,
				id: idx,
			}));
		} else {
			// ok, we display the detail of the template
			let template = artworkExtra.outputTemplates.filter((item) => item.id === templateId)[0];
			tableTitle = `Output Template - ${template.title}`;
			tableData = Object.keys(template).map((key, idx) => ({
				id: `${idx}`,
				name: key,
				value: template[key],
			}));
			columns = [
				{
					field: 'name',
					headerName: 'Name',
					flex: 0.3,
					// minWidth: 130,
					sortable: false,
					filterable: false,
					disableColumnMenu: true,
				},
				{
					field: 'value',
					headerName: 'Value',
					flex: 1,
					sortable: false,
					filterable: false,
					disableColumnMenu: true,
				},
			];
		}

		let infoDialog = {
			size: 'md',
			title: tableTitle,
			content: (
				<div>
					{templateId && (
						<Link
							component="button"
							variant="body2"
							style={{ paddingBottom: 8 }}
							onClick={() => {
								displayOutputTemplate();
							}}
						>
							View All Template
						</Link>
					)}
					<DataGrid
						columns={columns}
						rows={tableData}
						classes={{
							columnHeader: classes.dataGridHeader,
							cellContent: classes.dataGridAutoWrapCell,
						}}
						autoHeight
						disableColumnFilter
						disableVirtualization
						disableColumnMenu
						disableColumnSelector
						disableRowSelectionOnClick
						hideFooter
						rowSelection={false}
						density="compact"
					/>
				</div>
			),
		};
		openGlobalDialog(infoDialog);
	};

	const handleDisplayShortcuts = () => {
		let shortcutsTableData = [
			{ id: 1, action: 'Undo', key: 'CTRL/CMD + Z' },
			{ id: 2, action: 'Redo', key: 'CTRL/CMD + SHIFT + Z | CTRL/CMD + Y' },
			{ id: 3, action: 'Delete Field', key: 'Delete | Backspace' },
			{ id: 4, action: 'Move Field 1px', key: 'Keyboard Arrows' },
			{ id: 5, action: 'Move Field 10px', key: 'SHIFT + Keyboard Arrows' },
			{ id: 6, action: 'Resize Keeping Same Aspect Ratio', key: 'SHIFT + Resizing' },
		];
		let shortcutsColumns = [
			{
				headerName: 'Action',
				field: 'action',
				flex: 0.8,
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
			},
			{
				headerName: 'Shortcut Key',
				field: 'key',
				flex: 1,
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
			},
		];
		let shortcutsDialog = {
			size: 'sm',
			title: 'Shortcut Keys',
			content: (
				<div style={{ width: '100%', height: '100%' }}>
					<DataGrid
						columns={shortcutsColumns}
						rows={shortcutsTableData}
						classes={{
							columnHeader: classes.dataGridHeader,
							cellContent: classes.dataGridAutoWrapCell,
						}}
						autoHeight
						disableColumnFilter
						disableVirtualization
						disableColumnMenu
						disableColumnSelector
						disableRowSelectionOnClick
						hideFooter
						rowSelection={false}
						density="compact"
					/>
				</div>
			),
		};
		openGlobalDialog(shortcutsDialog);
	};

	// ##############################
	// event handler
	// #############################
	// handler of elements (fields)
	// const handleEleMouseOver = element => {
	// 	if (!renderElements.selectedElements.includes(element.field.id)) {
	// 		setRenderElements({ ...renderElements, hoverElement: element.field.id });
	// 	}
	// };
	// const handleEleMouseOut = () => {
	// 	setRenderElements({ ...renderElements, hoverElement: null });
	// };
	const handleGroupSelect = (groupName) => {
		// setRenderElements({
		// 	// selectedElements: artworkDesignerTemplate.fields
		// 	// 	.filter(f => f.groupName === groupName)
		// 	// 	.map(f => f.id),
		// 	hoverElement: null,
		// });
		setArtDesignState({
			selectedFieldIds: artworkDesignerTemplate.fields
				.filter((f) => f.groupName === groupName)
				.map((f) => f.id),
		});
	};
	/**	handle element selection
	 * element: {
			field,
		},
		append: indicate multiple selection. True - append it to selections, false - use it as selection
	*/
	const handleEleSelect = (element, append) => {
		if (!element) {
			setContextualPaletteOpen(false);
			// setRenderElements({
			// 	// selectedElements: [],
			// 	hoverElement: null,
			// });
			setArtDesignState({
				selectedFieldIds: [],
			});
			return;
		}
		setContextualPaletteOpen(true);
		if (!append) {
			// setRenderElements({ /* selectedElements: [element.field.id], */ hoverElement: null });
			setArtDesignState({
				selectedFieldIds: [element.field.id],
			});
			return;
		}
		// multiple selection
		let selectedEles = [...artworkDesignerTemplate.selectedFieldIds];
		if (!selectedEles.includes(element.field.id)) {
			// the element is not selected, so append it
			selectedEles.push(element.field.id);
		} else {
			// the element is already selected, so remove it
			selectedEles = selectedEles.filter((fieldId) => fieldId !== element.field.id);
		}
		// setRenderElements({
		// 	// selectedElements: selectedEles,
		// 	hoverElement: null,
		// });
		setArtDesignState({
			selectedFieldIds: selectedEles,
		});
	};

	// handler of element (field) selection clickaway
	const handleEleSelectClickAway = (e) => {
		if (
			e.target === workspaceContainerRef.current ||
			workspaceContainerRef.current.contains(e.target)
		) {
			// setRenderElements({ hoverElement: null /* , selectedElements: [] */ });
			setArtDesignState({
				selectedFieldIds: [],
			});
		}
	};

	/**
	 * handle element (field) rotated
	 * @param {array of field ID string} fieldIds. ID of fields that were rotated (selected field IDs)
	 * @param {Number} deltaAngle. The angle diff to oroginal angle under the current zoom
	 */
	const handleEleRotateStop = (fieldIds, deltaAngle) => {
		let fields = artworkDesignerTemplate.fields.map((f) =>
			fieldIds.includes(f.id)
				? {
						...f,
						position: {
							...f.position,
							angle: (f.position.angle + deltaAngle + 360) % 360,
						},
				  }
				: f
		);
		updateLocalDesignerTemplate({ fields });
	};

	/**
	 * handle element (field) resized
	 * @param {array of field object} resizedFields. The full dataset of the updated resized fields that were resized (selected fields)
	 */
	const handleEleResizeStop = (resizedFields = []) => {
		let fields = artworkDesignerTemplate.fields.map(
			(field) => _.find(resizedFields, (f) => f.id === field.id) || field
		);
		updateLocalDesignerTemplate({ fields });
	};

	/**
	 * handle element (field) dragged
	 * @param {array of field ID string} fieldIds. ID of fields that were dragged
	 * @param {Number in pixel} deltaX. X coordination diff to oroginal position in the current zoom
	 * @param {Number in pixel} deltaY. Y coordination diff to oroginal position in the current zoom
	 */
	const handleEleDragStop = (fieldIds, deltaX, deltaY) => {
		let fields = artworkDesignerTemplate.fields.map((f) =>
			fieldIds.includes(f.id)
				? {
						...f,
						position: {
							...f.position,
							left: f.position.left + deltaX / WSRect.zoom,
							top: f.position.top + deltaY / WSRect.zoom,
						},
				  }
				: f
		);

		updateLocalDesignerTemplate({ fields });
	};

	const handleTemplateInfoUpdate = (updatedInfo) => {
		updateLocalDesignerTemplate(updatedInfo);
	};

	// update field properties by configuring field palette
	// const handleFieldPaletteUpdate = newField => {
	// 	let templateFields = artworkDesignerTemplate.fields.concat([]);
	// 	let originalField = _.find(templateFields, { id: newField.id });
	// 	// set the order (to the end) of the field in its new group
	// 	if (originalField.groupName !== newField.groupName)
	// 		newField.zIndex = templateFields.filter(f => f.groupName === newField.groupName).length;

	// 	templateFields = templateFields.map(f => {
	// 		if (newField.id === f.id) {
	// 			return newField;
	// 		} else if (
	// 			originalField.groupName !== newField.groupName && // the field has been removed from the current group
	// 			originalField.groupName === f.groupName && // current iterated field is in the current group
	// 			f.zIndex > originalField.zIndex // current iterated field zIndex (order) is higher than the removed field
	// 		) {
	// 			// reset the order of the fields in the current group
	// 			f.zIndex = f.zIndex - 1;
	// 			return f;
	// 		} else {
	// 			return f;
	// 		}
	// 	});

	// 	let newTemplate = { ...artworkDesignerTemplate };
	// 	newTemplate.pages[currentPageIdx].fields = templateFields;
	// 	updateLocalDesignerTemplate(newTemplate);
	// };

	// update field properties by configuring field palette
	const handleFieldsUpdate = (updatedFields) => {
		// updatedFields = [...updatedFields];
		// let templateFields = [...artworkDesignerTemplate.fields]; //.concat([]);
		let updatedFieldIds = updatedFields.map((f) => f.id);
		let updatedFieldsObject = updatedFields.reduce(
			(accu, elem) => ({ ...accu, [elem.id]: elem }),
			{}
		);
		// let groupChangedFields = [];
		// let updatedTemplateFields = immutableUpdate(artworkDesignerTemplate.fields, {
		// 	$apply: fields =>
		// 		fields.map(f => (updatedFieldIds.includes(f.id) ? updatedFieldsObject[f.id] : f)),
		// });
		updateLocalDesignerTemplate({
			fields: immutableUpdate(artworkDesignerTemplate.fields, {
				// fields: {
				$apply: (fields) =>
					fields.reduce(
						(accu, f, idx, array) => {
							if (updatedFieldIds.includes(f.id)) {
								if (f.groupName === updatedFieldsObject[f.id].groupName)
									accu.newFields.push(updatedFieldsObject[f.id]);
								else {
									accu.fieldsGroupChanged.push(updatedFieldsObject[f.id]);
								}
							} else {
								accu.newFields.push(f);
							}

							if (idx === array.length - 1) {
								// end of the loop
								accu.newFields = accu.newFields.concat(accu.fieldsGroupChanged);
							}

							return accu;
						},
						{ newFields: [], fieldsGroupChanged: [] }
					).newFields,
				// 	fields
				// 		.map(f => {
				// 			if (updatedFieldIds.includes(f.id)) {
				// 				if (f.groupName === updatedFieldsObject[f.id].groupName)
				// 					return updatedFieldsObject[f.id];
				// 				else {
				// 					groupChangedFields.push(updatedFieldsObject[f.id]);
				// 					return null;
				// 				}
				// 			} else {
				// 				return f;
				// 			}
				// 		})
				// 		.filter(f => f),
				// // .concat(groupChangedFields),
				// $push: groupChangedFields,
				// },
			}),
		});
		// let originalFields = templateFields
		// 	.filter(f => updatedFieldIds.includes(f.id))
		// 	.reduce((accu, elem) => ({ ...accu, [elem.id]: elem }), {});

		// templateFields = templateFields.map(f => {
		// 	if (updatedField.id === f.id) {
		// 		return updatedField;
		// 	} else if (
		// 		originalField.groupName !== updatedField.groupName && // the field has been removed from the current group
		// 		originalField.groupName === f.groupName && // current iterated field is in the current group
		// 		f.zIndex > originalField.zIndex // current iterated field zIndex (order) is higher than the removed field
		// 	) {
		// 		// reset the order of the fields in the current group
		// 		// NOTE: the zIndex in originalFields should also be reset (which is the key to make the re-ordering working),
		// 		//			 because the field in originalFields is ref (pointer in c language) to the field (f) in templateFields
		// 		f.zIndex = f.zIndex - 1;
		// 		return f;
		// 	} else {
		// 		return f;
		// 	}
		// });

		// let newTemplate = { ...artworkDesignerTemplate };
		// newTemplate.fields = updatedTemplateFields;
		// updateLocalDesignerTemplate(newTemplateData);
	};
	// const handleFieldsUpdate = updatedFields => {
	// 	// updatedFields = [...updatedFields];
	// 	let templateFields = [...artworkDesignerTemplate.fields]; //.concat([]);
	// 	let updatedFieldIds = updatedFields.map(f => f.id);
	// 	let originalFields = templateFields
	// 		.filter(f => updatedFieldIds.includes(f.id))
	// 		.reduce((accu, elem) => ({ ...accu, [elem.id]: elem }), {});
	// 	for (let i = 0; i < updatedFields.length; i++) {
	// 		let updatedField = updatedFields[i];
	// 		let originalField = originalFields[updatedField.id]; //_.find(originalFields, { id: updatedField.id }); //

	// 		// set the order (to the end) of the field in its new group
	// 		if (originalField.groupName !== updatedField.groupName) {
	// 			updatedField.zIndex = templateFields.filter(
	// 				f => f.groupName === updatedField.groupName
	// 			).length;
	// 		}

	// 		templateFields = templateFields.map(f => {
	// 			if (updatedField.id === f.id) {
	// 				return updatedField;
	// 			} else if (
	// 				originalField.groupName !== updatedField.groupName && // the field has been removed from the current group
	// 				originalField.groupName === f.groupName && // current iterated field is in the current group
	// 				f.zIndex > originalField.zIndex // current iterated field zIndex (order) is higher than the removed field
	// 			) {
	// 				// reset the order of the fields in the current group
	// 				// NOTE: the zIndex in originalFields should also be reset (which is the key to make the re-ordering working),
	// 				//			 because the field in originalFields is ref (pointer in c language) to the field (f) in templateFields
	// 				f.zIndex = f.zIndex - 1;
	// 				return f;
	// 			} else {
	// 				return f;
	// 			}
	// 		});
	// 	}

	// 	let newTemplate = { ...artworkDesignerTemplate };
	// 	// newTemplate.pages[currentPageIdx].fields = templateFields;
	// 	newTemplate.fields = templateFields;
	// 	updateLocalDesignerTemplate(newTemplate);
	// };

	// update workspace rect on resize/zoom
	const updateWorkspaceRect = (zoom = 0) => {
		if (!workspaceContainerRef.current) return null;
		// let designRectCalc, designBoxRectCalc;
		let WSRectCalc;
		let WSContainerRect = {
			width: workspaceContainerRef.current.clientWidth - 2 * ART_VARIABLES.WSBorderH,
			height: workspaceContainerRef.current.clientHeight - 2 * ART_VARIABLES.WSBorderV,
			x: ART_VARIABLES.WSBorderH,
			y: ART_VARIABLES.WSBorderV,
		};
		if (zoom === 0) {
			// fit the screen
			let templateRect = {
				width: artworkDesignerTemplate.dimension.width,
				height: artworkDesignerTemplate.dimension.height,
				x: 0,
				y: 0,
			};

			WSRectCalc = calcRectToFitScreen(templateRect, WSContainerRect);
			if (WSRectCalc.zoom > 1) {
				// if zoom is greater than 100, we use 100% to fit
				return updateWorkspaceRect(1);
			} else if (WSRectCalc.zoom < ART_VARIABLES.minZoom) {
				// if zoom is less than "minZoom", we use minZoom to fit
				return updateWorkspaceRect(ART_VARIABLES.minZoom);
			}
		} else {
			let WSPreviewRectCalc = {
				width: artworkDesignerTemplate.dimension.width * zoom,
				height: artworkDesignerTemplate.dimension.height * zoom,
			};
			fitPosition(WSPreviewRectCalc, WSContainerRect);

			let WSBorderRectCalc = {
				width: WSPreviewRectCalc.width + 2 * ART_VARIABLES.WSBorderH,
				height: WSPreviewRectCalc.height + 2 * ART_VARIABLES.WSBorderV,
				x: WSPreviewRectCalc.x - ART_VARIABLES.WSBorderH,
				y: WSPreviewRectCalc.y - ART_VARIABLES.WSBorderV,
			};
			WSRectCalc = { previewRect: WSPreviewRectCalc, borderRect: WSBorderRectCalc, zoom: zoom };
		}

		if (
			WSRectCalc.previewRect.height < psWrapper.elemRef.current.scrollTop ||
			WSRectCalc.previewRect.width < psWrapper.elemRef.current.scrollLeft
		) {
			psWrapper.elemRef.current.scrollTop = 0;
			psWrapper.elemRef.current.scrollLeft = 0;
		}
		setWSRect(WSRectCalc);
	};

	const handleZoomChange = (newZoom) => {
		updateWorkspaceRect(newZoom);
		setZoomControls({ isFit: newZoom === 0 });
	};

	const handleSaveDesignerTemplate = () => {
		saveDesignerTemplate(({ error }) => {
			if (error) {
				const msg = (
					<div>
						<Typography variant="body2">
							Failed to save the template: [
							{error.response
								? error.response.data.message || error.response.data.error
								: error.message}
							]
						</Typography>
						<Typography style={{ fontSize: 13, fontStyle: 'italic', paddingTop: 16 }}>
							The template is now saved as draft.
						</Typography>
					</div>
				);
				notifyGeneral(msg, 'error', { autoHideDuration: null });
			} else {
				notifyGeneral('Template was saved successfully', 'success');
			}
		});
	};

	/**
	 * handle product picker selection.
	 * Set autoImport field data by selected spreadsheet row content
	 * @param {string} productPickerField. product picker field
	 * @param {object} ssRowData. Spreadsheet selected row data
	 format (NB: columnId is "columnIndex" or  "$ref:{columnIndex}"):
	 	{
			[columnId]: {
				"value": "Cadbury Creme Egg",
				"mediafileId": "23554",
				"previewUrl": "https://xxxxx",
				"optimisedUrl": "https://xxxx",
				"highResUrl": "https://xxxx"
			},
			...
		}
		* @return {null}
	 */
	const handleProductPickerSelection = (productPickerField, ssRowData) => {
		let newFieldInputData = renewFieldInputDataBySelectedSpreadsheet(
			productPickerField,
			artworkDesignerTemplate.fields,
			fieldInputData,
			ssRowData
		);
		setFieldInputData(newFieldInputData);
	};

	// ##############################
	// variables
	// #############################
	// get selectedFields from template so that it has always the latest data. Sorted by groupName & zIndex (display order)
	// let selectedFields = _.sortBy(
	// 	artworkDesignerTemplate.fields.filter(field =>
	// 		renderElements.selectedElements.includes(field.id)
	// 	),
	// 	['groupName', 'zIndex']
	// );
	// let ordering = {};
	// let selectedFields = artworkDesignerTemplate.fields
	// 	.map(f => {
	// 		if (typeof ordering[f.groupName] !== 'number') ordering[f.groupName] = 0;
	// 		else ordering[f.groupName] += 1;
	// 		if (artworkDesignerTemplate.selectedFieldIds.includes(f.id))
	// 			return { ...f, displayOrder: ordering[f.groupName] };
	// 		else return null;
	// 	})
	// 	.filter(f => f); // renderElements.selectedElements.includes(f.id));
	let ordering = {};
	// let numOfFields = artworkDesignerTemplate.fields.length;
	let sortedFields = _.orderBy(
		artworkDesignerTemplate.fields
			.map((f) => {
				if (typeof ordering[f.groupName] !== 'number')
					ordering[f.groupName] =
						0 +
						artworkDesignerTemplate.groups.findIndex((g) => g.name === f.groupName) *
							artworkDesignerTemplate.fields.length;
				else ordering[f.groupName] += 1;
				return { ...f, displayOrder: ordering[f.groupName] };
			})
			.filter((f) => f),
		['displayOrder'],
		['asc']
	);
	// let cropImageField = cropImage.fieldId
	// 	? artworkDesignerTemplate.fields.filter(f => f.id === cropImage.fieldId)[0]
	// 	: null;

	// NB: invalidFields contains invalid messages of fields and template settings
	let invalidFields = [], // format [{id: FIELD_ID, name: FIELD_NAME, groupName: FIELD_GROUPNAME, messages: ARRAY_OF_INVALID_MESSAGE_STRING}]
		unsupportedFieldsVideoArt = [], // indicate if there is unsupported field types while template is video artwork
		selectedFields = [],
		cropImageField = null,
		displayOrderInGroup = {};
	artworkDesignerTemplate.fields.forEach((f) => {
		// update displayOrderInGroup
		if (typeof displayOrderInGroup[f.groupName] !== 'number') displayOrderInGroup[f.groupName] = 0;
		else displayOrderInGroup[f.groupName] += 1;

		// check if the field is "cropImage" field
		if (cropImage.fieldId && !cropImageField && f.id === cropImage.fieldId) {
			cropImageField = f;
		}

		// check if field is selected
		if (artworkDesignerTemplate.selectedFieldIds.includes(f.id)) {
			selectedFields.push({ ...f, displayOrder: displayOrderInGroup[f.groupName] });
		}

		// check if there is any invalid data in the field.
		let invalidMsgs = validateField(f, artworkDesignerTemplate);
		if (invalidMsgs.length > 0) {
			invalidFields.push({
				id: f.id,
				name: f.name,
				groupName: f.groupName,
				messages: invalidMsgs,
			});
		}

		if (
			artworkDesignerTemplate.videoArtwork &&
			!ART_VARIABLES.supportedFieldTypesInVideoArtwork.includes(f.type)
		) {
			unsupportedFieldsVideoArt.push(`${f.name} (${f.groupName})`);
		}
	});

	let invalidTemplateSettingMsgs = validateTemplateSettings(artworkDesignerTemplate);
	if (invalidTemplateSettingMsgs.length > 0) {
		// add template settings invalid msg to beginning of the array
		invalidFields.unshift({
			id: 'templateSettings',
			name: '',
			groupName: '',
			messages: invalidTemplateSettingMsgs,
		});
	}

	if (unsupportedFieldsVideoArt.length > 0) {
		invalidFields.unshift({
			id: 'unsupportedFieldsInVideoArtwork',
			name: '',
			groupName: '',
			messages: unsupportedFieldsVideoArt,
		});
	}
	// console.log('design re-rendered');
	// use the following if we don't show the displayOrder
	// let selectedFields = artworkDesignerTemplate.fields.filter(f =>
	// 	renderElements.selectedElements.includes(f.id)
	// );
	// let selectedFieldsBounds = getOuterBoundsSelection(selectedFields);
	// let selectedFieldsBounds = getOuterBoundsSelection(selectedFields, WSRect.zoom, WSPreviewRef.current ? WSPreviewRef.current.getBoundingClientRect() : null);

	// field output data (combination of user input data & field default data)
	const fieldOutputData = getFieldOutputData(artworkDesignerTemplate.fields, fieldInputData);

	// Performance Hint: Changes of the value in context will trigger re-render in child compoent which has useContext hook. Hence usually we will only place "READ-ONLY" value in context.
	// ref: https://reactjs.org/docs/hooks-reference.html#usecontext
	const contextValues = { notifyGeneral };
	return (
		<DesignContext.Provider value={contextValues}>
			<div id={workspaceWrapperId} className={classes.designWrapper}>
				{artworkDesignerTemplate.mediaUrl ? (
					<React.Fragment>
						{/* Side Panel */}
						<Sidemenu
							handleGoback={handleGoback}
							templateGroups={groupsInCurrentPage}
							designerTemplate={artworkDesignerTemplate}
							handleNewGroup={handleNewGroup}
							// handleDelGroup={handleDelGroup}
							fieldDropzoneRef={WSPreviewRef}
							handleNewField={handleNewField}
							handleEleSelect={handleEleSelect}
							handleGroupSelect={handleGroupSelect}
							selectedFieldIds={artworkDesignerTemplate.selectedFieldIds}
							handleReorderFields={handleReorderFields}
							handleReorderGroups={handleReorderGroups}
							handleDuplicateFields={handleDuplicateFields}
							handleDelFields={handleDelFields}
							handleDelGroupWithFields={handleDelGroupWithFields}
							handleRenameGroup={handleRenameGroup}
							handleDuplicateGroupWithFields={handleDuplicateGroupWithFields}
							// handleTemplateInfoUpdate={handleTemplateInfoUpdate}
							handleDisplayShortcuts={handleDisplayShortcuts}
							// import template
							templateList={similarTemplates.templateList}
							fetchTemplateListStatus={similarTemplates.status}
							getTemplateList={getSimilarTemplates}
							importTemplateById={importArtworkTemplateById}
							enableImportTemplate={artworkDesignerTemplate.fields.length === 0}
							{...rest}
						/>
						{/* keyboard listener */}
						<KeyboardListener
							onSelectionDelete={() => handleDelFields(selectedFields)}
							onSelectionBump={
								bumpSelection /* _.throttle(bumpSelection.bind(this), 200, { leading: true }) */
							}
							onUndo={() => undoDesignTemplate()}
							onRedo={() => redoDesignTemplate()}
						/>
						{/* Work Space */}

						<div className={classes.workspaceWrapper} ref={workspaceContainerRef}>
							<ReactResizeDetector
								handleWidth
								handleHeight
								onResize={() => zoomControls.isFit && updateWorkspaceRect()}
							/>
							{/* workspace section */}
							<PerfectScrollWrapper scrollX={true} setScrollRef={setPsWrapper}>
								<section className={classes.workspaceSection}>
									{/* design area border, to make some space (margin) around the work space */}
									<div
										className={classes.designBorder}
										style={{
											left: WSRect.borderRect.x,
											top: WSRect.borderRect.y,
											width: WSRect.borderRect.width,
											height: WSRect.borderRect.height,
										}}
									></div>
									{/** video background in videoArtwork */}
									{artworkDesignerTemplate.videoArtwork &&
										// UIControls.isPreviewEnabled &&
										UIControls.isBGImageEnabled &&
										templateBGOutputDataWithSettings.mediafileOptimisedUrl && (
											<div
												className={classes.videoBGWrapperForVideoArtwork}
												style={{
													left: WSRect.previewRect.x,
													top: WSRect.previewRect.y,
													width: WSRect.previewRect.width,
													height: WSRect.previewRect.height,
												}}
											>
												<Player
													ref={videoArtworkVideoRef}
													// autoPlay // don't auto play. It is also used as static background
													muted={true}
													onEnded={() => setPausingAnimation(true)}
													fluid={true}
													crossOrigin="anonymous"
													src={templateBGOutputDataWithSettings.mediafileOptimisedUrl}
												>
													<BigPlayButton disabled />
													<LoadingSpinner disabled />
													<Shortcut disabled />
													<ControlBar autoHide={false} className={classes.videoControlBar}>
														<VolumeMenuButton disabled />
													</ControlBar>
												</Player>
											</div>
										)}
									{/* Assit overlay of design area, e.g. resize, rotate, dragging, preview, etc. */}
									<div
										className={classes.designAssit}
										style={{
											left: WSRect.previewRect.x,
											top: WSRect.previewRect.y,
											width: WSRect.previewRect.width,
											height: WSRect.previewRect.height,
											backgroundSize: 'contain',
											backgroundRepeat: 'no-repeat',
											backgroundImage:
												!artworkDesignerTemplate.videoArtwork && UIControls.isBGImageEnabled // video artwork will never use image BG but video BG
													? UIControls.isPreviewEnabled
														? // NB: if SVG file is supported in toolkit, the "mediafilePreviewUrl" may not only be image, but could also be null or svg url, then the following code may not working unless the preview url is guaranteed to always be image
														  templateBGOutputDataWithSettings.mediafilePreviewUrl
															? `url(${templateBGOutputDataWithSettings.mediafilePreviewUrl})`
															: undefined
														: `url(${artworkDesignerTemplate.mediaUrl})` // use sample template preview to show how ths template looks like
													: undefined,
										}}
										ref={WSPreviewRef}
									>
										<DesignOverlay
											// bounds={selectedFieldsBounds}
											// selectedFields={selectedFields}
											selectedFieldIds={artworkDesignerTemplate.selectedFieldIds}
											fields={artworkDesignerTemplate.fields}
											zoom={WSRect.zoom}
											showDot
											showBorder
											solidLine
											draggable
											onDragStop={handleEleDragStop}
											rotatable={artworkDesignerTemplate.selectedFieldIds.length === 1}
											onRotateStop={handleEleRotateStop}
											resizable
											onResizeStop={handleEleResizeStop}
											showActions
											handleDuplicateFields={handleDuplicateFields}
											handleDelFields={handleDelFields}
											handleEleSelect={handleEleSelect}
											handleEleSelectClickAway={handleEleSelectClickAway}
											highlightField={UIControls.isFieldHighlightEnabled}
										/>
										{UIControls.isPreviewEnabled && (
											<SVGPreview
												// zoomedWidth={WSRect.previewRect.width}
												// zoomedHeight={WSRect.previewRect.height}
												// innerRef={svgRef}
												allowAnimation={allowAnimation}
												pausingAnimation={pausingAnimation}
												width={artworkDesignerTemplate.dimension.width}
												height={artworkDesignerTemplate.dimension.height}
												fields={sortedFields}
												// fieldsPreviewValue={fieldsPreviewValue}
												fieldOutputData={fieldOutputData}
												// zoom={WSRect.zoom}
												isVideoArtwork={artworkDesignerTemplate.videoArtwork}
											/>
										)}
										{cropImageField && (
											<ImageCropper
												// field={cropImage.field}
												fieldPosition={{ ...cropImageField.position }}
												imageUrl={cropImage.imageUrl}
												maxBoundaryWidth={WSRect.previewRect.width}
												maxBoundaryHeight={WSRect.previewRect.height}
												zoom={WSRect.zoom}
												// handleCroppedImage={imgBase64Url => {
												// 	setFieldsPreviewValue({
												// 		...fieldsPreviewValue,
												// 		[cropImageField.id]: {
												// 			...fieldsPreviewValue[cropImageField.id],
												// 			croppedImgUrl: imgBase64Url,
												// 		},
												// 	});
												// 	setCropImage({});
												// }}
												handleCroppedImage={(blob) => {
													// Delete croppedImgUrl by revoking blob url
													fieldInputData[cropImageField.id] &&
														deleteCroppedImgUrl(fieldInputData[cropImageField.id].croppedImgUrl);

													setFieldInputData({
														...fieldInputData,
														[cropImageField.id]: {
															...fieldInputData[cropImageField.id],
															croppedImgUrl: URL.createObjectURL(blob),
														},
													});
													setCropImage({});
												}}
												handleClose={() => setCropImage({})}
											/>
										)}
									</div>
									{/* Design preview area */}
									{
										// 	<div
										// 	className={classes.designPreview}
										// 	style={{
										// 		left: WSRect.previewRect.x,
										// 		top: WSRect.previewRect.y,
										// 		width: WSRect.previewRect.width,
										// 		height: WSRect.previewRect.height,
										// 		backgroundSize: 'contain',
										// 		backgroundRepeat: 'no-repeat',
										// 		backgroundImage: `url(${artworkDesignerTemplate.mediaUrl})`,
										// 	}}
										// 	ref={WSPreviewRef}
										// >
										// 	{enablePreview &&
										// 		artworkDesignerTemplate.fields.length > 0 &&
										// 		artworkDesignerTemplate.fields.map((field, idx) => {
										// 			return (
										// 				<Element
										// 					key={field.id}
										// 					type={field.type}
										// 					zoom={WSRect.zoom}
										// 					field={field}
										// 					zIndex={ART_VARIABLES.ZINDEX_FIELDPREVIEW + idx}
										// 				>
										// 					{field.type}
										// 				</Element>
										// 			);
										// 		})}
										// </div>
									}
								</section>
							</PerfectScrollWrapper>
						</div>
						{/* Preview Control Panel */}
						<PreviewControlPanel
							open={UIControls.isPreviewEnabled /* && UIControls.isPreviewControlPanelOpen */}
							mode="design"
							disableUserDefined
							selectedFieldIds={artworkDesignerTemplate.selectedFieldIds}
							groups={artworkDesignerTemplate.groups}
							fields={sortedFields}
							artworkExtra={artworkExtra}
							spreadsheetContent={spreadsheetContentById}
							searchSpreadsheetContent={fetchSpreadsheetContentById}
							resetSpreadsheetContent={resetSpreadsheetContentById}
							handleProductPickerSelection={handleProductPickerSelection}
							userData={userData}
							// fieldsPreviewValue={fieldsPreviewValue}
							// setFieldsPreviewValue={setFieldsPreviewValue}
							resetFieldInputData={() => {
								// clean cropped image blob urls
								cleanCroppedImgUrls(fieldInputData);
								setFieldInputData({});
								setTemplateBGInputData({});
							}}
							setFieldInputDataById={(fieldId, inputData) => {
								if (
									fieldInputData[fieldId] &&
									isBlobUrl(fieldInputData[fieldId].croppedImgUrl) &&
									fieldInputData[fieldId].croppedImgUrl !== inputData.croppedImgUrl
								) {
									deleteCroppedImgUrl(fieldInputData[fieldId].croppedImgUrl);
								}
								setFieldInputData({
									...fieldInputData,
									[fieldId]: { ...fieldInputData[fieldId], ...inputData },
								});
							}}
							fieldOutputData={fieldOutputData}
							templateBGOutputDataWithSettings={templateBGOutputDataWithSettings}
							templateDimension={artworkDesignerTemplate.dimension}
							setTemplateBGInputData={(templateBGData) => {
								setTemplateBGInputData(templateBGData || {});
							}}
							// onClose={() =>
							// 	setUIControls({
							// 		...UIControls,
							// 		isPreviewControlPanelOpen: !UIControls.isPreviewControlPanelOpen,
							// 	})
							// }
							handleImageCrop={({ fieldId, imageUrl }) => {
								setCropImage({ fieldId, imageUrl });
							}}
							handleImageClip={({ fieldId, imageUrl, mediafileId }) => {
								setProgressStatus({
									status: 'OK',
									message: 'Clipping image...',
								});
								fetchClipImage({ mediafileId, queryParams: { imgUrl: imageUrl } })
									.then((response) => {
										setFieldInputData({
											...fieldInputData,
											[fieldId]: {
												...fieldInputData[fieldId],
												clippedImgUrl: response.data.clipImgUrl,
												croppedImgUrl: '', // clipped image is one of the sources for cropping, so we reset cropped image when clipped image is changed
											},
										});
									})
									.catch((err) => {
										let errMsg = err.response ? err.response.data.message : err.message;
										notifyGeneral(`Failed to clip image. ${errMsg}`, 'error');
									})
									.finally(() => {
										setProgressStatus({});
									});
							}}
							handleFieldFocus={(field) =>
								artworkDesignerTemplate.selectedFieldIds.includes(field.id)
									? null
									: handleEleSelect({ field }, false)
							}
							handleResetAnimation={
								// Note: VID-3497 we also use this to control replay the video in videoArtwork
								hasAnimation(artworkDesignerTemplate.fields, artworkDesignerTemplate.videoArtwork)
									? replayAnimationAndVideo
									: null
							}
							// handleSaveToPdfAndSVG={async () => {
							// 	// this is the test function to generate pdf & svg and download them to local
							// 	try {
							// 		// get pdf blob
							// 		let pdfBlob = await exportArtworkToPdf(sortedFields, fieldOutputData, {
							// 			size: [
							// 				artworkDesignerTemplate.dimension.width * pixelToPdfPt, // in pdf point by converting pixel to pdf point (72 dpi in web to 300dpi in pdf)
							// 				artworkDesignerTemplate.dimension.height * pixelToPdfPt,
							// 			],
							// 			templateSize: artworkDesignerTemplate.dimension, // in pixel
							// 			// templateBGUrl: artworkDesignerTemplate.templateBGUrl,
							// 			// templateMediaUrl: artworkDesignerTemplate.templateMediaUrl,
							// 			templatePdfUrl: artworkDesignerTemplate.templatePdfUrl,
							// 			templateSvgUrl: artworkDesignerTemplate.templateSvgUrl,
							// 			fontList: geFontListInTemplateFields(
							// 				sortedFields,
							// 				artworkExtra.fonts,
							// 				ART_VARIABLES
							// 			),
							// 			colorList: getColorListInTemplateFields(sortedFields), // artworkDesignerTemplate.fields
							// 			// viewBoxWidth: artworkDesignerTemplate.dimension.width,
							// 			// viewBoxHeight: artworkDesignerTemplate.dimension.height,
							// 			CONSTANTS: { placeholderSameAsText: ART_VARIABLES.placeholderSameAsText },
							// 		});

							// 		// get svg blob
							// 		let svgBlob = await exportArtworkToSVG(sortedFields, fieldOutputData, {
							// 			templateSize: artworkDesignerTemplate.dimension, // in pixel
							// 			// templateBGUrl: artworkDesignerTemplate.mediaUrl,
							// 			templateMediaUrl: artworkDesignerTemplate.templateMediaUrl,
							// 			// templatePdfUrl: artworkDesignerTemplate.templatePdfUrl,
							// 			templateSvgUrl: artworkDesignerTemplate.templateSvgUrl,
							// 			// downloadName: 'mysvg.svg',
							// 			animations: ART_VARIABLES.animations,
							// 			CONSTANTS: {
							// 				placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
							// 				// placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
							// 				DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
							// 				DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY,
							// 			},
							// 			fontList: geFontListInTemplateFields(
							// 				sortedFields,
							// 				artworkExtra.fonts,
							// 				ART_VARIABLES
							// 			),
							// 			colorList: getColorListInTemplateFields(sortedFields),
							// 		});

							// 		// download pdf & svg file
							// 		const DOMURL = window.URL || window.webkitURL || window;
							// 		let pdfBlobUrl = DOMURL.createObjectURL(pdfBlob);
							// 		let svgBlobUrl = DOMURL.createObjectURL(svgBlob);
							// 		triggerDownload(pdfBlobUrl, [
							// 			{ name: 'download', value: `untitled.pdf` },
							// 			{ name: 'target', value: '_blank' },
							// 		]);
							// 		triggerDownload(svgBlobUrl, [
							// 			{ name: 'download', value: 'untitled.svg' },
							// 			{ name: 'target', value: 'download' },
							// 		]);
							// 		// clean up
							// 		DOMURL.revokeObjectURL(pdfBlobUrl);
							// 		DOMURL.revokeObjectURL(svgBlobUrl);
							// 		pdfBlobUrl = null;
							// 		svgBlobUrl = null;
							// 		pdfBlob = null;
							// 		svgBlob = null;
							// 	} catch (err) {
							// 		notifyGeneral(`Failed to export pdf & svg. ${err.message}`, 'error');
							// 	}
							// }}
							// handleExportSVG={() => {
							// 	exportArtworkToSVG(sortedFields, fieldOutputData /* fieldsPreviewValue */, {
							// 		templateSize: artworkDesignerTemplate.dimension, // in pixel
							// 		// templateBGUrl: artworkDesignerTemplate.mediaUrl,
							// 		templateMediaUrl: artworkDesignerTemplate.templateMediaUrl,
							// 		// templatePdfUrl: artworkDesignerTemplate.templatePdfUrl,
							// 		templateSvgUrl: artworkDesignerTemplate.templateSvgUrl,
							// 		downloadName: 'mysvg.svg',
							// 		animations: ART_VARIABLES.animations,
							// 		CONSTANTS: {
							// 			placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
							// 			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
							// 			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY,
							// 		},
							// 		fontList: Array.from(
							// 			new Set(
							// 				sortedFields
							// 					.filter(f => f.type === 'text')
							// 					.map(f =>
							// 						!f.formatNumberStyle.currencyFontName ||
							// 						f.formatNumberStyle.currencyFontName ===
							// 							ART_VARIABLES.placeholderSameAsText
							// 							? [f.fontfaceName]
							// 							: [f.fontfaceName, f.formatNumberStyle.currencyFontName]
							// 					)
							// 					.flat()
							// 			)
							// 		)
							// 			.map(fontName => {
							// 				let font = _.find(
							// 					artworkExtra.fonts || [],
							// 					artFont => artFont.font === fontName
							// 				);
							// 				return font ? { name: fontName, fontUrl: font.path } : null;
							// 			})
							// 			.filter(item => item),
							// 		colorList: getColorListInFields(),
							// 	}).catch(err => {
							// 		notifyGeneral(`Failed to export svg artwork. ${err.message}`, 'error');
							// 		console.debug(err);
							// 	});
							// }}

							// handleExportToPdfByHighResImg={() => {
							// 	if (svgRef.current) {
							// 		svgToPDFByCanvas(svgRef.current, {
							// 			width: artworkDesignerTemplate.dimension.width,
							// 			height: artworkDesignerTemplate.dimension.height,
							// 			download: true,
							// 			downloadName: 'mytest.pdf',
							// 			highRes: true,
							// 			fontList: Array.from(
							// 				new Set(
							// 					artworkDesignerTemplate.fields
							// 						.filter(f => f.type === 'text')
							// 						.map(f =>
							// 							f.formatNumberStyle.currencyFontName ===
							// 							ART_VARIABLES.placeholderSameAsText
							// 								? [f.fontfaceName]
							// 								: [f.fontfaceName, f.currencyFontName]
							// 						)
							// 						.flat()
							// 				)
							// 			)
							// 				.map(fontName => {
							// 					let font = _.find(
							// 						artworkExtra.fonts || [],
							// 						artFont => artFont.font === fontName
							// 					);
							// 					return font ? { name: fontName, fontUrl: font.path } : null;
							// 				})
							// 				.filter(item => item),
							// 		});
							// 	} else {
							// 		console.debug('Error: svg ref is null');
							// 	}
							// }}
							// handleExportSVGToPdfByImg={() => {
							// 	if (svgRef.current) {
							// 		svgToPDFByCanvas(svgRef.current, {
							// 			width: artworkDesignerTemplate.dimension.width,
							// 			height: artworkDesignerTemplate.dimension.height,
							// 			download: true,
							// 			downloadName: 'mytest.pdf',
							// 			fontList: Array.from(
							// 				new Set(
							// 					artworkDesignerTemplate.fields
							// 						.filter(f => f.type === 'text')
							// 						.map(f =>
							// 							f.formatNumberStyle.currencyFontName ===
							// 							ART_VARIABLES.placeholderSameAsText
							// 								? [f.fontfaceName]
							// 								: [f.fontfaceName, f.currencyFontName]
							// 						)
							// 						.flat()
							// 				)
							// 			)
							// 				.map(fontName => {
							// 					let font = _.find(
							// 						artworkExtra.fonts || [],
							// 						artFont => artFont.font === fontName
							// 					);
							// 					return font ? { name: fontName, fontUrl: font.path } : null;
							// 				})
							// 				.filter(item => item),
							// 		});
							// 	} else {
							// 		console.debug('Error: svg ref is null');
							// 	}
							// }}
							// handleExportSVGToImg={() => {
							// 	if (svgRef.current) {
							// 		svgToImage(svgRef.current, {
							// 			width: artworkDesignerTemplate.dimension.width,
							// 			height: artworkDesignerTemplate.dimension.height,
							// 			format: 'png',
							// 			download: true,
							// 			downloadName: 'mytest.png',
							// 			fontList: Array.from(
							// 				new Set(
							// 					artworkDesignerTemplate.fields
							// 						.filter(f => f.type === 'text')
							// 						.map(f =>
							// 							f.formatNumberStyle.currencyFontName ===
							// 							ART_VARIABLES.placeholderSameAsText
							// 								? [f.fontfaceName]
							// 								: [f.fontfaceName, f.currencyFontName]
							// 						)
							// 						.flat()
							// 				)
							// 			)
							// 				.map(fontName => {
							// 					let font = _.find(
							// 						artworkExtra.fonts || [],
							// 						artFont => artFont.font === fontName
							// 					);
							// 					return font ? { name: fontName, fontUrl: font.path } : null;
							// 				})
							// 				.filter(item => item),
							// 		});
							// 	} else {
							// 		console.debug('Error: svg ref is null');
							// 	}
							// }}
						/>
						{/* Contextual Palette (draggable) */}
						<ElementPalette
							open={contextualPaletteOpen}
							onClose={() => setContextualPaletteOpen(false)}
							userData={userData}
							draggableBounds={`[id="${workspaceWrapperId}"]`}
							selectedFields={deepClone(selectedFields)}
							groups={groupsInCurrentPage}
							handleFieldsUpdate={handleFieldsUpdate}
							handleNewGroup={handleNewGroup}
							// handleDelGroup={handleDelGroup}
							artworkExtraData={artworkExtra}
							templateFields={artworkDesignerTemplate.fields}
							zoom={WSRect.zoom}
							paletteTabIndex={artworkDesignerTemplate.paletteTabIndex}
							setPaletteState={(paletteState = {}) => setArtDesignState(paletteState)}
							isVideoArtwork={artworkDesignerTemplate.videoArtwork}
						/>
						{/* Preview */}
						{
							// <DesignPreview draggableBounds={`[id="${workspaceWrapperId}"]`} />
						}

						{/* Design Settings */}
						<DesignSetting
							designerTemplate={artworkDesignerTemplate}
							artworkExtraData={artworkExtra}
							handleTemplateInfoUpdate={handleTemplateInfoUpdate}
							setDesignSettingOpen={(isOpen) => setArtDesignState({ designSettingOpen: isOpen })}
							displayOutputTemplate={displayOutputTemplate}
							open={artworkDesignerTemplate.designSettingOpen}
							userData={userData}
							// workspaceContainerRef={workspaceContainerRef}
						/>
						{/* bottom control ribbon */}
						<div className={classes.bottomActionRibbonWrapper}>
							<BottomActionRibbon
								// Invalid fields control
								handleClickInvalidFieldsBtn={() => setOpenInvalidFields(!openInvalidFields)}
								showInvalidFieldsBtn={invalidFields.length > 0}
								// undo/redo props
								canUndo={canUndo}
								canRedo={canRedo}
								undoHandler={() => undoDesignTemplate()}
								redoHandler={() => redoDesignTemplate()}
								// action buttons props
								saveDesignerTemplate={() => {
									if (invalidFields.length > 0) return setOpenInvalidFields(true);
									if (
										!artworkDesignerTemplate.fields ||
										artworkDesignerTemplate.fields.length === 0
									) {
										return notifyGeneral(
											intl.formatMessage({
												id: 'pages.Artwork.components.Design.emptyFieldsOnSaveErrMsg',
											}),
											'error'
										);
									}
									handleSaveDesignerTemplate();
								}}
								saveTemplateStatus={artworkDesignerTemplate.saveTemplateStatus}
								handleTestTemplate={handleTestTemplate}
								handleEsignTemplate={
									artworkDesignerTemplate.fields.filter(
										(f) =>
											f.predefinedValue &&
											f.predefinedValue.type === 'spreadsheet' &&
											Boolean(f.predefinedValue.from) &&
											Boolean(f.predefinedValue.fromColumn)
									).length > 0
										? handleEsignTemplate
										: null
								}
								// zoom props
								handleZoomOut={handleZoomChange}
								handleZoomIn={handleZoomChange}
								handleZoomSelect={handleZoomChange}
								zoom={WSRect.zoom}
								// preview control
								isPreviewEnabled={UIControls.isPreviewEnabled}
								togglePreview={() => {
									setUIControls({
										...UIControls,
										isPreviewEnabled: !UIControls.isPreviewEnabled,
										// isPreviewControlPanelOpen: !UIControls.isPreviewEnabled ? true : false,
									});
									replayAnimationAndVideo();
								}}
								// background image control
								isBGImageEnabled={UIControls.isBGImageEnabled}
								toggleBGImage={() => {
									setUIControls({
										...UIControls,
										isBGImageEnabled: !UIControls.isBGImageEnabled,
									});
									replayAnimationAndVideo();
								}}
								// highlight fields control
								isFieldHighlightEnabled={UIControls.isFieldHighlightEnabled}
								toggleFieldHighlight={() =>
									setUIControls({
										...UIControls,
										isFieldHighlightEnabled: !UIControls.isFieldHighlightEnabled,
									})
								}
							/>
						</div>
						{/** Popper to display invalid fields */}
						{openInvalidFields && (
							<InvalidFieldsMUIPopper
								invalidFields={invalidFields}
								handleClose={() => setOpenInvalidFields(false)}
								popperProps={{
									open: true,
									anchorEl: workspaceContainerRef.current,
									transition: true,
									placement: 'bottom',
								}}
								handleSelectField={(fieldId) => handleEleSelect({ field: { id: fieldId } }, false)}
								handleOpenTemplateSettings={() =>
									!artworkDesignerTemplate.designSettingOpen &&
									setArtDesignState({ designSettingOpen: true })
								}
								handleDeleteUnsupportedFieldsInVideoArtwork={() => {
									handleDelFields(
										artworkDesignerTemplate.fields.filter(
											(f) => !ART_VARIABLES.supportedFieldTypesInVideoArtwork.includes(f.type)
										)
									);
								}}
							/>
						)}
						{/** progress loader of any async process */}
						<ArtLoading
							open={Boolean(progressStatus.status)}
							// progressLoaderFormat="gif"
							message={progressStatus.message || ''}
							failureHandler={
								progressStatus.status === 'FAILED' ? () => setProgressStatus({}) : null
							}
						/>
					</React.Fragment>
				) : (
					<ArtLoading
						open={true}
						failureButtonText="Go Back"
						message={initStatus.message || ''}
						failureHandler={initStatus.status === 'FAILED' ? handleGoback : null}
					/>
				)}
			</div>
		</DesignContext.Provider>
	);
}

Design.propTypes = {
	turnOnSidebar: PropTypes.func.isRequired,
	turnOffSidebar: PropTypes.func.isRequired,
	artworkDesignerTemplate: PropTypes.object.isRequired,
	canUndo: PropTypes.bool.isRequired,
	canRedo: PropTypes.bool.isRequired,
	updateLocalDesignerTemplate: PropTypes.func.isRequired,
	setArtDesignState: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	getDesignerTemplate: PropTypes.func.isRequired,
	importArtworkTemplate: PropTypes.func.isRequired,
	resetDesignerTemplate: PropTypes.func.isRequired,
	resetArtworkFonts: PropTypes.func.isRequired,
	fetchArtworkFonts: PropTypes.func.isRequired,
	fetchArtworkSpreadsheets: PropTypes.func.isRequired,
	resetArtworkSpreadsheets: PropTypes.func.isRequired,
	fetchArtworkLists: PropTypes.func.isRequired,
	resetArtworkLists: PropTypes.func.isRequired,
	fetchArtworkAutoImport: PropTypes.func.isRequired,
	resetArtworkAutoImport: PropTypes.func.isRequired,
	fetchArtworkOutputTemplates: PropTypes.func.isRequired,
	resetArtworkOutputTemplates: PropTypes.func.isRequired,
	fetchArtworkCategories: PropTypes.func.isRequired,
	resetArtworkCategories: PropTypes.func.isRequired,
	saveDesignerTemplate: PropTypes.func.isRequired,
	// retrieveDraftDesignTemplate: PropTypes.func.isRequired,
	fetchLightboxes: PropTypes.func.isRequired,
	resetLightboxes: PropTypes.func.isRequired,
	fetchSpreadsheetContentById: PropTypes.func.isRequired,
	resetSpreadsheetContentById: PropTypes.func.isRequired,
	undoDesignTemplate: PropTypes.func.isRequired,
	redoDesignTemplate: PropTypes.func.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	resetGlobalDialog: PropTypes.func.isRequired,
	artworkExtra: PropTypes.object.isRequired,
	spreadsheetContentById: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
};

Design.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		artworkDesignerTemplate: state.artworkDesignerTemplate.present,
		artworkExtra: { ...state.artworkExtra, lightboxes: state.filemanager.lightboxes }, // add lightboxes to artworkExtra
		userData: { uid: state.authentication.userId }, // (logged in) user data
		spreadsheetContentById: {
			content: state.filemanager.spreadsheetContentById,
			fetchStatus: state.filemanager.fetchSSContentByIdStatus,
		},
		canUndo: state.artworkDesignerTemplate.past.length > 0,
		canRedo: state.artworkDesignerTemplate.future.length > 0,
	};
};

// query parameters: t ["template ID"], retrieveDraft ["yes", "no", undefined]
export default connect(mapStateToProps, {
	turnOnSidebar,
	turnOffSidebar,
	updateLocalDesignerTemplate,
	setArtDesignState,
	notifyGeneral,
	getDesignerTemplate,
	importArtworkTemplate,
	resetDesignerTemplate,
	resetArtworkFonts,
	fetchArtworkFonts,
	fetchArtworkSpreadsheets,
	resetArtworkSpreadsheets,
	fetchArtworkLists,
	resetArtworkLists,
	fetchArtworkAutoImport,
	resetArtworkAutoImport,
	fetchArtworkOutputTemplates,
	resetArtworkOutputTemplates,
	fetchArtworkCategories,
	resetArtworkCategories,
	saveDesignerTemplate,
	// retrieveDraftDesignTemplate,
	fetchLightboxes,
	resetLightboxes,
	fetchSpreadsheetContentById,
	resetSpreadsheetContentById,
	undoDesignTemplate,
	redoDesignTemplate,
	openGlobalDialog,
	resetGlobalDialog,
})(Design);
