import {
	AUTH_LOGIN,
	AUTH_LOGOUT,
	AUTH_HANDLEAUTH_SUCCESS,
	AUTH_HANDLEAUTH_FAILED,
} from 'redux/actions';

import jwt from 'jsonwebtoken';
import { _ } from 'utils/libHelper';
import { getAppTheme } from 'themes';
import config from 'config';
import { getDomainByHostname } from 'utils/appHelper';
// TODO: Need to refactor the authentication redux after having our own auth service api
// *** we should have user preference (from API) in the state which is combination of user config & domain config ***
let domainNameByHost = getDomainByHostname();
const authInitState = {
	accessToken: '', // raw access token
	token: '', // Full access token. it is tokenType+space+accessToken: `${tokenType} ${accessToken}`
	// accessToken: '', // the access token
	// tokenType: '', // token type, e.g. 'Bearer
	isLoggedIn: () => false,
	userLevel: null, // an integer
	userId: '',
	enabledFeatures: [],
	// role: '',	// To Be Decided. May not be required as userLevel can be used as user role
	domainName: domainNameByHost,
	appTheme: getAppTheme(domainNameByHost).default,
	isAuthorized: () => false,
	// idToken: '',
	// expiresAt: 0,
	// scopes: '',
	// profile: {},
	// isAuthenticated: ()=>false, // new Date().getTime() < expiresAt,
	// hasAnyScope: ()=>false,
	// state: '/',
	loginStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	error: null,
};

export const authentication = (state = authInitState, action) => {
	switch (action.type) {
		case AUTH_LOGIN:
			// return { ...state };
			return {
				...state,
				loginStatus: 'PROCESSING',
			};
		// case AUTH_LOGOUT:
		// 	localStorage.removeItem('auth');
		// 	return authInitState;
		case AUTH_LOGOUT:
			localStorage.removeItem(config.authCookieKey);
			return authInitState;
		case AUTH_HANDLEAUTH_SUCCESS: {
			let decodedToken = jwt.decode(action.result.data.accessToken);
			if (!decodedToken || !decodedToken.user || !decodedToken.user.ul) {
				return {
					...state,
					error: new Error('Invalid Token.'),
				};
			}
			if (Date.now() >= decodedToken.exp * 1000) {
				// the token was previously saved, and is expired
				// authCleanUp();
				return state;
			}
			// authSaveToken(action.result.accessToken);
			let domainName = decodedToken.domain || getDomainByHostname();

			let UL = parseInt(decodedToken.user.ul) || null;
			let enabledFeatures = JSON.parse(decodedToken.user.features || '[]');
			return {
				...state,
				accessToken: action.result.data.accessToken,
				token: `Bearer ${action.result.data.accessToken}`,
				userLevel: UL,
				userId: decodedToken.user.uid,
				enabledFeatures: enabledFeatures,
				domainName: domainName,
				loginStatus: 'OK',
				appTheme: getAppTheme(domainName).default,
				isLoggedIn: () => Date.now() < decodedToken.exp * 1000,
				isAuthorized: (requiredUserLevels, requiredFeatures) =>
					_isAuthorized(requiredUserLevels, requiredFeatures, UL, enabledFeatures),
			};
		}
		case AUTH_HANDLEAUTH_FAILED:
			// TODO: Use error page for this login failure
			return {
				...state,
				error: action.error,
				loginStatus: 'FAILED',
			};
		default:
			return state;
	}
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// ##############################
// Check if user is authorized
// params:
//		requiredUserLevels: object. {$eq: value, $gt: value, $in: [], ..., $eq|$gt|$gte|$in|$lt|$lte|$ne|$nin: value|[]}. The required user levels. Or operation applies. The value must be nuber or array of number, because userlevel should be integer
// 		requiredFeatures: array. The required features. Or operation applies.
// 		userLevel: number. The user level to be checked
// 		enabledFeatures: array. enabled features with the logged in user
// return:
//		true: the user level matches the required condition; the user has permission
//		false: the user doesn't have permission
// #############################
function _isAuthorized(requiredUserLevels = {}, requiredFeatures = [], userLevel, enabledFeatures) {
	if (userLevel == null) {
		// check is userLevel is null/undefined
		return false; // userLevel must be number (integer)
	}

	// check required features
	if (
		requiredFeatures.length > 0 &&
		!_.find(enabledFeatures, (enabledFeature) => requiredFeatures.includes(enabledFeature))
	) {
		return false;
	}
	// check required user levels
	if (Object.keys(requiredUserLevels).length > 0 && !_isULMatching(requiredUserLevels, userLevel)) {
		return false;
	}
	return true;
}

// ##############################
// Check if user level matches the required condition
// Helper function for _isAuthorized()
// params:
//		requiredUserLevels: object. {$eq: value, $gt: value, $in: [], ..., $eq|$gt|$gte|$in|$lt|$lte|$ne|$nin: value|[]}. The required user levels. Or operation applies. The value must be nuber or array of number, because userlevel should be integer
// 		userLevel: number. The user level to be checked
// return:
//		true: the user level matches the required condition; the user has permission
//		false: the user doesn't have permission
// #############################
function _isULMatching(requiredUserLevels, userLevel = null) {
	if (userLevel == null) {
		return false; // userLevel must be number (integer)
	}

	for (let operator in requiredUserLevels) {
		let requiredULValue;
		switch (operator) {
			case '$eq':
				requiredULValue = parseInt(requiredUserLevels[operator]);
				if (requiredULValue === userLevel) {
					return true;
				}
				break;
			case '$gt':
				requiredULValue = parseInt(requiredUserLevels[operator]);
				if (userLevel > requiredULValue) {
					return true;
				}
				break;
			case '$gte':
				requiredULValue = parseInt(requiredUserLevels[operator]);
				if (userLevel >= requiredULValue) {
					return true;
				}
				break;
			case '$in':
				requiredULValue = JSON.parse(requiredUserLevels[operator]);
				if (requiredULValue.includes(userLevel)) {
					return true;
				}
				break;
			case '$lt':
				requiredULValue = parseInt(requiredUserLevels[operator]);
				if (userLevel < requiredULValue) {
					return true;
				}
				break;
			case '$lte':
				requiredULValue = parseInt(requiredUserLevels[operator]);
				if (userLevel <= requiredULValue) {
					return true;
				}
				break;
			case '$ne':
				requiredULValue = parseInt(requiredUserLevels[operator]);
				if (userLevel !== requiredULValue) {
					return true;
				}
				break;
			case '$nin':
				requiredULValue = JSON.parse(requiredUserLevels[operator]);
				if (!requiredULValue.includes(userLevel)) {
					return true;
				}
				break;
			default:
				break;
		}
	}
	return false; // no match condition, return false
}
