import config from '../../config';
import {
	DOMAIN_SETTINGS,
	DOMAIN_SETTINGS_SUCCESS,
	DOMAIN_SETTINGS_FAIL,
	EDIT_DOMAIN_SETTINGS,
	EDIT_DOMAIN_SETTINGS_SUCCESS,
	EDIT_DOMAIN_SETTINGS_FAIL,
} from 'redux/actions';
// import { endOfDay, startOfDay } from 'date-fns';
// const filemanagerAPIBaseUrl = config.filemanagerAPI.baseUrl;
// const authHeaderKey = config.filemanagerAPI.authHeaderKey;
const usermanagerAPIBaseUrl = config.usermanagerAPI.baseUrl;
const authHeaderKey = config.usermanagerAPI.authHeaderKey;

// export const getDomainSettings = () => (dispatch, getState) => {
// 	let reqUrl = `${usermanagerAPIBaseUrl}/domains/spar/settings`;
// 	const authState = getState().authentication;
// 	let dateObj = {
// 		startDate: startOfDay(new Date()),
// 		endDate: endOfDay(new Date()),
// 	};
// 	let reqHeader = {
// 		'Content-Type': 'application/json',
// 		[authHeaderKey]: authState.token,
// 	};
// 	let filters = {
// 		createdDatetimeFrom: dateObj.startDate.toISOString(),
// 		createdDatetimeTo: dateObj.endDate.toISOString(),
// 		createdByUIDs: [getState().authentication.userId],
// 	};
// 	let axiosConfig = {
// 		url: reqUrl,
// 		method: 'post',
// 		headers: reqHeader,
// 		params: {
// 			domain: authState.domainName,
// 			uid: authState.userId,
// 			// ...(opts.queryParams || {}),
// 		},
// 		data: { filters: filters },
// 		timeout: 90000,
// 	};

// 	return new Promise(() => {
// 		dispatch({
// 			types: [DOMAIN_SETTINGS, DOMAIN_SETTINGS_SUCCESS, DOMAIN_SETTINGS_FAIL],
// 			promise: (client) => {
// 				return client.request(axiosConfig);
// 			},
// 			// afterSuccess: ({ dispatch, getState, result, error }) => {
// 			// 	if (error) return rej(error);
// 			// 	else {
// 			// 		res(result);
// 			// 	}
// 			// },
// 		});
// 	});
// };

export const getDomainSettings = () => (dispatch, getState) => {
	let reduxState = getState();
	let reqUrl = `${usermanagerAPIBaseUrl}/domains/${reduxState.authentication.domainName}/settings`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: reduxState.authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		// params: { domain: reduxState.authentication.domainName },
		headers: reqHeader,
		timeout: 30000,
	};
	// return dispatch({
	// 	types: [DOMAIN_SETTINGS, DOMAIN_SETTINGS_SUCCESS, DOMAIN_SETTINGS_FAIL],
	// 	promise: (client) => {
	// 		return client.request(axiosConfig);
	// 	},
	// 	afterSuccess: cb,
	// });
	return new Promise(() => {
		dispatch({
			types: [DOMAIN_SETTINGS, DOMAIN_SETTINGS_SUCCESS, DOMAIN_SETTINGS_FAIL],
			promise: (client) => {
				return client.request(axiosConfig);
			},
			// afterSuccess: ({ dispatch, getState, result, error }) => {
			// 	if (error) return rej(error);
			// 	else {
			// 		res(result);
			// 	}
			// },
		});
	});
};
export const editDomainSettings = (opts, cb) => (dispatch, getState) => {
	let reduxState = getState();
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: reduxState.authentication.token,
	};
	let reqUrl = `${usermanagerAPIBaseUrl}/domains/${reduxState.authentication.domainName}/settings`;
	let axiosConfig = {
		url: reqUrl,
		method: 'patch',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [EDIT_DOMAIN_SETTINGS, EDIT_DOMAIN_SETTINGS_SUCCESS, EDIT_DOMAIN_SETTINGS_FAIL],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};
