import React from 'react';
// import cx from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import {
	Typography,
	IconButton,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	MenuItem,
	Box,
} from '@mui/material';
import { Close as CloseIcon, ErrorOutline as HighlightIcon } from '@mui/icons-material';

// custom components
import { DraggableMUIDialog } from 'components';

// intl lang
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	title: {
		margin: 0,
		padding: theme.spacing(2),
		cursor: 'move',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

function BatchDialog({
	open,
	handleClose,
	handleBatchingTask,
	templatesProductPickerColumns,
	ssHeaderColumns,
}) {
	const classes = useStyles();
	const intl = useIntl();

	// filenamePerTemplate is an object, key is template id, value is the column Id that is used as filename
	//	format: {[templateId]: filenameColumnId, [templateId]: filenameColumnId, ...}
	const [filenamePerTemplate, setFilenamePerTemplate] = React.useState({});
	const [userOwnerColumnId, setUserOwnerColumnId] = React.useState();

	React.useEffect(() => {
		const singleProducuctPicker = templatesProductPickerColumns.reduce(
			(accu, templateProductPicker) => {
				if (templateProductPicker?.productPickerColumns.length === 1) {
					accu = {
						...accu,
						[templateProductPicker?.templateId]: templateProductPicker.productPickerColumns[0]
							?.columnId,
					};
				}
				return accu;
			},
			{}
		);
		setFilenamePerTemplate(singleProducuctPicker);
	}, [templatesProductPickerColumns]);

	return (
		<DraggableMUIDialog
			disableBackdropClick
			disableEscapeKeyDown
			open={open}
			maxWidth="sm"
			fullWidth={true}
			scroll="paper"
			onClose={handleClose}
			dragHandle={`.${classes.title}`}
		>
			<DialogTitle className={classes.title}>
				{intl.formatMessage({ id: 'pages.Artwork.components.Esign.BatchDialogTitle' })}
				<IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				<Typography variant="body2" sx={{ color: 'rose.main' }}>
					<HighlightIcon fontSize="small" sx={{ marginRight: 1 }} />
					{intl.formatMessage({ id: 'pages.Artwork.components.Esign.BatchDialogInstructionText' })}
				</Typography>
				<Box
					sx={{
						marginTop: 2,
						'& .MuiTextField-root': { m: 1, width: '90%' },
					}}
					noValidate
					autoComplete="off"
				>
					{templatesProductPickerColumns.map((templateProductPicker, idx) => {
						if (templateProductPicker?.productPickerColumns.length < 2) return null;

						return (
							<TextField
								key={`${templateProductPicker.templateId}-${idx}`}
								select
								defaultValue=""
								label={intl.formatMessage(
									{
										id: 'pages.Artwork.components.Esign.BatchFileNameColSelectLabel',
									},
									{
										templateName: templateProductPicker.templateName,
									}
								)}
								onChange={(e) => {
									setFilenamePerTemplate({
										...filenamePerTemplate,
										[templateProductPicker?.templateId]: e.target.value,
									});
								}}
							>
								{templateProductPicker.productPickerColumns.map((column) => (
									<MenuItem key={column.columnId} value={column.columnId}>
										{column.columnName}
									</MenuItem>
								))}
							</TextField>
						);
					})}

					<TextField
						select
						label={intl.formatMessage({
							id: 'pages.Artwork.components.Esign.BatchUserColSelectLabel',
						})}
						defaultValue=""
						onChange={(e) => {
							setUserOwnerColumnId(e.target.value);
						}}
					>
						<MenuItem value={'myfiles'}>myfiles</MenuItem>
						{ssHeaderColumns.map((header) => (
							<MenuItem key={header.columnId} value={header.columnId}>
								{header.columnName}
							</MenuItem>
						))}
					</TextField>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose} size="small" variant="contained">
					{intl.formatMessage({ id: 'GENERAL.Cancel' })}
				</Button>

				<Button
					disabled={
						!(
							templatesProductPickerColumns.length === Object.keys(filenamePerTemplate).length &&
							Boolean(userOwnerColumnId)
						)
					}
					size="small"
					variant="contained"
					color="primary"
					onClick={async () => {
						await handleBatchingTask(filenamePerTemplate, userOwnerColumnId);
						handleClose();
					}}
				>
					{intl.formatMessage({ id: 'GENERAL.Batch' })}
				</Button>
			</DialogActions>
		</DraggableMUIDialog>
	);
}

BatchDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleBatchingTask: PropTypes.func.isRequired,
	ssHeaderColumns: PropTypes.arrayOf(
		PropTypes.shape({
			columnId: PropTypes.string.isRequired,
			columnName: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
		})
	).isRequired,
	templatesProductPickerColumns: PropTypes.arrayOf(
		PropTypes.shape({
			templateId: PropTypes.string.isRequired,
			templateName: PropTypes.string.isRequired,
			productPickerColumns: PropTypes.arrayOf(
				PropTypes.shape({
					columnId: PropTypes.string.isRequired,
					columnName: PropTypes.string.isRequired,
					type: PropTypes.string.isRequired,
				})
			).isRequired,
		})
	).isRequired,
};

BatchDialog.defaultProps = { adjustOutput: 0 };

export default BatchDialog;
