import React from 'react';

// import {StaticRouter, Link as RouterLink } from 'react-router-dom';

// import config from 'config';

import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import { Search, RTTrComp, DraggableMUIDialog, ScreenManagerGridView } from 'components';
import PlaylistPreview from './PlaylistPreview';

import { _ } from 'utils/libHelper';
import { encryptStr } from 'utils/appHelper';
import { injectIntl, FormattedMessage } from 'react-intl';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

// material-ui components
import {
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	Fab,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Input,
	Link,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

// icons
import {
	Add as AddIcon,
	Save as SaveIcon,
	StayCurrentPortraitOutlined as PortraitIcon,
	StayCurrentLandscapeOutlined as LandscapeIcon,
	Close as CloseIcon,
	Loop as VideoLoopIcon,
	Edit as EditIcon,
	DeleteOutline as DeleteIcon,
	Visibility as PreviewIcon,
	FileCopy as DuplicateIcon,
	Cancel as CancelIcon,
	// Settings as SettingIcon,
} from '@mui/icons-material';
// CSS
import PlaylistsStyle from './PlaylistsStyle.jsx';

// redux
import { connect } from 'react-redux';
import {
	openGlobalDialog,
	resetGlobalDialog,
	fetchScreenManagerPlaylists,
	deleteScreenManagerPlaylist,
	duplicateScreenManagerPlaylist,
	notifyError,
	notifyGeneral,
	editScreenManagerPlaylist,
	addScreenManagerPlaylist,
	resetScreenManagerPlaylists,
	fetchScreenManagerDepartments,
	getScreenManagerPlaylistSlide,
	resetScreenManagerPlaylistSlideAllStatus,
} from 'redux/actions'; // actions

// Constants
import {
	MIN_USERLEVEL_MASTER_PLAYLIST_READ,
	MIN_USERLEVEL_To_Synchronise_Playlist,
} from 'pages/ScreenManager/Constants';
import {
	ROUTES_PATH_SCREENMANAGER_SLIDES,
	ROUTES_PATH_SCREENMANAGER_PLAYLISTS_Synchronised,
} from 'routes';

class Playlists extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// filtered: [],
			filtered:
				this.props.location.state === undefined
					? []
					: this.props.location.state === null
					? []
					: this.props.location.state.playlistSearchState,
			editing: {}, // the editing data of a screen
			adding: {}, // the adding data of a screen
			draggableDialog: false,
			previewDialogShowMaster: true,
			isDesktop: false,
			// editingGrid: {}, // the editing data of a screen
			playlistSortState:
				this.props.location.state === undefined
					? []
					: this.props.location.state === null
					? []
					: this.props.location.state.playlistSortState,
		};
		this.updateScreenResolution = this.updateScreenResolution.bind(this);
		this.columns = [
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.TitleCol',
				}), // 'Title',
				resizable: true,
				sortable: true,
				Footer: 'NOTE: Do NOT delete this line!',
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					return !editing ? (
						cellInfo.value
					) : (
						<Input
							className={this.props.classes.editingInput}
							inputProps={{
								'aria-label': 'playlistName',
								style: {
									border: 'unset',
									boxShadow: 'unset',
									height: 34,
									backgroundColor: '#ffffff',
								},
							}}
							onChange={(event) => {
								this.setState({ editing: { ...currentEditing, name: event.target.value } });
							}}
							value={
								typeof currentEditing.name === 'undefined'
									? currentEditing.original.name
									: currentEditing.name
							}
						/>
					);
				},
			},
			{
				accessor: (d) => d.noOfSlides,
				id: 'noOfSlides',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.NoOfItemsCol',
				}), //'No. of Items',
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => {
					let slidesTotal =
						cellInfo.original.noOfSlides + (cellInfo.original.noOfSlidesFromMaster || 0);
					return (
						<span>
							<Link
								variant="body1"
								className={this.props.classes.hoverCursor}
								underline="always"
								onClick={() => {
									if (cellInfo.original.syncGroupId === '') {
										this.props.history.push({
											pathname: ROUTES_PATH_SCREENMANAGER_SLIDES,
											search: `?playlistId=${encryptStr(cellInfo.original.id)}`,
											state: {
												playlistSearchState: this.state.filtered,
												playlistSortState: this.state.playlistSortState,
											},
										});
									} else {
										this.props.history.push({
											pathname: ROUTES_PATH_SCREENMANAGER_PLAYLISTS_Synchronised,
											search: `?syncGroupId=${encryptStr(cellInfo.original.syncGroupId)}`,
											state: {
												playlistSearchState: this.state.filtered,
												playlistSortState: this.state.playlistSortState,
											},
										});
									}
								}}
								title="Total slide count (own + master slides)"
							>
								{props.intl.formatMessage(
									{ id: 'pages.ScreenManager.components.Playlists.ViewNoOfItemText' },
									{ numberOfItems: slidesTotal }
								)}
							</Link>
							{` (${cellInfo.original.noOfSlides} + ${
								cellInfo.original.noOfSlidesFromMaster || 0
							})`}
						</span>
					);
				},
			},
			{
				accessor: (d) => d.duration,
				id: 'duration',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.DurationCol',
				}), //'Playlist total duration',
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => {
					let durTotal =
						(cellInfo.original.duration || 0) + (cellInfo.original.durationMaster || 0);
					return (
						<FormLabel title="Total Playlist Duration (own + master playlist)">
							{`${Math.floor(durTotal / 60)}m ${durTotal % 60}s
								(${cellInfo.original.duration || 0}s +
								${cellInfo.original.durationMaster || 0}s)`}
						</FormLabel>
					);
				},
			},
			{
				accessor: (d) => d.layout || '', // Orientation (portrait/landscape)
				id: 'layout',
				Header: (
					<div
						title={props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Playlists.LayoutCol',
						})}
					>
						<PortraitIcon className={this.props.classes.portraitIcon} />
						<LandscapeIcon className={this.props.classes.landscapeIcon} />
					</div>
				),
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					const getLayoutIcon = (layout) =>
						layout === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />;
					if (!editing) {
						return getLayoutIcon(cellInfo.value);
					} else {
						return (
							<RadioGroup
								aria-label="layout"
								name="layout"
								className={this.props.classes.radioGroup}
								value={currentEditing.layout || currentEditing.original.layout}
								onChange={(event) =>
									this.setState({ editing: { ...currentEditing, layout: event.target.value } })
								}
								row
							>
								{['landscape', 'portrait'].map((val) => (
									<FormControlLabel
										key={val}
										value={val}
										control={<Radio />}
										label={getLayoutIcon(val)}
									/>
								))}
							</RadioGroup>
						);
					}
				},
			},
			{
				accessor: (d) => d.departmentName || '',
				id: 'departmentName',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.DepartmentCol',
				}), //'Department',
				resizable: true,
				sortable: true,
				// filterable: true
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;

					if (!editing) {
						return cellInfo.value;
					} else {
						return (
							<Select
								id="playlistDepartmentEdit"
								variant="standard"
								// select
								label=""
								classes={{
									// root: this.props.classes.addingTextField,
									select: this.props.classes.dropdown,
								}}
								// className={this.props.classes.addingTextField}
								value={this.state.editing.departmentId || cellInfo.original.departmentId}
								onChange={(event) =>
									this.setState({
										editing: {
											...currentEditing,
											departmentId: event.target.value,
										},
									})
								}
								// helperText="Please select the department"
								// margin="dense"
							>
								{_.sortBy(this.props.screenManagerDepartments.departments, [
									(dept) => dept.name.toLowerCase(),
								]).map((department) => (
									<MenuItem key={department.name} value={department.id}>
										{department.name}
									</MenuItem>
								))}
							</Select>
						);
					}
				},
			},
			{
				accessor: (d) => (d.isMaster ? 'Yes' : 'No'),
				id: 'isMaster',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.IsMasterCol',
				}), //'Is Master',
				show: this.props.authentication.userLevel >= MIN_USERLEVEL_MASTER_PLAYLIST_READ,
				resizable: true,
				sortable: true,
				// filterable: true
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					return !editing ? (
						props.intl.formatMessage({
							id: cellInfo.value === 'Yes' ? 'GENERAL.Yes' : 'GENERAL.No',
						})
					) : (
						<RadioGroup
							aria-label="isMaster"
							name="isMaster"
							className={this.props.classes.radioGroup}
							value={
								currentEditing.isMaster === undefined
									? currentEditing.original.isMaster
										? 'Yes'
										: 'No'
									: currentEditing.isMaster
									? 'Yes'
									: 'No'
							}
							onChange={(event) =>
								this.setState({
									editing: {
										...currentEditing,
										isMaster: event.target.value === 'Yes' ? true : false,
									},
								})
							}
							row
						>
							{['Yes', 'No'].map((val) => (
								<FormControlLabel
									key={val}
									value={val}
									control={<Radio />}
									label={props.intl.formatMessage({
										id: val === 'Yes' ? 'GENERAL.Yes' : 'GENERAL.No',
									})}
								/>
							))}
						</RadioGroup>
					);
				},
			},
			{
				// NOTE - this is a "filter all" DUMMY column
				// you can't HIDE it because then it wont FILTER
				// but it has a size of ZERO with no RESIZE and the
				// FILTER component is NULL (it adds a little to the front)
				// You may possibly move it to the end of columns
				Header: 'All',
				id: 'all',
				width: 0,
				resizable: false,
				sortable: false,
				Filter: () => {},
				// eslint-disable-next-line no-unused-vars
				getProps: (state, rowInfo, column) => {
					return {
						style: { display: 'none' },
					};
				},
				getHeaderProps: () => {
					return {
						style: { display: 'none' },
					};
				},
				filterMethod: (filter, rows) => {
					// using match-sorter
					// it will take the content entered into the "filter"
					// and search for it in keys
					const result = matchSorter(rows, filter.value, {
						keys: ['name', 'departmentName', 'layout'],
						threshold: matchSorter.rankings.CONTAINS,
					});
					// console.log('row[0]:', result[0]);
					// VID-3488 No.6 use class name "rt-tbody" to find the react-table body element, then scroll to top when searching
					let tbody = document.getElementsByClassName('rt-tbody')[0];
					if (tbody !== undefined && tbody.scrollTop) tbody.scrollTop = 0;
					return result;
				},
				filterAll: true,
			},
		];
		this.screenActions = [
			{
				shouldDisplay: (x) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (x.id === currentEditing.original.id) {
							return false;
						}
					}
					return true;
				},
				icon: <PreviewIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Edit',
				}),
				clickHandler: this.previewPlaylist,
			},
			{
				shouldDisplay: (x) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (x.id === currentEditing.original.id) {
							return false;
						}
					}
					if (x.isMaster === 'No') {
						return true;
					} else {
						return false;
					}
				},
				icon: <DuplicateIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Duplicate',
				}),
				clickHandler: this.duplicatePlaylist,
			},
			{
				shouldDisplay: (playlist) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (playlist.id === currentEditing.original.id) {
							return false;
						}
					}
					return true;
				}, // hook func to verify that should the action be displayed for the given playlist
				// shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given playlist
				icon: <EditIcon fontSize="small" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Edit',
				}),
				clickHandler: this.editPlaylist,
			},
			{
				shouldDisplay: (x) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (x.id === currentEditing.original.id) {
							return false;
						}
					}
					return true;
				},
				icon: <DeleteIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Delete',
				}),
				clickHandler: this.deletePlaylist,
				color: 'red',
			},
			{
				shouldDisplay: (playlist) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (playlist.id === currentEditing.original.id) {
							return true;
						}
					}
					return false;
				}, // hook func to verify that should the action be displayed for the given playlist
				icon: <SaveIcon fontSize="small" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Save',
				}),
				clickHandler: this.cardViewSave,
			},
			{
				shouldDisplay: (playlist) => {
					let currentEditing = this.state.editing;
					// const editing = currentEditing.original === cellInfo.original;
					if (currentEditing.original !== undefined) {
						if (playlist.id === currentEditing.original.id) {
							return true;
						}
					}
					return false;
				}, // hook func to verify that should the action be displayed for the given playlist
				// shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given playlist
				icon: <CancelIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Cancel',
				}),
				clickHandler: this.cancelEditing,
			},
		];
		this.searchColumnIdForCardView = ['name', 'departmentName', 'layout'];
		this.columnsGridView = [
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.TitleCol',
				}), // 'Title',
				editable: true,
				editableComp: 'text',
			},
			{
				accessor: (d) => d.noOfSlides,
				id: 'noOfSlides',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.NoOfItemsCol',
				}), //'No. of Items',
				editable: false,
				CustomCell: (cellInfo) => {
					let slidesTotal = cellInfo.noOfSlides + (cellInfo.noOfSlidesFromMaster || 0);
					return (
						<span>
							<Link
								variant="body1"
								className={this.props.classes.hoverCursor}
								underline="always"
								onClick={() => {
									this.props.history.push({
										pathname: ROUTES_PATH_SCREENMANAGER_SLIDES,
										search: `?playlistId=${encryptStr(cellInfo.id)}`,
										state: {
											playlistSearchState: this.state.filtered,
											playlistSortState: this.state.playlistSortState,
										},
									});
								}}
								title="Total slide count (own + master slides)"
							>
								{props.intl.formatMessage(
									{ id: 'pages.ScreenManager.components.Playlists.ViewNoOfItemText' },
									{ numberOfItems: slidesTotal }
								)}
							</Link>
							{` (${cellInfo.noOfSlides} + ${cellInfo.noOfSlidesFromMaster || 0})`}
						</span>
					);
				},
			},
			{
				accessor: (d) => d.duration,
				id: 'duration',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.DurationCol',
				}), //'Playlist total duration',
				editable: false,
			},
			{
				accessor: (d) => d.layout || '', // Orientation (portrait/landscape)
				id: 'layout',
				Header: (
					<div
						title={props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Playlists.LayoutCol',
						})}
					>
						<PortraitIcon className={this.props.classes.portraitIcon} />
						<LandscapeIcon className={this.props.classes.landscapeIcon} />
					</div>
				),
				editable: true,
				editableComp: 'radio',
				CustomCell: (cellInfo) =>
					cellInfo.layout === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />,
				onEditRadioValues: ['landscape', 'portrait'],
			},
			{
				accessor: (d) => d.departmentName || '',
				id: 'departmentName',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.DepartmentCol',
				}), //'Department',
				editable: true,
				editableComp: 'dropdown',
				dropdownKeyValue: 'departmentId',
				editableDropdownValue: this.props.screenManagerDepartments.departments,
				editableCustomComp: function autocreateComp() {
					return this.props.screenManagerDepartments.departments;
				},
			},
			{
				accessor: (d) => (d.isMaster ? 'Yes' : 'No'),
				id: 'isMaster',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Playlists.IsMasterCol',
				}), //'Is Master',
				editable: true,
				editableComp: 'radio',
				onEditRadioValues: ['Yes', 'No'],
			},
		];
	}

	filterAll = (e) => {
		const { value } = e.target;
		// const filterAll = value;
		const filtered = [{ id: 'all', value: value }];
		// NOTE: this completely clears any COLUMN filters
		this.setState({ filtered });
	};

	editPlaylist = (playlistData) => {
		this.setState({ editing: { original: playlistData } });
	};

	saveEditing = () => {
		let newPlaylistData = { ...this.state.editing };
		delete newPlaylistData.original;
		if (Object.keys(newPlaylistData).length === 0) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Playlists.NotifyErrorNoDataChange',
					})
				)
			);
		}

		return this.props.editScreenManagerPlaylist(
			{ playlistId: this.state.editing.original.id, reqData: newPlaylistData },
			({ error }) => {
				if (!error) {
					this.setState({ editing: {} });
				}
			}
		);
	};

	saveEditOnCardView = (newPlaylistData) => {
		if (Object.keys(newPlaylistData).length === 0) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Playlists.NotifyErrorNoDataChange',
					})
				)
			);
		}

		return this.props.editScreenManagerPlaylist(
			{ playlistId: this.state.editing.original.id, reqData: newPlaylistData },
			({ error }) => {
				if (!error) {
					this.setState({ editing: {} });
				}
			}
		);
	};

	cancelEditing = () => {
		this.setState({ editing: {} });
	};

	addPlaylist = () => {
		let { name, departmentId, layout, isMaster } = { ...this.state.adding };
		if (!name || !departmentId || !layout) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Playlists.NotifyErrorMissingRequiredData',
					})
				)
			);
		}

		let reqData = {
			name,
			departmentId,
			layout,
			isMaster: !!isMaster,
		};
		return this.props.addScreenManagerPlaylist({ reqData }, ({ error }) => {
			if (!error) {
				this.setState({ adding: {} });
				this.props.resetGlobalDialog();
				this.props.notifyGeneral(
					`(${name}) ${this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Playlists.NotifyGenAddPLSuccess',
					})}`,
					'success'
				);
			}
		});
	};

	openAddingPlaylistDialog = () => {
		// window.alert('add new screen');
		// size, title, content, confirmCB
		const { classes, intl } = this.props;
		let content = (
			<div>
				<TextField
					required
					id="playlistName"
					variant="standard"
					label={intl.formatMessage({
						id: 'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLNameLabel',
					})}
					className={classes.addingTextField}
					margin="normal"
					inputProps={{
						'aria-label': 'playlistName',
						style: { border: 'unset', boxShadow: 'unset', backgroundColor: 'unset' },
					}}
					onChange={(event) =>
						this.setState(
							{ adding: { ...this.state.adding, name: event.target.value } },
							this.openAddingPlaylistDialog
						)
					}
					placeholder={intl.formatMessage({
						id: 'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLNameLabel',
					})}
				/>

				<TextField
					id="playlistDepartmentAdd"
					select
					required
					label="Department"
					variant="standard"
					className={this.props.classes.addingTextField}
					value={this.state.adding.departmentId || ''}
					onChange={(event) =>
						this.setState(
							{ adding: { ...this.state.adding, departmentId: event.target.value } },
							this.openAddingPlaylistDialog
						)
					}
					helperText="Please select the department"
					margin="normal"
				>
					{_.sortBy(this.props.screenManagerDepartments.departments, [
						(dept) => dept.name.toLowerCase(),
					]).map((department) => (
						<MenuItem key={department.name} value={department.id}>
							{department.name}
						</MenuItem>
					))}
				</TextField>

				<FormControl className={classes.addingFormControl}>
					<FormLabel required>
						{intl.formatMessage({
							id: 'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLLayoutLabel',
						})}
					</FormLabel>
					<RadioGroup
						aria-label="layout"
						name="layout"
						className={this.props.classes.radioGroup}
						// value={this.state.adding.playerId || ''}
						onChange={(event) =>
							this.setState(
								{ adding: { ...this.state.adding, layout: event.target.value } },
								this.openAddingPlaylistDialog
							)
						}
						row
					>
						{['landscape', 'portrait'].map((val) => (
							<FormControlLabel key={val} value={val} control={<Radio />} label={val} />
						))}
					</RadioGroup>
				</FormControl>
				{this.props.authentication.userLevel >= MIN_USERLEVEL_MASTER_PLAYLIST_READ && (
					<FormControl className={classes.addingFormControl}>
						<FormLabel required>
							{intl.formatMessage({
								id: 'pages.ScreenManager.components.Playlists.AddPlaylistDialogPLIsMasterLabel',
							})}
						</FormLabel>
						<RadioGroup
							aria-label="isMaster"
							name="isMaster"
							className={this.props.classes.radioGroup}
							// value={this.state.adding.playerId || ''}
							onChange={(event) =>
								this.setState(
									{
										adding: {
											...this.state.adding,
											isMaster: event.target.value === 'Yes' ? true : false,
										},
									},
									this.openAddingPlaylistDialog
								)
							}
							row
						>
							{['Yes', 'No'].map((val) => (
								<FormControlLabel
									key={val}
									value={val}
									control={<Radio />}
									label={intl.formatMessage({ id: val === 'Yes' ? 'GENERAL.Yes' : 'GENERAL.No' })}
								/>
							))}
						</RadioGroup>
					</FormControl>
				)}
				<Button
					variant="contained"
					color="primary"
					className={classes.addingButton}
					onClick={this.addPlaylist}
				>
					{intl.formatMessage({ id: 'GENERAL.Save' })}
					<SaveIcon className={classes.rightIconInButton} />
				</Button>
			</div>
		);
		let addingPlaylistDialog = {
			size: 'sm',
			title: intl.formatMessage({
				id: 'pages.ScreenManager.components.Playlists.AddPlaylistDialogTitle',
			}),
			content: content,
			onClose: this.setState.bind(this, { adding: {} }),
		};
		this.props.openGlobalDialog(addingPlaylistDialog);
	};

	previewPlaylist = (playlistInfo = {}) => {
		this.props.getScreenManagerPlaylistSlide({ playlistId: playlistInfo.id }, (playlistData) => {
			let playlist;
			if (
				!playlistData ||
				!playlistData.result ||
				!playlistData.result.data ||
				!playlistData.result.data.id
			)
				return;
			playlist = playlistData.result.data || {};

			this.draggableDialogProps = {
				size: 'md',
				title: playlist.name + ' (Preview)',
				content: (
					<PlaylistPreview
						playlist={playlist}
						showControls
						showMasterSlides={this.state.previewDialogShowMaster}
						customStyle={{ height: '480px' }}
					/>
				),
				actions: [
					<Tooltip
						key="action1"
						title={this.props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Playlists.previewDialogShowMasterSwitchTooltip',
						})}
					>
						<FormControlLabel
							control={
								<Switch
									checked={this.state.previewDialogShowMaster}
									onChange={() => {
										// Flip the switch state and launch the dialog again in order to reflect the changes
										this.setState(
											{ previewDialogShowMaster: !this.state.previewDialogShowMaster },
											this.previewPlaylist.bind(this, this.draggableDialogProps.playlistInfo)
										);
									}}
									name="checkedB"
									color="primary"
								/>
							}
							label={this.props.intl.formatMessage({
								id: 'pages.ScreenManager.components.Playlists.previewDialogShowMasterSwitchLabel',
							})}
							className={this.props.classes.previewDialogMasterSwitch}
						/>
					</Tooltip>,
					<Button
						autoFocus
						color="primary"
						onClick={() => this.setState({ draggableDialog: false })}
						key="action2"
					>
						{this.props.intl.formatMessage({ id: 'GENERAL.Close' })}
					</Button>,
				],
				playlistInfo: playlistInfo,
				disableBackdropClick: false,
				disableEscapeKeyDown: false,
			};

			this.setState({ draggableDialog: true });
		});
	};

	deletePlaylist = (playlistData) => {
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Playlists.DelPlaylistDialogTitle',
			}),
			content: (
				<Typography variant="body1" gutterBottom style={{ minHeight: 50, marginTop: 25 }}>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Playlists.DelPlaylistDialogMessage"
							values={{
								PLName: (
									<Typography variant="h6" component="span" color="secondary">
										{playlistData.name}
									</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				this.props.deleteScreenManagerPlaylist({ playlistId: playlistData.id });
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};

	duplicatePlaylist = (playlistData) => {
		let self = this;
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Playlists.DuplicatePlaylistDialogTitle',
			}),
			content: (
				<Typography variant="body1" gutterBottom style={{ minHeight: 50, marginTop: 25 }}>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Playlists.DuplicatePlaylistDialogMessage"
							values={{
								PLName: (
									<Typography variant="h6" component="span" color="secondary">
										{playlistData.name}
									</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				self.props.duplicateScreenManagerPlaylist({ srcPlaylistId: playlistData.id });
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};

	// TODO
	cardViewSave = (playlistData, e, screenData) => {
		// let currentEditing = this.state.editing;
		this.setState({
			editing: {
				...playlistData,
				...screenData,
			},
		});
		// this.setState({ editing: { original: playlistData } });
		if (screenData.isMaster) {
			screenData.isMaster = screenData.isMaster === 'Yes' ? true : false;
		}
		this.saveEditOnCardView(screenData);
	};
	componentDidMount() {
		this.props.fetchScreenManagerPlaylists();
		this.props.fetchScreenManagerDepartments();
		this.updateScreenResolution();
		window.addEventListener('resize', this.updateScreenResolution);
	}

	componentWillUnmount() {
		this.props.resetScreenManagerPlaylists();
		this.props.resetScreenManagerPlaylistSlideAllStatus();
		window.removeEventListener('resize', this.updateScreenResolution);
	}

	updateScreenResolution() {
		this.setState({ isDesktop: window.innerWidth > 955 });
	}

	getTrComp = (props) => <RTTrComp {...props} />;

	render() {
		const { classes, screenManagerPlaylists, intl } = this.props;
		const { isFetching, playlists } = screenManagerPlaylists;
		let cardViewData = [];
		playlists.forEach((item) => {
			// console.log(item);
			cardViewData.push({
				...item,
				isMaster: item.isMaster ? 'Yes' : 'No',
			});
		});
		// TODO in VID-3618 ticket
		// const orderPriority = playlists
		// 	.map((o) => o.syncGroupId)
		// 	.reduce((map, syncGroupId, idx) => {
		// 		if (map[syncGroupId] == null) {
		// 			map[syncGroupId] = idx;
		// 		}
		// 		return map;
		// 	}, {});
		// console.log('@@@@@@@@@@@@@@@@@@@@@@', orderPriority);

		// // Now implement the sort based on the priorty map we created:
		// playlists.sort((a, b) => orderPriority[a.syncGroupId] - orderPriority[b.syncGroupId]);

		// console.log('LASTTT --->>', playlists);
		// TODO
		this.columnsGridView.forEach((item) => {
			if (item.editableComp === 'dropdown') {
				item.editableDropdownValue = this.props.screenManagerDepartments.departments;
			}
		});
		const isDesktop = this.state.isDesktop;
		return (
			<div className={classes.contentWrapper}>
				<Paper className={classes.header}>
					<Typography variant="h6" gutterBottom className={classes.title}>
						{intl.formatMessage({ id: 'pages.ScreenManager.components.Playlists.TableTitle' })}
						<Tooltip
							title={intl.formatMessage({
								id: 'pages.ScreenManager.components.Playlists.AddPlaylistTooltipTitle',
							})}
						>
							<Fab
								color="secondary"
								aria-label="Add"
								className={classes.addFab}
								onClick={this.openAddingPlaylistDialog}
							>
								<AddIcon />
							</Fab>
						</Tooltip>
						{/* <Tooltip title="Synchronised Playlist">
							<IconButton
								className={classes.settingButton}
								color="inherit"
								onClick={() => {
									this.props.history.push({
										pathname: ROUTES_PATH_SCREENMANAGER_PLAYLISTS_Synchronised,
										// search: `?playlistId=${encryptStr(cellInfo.original.id)}`,
									});
								}}
								aria-label="setting"
							>
								<VideoLoopIcon fontSize="large" color="inherit" className={classes.rotate} />
							</IconButton>
						</Tooltip> */}
						{
							/** large preview of message */
							this.props.authentication.userLevel >= MIN_USERLEVEL_To_Synchronise_Playlist &&
								isDesktop && (
									<Tooltip title="Synchronised Playlist">
										<IconButton
											className={classes.settingButton}
											color="inherit"
											onClick={() => {
												this.props.history.push({
													pathname: ROUTES_PATH_SCREENMANAGER_PLAYLISTS_Synchronised,
													// search: `?playlistId=${encryptStr(cellInfo.original.id)}`,
												});
											}}
											aria-label="setting"
										>
											<VideoLoopIcon fontSize="large" color="inherit" className={classes.rotate} />
										</IconButton>
									</Tooltip>
								)
						}
					</Typography>
					<Search
						placeholder={intl.formatMessage({ id: 'GENERAL.Search' })}
						className={classes.search}
						onChange={this.filterAll.bind(this)}
						onClearClick={() => {
							if (this.state.filtered.length === 0) return;
							this.setState({ filtered: [] });
						}}
						inputValue={this.state.filtered.length === 0 ? '' : this.state.filtered[0].value}
						// OnEnterKeyPressed={this.filterAll.bind(this)}
					/>
				</Paper>
				{!isDesktop ? (
					<ScreenManagerGridView
						cardViewData={cardViewData}
						columns={this.columnsGridView}
						previewHeight={130}
						screenActions={this.screenActions}
						editMode={this.state.editing}
						searchFilter={this.state.filtered}
						searchColumnId={this.searchColumnIdForCardView}
						noDataMsg={
							isFetching
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'ReactTable.NoDataText',
								  })
						}
					/>
				) : (
					<ReactTable
						// ref="playlistTable"
						// International Lang
						previousText={intl.formatMessage({ id: 'ReactTable.PreviousText' })}
						nextText={intl.formatMessage({ id: 'ReactTable.NextText' })}
						loadingText={intl.formatMessage({ id: 'ReactTable.LoadingText' })}
						noDataText={intl.formatMessage({ id: 'ReactTable.NoDataText' })}
						pageText={intl.formatMessage({ id: 'ReactTable.PageText' })}
						ofText={intl.formatMessage({ id: 'ReactTable.OfText' })}
						rowsText={intl.formatMessage({ id: 'ReactTable.RowsText' })}
						defaultFiltered={[]}
						filtered={this.state.filtered}
						// filtered={this.state.clickCountNew}
						// onFilteredChange={filtered => {
						// 	this.setState({ filtered });
						// }}
						TrComponent={this.getTrComp}
						// TdComponent={TdComponent}
						// manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={playlists}
						// pages={pages} // Display the total number of pages
						loading={isFetching} // Display the loading overlay when we need it
						// onFetchData={this.fetchBrandsData.bind(this)} // Request new data when things change
						// data={this.props.getProductsDataReducerState.productsData}
						multiSort={false}
						columns={this.columns}
						defaultPageSize={10}
						style={{
							height: '600px', // This will force the table body to overflow and scroll, since there is not enough room
						}}
						className="-striped -highlight"
						// eslint-disable-next-line no-unused-vars
						defaultFilterMethod={(filter, row, column) => {
							const id = filter.pivotId || filter.id;
							return row[id] !== undefined
								? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
								: true;
						}}
						TfootComponent={({ children, className, ...rest }) => (
							<div className={className} {...rest} style={{ width: '100%' }}></div>
						)}
						// eslint-disable-next-line no-unused-vars
						getTheadTrProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.tableHeadCell + ' ' + state.className,
								style: { alignItems: 'center' },
							};
						}}
						onSortedChange={(x) => {
							this.setState({ playlistSortState: x });
						}}
						defaultSorted={
							this.state.playlistSortState.length === 0 ? [] : this.state.playlistSortState
						}
						// eslint-disable-next-line no-unused-vars
						getTableProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.table + ' ' + state.className,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTrProps={(state, rowInfo, column, instance) => {
							if (!rowInfo) return {};
							let isOnEditing = rowInfo.original === this.state.editing.original;
							let disabled = true;
							if (rowInfo.original.syncGroupId === '') {
								disabled = false;
							}
							//  isDraggable = rowInfo.original.syncGroupId === '' ? true : false;
							return {
								style: {
									alignItems: 'center',
								},
								previewAction: !isOnEditing
									? this.previewPlaylist.bind(this, rowInfo.original)
									: undefined,
								duplicateAction:
									!isOnEditing && !rowInfo.original.isMaster
										? this.duplicatePlaylist.bind(this, rowInfo.original)
										: undefined,
								editAction: !isOnEditing
									? this.editPlaylist.bind(this, rowInfo.original)
									: undefined,
								deleteAction: !isOnEditing
									? this.deletePlaylist.bind(this, rowInfo.original)
									: undefined,
								saveAction: isOnEditing ? this.saveEditing : undefined,
								cancelAction: isOnEditing ? this.cancelEditing : undefined,
								disabled: disabled,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTdProps={(state, rowInfo, column, instance) => {
							// get TD (column) props
							// let editAction, editingUI;
							// if (rowInfo) {
							// 	switch (column.id) {
							// 		case 'name':
							// 		case 'restricted_to':
							// 		case 'metadata':
							// 			editingUI = <EditBrandMeta brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		case 'brand_logo':
							// 			editingUI = <EditingLogo brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		default:
							// 			break;
							// 	}
							// 	if (editingUI) editAction = () => this.setState({ editingUI: editingUI });
							// }
							return {
								// style: { whiteSpace: 'unset' },
								// className: classes.tableCell + ' ' + state.className,
								// editAction: editAction,
							};
						}}
					/>
				)}
				{
					// <Dialog open={this.state.addingScreenOpen} size='md' title='Enter the details for the new Screen:' content={this.state.addingScreenUI} onClose={this.onCloseAddingScreenDialog} />
				}
				{this.state.draggableDialog && (
					<DraggableMUIDialog
						open={this.state.draggableDialog}
						onClose={() => this.setState({ draggableDialog: false })}
						maxWidth={this.draggableDialogProps.size}
						fullWidth={true}
						disableBackdropClick={false}
						disableEscapeKeyDown={false}
						dragHandle='[id="draggableDialogTitle"]'
					>
						<DialogTitle id="draggableDialogTitle" className={classes.draggableDialogTitle}>
							{this.draggableDialogProps.title}
							<IconButton
								aria-label="Close"
								className={classes.draggableDialogCloseIcon}
								onClick={() => this.setState({ draggableDialog: false })}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>{this.draggableDialogProps.content}</DialogContent>
						<DialogActions>{this.draggableDialogProps.actions}</DialogActions>
					</DraggableMUIDialog>
				)}
			</div>
		);
	}
}

Playlists.propTypes = {
	classes: PropTypes.object.isRequired,
	screenManagerPlaylists: PropTypes.object.isRequired,
	screenManagerDepartments: PropTypes.object.isRequired,
	authentication: PropTypes.object.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	resetGlobalDialog: PropTypes.func.isRequired,
	fetchScreenManagerPlaylists: PropTypes.func.isRequired,
	deleteScreenManagerPlaylist: PropTypes.func.isRequired,
	duplicateScreenManagerPlaylist: PropTypes.func.isRequired,
	notifyError: PropTypes.func.isRequired,
	editScreenManagerPlaylist: PropTypes.func.isRequired,
	addScreenManagerPlaylist: PropTypes.func.isRequired,
	resetScreenManagerPlaylists: PropTypes.func.isRequired,
	fetchScreenManagerDepartments: PropTypes.func.isRequired,
	getScreenManagerPlaylistSlide: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	resetScreenManagerPlaylistSlideAllStatus: PropTypes.func.isRequired,
};

Playlists.defaultProps = {
	// multiple: false,
	// allowZip: false,
	// allowPreview: true,
	// disableClick: false,
};

const mapStateToProps = (state) => {
	return {
		screenManagerPlaylists: state.screenManagerPlaylists,
		authentication: state.authentication,
		screenManagerDepartments: state.screenManagerDepartments,
	};
};

export default connect(mapStateToProps, {
	openGlobalDialog,
	resetGlobalDialog,
	fetchScreenManagerPlaylists,
	deleteScreenManagerPlaylist,
	duplicateScreenManagerPlaylist,
	notifyError,
	notifyGeneral,
	editScreenManagerPlaylist,
	addScreenManagerPlaylist,
	resetScreenManagerPlaylists,
	fetchScreenManagerDepartments,
	getScreenManagerPlaylistSlide,
	resetScreenManagerPlaylistSlideAllStatus,
})(injectIntl(withStyles(PlaylistsStyle)(Playlists)));
