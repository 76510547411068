import React from 'react';
import PropTypes from 'prop-types';
import {
	Menu as MenuIcon,
	MoreVert as MoreVertMenuIcon,
	ViewList as ViewListMenuIcon,
} from '@mui/icons-material';
import {
	// withStyles,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Box,
} from '@mui/material';

import cx from 'classnames';

import useStylesHeaderbar from './HeaderBarStyle.jsx';

import HeaderLinks from './HeaderLinks';

function HeaderBar({ ...props }) {
	const classes = useStylesHeaderbar();
	return (
		<AppBar className={cx(classes.appBar, classes.domain)}>
			<Toolbar className={classes.container}>
				<Box component="span" sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
					{/* Desktop View */}
					<div className={classes.sidebarMinimize}>
						<IconButton
							disabled={props.disableSidebarToggle}
							className={classes.menuButton}
							aria-label="open drawer"
							color="inherit"
							onClick={props.sidebarMinimize}
						>
							{props.miniActive ? (
								<ViewListMenuIcon className={classes.sidebarMenuIcon} />
							) : (
								<MoreVertMenuIcon className={classes.sidebarMenuIcon} />
							)}
						</IconButton>
					</div>
				</Box>

				<Box component="span" sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
					{/* Mobile View */}
					<IconButton
						// edge='start'
						// style={{ float: 'right' }}
						disabled={props.disableSidebarToggle}
						className={cx(classes.menuButton)}
						color="inherit"
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}
					>
						<MenuIcon className={classes.sidebarMenuIcon} />
					</IconButton>
				</Box>

				<div className={classes.flex}>
					<Box component="span" sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
						{/* Desktop View. */}
						{/* Title or any text */}
						<Typography variant="h5" className={classes.title}>
							{
								// TODO: International Lang for domain title.
								// If we want to support domain title in different languages, we have to configure it in domain config per "locale"
								props.title
							}
						</Typography>
					</Box>
				</div>
				{/* Links in header */}
				<HeaderLinks history={props.history} />
			</Toolbar>
		</AppBar>
	);
}

HeaderBar.propTypes = {
	handleDrawerToggle: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired, // The global title in header
	disableSidebarToggle: PropTypes.bool,
	sidebarMinimize: PropTypes.func.isRequired,
	miniActive: PropTypes.bool.isRequired,
};

HeaderBar.defaultProps = {
	disableSidebarToggle: false,
};

export default HeaderBar;
