import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import MyFilesTable from '../MyFilesTable/MyFilesTable';
import MyFilesFilters from '../MyFilesFilters/MyFilesFilters';
import { MUITablePagination } from 'components';
// import { _, getS3FileParamsFromCloudFrontUrl, triggerDownload } from 'utils/libHelper';
import { encryptStr } from 'utils/appHelper';
import { _ } from 'utils/libHelper';

// MUI components
import { Backdrop, CircularProgress } from '@mui/material';

// intl lang
import { useIntl } from 'react-intl';

// custom hooks
import useColumnsConfig from '../../../Hooks/useColumnsConfig';

// restful
import { userManagerFetchMyFiles } from 'restful';

// redux
import { connect } from 'react-redux';
import { notifyGeneral, openGlobalDialog } from 'redux/actions'; // actions
// constants
import { ROUTES_PATH_MYFILES_MEDIAFILE_LIST } from 'routes';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		// paddingBottom: theme.spacing(1),
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateRows:
			'minmax(30px, max-content) minmax(30px, max-content) minmax(30px, max-content)',
		gridGap: theme.spacing(1, 1),
		gridTemplateAreas: `
		"filter"
		"content"
		"pagination"
		`,
	},
	filter: {
		gridArea: 'filter',
		// marginBottom: theme.spacing(1),
	},
	content: {
		gridArea: 'content',
		// position: 'relative',
		overflow: 'auto',
	},
	pagination: {
		gridArea: 'pagination',
		// marginTop0: theme.spacing(1),
	},
	backdropLoading: {
		zIndex: 999,
		position: 'absolute',
		// alignItems: 'flex-start',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		// color: theme.palette.primary.main,
		borderRadius: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	// loadingCircularProgress: {
	// 	position: 'sticky',
	// 	top: 'calc(50% - 20px)',
	// },
}));

function MyFilesList({ history, notifyGeneral, userId, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();

	const [sortBy, setSortBy] = React.useState([]);
	// rTableInst is the instance of rtable
	// const [rTableInst, setRTableInst] = React.useState(null);
	// myFiles data
	const [myFiles, setMyFiles] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	// pagination states
	const [pageIndex, setPageIndex] = React.useState(0);
	const [itemsPerPage, setItemsPerPage] = React.useState(10);
	const [totalNumItems, setTotalNumItems] = React.useState(-1);
	const [searchKeywords, setSearchKeywords] = React.useState('');

	// handle setting search keywords
	const handleSearchKeywords = (keywords) => {
		setSearchKeywords(keywords);
		setPageIndex(0);
		history.push({
			pathname: ROUTES_PATH_MYFILES_MEDIAFILE_LIST,
			// search: `?search=${keywords}}`,
			search: `?search=${keywords}&uid=${encryptStr(userId)}`,
		});
	};

	// handle page changes (prev or next page)
	const handleChangePage = (event, newPage) => {
		setPageIndex(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPageIndex(
			event.target.value > totalNumItems
				? 0
				: Math.ceil((pageIndex * itemsPerPage) / Number(event.target.value))
		);
		setItemsPerPage(Number(event.target.value));
	};

	// handle sortBy updates with delay
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSortByWithDelay = React.useCallback(
		_.debounce((newSortBy) => {
			setSortBy((prevSortBy) =>
				prevSortBy[0]?.id !== newSortBy[0]?.id || prevSortBy[0]?.desc !== newSortBy[0]?.desc
					? newSortBy
					: prevSortBy
			);
			setPageIndex(0);
		}, 600),
		[]
	);

	const handleGotoFileExplored = React.useCallback(
		(rowId) => {
			history.push({
				pathname: ROUTES_PATH_MYFILES_MEDIAFILE_LIST,
				search: `?date=${encryptStr(rowId.dateCreated)}&uid=${encryptStr(userId)}`,
			});
		},
		[history, userId]
	);

	// Get columns configure by use hook.
	// NB: parameter passed to useColumnsConfig must be memorized
	// it is used for sorting selection in filter component, and used as column configure in table view component
	const columnsConfigure = useColumnsConfig(handleGotoFileExplored);

	// GET api integration.
	React.useEffect(() => {
		setIsLoading(true);
		userManagerFetchMyFiles({
			queryParams: {
				uid: userId,
			},
		})
			.then((res) => {
				res.data.forEach((o, i) => (o.id = i + 1));
				setMyFiles(res.data);
				setTotalNumItems(res.data.length || -1);
			})
			.catch((err) => {
				notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [notifyGeneral, userId]);
	React.useEffect(() => {
		// initialize redux user data
		function descendingComparator(a, b, orderBy) {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
			return 0;
		}
		function getComparator(order, orderBy) {
			return order === 'desc'
				? (a, b) => descendingComparator(a, b, orderBy)
				: (a, b) => -descendingComparator(a, b, orderBy);
		}
		function stableSort(array, comparator) {
			const stabilizedThis = array.map((el, index) => [el, index]);
			stabilizedThis.sort((a, b) => {
				const order = comparator(a[0], b[0]);
				if (order !== 0) {
					return order;
				}
				return a[1] - b[1];
			});
			return stabilizedThis.map((el) => el[0]);
		}
		if (sortBy.length !== 0) {
			setMyFiles((prev) =>
				stableSort(prev, getComparator(sortBy[0].desc ? 'desc' : 'asc', sortBy[0].id))
			);
		}
	}, [sortBy]);
	let newMyFiles =
		itemsPerPage > 0
			? myFiles.slice(pageIndex * itemsPerPage, pageIndex * itemsPerPage + itemsPerPage)
			: myFiles;
	return (
		<div className={classes.root}>
			<div className={classes.filter}>
				<MyFilesFilters searchKeywords={searchKeywords} setSearchKeywords={handleSearchKeywords} />
			</div>
			<div className={classes.content}>
				{
					// <Backdrop className={classes.backdropLoading} open={isLoading}>
					// 	<CircularProgress color="primary" size={65} thickness={5.5} />
					// </Backdrop>
				}
				<MyFilesTable
					myFiles={newMyFiles}
					columnsConfigure={columnsConfigure}
					appliedSortBy={sortBy}
					handleSortBy={handleSortByWithDelay}
					noDataMsg={
						isLoading
							? intl.formatMessage({
									id: 'ReactTable.LoadingText',
							  })
							: intl.formatMessage({
									id: 'pages.MyFiles.components.MyFilesList.NoFilesMsg',
							  })
					}
				/>
			</div>
			{newMyFiles.length > 0 && (
				<div className={classes.pagination}>
					<MUITablePagination
						// pagination props
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						rowsPerPage={itemsPerPage}
						disableRowsPerPage={false}
						pageIndex={pageIndex}
						totalNumRows={totalNumItems}
						labelRowsPerPage={intl.formatMessage({
							id: 'pages.UserMessages.components.UserMessages.ItemsPerPageLabel',
						})}
					/>
				</div>
			)}

			{/** Circular progress loader */}
			<Backdrop className={classes.backdropLoading} open={isLoading}>
				<CircularProgress color="primary" size={65} thickness={5.5} />
			</Backdrop>
		</div>
	);
}

MyFilesList.propTypes = {
	// below are redux state
	userId: PropTypes.string.isRequired,
	// below are redux actions
	notifyGeneral: PropTypes.func.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
};

MyFilesList.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		// domainName: state.authentication.domainName,
		userId: state.authentication.userId,
	};
};
// export default MyFilesList;
export default connect(mapStateToProps, {
	notifyGeneral,
	openGlobalDialog,
})(MyFilesList);
