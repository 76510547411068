import {
	GET_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	GET_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_GETSTATUS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	ADD_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_DELETESTATUS,
} from 'redux/actions';
import {
	REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_REORDERSTATUS,
} from 'redux/actions';
import { RESET_SCREENMANAGER_PLAYLIST_SLIDE } from 'redux/actions';

import config from 'config';

const screenApiBaseUrl = config.screenAPI.baseUrl;
const authHeaderKey = config.screenAPI.authHeaderKey;

///////////////////////////// Playlist Slide /////////////////////////
// Get playlist slides
// opts: {
// 	playlistId: xxxxx,
// }
export const getScreenManagerPlaylistSlide = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${screenApiBaseUrl}/playlists/${opts.playlistId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		timeout: 300000,
	};
	return dispatch({
		types: [
			GET_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
			GET_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
			GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset get playlist (slide) status
export const resetScreenManagerPlaylistSlideGetStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_GETSTATUS,
});

// reset the whole playlist SLIDE data store (including editing status, adding status, etc.)
export const resetScreenManagerPlaylistSlideAllStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLIST_SLIDE,
});

// Re-order Slide
// opts: {
// 	playlistId: xxxxx,
// 	reqData: {}
// }
export const reorderScreenManagerPlaylistSlides = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/playlists/${opts.playlistId}`,
		method: 'patch',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
			REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
			REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// Reset reorder status
export const resetScreenManagerPlaylistReorderStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_REORDERSTATUS,
});

// Editing playlist slide
// opts: {
// 	playlistId: xxxxx,
//  slideId: xxxx,
// 	reqData: {}
// }
export const editScreenManagerPlaylistSlide = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/playlists/${opts.playlistId}/slides/${opts.slideId}`,
		method: 'patch',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
			EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
			EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// Reset slide editing status
export const resetScreenManagerPlaylistSlideEditStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_EDITSTATUS,
});

// Adding playlist slide
// opts: {
//  playlistId: xxxx,
// 	reqData: {}
// }
export const addScreenManagerPlaylistSlide = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/playlists/${opts.playlistId}/slides`,
		method: 'post',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			ADD_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
			ADD_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
			ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

export const resetScreenManagerPlaylistSlideAddStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_ADDSTATUS,
});

// Deleting slide from playlist
// opts: {
// playlistId: xxxx,
// slideId: xxxx
// }
export const deleteScreenManagerPlaylistSlide = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/playlists/${opts.playlistId}/slides/${opts.slideId}`,
		method: 'delete',
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
			DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
			DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		playlistId: opts.playlistId,
		afterSuccess: cb,
	});
};

export const resetScreenManagerPlaylistSlideDeleteStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_DELETESTATUS,
});
