import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { ART_VARIABLES } from '../../Constants';

import { _, moment } from 'utils/libHelper';
// custom components
import { DraggableMUIDialog } from 'components';

import {
	SimplifiedOutlinedTextField,
	StyledOutlinedTextFieldSelection,
} from '../CustomMUI/CustomMUI';

// MUI components
import {
	/* Fab, CircularProgress, Backdrop, Typography,  */ IconButton,
	// Popover,
	Collapse,
	Tooltip,
	Select,
	Input,
	Menu,
	MenuItem,
	Checkbox,
	ListItemText,
	// TextField,
	Typography,
	Button,
	// Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Badge,
	Divider,
} from '@mui/material';

import {
	Settings as SettingIcon,
	// Undo as UndoIcon,
	// Redo as RedoIcon,
	ZoomOut as ZoomOutIcon,
	ZoomIn as ZoomInIcon,
	// CloudDone as CloudSaveDoneIcon,
	// Close as CloseIcon,
	// Build as PreviewIcon,
	// Photo as BackgroundImageIcon,
	Highlight as FieldHighlightIcon,
	Save as SaveIcon,
	Edit as DesignIcon,
	Close as CloseIcon,
	Replay as ResetPrintQueueIcon,
	Print as PrintIcon,
	// PrintDisabled as PrintDisabledIcon,
	Queue as PrintQueueIcon,
	AddBox as AddToQueueIcon,
	Done as OkIcon,
	Reply as GoBackIcon,
} from '@mui/icons-material';
// import {
// 	/* CloudAlert as CloudSaveFailedIcon,  */ CloudSync as CloudSaveIcon,
// } from 'mdi-material-ui';

// import { CloudAlert as CloudSaveFailedIcon } from 'mdi-material-ui';
import { blueGrey, grey, lime } from '@mui/material/colors';
// import {
// 	DividerVer,
// 	DividerHor,
// 	SimpleSwitchVer,
// 	OutlinedNumberTextField,
// } from '../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

const wrapperBGColor = blueGrey[600];
const wrapperColor = grey[200];
const activeColor = lime[500];
const activeHoverColor = lime[700];
const useStyles = makeStyles((theme) => ({
	createActionWrapper: {
		// position: 'absolute',
		// // top: '50%',
		// // transform: 'translateY(-50%)',
		// top: 40, // ART_VARIABLES.cssStyles.actionRibbonHeight + 10,
		// right: 10,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		color: wrapperColor,
		backgroundColor: wrapperBGColor,
		borderRadius: ART_VARIABLES.cssStyles.createActionButtonWidth / 2,
		// '&:hover': {
		// 	backgroundColor: blueGrey[700],
		// },
	},
	openButton: {
		// position: 'absolute',
		// right: '40%',
		// top: '30%',
		// borderRadius: 'unset',
		width: ART_VARIABLES.cssStyles.createActionButtonWidth,
		height: ART_VARIABLES.cssStyles.createActionButtonHeight,
		padding: 0,
		'&:hover': {
			backgroundColor: blueGrey[700],
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around',
		minHeight:
			ART_VARIABLES.cssStyles.createActionMinHeight -
			ART_VARIABLES.cssStyles.createActionButtonHeight,
		width: ART_VARIABLES.cssStyles.createActionButtonWidth,
	},
	zoneWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: 'inherit',
		fontSize: 'inherit',
		width: '100%',
		// height: '33%',
		padding: `${theme.spacing(1)} 0px`,
	},
	controlButton: {
		padding: 2,
		color: 'inherit',
		margin: '2px 0px',
		borderRadius: 4,
		border: `1px solid rgba(0,0,0,0.15)`,
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
			border: `1px solid rgba(0,0,0,0.3)`,
		},
		'& svg': {
			fontSize: '1.75rem',
		},
	},
	constrolButtonActive: {
		color: activeColor,
		border: `1px solid ${activeColor}`,
		'&:hover': {
			color: activeHoverColor,
			border: `1px solid ${activeHoverColor}`,
		},
	},
	zoomButtons: {
		padding: 2,
		color: 'inherit',
		margin: '2px 0px',
		border: `1px solid rgba(0,0,0,0.15)`,
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
			border: `1px solid rgba(0,0,0,0.3)`,
		},
	},
	zoomButtonsIcon: {
		fontSize: 'inherit',
		backgroundColor: 'transparent',
	},
	zoomPercent: {
		border: `1px solid rgba(0,0,0,0.15)`,
		borderRadius: 15,
		width: ART_VARIABLES.cssStyles.createActionButtonWidth - 4, //'100%', //80
		padding: 2,
		margin: '2px 0px',
		color: 'inherit',
		fontSize: 'inherit',
		textAlign: 'center',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
			border: `1px solid rgba(0,0,0,0.3)`,
		},
	},
	// zoomSelectIcon: {
	// 	color: 'inherit',
	// },
	zoomSelectIconHidden: {
		display: 'none',
	},
	zoomSelectInputRoot: {
		color: 'inherit',
		fontSize: 'inherit',
		paddingLeft: theme.spacing(0.5),
	},
	dialogTitle: {
		margin: 0,
		padding: theme.spacing(1, 2),
		cursor: 'move',
		fontSize: theme.typography.h6.fontSize,
		fontWeight: 600,
	},
	dialogCloseButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	dialogContentTextField: {
		margin: 'auto',
		width: `100%`,
		color: 'inherit',
	},
	dialogButton: {
		margin: theme.spacing(0.5),
	},
	printQueueContentRoot: {
		fontSize: 'inherit',
	},
	printQueueSelectQtyWrap: {
		display: 'flex',
		alignItems: 'center',
	},
	printQueueSelection: {
		margin: theme.spacing(1, 2),
		width: '50%',
		fontSize: 'inherit',
	},
	printQueueDialogActionsRoot: {
		flex: '0 0 auto',
		display: 'flex',
		padding: theme.spacing(0.5, 0),
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	horDivider: {
		width: 'auto',
		alignSelf: 'stretch',
		margin: theme.spacing(0, 0.7),
		// backgroundColor: 'rgba(0, 0, 0, 0.32)',
		borderColor: 'rgba(255,255,255, 0.25)',
	},
	// // popover: {
	// // 	marginRight: 18,
	// // },
	// paper: {
	// 	padding: theme.spacing(1),
	// 	width: 300,
	// 	maxHeight: '70vh',
	// 	color: wrapperColor,
	// 	backgroundColor: wrapperBGColor,
	// 	// fontSize: '0.825rem',
	// 	// display: 'flex',
	// 	// flexDirection: 'column',
	// 	// marginRight: 18,
	// 	// transform: 'translateX(8px)',
	// },
	// titleText: {
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	margin: 'auto',
	// 	fontWeight: 600,
	// 	fontSize: theme.typography.pxToRem(16),
	// 	padding: '8px 0px 16px 0px',
	// },
	// contentWrapper: {
	// 	// margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
	// 	width: '100%', //`calc(100% - calc(${theme.spacing(2)} * 2))`,
	// 	height: '100%', // `calc(100% - ${titleHeight}px)`,
	// 	color: 'inherit',
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	flexDirection: 'column',
	// 	fontSize: '0.8rem',
	// },
	// groupRowDiv: {
	// 	width: '100%',
	// 	// padding: `0px ${theme.spacing(2)}`,
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'space-evenly',
	// 	padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	// },
	// infoName: {
	// 	width: '25%',
	// 	textAlign: 'right',
	// },
	// infoValue: {
	// 	width: '50%',
	// 	// textAlign: 'center',
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// },
	/**
	 * ----------------------------------------
	 * animation rotate
	 * ----------------------------------------
	 */
	'@keyframes rotate': theme.animations['@keyframes rotateCenter'],
	rotate: {
		animation: '$rotate 4s linear infinite',
	},
}));

function CreateActions({
	showDesignBtn,
	initFileName,
	artMode,
	// actions
	handleSaveNewArtwork,
	handleSaveToExistingArtwork,
	enablePrint,
	handlePrintNow,
	handlePrintQueue,
	handleAddToPrintQueue,
	handleResetPrintQueue,
	maxSpecifyQtyToPrintQueue,
	handleDesignTemplate,
	// zoom
	handleZoomOut,
	handleZoomIn,
	handleZoomSelect,
	zoom,
	// preview control
	// isPreviewEnabled,
	// togglePreview,
	// background image control
	// isBGImageEnabled,
	// toggleBGImage,
	goToDetailPage,
	// field highlight control
	isFieldHighlightEnabled,
	toggleFieldHighlight,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const [expanded, setExpanded] = React.useState(true);
	const [selectedZoom, setSelectedZoom] = React.useState({ value: 0 /* , label: 'fit' */ });
	// const [designModeMenuAnchor, setDesignModeMenuAnchor] = React.useState(null);
	const [saveMenuAnchor, setSaveMenuAnchor] = React.useState(null);
	// const anchorRef = React.useRef(null);
	// Popover dialog for saving & printing
	const [popDialog, setPopDialog] = React.useState({
		type: '', // possible values: 'SAVE', 'PRINT_QUEUE', ''.
		size: 'sm',
		title: '',
	});
	// state to store the selection of the number of files to be added to print queue
	const [numOfFilesToPrintQueue, setNumOfFilesToPrintQueue] = React.useState(1);
	const [totalNumOfFilesInPrintQueue, setTotalNumOfFilesInPrintQueue] = React.useState(0);
	// state of artwork file (pdf) name for saving & save as
	const [savePdfName, setSavePdfName] = React.useState('');
	// function to build file name for saving & save as
	const getSaveFileName = (filename, mode = 'CREATE') => {
		filename = filename.trim();
		if (mode === 'CREATE') {
			filename = filename + ' - ' + moment().format('DD-MM-YYYY');
		} else if (mode === 'EDIT') {
			let lastIndexOfDash = filename.lastIndexOf('-');
			let isLastCharNum = Number.isInteger(parseInt(filename[filename.length - 1]));
			if (filename.length - lastIndexOfDash < 4 && isLastCharNum) {
				// when last dash is within 4 char to the end, we treat it as number indexed file name
				filename =
					filename.substring(0, lastIndexOfDash).trim() +
					` - ${parseInt(filename[filename.length - 1]) + 1}`;
			} else {
				filename = `${filename} - 1`;
			}
		}
		return filename;
	};
	React.useEffect(() => {
		setSavePdfName(getSaveFileName(initFileName, artMode));
	}, [artMode, initFileName]);

	const resetPopDialog = () => {
		setPopDialog({
			type: '',
			size: 'sm',
			title: '',
		});
		setSavePdfName(getSaveFileName(initFileName, artMode));
		setNumOfFilesToPrintQueue(1);
	};
	const SavePdfDialogContentComp = (
		<SimplifiedOutlinedTextField
			label={null}
			className={classes.dialogContentTextField}
			fullWidth={true}
			onBlur={(e) => setSavePdfName(e.target.value || '')}
			value={savePdfName}
		/>
	);
	const SavePdfActionsComp = (
		<React.Fragment>
			<Button
				variant="contained"
				// color="primary"
				className={classes.dialogButton}
				size="small"
				startIcon={<CloseIcon />}
				onClick={() => resetPopDialog()}
			>
				{intl.formatMessage({ id: 'GENERAL.Cancel' })}
			</Button>
			<Button
				variant="contained"
				color="primary"
				className={classes.dialogButton}
				size="small"
				startIcon={<OkIcon />}
				onClick={() => {
					handleSaveNewArtwork(savePdfName);
					resetPopDialog();
				}}
			>
				{intl.formatMessage({ id: 'GENERAL.Ok' })}
			</Button>
		</React.Fragment>
	);

	const printQueueDialogContentComp = (
		<div className={classes.printQueueContentRoot}>
			<Typography>
				{intl.formatMessage({
					id: 'pages.Artwork.components.CreateActions.PrintQueueDialogQuestionText',
				})}
			</Typography>
			<div className={classes.printQueueSelectQtyWrap}>
				<Typography component="span">
					{intl.formatMessage({
						id: 'pages.Artwork.components.CreateActions.PrintQueueDialogQtyText',
					})}
				</Typography>
				<StyledOutlinedTextFieldSelection
					className={classes.printQueueSelection}
					value={numOfFilesToPrintQueue || ''}
					onChange={(e) => {
						setNumOfFilesToPrintQueue(Number(e.target.value));
					}}
					options={_.range(1, maxSpecifyQtyToPrintQueue + 1, 1).map((num) => ({
						label: num,
						value: num,
					}))}
				/>
			</div>
			{totalNumOfFilesInPrintQueue > 0 && (
				<Typography variant="inherit">
					{intl.formatMessage(
						{ id: 'pages.Artwork.components.CreateActions.PrintQueueDialogTotalNumText' },
						// eslint-disable-next-line react/display-name
						{ totalNumOfFilesInPrintQueue, bold: (chunks) => <strong>{chunks}</strong> }
					)}
				</Typography>
			)}
		</div>
	);

	const printQueueDialogActionsComp = (
		<div className={classes.printQueueDialogActionsRoot}>
			{
				// 	<Button
				// 	variant="contained"
				// 	color="primary"
				// 	className={classes.dialogButton}
				// 	size="small"
				// 	disabled={totalNumOfFilesInPrintQueue !== 0}
				// 	startIcon={totalNumOfFilesInPrintQueue !== 0 ? <PrintDisabledIcon /> : <PrintIcon />}
				// 	onClick={() => {
				// 		handlePrintNow(numOfFilesToPrintQueue);
				// 		resetPopDialog();
				// 	}}
				// >
				// 	{intl.formatMessage({
				// 		id: 'pages.Artwork.components.CreateActions.PrintQueueDialogPrintNowButtonText',
				// 	})}
				// </Button>
				// <Button
				// 	variant="contained"
				// 	color="primary"
				// 	className={classes.dialogButton}
				// 	size="small"
				// 	disabled={totalNumOfFilesInPrintQueue === 0}
				// 	startIcon={totalNumOfFilesInPrintQueue === 0 ? <PrintDisabledIcon /> : <PrintIcon />}
				// 	onClick={() => {
				// 		handlePrintQueue().then(() => setTotalNumOfFilesInPrintQueue(0));
				// 		resetPopDialog();
				// 	}}
				// >
				// 	{intl.formatMessage({
				// 		id: 'pages.Artwork.components.CreateActions.PrintQueueDialogPrintQueuedItemsButtonText',
				// 	})}
				// </Button>
			}
			{/* reset queue */}
			<Button
				variant="contained"
				color="secondary"
				className={classes.dialogButton}
				size="small"
				startIcon={<ResetPrintQueueIcon />}
				onClick={() => {
					handleResetPrintQueue();
					setTotalNumOfFilesInPrintQueue(0);
				}}
			>
				{intl.formatMessage({
					id: 'pages.Artwork.components.CreateActions.PrintQueueDialogResetPrintQueueButtonText',
				})}
			</Button>

			<Button
				variant="contained"
				color="primary"
				className={classes.dialogButton}
				size="small"
				startIcon={<AddToQueueIcon />}
				onClick={() => {
					handleAddToPrintQueue(numOfFilesToPrintQueue);
					setTotalNumOfFilesInPrintQueue(totalNumOfFilesInPrintQueue + numOfFilesToPrintQueue);
					resetPopDialog();
				}}
			>
				{intl.formatMessage({
					id: 'pages.Artwork.components.CreateActions.PrintQueueDialogAddToQueueButtonText',
				})}
			</Button>
			<Button
				variant="contained"
				// color="primary"
				className={classes.dialogButton}
				size="small"
				startIcon={<CloseIcon />}
				onClick={() => resetPopDialog()}
			>
				{intl.formatMessage({ id: 'GENERAL.Cancel' })}
			</Button>
		</div>
	);

	return (
		<div
			className={classes.createActionWrapper}
			// ref={anchorRef}
			// onMouseEnter={() => setOpen(true)}
			// onMouseLeave={() => setOpen(true)}
			{...rest}
		>
			<IconButton
				className={classes.openButton}
				color="inherit"
				// onClick={
				// 	() => setDesignSettingOpen(!open) /* open ? handlePopoverClose : handlePopoverOpen */
				// }
				onClick={() => setExpanded(!expanded)}
				// onMouseEnter={() => setOpen(true)}
				// onMouseLeave={() => setOpen(false)}
				aria-label="setting"
			>
				<SettingIcon fontSize="large" color="inherit" className={classes.rotate} />
			</IconButton>
			<Collapse in={expanded} timeout="auto">
				<div className={classes.content}>
					<Tooltip
						title={intl.formatMessage({
							id: 'pages.Artwork.components.CreateActions.goBackTooltip',
						})}
						placement="left"
						arrow
					>
						<IconButton onClick={goToDetailPage}>
							<GoBackIcon />
						</IconButton>
					</Tooltip>
					<div className={classes.zoneWrapper}>
						{
							// 	<Tooltip
							// 	title={intl.formatMessage({
							// 		id: 'pages.Artwork.components.BottomActionRibbon.previewTooltip',
							// 	})}
							// 	placement="left"
							// 	arrow
							// >
							// 	<IconButton
							// 		className={cx(classes.controlButton, {
							// 			[classes.constrolButtonActive]: isPreviewEnabled,
							// 		})}
							// 		onClick={togglePreview}
							// 	>
							// 		<PreviewIcon />
							// 	</IconButton>
							// </Tooltip>
						}
						{
							// <Tooltip
							// 	title={intl.formatMessage({
							// 		id: 'pages.Artwork.components.BottomActionRibbon.backgroundTooltip',
							// 	})}
							// 	placement="left"
							// 	arrow
							// >
							// 	<IconButton
							// 		className={cx(classes.controlButton, {
							// 			[classes.constrolButtonActive]: isBGImageEnabled,
							// 		})}
							// 		onClick={toggleBGImage}
							// 	>
							// 		<BackgroundImageIcon />
							// 	</IconButton>
							// </Tooltip>
						}
						<Tooltip
							title={intl.formatMessage({
								id: 'pages.Artwork.components.BottomActionRibbon.fieldsHighlightTooltip',
							})}
							placement="left"
							arrow
						>
							<IconButton
								className={cx(classes.controlButton, {
									[classes.constrolButtonActive]: isFieldHighlightEnabled,
								})}
								onClick={toggleFieldHighlight}
							>
								<FieldHighlightIcon />
							</IconButton>
						</Tooltip>
					</div>
					<Divider className={classes.horDivider} />
					{/** save controls */}
					<div className={classes.zoneWrapper}>
						<Tooltip
							title={intl.formatMessage({
								id: 'GENERAL.Save',
							})}
							placement="left"
							arrow
						>
							<IconButton
								className={cx(classes.controlButton, {
									// [classes.constrolButtonActive]: isFieldHighlightEnabled,
								})}
								onClick={(e) => {
									if (artMode === 'CREATE') {
										setPopDialog({
											...popDialog,
											type: 'SAVE',
											title: intl.formatMessage({
												id: 'pages.Artwork.components.CreateActions.SavePdfDialogTitleText',
											}),
										});
									} else if (artMode === 'EDIT') {
										setSaveMenuAnchor(e.currentTarget);
									}
								}}
							>
								<SaveIcon />
							</IconButton>
						</Tooltip>
						{enablePrint && (
							<Tooltip
								title={intl.formatMessage({
									id: 'GENERAL.Print',
								})}
								placement="left"
								arrow
							>
								<IconButton
									className={cx(classes.controlButton, {
										// [classes.constrolButtonActive]: isFieldHighlightEnabled,
									})}
									onClick={() => {
										if (handlePrintQueue && totalNumOfFilesInPrintQueue > 0) {
											handlePrintQueue().then((err) => {
												if (!err) setTotalNumOfFilesInPrintQueue(0);
											});
										} else {
											handlePrintNow(1);
										}
									}}
								>
									<PrintIcon />
								</IconButton>
							</Tooltip>
						)}
						{handlePrintQueue && (
							<Tooltip
								title={intl.formatMessage({
									id: 'pages.Artwork.components.CreateActions.PrintQueueButtonTooltip',
								})}
								placement="left"
								arrow
							>
								<IconButton
									className={cx(classes.controlButton, {
										// [classes.constrolButtonActive]: isFieldHighlightEnabled,
									})}
									onClick={() => {
										setPopDialog({
											...popDialog,
											type: 'PRINT_QUEUE',
											title: intl.formatMessage({
												id: 'pages.Artwork.components.CreateActions.PrintQueueDialogTitleText',
											}),
										});
									}}
								>
									<Badge badgeContent={totalNumOfFilesInPrintQueue} color="secondary">
										<PrintQueueIcon />
									</Badge>
								</IconButton>
							</Tooltip>
						)}
						{showDesignBtn && (
							<Tooltip
								title={intl.formatMessage({
									id: 'pages.Artwork.components.CreateActions.DesignButtonTooltip',
								})}
								placement="left"
								arrow
							>
								<IconButton
									className={cx(classes.controlButton, {
										// [classes.constrolButtonActive]: isFieldHighlightEnabled,
									})}
									// onClick={e => setDesignModeMenuAnchor(e.currentTarget)}
									onClick={(e) => handleDesignTemplate(e)}
								>
									<DesignIcon />
								</IconButton>
							</Tooltip>
						)}
					</div>
					<Divider className={classes.horDivider} />
					{/** zoom controls */}
					<div className={classes.zoneWrapper}>
						<IconButton
							className={classes.zoomButtons}
							onClick={() => {
								if (zoom <= ART_VARIABLES.minZoom) return;
								setSelectedZoom({}); // set selectedZoom to empty so that no option is selected
								handleZoomOut(Math.max(zoom - ART_VARIABLES.zoomStep, ART_VARIABLES.minZoom));
							}}
						>
							<ZoomOutIcon className={classes.zoomButtonsIcon} />
						</IconButton>
						<div className={classes.zoomPercent}>
							<Select
								value={typeof selectedZoom.value === 'number' ? selectedZoom.value : ''}
								variant="standard"
								disableUnderline
								classes={{
									icon: classes.zoomSelectIconHidden,
								}}
								onChange={(e) => {
									setSelectedZoom({
										value: Number(e.target.value),
										// label: e.currentTarget.dataset.label,
									});
									handleZoomSelect(Number(e.target.value));
								}}
								renderValue={() => `${Math.round(zoom * 100)}%`}
								input={<Input classes={{ root: classes.zoomSelectInputRoot }} />}
								displayEmpty
							>
								{ART_VARIABLES.zoomOptions.map((option) => (
									<MenuItem key={option.value} value={option.value} /* data-label={option.label} */>
										<Checkbox checked={selectedZoom.value === option.value} />
										<ListItemText primary={option.label} />
									</MenuItem>
								))}
							</Select>
						</div>
						<IconButton
							className={classes.zoomButtons}
							onClick={() => {
								if (zoom >= ART_VARIABLES.maxZoom) return;
								setSelectedZoom({}); // set selectedZoom to empty so that no option is selected
								handleZoomIn(Math.min(zoom + ART_VARIABLES.zoomStep, ART_VARIABLES.maxZoom));
							}}
						>
							<ZoomInIcon className={classes.zoomButtonsIcon} />
						</IconButton>
					</div>
				</div>
			</Collapse>
			{/* Design Mode menu (popover) */}
			{
				// <Menu
				// 	open={Boolean(designModeMenuAnchor)}
				// 	anchorEl={designModeMenuAnchor}
				// 	onClose={() => setDesignModeMenuAnchor(null)}
				// 	getContentAnchorEl={null}
				// 	anchorOrigin={{
				// 		vertical: 'center',
				// 		horizontal: 'left',
				// 	}}
				// 	transformOrigin={{
				// 		vertical: 'center',
				// 		horizontal: 'right',
				// 	}}
				// 	disableAutoFocusItem
				// >
				// 	<MenuItem onClick={() => null} style={{ fontSize: 'inherit' }}>
				// 		Modern
				// 	</MenuItem>
				// 	<MenuItem onClick={() => null} style={{ fontSize: 'inherit' }}>
				// 		Classic
				// 	</MenuItem>
				// </Menu>
			}
			{/* Save & Save As */}
			{
				<Menu
					open={Boolean(saveMenuAnchor)}
					anchorEl={saveMenuAnchor}
					onClose={() => setSaveMenuAnchor(null)}
					// getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'center',
						horizontal: 'right',
					}}
					disableAutoFocusItem
				>
					<MenuItem
						onClick={() => {
							setSaveMenuAnchor(null);
							handleSaveToExistingArtwork();
						}}
						style={{ fontSize: 'inherit' }}
					>
						{intl.formatMessage({ id: 'GENERAL.Save' })}
					</MenuItem>
					<MenuItem
						onClick={() => {
							setSaveMenuAnchor(null);
							setPopDialog({
								...popDialog,
								type: 'SAVE',
								title: intl.formatMessage({
									id: 'pages.Artwork.components.CreateActions.SavePdfDialogTitleText',
								}),
							});
						}}
						style={{ fontSize: 'inherit' }}
					>
						{intl.formatMessage({ id: 'GENERAL.SaveAs' })}
					</MenuItem>
				</Menu>
			}
			{/** Save/Print dialog */}
			{popDialog.type && (
				<DraggableMUIDialog
					disableBackdropClick
					disableEscapeKeyDown
					open={Boolean(popDialog.type)}
					maxWidth={popDialog.size}
					fullWidth={true}
					scroll="paper"
					dragHandle={`.${classes.dialogTitle}`}
				>
					<DialogTitle className={classes.dialogTitle} component="div">
						{popDialog.title}
						<IconButton
							aria-label="Close"
							className={classes.dialogCloseButton}
							size="small"
							onClick={() => resetPopDialog()}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</DialogTitle>
					<DialogContent dividers>
						{popDialog.type === 'SAVE'
							? SavePdfDialogContentComp
							: popDialog.type === 'PRINT_QUEUE'
							? printQueueDialogContentComp
							: null}
					</DialogContent>
					<DialogActions>
						{popDialog.type === 'SAVE'
							? SavePdfActionsComp
							: popDialog.type === 'PRINT_QUEUE'
							? printQueueDialogActionsComp
							: null}
					</DialogActions>
				</DraggableMUIDialog>
			)}

			{
				// <Popover
				// 	// className={classes.popover}
				// 	classes={{
				// 		paper: classes.paper,
				// 	}}
				// 	open={open}
				// 	anchorEl={anchorRef.current || null}
				// 	anchorOrigin={{
				// 		vertical: 'center',
				// 		horizontal: 'left',
				// 	}}
				// 	transformOrigin={{
				// 		vertical: 'top',
				// 		horizontal: 'right',
				// 	}}
				// 	container={anchorRef.current}
				// 	onClose={() => setDesignSettingOpen(false)}
				// 	disableRestoreFocus
				// >
				// 	<div className={classes.titleText}>
				// 		{intl.formatMessage({
				// 			id: 'pages.Artwork.components.DesignSetting.titleText',
				// 		})}
				// 	</div>
				// 	<DividerHor />
				// 	<div className={classes.contentWrapper}>
				// 		<div className={classes.groupRowDiv}>
				// 			{[
				// 				{
				// 					label: intl.formatMessage({
				// 						id: 'pages.Artwork.components.DesignSetting.htmlArtworkSwitchLabel',
				// 					}),
				// 					checked: designerTemplate.htmlArtwork,
				// 					handleChange: checked => handleTemplateInfoUpdate({ htmlArtwork: checked }),
				// 				},
				// 				{
				// 					label: intl.formatMessage({
				// 						id: 'pages.Artwork.components.DesignSetting.esignTemplateSwitchLabel',
				// 					}),
				// 					checked: designerTemplate.isEsignTemplate,
				// 					handleChange: checked => handleTemplateInfoUpdate({ isEsignTemplate: checked }),
				// 				},
				// 				{
				// 					label: intl.formatMessage({
				// 						id: 'pages.Artwork.components.DesignSetting.esignOnlySwitchLabel',
				// 					}),
				// 					checked: designerTemplate.isEsignOnly,
				// 					handleChange: checked => handleTemplateInfoUpdate({ isEsignOnly: checked }),
				// 				},
				// 			]
				// 				.map(item => (
				// 					<SimpleSwitchVer
				// 						key={item.label}
				// 						SwitchProps={{
				// 							color: 'secondary',
				// 							size: 'small',
				// 						}}
				// 						label={item.label}
				// 						style={{ padding: 0 }}
				// 						checked={item.checked}
				// 						onChange={(e, checked) => item.handleChange(checked)}
				// 					/>
				// 				))
				// 				.reduce((accu, elem, idx) => {
				// 					return accu.length === 0 ? [elem] : [...accu, <DividerVer key={idx} />, elem];
				// 				}, [])}
				// 		</div>
				// 		<DividerHor />
				// 		<div className={classes.groupRowDiv}>
				// 			<span className={classes.infoName}>
				// 				{intl.formatMessage({
				// 					id: 'pages.Artwork.components.DesignSetting.animationDurationText',
				// 				})}
				// 			</span>
				// 			<DividerVer />
				// 			<span className={classes.infoValue}>
				// 				{designerTemplate.fields.filter(f => f.animation.entrance).length > 0 ? (
				// 					<div
				// 						style={{
				// 							display: 'flex',
				// 							alignItems: 'center',
				// 							justifyContent: 'center',
				// 							width: '100%',
				// 						}}
				// 					>
				// 						<OutlinedNumberTextField
				// 							// className={classes.denseTextbox}
				// 							style={{ margin: 0, marginRight: 8 }}
				// 							label=""
				// 							value={designerTemplate.templateDuration}
				// 							step={1}
				// 							min={0}
				// 							max={30}
				// 							handleOnChange={num => handleTemplateInfoUpdate({ templateDuration: num })}
				// 						/>
				// 						{intl.formatMessage({
				// 							id: 'pages.Artwork.components.GENERAL.secondShort',
				// 						})}
				// 					</div>
				// 				) : (
				// 					<span>{intl.formatMessage({ id: 'GENERAL.N/A' })}</span>
				// 				)}
				// 			</span>
				// 		</div>
				// 	</div>
				// </Popover>
			}
		</div>
	);
}

CreateActions.propTypes = {
	/**
	 * if true, show "design" button for user to go to design page
	 * Default: false
	 */
	showDesignBtn: PropTypes.bool,
	// initial file name
	initFileName: PropTypes.string.isRequired,
	/**
	 * artwork creation mode
	 */
	artMode: PropTypes.oneOf(['CREATE', 'EDIT']).isRequired,
	/**
	 * Handle save new artwork action (also Save-As action)
	 *
	 * @param {string} fileName. file name for saving
	 */
	handleSaveNewArtwork: PropTypes.func.isRequired,
	/**
	 * Handle save to existing artwork action (only for EDIT mode)
	 *
	 * @param {null} no param
	 */
	handleSaveToExistingArtwork: PropTypes.func.isRequired,
	// enable "print" action
	enablePrint: PropTypes.bool.isRequired,
	/**
	 * Handle "print" action
	 * @param {number} Number of copies to print
	 */
	handlePrintNow: PropTypes.func,
	/**
	 * Handle "print queue" action
	 * NB: it is also used to control if showing the printQueue button
	 * @param {null} no param
	 *
	 * @returns {Promise} err. err is null if no error, otherwise err is Error
	 */
	handlePrintQueue: PropTypes.func,
	/**
	 * Handle "add to print queue" action
	 * @param {number}, number of the current file to be added to queue
	 */
	handleAddToPrintQueue: PropTypes.func,
	/**
	 * Handle "reset print queue" action
	 * @param {number}, number of the current file to be added to queue
	 */
	handleResetPrintQueue: PropTypes.func,
	/**
	 * max number when specify qty to add to print queue
	 */
	maxSpecifyQtyToPrintQueue: PropTypes.number.isRequired,
	/**
	 * Handle "go to design page" action
	 * @param {event}.
	 */
	handleDesignTemplate: PropTypes.func.isRequired,

	// zoom controls
	handleZoomOut: PropTypes.func.isRequired,
	handleZoomIn: PropTypes.func.isRequired,
	handleZoomSelect: PropTypes.func.isRequired,
	zoom: PropTypes.number.isRequired,

	// preview control
	// isPreviewEnabled: PropTypes.bool.isRequired,
	// togglePreview: PropTypes.func.isRequired,

	// Background image control
	// isBGImageEnabled: PropTypes.bool.isRequired,
	// toggleBGImage: PropTypes.func.isRequired,

	// go to detail page
	goToDetailPage: PropTypes.func.isRequired,

	// Field highlight control
	isFieldHighlightEnabled: PropTypes.bool.isRequired,
	toggleFieldHighlight: PropTypes.func.isRequired,
};

CreateActions.defaultProps = { showDesignBtn: false };
export default CreateActions;
