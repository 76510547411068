import {
	FETCH_SCREENMANAGER_SCREENS,
	FETCH_SCREENMANAGER_SCREENS_SUCCESS,
	FETCH_SCREENMANAGER_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCREENS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_SCREENS_REQUEST,
	EDIT_SCREENMANAGER_SCREENS_SUCCESS,
	EDIT_SCREENMANAGER_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCREENS_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_SCREENS_REQUEST,
	ADD_SCREENMANAGER_SCREENS_SUCCESS,
	ADD_SCREENMANAGER_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCREENS_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_SCREENS_REQUEST,
	DELETE_SCREENMANAGER_SCREENS_SUCCESS,
	DELETE_SCREENMANAGER_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCREENS_DELETESTATUS,
} from 'redux/actions';
import config from 'config';

const screenApiBaseUrl = config.screenAPI.baseUrl;
const authHeaderKey = config.screenAPI.authHeaderKey;

export const fetchScreenManagerScreens = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${screenApiBaseUrl}/players`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		timeout: 300000,
	};
	return dispatch({
		types: [
			FETCH_SCREENMANAGER_SCREENS,
			FETCH_SCREENMANAGER_SCREENS_SUCCESS,
			FETCH_SCREENMANAGER_SCREENS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the whole screens data store (including editing status, adding status, etc.)
export const resetScreenManagerScreens = () => ({
	type: RESET_SCREENMANAGER_SCREENS,
});

// Editing Screen (player)
// opts: {
// 	playerId: xxxxx,
// 	reqData: {}
// }
export const editScreenManagerScreen = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/players/${opts.playerId}`,
		method: 'patch',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			EDIT_SCREENMANAGER_SCREENS_REQUEST,
			EDIT_SCREENMANAGER_SCREENS_SUCCESS,
			EDIT_SCREENMANAGER_SCREENS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// Reset editing status
export const resetScreenManagerScreenEditStatus = () => ({
	type: RESET_SCREENMANAGER_SCREENS_EDITSTATUS,
});

// Adding Screen (player)
// opts: {
// 	reqData: {}
// }
export const addScreenManagerScreen = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/players`,
		method: 'post',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			ADD_SCREENMANAGER_SCREENS_REQUEST,
			ADD_SCREENMANAGER_SCREENS_SUCCESS,
			ADD_SCREENMANAGER_SCREENS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

export const resetScreenManagerScreenAddStatus = () => ({
	type: RESET_SCREENMANAGER_SCREENS_ADDSTATUS,
});

// Deleting Screen (player)
// opts: {
// playerId: xxxx
// }
export const deleteScreenManagerScreen = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/players/${opts.playerId}`,
		method: 'delete',
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			DELETE_SCREENMANAGER_SCREENS_REQUEST,
			DELETE_SCREENMANAGER_SCREENS_SUCCESS,
			DELETE_SCREENMANAGER_SCREENS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		playerId: opts.playerId,
		afterSuccess: cb,
	});
};

export const resetScreenManagerScreenDeleteStatus = () => ({
	type: RESET_SCREENMANAGER_SCREENS_DELETESTATUS,
});
