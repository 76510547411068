import config from 'config';
import { getDomainConfig } from 'utils/appHelper';
import { pageRoutes } from 'pages';

const basePath = config.basePath;

/* Routes Structure
 *
 	{
		path: `${basePath}/screen_manager`,  // Required. Unique key in routes. The path is also used for breadcrumbs (crumbtrails). We don't use param in path, but instead using query or hash so that the props.location.pathname is static
	//	exactMatch: true/false,			// No longer used. Optional. Specify if the path must be exactly matched (NOTE: we use exact in every route, so this field is not required)
		sidebarName: 'Screen Manager', // Optional. Specify it only when showing it in sidebar.
		sidebarIcon: screenMgr,	// Optional. Specify it only when showing it in sidebar.
	// navbarName: 'Screen Manager',	// No longer used. Optional. Specify it only when showing the page name in headerbar. Note: we use global navbar name configured in domain config
	//	key: ROUTES_SCREENMANAGER, // No longer used. Required. Unique key in routes
		component: ScreenManager,	// Optional. If no component in route, there will be no page rendered for the path and it will only be used as parent name of a section in sidebar
		requiredUserLevel: {$eq: value, $gt: value, $in: [], ..., $eq|$gt|$gte|$in|$lt|$lte|$ne|$nin: value|[]},	// Optional. The user level required to visit the route path. Use "OR" operation. {} or null/undefined means no user level required. Inspired by MongoDB Comparison Query Operators (https://docs.mongodb.com/manual/reference/operator/query-comparison/)
		requiredFeatures: ['BronzeScreen'],	// Optional. The required features enabled to access the route path. Use "OR" operation. [] or null/undefined means any user can access.
		//	isLoginNotRequired: true/false/undefined, // No longer used. Optional. Indicate if login is required to visit the page. If true, the route does not requires login. We treat undefined as false, so if false/undefined, the route requires login.
		noBreadcrumbs: true/false	// Optional. Indicate if we need to show breadcrumb trails.
		children: [],	// Optional. The child routes/paths.
		isPublic: true/false, // Optional. if not specified, default false
	}
 */

export const getRoutes = (opts = {}) => {
	const { domain } = opts;
	const domainConf = getDomainConfig(domain);
	// appRoutes contains the application level routes
	const appRoutes = [{ redirect: true, path: '/', to: `${basePath}${domainConf.homePath}` }];
	const domainPageRoutes = pageRoutes.filter((route) => {
		return !domainConf.disabledRoutes.some((regex) => {
			return new RegExp(regex).test(route.path);
		});
	});

	const routes = [...domainPageRoutes, ...appRoutes];
	return routes;
};

export const getPublicRoutes = () => {
	const publicRoutes = pageRoutes.filter((route) => {
		return route.isPublic;
	});

	return publicRoutes;
};

export const getRoutesByPath = (opts = {}) => {
	const routes = getRoutes(opts);

	let routesByPath = {};
	let setRoutesByPath = (routes = []) => {
		routes.forEach((route) => {
			// if(!route.redirect){
			routesByPath[route.path] = route;
			if (route.children && route.children.length > 0) {
				setRoutesByPath(route.children);
			}
			// }
		});
	};
	setRoutesByPath(routes);
	return routesByPath;
};
