import React from 'react';

import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

// intl lang
import { useIntl } from 'react-intl';

// MUI components
import { Box, Grid, Button, Typography, Paper, CircularProgress, Backdrop } from '@mui/material';
import jwt from 'jsonwebtoken';
import { externalSSOLogin } from 'restful';
import { BWG_BRAND_DOMAIN_MAPPER } from '../../Constants';
import { orange } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#464D69',
		position: 'relative',
		padding: theme.spacing(8),
		top: 0,
		left: 0,
		backgroundColor: orange[50],
	},
	paperRoot: {
		boxShadow: theme.customShadows.widgetDark,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		maxWidth: 404,
	},
	textRow: {
		marginBottom: theme.spacing(10),
		textAlign: 'center',
	},
	errorCode: {
		fontSize: 70,
		fontWeight: 600,
	},
	safetyText: {
		fontWeight: 300,
		color: theme.palette.text.hint,
	},
	backButton: {
		boxShadow: theme.customShadows.widget,
		textTransform: 'none',
		fontSize: 22,
	},
}));

function SSOLogin({ location, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();

	const [errorMsg, setErrorMsg] = React.useState(null);
	const [domainHomeUrl, setDomainHomeUrl] = React.useState(null);

	React.useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get('token');
		if (!token) return setErrorMsg('Missing user token');

		// request for login with sessionCode
		externalSSOLogin({
			token,
			bodyParams: {},
		})
			.then((res) => {
				const { loginUrl } = res.data;
				window.location.assign(loginUrl);
			})
			.catch((err) => {
				// get domain from bwg token
				const decodedToken = jwt.decode(token);
				if (!decodedToken) return setErrorMsg('Invalid token. Please contact to Admin');
				const domain = decodedToken.Domain.toLowerCase();

				if (!BWG_BRAND_DOMAIN_MAPPER[domain])
					return setErrorMsg(`Unknown domain, please contact to Admin`);

				let errMsg = `Something's wrong here...`;
				if (err.response?.status === 403) {
					errMsg = `Invalid Token.`;
				}

				// domain is valid, show error message and button to domain home page
				setDomainHomeUrl(BWG_BRAND_DOMAIN_MAPPER[domain]);
				setErrorMsg(errMsg);
			});
	}, [location.search]);

	return (
		<Box>
			<Backdrop className={classes.backdropLoading} open={!errorMsg}>
				<CircularProgress color="primary" size={65} thickness={5.5} />
			</Backdrop>
			{errorMsg && (
				<Grid container className={classes.container}>
					<Paper classes={{ root: classes.paperRoot }}>
						<Typography
							variant="h1"
							color="inherit"
							className={cx(classes.textRow, classes.errorCode)}
						>
							Whoops!
						</Typography>
						<Typography variant="h5" color="inherit" className={classes.textRow}>
							{errorMsg}
						</Typography>
						{domainHomeUrl && (
							<>
								<Typography
									variant="h6"
									color="textPrimary"
									className={cx(classes.textRow, classes.safetyText)}
								>
									{intl.formatMessage({ id: 'pages.NotFound.BackSafeMsg' })}
								</Typography>

								<Button
									variant="contained"
									color="secondary"
									className={classes.backButton}
									onClick={() => window.location.assign(domainHomeUrl)}
								>
									{intl.formatMessage({ id: 'pages.NotFound.BackHomeButtonText' })}
								</Button>
							</>
						)}
					</Paper>
				</Grid>
			)}
		</Box>
	);
}

SSOLogin.propTypes = {};

SSOLogin.defaultProps = {};

export default SSOLogin;
