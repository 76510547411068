import React from 'react';

// import cx from 'classnames';
import PropTypes from 'prop-types';

// custom components
import { ReactTableMUIView } from 'components';

// react-table hooks
import { useSortBy, useTable } from 'react-table';

function MyFilesTableView({
	myFiles,
	columnsConfigure,
	appliedSortBy,
	handleSortBy,
	// setRTableInst,
	noDataMsg,
	...rest
}) {
	// ##############################
	// React-Table (treat it as part of local states)
	// #############################
	// function to select rows that require highlight
	// console.log('DATA IN TABLE', myFiles);
	const rowHighlightPicker = (row) => row.isSelected;

	const tableInst = useTable(
		{
			columns: columnsConfigure,
			data: myFiles,
			initialState: { sortBy: appliedSortBy },
			manualSortBy: true,
			disableMultiSort: true,
			autoResetSortBy: false,
			getRowId: (rowData) => rowData.id,
		},
		useSortBy
	);
	React.useEffect(() => {
		handleSortBy(tableInst.state.sortBy);
	}, [handleSortBy, tableInst.state.sortBy]);

	// React.useEffect(() => {
	// 	setRTableInst(tableInst);
	// }, [setRTableInst, tableInst]);
	return (
		<ReactTableMUIView
			isStickyHeader={true}
			showBorder={true}
			// enableResizer={true}
			rowHighlightPicker={rowHighlightPicker}
			// table props
			getTableProps={tableInst.getTableProps}
			headerGroups={tableInst.headerGroups}
			prepareRow={tableInst.prepareRow}
			rows={tableInst.rows}
			noDataMsg={noDataMsg}
		/>
	);
}

MyFilesTableView.propTypes = {
	/**
	 * Array of my files.
	 	[
			 {
				 dateCreated: "xxxxxxxxxxxx",
				 numberOfFiles: "xxxx",
			 }
		 ]
	 */
	myFiles: PropTypes.array.isRequired,
	/**
	 * Array of table columns configure
	 */
	columnsConfigure: PropTypes.array.isRequired,
	/**
	 * sortBy array
	 * NB: it is used as initial state in rTable for sorting
	 */
	appliedSortBy: PropTypes.array.isRequired,
	/**
	 * handle sorting message
	 * @param {array} sortBy_in_rTableInst. it is tableInst.state.sortBy
	 */
	handleSortBy: PropTypes.func.isRequired,

	//  message when no files found
	noDataMsg: PropTypes.string,
};
MyFilesTableView.defaultProps = { /* selectedRowIds: [], */ noDataMsg: 'No Files!!' };
export default MyFilesTableView;
