/**
 * As we use React/Redux more and more, we have better understanding of the concept of Redux store:
 * Redux store supposes to be application level data state which can be used cross pages/components
 * Component local-state (by useState()) is the data state used within the component. The performance is better than Redux store in case of changing data
 * With Context and local-state, we can achieve same flexibility like Redux
 */
import config from 'config';
import { axiosRequest, Cookies } from 'utils/libHelper';

const fileZipperAPIBaseUrl = config.fileZipperAPI.baseUrl;
const fileZipperAuthHeaderKey = config.fileZipperAPI.authHeaderKey;
const authHeaderKey = config.fileZipperAPI.authHeaderKeyForLogging;
const lassoApiCookieKey = config.lassoApiCookieKey;

export const registerZipFile = (opts = {}) => {
	const { queryParams = {}, bodyParams = {} } = opts;
	let reqUrl = `${fileZipperAPIBaseUrl}/register`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[fileZipperAuthHeaderKey]: config.fileZipperAPI.authHeaderValue,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		params: { ...queryParams },
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

export const downloadFileLogging = (opts = {}) => {
	const { queryParams = {}, bodyParams = {} } = opts;
	let accessToken = Cookies.get(lassoApiCookieKey);
	let reqUrl = `${window.location.origin}/lassoapi/logging/`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: accessToken,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		params: { ...queryParams },
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};
