import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';

import { Home as HomeIcon } from '@mui/icons-material';

import { ROUTES_PATH_HOME } from 'routes';

const Home = loadable(() => import('./components/Home/Home'), {
	fallback: <Loading size={50} />,
});

const homeRoutes = [
	{
		path: ROUTES_PATH_HOME,
		sidebarName: <FormattedMessage id="pages.Home.Routes.Home" />,
		sidebarIcon: HomeIcon,
		component: Home,
	},
];
export default homeRoutes;
