// ##############################
// // // ResizeBox styles
// #############################
import { blueGrey, blue } from '@mui/material/colors';
import rotateIco from './rotate_cw.ico';
import makeStyles from '@mui/styles/makeStyles';
const dotBGColor = blueGrey[700];
const dotBGHoverColor = blue[500];
const borderColor = blueGrey[700];

const maxActionFontSize = 20;
const minActionFontSize = 15;
// const actionButtonBGColor = pink['A400'];

const DesignOverlayStyle = makeStyles((theme) => ({
	overlayWrapper: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		backfaceVisibility: 'hidden',
	},
	fieldBoxWrapper: {
		position: 'absolute',
		overflow: 'hidden',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
	resizeBoxWrapper: {
		backfaceVisibility: 'hidden',
		position: 'absolute',
		// zIndex: 999,

		// fontSmoothing: 'antialiased',
		// letterSpacing: 0,
		// display: 'inline-block',
		// verticalAlign: 'baseline',
	},
	circleDot: {
		position: 'absolute',
		width: 10,
		height: 10,
		// visibility: visible;
		// left: -5,
		// top: -5,
		borderRadius: 5,
		backgroundColor: dotBGColor,
		boxShadow: `rgb(255, 255, 255) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 0px 4px 2px`,
		'&:hover': {
			backgroundColor: dotBGHoverColor,
		},
	},
	nwCircleDot: {
		left: -5,
		top: -5,
	},
	neCircleDot: {
		// right: -5,
		left: ({ zoom, bounds }) => bounds.position.width * zoom - 5,
		top: -5,
	},
	swCircleDot: {
		left: -5,
		// bottom: -5,
		top: ({ zoom, bounds }) => bounds.position.height * zoom - 5,
	},
	seCircleDot: {
		// right: -5,
		left: ({ zoom, bounds }) => bounds.position.width * zoom - 5,
		// bottom: -5,
		top: ({ zoom, bounds }) => bounds.position.height * zoom - 5,
	},
	squareDot: {
		position: 'absolute',
		width: 10,
		height: 10,
		// left: '50%',
		//  top: 0,
		//  marginLeft: -5,
		//  marginTop: -5,
		backgroundColor: dotBGColor,
		boxShadow: `rgb(255, 255, 255) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 0px 4px 2px`,
		'&:hover': {
			backgroundColor: dotBGHoverColor,
		},
	},
	nSquareDot: {
		left: ({ zoom, bounds }) => bounds.position.width * 0.5 * zoom, //'50%',
		top: 0,
		marginLeft: -5,
		marginTop: -5,
	},
	eSquareDot: {
		// right: 0,
		left: ({ zoom, bounds }) => bounds.position.width * zoom, //0,
		top: ({ zoom, bounds }) => bounds.position.height * 0.5 * zoom, //'50%',
		// marginRight: -5,
		marginLeft: -5,
		marginTop: -5,
	},
	sSquareDot: {
		// bottom: 0,
		top: ({ zoom, bounds }) => bounds.position.height * zoom,
		left: ({ zoom, bounds }) => bounds.position.width * 0.5 * zoom, //'50%',
		marginLeft: -5,
		marginTop: -5,
		// marginBottom: -5,
	},
	wSquareDot: {
		left: 0,
		// top: '50%',
		top: ({ zoom, bounds }) => bounds.position.height * 0.5 * zoom,
		marginLeft: -5,
		marginTop: -5,
	},
	visiable: {
		visibility: 'visible',
	},
	hidden: {
		visibility: 'hidden',
	},

	rotateTextContainer: {
		position: 'absolute',
		width: 40,
		height: 20,
		// left: '50%',
		// top: -65,
		backgroundColor: '#000',
		color: '#fff',
		marginLeft: -20,
		borderRadius: 10,
		background: 'rgb(255, 255, 255)',
		boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 0px 4px 2px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	rotateContainer: {
		position: 'absolute',
		width: 20,
		height: 20,
		// left: '50%',
		// top: -35,
		marginLeft: -10,
		borderRadius: 10,
		background: 'rgb(255, 255, 255)',
		boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 0px 4px 2px',
		'&:hover': {
			// cursor: 'url(\'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18	" x="0px" y="0px" viewBox="0 0 214.367 214.367" fill="%23039be5"%3E%3Cpath d="M202.403,95.22c0,46.312-33.237,85.002-77.109,93.484v25.663l-69.76-40l69.76-40v23.494 c27.176-7.87,47.109-32.964,47.109-62.642c0-35.962-29.258-65.22-65.22-65.22s-65.22,29.258-65.22,65.22 c0,9.686,2.068,19.001,6.148,27.688l-27.154,12.754c-5.968-12.707-8.994-26.313-8.994-40.441C11.964,42.716,54.68,0,107.184,0 S202.403,42.716,202.403,95.22z"%3E%3C/path%3E%3C/svg%3E\') 10 10, pointer'
			cursor: `url(${rotateIco}) 10 10, pointer`,
		},
	},
	rotateIcon: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		marginLeft: -7,
		marginTop: -7,
		fill: dotBGColor,
		'&:hover': {
			fill: dotBGHoverColor,
		},
	},
	svgRotateLine: {
		// width: 1,
		// height: 25,
		// left: '50%',
		// top: -25,
		position: 'absolute',
		width: '100%',
		height: '100%',
		fill: 'none',
		stroke: borderColor,
		strokeWidth: '2',
		strokeDasharray: '5, 2',
	},
	svgRectBorder: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		fill: 'none',
		stroke: borderColor,
		strokeWidth: '2',
		strokeDasharray: '5, 2',
	},
	svgRectBorderSolid: {
		strokeDasharray: '5, 0',
	},
	actionsWrapper: {
		position: 'absolute',
		top: 0,
		// left: '100%',
		// padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(1.5),
		display: 'flex',
		flexDirection: 'column',
	},
	actionButton: {
		margin: '2px 0px',
		padding: 2,
		// color: 'inherit',
		backgroundColor: '#ffffff',
		'&:hover': {
			backgroundColor: '#ffffff',
		},
	},
	actionButtonIcon: {
		margin: 2,
		// color: actionButtonBGColor,
		fontSize: ({ zoom, bounds }) => {
			let fontSize = Math.round((bounds.position.height * zoom) / 4);
			return Math.max(Math.min(fontSize, maxActionFontSize), minActionFontSize);
		},
	},
	fieldBox: {
		position: 'absolute',
		// zIndex: 1,
		backgroundColor: 'rgba(96, 125, 139, .2)',
		'&:hover': {
			// opacity: 0.2,
			backgroundColor: 'rgba(96, 125, 139, .3)',
			border: `1px dashed ${borderColor}`,
		},
	},
	fieldBoxNoBGColor: {
		backgroundColor: 'unset',
	},
	fieldSelected: {
		backgroundColor: 'rgba(255, 213, 79, .2)',
		'&:hover': {
			// opacity: 0.2,
			backgroundColor: 'rgba(255, 213, 79, .3)',
		},
	},
	fieldBoxRectBorder: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		fill: 'none',
		stroke: borderColor,
		strokeWidth: '1',
		strokeDasharray: '3, 2',
	},
}));

export default DesignOverlayStyle;
