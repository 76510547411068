import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';

import { Button, OutlinedInput, InputAdornment, Popover, IconButton } from '@mui/material';
import {
	Done as ApplyIcon,
	Close as CloseIcon,
	Close as ClearInputIcon,
	DateRange as DateRangeIcon,
} from '@mui/icons-material';

import 'react-date-range/dist/styles.css'; // load react-date-range main style file
import 'react-date-range/dist/theme/default.css'; // load react-date-range theme css file
import { DefinedRange, DateRange, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import { startOfDay, endOfDay } from 'date-fns';
import { getDateFormatString } from 'utils/generalHelper';

// intl lang
import { useIntl } from 'react-intl';

// constant
const dateFormatString = getDateFormatString();

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	rangePickerContainer: {
		display: 'flex',
		'& select': {
			// TODO: This is to fix the conflicts with bootstrap in lasso app, can be removed after replacing lasso
			width: 'auto',
			height: 'auto',
		},
	},
	btnContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 2),
		gap: theme.spacing(1),
	},
	outlinedInputSmall: {
		padding: theme.spacing(1),
		'&:hover': {
			cursor: 'pointer',
		},
	},
	pointerCursor: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	iconBtnSmall: {
		padding: theme.spacing(0.5),
	},
}));

function DateRangeSelector({
	className,
	selectedRange,
	customStaticRanges,
	handleRangeChange,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const [datetimeRangePopOverAnchor, setDatetimeRangePopOverAnchor] = React.useState(null);
	const [dateRange, setDateRange] = React.useState({
		key: 'selection',
		...(selectedRange
			? selectedRange
			: {
					startDate: null,
					endDate: new Date(''),
			  }),
	});
	const [customEnabled, setCustomEnabled] = React.useState(false);

	return (
		<div className={cx(classes.root, { [className]: Boolean(className) })}>
			<OutlinedInput
				classes={{ input: classes.outlinedInputSmall }}
				type="text"
				label={''}
				notched={false}
				readOnly={true}
				fullWidth={true}
				placeholder={`${dateFormatString} ~ ${dateFormatString}`}
				value={
					selectedRange
						? `${selectedRange.startDate.toLocaleDateString()} ~ ${selectedRange.endDate.toLocaleDateString()}`
						: ''
				}
				onClick={(e) => {
					// reset customEnabled to false when open up the popover
					setCustomEnabled(false);
					// reset dateRange by selectedRange
					setDateRange({
						key: 'selection',
						...(selectedRange
							? selectedRange
							: {
									startDate: null,
									endDate: new Date(''),
							  }),
					});
					setDatetimeRangePopOverAnchor(e.currentTarget);
				}}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							className={classes.iconBtnSmall}
							aria-label="Clear Input"
							onClick={(e) => {
								e.stopPropagation();
								// setSelectedDateRange(null);
								setDateRange((prev) => ({ ...prev, startDate: null, endDate: new Date('') }));
								handleRangeChange(null);
							}}
							edge="end"
						>
							<ClearInputIcon fontSize="small" />
						</IconButton>
					</InputAdornment>
				}
				startAdornment={
					<InputAdornment position="start" className={classes.pointerCursor}>
						<DateRangeIcon fontSize="small" />
					</InputAdornment>
				}
			/>
			<Popover
				anchorEl={datetimeRangePopOverAnchor}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				// getContentAnchorEl={null}
				open={Boolean(datetimeRangePopOverAnchor)}
				// onClose={() => setDatetimeRangePopOverAnchor(null)}
			>
				{
					// 	<DateRangePicker
					// 	onChange={(ranges) => {
					// 		setDateRange(ranges.selection);
					// 		if (ranges.selection.startDate !== ranges.selection.endDate) {
					// 			// user has selected both startDate & endDate
					// 			let newDateRange = {
					// 				startDate: ranges.selection.startDate,
					// 				endDate: ranges.selection.endDate,
					// 			};
					// 			// setSelectedDateRange(dataRange);
					// 			setDatetimeRangePopOverAnchor(null);
					// 			handleRangeChange(newDateRange);
					// 		}
					// 	}}
					// 	startDatePlaceholder={`From`}
					// 	endDatePlaceholder={`To`}
					// 	showSelectionPreview={true}
					// 	moveRangeOnFirstSelection={false}
					// 	months={1}
					// 	staticRanges={customStaticRanges ? createStaticRanges(customStaticRanges) : undefined}
					// 	inputRanges={[]}
					// 	ranges={[dateRange]}
					// 	direction="horizontal"
					// />
				}
				<div className={classes.rangePickerContainer}>
					<DefinedRange
						onChange={(ranges) => {
							if (ranges.selection.startDate === null && ranges.selection.endDate === null) {
								// custom selection
								setDateRange({
									key: 'selection',
									startDate: startOfDay(new Date()),
									endDate: endOfDay(new Date()),
								});
								setCustomEnabled(true);
								return null;
							} else {
								setCustomEnabled(false);
								setDateRange(ranges.selection);
							}
						}}
						staticRanges={[
							...createStaticRanges(customStaticRanges || defaultStaticRanges),
							{
								isSelected() {
									return customEnabled;
								},
								label: 'Custom',
								range: () => ({ startDate: null, endDate: null }),
							},
						]}
						inputRanges={[]}
						ranges={[dateRange]}
					/>
					{customEnabled && (
						<DateRange
							onChange={(ranges) => {
								setDateRange(ranges.selection);
							}}
							startDatePlaceholder={`From`}
							endDatePlaceholder={`To`}
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							months={1}
							ranges={[dateRange]}
							direction="horizontal"
						/>
					)}
				</div>
				<div className={classes.btnContainer}>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<ApplyIcon />}
						disabled={dateRange.startDate === dateRange.endDate || dateRange.startDate === null}
						onClick={() => {
							setDatetimeRangePopOverAnchor(null);
							handleRangeChange(dateRange);
						}}
					>
						{intl.formatMessage({ id: 'GENERAL.Ok' })}
					</Button>
					<Button
						variant="contained"
						color="secondary"
						size="small"
						startIcon={<CloseIcon />}
						onClick={() => {
							setDatetimeRangePopOverAnchor(null);
						}}
					>
						{intl.formatMessage({ id: 'GENERAL.Cancel' })}
					</Button>
				</div>
			</Popover>
		</div>
	);
}

DateRangeSelector.propTypes = {
	/**
	 * Class Name apply to root <div>
	 */
	className: PropTypes.string,
	/**
	 * selected range of date.
	 */
	selectedRange: PropTypes.shape({
		startDate: PropTypes.instanceOf(Date),
		endDate: PropTypes.instanceOf(Date),
	}),

	/**
	 * Custom static ranges
	 */
	customStaticRanges: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired, // e.g. 'Last 6 Months',
			/**
		 *  range is function to return object of {startDate: Date, endDate: Date}
		 * () => ({
					startDate: subMonths(new Date(), 6),
					endDate: endOfDay(new Date()),
				}),
		 */
			range: PropTypes.func.isRequired,
		})
	),
	/**
		 * handle changes of selected date range. Could be null or object as below
	 	 {
			 startDate: Date,
			 endDate: Date
		 }
		 */
	handleRangeChange: PropTypes.func.isRequired,
};
DateRangeSelector.defaultProps = {};
export default DateRangeSelector;
