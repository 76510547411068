import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

// MUI components
import { Button, LinearProgress, Backdrop, Typography, Modal } from '@mui/material';
import { /* Reply as GoBackIcon, */ Cancel as CloseIcon } from '@mui/icons-material';
import { /* red, */ grey } from '@mui/material/colors';
import loader from './loader.gif';

const BorderLinearProgress = withStyles({
	root: {
		height: 10,
		// backgroundColor: lighten('#ff6c5c', 0.5),
	},
	bar: {
		borderRadius: 20,
		// backgroundColor: '#ff6c5c',
	},
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
	// actionButtonsWrapper: {
	// 	position: 'absolute',
	// 	bottom: 100,
	// 	right: 70,
	// 	display: 'flex',
	// 	justifyContent: 'center',
	// 	alignItems: 'center',
	// 	flexDirection: 'column',
	// },
	// backdrop: {
	// 	zIndex: 0,
	// 	color: grey[200],
	// 	display: 'flex',
	// 	flexDirection: 'column',
	// },
	contentWrap: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		position: 'fixed',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		color: grey[200],
		flexDirection: 'column',
	},
	button: {
		margin: theme.spacing(1),
	},
	linearProgress: {
		margin: theme.spacing(1),
		width: '550px',
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: '70vw',
		},
	},
	message: {
		margin: theme.spacing(1.5),
		fontSize: 16,
	},
	// failureMessage: {
	// 	color: red[500],
	// },
}));

function ArtLoading({
	message,
	failureHandler,
	progressLoaderFormat,
	open,
	failureButtonText,
	...rest
}) {
	const classes = useStyles();
	return (
		<Modal // `disableBackdropClick` is removed by codemod.
			// You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)
			BackdropComponent={Backdrop}
			open={open}
			disableEscapeKeyDown
		>
			<div className={classes.contentWrap}>
				{!failureHandler &&
					(progressLoaderFormat === 'gif' ? (
						<img src={loader} width={100} height={100} alt="loader" />
					) : (
						<BorderLinearProgress className={classes.linearProgress} />
					))}
				<Typography component="div" variant="body2" className={classes.message}>
					{message}
				</Typography>
				{failureHandler && (
					<Button
						variant="contained"
						color="secondary"
						className={cx(classes.button)}
						startIcon={<CloseIcon />}
						onClick={failureHandler}
					>
						{failureButtonText}
					</Button>
				)}
			</div>
		</Modal>
	);
}

ArtLoading.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	failureHandler: PropTypes.func,
	progressLoaderFormat: PropTypes.oneOf(['svg', 'gif']),
	open: PropTypes.bool.isRequired,
	failureButtonText: PropTypes.string,
	// closable: PropTypes.bool,
};

ArtLoading.defaultProps = {
	failureHandler: null,
	progressLoaderFormat: 'svg',
	failureButtonText: 'Close',
};
export default ArtLoading;
