import {
	FETCH_SCREENMANAGER_SCREENS,
	FETCH_SCREENMANAGER_SCREENS_SUCCESS,
	FETCH_SCREENMANAGER_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCREENS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_SCREENS_REQUEST,
	EDIT_SCREENMANAGER_SCREENS_SUCCESS,
	EDIT_SCREENMANAGER_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCREENS_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_SCREENS_REQUEST,
	ADD_SCREENMANAGER_SCREENS_FAIL,
	ADD_SCREENMANAGER_SCREENS_SUCCESS,
	RESET_SCREENMANAGER_SCREENS_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_SCREENS_REQUEST,
	DELETE_SCREENMANAGER_SCREENS_SUCCESS,
	DELETE_SCREENMANAGER_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCREENS_DELETESTATUS,
} from 'redux/actions';

const screenManagerScreensInitState = {
	isFetching: false,
	screens: [],
	addingStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	editingStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	deleteStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
};

export const screenManagerScreens = (state = screenManagerScreensInitState, action) => {
	switch (action.type) {
		case FETCH_SCREENMANAGER_SCREENS:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_SCREENMANAGER_SCREENS_SUCCESS:
			return {
				...state,
				isFetching: false,
				screens: action.result.data, // this is axios response data, result is the name in middleware, data is response.data in axios request(url) function
			};
		case FETCH_SCREENMANAGER_SCREENS_FAIL:
			if (action.error.response && action.error.response.status === 404) {
				return {
					...state,
					isFetching: false,
					screens: [],
				};
			} else {
				return {
					...state,
					isFetching: false,
				};
			}
		case RESET_SCREENMANAGER_SCREENS:
			return {
				...state,
				isFetching: false,
				screens: [],
				addingStatus: '',
				editingStatus: '',
				deleteStatus: '',
			};
		case EDIT_SCREENMANAGER_SCREENS_REQUEST:
			return {
				...state,
				editingStatus: 'PROCESSING',
			};
		case EDIT_SCREENMANAGER_SCREENS_SUCCESS: {
			let updatedScreen = { ...action.result.data };
			delete updatedScreen.scheduleQueue;
			return {
				...state,
				screens: state.screens.map((screen) => {
					if (screen.id === updatedScreen.id) {
						screen = updatedScreen;
					}
					return screen;
				}),
				editingStatus: 'OK',
			};
		}
		case EDIT_SCREENMANAGER_SCREENS_FAIL:
			return {
				...state,
				editingStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_SCREENS_EDITSTATUS:
			return {
				...state,
				editingStatus: '',
			};
		case ADD_SCREENMANAGER_SCREENS_REQUEST:
			return {
				...state,
				addingStatus: 'PROCESSING',
			};
		case ADD_SCREENMANAGER_SCREENS_FAIL:
			return {
				...state,
				addingStatus: 'FAILED',
			};
		case ADD_SCREENMANAGER_SCREENS_SUCCESS: {
			let screensClone = state.screens.slice(0);
			let newScreen = { ...action.result.data };
			delete newScreen.scheduleQueue;
			screensClone.unshift(newScreen);
			return {
				...state,
				screens: screensClone,
				addingStatus: 'OK',
			};
		}
		case RESET_SCREENMANAGER_SCREENS_ADDSTATUS:
			return {
				...state,
				addingStatus: '',
			};
		case DELETE_SCREENMANAGER_SCREENS_REQUEST:
			return {
				...state,
				deleteStatus: 'PROCESSING',
			};
		case DELETE_SCREENMANAGER_SCREENS_FAIL:
			return {
				...state,
				deleteStatus: 'FAILED',
			};
		case DELETE_SCREENMANAGER_SCREENS_SUCCESS: {
			return {
				...state,
				screens: state.screens.filter((screen) => screen.id !== action.playerId),
				deleteStatus: 'OK',
			};
		}
		case RESET_SCREENMANAGER_SCREENS_DELETESTATUS:
			return {
				...state,
				deleteStatus: '',
			};
		default:
			return state;
	}
};
