import {
	FETCH_ARTWORK_FONTS,
	FETCH_ARTWORK_FONTS_SUCCESS,
	FETCH_ARTWORK_FONTS_FAIL,
	RESET_ARTWORK_FONTS,
	FETCH_ARTWORK_SPREADSHEETS,
	FETCH_ARTWORK_SPREADSHEETS_SUCCESS,
	FETCH_ARTWORK_SPREADSHEETS_FAIL,
	RESET_ARTWORK_SPREADSHEETS,
	FETCH_ARTWORK_LISTS,
	FETCH_ARTWORK_LISTS_SUCCESS,
	FETCH_ARTWORK_LISTS_FAIL,
	RESET_ARTWORK_LISTS,
	FETCH_ARTWORK_AUTOIMPORT,
	FETCH_ARTWORK_AUTOIMPORT_SUCCESS,
	FETCH_ARTWORK_AUTOIMPORT_FAIL,
	RESET_ARTWORK_AUTOIMPORT,
	FETCH_ARTWORK_OUTPUTTEMPLATES,
	FETCH_ARTWORK_OUTPUTTEMPLATES_SUCCESS,
	FETCH_ARTWORK_OUTPUTTEMPLATES_FAIL,
	RESET_ARTWORK_OUTPUTTEMPLATES,
	FETCH_ARTWORK_CATEGORIES,
	FETCH_ARTWORK_CATEGORIES_SUCCESS,
	FETCH_ARTWORK_CATEGORIES_FAIL,
	RESET_ARTWORK_CATEGORIES,
} from 'redux/actions';

import config from 'config';

// const artworkAPIBaseUrl = config.filemanagerAPI.baseUrl;
// const authHeaderKey = config.filemanagerAPI.authHeaderKey;

const artworkAPIBaseUrl = config.filemanagerAPI.baseUrl;
// const artworkAPIBaseUrl = `${config.filemanagerAPI.baseUrl}/artwork`;
const authHeaderKey = config.filemanagerAPI.authHeaderKey;

// fetch font list for artwork
// eslint-disable-next-line no-unused-vars
export const fetchArtworkFonts = (opts, cb) => (dispatch, getState) => {
	// TODO: we may keep the session key in request header rather than in query
	// let sessionKey = Cookies.get(config.lassoAPI.sessionKeyInCookie);
	let reqUrl = `${artworkAPIBaseUrl}/fonts`; //?-session=Site:${sessionKey}`;
	// let reqUrl = `${artworkAPIBaseUrl}/fonts/?${authHeaderKey}=${sessionKey}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token, //`Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName },
		timeout: 30000,
	};
	return dispatch({
		types: [FETCH_ARTWORK_FONTS, FETCH_ARTWORK_FONTS_SUCCESS, FETCH_ARTWORK_FONTS_FAIL],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the fonts data store
export const resetArtworkFonts = () => ({
	type: RESET_ARTWORK_FONTS,
});

// fetch spreadsheet list for artwork
// eslint-disable-next-line no-unused-vars
export const fetchArtworkSpreadsheets = (opts, cb) => (dispatch, getState) => {
	// TODO: we may keep the session key in request header rather than in query
	// let sessionKey = Cookies.get(config.lassoAPI.sessionKeyInCookie);
	let reqUrl = `${artworkAPIBaseUrl}/spreadsheets`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token, //`Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName },
		timeout: 30000,
	};
	return dispatch({
		types: [
			FETCH_ARTWORK_SPREADSHEETS,
			FETCH_ARTWORK_SPREADSHEETS_SUCCESS,
			FETCH_ARTWORK_SPREADSHEETS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the fonts data store
export const resetArtworkSpreadsheets = () => ({
	type: RESET_ARTWORK_SPREADSHEETS,
});

// fetch lists for artwork
// eslint-disable-next-line no-unused-vars
export const fetchArtworkLists = (opts, cb) => (dispatch, getState) => {
	// TODO: we may keep the session key in request header rather than in query
	// let sessionKey = Cookies.get(config.lassoAPI.sessionKeyInCookie);
	let reqUrl = `${artworkAPIBaseUrl}/lists`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token, //`Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName },
		timeout: 30000,
	};
	return dispatch({
		types: [FETCH_ARTWORK_LISTS, FETCH_ARTWORK_LISTS_SUCCESS, FETCH_ARTWORK_LISTS_FAIL],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the fonts data store
export const resetArtworkLists = () => ({
	type: RESET_ARTWORK_LISTS,
});

// fetch auto-import for artwork
// eslint-disable-next-line no-unused-vars
export const fetchArtworkAutoImport = (opts, cb) => (dispatch, getState) => {
	// TODO: we may keep the session key in request header rather than in query
	// let sessionKey = Cookies.get(config.lassoAPI.sessionKeyInCookie);
	let reqUrl = `${artworkAPIBaseUrl}/artwork/autoimport`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token, //`Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName },
		timeout: 30000,
	};
	return dispatch({
		types: [
			FETCH_ARTWORK_AUTOIMPORT,
			FETCH_ARTWORK_AUTOIMPORT_SUCCESS,
			FETCH_ARTWORK_AUTOIMPORT_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the fonts data store
export const resetArtworkAutoImport = () => ({
	type: RESET_ARTWORK_AUTOIMPORT,
});

// fetch output templates for artwork
// eslint-disable-next-line no-unused-vars
export const fetchArtworkOutputTemplates = (opts, cb) => (dispatch, getState) => {
	// TODO: we may keep the session key in request header rather than in query
	// let sessionKey = Cookies.get(config.lassoAPI.sessionKeyInCookie);
	let reqUrl = `${artworkAPIBaseUrl}/artwork/outputtemplates`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token, //`Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName },
		timeout: 30000,
	};
	return dispatch({
		types: [
			FETCH_ARTWORK_OUTPUTTEMPLATES,
			FETCH_ARTWORK_OUTPUTTEMPLATES_SUCCESS,
			FETCH_ARTWORK_OUTPUTTEMPLATES_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the fonts data store
export const resetArtworkOutputTemplates = () => ({
	type: RESET_ARTWORK_OUTPUTTEMPLATES,
});

// fetch categories for artwork
// eslint-disable-next-line no-unused-vars
export const fetchArtworkCategories = (opts, cb) => (dispatch, getState) => {
	// TODO: we may keep the session key in request header rather than in query
	// let sessionKey = Cookies.get(config.lassoAPI.sessionKeyInCookie);
	let reqUrl = `${artworkAPIBaseUrl}/categories`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token, //`Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName },
		timeout: 30000,
	};
	return dispatch({
		types: [
			FETCH_ARTWORK_CATEGORIES,
			FETCH_ARTWORK_CATEGORIES_SUCCESS,
			FETCH_ARTWORK_CATEGORIES_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the categories in artwork data store
export const resetArtworkCategories = () => ({
	type: RESET_ARTWORK_CATEGORIES,
});
