import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import { Popover, TextField, IconButton } from '@mui/material';
import { Done as OKIcon, Close as CancelIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
	displayText: {
		borderBottom: `dashed 1px ${theme.palette.primary.light}`,
		whiteSpace: 'pre-wrap',
		color: theme.palette.primary.main,
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
	emptyText: {
		color: theme.palette.error.main,
		fontStyle: 'italic',
		'&:hover': {
			color: theme.palette.error.dark,
		},
	},
	// highlight: {
	// 	color: 'inherit',
	// 	borderBottom: `dashed 1px currentColor`,
	// 	'&:hover': {
	// 		color: 'inherit',
	// 	},
	// },
	popoverPaper: {
		display: 'flex',
		padding: theme.spacing(2),
	},
	textField: {
		// margin: theme.spacing(2),
	},
	buttonWrapper: {
		padding: theme.spacing(1),
	},
	button: {
		border: `1px solid rgba(0,0,0,0.2)`,
		margin: theme.spacing(0, 0.5),
	},
	buttonOk: {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		'&:hover, &.Mui-focusVisible': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
}));

function EditableTextCell({ value, onCommitted, /* isHighlight, */ ...rest }) {
	const classes = useStyles();
	const [anchorElem, setAnchorElem] = React.useState(null);
	const [editValue, setEditValue] = React.useState(value);

	React.useEffect(() => {
		setEditValue(value);
	}, [value]);

	return (
		<div>
			<span
				className={cx(classes.displayText, {
					[classes.emptyText]: !value,
					// [classes.highlight]: isHighlight,
				})}
				onClick={(e) => setAnchorElem(e.target)}
			>
				{value || 'Empty'}
			</span>
			<Popover
				PaperProps={{ className: classes.popoverPaper }}
				open={Boolean(anchorElem)}
				anchorEl={anchorElem}
				onClose={() => setAnchorElem(null)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<TextField
					className={classes.textField}
					label="Edit Value"
					multiline
					rows={4}
					size="small"
					inputProps={{ style: { resize: 'both' } }}
					value={editValue}
					onChange={(e) => setEditValue(e.target.value)}
					variant="outlined"
				/>
				<div className={classes.buttonWrapper}>
					<IconButton
						// edge="end"
						// color="primary"
						className={cx(classes.button, classes.buttonOk)}
						size="small"
						aria-label="ok"
						onClick={() => {
							if (value !== editValue) onCommitted(editValue);
							setAnchorElem(null);
						}}
					>
						<OKIcon fontSize="small" className={classes.icon} />
					</IconButton>
					<IconButton
						// edge="start"
						className={cx(classes.button)}
						color="inherit"
						size="small"
						aria-label="cancel"
						onClick={() => {
							setAnchorElem(null);
						}}
					>
						<CancelIcon fontSize="small" className={classes.icon} />
					</IconButton>
				</div>
			</Popover>
		</div>
	);
}

EditableTextCell.propTypes = {
	onCommitted: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	// /**
	//  * if true, the row is highlighted
	//  */
	// isHighlight: PropTypes.bool,
};

EditableTextCell.defaultProps = {};

export default EditableTextCell;
