import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { _ } from 'utils/libHelper';
// MUI components
import { FormControlLabel, Checkbox, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ToggleButton from '@mui/material/ToggleButton';

import {
	FormatAlignLeft as FormatAlignLeftIcon,
	FormatAlignCenter as FormatAlignCenterIcon,
	FormatAlignRight as FormatAlignRightIcon,
	ImageSearch as ImageSearchIcon,
	Clear as ClearIcon,
} from '@mui/icons-material';

import {
	FormatVerticalAlignTop as FormatVerticalAlignTopIcon,
	FormatVerticalAlignCenter as FormatVerticalAlignCenterIcon,
	FormatVerticalAlignBottom as FormatVerticalAlignBottomIcon,
} from 'mdi-material-ui';

// Constants
import { ART_VARIABLES } from '../../../Constants';
import {
	SimpleRadioVer,
	StyledToggleButtonGroup,
	StyledOutlinedTextFieldSelection,
	// SimpleSwitchVer,
	SimpleSwitchRow,
	SimpleNumberSlider,
	DividerVer,
	DividerHor,
	StyledContainedButton,
	// StyledOutlinedTextField,
} from '../../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

const useStylePaletteStyles = makeStyles((theme) => ({
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	twoColumnsRowDiv: {
		justifyContent: 'space-evenly',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	imageSizing: {
		width: '70%',
		margin: 0,
	},
	alignUserDefinedFormControlLabel: {
		margin: 0,
	},
	alignUserDefinedFormControlLabelText: {
		fontSize: 'inherit',
		textAlign: 'center',
	},
	alignUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
	toggleGroupSmall: {
		width: 30,
		height: 30,
	},
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
}));

export const PdfPaletteStyles = ({ field, updateField, setColorPicker, ...rest }) => {
	const classes = useStylePaletteStyles();
	const intl = useIntl();
	return (
		<div className={classes.tabWrapper}>
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.PdfPalette.anchorText',
					})}
				</InputLabel>
				<div className={classes.groupRowDiv} style={{ justifyContent: 'space-evenly' }}>
					{[
						{
							label: intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.PdfPalette.anchorBottomText',
							}),
							value: 'bottom',
						},
						{
							label: intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.PdfPalette.anchorTopText',
							}),
							value: 'top',
						},
					].map((anchor) => (
						<SimpleRadioVer
							key={anchor.value}
							checked={field.anchor === anchor.value}
							onChange={(e) => updateField({ anchor: e.target.value || '' })}
							value={anchor.value}
							label={anchor.label}
						/>
					))}
				</div>
			</div>
			{
				// <div className={classes.groupsDiv}>
				// 	<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
				// 		{intl.formatMessage({
				// 			id: 'pages.Artwork.components.ElementPalette.PdfPalette.pdfSizingText',
				// 		})}
				// 	</InputLabel>
				// 	<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
				// 		<StyledOutlinedTextFieldSelection
				// 			className={classes.imageSizing}
				// 			label=""
				// 			value={field.sizing || ''}
				// 			onChange={e => updateField({ sizing: e.target.value || '' })}
				// 			smallMenu
				// 			smallIcon
				// 			// noneOption
				// 			options={ART_VARIABLES.imageSizingOptions}
				// 		/>
				// 		<DividerVer />
				// 		<SimpleSwitchVer
				// 			label="Clip Image"
				// 			style={{ padding: 0 }}
				// 			checked={field.sizingClipImage}
				// 			onChange={(e, checked) => updateField({ sizingClipImage: checked })}
				// 		/>
				// 	</div>
				// </div>
			}

			<DividerHor />
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.horAlignText',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledToggleButtonGroup
						value={field.horizontalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={(e, alignment) =>
							updateField({
								horizontalAlign: alignment || ART_VARIABLES.defaultFields.pdf.horizontalAlign,
							})
						}
						aria-label="pdf horizontal alignment"
					>
						<ToggleButton value="left" aria-label="left aligned">
							<FormatAlignLeftIcon />
						</ToggleButton>
						<ToggleButton value="center" aria-label="centered horizontal">
							<FormatAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="right" aria-label="right aligned">
							<FormatAlignRightIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<DividerVer />
					<FormControlLabel
						className={classes.alignUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={field.horizontalAlignUserDefined || false}
								onChange={(e) => updateField({ horizontalAlignUserDefined: e.target.checked })}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.alignUserDefinedFormControlLabelText,
						}}
					/>
				</div>
			</div>
			<DividerHor />
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.verAlignText',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledToggleButtonGroup
						value={field.verticalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={(e, alignment) =>
							updateField({
								verticalAlign: alignment || ART_VARIABLES.defaultFields.pdf.verticalAlign,
							})
						}
						aria-label="pdf vertical alignment"
					>
						<ToggleButton value="top" aria-label="top aligned">
							<FormatVerticalAlignTopIcon />
						</ToggleButton>
						<ToggleButton value="middle" aria-label="middle horizontal">
							<FormatVerticalAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="bottom" aria-label="bottpm aligned">
							<FormatVerticalAlignBottomIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<DividerVer />
					<FormControlLabel
						className={classes.alignUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={field.verticalAlignUserDefined || false}
								onChange={(e) => updateField({ verticalAlignUserDefined: e.target.checked })}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.alignUserDefinedFormControlLabelText,
						}}
					/>
				</div>
			</div>
			<DividerHor />
			<div className={classes.groupsDiv}>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<SimpleNumberSlider
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.borderWidthText',
						})}
						value={field.borderWidth}
						handleOnChange={(value) => updateField({ borderWidth: value })}
						step={ART_VARIABLES.borderWidthRange.step}
						min={ART_VARIABLES.borderWidthRange.min}
						max={ART_VARIABLES.borderWidthRange.max}
						unit={ART_VARIABLES.borderWidthRange.unit}
					/>
				</div>
			</div>
			<DividerHor />
			<div className={classes.groupsDiv}>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<span style={{ paddingRight: 8, width: 90, textAlign: 'left' }}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.borderColorText',
						})}
					</span>
					<div
						style={{ width: `calc(100% - 90px)`, display: 'inline-flex', justifyContent: 'center' }}
					>
						<div
							style={{
								backgroundColor: field.borderColor.hex || '#000',
								width: '80%',
								height: 40,
								margin: '0px 4px',
								cursor: 'pointer',
								borderRadius: 4,
							}}
							onClick={(e) =>
								setColorPicker({
									anchorEl: e.currentTarget,
									fieldKey: 'borderColor',
									disableAlpha: true,
									enablePantone: true,
									enableCMYK: true,
								})
							}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};

PdfPaletteStyles.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	setColorPicker: PropTypes.func.isRequired,
	// artworkExtraData: PropTypes.object.isRequired,
	// templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
};

PdfPaletteStyles.defaultProps = {};

/** PdfPaletteData Component */
const useDataPaletteStyles = makeStyles((theme) => ({
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
	defaultValuePreview: {
		width: '100%',
		// height: 120,
		padding: '8px 20%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export const PdfPaletteData = ({
	field,
	updateField,
	artworkExtraData,
	openMediaFileChooser,
	// templateFields,
	...rest
}) => {
	const classes = useDataPaletteStyles();
	const intl = useIntl();
	// generate grouped template fields
	// const groupedTemplateFields = {};
	// templateFields.forEach(f => {
	// 	// if (f.id !== field.id) {
	// 	if (!groupedTemplateFields[f.groupName]) {
	// 		groupedTemplateFields[f.groupName] = [];
	// 	}
	// 	groupedTemplateFields[f.groupName].push({
	// 		value: f.id,
	// 		label: f.name,
	// 		disabled: f.id === field.id,
	// 	});
	// 	// }
	// });
	const pdfChoiceArray =
		field.pdfOrigin === 'category'
			? field.pdfOriginChoice.length === 0
				? [{ categories: artworkExtraData.categories }]
				: [{ categories: artworkExtraData.categories }].concat(field.pdfOriginChoice)
			: [];
	return (
		<div className={classes.tabWrapper}>
			<div
				className={classes.groupsDiv}
				style={field.pdfOrigin ? {} : { margin: 0, width: '100%', border: 'none' }}
			>
				<StyledOutlinedTextFieldSelection
					fullWidth
					select
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.PdfPalette.pdfOriginText',
					})}
					value={field.pdfOrigin || ''}
					onChange={(e) =>
						updateField({
							pdfOrigin: e.target.value || '',
							// reset the options
							pdfOriginChoice: [],
							pdfOriginIncludeUserFiles: false,
						})
					}
					noneOption
					options={ART_VARIABLES.imageOriginOptions}
				/>
				{field.pdfOrigin === 'category' && (
					<div className={classes.groupsDiv}>
						<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.PdfPalette.pdfChoiceText',
							})}
						</InputLabel>
						{pdfChoiceArray.map((item, idx) => {
							if (idx !== 0 && (!Array.isArray(item.categories) || item.categories.length === 0))
								return null;
							return (
								<StyledOutlinedTextFieldSelection
									key={idx}
									fullWidth
									select
									label=""
									value={pdfChoiceArray[idx + 1] ? pdfChoiceArray[idx + 1].id : ''}
									onChange={(e) => {
										let newPdfChoice = field.pdfOriginChoice.slice(0, idx);
										if (e.target.value)
											newPdfChoice[idx] = _.find(item.categories, (c) => c.id === e.target.value);
										updateField({
											pdfOriginChoice: newPdfChoice,
										});
									}}
									smallMenu
									smallIcon
									noneMsg={
										artworkExtraData.categories.length === 0
											? intl.formatMessage({
													id:
														'pages.Artwork.components.ElementPalette.GENERAL.noCategoryAvailableText',
											  })
											: null
									}
									noneOption
									options={(item.categories || []).map((c) => ({
										label: c.name,
										value: c.id,
									}))}
								/>
							);
						})}
					</div>
				)}
				{field.pdfOrigin === 'admin_lightbox' && (
					<StyledOutlinedTextFieldSelection
						fullWidth
						select
						label="PDF Choice"
						value={field.pdfOriginChoice[0] ? field.pdfOriginChoice[0].id : ''}
						onChange={(e) => {
							updateField({
								pdfOriginChoice: e.target.value ? [{ id: e.target.value }] : [],
							});
						}}
						smallMenu
						smallIcon
						noneMsg={artworkExtraData.lightboxes.length === 0 ? 'No lightbox available' : null}
						noneOption
						options={(artworkExtraData.lightboxes || []).map((lightbox) => ({
							label: lightbox.name,
							value: lightbox.id,
						}))}
					/>
				)}
				{field.pdfOrigin && (
					<React.Fragment>
						<DividerHor />
						<SimpleSwitchRow
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.includeUserFilesText',
							})}
							SwitchProps={{ disabled: field.pdfOrigin === 'user_files' }}
							style={{ padding: '8px 24px' }}
							checked={field.pdfOriginIncludeUserFiles}
							onChange={(e, checked) => updateField({ pdfOriginIncludeUserFiles: checked })}
						/>
					</React.Fragment>
				)}
			</div>
			<DividerHor />
			{
				// <StyledOutlinedTextField
				// 	label={intl.formatMessage({
				// 		id: 'pages.Artwork.components.ElementPalette.PdfPalette.defaultPdfMediaText',
				// 	})}
				// 	value={field.defaultMediafileId || ''}
				// 	onChange={e => updateField({ defaultMediafileId: e.target.value || '' })}
				// />
			}
			{
				// select default pdf in popup
				<div className={classes.groupsDiv}>
					<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
						Default PDF
					</InputLabel>
					{field.defaultMediafilePreviewUrl && (
						<div className={classes.defaultValuePreview}>
							<object
								data={field.defaultMediafilePreviewUrl}
								type="image/svg+xml"
								aria-label="preview"
								style={{ maxHeight: '100%', maxWidth: '100%' }}
							/>
						</div>
					)}
					<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
						<StyledContainedButton
							label="Choose"
							startIcon={<ImageSearchIcon fontSize="small" />}
							size="small"
							onClick={() => openMediaFileChooser()}
						/>
						<StyledContainedButton
							label="Clear"
							startIcon={<ClearIcon fontSize="small" />}
							size="small"
							onClick={() =>
								updateField({
									defaultMediafileId: '',
									defaultMediafilePreviewUrl: '',
									defaultMediafileHighResUrl: '',
									defaultMediafileOptimisedUrl: '',
								})
							}
						/>
					</div>
				</div>
			}
		</div>
	);
};

PdfPaletteData.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	artworkExtraData: PropTypes.object.isRequired,
	// templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
	/**
	 * Function to open mediafile chooser
	 *
	 * @param {null} no parameters
	 */
	openMediaFileChooser: PropTypes.func.isRequired,
};

PdfPaletteData.defaultProps = {};
