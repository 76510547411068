import { TURN_ON_SIDEBAR, TURN_OFF_SIDEBAR } from 'redux/actions';
const defaultSidebar = {
	hidden: false,
};
export const sidebar = (state = defaultSidebar, action) => {
	const { type } = action;
	switch (type) {
		case TURN_ON_SIDEBAR:
			return {
				...state,
				hidden: false,
			};
		case TURN_OFF_SIDEBAR:
			return {
				...state,
				hidden: true,
			};
		default:
			return state;
	}
};
