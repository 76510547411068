/* eslint-disable react/no-string-refs */
import React from 'react';

import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { SketchPicker } from 'react-color';
import {
	pantoneCMYK,
	convertPantoneColor,
	convertCMYKcolor,
	convertHexColor,
} from 'utils/artwork/artUtilsWebUI';
// MUI components
import { Divider, TextField, Input } from '@mui/material';
import { Autocomplete } from '@mui/material';

import { ArrowDropDown as DropDownIcon, Clear as ClearIcon } from '@mui/icons-material';
const FONTSIZE = '11px';
const CMYKValMin = 0,
	CMYKValMax = 100,
	CMYKValStep = 1;
const wrapperWidth = 300;
const wrapperPadding = 10;
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	colorPickerWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: wrapperWidth + wrapperPadding * 2,
	},
	customSketchPickerWrapper: {
		borderRadius: '0px !important',
		boxShadow: 'unset !important',
		// width: `${wrapperWidth}px !important`,
		// padding: `${wrapperPadding}px ${wrapperPadding}px 0px !important`,
	},
	customColorWrapper: {
		backgroundColor: '#fff',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
		fontSize: FONTSIZE,
	},
	customColorLabel: {
		width: 65,
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'space-between',
		paddingRight: 8,
		// textAlign: 'center',
	},
	customColorInput: {
		width: `calc(100% - 65px)`,
		display: 'inline-flex',
		alignItems: 'center',
	},
	pantoneSelect: {
		width: '100%',
	},
	pantoneTextField: {
		fontSize: 'inherit',
		padding: 0,
		margin: 0,
	},
	autoCompleteInputRoot: {
		fontSize: 'inherit',
		padding: '4px !important',
		paddingRight: '40px !important',
	},
	autoCompleteClearIndicator: {
		padding: 0,
		margin: 0,
	},
	autoCompletePopupIndicator: {
		padding: 0,
		margin: 0,
	},
	autoCompleteEndAdornment: {
		top: 'unset',
		right: '0px !important',
	},
	autoCompleteListbox: {
		fontSize: FONTSIZE,
	},
	autoCompleteOption: {
		'&[data-focus="true"]': {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
	},
	MUIInputTextAlign: {
		textAlign: 'center',
	},
	CMYKValWrapper: {
		display: 'flex',
		alignItems: 'center',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
		margin: '0px 2px',
	},
	CMYKInput: {
		margin: `0px ${theme.spacing(0.5)}`,
		// width: '40%',
		// maxWidth: 20,
		fontSize: 'inherit',
	},
}));

const prepareColor = async (color) => {
	if (color.hex || color.rgb) return color;
	if (color.pantone) {
		return (await convertPantoneColor(color.pantone)) || {};
	} else if (color.cmyk) {
		return (await convertCMYKcolor(color.cmyk)) || {};
	}
	return color;
};

function ColorPicker({
	color: colorProp, // {hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 }, pantone: '', cmyk: {c: 1, m: 1, y: 1, k: 1}}
	disableAlpha,
	// onColorChange,
	onColorChangeComplete,
	enablePantone,
	enableCMYK,
	...rest
}) {
	const classes = useStyles();
	const [color, setColor] = React.useState({
		hex: '#000000',
		pantone: null,
		cmyk: {},
		rgb: { r: 0, g: 0, b: 0, a: 1 },
	});
	React.useEffect(() => {
		// use colorProp to initialize the color and cmyk value
		// and only run it once, subsequent changes is trigger on each color module
		// It means: This reusable component must be unmounted after each use
		prepareColor({
			hex: '#000000',
			pantone: null,
			cmyk: {},
			rgb: { r: 0, g: 0, b: 0, a: 1 },
			...colorProp,
		}).then((preparedColor) => {
			if (preparedColor) {
				setColor(preparedColor);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className={classes.colorPickerWrapper}>
			<SketchPicker
				className={classes.customSketchPickerWrapper}
				color={disableAlpha ? color.hex : color.rgb}
				disableAlpha={disableAlpha}
				width={wrapperWidth}
				// onChange={onColorChange}
				onChangeComplete={(color) => {
					let pickerColor = convertHexColor(color.hex);
					setColor(pickerColor);
					onColorChangeComplete(
						pickerColor || { hex: color.hex, rgb: color.rgb, pantone: null, cmyk: null }
					);
				}}
			/>
			{enablePantone && (
				<React.Fragment>
					<Divider style={{ width: '100%' }} />
					<div className={classes.customColorWrapper}>
						<span className={classes.customColorLabel}>Pantone</span>
						<div className={classes.customColorInput}>
							<Autocomplete
								className={classes.pantoneSelect}
								autoHighlight
								options={['', ...Object.keys(pantoneCMYK)]}
								size="small"
								value={color.pantone || ''}
								popupIcon={<DropDownIcon fontSize="small" />}
								clearIcon={<ClearIcon fontSize="small" />}
								classes={{
									inputRoot: classes.autoCompleteInputRoot,
									clearIndicator: classes.autoCompleteClearIndicator,
									popupIndicator: classes.autoCompletePopupIndicator,
									endAdornment: classes.autoCompleteEndAdornment,
									listbox: classes.autoCompleteListbox,
									option: classes.autoCompleteOption,
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										fullWidth
										InputLabelProps={{ shrink: false }}
									/>
								)}
								onChange={async (e, value) => {
									let pantoneColor = await convertPantoneColor(value);
									if (!pantoneColor) return;
									setColor(pantoneColor);
									onColorChangeComplete(pantoneColor);
								}}
							/>
						</div>
					</div>
				</React.Fragment>
			)}
			{enableCMYK && (
				<React.Fragment>
					<Divider style={{ width: '100%' }} />
					<div className={classes.customColorWrapper}>
						<span className={classes.customColorLabel}>CMYK</span>
						<div className={classes.customColorInput}>
							{['c', 'm', 'y', 'k'].map((item) => {
								return (
									<div key={item} className={classes.CMYKValWrapper}>
										<span style={{ padding: '0px 2px', textTransform: 'capitalize ' }}>{item}</span>
										<Input
											className={classes.CMYKInput}
											value={typeof color.cmyk[item] === 'undefined' ? '' : color.cmyk[item]}
											margin="dense"
											name={item}
											classes={{
												input: classes.MUIInputTextAlign,
											}}
											onChange={(e) => {
												setColor({
													...color,
													cmyk: {
														...color.cmyk,
														[e.target.name]: e.target.value ? Number(e.target.value) : '',
													},
												});
											}}
											error={
												Object.keys(color.cmyk).length > 0 && typeof color.cmyk[item] !== 'number'
											}
											onBlur={async () => {
												if (typeof color.cmyk[item] !== 'number') {
													let cmykInputClone = { ...color.cmyk };
													delete cmykInputClone[item];
													return setColor({
														...color,
														cmyk: cmykInputClone,
													});
												}
												if (color.cmyk[item] < CMYKValMin) {
													setColor({
														...color,
														cmyk: {
															...color.cmyk,
															[item]: CMYKValMin,
														},
													});
												} else if (color.cmyk[item] > CMYKValMax) {
													setColor({
														...color,
														cmyk: {
															...color.cmyk,
															[item]: CMYKValMax,
														},
													});
												}
												if (Object.keys(color.cmyk).length === 4) {
													let newColor = await convertCMYKcolor(color.cmyk);
													if (newColor) {
														setColor(newColor);
														onColorChangeComplete(newColor);
													}
												}
											}}
											inputProps={{
												step: CMYKValStep,
												min: CMYKValMin,
												max: CMYKValMax,
												type: 'number',
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
}

// This reusable component must be unmounted after each use
ColorPicker.propTypes = {
	color: PropTypes.object.isRequired, // {hex: '#333333', pantone: '101',cmyk: {c: 23, m: 12, y: 0, k: 100}, rgb: { r: 51, g: 51, b: 51 }}
	disableAlpha: PropTypes.bool, // Remove alpha slider and options from picker. Default false
	onColorChangeComplete: PropTypes.func.isRequired, // Ref to https://casesandberg.github.io/react-color/#api-onChangeComplete
	// onColorChange: PropTypes.func, // Ref to https://casesandberg.github.io/react-color/#api-onChange
	enablePantone: PropTypes.bool,
	enableCMYK: PropTypes.bool,
};

ColorPicker.defaultProps = {
	disableAlpha: false,
	// onColorChange: null,
	enablePantone: false,
	enableCMYK: false,
};

// This reusable component must be unmounted after each use
export default ColorPicker;
