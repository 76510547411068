import React from 'react';

import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Link as RouterLink } from 'react-router-dom';
// custom components
// import {
// 	// FileUploader,
// } from "../components/index";

// CSS
import HomeStyle from './HomeStyle.jsx';

// redux
import { connect } from 'react-redux';
import {} from 'redux/actions'; // actions

import config from 'config';
const basePath = config.basePath;

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// historyNotifications: [],
		};
	}

	// componentWillReceiveProps(nextProps){

	// }

	// storeDisplayed = (key) => {
	// 	this.setState(({ historyNotifications }) => ({
	// 			historyNotifications: [...historyNotifications, key],
	// 	}));
	// };

	render() {
		// const {
		// 	// classes,
		// } = this.props;

		return (
			<div>
				Home Page <br />
				<ul>
					{
						// 	<li>
						// 	<RouterLink to={`${basePath}/artdesign?mediaId=243488`}>Artwork Design</RouterLink>{' '}
						// </li>
						// <li>
						// 	<RouterLink to={`${basePath}/login`}>Login</RouterLink>
						// </li>
					}
					<li>
						<RouterLink to={`${basePath}/screen_manager`}>Screen Manager</RouterLink>
					</li>
				</ul>
			</div>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

Home.defaultProps = {
	// multiple: false,
	// allowZip: false,
	// allowPreview: true,
	// disableClick: false,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
	return {
		// notifier: state.notifier
	};
};

export default connect(mapStateToProps, {})(withStyles(HomeStyle)(Home));
