import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import ReactResizeDetector from 'react-resize-detector';
// import MaterialTable from 'material-table';

// Constants
import { ART_VARIABLES, DESIGN_MIN_UL } from '../../Constants';

// MUI components
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Link, useMediaQuery } from '@mui/material';

// custom components
import { PerfectScrollWrapper /* Loading */ } from 'components';

// Artwork components
import SVGPreview from '../SVGPreview/SVGPreview';
import PreviewControlPanel from '../PreviewControlPanel/PreviewControlPanel';
import ArtLoading from '../ArtLoading/ArtLoading';
import CreateOverlay from '../CreateOverlay/CreateOverlay';
import CreateActions from '../CreateActions/CreateActions';
import MobileCreateActions from '../MobileCreateActions/MobileCreateActions';
import ImageCropper from '../SVGPreview/ImageCropper';
import ReplayBtn from '../ReplayBtn/ReplayBtn';

// API
// import { artworkFetchArtContent, awsCreateS3Client, fetchClipImage } from 'restful';
import {
	artworkFetchArtContent,
	awsCreateS3Client,
	fetchClipImage,
	uploadFileFromAI,
	searchMediaFiles,
	generateArtworkPdfs,
} from 'restful';
import { generateMultipleArtworkToPdfPagesWithServer } from 'utils/artwork/artServerGenerator';
import {
	geFontListInTemplateFields,
	retrieveGridTableData,
	getFieldOutputData,
	outputTemplatePlan,
	getColorListInTemplateFields,
	getCloudFrontUrlOfS3File,
	loadFontfaceToDocument,
} from 'utils/artwork/artUtilsCommon';
import {
	deleteCroppedImgUrl,
	calcRectToFitScreen,
	fitPosition,
	renewFieldInputDataBySelectedSpreadsheet,
	isPrintableTemplate,
	hasAnimation,
} from 'utils/artwork/artUtilsWebUI';
import { pdfPtToPixel } from 'utils/artwork/constants';

import { getBucketFromS3Url, getDomainConfig, getPreviewUrl } from 'utils/appHelper';
import { isNullish, isBlobUrl, genRandomStr } from 'utils/generalHelper';
import { openPrintDialogForPdf, moment, isNumber, _ } from 'utils/libHelper';

import { getRouteMediafileDetail, getRouteArtworkDesigner } from 'routes';

// intl lang
import { useIntl } from 'react-intl';

// redux
import { connect } from 'react-redux';
import {
	// turnOnSidebar,
	// turnOffSidebar,
	// updateLocalDesignerTemplate,
	// setArtDesignState,
	notifyGeneral,
	getDesignerTemplate,
	resetDesignerTemplate,
	resetArtworkFonts,
	fetchArtworkFonts,
	fetchArtworkSpreadsheets,
	resetArtworkSpreadsheets,
	fetchArtworkLists,
	resetArtworkLists,
	fetchArtworkAutoImport,
	resetArtworkAutoImport,
	fetchArtworkOutputTemplates,
	resetArtworkOutputTemplates,
	fetchArtworkCategories,
	resetArtworkCategories,
	resetSpreadsheetContentById,
	reSyncCounter,
	fetchProgressUploadedFiles,
	// fetchAWSCredential,
	// resetAWSCredential,
	// saveArtworkFile,
	// resetSaveArtworkFileStatus,
	// saveDesignerTemplate,
	// retrieveDraftDesignTemplate,
	// undoDesignTemplate,
	// redoDesignTemplate,
	// openGlobalDialog,
} from 'redux/actions'; // actions
import { createArtworkAtServer, createVideoArtwork } from 'utils/artwork/artworkGenerator';
import { endOfDay, startOfDay } from 'date-fns';

// CSS
const useStyles = makeStyles((theme) => ({
	createWrapper: {
		// ...theme.contentWrapper,
		// padding: 0,
		display: 'flex',
		top: 0, //theme.headerBarHeight,
		bottom: 0,
		left: 0,
		right: 0,
		position: 'absolute',
		backgroundColor: ART_VARIABLES.cssStyles.designWrapperBGColor,
		'& input': {
			// TODO: REMOVE IT. This is to resolve the conflict to bootstrap in lasso page
			border: 'unset',
			boxShadow: 'unset',
			lineHeight: 'unset',
			backgroundColor: 'unset',
			margin: 'auto',
			// minHeight: 30, // remove it so that input fields in previewControlPanel have correct height
		},
		'& textarea': {
			// TODO: REMOVE IT. This is to resolve the conflict to bootstrap in lasso page
			border: 'unset',
			boxShadow: 'unset',
			lineHeight: 'unset',
			backgroundColor: 'unset',
			// margin: 'auto',
		},
	},
	workspaceWrapper: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		zIndex: 0,
		margin: 'auto',
		display: 'block',
		userSelect: 'none',
	},
	workspaceSection: {
		top: 0,
		left: 0,
		right: '500px', // This is to move the previewPalatte 500px to the right; this is the space for previewPalette & the action vertical bar
		bottom: 0,
		position: 'absolute',
		zIndex: 0,
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			right: 0,
		},
	},

	createSpacer: {
		overflow: 'hidden',
		position: 'relative',
	},
	createWorkplace: {
		position: 'absolute',
		border: `1px solid ${theme.palette.gray.main}`,
	},
	actionHolder: {
		position: 'absolute',
		top: 20,
		right: 20,
	},
	replayBtnContainer: {
		position: 'absolute',
		bottom: 40,
		right: 20,
		zIndex: 1,
	},
	videoBGWrapperForVideoArtwork: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		zIndex: -1,
	},
	bottomCreateActionWrapper: {
		position: 'absolute',
		left: 0, //ART_VARIABLES.cssStyles.menuBarWidth,
		height: ART_VARIABLES.cssStyles.mobileCreateActionHeight,
		bottom: 0,
		right: 0,
		backgroundColor: 'inherit',
		...theme.customBoxShadow,
		zIndex: 1,
		margin: 'auto',
		display: 'block',
		userSelect: 'none',
	},
}));

// constant variable
const CREATE_MODE = 'CREATE';
const EDIT_MODE = 'EDIT';
function Create({
	history,
	location,
	artworkDesignerTemplate,
	notifyGeneral,
	getDesignerTemplate,
	resetDesignerTemplate,
	resetArtworkFonts,
	artworkExtra,
	userData,
	domainName,
	fetchArtworkFonts,
	fetchArtworkSpreadsheets,
	resetArtworkSpreadsheets,
	fetchArtworkLists,
	resetArtworkLists,
	fetchArtworkAutoImport,
	resetArtworkAutoImport,
	fetchArtworkOutputTemplates,
	resetArtworkOutputTemplates,
	fetchArtworkCategories,
	resetArtworkCategories,
	resetSpreadsheetContentById,
	reSyncCounter,
	fetchProgressUploadedFiles,
	// fetchAWSCredential,
	// resetAWSCredential,
	// saveArtworkFile,
	// resetSaveArtworkFileStatus,
	// saveDesignerTemplate,
	// retrieveDraftDesignTemplate,
	// openGlobalDialog,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const theme = useTheme();
	const isMobileView = !useMediaQuery(theme.breakpoints.up(theme.mobileViewBreakpoint));
	// constants
	// const S3_RESOURCE = { resource: 's3' }; // used to fetch s3 credential
	// Ref
	const workspaceContainerRef = useRef();
	const workspaceWrapperRef = useRef();
	const WSPreviewRef = useRef();
	const userInputDataRef = React.useRef(); // for croppedImgUrl (blobUrl) cleanup on unmounted purpose
	const isInitializedRef = React.useRef(false);
	const videoArtworkVideoRef = useRef(); // ref to the video element in videoArtwork
	const isAIImageUploadStatusRef = useRef(false); // ref to the AI Image upload status in saveAIImage
	// state
	const [hasUnsavedData, setHasUnsavedData] = useState(false); // possible values: true, false
	// mode of artwork creation. Possible values: CREATE_MODE, EDIT_MODE
	const [artMode, setArtMode] = useState(CREATE_MODE);
	// artwork content. Only useful in EDIT_MODE. structure:  {id: mediafileId, name: mediafileName, s3Path: 's3 path of existing artwork file', s3Bucket: 'bucket in s3Path', basePath: 'basePath in s3Path'}
	const [artContent, setArtContent] = useState({});
	const [psWrapper, setPsWrapper] = useState({});
	const [selectedFieldIds, setSelectedFieldIds] = useState([]);
	// we only use templateFields in local state for artwork fields (don't use props from redux store)
	const [templateFields, setTemplateFields] = useState([]);
	// exporting progress status. {status: 'OK'|'DONE'|'FAILED', message: 'xxx'} or {} (to reset it)
	const [exportArtworkStatus, setExportArtworkStatus] = useState({});
	// [progressStatus]. Progress of any process. Most case there is only one progress happening in a page. This state handles the progress status of the happening process
	// const [progressStatus, setProgressStatus] = useState({});
	/**
	 * multiplePrintData is the print (queue) data.
	 * Output template is applied. [same as testFieldsData for testing]
	 * Format: [{data: fieldOutputData, num: 17}, ...], fieldOutputData is combination of input data & field default data
	 */
	const [multiplePrintData, setMultiplePrintData] = useState([]);

	// to control animation, enable|disable|reset preview animation
	const [allowAnimation, setAllowAnimation] = useState(true);
	const [pausingAnimation, setPausingAnimation] = useState(false);
	// const [contextualPaletteOpen, setContextualPaletteOpen] = useState(true);
	const [zoomControls, setZoomControls] = useState({ isFit: true });
	// template preparation status
	const [initStatus, setInitStatus] = useState({ status: 'OK', message: '' });
	// controls of UI. e.g. display preview, display BG image, etc.
	const [UIControls, setUIControls] = useState({
		isPreviewEnabled: true,
		isBGImageEnabled: true,
		isFieldHighlightEnabled: false,
		// isPreviewControlPanelOpen: false,
	});

	const [cropImage, setCropImage] = useState({});
	const [aIImageUploadStatus, setAIImageUploadStatus] = useState(false);
	// const [fieldsPreviewValue, setFieldsPreviewValue] = useState({});

	/**
	 * input data of each field. Called fieldInputData
	 * Format: {FIELD_ID: {}}
	 * NOTE-1: fieldInputData only contains user's input data through preview panel. Initial value is null/undefined; set to '' when it is cleared
	 * 				 We will have a merged dataset to merge fieldInputData & field default data, then use the merged dataset to output
	 * NOTE-2: When the value in a field is cleared by user, no data will be used for output the field (preview or pdf generation), hence the following rules:
	 * 				 - if field data in this fieldInputData is null/undefined, we will use field default data (in the merged dataset)
	 * 				 - if field data in this fieldInputData is '', we will leave it empty (in the merged dataset), even there is default data for the field
	 fieldInputData: {
		TEXT_FIELD_ID: {
			// TEXT Preview Data
			value: 'xxxx', // value of this text field. Possible values: null/undefined, '', 'text'
			fontsize: NUMBER | undefined,
			horizontalAlign: 'left' | 'center' | 'right' | 'justified' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		},
		IMAGE_FIELD_ID: {
			// IMAGE Preview Data. NB: priority to use image: croppedImgUrl > clippedImgUrl > highResUrl | optimisedUrl
			mediafileId: 'xxxxxx', // user selected mediafile ID. Possible values: null/undefined, '', '32345', 'external:brankbank:sser1234ji';
			previewUrl: 'xxxxxxx', // preview url of this image. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

			// NB: once cropped image is saved in s3, we don't delete it as saved mediafiles may still use it
			// croppedImgUrl could be blob object url, to prevent memory leak, we clean it in following cases:
			// 	- any place that updates it by setFieldInputDataById
			//	- input data updated by spreadsheet product picker in handleProductPickerSelection
			//	- a new cropped image is created in handleCroppedImage
			//	- field input data is reset by resetFieldInputData
			//	- after uploading it to s3 and convert it to web url (right after uploadCroppedImgToS3())
			croppedImgUrl: 'xxx', // web url or blob url of the cropped image (png format). Possible values: null/undefined, '', 'blob:https://xxx | https://xxxx'
			clippedImgUrl: 'https://xxxx', // web url of the clipped image. Possible values: null/undefined, '', 'https://xxx'
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
			// user customized field position.
			// NB: it is only used as part of file content, it is never used in preview or file generation, so do not merge it into output data. When not undefined, it is guaranteed to have left|top|width|height|angle
			// NB: In the case of "continue editing previously saved artwork file (not template)", this data must be retrieved and used to change the field "designed" position (we always use field.position to render field's preview)
			position: {left: num, top: num, width: num, height: num, angle: num},
		},
		BARCODE_FIELD_ID: {
			// BARCODE Preview Data
			value: 'xxxxxxx', // value of this barcode field. Possible values: null/undefined, '', 'barcode'
			EAN13Value: 'xxx', // processed data from "value", only in output data
			EAN5Value: 'xxx', // processed data from "value", only in output data
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		},
		PDF_FIELD_ID:{
			// Artwork field Preview Data
			mediafileId: 'xxxxxx', // user selected mediafile ID (not used for output, but used to save content). Possible values: null/undefined, '', '32345';
			previewUrl: 'xxxxxxx', // svg url of this pdf/svg. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // svg url of this pdf/svg. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // svg url of this pdf/svg. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			
			//svgUrl: 'xxxx',
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
			
		},
		VIDEO_FIELD_ID: {
			// VIDEO Preview Data
			mediafileId: 'xxxxxx', // user selected mediafile ID (not used for output, but used to save content). Possible values: null/undefined, '', '32345';
			previewUrl: 'xxxxxxx', // preview url of this video. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // transcoded video url of this mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // transcoded video url of this mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			
			// videoUrl: 'https://xxxxx', // undefined or original video url???
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
			videoLoop: true | false | undefined,
		},
		GRID_FIELD_ID: {
			// tableData format: [RowData, RowData, ...]; RowData: [cellText, cellText, ...]; cellText may be empty string, may contain multiple lines by '\n'. Get non-empty lines by `.split(/\r?\n|\r/g).filter((s) => s)`
			// sample data of tableData: [["Product","Small","Large"],["Cappuccino\n\nsubtitle","€1.99\n\nsubtitle","€2.99"],["Mocha","€2.49","€3.99"]]
			// possible value: null/undefined (no input data), [['xxx', 'xxx'], ...]
			// when its value is null/undefined, the code logic in "getFieldOutputData" to decide if using field.defaultEditorHtml to render or not
			tableData: [["Product","Small","Large"],["Cappuccino\n\ngghgh","€1.99\n\nnew line","€2.99"],["Mocha","€2.49","€3.99"]],
			hasHeader: true/false, // indicate if there is header in the table
			// editorHtml possible values: null/undefined, 'xxxx'
			editorHtml, // the html string in the editor.
			fontsize, // possible values: null/undefined, number. user defined font size (only available when user-defined font size is allowed)
		}
	}
	 */
	const [fieldInputData, setFieldInputData] = useState({});
	/**
	 * the template background from user's selection
	 * the value references to one single mediafile. The value is either null/undefined, "", or real value
	 * NB: in case the value is "", it means no background will be used, the default is ignored too.
	 {
		 	mediafileId: 'xxx', // mediafile id.
			mediafilePreviewUrl: 'https://xxx', //  preview url.
			mediafileHighResUrl: 'https://xxx', // high-resolution url of mediafile (for generating high quality pdf)
			mediafileOptimisedUrl: 'https://xxx', // optimised url of mediafile (fast loading & good quality for webpage)
			mediafilePdfUrl: 'https://xxx', // pdf url of the background mediafile
			mediafileSvgUrl: 'https://xxx' // svg url of the background mediafile
	 }
	 */
	const [templateBGInputData, setTemplateBGInputData] = useState({});

	/****
	 * WSRect.borderRect is the outer box of the design preview area, with ART_VARIABLES.WSBorderH & ART_VARIABLES.WSBorderV as the border (or margin) of the design preview area
	 * WSRect.previewRect is the design preview area, with the template media as its background image
	 * format of WSRect:
	  	{
				previewRect: { width: NUMBER_in_Pixel, height: NUMBER_in_Pixel, x: NUMBER_in_Pixel, y: NUMBER_in_Pixel},
				borderRect:{ width: NUMBER_in_Pixel, height: NUMBER_in_Pixel, x: NUMBER_in_Pixel, y: NUMBER_in_Pixel},
				zoom: NUMBER,	// e.g. 0.1, 0.20, 0.45, 1, 1.35, 2
			}
	 * */
	const [WSRect, setWSRect] = useState({ previewRect: {}, borderRect: {}, zoom: 1 });

	const templateBGOutputDataWithSettings = React.useMemo(() => {
		// NB: templateBGInputData overrides default when the value is ""
		return { ...artworkDesignerTemplate.templateBG, ...templateBGInputData };
	}, [artworkDesignerTemplate.templateBG, templateBGInputData]);

	// ##################
	//	helper functions
	// ##################
	const cleanCroppedImgUrls = (userInputData) => {
		Object.values(userInputData).forEach((fieldInput) => {
			deleteCroppedImgUrl(fieldInput.croppedImgUrl);
		});
	};

	const replayAnimationAndVideo = React.useCallback(() => {
		setAllowAnimation(false);
		setSelectedFieldIds([]);
		setTimeout(() => {
			setAllowAnimation(true);
			setPausingAnimation(false);
		}, 150);
	}, []);

	// update workspace rect on resize/zoom
	const updateWorkspaceRect = React.useCallback(
		(zoom = 0) => {
			if (!workspaceContainerRef.current || !psWrapper.elemRef || !psWrapper.elemRef.current)
				return null;
			// let designRectCalc, designBoxRectCalc;
			let WSRectCalc;
			let WSContainerRect = {
				width: workspaceContainerRef.current.clientWidth - 2 * ART_VARIABLES.WSBorderH,
				height:
					workspaceContainerRef.current.clientHeight -
					(isMobileView ? ART_VARIABLES.cssStyles.mobileCreateActionHeight : 0) -
					2 * ART_VARIABLES.WSBorderV,
				x: ART_VARIABLES.WSBorderH,
				y: ART_VARIABLES.WSBorderV,
			};
			if (zoom === 0) {
				// fit the screen
				let templateRect = {
					width: artworkDesignerTemplate.dimension.width,
					height: artworkDesignerTemplate.dimension.height,
					x: 0,
					y: 0,
				};

				WSRectCalc = calcRectToFitScreen(templateRect, WSContainerRect);
				if (WSRectCalc.zoom > 1) {
					// if zoom is greater than 100, we use 100% to fit
					return updateWorkspaceRect(1);
				} else if (WSRectCalc.zoom < ART_VARIABLES.minZoom) {
					// if zoom is less than "minZoom", we use minZoom to fit
					return updateWorkspaceRect(ART_VARIABLES.minZoom);
				}
			} else {
				let WSPreviewRectCalc = {
					width: artworkDesignerTemplate.dimension.width * zoom,
					height: artworkDesignerTemplate.dimension.height * zoom,
				};
				fitPosition(WSPreviewRectCalc, WSContainerRect);

				let WSBorderRectCalc = {
					width: WSPreviewRectCalc.width + 2 * ART_VARIABLES.WSBorderH,
					height: WSPreviewRectCalc.height + 2 * ART_VARIABLES.WSBorderV,
					x: WSPreviewRectCalc.x - ART_VARIABLES.WSBorderH,
					y: WSPreviewRectCalc.y - ART_VARIABLES.WSBorderV,
				};
				WSRectCalc = { previewRect: WSPreviewRectCalc, borderRect: WSBorderRectCalc, zoom: zoom };
			}

			if (
				WSRectCalc.previewRect.height < psWrapper.elemRef.current.scrollTop ||
				WSRectCalc.previewRect.width < psWrapper.elemRef.current.scrollLeft
			) {
				psWrapper.elemRef.current.scrollTop = 0;
				psWrapper.elemRef.current.scrollLeft = 0;
			}
			setWSRect(WSRectCalc);
		},
		[
			artworkDesignerTemplate.dimension.height,
			artworkDesignerTemplate.dimension.width,
			isMobileView,
			psWrapper.elemRef,
		]
	);

	// ##################
	//	side effects
	// ##################
	/* init required data */
	useEffect(() => {
		// componentDidMount event

		// add font to document so that svg preview can be rendered probably
		const initFontface = (fields, fontList) => {
			// // load fonts used by fields
			// let fontsBeingUsed = Array.from(
			// 	new Set(
			// 		fields
			// 			.filter((f) => f.type === 'text')
			// 			.map((f) =>
			// 				!f.formatNumberStyle.currencyFontName ||
			// 				f.formatNumberStyle.currencyFontName === ART_VARIABLES.placeholderSameAsText
			// 					? [f.fontfaceName]
			// 					: [f.fontfaceName, f.formatNumberStyle.currencyFontName]
			// 			)
			// 			.flat()
			// 	)
			// );

			// return Promise.all(
			// 	fontsBeingUsed
			// 		.map((fontName) => {
			// 			let font = _.find(fontList || [], (artFont) => artFont.font === fontName);
			// 			return font ? { name: fontName, fontUrl: font.path } : null;
			// 		})
			// 		.filter((item) => item)
			// 		.map((fontOption) => loadFontfaceToDocument(fontOption))
			// ).then((results) => {

			// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
			let fontlistInTemplate = geFontListInTemplateFields(fields, fontList, ART_VARIABLES);
			return Promise.all(
				fontlistInTemplate.map((fontOption) => loadFontfaceToDocument(fontOption))
			).then((results) => {
				let errorResults = results.filter((item) => item.error);
				if (errorResults.length > 0) {
					let msg = (
						<div>
							<Typography variant="body1" component={'div'} gutterBottom>
								{intl.formatMessage({
									id: 'pages.Artwork.components.Design.loadingFontFailureMsg',
								})}
								<Typography
									variant="body2"
									component={'div'}
									style={{ /* color: '#ff9800',  fontStyle: 'italic',*/ fontSize: '0.8rem' }}
								>
									<ul>
										{errorResults.map((result) => (
											<li key={result.error.font.name}>{`${result.error.font.name}`}</li>
										))}
									</ul>
								</Typography>
							</Typography>
						</div>
					);
					// notifyGeneral(msg, 'error', { autoHideDuration: null });
					let error = new Error('Can not load font face.');
					error.message = msg;
					throw error;
				}
			});
		};
		const getArtwork = (cb) => {
			const query = new URLSearchParams(location.search);
			const templateId = query.get('t');
			if (!templateId) {
				return cb({ error: new Error('Missing artwork template ID') });
			}
			getDesignerTemplate({ mediaId: templateId }, cb);
		};

		// progressively load required data
		const initData = async () => {
			try {
				// 1. fetch artwork font list
				setInitStatus({ status: 'OK', message: 'Loading fonts...' });
				await new Promise((res, rej) =>
					fetchArtworkFonts({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load fonts. Please try again later...'),
							});
						else res();
					})
				);
				// 2. fetch spreadsheet data
				setInitStatus({ status: 'OK', message: 'Loading spreadsheet data...' });
				await new Promise((res, rej) =>
					fetchArtworkSpreadsheets({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load spreadsheet data. Please try again later...'),
							});
						else res();
					})
				);
				// 3. fetch list data
				setInitStatus({ status: 'OK', message: 'Loading list data...' });
				await new Promise((res, rej) =>
					fetchArtworkLists({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load list data. Please try again later...'),
							});
						else res();
					})
				);
				// 4. fetch user data
				setInitStatus({ status: 'OK', message: 'Loading user data...' });
				await new Promise((res, rej) =>
					fetchArtworkAutoImport({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load user data. Please try again later...'),
							});
						else res();
					})
				);
				// 5. fetch output template data
				setInitStatus({ status: 'OK', message: 'Loading output template...' });
				await new Promise((res, rej) =>
					fetchArtworkOutputTemplates({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load output template. Please try again later...'),
							});
						else res();
					})
				);
				// 6. fetch artwork fields data. (Do we need categories data?)
				setInitStatus({ status: 'OK', message: 'Loading categories...' });
				await new Promise((res, rej) =>
					fetchArtworkCategories({}, ({ error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load category data. Please try again later...'),
							});
						else res();
					})
				);
				// 7. fetch artwork fields data
				setInitStatus({ status: 'OK', message: 'Loading template data...' });
				let isVideoTemplate = false;
				let sortedTemplateFields = await new Promise((res, rej) =>
					getArtwork(({ getState, error }) => {
						if (error)
							rej({
								originalError: error,
								error: new Error('Can not load the artwork template. Please try again later...'),
							});
						else {
							let state = getState();
							if (state.artworkDesignerTemplate.present.fields.length === 0) {
								// Empty template fields
								const err = new Error(
									'Template field is empty. Please contact to your administrator...'
								);
								return rej({
									originalError: err,
									error: err,
								});
							} else {
								setInitStatus({ status: 'OK', message: 'Loading font face...' });
								isVideoTemplate = Boolean(state.artworkDesignerTemplate.present.videoArtwork);
								initFontface(state.artworkDesignerTemplate.present.fields, state.artworkExtra.fonts)
									.then(() => {
										// initialize template fields (sorted by group & field). From here & now we will only use the template fields in local state
										let ordering = {};
										let sortedFields = _.orderBy(
											state.artworkDesignerTemplate.present.fields
												.map((f) => {
													if (typeof ordering[f.groupName] !== 'number')
														ordering[f.groupName] =
															0 +
															state.artworkDesignerTemplate.present.groups.findIndex(
																(g) => g.name === f.groupName
															) *
																state.artworkDesignerTemplate.present.fields.length;
													else ordering[f.groupName] += 1;
													return { ...f, displayOrder: ordering[f.groupName] };
												})
												.filter((f) => f),
											['displayOrder'],
											['asc']
										);
										// setTemplateFields(sortedFields);
										res(sortedFields);
									})
									.catch((err) => rej({ originalError: err, error: err }));
							}
						}
					})
				);
				// 8. load artwork content if applicable
				const query = new URLSearchParams(location.search);
				const artworkMediafileId = query.get('m');
				if (!isVideoTemplate && artworkMediafileId) {
					// there is artwork mediafile id in query, this is "EDIT" mode, load artwork content
					setInitStatus({ status: 'OK', message: 'Loading artwork content...' });
					let artContentResponse = await artworkFetchArtContent({
						mediafileId: artworkMediafileId,
					}).catch((err) => {
						err.originalError = err;
						err.error = new Error(
							`Can't load artwork content - ${
								err.response ? err.response.data.message : err.message
							}`
						);
						throw err;
					});
					let artContentData = artContentResponse.data;
					// get fields content as init fieldInputData
					let fieldsContent = (artContentData.fileContents || []).reduce((accu, field) => {
						// let fieldContent = field.fieldProperties;
						let fieldContent = field[`${field.type}Properties`] || {};
						// mediafile content (mediefileId, previewUrl, optimisedUrl, highResUrl) represents one entiry, it has three possible value: undefined, '', 'actual value'
						let mediafileContent = {};
						if (['image', 'video', 'pdf'].includes(field.type)) {
							if (
								fieldContent.mediafileId &&
								fieldContent.previewUrl &&
								fieldContent.optimisedUrl &&
								fieldContent.highResUrl
							) {
								mediafileContent = {
									mediafileId: fieldContent.mediafileId,
									previewUrl: fieldContent.previewUrl,
									optimisedUrl: fieldContent.optimisedUrl,
									highResUrl: fieldContent.highResUrl,
									// generateAI: fieldContent.generateAI,
								};
							} else if (
								fieldContent.mediafileId === '' ||
								fieldContent.previewUrl === '' ||
								fieldContent.optimisedUrl === '' ||
								fieldContent.highResUrl === ''
							) {
								mediafileContent = {
									mediafileId: '',
									previewUrl: '',
									optimisedUrl: '',
									highResUrl: '',
								};
							}
							if (field.type === 'image' && fieldContent.generateAI) {
								mediafileContent = {
									...mediafileContent,
									generateAI: fieldContent.generateAI,
								};
							}
							if (field.type === 'image' && fieldContent.croppedImgUrl) {
								// croppedImgUrl could be cropped from default image, hence croppedImgUrl is handled independently, no matter there is previewUrl, optimisedurl, etc. or not
								mediafileContent = {
									...mediafileContent,
									croppedImgUrl: fieldContent.croppedImgUrl,
								};
							}

							if (field.type === 'image' && fieldContent.clippedImgUrl) {
								// clippedImgUrl could be clipped from default image, hence clippedImgUrl is handled independently, no matter there is previewUrl, optimisedurl, etc. or not
								mediafileContent = {
									...mediafileContent,
									clippedImgUrl: fieldContent.clippedImgUrl,
								};
							}
						}

						let formatedContent = {
							...(fieldContent.horizontalAlign
								? { horizontalAlign: fieldContent.horizontalAlign }
								: {}),
							...(fieldContent.verticalAlign ? { verticalAlign: fieldContent.verticalAlign } : {}),
							...(!isNullish(fieldContent.value) ? { value: fieldContent.value } : {}),
							...(isNumber(fieldContent.fontSize) ? { fontsize: fieldContent.fontSize } : {}),
							// ...(!isNullish(fieldContent.mediafileId)
							// 	? { mediafileId: fieldContent.mediafileId }
							// 	: {}),
							// ...(!isNullish(fieldContent.previewUrl)
							// 	? { previewUrl: fieldContent.previewUrl }
							// 	: {}),
							// ...(!isNullish(fieldContent.optimisedUrl)
							// 	? { optimisedUrl: fieldContent.optimisedUrl }
							// 	: {}),
							// ...(!isNullish(fieldContent.highResUrl)
							// 	? { highResUrl: fieldContent.highResUrl }
							// 	: {}),
							...mediafileContent,
							...(!isNullish(fieldContent.videoLoop) ? { videoLoop: fieldContent.videoLoop } : {}),
							...(fieldContent.editorHtml ? { editorHtml: fieldContent.editorHtml } : {}),
						};

						// when there is position data, all of the "left", "top", "width" & "height" must have value, otherwise, we treat it as "No Custimized Position"
						if (
							isNumber(fieldContent.left) &&
							isNumber(fieldContent.top) &&
							isNumber(fieldContent.width) &&
							isNumber(fieldContent.height)
						) {
							// the field has customized position. We need to update the field.position in template fields as we always use field.position in template fields to render field
							sortedTemplateFields = sortedTemplateFields.map((f) => {
								if (f.id === field.id) {
									// update the field.position in template fields with user customized position
									f.position = {
										...f.position,
										left: fieldContent.left * pdfPtToPixel, // convert pdf pt to pixel
										top: fieldContent.top * pdfPtToPixel,
										width: fieldContent.width * pdfPtToPixel,
										height: fieldContent.height * pdfPtToPixel,
									};
									// add the position to content data
									formatedContent.position = f.position;
								}
								return f;
							});
						}

						// if fieldContent.editorHtml (grid field only) has value, we need to retrieve its data
						if (fieldContent.editorHtml) {
							let gridData = retrieveGridTableData({ editorHtml: fieldContent.editorHtml });
							if (gridData)
								formatedContent = {
									...formatedContent,
									...gridData,
									fontSize: fieldContent.fontSize,
								};
							// Could "else" case ever happen?
							// Ok, decision for now (on 20/05/2021), if "else" case happens, following behaviours to handle it:
							// 	- we will continue to use "editorHtml" for table editor in preview panel
							//	- we will leave "tableData" be undefined, so no data is rendered from output (preview output and pdf output)
							// so nothing needs to do in code for the behaviours above
						}

						if (Object.keys(formatedContent).length > 0)
							return { ...accu, [field.id]: formatedContent };
						else return accu;
					}, {});
					let templateBGContent = null;
					if (artContentData.templateBGcontents) {
						templateBGContent = {
							mediafileId: artContentData.templateBGcontents.mediafileId, // mediafile id
							mediafilePreviewUrl: artContentData.templateBGcontents.mediafilePreviewUrl, //  preview url
							mediafileHighResUrl: artContentData.templateBGcontents.mediafileHighResUrl, // high-resolution url of mediafile (for generating high quality pdf)
							mediafileOptimisedUrl: artContentData.templateBGcontents.mediafileOptimisedUrl, // optimised url of mediafile (fast loading & good quality for webpage)
							mediafilePdfUrl: artContentData.templateBGcontents.mediafilePdfUrl, // pdf url of the mediafile background
							mediafileSvgUrl: artContentData.templateBGcontents.mediafileSvgUrl, // svg url of the background mediafile
						};
					}
					let contentS3Path = artContentData.s3Path.trim();
					let bucketInS3Path = getBucketFromS3Url(contentS3Path);
					let bucketString = `s3://${bucketInS3Path}`;
					let basePathInS3Path = contentS3Path.substring(bucketString.length + 1); // plus 1 is to remove the leading slash

					setFieldInputData(fieldsContent);
					if (templateBGContent) setTemplateBGInputData(templateBGContent);
					setArtContent({
						id: artContentData.id,
						name: artContentData.name,
						s3Path: artContentData.s3Path,
						s3Bucket: bucketInS3Path,
						basePath: basePathInS3Path,
					});
					setArtMode(EDIT_MODE);
				}
				// Final Step: ok, at this point, we got final template fields (it is also updated with user customized position if applicable)
				setTemplateFields(sortedTemplateFields);
			} catch (err) {
				setInitStatus({
					status: 'FAILED',
					message: err.error ? err.error.message : err.message,
				});
			}
		};
		// reset spreadsheet content in case there was uncleaned data
		resetSpreadsheetContentById();
		initData().finally(() => {
			// post jobs after initializing
			isInitializedRef.current = true;
		});

		return () => {
			// componentWillUnmount event
			resetDesignerTemplate();
			resetArtworkFonts();
			resetArtworkSpreadsheets();
			resetArtworkLists();
			resetArtworkAutoImport();
			resetArtworkOutputTemplates();
			resetArtworkCategories();
			resetSpreadsheetContentById();
			// resetAWSCredential(S3_RESOURCE);
			// resetSaveArtworkFileStatus();
			if (userInputDataRef.current) cleanCroppedImgUrls(userInputDataRef.current);
		};
	}, [
		fetchArtworkAutoImport,
		fetchArtworkCategories,
		fetchArtworkFonts,
		fetchArtworkLists,
		fetchArtworkOutputTemplates,
		fetchArtworkSpreadsheets,
		getDesignerTemplate,
		intl,
		location.search,
		resetArtworkAutoImport,
		resetArtworkCategories,
		resetArtworkFonts,
		resetArtworkLists,
		resetArtworkOutputTemplates,
		resetArtworkSpreadsheets,
		resetDesignerTemplate,
		resetSpreadsheetContentById,
	]);

	useEffect(() => {
		if (videoArtworkVideoRef.current) {
			if (allowAnimation) {
				videoArtworkVideoRef.current.currentTime = 0;
				videoArtworkVideoRef.current.play();
			} else {
				videoArtworkVideoRef.current.pause();
				videoArtworkVideoRef.current.currentTime = 0;
			}
		}
	}, [allowAnimation]);

	useEffect(() => {
		if (psWrapper.elemRef && psWrapper.elemRef.current) updateWorkspaceRect();
	}, [psWrapper.elemRef, updateWorkspaceRect, workspaceContainerRef]);

	// Effect by calculated workspace position
	useEffect(() => {
		// update perfect scrollbar
		if (psWrapper.ps) psWrapper.ps.update();
	}, [WSRect, psWrapper.ps]);

	useEffect(() => {
		setZoomControls({ isFit: true });
	}, [isMobileView, updateWorkspaceRect]);

	useEffect(() => {
		if (
			cropImage.fieldId &&
			(selectedFieldIds.length > 1 ||
				(selectedFieldIds.length === 1 && cropImage.fieldId !== selectedFieldIds[0]))
		) {
			notifyGeneral(
				intl.formatMessage({
					id: 'pages.Artwork.SHAREABLE.finishCroppingImageWarning',
				}),
				'error'
			);
		}
	}, [cropImage.fieldId, intl, notifyGeneral, selectedFieldIds]);

	// update userInputDataRef on fieldInputData change
	React.useEffect(() => {
		userInputDataRef.current = fieldInputData;
	}, [fieldInputData]);

	useEffect(() => {
		if (isInitializedRef.current) {
			setHasUnsavedData(true);
		}
	}, [fieldInputData, templateBGInputData]);

	// Notification of unsaved changes
	useEffect(() => {
		if (hasUnsavedData) {
			const beforeunloadHandler = (e) => {
				e.preventDefault();
				e.returnValue = '';
			};
			window.addEventListener('beforeunload', beforeunloadHandler);
			// eslint-disable-next-line no-unused-vars
			const historyUnblock = history.block((newLocation) => {
				if (
					newLocation.pathname !== location.pathname ||
					newLocation.search !== location.search ||
					newLocation.hash !== location.hash
				) {
					return intl.formatMessage({
						id: 'pages.Artwork.components.General.unsavedChangesMsg',
					});
				}
			});
			return () => {
				window.removeEventListener('beforeunload', beforeunloadHandler);
				historyUnblock();
			};
		}
	}, [hasUnsavedData, history, intl, location.hash, location.pathname, location.search]);

	useEffect(() => {
		isAIImageUploadStatusRef.current = aIImageUploadStatus;
	}, [aIImageUploadStatus]);

	// handler of Goback
	const handleGoback = () => {
		if (location.state && location.state.fromPath) {
			history.push(location.state.fromPath);
		} else {
			history.go(-1);
		}
	};

	const handleFieldSelect = (fieldId) => {
		// console.log('selected field ' + fieldId);
		if (fieldId) setSelectedFieldIds([fieldId]);
		// append = false; // we don't support multiple selection for the time being
		// if (fieldId) {
		// 	if (!append) setSelectedFieldIds([fieldId]);
		// 	else setSelectedFieldIds([...selectedFieldIds, fieldId]);
		// }
	};

	// handler of element (field) selection clickaway
	const handleFieldSelectClickAway = (e) => {
		if (
			e.target === workspaceWrapperRef.current ||
			workspaceWrapperRef.current.contains(e.target)
		) {
			setSelectedFieldIds([]);
		}
	};

	/**
	 * handle field resized
	 * @param {array of field object} resizedFields. The full dataset of the updated resized fields that were resized (selected fields)
	 */
	const handleFieldResizeStop = (resizedFields = []) => {
		let fields = templateFields.map(
			(field) => _.find(resizedFields, (f) => f.id === field.id) || field
		);
		// update user input data with the field new position (this position data is only acting as part of field content)
		let newFieldInputData = resizedFields.reduce((accu, f) => {
			return { ...accu, [f.id]: { ...(accu[f.id] || {}), position: f.position } };
		}, fieldInputData);
		setTemplateFields(fields);
		setFieldInputData(newFieldInputData);

		// let fields = artworkDesignerTemplate.fields.map(
		// 	field => _.find(resizedFields, f => f.id === field.id) || field
		// );
		// updateLocalDesignerTemplate({ fields });
	};

	/**
	 * handle element (field) dragged
	 * @param {array of field ID string} fieldIds. ID of fields that were dragged
	 * @param {Number in pixel} deltaX. X coordination diff to oroginal position in the current zoom
	 * @param {Number in pixel} deltaY. Y coordination diff to oroginal position in the current zoom
	 */
	const handleFieldDragStop = (fieldIds, deltaX, deltaY) => {
		let newFieldInputData = { ...fieldInputData };
		let fields = templateFields.map((f) => {
			if (fieldIds.includes(f.id)) {
				let newPosition = {
					...f.position,
					left: f.position.left + deltaX / WSRect.zoom,
					top: f.position.top + deltaY / WSRect.zoom,
				};
				// update user input data with the field new position (this position data is only acting as part of field content)
				newFieldInputData[f.id] = { ...(newFieldInputData[f.id] || {}), position: newPosition };
				return { ...f, position: newPosition };
			} else {
				return f;
			}
		});
		setFieldInputData(newFieldInputData);
		setTemplateFields(fields);

		// updateLocalDesignerTemplate({ fields });
	};

	const handleZoomChange = (newZoom) => {
		updateWorkspaceRect(newZoom);
		setZoomControls({ isFit: newZoom === 0 });
	};

	/**
	 * handle product picker selection.
	 * Set autoImport field data by selected spreadsheet row content
	 * @param {string} productPickerField. product picker field
	 * @param {object} ssRowData. Spreadsheet selected row data
	 format (NB: columnId is "columnIndex" or  "$ref:{columnIndex}"):
	 	{
			[columnId]: {
				"value": "Cadbury Creme Egg",
				"mediafileId": "23554",
				"previewUrl": "https://xxxxx",
				"optimisedUrl": "https://xxxx",
				"highResUrl": "https://xxxx"
			},
			...
		}
		* @return {null}
	 */

	const handleProductPickerSelection = React.useCallback(
		(productPickerField, ssRowData) => {
			let newFieldInputData = renewFieldInputDataBySelectedSpreadsheet(
				productPickerField,
				templateFields,
				fieldInputData,
				ssRowData
			);
			setFieldInputData(newFieldInputData);
			// // merge the new input data with field default data
			// const mergedFieldOutData = getFieldOutputData(templateFields, newFieldInputData);

			// // if (!hideFieldOutput(field, templateFields, mergedFieldOutData)) {
			// // 2. loop through all fields, calculate input data in "price clac" & concatenation fields
			// // let calculatedFieldInputData = {};
			// templateFields.forEach(field => {
			// 	if (
			// 		field.type === 'text' &&
			// 		field.calcValue.price &&
			// 		field.calcValue.unit &&
			// 		field.calcValue.qty
			// 	) {
			// 		// this is "price calc" field
			// 		let calculatedValue =
			// 			Number(mergedFieldOutData[field.calcValue.price].value) *
			// 			Number(mergedFieldOutData[field.calcValue.unit].value) *
			// 			Number(mergedFieldOutData[field.calcValue.qty].value);
			// 		newFieldInputData[field.id] = {
			// 			...(newFieldInputData[field.id] || {}),
			// 			value: calculatedValue,
			// 		};
			// 	}

			// 	if (field.type === 'text' && new RegExp(/<field:\s*(.*?)\s*>/gim).test(field.defaultValue)) {
			// 		// this is "concatenation" field
			// 		let concatText = field.defaultValue,
			// 			matches = [],
			// 			reg = /<field:\s*(.*?)\s*>/gim,
			// 			m = null;
			// 		do {
			// 			m = reg.exec(field.defaultValue);
			// 			if (m) {
			// 				matches.push({ matchedText: m[0], captureValue: m[1], fieldId: m[1].split('_').pop() });
			// 			}
			// 		} while (m);

			// 		// loop through all matched text, replace it with the coresponding field value
			// 		matches.forEach(match => {
			// 			concatText = concatText.replace(
			// 				match.matchedText,
			// 				mergedFieldOutData[match.fieldId].value || ''
			// 			);
			// 		});

			// 		newFieldInputData[field.id] = {
			// 			...(newFieldInputData[field.id] || {}),
			// 			value: concatText,
			// 		};
			// 	}
			// });
			// // }
		},
		[fieldInputData, templateFields]
	);

	// ##############################
	// variables
	// #############################
	// let cropImageField = cropImage.fieldId
	// 	? templateFields.filter(f => f.id === cropImage.fieldId)[0]
	// 	: null;
	// // all font faces used in template "text" fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	// let fontlistInTemplate = Array.from(
	// 	new Set(
	// 		templateFields
	// 			.filter(f => f.type === 'text')
	// 			.map(f =>
	// 				!f.formatNumberStyle.currencyFontName ||
	// 				f.formatNumberStyle.currencyFontName === ART_VARIABLES.placeholderSameAsText
	// 					? [f.fontfaceName]
	// 					: [f.fontfaceName, f.formatNumberStyle.currencyFontName]
	// 			)
	// 			.flat()
	// 	)
	// )
	// 	.map(fontName => {
	// 		let font = _.find(artworkExtra.fonts || [], artFont => artFont.font === fontName);
	// 		return font ? { name: fontName, fontUrl: font.path } : null;
	// 	})
	// 	.filter(item => item);

	let cropImageField = null,
		artworkOutputTemplate = {};
	for (let i = 0; i < templateFields.length; i++) {
		let field = templateFields[i];
		// get the output template settings (most likely for esign template). Only do it on first occurs
		if (
			!artworkOutputTemplate.id &&
			artworkDesignerTemplate.outputTemplate &&
			Number(artworkDesignerTemplate.outputTemplate || 0) > 0
		) {
			for (let j = 0; j < artworkExtra.outputTemplates.length; j++) {
				if (artworkExtra.outputTemplates[j].id === Number(artworkDesignerTemplate.outputTemplate)) {
					artworkOutputTemplate = artworkExtra.outputTemplates[j];
					break;
				}
			}
		}

		// find field that is cropping image
		if (cropImage.fieldId && field.id === cropImage.fieldId) {
			cropImageField = field;
		}
	}

	// // all font faces used in template "text" fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	// let fontlistInTemplate = Array.from(new Set(fontNamesInTemplate))
	// 	.map(fontName => {
	// 		let font = _.find(artworkExtra.fonts || [], artFont => artFont.font === fontName);
	// 		return font ? { name: fontName, fontUrl: font.path } : null;
	// 	})
	// 	.filter(item => item);

	// field output data (combination of user input data & field default data)
	const fieldOutputData = getFieldOutputData(templateFields, fieldInputData);
	const canPrint = !artworkDesignerTemplate.videoArtwork && isPrintableTemplate(templateFields);

	/**
	 * This section is to generate test print data for esign print (print to output template)
	 */
	// let testFieldsData = [];
	// let testFieldId = 0;
	// let keys = Object.keys(fieldOutputData);
	// for (let i = keys.length - 1; i > 0; i--) {
	// 	if (fieldOutputData[keys[i]].value) {
	// 		testFieldId = keys[i];
	// 		break;
	// 	}
	// }
	// if (testFieldId !== 0) {
	// 	for (let i = 0; i < 20; i++) {
	// 		testFieldsData.push({
	// 			data: {
	// 				...fieldOutputData,
	// 				[testFieldId]: {
	// 					...fieldOutputData[testFieldId],
	// 					value: fieldOutputData[testFieldId].value + '--' + i,
	// 				},
	// 			},
	// 			num: 1,
	// 		});
	// 		// testFieldsData.push({
	// 		// 	...fieldsPreviewValue,
	// 		// 	[testFieldId]: {
	// 		// 		...fieldsPreviewValue[testFieldId],
	// 		// 		value: fieldsPreviewValue[testFieldId].value + '--' + i,
	// 		// 	},
	// 		// });
	// 	}
	// }

	// Performance Hint: Changing data in context will trigger re-render in child compoent which has useContext hook. Hence usually we will only place "READ-ONLY" value in context.
	// ref: https://reactjs.org/docs/hooks-reference.html#usecontext
	// const contextValues = { notifyGeneral };

	const getMaxSpecifyQtyToPrintQueue = () => {
		let maxSpecifyQty = 10,
			plannedQty = 1;
		if (Object.keys(artworkOutputTemplate).length > 0) {
			let outputPlan = outputTemplatePlan(artworkDesignerTemplate.dimension, artworkOutputTemplate);
			plannedQty = outputPlan.numOfItemsInPage;
		}
		return Math.max(maxSpecifyQty, plannedQty);
	};
	const uploadCroppedImgToS3 = async ({ s3Client, fieldInputDataVal }) => {
		const MIN_S3_UPLOAD_PART = 6 * 1024 * 1024; // 6mb, minimum part size on uploading to avoid AWS S3 SDK error
		let blobUrlsToClean = [];
		let newFieldInputData = { ...fieldInputDataVal };
		let s3BucketCroppedImg = (getDomainConfig(domainName) || {})['s3BucketCroppedImg'];
		let s3FilePathPrefixCroppedImg = (getDomainConfig(domainName) || {})['s3CroppedImgPathPrefix'];
		for (const fieldId in fieldInputDataVal) {
			if (isBlobUrl(fieldInputDataVal[fieldId].croppedImgUrl || '')) {
				let s3FileKey = `${s3FilePathPrefixCroppedImg}/${domainName}/${
					artworkDesignerTemplate.mediaId
				}/${fieldId}_${genRandomStr(12)}.png`;
				let croppedImgBlob = await fetch(fieldInputDataVal[fieldId].croppedImgUrl).then((r) =>
					r.blob()
				);

				let s3FileParams = {
					Bucket: s3BucketCroppedImg,
					Key: s3FileKey,
					Body: croppedImgBlob,
					ContentType: 'image/png',
					ContentLength: croppedImgBlob.size,
				};

				await s3Client
					.upload(s3FileParams, {
						partSize: Math.max(s3FileParams.ContentLength + 1 * 1024 * 1024, MIN_S3_UPLOAD_PART),
						queueSize: 1,
					})
					.promise();

				// get web url of the cropped image
				let croppedImgWebUrl = getCloudFrontUrlOfS3File({
					Bucket: s3BucketCroppedImg,
					Key: s3FileKey,
				});

				// add blobUrl for cleanup
				blobUrlsToClean.push(fieldInputDataVal[fieldId].croppedImgUrl);

				// update field input data
				newFieldInputData = {
					...newFieldInputData,
					[fieldId]: { ...newFieldInputData[fieldId], croppedImgUrl: croppedImgWebUrl },
				};
			}
		}
		return { newFieldInputData, blobUrlsToClean };
	};

	const saveVideoArtwork = async (fileName) => {
		// validation before actually processing it
		if (!fileName) {
			notifyGeneral(
				intl.formatMessage({
					id: 'pages.Artwork.components.Create.requireFilenameErrorMsg',
				}),
				'error'
			);
			return null;
		}
		let s3Bucket = (getDomainConfig(domainName) || {})['s3Bucket'];
		if (!s3Bucket) {
			notifyGeneral(
				intl.formatMessage({
					id: 'pages.Artwork.components.Create.domainNameNotFoundErrorMsg',
				}),
				'error'
			);
			return null;
		}

		try {
			const savedMediafileId = await createVideoArtwork({
				fileName,
				fieldInputData,
				artworkTemplate: artworkDesignerTemplate,
				templateFields,
				artworkAvailableFonts: artworkExtra.fonts,
				domainName,
				intl,
				userId: userData.uid,
				setExportArtworkStatus,
				ART_VARIABLES,
			});

			const msg = (
				<div>
					<Typography variant="body1" component="div">
						{intl.formatMessage({
							id: 'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessful',
						})}
					</Typography>
					<Typography variant="body2" component="div">
						{intl.formatMessage({
							id: 'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessfulAside',
						})}

						<Link
							style={{ cursor: 'pointer' }}
							underline="always"
							color="inherit"
							variant="inherit"
							onClick={() => {
								window.location.assign(getRouteMediafileDetail(savedMediafileId));
							}}
						>
							{intl.formatMessage({
								id: 'pages.Artwork.components.Create.clickHereBtnText',
							})}
						</Link>
						{intl.formatMessage({
							id:
								'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessfulLinkText2',
						})}
					</Typography>
				</div>
			);

			// Artwork was saved. Reset the exporting status
			setExportArtworkStatus({});
			notifyGeneral(msg, 'success', { autoHideDuration: null });
			setHasUnsavedData(false);
			reSyncCounter();
		} catch (err) {
			setExportArtworkStatus({
				status: 'FAILED',
				message: intl.formatMessage(
					{
						id: 'pages.Artwork.components.Create.savingProgressErrMsg',
					},
					{ errMsg: err.message }
				),
			});
		}
	};

	// This function will check if the image uploading is completed or not
	const fetchUploadStatus = () => {
		return new Promise((resolve) => {
			const checkStatus = () => {
				setTimeout(() => {
					if (!isAIImageUploadStatusRef.current) {
						resolve();
					} else {
						checkStatus();
					}
				}, 3000);
			};
			checkStatus();
		});
	};
	/**
	 * Server-side artwork pdf generation and save
	 * @param {string} fileName. Note: If not specified, it is for saving to existing artwork in "EDIT" mode; if specified, it is for saving a new artwork file & Save As cases
	 * @returns
	 */
	const saveArtworkAtServer = async (fileName) => {
		let fieldInputDataVal = '';
		// If the value is true that means AI generated image is being uploaded in the background.
		if (isAIImageUploadStatusRef.current || aIImageUploadStatus) {
			setExportArtworkStatus({
				status: 'OK',
				message: intl.formatMessage({
					id: 'pages.Artwork.components.Create.AIImageUploadMsg',
				}),
			});
			await fetchUploadStatus(); // Wait for the aIImageUploadStatus to change from true to false
			fieldInputDataVal = userInputDataRef.current;
		} else {
			fieldInputDataVal = fieldInputData;
		}
		let s3Bucket =
			artMode === EDIT_MODE ? artContent.s3Bucket : (getDomainConfig(domainName) || {})['s3Bucket'];
		if (!s3Bucket) {
			notifyGeneral(`Can't find the domain name`, 'error');
			return null;
		}
		let s3BasePath =
			artMode === EDIT_MODE
				? artContent.basePath
				: `${domainName}/${moment.utc().format('YYYYMMDD')}/${genRandomStr(28)}/`;

		let saveToExistingMediafileId = !fileName && artMode === EDIT_MODE ? artContent.id : null;

		try {
			const s3Client = await awsCreateS3Client();
			const { newFieldInputData, blobUrlsToClean } = await uploadCroppedImgToS3({
				s3Client,
				fieldInputDataVal,
			}).catch((err) => {
				throw new Error(
					intl.formatMessage(
						{
							id: 'pages.Artwork.components.Create.CropImageError',
						},
						{ errMsg: err.message }
					)
				);
			});
			// if blobUrlsToClean.length > 0, the croppedImgUrls are changed to web url from blobUrl, hence need to update input data
			// we update the fieldInputData immediately after the new input data, in case that anything fails in subsequent stages
			if (blobUrlsToClean.length > 0) {
				setFieldInputData(newFieldInputData);
				blobUrlsToClean.forEach((blobUrl) => {
					deleteCroppedImgUrl(blobUrl);
				});
			}
			const { createdMediafieldId } = await createArtworkAtServer({
				fileName,
				saveToExistingMediafileId,
				s3Bucket,
				s3BasePath,
				userId: userData.uid,
				fieldInputData: newFieldInputData,
				artworkTemplate: artworkDesignerTemplate,
				templateFields,
				templateBGInputData,
				templateBGOutputDataWithSettings,
				artworkAvailableFonts: artworkExtra.fonts,
				intl,
				setExportArtworkStatus,
			});
			let msg = (
				<Typography variant="body1" component="div">
					{intl.formatMessage({
						id: 'pages.Artwork.components.Create.savingSuccessfulMsg',
					})}
					<Link
						style={{ cursor: 'pointer' }}
						underline="always"
						color="inherit"
						variant="inherit"
						onClick={() => {
							window.location.assign(getRouteMediafileDetail(createdMediafieldId));
						}}
					>
						{intl.formatMessage({
							id: 'pages.Artwork.components.Create.clickHereBtnText',
						})}
					</Link>
					{intl.formatMessage({
						id: 'pages.Artwork.components.Create.savingSuccessfulToViewText',
					})}
				</Typography>
			);
			// Artwork was saved. Reset the exporting status
			setExportArtworkStatus({});
			notifyGeneral(msg, 'success', { autoHideDuration: null });
			// clean up
			setHasUnsavedData(false);

			// VID-3544 BUG 8: after user saves the first artwork, we will treat the subsequent user operations as editing
			// we support save & save-as, in case save-as in EDIT_MODE, we need to treat the save-as file as the mediafile for EDIT_MODE
			// so we don't check if the current artMode is on CREATE_MODE, we always set it to EDIT_MODE with the newly createdMediafieldId
			setArtContent({
				id: createdMediafieldId, //artContentData.id,
				name: fileName || artContent.name, //artContentData.name,
				s3Path: `s3://${s3Bucket}/${s3BasePath}`, //artContentData.s3Path,
				s3Bucket: s3Bucket, //bucketInS3Path,
				basePath: s3BasePath, // basePathInS3Path,
			});
			setArtMode(EDIT_MODE);
			reSyncCounter();
		} catch (err) {
			setExportArtworkStatus({
				status: 'FAILED',
				message: `Failed to save your artwork. ${err.message}`,
			});
			console.debug(err);
		}
	};
	/**
	 * Save artwork file
	 * 	- generate pdf & svg on browser side
	 * 	- it is not used, keep it for reference in case we want to use browser-side artwork generation again
	 * @param {string} fileName. Note: If not specified, it is for saving to existing artwork in "EDIT" mode; if specified, it is for saving a new artwork file & Save As cases
	 * @returns
	 */
	// const saveArtwork = async (fileName) => {
	// 	let s3Bucket =
	// 		artMode === EDIT_MODE ? artContent.s3Bucket : (getDomainConfig(domainName) || {})['s3Bucket'];
	// 	if (!s3Bucket) {
	// 		notifyGeneral(`Can't find the domain name`, 'error');
	// 		return null;
	// 	}

	// 	// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	// 	let fontlistInTemplate = geFontListInTemplateFields(
	// 		templateFields,
	// 		artworkExtra.fonts,
	// 		ART_VARIABLES
	// 	);
	// 	let colorList = getColorListInTemplateFields(templateFields);
	// 	setExportArtworkStatus({
	// 		status: 'OK',
	// 		message: 'Preparing artwork data...',
	// 	});
	// 	const MIN_UPLOAD_PART = 6 * 1024 * 1024; // 6mb, minimum part size on uploading to avoid AWS S3 SDK error

	// 	try {
	// 		const s3Client = await awsCreateS3Client();
	// 		const { newFieldInputData, blobUrlsToClean } = await uploadCroppedImgToS3({
	// 			s3Client,
	// 			fieldInputData,
	// 		}).catch((err) => {
	// 			throw new Error(`Can't save cropped images [${err.message}]`);
	// 		});
	// 		// if blobUrlsToClean.length > 0, the croppedImgUrls are changed to web url from blobUrl, hence need to update input data
	// 		// we update the fieldInputData immediately after the new input data, in case that anything fails in subsequent stages
	// 		if (blobUrlsToClean.length > 0) {
	// 			setFieldInputData(newFieldInputData);
	// 			blobUrlsToClean.forEach((blobUrl) => {
	// 				deleteCroppedImgUrl(blobUrl);
	// 			});
	// 		}
	// 		const fieldOutputData = getFieldOutputData(templateFields, newFieldInputData);
	// 		// get pdf blob
	// 		let pdfBlob = await exportArtworkToPdf(templateFields, fieldOutputData, {
	// 			size: [
	// 				artworkDesignerTemplate.dimension.width * pixelToPdfPt, // in pdf point by converting pixel to pdf point (72 dpi in web to 300dpi in pdf)
	// 				artworkDesignerTemplate.dimension.height * pixelToPdfPt,
	// 			],
	// 			templateSize: artworkDesignerTemplate.dimension, // in pixel
	// 			// templateBGUrl: artworkDesignerTemplate.templateBGUrl,
	// 			// templateMediaUrl: artworkDesignerTemplate.templateMediaUrl,
	// 			templatePdfUrl: templateBGOutputDataWithSettings.mediafilePdfUrl, // artworkDesignerTemplate.templatePdfUrl,
	// 			templateSvgUrl: templateBGOutputDataWithSettings.mediafileSvgUrl, //artworkDesignerTemplate.templateSvgUrl,
	// 			fontList: fontlistInTemplate,
	// 			colorList: colorList,
	// 			// viewBoxWidth: artworkDesignerTemplate.dimension.width,
	// 			// viewBoxHeight: artworkDesignerTemplate.dimension.height,
	// 			CONSTANTS: { placeholderSameAsText: ART_VARIABLES.placeholderSameAsText },
	// 		});

	// 		// // download the pdf file
	// 		// const DOMURL = window.URL || window.webkitURL || window;
	// 		// let pdfBlobUrl = DOMURL.createObjectURL(pdfBlob);
	// 		// triggerDownload(pdfBlobUrl, [
	// 		// 	{ name: 'download', value: `untitled.pdf` },
	// 		// 	{ name: 'target', value: '_blank' },
	// 		// ]);
	// 		// // clean up
	// 		// DOMURL.revokeObjectURL(pdfBlobUrl);
	// 		// pdfBlobUrl = null;
	// 		// pdfBlob = null;

	// 		// setExportArtworkStatus({
	// 		// 	status: 'OK',
	// 		// 	message: 'Creating artwork file...',
	// 		// });

	// 		// get svg blob
	// 		let svgBlob = await exportArtworkToSVG(templateFields, fieldOutputData, {
	// 			templateSize: artworkDesignerTemplate.dimension, // in pixel
	// 			// templateBGUrl: artworkDesignerTemplate.mediaUrl,
	// 			templateMediaUrl: templateBGOutputDataWithSettings.mediafileOptimisedUrl, //artworkDesignerTemplate.templateMediaUrl,
	// 			// templatePdfUrl: artworkDesignerTemplate.templatePdfUrl,
	// 			templateSvgUrl: templateBGOutputDataWithSettings.mediafileSvgUrl, //artworkDesignerTemplate.templateSvgUrl,
	// 			// downloadName: 'mysvg.svg',
	// 			animations: ART_VARIABLES.animations,
	// 			CONSTANTS: {
	// 				placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
	// 				DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
	// 				DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY,
	// 			},
	// 			fontList: fontlistInTemplate,
	// 			colorList: colorList,
	// 		});

	// 		// // download svg file
	// 		// const svgBlobUrl = DOMURL.createObjectURL(svgBlob);
	// 		// triggerDownload(svgBlobUrl, [
	// 		// 	{ name: 'download', value: 'untitled.svg' },
	// 		// 	{ name: 'target', value: 'download' },
	// 		// ]);
	// 		// // clean up
	// 		// DOMURL.revokeObjectURL(svgBlobUrl);
	// 		// svgBlob = null;

	// 		// upload to s3
	// 		setExportArtworkStatus({
	// 			status: 'OK',
	// 			message: 'Saving artwork file...',
	// 		});
	// 		// let cred = await new Promise((_res, _rej) => {
	// 		// 	fetchAWSCredential(S3_RESOURCE, ({ result, error }) => {
	// 		// 		if (error) _rej(error);
	// 		// 		else _res(result.data.credential);
	// 		// 	});
	// 		// });
	// 		// let s3Client = createS3Client({
	// 		// 	accesskey: cred.AccessKeyId, // required
	// 		// 	secretkey: cred.SecretAccessKey, // required
	// 		// 	sessionToken: cred.SessionToken, // optional
	// 		// 	domain: domainName, // optional
	// 		// });
	// 		let basePath =
	// 			artMode === EDIT_MODE
	// 				? artContent.basePath
	// 				: `${domainName}/${moment.utc().format('YYYYMMDD')}/${genRandomStr(28)}/`;
	// 		let pdfFileKey = `${basePath}${genRandomStr(12)}.pdf`;
	// 		let svgFileKey = `${basePath}${genRandomStr(12)}.svg`;

	// 		let params = [
	// 			{
	// 				Bucket: s3Bucket,
	// 				Key: pdfFileKey,
	// 				Body: pdfBlob,
	// 				ContentType: 'application/pdf',
	// 				ContentLength: pdfBlob.size,
	// 			},
	// 			{
	// 				Bucket: s3Bucket,
	// 				Key: svgFileKey,
	// 				Body: svgBlob,
	// 				ContentType: 'image/svg+xml',
	// 				ContentLength: svgBlob.size,
	// 			},
	// 		];
	// 		// NOTE: Keep partSize 1MB bigger than file size so that eTag from s3 is md5 of file
	// 		await Promise.all(
	// 			params.map((param) =>
	// 				s3Client
	// 					.upload(param, {
	// 						partSize: Math.max(param.ContentLength + 1 * 1024 * 1024, MIN_UPLOAD_PART),
	// 						queueSize: 1,
	// 					})
	// 					.promise()
	// 			)
	// 		);
	// 		// Save file in API
	// 		let templateId = artworkDesignerTemplate.mediaId.toString();
	// 		let bodyParams = {
	// 			// domain: domainName,
	// 			// name: fileName,
	// 			fileContents: formatArtworkContentForFileMgrApi(newFieldInputData),
	// 			// insert template BG content
	// 			...(isNullish(templateBGInputData.mediafileId)
	// 				? {}
	// 				: {
	// 						templateBGcontents: {
	// 							mediafileId: templateBGInputData.mediafileId,
	// 							...(isNullish(templateBGInputData.mediafilePreviewUrl)
	// 								? {}
	// 								: { mediafilePreviewUrl: templateBGInputData.mediafilePreviewUrl }),
	// 							...(isNullish(templateBGInputData.mediafileHighResUrl)
	// 								? {}
	// 								: { mediafileHighResUrl: templateBGInputData.mediafileHighResUrl }),
	// 							...(isNullish(templateBGInputData.mediafileOptimisedUrl)
	// 								? {}
	// 								: { mediafileOptimisedUrl: templateBGInputData.mediafileOptimisedUrl }),
	// 							...(isNullish(templateBGInputData.mediafilePdfUrl)
	// 								? {}
	// 								: { mediafilePdfUrl: templateBGInputData.mediafilePdfUrl }),
	// 							...(isNullish(templateBGInputData.mediafileSvgUrl)
	// 								? {}
	// 								: { mediafileSvgUrl: templateBGInputData.mediafileSvgUrl }),
	// 						},
	// 				  }),
	// 			// createdByUid: userData.uid,
	// 			files: [
	// 				{
	// 					s3Url: `s3://${s3Bucket}/${pdfFileKey}`,
	// 					size: pdfBlob.size,
	// 					fileType: 'pdf',
	// 				},
	// 				{
	// 					s3Url: `s3://${s3Bucket}/${svgFileKey}`,
	// 					size: svgBlob.size,
	// 					fileType: 'svg',
	// 				},
	// 			],
	// 		};

	// 		let savedMediafileId = '';
	// 		if (fileName && (artMode === CREATE_MODE || artMode === EDIT_MODE)) {
	// 			// Save new artwork & Save As cases
	// 			// bodyParams.domain = domainName;
	// 			bodyParams.name = fileName;
	// 			bodyParams.createdByUid = userData.uid;
	// 			let saveNewArtworkResponse = await artworkSaveArtwork({ templateId, bodyParams }).catch(
	// 				(err) => {
	// 					throw new Error(err.response ? err.response.data.message : err.message);
	// 				}
	// 			);
	// 			savedMediafileId = saveNewArtworkResponse.data.mediafileId;
	// 		} else if (!fileName && artMode === EDIT_MODE) {
	// 			// Save to existing artwork
	// 			// bodyParams.domain = domainName;
	// 			bodyParams.updatedByUid = userData.uid;
	// 			await artworkPutArtContent({ mediafileId: artContent.id, bodyParams }).catch((err) => {
	// 				throw new Error(err.response ? err.response.data.message : err.message);
	// 			});
	// 			savedMediafileId = artContent.id;
	// 			// update mediafile in screens api in case it is used in slides
	// 			let slideUrl = getCloudFrontUrlOfS3File(`s3://${s3Bucket}/${svgFileKey}`);
	// 			if (artworkDesignerTemplate.originalTemplate.fileType.toString() === 'image') {
	// 				slideUrl = getPreviewUrl({
	// 					originalMediafileUrl: `s3://${s3Bucket}/${pdfFileKey}`,
	// 					redirect: true,
	// 					processnow: false,
	// 					size: 'lores',
	// 				});
	// 			}
	// 			await screensPatchMediafileAsset({
	// 				mediafileId: savedMediafileId,
	// 				bodyParams: {
	// 					domain: domainName,
	// 					type: artworkDesignerTemplate.originalTemplate.fileType.toString(),
	// 					mediaUrl: slideUrl,
	// 					mediaPreviewUrl: getPreviewUrl({
	// 						originalMediafileUrl: `s3://${s3Bucket}/${pdfFileKey}`,
	// 						redirect: true,
	// 						processnow: false,
	// 						size: 'medium',
	// 					}),
	// 				},
	// 			}).catch((err) => {
	// 				if (err.response && err.response.data.status === 404) {
	// 					// the mediafile is not used in screen api
	// 					return null;
	// 				} else {
	// 					throw new Error(err.response ? err.response.data.message : err.message);
	// 				}
	// 			});
	// 		} else {
	// 			return null;
	// 		}

	// 		let msg = (
	// 			<Typography variant="body1" component="div">
	// 				{`Your artwork file has been saved successfully. `}
	// 				<Link
	// 					// className={this.props.classes.hoverCursor}
	// 					style={{ /* marginLeft: 8,  */ cursor: 'pointer' }}
	// 					underline="always"
	// 					color="inherit"
	// 					variant="inherit"
	// 					// href="#"
	// 					// disableRipple
	// 					// disableElevation
	// 					// color="inherit"
	// 					// startIcon={<ViewFileIcon />}
	// 					onClick={() => {
	// 						// history.push({
	// 						// 	pathname: '/search/',
	// 						// 	search: `?id=${savedMediafileId}`,
	// 						// });
	// 						// return null;
	// 						window.location.assign(getRouteMediafileDetail(savedMediafileId));
	// 					}}
	// 				>
	// 					Click here
	// 				</Link>
	// 				{` to view file`}
	// 			</Typography>
	// 		);

	// 		// Artwork was saved. Reset the exporting status
	// 		setExportArtworkStatus({});
	// 		notifyGeneral(msg, 'success', { autoHideDuration: null });
	// 		// clean up
	// 		pdfBlob = null;
	// 		svgBlob = null;
	// 		setHasUnsavedData(false);

	// 		// VID-3544 BUG 8: after user saves the first artwork, we will treat the subsequent user operations as editing
	// 		// so set mode to EDIT_MODE if it is on CREATE_MODE
	// 		if (artMode === CREATE_MODE) {
	// 			setArtContent({
	// 				id: savedMediafileId, //artContentData.id,
	// 				name: fileName, //artContentData.name,
	// 				s3Path: `s3://${s3Bucket}/${basePath}`, //artContentData.s3Path,
	// 				s3Bucket: s3Bucket, //bucketInS3Path,
	// 				basePath: basePath, // basePathInS3Path,
	// 			});
	// 			setArtMode(EDIT_MODE);
	// 		}
	// 	} catch (err) {
	// 		setExportArtworkStatus({
	// 			status: 'FAILED',
	// 			message: `Failed to save your artwork. ${err.message}`,
	// 		});
	// 		console.debug(err);
	// 	}
	// };

	/**
	 *
	 * @param {array} printData. Format: [{data: fieldOutputData, num: 17}, ...]
	 * @returns {Promise} err. err is null if no error, otherwise err is Error
	 */
	const printArtwork = async (printData) => {
		// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
		let fontlistInTemplate = geFontListInTemplateFields(
			templateFields,
			artworkExtra.fonts,
			ART_VARIABLES
		);
		let colorList = getColorListInTemplateFields(templateFields);
		setExportArtworkStatus({
			status: 'OK',
			message: 'Preparing artwork data...',
		});
		const s3Client = await awsCreateS3Client();

		try {
			let pdfUrl = await generateMultipleArtworkToPdfPagesWithServer(templateFields, printData, {
				outputTemplate: artworkOutputTemplate,
				templateSize: artworkDesignerTemplate.dimension, // in pixel
				// templateBGUrl: artworkDesignerTemplate.mediaUrl,
				// templateMediaUrl: templateBGOutputDataWithSettings.mediafileHighResUrl, //artworkDesignerTemplate.templateMediaUrl,
				// templatePdfUrl: templateBGOutputDataWithSettings.mediafilePdfUrl, //artworkDesignerTemplate.templatePdfUrl,
				// templateSvgUrl: templateBGOutputDataWithSettings.mediafileSvgUrl, //artworkDesignerTemplate.templateSvgUrl,
				// downloadName: 'multiplePages.pdf',
				animations: ART_VARIABLES.animations,
				CONSTANTS: {
					placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
					DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
					DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY,
				},
				fontList: fontlistInTemplate,
				colorList: colorList,
				domain: domainName,
				s3Client,
				reportProgress: (report) => {
					setExportArtworkStatus({
						status: report.status,
						message: (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								{report.message.map((msg, idx) => {
									if (!msg) return null;
									return <div key={`multiplePdfReport-${idx}`}>{msg}</div>;
								})}
							</div>
						),
					});
				},
				// reportProgress: _.throttle(
				// 	report => {
				// 		// if (report.status === 'DONE') setExportArtworkStatus({});
				// 		// else setExportArtworkStatus(report);
				// 		setExportArtworkStatus(report);
				// 	},
				// 	500,
				// 	{ leading: true, trailing: false }
				// ),
			}).then(async (svgPages) => {
				const res = await generateArtworkPdfs({ bodyParams: svgPages });
				return res.data.pdfUrl;
			});

			// open print dialog in browser to print the pdf
			// const DOMURL = window.URL || window.webkitURL || window;
			// let pdfBlobUrl = DOMURL.createObjectURL(pdfBlob);
			// openPrintDialogForPdf(getCloudFrontUrlOfS3File(pdfS3Url));
			openPrintDialogForPdf(pdfUrl);

			// // clean up
			// DOMURL.revokeObjectURL(pdfBlobUrl);
			// pdfBlobUrl = null;
			// pdfBlob = null;

			// job done. Reset the exporting status
			setExportArtworkStatus({});
		} catch (err) {
			let errMsg = err.response?.data.message || err.message;
			setExportArtworkStatus({
				status: 'FAILED',
				message: `Failed to create your artwork. ${errMsg}`,
			});
			console.debug(err);
			return err;
		}
		// .catch(err => {
		// 	// notifyGeneral(`Failed to export pdf. ${err.message}`, 'error');
		// 	setExportArtworkStatus({ status: 'FAILED', message: err.message });
		// 	console.debug(err);
		// });
	};

	const hasAnim = React.useMemo(() => {
		return hasAnimation(templateFields, artworkDesignerTemplate.videoArtwork);
	}, [artworkDesignerTemplate.videoArtwork, templateFields]);

	const saveAIImage = React.useCallback(
		async (fieldId, inputData, fileName) => {
			if (inputData.mediafileId === '') {
				let reqData = {
					unzip: false,
					fileStatus: 'new',
					allowSocialMedia: false,
					allowScreen: false,
					// isArtworkTemplate: false,
					keywords: '',
					previewnow: true,
					// below is fixed value
					notifiable: false,
					// adminContactID: userId,
					ownerID: userData.uid,
					userID: userData.uid,
					domainName: domainName,
					domainUrl: window.location.origin,
					files: [
						{
							originalName: fileName,
							type: 'png',
							// url: res.url,
							url: inputData.previewUrl,
						},
					],
				};
				let dateObj = {
					startDate: startOfDay(new Date()),
					endDate: endOfDay(new Date()),
				};
				let todaysDateParam = {
					createdDatetimeFrom: dateObj.startDate.toISOString(),
					createdDatetimeTo: dateObj.endDate.toISOString(),
					createdByUIDs: [userData.uid],
				};
				let formattedMediafile = {};
				// Temporarily AI generated URL is set in the artwork
				setFieldInputData({
					...fieldInputData,
					[fieldId]: { ...fieldInputData[fieldId], ...inputData },
				});
				setAIImageUploadStatus(true);
				await uploadFileFromAI({ bodyParams: reqData })
					.then(async (response) => {
						todaysDateParam.s3Url = response.data.files[0].s3Url;
						let slideUrl = getPreviewUrl({
							originalMediafileUrl: response.data.files[0].s3Url,
							redirect: true,
							processnow: true,
							size: 'small',
						});
						let slideUrlOpt = getPreviewUrl({
							originalMediafileUrl: response.data.files[0].s3Url,
							redirect: true,
							processnow: true,
							size: 'lores',
						});
						let slideUrlHigh = getPreviewUrl({
							originalMediafileUrl: response.data.files[0].s3Url,
							redirect: true,
							processnow: false,
							size: 'original',
						});
						inputData.previewUrl = slideUrl;
						inputData.optimisedUrl = slideUrlOpt;
						inputData.highResUrl = slideUrlHigh;

						formattedMediafile = {
							previewUrl: slideUrl,
							optimisedUrl: slideUrlOpt,
							highResUrl: slideUrlHigh,
						};
						// const delay = () => {
						const fetchProgress = () => {
							return new Promise((resolve) =>
								setTimeout(() => {
									fetchProgressUploadedFiles(
										{ queueID: response.data.queueID },
										({ result, error }) => {
											if (!error && ['completed', 'failed'].includes(result.data.status)) {
												searchMediaFiles({
													bodyParams: {
														filters: todaysDateParam,
													},
												})
													.then((resp) => {
														formattedMediafile.mediafileId = resp.data.mediafiles[0].id;
														inputData.mediafileId = resp.data.mediafiles[0].id;
														// When the uploading is complete the URLs along are replaced with the temporary once
														setFieldInputData({
															...fieldInputData,
															[fieldId]: { ...fieldInputData[fieldId], ...inputData },
														});
														reSyncCounter();
														setAIImageUploadStatus(false);
														resolve();
													})
													.catch((err) => {
														console.log('err', err);
													});
											} else {
												fetchProgress();
											}
										}
									);
								}, 3000)
							);
						};
						fetchProgress();
					})
					.catch((err) => {
						notifyGeneral(
							intl.formatMessage({
								id: 'pages.Artwork.components.Create.AIImageUploadError',
							}),
							'error'
						);
						console.log(err);
					});
			} else {
				if (
					fieldInputData[fieldId] &&
					isBlobUrl(fieldInputData[fieldId].croppedImgUrl) &&
					fieldInputData[fieldId].croppedImgUrl !== inputData.croppedImgUrl
				) {
					deleteCroppedImgUrl(fieldInputData[fieldId].croppedImgUrl);
				}
				setFieldInputData({
					...fieldInputData,
					[fieldId]: { ...fieldInputData[fieldId], ...inputData },
				});
			}
		},
		[
			domainName,
			fetchProgressUploadedFiles,
			fieldInputData,
			intl,
			notifyGeneral,
			reSyncCounter,
			userData.uid,
		]
	);
	// props for PreviewControlPanel comp in desktop view (it should have full props)
	const previewControlPanelProps = React.useMemo(() => {
		return {
			open: UIControls.isPreviewEnabled,
			mode: 'preview',
			rndProps: {
				default: workspaceContainerRef.current
					? {
							x: workspaceContainerRef.current.clientWidth,
							y: 20,
							width:
								ART_VARIABLES.cssStyles.menuPanelWidth - ART_VARIABLES.cssStyles.menuBarWidth + 100,
							height: '60%',
					  }
					: undefined,
			},
			selectedFieldIds: selectedFieldIds,
			groups: artworkDesignerTemplate.groups,
			fields: templateFields,
			artworkExtra: artworkExtra,
			userData: userData,
			resetSpreadsheetContent: resetSpreadsheetContentById,
			handleProductPickerSelection: handleProductPickerSelection,
			resetFieldInputData: () => {
				// clean cropped image blob urls
				cleanCroppedImgUrls(fieldInputData);
				// when reset, we will reset all input data except the position in image input data (if applicable),
				// Because the new position is already updated in template field
				// This behaviour is only applied in "Create" page, it doesn't apply to "Design" page, as position data isn't in the fieldInputData in "Design" page
				let resetFieldInputData = {};
				for (let fieldId in fieldInputData) {
					if (fieldInputData[fieldId].position) {
						resetFieldInputData[fieldId] = { position: fieldInputData[fieldId].position };
					}
				}
				setFieldInputData(resetFieldInputData);
				setTemplateBGInputData({});
			},
			setFieldInputDataById: async (fieldId, inputData, fileName) => {
				if (fileName) {
					await saveAIImage(fieldId, inputData, fileName);
				} else {
					if (
						fieldInputData[fieldId] &&
						isBlobUrl(fieldInputData[fieldId].croppedImgUrl) &&
						fieldInputData[fieldId].croppedImgUrl !== inputData.croppedImgUrl
					) {
						deleteCroppedImgUrl(fieldInputData[fieldId].croppedImgUrl);
					}
					setFieldInputData({
						...fieldInputData,
						[fieldId]: { ...fieldInputData[fieldId], ...inputData },
					});
				}
			},
			fieldOutputData: fieldOutputData,
			templateBGOutputDataWithSettings: templateBGOutputDataWithSettings,
			templateDimension: artworkDesignerTemplate.dimension,
			setTemplateBGInputData: (templateBGData) => {
				setTemplateBGInputData(templateBGData || {});
			},
			handleImageCrop: ({ fieldId, imageUrl }) => {
				setCropImage({ fieldId, imageUrl });
			},
			handleImageClip: ({ fieldId, imageUrl, mediafileId }) => {
				setExportArtworkStatus({
					status: 'OK',
					message: 'Clipping image...',
				});
				fetchClipImage({ mediafileId, queryParams: { imgUrl: imageUrl } })
					.then((response) => {
						setFieldInputData({
							...fieldInputData,
							[fieldId]: {
								...fieldInputData[fieldId],
								clippedImgUrl: response.data.clipImgUrl,
								croppedImgUrl: '', // clipped image is one of the sources for cropping, so we reset cropped image when clipped image is changed
							},
						});
					})
					.catch((err) => {
						let errMsg = err.response ? err.response.data.message : err.message;
						notifyGeneral(`Failed to clip image. ${errMsg}`, 'error');
					})
					.finally(() => {
						setExportArtworkStatus({});
					});
			},
			handleFieldFocus: (field) =>
				selectedFieldIds.includes(field.id) ? null : handleFieldSelect(field.id),
			handleResetAnimation:
				// Note: VID-3497 we also use this to control replay the video in videoArtwork
				// hasAnimation(templateFields, artworkDesignerTemplate.videoArtwork)
				hasAnim ? replayAnimationAndVideo : null,
		};
	}, [
		UIControls.isPreviewEnabled,
		artworkDesignerTemplate.dimension,
		artworkDesignerTemplate.groups,
		artworkExtra,
		fieldInputData,
		fieldOutputData,
		handleProductPickerSelection,
		hasAnim,
		notifyGeneral,
		replayAnimationAndVideo,
		resetSpreadsheetContentById,
		selectedFieldIds,
		templateBGOutputDataWithSettings,
		templateFields,
		saveAIImage,
		userData,
	]);

	let initFileName =
		artMode === CREATE_MODE
			? artworkDesignerTemplate.name
			: artMode === EDIT_MODE
			? artContent.name
			: 'untitled';
	if (artMode === CREATE_MODE && artworkDesignerTemplate.filenameFieldId) {
		if (artworkDesignerTemplate.filenameFieldAppend) {
			initFileName = (
				(fieldOutputData[artworkDesignerTemplate.filenameFieldId]?.value ?? '') +
				' - ' +
				artworkDesignerTemplate.name
			).trim();
		} else {
			initFileName = (
				fieldOutputData[artworkDesignerTemplate.filenameFieldId]?.value ||
				artworkDesignerTemplate.name
			).trim();
		}
	}

	return (
		<div /* id={workspaceWrapperId} */ className={classes.createWrapper}>
			{templateFields.length > 0 ? (
				<React.Fragment>
					{/* Work Space */}
					<div className={classes.workspaceWrapper} ref={workspaceWrapperRef}>
						{/* workspace section */}
						<PerfectScrollWrapper scrollX={true} setScrollRef={setPsWrapper}>
							<section className={classes.workspaceSection} ref={workspaceContainerRef}>
								<ReactResizeDetector
									handleWidth
									handleHeight
									onResize={() => zoomControls.isFit && updateWorkspaceRect()}
								/>
								{/* create area spacer, to make some space (margin) around the work area */}
								<div
									className={classes.createSpacer}
									style={{
										left: WSRect.borderRect.x,
										top: WSRect.borderRect.y,
										width: WSRect.borderRect.width,
										height: WSRect.borderRect.height,
									}}
								></div>
								{/* Assit overlay of design area, e.g. resize, rotate, dragging, preview, etc. */}
								<div
									className={classes.createWorkplace}
									style={{
										left: WSRect.previewRect.x,
										top: WSRect.previewRect.y,
										width: WSRect.previewRect.width,
										height: WSRect.previewRect.height,
										backgroundSize: 'contain',
										backgroundRepeat: 'no-repeat',
										backgroundImage:
											!artworkDesignerTemplate.videoArtwork && UIControls.isBGImageEnabled // video artwork will never use image BG but video BG
												? // NB: if SVG file is supported in toolkit, the "mediafilePreviewUrl" may not only be image, but could also be null or svg url, then the following code may not working unless the preview url is guaranteed to always be image
												  templateBGOutputDataWithSettings.mediafilePreviewUrl
													? `url(${templateBGOutputDataWithSettings.mediafilePreviewUrl})`
													: undefined
												: undefined,
									}}
									ref={WSPreviewRef}
								>
									{artworkDesignerTemplate.videoArtwork &&
										UIControls.isBGImageEnabled &&
										templateBGOutputDataWithSettings.mediafileOptimisedUrl && (
											<div className={classes.videoBGWrapperForVideoArtwork}>
												<video
													ref={videoArtworkVideoRef}
													autoPlay // VID-3544 BUG 3: auto play video as animation is auto-playing. (It is also used as static background)
													muted
													playsInline
													onEnded={() => setPausingAnimation(true)} // Note, loop must be unset or false, otherwise onEnd will never be fired
													// loop // don't loop
													crossOrigin="anonymous"
													src={templateBGOutputDataWithSettings.mediafileOptimisedUrl}
													style={{
														maxWidth: '100%',
														maxHeight: '100%',
														minWidth: '100%',
														minHeight: '100%',
													}}
												></video>
											</div>
										)}
									{
										<CreateOverlay
											fields={templateFields}
											zoom={WSRect.zoom}
											showDot
											showBorder
											solidLine
											handleFieldSelect={handleFieldSelect}
											handleFieldSelectClickAway={handleFieldSelectClickAway}
											highlightField={UIControls.isFieldHighlightEnabled}
											selectedFieldIds={selectedFieldIds}
											onDragStop={handleFieldDragStop}
											onResizeStop={handleFieldResizeStop}
										/>
									}
									{UIControls.isPreviewEnabled && (
										<SVGPreview
											allowAnimation={allowAnimation}
											pausingAnimation={pausingAnimation}
											width={artworkDesignerTemplate.dimension.width}
											height={artworkDesignerTemplate.dimension.height}
											fields={templateFields}
											// fieldsPreviewValue={fieldsPreviewValue}
											fieldOutputData={fieldOutputData}
											// zoom={WSRect.zoom}
											isVideoArtwork={artworkDesignerTemplate.videoArtwork}
										/>
									)}
									{cropImageField && (
										<ImageCropper
											fieldPosition={{ ...cropImageField.position }}
											imageUrl={cropImage.imageUrl}
											maxBoundaryWidth={WSRect.previewRect.width}
											maxBoundaryHeight={WSRect.previewRect.height}
											zoom={WSRect.zoom}
											// handleCroppedImage={imgBase64Url => {
											// 	setFieldsPreviewValue({
											// 		...fieldsPreviewValue,
											// 		[cropImageField.id]: {
											// 			...fieldsPreviewValue[cropImageField.id],
											// 			croppedImgUrl: imgBase64Url,
											// 		},
											// 	});
											// 	setCropImage({});
											// }}
											handleCroppedImage={(blob) => {
												// Delete croppedImgUrl by revoking blob url
												fieldInputData[cropImageField.id] &&
													deleteCroppedImgUrl(fieldInputData[cropImageField.id].croppedImgUrl);

												setFieldInputData({
													...fieldInputData,
													[cropImageField.id]: {
														...fieldInputData[cropImageField.id],
														croppedImgUrl: URL.createObjectURL(blob),
													},
												});
												setCropImage({});
											}}
											handleClose={() => setCropImage({})}
										/>
									)}
								</div>
							</section>
						</PerfectScrollWrapper>
					</div>
					{isMobileView && (
						<div className={classes.bottomCreateActionWrapper}>
							<MobileCreateActions
								PreviewControlPanelSettings={{
									...previewControlPanelProps,
									open: true,
									resetFieldInputData: undefined,
									handleResetAnimation: undefined,
								}}
								handleResetAnimation={previewControlPanelProps.handleResetAnimation}
								handleSaveNewArtwork={(fileName) => {
									artworkDesignerTemplate.videoArtwork
										? saveVideoArtwork(fileName)
										: saveArtworkAtServer(fileName);
								}}
								artMode={artMode}
								initFileName={initFileName}
							/>
						</div>
					)}
					{/* Create Actions (buttons) */}
					{!isMobileView && (
						<React.Fragment>
							{UIControls.isPreviewEnabled && hasAnim && (
								<div className={classes.replayBtnContainer}>
									<ReplayBtn replayHandler={replayAnimationAndVideo} />
								</div>
							)}
							<div className={classes.actionHolder}>
								<CreateActions
									showDesignBtn={userData.userLevel > DESIGN_MIN_UL}
									initFileName={initFileName}
									artMode={artMode}
									enablePrint={canPrint}
									handleSaveNewArtwork={(fileName) => {
										artworkDesignerTemplate.videoArtwork
											? saveVideoArtwork(fileName)
											: saveArtworkAtServer(fileName);
									}}
									handleSaveToExistingArtwork={() => saveArtworkAtServer()}
									handlePrintNow={
										canPrint
											? (num) =>
													printArtwork([
														{
															data: { ...fieldOutputData },
															templateBGData: { ...templateBGOutputDataWithSettings },
															num: num,
														},
													])
											: null
									}
									handlePrintQueue={
										canPrint
											? () =>
													printArtwork(multiplePrintData).then((err) => {
														if (!err) setMultiplePrintData([]);
														return err;
													})
											: null
									}
									handleAddToPrintQueue={
										canPrint
											? (num) =>
													setMultiplePrintData([
														...multiplePrintData,
														{
															data: { ...fieldOutputData },
															templateBGData: { ...templateBGOutputDataWithSettings },
															// templateBGData: {
															// 	mediafileId: templateBGOutputDataWithSettings.mediafileId,
															// 	mediafilePreviewUrl:
															// 		templateBGOutputDataWithSettings.mediafilePreviewUrl,
															// 	mediafileHighResUrl:
															// 		templateBGOutputDataWithSettings.mediafileHighResUrl,
															// 	mediafileOptimisedUrl:
															// 		templateBGOutputDataWithSettings.mediafileOptimisedUrl,
															// 	mediafilePdfUrl: templateBGOutputDataWithSettings.mediafilePdfUrl,
															// 	mediafileSvgUrl: templateBGOutputDataWithSettings.mediafileSvgUrl,
															// },
															num: num,
														},
													])
											: null
									}
									handleResetPrintQueue={() => setMultiplePrintData([])}
									maxSpecifyQtyToPrintQueue={getMaxSpecifyQtyToPrintQueue()}
									handleDesignTemplate={() =>
										// history.push(
										// 	`${ROUTES_PATH_ARTWORK_DESIGNER}?mediaId=${artworkDesignerTemplate.mediaId}`
										// )
										window.location.assign(getRouteArtworkDesigner(artworkDesignerTemplate.mediaId))
									}
									// zoom props
									handleZoomOut={handleZoomChange}
									handleZoomIn={handleZoomChange}
									handleZoomSelect={handleZoomChange}
									zoom={WSRect.zoom}
									// preview control
									// isPreviewEnabled={UIControls.isPreviewEnabled}
									// togglePreview={() =>
									// 	setUIControls({
									// 		...UIControls,
									// 		isPreviewEnabled: !UIControls.isPreviewEnabled,
									// 		// isPreviewControlPanelOpen: !UIControls.isPreviewEnabled ? true : false,
									// 	})
									// }
									// background image control
									// isBGImageEnabled={UIControls.isBGImageEnabled}
									// toggleBGImage={() =>
									// 	setUIControls({
									// 		...UIControls,
									// 		isBGImageEnabled: !UIControls.isBGImageEnabled,
									// 	})
									// }
									goToDetailPage={() => {
										const query = new URLSearchParams(location.search);
										const templateId = query.get('t');
										const artworkMediafileId = query.get('m');
										window.location.assign(
											getRouteMediafileDetail(artworkMediafileId || templateId)
										);
									}}
									// highlight fields control
									isFieldHighlightEnabled={UIControls.isFieldHighlightEnabled}
									toggleFieldHighlight={() =>
										setUIControls({
											...UIControls,
											isFieldHighlightEnabled: !UIControls.isFieldHighlightEnabled,
										})
									}
								/>
							</div>
						</React.Fragment>
					)}
					{/* Preview Control Panel */}

					{workspaceContainerRef.current && !isMobileView && (
						<PreviewControlPanel {...previewControlPanelProps} />
					)}

					{/** loader of exporting/saving artwork */}
					<ArtLoading
						open={Boolean(exportArtworkStatus.status)}
						progressLoaderFormat="gif"
						message={exportArtworkStatus.message || ''}
						failureHandler={
							exportArtworkStatus.status === 'FAILED' ? () => setExportArtworkStatus({}) : null
						}
					/>
				</React.Fragment>
			) : (
				<ArtLoading
					open={true}
					failureButtonText="Go Back"
					message={initStatus.message || ''}
					failureHandler={initStatus.status === 'FAILED' ? handleGoback : null}
				/>
			)}
		</div>
	);
}

Create.propTypes = {
	// turnOnSidebar: PropTypes.func.isRequired,
	// turnOffSidebar: PropTypes.func.isRequired,
	artworkDesignerTemplate: PropTypes.object.isRequired,
	// canUndo: PropTypes.bool.isRequired,
	// canRedo: PropTypes.bool.isRequired,
	// updateLocalDesignerTemplate: PropTypes.func.isRequired,
	// setArtDesignState: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	getDesignerTemplate: PropTypes.func.isRequired,
	resetDesignerTemplate: PropTypes.func.isRequired,
	resetArtworkFonts: PropTypes.func.isRequired,
	fetchArtworkFonts: PropTypes.func.isRequired,
	fetchArtworkSpreadsheets: PropTypes.func.isRequired,
	resetArtworkSpreadsheets: PropTypes.func.isRequired,
	fetchArtworkLists: PropTypes.func.isRequired,
	resetArtworkLists: PropTypes.func.isRequired,
	fetchArtworkAutoImport: PropTypes.func.isRequired,
	resetArtworkAutoImport: PropTypes.func.isRequired,
	fetchArtworkOutputTemplates: PropTypes.func.isRequired,
	resetArtworkOutputTemplates: PropTypes.func.isRequired,
	fetchArtworkCategories: PropTypes.func.isRequired,
	resetArtworkCategories: PropTypes.func.isRequired,
	resetSpreadsheetContentById: PropTypes.func.isRequired,
	reSyncCounter: PropTypes.func.isRequired,
	// fetchAWSCredential: PropTypes.func.isRequired,
	// resetAWSCredential: PropTypes.func.isRequired,
	// saveArtworkFile: PropTypes.func.isRequired,
	// resetSaveArtworkFileStatus: PropTypes.func.isRequired,
	// saveDesignerTemplate: PropTypes.func.isRequired,
	// retrieveDraftDesignTemplate: PropTypes.func.isRequired,
	// undoDesignTemplate: PropTypes.func.isRequired,
	// redoDesignTemplate: PropTypes.func.isRequired,
	// openGlobalDialog: PropTypes.func.isRequired,
	artworkExtra: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	domainName: PropTypes.string.isRequired,
	fetchProgressUploadedFiles: PropTypes.func.isRequired,
};

Create.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		artworkDesignerTemplate: state.artworkDesignerTemplate.present,
		artworkExtra: state.artworkExtra,
		userData: { uid: state.authentication.userId, userLevel: state.authentication.userLevel }, // (logged in) user data
		domainName: state.authentication.domainName,
		// canUndo: state.artworkDesignerTemplate.past.length > 0,
		// canRedo: state.artworkDesignerTemplate.future.length > 0,
	};
};

// query parameters: t ["template ID"], m ["artwork mediafile id (retrieve content)"], retrieveDraft ["yes", "no", undefined]
// TODO: location.state.fromPath is used for going back action. Could it be better as query parameter?
export default connect(mapStateToProps, {
	// turnOnSidebar,
	// turnOffSidebar,
	// updateLocalDesignerTemplate,
	// setArtDesignState,
	notifyGeneral,
	getDesignerTemplate,
	resetDesignerTemplate,
	resetArtworkFonts,
	fetchArtworkFonts,
	fetchArtworkSpreadsheets,
	resetArtworkSpreadsheets,
	fetchArtworkLists,
	resetArtworkLists,
	fetchArtworkAutoImport,
	resetArtworkAutoImport,
	fetchArtworkOutputTemplates,
	resetArtworkOutputTemplates,
	fetchArtworkCategories,
	resetArtworkCategories,
	resetSpreadsheetContentById,
	reSyncCounter,
	fetchProgressUploadedFiles,
	// fetchAWSCredential,
	// resetAWSCredential,
	// saveArtworkFile,
	// resetSaveArtworkFileStatus,
	// saveDesignerTemplate,
	// retrieveDraftDesignTemplate,
	// undoDesignTemplate,
	// redoDesignTemplate,
	// openGlobalDialog,
})(Create);
