const pantoneCMYK = {
	100: { c: 0, m: 0, y: 51, k: 0 },
	101: { c: 0, m: 0, y: 79, k: 0 },
	102: { c: 0, m: 0, y: 95, k: 0 },
	103: { c: 0, m: 3, y: 100, k: 18 },
	104: { c: 0, m: 3, y: 100, k: 30 },
	105: { c: 0, m: 3, y: 100, k: 50 },
	106: { c: 0, m: 2, y: 69, k: 0 },
	107: { c: 0, m: 4, y: 79, k: 0 },
	108: { c: 0, m: 6, y: 95, k: 0 },
	109: { c: 0, m: 10, y: 100, k: 0 },
	110: { c: 0, m: 12, y: 100, k: 7 },
	111: { c: 0, m: 11, y: 100, k: 27 },
	112: { c: 0, m: 10, y: 100, k: 38 },
	113: { c: 0, m: 7, y: 66, k: 0 },
	114: { c: 0, m: 8, y: 73, k: 0 },
	115: { c: 0, m: 9, y: 80, k: 0 },
	116: { c: 0, m: 16, y: 100, k: 0 },
	117: { c: 0, m: 18, y: 100, k: 15 },
	118: { c: 0, m: 18, y: 100, k: 27 },
	119: { c: 0, m: 12, y: 100, k: 49 },
	120: { c: 0, m: 9, y: 58, k: 0 },
	121: { c: 0, m: 11, y: 69, k: 0 },
	122: { c: 0, m: 17, y: 80, k: 0 },
	123: { c: 0, m: 24, y: 94, k: 0 },
	124: { c: 0, m: 28, y: 100, k: 6 },
	125: { c: 0, m: 26, y: 100, k: 26 },
	126: { c: 0, m: 25, y: 100, k: 37 },
	127: { c: 0, m: 7, y: 50, k: 0 },
	128: { c: 0, m: 11, y: 65, k: 0 },
	129: { c: 0, m: 16, y: 77, k: 0 },
	130: { c: 0, m: 30, y: 100, k: 0 },
	131: { c: 0, m: 32, y: 100, k: 9 },
	132: { c: 0, m: 28, y: 100, k: 30 },
	133: { c: 0, m: 20, y: 100, k: 56 },
	134: { c: 0, m: 11, y: 45, k: 0 },
	135: { c: 0, m: 19, y: 60, k: 0 },
	136: { c: 0, m: 27, y: 76, k: 0 },
	137: { c: 0, m: 35, y: 90, k: 0 },
	138: { c: 0, m: 42, y: 100, k: 1 },
	139: { c: 0, m: 37, y: 100, k: 23 },
	140: { c: 0, m: 27, y: 100, k: 54 },
	141: { c: 0, m: 19, y: 51, k: 0 },
	142: { c: 0, m: 28, y: 76, k: 0 },
	143: { c: 0, m: 35, y: 85, k: 0 },
	144: { c: 0, m: 48, y: 100, k: 0 },
	145: { c: 0, m: 47, y: 100, k: 8 },
	146: { c: 0, m: 43, y: 100, k: 33 },
	147: { c: 0, m: 28, y: 100, k: 56 },
	148: { c: 0, m: 16, y: 37, k: 0 },
	149: { c: 0, m: 23, y: 47, k: 0 },
	150: { c: 0, m: 35, y: 70, k: 0 },
	151: { c: 0, m: 48, y: 95, k: 0 },
	152: { c: 0, m: 51, y: 100, k: 1 },
	153: { c: 0, m: 46, y: 100, k: 18 },
	154: { c: 0, m: 46, y: 100, k: 34 },
	155: { c: 0, m: 12, y: 28, k: 0 },
	156: { c: 0, m: 22, y: 42, k: 0 },
	157: { c: 0, m: 43, y: 70, k: 0 },
	158: { c: 0, m: 61, y: 97, k: 0 },
	159: { c: 0, m: 66, y: 100, k: 7 },
	160: { c: 0, m: 62, y: 100, k: 32 },
	161: { c: 0, m: 52, y: 100, k: 64 },
	162: { c: 0, m: 15, y: 22, k: 0 },
	163: { c: 0, m: 31, y: 44, k: 0 },
	164: { c: 0, m: 46, y: 73, k: 0 },
	165: { c: 0, m: 59, y: 96, k: 0 },
	166: { c: 0, m: 64, y: 100, k: 0 },
	167: { c: 0, m: 60, y: 100, k: 17 },
	168: { c: 0, m: 57, y: 100, k: 59 },
	169: { c: 0, m: 20, y: 20, k: 0 },
	170: { c: 0, m: 40, y: 44, k: 0 },
	171: { c: 0, m: 53, y: 68, k: 0 },
	172: { c: 0, m: 66, y: 88, k: 0 },
	173: { c: 0, m: 69, y: 100, k: 4 },
	174: { c: 0, m: 70, y: 100, k: 36 },
	175: { c: 0, m: 65, y: 100, k: 60 },
	176: { c: 0, m: 25, y: 18, k: 0 },
	177: { c: 0, m: 45, y: 40, k: 0 },
	178: { c: 0, m: 59, y: 56, k: 0 },
	179: { c: 0, m: 79, y: 100, k: 0 },
	180: { c: 0, m: 79, y: 100, k: 11 },
	181: { c: 0, m: 74, y: 100, k: 47 },
	182: { c: 0, m: 26, y: 10, k: 0 },
	183: { c: 0, m: 46, y: 21, k: 0 },
	184: { c: 0, m: 68, y: 41, k: 0 },
	185: { c: 0, m: 91, y: 76, k: 0 },
	186: { c: 0, m: 100, y: 81, k: 4 },
	187: { c: 0, m: 100, y: 79, k: 20 },
	188: { c: 0, m: 97, y: 100, k: 50 },
	189: { c: 0, m: 37, y: 10, k: 0 },
	190: { c: 0, m: 55, y: 22, k: 0 },
	191: { c: 0, m: 76, y: 38, k: 0 },
	192: { c: 0, m: 100, y: 68, k: 0 },
	193: { c: 0, m: 100, y: 66, k: 13 },
	194: { c: 0, m: 100, y: 64, k: 33 },
	195: { c: 0, m: 100, y: 60, k: 55 },
	196: { c: 0, m: 25, y: 4, k: 0 },
	197: { c: 0, m: 45, y: 10, k: 0 },
	198: { c: 0, m: 78, y: 33, k: 0 },
	199: { c: 0, m: 100, y: 62, k: 0 },
	200: { c: 0, m: 100, y: 63, k: 12 },
	201: { c: 0, m: 100, y: 63, k: 29 },
	202: { c: 0, m: 100, y: 61, k: 43 },
	203: { c: 0, m: 34, y: 3, k: 0 },
	204: { c: 0, m: 58, y: 3, k: 0 },
	205: { c: 0, m: 84, y: 9, k: 0 },
	206: { c: 0, m: 100, y: 38, k: 3 },
	207: { c: 0, m: 100, y: 43, k: 19 },
	208: { c: 0, m: 100, y: 36, k: 37 },
	209: { c: 0, m: 100, y: 34, k: 53 },
	210: { c: 0, m: 39, y: 6, k: 0 },
	211: { c: 0, m: 55, y: 8, k: 0 },
	212: { c: 0, m: 72, y: 11, k: 0 },
	213: { c: 0, m: 95, y: 27, k: 0 },
	214: { c: 0, m: 100, y: 34, k: 8 },
	215: { c: 0, m: 100, y: 35, k: 27 },
	216: { c: 0, m: 95, y: 40, k: 49 },
	217: { c: 0, m: 28, y: 0, k: 0 },
	218: { c: 2, m: 61, y: 0, k: 0 },
	219: { c: 1, m: 88, y: 0, k: 0 },
	220: { c: 0, m: 100, y: 13, k: 17 },
	221: { c: 0, m: 100, y: 15, k: 30 },
	222: { c: 0, m: 100, y: 10, k: 59 },
	223: { c: 0, m: 46, y: 0, k: 0 },
	224: { c: 1, m: 63, y: 0, k: 0 },
	225: { c: 1, m: 83, y: 0, k: 0 },
	226: { c: 0, m: 99, y: 0, k: 0 },
	227: { c: 0, m: 100, y: 7, k: 19 },
	228: { c: 0, m: 100, y: 4, k: 41 },
	229: { c: 0, m: 100, y: 15, k: 60 },
	230: { c: 0, m: 34, y: 0, k: 0 },
	231: { c: 1, m: 52, y: 0, k: 0 },
	232: { c: 3, m: 67, y: 0, k: 0 },
	233: { c: 11, m: 100, y: 0, k: 0 },
	234: { c: 6, m: 100, y: 0, k: 26 },
	235: { c: 5, m: 100, y: 0, k: 40 },
	236: { c: 1, m: 30, y: 0, k: 0 },
	237: { c: 3, m: 49, y: 0, k: 0 },
	238: { c: 6, m: 63, y: 0, k: 0 },
	239: { c: 11, m: 79, y: 0, k: 0 },
	240: { c: 18, m: 94, y: 0, k: 0 },
	241: { c: 27, m: 100, y: 0, k: 2 },
	242: { c: 10, m: 100, y: 0, k: 49 },
	243: { c: 5, m: 29, y: 0, k: 0 },
	244: { c: 9, m: 38, y: 0, k: 0 },
	245: { c: 14, m: 53, y: 0, k: 0 },
	246: { c: 29, m: 90, y: 0, k: 0 },
	247: { c: 36, m: 100, y: 0, k: 0 },
	248: { c: 40, m: 100, y: 0, k: 2 },
	249: { c: 40, m: 100, y: 0, k: 28 },
	250: { c: 5, m: 18, y: 0, k: 0 },
	251: { c: 13, m: 39, y: 0, k: 0 },
	252: { c: 24, m: 56, y: 0, k: 0 },
	253: { c: 43, m: 95, y: 0, k: 0 },
	254: { c: 50, m: 100, y: 0, k: 0 },
	255: { c: 51, m: 100, y: 0, k: 25 },
	256: { c: 7, m: 20, y: 0, k: 0 },
	257: { c: 14, m: 34, y: 0, k: 0 },
	258: { c: 43, m: 76, y: 0, k: 0 },
	259: { c: 55, m: 100, y: 0, k: 15 },
	260: { c: 52, m: 100, y: 0, k: 26 },
	261: { c: 48, m: 100, y: 0, k: 40 },
	262: { c: 45, m: 100, y: 0, k: 55 },
	263: { c: 10, m: 14, y: 0, k: 0 },
	264: { c: 26, m: 28, y: 0, k: 0 },
	265: { c: 54, m: 56, y: 0, k: 0 },
	266: { c: 79, m: 90, y: 0, k: 0 },
	267: { c: 89, m: 100, y: 0, k: 0 },
	268: { c: 82, m: 100, y: 0, k: 12 },
	269: { c: 78, m: 100, y: 0, k: 33 },
	270: { c: 31, m: 27, y: 0, k: 0 },
	271: { c: 43, m: 37, y: 0, k: 0 },
	272: { c: 58, m: 48, y: 0, k: 0 },
	273: { c: 100, m: 96, y: 0, k: 8 },
	274: { c: 100, m: 100, y: 0, k: 28 },
	275: { c: 98, m: 100, y: 0, k: 43 },
	276: { c: 100, m: 100, y: 0, k: 58 },
	277: { c: 27, m: 7, y: 0, k: 0 },
	278: { c: 39, m: 14, y: 0, k: 0 },
	279: { c: 68, m: 34, y: 0, k: 0 },
	280: { c: 100, m: 72, y: 0, k: 18 },
	281: { c: 100, m: 72, y: 0, k: 32 },
	282: { c: 100, m: 68, y: 0, k: 54 },
	283: { c: 35, m: 9, y: 0, k: 0 },
	284: { c: 55, m: 19, y: 0, k: 0 },
	285: { c: 89, m: 43, y: 0, k: 0 },
	286: { c: 100, m: 66, y: 0, k: 2 },
	287: { c: 100, m: 68, y: 0, k: 12 },
	288: { c: 100, m: 67, y: 0, k: 23 },
	289: { c: 100, m: 64, y: 0, k: 60 },
	290: { c: 25, m: 2, y: 0, k: 0 },
	291: { c: 33, m: 3, y: 0, k: 0 },
	292: { c: 49, m: 11, y: 0, k: 0 },
	293: { c: 100, m: 57, y: 0, k: 2 },
	294: { c: 100, m: 58, y: 0, k: 21 },
	295: { c: 100, m: 57, y: 0, k: 40 },
	296: { c: 100, m: 46, y: 0, k: 70 },
	297: { c: 49, m: 1, y: 0, k: 0 },
	298: { c: 69, m: 7, y: 0, k: 0 },
	299: { c: 85, m: 19, y: 0, k: 0 },
	300: { c: 100, m: 44, y: 0, k: 0 },
	301: { c: 100, m: 45, y: 0, k: 18 },
	302: { c: 100, m: 25, y: 0, k: 50 },
	303: { c: 100, m: 11, y: 0, k: 74 },
	304: { c: 30, m: 0, y: 8, k: 0 },
	305: { c: 51, m: 0, y: 9, k: 0 },
	306: { c: 75, m: 0, y: 7, k: 0 },
	307: { c: 100, m: 16, y: 0, k: 27 },
	308: { c: 100, m: 5, y: 0, k: 47 },
	309: { c: 100, m: 0, y: 9, k: 72 },
	310: { c: 43, m: 0, y: 10, k: 0 },
	311: { c: 63, m: 0, y: 12, k: 0 },
	312: { c: 96, m: 0, y: 11, k: 0 },
	313: { c: 100, m: 0, y: 8, k: 13 },
	314: { c: 100, m: 0, y: 9, k: 30 },
	315: { c: 100, m: 0, y: 12, k: 43 },
	316: { c: 100, m: 0, y: 27, k: 68 },
	317: { c: 18, m: 0, y: 8, k: 0 },
	318: { c: 38, m: 0, y: 15, k: 0 },
	319: { c: 52, m: 0, y: 19, k: 0 },
	320: { c: 100, m: 0, y: 31, k: 7 },
	321: { c: 100, m: 0, y: 31, k: 23 },
	322: { c: 100, m: 0, y: 33, k: 35 },
	323: { c: 100, m: 0, y: 38, k: 47 },
	324: { c: 28, m: 0, y: 12, k: 0 },
	325: { c: 56, m: 0, y: 26, k: 0 },
	326: { c: 87, m: 0, y: 38, k: 0 },
	327: { c: 100, m: 0, y: 44, k: 17 },
	328: { c: 100, m: 0, y: 45, k: 32 },
	329: { c: 100, m: 0, y: 46, k: 46 },
	330: { c: 100, m: 0, y: 48, k: 60 },
	331: { c: 24, m: 0, y: 16, k: 0 },
	332: { c: 30, m: 0, y: 20, k: 0 },
	333: { c: 43, m: 0, y: 27, k: 0 },
	334: { c: 100, m: 0, y: 60, k: 3 },
	335: { c: 100, m: 0, y: 65, k: 30 },
	336: { c: 100, m: 0, y: 67, k: 47 },
	337: { c: 31, m: 0, y: 20, k: 0 },
	338: { c: 47, m: 0, y: 32, k: 0 },
	339: { c: 84, m: 0, y: 56, k: 0 },
	340: { c: 100, m: 0, y: 66, k: 9 },
	341: { c: 100, m: 0, y: 67, k: 29 },
	342: { c: 100, m: 0, y: 71, k: 43 },
	343: { c: 98, m: 0, y: 72, k: 61 },
	344: { c: 27, m: 0, y: 23, k: 0 },
	345: { c: 38, m: 0, y: 32, k: 0 },
	346: { c: 55, m: 0, y: 47, k: 0 },
	347: { c: 100, m: 0, y: 86, k: 3 },
	348: { c: 100, m: 0, y: 85, k: 24 },
	349: { c: 100, m: 0, y: 91, k: 42 },
	350: { c: 79, m: 0, y: 100, k: 75 },
	351: { c: 17, m: 0, y: 16, k: 0 },
	352: { c: 27, m: 0, y: 25, k: 0 },
	353: { c: 38, m: 0, y: 36, k: 0 },
	354: { c: 80, m: 0, y: 90, k: 0 },
	355: { c: 94, m: 0, y: 100, k: 0 },
	356: { c: 95, m: 0, y: 100, k: 27 },
	357: { c: 80, m: 0, y: 100, k: 56 },
	358: { c: 27, m: 0, y: 38, k: 0 },
	359: { c: 36, m: 0, y: 49, k: 0 },
	360: { c: 58, m: 0, y: 80, k: 0 },
	361: { c: 69, m: 0, y: 100, k: 0 },
	362: { c: 70, m: 0, y: 100, k: 9 },
	363: { c: 68, m: 0, y: 100, k: 24 },
	364: { c: 65, m: 0, y: 100, k: 42 },
	365: { c: 12, m: 0, y: 29, k: 0 },
	366: { c: 20, m: 0, y: 44, k: 0 },
	367: { c: 32, m: 0, y: 59, k: 0 },
	368: { c: 57, m: 0, y: 100, k: 0 },
	369: { c: 59, m: 0, y: 100, k: 7 },
	370: { c: 56, m: 0, y: 100, k: 27 },
	371: { c: 43, m: 0, y: 100, k: 56 },
	372: { c: 10, m: 0, y: 33, k: 0 },
	373: { c: 16, m: 0, y: 46, k: 0 },
	374: { c: 24, m: 0, y: 57, k: 0 },
	375: { c: 41, m: 0, y: 78, k: 0 },
	376: { c: 50, m: 0, y: 100, k: 0 },
	377: { c: 45, m: 0, y: 100, k: 24 },
	378: { c: 34, m: 0, y: 100, k: 60 },
	379: { c: 9, m: 0, y: 58, k: 0 },
	380: { c: 13, m: 0, y: 72, k: 0 },
	381: { c: 20, m: 0, y: 91, k: 0 },
	382: { c: 29, m: 0, y: 100, k: 0 },
	383: { c: 20, m: 0, y: 100, k: 19 },
	384: { c: 18, m: 0, y: 100, k: 31 },
	385: { c: 3, m: 0, y: 100, k: 58 },
	386: { c: 6, m: 0, y: 56, k: 0 },
	387: { c: 10, m: 0, y: 74, k: 0 },
	388: { c: 14, m: 0, y: 79, k: 0 },
	389: { c: 20, m: 0, y: 85, k: 0 },
	390: { c: 22, m: 0, y: 100, k: 8 },
	391: { c: 13, m: 0, y: 100, k: 33 },
	392: { c: 7, m: 0, y: 100, k: 49 },
	393: { c: 3, m: 0, y: 55, k: 0 },
	394: { c: 6, m: 0, y: 76, k: 0 },
	395: { c: 8, m: 0, y: 85, k: 0 },
	396: { c: 11, m: 0, y: 94, k: 0 },
	397: { c: 10, m: 0, y: 100, k: 11 },
	398: { c: 7, m: 0, y: 100, k: 28 },
	399: { c: 0, m: 0, y: 100, k: 43 },
	400: { c: 0, m: 3, y: 6, k: 16 },
	401: { c: 0, m: 5, y: 11, k: 23 },
	402: { c: 0, m: 6, y: 14, k: 31 },
	403: { c: 0, m: 7, y: 17, k: 43 },
	404: { c: 0, m: 8, y: 22, k: 56 },
	405: { c: 0, m: 10, y: 33, k: 72 },
	406: { c: 0, m: 5, y: 6, k: 16 },
	407: { c: 0, m: 8, y: 9, k: 26 },
	408: { c: 0, m: 10, y: 11, k: 34 },
	409: { c: 0, m: 13, y: 15, k: 45 },
	410: { c: 0, m: 18, y: 21, k: 56 },
	411: { c: 0, m: 27, y: 36, k: 72 },
	412: { c: 0, m: 30, y: 66, k: 98 },
	413: { c: 0, m: 0, y: 9, k: 20 },
	414: { c: 0, m: 0, y: 10, k: 30 },
	415: { c: 0, m: 0, y: 12, k: 41 },
	416: { c: 0, m: 0, y: 16, k: 50 },
	417: { c: 1, m: 0, y: 25, k: 65 },
	418: { c: 3, m: 0, y: 31, k: 75 },
	419: { c: 29, m: 0, y: 36, k: 100 },
	420: { c: 0, m: 0, y: 0, k: 15 },
	421: { c: 0, m: 0, y: 0, k: 26 },
	422: { c: 0, m: 0, y: 0, k: 33 },
	423: { c: 0, m: 0, y: 0, k: 44 },
	424: { c: 0, m: 0, y: 0, k: 61 },
	425: { c: 0, m: 0, y: 0, k: 77 },
	426: { c: 0, m: 0, y: 0, k: 99 },
	427: { c: 0, m: 0, y: 0, k: 11 },
	428: { c: 2, m: 0, y: 0, k: 18 },
	429: { c: 3, m: 0, y: 0, k: 32 },
	430: { c: 5, m: 0, y: 0, k: 45 },
	431: { c: 11, m: 1, y: 0, k: 64 },
	432: { c: 23, m: 2, y: 0, k: 77 },
	433: { c: 33, m: 3, y: 0, k: 95 },
	434: { c: 7, m: 9, y: 10, k: 0 },
	435: { c: 13, m: 15, y: 15, k: 0 },
	436: { c: 24, m: 25, y: 26, k: 0 },
	437: { c: 46, m: 45, y: 49, k: 0 },
	438: { c: 75, m: 68, y: 100, k: 10 },
	439: { c: 80, m: 73, y: 100, k: 20 },
	440: { c: 82, m: 76, y: 100, k: 30 },
	441: { c: 6, m: 0, y: 7, k: 9 },
	442: { c: 8, m: 0, y: 9, k: 19 },
	443: { c: 12, m: 0, y: 12, k: 30 },
	444: { c: 15, m: 0, y: 15, k: 42 },
	445: { c: 20, m: 0, y: 20, k: 65 },
	446: { c: 21, m: 0, y: 23, k: 75 },
	447: { c: 16, m: 0, y: 31, k: 82 },
	448: { c: 65, m: 58, y: 100, k: 35 },
	449: { c: 65, m: 55, y: 100, k: 28 },
	450: { c: 60, m: 50, y: 100, k: 22 },
	451: { c: 33, m: 28, y: 58, k: 0 },
	452: { c: 24, m: 18, y: 42, k: 0 },
	453: { c: 14, m: 10, y: 27, k: 0 },
	454: { c: 9, m: 6, y: 17, k: 0 },
	455: { c: 0, m: 17, y: 100, k: 65 },
	456: { c: 0, m: 15, y: 100, k: 43 },
	457: { c: 0, m: 15, y: 100, k: 28 },
	458: { c: 10, m: 10, y: 73, k: 0 },
	459: { c: 6, m: 7, y: 55, k: 0 },
	460: { c: 4, m: 5, y: 44, k: 0 },
	461: { c: 3, m: 3, y: 35, k: 0 },
	462: { c: 50, m: 58, y: 100, k: 45 },
	463: { c: 30, m: 56, y: 100, k: 37 },
	464: { c: 10, m: 49, y: 100, k: 35 },
	465: { c: 20, m: 32, y: 58, k: 0 },
	466: { c: 12, m: 22, y: 43, k: 0 },
	467: { c: 9, m: 15, y: 34, k: 0 },
	468: { c: 6, m: 9, y: 23, k: 0 },
	469: { c: 0, m: 52, y: 100, k: 62 },
	470: { c: 0, m: 58, y: 100, k: 33 },
	471: { c: 0, m: 59, y: 100, k: 18 },
	472: { c: 0, m: 34, y: 52, k: 0 },
	473: { c: 0, m: 23, y: 36, k: 0 },
	474: { c: 0, m: 15, y: 26, k: 0 },
	475: { c: 0, m: 11, y: 20, k: 0 },
	476: { c: 57, m: 80, y: 100, k: 45 },
	477: { c: 50, m: 85, y: 100, k: 35 },
	478: { c: 40, m: 86, y: 100, k: 30 },
	479: { c: 30, m: 48, y: 57, k: 0 },
	480: { c: 15, m: 29, y: 33, k: 0 },
	481: { c: 9, m: 19, y: 23, k: 0 },
	482: { c: 5, m: 11, y: 15, k: 0 },
	483: { c: 0, m: 91, y: 100, k: 60 },
	484: { c: 0, m: 95, y: 100, k: 29 },
	485: { c: 0, m: 95, y: 100, k: 0 },
	486: { c: 0, m: 47, y: 41, k: 0 },
	487: { c: 0, m: 35, y: 28, k: 0 },
	488: { c: 0, m: 26, y: 19, k: 0 },
	489: { c: 0, m: 15, y: 11, k: 0 },
	490: { c: 0, m: 74, y: 100, k: 72 },
	491: { c: 0, m: 79, y: 100, k: 52 },
	492: { c: 0, m: 70, y: 66, k: 30 },
	493: { c: 0, m: 46, y: 23, k: 5 },
	494: { c: 0, m: 33, y: 13, k: 0 },
	495: { c: 0, m: 24, y: 11, k: 0 },
	496: { c: 0, m: 16, y: 9, k: 0 },
	497: { c: 0, m: 70, y: 100, k: 78 },
	498: { c: 0, m: 64, y: 100, k: 60 },
	499: { c: 0, m: 58, y: 100, k: 49 },
	500: { c: 0, m: 38, y: 21, k: 11 },
	501: { c: 0, m: 27, y: 13, k: 3 },
	502: { c: 0, m: 18, y: 10, k: 1 },
	503: { c: 0, m: 11, y: 8, k: 0 },
	504: { c: 65, m: 100, y: 100, k: 35 },
	505: { c: 50, m: 100, y: 100, k: 25 },
	506: { c: 45, m: 100, y: 100, k: 15 },
	507: { c: 11, m: 45, y: 22, k: 0 },
	508: { c: 4, m: 34, y: 11, k: 0 },
	509: { c: 0, m: 24, y: 7, k: 0 },
	510: { c: 0, m: 17, y: 6, k: 0 },
	511: { c: 60, m: 100, y: 45, k: 30 },
	512: { c: 50, m: 100, y: 15, k: 10 },
	513: { c: 44, m: 83, y: 0, k: 0 },
	514: { c: 15, m: 50, y: 0, k: 0 },
	515: { c: 7, m: 38, y: 0, k: 0 },
	516: { c: 3, m: 27, y: 0, k: 0 },
	517: { c: 0, m: 18, y: 0, k: 0 },
	518: { c: 70, m: 100, y: 55, k: 25 },
	519: { c: 67, m: 100, y: 30, k: 10 },
	520: { c: 64, m: 100, y: 12, k: 0 },
	521: { c: 27, m: 47, y: 0, k: 0 },
	522: { c: 17, m: 37, y: 0, k: 0 },
	523: { c: 10, m: 26, y: 0, k: 0 },
	524: { c: 6, m: 15, y: 0, k: 0 },
	525: { c: 84, m: 100, y: 45, k: 5 },
	526: { c: 76, m: 100, y: 7, k: 0 },
	527: { c: 73, m: 100, y: 0, k: 0 },
	528: { c: 41, m: 55, y: 0, k: 0 },
	529: { c: 26, m: 40, y: 0, k: 0 },
	530: { c: 18, m: 31, y: 0, k: 0 },
	531: { c: 10, m: 20, y: 0, k: 0 },
	532: { c: 100, m: 80, y: 70, k: 25 },
	533: { c: 100, m: 83, y: 46, k: 13 },
	534: { c: 100, m: 80, y: 30, k: 5 },
	535: { c: 42, m: 27, y: 7, k: 0 },
	536: { c: 31, m: 20, y: 5, k: 0 },
	537: { c: 22, m: 12, y: 3, k: 0 },
	538: { c: 12, m: 7, y: 2, k: 0 },
	539: { c: 100, m: 49, y: 0, k: 70 },
	540: { c: 100, m: 55, y: 0, k: 55 },
	541: { c: 100, m: 57, y: 0, k: 38 },
	542: { c: 62, m: 22, y: 0, k: 3 },
	543: { c: 41, m: 11, y: 0, k: 0 },
	544: { c: 30, m: 6, y: 0, k: 0 },
	545: { c: 22, m: 3, y: 0, k: 0 },
	546: { c: 95, m: 9, y: 0, k: 83 },
	547: { c: 100, m: 19, y: 0, k: 75 },
	548: { c: 100, m: 24, y: 0, k: 64 },
	549: { c: 52, m: 6, y: 0, k: 25 },
	550: { c: 38, m: 4, y: 0, k: 19 },
	551: { c: 27, m: 3, y: 0, k: 13 },
	552: { c: 15, m: 0, y: 0, k: 9 },
	553: { c: 59, m: 0, y: 53, k: 80 },
	554: { c: 78, m: 0, y: 63, k: 67 },
	555: { c: 75, m: 0, y: 60, k: 55 },
	556: { c: 42, m: 0, y: 33, k: 27 },
	557: { c: 30, m: 0, y: 20, k: 15 },
	558: { c: 19, m: 0, y: 14, k: 9 },
	559: { c: 14, m: 0, y: 10, k: 6 },
	560: { c: 80, m: 0, y: 63, k: 75 },
	561: { c: 85, m: 0, y: 54, k: 52 },
	562: { c: 85, m: 0, y: 50, k: 31 },
	563: { c: 52, m: 0, y: 32, k: 1 },
	564: { c: 37, m: 0, y: 20, k: 0 },
	565: { c: 23, m: 0, y: 13, k: 0 },
	566: { c: 14, m: 0, y: 10, k: 0 },
	567: { c: 82, m: 0, y: 64, k: 70 },
	568: { c: 88, m: 0, y: 57, k: 36 },
	569: { c: 98, m: 0, y: 57, k: 17 },
	570: { c: 48, m: 0, y: 29, k: 0 },
	571: { c: 32, m: 0, y: 19, k: 0 },
	572: { c: 23, m: 0, y: 14, k: 0 },
	573: { c: 14, m: 0, y: 9, k: 0 },
	574: { c: 34, m: 0, y: 81, k: 71 },
	575: { c: 48, m: 0, y: 100, k: 53 },
	576: { c: 49, m: 0, y: 100, k: 39 },
	577: { c: 24, m: 0, y: 46, k: 10 },
	578: { c: 20, m: 0, y: 40, k: 6 },
	579: { c: 17, m: 0, y: 34, k: 3 },
	580: { c: 12, m: 0, y: 26, k: 2 },
	581: { c: 2, m: 0, y: 100, k: 72 },
	582: { c: 13, m: 0, y: 100, k: 46 },
	583: { c: 23, m: 0, y: 100, k: 17 },
	584: { c: 12, m: 0, y: 79, k: 6 },
	585: { c: 11, m: 0, y: 66, k: 2 },
	586: { c: 9, m: 0, y: 53, k: 0 },
	587: { c: 5, m: 0, y: 40, k: 0 },
	600: { c: 0, m: 0, y: 29, k: 0 },
	601: { c: 0, m: 0, y: 40, k: 0 },
	602: { c: 0, m: 0, y: 50, k: 0 },
	603: { c: 0, m: 0, y: 69, k: 1 },
	604: { c: 0, m: 0, y: 88, k: 3 },
	605: { c: 0, m: 2, y: 100, k: 7 },
	606: { c: 0, m: 4, y: 100, k: 12 },
	607: { c: 0, m: 0, y: 18, k: 1 },
	608: { c: 0, m: 0, y: 32, k: 2 },
	609: { c: 0, m: 0, y: 46, k: 4 },
	610: { c: 0, m: 0, y: 58, k: 6 },
	611: { c: 0, m: 1, y: 92, k: 11 },
	612: { c: 0, m: 2, y: 100, k: 20 },
	613: { c: 0, m: 4, y: 100, k: 30 },
	614: { c: 0, m: 0, y: 20, k: 4 },
	615: { c: 0, m: 1, y: 27, k: 6 },
	616: { c: 0, m: 2, y: 35, k: 9 },
	617: { c: 0, m: 2, y: 48, k: 17 },
	618: { c: 0, m: 3, y: 87, k: 30 },
	619: { c: 0, m: 4, y: 100, k: 43 },
	620: { c: 0, m: 5, y: 100, k: 53 },
	621: { c: 13, m: 0, y: 10, k: 2 },
	622: { c: 24, m: 0, y: 19, k: 4 },
	623: { c: 32, m: 0, y: 24, k: 10 },
	624: { c: 44, m: 0, y: 35, k: 20 },
	625: { c: 56, m: 0, y: 44, k: 33 },
	626: { c: 76, m: 0, y: 64, k: 62 },
	627: { c: 90, m: 0, y: 75, k: 83 },
	628: { c: 19, m: 0, y: 6, k: 0 },
	629: { c: 34, m: 0, y: 9, k: 0 },
	630: { c: 47, m: 0, y: 11, k: 0 },
	631: { c: 67, m: 0, y: 12, k: 2 },
	632: { c: 92, m: 0, y: 15, k: 5 },
	633: { c: 100, m: 0, y: 10, k: 25 },
	634: { c: 100, m: 0, y: 9, k: 40 },
	635: { c: 32, m: 0, y: 8, k: 0 },
	636: { c: 45, m: 0, y: 9, k: 0 },
	637: { c: 55, m: 0, y: 9, k: 0 },
	638: { c: 83, m: 0, y: 10, k: 0 },
	639: { c: 100, m: 0, y: 5, k: 5 },
	640: { c: 100, m: 0, y: 0, k: 22 },
	641: { c: 100, m: 4, y: 0, k: 30 },
	642: { c: 16, m: 4, y: 0, k: 2 },
	643: { c: 25, m: 7, y: 0, k: 4 },
	644: { c: 42, m: 15, y: 0, k: 6 },
	645: { c: 55, m: 24, y: 0, k: 9 },
	646: { c: 65, m: 30, y: 0, k: 11 },
	647: { c: 100, m: 56, y: 0, k: 23 },
	648: { c: 100, m: 62, y: 0, k: 52 },
	649: { c: 10, m: 4, y: 0, k: 1 },
	650: { c: 24, m: 9, y: 0, k: 2 },
	651: { c: 38, m: 18, y: 0, k: 6 },
	652: { c: 50, m: 25, y: 0, k: 10 },
	653: { c: 100, m: 62, y: 0, k: 20 },
	654: { c: 100, m: 67, y: 0, k: 38 },
	655: { c: 100, m: 68, y: 0, k: 52 },
	656: { c: 14, m: 3, y: 0, k: 0 },
	657: { c: 24, m: 7, y: 0, k: 0 },
	658: { c: 30, m: 15, y: 0, k: 0 },
	659: { c: 55, m: 30, y: 0, k: 0 },
	660: { c: 90, m: 57, y: 0, k: 0 },
	661: { c: 100, m: 69, y: 0, k: 9 },
	662: { c: 100, m: 71, y: 0, k: 18 },
	663: { c: 7, m: 6, y: 0, k: 0 },
	664: { c: 11, m: 9, y: 0, k: 0 },
	665: { c: 20, m: 17, y: 0, k: 2 },
	666: { c: 31, m: 30, y: 0, k: 7 },
	667: { c: 52, m: 49, y: 0, k: 14 },
	668: { c: 65, m: 64, y: 0, k: 30 },
	669: { c: 76, m: 78, y: 0, k: 47 },
	670: { c: 0, m: 13, y: 0, k: 0 },
	671: { c: 1, m: 20, y: 0, k: 0 },
	672: { c: 3, m: 34, y: 0, k: 0 },
	673: { c: 6, m: 49, y: 0, k: 0 },
	674: { c: 9, m: 67, y: 0, k: 0 },
	675: { c: 17, m: 100, y: 0, k: 3 },
	676: { c: 6, m: 100, y: 0, k: 22 },
	677: { c: 2, m: 13, y: 0, k: 0 },
	678: { c: 3, m: 21, y: 0, k: 0 },
	679: { c: 5, m: 27, y: 0, k: 0 },
	680: { c: 10, m: 43, y: 0, k: 2 },
	681: { c: 21, m: 61, y: 0, k: 4 },
	682: { c: 25, m: 79, y: 0, k: 12 },
	683: { c: 11, m: 100, y: 0, k: 43 },
	684: { c: 0, m: 17, y: 0, k: 2 },
	685: { c: 0, m: 25, y: 0, k: 3 },
	686: { c: 0, m: 30, y: 0, k: 5 },
	687: { c: 2, m: 44, y: 0, k: 12 },
	688: { c: 5, m: 57, y: 0, k: 19 },
	689: { c: 7, m: 77, y: 0, k: 34 },
	690: { c: 0, m: 97, y: 0, k: 59 },
	691: { c: 0, m: 15, y: 8, k: 1 },
	692: { c: 0, m: 23, y: 10, k: 2 },
	693: { c: 0, m: 30, y: 12, k: 6 },
	694: { c: 0, m: 36, y: 21, k: 10 },
	695: { c: 0, m: 50, y: 28, k: 20 },
	696: { c: 0, m: 60, y: 36, k: 32 },
	697: { c: 0, m: 68, y: 47, k: 42 },
	698: { c: 0, m: 16, y: 8, k: 0 },
	699: { c: 0, m: 24, y: 10, k: 0 },
	700: { c: 0, m: 36, y: 14, k: 0 },
	701: { c: 0, m: 45, y: 20, k: 0 },
	702: { c: 0, m: 69, y: 34, k: 5 },
	703: { c: 0, m: 83, y: 54, k: 16 },
	704: { c: 0, m: 90, y: 72, k: 29 },
	705: { c: 0, m: 9, y: 6, k: 0 },
	706: { c: 0, m: 17, y: 10, k: 0 },
	707: { c: 0, m: 30, y: 14, k: 0 },
	708: { c: 0, m: 46, y: 22, k: 0 },
	709: { c: 0, m: 66, y: 38, k: 0 },
	710: { c: 0, m: 79, y: 58, k: 0 },
	711: { c: 0, m: 100, y: 80, k: 2 },
	712: { c: 0, m: 14, y: 31, k: 0 },
	713: { c: 0, m: 19, y: 41, k: 0 },
	714: { c: 0, m: 27, y: 55, k: 0 },
	715: { c: 0, m: 36, y: 71, k: 0 },
	716: { c: 0, m: 45, y: 91, k: 0 },
	717: { c: 0, m: 53, y: 100, k: 2 },
	718: { c: 0, m: 56, y: 100, k: 8 },
	719: { c: 0, m: 10, y: 25, k: 0 },
	720: { c: 0, m: 15, y: 36, k: 1 },
	721: { c: 0, m: 24, y: 52, k: 3 },
	722: { c: 0, m: 36, y: 76, k: 9 },
	723: { c: 0, m: 43, y: 97, k: 17 },
	724: { c: 0, m: 51, y: 100, k: 36 },
	725: { c: 0, m: 53, y: 100, k: 48 },
	726: { c: 0, m: 8, y: 23, k: 2 },
	727: { c: 0, m: 15, y: 34, k: 5 },
	728: { c: 0, m: 21, y: 48, k: 10 },
	729: { c: 0, m: 31, y: 62, k: 18 },
	730: { c: 0, m: 38, y: 78, k: 29 },
	731: { c: 0, m: 52, y: 100, k: 54 },
	732: { c: 0, m: 55, y: 100, k: 64 },
	801: { c: 90, m: 5, y: 5, k: 0 },
	802: { c: 35, m: 0, y: 60, k: 0 },
	803: { c: 0, m: 0, y: 70, k: 0 },
	804: { c: 0, m: 20, y: 35, k: 0 },
	805: { c: 0, m: 40, y: 25, k: 0 },
	806: { c: 0, m: 50, y: 0, k: 0 },
	807: { c: 15, m: 75, y: 0, k: 0 },
	808: { c: 80, m: 0, y: 40, k: 0 },
	809: { c: 10, m: 0, y: 100, k: 0 },
	810: { c: 0, m: 10, y: 35, k: 0 },
	811: { c: 0, m: 25, y: 25, k: 0 },
	812: { c: 0, m: 50, y: 15, k: 0 },
	813: { c: 0, m: 70, y: 0, k: 0 },
	814: { c: 55, m: 60, y: 0, k: 0 },
	871: { c: 20, m: 25, y: 60, k: 25 },
	872: { c: 20, m: 30, y: 70, k: 15 },
	873: { c: 30, m: 30, y: 60, k: 10 },
	874: { c: 0, m: 20, y: 50, k: 30 },
	875: { c: 30, m: 40, y: 70, k: 0 },
	876: { c: 30, m: 50, y: 85, k: 0 },
	877: { c: 0, m: 0, y: 0, k: 40 },
	1205: { c: 0, m: 5, y: 31, k: 0 },
	1215: { c: 0, m: 9, y: 45, k: 0 },
	1225: { c: 0, m: 17, y: 62, k: 0 },
	1235: { c: 0, m: 29, y: 91, k: 0 },
	1245: { c: 0, m: 28, y: 100, k: 18 },
	1255: { c: 0, m: 27, y: 100, k: 34 },
	1265: { c: 0, m: 27, y: 100, k: 51 },
	1345: { c: 0, m: 14, y: 47, k: 0 },
	1355: { c: 0, m: 20, y: 56, k: 0 },
	1365: { c: 0, m: 29, y: 72, k: 0 },
	1375: { c: 0, m: 40, y: 90, k: 0 },
	1385: { c: 0, m: 44, y: 100, k: 7 },
	1395: { c: 0, m: 41, y: 100, k: 37 },
	1405: { c: 0, m: 36, y: 100, k: 63 },
	1485: { c: 0, m: 27, y: 54, k: 0 },
	1495: { c: 0, m: 33, y: 67, k: 0 },
	1505: { c: 0, m: 42, y: 77, k: 0 },
	1525: { c: 0, m: 58, y: 100, k: 10 },
	1535: { c: 0, m: 53, y: 100, k: 38 },
	1545: { c: 0, m: 53, y: 100, k: 72 },
	1555: { c: 0, m: 22, y: 34, k: 0 },
	1565: { c: 0, m: 34, y: 49, k: 0 },
	1575: { c: 0, m: 45, y: 72, k: 0 },
	1585: { c: 0, m: 56, y: 90, k: 0 },
	1595: { c: 0, m: 59, y: 100, k: 5 },
	1605: { c: 0, m: 56, y: 100, k: 30 },
	1615: { c: 0, m: 56, y: 100, k: 43 },
	1625: { c: 0, m: 31, y: 37, k: 0 },
	1635: { c: 0, m: 39, y: 48, k: 0 },
	1645: { c: 0, m: 49, y: 66, k: 0 },
	1655: { c: 0, m: 63, y: 91, k: 0 },
	1665: { c: 0, m: 68, y: 100, k: 0 },
	1675: { c: 0, m: 67, y: 100, k: 28 },
	1685: { c: 0, m: 68, y: 100, k: 44 },
	1765: { c: 0, m: 38, y: 21, k: 0 },
	1767: { c: 0, m: 27, y: 12, k: 0 },
	1775: { c: 0, m: 47, y: 29, k: 0 },
	1777: { c: 0, m: 58, y: 36, k: 0 },
	1785: { c: 0, m: 67, y: 50, k: 0 },
	1787: { c: 0, m: 76, y: 60, k: 0 },
	1788: { c: 0, m: 84, y: 88, k: 0 },
	1795: { c: 0, m: 94, y: 100, k: 0 },
	1797: { c: 0, m: 100, y: 99, k: 4 },
	1805: { c: 0, m: 91, y: 100, k: 23 },
	1807: { c: 0, m: 100, y: 96, k: 28 },
	1815: { c: 0, m: 90, y: 100, k: 51 },
	1817: { c: 0, m: 90, y: 100, k: 66 },
	1895: { c: 0, m: 28, y: 7, k: 0 },
	1905: { c: 0, m: 41, y: 9, k: 0 },
	1915: { c: 0, m: 71, y: 20, k: 0 },
	1925: { c: 0, m: 100, y: 55, k: 0 },
	1935: { c: 0, m: 100, y: 57, k: 5 },
	1945: { c: 0, m: 100, y: 56, k: 19 },
	1955: { c: 0, m: 100, y: 60, k: 37 },
	2365: { c: 2, m: 27, y: 0, k: 0 },
	2375: { c: 10, m: 57, y: 0, k: 0 },
	2385: { c: 19, m: 79, y: 0, k: 0 },
	2395: { c: 27, m: 95, y: 0, k: 0 },
	2405: { c: 34, m: 100, y: 0, k: 0 },
	2415: { c: 33, m: 100, y: 0, k: 8 },
	2425: { c: 37, m: 100, y: 0, k: 26 },
	2562: { c: 19, m: 35, y: 0, k: 0 },
	2563: { c: 22, m: 33, y: 0, k: 0 },
	2567: { c: 29, m: 36, y: 0, k: 0 },
	2572: { c: 30, m: 47, y: 0, k: 0 },
	2573: { c: 30, m: 43, y: 0, k: 0 },
	2577: { c: 40, m: 45, y: 0, k: 0 },
	2582: { c: 46, m: 72, y: 0, k: 0 },
	2583: { c: 46, m: 63, y: 0, k: 0 },
	2587: { c: 59, m: 66, y: 0, k: 0 },
	2592: { c: 60, m: 90, y: 0, k: 0 },
	2593: { c: 61, m: 89, y: 0, k: 0 },
	2597: { c: 85, m: 100, y: 0, k: 0 },
	2602: { c: 63, m: 100, y: 0, k: 3 },
	2603: { c: 69, m: 100, y: 0, k: 2 },
	2607: { c: 81, m: 100, y: 0, k: 7 },
	2612: { c: 64, m: 100, y: 0, k: 14 },
	2613: { c: 63, m: 100, y: 0, k: 15 },
	2617: { c: 79, m: 100, y: 0, k: 15 },
	2622: { c: 57, m: 98, y: 0, k: 46 },
	2623: { c: 59, m: 100, y: 0, k: 32 },
	2627: { c: 77, m: 100, y: 0, k: 31 },
	2635: { c: 28, m: 27, y: 0, k: 0 },
	2645: { c: 40, m: 36, y: 0, k: 0 },
	2655: { c: 54, m: 49, y: 0, k: 0 },
	2665: { c: 62, m: 60, y: 0, k: 0 },
	2685: { c: 96, m: 100, y: 0, k: 10 },
	2695: { c: 91, m: 100, y: 0, k: 49 },
	2705: { c: 40, m: 30, y: 0, k: 0 },
	2706: { c: 19, m: 9, y: 0, k: 0 },
	2707: { c: 17, m: 6, y: 0, k: 0 },
	2708: { c: 26, m: 10, y: 0, k: 0 },
	2715: { c: 57, m: 45, y: 0, k: 0 },
	2716: { c: 45, m: 29, y: 0, k: 0 },
	2717: { c: 29, m: 12, y: 0, k: 0 },
	2718: { c: 67, m: 41, y: 0, k: 0 },
	2725: { c: 77, m: 68, y: 0, k: 0 },
	2726: { c: 79, m: 66, y: 0, k: 0 },
	2727: { c: 71, m: 42, y: 0, k: 0 },
	2728: { c: 96, m: 69, y: 0, k: 0 },
	2735: { c: 100, m: 95, y: 0, k: 3 },
	2736: { c: 100, m: 91, y: 0, k: 0 },
	2738: { c: 100, m: 87, y: 0, k: 2 },
	2745: { c: 100, m: 95, y: 0, k: 15 },
	2746: { c: 100, m: 92, y: 0, k: 10 },
	2747: { c: 100, m: 85, y: 0, k: 13 },
	2748: { c: 100, m: 88, y: 0, k: 14 },
	2755: { c: 100, m: 97, y: 0, k: 30 },
	2756: { c: 100, m: 94, y: 0, k: 29 },
	2757: { c: 100, m: 82, y: 0, k: 30 },
	2758: { c: 100, m: 80, y: 0, k: 26 },
	2765: { c: 100, m: 97, y: 0, k: 45 },
	2766: { c: 100, m: 94, y: 0, k: 47 },
	2767: { c: 100, m: 78, y: 0, k: 54 },
	2768: { c: 100, m: 78, y: 0, k: 44 },
	2905: { c: 41, m: 2, y: 0, k: 0 },
	2915: { c: 59, m: 7, y: 0, k: 0 },
	2925: { c: 85, m: 24, y: 0, k: 0 },
	2935: { c: 100, m: 46, y: 0, k: 0 },
	2945: { c: 100, m: 45, y: 0, k: 14 },
	2955: { c: 100, m: 45, y: 0, k: 37 },
	2965: { c: 100, m: 38, y: 0, k: 64 },
	2975: { c: 30, m: 0, y: 5, k: 0 },
	2985: { c: 59, m: 0, y: 6, k: 0 },
	2995: { c: 90, m: 11, y: 0, k: 0 },
	3005: { c: 100, m: 34, y: 0, k: 2 },
	3015: { c: 100, m: 30, y: 0, k: 20 },
	3025: { c: 100, m: 17, y: 0, k: 51 },
	3035: { c: 100, m: 0, y: 5, k: 72 },
	3105: { c: 43, m: 0, y: 12, k: 0 },
	3115: { c: 63, m: 0, y: 18, k: 0 },
	3125: { c: 83, m: 0, y: 21, k: 0 },
	3135: { c: 100, m: 0, y: 16, k: 9 },
	3145: { c: 100, m: 0, y: 19, k: 23 },
	3155: { c: 100, m: 0, y: 24, k: 38 },
	3165: { c: 100, m: 0, y: 28, k: 65 },
	3242: { c: 37, m: 0, y: 18, k: 0 },
	3245: { c: 34, m: 0, y: 19, k: 0 },
	3248: { c: 43, m: 0, y: 24, k: 0 },
	3252: { c: 47, m: 0, y: 24, k: 0 },
	3255: { c: 49, m: 0, y: 28, k: 0 },
	3258: { c: 59, m: 0, y: 33, k: 0 },
	3262: { c: 71, m: 0, y: 33, k: 0 },
	3265: { c: 69, m: 0, y: 37, k: 0 },
	3268: { c: 90, m: 0, y: 49, k: 0 },
	3272: { c: 100, m: 0, y: 44, k: 0 },
	3275: { c: 95, m: 0, y: 47, k: 0 },
	3278: { c: 100, m: 0, y: 55, k: 5 },
	3282: { c: 100, m: 0, y: 46, k: 15 },
	3285: { c: 100, m: 0, y: 50, k: 7 },
	3288: { c: 100, m: 0, y: 54, k: 20 },
	3292: { c: 100, m: 0, y: 49, k: 46 },
	3295: { c: 100, m: 0, y: 53, k: 21 },
	3298: { c: 100, m: 0, y: 57, k: 42 },
	3302: { c: 100, m: 0, y: 54, k: 69 },
	3305: { c: 100, m: 0, y: 61, k: 61 },
	3308: { c: 100, m: 0, y: 60, k: 72 },
	3375: { c: 35, m: 0, y: 25, k: 0 },
	3385: { c: 45, m: 0, y: 33, k: 0 },
	3395: { c: 61, m: 0, y: 45, k: 0 },
	3405: { c: 85, m: 0, y: 65, k: 0 },
	3415: { c: 100, m: 0, y: 77, k: 22 },
	3425: { c: 100, m: 0, y: 78, k: 42 },
	3435: { c: 100, m: 0, y: 81, k: 66 },
	3935: { c: 1, m: 0, y: 68, k: 0 },
	3945: { c: 3, m: 0, y: 85, k: 0 },
	3955: { c: 6, m: 0, y: 100, k: 0 },
	3965: { c: 8, m: 0, y: 100, k: 0 },
	3975: { c: 0, m: 0, y: 100, k: 29 },
	3985: { c: 0, m: 3, y: 100, k: 41 },
	3995: { c: 0, m: 3, y: 100, k: 64 },
	4485: { c: 0, m: 26, y: 100, k: 69 },
	4495: { c: 0, m: 20, y: 95, k: 46 },
	4505: { c: 0, m: 15, y: 78, k: 36 },
	4515: { c: 0, m: 9, y: 50, k: 24 },
	4525: { c: 0, m: 7, y: 39, k: 17 },
	4535: { c: 0, m: 4, y: 30, k: 11 },
	4545: { c: 0, m: 3, y: 19, k: 6 },
	4625: { c: 0, m: 60, y: 100, k: 79 },
	4635: { c: 0, m: 48, y: 96, k: 44 },
	4645: { c: 0, m: 37, y: 68, k: 28 },
	4655: { c: 0, m: 26, y: 45, k: 18 },
	4665: { c: 0, m: 18, y: 32, k: 10 },
	4675: { c: 0, m: 11, y: 21, k: 6 },
	4685: { c: 0, m: 7, y: 14, k: 4 },
	4695: { c: 0, m: 81, y: 100, k: 77 },
	4705: { c: 0, m: 62, y: 71, k: 49 },
	4715: { c: 0, m: 42, y: 45, k: 34 },
	4725: { c: 0, m: 32, y: 35, k: 25 },
	4735: { c: 0, m: 22, y: 23, k: 15 },
	4745: { c: 0, m: 17, y: 18, k: 10 },
	4755: { c: 0, m: 10, y: 12, k: 6 },
	4975: { c: 0, m: 73, y: 100, k: 80 },
	4985: { c: 0, m: 59, y: 48, k: 48 },
	4995: { c: 0, m: 48, y: 38, k: 34 },
	5005: { c: 0, m: 38, y: 27, k: 23 },
	5015: { c: 0, m: 25, y: 15, k: 11 },
	5025: { c: 0, m: 18, y: 12, k: 7 },
	5035: { c: 0, m: 10, y: 9, k: 3 },
	5115: { c: 75, m: 100, y: 70, k: 15 },
	5125: { c: 65, m: 86, y: 49, k: 0 },
	5135: { c: 47, m: 64, y: 28, k: 0 },
	5145: { c: 30, m: 44, y: 13, k: 0 },
	5155: { c: 17, m: 29, y: 8, k: 0 },
	5165: { c: 8, m: 17, y: 5, k: 0 },
	5175: { c: 5, m: 10, y: 3, k: 0 },
	5185: { c: 80, m: 100, y: 85, k: 25 },
	5195: { c: 72, m: 90, y: 75, k: 15 },
	5205: { c: 50, m: 58, y: 50, k: 0 },
	5215: { c: 28, m: 35, y: 24, k: 0 },
	5225: { c: 17, m: 25, y: 15, k: 0 },
	5235: { c: 10, m: 15, y: 10, k: 0 },
	5245: { c: 6, m: 8, y: 7, k: 0 },
	5255: { c: 100, m: 80, y: 0, k: 55 },
	5265: { c: 77, m: 70, y: 0, k: 40 },
	5275: { c: 60, m: 47, y: 0, k: 30 },
	5285: { c: 31, m: 27, y: 0, k: 20 },
	5295: { c: 20, m: 15, y: 0, k: 10 },
	5305: { c: 14, m: 10, y: 0, k: 6 },
	5315: { c: 6, m: 6, y: 0, k: 5 },
	5395: { c: 100, m: 44, y: 0, k: 76 },
	5405: { c: 58, m: 17, y: 0, k: 46 },
	5415: { c: 42, m: 8, y: 0, k: 40 },
	5425: { c: 30, m: 4, y: 0, k: 31 },
	5435: { c: 13, m: 3, y: 0, k: 17 },
	5445: { c: 8, m: 1, y: 0, k: 13 },
	5455: { c: 6, m: 0, y: 0, k: 9 },
	5463: { c: 100, m: 0, y: 18, k: 83 },
	5467: { c: 100, m: 0, y: 33, k: 94 },
	5473: { c: 82, m: 0, y: 28, k: 52 },
	5477: { c: 55, m: 0, y: 27, k: 73 },
	5483: { c: 62, m: 0, y: 21, k: 31 },
	5487: { c: 35, m: 0, y: 16, k: 54 },
	5493: { c: 43, m: 0, y: 14, k: 21 },
	5497: { c: 17, m: 0, y: 9, k: 36 },
	5503: { c: 29, m: 0, y: 10, k: 14 },
	5507: { c: 10, m: 0, y: 6, k: 27 },
	5513: { c: 18, m: 0, y: 7, k: 5 },
	5517: { c: 8, m: 0, y: 5, k: 17 },
	5523: { c: 11, m: 0, y: 5, k: 3 },
	5527: { c: 6, m: 0, y: 4, k: 11 },
	5535: { c: 66, m: 0, y: 57, k: 82 },
	5545: { c: 59, m: 0, y: 50, k: 52 },
	5555: { c: 43, m: 0, y: 34, k: 38 },
	5565: { c: 30, m: 0, y: 24, k: 26 },
	5575: { c: 20, m: 0, y: 16, k: 17 },
	5585: { c: 12, m: 0, y: 11, k: 10 },
	5595: { c: 7, m: 0, y: 8, k: 7 },
	5605: { c: 65, m: 0, y: 56, k: 94 },
	5615: { c: 49, m: 0, y: 44, k: 64 },
	5625: { c: 28, m: 0, y: 29, k: 48 },
	5635: { c: 13, m: 0, y: 18, k: 33 },
	5645: { c: 7, m: 0, y: 11, k: 23 },
	5655: { c: 6, m: 0, y: 9, k: 16 },
	5665: { c: 5, m: 0, y: 7, k: 10 },
	5743: { c: 33, m: 0, y: 85, k: 82 },
	5747: { c: 32, m: 0, y: 100, k: 79 },
	5753: { c: 25, m: 0, y: 81, k: 67 },
	5757: { c: 27, m: 0, y: 95, k: 55 },
	5763: { c: 16, m: 0, y: 74, k: 57 },
	5767: { c: 15, m: 0, y: 68, k: 39 },
	5773: { c: 9, m: 0, y: 43, k: 38 },
	5777: { c: 10, m: 0, y: 49, k: 28 },
	5783: { c: 6, m: 0, y: 28, k: 27 },
	5787: { c: 7, m: 0, y: 31, k: 13 },
	5793: { c: 4, m: 0, y: 21, k: 18 },
	5797: { c: 5, m: 0, y: 24, k: 9 },
	5803: { c: 2, m: 0, y: 12, k: 11 },
	5807: { c: 2, m: 0, y: 14, k: 3 },
	5815: { c: 0, m: 0, y: 91, k: 79 },
	5825: { c: 0, m: 2, y: 87, k: 59 },
	5835: { c: 0, m: 2, y: 67, k: 40 },
	5845: { c: 0, m: 1, y: 47, k: 30 },
	5855: { c: 0, m: 0, y: 31, k: 18 },
	5865: { c: 0, m: 0, y: 25, k: 13 },
	5875: { c: 0, m: 0, y: 26, k: 11 },
	7401: { c: 0, m: 4, y: 18, k: 0 },
	7402: { c: 0, m: 6, y: 30, k: 0 },
	7403: { c: 0, m: 10, y: 50, k: 0 },
	7404: { c: 0, m: 9, y: 79, k: 0 },
	7405: { c: 0, m: 10, y: 99, k: 0 },
	7406: { c: 0, m: 18, y: 100, k: 0 },
	7407: { c: 0, m: 22, y: 85, k: 11 },
	7408: { c: 0, m: 25, y: 95, k: 0 },
	7409: { c: 0, m: 30, y: 95, k: 0 },
	7410: { c: 0, m: 30, y: 55, k: 0 },
	7411: { c: 0, m: 35, y: 69, k: 0 },
	7412: { c: 0, m: 42, y: 100, k: 7 },
	7413: { c: 0, m: 53, y: 100, k: 4 },
	7414: { c: 0, m: 46, y: 100, k: 11 },
	7415: { c: 0, m: 18, y: 15, k: 0 },
	7416: { c: 0, m: 60, y: 60, k: 0 },
	7417: { c: 0, m: 75, y: 75, k: 0 },
	7418: { c: 0, m: 70, y: 60, k: 5 },
	7419: { c: 0, m: 60, y: 45, k: 18 },
	7420: { c: 0, m: 80, y: 42, k: 20 },
	7421: { c: 0, m: 100, y: 30, k: 61 },
	7422: { c: 0, m: 9, y: 5, k: 0 },
	7423: { c: 0, m: 55, y: 23, k: 0 },
	7424: { c: 0, m: 75, y: 30, k: 0 },
	7425: { c: 0, m: 90, y: 30, k: 7 },
	7426: { c: 0, m: 100, y: 45, k: 18 },
	7427: { c: 0, m: 100, y: 65, k: 28 },
	7428: { c: 0, m: 80, y: 45, k: 55 },
	7429: { c: 0, m: 18, y: 3, k: 0 },
	7430: { c: 2, m: 31, y: 0, k: 0 },
	7431: { c: 0, m: 38, y: 2, k: 5 },
	7432: { c: 0, m: 55, y: 3, k: 10 },
	7433: { c: 0, m: 75, y: 15, k: 15 },
	7434: { c: 0, m: 80, y: 15, k: 20 },
	7435: { c: 0, m: 100, y: 10, k: 35 },
	7436: { c: 3, m: 8, y: 0, k: 0 },
	7437: { c: 6, m: 20, y: 0, k: 0 },
	7438: { c: 15, m: 35, y: 0, k: 0 },
	7439: { c: 20, m: 35, y: 0, k: 0 },
	7440: { c: 30, m: 40, y: 0, k: 0 },
	7441: { c: 36, m: 50, y: 0, k: 0 },
	7442: { c: 50, m: 70, y: 0, k: 0 },
	7443: { c: 6, m: 5, y: 0, k: 0 },
	7444: { c: 20, m: 17, y: 0, k: 0 },
	7445: { c: 30, m: 20, y: 0, k: 3 },
	7446: { c: 43, m: 38, y: 0, k: 0 },
	7447: { c: 60, m: 58, y: 0, k: 19 },
	7448: { c: 32, m: 42, y: 0, k: 55 },
	7449: { c: 72, m: 100, y: 77, k: 40 },
	7450: { c: 20, m: 10, y: 0, k: 0 },
	7451: { c: 40, m: 21, y: 0, k: 0 },
	7452: { c: 50, m: 32, y: 0, k: 0 },
	7453: { c: 50, m: 26, y: 0, k: 0 },
	7454: { c: 50, m: 24, y: 0, k: 10 },
	7455: { c: 80, m: 53, y: 0, k: 0 },
	7456: { c: 55, m: 35, y: 0, k: 7 },
	7457: { c: 12, m: 0, y: 2, k: 0 },
	7458: { c: 40, m: 0, y: 5, k: 6 },
	7459: { c: 57, m: 0, y: 6, k: 13 },
	7460: { c: 100, m: 0, y: 0, k: 5 },
	7461: { c: 78, m: 28, y: 0, k: 0 },
	7462: { c: 100, m: 50, y: 0, k: 10 },
	7463: { c: 100, m: 43, y: 0, k: 65 },
	7464: { c: 25, m: 0, y: 10, k: 0 },
	7465: { c: 50, m: 0, y: 25, k: 0 },
	7466: { c: 70, m: 0, y: 23, k: 0 },
	7467: { c: 95, m: 0, y: 25, k: 0 },
	7468: { c: 100, m: 10, y: 0, k: 28 },
	7469: { c: 100, m: 20, y: 0, k: 40 },
	7470: { c: 80, m: 15, y: 0, k: 45 },
	7471: { c: 28, m: 0, y: 14, k: 0 },
	7472: { c: 52, m: 0, y: 25, k: 0 },
	7473: { c: 70, m: 0, y: 38, k: 8 },
	7474: { c: 90, m: 0, y: 28, k: 22 },
	7475: { c: 50, m: 0, y: 25, k: 30 },
	7476: { c: 100, m: 0, y: 43, k: 60 },
	7477: { c: 80, m: 0, y: 10, k: 68 },
	7478: { c: 18, m: 0, y: 14, k: 0 },
	7479: { c: 55, m: 0, y: 50, k: 0 },
	7480: { c: 60, m: 0, y: 50, k: 0 },
	7481: { c: 60, m: 0, y: 55, k: 0 },
	7482: { c: 80, m: 0, y: 75, k: 0 },
	7483: { c: 85, m: 0, y: 100, k: 55 },
	7484: { c: 100, m: 0, y: 85, k: 50 },
	7485: { c: 6, m: 0, y: 10, k: 0 },
	7486: { c: 20, m: 0, y: 30, k: 0 },
	7487: { c: 30, m: 0, y: 45, k: 0 },
	7488: { c: 43, m: 0, y: 60, k: 0 },
	7489: { c: 60, m: 0, y: 80, k: 7 },
	7490: { c: 45, m: 0, y: 80, k: 35 },
	7491: { c: 32, m: 0, y: 100, k: 40 },
	7492: { c: 12, m: 0, y: 50, k: 7 },
	7493: { c: 14, m: 0, y: 36, k: 10 },
	7494: { c: 25, m: 0, y: 40, k: 15 },
	7495: { c: 25, m: 0, y: 80, k: 30 },
	7496: { c: 40, m: 0, y: 100, k: 38 },
	7497: { c: 40, m: 30, y: 70, k: 25 },
	7498: { c: 25, m: 0, y: 100, k: 80 },
	7499: { c: 0, m: 2, y: 15, k: 0 },
	7500: { c: 0, m: 2, y: 15, k: 3 },
	7501: { c: 0, m: 4, y: 20, k: 6 },
	7502: { c: 0, m: 8, y: 35, k: 10 },
	7503: { c: 0, m: 12, y: 35, k: 25 },
	7504: { c: 0, m: 25, y: 45, k: 40 },
	7505: { c: 0, m: 30, y: 70, k: 55 },
	7506: { c: 0, m: 5, y: 15, k: 0 },
	7507: { c: 0, m: 10, y: 30, k: 0 },
	7508: { c: 0, m: 15, y: 40, k: 4 },
	7509: { c: 0, m: 20, y: 50, k: 5 },
	7510: { c: 0, m: 30, y: 72, k: 11 },
	7511: { c: 0, m: 45, y: 100, k: 25 },
	7512: { c: 0, m: 46, y: 100, k: 33 },
	7513: { c: 0, m: 18, y: 28, k: 3 },
	7514: { c: 0, m: 24, y: 38, k: 5 },
	7515: { c: 0, m: 35, y: 50, k: 12 },
	7516: { c: 0, m: 52, y: 100, k: 35 },
	7517: { c: 0, m: 60, y: 100, k: 44 },
	7518: { c: 0, m: 40, y: 55, k: 60 },
	7519: { c: 50, m: 60, y: 100, k: 48 },
	7520: { c: 0, m: 16, y: 19, k: 0 },
	7521: { c: 0, m: 25, y: 20, k: 10 },
	7522: { c: 0, m: 40, y: 30, k: 16 },
	7523: { c: 0, m: 40, y: 35, k: 20 },
	7524: { c: 0, m: 55, y: 60, k: 27 },
	7525: { c: 0, m: 45, y: 50, k: 30 },
	7526: { c: 0, m: 65, y: 100, k: 35 },
	7527: { c: 0, m: 2, y: 6, k: 7 },
	7528: { c: 0, m: 3, y: 10, k: 10 },
	7529: { c: 0, m: 4, y: 12, k: 17 },
	7530: { c: 0, m: 8, y: 21, k: 32 },
	7531: { c: 0, m: 10, y: 27, k: 50 },
	7532: { c: 0, m: 17, y: 50, k: 65 },
	7533: { c: 0, m: 22, y: 85, k: 85 },
	7534: { c: 0, m: 2, y: 8, k: 10 },
	7535: { c: 0, m: 3, y: 15, k: 20 },
	7536: { c: 0, m: 4, y: 22, k: 32 },
	7537: { c: 3, m: 0, y: 10, k: 20 },
	7538: { c: 9, m: 0, y: 13, k: 30 },
	7539: { c: 2, m: 0, y: 9, k: 36 },
	7540: { c: 0, m: 0, y: 0, k: 72 },
	7541: { c: 2, m: 0, y: 0, k: 5 },
	7542: { c: 10, m: 0, y: 3, k: 16 },
	7543: { c: 7, m: 0, y: 0, k: 30 },
	7544: { c: 10, m: 1, y: 0, k: 40 },
	7545: { c: 23, m: 2, y: 0, k: 63 },
	7546: { c: 33, m: 4, y: 0, k: 72 },
	7547: { c: 35, m: 4, y: 0, k: 94 },
	8003: { c: 30, m: 25, y: 40, k: 20 },
	8021: { c: 0, m: 20, y: 30, k: 25 },
	8062: { c: 5, m: 35, y: 15, k: 25 },
	8100: { c: 10, m: 15, y: 5, k: 20 },
	8201: { c: 25, m: 0, y: 0, k: 25 },
	8281: { c: 35, m: 0, y: 20, k: 25 },
	8321: { c: 20, m: 0, y: 30, k: 25 },
	'Black 2': { c: 0, m: 3, y: 55, k: 87 },
	'Black 3': { c: 60, m: 0, y: 60, k: 91 },
	'Black 4': { c: 0, m: 22, y: 100, k: 89 },
	'Black 5': { c: 0, m: 40, y: 22, k: 87 },
	'Black 6': { c: 100, m: 35, y: 0, k: 100 },
	'Black 7': { c: 0, m: 0, y: 15, k: 82 },
	Black: { c: 0, m: 13, y: 49, k: 98 },
	'Blue 072': { c: 100, m: 88, y: 0, k: 5 },
	'Cool Gray 1': { c: 0, m: 0, y: 0, k: 6 },
	'Cool Gray 10': { c: 0, m: 2, y: 0, k: 60 },
	'Cool Gray 11': { c: 0, m: 2, y: 0, k: 68 },
	'Cool Gray 2': { c: 0, m: 0, y: 0, k: 10 },
	'Cool Gray 3': { c: 0, m: 0, y: 0, k: 17 },
	'Cool Gray 4': { c: 0, m: 0, y: 0, k: 24 },
	'Cool Gray 5': { c: 0, m: 0, y: 0, k: 29 },
	'Cool Gray 6': { c: 0, m: 0, y: 0, k: 31 },
	'Cool Gray 7': { c: 0, m: 0, y: 0, k: 37 },
	'Cool Gray 8': { c: 0, m: 1, y: 0, k: 43 },
	'Cool Gray 9': { c: 0, m: 1, y: 0, k: 51 },
	Green: { c: 100, m: 0, y: 59, k: 0 },
	'Hexachrome Black': { c: 1, m: 1, y: 1, k: 100 },
	'Hexachrome Cyan': { c: 98, m: 0, y: 0, k: 0 },
	'Hexachrome Green': { c: 93, m: 0, y: 100, k: 0 },
	'Hexachrome Magenta': { c: 0, m: 97, y: 0, k: 0 },
	'Hexachrome Orange': { c: 0, m: 52, y: 100, k: 0 },
	'Hexachrome Yellow': { c: 0, m: 1, y: 98, k: 0 },
	'Orange 021': { c: 0, m: 53, y: 100, k: 0 },
	'Process Black': { c: 0, m: 0, y: 0, k: 100 },
	'Process Blue': { c: 100, m: 10, y: 0, k: 10 },
	'Process Cyan': { c: 100, m: 0, y: 0, k: 0 },
	'Process Magenta': { c: 0, m: 100, y: 0, k: 0 },
	'Process Yellow': { c: 0, m: 0, y: 100, k: 0 },
	Purple: { c: 38, m: 88, y: 0, k: 0 },
	'Red 032': { c: 0, m: 90, y: 86, k: 0 },
	'Reflex Blue': { c: 100, m: 73, y: 0, k: 2 },
	'Rhodamine Red': { c: 3, m: 89, y: 0, k: 0 },
	'Rubine Red': { c: 0, m: 100, y: 15, k: 4 },
	Violet: { c: 98, m: 100, y: 0, k: 0 },
	'Warm Gray 1': { c: 0, m: 2, y: 3, k: 6 },
	'Warm Gray 10': { c: 0, m: 14, y: 28, k: 55 },
	'Warm Gray 11': { c: 0, m: 17, y: 34, k: 62 },
	'Warm Gray 2': { c: 0, m: 2, y: 5, k: 9 },
	'Warm Gray 3': { c: 0, m: 4, y: 8, k: 17 },
	'Warm Gray 4': { c: 0, m: 4, y: 9, k: 24 },
	'Warm Gray 5': { c: 0, m: 5, y: 10, k: 29 },
	'Warm Gray 6': { c: 0, m: 6, y: 12, k: 31 },
	'Warm Gray 7': { c: 0, m: 8, y: 14, k: 38 },
	'Warm Gray 8': { c: 0, m: 9, y: 16, k: 43 },
	'Warm Gray 9': { c: 0, m: 11, y: 20, k: 47 },
	'Warm Red': { c: 0, m: 75, y: 90, k: 0 },
	'Yellow 012': { c: 0, m: 4, y: 100, k: 0 },
	Yellow: { c: 0, m: 1, y: 100, k: 0 },
};

export default pantoneCMYK;
