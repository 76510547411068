import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { _ } from 'utils/libHelper';
import { roundDecimals } from 'utils/generalHelper';
import {
	loadFontfaceToDocument,
	isConcatField,
	getConcatFieldType,
} from 'utils/artwork/artUtilsCommon';

import { CreatableSelection } from 'components';
import makeStyles from '@mui/styles/makeStyles';
// MUI components
import {
	Divider,
	FormControlLabel,
	Checkbox,
	// OutlinedInput,
	// InputAdornment,
	// Select,
	InputLabel,
	Chip,
	IconButton,
	Tooltip,
	Typography,
	TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import ToggleButton from '@mui/material/ToggleButton';

import {
	FormatAlignLeft as FormatAlignLeftIcon,
	FormatAlignCenter as FormatAlignCenterIcon,
	FormatAlignRight as FormatAlignRightIcon,
	// FormatBold as FormatBoldIcon,
	// FormatItalic as FormatItalicIcon,
	FormatAlignJustify as FormatAlignJustifyIcon,
	OpenInNew as ViewSpreadsheetIcon,
} from '@mui/icons-material';

import {
	FormatVerticalAlignTop as FormatVerticalAlignTopIcon,
	FormatVerticalAlignCenter as FormatVerticalAlignCenterIcon,
	FormatVerticalAlignBottom as FormatVerticalAlignBottomIcon,
} from 'mdi-material-ui';

// Constants
import { ART_VARIABLES, DesignContext } from '../../../Constants';
import {
	StyledToggleButtonGroup,
	ControlledStyledOutlinedTextField,
	StyledOutlinedTextField,
	StyledOutlinedTextFieldSelection,
	SimpleSwitchRow,
	SimpleSwitchVer,
	SimpleRadioVer,
	SimpleNumberSlider,
	StyledOutlinedSelectionWithSubheader,
	DividerVer,
	OutlinedNumberTextField,
	OutlinedInputWithSelect,
} from '../../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

/** TextPaletteStyles Component */
const useStylePaletteStyles = makeStyles((theme) => ({
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		fontSize: 'inherit',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	groupRowLabel: {
		width: '70%',
	},
	fontSelect: {
		// paddingLeft: theme.spacing(1), // `0px ${theme.spacing(1)}`,
		width: '100%', //`calc(60% - calc(${theme.spacing(1)} * 2))`,
		color: 'inherit',
		fontSize: 'inherit',
	},
	currencyFontSelect: {
		margin: theme.spacing(1, 2),
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		fontSize: 'inherit',
	},
	inheritFontStyle: {
		color: 'inherit',
		fontSize: 'inherit',
	},
	autoCompleteEndAdornment: {
		top: 'unset',
		right: '0px !important',
	},
	autoCompleteInput: {
		padding: `3.5px 4px 3.5px 6px !important`,
	},
	fontsizeSelect: {
		// paddingRight: theme.spacing(1), //`0px ${theme.spacing(1)}`,
		width: '60%', //'`calc(40% - calc(${theme.spacing(1)} * 2))`,
		color: 'inherit',
		fontSize: 'inherit',
		padding: 0,
		'& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]': {
			'-webkit-appearance': 'textfield',
			'-moz-appearance': 'textfield',
		},
	},
	fontSizeInput: {
		padding: '12px 4px',
		textAlign: 'center',
	},
	fontSizeUDInput: {
		width: '30%',
	},
	// fontSizeMenuWrapper: {
	// 	minHeight: '10vh',
	// 	maxHeight: '50vh',
	// 	minWidth: 160,
	// 	maxWidth: 220,
	// },
	fontSizeSelectOverride: {
		paddingRight: '0px !important',
		paddingLeft: 4,
	},
	fontSizeSelectMenuPaperOverride: {
		maxHeight: 300,
	},
	// fontGroupRowDiv: {
	// 	// padding: 0,
	// 	justifyContent: 'space-between',
	// 	padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	// },
	twoColumnsRowDiv: {
		justifyContent: 'space-between',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	// textFieldLabel: {
	// 	fontSize: 'inherit',
	// 	// marginLeft: -8,
	// },
	selectMenuItem: {
		fontSize: 'inherit',
	},
	// textFieldSelectMenu: {
	// 	paddingTop: 12,
	// 	paddingBottom: 12,
	// },
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	fontsizeUserDefinedFormControlLabel: {
		margin: 0,
	},
	fontsizeUserDefinedFormControlLabelText: {
		fontSize: 'inherit',
		textAlign: 'center',
	},
	fontsizeUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
	alignUserDefinedFormControlLabel: {
		margin: 0,
	},
	alignUserDefinedFormControlLabelText: {
		fontSize: 'inherit',
		textAlign: 'center',
	},
	alignUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
	strikeThroughLineWidthSelect: {
		// width: '45%',
		margin: 'unset',
		color: 'inherit',
	},
	strikeThroughRadioGroup: {
		width: '55%',
		color: 'inherit',
		fontSize: 'inherit',
		display: 'flex',
		margin: '0px 4px',
		border: '1px solid',
		borderRadius: 4,
		borderColor: 'rgba(0, 0, 0, 0.23)',
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	strikeThroughStrike: {
		position: 'relative',
		'&:before': {
			position: 'absolute',
			content: '""',
			left: -2,
			top: '50%',
			right: -2,
			borderTop: '1px solid',
			transform: 'rotate(0deg)',
		},
	},
	strikeThroughStrikeUp: {
		position: 'relative',
		'&:before': {
			position: 'absolute',
			content: '""',
			left: -2,
			top: '50%',
			right: -2,
			borderTop: '1px solid',
			transform: 'rotate(-15deg)',
		},
	},
	strikeThroughStrikeDown: {
		position: 'relative',
		'&:before': {
			position: 'absolute',
			content: '""',
			left: -2,
			top: '50%',
			right: -2,
			borderTop: '1px solid',
			transform: 'rotate(15deg)',
		},
	},
	// MUISelectIconOutlined: {
	// 	right: 0,
	// },
	toggleGroupSmall: {
		width: 30,
		height: 30,
	},
	formatNumberCurrencySymbol: {
		margin: '8px 16px',
		width: 'calc(100% - 32px)',
	},
	formatNumberToggleRowDiv: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	batchRepeatingEleSpace: {
		margin: '8px 0px',
		width: '100%',
	},
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
}));
export const TextPaletteStyles = ({
	field,
	updateField,
	setColorPicker,
	artworkExtraData,
	// templateFields,
	...rest
}) => {
	const classes = useStylePaletteStyles();
	const intl = useIntl();
	const designContext = React.useContext(DesignContext);
	const artworkFonts = artworkExtraData.fonts || [];
	const [batchRepeatingFieldDelimiters, setBatchRepeatingFieldDelimiters] = React.useState(
		ART_VARIABLES.batchRepeatingFieldDelimiters.concat(
			// in case the delimiter was customized by user (so it is not a pre-defined delimiter )
			field.batchRepeatingFieldStyle.delimiter &&
				!_.find(
					ART_VARIABLES.batchRepeatingFieldDelimiters,
					(o) => o.value === field.batchRepeatingFieldStyle.delimiter
				)
				? [
						{
							value: field.batchRepeatingFieldStyle.delimiter,
							label: field.batchRepeatingFieldStyle.delimiter,
						},
				  ]
				: []
		)
	);

	const [currencySymbolOptions, setCurrencySymbolOptions] = React.useState(
		Array.from(
			new Set(
				ART_VARIABLES.currencySymbol
					.concat(
						field.formatNumberStyle && field.formatNumberStyle.currencySymbol
							? field.formatNumberStyle.currencySymbol
							: ''
					)
					.filter((f) => f)
			)
		)
	);

	let fontsizeOptions = [];
	for (
		var size = ART_VARIABLES.textFieldFontSizeMin, step = 2;
		size <= ART_VARIABLES.textFieldFontSizeMax;

	) {
		fontsizeOptions.push({ label: `${size} ${ART_VARIABLES.fieldFontSizeUnit}`, value: size });
		if (size === 20) {
			step = 10;
		}
		size += step;
	}
	return (
		<div className={classes.tabWrapper}>
			<StyledOutlinedTextFieldSelection
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.TextPalette.inputStyleText',
				})}
				value={field.inputStyle || ''}
				onChange={(e) => {
					updateField({
						// reset textVerticalAlign & textVerticalAlignUserDefined if inputStyle is 'text' (single line text)
						inputStyle: e.target.value || '',
						...(e.target.value === 'text'
							? { textVerticalAlign: 'bottom', textVerticalAlignUserDefined: false }
							: {}),
					});
				}}
				noneOption
				required
				options={[
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.inputStyleSingleLineText',
						}),
						value: 'text',
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.inputStyleTextboxText',
						}),
						value: 'textarea',
					},
				]}
			/>
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.TextPalette.stylesTitleText',
					})}
				</InputLabel>
				{[
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.stylesDoNotSetToFitText',
						}),
						fieldKey: 'doNotSetToFit',
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.stylesSmartQuotesText',
						}),
						fieldKey: 'smartQuotes',
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.stylesRemoveLineBreaksText',
						}),
						fieldKey: 'removeLineBreaks',
					},
					{
						label: intl.formatMessage({
							id:
								'pages.Artwork.components.ElementPalette.TextPalette.stylesWrapLinesContainingSlashText',
						}),
						fieldKey: 'wrapLinesContainingSlash',
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.stylesAllCapsText',
						}),
						fieldKey: 'allCaps',
					},
				]
					.map((item) => (
						<SimpleSwitchRow
							key={item.fieldKey}
							label={item.label}
							checked={Boolean(field[item.fieldKey])}
							onChange={(e, checked) => updateField({ [item.fieldKey]: checked })}
						/>
					))
					.reduce((accu, elem, idx) => {
						return accu.length === 0
							? [elem]
							: [...accu, <Divider key={idx} style={{ width: '100%' }} />, elem];
					}, [])}

				<Divider style={{ width: '100%' }} />
				<div className={classes.groupRowDiv} style={{ flexDirection: 'column' }}>
					<SimpleSwitchRow
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.stylesStrikeThroughText',
						})}
						style={{ padding: '4px 0px' }}
						checked={Boolean(field.strikeThrough)}
						onChange={(e, checked) =>
							updateField({
								strikeThrough: checked,
								strikeThroughStyle: checked ? ART_VARIABLES.defaultStrikeThrough : {},
							})
						}
					/>
					{field.strikeThrough && (
						<div style={{ padding: '4px 4px 8px 8px' }} className={classes.groupRowDiv}>
							<StyledOutlinedTextFieldSelection
								className={classes.strikeThroughLineWidthSelect}
								label={intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.stylesStrikeThroughLineWidthText',
								})}
								value={
									field.strikeThroughStyle && field.strikeThroughStyle.lineWidth
										? field.strikeThroughStyle.lineWidth
										: ''
								}
								onChange={(e) =>
									updateField({
										strikeThroughStyle: {
											...field.strikeThroughStyle,
											lineWidth: e.target.value ? Number(e.target.value) : '',
										},
									})
								}
								// smallMenu
								// smallIcon
								noneOption
								options={_.range(
									ART_VARIABLES.minStrikeLineWidth,
									ART_VARIABLES.maxStrikeLineWidth + 1,
									ART_VARIABLES.stepStrikeLineWidth
								).map((lineWidth) => ({
									label: `${lineWidth}px`,
									value: lineWidth,
								}))}
							/>

							<div className={classes.strikeThroughRadioGroup}>
								{[
									{
										textClassName: classes.strikeThroughStrike,
										type: 'strike',
									},
									{
										textClassName: classes.strikeThroughStrikeUp,
										type: 'strikeup',
									},
									{
										textClassName: classes.strikeThroughStrikeDown,
										type: 'strikedown',
									},
								].map((strikeThroughStyle) => (
									<SimpleRadioVer
										key={strikeThroughStyle.type}
										textClassName={strikeThroughStyle.textClassName}
										checked={
											(field.strikeThroughStyle &&
												field.strikeThroughStyle.type === strikeThroughStyle.type) ||
											false
										}
										onChange={(e) =>
											updateField({
												strikeThroughStyle: {
													...field.strikeThroughStyle,
													type: e.target.value || '',
												},
											})
										}
										value={strikeThroughStyle.type}
										label={intl.formatMessage({
											id:
												'pages.Artwork.components.ElementPalette.TextPalette.stylesStrikeThroughTypeSampleText',
										})}
									/>
								))}
							</div>
						</div>
					)}
				</div>
				<Divider style={{ width: '100%' }} />
				<div className={classes.groupRowDiv} style={{ flexDirection: 'column' }}>
					<SimpleSwitchRow
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberText',
						})}
						style={{ padding: '4px 0px' }}
						checked={Boolean(field.formatNumber)}
						onChange={(e, checked) =>
							updateField({
								formatNumber: checked,
								formatNumberStyle: checked ? ART_VARIABLES.defaultFormatNumber : {},
							})
						}
					/>
					{field.formatNumber && (
						<div
							className={classes.groupsDiv}
							style={{
								width: '90%',
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
								{intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCurrencyText',
								})}
							</InputLabel>
							<OutlinedInputWithSelect
								className={classes.formatNumberCurrencySymbol}
								label={intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCurrencySymbolText',
								})}
								value={
									field.formatNumberStyle && field.formatNumberStyle.currencySymbol
										? field.formatNumberStyle.currencySymbol
										: ''
								}
								// onChange={e => {
								// 	updateField({
								// 		formatNumberStyle: {
								// 			...field.formatNumberStyle,
								// 			currencySymbol: e.target.value.trim() || '',
								// 		},
								// 	});
								// }}
								onCommitted={(e) => {
									let val = e.target.value.trim();
									if (!val) {
										updateField({
											formatNumberStyle: {
												...field.formatNumberStyle,
												currencySymbol: '€', // if no value, use default '€'
											},
										});
									} else {
										setCurrencySymbolOptions(
											Array.from(new Set(currencySymbolOptions.concat(val).filter((f) => f)))
										);
										updateField({
											formatNumberStyle: {
												...field.formatNumberStyle,
												currencySymbol: val,
											},
										});
									}
								}}
								options={currencySymbolOptions.map((symbol) => ({
									label: symbol,
									value: symbol,
								}))}
							/>
							<Divider style={{ width: '100%' }} />
							<StyledOutlinedTextFieldSelection
								select
								label={intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberDisplayAtText',
								})}
								value={
									field.formatNumberStyle && field.formatNumberStyle.currencyFontScale
										? roundDecimals(field.formatNumberStyle.currencyFontScale * 100, 0)
										: ''
								}
								onChange={(e) =>
									updateField({
										formatNumberStyle: {
											...field.formatNumberStyle,
											currencyFontScale: e.target.value
												? roundDecimals(Number(e.target.value) / 100, 2)
												: '',
										},
									})
								}
								options={_.range(10, 101, 5).map((displayAtPercentageNum) => ({
									label: displayAtPercentageNum,
									value: displayAtPercentageNum,
								}))}
							/>
							<Divider style={{ width: '100%' }} />
							<Autocomplete
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.fontText',
								})}
								value={
									artworkFonts.length === 0
										? {}
										: {
												label:
													field.formatNumberStyle.currencyFontName ===
													ART_VARIABLES.placeholderSameAsText
														? intl.formatMessage({
																id:
																	'pages.Artwork.components.ElementPalette.GENERAL.fontSameAsTextMsg',
														  })
														: field.formatNumberStyle.currencyFontName,
												value: field.formatNumberStyle.currencyFontName,
										  }
								}
								size="small"
								options={(artworkFonts.length === 0
									? [
											{
												label: intl.formatMessage({
													id:
														'pages.Artwork.components.ElementPalette.GENERAL.fontListNotAvailableText',
												}),
												value: '',
											},
									  ]
									: [
											{
												label: intl.formatMessage({
													id: 'pages.Artwork.components.ElementPalette.GENERAL.fontSameAsTextMsg',
												}),
												value: ART_VARIABLES.placeholderSameAsText,
											},
											{ label: '---', value: '' },
									  ]
								).concat(
									_.sortBy(artworkFonts, [(item) => item.font.toLowerCase()]).map((font) => ({
										label: font.font,
										value: font.path.substring(font.path.lastIndexOf('/') + 1),
										fontpath: font.path,
									}))
								)}
								getOptionLabel={(option) => option.label || ''}
								getOptionDisabled={(option) => !option.value}
								isOptionEqualToValue={(option, value) => option.label === value.label}
								className={classes.currencyFontSelect}
								classes={{
									clearIndicator: classes.inheritFontStyle,
									popupIndicator: classes.inheritFontStyle,
									endAdornment: classes.autoCompleteEndAdornment,
									listbox: classes.inheritFontStyle,
									option: classes.inheritFontStyle,
									paper: classes.inheritFontStyle,
									input: classes.autoCompleteInput,
								}}
								disableClearable={true}
								onChange={(e, val, reason) => {
									if (reason === 'selectOption') {
										if (val.fontpath) {
											loadFontfaceToDocument({
												name: val.value || ART_VARIABLES.DEFAULT_FONT,
												fontUrl: val.fontpath || ART_VARIABLES.DEFAULT_FONTPATH,
											}).then(({ error }) => {
												if (error) {
													designContext.notifyGeneral &&
														designContext.notifyGeneral(
															`${intl.formatMessage({
																id:
																	'pages.Artwork.components.ElementPalette.GENERAL.cannotLoadFontPrefixText',
															})}: ${val.value || ART_VARIABLES.DEFAULT_FONT}`,
															'error'
														);
												} else {
													updateField({
														formatNumberStyle: {
															...field.formatNumberStyle,
															currencyFontName: val.value || '',
														},
													});
												}
											});
										} else {
											updateField({
												formatNumberStyle: {
													...field.formatNumberStyle,
													currencyFontName: val.value || '',
												},
											});
										}
									}
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label={intl.formatMessage({
											id: 'pages.Artwork.components.ElementPalette.GENERAL.fontFaceText',
										})}
										InputProps={{
											...params.InputProps,
											className: `${params.InputProps.className || ''} ${classes.inheritFontStyle}`,
										}}
										InputLabelProps={{
											sx: {
												fontSize: 'inherit',
												color: 'inherit',
											},
										}}
										variant="outlined"
										fullWidth
									/>
								)}
							/>
							<Divider style={{ width: '100%' }} />
							<div className={classes.formatNumberToggleRowDiv}>
								<StyledToggleButtonGroup
									value={
										field.formatNumberStyle && field.formatNumberStyle.currencyVerticalAlign
											? field.formatNumberStyle.currencyVerticalAlign
											: ''
									}
									exclusive
									classes={{
										grouped: classes.toggleGroupSmall,
									}}
									size="small"
									onChange={(e, alignment) =>
										updateField({
											formatNumberStyle: {
												...field.formatNumberStyle,
												currencyVerticalAlign:
													alignment || ART_VARIABLES.defaultFormatNumber.currencyVerticalAlign,
											},
										})
									}
									aria-label="format number currency ver alignment"
								>
									<ToggleButton value="top" aria-label="format number currency top aligned">
										<FormatVerticalAlignTopIcon />
									</ToggleButton>
									<ToggleButton
										value="middle"
										aria-label="format number currency centered vertical"
									>
										<FormatVerticalAlignCenterIcon />
									</ToggleButton>
									<ToggleButton value="bottom" aria-label="format number currency bottom aligned">
										<FormatVerticalAlignBottomIcon />
									</ToggleButton>
								</StyledToggleButtonGroup>
								<Divider orientation="vertical" className={classes.dividerVertical} />
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									{[
										{
											label: intl.formatMessage({
												id:
													'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCurrencySymbolPositionLeftText',
											}),
											type: 'leading',
										},
										{
											label: intl.formatMessage({
												id:
													'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCurrencySymbolPositionRightText',
											}),
											type: 'trailing',
										},
									].map((position) => (
										<SimpleRadioVer
											key={position.type}
											checked={
												(field.formatNumberStyle &&
													field.formatNumberStyle.currencyPosition === position.type) ||
												false
											}
											onChange={(e) =>
												updateField({
													formatNumberStyle: {
														...field.formatNumberStyle,
														currencyPosition: e.target.value || '',
													},
												})
											}
											value={position.type}
											label={position.label}
										/>
									))}
								</div>
							</div>
						</div>
					)}
					{field.formatNumber && (
						<div
							className={classes.groupsDiv}
							style={{ width: '90%', display: 'flex', alignItems: 'center' }}
						>
							<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
								{intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCentText',
								})}
							</InputLabel>

							{[
								{
									fieldKey: 'displayCent',
									label: intl.formatMessage({
										id:
											'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCentDisplayValueText',
									}),
								},
								{
									fieldKey: 'doNotDisplayZeroCent',
									label: intl.formatMessage({
										id:
											'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberCentNotDisplay0CentsText',
									}),
								},
							]
								.map((item) => (
									<SimpleSwitchRow
										key={item.fieldKey}
										label={item.label}
										checked={Boolean(
											field.formatNumberStyle && field.formatNumberStyle[item.fieldKey]
										)}
										onChange={(e, checked) =>
											updateField({
												formatNumberStyle: { ...field.formatNumberStyle, [item.fieldKey]: checked },
											})
										}
									/>
								))
								.reduce(
									(accu, ele, idx) =>
										accu.length === 0
											? [ele]
											: [...accu, <Divider key={idx} style={{ width: '100%' }} />, ele],
									[]
								)}
							<Divider style={{ width: '100%' }} />
							<StyledOutlinedTextFieldSelection
								select
								label={intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.stylesFormatNumberDisplayAtText',
								})}
								value={
									field.formatNumberStyle && field.formatNumberStyle.centFontScale
										? roundDecimals(field.formatNumberStyle.centFontScale * 100, 0)
										: ''
								}
								onChange={(e) =>
									updateField({
										formatNumberStyle: {
											...field.formatNumberStyle,
											centFontScale: e.target.value
												? roundDecimals(Number(e.target.value) / 100, 2)
												: '',
										},
									})
								}
								options={_.range(10, 101, 5).map((displayAtPercentageNum) => ({
									label: displayAtPercentageNum,
									value: displayAtPercentageNum,
								}))}
							/>
							<Divider style={{ width: '100%' }} />
							<div
								className={classes.formatNumberToggleRowDiv}
								style={{ justifyContent: 'center' }}
							>
								<StyledToggleButtonGroup
									value={
										field.formatNumberStyle && field.formatNumberStyle.centVerticalAlign
											? field.formatNumberStyle.centVerticalAlign
											: ''
									}
									exclusive
									classes={{
										grouped: classes.toggleGroupSmall,
									}}
									size="small"
									onChange={(e, alignment) =>
										updateField({
											formatNumberStyle: {
												...field.formatNumberStyle,
												centVerticalAlign:
													alignment || ART_VARIABLES.defaultFormatNumber.centVerticalAlign,
											},
										})
									}
									aria-label="format number cent ver alignment"
								>
									<ToggleButton value="top" aria-label="format number cent top aligned">
										<FormatVerticalAlignTopIcon />
									</ToggleButton>
									<ToggleButton value="middle" aria-label="format number cent centered vertical">
										<FormatVerticalAlignCenterIcon />
									</ToggleButton>
									<ToggleButton value="bottom" aria-label="format number cent bottom aligned">
										<FormatVerticalAlignBottomIcon />
									</ToggleButton>
								</StyledToggleButtonGroup>
							</div>
						</div>
					)}
				</div>
				<Divider style={{ width: '100%' }} />
				<div className={classes.groupRowDiv} style={{ flexDirection: 'column' }}>
					<SimpleSwitchRow
						label={intl.formatMessage({
							id:
								'pages.Artwork.components.ElementPalette.TextPalette.stylesBatchRepeatingFieldText',
						})}
						style={{ padding: '4px 0px' }}
						checked={Boolean(field.batchRepeatingField)}
						onChange={(e, checked) =>
							updateField({
								batchRepeatingField: checked,
								batchRepeatingFieldStyle: checked ? ART_VARIABLES.defaultBatchRepeating : {},
							})
						}
					/>
					{field.batchRepeatingField && (
						<div
							style={{ padding: '4px 4px 8px 8px', flexDirection: 'column' }}
							className={classes.groupRowDiv}
						>
							<StyledOutlinedTextFieldSelection
								className={classes.batchRepeatingEleSpace}
								label={intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.stylesBatchNumOfRepetitionsText',
								})}
								fullWidth
								value={
									field.batchRepeatingFieldStyle &&
									!isNaN(field.batchRepeatingFieldStyle.NoOfRepetitions)
										? field.batchRepeatingFieldStyle.NoOfRepetitions
										: ''
								}
								onChange={(e) =>
									updateField({
										batchRepeatingFieldStyle: {
											...field.batchRepeatingFieldStyle,
											NoOfRepetitions: Number(e.target.value),
										},
									})
								}
								options={_.range(0, 26, 1).map((num) => ({
									label: num,
									value: num,
								}))}
							/>
							<CreatableSelection
								label={intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.stylesBatchRepeatingFieldDelimiterText',
								})}
								className={classes.batchRepeatingEleSpace}
								value={
									field.batchRepeatingFieldStyle && field.batchRepeatingFieldStyle.delimiter
										? field.batchRepeatingFieldStyle.delimiter
										: ''
								}
								valueLabel={
									field.batchRepeatingFieldStyle && field.batchRepeatingFieldStyle.delimiter
										? (batchRepeatingFieldDelimiters.filter(
												(d) => d.value === field.batchRepeatingFieldStyle.delimiter
										  )[0] || {})['label']
										: null
								}
								options={batchRepeatingFieldDelimiters}
								isClearable={true}
								outlined
								handleCreation={(delimiter) => {
									setBatchRepeatingFieldDelimiters([
										...batchRepeatingFieldDelimiters,
										{ value: delimiter, label: delimiter },
									]);
									updateField({
										batchRepeatingFieldStyle: {
											...field.batchRepeatingFieldStyle,
											delimiter: delimiter || '',
										},
									});
								}}
								handleSelection={(delimiter) => {
									updateField({
										batchRepeatingFieldStyle: {
											...field.batchRepeatingFieldStyle,
											delimiter: delimiter || '',
										},
									});
								}}
							/>
						</div>
					)}
				</div>

				<Divider style={{ width: '100%' }} />
				<div className={classes.groupRowDiv} style={{ justifyContent: 'normal' }}>
					<span className={classes.groupRowLabel}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.stylesTableColumnsText',
						})}
					</span>
					{[
						{
							label: intl.formatMessage({
								id:
									'pages.Artwork.components.ElementPalette.TextPalette.stylesTableColumnsFixedLabel',
							}),
							value: 'fixed',
						},
						{
							label: intl.formatMessage({
								id:
									'pages.Artwork.components.ElementPalette.TextPalette.stylesTableColumnsFlexibleLabel',
							}),
							value: 'flexible',
						},
					].map((tableColumnsStyle) => (
						<SimpleRadioVer
							key={tableColumnsStyle.value}
							checked={field.tableColumns === tableColumnsStyle.value}
							onChange={(e) => updateField({ tableColumns: e.target.value || '' })}
							value={tableColumnsStyle.value}
							label={tableColumnsStyle.label}
						/>
					))}
				</div>
			</div>
			{/** font palette */}
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({ id: 'pages.Artwork.components.ElementPalette.GENERAL.fontText' })}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<Autocomplete
						value={
							artworkFonts.length > 0
								? { label: field.fontfaceName, value: field.fontfaceName }
								: {}
						}
						id="combo-box-demo"
						size="small"
						options={_.sortBy(artworkFonts, [(item) => item.font.toLowerCase()]).map((font) => ({
							label: font.font,
							value: font.path.substring(font.path.lastIndexOf('/') + 1),
							fontpath: font.path,
						}))}
						getOptionLabel={(option) => option.label || ''}
						isOptionEqualToValue={(option, value) => option.label === value.label}
						className={classes.fontSelect}
						classes={{
							clearIndicator: classes.inheritFontStyle,
							popupIndicator: classes.inheritFontStyle,
							endAdornment: classes.autoCompleteEndAdornment,
							listbox: classes.inheritFontStyle,
							option: classes.inheritFontStyle,
							input: classes.autoCompleteInput,
						}}
						disableClearable={true}
						onChange={(e, val, reason) => {
							if (reason === 'selectOption') {
								loadFontfaceToDocument({
									name: val.value || ART_VARIABLES.DEFAULT_FONT,
									fontUrl: val.fontpath || ART_VARIABLES.DEFAULT_FONTPATH,
								}).then(({ error }) => {
									if (error) {
										designContext.notifyGeneral &&
											designContext.notifyGeneral(
												`${intl.formatMessage({
													id:
														'pages.Artwork.components.ElementPalette.GENERAL.cannotLoadFontPrefixText',
												})}: ${val.value || ART_VARIABLES.DEFAULT_FONT}`,
												'error'
											);
									} else {
										updateField({ fontfaceName: val.value || ART_VARIABLES.DEFAULT_FONT });
									}
								});
							}
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.fontFaceText',
								})}
								InputProps={{
									...params.InputProps,
									className: `${params.InputProps.className || ''} ${classes.inheritFontStyle}`,
								}}
								variant="outlined"
								fullWidth
							/>
						)}
					/>
				</div>
				<Divider style={{ width: '100%' }} />
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<OutlinedInputWithSelect
						className={classes.fontsizeSelect}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.fontSizeText',
						})}
						inputSuffix={ART_VARIABLES.fieldFontSizeUnit}
						inputProps={{
							min: ART_VARIABLES.textFieldFontSizeMin,
							max: ART_VARIABLES.textFieldFontSizeMax,
							step: 1,
							type: 'number',
						}}
						value={field.fontsize || ''}
						onCommitted={(e) => {
							let val = Number(e.target.value || ART_VARIABLES.textFieldFontSizeDefault);
							if (val < ART_VARIABLES.textFieldFontSizeMin) {
								updateField({ fontsize: ART_VARIABLES.textFieldFontSizeMin });
							} else if (val > ART_VARIABLES.textFieldFontSizeMax) {
								updateField({ fontsize: ART_VARIABLES.textFieldFontSizeMax });
							} else {
								updateField({ fontsize: val });
							}
						}}
						options={fontsizeOptions}
					/>
					<Divider orientation="vertical" className={classes.dividerVertical} />
					<FormControlLabel
						className={classes.fontsizeUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.fontsizeUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={field.fontsizeUserDefined || false}
								onChange={(e) =>
									updateField({
										fontsizeUserDefined: e.target.checked,
										fontsizeUDStart: !e.target.checked ? null : field.fontsizeUDStart,
										fontsizeUDStep: !e.target.checked ? null : field.fontsizeUDStep,
										fontsizeUDEnd: !e.target.checked ? null : field.fontsizeUDEnd,
									})
								}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.fontsizeUserDefinedFormControlLabelText,
						}}
					/>
				</div>
				{field.fontsizeUserDefined && (
					<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
						<OutlinedNumberTextField
							className={classes.fontSizeUDInput}
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedStartText',
							})}
							value={field.fontsizeUDStart || ''}
							step={1}
							min={1}
							max={field.fontsizeUDEnd ? field.fontsizeUDEnd - 1 : undefined}
							handleOnChange={(num) => updateField({ fontsizeUDStart: num })}
						/>
						<OutlinedNumberTextField
							className={classes.fontSizeUDInput}
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedIncrementText',
							})}
							value={field.fontsizeUDStep || ''}
							step={1}
							min={1}
							handleOnChange={(num) => updateField({ fontsizeUDStep: num })}
						/>
						<OutlinedNumberTextField
							className={classes.fontSizeUDInput}
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedEndText',
							})}
							value={field.fontsizeUDEnd || ''}
							step={1}
							min={field.fontsizeUDStart ? field.fontsizeUDStart + 1 : 1}
							handleOnChange={(num) => updateField({ fontsizeUDEnd: num })}
						/>
					</div>
				)}
				<Divider style={{ width: '100%' }} />
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					{
						// 	<StyledToggleButtonGroup
						// 	size="small"
						// 	value={field.textFormat || []}
						// 	onChange={(e, format) => updateField({ textFormat: format })}
						// 	aria-label="text formatting"
						// >
						// 	<ToggleButton value="bold" aria-label="bold">
						// 		<FormatBoldIcon />
						// 	</ToggleButton>
						// 	<ToggleButton value="italic" aria-label="italic">
						// 		<FormatItalicIcon />
						// 	</ToggleButton>
						// </StyledToggleButtonGroup>
						// <Divider orientation="vertical" className={classes.dividerVertical} />
						// <div style={{ display: 'inline-flex', alignItems: 'center' }}>
					}
					<span style={{ margin: '0px 4px', width: 90, textAlign: 'center' }}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.fontColorText',
						})}
					</span>
					<div
						style={{
							display: 'inline-flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: `calc(100% - 90px)`,
						}}
					>
						<div
							style={{
								backgroundColor:
									field.fontColor && field.fontColor.hex ? field.fontColor.hex : '#000',
								width: '80%',
								height: 40,
								margin: '0px 4px',
								cursor: 'pointer',
								borderRadius: 4,
							}}
							onClick={(e) =>
								setColorPicker({
									anchorEl: e.currentTarget,
									fieldKey: 'fontColor',
									disableAlpha: true,
									enablePantone: true,
									enableCMYK: true,
								})
							}
						></div>
					</div>
				</div>

				<Divider style={{ width: '100%' }} />
				<div
					className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}
					style={{ justifyContent: 'space-evenly' }}
				>
					<StyledToggleButtonGroup
						value={field.textHorizontalAlign || ''}
						exclusive
						size="small"
						onChange={(e, alignment) =>
							updateField({
								textHorizontalAlign:
									alignment || ART_VARIABLES.defaultFields.text.textHorizontalAlign,
							})
						}
						aria-label="text alignment"
					>
						<ToggleButton value="left" aria-label="left aligned">
							<FormatAlignLeftIcon />
						</ToggleButton>
						<ToggleButton value="center" aria-label="centered horizontal">
							<FormatAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="right" aria-label="right aligned">
							<FormatAlignRightIcon />
						</ToggleButton>
						<ToggleButton value="justified" aria-label="justified aligned">
							<FormatAlignJustifyIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<Divider orientation="vertical" className={classes.dividerVertical} />
					<FormControlLabel
						className={classes.alignUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={field.textHorizontalAlignUserDefined || false}
								onChange={(e) => updateField({ textHorizontalAlignUserDefined: e.target.checked })}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.alignUserDefinedFormControlLabelText,
						}}
					/>
				</div>
				<Divider style={{ width: '100%' }} />
				<div
					className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}
					style={{ justifyContent: 'space-evenly' }}
				>
					<StyledToggleButtonGroup
						value={field.textVerticalAlign || ''}
						exclusive
						size="small"
						disabled={field.inputStyle === 'text'}
						onChange={(e, alignment) =>
							updateField({
								textVerticalAlign: alignment || ART_VARIABLES.defaultFields.text.textVerticalAlign,
							})
						}
						aria-label="text alignment"
					>
						<ToggleButton value="top" aria-label="top aligned">
							<FormatVerticalAlignTopIcon />
						</ToggleButton>
						<ToggleButton value="middle" aria-label="middle aligned">
							<FormatVerticalAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="bottom" aria-label="bottom aligned">
							<FormatVerticalAlignBottomIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<Divider orientation="vertical" className={classes.dividerVertical} />
					<FormControlLabel
						className={classes.alignUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disabled={field.inputStyle === 'text'}
								disableRipple
								checked={field.textVerticalAlignUserDefined || false}
								onChange={(e) => updateField({ textVerticalAlignUserDefined: e.target.checked })}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.alignUserDefinedFormControlLabelText,
						}}
					/>
				</div>
				<Divider style={{ width: '100%' }} />
				{[
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.spacingText',
						}),
						value: field.letterSpacing,
						handleValueChange: (value) => updateField({ letterSpacing: value }),
						step: ART_VARIABLES.textFieldLetterSpacingStep,
						min: ART_VARIABLES.textFieldLetterSpacingMin,
						max: ART_VARIABLES.textFieldLetterSpacingMax,
					},
					{
						/* (
							<span>
								Leading <br />
								(Line Height)
							</span>
						), */
						label: intl.formatMessage(
							{
								id: 'pages.Artwork.components.ElementPalette.TextPalette.leadingText',
							},
							{ br: <br /> }
						),
						value: field.leadingLineHeight,
						handleValueChange: (value) => updateField({ leadingLineHeight: value }),
						step: ART_VARIABLES.textFieldLeadingLineHeightStep,
						min: ART_VARIABLES.textFieldLeadingLineHeightMin,
						max: ART_VARIABLES.textFieldLeadingLineHeightMax,
					},
				]
					.map((item) => {
						return (
							<div key={item.label} className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
								<SimpleNumberSlider
									label={item.label}
									value={item.value}
									handleOnChange={item.handleValueChange}
									step={item.step}
									min={item.min}
									max={item.max}
								/>
							</div>
						);
					})
					.reduce(
						(accu, elem, idx) =>
							accu.length === 0
								? [elem]
								: [...accu, <Divider key={idx} style={{ width: '100%' }} />, elem],
						[]
					)}
			</div>
			{/** text shadow palette */}
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.shadowText',
					})}
				</InputLabel>
				{[
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.shadowHorizontalText',
						}),
						value:
							typeof field.shadowHorOffset === 'number'
								? field.shadowHorOffset
								: ART_VARIABLES.textFieldShadowOffsetDefault,
						handleValueChange: (value) => updateField({ shadowHorOffset: value }),
						step: ART_VARIABLES.textFieldShadowOffsetStep,
						min: ART_VARIABLES.textFieldShadowOffsetMin,
						max: ART_VARIABLES.textFieldShadowOffsetMax,
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.shadowVerticalText',
						}),
						value:
							typeof field.shadowVerOffset === 'number'
								? field.shadowVerOffset
								: ART_VARIABLES.textFieldShadowOffsetDefault,
						handleValueChange: (value) => updateField({ shadowVerOffset: value }),
						step: ART_VARIABLES.textFieldShadowOffsetStep,
						min: ART_VARIABLES.textFieldShadowOffsetMin,
						max: ART_VARIABLES.textFieldShadowOffsetMax,
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.shadowBlurRadiusText',
						}),
						value:
							typeof field.shadowBlurRadius === 'number'
								? field.shadowBlurRadius
								: ART_VARIABLES.textFieldShadowBlurDefault,
						handleValueChange: (value) => updateField({ shadowBlurRadius: value }),
						step: ART_VARIABLES.textFieldShadowBlurStep,
						min: ART_VARIABLES.textFieldShadowBlurMin,
						max: ART_VARIABLES.textFieldShadowBlurMax,
					},
				]
					.map((item) => {
						return (
							<div key={item.label} className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
								<SimpleNumberSlider
									label={item.label}
									value={item.value}
									handleOnChange={item.handleValueChange}
									step={item.step}
									min={item.min}
									max={item.max}
								/>
							</div>
						);
					})
					.reduce(
						(accu, elem, idx) =>
							accu.length === 0
								? [elem]
								: [...accu, <Divider key={idx} style={{ width: '100%' }} />, elem],
						[]
					)}
				<Divider style={{ width: '100%' }} />
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<span style={{ paddingRight: 8, width: 90, textAlign: 'left' }}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.colorText',
						})}
					</span>
					<div
						style={{ width: `calc(100% - 90px)`, display: 'inline-flex', justifyContent: 'center' }}
					>
						<div
							style={{
								backgroundColor:
									field.textShadowColor && field.textShadowColor.hex
										? field.textShadowColor.hex
										: '#000',
								width: '80%',
								height: 40,
								margin: '0px 4px',
								cursor: 'pointer',
								borderRadius: 4,
							}}
							onClick={(e) =>
								setColorPicker({
									anchorEl: e.currentTarget,
									fieldKey: 'textShadowColor',
									disableAlpha: true,
									enablePantone: true,
									enableCMYK: true,
								})
							}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};

TextPaletteStyles.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	artworkExtraData: PropTypes.object.isRequired,
	// templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
	setColorPicker: PropTypes.func.isRequired,
};

TextPaletteStyles.defaultProps = {};

/** TextPaletteData Component */
const useDataPaletteStyles = makeStyles((theme) => ({
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
	defaultValueHelperText: {
		// border: '1px solid rgba(0,0,0,0.15)',
		// borderTop: 'none',
		margin: 0,
		padding: '8px 8px',
		borderRadius: 4,
		backgroundColor: 'rgba(0,0,0,0.1)',
		'&:hover': {
			// borderColor: 'rgba(0,0,0,0.5)',
			backgroundColor: 'rgba(0,0,0,0.15)',
		},
	},
	defaultValueHelperLine: {
		margin: theme.spacing(0.5, 0),
		lineHeight: '1.6rem',
	},
}));

export const TextPaletteData = ({
	field,
	updateField,
	updateFields,
	artworkExtraData,
	templateFields,
	...rest
}) => {
	const classes = useDataPaletteStyles();
	const intl = useIntl();
	const defaultValueRef = React.useRef(null);
	const [fieldDefaultVal, setFieldDefaultVal] = React.useState(field.defaultValue);
	// local state to control focusing on default value textarea
	const [focusDefaultValue, setFocusDefaultValue] = React.useState({ isFocus: false, focusAt: 0 });

	React.useEffect(() => {
		setFieldDefaultVal(field.defaultValue);
	}, [field.defaultValue]);

	React.useEffect(() => {
		if (focusDefaultValue.isFocus) {
			defaultValueRef.current.focus({ preventScroll: false });
			// defaultValueRef.current.selectionEnd = focusDefaultValue.focusAt;
			defaultValueRef.current.setSelectionRange(
				focusDefaultValue.focusAt,
				focusDefaultValue.focusAt
			);
		}
	}, [focusDefaultValue]);
	// generate grouped template fields
	const groupedAllFieldsForEmbed = {};
	const groupedFieldsWithPredefinedSSValue = {};
	const groupedImageFields = {};
	const groupedTextFields = {};
	const validFieldTypesForEmbed = !isConcatField(field)
		? ['text', 'image']
		: getConcatFieldType(field, templateFields) === 'IMAGE_ONLY'
		? ['image']
		: ['text'];
	templateFields.forEach((f) => {
		if (!groupedAllFieldsForEmbed[f.groupName]) {
			groupedAllFieldsForEmbed[f.groupName] = [];
		}
		groupedAllFieldsForEmbed[f.groupName].push({
			value: f.name.trim().replace(/\s/g, '-') + '_' + f.id,
			label: f.name,
			/**
			 * disable following fields from selection as embedded field:
			 * - the current field itself
			 * - concatenation field (no nest concatenation)
			 * - field type is not suitable for the concatenation type of the current field
			 */
			disabled: f.id === field.id || isConcatField(f) || !validFieldTypesForEmbed.includes(f.type),
		});

		if (f.predefinedValue && f.predefinedValue.type === 'spreadsheet' && f.predefinedValue.from) {
			if (!groupedFieldsWithPredefinedSSValue[f.groupName]) {
				groupedFieldsWithPredefinedSSValue[f.groupName] = [];
			}
			groupedFieldsWithPredefinedSSValue[f.groupName].push({
				value: f.id,
				label: f.name,
				disabled: f.id === field.id,
			});
		}
		if (f.type === 'image') {
			if (!groupedImageFields[f.groupName]) {
				groupedImageFields[f.groupName] = [];
			}
			groupedImageFields[f.groupName].push({
				value: f.id,
				label: f.name,
				disabled: f.id === field.id,
			});
		}
		if (f.type === 'text') {
			if (!groupedTextFields[f.groupName]) {
				groupedTextFields[f.groupName] = [];
			}
			groupedTextFields[f.groupName].push({
				value: f.id,
				label: f.name,
				disabled: f.id === field.id,
			});
		}
	});

	let autoImportSpreadsheet = null;
	if (field.autoImportMeta.basedOn && field.autoImport === 'cell:') {
		let basedOnField = _.find(templateFields, (f) => f.id === field.autoImportMeta.basedOn);
		if (basedOnField) {
			autoImportSpreadsheet = _.find(
				artworkExtraData.spreadsheets,
				(s) => s.id === basedOnField.predefinedValue.from
			);
		}
	}

	let hasEmbededField = new RegExp(/<field:\s*(.*?)\s*>/gim).test(field.defaultValue || '');
	return (
		<div className={classes.tabWrapper}>
			{
				// <StyledOutlinedSelectionWithSubheader
				// 	fullWidth
				// 	select
				// 	label="Depends On"
				// 	value={field.dependsOn || ''}
				// 	onChange={e => updateField({ dependsOn: e.target.value || '' })}
				// 	noneOption
				// 	options={groupedTemplateFields}
				// />
			}
			{
				/** Auto Import Section */
				<div
					className={classes.groupsDiv}
					style={
						field.autoImport.startsWith('image:') || field.autoImport === 'cell:'
							? {}
							: { margin: 0, width: '100%', border: 'none' }
					}
				>
					<StyledOutlinedSelectionWithSubheader
						fullWidth
						select
						disabled={Boolean(field.predefinedValue.type) || hasEmbededField}
						label={
							field.predefinedValue.type || hasEmbededField ? (
								<Typography component="div" variant="inherit" noWrap>
									{intl.formatMessage({
										id:
											'pages.Artwork.components.ElementPalette.TextPalette.disableAutoImportHelperLabel',
									})}
								</Typography>
							) : (
								intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.autoImportText',
								})
							)
						}
						value={field.autoImport || ''}
						onChange={(e) =>
							updateField({
								autoImport: e.target.value || '',
								// reset autoImport metadata
								autoImportMeta: field.defaultAutoImportMeta,
								// reset predefinedValue
								predefinedValue: field.defaultTextPredefinedValue,
							})
						}
						noneOption
						options={{ ...artworkExtraData.autoImport, ...ART_VARIABLES.autoImportOtherForText }}
					/>
					{(field.autoImport.startsWith('image:') || field.autoImport === 'cell:') && (
						<StyledOutlinedSelectionWithSubheader
							fullWidth
							select
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.basedOnText',
							})}
							value={field.autoImportMeta.basedOn || ''}
							onChange={(e) =>
								updateField({
									autoImportMeta: {
										...field.defaultAutoImportMeta,
										basedOn: e.target.value || '',
										// spreadsheetCell: '',
									},
								})
							}
							noneMsg={
								field.autoImport === 'cell:' &&
								Object.keys(groupedFieldsWithPredefinedSSValue).length === 0
									? intl.formatMessage(
											{
												id: 'pages.Artwork.components.ElementPalette.GENERAL.no_NAME_AvailableText',
											},
											{ name: 'Fields' }
									  )
									: field.autoImport.startsWith('image:') &&
									  Object.keys(groupedImageFields).length === 0
									? intl.formatMessage({
											id: 'pages.Artwork.components.ElementPalette.GENERAL.noImageFieldsText',
									  })
									: null
							}
							noneOption
							options={
								field.autoImport === 'cell:'
									? groupedFieldsWithPredefinedSSValue
									: field.autoImport.startsWith('image:')
									? groupedImageFields
									: []
							}
						/>
					)}
					{field.autoImportMeta.basedOn && field.autoImport === 'cell:' && (
						<React.Fragment>
							<StyledOutlinedTextFieldSelection
								fullWidth
								select
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.chooseCellText',
								})}
								value={field.autoImportMeta.spreadsheetCell || ''}
								onChange={(e) =>
									updateField({
										autoImportMeta: {
											...field.autoImportMeta,
											spreadsheetCell: e.target.value || null,
										},
									})
								}
								noneMsg={
									autoImportSpreadsheet && Array.isArray(autoImportSpreadsheet.column_names)
										? null
										: intl.formatMessage({
												id: 'pages.Artwork.components.ElementPalette.GENERAL.notAvailableText',
										  })
								}
								noneOption
								options={
									autoImportSpreadsheet && Array.isArray(autoImportSpreadsheet.column_names)
										? autoImportSpreadsheet.column_names.map((c) => ({
												value: c.position,
												label: c.column_name,
										  }))
										: []
								}
							/>
							<div className={classes.groupRowDiv}>
								{[
									{
										label: intl.formatMessage({
											id: 'pages.Artwork.components.ElementPalette.GENERAL.esignEditableText',
										}),
										fieldKey: 'esignEditable',
									},
									{
										label: intl.formatMessage({
											id: 'pages.Artwork.components.ElementPalette.GENERAL.esignSearchableText',
										}),
										fieldKey: 'esignSearchable',
									},
									{
										label: intl.formatMessage({
											id: 'pages.Artwork.components.ElementPalette.GENERAL.esignFilterText',
										}),
										fieldKey: 'esignFilter',
									},
								]
									.map((item) => (
										<SimpleSwitchVer
											key={item.fieldKey}
											label={item.label}
											style={{ padding: 0 }}
											checked={field.autoImportMeta[item.fieldKey] || false}
											onChange={(e, checked) =>
												updateField({
													autoImportMeta: { ...field.autoImportMeta, [item.fieldKey]: checked },
												})
											}
										/>
									))
									.reduce((accu, elem, idx) => {
										return accu.length === 0 ? [elem] : [...accu, <DividerVer key={idx} />, elem];
									}, [])}
							</div>
						</React.Fragment>
					)}
				</div>
			}
			{
				/** Predefined Settings */
				<div
					className={classes.groupsDiv}
					style={field.predefinedValue.type ? {} : { margin: 0, width: '100%', border: 'none' }}
				>
					{field.predefinedValue.type && (
						<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.TextPalette.predefinedValueText',
							})}
						</InputLabel>
					)}
					<StyledOutlinedTextFieldSelection
						fullWidth
						select
						disabled={Boolean(field.autoImport) || hasEmbededField}
						label={
							field.autoImport || hasEmbededField ? (
								<Typography component="div" variant="inherit" noWrap>
									{intl.formatMessage({
										id:
											'pages.Artwork.components.ElementPalette.TextPalette.disablePredefinedHelperLabel',
									})}
								</Typography>
							) : field.predefinedValue.type ? (
								intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.typeText',
								})
							) : (
								intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.TextPalette.predefinedValueText',
								})
							)
						}
						value={field.predefinedValue.type || ''}
						onChange={(e) => {
							if (e.target.value !== field.predefinedValue.type) {
								updateFields([
									{
										...field,
										predefinedValue: {
											...field.defaultTextPredefinedValue,
											type: e.target.value || '',
										},
										// reset autoImport data in this field
										autoImport: '',
										autoImportMeta: field.defaultAutoImportMeta,
									},
									// reset the fields which's autoImport is based on this field
									...templateFields
										.filter(
											(f) =>
												f.id !== field.id &&
												f.autoImportMeta &&
												f.autoImportMeta.basedOn === field.id
										)
										.map((f) => ({ ...f, autoImportMeta: f.defaultAutoImportMeta })),
								]);
							}
						}} // it resets other properties in predefined object
						noneOption
						options={ART_VARIABLES.predefinedValueOptions}
					/>
					{field.predefinedValue.type && (
						<React.Fragment>
							<Divider style={{ width: '100%' }} />
							<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
								<StyledOutlinedTextFieldSelection
									fullWidth
									select
									label={intl.formatMessage({
										id: 'pages.Artwork.components.ElementPalette.GENERAL.chooseFromText',
									})}
									value={field.predefinedValue.from || ''}
									onChange={(e) => {
										if (e.target.value !== field.predefinedValue.from) {
											updateFields([
												{
													...field,
													predefinedValue: {
														...field.predefinedValue,
														from: e.target.value || null,
														// fromColumn: null,
														// listAllowOther: false,
														// isEsignTemplate: false,
														// isEsignOnly: false,
														// outputTemplate: null,
														// alternativeTemplateId: '',
													},
												},
												// reset the fields which's autoImport is based on this field
												...templateFields
													.filter(
														(f) =>
															f.id !== field.id &&
															f.autoImportMeta &&
															f.autoImportMeta.basedOn === field.id
													)
													.map((f) => {
														let selectedSpreadsheet = _.find(
															artworkExtraData.spreadsheets,
															(s) => s.id === e.target.value
														);
														let ifPositionExists = selectedSpreadsheet
															? _.find(
																	selectedSpreadsheet.column_names,
																	(s) => s.position === f.autoImportMeta.spreadsheetCell
															  )
															: undefined;
														let tempAutoImportMeta = {};
														if (!ifPositionExists) {
															tempAutoImportMeta = {
																...f.autoImportMeta,
																spreadsheetCell: null,
															};
															return { ...f, autoImportMeta: tempAutoImportMeta };
														} else {
															return { ...f };
														}
													}),
											]);
										}
									}}
									noneMsg={
										field.predefinedValue.type === 'list' && artworkExtraData.lists.length === 0
											? intl.formatMessage(
													{
														id:
															'pages.Artwork.components.ElementPalette.GENERAL.no_NAME_AvailableText',
													},
													{ name: 'List' }
											  )
											: field.predefinedValue.type === 'spreadsheet' &&
											  artworkExtraData.spreadsheets.length === 0
											? intl.formatMessage(
													{
														id:
															'pages.Artwork.components.ElementPalette.GENERAL.no_NAME_AvailableText',
													},
													{ name: 'Spreadsheet' }
											  )
											: null
									}
									noneOption
									options={
										field.predefinedValue.type === 'list'
											? artworkExtraData.lists.map((item) => ({
													value: item.id,
													label: item.list_name,
											  }))
											: field.predefinedValue.type === 'spreadsheet'
											? artworkExtraData.spreadsheets.map((item) => ({
													value: item.id,
													label: item.spreadsheet_name,
											  }))
											: []
									}
								/>
								{field.predefinedValue.type === 'spreadsheet' && field.predefinedValue.from && (
									<Tooltip
										arrow
										title={intl.formatMessage({
											id:
												'pages.Artwork.components.ElementPalette.TextPalette.viewSpreadsheetTooltip',
										})}
									>
										<IconButton
											size="small"
											href={`/manage_spreadsheets/?id=${field.predefinedValue.from}`}
											target="_blank"
											style={{ marginRight: 16 }}
										>
											<ViewSpreadsheetIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								)}
							</div>
						</React.Fragment>
					)}
					{/** fromColumn is only available for "spreadsheet", not for "list" */}
					{field.predefinedValue.type === 'spreadsheet' && field.predefinedValue.from && (
						<React.Fragment>
							<Divider style={{ width: '100%' }} />
							<StyledOutlinedTextFieldSelection
								fullWidth
								select
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.columnText',
								})}
								value={field.predefinedValue.fromColumn || ''}
								onChange={(e) =>
									updateField({
										predefinedValue: {
											...field.predefinedValue,
											fromColumn: e.target.value || null,
										},
									})
								}
								noneOption
								options={
									field.predefinedValue.type === 'list'
										? (
												artworkExtraData.lists.filter(
													(item) => item.id === field.predefinedValue.from
												)[0] || { list_items: [] }
										  ).list_items // in case the previously selected list is no longer available
												.map((item) => ({ value: item.sequence, label: item.value }))
										: field.predefinedValue.type === 'spreadsheet'
										? (
												artworkExtraData.spreadsheets.filter(
													(item) => item.id === field.predefinedValue.from
												)[0] || { column_names: [] }
										  ).column_names // in case the previously selected spreadsheet is no longer available
												.map((item) => ({
													value: item.position,
													label: item.column_name,
												}))
										: []
								}
							/>
						</React.Fragment>
					)}
					{field.predefinedValue.type === 'list' && field.predefinedValue.from && (
						<React.Fragment>
							<Divider style={{ width: '100%' }} />
							<SimpleSwitchRow
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.TextPalette.allowOtherText',
								})}
								style={{ padding: '8px 24px' }}
								checked={field.predefinedValue.listAllowOther}
								onChange={(e, checked) =>
									updateField({
										predefinedValue: { ...field.predefinedValue, listAllowOther: checked },
									})
								}
							/>
						</React.Fragment>
					)}
				</div>
			}
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.TextPalette.calValueTitle',
					})}
				</InputLabel>
				<div className={classes.groupRowDiv}>
					<StyledOutlinedSelectionWithSubheader
						fullWidth
						select
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.calValuePriceText',
						})}
						value={field.calcValue.price || ''}
						onChange={(e) =>
							updateField({ calcValue: { ...field.calcValue, price: e.target.value || '' } })
						}
						style={{
							margin: '8px 2px',
						}}
						smallMenu
						smallIcon
						noneOption
						options={groupedTextFields}
					/>
					<StyledOutlinedSelectionWithSubheader
						fullWidth
						select
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.calValueUnitText',
						})}
						value={field.calcValue.unit || ''}
						onChange={(e) =>
							updateField({ calcValue: { ...field.calcValue, unit: e.target.value || '' } })
						}
						style={{
							margin: '8px 2px',
						}}
						smallMenu
						smallIcon
						noneOption
						options={groupedTextFields}
					/>
					<StyledOutlinedSelectionWithSubheader
						fullWidth
						select
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.calValueQtyText',
						})}
						value={field.calcValue.qty || ''}
						onChange={(e) =>
							updateField({ calcValue: { ...field.calcValue, qty: e.target.value || '' } })
						}
						style={{
							margin: '8px 2px',
						}}
						smallMenu
						smallIcon
						noneOption
						options={groupedTextFields}
					/>
				</div>
				<Divider style={{ width: '100%' }} />
				<div className={classes.groupRowDiv}>
					<StyledOutlinedTextField
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.calValuePerText',
						})}
						value={field.calcValue.per || ''}
						onChange={(e) =>
							updateField({ calcValue: { ...field.calcValue, per: e.target.value || '' } })
						}
						style={{ margin: '8px 4px' }}
					/>
					<Divider orientation="vertical" className={classes.dividerVertical} />
					<SimpleSwitchVer
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.calValueHideText',
						})}
						style={{ padding: 0 }}
						checked={field.calcValue.hide}
						onChange={(e, checked) =>
							updateField({ calcValue: { ...field.calcValue, hide: checked } })
						}
					/>
				</div>
				<Divider style={{ width: '100%' }} />
				<StyledOutlinedTextField
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.TextPalette.calValueMultipleWeightMsgText',
					})}
					value={field.calcValue.multipleWeightsMsg || ''}
					onChange={(e) =>
						updateField({
							calcValue: { ...field.calcValue, multipleWeightsMsg: e.target.value || '' },
						})
					}
					style={{ padding: '0px 4px' }}
				/>
			</div>

			<div
				className={classes.groupsDiv}
				style={hasEmbededField ? {} : { margin: 0, width: '100%', border: 'none' }}
			>
				{hasEmbededField && (
					<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.TextPalette.embedFieldStyleTitleText',
						})}
					</InputLabel>
				)}
				{hasEmbededField && (
					<StyledOutlinedTextFieldSelection
						fullWidth
						select
						label={intl.formatMessage({
							id:
								'pages.Artwork.components.ElementPalette.TextPalette.embedFieldStyleEmbeddingStyleText',
						})}
						value={field.embedStyle || ''}
						onChange={(e) => {
							updateField({ embedStyle: e.target.value || 'inherit' });
						}}
						// noneOption
						options={ART_VARIABLES.embedStyleOptions}
					/>
				)}
				{hasEmbededField && (
					<StyledOutlinedTextFieldSelection
						fullWidth
						select
						label={intl.formatMessage({
							id:
								'pages.Artwork.components.ElementPalette.TextPalette.embedFieldStyleLeadingStyleText',
						})}
						value={field.embedLeadingStyle || ''}
						onChange={(e) => {
							updateField({ embedLeadingStyle: e.target.value || 'inherit' });
						}}
						// noneOption
						options={ART_VARIABLES.embedStyleOptions}
					/>
				)}
				<StyledOutlinedSelectionWithSubheader
					fullWidth
					select
					disabled={Boolean(field.autoImport || field.predefinedValue.type)}
					label={
						field.autoImport || field.predefinedValue.type ? (
							<Typography component="div" variant="inherit" noWrap>
								{intl.formatMessage({
									id:
										'pages.Artwork.components.ElementPalette.TextPalette.disableEmbeddedHelperLabel',
								})}
							</Typography>
						) : (
							intl.formatMessage({
								id:
									'pages.Artwork.components.ElementPalette.TextPalette.embedFieldStyleSelectFieldText',
							})
						)
					}
					value={''}
					onChange={(e) => {
						// updateField({ calcValue: { ...field.calcValue, qty: e.target.value || '' } })
						let firstPart = field.defaultValue.substring(0, defaultValueRef.current.selectionStart);
						let secondPart = field.defaultValue.substring(defaultValueRef.current.selectionEnd);
						let insertStr = ` <field:${e.target.value || ''}> `;
						// string.replace(/^ +| +$/gm, "");
						// trim only white space, don't trim line breaks
						let defaultVal =
							firstPart.replace(/^ +| +$/gm, '') + insertStr + secondPart.replace(/^ +| +$/gm, '');
						// let defaultVal = firstPart.trim() + insertStr + secondPart.trim();
						e.target.value &&
							updateField({
								defaultValue: defaultVal,
								embedStyle: field.embedStyle || 'inherit',
								embedLeadingStyle: field.embedLeadingStyle || 'inherit',
								hideInput: true,
							});
						setFieldDefaultVal(defaultVal);
						// make default value textarea to be focused
						setFocusDefaultValue({
							isFocus: true,
							focusAt:
								defaultValueRef.current.selectionEnd +
								insertStr.length -
								(firstPart.length - firstPart.trim().length),
						});
					}}
					// noneOption
					options={groupedAllFieldsForEmbed}
				/>
			</div>

			<ControlledStyledOutlinedTextField
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GENERAL.defaultValText',
				})}
				inputRef={defaultValueRef}
				inputProps={{ style: { resize: 'vertical' } }}
				multiline
				rows={5}
				value={fieldDefaultVal || ''}
				onChange={(e) => {
					// let hasEmbededField = new RegExp(/<field:\s*(.*?)\s*>/gim).test(e.target.value || '');
					// let data = { defaultValue: e.target.value || '' };
					// if (!hasEmbededField) {
					// 	data.embedStyle = '';
					// 	data.embedLeadingStyle = '';
					// }
					// updateField(data);
					setFieldDefaultVal(e.target.value || '');
				}}
				onBlur={(e) => {
					let hasEmbededField = new RegExp(/<field:\s*(.*?)\s*>/gim).test(e.target.value || '');
					let data = { defaultValue: e.target.value || '' };
					if (!hasEmbededField) {
						data.embedStyle = '';
						data.embedLeadingStyle = '';
					}
					updateField(data);
					focusDefaultValue.isFocus && setFocusDefaultValue({ isFocus: false, focusAt: 0 });
				}}
				FormHelperTextProps={
					new RegExp(/<field:\s*(.*?)\s*>/gim).test(fieldDefaultVal || '')
						? {
								component: 'div' /* variant: 'filled', filled: true, disabled: true */,
								className: classes.defaultValueHelperText,
						  }
						: null
				}
				helperText={
					new RegExp(/<field:\s*(.*?)\s*>/gim).test(fieldDefaultVal || '') ? (
						<div>
							{(fieldDefaultVal || '').split('\n').map((lineStr, lineIdx) => {
								// NOTE: we want each word in words array to be "word + whitespace" except last word
								// let words = lineStr.split(/(?<=\s)/gm).filter((w) => Boolean(w.trim())); // with Regex Lookbehind
								let words = lineStr.split(' ').filter((w) => Boolean(w.trim()));
								words = words.map((w, idx) => (idx === words.length - 1 ? w : w + ' '));

								return (
									<div
										key={`concat-helper-br-${lineIdx}`}
										className={classes.defaultValueHelperLine}
									>
										{words.map((word, wordIdx) => {
											if (word.startsWith('<field:')) {
												// find embedded field name
												let fieldName = word
													.substring(0, word.lastIndexOf('_'))
													.replace('<field:', '');
												return (
													<Chip
														key={`concat-helper-${lineIdx}-${wordIdx}`}
														color="secondary"
														component="span"
														label={fieldName + word.substring(word.indexOf('>') + 1)}
														size="small"
														style={{ marginRight: word.endsWith(' ') ? '.3rem' : 0 }}
													/>
												);
											} else {
												return word;
											}
										})}
									</div>
								);
							})}
						</div>
					) : null
				}
			/>
		</div>
	);
};

TextPaletteData.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	updateFields: PropTypes.func.isRequired,
	artworkExtraData: PropTypes.object.isRequired,
	templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
};

TextPaletteData.defaultProps = {};
