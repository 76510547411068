import React from 'react';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import { convertHtmlAttrToReact } from 'utils/libHelper';
import { prepareConcatImageField } from 'utils/artwork/artFieldsCreator';
import { createAnimationOfFieldInVideoArtwork } from 'utils/artwork/artUtilsWebUI';

const useStyles = makeStyles((theme) => ({
	pauseAnimation: {
		animationPlayState: 'paused !important',
	},
}));

/**
 * Fix for "Can't perform a React state update on an unmounted component" problem caused by Asynchronous (Promise) setState in useEffect
 * reference to https://www.debuggr.io/react-update-unmounted-component/
 *
 * @param {func} effect
 * @param {Array} dependencies
 */
function useAbortableEffect(effect, dependencies) {
	const status = {}; // mutable status object
	React.useEffect(() => {
		status.aborted = false;
		// pass the mutable object to the effect callback
		// store the returned value for cleanup
		const cleanUpFn = effect(status);
		return () => {
			// mutate the object to signal the consumer
			// this effect is cleaning up
			status.aborted = true;
			if (typeof cleanUpFn === 'function') {
				// run the cleanup function
				cleanUpFn();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...dependencies]);
}

function ConcatImageOnly({
	templateFields,
	fieldOutputData,
	field,
	// fieldPreviewData,
	animationDelay,
	allowAnimation,
	pausingAnimation,
	isVideoArtwork,
	...rest
}) {
	const classes = useStyles();
	const [imageData, setImageData] = React.useState(null);
	useAbortableEffect(
		(status) => {
			prepareConcatImageField({
				mode: 'preview',
				// inputData: fieldPreviewData,
				field,
				templateFields,
				fieldOutputData,
				dataForExport: {},
				CONSTANTS: { placeholderSameAsText: ART_VARIABLES.placeholderSameAsText },
			})
				.then((concatImageData) => {
					if (!status.aborted) setImageData(concatImageData);
				})
				.catch((err) => {
					console.error(err);
					if (!status.aborted) setImageData(null);
				});
		},
		[field, fieldOutputData, templateFields]
	);

	if (!imageData) return null;
	let { images, gContentScale } = imageData;

	const noAnimationStyle = `0s ease 0s 1 normal none running none`;
	const animationEntranceStyle = isVideoArtwork
		? createAnimationOfFieldInVideoArtwork(field)
		: field.animation.entrance
		? `${field.animation.entrance} ${
				field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
		  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
				animationDelay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
		  }s both`
		: noAnimationStyle;
	// const animationExitStyle = field.animation.exit
	// 	? `${field.animation.exit} ${field.animation.duration ||
	// 			1}s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${0}s both`
	// 	: noAnimationStyle;

	return (
		<svg
			x={field.position.left}
			y={field.position.top}
			width={field.position.width}
			height={field.position.height}
			overflow={'visible'}
		>
			<g
				className={cx({ [classes.pauseAnimation]: pausingAnimation })}
				style={{
					animation: allowAnimation ? animationEntranceStyle : noAnimationStyle,
					opacity: isVideoArtwork && !allowAnimation ? 0 : undefined, // allowAnimation is used to control replay of animation/video, there is about 100ms delay when replay animation/video, so for videoArtwork, we hide text preview when replay was just clicked but before replay actually begins
				}}
			>
				{/* Somehow, "transform" not working when together with "style" in the same <g> */}
				<g
					transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
						field.position.height / 2
					}) scale(${gContentScale})`}
				>
					{images.map((image, idx) => {
						return (
							<image
								key={`concat-${field.id}-${image.id}-${idx}`}
								{...convertHtmlAttrToReact(image.attrs)}
							/>
						);
					})}
				</g>
			</g>
		</svg>
	);
}

ConcatImageOnly.propTypes = {
	templateFields: PropTypes.array.isRequired,
	/**
	 * field output data of all tempalte fields, combination of user input data & field default data
	 * guaranteed any value is not null|undefined, it will be '', true/false or "actual value"
	 */
	fieldOutputData: PropTypes.object.isRequired,
	field: PropTypes.object.isRequired, // it is text field
	// fieldPreviewData: PropTypes.object.isRequired, // the preview data of a field
	animationDelay: PropTypes.number.isRequired, // animation delay. Relative to previous fields
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is aninimation paused (on video end in videoArtwork)
	isVideoArtwork: PropTypes.bool.isRequired,
};

ConcatImageOnly.defaultProps = {};
export default React.memo(ConcatImageOnly);
