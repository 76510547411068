import React from 'react';

import { /*  DesignContext, */ ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { convertHtmlAttrToReact } from 'utils/libHelper';
import { prepareGridField } from 'utils/artwork/artFieldsCreator';

const useStyles = makeStyles((theme) => ({
	pauseAnimation: {
		animationPlayState: 'paused !important',
	},
}));

function Grid({
	field,
	fieldPreviewData,
	animationDelay,
	allowAnimation,
	pausingAnimation,
	...rest
}) {
	// const designContext = React.useContext(DesignContext);
	const classes = useStyles();
	// const intl = useIntl();

	const gridRenderData = prepareGridField({
		mode: 'preview',
		inputData: fieldPreviewData,
		field,
		CONSTANTS: { placeholderSameAsText: ART_VARIABLES.placeholderSameAsText },
	});

	if (!gridRenderData) return null;
	const { headerRowCells, contentRowCells, ruleLines, scale } = gridRenderData;

	const noAnimationStyle = `0s ease 0s 1 normal none running none`;
	const animationEntranceStyle = field.animation.entrance
		? `${field.animation.entrance} ${
				field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
		  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
				animationDelay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
		  }s both`
		: noAnimationStyle;
	// const animationExitStyle = field.animation.exit
	// 	? `${field.animation.exit} ${field.animation.duration ||
	// 			1}s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${0}s both`
	// 	: noAnimationStyle;

	return (
		<svg
			x={field.position.left}
			y={field.position.top}
			width={field.position.width}
			height={field.position.height}
			overflow={'visible'}
		>
			<g
				className={cx({ [classes.pauseAnimation]: pausingAnimation })}
				style={{
					animation:
						allowAnimation && field.animation.entrance ? animationEntranceStyle : noAnimationStyle,
				}}
			>
				{/* Somehow, "transform" not working when together with "style" in the same <g> */}
				<g
					transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
						field.position.height / 2
					}) scale(${scale})`}
				>
					{
						// 	<text
						// 	fontSize="234.37500000000003pt"
						// 	fill="#000000"
						// 	textAnchor="middle"
						// 	dominantBaseline="middle"
						// >
						// 	I am a menu
						// </text>
					}
					{headerRowCells && (
						<text {...convertHtmlAttrToReact(headerRowCells.rowText.attrs)}>
							{headerRowCells.cellsTspan.map((cell, idx) => {
								return (
									<tspan
										key={`grid-header-${field.id}-${idx}`}
										{...convertHtmlAttrToReact(cell.attrs)}
									>
										{cell.textContent}
									</tspan>
								);
							})}
						</text>
					)}
					{contentRowCells &&
						contentRowCells.map((rowCells, rowIdx) => {
							// {
							// 	rowText: { attrs: textAttrs },
							// 	cellsTspan: [
							// 		{
							// 			content: { attrs: contentTspanAttrs, 	bBox: bBox, textContent: contentTspan.textContent,},
							// 			subtitle: { attrs: subtitleTspanAttrs, bBox: bBox, textContent: subtitleTspan.textContent,},
							// 		},
							// 		...
							// 	],
							// },

							return (
								<text
									key={`grid-row-${field.id}-${rowIdx}`}
									{...convertHtmlAttrToReact(rowCells.rowText.attrs)}
								>
									{rowCells.cellsTspan.map((cell, cellIdx) => {
										return (
											<React.Fragment key={`grid-row-content-${field.id}-${rowIdx}-${cellIdx}`}>
												{cell.content.positioned &&
													cell.content.positioned.map((contentLine, lineIdx) => {
														return (
															<tspan
																key={`grid-row-content-${field.id}-${rowIdx}-${cellIdx}-${lineIdx}`}
																{...convertHtmlAttrToReact(contentLine.attrs)}
															>
																{contentLine.textContent}
															</tspan>
														);
													})}
												{cell.subtitle.positioned &&
													cell.subtitle.positioned.map((subtitleLine, lineIdx) => {
														return (
															<tspan
																key={`grid-row-subtitle-${field.id}-${rowIdx}-${cellIdx}-${lineIdx}`}
																{...convertHtmlAttrToReact(subtitleLine.attrs)}
															>
																{subtitleLine.textContent}
															</tspan>
														);
													})}
											</React.Fragment>
										);
										// return (
										// 	<React.Fragment key={`grid-row-content-${field.id}-${rowIdx}-${cellIdx}`}>
										// 		{/** content <tspan> */}
										// 		<tspan
										// 			// key={`grid-row-content-${field.id}-${rowIdx}-${cellIdx}`}
										// 			{...convertHtmlAttrToReact(cell.content.attrs)}
										// 		>
										// 			{cell.content.textContent}
										// 		</tspan>
										// 		{/** content <tspan> */}
										// 		<tspan
										// 			// key={`grid-row-subtitle-${field.id}-${rowIdx}-${cellIdx}`}
										// 			{...convertHtmlAttrToReact(cell.subtitle.attrs)}
										// 		>
										// 			{cell.subtitle.textContent}
										// 		</tspan>
										// 	</React.Fragment>
										// );
									})}
								</text>
							);
						})}
					{
						/** rules (underlines) */
						ruleLines &&
							ruleLines.map((line, idx) => {
								return (
									<line
										key={`grid-rules-${field.id}-${idx}`}
										{...convertHtmlAttrToReact(line.attrs)}
									/>
								);
							})
					}
				</g>
			</g>
		</svg>
	);
}

Grid.propTypes = {
	field: PropTypes.object.isRequired, // it is text field
	fieldPreviewData: PropTypes.object.isRequired, // the preview data of a field
	animationDelay: PropTypes.number.isRequired, // animation delay. Relative to previous fields
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is aninimation paused (on video end in videoArtwork)
};

Grid.defaultProps = {};
export default React.memo(Grid);
