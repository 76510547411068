import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import Popover from '@mui/material/Popover';
import SpeedDial from '@mui/material/SpeedDial';
// import SpeedDialIcon from '@mui/lab/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	// hidden: {
	// 	display: 'none'
	// },
	transparentBG: {
		backgroundColor: 'transparent',
	},
}));

/*
 * Example of speedDialActions
 const actions = [
	{ icon: <DeleteIcon />, name: 'Delete', onClick: (event)=>{
		console.log('you clicked delete button');
	} },
	{ icon: <EditIcon />, name: 'Edit', onClick: (event)=>{
		console.log('you clicked edit button');
	} },
	{ icon: <DeleteIcon />, name: 'Delete', onClick: (event)=>{
		console.log('you clicked delete button');
	} },
	{ icon: <EditIcon />, name: 'Edit', onClick: (event)=>{
		console.log('you clicked edit button');
	} },
];
 */
function SpeedDialPopover({ speedDialActions, isOpen, anchorPosition, onClose }) {
	const classes = useStyles();
	return (
		<Popover
			id="transparentPopover"
			elevation={0}
			PaperProps={{ className: classes.transparentBG }}
			open={isOpen}
			anchorPosition={anchorPosition}
			onClose={onClose}
			anchorReference="anchorPosition"
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'left',
			}}
		>
			<SpeedDial
				ariaLabel="SpeedDial Actions"
				// ButtonProps={{ className: classes.hidden }}
				// icon={<SpeedDialIcon />}
				hidden
				open={isOpen}
				direction="right"
			>
				{speedDialActions.map((action) => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={action.onClick}
						tooltipPlacement="bottom"
					/>
				))}
			</SpeedDial>
		</Popover>
	);
}

SpeedDialPopover.propTypes = {
	speedDialActions: PropTypes.array.isRequired,
	isOpen: PropTypes.bool.isRequired,
	anchorPosition: PropTypes.object,
	onClose: PropTypes.func.isRequired,
};

SpeedDialPopover.defaultProps = {};

export default SpeedDialPopover;
