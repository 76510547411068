import React from 'react';
import PropTypes from 'prop-types';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { useTheme } from '@mui/material/styles';

const LOCALSTORAGE_PREFIX = 'PageTourDone_';

/**
 * Page tour component base off Joyride. https://github.com/gilbarbara/react-joyride.
 * NOTE: props.tourId used to store tour done status. Uses Localstorage and add a prefix: const LOCALSTORAGE_PREFIX
 * @param {*} (object) props - check PageTour.propTyes at the bottom.
 * @returns Joyride - renders Joyride component
 */
const PageTour = (props) => {
	// let pageTourDone =
	// 	props.tourId && localStorage.getItem(LOCALSTORAGE_PREFIX + props.tourId) === 'true';
	let pageTourDone = 'true';
	const [pageTourHelpers, setPageTourHelpers] = React.useState({});
	const { steps, run, runIfNotDone, tourId, onClose, ...rest } = props;
	const theme = useTheme();

	return (
		<Joyride
			steps={steps}
			styles={{
				options: {
					primaryColor: theme.palette.primary.main,
					textColor: theme.palette.text.primary,
				},
				tooltipContainer: {
					textAlign: 'left',
				},
			}}
			scrollToFirstStep={true}
			showSkipButton={true}
			showProgress={true}
			continuous={true}
			run={run || (runIfNotDone && !pageTourDone)}
			spotlightClicks={true}
			hideCloseButton={true}
			getHelpers={(helpers) => setPageTourHelpers(helpers)}
			callback={(cbArgs) => {
				const { action, status } = cbArgs;
				if (action === ACTIONS.CLOSE || [STATUS.FINISHED, STATUS.SKIPPED].indexOf(status) >= 0) {
					// Reset the tour and close it
					pageTourHelpers.reset && pageTourHelpers.reset();
					onClose && onClose();
					// Sets the done status to localstorage
					tourId && localStorage.setItem(LOCALSTORAGE_PREFIX + tourId, true);
				}
			}}
			{...rest}
		/>
	);
};

PageTour.propTyes = {
	/** Joyride "steps" prop */
	steps: PropTypes.array.isRequired,
	/** Joyride "run" prop */
	run: PropTypes.bool.isRequired,
	/** Checks if the tour is already done using the given tourId */
	runIfNotDone: PropTypes.bool,
	/** Id used to store tour done status. Uses Localstorage and add a prefix: const LOCALSTORAGE_PREFIX*/
	tourId: PropTypes.string,
	/** onClose handler. RRan if the tour is finished, closed or skipped. */
	onClose: PropTypes.func,
};

PageTour.defaultProps = {
	steps: [],
	run: false,
	runIfNotDone: false,
};

// export default withStyles(PageTourStyle)(PageTour);
export default PageTour;
