const animations = {
	/* ----------------------------------------------
	 * Generated by Animista on 2019-10-18 10:17:32
	 * Licensed under FreeBSD License.
	 * See http://animista.net/license for more info.
	 * w: http://animista.net, t: @cssanimista
	 * ---------------------------------------------- */

	/**
	 * ----------------------------------------
	 * animation slide-out-elliptic-top-bck
	 * ----------------------------------------
	 */
	'@keyframes slideOutEllipticTopBck': {
		'0%': {
			transform: 'translateY(0) rotateX(0) scale(1)',
			'transform-origin': '50% 1400px',
			opacity: 1,
		},
		'100%': {
			transform: 'translateY(-600px) rotateX(-30deg) scale(0)',
			transformOrigin: '50% 100%',
			opacity: 1,
		},
	},
	// animSlideOutEllipticTopBck: {
	// 	'animation': '$slideOutEllipticTopBck 0.7s ease-in both'
	// },
	/**
	 * ----------------------------------------
	 * animation slide-in-elliptic-top-fwd
	 * ----------------------------------------
	 */
	'@keyframes slideInEllipticTopFwd': {
		'0%': {
			transform: 'translateY(-600px) rotateX(-30deg) scale(0)',
			transformOrigin: '50% 100%',
			opacity: 0,
		},
		'100%': {
			transform: 'translateY(0) rotateX(0) scale(1)',
			transformOrigin: '50% 1400px',
			opacity: 1,
		},
	},
	// animSlideInEllipticTopFwd: {
	// 	'animation': '$slideInEllipticTopFwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
	// },

	/**
	 * ----------------------------------------
	 * animation rotate-center
	 * ----------------------------------------
	 */
	'@keyframes rotateCenter': {
		'0%': {
			transform: 'rotate(0)',
		},
		'100%': {
			transform: 'rotate(360deg)',
		},
	},
	// animRotateCenter: {
	// 	animation: '$rotateCenter 0.7s ease-in-out both',
	// },

	/**
	 * ----------------------------------------
	 * animation shake (ring)
	 * ----------------------------------------
	 */
	'@keyframes ring': {
		'0%': {
			transform: 'rotate(15deg)',
		},
		'50%': {
			transform: 'rotate(-15deg)',
		},
		'100%': {
			transform: 'rotate(15deg)',
		},
	},
	// shake: {
	// 	animation: '$ring .3s infinite',
	// },
};

export default animations;
