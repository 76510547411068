import { OPEN_GLOBAL_DIALOG, RESET_GLOBAL_DIALOG } from 'redux/actions';
const defaultDialog = {
	open: false,
	size: 'sm',
	title: '',
	content: '',
	confirmCB: null,
	onClose: null,
};
export const globalDialog = (state = defaultDialog, action) => {
	const { type } = action;
	switch (type) {
		case OPEN_GLOBAL_DIALOG:
			return {
				...state,
				open: true,
				size: action.size,
				title: action.title,
				content: action.content,
				confirmCB: action.confirmCB || null,
				onClose: action.onClose || null,
			};
		case RESET_GLOBAL_DIALOG:
			return {
				...state,
				open: false,
				size: 'sm',
				title: '',
				content: '',
				confirmCB: null,
				onClose: null,
			};
		default:
			return state;
	}
};
