import {
	FETCH_SCREENMANAGER_PLAYLISTS,
	FETCH_SCREENMANAGER_PLAYLISTS_SUCCESS,
	FETCH_SCREENMANAGER_PLAYLISTS_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_PLAYLISTS_REQUEST,
	EDIT_SCREENMANAGER_PLAYLISTS_SUCCESS,
	EDIT_SCREENMANAGER_PLAYLISTS_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_PLAYLISTS_REQUEST,
	ADD_SCREENMANAGER_PLAYLISTS_FAIL,
	ADD_SCREENMANAGER_PLAYLISTS_SUCCESS,
	RESET_SCREENMANAGER_PLAYLISTS_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_PLAYLISTS_REQUEST,
	DELETE_SCREENMANAGER_PLAYLISTS_SUCCESS,
	DELETE_SCREENMANAGER_PLAYLISTS_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_DELETESTATUS,
} from 'redux/actions';
import {
	DUPLICATE_SCREENMANAGER_PLAYLIST_REQUEST,
	DUPLICATE_SCREENMANAGER_PLAYLIST_SUCCESS,
	DUPLICATE_SCREENMANAGER_PLAYLIST_FAIL,
} from 'redux/actions';

const screenManagerPlaylistsInitState = {
	isFetching: false,
	playlists: [],
	addingStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	editingStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	deleteStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	duplicateStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
};

export const screenManagerPlaylists = (state = screenManagerPlaylistsInitState, action) => {
	switch (action.type) {
		case FETCH_SCREENMANAGER_PLAYLISTS:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_SCREENMANAGER_PLAYLISTS_SUCCESS:
			return {
				...state,
				isFetching: false,
				playlists: action.result.data, // this is axios response data, result is the name in middleware, data is response.data in axios request(url) function
			};
		case FETCH_SCREENMANAGER_PLAYLISTS_FAIL:
			if (action.error.response && action.error.response.status === 404) {
				return {
					...state,
					isFetching: false,
					playlists: [],
				};
			} else {
				return {
					...state,
					isFetching: false,
				};
			}
		case RESET_SCREENMANAGER_PLAYLISTS:
			return {
				...state,
				isFetching: false,
				playlists: [],
				addingStatus: '',
				editingStatus: '',
				deleteStatus: '',
			};
		case EDIT_SCREENMANAGER_PLAYLISTS_REQUEST:
			return {
				...state,
				editingStatus: 'PROCESSING',
			};
		case EDIT_SCREENMANAGER_PLAYLISTS_SUCCESS: {
			let updatedPlaylist = { ...action.result.data };
			updatedPlaylist.noOfSlides = updatedPlaylist.slides.length;
			delete updatedPlaylist.slides;
			updatedPlaylist.noOfSlidesFromMaster = updatedPlaylist.masterSlides.length;
			updatedPlaylist.durationMaster = updatedPlaylist.masterSlides.reduce(
				(accu, masterSlide) => accu + masterSlide.duration,
				0
			);
			delete updatedPlaylist.masterSlides;
			return {
				...state,
				playlists: state.playlists.map((playlist) => {
					if (playlist.id === updatedPlaylist.id) {
						playlist = updatedPlaylist;
					}
					return playlist;
				}),
				editingStatus: 'OK',
			};
		}
		case EDIT_SCREENMANAGER_PLAYLISTS_FAIL:
			return {
				...state,
				editingStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_PLAYLISTS_EDITSTATUS:
			return {
				...state,
				editingStatus: '',
			};
		case ADD_SCREENMANAGER_PLAYLISTS_REQUEST:
			return {
				...state,
				addingStatus: 'PROCESSING',
			};
		case ADD_SCREENMANAGER_PLAYLISTS_FAIL:
			return {
				...state,
				addingStatus: 'FAILED',
			};
		case ADD_SCREENMANAGER_PLAYLISTS_SUCCESS: {
			let playlistsClone = state.playlists.slice(0);
			let newPlaylist = { ...action.result.data };
			newPlaylist.noOfSlides = newPlaylist.slides.length;
			newPlaylist.noOfSlidesFromMaster = newPlaylist.masterSlides.length;
			newPlaylist.durationMaster = newPlaylist.masterSlides.reduce(
				(accu, masterSlide) => accu + masterSlide.duration,
				0
			);
			delete newPlaylist.slides;
			delete newPlaylist.masterSlides;
			playlistsClone.unshift(newPlaylist);
			return {
				...state,
				playlists: playlistsClone,
				addingStatus: 'OK',
			};
		}
		case RESET_SCREENMANAGER_PLAYLISTS_ADDSTATUS:
			return {
				...state,
				addingStatus: '',
			};
		case DELETE_SCREENMANAGER_PLAYLISTS_REQUEST:
			return {
				...state,
				deleteStatus: 'PROCESSING',
			};
		case DELETE_SCREENMANAGER_PLAYLISTS_FAIL:
			return {
				...state,
				deleteStatus: 'FAILED',
			};
		case DELETE_SCREENMANAGER_PLAYLISTS_SUCCESS: {
			return {
				...state,
				playlists: state.playlists.filter((playlist) => playlist.id !== action.playlistId),
				deleteStatus: 'OK',
			};
		}
		case RESET_SCREENMANAGER_PLAYLISTS_DELETESTATUS:
			return {
				...state,
				deleteStatus: '',
			};
		case DUPLICATE_SCREENMANAGER_PLAYLIST_REQUEST:
			return {
				...state,
				duplicateStatus: 'PROCESSING',
			};
		case DUPLICATE_SCREENMANAGER_PLAYLIST_FAIL:
			return {
				...state,
				duplicateStatus: 'FAILED',
			};
		case DUPLICATE_SCREENMANAGER_PLAYLIST_SUCCESS: {
			let { srcPlaylistId } = action;
			let duplicatedPlaylist = { ...action.result.data };
			duplicatedPlaylist.noOfSlides = duplicatedPlaylist.slides.length;
			duplicatedPlaylist.durationMaster = duplicatedPlaylist.masterSlides.reduce(
				(accu, masterSlide) => {
					return accu + masterSlide.duration;
				},
				0
			);
			duplicatedPlaylist.noOfSlidesFromMaster = duplicatedPlaylist.masterSlides.length;
			delete duplicatedPlaylist.slides;
			delete duplicatedPlaylist.masterSlides;
			let currentPlaylists = [...state.playlists];
			let indexOfSrcPlaylist = currentPlaylists.findIndex((item) => item.id === srcPlaylistId);
			currentPlaylists.splice(indexOfSrcPlaylist, 0, duplicatedPlaylist);
			return {
				...state,
				playlists: currentPlaylists,
				duplicateStatus: 'OK',
			};
		}
		default:
			return state;
	}
};
