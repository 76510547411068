/** ##############################
 * 	Artwork WebUI Helper
 *
 * 	Used only in browser-side (NOT used in server-side artwork generation)
 *
 *  It has functions that are used only in webUI, to render the artwork in pages
 *
 * 	#############################
 */

import colorConvert from 'color-convert';
import { getRgbFromCmyk } from 'restful/fileManager';
import { isBlobUrl, floorDecimals } from '../generalHelper';
import _pantoneCMYK from './Pantone-CMYK';
import config from 'config';
import _deepClone from 'rfdc';

/**
 *
 * @param {object|array|null} opts rfdc options. Default { proto: false, circles: false }
 */
const deepClone = (JSONData = {}, opts) => {
	return _deepClone(opts)(JSONData);
};

export const pantoneCMYK = _pantoneCMYK;
/**
 * Validate artwork field
 * @param {object} field Artwork field
 * @returns {array} array of invalid message string
 */
export const validateField = (field, artworkTemplate) => {
	let invalidMsg = [];
	switch (field.type) {
		case 'text':
			// auto import is from "image" or "spreadsheet", but no "basedOn" selected
			if (
				(field.autoImport.startsWith('image:') || field.autoImport === 'cell:') &&
				!field.autoImportMeta.basedOn
			) {
				invalidMsg.push(`Missing "basedOn" in auto-import data`);
			}
			// auto import is from "spreadsheet","basedOn" is selected, but missing "choose cell"
			if (
				field.autoImport === 'cell:' &&
				field.autoImportMeta.basedOn &&
				!field.autoImportMeta.spreadsheetCell
			) {
				invalidMsg.push(`Missing "cell" data in auto-import`);
			}
			// predefined type is selected, but "from" is missing
			if (
				['spreadsheet', 'list'].includes(field.predefinedValue.type) &&
				!field.predefinedValue.from
			) {
				invalidMsg.push(`Missing "Choose From" in predefined data`);
			}
			// predefined type is 'spreadsheet', , but "from" is missing
			if (
				field.predefinedValue.type === 'spreadsheet' &&
				field.predefinedValue.from &&
				!field.predefinedValue.fromColumn
			) {
				invalidMsg.push(`Missing "Column" in predefined data`);
			}
			// field is product picker, but it is hidden from input
			if (
				field.predefinedValue.type === 'spreadsheet' &&
				field.predefinedValue.from &&
				field.predefinedValue.fromColumn &&
				field.hideInput
			) {
				invalidMsg.push(`Predefined (product picker) field is hidden from input`);
			}
			// has selection in calculate value and (auto import or predefined data)
			if (
				(field.calcValue.price || field.calcValue.unit || field.calcValue.qty) &&
				(field.predefinedValue.type || field.autoImport)
			) {
				invalidMsg.push(
					`Confliction between calucalate value and ${
						field.autoImport ? 'auto import' : 'predefined data'
					}. Only one of them can be set.`
				);
			}
			// TODO: hide autoImport/predefined, calcValue from each other if one is selected
			// some of "price", "unit" & "qty" have values, but not all
			if (
				(field.calcValue.price || field.calcValue.unit || field.calcValue.qty) &&
				!(field.calcValue.price && field.calcValue.unit && field.calcValue.qty)
			) {
				invalidMsg.push(`Missing selection in calucalate value`);
			}

			// user defined fontsize min, step, max validation
			if (
				field.fontsizeUserDefined &&
				(!field.fontsizeUDStart || !field.fontsizeUDStep || !field.fontsizeUDEnd)
			) {
				invalidMsg.push(`Missing configuration in user defined font size`);
			}

			if (
				artworkTemplate.autoCreateArtwork &&
				!field.autoImport &&
				!field.defaultValue &&
				(!field.calcValue.price || !field.calcValue.unit || !field.calcValue.qty)
			) {
				invalidMsg.push(
					`Auto-Create artwork requires configuration on one of Auto-Import, Calculate value and Default value`
				);
			}
			break;
		case 'image':
			// auto import is selected, but basedOn isn't selected
			if (['cell:', 'cellimg:'].includes(field.autoImport) && !field.autoImportMeta.basedOn) {
				invalidMsg.push(`Missing "basedOn" in auto-import data`);
			}
			// auto import & its basedOn are selected, but column isn't selected
			if (
				['cell:', 'cellimg:'].includes(field.autoImport) &&
				field.autoImportMeta.basedOn &&
				!field.autoImportMeta.spreadsheetImageColumn &&
				!field.autoImportMeta.spreadsheetCell
			) {
				invalidMsg.push(`Missing "column" in auto-import data`);
			}

			// media file origin selected, but no choice selected
			if (
				['category', 'admin_lightbox'].includes(field.imageOrigin) &&
				field.imageOriginChoice.length === 0
			) {
				invalidMsg.push(`Missing "Image Choice" in image origin data`);
			}

			if (artworkTemplate.autoCreateArtwork && !field.autoImport && !field.defaultMediafileId) {
				invalidMsg.push(
					`Auto-Create artwork requires configuration on one of Auto-Import and Default image`
				);
			}

			break;
		case 'barcode':
			// auto import is selected, but basedOn isn't selected
			if (
				(field.autoImport.startsWith('image:') || field.autoImport === 'cell:') &&
				!field.autoImportMeta.basedOn
			) {
				invalidMsg.push(`Missing "basedOn" in auto-import data`);
			}

			// auto import is "spreadsheet", the basedOn is selected, but cell isn't selected
			if (
				field.autoImport === 'cell:' &&
				field.autoImportMeta.basedOn &&
				!field.autoImportMeta.spreadsheetCell
			) {
				invalidMsg.push(`Missing "cell" selection in auto-import data`);
			}

			if (artworkTemplate.autoCreateArtwork && !field.autoImport && !field.defaultValue) {
				invalidMsg.push(
					`Auto-Create artwork requires configuration on one of Auto-Import and Default value`
				);
			}
			break;
		case 'pdf':
			// media file origin selected, but no choice selected
			if (
				['category', 'admin_lightbox'].includes(field.pdfOrigin) &&
				field.pdfOriginChoice.length === 0
			) {
				invalidMsg.push(`Missing "PDF Choice" in PDF origin data`);
			}

			if (artworkTemplate.autoCreateArtwork && !field.defaultMediafileId) {
				invalidMsg.push(`Auto-Create artwork requires configuration on Default PDF`);
			}
			break;
		case 'video':
			// media file origin selected, but no choice selected
			if (
				['category', 'admin_lightbox'].includes(field.videoOrigin) &&
				field.videoOriginChoice.length === 0
			) {
				invalidMsg.push(`Missing "Video Choice" in video origin data`);
			}

			if (artworkTemplate.autoCreateArtwork && !field.defaultMediafileId) {
				invalidMsg.push(`Auto-Create artwork requires configuration on Default video`);
			}
			break;
		case 'grid':
			// user defined fontsize min, step, max validation
			if (
				field.fontsizeUserDefined &&
				(!field.fontsizeUDStart || !field.fontsizeUDStep || !field.fontsizeUDEnd)
			) {
				invalidMsg.push(`Missing configuration in user defined font size`);
			}

			if (artworkTemplate.autoCreateArtwork && !field.defaultEditorHtml) {
				invalidMsg.push(`Auto-Create artwork requires configuration on Default value`);
			}
			break;
		default:
			break;
	}
	return invalidMsg;
};

/**
 * Validate artwork template settings
 * @param {object} artTemplate Artwork field
 * @returns {array} array of invalid message string
 */
export const validateTemplateSettings = (artTemplate) => {
	let invalidMsg = [];
	let templateBG = artTemplate.templateBG;
	if (templateBG.mediafileOrigin)
		if (
			['category', 'admin_lightbox'].includes(templateBG.mediafileOrigin) &&
			templateBG.mediafileOriginChoice.length === 0
		) {
			// template background media file origin selected, but no choice selected
			invalidMsg.push(`Missing "Background Choice" in template setting`);
		}

	if (artTemplate.videoArtwork && templateBG.isCustomisable) {
		invalidMsg.push(`Template background is not customizable in video artwork`);
	}

	if (artTemplate.videoArtwork && !templateBG.mediafileId) {
		invalidMsg.push(`Video artwork must have a background`);
	}

	return invalidMsg;
};

/**
 * Find effect fields by cause fields, e.g. fields to be deleted
 * @param {array} causeFieldIds Array of field Id that cause the effect
 * @param {array} templateFields All template fields
 * @returns {array}
 */
export const findEffectFields = (causeFieldIds, templateFields) => {
	/**
	 * Following are cases of field association
	 * - fields are used as basedOn in auto import
	 * - fields are used as outputDependsOn
	 * - fields are used for calculating value (text field only)
	 * - fields are used as barcode append (barcode only)
	 * - fields are used as embedded fields in concatenation field
	 */
	let fieldsAffectedByAutoImportBasedOn = [],
		fieldsAffectedByOutputDependsOn = [],
		fieldsAffectedByCalculatingVal = [],
		fieldsAffectedByPreSearchFieldId = [],
		fieldsAffectedByBarcodeAppend = [],
		fieldsAffectedByConcatField = [];
	for (let i = 0; i < templateFields.length; i++) {
		let f = templateFields[i];
		if (causeFieldIds.includes(f.id)) continue; // skip the (to be deleted) fields themself
		// affected by auto import basedon
		if (
			f.autoImportMeta &&
			f.autoImportMeta.basedOn &&
			causeFieldIds.includes(f.autoImportMeta.basedOn)
		) {
			fieldsAffectedByAutoImportBasedOn.push(f);
		}
		// affected by outputDependsOn
		if (f.outputDependsOn && causeFieldIds.includes(f.outputDependsOn)) {
			fieldsAffectedByOutputDependsOn.push(f);
		}
		// affected by calculating value (text field only)
		if (
			f.type === 'text' &&
			(causeFieldIds.includes(f.calcValue.price || '') ||
				causeFieldIds.includes(f.calcValue.unit || '') ||
				causeFieldIds.includes(f.calcValue.qty || ''))
		) {
			fieldsAffectedByCalculatingVal.push(f);
		}
		// affected by preSearchFieldId
		if (f.type === 'image' && f.preSearchFieldId && causeFieldIds.includes(f.preSearchFieldId)) {
			fieldsAffectedByPreSearchFieldId.push(f);
		}
		// affected by barcode append (barcode only)
		if (f.type === 'barcode' && f.append && causeFieldIds.includes(f.append)) {
			fieldsAffectedByBarcodeAppend.push(f);
		}
		//affected by embedded fields in concatenation field
		let regexToFindConcatField = /<field:\s*(.*?)\s*>/gm;
		if (f.type === 'text' && regexToFindConcatField.test(f.defaultValue)) {
			let matches = f.defaultValue.match(regexToFindConcatField);
			let embeddedFieldIds = matches.map((match) => {
				let embeddedFieldId = match.substring(match.lastIndexOf('_') + 1).slice(0, -1);
				return embeddedFieldId;
			});
			if (embeddedFieldIds.some((embeddedFieldId) => causeFieldIds.includes(embeddedFieldId))) {
				fieldsAffectedByConcatField.push(f);
			}
		}
	}

	return [
		{ type: 'AUTO_IMPORT', fields: fieldsAffectedByAutoImportBasedOn },
		{ type: 'OUTPUT_DEPEND_ON', fields: fieldsAffectedByOutputDependsOn },
		{ type: 'CALC_VALUE', fields: fieldsAffectedByCalculatingVal },
		{ type: 'PRE_SEARCH_FIELD', fields: fieldsAffectedByPreSearchFieldId },
		{ type: 'BARCODE_APPEND', fields: fieldsAffectedByBarcodeAppend },
		{ type: 'CONCAT_FIELD', fields: fieldsAffectedByConcatField },
	];
};

/**
 * Set field input data by selected (product picker) spreadsheet
 * Note: this function just renews the input data of fields, doesn't process any data.
 * so calculated field data, barcode text etc. are not handled here, but handled in getFieldOutputData func
 *
 * @param {object} productPickerField product picker field
 * @param {array} templateFields array of template field
 * @param {object} fieldInputData current field inputdata {[field.id]: {}, ...}
 * @param {object} ssRowData row data in selected spreadsheet. format (NB: columnId is "columnIndex" or  "$ref:{columnIndex}"):
		{
			[columnId]: {
				"value": "Cadbury Creme Egg", // for text & barcode field
				"mediafileId": "23554", // for image field
				"previewUrl": "https://xxxxx", // for image field
				"optimisedUrl": "https://xxxx", // for image field
				"highResUrl": "https://xxxx" // for image field
			},
			...
		}
 *
 * @return {object} newFieldInputData
 */
export const renewFieldInputDataBySelectedSpreadsheet = (
	productPickerField,
	templateFields,
	fieldInputData,
	ssRowData
) => {
	/**
	 * - loop through all fields, set input data in the fields that have "autoImport" from this productPickerField
	 */
	let productPickerFieldId = productPickerField.id;
	let fieldInputDataByProductPicker = {};
	// Set input data for product picker itself
	fieldInputDataByProductPicker[productPickerFieldId] = {
		...(fieldInputData[productPickerFieldId] || {}),
		value: ssRowData[productPickerField.predefinedValue.fromColumn]?.value?.trim(),
	};
	// loop through all fields, set input data in the fields that have "autoImport" from this productPickerField
	// NB: don't set fallback value to "" so that default value in the field can be used
	templateFields.forEach((field) => {
		if (
			field.type === 'text' &&
			field.autoImport === 'cell:' &&
			field.autoImportMeta.basedOn === productPickerFieldId
		) {
			// this field's input value is based on the "product picker" spreadsheet content
			fieldInputDataByProductPicker[field.id] = {
				...(fieldInputData[field.id] || {}),
				value: ssRowData[field.autoImportMeta.spreadsheetCell]?.value?.trim(),
			};
		}
		// barcode field
		if (
			field.type === 'barcode' &&
			field.autoImport === 'cell:' &&
			field.autoImportMeta.basedOn === productPickerFieldId
		) {
			fieldInputDataByProductPicker[field.id] = {
				...(fieldInputData[field.id] || {}),
				value: ssRowData[field.autoImportMeta.spreadsheetCell]?.value?.trim(),
			};
		}

		// image field
		if (
			field.type === 'image' &&
			field.autoImport === 'cellimg:' &&
			field.autoImportMeta.basedOn === productPickerFieldId
		) {
			// Delete croppedImgUrl by revoking blob url
			fieldInputData[field.id] && deleteCroppedImgUrl(fieldInputData[field.id].croppedImgUrl);
			// use spreadsheetImageColumn in "image" field
			fieldInputDataByProductPicker[field.id] = {
				...(fieldInputData[field.id] || {}),
				mediafileId: ssRowData[
					'$ref:' + field.autoImportMeta.spreadsheetImageColumn
				]?.mediafileId?.trim(),
				previewUrl: ssRowData[
					'$ref:' + field.autoImportMeta.spreadsheetImageColumn
				]?.previewUrl?.trim(),
				optimisedUrl: ssRowData[
					'$ref:' + field.autoImportMeta.spreadsheetImageColumn
				]?.optimisedUrl?.trim(),
				highResUrl: ssRowData[
					'$ref:' + field.autoImportMeta.spreadsheetImageColumn
				]?.highResUrl?.trim(),
				croppedImgUrl: '', // reset cropped image whenever the image source changes
				clippedImgUrl: '', // reset clippedImgUrl whenever the image source changes
			};
		}
	});

	// loop through again to build input data in fields that are depend/related on other fields
	let newFieldInputData = { ...fieldInputData, ...fieldInputDataByProductPicker };
	return newFieldInputData;
};

/**
 * Delete croppedImg by revoking blob url
 * NB: we don't delete the file from s3 bucket
 * 		 because the only case to save the file to s3 is when saving an artwork pdf to database (as mediafile)
 * 		 so there is at least one mediafile using it, hence we don't delete it from s3
 *
 * @param {string} croppedImgUrl
 */
export const deleteCroppedImgUrl = (croppedImgUrl) => {
	if (isBlobUrl(croppedImgUrl)) URL.revokeObjectURL(croppedImgUrl);
};

/**
 *
 * @param {object} rgb Example { r: 0, g: 0, b: 0, a: 1 }
 *
 * @returns null or object of {hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 }, pantone: 'xxx', cmyk: {c: 1, m: 1, y: 1, k: 1}}
 */
export const convertRGBColor = (rgb) => {
	if (rgb) {
		rgb = [rgb.r, rgb.g, rgb.b];
		let hex = '#' + colorConvert.rgb.hex(rgb);
		let cmyk = colorConvert.rgb.cmyk(rgb);
		return {
			hex: hex,
			rgb: { r: rgb[0], g: rgb[1], b: rgb[2], a: 1 },
			pantone: '',
			cmyk: { c: cmyk[0], m: cmyk[1], y: cmyk[2], k: cmyk[3] },
		};
	} else {
		return null;
	}
};

// Following are helper function to find dpi, viewport, screen. Reference: DPI Check, https://codepen.io/_LMD/pen/oGzpbP
// calculate the orientation (iOS way vs chrome)
// eslint-disable-next-line no-unused-vars
export const calcOrientation = function () {
	let orientation = 0;
	if (typeof window.screen.orientation !== 'undefined') {
		orientation = window.screen.orientation.angle;
	} else {
		orientation = window.orientation;
	}
	return orientation;
};

/**
 *
 * @param {string} hex Example #000000
 *
 * @returns null or object of {hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 }, pantone: 'xxx', cmyk: {c: 1, m: 1, y: 1, k: 1}}
 */
export const convertHexColor = (hex) => {
	if (hex) {
		let rgb = colorConvert.hex.rgb(hex);
		let cmyk = colorConvert.rgb.cmyk(rgb);
		return {
			hex: hex,
			rgb: { r: rgb[0], g: rgb[1], b: rgb[2], a: 1 },
			pantone: '',
			cmyk: { c: cmyk[0], m: cmyk[1], y: cmyk[2], k: cmyk[3] },
		};
	} else {
		return null;
	}
};

/**
 *
 * @param {object} cmyk Example {c: 100, m: 100, y: 50, k: 10}
 *
 * @returns null or object of {hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 }, pantone: 'xxx', cmyk: {c: 1, m: 1, y: 1, k: 1}}
 */
export const convertCMYKcolor = async (cmyk) => {
	if (
		typeof cmyk === 'object' &&
		typeof cmyk.c === 'number' &&
		typeof cmyk.m === 'number' &&
		typeof cmyk.y === 'number' &&
		typeof cmyk.k === 'number'
	) {
		// let rgb = colorConvert.cmyk.rgb(cmyk.c, cmyk.m, cmyk.y, cmyk.k);
		try {
			let res = await getRgbFromCmyk({
				queryParams: {
					c: cmyk.c,
					m: cmyk.m,
					y: cmyk.y,
					k: cmyk.k,
				},
			});
			const rgb = Object.values(res.data.rgb);

			let selectedHex = '#' + colorConvert.rgb.hex(rgb);
			return {
				hex: selectedHex,
				rgb: { r: rgb[0], g: rgb[1], b: rgb[2], a: 1 },
				pantone: '',
				cmyk: cmyk,
			};
		} catch (err) {
			return null;
		}
	} else {
		return null;
	}
};

/**
 *
 * @param {string} pantone pantone code
 *
 * @returns null or object of {hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 }, pantone: 'xxx', cmyk: {c: 1, m: 1, y: 1, k: 1}}
 */
export const convertPantoneColor = async (pantone) => {
	if (!pantoneCMYK[pantone]) return null; // we don't have this pantone color
	let cmyk = pantoneCMYK[pantone];
	try {
		// let rgb = colorConvert.cmyk.rgb(cmyk.c, cmyk.m, cmyk.y, cmyk.k);
		let res = await getRgbFromCmyk({
			queryParams: {
				c: cmyk.c,
				m: cmyk.m,
				y: cmyk.y,
				k: cmyk.k,
			},
		});
		const rgb = Object.values(res.data.rgb);

		let selectedHex = '#' + colorConvert.rgb.hex(rgb);
		return {
			hex: selectedHex,
			rgb: { r: rgb[0], g: rgb[1], b: rgb[2], a: 1 },
			pantone: pantone,
			cmyk: cmyk,
		};
	} catch (err) {
		// console.debug(`Failed to convert cmyk to rgb: ${err?.response?.data?.message ?? err.message}`);
		return null;
	}
};

/**
 * calculate start time of the animation for a field in video artwork
 * NB: We use fixed 24 frames per second in the calculation
 * @param {string} startTime HH:MM:SS:FF or ""
 * @returns {number} start time in milli second
 */
export const calcAnimationStartTimeInMSForVideoArtwork = (startTime) => {
	if (!startTime) return 0;
	let [hh, mm, ss, ff] = startTime.split(':').map((slice) => parseInt(slice));
	return (hh * 60 * 60 + mm * 60 + ss) * 1000 + Math.round((ff / 24) * 1000);
};

/**
 * create animation css for field in video artwork
 * @param {object} field
 * @returns {string}
 */
export const createAnimationOfFieldInVideoArtwork = (field) => {
	let { duration = 0, fadeOut = 0 } = field.insertionOnVideo;
	// reset minus number to 0
	duration = Math.max(duration, 0);
	fadeOut = Math.max(fadeOut, 0);

	return `fadeInOutVideoArtwork-${field.id} ${Math.max(
		duration + (duration === 0 ? 0 : fadeOut),
		1
	)}ms cubic-bezier(0.250, 0.460, 0.450, 0.940) ${calcAnimationStartTimeInMSForVideoArtwork(
		field.insertionOnVideo.startTime
	)}ms both`;
};

/**
 * Get accepted file types for uploading by field (type)
 * @param {object} field
 *
 * @return {array | undefined} an array of file extensions or undefined
 */
export const getUploadFileTypesByFieldType = (field) => {
	return config.supportedUploadFileTypesByArtworkFieldType[field.type];
};

/**
 * Get supported video types on video editing
 * @returns {string}
 */

export const getSupportedVideoTypesOnVideoEditing = () => config.supportedVideoTypesOnVideoEditing;

/**
 * create keyframes styles of fade-in-out for video artwork
 * NB: Animation at 0% must set opacity to 0, otherwise it could remain appearing until animation-delay time in some cases
 * @param {object} insertionOnVideo
 * @returns {object} animation styles in object
 */
export const createAnimationFadeInOutKeyframesInVideoArtwork = (insertionOnVideo) => {
	let { duration = 0, fadeIn = 0, fadeOut = 0 } = insertionOnVideo;
	// reset minus number to 0
	duration = Math.max(duration, 0);
	fadeIn = Math.max(fadeIn, 0);
	fadeOut = Math.max(fadeOut, 0);
	let totalAnimationDuration = duration + (duration === 0 ? 0 : fadeOut);
	let animationSettings = {};

	if (duration === 0) {
		// the field will stay on video forevery, never exit
		if (fadeIn === 0) {
			// no fade in, appears immediately
			animationSettings['0%'] = { opacity: '0' };
			animationSettings['1%'] = { opacity: '1' };
		} else {
			animationSettings['0%'] = { opacity: '0' };
		}
		animationSettings['100%'] = { opacity: '1' };
	} else {
		// fade-in stage
		let fadeInPercent = 0;
		if (fadeIn === 0) {
			// no fade in, appears immediately
			animationSettings['0%'] = { opacity: '0' };
			animationSettings['1%'] = { opacity: '1' };
		} else {
			animationSettings['0%'] = { opacity: '0' };
			// using Math.max to avoid it is rounded to zero
			fadeInPercent = Math.max(
				Math.round((Math.min(duration, fadeIn) / totalAnimationDuration) * 100),
				1
			);
			fadeInPercent = fadeInPercent - (fadeInPercent === 100 ? 1 : 0);
			animationSettings[`${fadeInPercent}%`] = { opacity: '1' };
		}

		// fade-out stage
		if (fadeOut === 0) {
			// no fade out but has duration, it exits immediately
			animationSettings['99.9%'] = { opacity: '1' };
			animationSettings['100%'] = { opacity: '0' };
		} else {
			let fadeOutPercent = Math.round((duration / totalAnimationDuration) * 100);
			animationSettings[`${fadeOutPercent - (fadeOutPercent === 100 ? 1 : 0)}%`] = {
				opacity: '1',
			};
			animationSettings['100%'] = { opacity: '0' };
		}
	}

	return animationSettings;
};

/**
 * Check if the given field is a "product picker" field
 * Make it being a function so that we only change here if the logic to judge "product picker" field changes
 * @param {object} field Artwork field
 *
 * @return {boolean}. If true, the given field is a "product picker" field, otherwise not.
 */
export const isProductPickerField = (field) => {
	if (
		field.type === 'text' &&
		field.predefinedValue.type === 'spreadsheet' &&
		Boolean(field.predefinedValue.from)
	) {
		return true;
	}

	return false;
};

/**
 * Check is there animation in artwork template
 * @param {array} templateFields
 * @param {boolean} isVideoArtwork
 * @returns {boolean}. If true, at least one field has animation, otherwise, no animation
 */
export const hasAnimation = (templateFields, isVideoArtwork) => {
	if (isVideoArtwork) return true;
	else return Boolean(templateFields.find((f) => f.animation.entrance));
};

/**
 * Clone a field with new field id and update its associated field
 * @param {object} field template field to be cloned
 * @param {object} fieldIdMap All fields in the template that WILL have a new field id. Format: {[oldFieldId]: newFieldId, ...}
 *
 * @returns {object} cloned field
 */
export const cloneField = (field, fieldIdMap) => {
	let clonedField = deepClone(field);

	// replace old ID with the new ID. If field doesn't have new field id, we use existing (old) id.
	clonedField.id = fieldIdMap[clonedField.id] || clonedField.id; // fallback value may never happen. Have it to avoid potential code exception

	// update auto import basedOn with the new field ID
	// ONLY when the basedOn field has a new id
	// apply to text, image, barcode field types. These fields have same autoImportMeta data structure, hence following code works for these three field types
	// (don't need to consider if they are in the same group or not, because the field ID is unique cross all groups)
	if (
		clonedField.autoImportMeta &&
		clonedField.autoImportMeta.basedOn &&
		fieldIdMap[clonedField.autoImportMeta.basedOn]
	) {
		clonedField.autoImportMeta.basedOn = fieldIdMap[clonedField.autoImportMeta.basedOn];
	}

	// update outputDependsOn with the new field ID,
	// ONLY when the dependOn field has a new id
	if (clonedField.outputDependsOn && fieldIdMap[clonedField.outputDependsOn]) {
		clonedField.outputDependsOn = fieldIdMap[clonedField.outputDependsOn];
	}

	// update field IDs used for calculating value (text field only)
	// ONLY when the associated field has a new id
	if (clonedField.type === 'text') {
		if (clonedField.calcValue.price && fieldIdMap[clonedField.calcValue.price]) {
			clonedField.calcValue.price = fieldIdMap[clonedField.calcValue.price];
		}
		if (clonedField.calcValue.unit && fieldIdMap[clonedField.calcValue.unit]) {
			clonedField.calcValue.unit = fieldIdMap[clonedField.calcValue.unit];
		}
		if (clonedField.calcValue.qty && fieldIdMap[clonedField.calcValue.qty]) {
			clonedField.calcValue.qty = fieldIdMap[clonedField.calcValue.qty];
		}
	}

	// update append (field ID) in barcode field
	// ONLY when the append field has a new id
	if (clonedField.type === 'barcode' && clonedField.append && fieldIdMap[clonedField.append]) {
		clonedField.append = fieldIdMap[clonedField.append];
	}

	// update the embedded fields in concatenation field
	// ONLY when the embedded field has a new id
	let regexToFindConcatField = /<field:\s*(.*?)\s*>/gm;
	if (clonedField.type === 'text' && regexToFindConcatField.test(clonedField.defaultValue)) {
		let matches = clonedField.defaultValue.match(regexToFindConcatField);
		matches.forEach((match) => {
			let embeddedFieldId = match.substring(match.lastIndexOf('_') + 1).slice(0, -1);
			if (fieldIdMap[embeddedFieldId]) {
				let embeddedFieldNewStr = match.replace(embeddedFieldId, fieldIdMap[embeddedFieldId]);
				clonedField.defaultValue = clonedField.defaultValue.replaceAll(match, embeddedFieldNewStr);
			} else {
				// In this "else" case, the embedded field has no change, so we don't need to update the default value in new field
			}
		});
	}
	return clonedField;
};

/**
 * Check if the template is printable
 * The rule to judge it is printable:
 * 	- no video field
 *
 * @param {array} templateFields
 * @returns {boolean}. If true, template is printable, otherwise, not printable
 */
export const isPrintableTemplate = (templateFields) => {
	return Boolean(!templateFields.find((f) => f.type === 'video'));
};

// ##############################
// artwork zoom
// #############################
export const calcRectToFitScreen = (templateRect, WSBorderRect) => {
	let WSPreviewRectCalc = {
			x: templateRect.x,
			y: templateRect.y,
			width: templateRect.width,
			height: templateRect.height,
			// zoom: 100,
		},
		containerWHRatio = WSBorderRect.width / WSBorderRect.height,
		templateWHRatio = templateRect.width / templateRect.height;

	if (containerWHRatio > templateWHRatio) {
		WSPreviewRectCalc.height = WSBorderRect.height;
		WSPreviewRectCalc.width = WSBorderRect.height * templateWHRatio;
	} else {
		WSPreviewRectCalc.width = WSBorderRect.width;
		WSPreviewRectCalc.height = WSBorderRect.width / templateWHRatio;
	}

	let zoom = floorDecimals(WSPreviewRectCalc.width / templateRect.width, 2); // The real zoom, 2 decimal number
	// use zoom to calculate width/height to make them more accurate in case the zoom was rounded
	WSPreviewRectCalc.width = templateRect.width * zoom;
	WSPreviewRectCalc.height = templateRect.height * zoom;

	WSPreviewRectCalc.x = WSBorderRect.x + (WSBorderRect.width - WSPreviewRectCalc.width) / 2;
	WSPreviewRectCalc.y = WSBorderRect.y + (WSBorderRect.height - WSPreviewRectCalc.height) / 2;

	let WSBorderRectCalc = {
		x: WSPreviewRectCalc.x - WSBorderRect.x,
		y: WSPreviewRectCalc.y - WSBorderRect.y,
		width: WSPreviewRectCalc.width + 2 * WSBorderRect.x,
		height: WSPreviewRectCalc.height + 2 * WSBorderRect.y,
		// zoom: WSPreviewRectCalc.zoom,
	};
	return { previewRect: WSPreviewRectCalc, borderRect: WSBorderRectCalc, zoom };
};

// fit the position of rect in the given container rect
export const fitPosition = (WSPreviewRectCalc, containerRect) => {
	WSPreviewRectCalc.x = Math.max(
		containerRect.x,
		(containerRect.width - WSPreviewRectCalc.width) / 2
	);
	WSPreviewRectCalc.y = Math.max(
		containerRect.y,
		(containerRect.height - WSPreviewRectCalc.height) / 2
	);
};

// ##############################
// selection box
// #############################
// used to calculate the rect corner coordination with rotating angle
function getCorner(pivotX, pivotY, cornerX, cornerY, angle) {
	var x, y, distance, diffX, diffY;

	/// get distance from center to point
	diffX = cornerX - pivotX;
	diffY = cornerY - pivotY;
	distance = Math.sqrt(diffX * diffX + diffY * diffY);

	/// find angle from pivot to corner
	angle += Math.atan2(diffY, diffX);

	/// get new x and y and round it off to integer
	x = pivotX + distance * Math.cos(angle);
	y = pivotY + distance * Math.sin(angle);

	return { x: x, y: y };
}
function getElementBounds(element) {
	let elePosition = element.position;
	var px = elePosition.width * 0.5 + elePosition.left, /// pivot
		py = elePosition.height * 0.5 + elePosition.top,
		ar = (elePosition.angle * Math.PI) / 180, /// angle in radians
		TL,
		TR,
		BR,
		BL, /// corners (topLeft, topRight, bottomLeft, BottomRight)
		BTLX, // bounding top-left X coordination
		BTLY,
		BBRX, // bounding bottom-right X coordination
		BBRY,
		bounds; /// bounding box. BTL - BoundTopLeft, BBR - BoundBottomRight

	/// get corner coordinates
	TL = getCorner(px, py, elePosition.left, elePosition.top, ar);
	TR = getCorner(px, py, elePosition.left + elePosition.width, elePosition.top, ar);
	BL = getCorner(px, py, elePosition.left, elePosition.top + elePosition.height, ar);
	BR = getCorner(
		px,
		py,
		elePosition.left + elePosition.width,
		elePosition.top + elePosition.height,
		ar
	);

	/// get bounding box
	BTLX = Math.min(TL.x, TR.x, BR.x, BL.x);
	BTLY = Math.min(TL.y, TR.y, BR.y, BL.y);
	BBRX = Math.max(TL.x, TR.x, BR.x, BL.x);
	BBRY = Math.max(TL.y, TR.y, BR.y, BL.y);

	bounds = [BTLX, BTLY, BBRX - BTLX, BBRY - BTLY]; // [left, top, width, height]
	return { left: bounds[0], top: bounds[1], width: bounds[2], height: bounds[3] };
}
/**
 *
 * @param {array} elements (artwork fields). The elements (fields) that you want to get the outer bounds
 */
export const getOuterBoundsSelection = (elements = []) => {
	if (elements.length < 1) {
		return null;
	} else if (elements.length === 1) {
		return { position: { ...elements[0].position } };
	} else {
		let minLefts = [],
			maxLefts = [],
			minTops = [],
			maxTops = [];
		elements.forEach((ele) => {
			let bounds = getElementBounds(ele);
			minLefts.push(bounds.left);
			maxLefts.push(bounds.left + bounds.width);
			minTops.push(bounds.top);
			maxTops.push(bounds.top + bounds.height);
		});
		let minLeft = Math.min(...minLefts);
		let minTop = Math.min(...minTops);
		let maxLeft = Math.max(...maxLefts);
		let maxTop = Math.max(...maxTops);

		return {
			// id: elements.map(ele => ele.id).join('-'),
			position: {
				left: minLeft,
				top: minTop,
				width: maxLeft - minLeft,
				height: maxTop - minTop,
				angle: 0,
			},
		};
	}
};
