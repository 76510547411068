/** ##############################
 * 	Artwork Browser-Side Generator
 *
 * ** Used only by browser-side **
 * Because it is browser-side only, you can import any helper functions
 *
 *  Generator functions that are used in browser-side
 *
 * 	#############################
 */

import { getDomainConfig } from '../appHelper';
import { format as dateFormat } from 'date-fns';
import {
	artworkPutArtContent,
	artworkSaveArtwork,
	batchSaveArtwork,
	awsCreateS3Client,
	fetchArtworkAutoImport,
	fetchArtworkCategories,
	fetchArtworkFonts,
	fetchArtworkLists,
	fetchArtworkOutputTemplates,
	fetchArtworkSpreadsheets,
} from 'restful';
import { ART_VARIABLES } from 'pages/Artwork/Constants';
import moment from 'moment';
import { isNullish, genRandomStr, roundDecimals } from '../generalHelper';
import {
	geFontListInTemplateFields,
	loadFontface,
	getColorListInTemplateFields,
	getFieldOutputData,
	hideFieldOutput,
} from './artUtilsCommon';
import {
	createArtworkSVGForServerSaving,
	isValidFieldForVideoArtwork,
	generateImageOfFieldInTemplate,
} from './artUtilsGenerator';
import { pixelToPdfPt, pdfPtToPixel } from './constants';
import _uniqBy from 'lodash/uniqBy';

// constants used in artwork
const {
	placeholderSameAsText,
	DEFAULT_ANIMATION_DURATION,
	DEFAULT_ANIMATION_DELAY,
	supportedFieldTypesInVideoArtwork,
	animations,
} = ART_VARIABLES;

/**
 * Server-side artwork pdf generation and save
 * @param {Object} param. Note: If not specified, it is for saving to existing artwork in "EDIT" mode; if specified, it is for saving a new artwork file & Save As cases
 * @returns {Promise<Object>}
   {
  		createdMediafieldId,
   }
 */
export const createArtworkAtServer = async ({
	fileName, // if has value, it is for saving or saving as to a new artwork;
	saveToExistingMediafileId, // Optional. Existing mediafile id. It is for saving to existing artwork
	s3Bucket,
	s3BasePath,
	userId,
	fieldInputData,
	artworkTemplate,
	templateFields,
	templateBGInputData,
	templateBGOutputDataWithSettings,
	artworkAvailableFonts,
	allowFilenameOverridedByFilenameField = false, // default false. If true and filenameField defined, the filenameField value will override the provided filename
	intl,
	setExportArtworkStatus, // optional
}) => {
	// validations
	if ((fileName && saveToExistingMediafileId) || (!fileName && !saveToExistingMediafileId)) {
		throw new Error(intl.formatMessage({ id: 'utils.artwork.unknownCreationModeMsg' }));
	}

	// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	let fontlistInTemplate = geFontListInTemplateFields(templateFields, artworkAvailableFonts, {
		placeholderSameAsText,
	});
	// load font faces so that the text field can be rederred correctly
	await loadFontface(fontlistInTemplate);

	let colorList = getColorListInTemplateFields(templateFields);
	setExportArtworkStatus &&
		setExportArtworkStatus({
			status: 'OK',
			message: intl.formatMessage({ id: 'utils.artwork.savingProgressMsgPreparing' }),
		});

	try {
		const s3Client = await awsCreateS3Client();
		const fieldOutputData = getFieldOutputData(templateFields, fieldInputData);

		// create svg dom
		let {
			svgToPdf,
			printSVG,
			rawSVG,
			pdfBGUrl,
			printFontList,
			printColorList,
		} = await createArtworkSVGForServerSaving(templateFields, fieldOutputData, {
			templateSize: artworkTemplate.dimension, // in pixel
			templateMediaUrl: templateBGOutputDataWithSettings.mediafileOptimisedUrl, //artworkDesignerTemplate.templateMediaUrl,
			templatePdfUrl: templateBGOutputDataWithSettings.mediafilePdfUrl,
			templateSvgUrl: templateBGOutputDataWithSettings.mediafileSvgUrl, //artworkDesignerTemplate.templateSvgUrl,
			s3Params: {
				// used to upload images to s3 for server-side pdf generation
				bucket: s3Bucket,
				filePrefix: s3BasePath,
			},
			s3Client,
			animations,
			CONSTANTS: {
				placeholderSameAsText,
				DEFAULT_ANIMATION_DURATION,
				DEFAULT_ANIMATION_DELAY,
			},
			fontList: fontlistInTemplate,
			colorList: colorList,
		});

		// call api to generate & save artwork on server side
		setExportArtworkStatus &&
			setExportArtworkStatus({
				status: 'OK',
				message: intl.formatMessage({ id: 'utils.artwork.savingArtworkMsg' }),
			});

		let templateId = artworkTemplate.mediaId.toString();
		let bodyParams = {
			fileContents: formatArtworkContentForFileMgrApi({ fieldInputData, templateFields }),
			// insert template BG content
			...(isNullish(templateBGInputData.mediafileId)
				? {}
				: {
						templateBGcontents: {
							mediafileId: templateBGInputData.mediafileId,
							...(isNullish(templateBGInputData.mediafilePreviewUrl)
								? {}
								: { mediafilePreviewUrl: templateBGInputData.mediafilePreviewUrl }),
							...(isNullish(templateBGInputData.mediafileHighResUrl)
								? {}
								: { mediafileHighResUrl: templateBGInputData.mediafileHighResUrl }),
							...(isNullish(templateBGInputData.mediafileOptimisedUrl)
								? {}
								: { mediafileOptimisedUrl: templateBGInputData.mediafileOptimisedUrl }),
							...(isNullish(templateBGInputData.mediafilePdfUrl)
								? {}
								: { mediafilePdfUrl: templateBGInputData.mediafilePdfUrl }),
							...(isNullish(templateBGInputData.mediafileSvgUrl)
								? {}
								: { mediafileSvgUrl: templateBGInputData.mediafileSvgUrl }),
						},
				  }),
			serverGenArtwork: {
				rawSVG: new XMLSerializer().serializeToString(rawSVG),
				printSVG: new XMLSerializer().serializeToString(printSVG),
				s3BasePath: `s3://${s3Bucket}/${s3BasePath}`,
				svgToPdf: {
					pageSize: {
						width: artworkTemplate.dimension.width * pixelToPdfPt,
						height: artworkTemplate.dimension.height * pixelToPdfPt,
					},
					fontList: printFontList,
					colorList: printColorList,
					svg: new XMLSerializer().serializeToString(svgToPdf),
					...(pdfBGUrl ? { pagePdfBGUrl: pdfBGUrl } : {}),
				},
			},
		};

		let createdMediafieldId = '';
		if (fileName) {
			// Save new artwork & Save As cases
			fileName = finalizeArtworkFilename({
				allowFilenameOverridedByFilenameField,
				artworkTemplate,
				fieldOutputData,
				fileName,
			});

			bodyParams.name = fileName;

			bodyParams.createdByUid = userId;
			let saveNewArtworkResponse = await artworkSaveArtwork({ templateId, bodyParams });
			createdMediafieldId = saveNewArtworkResponse.data.mediafileId;
		} else {
			// Save to existing artwork
			// Note: API needs to update mediafile in screens api in case it is used in slides

			bodyParams.updatedByUid = userId;
			await artworkPutArtContent({ mediafileId: saveToExistingMediafileId, bodyParams });
			createdMediafieldId = saveToExistingMediafileId;
		}

		// return createdMediafieldId
		return { createdMediafieldId };
	} catch (err) {
		console.debug(err);
		throw new Error(err.response ? err.response.data.message : err.message);
	}
};

function finalizeArtworkFilename({
	fileName, // specified/provied fileName
	artworkTemplate,
	fieldOutputData,
	allowFilenameOverridedByFilenameField,
}) {
	if (allowFilenameOverridedByFilenameField && artworkTemplate.filenameFieldId) {
		const filenameFieldValue = fieldOutputData[artworkTemplate.filenameFieldId]?.value ?? '';
		if (filenameFieldValue) {
			fileName = (
				filenameFieldValue +
				(artworkTemplate.filenameFieldAppend ? ' - ' + artworkTemplate.name : '') +
				' - ' +
				dateFormat(new Date(), 'yyyy-MM-dd')
			).trim();
		}
		// else: we still use the fileName provided
	}

	return fileName;
}

/**
 * Batch artwork with server-side process
 * @param {Object} param
  // listOfTemplateFieldInputData is the array of selected artwork template:
	// 	item in the array is { artTemplate, userOwnerColumnId, filenameColumnId, templateBGOutputDataWithSettings, itemsFieldInputData },
	//	the itemsFieldInputData is an array of item data of each selected row for a selected template
	// 	the templateBGOutputDataWithSettings is an object of template background output settings, in case there is customized settings, e.g. change backgroup image
	//
	// 	Idea of this structure is we will process each template, in each template we will process each row that is the fieldInputData object
	//	Once we processed all rows in all templates, we send the processed data to api for server-side generation
	//
	//	sample format as below
	// [
	// 	{
	// 		// each object is for a selected template
	// 		artTemplate,
	// 		itemsFieldInputData: [
	// 			{ // item 1
	// 				fieldInputData: {
	// 					// entire object is for one selected row
	// 					[filedId]: OBJECT_OF_FIELD_INPUT_VALUE_FROM_ROW,
	// 					[filedId]: OBJECT_OF_FIELD_INPUT_VALUE_FROM_ROW,
	// 					[filedId]: OBJECT_OF_FIELD_INPUT_VALUE_FROM_ROW,
	// 				},
	// 				fileName,
	// 				userId,
	// 			},
	// 			{ // item 2
	// 				fieldInputData: {
	// 					// entire object is for one selected row
	// 					[filedId]: OBJECT_OF_FIELD_INPUT_VALUE_FROM_ROW,
	// 					[filedId]: OBJECT_OF_FIELD_INPUT_VALUE_FROM_ROW,
	// 					[filedId]: OBJECT_OF_FIELD_INPUT_VALUE_FROM_ROW,
	// 				},
	// 				fileName,
	// 				userId,
	// 			},
	// 		],
	// 	},
	// ];
 * @returns {Promise<Object>}
 * {
 * 		totalFailed,
 * 		invalid, // [{templateId, failedFiles: [...]}]
 * }
 */
export const batchingEsignForServerProcess = async ({
	listOfTemplateFieldInputData,
	s3Bucket,
	s3BasePath,
	artworkAvailableFonts,
	domainName,
	intl,
	setExportArtworkStatus = () => null, // optional
}) => {
	// NOTE: In batching, we don't have custom data, No custom template BG

	setExportArtworkStatus({
		status: 'OK',
		message: intl.formatMessage({ id: 'utils.artwork.savingProgressMsgPreparing' }),
	});

	const s3Client = await awsCreateS3Client();
	let totalFiles = 0,
		totalInvalid = 0;
	const batchSaveBody = [], // [{templateId, artworks: [...]}]
		invalid = []; // [{templateId, failedFiles: [...]}]
	for (const templateFieldInputData of listOfTemplateFieldInputData) {
		// 1. loop through each artwork template
		const { artTemplate, itemsFieldInputData } = templateFieldInputData;

		const templateFields = artTemplate.fields;
		const templateBGOutputDataWithSettings = artTemplate.templateBG;

		// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
		const fontlistInTemplate = geFontListInTemplateFields(
			artTemplate.fields,
			artworkAvailableFonts,
			{
				placeholderSameAsText,
			}
		);
		// load font faces so that the text field can be rederred correctly
		await loadFontface(fontlistInTemplate);

		const colorList = getColorListInTemplateFields(templateFields);

		// 2. loop through each row's fieldInputData
		const processedItems = [],
			failedFiles = [];

		for (const itemData of itemsFieldInputData) {
			totalFiles += 1;
			const { fieldInputData, fileName, userId } = itemData;
			const fieldOutputData = getFieldOutputData(templateFields, fieldInputData);
			let artworkInBodyParams = null;
			if (artTemplate.templateType === 'PDF') {
				artworkInBodyParams = await createPdfArtworkApiBody({
					templateFields,
					fieldOutputData,
					artTemplate,
					templateBGOutputDataWithSettings,
					s3Bucket,
					s3BasePath,
					s3Client,
					fontlistInTemplate,
					colorList,
					fileName,
					userId,
					fieldInputData,
				});
			} else if (artTemplate.templateType === 'VIDEO') {
				artworkInBodyParams = await createVideoArtworkApiBody({
					artTemplate,
					templateFields,
					fieldOutputData,
					fontlistInTemplate,
					s3Client,
					fieldInputData,
					fileName,
					userId,
					domainName,
				});
			}

			if (artworkInBodyParams) processedItems.push(artworkInBodyParams);
			else {
				// error, can't create api body
				failedFiles.push(fileName);
				totalInvalid += 1;
			}
		}

		// finished processing all items for this template
		if (processedItems.length > 0)
			batchSaveBody.push({
				templateId: artTemplate.mediaId.toString(),
				artworks: processedItems,
			});

		if (failedFiles.length > 0) {
			invalid.push({
				templateId: artTemplate.mediaId.toString(),
				failedFiles,
			});
		}
	}

	// error: no valid artwork
	if (batchSaveBody.length === 0) return { totalFailed: 0, totalFiles, totalInvalid, invalid };

	setExportArtworkStatus({
		status: 'OK',
		message: intl.formatMessage({ id: 'utils.artwork.savingArtworkMsg' }),
	});
	// call api to generate & save artwork on server side
	const batchSaveArtworkResponse = await batchSaveArtwork({ bodyParams: batchSaveBody });
	return {
		totalFailed: batchSaveArtworkResponse.data.totalFailed,
		totalFiles,
		totalInvalid,
		invalid,
	};
};

async function createVideoArtworkApiBody({
	artTemplate,
	templateFields,
	fieldOutputData,
	fontlistInTemplate,
	s3Client,
	fieldInputData,
	fileName,
	userId,
	domainName,
}) {
	// loop through all fields to generate transparent png of the field with user input data
	const fieldImages = await templateFields.reduce((promiseChain, field) => {
		return promiseChain.then(async (accu) => {
			if (
				!hideFieldOutput(field, templateFields, fieldOutputData) &&
				isValidFieldForVideoArtwork(field, supportedFieldTypesInVideoArtwork)
			) {
				let fieldImgBlob = await generateImageOfFieldInTemplate(field, fieldOutputData, {
					templateSize: artTemplate.dimension, // in pixel
					allFields: templateFields,
					imgFormat: 'png',
					fontList: fontlistInTemplate,
					CONSTANTS: {
						placeholderSameAsText,
						DEFAULT_ANIMATION_DURATION,
						DEFAULT_ANIMATION_DELAY,
					},
				});
				if (fieldImgBlob) accu = { ...accu, [field.id]: { fieldImgBlob } };
			}
			return accu;
		});
	}, Promise.resolve({}));

	if (Object.keys(fieldImages).length === 0) {
		return null;
	}

	// uploads image of rendered fields to s3
	const s3BucketVideoArtworkFieldImg = (getDomainConfig(domainName) || {})[
		's3BucketVideoArtworkFieldImg'
	];
	const s3PathPrefixVideoArtworkFieldImg = (getDomainConfig(domainName) || {})[
		's3PathPrefixVideoArtworkFieldImg'
	];

	const fieldImagesS3UploadParams = Object.keys(fieldImages).map((fieldId) => {
		const params = {
			Bucket: s3BucketVideoArtworkFieldImg,
			Key: `${s3PathPrefixVideoArtworkFieldImg}/${domainName}/${moment.utc().format('YYYYMMDD')}/${
				artTemplate.mediaId
			}/${fieldId}_${genRandomStr(12)}.png`,
			Body: fieldImages[fieldId].fieldImgBlob,
			ContentType: 'application/png',
			ContentLength: fieldImages[fieldId].fieldImgBlob.size,
		};
		fieldImages[fieldId].s3Url = `s3://${params.Bucket}/${params.Key}`;
		return params;
	});

	const MIN_UPLOAD_PART = 6 * 1024 * 1024;
	await Promise.all(
		fieldImagesS3UploadParams.map((param) =>
			s3Client
				.upload(param, {
					partSize: Math.max(param.ContentLength + 1 * 1024 * 1024, MIN_UPLOAD_PART),
					queueSize: 1,
				})
				.promise()
		)
	);

	// Save file in API
	const fileContents = formatArtworkContentForFileMgrApi({ fieldInputData, templateFields });

	const bodyParams = {
		fileContents,
		// Don't need to insert template BG content. VideoArtwork doesn't allow user input template background
		name: fileName,
		createdByUid: userId,
		videoArtwork: {
			videoMediafileId: artTemplate.templateBG.mediafileId,
			fieldImages: templateFields
				.map((field, idx) => {
					if (fieldImages[field.id]) {
						return {
							...field.insertionOnVideo,
							id: field.id,
							s3Url: fieldImages[field.id].s3Url,
							layer: idx,
							asPreview: artTemplate.videoPreviewFieldId === field.id,
						};
					} else {
						return null;
					}
				})
				.filter((item) => item),
		},
	};
	return bodyParams;
}

async function createPdfArtworkApiBody({
	templateFields,
	fieldOutputData,
	artTemplate,
	templateBGOutputDataWithSettings,
	s3Bucket,
	s3BasePath,
	s3Client,
	fontlistInTemplate,
	colorList,
	fileName,
	userId,
	fieldInputData,
}) {
	// create svg dom
	let {
		svgToPdf,
		printSVG,
		rawSVG,
		pdfBGUrl,
		printFontList,
		printColorList,
	} = await createArtworkSVGForServerSaving(templateFields, fieldOutputData, {
		templateSize: artTemplate.dimension, // in pixel
		templateMediaUrl: templateBGOutputDataWithSettings.mediafileOptimisedUrl, //artworkDesignerTemplate.templateMediaUrl,
		templatePdfUrl: templateBGOutputDataWithSettings.mediafilePdfUrl,
		templateSvgUrl: templateBGOutputDataWithSettings.mediafileSvgUrl, //artworkDesignerTemplate.templateSvgUrl,
		s3Params: {
			// used to upload images to s3 for server-side pdf generation
			bucket: s3Bucket,
			filePrefix: s3BasePath,
		},
		s3Client,
		animations,
		CONSTANTS: {
			placeholderSameAsText,
			DEFAULT_ANIMATION_DURATION,
			DEFAULT_ANIMATION_DELAY,
		},
		fontList: fontlistInTemplate,
		colorList: colorList,
	});

	const artworkInBodyParams = {
		name: fileName,
		createdByUid: userId,
		fileContents: formatArtworkContentForFileMgrApi({ fieldInputData, templateFields }),
		// No custom template BG in batching, so DONOT insert template BG content

		serverGenArtwork: {
			rawSVG: new XMLSerializer().serializeToString(rawSVG),
			printSVG: new XMLSerializer().serializeToString(printSVG),
			s3BasePath: `s3://${s3Bucket}/${s3BasePath}`,
			svgToPdf: {
				pageSize: {
					width: artTemplate.dimension.width * pixelToPdfPt,
					height: artTemplate.dimension.height * pixelToPdfPt,
				},
				fontList: printFontList,
				colorList: printColorList,
				svg: new XMLSerializer().serializeToString(svgToPdf),
				...(pdfBGUrl ? { pagePdfBGUrl: pdfBGUrl } : {}),
			},
		},
	};
	return artworkInBodyParams;
}
/**
 * create video artwork file
 * Note: caller must handle error
 *
 * @param {object} param0
 * @returns
 */
export const createVideoArtwork = async ({
	fileName,
	fieldInputData,
	artworkTemplate,
	templateFields, // we allow user resize/drag fields in some scenarios, so this is customized fields from artworkTemplate.fields
	artworkAvailableFonts,
	domainName,
	allowFilenameOverridedByFilenameField = false, // default false. If true and filenameField defined, the filenameField value will override the provided filename
	intl,
	userId,
	setExportArtworkStatus, // optional. Function to set processing status. Param: { status: 'OK' | 'FAILED', message: 'msg, }
}) => {
	// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	let fontlistInTemplate = geFontListInTemplateFields(templateFields, artworkAvailableFonts, {
		placeholderSameAsText,
	});
	// load font faces so that the text field can be rederred correctly
	await loadFontface(fontlistInTemplate);

	setExportArtworkStatus &&
		setExportArtworkStatus({
			status: 'OK',
			message: intl.formatMessage({ id: 'utils.artwork.savingProgressMsgPreparing' }),
		});

	// get final input data. It is the combination of user input & field default data, including all fields
	const fieldOutputData = getFieldOutputData(templateFields, fieldInputData);
	// loop through all fields to generate transparent png of the field with user input data
	const fieldImages = await templateFields.reduce((promiseChain, field) => {
		return promiseChain.then(async (accu) => {
			if (
				!hideFieldOutput(field, templateFields, fieldOutputData) &&
				isValidFieldForVideoArtwork(field, supportedFieldTypesInVideoArtwork)
			) {
				let fieldImgBlob = await generateImageOfFieldInTemplate(field, fieldOutputData, {
					templateSize: artworkTemplate.dimension, // in pixel
					allFields: templateFields,
					imgFormat: 'png',
					fontList: fontlistInTemplate,
					CONSTANTS: {
						placeholderSameAsText,
						DEFAULT_ANIMATION_DURATION,
						DEFAULT_ANIMATION_DELAY,
					},
				});
				if (fieldImgBlob) accu = { ...accu, [field.id]: { fieldImgBlob } };
			}
			return accu;
		});
	}, Promise.resolve({}));

	if (Object.keys(fieldImages).length === 0) {
		throw new Error(
			intl.formatMessage({
				id: 'pages.Artwork.components.Create.noValidFieldErrMsg',
			})
		);
	}

	setExportArtworkStatus &&
		setExportArtworkStatus({
			status: 'OK',
			message: intl.formatMessage({
				id: 'utils.artwork.savingProgressMsgUploadingFiles',
			}),
		});

	// uploads image of rendered fields to s3
	const s3BucketVideoArtworkFieldImg = (getDomainConfig(domainName) || {})[
		's3BucketVideoArtworkFieldImg'
	];
	const s3PathPrefixVideoArtworkFieldImg = (getDomainConfig(domainName) || {})[
		's3PathPrefixVideoArtworkFieldImg'
	];

	const fieldImagesS3UploadParams = Object.keys(fieldImages).map((fieldId) => {
		const params = {
			Bucket: s3BucketVideoArtworkFieldImg,
			Key: `${s3PathPrefixVideoArtworkFieldImg}/${domainName}/${moment.utc().format('YYYYMMDD')}/${
				artworkTemplate.mediaId
			}/${fieldId}_${genRandomStr(12)}.png`,
			Body: fieldImages[fieldId].fieldImgBlob,
			ContentType: 'application/png',
			ContentLength: fieldImages[fieldId].fieldImgBlob.size,
		};
		fieldImages[fieldId].s3Url = `s3://${params.Bucket}/${params.Key}`;
		return params;
	});

	// create s3 client
	const s3Client = await awsCreateS3Client();
	// NOTE: Keep partSize 1MB bigger than file size so that eTag from s3 is md5 of file
	// minimum part size on uploading, set it to 6mb to avoid AWS S3 SDK error
	const MIN_UPLOAD_PART = 6 * 1024 * 1024;
	await Promise.all(
		fieldImagesS3UploadParams.map((param) =>
			s3Client
				.upload(param, {
					partSize: Math.max(param.ContentLength + 1 * 1024 * 1024, MIN_UPLOAD_PART),
					queueSize: 1,
				})
				.promise()
		)
	);

	setExportArtworkStatus &&
		setExportArtworkStatus({
			status: 'OK',
			message: intl.formatMessage({ id: 'utils.artwork.savingProgressMsgSavingFinalArtwork' }),
		});

	// Save file in API
	const templateId = artworkTemplate.mediaId.toString();
	const fileContents = formatArtworkContentForFileMgrApi({ fieldInputData, templateFields });

	// finalize artwork filename
	fileName = finalizeArtworkFilename({
		allowFilenameOverridedByFilenameField,
		artworkTemplate,
		fieldOutputData,
		fileName,
	});
	const bodyParams = {
		fileContents,
		// Don't need to insert template BG content. VideoArtwork doesn't allow user input template background
		name: fileName,
		createdByUid: userId,
		videoArtwork: {
			videoMediafileId: artworkTemplate.templateBG.mediafileId,
			fieldImages: templateFields
				.map((field, idx) => {
					if (fieldImages[field.id]) {
						return {
							...field.insertionOnVideo,
							id: field.id,
							s3Url: fieldImages[field.id].s3Url,
							layer: idx,
							asPreview: artworkTemplate.videoPreviewFieldId === field.id,
						};
					} else {
						return null;
					}
				})
				.filter((item) => item),
		},
	};

	// Save new artwork
	const saveNewArtworkResponse = await artworkSaveArtwork({ templateId, bodyParams }).catch(
		(err) => {
			throw new Error(err.response ? err.response.data.message : err.message);
		}
	);

	// return saved mediafile Id
	return saveNewArtworkResponse.data.mediafileId;
};

export const fetchArtworkExtra = async () => {
	// 1. fetch artwork font list
	const artFonts = await fetchArtworkFonts({}).catch(() => {
		throw new Error('Can not load fonts. Please try again later...');
	});

	// 2. fetch spreadsheet data
	const artSpreadsheets = await fetchArtworkSpreadsheets({}).catch(() => {
		throw new Error('Can not load spreadsheet data. Please try again later...');
	});

	// 3. fetch list data
	const artLists = await fetchArtworkLists({}).catch(() => {
		throw new Error('Can not load list data. Please try again later...');
	});

	// 4. fetch user data
	const artAutoImport = await fetchArtworkAutoImport({}).catch(() => {
		throw new Error('Can not load auto-import data. Please try again later...');
	});

	// 5. fetch output template data
	const artOutputTemplates = await fetchArtworkOutputTemplates({}).catch(() => {
		throw new Error('Can not load output template. Please try again later...');
	});

	// 6. fetch artwork fields data. (Do we need categories data?)
	const artCategories = await fetchArtworkCategories({}).catch(() => {
		throw new Error('Can not load output template. Please try again later...');
	});

	// 7. build artwork extra data
	let originalAutoImportData = artAutoImport.data;
	let autoImportData = {};
	autoImportData['User Standard Data'] = originalAutoImportData.userStandardData.map((item) => ({
		value: `${item.id}:`,
		label: item.label,
	}));
	autoImportData['User Data'] = originalAutoImportData.userData.map((item) => ({
		value: `user:${item.id}`,
		label: item.label,
	}));
	autoImportData['Image Data'] = originalAutoImportData.imageData.map((item) => ({
		value: `image:${item.id}`,
		label: item.label,
	}));
	const artExtra = {
		fonts: artFonts.data.results,
		spreadsheets: artSpreadsheets.data.results,
		lists: artLists.data.results,
		autoImport: autoImportData,
		outputTemplates: artOutputTemplates.data.results,
		categories: artCategories.data.categories,
	};
	return artExtra;
};

/**
 * format fieldInputData to be compatible data structure for "save" artwork api
 */
const formatArtworkContentForFileMgrApi = ({ fieldInputData, templateFields }) => {
	let fieldIdTypeMapping = templateFields.reduce((accu, field) => {
		return { ...accu, [field.id]: field.type };
	}, {});
	return Object.keys(fieldInputData)
		.map((fieldId) => {
			let fieldContent = fieldInputData[fieldId];
			let fieldProperties = {
				...(fieldContent.horizontalAlign ? { horizontalAlign: fieldContent.horizontalAlign } : {}),
				...(fieldContent.verticalAlign ? { verticalAlign: fieldContent.verticalAlign } : {}),
				...(!isNullish(fieldContent.value) ? { value: fieldContent.value } : {}),
				...(fieldContent.fontsize ? { fontSize: fieldContent.fontsize } : {}), // it is for both text & grid fields
				...(!isNullish(fieldContent.mediafileId) ? { mediafileId: fieldContent.mediafileId } : {}),
				...(!isNullish(fieldContent.previewUrl) ? { previewUrl: fieldContent.previewUrl } : {}), // previewUrl is image url if "image"/"video" field; it is svg url if "pdf" field
				...(!isNullish(fieldContent.optimisedUrl) // optimisedUrl is image url if "image"field; it is svg url if "pdf" field; it is transcoded video url if "video" field
					? { optimisedUrl: fieldContent.optimisedUrl }
					: {}),
				...(!isNullish(fieldContent.highResUrl) ? { highResUrl: fieldContent.highResUrl } : {}), // highResUrl is image url for "image"field; it is svg url for "pdf" field; it is transcoded video url for "video" field
				...(fieldContent.croppedImgUrl ? { croppedImgUrl: fieldContent.croppedImgUrl } : {}),
				...(fieldContent.clippedImgUrl ? { clippedImgUrl: fieldContent.clippedImgUrl } : {}),
				...(!isNullish(fieldContent.videoLoop) ? { videoLoop: fieldContent.videoLoop } : {}),
				...(fieldContent.position
					? {
							left: roundDecimals(fieldContent.position.left / pdfPtToPixel), // convert pixel to pdf pt
							top: roundDecimals(fieldContent.position.top / pdfPtToPixel),
							width: roundDecimals(fieldContent.position.width / pdfPtToPixel),
							height: roundDecimals(fieldContent.position.height / pdfPtToPixel),
					  }
					: {}),
				...(fieldContent.editorHtml ? { editorHtml: fieldContent.editorHtml } : {}),
			};
			if (Object.keys(fieldProperties).length > 0) {
				return {
					id: fieldId,
					type: fieldIdTypeMapping[fieldId],
					[`${fieldIdTypeMapping[fieldId]}Properties`]: fieldProperties,
				};
			} else {
				return null;
			}
		})
		.filter((item) => item);
};

// /**
//  * (browser-side only) export multiple artwork to pdf (multiple pages) with/without output template file
//  * @param {array} fields All fields in the artwork template design
//  * @param {object} fieldOutputDataArray // fieldsInputDataArray. [{data: {fieldOutputData}, templateBGData:{}, num: 17}, ...] // fieldOutputData is field output data, combination of user input data and field default data. format: [{FIELD_ID: {}, FIELD_ID: {}, ...}, ...]
//  * templateBGData is Optional. Format of templateBGData in fieldOutputDataArray:
//   {
// 		mediafileId: 'xxx',
// 		mediafilePreviewUrl:'xxx'|''|null,
// 		mediafileHighResUrl:'xxx'|''|null,
// 		mediafileOptimisedUrl:'xxx'|''|null,
// 		mediafilePdfUrl: 'xxx'|''|null,
// 		mediafileSvgUrl: 'xxx'|''|null,
// 	}
//  * @param {object} opts
//   {
// 			outputTemplate: { "id": 2, "title": "A4 14-Up", "mediafile_id": 0, "page_width": 595, "page_height": 844, "margin_top": 25, "margin_bottom": 0, "margin_left": 0, "margin_right": 0, "gutter_hor": 3, "gutter_ver": 0, "created_by": 0, "created_date": "0000-00-00", "mediafile_url": null },,
// 			templateSize: {width: xx, height: xx},
// 			outputFileS3Url: string, // optional. the s3 url where the output pdf file should be saved
// 			fontList: an array of font object. [{name: 'xxx', fontUrl: 'xxxx'}, ...],
// 			colorList: OBJECT, {'R-G-B': [c,m,y,k], ...}
// 			animations: ART_VARIABLES.animations, // for print, hence no animation required
//   		CONSTANTS:{
//   			placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
// 				DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
//   			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY
//   		},
// 			reportProgress: func, // function to report progress. param {object}. {status: 'OK'|'DONE'|'FAILED', message: ['xxx', 'yyy', ...]} (NB: message is an array)
// 			domain: string, // required
//   }

//  */
// export const generateMultipleArtworkToPdfPagesWithServer = async (
// 	fields,
// 	fieldOutputDataArray,
// 	opts = {}
// ) => {
// 	if (fieldOutputDataArray.length === 0) {
// 		throw new Error('No print data');
// 	} else if (!PDFDocument) {
// 		throw new Error(`PDF generator wasn't initialized!!`);
// 	}

// 	let domainConf = getDomainConfig(opts.domain);
// 	let pageCreationReportMsg = '';
// 	const reportProgress = opts.reportProgress
// 		? () => {
// 				opts.reportProgress({
// 					status: 'OK',
// 					message: [pageCreationReportMsg],
// 				});
// 		  }
// 		: () => null;

// 	let s3Client = await awsCreateS3Client();
// 	const sleep = (timeout) => new Promise((res) => setTimeout(res, timeout || 0));
// 	const sleepTimeout = 5; // in ms

// 	let hasOutputTemplate = !isNullish(opts.outputTemplate?.id); // Object.keys(opts.outputTemplate || {}).length > 0;
// 	let isPrintable = true;
// 	let outputTemplate = { width: opts.templateSize.width, height: opts.templateSize.height }, // default, we use item size in outputTemplate
// 		numOfItemsInRow = 1,
// 		numOfRowsInPage = 1,
// 		numOfItemsInPage = 1;
// 	if (hasOutputTemplate) {
// 		let outputPlan = outputTemplatePlan(opts.templateSize, opts.outputTemplate);
// 		numOfItemsInRow = outputPlan.numOfItemsInRow;
// 		numOfRowsInPage = outputPlan.numOfRowsInPage;
// 		numOfItemsInPage = outputPlan.numOfItemsInPage;
// 		outputTemplate = outputPlan.formatedOutputTemplate;
// 	}

// 	// outputTemplateSVG is the root svg of output (pdf) page. Clone it when using
// 	const outputTemplateSVG = document.createElementNS(NS.SVG, 'svg');
// 	outputTemplateSVG.setAttribute('viewBox', `0 0 ${outputTemplate.width} ${outputTemplate.height}`);
// 	outputTemplateSVG.setAttribute('xmlns', NS.SVG);
// 	outputTemplateSVG.setAttribute('overflow', 'hidden');
// 	// no harm to add animation css style here, just in case we need to export svg. It will be ignored when generating pdf
// 	addAnimationStyleToSVG(outputTemplateSVG, { fields, animations: opts.animations });
// 	// Create fonts style required in this artwork except the ones in nested artwork. The fonts style required in nested artwork is inside the itself
// 	// As we use fontList data attribute to carry the font data (including the fonts in nested artwork), so no need to use dataUrl (base64) of the font
// 	await addFontStyleToSVG(outputTemplateSVG, { full: false, fontList: opts.fontList });

// 	// rootItemSVG is the root svg of the item ([esign] ticket/card)
// 	let rootItemSVG = document.createElementNS(NS.SVG, 'svg');
// 	rootItemSVG.setAttribute('viewBox', `0 0 ${opts.templateSize.width} ${opts.templateSize.height}`);
// 	rootItemSVG.setAttribute('width', opts.templateSize.width);
// 	rootItemSVG.setAttribute('height', opts.templateSize.height);
// 	rootItemSVG.setAttribute('xmlns', NS.SVG);
// 	rootItemSVG.setAttribute('overflow', 'hidden');

// 	/**
// 	 * add background of the item svg (not output svg page). (svg background overrides image background)
// 	 * Note: we can't use templatePdfUrl for item background here, because item must be a svg dom so that it can be converted to pdf by pdfkit (pdf background can't be inserted to svg dom)
// 	 * Only one case we use templatePdfUrl for item background is when no outputTemplate and the template (item) has templatePdfUrl, in this case, it is loaded by pdflib in the later stage
// 	 */
// 	const insertItemBG = async ({ itemSVG, svgUrl, imageUrl }) => {
// 		if (svgUrl) {
// 			// template background is svg
// 			const svgBG = await createSvgDomFromUrl(svgUrl, opts.templateSize);

// 			let colorListObj = getColorListFromDataAttr(svgBG); // retrieve the color list from this svg (only when the svg was generated by our artwork tool)
// 			if (colorListObj) {
// 				opts.colorList = { ...opts.colorList, ...colorListObj };
// 			}
// 			let fontListArray = getFontListFromDataAttr(svgBG); // retrieve the font list from this svg (only when the svg was generated by our artwork tool)
// 			if (fontListArray) {
// 				opts.fontList = _uniqBy(opts.fontList.concat(fontListArray), 'name');
// 			}
// 			// We get the enough data, let's remove data attributes to reduce the output size
// 			removeColorListDataAttr(svgBG);
// 			removeFontListDataAttr(svgBG);

// 			itemSVG.append(svgBG);
// 		} else if (imageUrl) {
// 			// background is image
// 			const backgroundImage = document.createElementNS(NS.SVG, 'image');
// 			backgroundImage.setAttribute('width', '100%');
// 			backgroundImage.setAttribute('height', '100%');
// 			backgroundImage.setAttribute('x', '0');
// 			backgroundImage.setAttribute('y', '0');
// 			backgroundImage.setAttribute('href', imageUrl);

// 			itemSVG.append(backgroundImage);
// 		}
// 	};

// 	// page dimension [width, height] in pdf point
// 	const pageDimension = [outputTemplate.width * pixelToPdfPt, outputTemplate.height * pixelToPdfPt];
// 	let pdfBGUrl = null;

// 	///////////////////////
// 	// create page svg of each page
// 	//////////////////////
// 	let totalItems = fieldOutputDataArray.reduce((accu, outputData) => {
// 		return accu + outputData.num;
// 	}, 0);
// 	let totalPages = Math.ceil(totalItems / numOfItemsInPage);
// 	let itemIndex = 0,
// 		pageIndex = 0,
// 		currentPageSVG = outputTemplateSVG.cloneNode(true); // initialize page svg
// 	let svgPages = []; // array of svg page

// 	const serverSideProcess = {
// 		// rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
// 		s3Client: s3Client, // mandatary
// 		s3Params: {
// 			// mandatary
// 			bucket: domainConf.s3ArtworkTempStorage.bucket,
// 			filePrefix:
// 				domainConf.s3ArtworkTempStorage.basePath + new Date().toISOString().split('T')[0] + '/',
// 		},
// 	};

// 	// assign pdf page background for this page
// 	if (hasOutputTemplate && outputTemplate.mediafile_url) {
// 		// The output template has a pdf background. Note: outputTemplate.mediafile_url is a pdf file
// 		pdfBGUrl = outputTemplate.mediafile_url;
// 	}

// 	for (let i = 0; i < fieldOutputDataArray.length; i++) {
// 		// 1. clone the item svg and add the cloned item svg to page svg by the number in its data object
// 		const fieldOutputData = fieldOutputDataArray[i].data;
// 		const templateBGData = fieldOutputDataArray[i].templateBGData || {};

// 		let rootSVG = rootItemSVG.cloneNode(true);
// 		// 2. insert item background (we prefer item background to be svg > pdf > image),
// 		// in case svg item BG is available, we will insert it regardless the output template background is available or not
// 		await insertItemBG({
// 			itemSVG: rootSVG,
// 			svgUrl: templateBGData.mediafileSvgUrl,
// 			imageUrl:
// 				!hasOutputTemplate && templateBGData.mediafilePdfUrl // in this case, we insert pdf background at later stage, hence use null for image
// 					? null
// 					: templateBGData.mediafileHighResUrl,
// 		});

// 		// creating shadow image in prepareTextField requires root svg to be appended to document
// 		document.body.append(rootSVG);

// 		// create itemSVG by appending all field elements to rootSVG
// 		await fields.reduce((promiseChain, field) => {
// 			return promiseChain.then(async () => {
// 				if (hideFieldOutput(field, fields, fieldOutputData)) return null;
// 				switch (field.type) {
// 					case 'text':
// 						return isConcatField(field)
// 							? getConcatFieldType(field, fields) === 'TEXT_ONLY'
// 								? prepareConcatTextField({
// 										mode: ARTWORK_SERVER_SIDE_PROCESS,
// 										field,
// 										templateFields: fields,
// 										fieldOutputData,
// 										dataForExport: {
// 											rootSVG,
// 											isPrintable,
// 											fontList: opts.fontList,
// 											animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
// 										},
// 										serverSideProcess,
// 										CONSTANTS: opts.CONSTANTS,
// 								  })
// 								: prepareConcatImageField({
// 										mode: ARTWORK_SERVER_SIDE_PROCESS,
// 										field,
// 										templateFields: fields,
// 										fieldOutputData,
// 										dataForExport: {
// 											rootSVG,
// 											isPrintable,
// 											fontList: opts.fontList,
// 											animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
// 										},
// 										serverSideProcess: {},
// 										CONSTANTS: opts.CONSTANTS,
// 								  })
// 							: prepareTextField({
// 									mode: ARTWORK_SERVER_SIDE_PROCESS,
// 									inputData: fieldOutputData[field.id],
// 									field,
// 									dataForExport: {
// 										rootSVG,
// 										isPrintable,
// 										fontList: opts.fontList,
// 										animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
// 									},
// 									serverSideProcess,
// 									CONSTANTS: opts.CONSTANTS,
// 							  });

// 					case 'image':
// 						return prepareImageField({
// 							mode: ARTWORK_SERVER_SIDE_PROCESS,
// 							inputData: fieldOutputData[field.id],
// 							field,
// 							dataForExport: {
// 								rootSVG,
// 								isPrintable,
// 								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
// 							},
// 							serverSideProcess: {},
// 						});
// 					case 'barcode':
// 						return prepareBarcodeField({
// 							mode: ARTWORK_SERVER_SIDE_PROCESS,
// 							inputData: fieldOutputData[field.id],
// 							field,
// 							dataForExport: {
// 								rootSVG,
// 								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
// 							},
// 							serverSideProcess: {},
// 						});
// 					// case 'video':
// 					// 	return prepareVideoField({
// 					// 		mode: ARTWORK_SERVER_SIDE_PROCESS,
// 					// 		inputData: fieldOutputData[field.id],
// 					// 		field,
// 					// 		dataForExport: { rootSVG, animationDelay: calcAnimationDelay(fields, field, fieldOutputData) },
// 					// 		serverSideProcess: {},
// 					// 		CONSTANTS: opts.CONSTANTS,
// 					// 	});
// 					case 'pdf': {
// 						const { colorListObj, fontListArray } =
// 							(await preparePdfField({
// 								mode: ARTWORK_SERVER_SIDE_PROCESS,
// 								inputData: fieldOutputData[field.id],
// 								field,
// 								dataForExport: {
// 									rootSVG,
// 									animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
// 								},
// 								serverSideProcess: {},
// 							})) || {};
// 						if (colorListObj) {
// 							opts.colorList = { ...opts.colorList, ...colorListObj };
// 						}
// 						if (fontListArray) {
// 							opts.fontList = _uniqBy(opts.fontList.concat(fontListArray), 'name');
// 						}
// 						return null;
// 					}
// 					case 'grid':
// 						return prepareGridField({
// 							mode: ARTWORK_SERVER_SIDE_PROCESS,
// 							inputData: fieldOutputData[field.id],
// 							field,
// 							dataForExport: {
// 								rootSVG,
// 								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
// 							},
// 							serverSideProcess: {},
// 							CONSTANTS: opts.CONSTANTS,
// 						});
// 					default:
// 						return null; //Promise.resolve();
// 				}
// 			});
// 		}, Promise.resolve());

// 		// NOTE: opts.colorList & opts.fontList will NOT be used as data attribute because we do not reuse it in nested svg in future
// 		// at this point, item is created (as rootSVG), we will add it to page with its quantity

// 		// 2. clone the item svg and add the cloned item svg to page svg by the number in its data object
// 		for (let idx = 0; idx < fieldOutputDataArray[i].num; idx++) {
// 			// find|create svg page
// 			let outputPageIndex = floorDecimals(itemIndex / numOfItemsInPage); // 0-indexed. 1st page is 0, 2nd page is 1, etc.
// 			if (outputPageIndex !== pageIndex) {
// 				// page is full, let's add it to all svgPages and start a new page

// 				// to prevent potential "page not responding" dialog, let it sleep for a little while
// 				// NB: When tab is inactive, browser sets minimal setTimeout period to 1000ms, hence "sleep" actually takes min 1s to finish
// 				// so we only apply the "sleep" for each page not for each item
// 				await sleep(sleepTimeout);

// 				// page background:
// 				//		- if there is output template, we will use the background (it is pdf) in all pages
// 				//		- if no outputTemplate, each svg item is a page, the background could be customisable by user,
// 				// 			- if custom BG is SVG, it's been insterted by insertItemBG() at early stage
// 				// 			- if custom BG is PDF (in fieldOutputDataArray[i]), we will keep it with the SVG page for server-side process
// 				svgPages.push({
// 					svg: currentPageSVG.cloneNode(true),
// 					pagePdfBGUrl: pdfBGUrl
// 						? pdfBGUrl // using the background url in output template
// 						: !hasOutputTemplate &&
// 						  !templateBGData.mediafileSvgUrl &&
// 						  templateBGData.mediafilePdfUrl
// 						? templateBGData.mediafilePdfUrl // using custom BG
// 						: undefined, // SVG/image BG that's already inserted to itemSVG, or no BG at all
// 				});
// 				// start to process new page
// 				currentPageSVG = null; // free memory.
// 				currentPageSVG = outputTemplateSVG.cloneNode(true); // create a new page svg
// 				pageIndex = outputPageIndex;
// 			}

// 			// calculate (x, y) of this item in a page (which row and which item in a row of this item)
// 			let rowIndex = floorDecimals(itemIndex / numOfItemsInRow) % numOfRowsInPage; // zero-indexed number. 1st row as 0, 2nd row as 1, etc.
// 			let positionInRow = (itemIndex % numOfItemsInPage) - rowIndex * numOfItemsInRow; // zero-indexed number. 1st position in a row as 0, 2nd position in a row as 1, etc.
// 			let itemX = hasOutputTemplate
// 				? outputTemplate.margin_left +
// 				  positionInRow * (opts.templateSize.width + outputTemplate.gutter_ver) // first item in a row doesn't have gutter to the left
// 				: 0; // x position of this item (in pixel)
// 			let itemY = hasOutputTemplate
// 				? outputTemplate.margin_top +
// 				  rowIndex * (opts.templateSize.height + outputTemplate.gutter_hor) // first row in a page doesn't have gutter to the top
// 				: 0; // y position of this item (in pixel)

// 			let itemSVG = rootSVG.cloneNode(true);

// 			itemSVG.setAttribute('x', itemX);
// 			itemSVG.setAttribute('y', itemY);
// 			currentPageSVG.append(itemSVG);
// 			itemSVG = null;
// 			itemIndex += 1;
// 		}

// 		rootSVG.remove();
// 		rootSVG = null;
// 	}

// 	// add last page to array
// 	const templateBGData = fieldOutputDataArray[fieldOutputDataArray.length - 1].templateBGData || {};
// 	svgPages.push({
// 		svg: currentPageSVG.cloneNode(true),
// 		pagePdfBGUrl: pdfBGUrl
// 			? pdfBGUrl
// 			: !hasOutputTemplate && !templateBGData.mediafileSvgUrl && templateBGData.mediafilePdfUrl
// 			? templateBGData.mediafilePdfUrl
// 			: undefined,
// 	});

// 	pageCreationReportMsg = `Generating print PDF - ${totalPages} pages`;
// 	reportProgress();

// 	const res = await generateArtworkPdfs({
// 		bodyParams: {
// 			pageSize: {
// 				width: pageDimension[0],
// 				height: pageDimension[1],
// 			},
// 			fontList: opts.fontList.map((font) => ({ ...font, name: cleanupFontName(font.name) })),
// 			colorList: opts.colorList,
// 			outputFileS3Url: opts.outputFileS3Url,
// 			svgPages: svgPages.map((svgPage) => ({
// 				svg: new XMLSerializer().serializeToString(svgPage.svg),
// 				pagePdfBGUrl: svgPage.pagePdfBGUrl,
// 			})),
// 		},
// 	});
// 	return res.data.pdfUrl;
// };
