import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
// import { FormattedMessage } from 'react-intl';

// Constants
import {
	ROUTES_PATH_ARTWORK_DESIGNER,
	ROUTES_PATH_ARTWORK_CREATOR,
	ROUTES_PATH_ARTWORK_ESIGN,
} from 'routes';
import { DESIGN_MIN_UL } from './Constants';

// import { InsertPhoto as ArtworkIcon } from '@mui/icons-material';

const ArtworkDesign = loadable(() => import('./components/Design/Design'), {
	fallback: <Loading size={50} />,
});

const ArtworkCreate = loadable(() => import('./components/Create/Create'), {
	fallback: <Loading size={50} />,
});

const ArtworkEsign = loadable(() => import('./components/Esign/Esign'), {
	fallback: <Loading size={50} />,
});

const artworkRoutes = [
	{
		path: ROUTES_PATH_ARTWORK_DESIGNER,
		// sidebarName: <FormattedMessage id="pages.Artwork.Routes.Artwork" />,
		// sidebarIcon: ArtworkIcon,
		requiredUserLevel: { $gt: DESIGN_MIN_UL },
		noBreadcrumbs: true,
		component: ArtworkDesign,
		children: [],
	},
	{
		path: ROUTES_PATH_ARTWORK_CREATOR,
		// sidebarName: <FormattedMessage id="pages.Artwork.Routes.Artwork" />,
		// sidebarIcon: ArtworkIcon,
		// sidebarName: '',
		// sidebarIcon: null,
		// noBreadcrumbs: false,
		component: ArtworkCreate,
		children: [],
	},
	{
		path: ROUTES_PATH_ARTWORK_ESIGN,
		// sidebarName: <FormattedMessage id="pages.Artwork.Routes.Artwork" />,
		// sidebarIcon: ArtworkIcon,
		// sidebarName: '',
		// sidebarIcon: null,
		// noBreadcrumbs: false,
		component: ArtworkEsign,
		children: [],
	},
];
export default artworkRoutes;
