import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import matchSorter from 'match-sorter';
import {
	Typography,
	Card,
	// CardHeader,
	// CardMedia,
	CardActions,
	IconButton,
	CardContent,
	Grid,
	FormControlLabel,
	Radio,
	RadioGroup,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
// intl lang
// import { useIntl } from 'react-intl';
import { lightGreen, red, grey } from '@mui/material/colors';

import { _ } from 'utils/libHelper';

const useStyles = makeStyles((theme) => ({
	// root: {
	// 	position: 'relative',
	// 	width: '100%',
	// 	height: '100%',
	// },
	containerBox: {
		display: 'flex',
		position: 'relative',
		// overflow: 'hidden',
		width: '100%',
		height: '100%',
		// height: 'calc(100% - 100px)',
		minHeight: `max(120px, calc(100% - 100px))`, //120,
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		alignContent: 'flex-start',
		// [theme.breakpoints.up(theme.screenBreakpointUp)]: {
		// 	justifyContent: 'flex-start',
		// },
		[theme.breakpoints.down(theme.screenBreakpointDown)]: {
			justifyContent: 'space-evenly',
		},
	},
	itemBox: {
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		flex: '0 1 auto',
		alignSelf: 'auto',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid rgba(0,0,0,0.1)`,
		borderRadius: 4,
		height: 'auto',
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		width: '100%',
		// [theme.breakpoints.up(theme.screenBreakpointUp)]: {
		// 	width: ({ numItemsPerRow }) => {
		// 		// minus theme.spacing(2) is because we use theme.spacing(1) for margin left & right
		// 		return `calc(${(1 / numItemsPerRow) * 100}% - ${theme.spacing(2)}px)`;
		// 	},
		// },
		// [theme.breakpoints.down(theme.screenBreakpointDown)]: {
		// 	width: '90%',
		// },
		'&:hover': {
			borderColor: `rgba(0,0,0,0.3)`,
			boxShadow: theme.customBoxShadow.boxShadow,
		},
		'& img': {
			maxHeight: '100%',
			maxWidth: '100%',
		},
	},
	itemBoxNotLive: {
		backgroundColor: `rgb(247 116 116)`,
	},
	cardRoot: {
		width: '100%',
		boxShadow: 'unset',
	},
	cardHeader: {
		padding: 'unset',
		paddingLeft: theme.spacing(0.5),
		// fontSize: '1rem',
	},
	cardHeaderContent: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	cardMedia: {
		// height: 100,
		// paddingTop: '56.25%', // 16:9
		backgroundSize: 'contain',
		backgroundColor: `rgba(0,0,0,0.1)`,
	},
	cardAction: {
		padding: 'unset',
		paddingTop: theme.spacing(1),
		flexWrap: 'wrap',
		gap: theme.spacing(0.4),
	},
	// actionIconButton: {
	// 	padding: 4,
	// 	border: `1px solid`,
	// 	'&:hover': {
	// 		backgroundColor: `rgba(0,0,0,0.1)`,
	// 	},
	// },
	iconButtons: {
		marginLeft: theme.spacing(1 / 2),
		marginRight: theme.spacing(1 / 2),
	},
	iconButtonRootSmaller: {
		fontSize: theme.typography.pxToRem(12),
		padding: theme.spacing(1 / 2),
	},
	iconButtonRootWithBGColor: {
		backgroundColor: lightGreen[300],
		'&:hover': {
			backgroundColor: lightGreen[500],
		},
		'&:disabled': {
			backgroundColor: grey[500],
		},
	},
	iconButtonRootWithRedBGColor: {
		backgroundColor: red[300],
		'&:hover': {
			backgroundColor: red[500],
		},
		'&:disabled': {
			backgroundColor: grey[500],
		},
	},
	textField: {
		// marginLeft: theme.spacing.unit,
		// marginRight: theme.spacing.unit,
		// width: 300,
		color: 'black',
		fontSize: 30,
		opacity: 1,
		borderBottom: 0,
		'&:before': {
			borderBottom: 0,
		},
	},
	// disabledTextField: {
	// 	color: 'black',
	// 	borderBottom: 0,
	// 	'&:before': {
	// 		borderBottom: 0,
	// 	},
	// },
	underlineText: {
		'&&&:before': {
			borderBottom: 'none',
		},
		'&&:after': {
			borderBottom: 'none',
		},
	},
	// textFieldInputUnderline: {
	// 	'&:before': {
	// 		borderBottom: `1px solid ${textFieldLineColor}`,
	// 	},
	// 	'&:after': {
	// 		borderBottom: `2px solid ${textFieldLineFocusColor}`,
	// 	},
	// 	'&:hover:not(.Mui-disabled):before': {
	// 		borderBottom: `2px solid ${textFieldLineHoverColor}`,
	// 	},
	// },
	textFieldInput: {
		color: 'inherit',
		// fontSize: 12,
	},
}));

function ScreenManagerGridView({
	cardViewData,
	columns,
	screenActions,
	noDataMsg,
	editMode,
	searchFilter,
	searchColumnId,
	...rest
}) {
	const classes = useStyles();
	const [screenEditData, setScreenEditData] = React.useState({});
	const [cardDetails, setCardDetails] = React.useState([]);
	const generateDOM = (layoutData, id, elem) => {
		// editMode
		let currentEditing = editMode;
		let isEditMode = true;
		if (editMode !== undefined && currentEditing.original !== undefined) {
			if (layoutData.id === currentEditing.original.id) {
				isEditMode = false;
			}
		}
		if (!isEditMode) {
			isEditMode = elem.editable ? false : true;
		}
		if (!isEditMode) {
			if (elem.editable) {
				switch (elem.editableComp) {
					case 'text': {
						return (
							<TextField
								name="email"
								key={layoutData.id}
								defaultValue={screenEditData[[elem.id]] || layoutData[id]}
								size="small"
								variant={'outlined'}
								onChange={(e) => {
									let editObj = {
										[elem.id]: e.target.value,
									};
									setScreenEditData({ ...screenEditData, ...editObj });
								}}
								// disabled={isEditMode}
								className={classes.textField}
								InputProps={{
									className: classes.textFieldInput,
									readOnly: isEditMode,
								}}
							/>
						);
					}
					case 'dropdown': {
						return (
							<Select
								id="playlistDepartmentEdit"
								label="Department"
								value={screenEditData[[elem.dropdownKeyValue]] || layoutData[elem.dropdownKeyValue]}
								onChange={(event) => {
									let editObj = {
										[elem.dropdownKeyValue]: event.target.value,
									};
									setScreenEditData({ ...screenEditData, ...editObj });
								}}
							>
								{_.sortBy(elem.editableDropdownValue, [(dept) => dept.name.toLowerCase()]).map(
									(department) => (
										<MenuItem key={department.name} value={department.id}>
											{department.name}
										</MenuItem>
									)
								)}
							</Select>
						);
					}
					case 'radio': {
						return (
							<RadioGroup
								aria-label="layout"
								name="layout"
								value={screenEditData[[elem.id]] || layoutData[id]}
								onChange={(event) => {
									let editObj = {
										[elem.id]: event.target.value,
									};
									setScreenEditData({ ...screenEditData, ...editObj });
								}}
								row
							>
								<FormControlLabel
									key={layoutData.id + elem.onEditRadioValues[0]}
									value={elem.onEditRadioValues[0]}
									control={<Radio />}
									label={elem.onEditRadioValues[0]}
								/>
								<FormControlLabel
									key={layoutData.id + elem.onEditRadioValues[1]}
									value={elem.onEditRadioValues[1]}
									control={<Radio />}
									label={elem.onEditRadioValues[1]}
								/>
							</RadioGroup>
						);
					}
					default:
						break;
				}
			}
		} else {
			let cardView;
			if (elem.CustomCell) {
				cardView = elem.CustomCell(layoutData);
			} else {
				cardView = layoutData[id];
			}
			return <label key={layoutData.id}>{cardView}</label>;
		}
	};

	React.useEffect(() => {
		setCardDetails(cardViewData);
		if (searchFilter.length > 0 && searchFilter[0].id === 'all') {
			const result = matchSorter(cardViewData, searchFilter[0].value, {
				keys: searchColumnId,
				threshold: matchSorter.rankings.CONTAINS,
			});
			setCardDetails(result);
		}
	}, [cardViewData, searchColumnId, searchFilter]);
	return (
		<div className={classes.containerBox}>
			{cardDetails.length > 0 ? (
				cardDetails.map((cardData, idx) => {
					return (
						<div key={cardData.id + '-' + idx} className={cx(classes.itemBox)}>
							<Card raised className={classes.cardRoot}>
								<CardContent>
									{columns.map((elem) => (
										<Grid key={columns.indexOf(elem)} direction="row" container spacing={2}>
											{
												<Grid container alignItems="center" item paddingBottom={0.5} xs={4}>
													{elem.Header}
													{':'}
												</Grid>
											}
											<Grid container alignItems="center" item paddingBottom={0.5} xs={8}>
												{generateDOM(cardData, elem.id, elem)}
											</Grid>
										</Grid>
									))}
								</CardContent>
								{Array.isArray(screenActions) && screenActions.length > 0 && (
									<CardActions className={classes.cardAction} disableSpacing={true}>
										{screenActions.map((action, idx) => {
											return action.shouldDisplay(cardData) ? (
												<IconButton
													key={cardData.id + '' + idx}
													// className={classes.actionIconButton}
													classes={{
														root: cx(
															classes.iconButtonRootSmaller,
															action.color === 'red'
																? classes.iconButtonRootWithRedBGColor
																: classes.iconButtonRootWithBGColor,
															// classes.iconButtonRootWithBGColor,
															classes.iconButtons
														),
													}}
													size="small"
													// color="primary"
													title={action.tooltip}
													disabled={
														typeof action.isDisabled === 'function'
															? action.isDisabled(cardData)
															: false
													}
													onClick={(e) => {
														let screenDataTemp = { ...screenEditData };
														setScreenEditData({});
														return typeof action.clickHandler === 'function'
															? action.clickHandler(cardData, e, screenDataTemp)
															: null;
													}}
												>
													{action.icon}
												</IconButton>
											) : null;
										})}
									</CardActions>
								)}
							</Card>
						</div>
					);
				})
			) : (
				<Typography
					color="error"
					style={{ textAlign: 'center', width: '100%', paddingTop: 50, minHeight: 120 }}
				>
					{/* isLoading ? null :  */ noDataMsg}
				</Typography>
			)}
		</div>
	);
}

ScreenManagerGridView.propTypes = {
	cardViewData: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	/**
			* Extra click actions to each screen's data
			 [
						 {
							 shouldDisplay: (cardData)=>true/false, // hook function to verify should the action be displayed for the cardData
							 icon: <AddIcon fontSize="small" />,
							 tooltip: 'add',
							 clickHandler: (cardData, event) => {},
						 },
						 {
							 shouldDisplay: (cardData)=>true/false, // hook function to verify should the action be displayed for the cardData
							 icon: <SaveIcon fontSize="small" />,
							 tooltip: 'Save',
							 clickHandler: (cardData, event) => {},
						 },
					 ],
			*/
	screenActions: PropTypes.arrayOf(PropTypes.object),
	noDataMsg: PropTypes.string,
	editMode: PropTypes.object,
	searchFilter: PropTypes.array,
	/**
	 * Id's of the column which needs to be searched eg. ['name', 'description', 'duration']
	 */
	searchColumnId: PropTypes.array,
};

ScreenManagerGridView.defaultProps = {
	noDataMsg: 'No rows found!!',
	screenActions: [],
};

export default ScreenManagerGridView;
