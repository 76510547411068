import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import { amber } from '@mui/material/colors';

import { useIntl } from 'react-intl';
import { SearchV2 } from 'components';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	dateRangeRoot: {
		width: '100%',
	},
	button: {
		padding: theme.spacing(0.5, 1),
		whiteSpace: 'nowrap',
	},
	interactionSection: {
		display: 'flex',
	},
	filter: {
		flex: `0 1 auto`,
	},
	search: {
		flex: `1 0 auto`,
	},
	viewSwitchBtns: {
		flex: `0 1 auto`,
		display: 'inline-flex',
		gap: theme.spacing(0, 0.5),
	},
	filtersDisplaySection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		gap: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	selectedFilterChipsContainer: {
		...theme.customBoxShadow,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5),
		padding: theme.spacing(0.5, 1),
		border: `1px solid rgba(0,0,0,0.2)`,
		borderRadius: 16,
		backgroundColor: amber[200],
	},
	filterPaper: {
		marginTop: theme.spacing(1),
		maxHeight: 300,
	},
	filtersContainer: {
		position: 'relative',
		maxWidth: 530,
		// maxHeight: 300,
		padding: theme.spacing(0.5, 2),
		// marginTop: theme.spacing(1),
		// overflowY: 'auto',
		// overflowX: 'hidden',
	},
	filterSection: {
		display: 'flex',
		flexDirection: 'column',
		'&::after': {
			content: `""`,
			// width: '100%',
			border: 'none',
			height: 1,
			margin: 0,
			// flexShrink: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.12)',
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	datetimeFilterContainer: {
		display: 'flex',
		gap: theme.spacing(1),
		alignItems: 'center',
	},
	filterMenuItemContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	filterMenuItem: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	switchFilterContainer: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},
	switchFilterName: {
		minWidth: 140,
	},
	filterSwitch: {
		marginLeft: theme.spacing(1),
	},
	filtersBtnContainer: {
		display: 'flex',
		gap: theme.spacing(1),
		padding: theme.spacing(0.5, 1),
		alignItems: 'center',
		justifyContent: 'flex-end',
		position: 'sticky',
		bottom: 0,
		zIndex: 1,
		float: 'right',
	},
	sortByWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},
	sortBySelectWrapper: {
		flex: '1 0 auto',
		maxWidth: '80%',
	},
	sortBySelect: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	sortByDirection: {
		flex: '0 0 auto',
		marginLeft: theme.spacing(2),
	},
}));

function EditDomainSettingsFilters({
	searchKeywords,
	setSearchKeywords,
	// columnsConfigure,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	return (
		<div className={classes.root}>
			<section className={classes.interactionSection}>
				<div className={classes.search}>
					<SearchV2
						className={classes.searchWrapper}
						placeholder={`${intl.formatMessage({ id: 'GENERAL.Search' })}...`}
						value={searchKeywords}
						OnEnterKeyPressed={(e) => {
							if (searchKeywords === (e.target.value || '')) return;
							setSearchKeywords(e.target.value || '');
							// reset page index
							// gotoPage(0);
						}}
						onSearchClick={(keywords) => {
							if (searchKeywords === keywords) return;
							setSearchKeywords(keywords || '');
							// reset page index
							// gotoPage(0);
							// }
						}}
						onClearClick={() => {
							if (searchKeywords === '') return;
							setSearchKeywords('');
							// reset page index
							// gotoPage(0);
						}}
					/>
				</div>
			</section>
		</div>
	);
}

EditDomainSettingsFilters.propTypes = {
	/**
	 * search keywords from user
	 */
	searchKeywords: PropTypes.string,
	/**
	 * handler of changing of search keywords
	 * @param {string}
	 */
	setSearchKeywords: PropTypes.func.isRequired,

	// selectedFilters: PropTypes.object.isRequired,
	/**
	 * handler of setting user selected filters
	 * @param {object} filters. The updated filters
	 */
	// setSelectedFilters: PropTypes.func.isRequired,
	/**
	 * Array of table columns configure (used for sortable columns)
	 */
	// columnsConfigure: PropTypes.array.isRequired,
	/**
	 * sortBy array
	 * NB: it is used as initial state in rTable for sorting
	 */
	// appliedSortBy: PropTypes.array.isRequired,
	/**
	 * set sortBy for searching messages
	 * @param {array} sortBy_in_rTableInst. format: [{id: 'xxx', desc: true/false}]. it is same format as tableInst.state.sortBy.
	 */
	// setSortBy: PropTypes.func.isRequired,
};
EditDomainSettingsFilters.defaultProps = { searchKeywords: '' };

export default EditDomainSettingsFilters;
