import React from 'react';
import ReactDOM from 'react-dom';
// import configureStore from 'redux/configureStore';
import store from 'redux/store';
import * as serviceWorker from './serviceWorker';
// import { AppContainer } from 'react-hot-loader';

// polyfills
import 'core-js/stable/set-immediate';

import { handleAuth } from 'redux/actions'; // redux actions
import { Provider as ReduxProvider } from 'react-redux';

import App from 'App/App';

// Initialize auth info in case user closed the page but the auth info is still in localstorage/cookie
// (NOTE: the auth info may be not available, but don't worry, we will update it in authCallback component)
// const store = configureStore();
store.dispatch(handleAuth());

// ReactDOM.render(<App />, document.getElementById('root'));
// const hist = createBrowserHistory({ basename: config.urlBaseName });
const MOUNT_NODE = document.getElementById('root');

function renderWithHRM(RootElement) {
	ReactDOM.render(
		// <AppContainer>
		// 	<ReduxProvider store={store}>
		// 		<RootElement />
		// 	</ReduxProvider>
		// </AppContainer>,
		<ReduxProvider store={store}>
			<RootElement />
		</ReduxProvider>,
		MOUNT_NODE
	);
}

// if (module.hot) {
// 	// Hot reloadable React components and translation json files
// 	module.hot.accept(['./lang', './App/App'], () => {
// 		const NextApp = require('./App/App').default;
// 		renderWithHRM(NextApp);
// 	});
// }

renderWithHRM(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
