import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import {
	Table as MuiTable,
	TableBody,
	TableCell,
	TableContainer,
	// TableFooter,
	TableHead,
	TablePagination,
	// TablePaginationActions,
	TableSortLabel,
	TableRow,
	// CircularProgress,
	Typography,
} from '@mui/material';

import TablePaginationActions from './TablePaginationActions';

const paginationHeight = 52;
const useStyles = makeStyles((theme) => ({
	enhancedRTableRoot: {
		width: '100%',
		height: '100%',
		// padding: theme.spacing(1),
		position: 'relative',
	},
	rTableRootBorder: {
		border: '1px solid rgba(0,0,0,0.3)',
		borderRadius: 4,
	},
	tableWrapper: {
		width: '100%',
		height: '100%',
		// position: 'relative',
	},
	tableContainer: {
		maxHeight: `calc(100% - ${paginationHeight}px)`,
	},
	table: {
		border: '1px solid rgba(0,0,0,0.1)',
		borderRadius: 4,
	},
	tableHeadCell: {
		fontWeight: 700,
		fontSize: '1.15em',
		padding: theme.spacing(1, 1),
		whiteSpace: 'nowrap',
	},
	noCursor: {
		cursor: 'unset',
	},
	tableBodyCell: {
		padding: theme.spacing(0.5, 1),
	},
	// stickyTableHead: {
	// 	position: 'sticky',
	// 	top: 0,
	// 	alignSelf: 'flex-start',
	// 	zIndex: 1,
	// },
	stickyHeaderCell: {
		backgroundColor: '#cbedfd', // theme.palette.primary.light,
	},
	pagination: {
		height: paginationHeight,
	},
	paginationToleft: {
		flex: 'none',
	},
	// resizer: {
	// 	display: 'inline-block',
	// 	background: 'blue',
	// 	width: 10,
	// 	height: '100%',
	// 	position: 'absolute',
	// 	right: 0,
	// 	top: 0,
	// 	transform: 'translateX(50%)',
	// 	zIndex: 1,
	// 	/* prevents from scrolling while dragging on touch devices */
	// 	touchAction: 'none',

	// 	'&.isResizing': {
	// 		background: 'red',
	// 	},
	// },
	// rowHighlight: {
	// 	backgroundColor: theme.palette.primary.main,
	// 	color: theme.palette.primary.contrastText,
	// 	'&:hover': {
	// 		backgroundColor: theme.palette.primary.dark,
	// 	},
	// },
	inheritHighlight: {
		color: 'inherit',
		backgroundColor: 'inherit',
	},
	loader: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		zIndex: 999,
		opacity: 1,
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.15)',
		WebkitTapHighlightColor: 'transparent',
		borderRadius: 4,
	},
	hideLoader: {
		zIndex: -1,
		opacity: 0,
	},
	noData: {
		textAlign: 'center',
		paddingTop: theme.spacing(3),
	},
}));

const EnhancedRTable = ({
	className,
	isStickyHeader,
	isLoading,
	disableRowsPerPage,
	rowHighlightPicker,

	// pagination props
	handleChangePage,
	handleChangeRowsPerPage,
	rowsPerPage,
	pageIndex,
	totalNumRows,

	// table props
	getTableProps,
	headerGroups,
	prepareRow,
	page,
}) => {
	const classes = useStyles();
	const defaultRowPerPageOptions = [10, 25, 50, 100];

	// Render the UI for your table
	return (
		<div
			className={cx(classes.enhancedRTableRoot, {
				[className]: true,
				[classes.rTableRootBorder]: !isLoading && page.length === 0,
			})}
		>
			{
				<div className={cx(classes.loader, { [classes.hideLoader]: !isLoading })}>
					{
						// <CircularProgress />
					}
					<Typography
						variant="h4"
						style={{
							padding: '4px 16px',
							backgroundColor: '#eeeeee',
							borderRadius: 16,
						}}
					>
						Loading...
					</Typography>
				</div>
			}
			{!isLoading && page.length === 0 && (
				<Typography className={classes.noData} color="error" variant="h4">
					No Data...
				</Typography>
			)}
			{page.length > 0 && (
				<div className={classes.tableWrapper}>
					<TableContainer className={cx(classes.tableContainer)}>
						{
							// <TableToolbar
							// 	numSelected={Object.keys(selectedRowIds).length}
							// 	// deleteUserHandler={deleteUserHandler}
							// 	// addUserHandler={addUserHandler}
							// 	preGlobalFilteredRows={preGlobalFilteredRows}
							// 	setGlobalFilter={setGlobalFilter}
							// 	globalFilter={globalFilter}
							// />
						}
						<MuiTable className={classes.table} stickyHeader={isStickyHeader} {...getTableProps()}>
							{/** Table Header */}
							<TableHead>
								{headerGroups.map((headerGroup, headerGroupIdx) => (
									<TableRow
										key={`rTable-headerRow-${headerGroupIdx}`}
										{...headerGroup.getHeaderGroupProps()}
									>
										{headerGroup.headers.map((column, columnInHeaderGroupIdx) => {
											return (
												<TableCell
													key={`rTable-headerCell-${columnInHeaderGroupIdx}`}
													className={cx(classes.tableHeadCell, {
														[classes.stickyHeaderCell]: isStickyHeader,
													})}
													{...column.getHeaderProps()}
													{...(column.canSort ? column.getSortByToggleProps() : {})}
												>
													<TableSortLabel
														active={column.isSorted}
														direction={column.isSortedDesc ? 'desc' : 'asc'}
														hideSortIcon={!column.canSort}
														className={cx({ [classes.noCursor]: !column.canSort })}
													>
														{column.render('Header')}
													</TableSortLabel>
												</TableCell>
											);
										})}
									</TableRow>
								))}
							</TableHead>
							{/** Table Body */}
							<TableBody>
								{/* page is "An array of rows for the current page" */}
								{page.map((row, rowInPageIdx) => {
									prepareRow(row);
									let isRowHighlight = rowHighlightPicker && rowHighlightPicker(row);
									return (
										<TableRow
											key={`rTable-rowInPage-${rowInPageIdx}`}
											{...row.getRowProps()}
											hover={true}
											selected={isRowHighlight}
											// className={cx({
											// 	[classes.rowHighlight]: isRowHighlight,
											// })}
										>
											{row.cells.map((cell, cellInRowIdx) => {
												return (
													<TableCell
														key={`rTable-cellInRow-${cellInRowIdx}`}
														className={cx(classes.tableBodyCell, {
															// [classes.inheritHighlight]: isRowHighlight,
														})}
														// padding="none"
														// size="small"
														{...cell.getCellProps()}
													>
														{cell.render('Cell')}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</TableBody>
						</MuiTable>
					</TableContainer>
					<TablePagination
						className={classes.pagination}
						classes={{ spacer: classes.paginationToleft }}
						rowsPerPageOptions={
							Array.from(new Set(defaultRowPerPageOptions).add(rowsPerPage))
							// { label: rowsPerPage.toString(), value: rowsPerPage },
						}
						component="div"
						count={totalNumRows}
						rowsPerPage={rowsPerPage}
						page={pageIndex}
						SelectProps={{
							inputProps: { 'aria-label': 'rows per page' },
							disabled: disableRowsPerPage,
							// native: true,
						}}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</div>
			)}
		</div>
	);
};

EnhancedRTable.propTypes = {
	/**
	 * className applys to table container
	 */
	className: PropTypes.string,
	// /**
	//  * columns configuration object for the entire table for React-Table. Ref: https://react-table.tanstack.com/docs/api/useTable
	//  *
	//  * - Must be memoized
	//  * - Supports nested columns arrays
	//  */
	// columns: PropTypes.array.isRequired,
	// // a: PropTypes.shape({
	// // 	originalRow: PropTypes.object.isRequired,
	// // 	index: PropTypes.number.isRequired,
	// // })
	// /**
	//  * The data array that you want to display on the table. Ref: https://react-table.tanstack.com/docs/api/useTable
	//  *
	//  * - Must be memoized
	//  * - The data array that you want to display on the table
	//  */
	// data: PropTypes.array.isRequired,
	// updateMyData: PropTypes.func.isRequired,
	// setData: PropTypes.func.isRequired,
	// /**
	//  * If true, reset pageIndex on table changes: data changed, sort, filter, etc. For detail, see "autoResetPage" at https://react-table.tanstack.com/docs/api/usePagination
	//  */
	// skipPageReset: PropTypes.bool.isRequired,
	// /**
	//  * function to return row id from original row data
	//  * Function(originalRow, rowIndex) => any
	//  * @param {object} originalRow
	// //  * @param {number} rowIndex
	//  */
	// rowIdAccessor: PropTypes.func,
	// /**
	//  * Any Table options for react-table that you want to override the defaults used in the component
	//  */
	// rTableOptions: PropTypes.object,
	/**
	 * if true, the header of table is sticky
	 */
	isStickyHeader: PropTypes.bool,
	/**
	 * if true, a loader will be displyed, otherwise, no loader to display
	 */
	isLoading: PropTypes.bool,
	/**
	 * function to pick up the rows that will be highlight (e.g. row is selected)
	 * NB: if this function is not provided, row highlight is disabled
	 * @param {object} row. It is the row object returned by the table instance
	 * @returns {bool} if true, highlight the row, otherwise don't
	 *
	 * Example: (row) => row.isSelected
	 */
	rowHighlightPicker: PropTypes.func,
	// /**
	//  * If true, selection checkbox column is inserted as first column
	//  * NB: "select all" will select all rows even though not all rows are on the current page
	//  * 			the solution is using server side pagination, the client should only download data for the current page.
	//  */
	// enableSelection: PropTypes.bool,
	// /**
	//  * initial number of rows per page
	//  * NB: this component only supports server-side pagination
	//  */
	// initPageSize: PropTypes.oneOf([10, 25, 50, 100]).isRequired,

	// enableResizeColumns: PropTypes.bool,

	// pagination props
	/**
	 * function(event: object, page: number [0-indexed]) => void
	 */
	handleChangePage: PropTypes.func.isRequired,
	handleChangeRowsPerPage: PropTypes.func.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	disableRowsPerPage: PropTypes.bool,
	pageIndex: PropTypes.number.isRequired,
	/**
	 * Total number of rows
	 * NB: this component only supports server-side pagination
	 */
	totalNumRows: PropTypes.number.isRequired,

	// table props
	getTableProps: PropTypes.func.isRequired,
	headerGroups: PropTypes.array.isRequired,
	prepareRow: PropTypes.func.isRequired,
	page: PropTypes.array.isRequired,
};

EnhancedRTable.defaultProps = {
	isStickyHeader: false,
	isLoading: false,
	disableRowsPerPage: false,
};
/**
 * Notes when using this component:
 * - The "height" of the parent is required to make the position & scrolling working properly
 */

export default EnhancedRTable;
