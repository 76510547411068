import React from 'react';

import PropTypes from 'prop-types';
// import cx from 'classnames';

import { makeStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';
// MUI components
import { IconButton, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { toLocaleDate } from 'utils/generalHelper';

import { DraggableMUIDialog } from 'components';

import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		margin: 0,
		padding: theme.spacing(1, 2),
		cursor: 'move',
		fontSize: theme.typography.h6.fontSize,
		fontWeight: 600,
	},
	dialogCloseButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	dialogSubject: {
		width: 'calc(100% - 15px)',
	},
	dialogFooter: {
		backgroundColor: '#f5f5f5',
	},

	modalHeader: {
		padding: '9px 15px',
		borderBottom: '1px solid #eee',
	},
	modalHeaderTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',

		'& h2': {
			width: '90%',
			margin: '0px',
		},
	},
	modalHeaderBody: {
		marginTop: '6px',
		fontWeight: 'bold',
		fontSize: '12px',
	},
	messageBody: {
		maxHeight: '400px',
		// padding: '15px',
		overflow: 'auto',
		minHeight: '100px',
		fontSize: '12px',
		'& a': {
			textDecoration: 'none',
			color: '#0088cc',
			fontSize: '12px',
		},
	},

	messageFooter: {
		padding: '14px 15px 15px',
		marginBottom: '0',
		textAlign: 'right',
		backgroundColor: '#f5f5f5',
		borderTop: '1px solid #ddd',
		borderRadius: '0 0 6px 6px',
		boxShadow: 'inset 0 1px 0 #ffffff',
	},
	btn: {
		display: 'inline-block',
		// padding: '4px 12px',
		marginBottom: '0',
		// fontSize: '14px',
		lineHeight: '20px',
		borderRadius: '4px',
		textAlign: 'center',
		verticalAlign: 'middle',
		cursor: 'pointer',
		color: '#333333',
		border: '1px solid #cccccc',
	},
	btnClose: {
		display: 'inline-block',
		// padding: '4px 12px',
		borderRadius: '4px',
		marginLeft: '5px',
		// fontSize: '14px',
		lineHeight: '20px',
		textAlign: 'center',
		verticalAlign: 'middle',
		cursor: 'pointer',
		color: '#ffffff',
		border: 'none',
		// textShadow: '0 1px 1px rgb(255 255 255 / 75%)',
		backgroundColor: '#006dcc',
	},
}));

function UserMessagesPreview({ msgId, userMsgData, anchorEl, onClose, onSetMsgUnread, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();
	const dateTime = new Date(userMsgData.date);
	const msgTime = dateTime.getHours() + ':' + dateTime.getMinutes() + ':' + dateTime.getSeconds();

	const msgContentRaw = userMsgData.content;
	let msgContent = '';
	const regex = /''/gi;
	msgContent = msgContentRaw.replaceAll(regex, '"');
	const dialogSize = 'sm';

	return (
		<DraggableMUIDialog
			disableBackdropClick
			disableEscapeKeyDown
			open={Boolean(anchorEl)}
			maxWidth={dialogSize}
			fullWidth={true}
			scroll="paper"
			dragHandle={`.${classes.dialogTitle}`}
		>
			<DialogTitle className={classes.dialogTitle} component="div">
				<div className={classes.dialogSubject}>{userMsgData.subject}</div>
				<IconButton
					aria-label="Close"
					className={classes.dialogCloseButton}
					size="small"
					onClick={(e) => onClose(e)}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
				<div className={classes.modalHeaderBody}>
					Date: {userMsgData.date ? toLocaleDate(new Date(userMsgData.date)) : ''}{' '}
					{userMsgData.date ? msgTime : ''}
					<br></br>
					From: {userMsgData.senderName} {'<'}
					{userMsgData.senderEmail}
					{'>'} <br></br>
				</div>
			</DialogTitle>
			<DialogContent dividers>
				<div className={classes.messageBody} dangerouslySetInnerHTML={{ __html: msgContent }} />
			</DialogContent>
			<DialogActions className={classes.dialogFooter}>
				<button
					className={classes.btn}
					data-dismiss="modal"
					data-inboxmsgid=""
					id="inboxMessageMarkAsUnread"
					onClick={() => {
						onSetMsgUnread(msgId, { isRead: false });
						onClose();
					}}
				>
					{intl.formatMessage({
						id: 'pages.UserMessages.components.UserMessagesPreview.MsgStatus',
					})}
				</button>
				<button
					className={classes.btnClose}
					data-dismiss="modal"
					aria-hidden="true"
					onClick={(e) => onClose(e)}
				>
					{intl.formatMessage({ id: 'GENERAL.Close' })}
				</button>
			</DialogActions>
		</DraggableMUIDialog>
	);
}

UserMessagesPreview.propTypes = {
	userMsgData: PropTypes.shape({
		date: PropTypes.string.isRequired,
		subject: PropTypes.string.isRequired,
		senderEmail: PropTypes.string.isRequired,
		senderName: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
	}),
	msgId: PropTypes.string,
	anchorEl: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	onSetMsgUnread: PropTypes.func.isRequired,
};
UserMessagesPreview.defaultProps = {};
export default UserMessagesPreview;
