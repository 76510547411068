import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
// import Element from '../Elements/Element';

import { _, deepClone } from 'utils/libHelper';
import { roundDecimals } from 'utils/generalHelper';
import { getAngle, getCursor, getLength, degToRadian, getNewStyle } from './utils';
import { getOuterBoundsSelection } from 'utils/artwork/artUtilsWebUI';

// MUI components
import { IconButton, ClickAwayListener } from '@mui/material';

import { FileCopy as DuplicateIcon, Delete as DeleteIcon } from '@mui/icons-material';

// CSS
import { default as useStyles } from './DesignOverlayStyle.jsx';

import { ART_VARIABLES } from '../../Constants';

// get previous value
// function usePrevious(value) {
// 	const ref = useRef();
// 	useEffect(() => {
// 		ref.current = value;
// 	});
// 	return ref.current;
// }

function RotateIcon(props) {
	return (
		<svg {...props}>
			<path d="M12.378,10.467L15,6.489h-1.384c-0.26-3.254-2.961-5.752-6.297-5.752c-1.688,0-3.276,0.651-4.47,1.834 C1.657,3.754,1,5.327,1,7c0,3.453,2.835,6.263,6.32,6.263c1.425,0,2.77-0.461,3.891-1.33l0.315-0.244l-1.521-1.609l-0.262,0.191 c-0.706,0.516-1.546,0.787-2.423,0.787c-2.257,0-4.096-1.822-4.096-4.061c0-2.24,1.837-4.063,4.096-4.063 c2.094,0,3.811,1.532,4.063,3.551H9.759L12.378,10.467z"></path>
		</svg>
	);
}

const positions = ['n', 's', 'w', 'e', 'nw', 'ne', 'sw', 'se']; // using lowercase as for cursor in css

function DesignOverlay({
	// eslint-disable-next-line no-unused-vars
	children,
	// bounds,
	// selectedFields,
	selectedFieldIds,
	fields,
	zoom,
	showDot,
	showBorder,
	solidLine,
	resizable,
	onResizeStart,
	onResizeStop,
	onResizing,

	rotatable,
	onRotateStart,
	onRotateStop,
	onRotating,

	draggable,
	onDragStart,
	onDragStop,
	onDragging,

	showActions,
	handleDuplicateFields,
	handleDelFields,

	handleEleSelect,
	handleEleSelectClickAway,
	highlightField,

	// enablePreview,
}) {
	const [allFields, setAllFields] = useState([]);
	React.useEffect(() => {
		setAllFields(fields);
	}, [fields]);
	const selectedFields = allFields.filter((f) => selectedFieldIds.includes(f.id));

	const bounds = getOuterBoundsSelection(selectedFields);
	const classes = useStyles({
		zoom,
		bounds: bounds || { position: { width: 0, height: 0, left: 0, top: 0, angle: 0 } },
	}); // actualHeight: bounds ? bounds.position.height * zoom : 0 });

	// const [rotateAngle, setRotateAngle] = useState(field.position.angle || 0);
	const selectionWrapperRef = useRef();
	// const draggableHandlerId = genUUID();
	const isMultiSelection = selectedFields.length > 1;
	let _isMouseDown = false;

	// Rotate
	const startRotate = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (e.button !== 0) return; // only left click allowed
		const { clientX, clientY } = e;
		// const startAngle = bounds.position.angle;
		const rect = selectionWrapperRef.current.getBoundingClientRect();
		const center = {
			x: rect.left + rect.width / 2,
			y: rect.top + rect.height / 2,
		};
		const startVector = {
			x: clientX - center.x,
			y: clientY - center.y,
		};
		onRotateStart && onRotateStart();
		_isMouseDown = true;
		const onMove = _.throttle(
			(e) => {
				if (!_isMouseDown) return; // fix windows press win key during mouseup issue
				e.stopImmediatePropagation();
				e.preventDefault();
				const { clientX, clientY } = e;
				const rotateVector = {
					x: clientX - center.x,
					y: clientY - center.y,
				};
				const deltaAngle = Math.round(getAngle(startVector, rotateVector)); // angle diff to the original angle
				setAllFields(
					allFields.map((f) =>
						selectedFieldIds.includes(f.id)
							? {
									...f,
									position: {
										...f.position,
										angle: (f.position.angle + deltaAngle + 360) % 360,
									},
							  }
							: f
					)
				);
				onRotating && onRotating(selectedFieldIds, deltaAngle);
			},
			50,
			{ leading: false, trailing: true }
		);
		const onUp = (e) => {
			e.stopPropagation();
			e.preventDefault();
			document.removeEventListener('mousemove', onMove);
			document.removeEventListener('mouseup', onUp);
			if (!_isMouseDown) return;
			_isMouseDown = false;
			const { clientX, clientY } = e;
			const rotateVector = {
				x: clientX - center.x,
				y: clientY - center.y,
			};
			const deltaAngle = Math.round(getAngle(startVector, rotateVector)); // angle diff to the original angle
			setAllFields(
				allFields.map((f) =>
					selectedFieldIds.includes(f.id)
						? {
								...f,
								position: {
									...f.position,
									angle: (f.position.angle + deltaAngle + 360) % 360,
								},
						  }
						: f
				)
			);
			onRotateStop && onRotateStop(selectedFieldIds, deltaAngle);
		};
		document.addEventListener('mousemove', onMove);
		document.addEventListener('mouseup', onUp);
	};

	// resize
	const startResize = (e, cursor, position) => {
		e.stopPropagation();
		e.preventDefault();
		if (e.button !== 0) return; // only allow left click on mouse
		document.body.style.cursor = cursor; // set the body cursor to the same cursor in case the mouse is not over the resize handler

		const { clientX: startX, clientY: startY } = e;
		const originalSelectedFields = deepClone(selectedFields);
		// outer bounds rect
		const rect = {
			width: bounds.position.width * zoom,
			height: bounds.position.height * zoom,
			centerX: bounds.position.left * zoom + (bounds.position.width * zoom) / 2,
			centerY: bounds.position.top * zoom + (bounds.position.height * zoom) / 2,
			rotateAngle: bounds.position.angle,
		};
		// function to get resized fields
		const updatedSelectedFields = (e) => {
			// Note: following variables are based on current zoom
			const { clientX, clientY } = e;
			const deltaX = clientX - startX;
			const deltaY = clientY - startY;
			const alpha = Math.atan2(deltaY, deltaX);
			const deltaL = getLength(deltaX, deltaY);
			const isShiftKey = e.shiftKey;

			// Note: all variables are based on current zoom, inclucing ART_VARIABLES.minWidthResizeBox & ART_VARIABLES.minHeightResizeBox
			const beta = alpha - degToRadian(bounds.position.angle);
			const deltaW = deltaL * Math.cos(beta);
			const deltaH = deltaL * Math.sin(beta);
			const ratio = isMultiSelection || isShiftKey ? rect.width / rect.height : null;
			const newPosition = getNewStyle(
				position,
				{ ...rect },
				deltaW,
				deltaH,
				ratio,
				ART_VARIABLES.minWidthResizeBox,
				ART_VARIABLES.minHeightResizeBox
			);
			let resizedFields = originalSelectedFields.map((field) => {
				let {
					left: newFieldLeft,
					top: newFieldTop,
					width: newFieldWidth,
					height: newFieldHeight,
				} = newPosition;
				let fontsize = field.fontsize;
				if (isMultiSelection) {
					const scale = newPosition.width / rect.width;
					newFieldWidth = field.position.width * zoom * scale;
					newFieldHeight = field.position.height * zoom * scale;
					newFieldLeft =
						newPosition.left + (field.position.left * zoom - bounds.position.left * zoom) * scale;
					newFieldTop =
						newPosition.top + (field.position.top * zoom - bounds.position.top * zoom) * scale;
					// we also scale the font size (only for text field) on multiple selection
					if (field.type === 'text' && field.fontsize) {
						fontsize = roundDecimals(Number(field.fontsize) * scale, 0);
					}
				}

				let newField = {
					...field,
					fontsize,
					position: {
						top: newFieldTop / zoom,
						left: newFieldLeft / zoom,
						width: newFieldWidth / zoom,
						height: newFieldHeight / zoom,
						angle: field.position.angle, // the angle of the field is not changed during resizing
					},
				};
				return newField;
			});
			return resizedFields;
		};

		onResizeStart && onResizeStart(selectedFieldIds);
		_isMouseDown = true;
		const onMove = _.throttle(
			(e) => {
				if (!_isMouseDown) return; // patch: fix windows press win key during mouseup issue
				e.stopImmediatePropagation();
				e.preventDefault();
				const resizedFields = updatedSelectedFields(e);
				setAllFields(
					allFields.map((field) => _.find(resizedFields, (f) => f.id === field.id) || field)
				);

				onResizing && onResizing(resizedFields);
			},
			200,
			{ leading: false, trailing: true }
		);

		const onUp = (e) => {
			e.stopPropagation();
			e.preventDefault();
			document.body.style.cursor = 'auto';
			document.removeEventListener('mousemove', onMove);
			document.removeEventListener('mouseup', onUp);
			if (!_isMouseDown) return;
			_isMouseDown = false;
			const resizedFields = updatedSelectedFields(e);

			setAllFields(
				allFields.map((field) => _.find(resizedFields, (f) => f.id === field.id) || field)
			);
			onResizeStop && onResizeStop(resizedFields);
		};
		document.addEventListener('mousemove', onMove);
		document.addEventListener('mouseup', onUp);
	};

	// Drag
	const startDrag = (e) => {
		e.stopPropagation();
		e.preventDefault();
		let { clientX: startX, clientY: startY } = e;
		onDragStart && onDragStart(selectedFieldIds);
		_isMouseDown = true;
		const onMove = _.throttle(
			(e) => {
				if (!_isMouseDown) return; // patch: fix windows press win key during mouseup issue
				e.stopImmediatePropagation();
				const { clientX, clientY } = e;
				const deltaX = clientX - startX; // X coordination diff to the original position
				const deltaY = clientY - startY; // Y coordination diff to the original position
				setAllFields(
					allFields.map((f) =>
						selectedFieldIds.includes(f.id)
							? {
									...f,
									position: {
										...f.position,
										left: f.position.left + deltaX / zoom,
										top: f.position.top + deltaY / zoom,
									},
							  }
							: f
					)
				);
				onDragging && onDragging(selectedFieldIds, deltaX, deltaY);
				// startX = clientX;
				// startY = clientY;
			},
			100,
			{ leading: false, trailing: true }
		);
		const onUp = (e) => {
			e.stopPropagation();
			e.preventDefault();
			document.removeEventListener('mousemove', onMove);
			document.removeEventListener('mouseup', onUp);
			if (!_isMouseDown) return;
			_isMouseDown = false;
			const { clientX, clientY } = e;
			const deltaX = clientX - startX; // X coordination diff to the original position
			const deltaY = clientY - startY; // Y coordination diff to the original position
			setAllFields(
				allFields.map((f) =>
					selectedFieldIds.includes(f.id)
						? {
								...f,
								position: {
									...f.position,
									left: f.position.left + deltaX / zoom,
									top: f.position.top + deltaY / zoom,
								},
						  }
						: f
				)
			);
			onDragStop && onDragStop(selectedFieldIds, deltaX, deltaY);
		};
		document.addEventListener('mousemove', onMove);
		document.addEventListener('mouseup', onUp);
	};

	return (
		<ClickAwayListener onClickAway={handleEleSelectClickAway} /* mouseEvent="onMouseDown" */>
			<div className={classes.overlayWrapper} style={{ zIndex: ART_VARIABLES.ZINDEX_RESIZEBOX }}>
				<div className={classes.fieldBoxWrapper}>
					{allFields.map((f, idx) => {
						let isSelected = selectedFieldIds.includes(f.id);
						return (
							<React.Fragment key={f.id}>
								<div
									// key={f.id}
									style={{
										zIndex: idx + 1,
										width: f.position.width * zoom,
										height: f.position.height * zoom,
										left: f.position.left * zoom,
										top: f.position.top * zoom,
										transform: `rotate(${f.position.angle}deg)`,
										cursor: isSelected ? 'move' : null,
									}}
									className={cx(classes.fieldBox, {
										[classes.fieldSelected]: isSelected,
										[classes.fieldBoxNoBGColor]: !isSelected && !highlightField,
									})}
									onMouseDown={(e) => {
										if (document.activeElement) {
											document.activeElement.blur();
										}
										e.nativeEvent.stopImmediatePropagation();
										if (draggable) startDrag(e);
									}}
									onMouseUp={(e) => {
										// e.stopPropagation();
										// e.preventDefault();
										if (_isMouseDown) {
											// there was no dragging, as _isMouseDown was set to true onMouseDown and wasn't reset to false
											// we trigger element (field) selection here
											_isMouseDown = false;
											handleEleSelect({ field: f }, e.ctrlKey || e.metaKey);
										}
									}}
									// onClick={e => {
									// 	handleEleSelect({ field: f }, e.ctrlKey || e.metaKey);
									// }}
								>
									{
										// 	<rect
										// 	width={`${f.position.width * zoom}px`}
										// 	height={`${f.position.height * zoom}px`}
										// 	className={cx(classes.fieldBoxRectBorder)}
										// ></rect>
									}
								</div>
								{
									// 	enablePreview && (
									// 	<Element
									// 		// key={f.id}
									// 		type={f.type}
									// 		zoom={zoom}
									// 		field={f}
									// 		// zIndex={ART_VARIABLES.ZINDEX_FIELDPREVIEW + idx}
									// 	/>
									// )
								}
							</React.Fragment>
						);
					})}
				</div>
				{bounds && showBorder && (
					<svg
						// id={draggableHandlerId}
						ref={selectionWrapperRef}
						width={bounds.position.width * zoom}
						height={bounds.position.height * zoom}
						// onMouseDown={e => {
						// 	if (document.activeElement) {
						// 		document.activeElement.blur();
						// 	}
						// 	e.nativeEvent.stopImmediatePropagation();
						// 	if (draggable) startDrag(e);
						// }}
						style={{
							position: 'absolute',
							left: bounds.position.left * zoom,
							top: bounds.position.top * zoom,
							width: bounds.position.width * zoom,
							height: bounds.position.height * zoom,
							transform: `rotate(${bounds.position.angle}deg)`,
							cursor: draggable ? 'move' : null,
							zIndex: 0,
						}}
						className={cx({ [classes.visiable]: showBorder, [classes.hidden]: !showBorder })}
					>
						<rect
							width={bounds.position.width * zoom}
							height={bounds.position.height * zoom}
							className={cx(classes.svgRectBorder, { [classes.svgRectBorderSolid]: solidLine })}
						></rect>
					</svg>
				)}
				{bounds && (
					<div
						className={classes.resizeBoxWrapper}
						style={{
							left: bounds.position.left * zoom,
							top: bounds.position.top * zoom,
							transform: `rotate(${bounds.position.angle}deg)`,
							transformOrigin: `${bounds.position.width * 0.5 * zoom}px ${
								bounds.position.height * 0.5 * zoom
							}px`,
							zIndex: 999,
						}}
						// onMouseUp={e => {
						// 	console.log(`mouse up in resize box. _isMouseDown=${_isMouseDown}`);
						// }}
					>
						{
							// 	showBorder && (
							// 	<svg
							// 		id={draggableHandlerId}
							// 		width={bounds.position.width * zoom}
							// 		height={bounds.position.height * zoom}
							// 		style={{ position: 'absolute' }}
							// 		className={cx({ [classes.visiable]: showBorder, [classes.hidden]: !showBorder })}
							// 	>
							// 		<rect
							// 			width={bounds.position.width * zoom}
							// 			height={bounds.position.height * zoom}
							// 			className={cx(classes.svgRectBorder, { [classes.svgRectBorderSolid]: solidLine })}
							// 		></rect>
							// 	</svg>
							// )
						}
						{showActions && (
							<div
								className={classes.actionsWrapper}
								style={{ left: bounds.position.width * zoom }}
							>
								<IconButton
									className={classes.actionButton}
									color="inherit"
									onClick={(e) => {
										e.stopPropagation();
										handleDuplicateFields(selectedFields);
									}}
									style={{ transform: `rotate(${-bounds.position.angle}deg)` }}
								>
									<DuplicateIcon className={classes.actionButtonIcon} />
								</IconButton>
								<IconButton
									className={classes.actionButton}
									color="inherit"
									onClick={(e) => {
										e.stopPropagation();
										handleDelFields(selectedFields);
									}}
									style={{ transform: `rotate(${-bounds.position.angle}deg)` }}
								>
									<DeleteIcon className={classes.actionButtonIcon} />
								</IconButton>
							</div>
						)}
						{rotatable && (
							<div
								style={{ left: bounds.position.width * 0.5 * zoom, top: 0, position: 'absolute' }}
							>
								<svg
									width="1px"
									height="25px"
									style={{ position: 'absolute', left: 0, top: -25 }}
									className={cx({ [classes.visiable]: showDot, [classes.hidden]: !showDot })}
								>
									<rect className={classes.svgRotateLine}></rect>
								</svg>
								<div
									onMouseDown={startRotate}
									className={cx(classes.rotateContainer, {
										[classes.visiable]: showDot,
										[classes.hidden]: !showDot,
									})}
									style={{ left: 0, top: -35 }}
								>
									<RotateIcon
										width="14"
										height="14"
										viewBox="0 0 15 14"
										className={classes.rotateIcon}
									/>
								</div>
								<div
									className={cx(classes.rotateTextContainer, {
										[classes.visiable]: showDot,
										[classes.hidden]: !showDot,
									})}
									style={{
										transform: `rotate(${-bounds.position.angle}deg)`,
										left: 0,
										top: -65,
									}}
								>
									{bounds.position.angle}°
								</div>
							</div>
						)}
						{resizable &&
							positions.map((pos) => {
								if (isMultiSelection && pos.length === 1) {
									// hide side handler for multiple selections
									return null;
								}
								const cursor = `${getCursor(bounds.position.angle, pos)}-resize`;
								return (
									<div
										key={pos}
										className={cx(
											classes[pos.length === 1 ? `${pos}SquareDot` : `${pos}CircleDot`],
											{
												[classes.squareDot]: pos.length === 1,
												[classes.circleDot]: pos.length === 2,
												[classes.visiable]: showDot,
												[classes.hidden]: !showDot,
											}
										)}
										style={{ cursor }}
										onMouseDown={(e) => startResize(e, cursor, pos)}
										onMouseUp={() => {
											// e.stopPropagation();
											// e.preventDefault();
											if (_isMouseDown) {
												// there was no resizing, as _isMouseDown was set to true onMouseDown and wasn't reset to false
												_isMouseDown = false;
											}
										}}
									></div>
								);
							})}
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
}

DesignOverlay.propTypes = {
	// type: PropTypes.oneOf(['text', 'video', 'image', 'pdf', 'barcode']).isRequired,
	// bounds: PropTypes.object,
	// selectedFields: PropTypes.array.isRequired,
	selectedFieldIds: PropTypes.array.isRequired,
	fields: PropTypes.array.isRequired,
	zoom: PropTypes.number.isRequired, // double/float. the current zoom. e.g. 0.1, 0.25, 1, 1.35, 2, etc.
	showDot: PropTypes.bool,
	showBorder: PropTypes.bool,
	solidLine: PropTypes.bool,

	resizable: PropTypes.bool,
	/**
	 *	usage: onResizeStart(fieldIds)
	 * @param {array of field ID string} fieldIds. ID of fields that will be resized (selected field IDs)
	 */
	onResizeStart: PropTypes.func,
	/**
	 *	usage: onResizeStop(resizedFields)
	 * @param {array of field object} resizedFields. The full dataset of the updated resized fields that were resized (selected fields)
	 */
	onResizeStop: PropTypes.func,
	/**
	 *	usage: onResizing(resizedFields)
	 * @param {array of field object} resizedFields. The full dataset of the updated resized fields that are being resized (selected fields)
	 */
	onResizing: PropTypes.func,

	rotatable: PropTypes.bool,
	/**
	 *	usage: onRotateStart(fieldIds)
	 * @param {array of field ID string} fieldIds. ID of fields that will be rotated (selected field IDs)
	 */
	onRotateStart: PropTypes.func,
	/**
	 *	usage: onRotateStop(fieldIds, deltaAngle)
	 * @param {array of field ID string} fieldIds. ID of fields that were rotated (selected field IDs)
	 * @param {Number} deltaAngle. The angle diff to oroginal angle under the current zoom
	 */
	onRotateStop: PropTypes.func,
	/**
	 *	usage: onRotating(fieldIds, deltaAngle)
	 * @param {array of field ID string} fieldIds. ID of fields that are being rotated (selected field IDs)
	 * @param {Number} deltaAngle. The angle diff to oroginal angle under the current zoom
	 */
	onRotating: PropTypes.func,

	draggable: PropTypes.bool,
	/**
	 *	usage: onDragStop(fieldIds)
	 * @param {array of field ID string} fieldIds. ID of fields that will be dragged (selected field IDs)
	 */
	onDragStart: PropTypes.func,
	/**
	 *	usage: onDragStop(fieldIds, deltaX, deltaY)
	 * @param {array of field ID string} fieldIds. ID of fields that were dragged (selected field IDs)
	 * @param {Number in pixel} deltaX. X coordination diff to oroginal position in the current zoom
	 * @param {Number in pixel} deltaY. Y coordination diff to oroginal position in the current zoom
	 */
	onDragStop: PropTypes.func,
	/**
	 *	usage: onDragging(fieldIds, deltaX, deltaY)
	 * @param {array of field ID string} fieldIds. ID of fields that are being dragged (selected field IDs)
	 * @param {Number in pixel} deltaX. X coordination diff to oroginal position in the current zoom
	 * @param {Number in pixel} deltaY. Y coordination diff to oroginal position in the current zoom
	 */
	onDragging: PropTypes.func,

	showActions: PropTypes.bool,
	handleDuplicateFields: PropTypes.func,
	handleDelFields: PropTypes.func,

	handleEleSelect: PropTypes.func.isRequired,
	handleEleSelectClickAway: PropTypes.func.isRequired,
	highlightField: PropTypes.bool,
	// enablePreview: PropTypes.bool.isRequired,
};

DesignOverlay.defaultProps = {
	// bounds: null,
	showDot: false,
	showBorder: false,
	solidLine: false,

	resizable: false,
	rotatable: false,
	draggable: false,

	showActions: false,
	highlightField: false,
};

export default DesignOverlay;
