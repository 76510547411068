import {
	FETCH_USERDATA,
	FETCH_USERDATA_SUCCESS,
	FETCH_USERDATA_FAILED,
	FETCH_USERDATABYGROUP_SUCCESS,
	RESET_USERDATABYGROUP,
} from 'redux/actions';

const initState = {
	fetchUserDataStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	userData: {}, // this is the logged in user data, should never be reset
	userDataByGroup: {},
};

function usermanager(state = initState, action) {
	switch (action.type) {
		case FETCH_USERDATA:
			return {
				...state,
				fetchUserDataStatus: 'PROCESSING',
			};
		case FETCH_USERDATA_SUCCESS: {
			let userDataResponse = action.result.data;
			return {
				...state,
				fetchUserDataStatus: 'OK',
				userData: {
					/**
					 * Only required user data is stored to save memory usage
					 * For the details of each data field, see the document in /users/{userId} endpoint in usermanager api
					 */
					adminId: userDataResponse.adminId,
					adminGroupId: userDataResponse.adminGroupId,
					adminGroupName: userDataResponse.adminGroupName,
					adminUserIdInAdminGroup: userDataResponse.adminUserIdInAdminGroup,
					isAdminInAdminGroup: userDataResponse.isAdminInAdminGroup,
					email: userDataResponse.email,
					firstname: userDataResponse.firstname,
					lastname: userDataResponse.lastname,
					company: userDataResponse.company,
					status: userDataResponse.status,
					allowLiveUpload: userDataResponse.allowLiveUpload,
					screenplayer: userDataResponse.screenplayer,
					accessibleFileCategories: userDataResponse.accessibleFileCategories,
					userGroups: userDataResponse.userGroups,
					adminUsers: userDataResponse.adminUsers,
					startleLoginUrl: userDataResponse.startleLoginUrl,
				},
			};
		}
		case FETCH_USERDATABYGROUP_SUCCESS: {
			let userDataResponse = action.result.data;
			return {
				...state,
				fetchUserDataStatus: 'OK',
				userDataByGroup: userDataResponse,
			};
		}
		case RESET_USERDATABYGROUP: {
			return { ...state, userDataByGroup: {} };
		}
		case FETCH_USERDATA_FAILED:
			return {
				...state,
				fetchUserDataStatus: 'FAILED',
			};
		default:
			return state;
	}
}

export default usermanager;
