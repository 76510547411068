export const OPEN_GLOBAL_DIALOG = 'OPEN_GLOBAL_DIALOG';
export const RESET_GLOBAL_DIALOG = 'RESET_GLOBAL_DIALOG';

/*
 * parameter format
 	{
		size: '', // enum: 'xs', 'sm', 'md', 'lg', 'xl'
		title: '',
		content: '',
		confirmCB: null, // in case the confirmCB is not null, there will be a "Confirm" button in the dialog
		onClose: null,
	}
 */
export const openGlobalDialog = ({ size, title, content, confirmCB, onClose }) => ({
	type: OPEN_GLOBAL_DIALOG,
	size,
	title,
	content,
	confirmCB,
	onClose,
});
export const resetGlobalDialog = () => ({
	type: RESET_GLOBAL_DIALOG,
});
