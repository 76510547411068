import {
	FETCH_SCREENMANAGER_SCREENBOARD,
	FETCH_SCREENMANAGER_SCREENBOARD_SUCCESS,
	FETCH_SCREENMANAGER_SCREENBOARD_FAIL,
} from 'redux/actions';
import config from 'config';

const screenApiBaseUrl = config.screenAPI.baseUrl;
const authHeaderKey = config.screenAPI.authHeaderKey;

export const fetchScreenManagerScreenboard = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${screenApiBaseUrl}/report/players`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		timeout: 300000,
	};
	return dispatch({
		types: [
			FETCH_SCREENMANAGER_SCREENBOARD,
			FETCH_SCREENMANAGER_SCREENBOARD_SUCCESS,
			FETCH_SCREENMANAGER_SCREENBOARD_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};
