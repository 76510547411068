import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

import { Dialog, Paper } from '@mui/material';

function PaperComponent({ dragHandle, ...rest }) {
	return (
		<Draggable handle={dragHandle || undefined}>
			<Paper {...rest} />
		</Draggable>
	);
}

const DraggableMUIDialog = ({
	children,
	disableBackdropClick,
	dragHandle,
	PaperProps,
	onClose,
	...rest
}) => (
	<Dialog
		disableEscapeKeyDown
		scroll="paper"
		PaperProps={{ dragHandle, ...PaperProps }}
		PaperComponent={PaperComponent}
		onClose={(event, reason) => {
			if ((!disableBackdropClick || reason !== 'backdropClick') && typeof onClose === 'function') {
				onClose(event, reason);
			}
		}}
		{...rest}
	>
		{children}
	</Dialog>
);

DraggableMUIDialog.propTypes = {
	/**
	 * Specifies a selector to be used as the handle that initiates drag
	 * If not specified, the whole dialog is draggable
	 * Example: '.handle', [id="handle"]
	 * See "handle" props in react-draggable for details. Ref: https://www.npmjs.com/package/react-draggable
	 * NB: The children is suggested (but don't have to) to be MUI dialog children components, e.g. DialogTitle, DialogContent.
	 */
	dragHandle: PropTypes.string,
	PaperProps: PropTypes.object,
	disableBackdropClick: PropTypes.bool,
	onClose: PropTypes.func,
};

DraggableMUIDialog.defaultProps = { PaperProps: {}, disableBackdropClick: false };

export default DraggableMUIDialog;
