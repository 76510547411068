import React from 'react';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import { retrieveGridTableData, sanitizeFieldOutputValue } from 'utils/artwork/artUtilsCommon';
import { isProductPickerField, getUploadFileTypesByFieldType } from 'utils/artwork/artUtilsWebUI';

import { _ } from 'utils/libHelper.js';
import { Rnd } from 'react-rnd';

import GridInputTable from '../GridInputTable/GridInputTable';

import { RnDDialog, MediafileMultiChoosers } from 'components';
// MUI components
import {
	InputLabel,
	Tooltip,
	IconButton,
	Fab,
	Button,
	Collapse,
	List,
	// Menu,
	// MenuItem,
	Typography,
	// TextField,
	CircularProgress,
} from '@mui/material';

import ToggleButton from '@mui/material/ToggleButton';
import Autocomplete from '@mui/material/Autocomplete';
// MUI ICONs
import {
	ImageSearch as ImageSearchIcon,
	Clear as ClearIcon,
	FormatAlignLeft as FormatAlignLeftIcon,
	FormatAlignCenter as FormatAlignCenterIcon,
	FormatAlignRight as FormatAlignRightIcon,
	Loop as VideoLoopIcon,
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	Crop as ImageCropIcon,
	// TextFields as TextFieldIcon,
	// Photo as ImageFieldIcon,
	// MovieCreation as VideoFieldIcon,
	// ExpandMore as ExpandMoreIcon,
	// SaveAlt as PrintToOutputTemplateIcon,
} from '@mui/icons-material';

// import { green, red, grey } from '@mui/material/colors';
import {
	FormatVerticalAlignTop as FormatVerticalAlignTopIcon,
	FormatVerticalAlignCenter as FormatVerticalAlignCenterIcon,
	FormatVerticalAlignBottom as FormatVerticalAlignBottomIcon,
	FormatAlignJustify as FormatAlignJustifyIcon,
	Cancel as ResetIcon,
	// Download as DownloadPdfIcon,
	// Export as DownloadSVGIcon,
	Play as PlayAnimationIcon,
	AutoFix as ImageClipIcon,
	// PdfBox as PdfFieldIcon,
	// Barcode as BarcodeIcon,
} from 'mdi-material-ui';

import {
	StyledToggleButtonGroup,
	StyledOutlinedTextField,
	SimplifiedOutlinedTextField,
	StyledContainedButton,
	StyledOutlinedTextFieldSelection,
	DividerVer,
} from '../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';
import { fetchSpreadsheetDetailsById, fetchSpreadsheetContentById } from 'restful';

const panelTitleHeight = 40;
const useStyles = makeStyles((theme) => ({
	previewControlPanelWrapper: {
		// position: 'absolute',
		// top: 20,
		// right: 60,
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignContent: 'flex-start',
		alignItems: 'flex-start',
		// userSelect: 'none',
		// transition: 'visibility 150ms',
		borderRadius: 5,
		width: '100%', //300,
		// height: 400,
		// minHeight: 300,
		height: '100%',
		// maxHeight: 540,
		backgroundColor: '#fff',
		boxShadow: '2px 0px 5px rgba(0,0,0,0.33), -2px -2px 5px rgba(0,0,0,0.33)',
		overflow: 'hidden',
		// opacity: 1,
		zIndex: 0,
	},
	panelTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		position: 'relative',
		color: ART_VARIABLES.cssStyles.previewControlPanelTitleColor,
		backgroundColor: ART_VARIABLES.cssStyles.previewControlPanelTitleBGColor,
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 16,
		// lineHeight: 20,
		textTransform: 'uppercase',
		userSelect: 'none',
		whiteSpace: 'nowrap',
		// overflow: 'hidden',
		cursor: 'move',
		height: panelTitleHeight,
		// borderTopLeftRadius: 4,
		// borderTopRightRadius: 4,
		padding: '10px 15px',
	},
	panelTitleButton: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 15,
		// height: 20,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
	},
	// resetButton: {
	// 	// backgroundColor: theme.palette.warning.main,
	// 	// color: theme.palette.danger.main,
	// 	position: 'fixed',
	// 	right: 5,
	// 	bottom: 15,
	// 	opacity: 0.5,
	// 	zIndex: 100,
	// 	'&:hover': {
	// 		opacity: 1,
	// 	},
	// },
	floatButtons: {
		// backgroundColor: theme.palette.warning.main,
		// color: theme.palette.danger.main,
		position: 'fixed',
		right: 0,
		bottom: 0,
		transform: `translateX(100%)`,
		// opacity: 0.5,
		// zIndex: 100,
		display: 'flex',
		flexDirection: 'column',
		// backgroundColor: 'rgba(0,0,0, 0.1)',
		// width: 'calc(100% - 30px)',
		// borderRadius: 4,
		padding: theme.spacing(0.5), //`0px ${theme.spacing(0.5)}`,
		// '&:hover': {
		// 	opacity: 1,
		// },
	},
	floatButton: {
		margin: theme.spacing(0.5), //`${theme.spacing(0.5)} 0px`,
		width: 30,
		height: 30,
		minHeight: 'unset',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	panelRoot: {
		transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
	},
	panelClosed: {
		visibility: 'hidden',
	},
	panelContent: {
		width: '100%',
		// minHeight: 260,
		height: `calc(100% - ${panelTitleHeight}px)`,
		color: ART_VARIABLES.cssStyles.previewControlPanelContentColor,
		backgroundColor: ART_VARIABLES.cssStyles.previewControlPanelContentBGColor,
		// maxHeight: 500,
		// overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		overflowX: 'hidden',
		overflowY: 'auto',
		// alignItems: 'flex-start',
		// justifyContent: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		fontSize: ART_VARIABLES.cssStyles.previewControlPanelFontSize,

		// paddingTop: theme.spacing(1),
		// paddingBottom: theme.spacing(1),
	},
	panelCollapsedStyle: {
		display: 'none',
	},
	// scrollWrapper: {
	// 	width: '100%',
	// 	minHeight: `calc(100% - 48px)`,
	// 	color: ART_VARIABLES.cssStyles.previewControlPanelContentColor,
	// 	backgroundColor: ART_VARIABLES.cssStyles.previewControlPanelContentBGColor,
	// 	// flex: '1 0 auto',
	// 	paddingTop: theme.spacing(1.5),
	// 	paddingBottom: theme.spacing(2),
	// 	// '&:before': {
	// 	// 	content: '""',
	// 	// },
	// 	// '&:after': {
	// 	// 	content: '""',
	// 	// 	paddingTop: theme.spacing(1),
	// 	// },
	// },
	textFieldWrapper: {
		margin: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
		width: `calc(100% - calc(${theme.spacing(1.5)} * 2))`,
		color: 'inherit',
	},
	hightlightBorder: {
		borderColor: `#647BFE !important`, // `rgba(229,136,0, 0.6) !important`,
		border: `2px solid !important`,
		'&:hover': {
			borderColor: `#4A62E4 !important`, // `rgba(229,136,0, 1) !important`,
			border: `2px solid !important`,
		},
	},
	hightlightBG: {
		backgroundColor: 'rgba(232, 217, 104, 0.25)',
	},
	productPickerTextField: {
		color: 'inherit',
		fontSize: 'inherit',
		backgroundColor: 'rgba(254, 255, 133, 1)', // '#feff85',
	},
	inheritFontStyle: {
		color: 'inherit',
		fontSize: 'inherit',
	},
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
		width: `calc(100% - calc(${theme.spacing(1.5)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		// width: '100%',
		padding: 'unset', //`0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		margin: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
		width: `calc(100% - calc(${theme.spacing(1.5)} * 2))`,
		color: 'inherit',
	},

	toggleGroupSmall: {
		width: 26,
		height: 30,
	},

	fontsizeSelection: {
		margin: 'unset',
		width: '100%',
	},

	squareIconButton: {
		padding: 2,
		// color: 'inherit',
		margin: '0px 2px',
		borderRadius: 4,
		border: `1px solid rgba(0,0,0,0.15)`,
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
			border: `1px solid rgba(0,0,0,0.3)`,
		},
	},
	squareIconButtonActive: {
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
	},

	DividerVerStyle: {
		margin: `0px 8px`,
	},
	disableClick: {
		pointerEvents: 'none',
		opacity: 0.5,
	},

	contentWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		// paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	groupContentWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	groupTitleButton: {
		padding: '0px 12px',
		// fontSize: '1.0rem',
		fontWeight: 600,
		borderTop: '1px solid rgba(0,0,0,0.2)',
		// borderBottom: '1px solid rgba(0,0,0,0.2)',
		borderRadius: 0,
		minHeight: 40,
		textTransform: 'unset',
		backgroundColor: 'rgba(74, 74, 74, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(74, 74, 74, 0.2)',
		},
	},
	groupTitleButtonExpanded: {
		backgroundColor: 'rgba(74, 74, 74, 0.15)',
	},
	groupTitleButtonLabel: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	fieldPreviewWrapper: {
		width: '80%',
		margin: '0px auto',
		height: 120,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	gridInputTableWrapper: {
		width: '100%',
		padding: theme.spacing(1.5),
	},
	gridInputTableOnUDFontsize: {
		paddingTop: theme.spacing(1),
	},
}));

/**
 * check if elem is partialy in container's view. NOTE: this is not a common solution, it only works in this file
 * @param {Dom} container
 * @param {Dom} elem
 * @param {Number} minPart. Min percentage of the elem height in the container view
 */
const isVertPartialInView = (container, elem, minPart = 0.5) => {
	let containerTop = container.scrollTop,
		containerBottom = containerTop + container.clientHeight;
	let parentOffsetTop = elem.offsetParent ? elem.offsetParent.offsetTop : 0;
	let elemTop = elem.offsetTop + parentOffsetTop,
		elemBottom = elemTop + elem.clientHeight;

	return (
		(elemTop > containerTop &&
			elemTop < containerBottom &&
			(containerBottom - elemTop) / elem.clientHeight >= minPart) ||
		(elemBottom > containerTop &&
			elemBottom < containerBottom &&
			(elemBottom - containerTop) / elem.clientHeight >= minPart)
	);
};

function PreviewControlPanel({
	open,
	mode,
	selectedFieldIds,
	groups,
	fields,
	artworkExtra,
	userData,
	// fieldsPreviewValue,
	// setFieldsPreviewValue,
	resetFieldInputData,
	setFieldInputDataById,
	setTemplateBGInputData,
	fieldOutputData,
	templateBGOutputDataWithSettings,
	templateDimension,
	handleImageCrop,
	handleImageClip,
	handleFieldFocus,
	// handleSaveToPdfAndSVG,
	// handleExportSVG,
	// handlePrintToOutputTemplate,
	// handleExportSVGToPdfByImg,
	// handleExportToPdfByHighResImg,
	// handleExportSVGToImg,
	handleResetAnimation,
	disableUserDefined,
	disableTitle,
	handleProductPickerSelection,
	rndProps,
	rndClassName,
	expandAllGroups,
	// onClose,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	// const selectedField =
	// 	selectedFieldIds.length === 1 ? _.find(fields, f => f.id === selectedFieldIds[0]) || {} : {};
	const selectedField = React.useMemo(
		() =>
			selectedFieldIds.length === 1
				? _.find(fields, (f) => f.id === selectedFieldIds[0]) || {}
				: {},
		[fields, selectedFieldIds]
	);
	// "resetNum" is used to forcely re-render when resetting preview data
	// const [resetNum, setResetNum] = React.useState(0);
	const [panelExpand, setPanelExpand] = React.useState(true);
	// const [selectedField, setSelectedField] = React.useState(originalSelectedField);
	// control Expand/Collapse of group
	const [groupPanelExpand, setGroupPanelExpand] = React.useState(
		groups.length === 1 ? { name: groups[0].name } : {}
	);
	// field where user wants to choose mediafile
	const [fieldToChooseMediafile, setFieldToChooseMediafile] = React.useState(null);
	// product picker open/close controller
	const [openProductPicker, setOpenProductPicker] = React.useState(false);
	const [productPickerKeyword, setProductPickerKeyword] = React.useState('');
	const panelContentRef = React.useRef(null); // scrollable div container
	// selectedFieldElemRef is the ref of the selected field element
	const selectedFieldElemRef = React.useRef(null);
	const [spreadSheetOptions, setSpreadSheetOptions] = React.useState({});
	const RndRef = React.useRef(null);

	React.useEffect(() => {
		if (selectedField.id) {
			setPanelExpand((old) => {
				if (!old) {
					let rootElem = RndRef.current.getSelfElement();
					RndRef.current.updateSize({
						width: rootElem.offsetWidth,
						height: '60%', // set height to 60% to open the panel
					});
					return true;
				} else return old;
			});
		}
	}, [selectedField.id]);

	React.useEffect(() => {
		// apply "open" condition is to prevent unnecessary code execution, can be removed if scrollIntoView not functioning well
		if (!open || !panelExpand) return;

		if (selectedField.id) {
			setGroupPanelExpand({ name: selectedField.groupName }); // only trigger by changing selectedField

			// we use <Collapse /> to hold the field list, it takes about 500ms to expand, so the field element can only be scrollIntoView or focused after the <Collapse /> is expanded
			setTimeout(() => {
				// auto focus to <input> for text field in preview mode, not to its root <div> element
				// In design mode, auto focus on "text" field is conflicted to undo/redo as a text field could be focused by undo and ctrl+z no longer working in that case
				if (selectedFieldElemRef.current) {
					if (mode === 'preview' && selectedField.type === 'text') {
						// selectedFieldElemRef of text is input field, not its root <div> element, hence can't use isVertPartialInView function
						selectedFieldElemRef.current.focus();
						// console.log(`focus to ${selectedField.id}`);
					} else {
						if (!isVertPartialInView(panelContentRef.current, selectedFieldElemRef.current))
							selectedFieldElemRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start',
								inline: 'nearest',
							});
					}
				}
			}, 500);
		}
	}, [mode, open, panelExpand, selectedField.groupName, selectedField.id, selectedField.type]);

	// React.useEffect(() => {
	// 	// apply "open" condition is to prevent unnecessary code execution, can be removed if scrollIntoView not functioning well
	// 	if (!open) return;
	// 	if (!originalSelectedField) {
	// 		setSelectedField(null);
	// 		return;
	// 	}
	// 	if (
	// 		!selectedField ||
	// 		(originalSelectedField && selectedField.id !== originalSelectedField.id)
	// 	) {
	// 		setSelectedField(originalSelectedField);
	// 		if (originalSelectedField) {
	// 			setGroupPanelExpand({ name: originalSelectedField.groupName });
	// 		}
	// 		// we use <Collapse /> to hold the field list, it takes about 500ms to expand, so the field element can only be scrollIntoView or focused after the <Collapse /> is expanded
	// 		setTimeout(() => {
	// 			// TODO: auto focus on "text" (input) field in preview mode. In design mode, auto focus on "text" field is conflicted to undo/redo as a text field could be focused by undo and ctrl+z no longer working in that case
	// 			if (selectedFieldElemRef.current && selectedField) {
	// 				// if (selectedField.type !== 'text') {
	// 				selectedFieldElemRef.current.scrollIntoView({
	// 					behavior: 'auto',
	// 					block: 'start',
	// 					inline: 'nearest',
	// 				});
	// 				// selectedFieldElemRef.current.scrollIntoView(true);
	// 				// } else {
	// 				// 	selectedFieldElemRef.current.focus();
	// 				// }
	// 			}
	// 		}, 500);
	// 	}
	// }, [open, originalSelectedField, selectedField]);

	/**
	 * Get mediafile chooser filters for template background
	 * NB: when designer chooses default mediafile for a field, we ONLY use filters when mediafile origin is from 'category' or 'admin_lightbox'
	 * @param {object} templateBGSettings. The template background object.
	 * @param {object} artTemplateDimension. Artwork template dimension
	 * @param {string} filterCase. enum ['filelibrary', 'myfiles'].
	 *
	 * @return {object} filters. Filters that are defined in POST /mediafiles endpoint, except "keywords"
	 */
	const getTemplateBGFiltersForFilemanager = (
		templateBGSettings,
		artTemplateDimension,
		filterCase = ''
	) => {
		let filters = {};
		filters.fileExt = ['PDF', 'SVG'];
		filters.fileWidth = artTemplateDimension.width;
		filters.fileHeight = artTemplateDimension.height;
		if (filterCase === 'filelibrary') {
			let originChoiceId =
				templateBGSettings.mediafileOriginChoice.length > 0
					? templateBGSettings.mediafileOriginChoice[
							templateBGSettings.mediafileOriginChoice.length - 1
					  ].id.toString() || null
					: null;
			if (templateBGSettings.mediafileOrigin === 'category' && originChoiceId) {
				filters.categories = [originChoiceId];
			} else if (templateBGSettings.mediafileOrigin === 'admin_lightbox' && originChoiceId) {
				filters.lightboxes = [originChoiceId];
			} else if (templateBGSettings.mediafileOrigin === 'user_files') {
				filters.createdByUIDs = [userData.uid];
			}
		} else if (filterCase === 'myfiles') {
			filters.createdByUIDs = [userData.uid];
		}

		return filters;
	};

	/**
	 * Get filters by artwork field for calling /mediafiles endpoint in filemanager api
	 * @param {object} field. The field object.
	 * @param {string} filterCase. enum ['filelibrary', 'myfiles'].
	 *
	 * @return {object} filters. Filters that are defined in POST /mediafiles endpoint, except "keywords"
	 */
	const getFieldFiltersForFilemanager = (field, filterCase = '') => {
		let filters = {};
		switch (field.type) {
			case 'image':
			case 'pdf':
			case 'video': {
				// filters.fileTypeGroup =
				// 	field.type === 'image' ? ['image'] : field.type === 'pdf' ? ['svg'] : ['video'];
				if (field.type === 'pdf') {
					filters.isCreatedArtworkSVG = true;
				} else {
					filters.fileTypeGroup = field.type === 'image' ? ['image'] : ['video'];
				}

				if (filterCase === 'filelibrary') {
					// originchoice can only be one of the three
					let originChoice =
						field.imageOriginChoice || field.pdfOriginChoice || field.videoOriginChoice;
					let choiceId =
						originChoice.length > 0
							? originChoice[originChoice.length - 1].id.toString() || null
							: null;
					let origin = field.imageOrigin || field.pdfOrigin || field.videoOrigin;
					if (origin === 'category' && choiceId) {
						filters.categories = [choiceId];
					} else if (origin === 'admin_lightbox' && choiceId) {
						filters.lightboxes = [choiceId];
					} else if (origin === 'user_files') {
						filters.createdByUIDs = [userData.uid];
					}
				} else if (filterCase === 'myfiles') {
					filters.createdByUIDs = [userData.uid];
				}

				// switch (field.imageOrigin || field.pdfOrigin || field.videoOrigin) {
				// 	case 'category':
				// 		if (filterCase === 'filelibrary') {
				// 			filters.categories = [choiceId];
				// 		}
				// 		break;
				// 	case 'admin_lightbox':
				// 		if (filterCase === 'filelibrary') {
				// 			filters.lightboxes = [choiceId];
				// 		}
				// 		break;
				// 	case 'user_files':
				// 		// origin 'user_files' is only applicable to 'myfiles' cases
				// 		if (filterCase === 'myfiles') {
				// 			filters.createdByUIDs = [userData.uid];
				// 		}
				// 		break;
				// 	case 'user_avatar':
				// 	default:
				// 		break;
				// }

				// // includeUserFiles is only applicable to 'myfiles' case
				// if (
				// 	filterCase === 'myfiles' &&
				// 	((field.type === 'image' && field.imageOriginIncludeUserFiles) ||
				// 		(field.type === 'pdf' && field.pdfOriginIncludeUserFiles) ||
				// 		(field.type === 'video' && field.videoOriginIncludeUserFiles))
				// ) {
				// 	// if (filterCase === 'myfiles') {
				// 	filters.createdByUIDs = [userData.uid];
				// 	// }
				// }
				break;
			}
			default:
				break;
		}
		return filters;
	};

	const getMediafileChooserProvidersForTemplateBG = (templateBGSettings) => {
		let mediafileChooserProviders = [];

		if (
			!templateBGSettings.mediafileOrigin ||
			['category', 'admin_lightbox'].includes(templateBGSettings.mediafileOrigin)
		) {
			mediafileChooserProviders.push('TOOLKIT_FILELIBRARY');
			// mediafileChooserProviders.push('TOOLKIT_GENERATE_IMAGE');
		}

		if (
			templateBGSettings.includeUserFiles ||
			templateBGSettings.mediafileOrigin === 'user_files'
		) {
			mediafileChooserProviders.push('TOOLKIT_MYFILES');
			mediafileChooserProviders.push('TOOLKIT_FILEUPLOADER');
		}

		return mediafileChooserProviders.length === 0
			? ['TOOLKIT_FILELIBRARY']
			: mediafileChooserProviders;
	};

	const getMediafileChooserProvidersByField = (field) => {
		// when mediafileOrigin is null, there is no origin, user can select from whole library
		let mediafileOrigin = field.imageOrigin || field.pdfOrigin || field.videoOrigin || null;
		let includeUserOwnFiles =
			field.imageOriginIncludeUserFiles ||
			field.pdfOriginIncludeUserFiles ||
			field.videoOriginIncludeUserFiles;

		let mediafileChooserProviders = [];

		if (!mediafileOrigin || ['category', 'admin_lightbox'].includes(mediafileOrigin)) {
			mediafileChooserProviders.push('TOOLKIT_FILELIBRARY');
		}

		if (mediafileOrigin === 'user_files' || includeUserOwnFiles) {
			mediafileChooserProviders.push('TOOLKIT_MYFILES');
			mediafileChooserProviders.push('TOOLKIT_FILEUPLOADER');
		}
		if (field.generateAi && mode === 'preview') {
			mediafileChooserProviders.push('TOOLKIT_GENERATE_IMAGE');
		}
		return mediafileChooserProviders.length === 0
			? ['TOOLKIT_FILELIBRARY']
			: mediafileChooserProviders;
	};

	const onFieldFocus = (field) => () => {
		if (selectedField && selectedField.id === field.id) return;
		// setSelectedField(field);
		if (handleFieldFocus) handleFieldFocus(field);
	};
	/**
	 * Get the common "alignment component". It is kind of hard-coded. Only used internally here
	 * @param {import('aws-sdk/clients/signer').bool} hor
	 * @param {import('aws-sdk/clients/signer').bool} ver
	 * @param {object} field
	 */
	const getAlignmentComp = (hor, ver, field) => {
		/**
		 * Note: the structure in fieldInputData[field.id] must use "horizontalAlign" & "verticalAlign" as keyname
		 * fieldOutputData is merged input data & field default data
		 */
		// const defaultHor = field.horizontalAlign || field.textHorizontalAlign; // default horAlignment in field
		// const defaultVer = field.verticalAlign || field.textVerticalAlign; // default verAlignment in field

		return (
			<React.Fragment>
				{hor && (
					<StyledToggleButtonGroup
						value={fieldOutputData[field.id].horizontalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={
							(e, alignment) => setFieldInputDataById(field.id, { horizontalAlign: alignment }) // alignment could be null. Set the fallback value to null so that default field value will be used
						}
						aria-label="horizontal alignment"
					>
						<ToggleButton value="left" aria-label="left aligned">
							<FormatAlignLeftIcon />
						</ToggleButton>
						<ToggleButton value="center" aria-label="centered horizontal">
							<FormatAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="right" aria-label="right aligned">
							<FormatAlignRightIcon />
						</ToggleButton>
						{field.type === 'text' && (
							<ToggleButton value="justified" aria-label="justified aligned">
								<FormatAlignJustifyIcon />
							</ToggleButton>
						)}
					</StyledToggleButtonGroup>
				)}
				{hor && ver && <DividerVer className={classes.DividerVerStyle} />}
				{ver && (
					<StyledToggleButtonGroup
						value={fieldOutputData[field.id].verticalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={
							(e, alignment) => setFieldInputDataById(field.id, { verticalAlign: alignment }) // alignment could be null. Set the fallback value to null so that default field value will be used
						}
						aria-label="vertical alignment"
					>
						<ToggleButton value="top" aria-label="top aligned">
							<FormatVerticalAlignTopIcon />
						</ToggleButton>
						<ToggleButton value="middle" aria-label="middle horizontal">
							<FormatVerticalAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="bottom" aria-label="bottpm aligned">
							<FormatVerticalAlignBottomIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
				)}
			</React.Fragment>
		);
	};

	/**
	 *
	 * @param {object} field. Product picker field
	 * @param {string} keyword. Search keyword
	 * @param {object} opts. { offset = 0, limit = 20, append = false } // if append is true, then append response data to existing data
	 */
	const fetchSSContentById = (field, keyword, opts = {}) => {
		// if (!keyword) return null;
		let { offset = 0, limit = 20 } = opts;
		let spreadsheetId = field.predefinedValue.from;
		let columnId = field.predefinedValue.fromColumn;
		// TODO: Validate field data in design view
		// if the mandatory data is missing, we don't perform "fetch/search"
		if (!spreadsheetId || !columnId) {
			console.debug(
				`missing required data to fetch spreadsheet content: spreadsheetId ${spreadsheetId}, columnId ${columnId}`
			);
			return null;
		}
		let queryParams = {
			columnIndex: `${columnId}`,
			filter: keyword || '',
			offset,
			limit,
		};
		try {
			fetchSpreadsheetDetailsById({ spreadsheetId, queryParams: queryParams })
				.then((response) => {
					setSpreadSheetOptions((prevOptions) => {
						const prevRows = prevOptions.rows || [];
						// Concatenate and remove duplicates
						const newRows = [...prevRows, ...response.data.rows];
						const uniqueRows = newRows.reduce((acc, current) => {
							const x = acc.find((item) => item.rowID === current.rowID);
							if (!x) {
								return acc.concat([current]);
							} else {
								return acc;
							}
						}, []);

						return {
							totalFound: response.data.totalFound,
							rows: uniqueRows,
						};
					});
				})
				.catch((err) => {
					let errMsg = err.response ? err.response.data.message : err.message;
					console.log(`Failed to clip image. ${errMsg}`, 'error');
				});
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const fetchContentOnSelect = async (field, keyword, rows, opts = {}) => {
		// if (!keyword) return null;
		let { limit = 20 } = opts;
		let spreadsheetId = field.predefinedValue.from;
		let columnId = field.predefinedValue.fromColumn;
		// TODO: Validate field data in design view
		// if the mandatory data is missing, we don't perform "fetch/search"
		if (!spreadsheetId || !columnId) {
			console.debug(
				`missing required data to fetch spreadsheet content: spreadsheetId ${spreadsheetId}, columnId ${columnId}`
			);
			return null;
		}
		let queryParams = {
			searchColumnIndices: `${columnId}`,
			rows: `${rows}`,
			searchString: keyword || '',
			offset: 0,
			limit,
		};
		try {
			let res = await fetchSpreadsheetContentById({ spreadsheetId, queryParams });
			return res;
		} catch (err) {
			return null;
		}
	};

	const debouncedFetchSSContentById = _.debounce(
		fetchSSContentById,
		500
		// { leading: true, trailing: false }
	);

	// In some browsers the following doesn't work to detect if reaching to bottom of element, e.g. firefox's "scrollTop" has 1px less sometimes
	// listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
	// the workaround is using `listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1`,
	// it could cause the api calls are triggered more than once, so we use "debounce" to call api
	const debouncedFetchSSContentByIdOnScroll = _.debounce(fetchSSContentById, 30);

	const getTextFieldComp = (field, innerRef) => {
		// fieldInputData: {
		// 	TEXT_FIELD_ID: {
		// 		// TEXT Preview Data
		// 		value: 'xxxx', // value of this text field. Possible values: null/undefined, '', 'text'
		// 		fontsize: NUMBER | undefined,
		// 		horizontalAlign: 'left' | 'center' | 'right' | 'justified' | undefined,
		// 		verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		// 	},
		// }
		let isSingleLine = field.inputStyle === 'text';
		// isProductPicker & isPredefinedToList are against each other, only one of them can be true
		let isPredefinedToList =
			field.predefinedValue.type === 'list' && Boolean(field.predefinedValue.from);
		// variables for product picker field
		let isFieldActive = Boolean(innerRef); // "innerRef is not null" indicates the field is selected/focused
		let isProductPicker = isProductPickerField(field),
			isSearchingSS = isFieldActive && !spreadSheetOptions.rows,
			productPickerOptions = [];

		// if (isProductPicker) {
		// 	console.log(
		// 		`this field id ${field.id}, selected field id ${
		// 			(selectedField || {}).id
		// 		}, innerRef is null? ${isFieldActive}, open product picker ${openProductPicker}`
		// 	);
		// }
		if (isProductPicker && isFieldActive && spreadSheetOptions.rows) {
			productPickerOptions = spreadSheetOptions.rows
				.map((rowContent) => {
					return {
						value: rowContent.rowID,
						label: rowContent.rowDisplay,
					};
				})
				.filter((option) => !!option.label);
		}
		const hasUserDefined =
			field.textHorizontalAlignUserDefined ||
			field.textVerticalAlignUserDefined ||
			field.fontsizeUserDefined;
		return (
			<div
				key={field.id /* `${field.id}-${resetNum}` */}
				ref={mode === 'design' ? innerRef : undefined}
				className={cx({
					[classes.groupsDiv]: hasUserDefined,
					[classes.hightlightBorder]: isFieldActive && hasUserDefined,
				})}
				onFocus={onFieldFocus(field)}
				// onFocus={() => {
				// 	if (selectedField && selectedField.id === field.id) return;
				// 	setSelectedField(field);
				// 	handleFieldFocus(field);
				// }}
			>
				{hasUserDefined && (
					<React.Fragment>
						<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
							{field.name || 'Undefined'}
						</InputLabel>
						<div
							className={cx(classes.groupRowDiv, { [classes.disableClick]: disableUserDefined })}
							style={{ justifyContent: 'center' }}
						>
							{(field.textHorizontalAlignUserDefined || field.textVerticalAlignUserDefined) &&
								getAlignmentComp(
									field.textHorizontalAlignUserDefined,
									field.textVerticalAlignUserDefined,
									field
								)}
							{field.fontsizeUserDefined && (
								<React.Fragment>
									{(field.textHorizontalAlignUserDefined || field.textVerticalAlignUserDefined) && (
										<DividerVer className={classes.DividerVerStyle} />
									)}
									<StyledOutlinedTextFieldSelection
										className={classes.fontsizeSelection}
										label={intl.formatMessage({
											id: 'pages.Artwork.components.PreviewControlPanel.fontSizeTitle',
										})}
										value={fieldOutputData[field.id].fontsizeOriginalValue}
										onChange={
											(e) => setFieldInputDataById(field.id, { fontsize: e.target.value || null }) // set fallback value to null so that default fontsize will be used
										}
										SelectProps={{
											displayEmpty: true,
											renderValue: () => `${fieldOutputData[field.id].fontsizeOriginalValue}pt`,
										}}
										InputLabelProps={{ shrink: true }}
										smallMenu
										smallIcon
										// noneOption
										// noneMsg={artworkFonts.length === 0 ? 'Font list is not available' : 'None'}
										options={_.range(
											Math.min(field.fontsizeUDStart || 2, field.fontsize),
											Math.max(field.fontsizeUDEnd || 0, field.fontsize) + 1,
											field.fontsizeUDStep || 2
										).map((size) => ({
											label: `${size}${ART_VARIABLES.fieldFontSizeUnit}`,
											value: size,
										}))}
									/>
								</React.Fragment>
							)}
						</div>
					</React.Fragment>
				)}

				{!isProductPicker && !isPredefinedToList && (
					<StyledOutlinedTextField
						label={
							hasUserDefined
								? undefined
								: field.name || intl.formatMessage({ id: 'GENERAL.Undefined' })
						}
						className={cx(classes.textFieldWrapper, {
							[classes.hightlightBG]: isFieldActive, //&& mode === 'design', // && !hasUserDefined,
						})}
						inputRef={mode === 'preview' ? innerRef : undefined}
						inputProps={isSingleLine ? undefined : { style: { resize: 'vertical' } }}
						multiline={isSingleLine ? false : true}
						rows={isSingleLine ? undefined : 4}
						placeholder={hasUserDefined ? field.name || '' : undefined}
						onBlur={
							(e) => setFieldInputDataById(field.id, { value: e.target.value || '' }) // set fallback value to '' so that no default value will be used after user clears the value
						}
						value={fieldOutputData[field.id].value}
						// onChange={e =>null}
						// onFocus={() => handleFieldFocus(field)}
					/>
				)}

				{isPredefinedToList && (
					<StyledOutlinedTextFieldSelection
						fullWidth
						select
						label={
							hasUserDefined
								? undefined
								: field.name || intl.formatMessage({ id: 'GENERAL.Undefined' })
						}
						className={cx(classes.textFieldWrapper, {
							[classes.hightlightBG]: isFieldActive,
						})}
						value={fieldOutputData[field.id].value || ''}
						onChange={
							(e) => setFieldInputDataById(field.id, { value: e.target.value || '' }) // set fallback value to '' so that no default value will be used after user clears the value
						}
						noneOption
						options={(
							artworkExtra.lists.find((item) => item.id === field.predefinedValue.from) || {
								list_items: [],
							}
						).list_items.map((item) => ({ value: item.value.trim(), label: item.value.trim() }))} // we only use text value (for input data only), doesn't matter there is duplicate text
					/>
				)}

				{isProductPicker && (
					<Autocomplete
						fullWidth
						open={isFieldActive && openProductPicker} // when isFieldActive is true, this product picker field is selected/focused
						classes={{ paper: classes.inheritFontStyle }}
						blurOnSelect={true}
						onFocus={() => {
							// if (!isFieldActive) return; // product picker actions only when it is active
							if (!openProductPicker) {
								setOpenProductPicker(true);
							}
							debouncedFetchSSContentById(field, ''); // we initialize spreadsheet data
							// console.log(`autocomplete event: onFocus. product picker ${field.id}`);
						}}
						onBlur={() => {
							// if (!isFieldActive) return; // product picker actions only when it is active
							setOpenProductPicker(false);
							setProductPickerKeyword('');
							// console.log(`autocomplete event: onBlur. product picker ${field.id}`);
							//TODO: set value of this field, even there was selection happened, in case user changes it (by delete some letters manually) after selection
							// setFieldInputDataById(field.id, { value: e.target.value || '' });
							// console.log(
							// 	`autocomplete event: set product picker filed value onBlur ${e.target.value || ''}`
							// );
						}}
						// onClose={() => {
						// 	resetSpreadsheetContent();
						// }}
						onInputChange={(e, value, reason) => {
							// console.log(`autocomplete event: onInputChange. value ${value}, reason ${reason}`);
							// reset reason is by user selection, we don't take any action by that reason
							if (reason === 'reset') return null;
							if (!openProductPicker) {
								setOpenProductPicker(true);
							}
							setProductPickerKeyword(value);
							debouncedFetchSSContentById(field, value || '');
						}}
						onChange={async (e, selectedOption, reason) => {
							if (reason === 'selectOption') {
								let ssRowContent = await fetchContentOnSelect(
									field,
									productPickerKeyword || '',
									selectedOption.value,
									{
										offset: (spreadSheetOptions.rows || []).length,
									}
								);
								if (ssRowContent.data.rows) {
									let columnDataMap = ssRowContent.data.rows[0].rowData.reduce((accu, column) => {
										// column is data of the column, it contains value for text/barcode, and imageUrls for image field
										accu[column.columnId] = column;
										return accu;
									}, {});
									handleProductPickerSelection(field, columnDataMap);
								}
							} else if (reason === 'clear') {
								// delete it from user input data onClear
								setFieldInputDataById(field.id, { value: '' });
							}
							// Note, we use "blurOnSelect", so onBlur event is triggered right after here, hence no need to reset state (as below) here
							// resetSpreadsheetContent(); // reset spreadsheet content when going away
							// setOpenProductPicker(false);
						}}
						ListboxProps={{
							onScroll: (e) => {
								const listboxNode = e.currentTarget;
								// // const heightOffsetToReload = 30;
								// if (
								// 	!isFirefox &&
								// 	listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
								// ) {
								// 	if (
								// 		spreadsheetContent.content.totalFound >
								// 		(spreadsheetContent.content.rows || []).length
								// 	) {
								// 		fetchSSContentById(field, productPickerKeyword || '', {
								// 			offset: (spreadsheetContent.content.rows || []).length,
								// 			append: true,
								// 		});
								// 	}
								// }
								// Below is a workaround to replace the above commented code, to detect if reaching to bottom of element
								if (
									listboxNode.scrollTop + listboxNode.clientHeight >=
										listboxNode.scrollHeight - 1 &&
									spreadSheetOptions.totalFound > (spreadSheetOptions.rows || []).length
								) {
									debouncedFetchSSContentByIdOnScroll(field, productPickerKeyword || '', {
										offset: (spreadSheetOptions.rows || []).length,
										append: true,
									});
								}
							},
						}}
						// getOptionSelected={(option, value) => option.label === value}
						getOptionLabel={(option) => option.label}
						// isOptionEqualToValue prop to define a custom equality test function, which will help Autocomplete determine whether an option matches a value
						isOptionEqualToValue={(option, value) => option.label === value.label}
						// renderOption is used to render dropdown list. It overrides getOptionLabel
						renderOption={(props, option) => (
							<li {...props} key={option.value}>
								<Typography title={option.label} variant="body2" noWrap>
									{option.label}
								</Typography>
							</li>
						)}
						options={productPickerOptions}
						loading={isSearchingSS}
						// value={{ label: fieldOutputData[field.id].value }}
						renderInput={(params) => (
							<SimplifiedOutlinedTextField
								{...params}
								label={
									hasUserDefined
										? undefined
										: field.name || intl.formatMessage({ id: 'GENERAL.Undefined' })
								}
								className={cx(classes.textFieldWrapper, classes.productPickerTextField)}
								inputRef={mode === 'preview' ? innerRef : undefined}
								// NB: VID-3199. in case of product picker, the input box is always a single line input box
								// inputProps={
								// 	isSingleLine
								// 		? { ...(params.inputProps || {}) }
								// 		: { ...(params.inputProps || {}), style: { resize: 'vertical' } }
								// }
								// multiline={isSingleLine ? false : true}
								// rows={isSingleLine ? undefined : 4}
								placeholder={hasUserDefined ? field.name || '' : undefined}
								// onChange={e => {
								// 	console.log(
								// 		`autocomplete event: textfieldInputChange. e.target.value ${e.target.value}`
								// 	);
								// 	// debouncedFetchSSContentById(field, e.target.value);
								// }}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{isSearchingSS ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
									className: `${params.InputProps.className || ''} ${classes.inheritFontStyle}`,
								}}
							/>
						)}
					/>
				)}
			</div>
		);
	};
	const getImageFieldComp = (field, innerRef) => {
		// fieldInputData: {
		// 	IMAGE_FIELD_ID: {
		// 		// IMAGE Preview Data
		// previewUrl: 'xxxxxxx', // preview url of this image. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
		// optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
		// highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

		// croppedImgUrl: 'xxx', // web url or blob url of the cropped image (png format). Possible values: null/undefined, '', 'blob:https//xxx | https://xxxx'
		// clippedImgUrl: 'xxx', // web url or blob url of the clipped image (png format). Possible values: null/undefined, '', 'https://xxxx'
		// horizontalAlign: 'left' | 'center' | 'right' | undefined,
		// verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		// 	},
		// }
		return (
			<div
				key={field.id /* `${field.id}-${resetNum}` */}
				className={cx(classes.groupsDiv, {
					[classes.hightlightBorder]: innerRef, //&& mode === 'design',
				})}
				ref={innerRef}
				onFocus={onFieldFocus(field)}
				// onFocus={() => {
				// 	if (selectedField && selectedField.id === field.id) return;
				// 	setSelectedField(field);
				// 	handleFieldFocus(field);
				// }}
			>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{field.name || 'Undefined'}
				</InputLabel>
				{fieldOutputData[field.id].previewUrl && (
					<div className={classes.fieldPreviewWrapper}>
						<img
							src={fieldOutputData[field.id].previewUrl}
							alt="preview"
							style={{ maxHeight: '100%', maxWidth: '100%' }}
						/>
					</div>
				)}
				<div
					className={cx(classes.groupRowDiv, { [classes.disableClick]: disableUserDefined })}
					style={{ justifyContent: 'center' }}
				>
					{getAlignmentComp(
						field.horizontalAlignUserDefined,
						field.verticalAlignUserDefined,
						field
					)}
				</div>
				<div
					className={classes.groupRowDiv}
					style={{ justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}
				>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonChoose',
						})}
						startIcon={<ImageSearchIcon fontSize="small" />}
						size="small"
						onClick={() => setFieldToChooseMediafile(field)}
					/>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonImageClip',
						})}
						startIcon={<ImageClipIcon fontSize="small" />}
						size="small"
						disabled={Boolean(
							!fieldOutputData[field.id].mediafileId ||
								(!fieldOutputData[field.id].highResUrl &&
									!fieldOutputData[field.id].optimisedUrl) ||
								fieldOutputData[field.id].clippedImgUrl
						)}
						onClick={() => {
							// the image here could be default image or user selected one
							let imageUrl =
								fieldOutputData[field.id].highResUrl || fieldOutputData[field.id].optimisedUrl;
							if (imageUrl)
								handleImageClip({
									fieldId: field.id,
									imageUrl,
									mediafileId: fieldOutputData[field.id].mediafileId,
								});
						}}
					/>
					{field.sizing === 'crop' && (
						<StyledContainedButton
							label={intl.formatMessage({
								id: 'pages.Artwork.components.PreviewControlPanel.buttonImageCrop',
							})}
							startIcon={<ImageCropIcon fontSize="small" />}
							size="small"
							disabled={Boolean(
								!fieldOutputData[field.id].clippedImgUrl && // if clipped image available, use it for cropping
									!fieldOutputData[field.id].highResUrl &&
									!fieldOutputData[field.id].optimisedUrl
							)}
							onClick={() => {
								let imageUrl =
									fieldOutputData[field.id].clippedImgUrl ||
									fieldOutputData[field.id].highResUrl ||
									fieldOutputData[field.id].optimisedUrl;
								if (imageUrl) handleImageCrop({ fieldId: field.id, imageUrl });
							}}
						/>
					)}

					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonReset',
						})}
						startIcon={<ResetIcon fontSize="small" />}
						size="small"
						disabled={Boolean(
							!fieldOutputData[field.id].croppedImgUrl && !fieldOutputData[field.id].clippedImgUrl
						)}
						onClick={() => {
							// reset clipped & cropped images
							setFieldInputDataById(field.id, {
								croppedImgUrl: '',
								clippedImgUrl: '',
							});
						}}
					/>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonClear',
						})}
						startIcon={<ClearIcon fontSize="small" />}
						size="small"
						disabled={!fieldOutputData[field.id].mediafileId}
						onClick={() => {
							// Remove user input data
							setFieldInputDataById(field.id, {
								mediafileId: '',
								previewUrl: '',
								optimisedUrl: '',
								highResUrl: '',
								croppedImgUrl: '',
								clippedImgUrl: '',
							});
						}}
					/>
				</div>
				{
					// <StyledOutlinedTextField
					// 	label={'Test Image Url'}
					// 	className={classes.textFieldWrapper}
					// 	// inputRef={defaultValueRef}
					// 	// inputProps={isSingleLine ? undefined : { style: { resize: 'vertical' } }}
					// 	// multiline={isSingleLine ? false : true}
					// 	// rows={isSingleLine ? undefined : 4}
					// 	// placeholder={hasUserDefined ? field.name || '' : undefined}
					// 	onBlur={
					// 		e => null
					// 		// setFieldsPreviewValue({
					// 		// 	...fieldsPreviewValue,
					// 		// 	[field.id]: {
					// 		// 		...fieldsPreviewValue[field.id],
					// 		// 		previewUrl: e.target.value || null,
					// 		// 		imageUrl: e.target.value || null, // reset the imageUrl with its high quality image
					// 		// 		croppedImgUrl: '', // reset the cropped img to '' when changing to a new image
					// 		// 	},
					// 		// })
					// 	}
					// 	value={fieldOutputData[field.id].previewUrl || ''}
					// 	// onChange={e =>
					// 	// 	setFieldsPreviewValue({
					// 	// 		...fieldsPreviewValue,
					// 	// 		[field.id]: { ...fieldsPreviewValue[field.id], value: e.target.value || '' },
					// 	// 	})
					// 	// }
					// 	// onFocus={() => handleFieldFocus(field)}
					// />
				}
			</div>
		);
	};

	/**
	 *
	 * @param {object} field
	 */
	const getBarcodeFieldComp = (field, innerRef) => {
		// fieldInputData: {
		// 	BARCODE_FIELD_ID: {
		// 		// BARCODE Preview Data
		// 		value: 'xxxxxxx',
		//		EAN13Value: 'xxx',
		//		EAN5Value: 'xxx',
		// 		horizontalAlign: 'left'|'center'|'right'|undefined,
		// 		verticalAlign: 'top'|'middle'|'bottom'|undefined,
		// 	},
		// }
		const hasUserDefined = field.horizontalAlignUserDefined || field.verticalAlignUserDefined;
		return (
			<div
				key={field.id /* `${field.id}-${resetNum}` */}
				className={cx({
					[classes.groupsDiv]: hasUserDefined,
					[classes.hightlightBorder]: innerRef /* && mode === 'design' */ && hasUserDefined,
				})}
				ref={innerRef}
				onFocus={onFieldFocus(field)}
				// onFocus={() => handleFieldFocus(field)}
			>
				{hasUserDefined && (
					<React.Fragment>
						<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
							{field.name || 'Undefined'}
						</InputLabel>
						<div
							className={cx(classes.groupRowDiv, { [classes.disableClick]: disableUserDefined })}
							style={{ justifyContent: 'center' }}
						>
							{getAlignmentComp(
								field.horizontalAlignUserDefined,
								field.verticalAlignUserDefined,
								field
							)}
						</div>
					</React.Fragment>
				)}

				<StyledOutlinedTextField
					label={
						hasUserDefined
							? undefined
							: field.name || intl.formatMessage({ id: 'GENERAL.Undefined' })
					}
					className={cx(classes.textFieldWrapper, {
						[classes.hightlightBG]: innerRef, // && mode === 'design', // && !hasUserDefined,
					})}
					inputProps={{ style: { resize: 'vertical' } }}
					multiline
					rows={3}
					error={Boolean(fieldOutputData[field.id].value && !fieldOutputData[field.id].EAN13Value)}
					placeholder={hasUserDefined ? field.name || '' : undefined}
					onBlur={(e) => {
						// validate barcode value is done when build ean13 code from the input value
						setFieldInputDataById(field.id, {
							value: e.target.value || '',
						});
					}}
					value={fieldOutputData[field.id].value}
					// onChange={e =>null}
					// onFocus={() => handleFieldFocus(field)}
				/>
			</div>
		);
	};

	const getPdfFieldComp = (field, innerRef) => {
		// fieldInputData: {
		// PDF_FIELD_ID:{
		// 	// PDF field Preview Data
		// previewUrl: 'xxxxxxx', // svg url of this pdf/svg mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
		// optimisedUrl: 'xxxx', // svg url of this pdf/svg mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
		// highResUrl: 'xxxx', // svg url of this pdf/svg mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

		// horizontalAlign: 'left' | 'center' | 'right' | undefined,
		// verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		// },
		return (
			<div
				key={field.id /* `${field.id}-${resetNum}` */}
				className={cx(classes.groupsDiv, {
					[classes.hightlightBorder]: innerRef, //&& mode === 'design',
				})}
				ref={innerRef}
				onFocus={onFieldFocus(field)}
				// onFocus={() => handleFieldFocus(field)}
			>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{field.name || 'Undefined'}
				</InputLabel>
				{fieldOutputData[field.id].previewUrl && (
					<div className={classes.fieldPreviewWrapper}>
						<object
							data={fieldOutputData[field.id].previewUrl}
							type="image/svg+xml"
							aria-label="preview"
							style={{ maxHeight: '100%', maxWidth: '100%' }}
						/>
					</div>
				)}
				<div
					className={cx(classes.groupRowDiv, { [classes.disableClick]: disableUserDefined })}
					style={{ justifyContent: 'center' }}
				>
					{getAlignmentComp(
						field.horizontalAlignUserDefined,
						field.verticalAlignUserDefined,
						field
					)}
				</div>
				<div className={classes.groupRowDiv}>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonChoose',
						})}
						startIcon={<ImageSearchIcon fontSize="small" />}
						size="small"
						// onClick={() => null}
						onClick={() => setFieldToChooseMediafile(field)}
					/>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonClear',
						})}
						startIcon={<ClearIcon fontSize="small" />}
						size="small"
						onClick={() => {
							// Remove user input data
							setFieldInputDataById(field.id, {
								mediafileId: '',
								previewUrl: '',
								optimisedUrl: '',
								highResUrl: '',
							});
						}}
					/>
				</div>
				{
					// <StyledOutlinedTextField
					// 	label={'Test Pdf Url'}
					// 	className={classes.textFieldWrapper}
					// 	onBlur={
					// 		e => {
					// 			setFieldInputDataById(field.id, {
					// 				previewUrl: '',
					// 				optimisedUrl: e.target.value,
					// 				highResUrl: e.target.value,
					// 			});
					// 		}
					// 		// setFieldsPreviewValue({
					// 		// 	...fieldsPreviewValue,
					// 		// 	[field.id]: {
					// 		// 		...fieldsPreviewValue[field.id],
					// 		// 		svgUrl: e.target.value || null,
					// 		// 		// previewUrl: e.target.value
					// 		// 		// 	? `https://apigw.visualid.com/preview/v1/preview/?redirect=true&prekey=XTEh1eydHiEv6nKxfj1u4HjCHw1Y5X16&size=medium&fileurl=s3://visualid-mediafiles/${e.target.value}`
					// 		// 		// 	: null,
					// 		// 		// previewHighResUrl: e.target.value
					// 		// 		// 	? `https://apigw.visualid.com/preview/v1/preview/?redirect=true&prekey=XTEh1eydHiEv6nKxfj1u4HjCHw1Y5X16&size=original&fileurl=s3://visualid-mediafiles/${e.target.value}`
					// 		// 		// 	: null,
					// 		// 	},
					// 		// })
					// 	}
					// 	value={fieldOutputData[field.id].optimisedUrl || ''}
					// />
				}
			</div>
		);
	};
	const getVideoFieldComp = (field, innerRef) => {
		// fieldInputData: {
		// 	VIDEO_FIELD_ID: {
		// 		// VIDEO Preview Data
		// previewUrl: 'xxxxxxx', // preview url of this video. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
		// optimisedUrl: 'xxxx', // transcoded video url of this mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
		// highResUrl: 'xxxx', // transcoded video url of this mediafile. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

		// horizontalAlign: 'left' | 'center' | 'right' | undefined,
		// verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		// videoLoop: true | false | undefined,
		// 	},
		// }
		return (
			<div
				key={field.id /* `${field.id}-${resetNum}` */}
				className={cx(classes.groupsDiv, {
					[classes.hightlightBorder]: innerRef, //&& mode === 'design',
				})}
				ref={innerRef}
				onFocus={onFieldFocus(field)}
				// onFocus={() => handleFieldFocus(field)}
			>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{field.name || 'Undefined'}
				</InputLabel>
				{fieldOutputData[field.id].previewUrl && (
					<div className={classes.fieldPreviewWrapper}>
						<img
							src={fieldOutputData[field.id].previewUrl}
							alt="preview"
							style={{ maxHeight: '100%', maxWidth: '100%' }}
						/>
					</div>
				)}
				<div
					className={cx(classes.groupRowDiv, { [classes.disableClick]: disableUserDefined })}
					style={{ justifyContent: 'center' }}
				>
					{getAlignmentComp(
						field.horizontalAlignUserDefined,
						field.verticalAlignUserDefined,
						field
					)}
					{field.videoLoopUserDefined && (
						<React.Fragment>
							<DividerVer className={classes.DividerVerStyle} />
							<Tooltip
								title={intl.formatMessage({
									id: 'pages.Artwork.components.PreviewControlPanel.videoLoopTooltip',
								})}
								// placement="top"
								arrow
							>
								<IconButton
									className={cx(classes.squareIconButton, {
										[classes.squareIconButtonActive]: fieldOutputData[field.id].videoLoop,
									})}
									onClick={() =>
										setFieldInputDataById(field.id, {
											videoLoop: !fieldOutputData[field.id].videoLoop,
										})
									}
								>
									<VideoLoopIcon />
								</IconButton>
							</Tooltip>
						</React.Fragment>
					)}
				</div>
				<div className={classes.groupRowDiv}>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonChoose',
						})}
						startIcon={<ImageSearchIcon fontSize="small" />}
						size="small"
						// onClick={() => null}
						onClick={() => setFieldToChooseMediafile(field)}
					/>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonClear',
						})}
						startIcon={<ClearIcon fontSize="small" />}
						size="small"
						onClick={() => {
							// Remove user input data
							setFieldInputDataById(field.id, {
								mediafileId: '',
								previewUrl: '',
								optimisedUrl: '',
								highResUrl: '',
							});
						}}
					/>
				</div>
				{
					// <StyledOutlinedTextField
					// 	label={'Test Video Url'}
					// 	className={classes.textFieldWrapper}
					// 	// inputRef={defaultValueRef}
					// 	// inputProps={isSingleLine ? undefined : { style: { resize: 'vertical' } }}
					// 	// multiline={isSingleLine ? false : true}
					// 	// rows={isSingleLine ? undefined : 4}
					// 	// placeholder={hasUserDefined ? field.name || '' : undefined}
					// 	onBlur={
					// 		e => null
					// 		// setFieldsPreviewValue({
					// 		// 	...fieldsPreviewValue,
					// 		// 	[field.id]: { ...fieldsPreviewValue[field.id], previewUrl: e.target.value || null },
					// 		// })
					// 	}
					// 	value={fieldOutputData[field.id].previewUrl || ''}
					// 	// onChange={e =>
					// 	// 	setFieldsPreviewValue({
					// 	// 		...fieldsPreviewValue,
					// 	// 		[field.id]: { ...fieldsPreviewValue[field.id], value: e.target.value || '' },
					// 	// 	})
					// 	// }
					// 	// onFocus={() => handleFieldFocus(field)}
					// />
				}
			</div>
		);
	};

	const getGridFieldComp = (field, innerRef) => {
		/**
		 * Input data format of grid field
		  GRID_FIELD_ID: {
				// tableData format: [RowData, RowData, ...]; RowData: [cellText, cellText, ...]; cellText may be empty string, may contain multiple lines by '\n'. Get non-empty lines by `.split(/\r?\n|\r/g).filter((s) => s)`
				// sample data of tableData: [["Product","Small","Large"],["Cappuccino\n\nsubtitle","€1.99\n\nsubtitle","€2.99"],["Mocha","€2.49","€3.99"]]
				// possible value: null/undefined (no input data), [['xxx', 'xxx'], ...]
				// when its value is null/undefined, the code logic in "getFieldOutputData" to decide using field.defaultEditorHtml to render or not
				tableData: [["Product","Small","Large"],["Cappuccino\n\ngghgh","€1.99\n\nnew line","€2.99"],["Mocha","€2.49","€3.99"]],
				hasHeader: true/false, // indicate if there is header in the table
				// editorHtml possible values: null/undefined, 'xxxx'
				editorHtml, // the html string in the editor.
		  }
		 */
		const fieldRenderData = fieldOutputData[field.id];
		return (
			<div
				key={field.id}
				className={cx(classes.groupsDiv, {
					[classes.hightlightBorder]: innerRef, //&& mode === 'design',
				})}
				ref={innerRef}
				onFocus={onFieldFocus(field)}
			>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{field.name || 'Undefined'}
				</InputLabel>
				{field.fontsizeUserDefined && (
					<div className={cx(classes.groupRowDiv)} style={{ paddingTop: 8 }}>
						<StyledOutlinedTextFieldSelection
							className={classes.fontsizeSelection}
							label={intl.formatMessage({
								id: 'pages.Artwork.components.PreviewControlPanel.fontSizeTitle',
							})}
							value={fieldRenderData.fontsize || ''}
							onChange={(e) =>
								setFieldInputDataById(field.id, { fontsize: e.target.value || null })
							}
							SelectProps={{
								displayEmpty: true,
								renderValue: () =>
									fieldRenderData.fontsize
										? `${fieldRenderData.fontsize}pt`
										: intl.formatMessage({
												id:
													'pages.Artwork.components.PreviewControlPanel.customFontsizeNoneMsgGridField',
										  }),
							}}
							InputLabelProps={{ shrink: true }}
							smallMenu
							smallIcon
							noneOption
							// noneMsg={ 'Font list is not available' : 'None'}
							options={_.range(
								field.fontsizeUDStart || 2,
								(field.fontsizeUDEnd || 4) + 1,
								field.fontsizeUDStep || 2
							).map((size) => ({
								label: `${size}${ART_VARIABLES.fieldFontSizeUnit}`,
								value: size,
							}))}
						/>
					</div>
				)}
				<div
					className={cx(classes.gridInputTableWrapper, {
						[classes.gridInputTableOnUDFontsize]: field.fontsizeUserDefined,
					})}
				>
					<GridInputTable
						disableEditing={false}
						disableControls={true}
						initialTableHtmlText={
							// there is always a default editorHtml, default is ART_VARIABLES.sampleGridTableText
							fieldRenderData.editorHtml
						}
						handleEditorOnBlur={(editorHtml) => {
							// retrieve table row/column (cell) data from tableElem
							let gridData = retrieveGridTableData({ editorHtml });
							if (gridData) setFieldInputDataById(field.id, { ...gridData, editorHtml });
							else setFieldInputDataById(field.id, {});

							// if (!tableElem) {
							// 	// no table data at all
							// 	return setFieldInputDataById(field.id, {});
							// }
							// let tableData = [];
							// for (let row of tableElem.rows) {
							// 	const rowIndex = row.rowIndex;
							// 	tableData[rowIndex] = [];
							// 	// console.log(rowIndex);
							// 	for (let cell of row.cells) {
							// 		tableData[rowIndex].push(
							// 			cell.innerText.trim()
							// 			// .split(/\r?\n|\r/g)
							// 			// .filter((s) => s) // remove empty lines
							// 			// .join(',')
							// 		);
							// 	}
							// }
							// console.log(JSON.stringify(tableData));
							// console.log(JSON.stringify(editorHtml));
							// setFieldInputDataById(field.id, {
							// 	tableData,
							// 	hasHeader: tableElem.tHead?.rows.length > 0 ?? false,
							// 	editorHtml, // used to continue editing
							// });
						}}
					/>
				</div>
			</div>
		);
	};

	const getTemplateBGComp = (templateBGSettings /* innerRef */) => {
		// templateBGSettings: {
		// 	mediafileId: 'xxx', //  mediafile id
		// 	mediafilePreviewUrl: 'https://xxx', // merged output mediafile preview url
		// 	mediafileHighResUrl: 'https://xxx', // high-resolution url of merged output mediafile (for generating high quality pdf)
		// 	mediafileOptimisedUrl: 'https://xxx', // optimised url of merged output mediafile (fast loading & good quality for webpage)
		// 	mediafilePdfUrl: 'https://xxx', // pdf url of the merged output mediafile background
		// 	mediafileSvgUrl: 'https://xxx', // svg url of the merged output mediafile background
		// 	mediafileOrigin: 'user_files', // it uses imageOriginOptions as its option choices. possible values: 'category', 'admin_lightbox', 'user_files', 'user_avatar'
		// 	mediafileOriginChoice: [], // format: [{id: 'xxx', ...rest}, ...]. "id" in the object is mandatory, value of "id" is integer (for category) or string (for lightbox)
		// 	includeUserFiles: false, // weather or not including user's own files
		// 	isCustomisable:  false, // weather or not allowing end user to select background
		// },
		return (
			<div
				className={cx(classes.groupsDiv, {
					// [classes.hightlightBorder]: innerRef, //&& mode === 'design',
				})}
			>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{'Template Background'}
				</InputLabel>
				{templateBGSettings.mediafilePreviewUrl && (
					<div className={classes.fieldPreviewWrapper}>
						<img
							src={templateBGSettings.mediafilePreviewUrl}
							alt="preview"
							style={{ maxHeight: '100%', maxWidth: '100%' }}
						/>
					</div>
				)}

				<div className={classes.groupRowDiv}>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonChoose',
						})}
						startIcon={<ImageSearchIcon fontSize="small" />}
						size="small"
						onClick={() => setFieldToChooseMediafile({ ...templateBGSettings, id: 'templateBG' })}
					/>
					<StyledContainedButton
						label={intl.formatMessage({
							id: 'pages.Artwork.components.PreviewControlPanel.buttonClear',
						})}
						startIcon={<ClearIcon fontSize="small" />}
						size="small"
						onClick={() => {
							// Remove user input data
							setTemplateBGInputData({
								mediafileId: '',
								mediafilePreviewUrl: '',
								mediafileHighResUrl: '',
								mediafileOptimisedUrl: '',
								mediafilePdfUrl: '',
								mediafileSvgUrl: '',
							});
						}}
					/>
				</div>
			</div>
		);
	};

	const dragHandleClassName = 'previewControlPanelDragHandler'; // Not for CSS style, only act as a selector for dragging
	return (
		<Rnd
			ref={RndRef}
			default={{
				x: ART_VARIABLES.cssStyles.menuBarWidth + 80,
				y: 20,
				width: ART_VARIABLES.cssStyles.menuPanelWidth - ART_VARIABLES.cssStyles.menuBarWidth + 100,
				height: '60%',
			}}
			minWidth={340}
			minHeight={panelTitleHeight}
			maxHeight="85%"
			maxWidth={1000}
			dragHandleClassName={dragHandleClassName}
			enableResizing={{
				top: false,
				right: true,
				bottom: true,
				left: true,
				topRight: false,
				bottomRight: true,
				bottomLeft: true,
				topLeft: false,
			}}
			bounds={'parent'}
			{...rndProps}
			className={cx(classes.panelRoot, rndClassName, { [classes.panelClosed]: !open })}
		>
			{open && (
				<div className={classes.previewControlPanelWrapper}>
					{/* panel title */}
					{!disableTitle && (
						<div className={cx(classes.panelTitle, dragHandleClassName)}>
							{intl.formatMessage({ id: 'pages.Artwork.components.PreviewControlPanel.title' })}
							{
								// 	onClose && (
								// <div className={classes.panelTitleButton} onClick={onClose}>
								// 	X
								// </div>
								// )
							}
							{
								<div
									onClick={() => {
										setPanelExpand(!panelExpand);
										let rootElem = RndRef.current.getSelfElement();
										RndRef.current.updateSize({
											width: rootElem.offsetWidth,
											height: panelExpand ? panelTitleHeight : '60%',
										});
									}}
									className={classes.panelTitleButton}
								>
									<IconButton style={{ color: 'inherit' }}>
										{panelExpand ? (
											<ExpandLessIcon fontSize="small" />
										) : (
											<ExpandMoreIcon fontSize="small" />
										)}
									</IconButton>
								</div>
							}
						</div>
					)}
					{/** float buttons (only when at least one of the handler is provided) */}
					{(resetFieldInputData || handleResetAnimation) && (
						<div className={classes.floatButtons}>
							{panelExpand && resetFieldInputData && (
								<React.Fragment>
									<Tooltip
										title={intl.formatMessage({
											id: 'pages.Artwork.components.PreviewControlPanel.resetPreviewDataTooltip',
										})}
										placement="right-end"
										arrow
									>
										<Fab
											size="small"
											color="secondary"
											aria-label="reset"
											className={classes.floatButton}
											onClick={() => {
												resetFieldInputData();
												// setResetNum(resetNum + 1);
											}}
										>
											<ResetIcon />
										</Fab>
									</Tooltip>
								</React.Fragment>
							)}
							{handleResetAnimation && (
								<Tooltip
									title={intl.formatMessage({
										id: 'pages.Artwork.components.PreviewControlPanel.playAnimationTooltip',
									})}
									placement="right-end"
									arrow
								>
									<Fab
										size="small"
										color="secondary"
										aria-label="reset-animation"
										className={classes.floatButton}
										onClick={handleResetAnimation}
									>
										<PlayAnimationIcon />
									</Fab>
								</Tooltip>
							)}
						</div>
					)}
					{/* panel content */}
					<div
						ref={panelContentRef}
						className={cx(classes.panelContent, { [classes.panelCollapsedStyle]: !panelExpand })}
					>
						<div className={classes.contentWrapper}>
							{/** tempalte background */}
							{templateBGOutputDataWithSettings.isCustomisable && (
								<div>{getTemplateBGComp(templateBGOutputDataWithSettings)}</div>
							)}
							{groups.map((group) => {
								const isExpanded = expandAllGroups || group.name === groupPanelExpand.name;
								return (
									<div key={group.name} className={classes.groupContentWrapper}>
										<Button
											className={cx(classes.groupTitleButton, {
												[classes.groupTitleButtonExpanded]: isExpanded,
											})}
											color="inherit"
											fullWidth
											classes={{ label: classes.groupTitleButtonLabel }}
											sx={{
												justifyContent: 'space-between',
											}}
											endIcon={
												isExpanded ? (
													<ExpandLessIcon fontSize="small" />
												) : (
													<ExpandMoreIcon fontSize="small" />
												)
											}
											onClick={() => {
												setGroupPanelExpand(isExpanded ? {} : { name: group.name });
											}}
										>
											{group.name}
										</Button>
										<Collapse in={isExpanded} timeout="auto">
											<List component="div">
												{fields.map((f) => {
													if (f.groupName !== group.name || f.hideInput) return null; // The field is not in this group or is hidden from input.
													let fieldComp = null;
													let innerRef = f.id === selectedField.id ? selectedFieldElemRef : null;
													switch (f.type) {
														case 'text':
															fieldComp = getTextFieldComp(f, innerRef);
															break;
														case 'image':
															fieldComp = getImageFieldComp(f, innerRef);
															break;
														case 'pdf':
															fieldComp = getPdfFieldComp(f, innerRef);
															break;
														case 'barcode':
															fieldComp = getBarcodeFieldComp(f, innerRef);
															break;
														case 'video':
															fieldComp = getVideoFieldComp(f, innerRef);
															break;
														case 'grid':
															fieldComp = getGridFieldComp(f, innerRef);
															break;
														default:
															break;
													}
													return fieldComp;
												})}
											</List>
										</Collapse>
									</div>
								);
							})}
						</div>
					</div>
					{fieldToChooseMediafile && (
						<RnDDialog
							open={true}
							size="md"
							title="Select Media File"
							resizable={true}
							onClose={() => {
								setFieldToChooseMediafile(null);
							}}
						>
							<MediafileMultiChoosers
								enabledProviders={
									fieldToChooseMediafile.id === 'templateBG'
										? getMediafileChooserProvidersForTemplateBG(templateBGOutputDataWithSettings)
										: getMediafileChooserProvidersByField(fieldToChooseMediafile)
								}
								filtersToolkitFileLibrary={
									fieldToChooseMediafile.id === 'templateBG' // this is to choose template background
										? getTemplateBGFiltersForFilemanager(
												templateBGOutputDataWithSettings,
												templateDimension,
												'filelibrary'
										  )
										: getFieldFiltersForFilemanager(fieldToChooseMediafile, 'filelibrary')
								}
								filtersToolkitMyFiles={
									fieldToChooseMediafile.id === 'templateBG' // this is to choose template background
										? getTemplateBGFiltersForFilemanager(
												templateBGOutputDataWithSettings,
												templateDimension,
												'myfiles'
										  )
										: getFieldFiltersForFilemanager(fieldToChooseMediafile, 'myfiles')
								}
								initSearchableKeywords={
									fieldToChooseMediafile.type === 'image' &&
									fieldToChooseMediafile.preSearchFieldId &&
									fieldOutputData[fieldToChooseMediafile.preSearchFieldId]?.value
										? fieldOutputData[fieldToChooseMediafile.preSearchFieldId].value
												.split(' ')[0]
												.trim()
												.substring(0, 5) // we use the first 5 char in the first word as the initial searchable keyword
										: ''
								}
								initGenerateAIPrompt={
									fieldToChooseMediafile.type === 'image' &&
									fieldToChooseMediafile.generateAiFieldId &&
									fieldOutputData[fieldToChooseMediafile.generateAiFieldId]?.value
										? sanitizeFieldOutputValue(
												fieldOutputData,
												fieldOutputData[fieldToChooseMediafile.generateAiFieldId].value
										  )
										: ''
								}
								S3UploaderProps={{
									// use default accept file types if null
									accept:
										fieldToChooseMediafile.id === 'templateBG'
											? '.pdf, .svg'
											: getUploadFileTypesByFieldType(fieldToChooseMediafile),
								}}
								onMediafileSelect={(selectedMediafile, fName) => {
									// if (fieldToChooseMediafile.generateAi) {
									// 	let newData = {
									// 		mediafileId: selectedMediafile.id || '', // set fallback value to '' to indicate we don't want to use default
									// 		previewUrl:
									// 			fieldToChooseMediafile.type === 'pdf'
									// 				? selectedMediafile.svgUrl || ''
									// 				: selectedMediafile.previewUrl || '', // set fallback value to '' is to indicate we don't want to use default
									// 		optimisedUrl:
									// 			fieldToChooseMediafile.type === 'pdf'
									// 				? selectedMediafile.svgUrl || ''
									// 				: selectedMediafile.optimisedUrl || '', // set fallback value to '' is to indicate we don't want to use default
									// 		highResUrl:
									// 			fieldToChooseMediafile.type === 'pdf'
									// 				? selectedMediafile.svgUrl || ''
									// 				: selectedMediafile.highResUrl || '', // set fallback value to '' is to indicate we don't want to use default
									// 	};
									// 	if (fieldToChooseMediafile.type === 'image') {
									// 		newData.croppedImgUrl = ''; // reset the cropped img to '' when changing to a new image
									// 		newData.clippedImgUrl = ''; // reset the clipped img to '' when changing to a new image
									// 	}
									// 	setFieldInputDataById(fieldToChooseMediafile.id, newData);
									// }
									if (fieldToChooseMediafile.id === 'templateBG') {
										setTemplateBGInputData({
											mediafileId: selectedMediafile.id,
											mediafilePreviewUrl: selectedMediafile.previewUrl,
											mediafileHighResUrl: selectedMediafile.highResUrl,
											mediafileOptimisedUrl: selectedMediafile.optimisedUrl,
											mediafilePdfUrl:
												selectedMediafile.fileType.toUpperCase() === 'PDF'
													? selectedMediafile.originalUrl
													: null,
											mediafileSvgUrl: selectedMediafile.svgUrl,
										});
									} else if (['image', 'pdf', 'video'].includes(fieldToChooseMediafile.type)) {
										let newData = {
											mediafileId: selectedMediafile.id || '', // set fallback value to '' to indicate we don't want to use default
											previewUrl:
												fieldToChooseMediafile.type === 'pdf'
													? selectedMediafile.svgUrl || ''
													: selectedMediafile.previewUrl || '', // set fallback value to '' is to indicate we don't want to use default
											optimisedUrl:
												fieldToChooseMediafile.type === 'pdf'
													? selectedMediafile.svgUrl || ''
													: selectedMediafile.optimisedUrl || '', // set fallback value to '' is to indicate we don't want to use default
											highResUrl:
												fieldToChooseMediafile.type === 'pdf'
													? selectedMediafile.svgUrl || ''
													: selectedMediafile.highResUrl || '', // set fallback value to '' is to indicate we don't want to use default
										};
										if (fieldToChooseMediafile.type === 'image') {
											newData.croppedImgUrl = ''; // reset the cropped img to '' when changing to a new image
											newData.clippedImgUrl = ''; // reset the clipped img to '' when changing to a new image
										}
										setFieldInputDataById(fieldToChooseMediafile.id, newData, fName);
									}
									setFieldToChooseMediafile(null);
								}}
							/>
						</RnDDialog>
					)}
				</div>
			)}
		</Rnd>
	);
}

PreviewControlPanel.propTypes = {
	open: PropTypes.bool.isRequired,
	mode: PropTypes.oneOf(['preview', 'design']).isRequired,
	selectedFieldIds: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	fields: PropTypes.array.isRequired,
	/**
	 * Artwork extra data
	 * it is "artworkExtra" in redux
	 */
	artworkExtra: PropTypes.object.isRequired,
	/**
	 * logged in user data
	 	{
			 uid: 'xxx', // user id
		 }
	 */
	userData: PropTypes.object.isRequired,
	/**
	 * fieldsPreviewValue - user input data of each field.
	 * The variable name may be fine tuned (e.g. userInputData, etc.), but require so many changes
	 * Note, fields may have default value; the default value is overriden by this user input data
	 * Format: {FIELD_ID: {}}
	 */
	// fieldsPreviewValue: PropTypes.object.isRequired,
	/**
	 * Set user input data
	 */
	// setFieldsPreviewValue: PropTypes.func.isRequired,
	/**
	 * Set user input data by field id
	 *
	 * @param {string} fieldId
	 * @param {object} inputData
	 */
	setFieldInputDataById: PropTypes.func.isRequired,
	/**
	 * Set template background
	 *
	 * @param {object} inputData
	 */
	setTemplateBGInputData: PropTypes.func.isRequired,
	/**
	 * Reset user input data to initial dataset
	 *
	 *
	 * @param {null}. No param required
	 */
	resetFieldInputData: PropTypes.func,
	/**
	 * field output data, combination of user input data & field default data
	 * guaranteed any value is not null|undefined, it will be '', true/false or "actual value"
	 */
	fieldOutputData: PropTypes.object.isRequired,
	/**
	 * settings and output data of template background
	 * NB: output data is the result of merging default & user input
	 */
	templateBGOutputDataWithSettings: PropTypes.object.isRequired,
	/**
	 * artwork template dimension
	 * format: {width: num, height: num}
	 */
	templateDimension: PropTypes.object.isRequired,

	handleImageCrop: PropTypes.func.isRequired,
	handleImageClip: PropTypes.func.isRequired,
	handleFieldFocus: PropTypes.func, // usage: handleFieldFocus(field)
	// handleSaveToPdfAndSVG: PropTypes.func,
	// handleExportSVG: PropTypes.func,
	// handlePrintToOutputTemplate: PropTypes.func,
	// onClose: PropTypes.func,
	// handleExportSVGToPdfByImg: PropTypes.func.isRequired,
	// handleExportToPdfByHighResImg: PropTypes.func.isRequired,
	// handleExportSVGToImg: PropTypes.func.isRequired,
	handleResetAnimation: PropTypes.func,
	disableUserDefined: PropTypes.bool,
	// zoom: PropTypes.number.isRequired, // double/float. the current zoom. e.g. 0.1, 0.25, 1, 1.35, 2, etc.
	// width: PropTypes.number.isRequired,
	// height: PropTypes.number.isRequired,

	/**
	 * Handle product picker selection
	 * @param {string} productPickerField. product picker field
	 * @param {object} ssRowData. selected spreadsheet row data.
		format (NB: columnId is "columnIndex" or  "$ref:{columnIndex}"):
		{
			[columnId]: {
				"value": "Cadbury Creme Egg",
				"mediafileId": "23554",
				"previewUrl": "https://xxxxx",
				"optimisedUrl": "https://xxxx",
				"highResUrl": "https://xxxx"
			},
			...
		}
	 */
	handleProductPickerSelection: PropTypes.func.isRequired,

	disableTitle: PropTypes.bool,
	/**
	 * Props for Rnd component
	 * it overrides the default props
	 */
	rndProps: PropTypes.object,

	/**
	 * customise className for Rnd component
	 */
	rndClassName: PropTypes.string,

	/**
	 * if true, all groups are expanded and will not be collapsed, no matter if the group is active or not
	 */
	expandAllGroups: PropTypes.bool,
};

PreviewControlPanel.defaultProps = {
	disableTitle: false,
	disableUserDefined: false,
	rndProps: {},
	rndClassName: '',
	expandAllGroups: false,
	// handleFieldFocus: null,
	// handlePrintToOutputTemplate: null,
};
export default React.memo(PreviewControlPanel);
