// import React from 'react';

// // Intl Lang
// import { FormattedMessage } from 'react-intl';

import {
	NOTIFY_SNACKBAR,
	NOTIFY_DETAIL,
	RESET_NOTIFY_DETAIL,
	REMOVE_NOTIFY_SNACKBAR,
	GENERAL_NOTIFY_MESSAGE,
} from 'redux/actions';

import { /*RESET_ERROR_MESSAGE,*/ GENERAL_ERROR_MESSAGE } from 'redux/actions';

// user manager failure actions
import { FETCH_USERDATA_FAILED } from 'redux/actions';

// screen manager screens failure actions
import {
	FETCH_SCREENMANAGER_SCREENS_FAIL,
	EDIT_SCREENMANAGER_SCREENS_FAIL,
	ADD_SCREENMANAGER_SCREENS_FAIL,
	DELETE_SCREENMANAGER_SCREENS_FAIL,
} from 'redux/actions';
// screen manager playlists failure actions
import {
	FETCH_SCREENMANAGER_PLAYLISTS_FAIL,
	EDIT_SCREENMANAGER_PLAYLISTS_FAIL,
	ADD_SCREENMANAGER_PLAYLISTS_FAIL,
	DELETE_SCREENMANAGER_PLAYLISTS_FAIL,
	DUPLICATE_SCREENMANAGER_PLAYLIST_FAIL,
} from 'redux/actions';
// screen manager playlists slide failure actions
import {
	GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
} from 'redux/actions';
// screen manager departments failure
import {
	FETCH_SCREENMANAGER_DEPARTMENTS_FAIL,
	EDIT_SCREENMANAGER_DEPARTMENTS_FAIL,
	ADD_SCREENMANAGER_DEPARTMENTS_FAIL,
	DELETE_SCREENMANAGER_DEPARTMENTS_FAIL,
} from 'redux/actions';
// screen manager screen schedules failure
import {
	GET_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	EDIT_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	ADD_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	DELETE_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_FAIL,
} from 'redux/actions';
// screen manager screen board failure
import { FETCH_SCREENMANAGER_SCREENBOARD_FAIL } from 'redux/actions';
// Artwork failure
// import {
// 	// FETCH_ARTWORK_FONTS_FAIL,
// 	// FETCH_ARTWORK_SPREADSHEETS_FAIL,
// 	// FETCH_ARTWORK_LISTS_FAIL,
// 	// FETCH_ARTWORK_AUTOIMPORT_FAIL,
// 	// FETCH_ARTWORK_OUTPUTTEMPLATES_FAIL,
// 	// FETCH_ARTWORK_CATEGORIES_FAIL,
// 	// SAVE_ARTWORK_DESIGN_TEMPLATE_FAIL,
// 	SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
// 	// RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
// } from 'redux/actions';

// aws resource failure
import { FETCH_AWS_RESOURCE_CREDENTIAL_FAIL } from 'redux/actions';

// file manager failure actions
// import { FILEMANAGER_FETCH_LIGHTBOXES_FAIL } from 'redux/actions';

const errorNotify = (msg) => {
	return {
		key: new Date().getTime() + Math.random(),
		message: msg,
		options: {
			variant: 'error',
			// action: (key) => (
			// 	<Button size="small" onClick={() => {
			// 		this.props.closeSnackbar(key)
			// 		}}>
			// 			Close
			// 	</Button>
			// ),
			autoHideDuration: 10000,
			anchorOrigin: { vertical: 'top', horizontal: 'center' },
		},
	};
};

const notifyGeneral = (msg, level, options) => {
	return {
		key: new Date().getTime() + Math.random(),
		message: msg,
		options: {
			variant: level,
			// action: (key) => (
			// 	<Button size="small" onClick={() => {
			// 		this.props.closeSnackbar(key)
			// 		}}>
			// 			Close
			// 	</Button>
			// ),
			autoHideDuration: 10000,
			anchorOrigin: { vertical: 'top', horizontal: 'center' },
			...options,
		},
	};
};

const defaultNotifier = {
	notifications: [],
	notificationDetail: {}, // notification details that to be shown in notifier
};

export const notifier = (state = defaultNotifier, action) => {
	const { type, error } = action; // error may be axios error (may have response data) or general JS Error; For general JS Error, error.message can be React component or string
	switch (type) {
		case NOTIFY_SNACKBAR:
			return {
				...state,
				notifications: [...state.notifications, { ...action.notification }],
			};
		case NOTIFY_DETAIL:
			return {
				...state,
				notificationDetail: action.notificationDetail,
			};
		case RESET_NOTIFY_DETAIL:
			return {
				...state,
				notificationDetail: {},
			};
		case REMOVE_NOTIFY_SNACKBAR:
			return {
				...state,
				notifications: state.notifications.filter(
					(notification) => notification.key !== action.key
				),
			};
		case GENERAL_NOTIFY_MESSAGE:
			return {
				...state,
				notifications: [
					...state.notifications,
					notifyGeneral(action.message, action.level, action.options),
				],
			};
		default:
			if (error) {
				let errorMsg = error.response
					? error.response.data.message || error.response.data.error || error.response.data.detail
					: error.message;
				// TODO: support multiple language in error message
				switch (type) {
					case GENERAL_ERROR_MESSAGE:
						break;
					case FETCH_SCREENMANAGER_SCREENS_FAIL:
						error.message = `Failed to get screens. [${errorMsg}]`;
						break;
					case EDIT_SCREENMANAGER_SCREENS_FAIL:
						error.message = `Failed to update the screen. [${errorMsg}]`;
						break;
					case ADD_SCREENMANAGER_SCREENS_FAIL:
						error.message = `Failed to add the screen. [${errorMsg}]`;
						break;
					case DELETE_SCREENMANAGER_SCREENS_FAIL:
						error.message = `Failed to delete screen. [${errorMsg}]`;
						break;
					case FETCH_SCREENMANAGER_PLAYLISTS_FAIL:
						error.message = `Failed to get playlists. [${errorMsg}]`;
						break;
					case EDIT_SCREENMANAGER_PLAYLISTS_FAIL:
						error.message = `Failed to update the playlist. [${errorMsg}]`;
						break;
					case ADD_SCREENMANAGER_PLAYLISTS_FAIL:
						error.message = `Failed to add the playlist. [${errorMsg}]`;
						break;
					case DELETE_SCREENMANAGER_PLAYLISTS_FAIL:
						error.message = `Failed to delete the playlist. [${errorMsg}]`;
						break;
					case DUPLICATE_SCREENMANAGER_PLAYLIST_FAIL:
						error.message = `Failed to duplicate the playlist. [${errorMsg}]`;
						break;
					case GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
						error.message = `Failed to get slide list. [${errorMsg}]`;
						break;
					case ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
						error.message = `Failed to add slide. [${errorMsg}]`;
						break;
					case EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
						error.message = `Failed to update the slide. [${errorMsg}]`;
						break;
					case DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
						error.message = `Failed to delete the slide. [${errorMsg}]`;
						break;
					case REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
						error.message = `Failed to change the order of the slides. [${errorMsg}]`;
						break;
					case FETCH_SCREENMANAGER_DEPARTMENTS_FAIL:
						error.message = `Failed to fetch departments. [${errorMsg}]`;
						break;
					case EDIT_SCREENMANAGER_DEPARTMENTS_FAIL:
						error.message = `Failed to update the department. [${errorMsg}]`;
						break;
					case ADD_SCREENMANAGER_DEPARTMENTS_FAIL:
						error.message = `Failed to add the department. [${errorMsg}]`;
						break;
					case DELETE_SCREENMANAGER_DEPARTMENTS_FAIL:
						error.message = `Failed to delete the department. [${errorMsg}]`;
						break;
					case GET_SCREENMANAGER_SCREEN_SCHEDULE_FAIL:
						error.message = `Failed to get screen schedule. [${errorMsg}]`;
						break;
					case EDIT_SCREENMANAGER_SCREEN_SCHEDULE_FAIL:
						error.message = `Failed to update the screen schedule. [${errorMsg}]`;
						break;
					case ADD_SCREENMANAGER_SCREEN_SCHEDULE_FAIL:
						error.message = `Failed to add the screen schedule. [${errorMsg}]`;
						break;
					case DELETE_SCREENMANAGER_SCREEN_SCHEDULE_FAIL:
						error.message = `Failed to delete the screen schedule. [${errorMsg}]`;
						break;
					case ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_FAIL:
						error.message = `Failed to publish the schedule to screens. [${errorMsg}]`;
						break;
					case FETCH_SCREENMANAGER_SCREENBOARD_FAIL:
						error.message = `Failed to fetch screens. [${errorMsg}]`;
						break;
					// case FETCH_ARTWORK_FONTS_FAIL:
					// 	error.message = `Failed to fetch fonts. [${errorMsg}]`;
					// 	break;
					// case FETCH_ARTWORK_SPREADSHEETS_FAIL:
					// 	error.message = `Failed to fetch spreadsheets. [${errorMsg}]`;
					// 	break;
					// case FETCH_ARTWORK_LISTS_FAIL:
					// 	error.message = `Failed to fetch lists. [${errorMsg}]`;
					// 	break;
					// case FETCH_ARTWORK_AUTOIMPORT_FAIL:
					// 	error.message = `Failed to fetch auto-import list. [${errorMsg}]`;
					// 	break;
					// case FETCH_ARTWORK_OUTPUTTEMPLATES_FAIL:
					// 	error.message = `Failed to fetch output templates. [${errorMsg}]`;
					// 	break;
					// case FETCH_ARTWORK_CATEGORIES_FAIL:
					// 	error.message = `Failed to fetch artwork categories. [${errorMsg}]`;
					// 	break;
					// case SAVE_ARTWORK_DESIGN_TEMPLATE_FAIL:
					// 	error.message = `Failed to save artwork template. [${errorMsg}]`;
					// 	break;
					// case SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL:
					// 	error.message = `Can not save template as draft. [${errorMsg}]`;
					// 	break;
					// case RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL:
					// 	error.message = `Can not retrieve draft template. [${errorMsg}]`;
					// 	break;
					case FETCH_AWS_RESOURCE_CREDENTIAL_FAIL:
						error.message = `Can not initialize resource${
							action.resource ? ' - ' + action.resource : ''
						}. [${errorMsg}]`;
						break;
					// case POST_PROCESS_UPLOADED_FILES_FAIL:
					// 	error.message = `Can not process the files. [${errorMsg}]`;
					// 	break;
					case FETCH_USERDATA_FAILED:
						if (error.response?.status !== 403) {
							error.message = `Failed to fetch user data. Reload the page or report it to administrator. [${errorMsg}]`;
						} else {
							// don't print error if 403
							error.message = '';
						}
						break;
					default:
						// For any other types, don't show error message
						error.message = null;
						break;
				}
				if (error.message) {
					// errorMsg = <FormattedMessage id='redux.reducers.notifier.NotifyError' values={{errMsg: `[${errorMsg}]`}} />;
					return {
						...state,
						notifications: [...state.notifications, errorNotify(error.message)],
					};
				} else {
					return state;
				}
			} else {
				return state;
			}
	}
};
