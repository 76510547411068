import React from 'react';
// import cx from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import { Popover, Typography, Button, Chip, Tooltip } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { StyledOutlinedTextFieldSelection } from '../CustomMUI/CustomMUI';

const useStyles = makeStyles((theme) => ({
	popoverPaper: {
		display: 'flex',
		padding: theme.spacing(2),
		minWidth: 250,
		flexDirection: 'column',
	},
	select: {
		width: '100%',
		margin: 'inherit',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(0.5),
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: theme.spacing(2),
	},
}));

function Filters({ filters, anchorElem, onClose, onFilterChange }) {
	const classes = useStyles();

	return (
		<Popover
			PaperProps={{ className: classes.popoverPaper }}
			open={Boolean(anchorElem)}
			anchorEl={anchorElem}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'left',
			}}
		>
			{filters.length === 0 ? (
				<Typography>No Filterable Columns</Typography>
			) : (
				filters.map((filter, idx) => {
					return (
						<StyledOutlinedTextFieldSelection
							key={`esign-filter-${idx}`}
							className={classes.select}
							fullWidth
							select
							label={filter.title}
							value={filter.value || ''}
							onChange={(e) => {
								onFilterChange(filter.id, e.target.value);
							}}
							noneOption
							options={filter.options}
						/>
					);
				})
			)}

			<div className={classes.buttonContainer}>
				<Button
					variant="contained"
					startIcon={<ClearIcon fontSize="small" />}
					size="small"
					onClick={onClose}
				>
					{`Close`}
				</Button>
			</div>
		</Popover>
	);
}

Filters.propTypes = {
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			options: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.string.isRequired,
					value: PropTypes.string.isRequired,
				})
			).isRequired,
		})
	).isRequired,
	anchorElem: PropTypes.any,
	onClose: PropTypes.func.isRequired,
	onFilterChange: PropTypes.func.isRequired,
};

Filters.defaultProps = {};

/**
 * filter chips comp
 */

const useFilterChipsStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'nowrap',
		flex: '1 0 auto',
		padding: theme.spacing(0, 1),
	},
	chip: {
		height: 'auto',
		marginLeft: 1,
		marginRight: 1,
		borderRadius: 20,
	},
	labelText: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textAlign: 'center',
		maxWidth: 200,
		textOverflow: 'ellipsis',
	},
}));

function FilterChips({ filters, resetFilter }) {
	const classes = useFilterChipsStyles();

	return (
		<div className={classes.wrapper}>
			{filters.map((filter, idx) => {
				return filter.value ? (
					<Tooltip
						title={`<<${filter.title}>> ${filter.value}`}
						key={`esign-filter-chips-${idx}`}
						arrow={true}
						enterDelay={500}
						enterNextDelay={500}
					>
						<Chip
							// key={`esign-filter-chips-${idx}`}
							color="secondary"
							size="small"
							className={classes.chip}
							component="div"
							label={
								<section>
									<div className={classes.labelText}> {`<<${filter.title}>>`}</div>
									<div className={classes.labelText}> {`${filter.value}`}</div>
								</section>
							}
							onDelete={() => resetFilter(filter.id)}
						/>
					</Tooltip>
				) : null;
			})}
		</div>
	);
}

FilterChips.propTypes = {
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			options: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.string.isRequired,
					value: PropTypes.string.isRequired,
				})
			).isRequired,
		})
	).isRequired,
	resetFilter: PropTypes.func.isRequired,
};

FilterChips.defaultProps = {};

export { Filters, FilterChips };
