import { createSelector } from 'reselect';

/**
 * Direct selector to the languageToggle state (redux store state)
 */
const selectLang = (state) => state.lang;

/**
 * Select the language locale
 */

const makeSelectLocale = () => createSelector(selectLang, (langState) => langState.locale);

export { makeSelectLocale };
