import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { ROUTES_PATH_LOGIN } from 'routes';

const Login = loadable(() => import('./components'), {
	fallback: <Loading size={50} />,
});

const LoginRoutes = [
	{
		path: ROUTES_PATH_LOGIN,
		component: Login,
		isPublic: true,
	},
];
export default LoginRoutes;
