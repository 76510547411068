// domain specific configurations override default if specified, otherwise default will be used
let domainCfg = {
	default: {
		name: 'VisualID',
		logo: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/visualid-logo-toolkit.jpg',
		loginBG: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/visualid-login-bg.png',
		homePath: '/home',
		headerBarTitle: 'VID Toolkit',
		maxFileUploaderTotalFilesSize: 2.5 * 1024 * 1024 * 1024, // 2.5GB. Max total combined size of files
		maxFileUploaderSingleFileSize: 2.5 * 1024 * 1024 * 1024, // 2.5GB. Max single file size
		maxFileUploaderZipfileSizeToUnzip: 2 * 1024 * 1024 * 1024, // 2GB. Max size of zip file that is allowed to unzip
		awsRegion: 'eu-west-1', // all aws resources should be in the same domain, e.g. instance, database, s3, etc.
		s3Bucket: 'visualid-mediafiles', // general s3 bucket used by a domain (e.g. fileUploader)
		s3TmpPathPrefix: 'tmp', // tmp folder in s3Bucket for dummy, temporary or non-important files
		s3BrowserBucket: '', // s3 bucket for s3Browser functionalities
		s3BucketCroppedImg: 'vid-mediafiles-ie', // s3 bucket for artwork cropped images
		s3CroppedImgPathPrefix: 'public/artcropimgs', // file path prefix in s3 bucket for artwork cropped images
		s3BucketVideoArtworkFieldImg: 'vid-mediafiles-ie', // s3 bucket to store images of rendered field on videoArtwork
		s3PathPrefixVideoArtworkFieldImg: 'videoArtwork_field_images', // file path prefix for images of rendered field on videoArtwork
		s3ArtworkTempStorage: {
			bucket: 'vid-mediafiles-ie',
			basePath: '_tmp/artwork-temp-files/',
		},
		userLevelRole: {
			10: 'Browse Only',
			20: 'Download High Res',
			30: 'Download & Submit',
			33: 'Designer',
			34: 'Data Supplier',
			35: 'Media Approver',
			40: 'Administrator',
			50: 'Super User',
			60: 'Developer',
		},
		bulkSearchField: false,
		enableArtwork: true,
		enableScreens: false,
		enableShoppingCart: false,
		enableSocialMedia: true,
		enableEsign: true,
		hasDimensions: false,
		// domain level access control. apply to whole domain no matter what user is.
		// It is an array of RegExp expressions, if any of them is matched, the route is disabled
		// Note: this is a disposable solution, will be replaced by server-side configuration on user's login
		disabledRoutes: [],
	},
	careplus: {
		name: 'Careplus',
		logo: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/careplus-logo-toolkit.jpg',
		loginBG: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/careplus-login-bg.jpg',
		headerBarTitle: 'The Knowledge Hub',
	},
	centra: {
		name: 'Centra',
		logo: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/centra-logo-toolkit.jpg',
		loginBG: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/centra-login-bg.jpg',
		headerBarTitle: 'Centra Local Marketing Toolkit',
	},
	heineken: {
		name: 'Heineken',
		logo: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/heineken-toolkit-logo.jpg',
		loginBG: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/heineken-brand-hero.jpg',
		headerBarTitle: 'Heineken Marketing Toolkit',
		homePath: '/explore',
		disabledRoutes: [/^\/(home|screen_manager).*$/g],
		disableBreadCrumbs: true,
	},
	spar: {
		name: 'Spar',
		logo: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/spar-logo-toolkit.jpg',
		loginBG: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/spar-login-bg.jpg',
		headerBarTitle: 'SPAR Marketing Toolkit',
	},
	digicel: {
		name: 'Digicel',
		logo: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-logo-toolkit.jpg',
		loginBG: 'https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-login-bg.png',
		headerBarTitle: 'Digicel Toolkit',
		maxFileUploaderTotalFilesSize: 15 * 1024 * 1024 * 1024, // 10GB. Max total combined size of files
		maxFileUploaderSingleFileSize: 15 * 1024 * 1024 * 1024, // 10GB. Max single file size
		awsRegion: 'us-east-1', // all aws resources should be in the same domain, e.g. instance, database, s3, etc.
		s3Bucket: 'visualid-digicel', // general s3 bucket used by the domain
		s3TmpPathPrefix: 'tmp', // tmp folder in s3Bucket for dummy, temporary or non-important files
		s3BrowserBucket: 'digicel-brandreboot', // s3 bucket for s3Browser functionalities
		s3BucketCroppedImg: 'vid-mediafiles-usa', // s3 bucket for artwork cropped images
		s3CroppedImgPathPrefix: 'public/artcropimgs', // file path prefix in s3 bucket for artwork cropped images
		userLevelRole: {
			10: 'Browse Only',
			20: 'Download High Res',
			30: 'Manufacturer / Agency',
			31: 'Market Team',
			33: 'Designer',
			34: 'Data Supplier',
			35: 'Market Admin',
			40: 'Group Admin',
			50: 'Super User',
			60: 'Developer',
		},
	},
};

export default domainCfg;
