/* eslint-disable react/jsx-pascal-case */
import { React, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

/** STYLING */
const slideStyle = () => ({
	slide: {
		height: '100%',
		backgroundColor: 'black',
		/* introduced to suppress any unwanted scrollbars, especially due to html animations */
		overflow: 'hidden',
	},
	slideElement: {
		height: '100%',
		width: '100%',
		margin: 'auto',
		// !important. to make the content auto fit
		objectFit: 'contain',
		zIndex: 999,
		backgroundColor: 'black',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
});

/** COMPONENT. Renders a preview for a slide as per its type. Read PropTypes to know more */
const SlidePreview = (props) => {
	const { type, mediaUrl, srcUrl, customStyle = {}, classes } = props;
	const [isLoading, setIsLoading] = useState(true);
	let SlideElement = <div />;

	switch (type) {
		case 'image':
			SlideElement = <img src={srcUrl || mediaUrl} alt="Slide_Image" />;
			break;
		case 'video':
			SlideElement = <video src={srcUrl || mediaUrl} muted autoPlay loop />;
			break;
		case 'html':
			SlideElement = <iframe src={mediaUrl} title="htmlFrame"></iframe>;
			break;
		case 'svg':
			SlideElement = <iframe src={mediaUrl} title="svgFrame"></iframe>;
			break;
		case 'weburl':
			SlideElement = <iframe title="weburlFrame" src={mediaUrl}></iframe>;
			break;
		default:
			SlideElement = <div />;
			break;
	}

	return (
		<div className={classes.slide} style={customStyle}>
			{isLoading && <CircularProgress className={classes.progress} />}
			<SlideElement.type
				{...SlideElement.props}
				className={classes.slideElement}
				onLoad={setIsLoading.bind(null, false)}
				onCanPlay={setIsLoading.bind(null, false)}
			/>
		</div>
	);
};

SlidePreview.propTypes = {
	/* Playlist's slide object props */
	id: PropTypes.string.isRequired,
	duration: PropTypes.number,
	type: PropTypes.string.isRequired,
	srcUrl: PropTypes.string,
	mediaUrl: PropTypes.string,
	// Style object to override default slide style
	customStyle: PropTypes.object,
	classes: PropTypes.object,
};

SlidePreview.defaultProps = {
	srcUrl: '',
	mediaUrl: '',
	customStyle: {},
};

export default withStyles(slideStyle)(SlidePreview);
