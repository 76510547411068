import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

// Constants
import { ART_VARIABLES } from '../../Constants';

import {
	Typography,
	// Button,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Slide,
	DialogContent,
	DialogActions,
	TablePagination,
	// CircularProgress,
	Card,
	CardContent,
	Avatar,
	// Pagination,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Close as CloseIcon } from '@mui/icons-material';
import { deepOrange } from '@mui/material/colors';

import SVGPreview from '../SVGPreview/SVGPreview';

import { fileMgrFetchSSById } from 'restful';
import { buildPrintData, convertSSContentToRTableData } from './esignUtils.jsx';

// intl lang
// import { useIntl } from 'react-intl';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesTablePaginationActions = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));
const TablePaginationActions = (props) => {
	const classes = useStylesTablePaginationActions();
	// page in props is 0-indexed, onPageChange in props requires 0-indexed number
	const { count, page, rowsPerPage, onPageChange } = props;

	return (
		<div className={classes.root}>
			<Pagination
				count={Math.ceil(count / rowsPerPage)}
				page={page + 1}
				showFirstButton
				showLastButton
				onChange={(e, newPage) => onPageChange(e, newPage - 1)}
			/>
		</div>
	);
};

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	itemsWrapper: {
		// position: 'relative', // don't set position to "content" so that loader covers the whole dialog
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		// backgroundColor: `rgba(0,0,0,0.1)`,
	},
	loader: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		zIndex: 2,
		opacity: 1,
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		WebkitTapHighlightColor: 'transparent',
		// borderRadius: 4,
	},
	hidden: {
		zIndex: -1,
		opacity: 0,
	},
	SVGPreviewCardRoot: {
		minWidth: ART_VARIABLES.cssStyles.minItemWidthInProofView,
		margin: theme.spacing(1),
		// backgroundColor: `rgba(0,0,0,0.2)`,
	},
	SVGPreviewCardContent: {
		padding: 'unset',
		position: 'relative',
		backgroundColor: `rgba(0,0,0,0.1)`,
		'&:last-child': {
			padding: 'unset',
		},
	},
	itemIndexAvatar: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		fontSize: '0.7rem',
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: deepOrange[500],
	},
	noData: {
		// textAlign: 'center',
		// paddingTop: theme.spacing(3),

		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		// zIndex: 2,
		// opacity: 1,
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

function ProofDialog({
	open,
	title,
	ssId,
	artTemplate,
	// artworkExtra,
	filters,
	searchKeywords,
	handleClose,
	notifyGeneral,
}) {
	const classes = useStyles();
	// const intl = useIntl();
	const [paginationSettings, setPaginationSettings] = React.useState({
		// count: 0,
		rowsPerPage: 25,
		page: 0,
	});
	const [totalNumItems, setTotalNumItems] = React.useState(-1);
	const [itemsOutputData, setItemsOutputData] = React.useState([]);
	// const [ssContent, setSSContent] = React.useState(null);
	const [isFetchingSSContent, setIsFetchingSSContent] = React.useState(false);

	// NB: fonts are loaded when loading the template, hence no need to load fonts here
	// onComponentMounted
	// React.useEffect(() => {
	// 	/**
	// 	 * initFontface: load font used by template to document so that svg preview can be rendered properly
	// 	 * @param {object} fields. all fields in template
	// 	 * @param {array} fontList. all available fonts
	// 	 * @returns {void}
	// 	 */
	// 	const initFontface = (fields, fontList) => {
	// 		// get all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	// 		let fontlistInTemplate = geFontListInTemplateFields(fields, fontList, ART_VARIABLES);
	// 		return Promise.all(
	// 			fontlistInTemplate.map((fontOption) => loadFontfaceToDocument(fontOption))
	// 		).then((results) => {
	// 			let errorResults = results.filter((item) => item.error);
	// 			if (errorResults.length > 0) {
	// 				let msg = (
	// 					<div>
	// 						<Typography variant="body1" component={'div'} gutterBottom>
	// 							{intl.formatMessage({
	// 								id: 'pages.Artwork.components.Design.loadingFontFailureMsg',
	// 							})}
	// 							<Typography
	// 								variant="body2"
	// 								component={'div'}
	// 								style={{ /* color: '#ff9800',  fontStyle: 'italic',*/ fontSize: '0.8rem' }}
	// 							>
	// 								<ul>
	// 									{errorResults.map((result) => (
	// 										<li key={result.error.font.name}>{`${result.error.font.name}`}</li>
	// 									))}
	// 								</ul>
	// 							</Typography>
	// 						</Typography>
	// 					</div>
	// 				);
	// 				notifyGeneral(msg, 'error', { autoHideDuration: null });
	// 			}
	// 		});
	// 	};

	// 	initFontface(artTemplate.fields, artworkExtra.fonts);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	/**
	 * Fetch spreadsheet content
	 */
	React.useEffect(() => {
		setIsFetchingSSContent(true);
		let fetchingCols = artTemplate.metadata.columnIndices;
		let primaryCol = artTemplate.metadata.primaryColIndex.toString();
		let selectedFilters = filters.filter((filter) => Boolean(filter.value));
		fileMgrFetchSSById({
			ssId: ssId,
			queryParams: {
				...(searchKeywords && artTemplate.metadata.searchColumnIndices.length > 0
					? {
							searchString: searchKeywords,
							searchColumnIndices: artTemplate.metadata.searchColumnIndices.join(','),
					  }
					: {}),
				...(selectedFilters.length > 0
					? {
							esignFilterIndices: selectedFilters.map((filter) => filter.id).join('|'),
							esignFilterValues: selectedFilters
								.map((filter) => encodeURIComponent(filter.value)) // using encodeURIComponent() to escape the special chars (particularly "|"). There is another time of encodeURIComponent() invoked by axios automatically
								.join('|'),
					  }
					: {}),
				offset: paginationSettings.page * paginationSettings.rowsPerPage,
				limit: paginationSettings.rowsPerPage,
				columnIndices: fetchingCols.includes(primaryCol)
					? fetchingCols.join(',') || null
					: [...fetchingCols, primaryCol].join(','),
			},
		})
			.then((response) => {
				setTotalNumItems(response.data.totalFound);
				let ssRows = convertSSContentToRTableData(response.data, {});
				let rowQty = ssRows.reduce((accu, row) => {
					return { ...accu, [row.rowId]: 1 };
				}, {});
				let multiplePrintData = buildPrintData(ssRows, rowQty, artTemplate);

				setItemsOutputData(multiplePrintData.map((printData) => printData.data));
			})
			.catch((err) => {
				let msg = err.response?.data.message || err.message;
				notifyGeneral(`Can not fetch page content, please try again later. [${msg}]`, 'error');
				setItemsOutputData([]);
			})
			.then(() => setIsFetchingSSContent(false));
	}, [
		artTemplate,
		artTemplate.metadata.columnIndices,
		artTemplate.metadata.primaryColIndex,
		filters,
		notifyGeneral,
		paginationSettings.page,
		paginationSettings.rowsPerPage,
		searchKeywords,
		ssId,
	]);

	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						{title}
					</Typography>
					<IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent dividers>
				<div className={cx(classes.loader, { [classes.hidden]: !isFetchingSSContent })}>
					{
						// <CircularProgress />
					}
					{
						<Typography
							variant="h4"
							style={{
								padding: '4px 16px',
								backgroundColor: '#eeeeee',
								borderRadius: 16,
							}}
						>
							Loading...
						</Typography>
					}
				</div>

				<div
					className={cx(classes.noData, {
						[classes.hidden]: isFetchingSSContent || itemsOutputData.length > 0,
					})}
				>
					<Typography color="error" variant="h4">
						No Data...
					</Typography>
				</div>
				{itemsOutputData.length > 0 && (
					<div className={classes.itemsWrapper}>
						{itemsOutputData.map((itemOutputData, idx) => {
							return (
								<Card className={classes.SVGPreviewCardRoot} key={`proof-item-${idx}`}>
									<CardContent className={classes.SVGPreviewCardContent}>
										<Avatar className={classes.itemIndexAvatar}>
											{paginationSettings.page * paginationSettings.rowsPerPage + idx + 1}
										</Avatar>
										<SVGPreview
											allowAnimation={false}
											width={artTemplate.dimension.width}
											height={artTemplate.dimension.height}
											fields={artTemplate.fields}
											fieldOutputData={itemOutputData}
											pausingAnimation={false}
											isVideoArtwork={false}
										/>
									</CardContent>
								</Card>
							);
						})}
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<TablePagination
					className={classes.pagination}
					// classes={{ spacer: classes.paginationToleft }}
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={totalNumItems}
					// rowsPerPage={rowsPerPage}
					// page={pageIndex}
					labelRowsPerPage="Items per page:"
					SelectProps={{
						inputProps: { 'aria-label': 'items per page:' },
						// disabled: disableRowsPerPage,
						// native: true,
					}}
					onPageChange={(e, newPage) => {
						setPaginationSettings({ ...paginationSettings, page: newPage });
					}}
					onRowsPerPageChange={(e) => {
						setPaginationSettings({ ...paginationSettings, rowsPerPage: Number(e.target.value) });
					}}
					ActionsComponent={TablePaginationActions}
					{...paginationSettings}
				/>
			</DialogActions>
		</Dialog>
	);
}

ProofDialog.propTypes = {
	// // list of artwork templates for selection
	// artTemplateList: PropTypes.arrayOf(
	// 	PropTypes.shape({
	// 		id: PropTypes.string.isRequired,
	// 		name: PropTypes.string.isRequired,
	// 		previewUrl: PropTypes.string.isRequired,
	// 	})
	// ).isRequired,
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	ssId: PropTypes.string.isRequired,
	artTemplate: PropTypes.object.isRequired,
	// artworkExtra: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	filters: PropTypes.array.isRequired,
	searchKeywords: PropTypes.string.isRequired,
};

ProofDialog.defaultProps = {};

export default ProofDialog;
