import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';

const LoadingStyle = (theme) => ({
	progress: {
		margin: theme.spacing(2),
	},
	container: {
		textAlign: 'center',
	},
});

class Loading extends Component {
	render() {
		// let loadingStyle = {textAlign: 'center', margin: 'auto'};
		let { classes, size, thickness } = this.props;
		return (
			<div className={classes.container}>
				<CircularProgress className={classes.progress} size={size} thickness={thickness} />
			</div>
		);
	}
}

Loading.propTypes = {
	classes: PropTypes.object.isRequired,
	size: PropTypes.number,
	thickness: PropTypes.number,
};

Loading.defaultProps = {
	classes: {},
	size: 40,
	thickness: 3.6,
};

export default withStyles(LoadingStyle)(Loading);
