import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { deepClone, immutableUpdate } from 'utils/libHelper';
// import { PerfectScrollWrapper } from 'components';
import { useDrag, useDrop } from 'react-dnd';

// custom components
import { InputBox } from 'components';

// MUI components
import {
	Divider,
	List,
	ListSubheader,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	IconButton,
	Typography,
	Tooltip,
} from '@mui/material';
import {
	// ExpandLess as ExpandLessIcon,
	ArrowRight as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	TextFields as TextFieldIcon,
	Photo as ImageFieldIcon,
	MovieCreation as VideoFieldIcon,
	ViewList as GridFieldIcon,
	Delete as DeleteIcon,
	FileCopy as DuplicateIcon,
	Warning as UnknowFieldIcon,
} from '@mui/icons-material';
import {
	LayersTriple as GroupIcon,
	FilePdfBox as PdfFieldIcon,
	Barcode as BarcodeIcon,
	CheckboxMultipleMarked as GroupSelectIcon,
	RenameBox as RenameIcon,
} from 'mdi-material-ui';

import { blueGrey } from '@mui/material/colors';

// intl lang
import { useIntl } from 'react-intl';

const DividerHor = withStyles((theme) => ({
	fullWidth: {
		width: '100%',
	},
}))(({ classes, ...rest }) => {
	return <Divider className={classes.fullWidth} {...rest} />;
});
// const DividerVer = withStyles(theme => ({
// 	dividerVertical: {
// 		alignSelf: 'stretch',
// 		height: 'auto',
// 		margin: theme.spacing(0.25, 0.5),
// 	},
// }))(({ classes, ...rest }) => {
// 	return <Divider orientation="vertical" className={classes.dividerVertical} {...rest} />;
// });
const StyledTooltipArrow = withStyles((theme) => ({
	arrow: {
		color: blueGrey[800], // theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: blueGrey[800], // theme.palette.common.black,
	},
}))(({ classes, ...rest }) => {
	return <Tooltip classes={classes} arrow {...rest} />;
});

const DraggableTypes = {
	FIELD: 'field',
	GROUP: 'group',
};

let groupHoverTimer = null;
const clearHoverGroupTimer = (timer = groupHoverTimer || {}) => {
	clearTimeout(timer.id);
	groupHoverTimer = null;
};

const ReactDndListSubHeader = withStyles((theme) => ({
	listGroupheader: {
		display: 'flex',
		alignItems: 'center',
		// justifyContent: 'space-between',
		color: 'inherit',
		padding: theme.spacing(1), //`0px ${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
		cursor: 'pointer',
	},
	expandButton: {
		marginRight: theme.spacing(2),
	},
	onDragging: {
		opacity: 0.2,
	},
	onHover: {
		backgroundColor: 'rgba(0, 0, 0, 0.15)',
	},
	actionButtonIcon: {
		margin: 2,
		fontSize: '1rem',
	},
	actionButtonWrapper: {
		position: 'absolute',
		display: 'flex',
		width: `calc(100% - ${theme.spacing(1)})`,
		paddingRight: theme.spacing(2),
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
}))(
	({
		classes,
		group,
		isEmpty,
		onClick,
		icon,
		onIconClick,
		moveGroup,
		handleDraggingGroupBegin,
		handleFieldOverGroup,
		handleGroupSelect,
		findGroupIndex,
		handleGroupDrop,
		handleDelGroupWithFields,
		handleRenameGroup,
		handleDuplicateGroupWithFields,
		...rest
	}) => {
		const intl = useIntl();
		let ref = React.useRef(null);
		const [editing, setEditing] = React.useState(false);
		const [hover, setHover] = React.useState(false);
		const handleMouseEnter = () => {
			if (!draggingItem && !hover) setHover(true);
		};
		const handleMouseLeave = () => {
			if (!draggingItem && hover) setHover(false);
		};
		const [{ isDragging }, drag] = useDrag({
			// item: { type: DraggableTypes.GROUP, group: group, originalIndex: findGroupIndex(group) },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			// begin: () => {
			// 	handleDraggingGroupBegin();
			// 	setHover(false);
			// },
			// fix break changes in react-dnd v14
			type: DraggableTypes.GROUP,
			item: () => {
				handleDraggingGroupBegin();
				setHover(false);
				return { type: DraggableTypes.GROUP, group: group, originalIndex: findGroupIndex(group) };
			},
			end: (item, monitor) => {
				// somehow, the groups is not latest data here, but it is latest in drop()
				// So we fire the drop handler in drop() down below
				if (!monitor.didDrop()) {
					// No successful drop, reset group to its original position
					moveGroup({ draggingGroup: item.group, atIndex: item.originalIndex });
				}
			},
		});
		const [{ draggingItem }, drop] = useDrop({
			accept: [DraggableTypes.GROUP, DraggableTypes.FIELD],
			canDrop: ({ type }) => type === DraggableTypes.GROUP,
			collect: (monitor) => ({
				draggingItem: monitor.getItem(),
			}),
			drop: () => handleGroupDrop(),
			hover: (item) => {
				if (groupHoverTimer && groupHoverTimer.elem === group.name) return;

				// clear group hover timer
				if (groupHoverTimer) clearHoverGroupTimer(groupHoverTimer);

				if (item.type === DraggableTypes.GROUP && group.name !== item.group.name)
					moveGroup({ draggingGroup: item.group, atIndex: findGroupIndex(group) });
				else if (item.type === DraggableTypes.FIELD) {
					groupHoverTimer = { elem: group.name };
					groupHoverTimer.id = setTimeout(() => {
						handleFieldOverGroup(item.field, group.name);
						clearHoverGroupTimer(groupHoverTimer);
					}, 500);
				}
			},
		});
		drag(drop(ref));

		return (
			<div>
				{editing && (
					<InputBox
						label={intl.formatMessage({
							id: 'pages.Artwork.components.Sidemenu.SidePanelLayers.groupNameLabelText',
						})}
						value={group.name}
						// handleOnChange={name => setNewGroupName(name)}
						handleCancel={() => setEditing(false)}
						handleConfirm={(newGroupName) => {
							setEditing(false);
							if (newGroupName) handleRenameGroup(group.name, newGroupName);
						}}
					/>
				)}
				{!editing && (
					<ListSubheader
						component="div"
						disableSticky
						className={cx(classes.listGroupheader, {
							[classes.onDragging]: isDragging,
							[classes.onHover]: hover,
						})}
						onClick={onClick}
						ref={ref}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						{hover && (
							<div className={classes.actionButtonWrapper}>
								{!isEmpty && (
									<React.Fragment>
										<StyledTooltipArrow title={intl.formatMessage({ id: 'GENERAL.Rename' })}>
											<IconButton
												edge="end"
												color="inherit"
												size="small"
												aria-label="group-rename"
												onClick={(e) => {
													e.stopPropagation();
													setEditing(true);
												}}
											>
												<RenameIcon fontSize="small" className={classes.actionButtonIcon} />
											</IconButton>
										</StyledTooltipArrow>
										<StyledTooltipArrow title={intl.formatMessage({ id: 'GENERAL.Duplicate' })}>
											<IconButton
												edge="end"
												color="inherit"
												size="small"
												aria-label="group-duplicate"
												onClick={(e) => {
													e.stopPropagation();
													handleDuplicateGroupWithFields(group.name);
												}}
											>
												<DuplicateIcon fontSize="small" className={classes.actionButtonIcon} />
											</IconButton>
										</StyledTooltipArrow>
										<StyledTooltipArrow title={intl.formatMessage({ id: 'GENERAL.Select' })}>
											<IconButton
												edge="end"
												color="inherit"
												size="small"
												onClick={(e) => {
													e.stopPropagation();
													handleGroupSelect(group.name);
												}}
												aria-label="group-selection"
											>
												<GroupSelectIcon fontSize="small" className={classes.actionButtonIcon} />
											</IconButton>
										</StyledTooltipArrow>
									</React.Fragment>
								)}
								<StyledTooltipArrow title={intl.formatMessage({ id: 'GENERAL.Delete' })}>
									<IconButton
										edge="end"
										color="inherit"
										size="small"
										aria-label="group-delete"
										onClick={(e) => {
											e.stopPropagation();
											handleDelGroupWithFields(group.name);
										}}
									>
										<DeleteIcon fontSize="small" className={classes.actionButtonIcon} />
									</IconButton>
								</StyledTooltipArrow>
							</div>
						)}
						{icon && (
							<IconButton
								className={classes.expandButton}
								color="inherit"
								size="small"
								onClick={onIconClick}
							>
								{icon}
							</IconButton>
						)}
						<Typography align="left" color="inherit" variant="body1" style={{ fontWeight: 700 }}>
							{`${group.name}${isEmpty ? ' - (Empty)' : ''}`}
						</Typography>
					</ListSubheader>
				)}
			</div>
		);
	}
);

const ReactDndListItem = withStyles((theme) => ({
	listItem: {
		padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
		cursor: 'pointer',
		transform: 'translateZ(0)',
		// '&:hover': {
		// 	backgroundColor: 'rgba(0, 0, 0, 0.15)',
		// },
	},
	hover: {
		backgroundColor: 'rgba(0, 0, 0, 0.15)',
	},
	highlight: {
		backgroundColor: 'rgba(0, 0, 0, 0.10)',
	},
	listItemIcon: {
		minWidth: 'unset',
		paddingRight: theme.spacing(1),
		color: 'inherit',
	},
	isDragging: {
		opacity: 0.2,
	},
	actionButtonWrapper: {
		position: 'absolute',
		display: 'flex',
		width: `calc(100% - ${theme.spacing(1)})`,
		paddingRight: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	actionButtonIcon: {
		margin: 2,
		fontSize: '1rem',
	},
}))(
	({
		classes,
		field,
		FieldIcon,
		text,
		highlight,
		moveField,
		handleFieldSelect,
		findFieldIndex,
		handleFieldDrop,
		handleFieldDelete,
		handleFieldDuplicate,
		showActions,
		...rest
	}) => {
		const intl = useIntl();
		let ref = React.useRef(null);
		const [hover, setHover] = React.useState(false);
		const handleMouseEnter = () => {
			if (!draggingItem && !hover) setHover(true);
		};
		const handleMouseLeave = () => {
			if (!draggingItem && hover) setHover(false);
		};
		const [{ isDragging }, drag] = useDrag({
			// item: {
			// 	type: DraggableTypes.FIELD,
			// 	field: field,
			// 	originalIndex: findFieldIndex(field),
			// },
			// begin: () => setHover(false),
			// fix break changes in react-dnd v14
			type: DraggableTypes.FIELD,
			item: () => {
				setHover(false);
				return { type: DraggableTypes.FIELD, field: field, originalIndex: findFieldIndex(field) };
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			isDragging: (monitor) => field.id === monitor.getItem().field.id,
			end: (item, monitor) => {
				// somehow, the fields is not latest data here, but it is latest in drop()
				// So we fire the drop handler in drop() down below
				if (!monitor.didDrop()) {
					// No successful drop, reset field to its original position
					moveField({ draggingField: item.field, atIndex: item.originalIndex });
				}
			},
		});
		const [{ draggingItem }, drop] = useDrop({
			accept: DraggableTypes.FIELD,
			collect: (monitor) => ({
				draggingItem: monitor.getItem(), // indicates if there is an item being dragged, so that we don't listen on "hover" event
			}),
			drop: ({ field: draggingField }) => {
				handleFieldDrop();
				// select the dragging field after drop
				handleFieldSelect({ field: draggingField });
			},
			hover: ({ type, field: draggingField }) => {
				// clear group hover timer
				if (groupHoverTimer) clearHoverGroupTimer(groupHoverTimer);
				if (type === DraggableTypes.FIELD && draggingField.id !== field.id) {
					moveField({
						// always update the groupName with the one in dropping target in case the group was changed
						draggingField: { ...draggingField, groupName: field.groupName },
						atIndex: findFieldIndex(field),
					});
				}
			},
		});
		drag(drop(ref));

		return (
			<ListItem
				ref={ref}
				// component="div"
				// button
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					handleFieldSelect({ field }, e.ctrlKey || e.metaKey);
				}}
				className={cx(classes.listItem, {
					[classes.isDragging]: isDragging,
					[classes.highlight]: highlight && !hover,
					[classes.hover]: hover,
				})}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<ListItemIcon className={classes.listItemIcon}>
					<FieldIcon fontSize="small" color="inherit" />
				</ListItemIcon>
				<ListItemText primary={text} primaryTypographyProps={{ variant: 'inherit' }} />
				{showActions && hover && (
					<div className={classes.actionButtonWrapper}>
						<StyledTooltipArrow title={intl.formatMessage({ id: 'GENERAL.Duplicate' })}>
							<IconButton
								edge="end"
								color="inherit"
								size="small"
								aria-label="duplicate"
								onClick={(e) => {
									e.stopPropagation();
									handleFieldDuplicate(field);
								}}
							>
								<DuplicateIcon fontSize="small" className={classes.actionButtonIcon} />
							</IconButton>
						</StyledTooltipArrow>
						<StyledTooltipArrow title={intl.formatMessage({ id: 'GENERAL.Delete' })}>
							<IconButton
								edge="end"
								color="inherit"
								size="small"
								aria-label="delete"
								onClick={(e) => {
									e.stopPropagation();
									handleFieldDelete(field);
								}}
							>
								<DeleteIcon fontSize="small" className={classes.actionButtonIcon} />
							</IconButton>
						</StyledTooltipArrow>
					</div>
				)}
			</ListItem>
		);
	}
);

const titleHeight = 74;
const borderColor = blueGrey[300];
const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
		width: '100%',
		height: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: 'inherit',
		paddingBottom: theme.spacing(4),
	},
	title: {
		width: '100%',
		position: 'relative',
		color: 'inherit',
		backgroundColor: 'inherit',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(16),
		// textTransform: 'capitalize',
		userSelect: 'none',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		height: titleHeight,
		padding: '10px 2px',
		// margin: 'auto',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'&:after': {
			content: '""',
			margin: '0 auto',
			width: '100%',
			// padding: '4px 0',
			opacity: 0.8,
			borderBottom: `1px solid ${borderColor}`,
		},
	},
	titleText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
	},

	contentWrapper: {
		padding: `${theme.spacing(0.5)} ${theme.spacing(0)}`,
		width: `calc(100% - calc(${theme.spacing(0)} * 2))`,
		height: `calc(100% - ${titleHeight}px)`,
		color: 'inherit',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		// paddingBottom: theme.spacing(2),
		// fontSize: '0.8rem',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	listRoot: {
		width: '100%',
		height: '100%',
		backgroundColor: 'inherit',
		padding: 0,
	},
	listGroupWrapper: {
		width: '100%',
		color: 'inherit',
		fontSize: 'inherit',
	},
	collapseWrapper: {
		paddingLeft: theme.spacing(2),
	},
}));

// const getTemplateFieldsObject = fields => {
// 	return fields.reduce((accu, elem) => {
// 		if (!accu[elem.groupName]) {
// 			accu[elem.groupName] = [];
// 		}
// 		accu[elem.groupName].push(elem);
// 		return accu;
// 	}, {});
// };
// const getGroups = groups => groups.map(g => g.name);

function SidePanelLayers({
	designerTemplate,
	handleEleSelect,
	handleGroupSelect,
	selectedFieldIds,
	handleReorderFields,
	handleReorderGroups,
	handleDuplicateFields,
	handleDelFields,
	handleDelGroupWithFields,
	handleRenameGroup,
	handleDuplicateGroupWithFields,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const [groupExpand, setGroupExpand] = React.useState({});
	React.useEffect(() => {
		if (selectedFieldIds.length > 0) {
			setGroupExpand(
				designerTemplate.fields.reduce((accu, f) => {
					if (selectedFieldIds.includes(f.id)) {
						accu[f.groupName] = true;
					}
					return accu;
				}, {})
			);
		}
	}, [designerTemplate.fields, selectedFieldIds]);

	const [groups, setGroups] = React.useState([]);
	React.useEffect(() => {
		setGroups([...designerTemplate.groups]);
	}, [designerTemplate.groups]);

	const [fields, setFields] = React.useState([]);
	React.useEffect(() => {
		setFields(deepClone(designerTemplate.fields));
	}, [designerTemplate.fields]);

	const moveField = React.useCallback(
		({ draggingField, atIndex }) => {
			let dragIndex = fields.findIndex((f) => f.id === draggingField.id);
			let newFields = immutableUpdate(fields, {
				$splice: [
					[dragIndex, 1],
					[atIndex, 0, draggingField],
				],
			});
			setFields(newFields);
		},
		[fields]
	);
	const moveGroup = React.useCallback(
		({ draggingGroup, atIndex }) => {
			let newGroups = immutableUpdate(groups, {
				$splice: [
					[groups.findIndex((g) => g.name === draggingGroup.name), 1],
					[atIndex, 0, draggingGroup],
				],
			});
			setGroups(newGroups);
		},
		[groups]
	);
	const handleFieldOverGroup = (field, hoverGroupName) => {
		let index1stFieldInGroup = fields.findIndex((f) => f.groupName === hoverGroupName);
		if (index1stFieldInGroup !== -1) {
			// the group has field(s)
			setGroupExpand({
				// ...groupExpand,
				[field.groupName]: true,
				[hoverGroupName]: true,
			});
		} else {
			// the group has no field
			let fieldIndex = fields.findIndex((f) => f.id === field.id);
			let newFields = immutableUpdate(fields, {
				[fieldIndex]: {
					$merge: { groupName: hoverGroupName },
				},
			});
			setFields(newFields);
		}
	};
	return (
		<div className={classes.wrapper}>
			<div className={classes.title}>
				<div className={classes.titleText}>
					<GroupIcon style={{ marginRight: 8 }} fontSize="small" />
					{intl.formatMessage({
						id: 'pages.Artwork.components.Sidemenu.SidePanelLayers.titleText',
					})}
				</div>
			</div>

			<div className={classes.contentWrapper}>
				{groups.length === 0 ? (
					<div>
						{intl.formatMessage({
							id: 'pages.Artwork.components.Sidemenu.SidePanelLayers.emptyTemplateMsg',
						})}
					</div>
				) : (
					<List component="nav" className={classes.listRoot}>
						{groups.map((group) => {
							let groupName = group.name;
							let fieldsInGroup = fields.filter((f) => f.groupName === groupName);
							const emptyGroup = fieldsInGroup.length === 0;
							return (
								<div className={classes.listGroupWrapper} key={groupName}>
									<ReactDndListSubHeader
										{...{
											group,
											isEmpty: emptyGroup,
											onClick: () =>
												setGroupExpand({
													...groupExpand,
													[groupName]: emptyGroup ? false : !groupExpand[groupName],
												}),
											moveGroup,
											handleGroupSelect,
											handleFieldOverGroup,
											handleDelGroupWithFields,
											handleRenameGroup,
											handleDuplicateGroupWithFields,
											handleDraggingGroupBegin: () => setGroupExpand({}),
											findGroupIndex: (group) => groups.findIndex((g) => g.name === group.name),
											handleGroupDrop: () => handleReorderGroups(groups),
											icon:
												!emptyGroup && groupExpand[groupName] ? (
													<ExpandMoreIcon fontSize="small" />
												) : (
													<ExpandLessIcon fontSize="small" />
												),
											onIconClick: () =>
												setGroupExpand({
													...groupExpand,
													[groupName]: emptyGroup ? false : !groupExpand[groupName],
												}),
										}}
									/>
									{!emptyGroup && (
										<Collapse
											in={Boolean(groupExpand[groupName])}
											timeout="auto"
											className={classes.collapseWrapper}
											unmountOnExit
										>
											<List component="div" disablePadding>
												{fieldsInGroup
													.map((f) => {
														let FieldIcon = UnknowFieldIcon;
														switch (f.type) {
															case 'text':
																FieldIcon = TextFieldIcon;
																break;
															case 'image':
																FieldIcon = ImageFieldIcon;
																break;
															case 'pdf':
																FieldIcon = PdfFieldIcon;
																break;
															case 'video':
																FieldIcon = VideoFieldIcon;
																break;
															case 'barcode':
																FieldIcon = BarcodeIcon;
																break;
															case 'grid':
																FieldIcon = GridFieldIcon;
																break;
															default:
																break;
														}
														const isSelected = selectedFieldIds.includes(f.id);

														return (
															<ReactDndListItem
																key={f.id}
																{...{
																	field: f,
																	FieldIcon,
																	text: f.name,
																	highlight: isSelected,
																	moveField,
																	// handleFieldClick: handleEleSelect,
																	handleFieldSelect: handleEleSelect,
																	findFieldIndex: (field) =>
																		fields.findIndex((f) => f.id === field.id),
																	handleFieldDrop: () => handleReorderFields(fields),
																	handleFieldDelete: (field) => handleDelFields([field]),
																	handleFieldDuplicate: (field) => handleDuplicateFields([field]),
																	showActions: true,
																	// isSelected && selectedFieldIds.length > 1 ? false : true,
																}}
															/>
														);
													})
													.reduce(
														(accu, elem, idx) =>
															accu.length === 0
																? [elem]
																: [...accu, <DividerHor key={idx} />, elem],
														[]
													)}
												<DividerHor />
											</List>
										</Collapse>
									)}
								</div>
							);
						})}
					</List>
				)}
			</div>
		</div>
	);
}

SidePanelLayers.propTypes = {
	designerTemplate: PropTypes.object.isRequired,
	handleEleSelect: PropTypes.func.isRequired,
	handleGroupSelect: PropTypes.func.isRequired,
	selectedFieldIds: PropTypes.array.isRequired,
	handleReorderFields: PropTypes.func.isRequired,
	handleReorderGroups: PropTypes.func.isRequired,
	handleDuplicateFields: PropTypes.func.isRequired,
	handleDelFields: PropTypes.func.isRequired,
	handleDelGroupWithFields: PropTypes.func.isRequired,
	handleRenameGroup: PropTypes.func.isRequired,
	handleDuplicateGroupWithFields: PropTypes.func.isRequired,
};

SidePanelLayers.defaultProps = {};
export default SidePanelLayers;
