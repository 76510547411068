import React from 'react';

// import cx from 'classnames';

import PropTypes from 'prop-types';
import { IconButton, Box, Checkbox } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { _ } from 'utils/libHelper';
import { useIntl } from 'react-intl';

import { ReactTableV8MUIView } from 'components';

// react-table
import { useReactTable, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';

const useStyles = makeStyles((theme) => ({
	actionsWrapper: {
		display: 'flex',
		gap: theme.spacing(0.5),
		flexWrap: 'wrap',
		maxWidth: 160,
		minWidth: 80,
		justifyContent: 'center',
	},
	actionIconButton: {
		// border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
	},
}));

function PlayerTableView({
	screens,
	columns,
	playerActions,
	noDataMsg,
	setSortBy,
	sortBy,
	setSelectedRowIds,
	selectedRowIds,
}) {
	const classes = useStyles();
	const intl = useIntl();

	// ##############################
	// React-Table (treat it as part of local states)
	// #############################
	// function to select rows that require highlight
	const rowHighlightPicker = React.useCallback((row) => selectedRowIds.includes(row.id), [
		selectedRowIds,
	]);
	const isCustomSorting = React.useMemo(() => sortBy !== undefined && setSortBy !== undefined, [
		setSortBy,
		sortBy,
	]);

	const actionsCol = React.useMemo(
		() => ({
			id: 'actions',
			enableSorting: false,
			enableResizing: false,
			header: intl.formatMessage({ id: 'GENERAL.Actions' }),
			cell: function rowAction({ row }) {
				const rowOriginal = row.original;
				return (
					<div className={classes.actionsWrapper} aria-label="table-row-actions">
						{playerActions.map((action, idx) => {
							return action.shouldDisplay({ rowOriginal }) ? (
								action.CustomComp ? (
									<action.CustomComp
										key={rowOriginal.id + '' + idx}
										rowOriginal={rowOriginal}
										disabled={action.isDisabled({ rowOriginal })}
									/>
								) : (
									<IconButton
										key={rowOriginal.id + '' + idx}
										className={classes.actionIconButton}
										size="small"
										title={action.tooltip}
										disabled={action.isDisabled({ rowOriginal })}
										onClick={(e) =>
											typeof action.clickHandler === 'function'
												? action.clickHandler({ rowOriginal }, e)
												: null
										}
									>
										{action.icon}
									</IconButton>
								)
							) : null;
						})}
					</div>
				);
			},
		}),
		[classes.actionIconButton, classes.actionsWrapper, intl, playerActions]
	);

	const selectionCol = React.useMemo(() => {
		return {
			id: 'selection',
			enableSorting: false,
			enableResizing: false,
			maxSize: 50,
			header: function renderSelectionHeader({
				table,
				// header,
				// column /* setSelectedRowIds, selectedRowIds, data */,
			}) {
				const {
					data,
					meta: { setSelectedRowIds, selectedRowIds },
				} = table.options;
				return (
					<Checkbox
						disableRipple
						indeterminate={selectedRowIds.length > 0 && selectedRowIds.length !== data.length}
						checked={selectedRowIds.length === data.length}
						size="medium"
						onChange={(e) => {
							// setSelectgedAll(e.target.checked);
							// setSelectedRowIds(e.target.checked?data.:[]);
							setSelectedRowIds(e.target.checked ? data.map((s) => s.id) : []);
						}}
					/>
				);
			},
			cell: function rowSelection({ table, row /* setSelectedRowIds, selectedRowIds */ }) {
				const {
					meta: { setSelectedRowIds, selectedRowIds },
				} = table.options;
				const rowOriginal = row.original;
				return (
					<Box
						aria-label="table-row-selection"
						sx={{
							display: 'flex',
							flexDirection: 'row',
							padding: 0.5,
						}}
					>
						<Checkbox
							color="primary"
							size="small"
							disableRipple
							checked={selectedRowIds.includes(rowOriginal.id)}
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedRowIds(selectedRowIds.concat(rowOriginal.id));
								} else {
									setSelectedRowIds(selectedRowIds.filter((id) => id !== rowOriginal.id));
								}
							}}
						/>
					</Box>
				);
			},
		};
	}, []);

	const tableInst = useReactTable({
		columns: [
			...(selectedRowIds && setSelectedRowIds ? [selectionCol] : []),
			actionsCol,
			...columns,
		],
		data: screens,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		state: { ...(isCustomSorting ? { sorting: sortBy } : {}) },
		// sorting
		manualSorting: isCustomSorting,
		enableMultiSort: false,
		enableSorting: true,
		enableSortingRemoval: true,
		...(isCustomSorting ? { onSortingChange: setSortBy } : {}),
		// resizing
		enableColumnResizing: true,
		columnResizeMode: 'onChange',
		getRowId: (rowData) => rowData.id,
		// props from parent component that are used in Header/Cells to handle user operations
		meta: {
			setSelectedRowIds,
			selectedRowIds,
		},
	});

	return (
		<ReactTableV8MUIView
			isStickyHeader={true}
			showBorder={true}
			enableResizer={true}
			rowHighlightPicker={rowHighlightPicker}
			// table props
			headerGroups={tableInst.getHeaderGroups()}
			rows={tableInst.getRowModel().rows}
			noDataMsg={noDataMsg}
		/>
	);
}

PlayerTableView.propTypes = {
	noDataMsg: PropTypes.string.isRequired,
	screens: PropTypes.arrayOf(PropTypes.object).isRequired,
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	playerActions: PropTypes.arrayOf(
		PropTypes.shape({
			shouldDisplay: PropTypes.func.isRequired, // arg is {rowOriginal:row.original}. hook func to verify that should the action be displayed
			isDisabled: PropTypes.func.isRequired, // args is {rowOriginal:row.original}
			icon: PropTypes.element.isRequired, // jsx component of icon
			tooltip: PropTypes.string.isRequired,
			CustomComp: PropTypes.element, // the CustomComp will be used as is if provided: <CustomComp />
			clickHandler: PropTypes.func.isRequired, // arg is {rowOriginal:row.original}
		})
	),
	// sortBy. It is to control if the sortBy is enabled or not
	// if enabled, the format of sortBy is array of:
	// {
	// 	desc: true/false,
	// 	id: 'column id in reactTable column config'
	// }
	sortBy: PropTypes.array,
	setSortBy: PropTypes.func,

	/**
	 * function to handle multiple selection changes
	 * the arg passed to the function is a list of id, the id is the "id" field of the original data
	 * NB: the function should use memorized (e.g. React.useCallback())
	 */
	setSelectedRowIds: PropTypes.func,
	/** the list of selected row id
	 * If both setSelectedRowIds & selectedRowIds are specified, we will enable multipleSelections, otherwise it is disabled
	 */
	selectedRowIds: PropTypes.arrayOf(PropTypes.string),
};

PlayerTableView.defaultProps = {};

export default PlayerTableView;
