import React from 'react';

import PropTypes from 'prop-types';
import {
	MenuItem,
	Select,
	Typography,
	TextField,
	IconButton,
	Tooltip,
	Box,
	Autocomplete,
} from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
// icons
import {
	AddCircleOutlined as AddIcon,
	CheckCircle as OKIcon,
	Cancel as CancelIcon,
} from '@mui/icons-material';

import timezones from './timezones.js';

const CommandRowComp = ({ sequenceNo, name, options }) => {
	return (
		<Box sx={{ display: 'flex', borderBottom: '1px solid #eeeeee', padding: 1 }}>
			<Box style={{ width: 100 }}>{sequenceNo}</Box>
			<Box sx={{ width: 200 }}>{name}</Box>
			<Box sx={{ flex: '1 1 auto' }}>{JSON.stringify(options)}</Box>
		</Box>
	);
};

export const AddCommandRowComp = ({
	isLinuxBox,
	sequenceNo,
	handleCancelAddCommand,
	handleSetCommand,
	notifyGeneral,
}) => {
	const intl = useIntl();
	const commandNames = React.useMemo(
		() =>
			!isLinuxBox
				? [
						'REBOOT_SYS',
						'RELAUNCH_PLAYER',
						'SETTING_TIMEZONE',
						'SETTING_SCREEN_ROTATE',
						'SCREENSHOT',
						'UPDATE_APK',
				  ]
				: ['REBOOT_SYS', 'SETTING_TIMEZONE', 'SETTING_SCREEN_ROTATE', 'UPDATE_UPDATER'],
		[isLinuxBox]
	);
	const screenRotateOptions = React.useMemo(() => ['normal', 'left', 'right', 'inverted'], []);

	const [addedCommand, setAddedCommand] = React.useState({ name: '', options: {} });

	return (
		<Box
			sx={{
				display: 'flex',
				borderBottom: '1px solid #eeeeee',
				padding: 1,
				alignItems: 'center',
			}}
		>
			<Box style={{ width: 100 }}>{sequenceNo}</Box>
			<Box sx={{ width: 200 }}>
				<Select
					sx={{ width: '100%' }}
					value={addedCommand.name}
					onChange={(e) => {
						// reset options (don't need to setOptionsInput('') because the whole component will be re-render when (props) name is changed)
						setAddedCommand({ name: e.target.value, options: {} });
					}}
				>
					{commandNames.map((command, idx) => {
						return (
							<MenuItem key={idx} sx={{ fontSize: '0.9rem' }} value={command}>
								{command}
							</MenuItem>
						);
					})}
				</Select>
			</Box>
			<Box sx={{ flex: '1 1 auto', paddingLeft: 1 }}>
				{addedCommand.name === 'SETTING_TIMEZONE' ? (
					<Autocomplete
						size="small"
						value={addedCommand.options?.timezone ?? ''}
						options={timezones}
						renderInput={(params) => <TextField {...params} label="" />}
						onChange={(e, value) => {
							setAddedCommand({ ...addedCommand, options: { timezone: value } });
						}}
					/>
				) : addedCommand.name === 'SETTING_SCREEN_ROTATE' ? (
					<Select
						sx={{ width: '100%' }}
						value={addedCommand.options?.screenRotate ?? ''}
						onChange={(e) => {
							setAddedCommand({ ...addedCommand, options: { screenRotate: e.target.value } });
						}}
					>
						{screenRotateOptions.map((option, idx) => {
							return (
								<MenuItem key={idx} sx={{ fontSize: '0.9rem' }} value={option}>
									{option}
								</MenuItem>
							);
						})}
					</Select>
				) : addedCommand.name === 'UPDATE_UPDATER' ? (
					<TextField
						size="small"
						value={addedCommand.options?.updaterZipUrl ?? ''}
						fullWidth={true}
						onChange={(e) => {
							setAddedCommand({ ...addedCommand, options: { updaterZipUrl: e.target.value } });
						}}
					/>
				) : addedCommand.name === 'UPDATE_APK' ? (
					<TextField
						size="small"
						value={addedCommand.options?.apkUrl ?? ''}
						fullWidth={true}
						onChange={(e) => {
							setAddedCommand({ ...addedCommand, options: { apkUrl: e.target.value } });
						}}
					/>
				) : null}
			</Box>
			<Box sx={{ paddingLeft: 2, flex: '1 1 auto' }}>
				{/* OK button */}
				<Tooltip
					title={intl.formatMessage({
						id: 'GENERAL.Add',
					})}
				>
					<IconButton
						// size="large"
						color="primary"
						sx={{ padding: 0.5 }}
						component="span"
						disabled={!addedCommand.name}
						onClick={async () => {
							if (
								!['REBOOT_SYS', 'RELAUNCH_PLAYER', 'SCREENSHOT'].includes(addedCommand.name) &&
								Object.keys(addedCommand.options).length === 0
							) {
								notifyGeneral(
									intl.formatMessage({
										id: 'pages.Admin.components.PlayerUniverse.MissingRequiredCommandOptionsMsg',
									}),
									'error'
								);
								return;
							}
							await handleSetCommand({
								name: addedCommand.name,
								options:
									Object.keys(addedCommand.options).length > 0 ? addedCommand.options : undefined,
							});
							handleCancelAddCommand && handleCancelAddCommand();
						}}
					>
						<OKIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
				<Tooltip
					title={intl.formatMessage({
						id: 'GENERAL.Cancel',
					})}
				>
					<IconButton
						// size="large"
						color="secondary"
						sx={{ padding: 0.5 }}
						onClick={() => {
							setAddedCommand({ name: '', options: {} });
							handleCancelAddCommand && handleCancelAddCommand();
						}}
					>
						<CancelIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
};

function PlayerCommandComp({ player, handleSetCommand, notifyGeneral }) {
	const intl = useIntl();
	const commands = [...(player.sysExecCommands || [])];
	const [openAddCommand, setOpenAddCommand] = React.useState(false);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 200 }}>
			{/* header */}
			<CommandRowComp
				sequenceNo={intl.formatMessage({
					id: 'pages.Admin.components.PlayerUniverse.CommandRowOrderHeader',
				})}
				name={intl.formatMessage({
					id: 'pages.Admin.components.PlayerUniverse.CommandRowNameHeader',
				})}
				options={intl.formatMessage({
					id: 'pages.Admin.components.PlayerUniverse.CommandRowOptionsHeader',
				})}
			/>
			{/* Existing commands */}
			{commands.length > 0 ? (
				commands.map((command, idx) => {
					return <CommandRowComp key={idx} sequenceNo={idx} {...command} />;
				})
			) : openAddCommand ? null : (
				<Typography sx={{ margin: 'auto', padding: 1, color: 'rose.main' }} variant="h4">
					{intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.NoCommandMsg',
					})}
				</Typography>
			)}
			{/* New command */}
			{openAddCommand && (
				<AddCommandRowComp
					isLinuxBox={player.isLinuxBox}
					notifyGeneral={notifyGeneral}
					sequenceNo={commands.length}
					handleCancelAddCommand={() => {
						setOpenAddCommand(false);
					}}
					handleSetCommand={handleSetCommand}
				/>
			)}
			{/* Add new command button */}
			<Box>
				<Tooltip
					title={intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.AddCommandTooltip',
					})}
				>
					<IconButton
						size="large"
						color="primary"
						sx={{ margin: 1 }}
						disabled={openAddCommand}
						onClick={() => setOpenAddCommand(true)}
					>
						<AddIcon fontSize="large" />
					</IconButton>
				</Tooltip>
			</Box>
			{/* history commands */}
		</Box>
	);
}

PlayerCommandComp.propTypes = {
	/**
	 * handleSetCommand function (async): async (command)=>{}.
	 * command is {name: '', options: {} | undefined}
	 */
	handleSetCommand: PropTypes.func.isRequired, // must be async function (or return Promise)
	player: PropTypes.object.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
};

PlayerCommandComp.defaultProps = {};

export default PlayerCommandComp;
