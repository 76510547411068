// ##############################
// // // Screen Manager styles
// #############################
import { grey, red, blue } from '@mui/material/colors';

const SchedulesStyle = (theme) => ({
	contentWrapper: {
		...theme.contentWrapper,
	},
	header: {
		height: 50,
		width: '100%',
		display: 'flex',
		marginBottom: 15,
		// justifyContent: 'center',
		alignItems: 'center',
		// position: 'relative',
		backgroundColor: grey[100],
	},
	title: {
		width: '60%',
		marginLeft: 20,
		// marginTop: 'auto',
		// marginBottom: 'auto'
		// position: 'absolute',
		// top: '50%',
		// transform: 'translateY(-50%)'
	},
	helpIcon: {
		marginLeft: 'auto',
		marginRight: 10,
		cursor: 'pointer',
		color: blue[700],
	},
	content: {
		width: '100%',
		height: '100%',
		margin: 'auto',
	},
	// paper: {
	// 	display: 'flex'
	// },
	// scheduleQueuePaper: {
	// 	display: 'flex',
	// 	flexDirection: 'column',
	// 	alignItems: 'flex-start',
	// 	height: '100%',
	// 	minHeight: 500,
	// },
	// scheduleQueueTitle:{
	// 	width: '100%',
	// 	margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
	// },
	buttonDivider: {
		marginTop: theme.spacing(3),
	},
	buttonWrapper: {
		width: '100%',
		display: 'flex',
		minHeight: 50,
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: blue[300],
		minWidth: 100,
		minHeight: 50,
		color: '#fff',
		'&:hover': {
			backgroundColor: red[500],
		},
	},

	calSlotDurationBtnCls: {
		borderRadius: 0,
	},

	scheduleContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: '100%',
		width: '100%',
		minHeight: 500,
	},
	schedulePaper: {
		width: '100%',
		maxHeight: 600,
		// margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
	},

	// margin: {
	//   margin: theme.spacing(1),
	// },
	// textField: {
	//   flexBasis: 200,
	// },
	// paperContainer: {
	//   padding: theme.spacing(2),
	//   textAlign: 'center',
	// 	color: theme.palette.text.secondary,
	// 	// backgroundColor: 'yellow',
	// 	margin: 'auto',
	// 	// maxWidth: 140,
	// 	// minWidth: 100,
	// 	height: 260,
	// 	width: 500,
	// 	// width: '90%',
	// 	'& img': {
	// 		maxWidth: '100%',
	// 		maxHeight: '100%'
	// 	},
	// 	'&:hover': {
	// 		backgroundColor: '#e2f2f3',
	// 		textDecoration: 'underline',
	// 		cursor: 'pointer',
	// 		'& svg': {
	// 			color: cyan[500],
	// 		}
	// 	},

	// },
	// cardContainer: {
	//   padding: theme.spacing(2),
	//   textAlign: 'center',
	// 	color: theme.palette.text.secondary,
	// 	margin: 'auto',
	// 	// maxWidth: 140,
	// 	// minWidth: 100,
	// 	height: 260,
	// 	width: 500
	// },
	// avatar: {
	//   margin: 10,
	//   width: 150,
	// 	height: 150,
	// 	// marginLeft: '60%',
	// 	// marginTop: '-32%'
	// 	// top: -245,
	// 	// right: -260
	// 	top: '-100%',
	// 	right: '-50%',
	// 	color: grey[500],
	// 	backgroundColor: grey[300]
	// },
	// icon: {
	// 	// width: '30%',
	// 	fontSize: 120,
	// 	position: 'relative',
	// 	// '&:hover': {
	//   //   color: cyan[500],
	//   // },
	// },
	// sectionText: {
	// 	position: 'relative',
	// 	// top: -220,
	// 	bottom: 200,
	// }
});

export default SchedulesStyle;
