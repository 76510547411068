import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

// MUI components
import { Backdrop, IconButton, Modal } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import ContentView from './ContentView';

const useStyles = makeStyles((theme) => ({
	modalRoot: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	contentWrap: {
		pointerEvents: 'none',
		width: '70vw',
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: '100vw',
			padding: theme.spacing(1),
		},
	},
	closeButton: {
		position: 'absolute',
		pointerEvents: 'all',
		right: theme.spacing(2),
		top: theme.spacing(2),
		cursor: 'pointer',
		zIndex: 2,
		backgroundColor: theme.palette.secondary.light,
		color: theme.palette.secondary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
		},
	},
}));

function MediafilesCarousel({ open, onClose, mediafiles, autoPlayVideo, showDots, ...rest }) {
	const classes = useStyles();
	return (
		<Modal className={classes.modalRoot} BackdropComponent={Backdrop} open={open} onClose={onClose}>
			<div className={classes.contentWrap}>
				<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
				<ContentView
					mediafiles={mediafiles}
					autoPlayVideo={autoPlayVideo}
					showDots={showDots}
				></ContentView>
			</div>
		</Modal>
	);
}

MediafilesCarousel.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	mediafiles: PropTypes.arrayOf(
		PropTypes.shape({
			isSelected: PropTypes.bool,
			isVideo: PropTypes.bool,
			previewUrl: PropTypes.string.isRequired,
		})
	).isRequired,
	autoPlayVideo: PropTypes.bool,
	showDots: PropTypes.bool,
};

MediafilesCarousel.defaultProps = {
	open: false,
	autoPlayVideo: true,
	showDots: false,
};
export default MediafilesCarousel;
