import React from 'react';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { moment } from 'utils/libHelper.js';

// MUI components
import { Button, ButtonGroup, Popover } from '@mui/material';
import { Edit as EditIcon, PlayArrow as PlayIcon, Save as SaveIcon } from '@mui/icons-material';

import PreviewControlPanel from '../PreviewControlPanel/PreviewControlPanel';

// intl lang
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	ribbonWrapper: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		color: ART_VARIABLES.cssStyles.mobileCreateActionColor,
		backgroundColor: ART_VARIABLES.cssStyles.mobileCreateActionBGColor,
	},
	buttonGroup: {
		height: '100%',
	},
	playIconButton: {
		padding: 8,
		color: 'inherit',
	},
	editPanelPaper: {
		width: '100%',
		maxWidth: 'unset',
		maxHeight: '70vh',
	},

	previewControlPanelRndRoot: {
		position: 'relative !important',
	},
}));

function MobileCreateActions({
	artMode,
	PreviewControlPanelSettings,
	handleSaveNewArtwork,
	initFileName,
	handleResetAnimation,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const [editPanelAnchor, setEditPanelAnchor] = React.useState(null);

	const getSaveFileName = React.useCallback((filename, mode = 'CREATE') => {
		filename = filename.trim();
		if (mode === 'CREATE') {
			filename = filename + ' - ' + moment().format('DD-MM-YYYY');
		} else if (mode === 'EDIT') {
			let lastIndexOfDash = filename.lastIndexOf('-');
			let isLastCharNum = Number.isInteger(parseInt(filename[filename.length - 1]));
			if (filename.length - lastIndexOfDash < 4 && isLastCharNum) {
				// when last dash is within 4 char to the end, we treat it as number indexed file name
				filename =
					filename.substring(0, lastIndexOfDash).trim() +
					` - ${parseInt(filename[filename.length - 1]) + 1}`;
			} else {
				filename = `${filename} - 1`;
			}
		}
		return filename;
	}, []);

	const handleCloseEditPanel = () => setEditPanelAnchor(null);

	const handleEditClick = (e) => {
		if (editPanelAnchor) setEditPanelAnchor(null);
		else setEditPanelAnchor(e.currentTarget.parentElement); // use the buttonGroup as the anchor
	};

	const handleSaveAction = () => {
		handleSaveNewArtwork(getSaveFileName(initFileName, artMode));
	};

	return (
		<div className={classes.ribbonWrapper}>
			<ButtonGroup
				className={classes.buttonGroup}
				size="small"
				color="inherit"
				fullWidth
				disableElevation
				variant="text"
				aria-label="button group"
			>
				<Button component="div" startIcon={<EditIcon />} onClick={handleEditClick}>
					{intl.formatMessage({
						id: 'GENERAL.Edit',
					})}
				</Button>
				{handleResetAnimation && (
					<Button data-action="play" component="div" onClick={handleResetAnimation}>
						<PlayIcon style={{ fontSize: '2.0rem' }} />
					</Button>
				)}
				<Button
					data-action="save"
					component="div"
					startIcon={<SaveIcon />}
					onClick={handleSaveAction}
				>
					{intl.formatMessage({
						id: 'GENERAL.Save',
					})}
				</Button>
			</ButtonGroup>

			<Popover
				id="edit-Panel"
				anchorEl={editPanelAnchor}
				elevation={0}
				marginThreshold={0}
				open={Boolean(editPanelAnchor)}
				onClose={handleCloseEditPanel}
				classes={{ paper: classes.editPanelPaper }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<PreviewControlPanel
					{...PreviewControlPanelSettings}
					disableTitle={true}
					rndProps={{
						default: {},
						size: { width: '100%' },
						minWidth: undefined,
						minHeight: undefined,
						maxHeight: undefined,
						maxWidth: undefined,
						disableDragging: true,
						enableResizing: false,
					}}
					rndClassName={classes.previewControlPanelRndRoot}
					expandAllGroups={true}
				/>
			</Popover>
		</div>
	);
}

MobileCreateActions.propTypes = {
	/**
	 * props (object) for PreviewControlPanel comp
	 */
	PreviewControlPanelSettings: PropTypes.object.isRequired,
	/**
	 * artwork creation mode
	 */
	artMode: PropTypes.oneOf(['CREATE', 'EDIT']).isRequired,
	/**
	 * Handle save new artwork action (also Save-As action)
	 *
	 * @param {string} fileName. file name for saving
	 */
	handleSaveNewArtwork: PropTypes.func.isRequired,

	/**
	 * initial file name for saving artwork
	 * Note: all saving actions are saving to new file
	 */
	initFileName: PropTypes.string.isRequired,
	/**
	 * function to reset animatioin
	 */
	handleResetAnimation: PropTypes.func,
};

MobileCreateActions.defaultProps = {};
export default MobileCreateActions;
