import {
	DOMAIN_SETTINGS,
	DOMAIN_SETTINGS_SUCCESS,
	DOMAIN_SETTINGS_FAIL,
	EDIT_DOMAIN_SETTINGS,
	EDIT_DOMAIN_SETTINGS_SUCCESS,
	EDIT_DOMAIN_SETTINGS_FAIL,
} from 'redux/actions';
import { decryptStrBase64 } from 'utils/appHelper';

const initState = {
	settings: [],
};

function domainSettings(state = initState, action) {
	switch (action.type) {
		case DOMAIN_SETTINGS:
			return {
				...state,
			};
		case DOMAIN_SETTINGS_SUCCESS:
			return {
				...state,
				settings: JSON.parse(decryptStrBase64(action.result.data.pref)),
			};
		case DOMAIN_SETTINGS_FAIL:
			return {
				...state,
			};
		case EDIT_DOMAIN_SETTINGS:
			return {
				...state,
			};
		case EDIT_DOMAIN_SETTINGS_SUCCESS: {
			let updatedSettings = { ...action.result.data };
			return {
				...state,
				settings: state.settings.map((x) => {
					if (x.key === updatedSettings.key) {
						x = { ...x, ...updatedSettings };
					}
					return x;
				}),
			};
		}
		case EDIT_DOMAIN_SETTINGS_FAIL:
			return {
				...state,
			};
		default:
			return state;
	}
}

export default domainSettings;
