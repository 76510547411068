import { combineReducers } from 'redux';

import { authentication } from './authentication';
import { notifier } from './notifier';
import { globalDialog } from './globalDialog';
import { screenManagerScreens } from './screenManagerScreens';
import { screenManagerPlaylists } from './screenManagerPlaylists';
import { screenManagerDepartments } from './screenManagerDepartments';
import { screenManagerPlaylistSlide } from './screenManagerPlaylistSlide';
import { screenManagerScreenSchedule } from './screenManagerScreenSchedule';
import { screenManagerScreenboard } from './screenManagerScreenboard';
import { screenManagerUniverse } from './screenManagerUniverse';
import { lang } from './lang';
import { sidebar } from './sidebar';
import { default as artworkDesignerTemplate } from './artworkDesignerTemplate';
import { default as artworkExtra } from './artworkExtra';
import { default as awsResource } from './awsResource';
import { default as filemanager } from './filemanager';
import { default as usermanager } from './usermanager';
import { default as generalData } from './generalData';
import { default as domainSettings } from './domainSettings';

const rootReducer = combineReducers({
	authentication,
	notifier,
	globalDialog,
	screenManagerScreens,
	screenManagerPlaylists,
	screenManagerDepartments,
	screenManagerPlaylistSlide,
	screenManagerScreenSchedule,
	screenManagerScreenboard,
	screenManagerUniverse,
	lang,
	sidebar,
	artworkDesignerTemplate,
	artworkExtra,
	awsResource,
	filemanager,
	usermanager,
	generalData,
	domainSettings,
});

export default rootReducer;
