import {
	FETCH_SCREENMANAGER_DEPARTMENTS,
	FETCH_SCREENMANAGER_DEPARTMENTS_SUCCESS,
	FETCH_SCREENMANAGER_DEPARTMENTS_FAIL,
	RESET_SCREENMANAGER_DEPARTMENTS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_DEPARTMENTS_REQUEST,
	EDIT_SCREENMANAGER_DEPARTMENTS_SUCCESS,
	EDIT_SCREENMANAGER_DEPARTMENTS_FAIL,
	RESET_SCREENMANAGER_DEPARTMENTS_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_DEPARTMENTS_REQUEST,
	ADD_SCREENMANAGER_DEPARTMENTS_FAIL,
	ADD_SCREENMANAGER_DEPARTMENTS_SUCCESS,
	RESET_SCREENMANAGER_DEPARTMENTS_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_DEPARTMENTS_REQUEST,
	DELETE_SCREENMANAGER_DEPARTMENTS_SUCCESS,
	DELETE_SCREENMANAGER_DEPARTMENTS_FAIL,
	RESET_SCREENMANAGER_DEPARTMENTS_DELETESTATUS,
} from 'redux/actions';

const screenManagerDepartmentsInitState = {
	isFetching: false,
	departments: [],
	addingStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	editingStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	deleteStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
};

export const screenManagerDepartments = (state = screenManagerDepartmentsInitState, action) => {
	switch (action.type) {
		case FETCH_SCREENMANAGER_DEPARTMENTS:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_SCREENMANAGER_DEPARTMENTS_SUCCESS:
			return {
				...state,
				isFetching: false,
				departments: action.result.data, // this is axios response data, result is the name in middleware, data is response.data in axios request(url) function
			};
		case FETCH_SCREENMANAGER_DEPARTMENTS_FAIL:
			if (action.error.response && action.error.response.status === 404) {
				return {
					...state,
					isFetching: false,
					departments: [],
				};
			} else {
				return {
					...state,
					isFetching: false,
				};
			}
		case RESET_SCREENMANAGER_DEPARTMENTS:
			return {
				...state,
				isFetching: false,
				departments: [],
				addingStatus: '',
				editingStatus: '',
				deleteStatus: '',
			};
		case EDIT_SCREENMANAGER_DEPARTMENTS_REQUEST:
			return {
				...state,
				editingStatus: 'PROCESSING',
			};
		case EDIT_SCREENMANAGER_DEPARTMENTS_SUCCESS: {
			let updatedDepartment = { ...action.result.data };
			return {
				...state,
				departments: state.departments.map((department) => {
					if (department.id === updatedDepartment.id) {
						department = updatedDepartment;
					}
					return department;
				}),
				editingStatus: 'OK',
			};
		}
		case EDIT_SCREENMANAGER_DEPARTMENTS_FAIL:
			return {
				...state,
				editingStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_DEPARTMENTS_EDITSTATUS:
			return {
				...state,
				editingStatus: '',
			};
		case ADD_SCREENMANAGER_DEPARTMENTS_REQUEST:
			return {
				...state,
				addingStatus: 'PROCESSING',
			};
		case ADD_SCREENMANAGER_DEPARTMENTS_FAIL:
			return {
				...state,
				addingStatus: 'FAILED',
			};
		case ADD_SCREENMANAGER_DEPARTMENTS_SUCCESS: {
			let departmentClone = state.departments.slice(0);
			let newDepartment = { ...action.result.data };
			departmentClone.unshift(newDepartment);
			return {
				...state,
				departments: departmentClone,
				addingStatus: 'OK',
			};
		}
		case RESET_SCREENMANAGER_DEPARTMENTS_ADDSTATUS:
			return {
				...state,
				addingStatus: '',
			};
		case DELETE_SCREENMANAGER_DEPARTMENTS_REQUEST:
			return {
				...state,
				deleteStatus: 'PROCESSING',
			};
		case DELETE_SCREENMANAGER_DEPARTMENTS_FAIL:
			return {
				...state,
				deleteStatus: 'FAILED',
			};
		case DELETE_SCREENMANAGER_DEPARTMENTS_SUCCESS: {
			return {
				...state,
				departments: state.departments.filter(
					(department) => department.id !== action.departmentId
				),
				deleteStatus: 'OK',
			};
		}
		case RESET_SCREENMANAGER_DEPARTMENTS_DELETESTATUS:
			return {
				...state,
				deleteStatus: '',
			};
		default:
			return state;
	}
};
