/** ##############################
 * 	Artwork Browser-Side Template convertor
 *
 * ** Used only by browser-side **
 * Because it is browser-side only, you can import any helper files
 *
 *  Convertion/transformation functions to convert artwork-template field structure between Api to React
 *
 * 	#############################
 */
import { isNullish, roundDecimals } from '../generalHelper';

import { DEFAULT_FONTSIZE } from './constants';
import { convertCMYKcolor, convertHexColor, convertPantoneColor } from './artUtilsWebUI';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';

// use window.matchMedia and loop downwards to find the pixel multiplier.
// we use dppx becuase iOS only supports -webkit-min-device-pixel-ratio,
// which doesn't allow dpi units (shame, that would have told an iPad from an iPad mini)
export const calcDPpx = function () {
	// return dot per pixel
	let dppx = 1;
	if (typeof window.devicePixelRatio !== 'undefined') {
		dppx = Number(window.devicePixelRatio.toFixed(2));
	} else {
		// fallback
		for (var i = 5; i >= 1; i = Number((i - 0.05).toFixed(2))) {
			if (
				window.matchMedia('(-webkit-min-device-pixel-ratio: ' + i + ')').matches ||
				window.matchMedia('(min-resolution: ' + i + 'dppx)').matches
			) {
				dppx = i;
				break;
			}
		}
	}
	return dppx;
};

export const calcDpi = function () {
	// return dpi in CSSinch
	let dpi = 96;
	if (window.matchMedia('(min-resolution: 10dpi)').matches) {
		for (var i = 650; i >= 1; i = i - 1) {
			if (window.matchMedia('(min-resolution: ' + i + 'dpi)').matches) {
				dpi = i;
				break;
			}
		}
	}
	return dpi;
};

export const categoryObject = (categories, parentAccu = {}, parentId) => {
	return categories.reduce((accu, item) => {
		if (parentId) {
			accu[item.id] = [...accu[parentId], item];
		} else {
			accu[item.id] = [item];
		}
		if (Array.isArray(item.categories) && item.categories.length > 0) {
			return categoryObject(item.categories, accu, item.id);
		} else {
			return accu;
		}
	}, parentAccu);
};

export const artworkColor = async (remoteArtColor) => {
	let defaultColor = {
		hex: '#000000',
		rgb: { r: 0, g: 0, b: 0, a: 1 },
		cmyk: { c: 0, m: 0, y: 0, k: 100 },
		pantone: '',
	};
	if (!remoteArtColor) return defaultColor;
	let color = {};
	if (remoteArtColor.startsWith('#')) {
		color = convertHexColor(remoteArtColor) || { hex: remoteArtColor };
	} else if (remoteArtColor.startsWith('cmyk')) {
		let colorParts = remoteArtColor.split(' ').filter((c) => c !== '');
		let cmyk = {
			c: Number(colorParts[1]),
			m: Number(colorParts[2]),
			y: Number(colorParts[3]),
			k: Number(colorParts[4]),
		};
		color = (await convertCMYKcolor(cmyk)) || defaultColor;
	} else {
		// pantone color
		color = (await convertPantoneColor(remoteArtColor)) || defaultColor;
	}
	return color;
};

// calculate the screen and the viewport sizes. If you don't know the difference... hmmm.
// eslint-disable-next-line no-unused-vars
export const calcScreen = function () {
	let dppx = calcDPpx();
	let screenW = Math.max(window.screen.width || 0);
	let screenH = Math.max(window.screen.height || 0);
	let windowW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	let windowH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	return {
		viewportCssPX: `${windowW}x${windowH} px`,
		viewportActualPX: `${windowW * dppx}x${windowH * dppx} px`,
		screenCssPX: `${screenW}x${screenH} px`,
		screenActualPX: `${screenW * dppx}x${screenH * dppx} px`,
	};
};

/**
 * Internal function. Convert local color object to string color for remote server
 * @param {object} color The object of color in object. Example:
 * {
		hex: '#000000',
		rgb: { r: 0, g: 0, b: 0, a: 1 },
		cmyk: { c: 0, m: 0, y: 0, k: 100 },
		pantone: '',
	}
 * @return {string}. Priority: pantone > cmyk > hex.
 */
export const convertLocalColorToRemote = (color = {}) => {
	return color.pantone
		? color.pantone
		: color.cmyk
		? `cmyk ${color.cmyk.c} ${color.cmyk.m} ${color.cmyk.y} ${color.cmyk.k}`
		: color.hex || '#000000';
};

export const convertRemoteFieldsToLocal = async (artworkTemplate, artworkExtraData) => {
	// const cssDpi = calcDpi();
	// TODO: Is it required to apply cssDPpx to the field position???
	// const cssDPpx = calcDPpx();
	const printDpi = 300;
	const ptPerInch = 72;
	const dpiPerPt = printDpi / ptPerInch;
	const dpiPerPx = 1; // cssDpi/cssDpi; //cssDPpx; // ??? do we really need to consider DPpx?
	// TODO: We need to re-think about the these scales when creating preview. e.g. FontSize PT to PX not consider 300 dpi; DPpx is not used
	const pointToPixelScale = dpiPerPt * dpiPerPx;
	// const fontSizePtToPx = cssDpi / ptPerInch; // VID-3184. We use point for font_size from 13/08/2020. So no need of this conversion
	// get sorted artwork fields
	let sortedFields = _sortBy(Array.isArray(artworkTemplate.fields) ? artworkTemplate.fields : [], [
		'group_sequence',
		'sequence',
	]);
	// prepare category (id, value) map
	const categoriesById = categoryObject(artworkExtraData.categories);

	// 		name: 'Page 1',
	// 		// use sample artwork from: https://centra-beta.visualid.com/pos_creator/design/?id=139176&groupsq=0&-action=New
	// 		dimension: { width: 2481, height: 3508 }, // in pixels (as in filemanager database)
	// 		mediaId: 139176,
	// 		mediaUrl:
	const defaultGroupName = 'Group 1';
	const defaultInsertionOnVideo = {
		// The timecode (from the beginning of the video, ZEROBASED) of the insertion that you want the overlay to first appear on
		// HH:MM:SS:FF (hour:min:sec:NoOfFrames). if '', it starts from beginning of video (zero).
		startTime: '',
		// the time, in milliseconds, for this field to remain on the output video.
		// This duration includes entrance time but not exit time.
		// if 0, it will never exit
		// if fadeIn is greater than duration, duration time is used for fade-in
		duration: 0,
		// the length of time, in milliseconds, between startTime to it fully appears on the video.
		// if 0, it appears immediately after startTime
		fadeIn: 0,
		// the length of time, in milliseconds, between the end of time to it fully exits on the video.
		// if 0, it exists immediately
		fadeOut: 0,
	};
	const filenameField = sortedFields.find((remoteField) => remoteField.filename_field);
	const videoPreviewField = sortedFields.find((remoteField) => remoteField.video_preview_field);
	// find the field that has outputTemplate setting, the esign_alt_template_id in the field will be used to set alternativeTemplateId no matter it has value or not
	const fieldHasOutputTemplate = sortedFields.find(
		(remoteField) => remoteField.outputtemplate_id && Number(remoteField.outputtemplate_id || 0) > 0
	);
	let template = {
		fields: [],
		groups: [],
		lastUsedGroupName: defaultGroupName,
		name: artworkTemplate.name,
		dimension: { width: Number(artworkTemplate.width), height: Number(artworkTemplate.height) },
		mediaId: artworkTemplate.mediafileId,
		mediaUrl: artworkTemplate.mediaUrl,
		templateType: artworkTemplate.templateType, // enum of [PDF, VIDEO]

		templateBG: {
			mediafileId: artworkTemplate.templateBG?.mediafileId?.toString(), // default image mediafile id
			mediafilePreviewUrl: artworkTemplate.templateBG?.mediafilePreviewUrl, // default mediafile preview url
			mediafileHighResUrl: artworkTemplate.templateBG?.mediafileHighResUrl, // high-resolution url of default mediafile (for generating high quality pdf)
			mediafileOptimisedUrl: artworkTemplate.templateBG?.mediafileOptimisedUrl, // optimised url of default mediafile (fast loading & good quality for webpage)
			mediafilePdfUrl: artworkTemplate.templateBG?.mediafilePdfUrl, // pdf url of the default mediafile background
			mediafileSvgUrl: artworkTemplate.templateBG?.mediafileSvgUrl, // svg url of the default mediafile background
			mediafileOrigin: artworkTemplate.templateBG?.mediafileOrigin || 'user_files', // it uses imageOriginOptions as its option choices. possible values: 'category', 'admin_lightbox', 'user_files', 'user_avatar'
			mediafileOriginChoice:
				artworkTemplate.templateBG?.mediafileOrigin === 'category' && // format: [{id: 'xxx', ...rest}, ...]. "id" in the object is mandatory, value of "id" is integer (for category) or string (for lightbox)
				artworkTemplate.templateBG?.mediafileOriginChoiceId &&
				artworkExtraData.categories.length > 0
					? categoriesById[artworkTemplate.templateBG.mediafileOriginChoiceId] || []
					: artworkTemplate.templateBG?.mediafileOrigin === 'admin_lightbox' &&
					  artworkTemplate.templateBG?.mediafileOriginChoiceId
					? [{ id: artworkTemplate.templateBG.mediafileOriginChoiceId.toString() }]
					: [],
			includeUserFiles: artworkTemplate.templateBG?.includeUserFiles || false, // weather or not including user's own files
			isCustomisable: artworkTemplate.templateBG?.isCustomisable || false, // weather or not allowing end user to select background
		},
		videoArtwork: sortedFields[0] ? Boolean(sortedFields[0].videoArtwork) : false, // TODO: If no fields in template, how to know if it is video artwork
		autoCreateArtwork: Boolean(sortedFields[0]?.auto_create),
		htmlArtwork: sortedFields[0]
			? Boolean(
					typeof sortedFields[0].html_aw === 'string'
						? sortedFields[0].html_aw.trim()
						: sortedFields[0].html_aw
			  )
			: true, //sortedFields.length > 0 && sortedFields[0].html_aw ? true : false,
		templateDuration: sortedFields[0] ? sortedFields[0].animation_total_duration || 0 : 0, //sortedFields.length > 0 ? sortedFields[0].animation_total_duration : 0,
		isEsignTemplate: sortedFields[0] ? sortedFields[0].template_isesign : false, //sortedFields.length > 0 && sortedFields[0].template_isesign ? true : false,
		isEsignOnly: sortedFields[0] ? sortedFields[0].template_isesignonly : false, //sortedFields.length > 0 && sortedFields[0].template_isesignonly ? true : false,
		esignPrintFiles: artworkTemplate.esignPrintFiles || {},
		filenameFieldId: filenameField?.id ?? '',
		filenameFieldAppend: filenameField?.filename_field_append ?? false,
		videoPreviewFieldId: videoPreviewField?.id ?? '',
		// set output template
		outputTemplate: fieldHasOutputTemplate?.outputtemplate_id || null,
		alternativeTemplateId:
			fieldHasOutputTemplate?.esign_alt_template_id &&
			Number(fieldHasOutputTemplate?.esign_alt_template_id || 0) > 0
				? `${fieldHasOutputTemplate.esign_alt_template_id}`
				: '',
	};

	for (let i = 0; i < sortedFields.length; i++) {
		const remoteField = sortedFields[i];
		let localField = {};
		localField.type = remoteField.type;
		localField.id = remoteField.id;
		localField.name = remoteField.label || 'Untitled';
		localField.imageRepositioning =
			typeof remoteField.img_reposition === 'boolean' ? remoteField.img_reposition : true; // if api returns non-boolean valuue, use true as default
		localField.position = {
			left: Number(remoteField.aleft) * pointToPixelScale,
			top: Number(remoteField.top) * pointToPixelScale,
			width: Number(remoteField.width) * pointToPixelScale,
			height: Number(remoteField.height) * pointToPixelScale,
			angle: (Number(remoteField.rotate) * -1 + 360) % 360,
		};
		localField.groupName = remoteField.group_label || '';
		localField.helperText = remoteField.help_text || '';
		localField.hideInput = Boolean(remoteField.value_hidden) || false; // ???
		localField.hideOutput = Boolean(remoteField.output_hidden) || false; // ???
		localField.outputDependsOn = remoteField.depends_on !== '0' ? remoteField.depends_on || '' : ''; // ??? it is a field id
		localField.animation =
			remoteField.animation_name && Number(remoteField.animation_duration) > 0
				? {
						entrance: remoteField.animation_name || '',
						delay:
							typeof remoteField.animation_delay === 'number' ? remoteField.animation_delay : null,
						duration: Number(remoteField.animation_duration),
						exit: remoteField.animation_exit || '',
				  }
				: {
						entrance: '',
						delay: null,
						duration: null,
						exit: '',
				  };
		localField.insertionOnVideo = remoteField.insertionOnVideo || defaultInsertionOnVideo; // we include insertionOnVideo in all fields, but control to use it by supportedFieldTypesInVideoArtwork

		switch (remoteField.type) {
			case 'text': {
				localField.inputStyle = remoteField.text_display || 'text';
				localField.doNotSetToFit = Boolean(remoteField.text_nofit) || false;
				localField.smartQuotes = Boolean(remoteField.text_smartquotes) || false;
				localField.removeLineBreaks = Boolean(remoteField.text_nobreaks) || false;
				localField.wrapLinesContainingSlash = Boolean(remoteField.text_breakslash) || false;

				// Note: remoteField.font_strikestyle could be '' for Horizontal Strikethrough, we can't use it to decide if strike_through is turned on/off
				// Fortunately, the remoteField.font_strikethrough is number where the value is greater than 0 when strikeThrough is turned on
				localField.strikeThrough = Boolean(
					remoteField.font_strikethrough /* || remoteField.font_strikestyle */
				);
				localField.strikeThroughStyle = localField.strikeThrough
					? {
							lineWidth: remoteField.font_strikethrough || 1,
							type: remoteField.font_strikestyle || 'strike', // ??? what is the value of strike, (not strikeup, strikedown). Answer: the current implemention use '' as 'strike'
					  }
					: {}; // ???
				localField.allCaps = Boolean(remoteField.text_allcaps) || false;
				localField.formatNumber = Boolean(remoteField.font_formatnumber.trim()) || false;
				localField.formatNumberStyle = localField.formatNumber
					? {
							currencySymbol: remoteField.font_formatnumber.trim() || '€',
							currencyFontScale: remoteField.font_formatnumbersize
								? roundDecimals(Number(remoteField.font_formatnumbersize) / 100, 2)
								: 1,
							currencyFontName: remoteField.text_currency_font || 'same',
							currencyVerticalAlign: remoteField.font_formatnumberalign || 'top',
							currencyPosition: remoteField.font_formatposition || 'leading',
							displayCent: Boolean(remoteField.font_formatcurrencycents) || false,
							doNotDisplayZeroCent: Boolean(remoteField.font_formatcurrencywhole) || false,
							centFontScale: remoteField.font_formatdecimalsize
								? roundDecimals(Number(remoteField.font_formatdecimalsize) / 100, 2)
								: 1,
							centVerticalAlign: remoteField.font_formatdecimalalign || 'bottom',
					  }
					: {};
				localField.batchRepeatingField = Boolean(
					remoteField.text_batchrepeat && remoteField.text_batchdelimiter
				); //???
				localField.batchRepeatingFieldStyle = localField.batchRepeatingField
					? {
							NoOfRepetitions: remoteField.text_batchrepeat || 1,
							delimiter: remoteField.text_batchdelimiter
								? remoteField.text_batchdelimiter === '\\n' ||
								  remoteField.text_batchdelimiter === '\n'
									? `\n`
									: `${remoteField.text_batchdelimiter}`
								: `\n`,
					  }
					: {};
				localField.tableColumns = remoteField.tablecolumns || 'fixed';
				localField.fontfaceName = remoteField.font_face || 'HelveticaNeueLTStd-Lt.otf';
				// localField.fontsize = roundDecimals(Number(remoteField.font_size) * fontSizePtToPx, 0) || 12; // VID-3184. We use point for font_size from 13/08/2020
				localField.fontsize = roundDecimals(Number(remoteField.font_size || DEFAULT_FONTSIZE), 0); // VID-3184. We use point for font_size from 13/08/2020
				localField.fontsizeUserDefined = Boolean(remoteField.font_size_ud) || false;
				// localField.textFormat=remoteField.id;	// ??? do we have this in the current artwork. answer: no, not using
				localField.fontColor = await artworkColor(remoteField.font_colour); //{ hex: '#000', rgb: { r: 0, g: 0, b: 0, a: 1 } };
				localField.textHorizontalAlign = remoteField.h_align_txt || 'center';
				localField.textHorizontalAlignUserDefined = Boolean(remoteField.h_align_txt_ud) || false;
				// VID-3199, when field is single line text, the textVerticalAlign must be 'bottom' & textVerticalAlignUserDefined must be false
				localField.textVerticalAlign =
					localField.inputStyle === 'text' ? 'bottom' : remoteField.v_align_txt || 'bottom';
				localField.textVerticalAlignUserDefined =
					localField.inputStyle === 'text' ? false : Boolean(remoteField.v_align_txt_ud) || false;
				localField.letterSpacing = remoteField.font_letterspacing
					? roundDecimals(Number(remoteField.font_letterspacing) / Number(remoteField.font_size), 1) // percentage of font size
					: 1; // ??? if the spacing is 0, shall we use 0 or use default value 1. Answer: if 0, the artwork does nothing. If has value, apply css letterSpacing style with the value in pixel. ????
				localField.leadingLineHeight = remoteField.font_leading
					? remoteField.font_leading === 'compact'
						? 1.0 // it is percentage of font size
						: remoteField.font_leading === 'auto'
						? 1.2 // it is percentage of font size
						: isNaN(Number(remoteField.font_leading)) // ??? Note: if it have value, it is point, it also works with 'do not set to fit' to control the 'other' option.
						? 1.2
						: roundDecimals(Number(remoteField.font_leading) / Number(remoteField.font_size), 1) // ??? is the value in point? Answer: yes. So we need to convert it to percentage of font size
					: 1.2; // NOTE: need review this part
				var hasShadow = Boolean(
					remoteField.shadow_hor || remoteField.shadow_ver || remoteField.shadow_blur
				);
				localField.shadowHorOffset = hasShadow ? remoteField.shadow_hor || 0 : null;
				localField.shadowVerOffset = hasShadow ? remoteField.shadow_ver || 0 : null;
				localField.shadowBlurRadius = hasShadow ? remoteField.shadow_blur || 0 : null;
				localField.textShadowColor = await artworkColor(remoteField.shadow_colour);
				/** text data */
				localField.autoImport =
					remoteField.dataimport &&
					['date:', 'cell:', 'cellimg:', 'image:', 'user:', 'userstd:'].some((k) =>
						remoteField.dataimport.startsWith(k)
					)
						? remoteField.dataimport.substring(0, remoteField.dataimport.lastIndexOf(':'))
						: '';
				localField.defaultAutoImportMeta = {
					basedOn: '',
					spreadsheetCell: null,
					esignEditable: false,
					esignSearchable: false,
					esignFilter: false,
				};
				let basedOnVal = '';
				if (localField.autoImport.startsWith('image:') || localField.autoImport === 'cell:') {
					basedOnVal = remoteField.dataimport.substring(
						remoteField.dataimport.lastIndexOf(':') + 1
					); // due to legacy issue, we assume the value after the last : is the based on field ID
				}
				localField.autoImportMeta = !localField.autoImport
					? localField.defaultAutoImportMeta
					: {
							basedOn: basedOnVal || '', // field ID
							spreadsheetCell:
								basedOnVal && localField.autoImport === 'cell:' ? remoteField.list_column : null, // column index in the spreadsheet
							esignEditable:
								basedOnVal && localField.autoImport === 'cell:'
									? Boolean(remoteField.esign_editable)
									: false,
							esignSearchable:
								basedOnVal && localField.autoImport === 'cell:'
									? Boolean(remoteField.esign_search)
									: false,
							esignFilter:
								basedOnVal && localField.autoImport === 'cell:'
									? Boolean(remoteField.esign_filter)
									: false,
					  };

				localField.calcValue = {
					price: remoteField.price_calculate !== '0' ? remoteField.price_calculate || '' : '', // field Id
					unit: remoteField.unit_calculate !== '0' ? remoteField.unit_calculate || '' : '', // field Id
					qty: remoteField.qty_calculate !== '0' ? remoteField.qty_calculate || '' : '', // field Id
					per: remoteField.target_calculate !== '0' ? remoteField.target_calculate || '' : '', // user typed string
					hide: Boolean(remoteField.target_calculate_hide) || false,
					multipleWeightsMsg: remoteField.unit_multiple || '',
				};

				var predefinedValueType =
					remoteField.list_origin && remoteField.list_id ? remoteField.list_origin : '';
				localField.predefinedValue = {
					type: predefinedValueType,
					from: predefinedValueType ? remoteField.list_id : null, // list id or spreadsheet id
					fromColumn:
						predefinedValueType && remoteField.list_column ? remoteField.list_column : null, // Note, we need the value to be string. [Do we? Have changed its value to int or null on 12/05/2020]
					listAllowOther: predefinedValueType ? Boolean(remoteField.list_other) : false,
				};
				localField.defaultTextPredefinedValue = {
					type: '',
					from: null,
					fromColumn: null,
					listAllowOther: false,
				};
				localField.defaultValue = remoteField.value.trim() || '';
				let hasEmbededField = new RegExp(/<field:\s*(.*?)\s*>/gim).test(localField.defaultValue);
				localField.embedStyle = hasEmbededField ? remoteField.embed_style || '' : '';
				localField.embedLeadingStyle = hasEmbededField ? remoteField.leading_style || '' : '';
				let isFontSizeUDValid = Boolean(
					remoteField.font_size_ud_start &&
						remoteField.font_size_ud_inc &&
						remoteField.font_size_ud_end
				);
				localField.fontsizeUDStart = isFontSizeUDValid
					? Number(remoteField.font_size_ud_start)
					: null;
				localField.fontsizeUDStep = isFontSizeUDValid ? Number(remoteField.font_size_ud_inc) : null;
				localField.fontsizeUDEnd = isFontSizeUDValid ? Number(remoteField.font_size_ud_end) : null;

				break;
			}
			case 'image': {
				// styles
				localField.sizing = remoteField.img_scale || 'crop';
				localField.sizingClipImage = Boolean(remoteField.img_clip) || false;
				localField.horizontalAlign = remoteField.h_align_img || 'center';
				localField.horizontalAlignUserDefined = Boolean(remoteField.h_align_img_ud) || false;
				localField.verticalAlign = remoteField.v_align || 'middle';
				localField.verticalAlignUserDefined = Boolean(remoteField.v_align_ud) || false;
				localField.borderWidth = remoteField.border_width || 0; // ??? do we need to consider null case (means the field does not have border)? it is question to all field types which have color palette
				localField.borderColor = await artworkColor(remoteField.border_colour); // ??? do we need to consider null case (means the field does not have border)? or we give a default value

				// data
				localField.autoImport =
					remoteField.dataimport &&
					['cell:', 'cellimg:'].some((k) => remoteField.dataimport.startsWith(k))
						? remoteField.dataimport.substring(0, remoteField.dataimport.lastIndexOf(':'))
						: '';
				localField.defaultAutoImportMeta = {
					basedOn: '',
					spreadsheetImageColumn: null,
					spreadsheetCell: null,
					esignEditable: false,
					esignSearchable: false,
					esignFilter: false,
				};
				let basedOnVal = localField.autoImport
					? remoteField.dataimport.substring(remoteField.dataimport.lastIndexOf(':') + 1)
					: ''; // due to legacy issue, we assume the value after the last : is the based on field ID

				localField.autoImportMeta = {
					basedOn: basedOnVal,
					spreadsheetImageColumn:
						localField.autoImport === 'cellimg:' && basedOnVal ? remoteField.list_column : null, // ???
					spreadsheetCell:
						localField.autoImport === 'cell:' && basedOnVal ? remoteField.list_column : null, // ???
					esignEditable:
						localField.autoImport === 'cell:' && basedOnVal
							? Boolean(remoteField.esign_editable)
							: false,
					esignSearchable:
						localField.autoImport === 'cell:' && basedOnVal
							? Boolean(remoteField.esign_search)
							: false,
					esignFilter:
						localField.autoImport === 'cell:' && basedOnVal
							? Boolean(remoteField.esign_filter)
							: false,
				};

				localField.imageOrigin = remoteField.choice_origin || 'user_files';
				localField.imageOriginChoice =
					remoteField.choice_origin === 'category' &&
					remoteField.choice_id &&
					artworkExtraData.categories.length > 0
						? categoriesById[remoteField.choice_id] || [] // category ID is integer
						: remoteField.choice_origin === 'admin_lightbox' && remoteField.choice_id
						? [{ id: remoteField.choice_id.toString() }] // lightbox ID is string
						: [];
				localField.imageOriginIncludeUserFiles = Boolean(remoteField.choice_own) || false;
				localField.imageOriginChoiceStyle = remoteField.choice_style || 'popup';
				localField.preSearchFieldId = remoteField.preSearchFieldId || '';
				localField.defaultMediafileId = `${remoteField.value_img || ''}`; // remoteField.value_img ??? it is a media file ID
				localField.defaultMediafilePreviewUrl = remoteField.defaultPreviewUrl || ''; // default mediafile preview url
				localField.defaultMediafileHighResUrl = remoteField.defaultHighResUrl || ''; // high-resolution url of default mediafile (for generating high quality pdf)
				localField.defaultMediafileOptimisedUrl = remoteField.defaultOptimisedUrl || ''; // optimised url of default mediafile (fast loading & good quality for webpage)
				localField.generateAi = Boolean(remoteField.generateAi) || false;
				localField.generateAiFieldId = remoteField.generateAiFieldId || '';
				break;
			}
			case 'pdf':
				// style
				localField.anchor = remoteField.pdf_anchor || 'bottom';
				localField.sizing = remoteField.img_scale || 'fit';
				localField.sizingClipImage = Boolean(remoteField.img_clip) || true;

				localField.horizontalAlign = remoteField.h_align_img || 'left';
				localField.horizontalAlignUserDefined = Boolean(remoteField.h_align_img_ud) || false;
				localField.verticalAlign = remoteField.v_align || 'top';
				localField.verticalAlignUserDefined = Boolean(remoteField.v_align_ud) || false;
				localField.borderWidth = remoteField.border_width || 0;
				localField.borderColor = await artworkColor(remoteField.border_colour);
				// data
				localField.pdfOrigin = remoteField.choice_origin || 'user_files';
				localField.pdfOriginChoice =
					remoteField.choice_origin === 'category' &&
					remoteField.choice_id &&
					artworkExtraData.categories.length > 0
						? categoriesById[remoteField.choice_id] || [] // category ID is integer
						: remoteField.choice_origin === 'admin_lightbox' && remoteField.choice_id
						? [{ id: remoteField.choice_id.toString() }] // lightbox ID is string
						: [];
				localField.pdfOriginIncludeUserFiles = Boolean(remoteField.choice_own) || false;
				localField.pdfOriginChoiceStyle = remoteField.choice_style || 'popup';
				localField.defaultMediafileId = `${remoteField.value_img || ''}`; // remoteField.value_img ??? it is a media file ID
				localField.defaultMediafilePreviewUrl = remoteField.defaultPreviewUrl || ''; // default mediafile preview url
				localField.defaultMediafileHighResUrl = remoteField.defaultHighResUrl || ''; // high-resolution url of default mediafile (for generating high quality pdf)
				localField.defaultMediafileOptimisedUrl = remoteField.defaultOptimisedUrl || ''; // optimised url of default mediafile (fast loading & good quality for webpage)

				break;
			case 'barcode': {
				// styles
				localField.color = await artworkColor(remoteField.font_colour);
				localField.horizontalAlign = remoteField.h_align_img || 'left';
				localField.horizontalAlignUserDefined = Boolean(remoteField.h_align_img_ud) || false;
				localField.verticalAlign = remoteField.v_align || 'top';
				localField.verticalAlignUserDefined = Boolean(remoteField.v_align_ud) || false;
				// data
				localField.append =
					!remoteField.barcode_append ||
					remoteField.barcode_append === '0' ||
					remoteField.barcode_append === 'null'
						? ''
						: `${remoteField.barcode_append}`; // it is a field ID
				localField.EAN5Addon = Boolean(remoteField.barcode_addon) || false;

				localField.autoImport =
					remoteField.dataimport &&
					['cell:', 'image:'].some((k) => remoteField.dataimport.startsWith(k))
						? remoteField.dataimport.substring(0, remoteField.dataimport.lastIndexOf(':'))
						: '';
				localField.defaultAutoImportMeta = {
					basedOn: '',
					spreadsheetCell: null,
					esignEditable: false,
					esignSearchable: false,
					esignFilter: false,
				};
				let basedOnVal = localField.autoImport
					? remoteField.dataimport.substring(remoteField.dataimport.lastIndexOf(':') + 1)
					: ''; // due to legacy issue, we assume the value after the last : is the based on field ID
				localField.autoImportMeta = {
					basedOn: basedOnVal, // ???
					spreadsheetCell:
						localField.autoImport === 'cell:' && basedOnVal ? remoteField.list_column : null, // ???
					esignEditable:
						localField.autoImport === 'cell:' && basedOnVal
							? Boolean(remoteField.esign_editable)
							: false,
					esignSearchable:
						localField.autoImport === 'cell:' && basedOnVal
							? Boolean(remoteField.esign_search)
							: false,
					esignFilter:
						localField.autoImport === 'cell:' && basedOnVal
							? Boolean(remoteField.esign_filter)
							: false,
				};

				localField.defaultValue = `${remoteField.value_img || ''}`; // remoteField.value_img ??? it is a media file ID

				break;
			}
			case 'video':
				localField.sizing = remoteField.img_scale || 'fit';
				localField.sizingClipImage = Boolean(remoteField.img_clip) || true;

				localField.horizontalAlign = remoteField.h_align_img || 'left';
				localField.horizontalAlignUserDefined = Boolean(remoteField.h_align_img_ud) || false;
				localField.verticalAlign = remoteField.v_align || 'top';
				localField.verticalAlignUserDefined = Boolean(remoteField.v_align_ud) || false;
				localField.borderWidth = remoteField.border_width || 0;
				localField.borderColor = await artworkColor(remoteField.border_colour);

				localField.videoOrigin = remoteField.choice_origin || 'user_files';
				localField.videoOriginChoice =
					remoteField.choice_origin === 'category' &&
					remoteField.choice_id &&
					artworkExtraData.categories.length > 0
						? categoriesById[remoteField.choice_id] || [] // category ID is integer
						: remoteField.choice_origin === 'admin_lightbox' && remoteField.choice_id
						? [{ id: remoteField.choice_id.toString() }] // lightbox ID is string
						: [];
				localField.videoOriginIncludeUserFiles = Boolean(remoteField.choice_own) || false;
				localField.videoOriginChoiceStyle = remoteField.choice_style || 'popup';
				localField.defaultMediafileId = `${remoteField.value_img || ''}`; // remoteField.value_img ??? it is a media file ID
				localField.defaultMediafilePreviewUrl = remoteField.defaultPreviewUrl || ''; // default mediafile preview url
				localField.defaultMediafileHighResUrl = remoteField.defaultHighResUrl || ''; // high-resolution url of default mediafile (for generating high quality pdf)
				localField.defaultMediafileOptimisedUrl = remoteField.defaultOptimisedUrl || ''; // optimised url of default mediafile (fast loading & good quality for webpage)
				localField.videoLoop = remoteField.video_loop || false;
				localField.videoLoopUserDefined = remoteField.video_loop_ud || false;

				break;
			case 'grid': {
				localField = { ...localField, ...remoteField.gridConfig };
				localField.fontsizeUserDefined = Boolean(remoteField.font_size_ud) || false;
				let isFontSizeUDValid = Boolean(
					remoteField.font_size_ud_start &&
						remoteField.font_size_ud_inc &&
						remoteField.font_size_ud_end
				);
				localField.fontsizeUDStart = isFontSizeUDValid
					? Number(remoteField.font_size_ud_start)
					: null;
				localField.fontsizeUDStep = isFontSizeUDValid ? Number(remoteField.font_size_ud_inc) : null;
				localField.fontsizeUDEnd = isFontSizeUDValid ? Number(remoteField.font_size_ud_end) : null;
				break;
			}
			default:
				break;
		}

		template.groups[remoteField.group_sequence || 0] = { name: remoteField.group_label };
		template.fields.push(localField);
	}
	// clean up groups array by removing null value and duplicates
	template.groups = _uniqBy(
		template.groups.filter((g) => Boolean(g) || Boolean(g.name)),
		'name'
	);
	if (template.groups.length > 0) {
		template.lastUsedGroupName = template.groups[template.groups.length - 1]['name'];
	} else {
		template.groups = [{ name: defaultGroupName }];
		template.lastUsedGroupName = defaultGroupName;
	}
	return template;
};

export const convertLocalFieldsToRemote = (artworkTemplate) => {
	if (artworkTemplate.fields.length === 0) return [];
	// const cssDpi = calcDpi();
	// TODO: Is it required to apply cssDPpx to the field position???
	// const cssDPpx = calcDPpx();
	const printDpi = 300;
	const ptPerInch = 72;
	const dpiPerPt = printDpi / ptPerInch;
	const dpiPerPx = 1; // cssDpi/cssDpi; //cssDPpx; // ??? do we really need to consider DPpx?
	// TODO: We need to re-think about the these scales when creating preview. e.g. FontSize PT to PX not consider 300 dpi; DPpx is not used
	const pointToPixelScale = dpiPerPt * dpiPerPx;
	// const fontSizePtToPx = cssDpi / ptPerInch; // VID-3184. We use point for font_size from 13/08/2020. So no need of this conversion
	const groupNamesInFields = artworkTemplate.fields.map((f) => f.groupName);
	const usedGroups = artworkTemplate.groups.filter((g) => groupNamesInFields.includes(g.name));

	let groupsJSON = usedGroups.reduce((accu, item, idx) => {
		accu[item.name] = { ...item, sequence: idx + 1, fieldSequence: 1 };
		return accu;
	}, {});
	let templateBG = {
		...(isNullish(artworkTemplate.templateBG?.mediafileId)
			? {}
			: { mediafileId: artworkTemplate.templateBG.mediafileId.toString() }), // default image mediafile id
		...(isNullish(artworkTemplate.templateBG?.mediafilePreviewUrl)
			? {}
			: { mediafilePreviewUrl: artworkTemplate.templateBG.mediafileOptimisedUrl }),
		...(isNullish(artworkTemplate.templateBG?.mediafileHighResUrl)
			? {}
			: { mediafileHighResUrl: artworkTemplate.templateBG.mediafileHighResUrl }),
		...(isNullish(artworkTemplate.templateBG?.mediafileOptimisedUrl)
			? {}
			: { mediafileOptimisedUrl: artworkTemplate.templateBG.mediafileOptimisedUrl }),
		...(isNullish(artworkTemplate.templateBG?.mediafilePdfUrl)
			? {}
			: { mediafilePdfUrl: artworkTemplate.templateBG.mediafilePdfUrl }),
		...(isNullish(artworkTemplate.templateBG?.mediafileSvgUrl)
			? {}
			: { mediafileSvgUrl: artworkTemplate.templateBG.mediafileSvgUrl }),
		...(!artworkTemplate.templateBG?.mediafileOrigin
			? {}
			: { mediafileOrigin: artworkTemplate.templateBG.mediafileOrigin }),
		...(Array.isArray(artworkTemplate.templateBG?.mediafileOriginChoice) &&
		artworkTemplate.templateBG.mediafileOriginChoice.length > 0
			? {
					mediafileOriginChoiceId:
						artworkTemplate.templateBG.mediafileOriginChoice[
							artworkTemplate.templateBG.mediafileOriginChoice.length - 1
						].id?.toString() || '',
			  }
			: {}),
		includeUserFiles: artworkTemplate.templateBG?.includeUserFiles || false, // weather or not including user's own files
		isCustomisable: artworkTemplate.templateBG?.isCustomisable || false, // weather or not allowing end user to select background
	};

	let fields = artworkTemplate.fields.map((f) => {
		let remoteField = {};
		// global template properties
		remoteField.html_aw = artworkTemplate.htmlArtwork;
		remoteField.auto_create = artworkTemplate.autoCreateArtwork;
		remoteField.videoArtwork = artworkTemplate.videoArtwork;
		if (artworkTemplate.templateDuration)
			remoteField.animation_total_duration = artworkTemplate.templateDuration;
		remoteField.template_isesign = artworkTemplate.isEsignTemplate;
		remoteField.template_isesignonly = artworkTemplate.isEsignOnly;
		// general field properties
		remoteField.type = f.type;
		remoteField.id = f.id;
		remoteField.label = f.name || '';
		remoteField.aleft = roundDecimals(f.position.left / pointToPixelScale, 0);
		remoteField.top = roundDecimals(f.position.top / pointToPixelScale, 0);
		remoteField.width = roundDecimals(f.position.width / pointToPixelScale, 0);
		remoteField.height = roundDecimals(f.position.height / pointToPixelScale, 0);
		remoteField.rotate = f.position.angle * -1;
		remoteField.img_reposition = f.imageRepositioning; // imageRepositioning is always boolean inside React toolkit
		remoteField.group_label = f.groupName;
		remoteField.help_text = f.helperText || '';
		remoteField.value_hidden = f.hideInput || false;
		remoteField.output_hidden = f.hideOutput || false;
		remoteField.depends_on = f.outputDependsOn || ''; // ??? shall we use null as default?
		remoteField.animation_name = f.animation.entrance || '';
		remoteField.animation_delay = f.animation.delay || 0; // ??? shall we use null as default?
		remoteField.animation_duration = f.animation.duration || 0;
		remoteField.animation_exit = f.animation.exit || '';
		if (
			artworkTemplate.videoArtwork &&
			f.insertionOnVideo
			// &&
			// f.insertionOnVideo.startTime &&
			// f.insertionOnVideo.duration > 0
		) {
			// the field could be non-text field, but it is fine as we use supportedFieldTypesInVideoArtwork to control to use it or not
			// idea behinds it is that the insertion in theory works in all fields except video field, so we could easily support other fields without changing anything
			remoteField.insertionOnVideo = f.insertionOnVideo;
		}
		remoteField.group_sequence = usedGroups.length === 1 ? 0 : groupsJSON[f.groupName].sequence;
		remoteField.sequence = groupsJSON[f.groupName].fieldSequence;
		groupsJSON[f.groupName].fieldSequence += 1;

		remoteField.filename_field = artworkTemplate.filenameFieldId === f.id;
		remoteField.filename_field_append =
			artworkTemplate.filenameFieldId === f.id
				? artworkTemplate.filenameFieldAppend ?? false
				: false;
		remoteField.video_preview_field = artworkTemplate.videoPreviewFieldId === f.id;

		switch (f.type) {
			case 'text': {
				remoteField.text_display = f.inputStyle || 'text';
				remoteField.text_nofit = f.doNotSetToFit || false;
				remoteField.text_smartquotes = f.smartQuotes || false;
				remoteField.text_nobreaks = f.removeLineBreaks || false;
				remoteField.text_breakslash = f.wrapLinesContainingSlash || false;

				remoteField.font_strikethrough = !f.strikeThrough ? 0 : f.strikeThroughStyle.lineWidth; // treat it in pixel
				remoteField.font_strikestyle = !f.strikeThrough
					? ''
					: f.strikeThroughStyle.type === 'strike'
					? ''
					: f.strikeThroughStyle.type;

				remoteField.text_allcaps = f.allCaps || false;
				remoteField.font_formatnumber = f.formatNumber ? f.formatNumberStyle.currencySymbol : '';
				remoteField.font_formatnumbersize = f.formatNumber
					? parseInt(f.formatNumberStyle.currencyFontScale * 100)
					: 100;
				remoteField.text_currency_font = f.formatNumber
					? f.formatNumberStyle.currencyFontName
					: 'same';
				remoteField.font_formatnumberalign = f.formatNumber
					? f.formatNumberStyle.currencyVerticalAlign
					: 'top';
				remoteField.font_formatposition = f.formatNumber
					? f.formatNumberStyle.currencyPosition
					: 'leading';
				remoteField.font_formatcurrencycents = f.formatNumber
					? f.formatNumberStyle.displayCent
					: false;
				remoteField.font_formatcurrencywhole = f.formatNumber
					? f.formatNumberStyle.doNotDisplayZeroCent
					: false;
				remoteField.font_formatdecimalsize = f.formatNumber
					? parseInt(f.formatNumberStyle.centFontScale * 100)
					: 100;
				remoteField.font_formatdecimalalign = f.formatNumber
					? f.formatNumberStyle.centVerticalAlign
					: 'bottom';

				remoteField.text_batchrepeat = f.batchRepeatingField
					? f.batchRepeatingFieldStyle.NoOfRepetitions
					: 0;
				remoteField.text_batchdelimiter = f.batchRepeatingField
					? f.batchRepeatingFieldStyle.delimiter
					: ''; // ??? what is the default value?
				remoteField.tablecolumns = f.tableColumns;
				remoteField.font_face = f.fontfaceName;
				remoteField.font_size = roundDecimals(f.fontsize, 0); // VID-3184. We use point for font_size from 13/08/2020
				remoteField.font_size_ud = f.fontsizeUserDefined;
				remoteField.font_colour = convertLocalColorToRemote(f.fontColor);
				remoteField.font_colour_hex = f.fontColor.hex || '#000000';
				remoteField.h_align_txt = f.textHorizontalAlign || 'center';
				remoteField.h_align_txt_ud = f.textHorizontalAlignUserDefined || false;
				remoteField.v_align_txt = f.textVerticalAlign || 'bottom';
				remoteField.v_align_txt_ud = f.textVerticalAlignUserDefined || false;
				remoteField.font_letterspacing =
					f.letterSpacing === 1 ? 0 : parseInt(f.letterSpacing * f.fontsize); // For backward compitible, we use point for font_letterspacing. // VID-3184. We use point for font_size from 13/08/2020. So it is already in point
				remoteField.font_leading =
					f.leadingLineHeight === 1.0
						? 'compact'
						: f.leadingLineHeight === 1.2
						? 'auto'
						: roundDecimals(f.leadingLineHeight * f.fontsize, 0).toString(); // ??? need to check if it is correct // VID-3184. We use point for font_size from 13/08/2020. So it is already in point

				var hasShadow = Boolean(!!f.shadowHorOffset || !!f.shadowVerOffset || !!f.shadowBlurRadius);
				remoteField.shadow_hor = hasShadow ? f.shadowHorOffset || 0 : 0;
				remoteField.shadow_ver = hasShadow ? f.shadowVerOffset || 0 : 0;
				remoteField.shadow_blur = hasShadow ? f.shadowBlurRadius || 0 : 0;
				remoteField.shadow_colour = hasShadow
					? convertLocalColorToRemote(f.textShadowColor)
					: '#000000';
				remoteField.shadow_colour_hex = f.textShadowColor.hex || '#000000';
				/** text data */
				remoteField.dataimport =
					!f.predefinedValue.type && f.autoImport
						? `${f.autoImport}:${f.autoImportMeta.basedOn}`
						: '';
				if (remoteField.dataimport) {
					remoteField.list_column = Number(f.autoImportMeta.spreadsheetCell || 0);
					remoteField.esign_editable = f.autoImportMeta.esignEditable || false;
					remoteField.esign_search = f.autoImportMeta.esignSearchable || false;
					remoteField.esign_filter = f.autoImportMeta.esignFilter || false;
				}

				remoteField.price_calculate = f.calcValue.price || '0';
				remoteField.unit_calculate = f.calcValue.unit || '0';
				remoteField.qty_calculate = f.calcValue.qty || '0';
				remoteField.target_calculate = f.calcValue.per || '0';
				remoteField.target_calculate_hide = f.calcValue.hide || false;
				remoteField.unit_multiple = f.calcValue.multipleWeightsMsg || '';
				remoteField.list_origin =
					!f.autoImport && f.predefinedValue.type ? f.predefinedValue.type || '' : '';
				if (remoteField.list_origin) {
					remoteField.list_id = Number(f.predefinedValue.from || 0);
					remoteField.list_column = Number(f.predefinedValue.fromColumn || 0);
					remoteField.list_other = +(f.predefinedValue.listAllowOther || false); // convert boolean to int
					remoteField.outputtemplate_id =
						f.predefinedValue.type === 'spreadsheet' && f.predefinedValue.from
							? Number(artworkTemplate.outputTemplate || 0)
							: 0;
					remoteField.esign_alt_template_id =
						f.predefinedValue.type === 'spreadsheet' && f.predefinedValue.from
							? Number(artworkTemplate.alternativeTemplateId) || 0
							: 0;
				}

				remoteField.value = f.defaultValue || '';
				remoteField.embed_style = f.embedStyle || 'inherit'; // ??? is there a default value
				remoteField.leading_style = f.embedLeadingStyle || 'inherit'; // ??? is there a default value
				remoteField.font_size_ud_start = Number(f.fontsizeUDStart || 0);
				remoteField.font_size_ud_inc = Number(f.fontsizeUDStep || 0);
				remoteField.font_size_ud_end = Number(f.fontsizeUDEnd || 0);

				break;
			}
			case 'image': {
				// styles
				remoteField.img_scale = f.sizing || 'crop';
				remoteField.img_clip = f.sizingClipImage || false;
				remoteField.h_align_img = f.horizontalAlign || false;
				remoteField.h_align_img_ud = f.horizontalAlignUserDefined || false;
				remoteField.v_align = f.verticalAlign || 'middle';
				remoteField.v_align_ud = f.verticalAlignUserDefined || false;
				remoteField.border_width = Number(f.borderWidth) || 0;
				remoteField.border_colour = convertLocalColorToRemote(f.borderColor);
				remoteField.border_colour_hex = f.borderColor.hex || '#000000';
				// data
				remoteField.dataimport = f.autoImport ? `${f.autoImport}:${f.autoImportMeta.basedOn}` : '';
				if (remoteField.dataimport) {
					remoteField.list_column = Number(
						f.autoImportMeta.spreadsheetImageColumn || f.autoImportMeta.spreadsheetCell || 0
					);
					remoteField.esign_editable = f.autoImportMeta.esignEditable || false;
					remoteField.esign_search = f.autoImportMeta.esignSearchable || false;
					remoteField.esign_filter = f.autoImportMeta.esignFilter || false;
				}

				remoteField.choice_origin = f.imageOrigin || 'user_files';
				remoteField.choice_id =
					f.imageOriginChoice.length > 0
						? Number(f.imageOriginChoice[f.imageOriginChoice.length - 1].id || 0)
						: 0;
				remoteField.choice_own = f.imageOriginIncludeUserFiles || false;
				remoteField.choice_style = f.imageOriginChoiceStyle || 'popup';
				remoteField.preSearchFieldId = f.preSearchFieldId || '';
				remoteField.value_img = f.defaultMediafileId ? Number(f.defaultMediafileId) : 0; // ??? we don't have it yet. it is a media file ID
				remoteField.generateAi = f.generateAi || false;
				remoteField.generateAiFieldId = f.generateAiFieldId || '';
				break;
			}
			case 'pdf':
				// style
				remoteField.pdf_anchor = f.anchor || 'bottom';
				remoteField.img_scale = f.sizing || 'fit';
				remoteField.img_clip = f.sizingClipImage || true;
				remoteField.h_align_img = f.horizontalAlign || 'left';
				remoteField.h_align_img_ud = f.horizontalAlignUserDefined || false;
				remoteField.v_align = f.verticalAlign || 'top';
				remoteField.v_align_ud = f.verticalAlignUserDefined || false;
				remoteField.border_width = Number(f.borderWidth) || 0;
				remoteField.border_colour = convertLocalColorToRemote(f.borderColor);
				remoteField.border_colour_hex = f.borderColor.hex || '#000000';
				// data
				remoteField.choice_origin = f.pdfOrigin || 'user_files';

				remoteField.choice_id =
					f.pdfOriginChoice.length > 0
						? Number(f.pdfOriginChoice[f.pdfOriginChoice.length - 1].id || 0)
						: 0;
				remoteField.choice_own = f.pdfOriginIncludeUserFiles || false;
				remoteField.choice_style = f.pdfOriginChoiceStyle || 'popup';
				remoteField.value_img = f.defaultMediafileId ? Number(f.defaultMediafileId) : 0; // ??? we don't have it yet. it is a media file ID

				break;
			case 'barcode': {
				// styles
				remoteField.font_colour = convertLocalColorToRemote(f.color);
				remoteField.font_colour_hex = f.color.hex || '#000000';
				remoteField.h_align_img = f.horizontalAlign || 'left';
				remoteField.h_align_img_ud = f.horizontalAlignUserDefined || false;
				remoteField.v_align = f.verticalAlign || 'top';
				remoteField.v_align_ud = f.verticalAlignUserDefined || false;
				// data
				remoteField.barcode_append = f.append || '0';
				remoteField.barcode_addon = f.EAN5Addon || false;

				remoteField.dataimport = f.autoImport ? `${f.autoImport}:${f.autoImportMeta.basedOn}` : '';
				if (remoteField.dataimport) {
					remoteField.list_column = Number(f.autoImportMeta.spreadsheetCell || 0);
					remoteField.esign_editable = f.autoImportMeta.esignEditable || false;
					remoteField.esign_search = f.autoImportMeta.esignSearchable || false;
					remoteField.esign_filter = f.autoImportMeta.esignFilter || false;
				}
				remoteField.value_img = f.defaultValue ? Number(f.defaultValue) : 0; // ??? we don't have it yet. it is a media file ID

				break;
			}
			case 'video':
				remoteField.img_scale = f.sizing || 'fit';
				remoteField.img_clip = f.sizingClipImage || true;
				remoteField.h_align_img = f.horizontalAlign || 'left';
				remoteField.h_align_img_ud = f.horizontalAlignUserDefined || false;
				remoteField.v_align = f.verticalAlign || 'top';
				remoteField.v_align_ud = f.verticalAlignUserDefined || false;
				remoteField.border_width = Number(f.borderWidth) || 0;
				remoteField.border_colour = convertLocalColorToRemote(f.borderColor);
				remoteField.border_colour_hex = f.borderColor.hex || '#000000';

				remoteField.choice_origin = f.videoOrigin || 'user_files';
				remoteField.choice_id =
					f.videoOriginChoice.length > 0
						? Number(f.videoOriginChoice[f.videoOriginChoice.length - 1].id || 0)
						: 0;
				remoteField.choice_own = f.videoOriginIncludeUserFiles || false;
				remoteField.choice_style = f.videoOriginChoiceStyle || 'popup';
				remoteField.value_img = f.defaultMediafileId ? Number(f.defaultMediafileId) : 0; // ??? we don't have it yet. it is a media file ID
				remoteField.video_loop = f.videoLoop;
				remoteField.video_loop_ud = f.videoLoopUserDefined;
				break;
			case 'grid':
				remoteField.font_size_ud = f.fontsizeUserDefined;
				remoteField.font_size_ud_start = Number(f.fontsizeUDStart || 0);
				remoteField.font_size_ud_inc = Number(f.fontsizeUDStep || 0);
				remoteField.font_size_ud_end = Number(f.fontsizeUDEnd || 0);
				remoteField.gridConfig = {
					// styles
					fontColor: f.fontColor,
					allCap: f.allCap,
					headerRule: f.headerRule,
					headerRuleColor: f.headerRuleColor,
					bodyRowsRule: f.bodyRowsRule,
					bodyRowsRuleColor: f.bodyRowsRuleColor,

					// area 3.1
					titleHeaderFontfaceName: f.titleHeaderFontfaceName,
					titleHeaderFontsize: f.titleHeaderFontsize,

					// area 3.3
					titleCellFontfaceName: f.titleCellFontfaceName,
					titleCellFontsize: f.titleCellFontsize,
					titleCellSubtitleFontsizeScale: f.titleCellSubtitleFontsizeScale,

					// area 3.2
					valueHeaderFontfaceName: f.valueHeaderFontfaceName,
					valueHeaderFontsize: f.valueHeaderFontsize,

					// area 3.4
					valueCellFontfaceName: f.valueCellFontfaceName,
					valueCellFontsize: f.valueCellFontsize,
					valueCellSubtitleFontsizeScale: f.valueCellSubtitleFontsizeScale,

					// data
					defaultEditorHtml: f.defaultEditorHtml,
				};
				break;
			default:
				break;
		}

		return remoteField;
	});
	return { templateBG, fields };
};
