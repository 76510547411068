import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { convertPxToMm } from 'utils/artwork/artUtilsCommon';

// MUI components
import { TextField, Typography, Button } from '@mui/material';
import { Autocomplete } from '@mui/material';

import { blueGrey } from '@mui/material/colors';

import { Import as ImportIcon, InformationVariant as TipIcon } from 'mdi-material-ui';

// intl lang
import { useIntl } from 'react-intl';

const titleHeight = 74;
const borderColor = blueGrey[300];
const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
		width: '100%',
		height: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: 'inherit',
	},
	title: {
		width: '100%',
		position: 'relative',
		color: 'inherit',
		backgroundColor: 'inherit',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(16),
		// textTransform: 'capitalize',
		userSelect: 'none',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		height: titleHeight,
		padding: '10px 2px',
		margin: 'auto',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'&:after': {
			content: '""',
			margin: '0 auto',
			width: '100%',
			// padding: '4px 0',
			opacity: 0.8,
			borderBottom: `1px solid ${borderColor}`,
		},
	},
	titleText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
	},

	contentWrapper: {
		// margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		// width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		padding: theme.spacing(2, 1),
		width: '100%',
		height: `calc(100% - ${titleHeight}px)`,
		color: 'inherit',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	autoComplete: {
		width: '100%',
	},
	// autoCompleteOption: {
	// 	// '&:focus': {
	// 	// 	backgroundColor: 'rgba(0, 0, 0, 0.06)',
	// 	// },
	// 	'&[data-focus="true"]': {
	// 		backgroundColor: 'rgba(0, 0, 0, 0.06)',
	// 	},
	// },
	inheritFontStyle: {
		color: 'inherit',
		fontSize: 'inherit',
	},
	// autoCompleteLabel: {
	// 	color: 'inherit',
	// 	fontSize: 'inherit',
	// 	'& $focused': {
	// 		color: 'inherit',
	// 		fontSize: 'inherit',
	// 	},
	// },
	// autoCompleteLabelRoot: {
	// 	'&$focused$notchedOutline': {
	// 		borderColor: 'orange',
	// 	},
	// },
	// autoCompleteLabelFocused: {},
	// autoCompleteLabelNotchedOutline: {},
	ButtonsRoot: {
		width: '100%',
		padding: theme.spacing(2, 0),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	tipRoot: {
		width: '100%',
		padding: theme.spacing(1, 0),
		display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'center',
	},
	tipContent: {
		// fontStyle: 'italic',
		// color: theme.palette.warning.light,
		color: theme.palette.text.hint,
		paddingLeft: theme.spacing(1),
	},
}));

function ImportTemplate({ templateList, fetchTemplateListStatus, importTemplateById, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();
	// state of user selected template for importing. format: { templateId: '', label: '' }
	const [selectedTemplate, setSelectedTemplate] = React.useState({});
	return (
		<div className={classes.wrapper}>
			<div className={classes.title}>
				<div className={classes.titleText}>
					<ImportIcon style={{ marginRight: 8 }} fontSize="small" />
					{intl.formatMessage({
						id: 'pages.Artwork.components.Sidemenu.ImportTemplate.titleText',
					})}
				</div>
			</div>
			<div className={classes.contentWrapper}>
				{
					<Autocomplete
						className={classes.autoComplete}
						classes={{
							clearIndicator: classes.inheritFontStyle,
							popupIndicator: classes.inheritFontStyle,
							// option: classes.autoCompleteOption,
						}}
						loading={fetchTemplateListStatus === 'PROCESSING'}
						blurOnSelect={true}
						openOnFocus={true}
						selectOnFocus={true}
						noOptionsText={intl.formatMessage({
							id: 'pages.Artwork.components.Sidemenu.ImportTemplate.noOptionsText',
						})}
						openText={intl.formatMessage({ id: 'GENERAL.Open' })}
						clearText={intl.formatMessage({ id: 'GENERAL.Clear' })}
						// open={true}
						// autoHighlight
						// autoSelect={true}
						options={[
							{ templateId: '', label: 'None' },
							...templateList.map((template) => ({
								templateId: template.id,
								label: `${template.name} (${convertPxToMm(template.width)} x ${convertPxToMm(
									template.height
								)} mm)`,
							})),
						]}
						size="small"
						value={selectedTemplate || {}}
						// popupIcon={<DropDownIcon fontSize="small" />}
						// closeIcon={<ClearIcon fontSize="small" />}
						// classes={{
						// 	inputRoot: classes.autoCompleteInputRoot,
						// 	clearIndicator: classes.autoCompleteClearIndicator,
						// 	popupIndicator: classes.autoCompletePopupIndicator,
						// 	endAdornment: classes.autoCompleteEndAdornment,
						// 	listbox: classes.autoCompleteListbox,
						// 	option: classes.autoCompleteOption,
						// }}
						getOptionLabel={(option) => option.label || ''}
						isOptionEqualToValue={(option, value) => option.templateId === value.templateId}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								// label="Select template to import"
								placeholder={intl.formatMessage({
									id: 'pages.Artwork.components.Sidemenu.ImportTemplate.autoCompletePlaceHolder',
								})}
								// InputLabelProps={{ shrink: false }}
								InputProps={{
									...params.InputProps,
									className: `${params.InputProps.className || ''} ${classes.inheritFontStyle}`,
								}}
								// InputLabelProps={{
								// 	...(params.InputLabelProps || {}),
								// 	className: `${(params.InputLabelProps || {}).className || ''} ${
								// 		classes.autoCompleteLabel
								// 	}`,
								// 	// classes: { focused: classes.inheritFontStyle },
								// 	// classes: {
								// 	// 	root: classes.autoCompleteLabelRoot,
								// 	// 	focused: classes.autoCompleteLabelFocused,
								// 	// 	notchedOutline: classes.autoCompleteLabelNotchedOutline,
								// 	// },
								// }}
							/>
						)}
						onChange={(e, value, reason) => {
							if (reason === 'selectOption') {
								setSelectedTemplate(value);
							} else if (reason === 'clear') {
								setSelectedTemplate({});
							}
						}}
					/>
				}
				{/* Buttons */}
				<div className={classes.ButtonsRoot}>
					<Button
						variant="contained"
						color="primary"
						disabled={!selectedTemplate.templateId}
						className={classes.button}
						startIcon={<ImportIcon fontSize="small" />}
						onClick={() => importTemplateById(selectedTemplate.templateId)}
					>
						{intl.formatMessage({
							id: 'pages.Artwork.components.Sidemenu.ImportTemplate.importButtonText',
						})}
					</Button>
				</div>
				{/* Tip text */}
				<div className={classes.tipRoot}>
					<TipIcon fontSize="large" color="inherit" />
					<Typography variant="body2" component="span" className={classes.tipContent}>
						{
							// `You can't import template once you save it. The "import" option is ONLY available until your first save.`
						}
						{intl.formatMessage({ id: 'pages.Artwork.components.Sidemenu.ImportTemplate.tipText' })}
					</Typography>
				</div>
			</div>
		</div>
	);
}

ImportTemplate.propTypes = {
	/**
	 * A list of templates for user to select
	 * Format:
	  [
			{
				"id": "string",
				"name": "string",
				"width": 0, // template width in pixel
				"height": 0 // template height in pixel
			},
			...
		]
	 */
	templateList: PropTypes.array.isRequired,
	// status of GET template list
	fetchTemplateListStatus: PropTypes.oneOf(['', 'PROCESSING', 'OK', 'FAILED']).isRequired,
	/**
	 * trigger importing template process
	 * @param {string} templateId. template id that to be imported
	 */
	importTemplateById: PropTypes.func.isRequired,
};

ImportTemplate.defaultProps = {};
export default ImportTemplate;
