import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

import { grey } from '@mui/material/colors';
// icons
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';

// const SearchStyle = (theme) => ({
// 	container: {
// 		textAlign: 'center',
// 	},
// 	iconButton: {
// 		// padding: theme.spacing(1),
// 		marginLeft: theme.spacing(1),
// 	},
// 	input: {
// 		marginLeft: theme.spacing(1),
// 		fontSize: 'inherit',
// 		flex: 1,
// 		position: 'relative',
// 		width: '50%',
// 		borderStyle: 'solid',
// 		borderWidth: 1,
// 		borderRadius: 4,
// 		borderColor: grey[500],
// 		backgroundColor: '#ffffff',
// 		paddingLeft: theme.spacing(1),
// 	},
// });

const useStyles = makeStyles((theme) => ({
	container: {
		textAlign: 'center',
	},
	iconButton: {
		// padding: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	input: {
		marginLeft: theme.spacing(1),
		fontSize: 'inherit',
		flex: 1,
		position: 'relative',
		width: '50%',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: 4,
		borderColor: grey[500],
		backgroundColor: '#ffffff',
		paddingLeft: theme.spacing(1),
	},
}));

function SearchV2({
	className,
	placeholder,
	onChange,
	OnEnterKeyPressed,
	onSearchClick,
	onClearClick,
	value,
	triggerSearchOnBlur,
	...rest
}) {
	const classes = useStyles();
	const [inputValue, setInputValue] = React.useState(value);
	React.useEffect(() => {
		setInputValue(value);
	}, [value]);
	return (
		<div className={cx(classes.container, className)}>
			<InputBase
				className={classes.input}
				placeholder={placeholder}
				// inputRef={this.inputRef}
				value={inputValue}
				inputProps={{
					'aria-label': 'Search',
					style: { border: 'unset', margin: 'auto', boxShadow: 'unset' },
				}}
				onChange={(e) => {
					setInputValue(e.target.value);
					if (typeof onChange === 'function') onChange(e);
				}}
				onKeyPress={(e) => {
					if (e.key === 'Enter' && typeof OnEnterKeyPressed === 'function') {
						OnEnterKeyPressed(e);
					}
				}}
				onBlur={() => {
					if (!triggerSearchOnBlur) return;
					if (!inputValue) {
						onClearClick();
					} else if (typeof onSearchClick === 'function') {
						onSearchClick(inputValue);
					}
				}}
			/>
			<IconButton
				className={classes.iconButton}
				aria-label="Search"
				onClick={() => {
					if (typeof onSearchClick === 'function') {
						onSearchClick(inputValue);
					}
				}}
				title="Search"
				size="small"
			>
				<SearchIcon />
			</IconButton>
			{typeof onClearClick === 'function' && (
				<IconButton
					className={classes.iconButton}
					aria-label="Clear"
					onClick={() => {
						setInputValue('');
						onClearClick();
					}}
					title="Clear"
					size="small"
				>
					<ClearIcon />
				</IconButton>
			)}
		</div>
	);
}

SearchV2.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	triggerSearchOnBlur: PropTypes.bool,
	/**
	 * @param {Event}
	 */
	onChange: PropTypes.func,
	/**
	 * @param {Event}
	 */
	OnEnterKeyPressed: PropTypes.func,
	/**
	 * @param {string}. Search keywords
	 */
	onSearchClick: PropTypes.func,
	/**
	 * no parameter
	 */
	onClearClick: PropTypes.func,
};

SearchV2.defaultProps = {
	classes: {},
	placeholder: 'Search...',
	value: '',
	triggerSearchOnBlur: true,
};

export default SearchV2;
