/** ##############################
 * 	Artwork Common Helper
 *
 * ** Used by both server-side artwork processor and browser-side **
 * ** Try NOT import too many libraries, otherwise it will cause large build **
 * ** Particularly DO NOT import functions from files that have so many imports
 * ** For example,
 * 			- DO NOT import from "utils/Helper.js" because that file has so many import
 * 			- DO NOT import from "utils/appHelper.js" because that file has imports from "utils/Helper.js"
 * **	Well, it does not mean you can't import any library :)
 *
 *  It has common functions that are used by anything artwrok-related
 *
 * 	#############################
 */
import { floorDecimals, isNullish, roundDecimals } from '../generalHelper';
import PPU from './priceUnitQty.js';
import {
	COLORLIST_DATA_ATTR_NAME,
	DEFAULT_FONTSIZE,
	FONTLIST_DATA_ATTR_NAME,
	NS,
	PIXEL_UNIT_MAP,
	pdfPtToPixel,
} from './constants.js';
import { create as jssCreate } from 'jss'; // jss lib is used by MUI, we have MUI, hence we also have jss lib installed. (it doesn't have to be in package.json)
import jssPreset from '@mui/styles/jssPreset';
// import _ from 'lodash';
import _join from 'lodash/join';
import _drop from 'lodash/drop';
import _find from 'lodash/find';
import config from 'config';

/**
 * Check if the field has output data
 * @param {object} fieldOutData {[fieldId]: {}, [fieldId]: {}, ...}
 * @param {object} field the field data
 *
 * @returns {Boolean}
 */
const doesFieldHaveValue = (fieldOutData, field) => {
	switch (field.type) {
		case 'text':
		case 'barcode':
			return Boolean(fieldOutData[field.id].value);
		case 'image':
		case 'pdf':
			return Boolean(fieldOutData[field.id].previewUrl);
		case 'video':
			return Boolean(fieldOutData[field.id].previewUrl);
		case 'grid':
			return fieldOutData[field.id].tableData.length > 0;
		default:
			break;
	}
};

/**
 * Validate ean8, ean13 barcode value.
 * Ref: https://www.getnewidentity.com/validate-ean.php
 * @param {string} eanCode EAN barcode
 *
 * @return {boolean}. If true, the barcode is valid, otherwise is invalid
 */
const isBarcodeValid = (eanCode) => {
	if ('' === eanCode) return !0;
	if (!/^(\d{8}|\d{13})$/.test(eanCode)) return false;
	// if (!/^(\d{8}|\d{12}|\d{13})$/.test(eanCode)) return false;
	for (
		var eanCodeLength = eanCode.length,
			checksum = 0,
			evenOddHash = 8 === eanCodeLength ? [3, 1] : [1, 3],
			i = 0;
		eanCodeLength - 1 > i;
		i++
	)
		checksum += parseInt(eanCode.charAt(i), 10) * evenOddHash[i % 2];

	checksum = (10 - (checksum % 10)) % 10;
	return checksum + '' === eanCode.charAt(eanCodeLength - 1);
};

/**
 * calculate check digit for ean13 barcode
 * @param {string} ean13Code
 *
 * @return {string|null} check digit of the given ean13 code
 */
const calcEan13CheckDigit = (ean13Code) => {
	// validate ean13Code
	if (typeof ean13Code !== 'string' || ean13Code.length !== 12) return null;
	for (
		var eanCodeLength = ean13Code.length, checksum = 0, evenOddHash = [1, 3], i = 0;
		eanCodeLength > i;
		i++
	)
		checksum += parseInt(ean13Code.charAt(i), 10) * evenOddHash[i % 2];

	checksum = (10 - (checksum % 10)) % 10;
	return checksum + '';
};
/**
 * Padding ean barcode (padding zeros to beginning)
 * Note, the eancode should be valid before calling this function
 * @param {string} eanCode integer in string format
 * @param {number} size, default 13
 *
 * @return {string|null}, if the length of eanCode is greater than the size, return null, otherwise, return formatted eanCode
 */
const paddingEANCode = (eanCode, size = 13) => {
	let eanCodeNumber = parseInt(eanCode);
	if (isNaN(eanCodeNumber) || eanCodeNumber.toString().length > size) return null;
	// padding leading zeros and make total length to "size"
	return eanCodeNumber.toString().padStart(size, '0');
};

/**
 * format ean5 code
 * original ean5 code could be price decimal, we will remove "." and use only the number as ean5 code
 * @param {string} ean5Code code. could be price decimal, etc.
 *
 * @return {string|null}
 */
const formatEan5Code = (ean5Code) => {
	if (ean5Code) {
		const regexToFindNum = /[\d,.]+/g; // regex to find number
		// remove decimal & format ean5 code
		let ean5Num = parseFloat((ean5Code.match(regexToFindNum) || [])[0]);
		if (isNaN(ean5Num)) {
			ean5Code = null;
		} else if (Number.isInteger(ean5Num)) {
			// it is  an integer
			ean5Code = paddingEANCode(ean5Num.toString(), 5);
		} else {
			// it is a float number, but we only use the whole number part

			ean5Code = paddingEANCode(parseInt(ean5Num * 100).toString(), 5);
		}
	} else {
		ean5Code = null;
	}
	return ean5Code;
};

/**
 * build barcode value/text
 * See comment in ticket VID-3185 for detail explaination
 * The code in this func has many if...else, that is to match the logic explaination in VID-3185
 *
 * @param {*} barcodeField "text" field
 * @param {*} appendField null or "text" field
 * @param {*} fieldInputData field input data
 *
 * @return {object}. {ean13Code, ean5Code}. ean13Code/ean5Code can be null/undefined
 */
const buildEANBarcode = (barcodeField = {}, appendField = {}, fieldInputData = {}) => {
	if (barcodeField.type !== 'barcode' || (appendField.type && appendField.type !== 'text'))
		return {};

	let ean13Code = isNullish((fieldInputData[barcodeField.id] || {}).value) // merge user input data & field default data.
		? barcodeField.defaultValue || null
		: (fieldInputData[barcodeField.id] || {}).value || null;
	let ean5Code = isNullish((fieldInputData[barcodeField.append] || {}).value) // merge user input data & field default data in barcode append (EAN5) field.
		? appendField.defaultValue || null
		: (fieldInputData[barcodeField.append] || {}).value || null;
	if (ean13Code && isBarcodeValid(ean13Code.padStart(13, '0'))) {
		// we use padded ean13 code when check if it is valid, in case Excel stripped zeros from original value
		// ean13Code is valid,
		ean13Code = paddingEANCode(ean13Code, 13);
		ean5Code = barcodeField.EAN5Addon ? formatEan5Code(ean5Code) : null;
	} else if (isNullish(ean13Code)) {
		// ean13Code has no value
		ean13Code = null;
		ean5Code = null;
	} else {
		/** ean13Code has value but is invalid, it may require a little calculation */
		// remove leading & trailing zeros from ean13Code
		ean13Code = ean13Code.replace(/^0+(\d)|(\d)0+$/gm, '$1$2');

		if (ean13Code === '0') {
			ean5Code = null;
			ean13Code = null;
		} else if (!ean5Code) {
			// ean5Code has no value
			ean5Code = null;
			ean13Code = null; // ean13Code.padEnd(12, '0') + calcEan13CheckDigit(ean13Code.padEnd(12, '0')); // ??? padStart or padEnd ???
		} else {
			// In this "else", ean13Code & ean5Code both have value (not null)
			if (barcodeField.EAN5Addon) {
				ean5Code = null;
				ean13Code = null;
			} else {
				// Case: EAN5 addon = false. We append ean5Code to end of ean13Code (12 digits + 1 check digit)
				if (ean13Code.length + 5 > 12) {
					// ean13 code has no space to append ean5Code
					ean5Code = null;
					ean13Code = null;
				} else {
					let ean13WithoutCheckDigit = ean13Code.padStart(7, '0') + formatEan5Code(ean5Code); // ??? padStart or padEnd ???
					let checkDigit = calcEan13CheckDigit(ean13WithoutCheckDigit);
					ean13Code = checkDigit ? ean13WithoutCheckDigit + checkDigit : null;
					ean5Code = null;
				}
			}
		}
	}

	return { ean13Code, ean5Code };
};

export const convertPxToMm = (valueInPx) => {
	const pointPerMm = 2.8346456692913;
	const printDpi = 300;
	const ptPerInch = 72;
	const dpiPerPt = printDpi / ptPerInch;
	const dpiPerPx = 1; // cssDpi/cssDpi; //cssDPpx; // ??? do we really need to consider DPpx?
	const pxPerPoint = dpiPerPt * dpiPerPx;
	const pxPerMm = pxPerPoint * pointPerMm;
	return roundDecimals(valueInPx / pxPerMm, 0);
};

export const convertMmToPx = (valueInMm) => {
	const pointPerMm = 2.8346456692913;
	const printDpi = 300;
	const ptPerInch = 72;
	const dpiPerPt = printDpi / ptPerInch;
	const dpiPerPx = 1; // cssDpi/cssDpi; //cssDPpx; // ??? do we really need to consider DPpx?
	const pxPerPoint = dpiPerPt * dpiPerPx;
	const pxPerMm = pxPerPoint * pointPerMm;
	return roundDecimals(valueInMm * pxPerMm, 0);
};

export const getTextFieldOutputValue = (textField, fieldInputData) => {
	return isNullish((fieldInputData[textField.id] || {}).value) // merge user input data & field default data.
		? textField.defaultValue.trim() || ''
		: (fieldInputData[textField.id] || {}).value.trim() || '';
};

export const getTextFieldValue = (field, templateFields, fieldInputData) => {
	let fieldValue = getTextFieldOutputValue(field, fieldInputData);

	if (
		field.type === 'text' &&
		field.calcValue.price &&
		field.calcValue.unit &&
		field.calcValue.qty
	) {
		// this is "price calc" field
		let priceField = templateFields.find((f) => f.id === field.calcValue.price);
		let unitField = templateFields.find((f) => f.id === field.calcValue.unit);
		let qtyField = templateFields.find((f) => f.id === field.calcValue.qty);
		if (!priceField || !unitField || !qtyField) return fieldValue;
		let priceVal = getTextFieldOutputValue(priceField, fieldInputData),
			unitVal = getTextFieldOutputValue(unitField, fieldInputData),
			qtyVal = getTextFieldOutputValue(qtyField, fieldInputData);
		let outputUnit = isNaN(parseFloat(field.calcValue.per)) ? null : field.calcValue.per;
		let calcPrice = PPU.PricePerUnit(priceVal, unitVal, qtyVal, outputUnit, {
			// Fix for checklist 1 in VID-3382
			// Because of VID-3199 (checklist 2), the field.formatNumberStyle will not be used if the text value contains non-numeric char,
			// the calculated value here most likely will have non-numeric char (e.g. unit, per, etc.), hence we need to use field.formatNumberStyle here
			// (Previou Comment: we don't use currency symbol when calculate price as currency symbol will be inserted by field.formatNumberStyle in preview comp)
			currencySymbol: field.formatNumberStyle.currencySymbol || '',
			currencyPosition:
				field.formatNumberStyle.currencyPosition === 'leading'
					? 'leading'
					: field.formatNumberStyle.currencyPosition === 'trailing'
					? 'trailing'
					: '',
			perStr: !outputUnit
				? (field.calcValue.per || '').length > 1
					? ` ${field.calcValue.per || ''} `
					: field.calcValue.per || ''
				: ' per ',
			multiUnitMsg: field.calcValue.multipleWeightsMsg || '',
		});
		return calcPrice;
	}
	return fieldValue;
};

/**
 * Format output template and calculate number of items into output template
 * @param {object} templateSize {width: num, height: num}
 * @param {object} outputTemplate
 *
 * @returns {object} 
 * format:
 	{
		 formatedOutputTemplate: {}, // all units are converted from mm to px
		 numOfItemsInRow: num, 
		 numOfRowsInPage: num, 
		 numOfItemsInPage: num
	}
 */
export const outputTemplatePlan = (templateSize, outputTemplate) => {
	let numOfItemsInRow = 1,
		numOfRowsInPage = 1,
		numOfItemsInPage = 1;

	let formatedOutputTemplate = {
		id: outputTemplate.id,
		title: outputTemplate.title,
		mediafile_id: outputTemplate.mediafile_id,

		width: convertMmToPx(outputTemplate.page_width),

		height: convertMmToPx(outputTemplate.page_height),

		margin_top: convertMmToPx(outputTemplate.margin_top),

		margin_bottom: convertMmToPx(outputTemplate.margin_bottom),

		margin_left: convertMmToPx(outputTemplate.margin_left),

		margin_right: convertMmToPx(outputTemplate.margin_right),

		gutter_hor: convertMmToPx(outputTemplate.gutter_hor),

		gutter_ver: convertMmToPx(outputTemplate.gutter_ver),
		created_by: outputTemplate.created_by,
		created_date: outputTemplate.created_date,
		mediafile_url: outputTemplate.mediafile_url, // NOTE: this is a pdf
	};

	/**
			 * calculate number of items in rows and number of rows in a page
			 * 	the number of items we can fit in the available width (same for number of rows in the available height):
						(W - r - l + g)
					x = ———————————————, [x]
										T + g
					where
						W = page width
						r = right margin
						l = left margin
						g = gutter
						T = width of item (Ticket)
					The outcome should be cast from a decimal to a floor integer [x].
				================================================================================
			 */
	numOfItemsInRow =
		floorDecimals(
			(formatedOutputTemplate.width -
				formatedOutputTemplate.margin_left -
				formatedOutputTemplate.margin_right +
				formatedOutputTemplate.gutter_ver) /
				(templateSize.width + formatedOutputTemplate.gutter_ver) +
				0.01 // some templates is slightly offset, 0.01 is to correct the offset
		) || 1;
	numOfRowsInPage =
		floorDecimals(
			(formatedOutputTemplate.height -
				formatedOutputTemplate.margin_top -
				formatedOutputTemplate.margin_bottom +
				formatedOutputTemplate.gutter_hor) /
				(templateSize.height + formatedOutputTemplate.gutter_hor) +
				0.01 // some templates is slightly offset, 0.01 is to correct the offset
		) || 1;
	numOfItemsInPage = numOfItemsInRow * numOfRowsInPage;
	return { formatedOutputTemplate, numOfItemsInRow, numOfRowsInPage, numOfItemsInPage };
};

/**
 * load fonts that are used in template
 * @param {*} fontList [{ name: fontName, fontUrl: font.path }, ...]
 * @returns
 */
export const loadFontface = (fontList) => {
	// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	return Promise.all(fontList.map((fontOption) => loadFontfaceToDocument(fontOption))).then(
		(results) => {
			let errorResults = results.filter((item) => item.error);
			if (errorResults.length > 0) {
				let error = new Error(
					`Can not load font face: ${errorResults
						.map((errResult) => errResult.error.font.name)
						.join(', ')}`
				);
				throw error;
			}
		}
	);
};

/**
 * Add fontface to window.document (to page)
 * Ref:
 * - https://stackoverflow.com/questions/46337609/dynamically-added-font-face-not-working
 * - https://usefulangle.com/post/74/javascript-dynamic-font-loading
 * - https://webplatform.github.io/docs/tutorials/typography/fontface/
 *
 * @param {object} opts {name: 'xxxx', fontUrl: 'xxx'}
 *
 * If failure, it throws an error with font opts
 */
export const loadFontfaceToDocument = (opts) => {
	const fontName = cleanupFontName(opts.name);
	// if the font is already added, skip it.
	// document.fonts.check doesn't work on Safari, Firefox and iOS, so ignore them
	// if (!isSafari && !isFirefox && !isIOS && document.fonts.check(`1rem "${fontName}"`))
	// 	return Promise.resolve({});
	// load the new font
	let newFont = new FontFace(fontName, `url(${encodeURI(opts.fontUrl)})`);
	return newFont
		.load()
		.then(function (loaded_face) {
			document.fonts.add(loaded_face);
			return {};
		})
		.catch(function (error) {
			error.font = opts;
			return { error };
		});
	// var styleSheet = window.document.styleSheets[0];
	// styleSheet.insertRule(
	// 	`@font-face {
	// 		font-family: ${opts.name};
	// 		src: url('${opts.fontUrl}');
	// 	}`,
	// 	styleSheet.cssRules.length
	// );
};

/**
 * Clean up font name by replacing "." with " "
 * The fontname from API is the font file name with "." in there, such fontname is not supported in Safari or Firefox. Hence need clean-up when using it
 * @param {string} originalFontName
 * @returns {string}
 */
export const cleanupFontName = (originalFontName) => {
	return originalFontName ? originalFontName.replace(/\./gm, ' ') : originalFontName;
};

/**
 * Add colorList to Dom as data attribute
 * @param {Element} elem
 * @param {Object} colorList format: {'R-G-B': [c,m,y,k], ...}
 */
export const setColorListDataAttr = (elem, colorList) => {
	elem.setAttribute(COLORLIST_DATA_ATTR_NAME, JSON.stringify(colorList));
};
/**
 * Retrieve colorList from data attribute in Dom
 * @param {Element} elem
 *
 * @return Object|null
 */
export const getColorListFromDataAttr = (elem) => {
	let colorListData = elem.getAttribute(COLORLIST_DATA_ATTR_NAME);
	if (colorListData) {
		try {
			return JSON.parse(colorListData);
		} catch (err) {
			console.debug(err);
			return null;
		}
	}
	return null;
};
export const removeColorListDataAttr = (elem) => {
	elem.removeAttribute(COLORLIST_DATA_ATTR_NAME);
};

/**
 *
 * @param {Element} elem
 * @param {Array} fontList [{name: 'xxxx', fontUrl: 'xxxx}, ...]
 */
export const setFontListDataAttr = (elem, fontList) => {
	elem.setAttribute(FONTLIST_DATA_ATTR_NAME, JSON.stringify(fontList));
};
/**
 * Retrieve fontList from data attribute in Dom
 * @param {Element} elem
 *
 * @return Object|null
 */
export const getFontListFromDataAttr = (elem) => {
	let fontListData = elem.getAttribute(FONTLIST_DATA_ATTR_NAME);
	if (fontListData) {
		try {
			return JSON.parse(fontListData);
		} catch (err) {
			console.debug(err);
			return null;
		}
	}
	return null;
};
export const removeFontListDataAttr = (elem) => {
	elem.removeAttribute(FONTLIST_DATA_ATTR_NAME);
};

/**
 * 
 * @param {any|SVGElement} svg 
 * @param {Object} opts
   {
		 fields: Array, // array of all fields in template
		 animations: Array, // all available animation styles (in JSS format)
	 }
 */
export const addAnimationStyleToSVG = (svg, opts = {}) => {
	let jssInst = jssCreate({
		plugins: [...jssPreset().plugins],

		// eslint-disable-next-line no-unused-vars
		createGenerateId: () => (rule, sheet) => rule.name,
	});
	const style = document.createElementNS(NS.SVG, 'style');

	style.type = 'text/css';
	style.innerHTML = jssInst
		.createStyleSheet(
			opts.fields
				.map((field) => {
					let animStyles = {};
					if (field.animation.entrance) {
						animStyles[`@keyframes ${field.animation.entrance}`] =
							opts.animations[`@keyframes ${field.animation.entrance}`];
					}

					if (field.animation.exit) {
						animStyles[`@keyframes ${field.animation.exit}`] =
							opts.animations[`@keyframes ${field.animation.exit}`];
					}

					return animStyles;
				})
				.reduce((accu, animStyle) => {
					return { ...accu, ...animStyle };
				}, {})
		)
		.toString();

	svg.insertBefore(style, svg.firstChild);
	jssInst = null;
};

/**
 *  get svg data
 * @param {SVGElement|any} svg
 * @param {Object} opts {full: BOOL, fontList:[{name: 'xxxx', fontUrl: 'xxxx}, ...]}
 *
 * @return svg DOM element.
 */
export const addFontStyleToSVG = async (svg, opts = {}) => {
	if (opts.full) {
		// use assets base64 url
		if (opts.fontList.length > 0) {
			let fontBase64Urls = await Promise.all(
				opts.fontList.map((font) => loadFontToBase64Url(font))
			);
			const style = document.createElementNS(NS.SVG, 'style');

			style.type = 'text/css';
			style.innerHTML = fontBase64Urls
				.map(
					(font) => `@font-face {
							font-family: "${cleanupFontName(font.name)}";
							src: url("${font.base64Url}")${
						getFontfaceFormatByFileNameExtersion(font.name)
							? ' format("' + getFontfaceFormatByFileNameExtersion(font.name) + '")'
							: ''
					};
					}`
				)
				.join(`\n`);
			svg.insertBefore(style, svg.firstChild);
		}
	} else {
		// use asset links
		if (opts.fontList.length > 0) {
			const style = document.createElementNS(NS.SVG, 'style');

			style.type = 'text/css';
			style.innerHTML = opts.fontList
				.map(
					(font) => `@font-face {
							font-family: "${cleanupFontName(font.name)}";
							src: url("${encodeURI(font.fontUrl)}")${
						getFontfaceFormatByFileNameExtersion(font.name)
							? ' format("' + getFontfaceFormatByFileNameExtersion(font.name) + '")'
							: ''
					};
					}`
				)
				.join(`\n`);
			svg.insertBefore(style, svg.firstChild);
		}
	}
	return svg;
};

/**
 * get field output data by merging field input data and default data (combination of user input data & field default data)
 * all the value is guaranteed to NOT be null/undefined, the value could be '', true/false, or "actual value"
 * Rules to merge the values:
 * - if value in fieldInputData is null/undefined, use default value (or fallback value) in field
 * - if value in fieldInputData is '', we leave it as it is (being '') [Hint: If you want to use default value when user clears the data, set it to null in fieldInputData]
 * - fontsize in the field is in pdf point (pt), we need to conver it to web point
 * @param {array} templateFields Array of template fields
 * @param {object} fieldInputData field input data per field. Format is the fieldInputData in Create & Design component
 *
 * @return {object} fieldOutData. Same format as the "fieldInputData" in Create & Design component
 */
export const getFieldOutputData = (templateFields, fieldInputData) => {
	let fieldOutData = {};
	templateFields.forEach((field) => {
		switch (field.type) {
			case 'text':
				fieldOutData[field.id] = {
					// the input fontsize is in pdf pt, we need to conver it to web pt
					fontsize:
						(isNullish((fieldInputData[field.id] || {}).fontsize)
							? field.fontsize // field must have fontsize value, hence no fallback value given
							: (fieldInputData[field.id] || {}).fontsize || DEFAULT_FONTSIZE) *
						(pdfPtToPixel / PIXEL_UNIT_MAP.pt), // convert fontsize from pdf pt to web pt. possible value: NUMBER
					fontsizeOriginalValue: isNullish((fieldInputData[field.id] || {}).fontsize)
						? field.fontsize // field must have fontsize value, hence no fallback value given
						: (fieldInputData[field.id] || {}).fontsize || DEFAULT_FONTSIZE, // the original merged fontsize value (without converting). possible value: NUMBER
					horizontalAlign: isNullish((fieldInputData[field.id] || {}).horizontalAlign)
						? field.textHorizontalAlign || ''
						: (fieldInputData[field.id] || {}).horizontalAlign || '', // default field data. 'left' | 'center' | 'right' | 'justified' | undefined,
					verticalAlign: isNullish((fieldInputData[field.id] || {}).verticalAlign)
						? field.textVerticalAlign || ''
						: (fieldInputData[field.id] || {}).verticalAlign || '', // default field data. 'top' | 'middle' | 'bottom' | undefined,
					// ...(fieldInputData[field.id] || {}), // override default values by user input data
					value: getTextFieldValue(field, templateFields, fieldInputData),
					// isNullish((fieldInputData[field.id] || {}).value) // merge user input data & field default data.
					// 	? field.defaultValue || ''
					// 	: (fieldInputData[field.id] || {}).value || '',
				};
				break;
			case 'barcode': {
				let appendField = _find(templateFields, (f) => f.id === field.append);
				let { ean13Code, ean5Code } = buildEANBarcode(field, appendField, fieldInputData);
				fieldOutData[field.id] = {
					horizontalAlign: isNullish((fieldInputData[field.id] || {}).horizontalAlign)
						? field.horizontalAlign || ''
						: (fieldInputData[field.id] || {}).horizontalAlign || '', // default field data. 'left' | 'center' | 'right' | 'justified' | undefined,
					verticalAlign: isNullish((fieldInputData[field.id] || {}).verticalAlign)
						? field.verticalAlign || ''
						: (fieldInputData[field.id] || {}).verticalAlign || '', // default field data. 'top' | 'middle' | 'bottom' | undefined,
					// ...(fieldInputData[field.id] || {}), // override default values by user input data
					value: isNullish((fieldInputData[field.id] || {}).value) // merge user input data & field default data.
						? field.defaultValue || ''
						: (fieldInputData[field.id] || {}).value || '',
					EAN13Value: ean13Code, // (fieldInputData[field.id] || {}).EAN13Value || '',
					EAN5Value: ean5Code, // (fieldInputData[field.id] || {}).EAN5Value || '',
				};
				break;
			}
			case 'image':
				fieldOutData[field.id] = {
					horizontalAlign: isNullish((fieldInputData[field.id] || {}).horizontalAlign)
						? field.horizontalAlign || ''
						: (fieldInputData[field.id] || {}).horizontalAlign || '', // default field data. 'left' | 'center' | 'right' | undefined,
					verticalAlign: isNullish((fieldInputData[field.id] || {}).verticalAlign)
						? field.verticalAlign || ''
						: (fieldInputData[field.id] || {}).verticalAlign || '', // default field data. 'top' | 'middle' | 'bottom' | undefined,
					croppedImgUrl: (fieldInputData[field.id] || {}).croppedImgUrl || '', // use '' as default value in croppedImgUrl
					clippedImgUrl: (fieldInputData[field.id] || {}).clippedImgUrl || '', // use '' as default value in clippedImgUrl
					// ...(fieldInputData[field.id] || {}), // override default values by user input data
					mediafileId: isNullish((fieldInputData[field.id] || {}).mediafileId) // merge user input data & field default data.
						? field.defaultMediafileId || ''
						: (fieldInputData[field.id] || {}).mediafileId || '',
					previewUrl: isNullish((fieldInputData[field.id] || {}).previewUrl) // merge user input data & field default data.
						? field.defaultMediafilePreviewUrl || ''
						: (fieldInputData[field.id] || {}).previewUrl || '',
					optimisedUrl: isNullish((fieldInputData[field.id] || {}).optimisedUrl) // merge user input data & field default data.
						? field.defaultMediafileOptimisedUrl || ''
						: (fieldInputData[field.id] || {}).optimisedUrl || '',
					highResUrl: isNullish((fieldInputData[field.id] || {}).highResUrl) // merge user input data & field default data.
						? field.defaultMediafileHighResUrl || ''
						: (fieldInputData[field.id] || {}).highResUrl || '',
				};
				break;
			case 'pdf':
				fieldOutData[field.id] = {
					horizontalAlign: isNullish((fieldInputData[field.id] || {}).horizontalAlign)
						? field.horizontalAlign || ''
						: (fieldInputData[field.id] || {}).horizontalAlign || '', // default field data. 'left' | 'center' | 'right' | undefined,
					verticalAlign: isNullish((fieldInputData[field.id] || {}).verticalAlign)
						? field.verticalAlign || ''
						: (fieldInputData[field.id] || {}).verticalAlign || '', // default field data.
					// ...(fieldInputData[field.id] || {}), // override default values by user input data
					previewUrl: isNullish((fieldInputData[field.id] || {}).previewUrl) // merge user input data & field default data.
						? field.defaultMediafilePreviewUrl || ''
						: (fieldInputData[field.id] || {}).previewUrl || '',
					optimisedUrl: isNullish((fieldInputData[field.id] || {}).optimisedUrl) // merge user input data & field default data.
						? field.defaultMediafileOptimisedUrl || ''
						: (fieldInputData[field.id] || {}).optimisedUrl || '',
					highResUrl: isNullish((fieldInputData[field.id] || {}).highResUrl) // merge user input data & field default data.
						? field.defaultMediafileHighResUrl || ''
						: (fieldInputData[field.id] || {}).highResUrl || '',
				};
				break;
			case 'video':
				fieldOutData[field.id] = {
					horizontalAlign: isNullish((fieldInputData[field.id] || {}).horizontalAlign)
						? field.horizontalAlign || ''
						: (fieldInputData[field.id] || {}).horizontalAlign || '', // default field data. 'left' | 'center' | 'right' | undefined,
					verticalAlign: isNullish((fieldInputData[field.id] || {}).verticalAlign)
						? field.verticalAlign || ''
						: (fieldInputData[field.id] || {}).verticalAlign || '', // default field data.
					videoLoop: isNullish((fieldInputData[field.id] || {}).videoLoop)
						? field.videoLoop || false
						: (fieldInputData[field.id] || {}).videoLoop || false,
					// (fieldInputData[field.id] || {}).videoLoop || field.videoLoop, // default field data
					// ...(fieldInputData[field.id] || {}), // override default values by user input data
					previewUrl: isNullish((fieldInputData[field.id] || {}).previewUrl) // merge user input data & field default data.
						? field.defaultMediafilePreviewUrl || ''
						: (fieldInputData[field.id] || {}).previewUrl || '',
					optimisedUrl: isNullish((fieldInputData[field.id] || {}).optimisedUrl) // merge user input data & field default data.
						? field.defaultMediafileOptimisedUrl || ''
						: (fieldInputData[field.id] || {}).optimisedUrl || '',
					highResUrl: isNullish((fieldInputData[field.id] || {}).highResUrl) // merge user input data & field default data.
						? field.defaultMediafileHighResUrl || ''
						: (fieldInputData[field.id] || {}).highResUrl || '',
				};
				break;
			case 'grid':
				fieldOutData[field.id] = {
					tableData: fieldInputData[field.id]?.tableData ?? [], // tableData is only from user's input, so that the table data from defaultEditorHtml wouldn't be rendered
					hasHeader: fieldInputData[field.id]?.hasHeader ?? false, // hasHeader is only from user's input, so that the table data from defaultEditorHtml wouldn't be rendered
					editorHtml: fieldInputData[field.id]?.editorHtml ?? field.defaultEditorHtml ?? '', // the html string in the editor. Fallback to field default editor html by design
					fontsize: fieldInputData[field.id]?.fontsize,
					// VID-3577: We want to display default grid table (created by design) when the editorHtml falls to default
					...(fieldInputData[field.id]?.editorHtml === undefined && field.defaultEditorHtml
						? retrieveGridTableData({ editorHtml: field.defaultEditorHtml }) || {}
						: {}),
				};
				break;
			default:
				break;
		}
	});
	return fieldOutData;
};

/**
 * retrieve table data from table html content for grid field (user input in tinymce)
 * @param {object} param
	{
		editorHtml, // user input table html content (tinymce)
	}
 * @returns {object|null}
	{
		tableData,
		hasHeader,
	}
 */
export const retrieveGridTableData = ({ editorHtml }) => {
	if (!editorHtml) return null;
	const div = document.createElementNS(NS.HTML, 'div');
	div.innerHTML = editorHtml;
	let table = div.getElementsByTagName('table')[0];
	if (!table) return null; // no table data at all

	let tableData = [];

	for (let row of table.rows) {
		const rowIndex = row.rowIndex;
		tableData[rowIndex] = [];
		// console.log(rowIndex);
		for (let cell of row.cells) {
			tableData[rowIndex].push(cell.innerText.trim());
		}
	}
	const hasHeader = table.tHead?.rows.length > 0 ?? false;
	// console.log(JSON.stringify(tableData));
	// console.log(JSON.stringify(editorHtml));
	return { tableData, hasHeader };
};

/**
 * Calc field animation delay
 * @param {array} allFields All fields in artwork template (in its layering/group order )
 * @param {object} field The field to be calculate its animation delay
 * @param {object} fieldOutputData {[fieldId]: {}, [fieldId]: {}, ...}
 */
export const calcAnimationDelay = (allFields, field, fieldOutputData) => {
	let animationDelay = 0;
	for (let i = 0; i < allFields.length; i++) {
		let f = allFields[i];
		// After discussion with Pier on 04/09/2020, Pier decided to disable "group" factor when doing animation. To enable it, just uncomment the following line
		// if (f.groupName !== field.groupName) continue;
		animationDelay += doesFieldHaveValue(fieldOutputData, f) ? f.animation.delay || 0 : 0;
		if (f.id === field.id) break;
	}
	return animationDelay;
};

export const isConcatField = (field) => {
	// the value of concatenation field is always its default value
	return field.type === 'text' && new RegExp(/<field:\s*(.*?)\s*>/gim).test(field.defaultValue);
};

/**
 * @param {object} fieldOutputData {[fieldId]: {}, [fieldId]: {}, ...}
 * @param {string} field The text from the field
 */
export const sanitizeFieldOutputValue = (fieldOutputData, text) => {
	let result = '';
	// Checks if the field isConcatField
	if (new RegExp(/<field:\s*(.*?)\s*>/gim).test(text)) {
		result = (text || '')
			.split('\n')
			.map((lineStr) => {
				let words = lineStr.split(' ').filter((w) => Boolean(w.trim()));
				words = words.map((w, idx) => (idx === words.length - 1 ? w : w + ' '));
				return words
					.map((word) => {
						if (word.startsWith('<field:')) {
							// Extract the ID
							let fieldId = word
								.trim()
								.substring(word.lastIndexOf('_') + 1)
								.slice(0, -1);
							let fieldValue = fieldOutputData[fieldId]?.value || '';
							// Replace the concat field with <field: with the actual value
							return fieldValue + ' ';
						} else {
							return word;
						}
					})
					.join('');
			})
			.join('\n');
	} else {
		result = text;
	}
	return result;
};

/**
 * Create Dom element of svg external asset
 * @param {String} svgUrl
 * @param {object} dimension: {width: NUMBER, height: NUMBER}
 *
 * @return {Promise} svg dom
 */
export const createSvgDomFromUrl = async (svgUrl, dimension = {}) => {
	const wrapper = document.createElementNS(NS.SVG, 'svg');
	let svgString = await fetch(svgUrl).then((res) => res.text());
	wrapper.innerHTML = svgString;
	let svgElem = wrapper.children[0];

	svgElem.setAttribute('x', 0);

	svgElem.setAttribute('y', 0);
	svgElem.setAttribute('width', dimension.width || '100%');
	svgElem.setAttribute('height', dimension.height || '100%');
	// svg.setAttribute('overflow', 'visible');
	return svgElem;
};
/**
 * get color list used in artwork template
 * @param {object} artTemplateFields
 * @returns {object}
 */
export const getColorListInTemplateFields = (artTemplateFields) => {
	// use rgb as key, cmyk color as the value. has black & white by default
	let colorList = { '0-0-0': [0, 0, 0, 100], '255-255-255': [0, 0, 0, 0] };
	const assignCmykColor = (field, key) => {
		let colorRGB = `${field[key].rgb.r}-${field[key].rgb.g}-${field[key].rgb.b}`;
		if (!colorList[colorRGB] && field[key].cmyk) {
			colorList[colorRGB] = [
				field[key].cmyk.c,
				field[key].cmyk.m,
				field[key].cmyk.y,
				field[key].cmyk.k,
			];
		}
	};
	artTemplateFields.forEach((f) => {
		switch (f.type) {
			case 'text':
				assignCmykColor(f, 'fontColor');
				assignCmykColor(f, 'textShadowColor');
				break;
			case 'video':
				assignCmykColor(f, 'borderColor');
				break;
			case 'image':
				assignCmykColor(f, 'borderColor');
				break;
			case 'pdf':
				assignCmykColor(f, 'borderColor');
				break;
			case 'barcode':
				assignCmykColor(f, 'color');
				break;
			case 'grid':
				assignCmykColor(f, 'fontColor');
				assignCmykColor(f, 'headerRuleColor');
				assignCmykColor(f, 'bodyRowsRuleColor');
				break;
			default:
				break;
		}
	});
	return colorList;
};

/**
 * Get font list used in artwork template
 * @param {object} artTemplateFields
 * @param {array} artworkAvailableFonts it is the artworkExtra.fonts
 * @param {object} ART_VARIABLES constants of artwork
 * @returns array
 */
export const geFontListInTemplateFields = (
	artTemplateFields,
	artworkAvailableFonts,
	ART_VARIABLES
) => {
	let fontNamesInTemplate = new Set();
	artTemplateFields.forEach((field) => {
		// get font names used in the template
		if (field.type === 'text') {
			fontNamesInTemplate.add(field.fontfaceName);
			if (
				field.formatNumberStyle.currencyFontName &&
				field.formatNumberStyle.currencyFontName !== ART_VARIABLES.placeholderSameAsText
			) {
				fontNamesInTemplate.add(field.formatNumberStyle.currencyFontName);
			}
		}

		if (field.type === 'grid') {
			fontNamesInTemplate.add(field.titleHeaderFontfaceName);
			if (ART_VARIABLES.placeholderSameAsText !== field.titleCellFontfaceName) {
				fontNamesInTemplate.add(field.titleCellFontfaceName);
			}
			if (ART_VARIABLES.placeholderSameAsText !== field.valueHeaderFontfaceName) {
				fontNamesInTemplate.add(field.valueHeaderFontfaceName);
			}
			if (ART_VARIABLES.placeholderSameAsText !== field.valueCellFontfaceName) {
				fontNamesInTemplate.add(field.valueCellFontfaceName);
			}
		}
	});

	let fontlistInTemplate = Array.from(fontNamesInTemplate)
		.map((fontName) => {
			let font = _find(
				artworkAvailableFonts || [],
				(artFont) => artFont.path.substring(artFont.path.lastIndexOf('/') + 1) === fontName
			);
			return font ? { name: fontName, fontUrl: font.path } : null;
		})
		.filter((item) => item);
	return fontlistInTemplate;
};

/**
 * Verify should hide the field from output
 * @param {object} field The field to be checked
 * @param {array} allFields Template fields
 * @param {object} fieldOutputData tempalte fields output data. Combination of user input data & field default data
 * @param {object} opts 
 	{
		 ignoreHideOutput: false, // ignore field.hideOutput setting. Default false
	}
 *
 * @return {boolean}. If true, hide the output of the field, otherwise, render the field
 */
export const hideFieldOutput = (field, allFields, fieldOutputData, opts = {}) => {
	let { ignoreHideOutput = false } = opts;
	if (!ignoreHideOutput && field.hideOutput) return true;
	if (field.outputDependsOn) {
		let dependOnField = allFields.find((f) => f.id === field.outputDependsOn);
		if (!dependOnField) return false;

		// the behaviour on dependOn field for output on the event of displaying preview
		if (
			(['text', 'barcode'].includes(dependOnField.type) &&
				!fieldOutputData[dependOnField.id].value) || // check the value in depend-on text/barcode field to see if it has value
			(['image', 'pdf', 'video'].includes(dependOnField.type) &&
				!fieldOutputData[dependOnField.id].previewUrl) // check the previewUrl in depend-on image/pdf/video field to see if it has value
		) {
			// the depend on field doesn't has value (neither default value), hence this field will not be redendered
			return true;
		}
	}
	return false;
};

/**
 * Get concatenation field type
 * To decide a concatenation field is text-only or image-only, we use the following rule:
 * 	- if there is at least one image embeded field, we say it is image-only field, otherwise it is text-only field.
 * @param {object} concatField
 * @param {array} templateFields template fields
 *
 * @returns {string} "TEXT_ONLY", "IMAGE_ONLY"
 */
export const getConcatFieldType = (concatField, templateFields) => {
	let concatText = concatField.defaultValue;
	let embededFieldIds = (concatText.match(/<field:\s*(.*?)\s*>/gim) || []).map(
		(match) => match.substring(match.lastIndexOf('_') + 1).slice(0, -1)
		// match
		// 	.split('_')
		// 	.pop()
		// 	.slice(0, -1)
	);
	for (let i = 0; i < embededFieldIds.length; i++) {
		let embededField = templateFields.find((f) => f.id === embededFieldIds[i]);
		if (!embededField) continue;
		if (embededField.type === 'image') {
			return 'IMAGE_ONLY';
		}
	}
	// at this point, we cant' find image embeded field, so we treat it as text concatenation field
	return 'TEXT_ONLY';
};

/**
 * convert s3Url to s3 param object
 * @param {string} s3Url s3://xxxx/xxxx
 * @returns {object}
	{
		Bucket: 'xxx,
		Key: 'xxxx/sss.pdf',
	}
	*/
export const convertS3UrlToS3Params = (s3Url) => {
	let s3url_slices = s3Url.split('://')[1].split('/');
	let fileKey = _join(_drop(s3url_slices), '/');
	return {
		Bucket: s3url_slices[0],
		Key: fileKey,
	};
};

/**
	 * 	Convert s3 url or object params to cloudfront url
	 * @param {object|string} s3FileParams s3 url or s3 params object
			{
				Bucket: 'bucketName',
				Key: 'domain/path/file.ext, // NB: no '/' at beginning
			}
	 */
export const getCloudFrontUrlOfS3File = (s3FileParams) => {
	if (!s3FileParams) return;
	if (typeof s3FileParams === 'string') {
		s3FileParams = convertS3UrlToS3Params(s3FileParams);
	}
	let cloudFrontOriginUrl =
		config.AWS.s3BucketToCloudFront[s3FileParams.Bucket] || config.AWS.s3BucketToCloudFront.default;
	return `${cloudFrontOriginUrl}/${s3FileParams.Key}`;
};

/**
 * Retrieve file from url and return its dataUri
 * @param {string} webUrl
 */
export const retrieveBase64DataUrl = async (webUrl) => {
	const fetchResponse = await fetch(encodeURI(webUrl), {
		mode: 'cors',
		method: 'GET',
		redirect: 'follow',
		// referrerPolicy: 'strict-origin-when-cross-origin',
		cache: 'reload',
	});
	if (!fetchResponse.ok) {
		throw new Error(`${fetchResponse.status} - ${fetchResponse.statusText || 'Request failed.'}`);
	}
	const blob = await fetchResponse.blob();
	const base64Url = await blobToBase64Url(blob);
	return base64Url;
};

export const blobToBase64Url = (blob) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsDataURL(blob);
	});

export const getVideoDimension = (url) => {
	return new Promise((res) => {
		// create the video element
		let video = document.createElement('video');

		// place a listener on it
		video.addEventListener(
			'loadedmetadata',
			() => {
				// retrieve dimensions
				let height = video.videoHeight;
				let width = video.videoWidth;
				// send back result
				video.remove();
				video = null;
				res({ width, height });
			},
			false
		);

		// start download meta-datas
		video.src = url;
	});
};

export const getImageSize = async (imgUrl) => {
	return new Promise((res, rej) => {
		var img = new Image();
		img.addEventListener('load', function () {
			res({ naturalWidth: this.naturalWidth, naturalHeight: this.naturalHeight });
			img.remove();
		});
		img.addEventListener('error', function () {
			rej(new Error(`Image (${imgUrl}) can not be loaded`));
			img.remove();
		});
		img.src = imgUrl;
	});
};

export const getFontfaceFormatByFileNameExtersion = (fontFileName = '') => {
	let nameSplits = fontFileName.split('.');
	let ext = nameSplits[nameSplits.length - 1];
	switch (ext) {
		case 'eot':
			return 'embedded-opentype';
		case 'otf':
			return 'opentype';
		case 'woff':
			return 'woff';
		case 'ttf':
			return 'truetype';
		case 'svg':
			return 'svg';
		case 'woff2':
			return 'woff2';
		default:
			return null;
	}
};

/**
 * Load fontface and store the font as base64 Url
 * Ref:
 * - https://stackoverflow.com/questions/46337609/dynamically-added-font-face-not-working
 * - https://usefulangle.com/post/74/javascript-dynamic-font-loading
 * - https://webplatform.github.io/docs/tutorials/typography/fontface/
 *
 * @param {object} opts {name: 'xxxx', fontUrl: 'xxx'}
 */
// const fontBase64Store = {};
export const loadFontToBase64Url = async (opts) => {
	// const fontBase64 = await import(encodeURI(opts.fontUrl));
	const response = await fetch(encodeURI(opts.fontUrl));
	const blob = await response.blob();
	const b64Url = await blobToBase64Url(blob);
	// const data = await response.arrayBuffer();
	// const b64 = arrayBufferToBase64(data);
	// fontBase64Store[opts.name] = { base64Url: b64Url, fontUrl: opts.fontUrl };
	return { ...opts, base64Url: b64Url };
};

/**
 * Load fontface to arraybuffer
 * @param {object} opts {name: 'xxxx', fontUrl: 'xxx'}
 */
export const loadFontToArrayBuffer = async (opts) => {
	// const fontBase64 = await import(encodeURI(opts.fontUrl));
	const response = await fetch(encodeURI(opts.fontUrl));
	const data = await response.arrayBuffer();
	return { name: opts.name, arrayBuffer: data };
	// const blob = await response.blob();
	// const b64Url = await blobToBase64Url(blob);
	// return {
	// 	name: opts.name,
	// 	arrayBuffer: base64ToArrayBuffer(b64Url.split(';base64,')[1]),
	// };
};

/**
 * Load any url to arraybuffer
 * @param {string} url http(s) url
 */
export const loadUrlToArrayBuffer = async (url) => {
	const response = await fetch(encodeURI(url)); //, { redirect: 'follow', mode: 'no-cors' });
	const buffer = await response.arrayBuffer();
	return buffer;
};
