/** ##############################
 * 	Artwork Field Creator Helper
 *
 *  It has functions to create artwork filed HTMLElement or SVGElement,
 * 	the same function also creates object used for artwork generation
 *
 *  The idea to use the same function to create Element & object-for-generation is to
 *  maintain consistance, to have the same output for browser preview, browser-side artwork
 *  generation and server-side artwork generation
 *
 * 	** Used by both server-side artwork processor and webUI **
 *
 *  ** Try NOT import too many libraries, otherwise it will cause large build **
 *	It does not mean you can't import any library :)
 *
 * 	#############################
 */
import JsBarcode from 'jsbarcode';

import { genRandomStr, isNullish, roundDecimals } from '../generalHelper';
import {
	cleanupFontName,
	getColorListFromDataAttr,
	getFontListFromDataAttr,
	hideFieldOutput,
	isConcatField,
	removeColorListDataAttr,
	removeFontListDataAttr,
	addFontStyleToSVG,
	getImageSize,
	getVideoDimension,
	retrieveBase64DataUrl,
	getCloudFrontUrlOfS3File,
} from './artUtilsCommon';
import {
	NS,
	PIXEL_UNIT_MAP,
	dyForAlphabeticOnTopVerAlign,
	pdfPtToPixel,
	DOMURL,
	DEFAULT_FONTSIZE,
	ARTWORK_SERVER_SIDE_PROCESS,
} from './constants';

/**
 *
 * @param {object} concatField the concatenation field
 *
 * @returns {array} embedded field Ids, could be empty
 */
const getEmbeddedFieldIds = (concatField) => {
	let regexToFindConcatField = /<field:\s*(.*?)\s*>/gm;
	const concatText = concatField.defaultValue;
	let embeddedFieldIds = [];
	if (concatField.type === 'text' && regexToFindConcatField.test(concatText)) {
		let matches = concatText.match(regexToFindConcatField);
		embeddedFieldIds = matches.map((match) => {
			let embeddedFieldId = match.substring(match.lastIndexOf('_') + 1).slice(0, -1);
			return embeddedFieldId;
		});
	}
	return embeddedFieldIds;
};

// create <rect> as field border
const createBorderRect = (field) => {
	// the border rect is drawed outside of rect (outer)
	const rect = document.createElementNS(NS.SVG, 'rect');
	rect.setAttribute('width', field.position.width + field.borderWidth);
	rect.setAttribute('height', field.position.height + field.borderWidth);

	rect.setAttribute('x', -field.borderWidth / 2);

	rect.setAttribute('y', -field.borderWidth / 2);
	rect.setAttribute('fill', 'none');
	rect.setAttribute('stroke', field.borderColor.hex || '#000000');
	rect.setAttribute('stroke-width', field.borderWidth);
	return rect;
};

// get preserveAspect attribute of a field
const getPreserveAspectRatio = (horAlign, verAlign) => {
	let xAlign = 'xMin',
		yAlign = 'YMin';
	switch (horAlign) {
		case 'left':
			xAlign = 'xMin';
			break;
		case 'center':
			xAlign = 'xMid';
			break;
		case 'right':
			xAlign = 'xMax';
			break;
		default:
			break;
	}
	switch (verAlign) {
		case 'top':
			yAlign = 'YMin';
			break;
		case 'middle':
			yAlign = 'YMid';
			break;
		case 'bottom':
			yAlign = 'YMax';
			break;
		default:
			break;
	}
	return `${xAlign}${yAlign} meet`;
};

/**
 * Build initial header cells for grid header
 * NB: we don't apply "scale" here, so that we get obsulute text width/height in bBox
 * @param {object} param
	{ 
		field,
		titleHeaderConf, // {fontfaceName:'xxx', fontsize: NUMBER}. fontsize is a number in web pt
		valueHeaderConf, // {fontfaceName:'xxx', fontsize: NUMBER}. fontsize is a number in web pt
		headerRowData: ["Product","Small","Large"], // NB: value could be ''
	}

	@returns {object}
	{
		rowText: { attrs: textAttrs },
		cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, textContent: tspan.textContent }, ...],
	}
 */
const buildGridHeaderCells = ({
	field,
	titleHeaderConf,
	valueHeaderConf,
	headerRowData,
	// scale,
}) => {
	// create svg of this field
	let svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('viewBox', `0 0 ${field.position.width} ${field.position.height}`);
	svg.setAttribute('x', field.position.left);
	svg.setAttribute('y', field.position.top);
	svg.setAttribute('width', field.position.width);
	svg.setAttribute('height', field.position.height);
	svg.setAttribute('overflow', 'visible');
	// svg.setAttribute('transform', `scale(${scale})`);
	svg.setAttribute('visibility', 'hidden'); // this svg is only used to calc text/tspan bbox, so make it hidden
	document.body.append(svg);
	const text = document.createElementNS(NS.SVG, 'text');
	const textAttrs = {
		x: '0',
		'dominant-baseline': 'alphabetic',
	};
	setDomAttrs(text, textAttrs);
	svg.append(text);
	let cellsTspan = [];
	headerRowData.forEach((headerCellData, idx) => {
		// title header (area 3.1) or value header (area 3.2)
		let cellConf = idx === 0 ? titleHeaderConf : valueHeaderConf;
		const tspan = document.createElementNS(NS.SVG, 'tspan');
		tspan.textContent = field.allCap ? headerCellData.toUpperCase() : headerCellData;
		let tspanAttrs = {
			style: `font-family: "${cleanupFontName(cellConf.fontfaceName)}";`,
			'font-size': `${cellConf.fontsize}pt`,
			fill: field.fontColor.hex,
		};
		setDomAttrs(tspan, tspanAttrs);
		text.appendChild(tspan);

		let bBox = tspan.getBBox();
		cellsTspan.push({
			attrs: tspanAttrs,
			bBox: { /* x: bBox.x, y: bBox.y, */ width: bBox.width, height: bBox.height },
			textContent: tspan.textContent,
		});
	});
	svg.remove();
	return { rowText: { attrs: textAttrs }, cellsTspan };
};

/**
	 * Build initial row cells for grid content
	 * NB: we don't apply "scale" here, so that we get obsulute text width/height in bBox
	 * @param {object} param
		{ 
			field,
			titleCellConf, // styles applied to title cells. {fontfaceName:'xxx', fontsize: NUMBER}. fontsize is a number in web pt
			valueCellConf, // styles applied to value cells. {fontfaceName:'xxx', fontsize: NUMBER}. fontsize is a number in web pt
			contentRowsData: [["Cappuccino\n\ngghgh","€1.99\n\nnew line","€2.99"],["Mocha","€2.49","€3.99"]], // array of array, 1st level of array is row, 2nd level of array is cell content. NB: value could be ''
			// extraInfo: {maxFontsizeInHeaderRow, maxFontsizeInContentRow, maxSubtitleFontsizeInContentRow},
		}
	
		@returns {array}
		[
			{
				rowText: { attrs: textAttrs },
				cellsTspan: [
					{
						content: {
							attrs: contentTspanAttrs,
							bBox: {
								width: contentBBox.width,
								height: contentBBox.height,
							},
							textContent: contentTspan.textContent, // could be ''
						},
						subtitle: {
							attrs: subtitleTspanAttrs,
							bBox: {
								width: subtitleBBox.width,
								height: subtitleBBox.height,
							},
							textContent: subtitleTspan.textContent, // could be ''
						},
					},
					...
				],
			},
			...
		]
	 */
const buildGridContentRowCells = ({ field, titleCellConf, valueCellConf, contentRowsData }) => {
	// create svg of this field
	let svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('viewBox', `0 0 ${field.position.width} ${field.position.height}`);
	svg.setAttribute('x', field.position.left);
	svg.setAttribute('y', field.position.top);
	svg.setAttribute('width', field.position.width);
	svg.setAttribute('height', field.position.height);
	svg.setAttribute('overflow', 'visible');
	// svg.setAttribute('transform', `scale(${scale})`);
	svg.setAttribute('visibility', 'hidden'); // this svg is only used to calc text/tspan bbox, so make it hidden
	document.body.append(svg);
	let rowTspans = [];
	// hasSubtitleInPrevRow = false;
	contentRowsData.forEach((rowCells) => {
		const text = document.createElementNS(NS.SVG, 'text');
		const textAttrs = {
			x: '0',
			'dominant-baseline': 'alphabetic',
		};
		setDomAttrs(text, textAttrs);
		svg.append(text);

		let cellsTspan = [];
		rowCells.forEach((rowCellText, cellIdx) => {
			// title cell or value cells (area 3.3 or area 3.4)
			// NB: row cells may have subtitles.
			let cellTexts = rowCellText.split(/\r?\n|\r/g).filter((s) => s);
			let contentStr = cellTexts[0] ?? '';
			let subtitleStr = cellTexts[1] ?? '';
			let cellConf = cellIdx === 0 ? titleCellConf : valueCellConf;

			// render content <tspan>
			const contentTspan = document.createElementNS(NS.SVG, 'tspan');
			contentTspan.textContent = field.allCap ? contentStr.toUpperCase() : contentStr;
			// let contentFontsize = cellConf.fontsize;
			// let contentDy = 1;
			// if (rowIdx === 0 && cellIdx === 0) {
			// 	// first column in first content row
			// 	contentDy = headerDy + maxFontsizeInHeaderRow / maxFontsizeInContentRow;
			// } else if (cellIdx === 0) {
			// 	// first column in a non-first content row
			// 	contentDy = hasSubtitleInPrevRow
			// 		? (rowIdx + 1) * contentRowDy +
			// 		  headerDy +
			// 		  maxSubtitleFontsizeInContentRow / maxFontsizeInContentRow
			// 		: (rowIdx + 1) * contentRowDy + headerDy;
			// } else {
			// 	contentDy = previousSubtitleFontsize !== 0 ? previousSubtitleFontsize / contentFontsize : 0;
			// }
			let contentTspanAttrs = {
				style: `font-family: "${cleanupFontName(cellConf.fontfaceName)}";`,
				'font-size': `${cellConf.fontsize}pt`,
				fill: field.fontColor.hex,
				// dy: `${contentDy}em`,
			};
			setDomAttrs(contentTspan, contentTspanAttrs);
			text.appendChild(contentTspan);

			let contentBBox = contentTspan.getBBox();

			// render subtitle <tspan>
			const subtitleTspan = document.createElementNS(NS.SVG, 'tspan');
			subtitleTspan.textContent = field.allCap ? subtitleStr.toUpperCase() : subtitleStr;
			// title cell or value cells (area 3.3 or area 3.4)
			// const subtitleFontsize = cellConf.fontsize * cellConf.subtitleFontsizeScale;
			let subtitleTspanAttrs = {
				style: `font-family: "${cleanupFontName(cellConf.fontfaceName)}";`,
				'font-size': `${cellConf.subtitleFontsize}pt`,
				fill: field.fontColor.hex,
				// dy: '1em',
			};
			// if (subtitleTspan.textContent) previousSubtitleFontsize = subtitleFontsize;
			// else previousSubtitleFontsize = 0;
			setDomAttrs(subtitleTspan, subtitleTspanAttrs);
			text.appendChild(subtitleTspan);

			let subtitleBBox = subtitleTspan.getBBox();

			cellsTspan.push({
				content: {
					attrs: contentTspanAttrs,
					bBox: {
						// x: contentBBox.x,
						// y: contentBBox.y,
						width: contentBBox.width,
						height: contentBBox.height,
					},
					textContent: contentTspan.textContent,
				},
				subtitle: {
					attrs: subtitleTspanAttrs,
					bBox: {
						// x: subtitleBBox.x,
						// y: subtitleBBox.y,
						width: subtitleBBox.width,
						height: subtitleBBox.height,
					},
					textContent: subtitleTspan.textContent,
				},
			});
		});

		rowTspans.push({ rowText: { attrs: textAttrs }, cellsTspan });
	});
	svg.remove();
	return rowTspans;
};

/**
	 * set the horizontal position & size of cells
	 * from right to left as we want each row to expand to most right and give max space to the first column
	
	 * @param {object} param
		{
			headerRowCells, // initial header cells (columns). Coulbe be null/undefined
			contentRowCells, // initial content cells (columns) of each row. could be null/undefined
			field, // grid field configuration
			paddingBetweenCells // padding (in pixel) between columns.
			paddingLeftMost, // padding (in pixel) to most left (for first column)
			paddingRightMost, // padding (in pixel) to most right (for last column)
			scale, // always <=1;
			titleHeaderHorAlign = 'left',
			valueHeaderHorAlign = 'right',
			titleCellHorAlign = 'left',
			valueCellHorAlign = 'right',
		}
	
	 * @returns {Boolean} isHorFittedInRows. if false, there is at least one row not fitted, otherwise, all fitted
		headerRowCells & contentRowCells are updated with horizontal (x) position & size
		 headerRowCells is null or object as below (attrs now contains x & text-anchor)
		{
			rowText: { attrs: textAttrs },
			cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: tspan.textContent }, ...],
		}
	
		 contentRowCells is null or array as below (attrs now contains x & text-anchor)
		 [
			{
				rowText: { attrs: textAttrs },
				cellsTspan: [
					{
						content: { attrs: contentTspanAttrs, 	bBox: bBox,  cellWidth: cellWidth, textContent: contentTspan.textContent,},
						subtitle: { attrs: subtitleTspanAttrs, bBox: bBox,  cellWidth: cellWidth, textContent: subtitleTspan.textContent,},
					},
					...
				],
			},
			...
		]
	
	 */
const setCellHorPosSize = ({
	headerRowCells = {},
	contentRowCells = [],
	field,
	paddingBetweenCells,
	paddingLeftMost,
	paddingRightMost,
	scale, // always <=1;
	titleHeaderHorAlign = 'left',
	valueHeaderHorAlign = 'right',
	titleCellHorAlign = 'left',
	valueCellHorAlign = 'right',
}) => {
	const containerWidth = field.position.width * (1 / scale); // scale is alwasy <=1; when scale down, the width of row is actually getting bigger (has more space in row)
	const headerCells = headerRowCells.cellsTspan || []; // headerRowCells.cellsTspan format:  [{ attrs: tspanAttrs, bBox: bBox, textContent: tspan.textContent }, ...],
	let isHorFittedInRows = true; // if false, there is at least one row not fitted
	// find max width in each cells in reverse direction (right to left)
	let maxWidthInColumns = [],
		minTitleColWidth = 0; // As we support text-wrap in content title cells, we need min-width of the column to calculate how to wrap text.
	// we don't wrap the title text in header, so we will firstly try to use the "title width in header" as minTitleColWidth to ensure sufficient space for title in header
	// In case of one of headerCells and contentRowCells could be empty array, so we loop through them seperately
	for (let i = headerCells.length - 1; i >= 0; i--) {
		// loop through header cells in reverse direction to find max width in each cell (column)
		maxWidthInColumns[i] = Math.max(maxWidthInColumns[i] ?? 0, headerCells[i].bBox.width || 0);
		if (i === 0) minTitleColWidth = headerCells[i].bBox.width || 0;
	}
	// Only if no title in header (minTitleColWidth === 0), we will try to use "minimum width in content title cells (first cell)" as minTitleColWidth
	// so that at least one cell in content title cells doesn't need text-wrap
	const findMinTitleWidthInContent = minTitleColWidth === 0;
	contentRowCells.forEach((contentRow) => {
		for (let i = contentRow.cellsTspan.length - 1; i >= 0; i--) {
			// loop through cells (columns) in reverse direction to find max width in each cell (column)
			const rowCell = contentRow.cellsTspan[i];
			maxWidthInColumns[i] = Math.max(
				maxWidthInColumns[i] ?? 0,
				rowCell.content.bBox.width,
				rowCell.subtitle.bBox.width
			);
			if (i === 0 && findMinTitleWidthInContent) {
				// use the minimum width of title column in content as minTitleColWidth in case there is no header row
				minTitleColWidth = Math.min(
					minTitleColWidth,
					rowCell.content.bBox.width,
					rowCell.subtitle.bBox.width
					// ...[minTitleColWidth, rowCell.content.bBox.width, rowCell.subtitle.bBox.width].filter(
					// 	(w) => w > 0
					// )
				);
			}
		}
	});

	/**
	 * At this point, we got minTitleColWidth either by "title width (first cell) in header" (named it "header-title-width") or "minimum width in content title cells (first cell)" (named it "min-content-title")
	 * minTitleColWidth is most important factor in the logic as it decides how to wrap the title text
	 * the text width in title header or content cells could be very small, as they could be just one letter, e.g. "a", "b", or even empty ""
	 * hence we bring in another measurement: "average-column-width". It is "container_width / number_of_columns"
	 * keep in mind, we choose "header-title-width" as minTitleColWidth first; we consider using "min-content-title" as minTitleColWidth ONLY when "header-title-width" is empty
	 * finally we will use the maximum of "minTitleColWidth at this point" & "average-column-width" as the final minTitleColWidth
	 *
	 */
	let numCellsInRow =
		headerCells.length > 0
			? headerCells.length
			: contentRowCells[0]?.cellsTspan
			? contentRowCells[0].cellsTspan.length
			: 0;
	if (numCellsInRow !== 0)
		minTitleColWidth = Math.max(minTitleColWidth, containerWidth / numCellsInRow);

	// set the x & width of each column with paddingRight applied.
	// rowCells contains cells in only one row
	// NB: 1) the x position is set based on the cell horAlign. 2) title column is handled
	// NB: the calculation is trying to fit horizontally to the container box
	const setCellXAttrsInRow = (rowCells, mode) => {
		const titleHorAlign = mode === 'header' ? titleHeaderHorAlign : titleCellHorAlign;
		const valueHorAlign = mode === 'header' ? valueHeaderHorAlign : valueCellHorAlign;
		let isHorFitted = true;
		let rowSpaceInUse = 0;
		for (let i = rowCells.length - 1; i >= 0; i--) {
			// again, we calculate from right to left
			if (i === 0) {
				// first column. first column requires left & right padding
				const cellRightX = containerWidth - rowSpaceInUse - paddingBetweenCells;
				const paddingLeft = paddingLeftMost;

				const cellWidth =
					cellRightX - paddingLeft > minTitleColWidth ? cellRightX - paddingLeft : minTitleColWidth;
				const cellLeftX = cellRightX - cellWidth;
				isHorFitted = cellLeftX >= paddingLeft;
				let cellXAttrs = {};
				if (titleHorAlign === 'left')
					cellXAttrs = {
						x: cellLeftX, //cellRightX > cellWidth ? 0 : cellRightX - cellWidth,
						'text-anchor': 'start',
					};
				else if (titleHorAlign === 'center')
					cellXAttrs = {
						x: cellRightX - (cellRightX - cellLeftX) / 2, // cellRightX > cellWidth ? cellRightX / 2 : cellRightX - cellWidth / 2,
						'text-anchor': 'middle',
					};
				else if (titleHorAlign === 'right') cellXAttrs = { x: cellRightX, 'text-anchor': 'end' };
				else
					cellXAttrs = {
						x: cellLeftX, //cellRightX > cellWidth ? 0 : cellRightX - cellWidth,
						'text-anchor': 'start',
					};

				if (mode === 'header') {
					rowCells[i].attrs = {
						...rowCells[i].attrs,
						// width: cellWidth,
						...cellXAttrs,
					};
					rowCells[i].cellWidth = cellWidth;
				} else if (mode === 'content') {
					rowCells[i].content.attrs = {
						...rowCells[i].content.attrs,
						// width: cellWidth,
						...cellXAttrs,
					};
					rowCells[i].content.cellWidth = cellWidth;
					// rowCells[i].content.requireWrap = rowCells[i].content.bBox.width > cellWidth;

					rowCells[i].subtitle.attrs = {
						...rowCells[i].subtitle.attrs,
						// width: cellWidth,
						...cellXAttrs,
					};
					rowCells[i].subtitle.cellWidth = cellWidth;
					// rowCells[i].subtitle.requireWrap = rowCells[i].subtitle.bBox.width > cellWidth;
				}
				rowSpaceInUse += paddingBetweenCells + cellWidth;
			} else {
				let paddingRight = i === rowCells.length - 1 ? paddingRightMost : paddingBetweenCells;
				let cellRightX = containerWidth - rowSpaceInUse - paddingRight;
				let cellXAttrs = {};
				if (valueHorAlign === 'left')
					cellXAttrs = { x: cellRightX - maxWidthInColumns[i], 'text-anchor': 'start' };
				else if (valueHorAlign === 'center')
					cellXAttrs = { x: cellRightX - maxWidthInColumns[i] / 2, 'text-anchor': 'middle' };
				else if (valueHorAlign === 'right') cellXAttrs = { x: cellRightX, 'text-anchor': 'end' };
				else cellXAttrs = { x: cellRightX - maxWidthInColumns[i], 'text-anchor': 'start' };

				if (mode === 'header') {
					rowCells[i].attrs = {
						...rowCells[i].attrs,
						// width: maxWidthInColumns[i],
						...cellXAttrs,
					};
					rowCells[i].cellWidth = maxWidthInColumns[i];
				} else if (mode === 'content') {
					rowCells[i].content.attrs = {
						...rowCells[i].content.attrs,
						// width: maxWidthInColumns[i],
						...cellXAttrs,
					};
					rowCells[i].content.cellWidth = maxWidthInColumns[i];

					rowCells[i].subtitle.attrs = {
						...rowCells[i].subtitle.attrs,
						// width: maxWidthInColumns[i],
						...cellXAttrs,
					};
					rowCells[i].subtitle.cellWidth = maxWidthInColumns[i];
				}
				rowSpaceInUse += paddingRight + maxWidthInColumns[i];
			}
		}
		return isHorFitted;
	};
	// header row
	let isHeaderRowFitted = setCellXAttrsInRow(headerCells, 'header');
	if (isHorFittedInRows) isHorFittedInRows = isHeaderRowFitted; // if isHorFittedInRows=false, not need to update it
	// let spaceInUseHeaderRow = 0;
	// for (let i = headerCells.length - 1; i >= 0; i--) {
	// 	// again, we calculate from right to left
	// 	if (i === 0) {
	// 		// first column
	// 		const cellRightX = containerWidth - spaceInUseHeaderRow - paddingBetweenCells;
	// 		const cellWidth = cellRightX > minTitleColWidth ? cellRightX : minTitleColWidth;
	// 		let cellXAttrs = {};
	// 		if (titleHeaderHorAlign === 'left')
	// 			cellXAttrs = {
	// 				x: cellRightX > cellWidth ? 0 : cellRightX - cellWidth,
	// 				'text-anchor': 'start',
	// 			};
	// 		else if (titleHeaderHorAlign === 'center')
	// 			cellXAttrs = {
	// 				x: cellRightX > cellWidth ? cellRightX / 2 : cellRightX - cellWidth / 2,
	// 				'text-anchor': 'middle',
	// 			};
	// 		else if (titleHeaderHorAlign === 'right')
	// 			cellXAttrs = { x: cellRightX, 'text-anchor': 'end' };
	// 		else
	// 			cellXAttrs = {
	// 				x: cellRightX > cellWidth ? 0 : cellRightX - cellWidth,
	// 				'text-anchor': 'start',
	// 			};

	// 		headerCells[i].attrs = {
	// 			...headerCells[i].attrs,
	// 			width: cellWidth,
	// 			...cellXAttrs,
	// 		};
	// 		// spaceInUseHeaderRow = containerWidth;
	// 		spaceInUseHeaderRow += paddingBetweenCells + cellWidth;
	// 	} else {
	// 		let cellRightX = containerWidth - spaceInUseHeaderRow - paddingBetweenCells;
	// 		let cellXAttrs = {};
	// 		if (valueHeaderHorAlign === 'left')
	// 			cellXAttrs = { x: cellRightX - maxWidthInColumns[i], 'text-anchor': 'start' };
	// 		else if (valueHeaderHorAlign === 'center')
	// 			cellXAttrs = { x: cellRightX - maxWidthInColumns[i] / 2, 'text-anchor': 'middle' };
	// 		else if (valueHeaderHorAlign === 'right')
	// 			cellXAttrs = { x: cellRightX, 'text-anchor': 'end' };
	// 		else cellXAttrs = { x: cellRightX - maxWidthInColumns[i], 'text-anchor': 'start' };

	// 		headerCells[i].attrs = {
	// 			...headerCells[i].attrs,
	// 			width: maxWidthInColumns[i],
	// 			...cellXAttrs,
	// 		};
	// 		spaceInUseHeaderRow += paddingBetweenCells + maxWidthInColumns[i];
	// 	}
	// }
	// content rows
	contentRowCells.forEach((contentRow) => {
		const rowCells = contentRow.cellsTspan; // NB: it contains "content" & "subtitle"
		let isRowFitted = setCellXAttrsInRow(rowCells, 'content');
		if (isHorFittedInRows) isHorFittedInRows = isRowFitted; // if isHorFittedInRows=false, not need to update it
		// let spaceInUseContentRow = 0;
		// for (let i = rowCells.length - 1; i >= 0; i--) {
		// 	// again, we calculate from right to left
		// 	if (i === 0) {
		// 		// this is first content column (title column)
		// 		const cellRightX = containerWidth - spaceInUseContentRow - paddingBetweenCells;
		// 		const cellWidth = cellRightX > minTitleColWidth ? cellRightX : minTitleColWidth;

		// 		// 	let cellXAttrs = {};
		// 		// if (titleHeaderHorAlign === 'left')
		// 		// 	cellXAttrs = {
		// 		// 		x: cellRightX > maxWidthInColumns[i] ? 0 : cellRightX - maxWidthInColumns[i],
		// 		// 		'text-anchor': 'start',
		// 		// 	};
		// 		// else if (titleHeaderHorAlign === 'center')
		// 		// 	cellXAttrs = {
		// 		// 		x:
		// 		// 			cellRightX > maxWidthInColumns[i]
		// 		// 				? cellRightX / 2
		// 		// 				: cellRightX - maxWidthInColumns[i] / 2,
		// 		// 		'text-anchor': 'middle',
		// 		// 	};
		// 		// else if (titleHeaderHorAlign === 'right')
		// 		// 	cellXAttrs = { x: cellRightX, 'text-anchor': 'end' };
		// 		// else
		// 		// 	cellXAttrs = {
		// 		// 		x: cellRightX > maxWidthInColumns[i] ? 0 : cellRightX - maxWidthInColumns[i],
		// 		// 		'text-anchor': 'start',
		// 		// 	};

		// 		let cellXAttrs = {};
		// 		if (titleCellHorAlign === 'left')
		// 			cellXAttrs = {
		// 				x: cellRightX > cellWidth ? 0 : cellRightX - cellWidth,
		// 				'text-anchor': 'start',
		// 			};
		// 		else if (titleCellHorAlign === 'center')
		// 			cellXAttrs = {
		// 				x: cellRightX > cellWidth ? cellRightX / 2 : cellRightX - cellWidth / 2,
		// 				'text-anchor': 'middle',
		// 			};
		// 		else if (titleCellHorAlign === 'right')
		// 			cellXAttrs = { x: cellRightX, 'text-anchor': 'end' };
		// 		else
		// 			cellXAttrs = {
		// 				x: cellRightX > cellWidth ? 0 : cellRightX - cellWidth,
		// 				'text-anchor': 'start',
		// 			};

		// 		rowCells[i].content.attrs = {
		// 			...rowCells[i].content.attrs,
		// 			width: cellWidth,
		// 			...cellXAttrs,
		// 		};
		// 		rowCells[i].content.requireWrap = rowCells[i].content.bBox.width > cellWidth;

		// 		rowCells[i].subtitle.attrs = {
		// 			...rowCells[i].subtitle.attrs,
		// 			width: cellWidth,
		// 			...cellXAttrs,
		// 		};
		// 		rowCells[i].subtitle.requireWrap = rowCells[i].subtitle.bBox.width > cellWidth;

		// 		spaceInUseContentRow += paddingBetweenCells + cellWidth;
		// 	} else {
		// 		let cellRightX = containerWidth - spaceInUseContentRow - paddingBetweenCells;
		// 		let cellXAttrs = {};
		// 		if (valueCellHorAlign === 'left')
		// 			cellXAttrs = { x: cellRightX - maxWidthInColumns[i], 'text-anchor': 'start' };
		// 		else if (valueCellHorAlign === 'center')
		// 			cellXAttrs = { x: cellRightX - maxWidthInColumns[i] / 2, 'text-anchor': 'middle' };
		// 		else if (valueCellHorAlign === 'right')
		// 			cellXAttrs = { x: cellRightX, 'text-anchor': 'end' };
		// 		else cellXAttrs = { x: cellRightX - maxWidthInColumns[i], 'text-anchor': 'start' };

		// 		rowCells[i].content.attrs = {
		// 			...rowCells[i].content.attrs,
		// 			width: maxWidthInColumns[i],
		// 			...cellXAttrs,
		// 		};
		// 		rowCells[i].subtitle.attrs = {
		// 			...rowCells[i].subtitle.attrs,
		// 			width: maxWidthInColumns[i],
		// 			...cellXAttrs,
		// 		};
		// 		spaceInUseContentRow += paddingBetweenCells + maxWidthInColumns[i];
		// 	}
		// }
	});

	// return { headerRowCells, contentRowCells };
	return isHorFittedInRows;
};

/**
 * Build grid field svg
 * 
 * @returns {object}
	{ 
		svgBBox, // svg bBox from getBBox()
		ruleLines: [ {attrs: SVGLine_Attrs_Object}, ... ],
	}
 */
const getGridSVGBBox = ({
	field,
	scale,
	headerRowCells,
	contentRowCells,
	headerRuleConf,
	rowsRuleConf,
	isEven,
	// requireHeaderRule = false,
	// requireContentRowRule = false,
}) => {
	const requireHeaderRule = Boolean(headerRuleConf);
	const requireContentRowRule = Boolean(rowsRuleConf);
	let rowBBoxes = [],
		ruleLines = [];
	// create svg container of this field
	let svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('viewBox', `0 0 ${field.position.width} ${field.position.height}`);
	svg.setAttribute('x', field.position.left);
	svg.setAttribute('y', field.position.top);
	svg.setAttribute('width', field.position.width);
	svg.setAttribute('height', field.position.height);
	// svg.setAttribute('transform', `scale(${scale})`);
	svg.setAttribute('overflow', 'visible');
	svg.setAttribute('visibility', 'hidden');
	document.body.append(svg);
	const gRoot = document.createElementNS(NS.SVG, 'g');
	gRoot.setAttribute('transform', `scale(${scale})`);
	svg.append(gRoot);

	// append text & tspan elements to svg container
	if (headerRowCells) {
		const headerRowText = document.createElementNS(NS.SVG, 'text');
		setDomAttrs(headerRowText, headerRowCells.rowText.attrs);
		gRoot.append(headerRowText);
		headerRowCells.cellsTspan.forEach((cell) => {
			const headerCellTspan = document.createElementNS(NS.SVG, 'tspan');
			headerCellTspan.textContent = cell.textContent;
			setDomAttrs(headerCellTspan, cell.attrs);
			headerRowText.appendChild(headerCellTspan);
		});
		// row could be empty, its width/height could be 0,

		if (requireHeaderRule) rowBBoxes.push(headerRowText.getBBox());
	}
	if (contentRowCells) {
		contentRowCells.forEach((contentRow) => {
			const contentRowText = document.createElementNS(NS.SVG, 'text');
			setDomAttrs(contentRowText, contentRow.rowText.attrs);
			gRoot.append(contentRowText);
			contentRow.cellsTspan.forEach((cell) => {
				(cell.content.positioned || []).forEach((line) => {
					const contentCellTspan = document.createElementNS(NS.SVG, 'tspan');
					contentCellTspan.textContent = line.textContent;
					setDomAttrs(contentCellTspan, line.attrs);
					contentRowText.appendChild(contentCellTspan);
				});

				(cell.subtitle.positioned || []).forEach((line) => {
					const subtitleCellTspan = document.createElementNS(NS.SVG, 'tspan');
					subtitleCellTspan.textContent = line.textContent;
					setDomAttrs(subtitleCellTspan, line.attrs);
					contentRowText.appendChild(subtitleCellTspan);
				});
			});
			// row could be empty, its width/height could be 0,

			if (requireContentRowRule) rowBBoxes.push(contentRowText.getBBox());
		});
	}

	ruleLines = createRulesInGrid({ rowBBoxes, headerRuleConf, rowsRuleConf, isEven });
	// append rule (underlines) to gRoot
	ruleLines.forEach((line) => {
		const underline = document.createElementNS(NS.SVG, 'line');
		setDomAttrs(underline, line.attrs);
		gRoot.append(underline);
	});

	const svgBBox = svg.getBBox();
	svg.remove();
	return { svgBBox, ruleLines };
};

/**
 * wrap text into lines by width only. 
 * NB: only call this func when the text requires "wrap".
 * The func itself assumes the text doesn't fit the width, it doesn't check if the original text fits the width or not
 * @param {object} param
	{
		scale, // scale factor of text container, usually the parent <svg>
		textStr, // string to be wrapped
		tspanAttrs, // tspan attributes, e.g. font-size, font-familly, etc.
		containerWidth, // number. width of container that the text to be fitted
	}

 * @returns {array}. Array of string. Each item in the array repressent as a line
 */
const wrapTextIntoLinesByWidth = ({ /* scale, */ textStr, tspanAttrs, containerWidth }) => {
	// create <svg> <text> <tspan> container
	const svg = document.createElementNS(NS.SVG, 'svg');
	// svg.setAttribute('transform', `scale(${scale})`);
	const text = document.createElementNS(NS.SVG, 'text');
	const tspan = document.createElementNS(NS.SVG, 'tspan');
	document.body.append(svg);
	svg.append(text);
	text.appendChild(tspan);
	setDomAttrs(tspan, tspanAttrs);

	// split text to words
	let words = textStr.split(/\s+/gm).filter((w) => Boolean(w.trim())),
		word,
		lineWords = [],
		lines = [];
	while ((word = words.shift())) {
		lineWords.push(word);
		tspan.textContent = lineWords.join(' ');

		if (tspan.getBBox().width > containerWidth) {
			let isOneWordLine = lineWords.length === 1; // in case there is only one word for the line
			if (!isOneWordLine) lineWords.pop();
			lines.push(lineWords.join(' '));
			lineWords = !isOneWordLine ? [word] : [];
		}
	}
	// add remaining words to new line;
	if (lineWords.length > 0) lines.push(lineWords.join(' '));

	svg.remove();
	return lines;
};

/**
 * create rules in grid
 * NB: doesn't support empty row
 * @param {object} param
  {
		rowBBoxes, // [rowBBox, ...]
		headerRuleConf,
		rowsRuleConf,
		isEven, // if true, rules (underlines) between rows are even
	}
 * @returns {array} ruleLines
	[
		{attrs: SVGLine_Attrs_Object},
		...
	]
 */
const createRulesInGrid = ({ rowBBoxes, headerRuleConf, rowsRuleConf, isEven = true }) => {
	let ruleLines = [];
	const gutterBottom = 3; // in pixel. padding between line and row bottom in case of isEven=false or overlapping rows
	let spaceToNextRow = 0, // when evenRule=true, spaceToNextRow is guarenteed to have value as at least two rows are available
		evenRule = isEven && rowBBoxes.length > 1;
	rowBBoxes.forEach((rowBBox, rowIdx) => {
		// don't support empty row.
		if (rowBBox.width === 0 && rowBBox.height === 0) {
			// it is empty row, can't draw rule
			return null;
		}
		let ruleConf = rowIdx === 0 && headerRuleConf ? headerRuleConf : rowsRuleConf;
		// find next non-empty row (could be undefined if current row is last row)
		let nextRowBBox = rowBBoxes
			.slice(rowIdx + 1)
			.find((bbox) => bbox.width !== 0 && bbox.height !== 0); // rowBBoxes[rowIdx + 1];
		if (nextRowBBox) {
			spaceToNextRow = nextRowBBox.y - rowBBox.y - rowBBox.height;
		}
		// NB: in case nextRowBBox is undefined (no next row), spaceToNextRow will be the previous value, so that the final rule can be draw evenly
		if (evenRule && spaceToNextRow > 0) {
			// we can only apply even rules when the current row is not overlapping with next row
			let rowBottomY = rowBBox.y + rowBBox.height;
			ruleLines.push({
				attrs: {
					x1: rowBBox.x,
					y1: rowBottomY + spaceToNextRow / 2,
					x2: rowBBox.x + rowBBox.width,
					y2: rowBottomY + spaceToNextRow / 2,
					stroke: ruleConf.color.hex, // use hex color here. The hex color is converted to cmyk (through rgb) when printing to pdf
					'stroke-width': ruleConf.lineWidth,
				},
			});
		} else {
			// underline rule that attaches to the row
			ruleLines.push({
				attrs: {
					x1: rowBBox.x,
					y1: rowBBox.y + rowBBox.height + gutterBottom,
					x2: rowBBox.x + rowBBox.width,
					y2: rowBBox.y + rowBBox.height + gutterBottom,
					stroke: ruleConf.color.hex, // use hex color here. The hex color is converted to cmyk (through rgb) when printing to pdf
					'stroke-width': ruleConf.lineWidth,
				},
			});
		}
	});
	return ruleLines;
};

/**
 * Set vertical alignment for grid cells
 * @param {object} param
 *
 * @returns null. headerRowCells & contentRowCells are updated with vertical position dy
 * headerRowCells is null or object as below (attrs now contains x, text-anchor & dy)
	{
		rowText: { attrs: textAttrs },
		cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: tspan.textContent }, ...],
	}

	 contentRowCells is null or array as below (attrs in now contains x & text-anchor, final positioned data (can be null/undefined) is added to content & subtitle )
	 [
		{
			rowText: { attrs: textAttrs },
			cellsTspan: [
				{
					content: { attrs: contentTspanAttrs, 	bBox: bBox,  cellWidth: cellWidth, textContent: contentTspan.textContent, positioned: null | [{ attrs: { ...contentData.attrs, dy: `Xem`, }, textContent: lineText, }, ...]},
					subtitle: { attrs: subtitleTspanAttrs, bBox: bBox,  cellWidth: cellWidth, textContent: subtitleTspan.textContent, positioned: null | [{ attrs: { ...subtitleData.attrs, dy: `Xem`, }, textContent: lineText, }, ...]},
				},
				...
			],
		},
		...
	]
 * 
 */
const setCellVerPos = ({
	headerRowCells = {},
	contentRowCells = [],
	// field,
	// scale, // always <=1;
	// maxFontsizeInHeaderRow,
	// maxFontsizeInContentRow,
	// maxSubtitleFontsizeInContentRow,
	// baseRowDy,
	rowDyScale,
	// titleHeaderFontsize,
	// titleCellFontsize,
	// titleCellSubtitleFontsize,
	titleHeaderConf,
	valueHeaderConf,
	titleCellConf,
	valueCellConf,
}) => {
	const maxFontsizeInHeaderRow = Math.max(titleHeaderConf.fontsize, valueHeaderConf.fontsize);
	const maxFontsizeInContentRow = Math.max(titleCellConf.fontsize, valueCellConf.fontsize);
	const headerCells = headerRowCells.cellsTspan || []; // headerRowCells.cellsTspan format:  [{ attrs: tspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: tspan.textContent }, ...],
	// const headerDy =
	// 	titleHeaderConf.fontsize < valueHeaderConf.fontsize
	// 		? valueHeaderConf.fontsize / titleHeaderConf.fontsize
	// 		: 1;

	// find the index of cell that is not empty in header row
	let indexNonEmptyCell = headerCells.findIndex((cell) => cell.textContent);
	const headerDy =
		indexNonEmptyCell === 0
			? maxFontsizeInHeaderRow / titleHeaderConf.fontsize
			: maxFontsizeInHeaderRow / valueHeaderConf.fontsize;
	// if (headerCells.length > 0) {
	// no text-wrap in header, so only set dy on first non-empty cell or first cell if all cells are empty
	if (indexNonEmptyCell !== -1) {
		// found the first non-empty cell, we set dy to it to hold up the row space (height)
		headerCells[indexNonEmptyCell].attrs = {
			...headerCells[indexNonEmptyCell].attrs,
			dy: `${headerDy}em`,
		};
	}
	// following is not required for retaining the position of the empty header row.
	// the position of empty header row is retained by the initial rowDy below (for content): rowDyIncrement + maxFontsizeInHeaderRow / titleCellConf.fontsize
	// (Discard, keep the code below for reference)
	// else {
	// 	// header row is empty, but we need to keep the row space in the grid,
	// 	// so we give \u200B unicode (&ZeroWidthSpace;) as content in the first cell, and set dy to maintain its space
	// 	// NB: \u200B will be a dot in the generated PDF
	// 	// If don't want to keep empty header row, just do nothing here (don't handle this "else" case)
	// 	headerCells[0].attrs = {
	// 		...headerCells[0].attrs,
	// 		dy: `${headerDy}em`,
	// 	};
	// 	headerCells[0].textContent = '\u200B';
	// }
	// }

	// calc row incremental step in content row
	const rowDyIncrement = (rowDyScale * maxFontsizeInContentRow) / titleCellConf.fontsize;
	// initial dy for first row in content. NB: this first row could be the first row in whole table if there is no header
	let rowDy =
		headerCells.length > 0 // if there is header row, no matter it is empty row or not
			? rowDyIncrement + maxFontsizeInHeaderRow / titleCellConf.fontsize // when there is header
			: maxFontsizeInContentRow / titleCellConf.fontsize; // when there is no header, we don't use rowDyIncrement, but only shift by one row ("1em") so that the first row stays at most top
	contentRowCells.forEach((contentRow) => {
		// contentRow data structure:
		// {
		// 	rowText: { attrs: textAttrs },
		// 	cellsTspan: [
		// 		{
		// 			content: { attrs: contentTspanAttrs, 	bBox: bBox, cellWidth: cellWidth, textContent: contentTspan.textContent,},
		// 			subtitle: { attrs: subtitleTspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: subtitleTspan.textContent,},
		// 		},
		// 		...
		// 	],
		// },
		// we need to record the max lines in a row, so that we can calculate the next row's dy
		let maxContentLinesInRow = 1,
			maxSubtitleLinesInRow = 0;
		let isRowDyUsed = false; // indicate if the rowDy is consumed
		// control the dy on the first text in a new cell, in the case of the prev cell has subtitle. It is always <=0
		let nextCellBeginningDy = 0;
		// let isRowEmpty = !contentRow.cellsTspan.find((cell) => cell.content.textContent);
		// loop through all cells in the row. NB: cell contains "content" & "subtitle"
		contentRow.cellsTspan.forEach((rowCell, cellIdx) => {
			const contentData = rowCell.content,
				subtitleData = rowCell.subtitle;
			// rowDy will be consumed by first non-empty cell, so the row will still have its space with correct position
			// if all cells in the row are empty, rowDy will not be consumed by the row at all,
			// but rowDy is added up for the next row, hence, next row will have correct position (by dy)
			// so no need to use \u200B for vertical positioning. Discard the code below

			// // (discard, keep it here for reference only) use \u200B as content in the first cell if the row isn't empty
			// if (!isRowEmpty && !contentData.textContent && cellIdx === 0) {
			// 	// when row isn't empty and first cell in the row has no content, we give \u200B unicode (&ZeroWidthSpace;) as its content
			// 	// as without content in the first cell, we can't do vertical alignment (dy) for the other cells in the same row
			// 	// NB: \u200B will be a dot in the generated PDF
			// 	contentData.textContent = '\u200B';
			// }

			if (contentData.textContent) {
				let contentLines = [contentData.textContent];
				// wrap text in title cell
				if (cellIdx === 0 && contentData.bBox.width > contentData.cellWidth) {
					// this is title cell (area 3.3), we need to wrap the text
					// NB: no scale applied, as containerWidth has "scale" applied already for the title cell (first cell in a row)
					contentLines = wrapTextIntoLinesByWidth({
						// scale,
						textStr: contentData.textContent,
						tspanAttrs: contentData.attrs,
						containerWidth: contentData.cellWidth,
					});
				}

				// create final positioned content <tspan> data, array of object by text lines
				rowCell.content.positioned = contentLines.map((lineText, contentLineIdx) => {
					let lineAttrs = {
						attrs: {
							...contentData.attrs,
							dy: `${!isRowDyUsed ? rowDy : contentLineIdx === 0 ? nextCellBeginningDy : 1}em`,
						},
						textContent: lineText,
					};
					// reset nextCellBeginningDy after using it
					if (contentLineIdx === 0) nextCellBeginningDy = 0;
					if (!isRowDyUsed) isRowDyUsed = true;
					return lineAttrs;
				});

				maxContentLinesInRow = Math.max(contentLines.length, maxContentLinesInRow);
			}

			if (subtitleData.textContent) {
				let subtitleLines = [subtitleData.textContent];
				if (cellIdx === 0 && subtitleData.bBox.width > subtitleData.cellWidth) {
					// this is title cell (area 3.3), we need to wrap the text
					// NB: no scale applied, as containerWidth has "scale" applied already for the title cell (first cell in a row)
					subtitleLines = wrapTextIntoLinesByWidth({
						// scale,
						textStr: subtitleData.textContent,
						tspanAttrs: subtitleData.attrs,
						containerWidth: subtitleData.cellWidth,
					});
				}
				// create final positioned content <tspan> data, array of object by text lines
				rowCell.subtitle.positioned = subtitleLines.map((lineText) => {
					return { attrs: { ...subtitleData.attrs, dy: `1em` }, textContent: lineText };
				});
				// set nextCellBeginningDy so that the first text in next cell can re-align
				nextCellBeginningDy =
					cellIdx === 0 // if it is title cell
						? subtitleLines.length * (titleCellConf.subtitleFontsize / valueCellConf.fontsize) * -1
						: subtitleLines.length * (valueCellConf.subtitleFontsize / valueCellConf.fontsize) * -1;

				maxSubtitleLinesInRow = Math.max(subtitleLines.length, maxSubtitleLinesInRow);
			}
		});

		// calculate the dy for next row after all cells in current row have been processed
		rowDy =
			rowDy +
			(maxContentLinesInRow - 1) + // first content line is already applied with rowDy, hence minus 1
			(maxSubtitleLinesInRow * titleCellConf.subtitleFontsize) / titleCellConf.fontsize +
			rowDyIncrement;
		// isRowDyUsed = false;
	});
};

/**
 * Shink to fit grid data to field container
 * NB:
 * 	- it doesn't expand the grid to fit the container, it only shinks (scale down) the grid to fit. 
 * 	- If the grid is within the container with initial scale, it does nothing
 * 	- cell text in a row must have value, unless the whole row is empty, so that all cells in the row have <text> element
 * 	- require padding to leftmost & rightmost, so that the final output wouldn't be offset from field container
 * @param {object} param
	{
		field, // grid field configure
		headerRowData, // input data in header row. NB: value should not be '' unless entire row is empty. sample: ["Product","Small","Large"]. NB: value should not be ''
		contentRowsData, // input data in content rows. NB: value should not be '' unless entire row is empty. sample: [["Cappuccino\n\ngghgh","€1.99\n\nnew line","€2.99"],["Mocha","€2.49","€3.99"]],
		titleHeaderConf, // area 3.1 conf
		valueHeaderConf, // area 3.2 conf
		titleCellConf, // area 3.3 conf
		valueCellConf, // area 3.4 conf
		headerRuleConf, // rule (underline) conf for header
		rowsRuleConf, // rule (underline) conf for rows in content body
		isEventRule, // if true, rules (underlines) between rows are even
		paddingBetweenCellsEM = 0.5, // number. Number of em, e.g. 1em or 0.5em
		minPaddingBetweenCells, // in pixel
		paddingLeftMost, // padding (in pixel) to most left (first column)
		paddingRightMost, // padding (in pixel) to most right (last column)
	}
 * @returns {object}. format: { headerRowCells, contentRowCells, scale }
 */
const fitGridToContainer = ({
	field,
	headerRowData,
	contentRowsData,
	titleHeaderConf,
	valueHeaderConf,
	titleCellConf,
	valueCellConf,
	headerRuleConf,
	rowsRuleConf,
	isEventRule, // if true, rules (underlines) between rows are even
	paddingBetweenCellsEM = 0.5, // number. Number of em, e.g. 1em or 0.5em. Horizontal only
	minPaddingBetweenCells = 40, // in pixel
	paddingLeftMost = 0,
	paddingRightMost = 0,
}) => {
	// constants
	// // alignment in areas: left, center, right
	// const titleHeaderHorAlign = titleHeaderConf.titleHeaderHorAlign,
	// 	valueHeaderHorAlign = valueHeaderConf.valueHeaderHorAlign,
	// 	titleCellHorAlign = titleCellConf.titleCellHorAlign,
	// 	valueCellHorAlign = valueCellConf.valueCellHorAlign;
	const maxFontSizeInValueColumns = Math.max(valueHeaderConf.fontsize, valueCellConf.fontsize); // area 3.2 & 3.4

	// get initial row/cells render attrs & bBox (no scale applied)
	let headerRowCells, contentRowCells;
	if (headerRowData.length > 0) {
		headerRowCells = buildGridHeaderCells({
			field,
			titleHeaderConf,
			valueHeaderConf,
			headerRowData,
			// scale,
		});
	}
	if (contentRowsData.length > 0) {
		contentRowCells = buildGridContentRowCells({
			field,
			titleCellConf,
			valueCellConf,
			contentRowsData,
			// scale,
		});
	}
	/**
		 * At this point.
		 headerRowCells is null or object as below. NB: no scale applied to the bBox value
		{
			rowText: { attrs: textAttrs },
			cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, textContent: tspan.textContent }, ...],
		}
		 contentRowCells is null or array as below. NB: no scale applied to the bBox value
		 [ 
			 { 
					rowText: { attrs: textAttrs },
					cellsTspan: [{
							content: { attrs: contentTspanAttrs, 	bBox: bBox, textContent: contentTspan.textContent,},
							subtitle: { attrs: subtitleTspanAttrs, bBox: bBox, textContent: subtitleTspan.textContent,},
						}, ...
					],
			},
			...
		]
		 */

	// step 1: we will find the best "scale" to make grid fitting into the field container, horizontally and vertically
	let scale = 1, // initial scale to 1, it is also the max scale
		minScale = 0.1, // acceptable minimum scale
		scaleDownStep = 0.02; // will be reset to 0.02 after initial loop
	let rowDyScale = 1; // min 1, only increase up
	// the horizontal padding between cells
	// use {paddingBetweenCellsEM}em for the padding between columns: find the max font-size (in pt) in the (value area 3.2 & 3.4) cells, then convert pt to px
	let paddingBetweenCellsBase =
		maxFontSizeInValueColumns * PIXEL_UNIT_MAP.pt * paddingBetweenCellsEM; // NUMBER em in pixel
	let fittedSVG = {}; // result from getGridSVGBBox. {svgBBox, ruleLines}
	let isGridFittedInContainer = false; // if false, hor or ver of grid is not fitted in field container
	// do...while loop will use "scale" to shink horizontally & vertically to fit the grid into the field container
	do {
		// apply scale to paddingBetweenCells
		let paddingBetweenCells = Math.max(paddingBetweenCellsBase * scale, minPaddingBetweenCells); // 0.5em in pixel

		// set the horizontal position & size of cells in headerRowCells & contentRowCells. (scale applied)
		// from right to left as we want each row to expand to end and give max space to the first column
		// As we look for the best scale here, it is not for final output, hence we use the following to ensure the grid uses up all space in the field container:
		//	- use "left" for 1st column align,
		//	- use "right" for last column align
		let isHorFittedInRows = setCellHorPosSize({
			headerRowCells,
			contentRowCells,
			field,
			paddingBetweenCells,
			paddingLeftMost,
			paddingRightMost,
			scale, // always <=1;
			titleHeaderHorAlign: 'left',
			valueHeaderHorAlign: 'right',
			titleCellHorAlign: 'left',
			valueCellHorAlign: 'right',
		});

		/**
		 * At this point.
		 headerRowCells is null or object as below (attrs now contains x & text-anchor)
		{
			rowText: { attrs: textAttrs },
			cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: tspan.textContent }, ...],
		}
	
		 contentRowCells is null or array as below (attrs now contains x & text-anchor)
		 [
			{
				rowText: { attrs: textAttrs },
				cellsTspan: [
					{
						content: { attrs: contentTspanAttrs, 	bBox: bBox,  cellWidth: cellWidth, textContent: contentTspan.textContent,},
						subtitle: { attrs: subtitleTspanAttrs, bBox: bBox,  cellWidth: cellWidth, textContent: subtitleTspan.textContent,},
					},
					...
				],
			},
			...
		]
		 */

		// set the vertical y position of cells, including text-wrap
		setCellVerPos({
			headerRowCells,
			contentRowCells,
			// scale, // always <=1;
			rowDyScale,
			titleHeaderConf,
			valueHeaderConf,
			titleCellConf,
			valueCellConf,
		});

		/**
		 * at this point, we got final output
		 headerRowCells is null or object as below (attrs now contains x, text-anchor & dy)
		{
			rowText: { attrs: textAttrs },
			cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: tspan.textContent }, ...],
		}
	
		 contentRowCells is null or array as below (attrs in now contains x & text-anchor, final positioned data [array of wrapped lines] (could be null/undefined) is added to content & subtitle )
		 [
			{
				rowText: { attrs: textAttrs },
				cellsTspan: [
					{
						content: { attrs: contentTspanAttrs, 	bBox: bBox,  cellWidth: cellWidth, textContent: contentTspan.textContent, positioned: null | [{ attrs: { ...contentData.attrs, dy: `Xem`, }, textContent: lineText, }, ...]},
						subtitle: { attrs: subtitleTspanAttrs, bBox: bBox,  cellWidth: cellWidth, textContent: subtitleTspan.textContent, positioned: null | [{ attrs: { ...subtitleData.attrs, dy: `Xem`, }, textContent: lineText, }, ...]},
					},
					...
				],
			},
			...
		]
		 */
		// get rendered grid (svg) bBox (including rules) to see if it fits
		fittedSVG = getGridSVGBBox({
			field,
			scale,
			headerRowCells,
			contentRowCells,
			headerRuleConf,
			rowsRuleConf,
			isEven: isEventRule,
		});

		if (isHorFittedInRows && fittedSVG.svgBBox.height <= field.position.height)
			isGridFittedInContainer = true;

		if (scale - scaleDownStep < minScale) break;
		if (!isGridFittedInContainer) scale -= scaleDownStep;
	} while (!isGridFittedInContainer);
	// console.log(`grid final scale: ${scale}, final rowDyScale: ${rowDyScale}`);

	// ok, the best scale is found from step 1, but the height may require to expand
	// step 2: we will search for the best rowDyScale to expand grid vertically.
	// NB: at least two rows are required (No point to expand the height if there is only one row)
	// NB: empty row counts
	// NB: headerRow is counted as 1 row
	let totalNumOfRows = (headerRowData.length > 0 ? 1 : 0) + contentRowsData.length;
	let rowDyScaleUpStep = 0.1;
	let currentFittedSVG = fittedSVG; // it is the record of last fittedSVG, so that we don't need to roll back after finding the best rowDyScale
	let isVerExceed = false;
	if (totalNumOfRows > 1) {
		do {
			rowDyScale += rowDyScaleUpStep;
			// set the vertical y position of cells, including text-wrap
			setCellVerPos({
				headerRowCells,
				contentRowCells,
				rowDyScale,
				titleHeaderConf,
				valueHeaderConf,
				titleCellConf,
				valueCellConf,
			});
			fittedSVG = getGridSVGBBox({
				field,
				scale,
				headerRowCells,
				contentRowCells,
				headerRuleConf,
				rowsRuleConf,
				isEven: isEventRule,
			});
			isVerExceed = fittedSVG.svgBBox.height > field.position.height;
			if (!isVerExceed) {
				currentFittedSVG = fittedSVG; // update the record of last fittedSVG
			}
		} while (!isVerExceed); // if vertically not exceed the container, we keep searching for the best rowDyScale
		rowDyScale -= rowDyScaleUpStep; // current rowDyScale causes grid exceeding the container, so the previous rowDyScale is the best, roll back one step
	}

	return { scale, rowDyScale, ruleLines: currentFittedSVG.ruleLines };

	// if (totalNumOfRows > 1) {
	// 	// let rowDyScaleUpStep = 0.2;
	// 	do {
	// 		rowDyScale += rowDyScaleUpStep;
	// 		// set the vertical y position of cells, including text-wrap
	// 		setCellVerPos({
	// 			headerRowCells,
	// 			contentRowCells,
	// 			rowDyScale,
	// 			titleHeaderConf,
	// 			valueHeaderConf,
	// 			titleCellConf,
	// 			valueCellConf,
	// 		});
	// 		svgBBox = getGridSVGBBox({ field, scale, headerRowCells, contentRowCells }).svgBBox;
	// 	} while (svgBBox.height <= field.position.height);

	// 	// ok, found the best fit rowDyScale, but need to roll back the rowDyScale for one step
	// 	rowDyScale -= rowDyScaleUpStep;
	// 	setCellVerPos({
	// 		headerRowCells,
	// 		contentRowCells,
	// 		rowDyScale,
	// 		titleHeaderConf,
	// 		valueHeaderConf,
	// 		titleCellConf,
	// 		valueCellConf,
	// 	});
	// }
	// // get row bBoxes for creating lines for rules (underlines)
	// let ruleLines = [];
	// if (headerRuleConf || rowsRuleConf) {
	// 	let rowBBoxes = getGridSVGBBox({
	// 		field,
	// 		scale,
	// 		headerRowCells,
	// 		contentRowCells,
	// 		requireHeaderRule: Boolean(headerRuleConf),
	// 		requireContentRowRule: Boolean(rowsRuleConf),
	// 	}).rowBBoxesForRules;
	// 	ruleLines = createRulesInGrid({ rowBBoxes, headerRuleConf, rowsRuleConf, isEven: isEventRule });
	// }
	// return { /* headerRowCells, contentRowCells,  */ scale, rowDyScale, ruleLines };
};

/**
	 * Build grid cells (<text> <tspan>) for final output
	 * NB: it doesn't expand the grid to fit the container, it only shinks (scale down) the grid to fit. 
	 * If the grid is within the container with initial scale, it does nothing
	 * @param {object} param
		{
			field, // grid field configure
			headerRowData, // input data in header row
			contentRowsData, // input data in content rows
			titleHeaderConf, // area 3.1 conf
			valueHeaderConf, // area 3.2 conf
			titleCellConf, // area 3.3 conf
			valueCellConf, // area 3.4 conf
			paddingBetweenCellsEM = 0.5, // number. Number of em, e.g. 1em or 0.5em
			minPaddingBetweenCells, // in pixel
			paddingLeftMost, // padding (in pixel) to most left (first column)
			paddingRightMost, // padding (in pixel) to most right (last column)
		}
	 * @returns {object}. format: { headerRowCells, contentRowCells, scale }
	 */
const buildGridCells = ({
	field,
	headerRowData,
	contentRowsData,
	scale,
	rowDyScale,
	titleHeaderConf,
	valueHeaderConf,
	titleCellConf,
	valueCellConf,
	paddingBetweenCellsEM = 0.5, // number. Number of em, e.g. 1em or 0.5em. Horizontal only
	minPaddingBetweenCells = 40, // in pixel
	paddingLeftMost = 0,
	paddingRightMost = 0,
}) => {
	// constants
	// alignment in areas, possible values: left, center, right
	const titleHeaderHorAlign = titleHeaderConf.horAlign,
		valueHeaderHorAlign = valueHeaderConf.horAlign,
		titleCellHorAlign = titleCellConf.horAlign,
		valueCellHorAlign = valueCellConf.horAlign;
	const maxFontSizeInValueColumns = Math.max(valueHeaderConf.fontsize, valueCellConf.fontsize); // area 3.2 & 3.4

	// get initial row/cells render attrs & bBox (no scale applied)
	let headerRowCells, contentRowCells;
	if (headerRowData.length > 0) {
		headerRowCells = buildGridHeaderCells({
			field,
			titleHeaderConf,
			valueHeaderConf,
			headerRowData,
		});
	}
	if (contentRowsData.length > 0) {
		contentRowCells = buildGridContentRowCells({
			field,
			titleCellConf,
			valueCellConf,
			contentRowsData,
		});
	}

	// the horizontal padding between cells in pixel (NUMBER of em in pixel)
	// use {paddingBetweenCellsEM}em for the padding between columns: find the max font-size (in pt) in the (value area 3.2 & 3.4) cells, then convert pt to px
	let paddingBetweenCells = Math.max(
		maxFontSizeInValueColumns * PIXEL_UNIT_MAP.pt * paddingBetweenCellsEM * scale,
		minPaddingBetweenCells
	);

	// set the horizontal position & size of cells in headerRowCells & contentRowCells. (scale applied)
	// from right to left as we want each row to expand to end and give max space to the first column
	setCellHorPosSize({
		headerRowCells,
		contentRowCells,
		field,
		paddingBetweenCells,
		paddingLeftMost,
		paddingRightMost,
		scale, // always <=1;
		titleHeaderHorAlign,
		valueHeaderHorAlign,
		titleCellHorAlign,
		valueCellHorAlign,
	});

	// set the vertical y position of cells, including text-wrap
	setCellVerPos({
		headerRowCells,
		contentRowCells,
		rowDyScale,
		titleHeaderConf,
		valueHeaderConf,
		titleCellConf,
		valueCellConf,
	});

	return { headerRowCells, contentRowCells };
};

/**
 * Get bounding box of text in (multiple) tspans in SVG context
 * @param {array} tspans of tspan object. [{value: str, attrs: {}}]
 *
 * @returns {object} Rendered text width & height. {width: num, height: num}
 */
const getSizeOfTspansInSVGContext = (tspans) => {
	// create svg of this field
	let svg = document.createElementNS(NS.SVG, 'svg');
	document.body.append(svg);
	const text = document.createElementNS(NS.SVG, 'text');

	for (let i = 0; i < tspans.length; i++) {
		let tspanObj = tspans[i];
		let tspan = document.createElementNS(NS.SVG, 'tspan');
		setDomAttrs(tspan, tspanObj.attrs);
		tspan.textContent = tspanObj.value;
		text.appendChild(tspan);
	}

	svg.append(text);

	let textBBox = text.getBBox();
	svg.remove();
	return { width: textBBox.width, height: textBBox.height };
};

// function to check if the rendered Text is too big
const isTooBigInConcatField = ({ containerSize, actualBBoxSize }) => {
	return actualBBoxSize.width > containerSize.width || actualBBoxSize.height > containerSize.height;
};

// scale up to find best scale to fit text in its container
// used only in prepareTextField
const scaleUpToFitTextToContainer = ({
	text,
	maxScale,
	minScale,
	containerWidth,
	containerHeight,
}) => {
	const svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('viewBox', `0 0 ${containerWidth} ${containerHeight}`);

	svg.setAttribute('x', 0);

	svg.setAttribute('y', 0);
	svg.setAttribute('width', containerWidth);
	svg.setAttribute('height', containerHeight);
	svg.setAttribute('overflow', 'visible');

	// <g> for content. hold <text> in any mode
	const gContent = document.createElementNS(NS.SVG, 'g');
	gContent.append(text.cloneNode(true));
	svg.append(gContent);
	document.body.append(svg);

	const stepScale = 0.01;
	// use Binary Search (to upperBound) to find best scale
	let midScale = (minScale + maxScale) / 2;
	while (minScale + stepScale < maxScale) {
		gContent.setAttribute('transform', `scale(${midScale})`);

		let bb = svg.getBBox();
		if (bb.width > containerWidth || bb.height > containerHeight) {
			// "text" is too big,
			maxScale = midScale;
		} else {
			// "text" is too small
			minScale = midScale + stepScale;
		}
		midScale = (minScale + maxScale) / 2;
	}
	svg.remove();
	return midScale;
};

/**
 * Create tspan DOM for concat field by the given inputData & text field setting with auto-wrap option
 * There are 3 levels line-breaks in concatenation field.
 * Assume the following:
 * 	- The original input text is: 'this is concatenation field <field:xxxx-xxxxID>\nthis is 2nd line.'.
 * 	- The text in <field:xxxx-xxxxID> is: 1st line \n 2nd line that is a long sentence with more words \n 3rd line
 * Now the 3 levels line-breaks are as below:
 * 1. line break "\n" in original text in concatenation field.
 * 2. line break "\n" in the text of <field:xxxx-xxxxID>.
 * 3. With auto-wrap enabled, the "2nd line that is a long sentence with more words" in <field:xxxx-xxxxID> may be required to be lined up to multiple lines
 * This func deals with level 2 & 3 line-breaks
 *
 * NB: Having both concatField & embededField in parameters is because it may require original embeded field to process render-text, but using concatenation field for styles
 * @param {object} inputData // data for output with (concatField or embededField). {value: 'xxxx', fontsize: number}
 * @param {object} concatField // concatenation field
 * @param {object} embededField // embeded field. it is null if not embeded field.
 * @param {object} CONSTANTS
 * @param {object} opts {hasLeadingSpace: false, keepOriginalStyle: true, autoWrap: boolean, widthInCurrentLine: num}
 *
 * @returns {object}. return object of lines. {lineNum: [pieceTspanObj, ...], lineNum: [pieceTspanObj, ...], ...}
	pieceTspanObj:
		{
			// arry of tspans for this string piece (or embededField).
			// it usually has one item. It has more than one item in following cases:
			//	- when it is embeded number field, it has three items for euro, cent, currency
			tspans: [
				{
					value: 'xxx',
					attrs: {}
				},
				...
			],
			size: {
				width: num,
				height: num
			}
		}
 * lineNum is 0-indexed number but in string format, e.g. "0", "1", ...
 * lineNum "0" belongs to the current line (in level 1 line-break)
 * if the (pieceTspanObj) array of a lineNum is empty, there is no text to be append to this line
 */
const createTspanWithAutoWrapForConcatField = (
	inputData,
	concatField,
	embededField = null,
	CONSTANTS,
	opts = {}
) => {
	if (!inputData) return []; // empty text
	let {
		hasLeadingSpace = false,
		keepOriginalStyle = true,
		autoWrap = false,
		widthInCurrentLine = 0,
		tspansInCurrentLine = [],
	} = opts;
	let containerSize = { width: concatField.position.width, height: concatField.position.height };
	let {
		// isSingleLine,
		isNumberField,
		currencyFont, // when it is null, it uses same font as the field (field.fontfaceName)
		currencyStr,
		euroStr,
		centStr,
		formatedText, // this is array of lines of text
		displayOrder, // possible values: ECS=>euro,cent,symbol; SEC=>symbol,euro,cent; CS=>cent,symbol
	} = formatTextFieldValue({
		textStr: inputData.value,
		field: embededField || concatField,
		placeholderSameAsText: CONSTANTS.placeholderSameAsText,
	});
	let fieldForStyle = keepOriginalStyle ? embededField || concatField : concatField; // fallback to concatField if embededField is null
	let _fontsize = inputData.fontsize;
	let lineNum = 0;
	let lines = { 0: [] }; // init it with value to make sure "lines" is never an empty object
	if (isNumberField) {
		// number string. currency, euro, cent parts are in their own <tspan>
		// In this case, an array of tspan is returned as one item to represent the whole number e.g. [<tspan>, <tspan>, <tspan>]

		let currencyTspan = {
			value: currencyStr,
			attrs: {
				fill: fieldForStyle.fontColor.hex,
				'font-size': `${_fontsize}pt`,
				'font-family': `"${cleanupFontName(currencyFont || fieldForStyle.fontfaceName)}"`, // some font-face don't have currency symbol, hence we use specified currentFont in its original field
			},
		};
		let euroTspan = {
			value: euroStr,
			attrs: {
				fill: fieldForStyle.fontColor.hex,
				'font-size': `${_fontsize}pt`,
				'font-family': `"${cleanupFontName(fieldForStyle.fontfaceName)}"`,
			},
		};
		let centTspan = {
			value: centStr,
			attrs: {
				fill: fieldForStyle.fontColor.hex,
				'font-size': `${_fontsize}pt`,
				'font-family': `"${cleanupFontName(fieldForStyle.fontfaceName)}"`,
			},
		};

		// const insertLeadingSpace = (tspanObj) => {
		// 	// tspanObj.value = ' ' + tspanObj.value;
		// 	// return tspanObj;
		// 	// return a new object
		// 	return { ...tspanObj, value: ' ' + tspanObj.value };
		// };

		// let tspans =
		// 	displayOrder === 'ECS'
		// 		? [hasLeadingSpace ? insertLeadingSpace(euroTspan) : euroTspan, centTspan, currencyTspan]
		// 		: displayOrder === 'SEC'
		// 		? [
		// 				hasLeadingSpace ? insertLeadingSpace(currencyTspan) : currencyTspan,
		// 				euroTspan,
		// 				centTspan,
		// 		  ]
		// 		: displayOrder === 'CS'
		// 		? [hasLeadingSpace ? insertLeadingSpace(centTspan) : centTspan, currencyTspan]
		// 		: [];
		let tspans =
			displayOrder === 'ECS'
				? [euroTspan, centTspan, currencyTspan]
				: displayOrder === 'SEC'
				? [currencyTspan, euroTspan, centTspan]
				: displayOrder === 'CS'
				? [centTspan, currencyTspan]
				: [];
		if (hasLeadingSpace && tspans[0]) tspans[0].value = ' ' + tspans[0].value;

		let size = getSizeOfTspansInSVGContext(tspans);
		// we only wrap the whole number text to new line when auto-wrap is enabled
		if (autoWrap && size.width > containerSize.width - widthInCurrentLine) {
			// current line has no space for the number text, we use [] to indicate "need to go to next line"
			lines[lineNum] = [];
			lineNum++;

			// remove leading white space because this number text will go into the beginning of the new line
			if (hasLeadingSpace) {
				tspans[0].value = tspans[0].value.trim();
				// re-calculate the size of the number text
				size = getSizeOfTspansInSVGContext(tspans);
			}
		}
		lines[lineNum] = [
			{
				tspans: tspans,
				size,
			},
		];
	} else {
		// non-numeric field
		formatedText.forEach((lineTextStr, textLineIndex) => {
			lineTextStr = lineTextStr.trim(); // trim spaces in the line text
			if (autoWrap) {
				// we need wrap the text to new lines if it is too long
				// // split text to multiple words by "Lookbehind" regex with keeping the white-spaces as they are. then we remove (empty) items that are only space or line-breaks
				// // NB: each word has trailing space by above "lookbehind" regex
				// let words = lineTextStr.split(/(?<=\s)/gm).filter((w) => Boolean(w.trim()));

				// split text by white-spaces. then remove empty str. NB: no space in each "word" after using this regex
				let words = lineTextStr.split(/\s+/gm).filter((w) => Boolean(w.trim()));
				let wrappedLineText = '';
				let tspan = {
					// value: word,
					attrs: {
						fill: fieldForStyle.fontColor.hex,
						'font-size': `${_fontsize}pt`,
						'font-family': `"${cleanupFontName(fieldForStyle.fontfaceName)}"`,
					},
				};

				words.forEach((word, wordIndex) => {
					if (hasLeadingSpace && textLineIndex === 0 && wordIndex === 0) {
						word = ' ' + word;
					}
					if (wordIndex !== 0) word = ' ' + word; // add white space to beginning if it is not first word in the line
					// else if (wordIndex !== 0) {
					// 	// add space ' ' to each word that is not the first one in a line (except first line)
					// 	word = ' ' + word;
					// }

					// get the text size with the new word to be added
					let size = getSizeOfTspansInSVGContext([
						...tspansInCurrentLine,
						{ ...tspan, value: wrappedLineText + word },
					]);
					// when tspansInCurrentLine.length is 0, we insert this new word to the current line regardless of its size (we don't allow empty line)
					if (
						(tspansInCurrentLine.length > 0 || wrappedLineText) &&
						size.width > containerSize.width
					) {
						// the size of this new word is too big for current line, let's finish up the current line, and insert the "word" to next line.
						if (!lines[lineNum]) lines[lineNum] = [];
						if (wrappedLineText.length > 0) {
							// Add wrappedLineText (without the new word) to the current line
							lines[lineNum].push({
								tspans: [{ ...tspan, value: wrappedLineText }],
								size: getSizeOfTspansInSVGContext([{ ...tspan, value: wrappedLineText }]),
							});
							// then reset wrappedLineText for next line
							wrappedLineText = '';
						}

						// remove leading white space from word as the word will go into the beginning of the next line
						// Note (VID-3644):
						//			- previous comment has a condition "if (wordIndex !== 0)" to remove space if it is not the first word in the line, forgot why was it
						//			- comment it here in case the condition is required (unlikely)
						//			- in the change for vid-3644, removed the condtion, so space is removed if the "word" goes to next line
						word = word.trim();

						// increase line for the rest words
						lineNum++;
						widthInCurrentLine = 0; // reset line width in next line
						tspansInCurrentLine = []; // reset tspansInCurrentLine in next line
						lines[lineNum] = [];
					}
					// insert the new word to wrappedLineText. The wrappedLineText is either empty or still short with the new word
					wrappedLineText = wrappedLineText + word;
					// Because we measure the size of wrappedLineText (containing all wrapped words), we don't need to update widthInCurrentLine, it always is zero except first line (when lineNum = 0)
					// widthInCurrentLine += size.width;
				});
				// All words are processed. Let's insert wrappedLineText to current line (before the line number increases)
				if (wrappedLineText.length > 0) {
					lines[lineNum].push({
						tspans: [{ ...tspan, value: wrappedLineText }],
						size: getSizeOfTspansInSVGContext([{ ...tspan, value: wrappedLineText }]),
					});
				}
			} else {
				// render lineTextStr into current line when auto-wrap is disabled
				if (hasLeadingSpace && textLineIndex === 0) {
					lineTextStr = ' ' + lineTextStr;
				}

				let tspan = {
					value: lineTextStr,
					attrs: {
						fill: fieldForStyle.fontColor.hex,
						'font-size': `${_fontsize}pt`,
						'font-family': `"${cleanupFontName(fieldForStyle.fontfaceName)}"`,
					},
				};

				let size = getSizeOfTspansInSVGContext([tspan]);
				if (!lines[lineNum]) lines[lineNum] = [];
				lines[lineNum].push({
					tspans: [tspan],
					size,
				});
				// widthInCurrentLine += size.width;
			}

			// at end of each line, we increase the line number, reset widthInCurrentLine & tspansInCurrentLine, except last line
			if (textLineIndex < formatedText.length - 1) {
				lineNum++;
				// reset widthInCurrentLine & tspansInCurrentLine because it will be new line in next loop
				widthInCurrentLine = 0;
				tspansInCurrentLine = [];
				lines[lineNum] = [];
			}
		});
	}

	return lines;
};

/**
 * Create tspans for each line in concat field
 * @param {*} param0
 * @returns
 */
const createTspansPerLineInConcatField = ({
	originalText,
	_fontSize,
	autoWrap,
	field,
	templateFields,
	fieldOutputData,
	CONSTANTS,
}) => {
	let lines = originalText.split(/\n/).map((item) => item.trim());
	let tspansPerLine = {}, // object to hold <tspan> array per line. {[lineNum]: tspans: [], strikeThroughStyle:{}, size: {width: num, height: num}}
		lineNum = 0;
	lines.forEach((line) => {
		let pieces = [], // string and embeded fields in one line
			// regexToSplitLine = /(\s?.*?)<field:\s*(.*?)\s*>(\s?.*?)/gm,
			regexToSplitLine = /(\s*.*?)<field:\s*(.*?)\s*>(\s*.*?)/gm,
			m = null,
			lastMatch = '';
		do {
			m = regexToSplitLine.exec(line);
			if (m) {
				lastMatch = m[0];
				if (m[1]) pieces.push({ value: m[1], isEmbeded: false });
				pieces.push({
					value: m[2].substring(m[2].lastIndexOf('_') + 1) /* m[2].split('_').pop() */,
					isEmbeded: true,
				});
				if (m[3]) pieces.push({ value: m[3], isEmbeded: false });
			} else {
				// no match, the whole line or the remaining is regular string
				pieces.push({
					value: !lastMatch ? line : line.substring(line.lastIndexOf(lastMatch) + lastMatch.length), // maybe use "" (instead of substring()) when lastMatch has value???
					isEmbeded: false,
				});
			}
		} while (m);
		let hasLeadingSpace = false;

		for (let i = 0; i < pieces.length; i++) {
			let piece = pieces[i];
			if (!piece.value) continue;
			if (!piece.value.trim()) {
				// ok, this piece is a space, it will be inserted to the front of the next piece
				hasLeadingSpace = true;
				continue;
			}
			let concatField = field, // default is the concatenation field
				embededField = null, // embededField
				pieceStr = piece.value, // string of the piece or field id
				fontsize = _fontSize, // default to the font size of the concatenationn field
				lineHeight = field.leadingLineHeight, // default to the line height of the concatenation field
				strikeThroughStyle = {}; // strikeThroughStyle: {type: ''|'strike'|'strikeup'|'strikedown', lineWidth: 1, strikeColorInEmbeddedField: embeddedField.fontColor.hex}
			if (piece.isEmbeded) {
				// piece.value here is field id if isEmbeded is true
				embededField = templateFields.find((f) => f.id === piece.value);
				if (!embededField) {
					// it may never happen
					console.debug(
						`Can't find embeded field (${piece.value}) in concatenation field ${field.id} (${field.name})`
					);
					continue;
				}
				if (
					hideFieldOutput(embededField, templateFields, fieldOutputData, { ignoreHideOutput: true })
				) {
					// the dependent field of the embeded field has no value, hence no output of this embeded field
					continue;
				}
				if (embededField.type !== 'text' && embededField.type !== 'barcode') continue; // we only render text|barcode embeded field in this func, so ignore it

				let embededFieldInputData = fieldOutputData[embededField.id];

				pieceStr = embededFieldInputData.value || '';
				// if the next piece does not start with white-space, it should be appended to current pieceStr
				if (pieces[i + 1] && !pieces[i + 1].isEmbeded && !pieces[i + 1].value.startsWith(' ')) {
					pieceStr += pieces[i + 1].value;
					i++;
				}

				// NB: barcode embedded field will NEVER use its original styles
				if (field.embedStyle === 'original' && embededField.type !== 'barcode') {
					fontsize = embededFieldInputData.fontsize;
					lineHeight = embededField.leadingLineHeight;
					if (embededField.strikeThrough) {
						strikeThroughStyle = {
							...embededField.strikeThroughStyle,
							strikeColorInEmbeddedField: embededField.fontColor?.hex || '#000000',
						};
					}
				}
			}

			// the string has only white space, ignore it. (pieceStr may be changed to the text in embeded field)
			if (!pieceStr.trim()) continue;
			let linesInPiece = createTspanWithAutoWrapForConcatField(
				{ value: pieceStr, fontsize: fontsize },
				concatField,
				embededField,
				CONSTANTS,
				{
					hasLeadingSpace: hasLeadingSpace,
					keepOriginalStyle:
						field.embedStyle === 'original' && (!embededField || embededField.type !== 'barcode'),
					autoWrap,
					widthInCurrentLine: tspansPerLine[lineNum]
						? tspansPerLine[lineNum].reduce((accu, item) => accu + (item.size.width || 0), 0)
						: 0,
					tspansInCurrentLine: tspansPerLine[lineNum]
						? tspansPerLine[lineNum].reduce((accu, item) => accu.concat(item.tspans || []), [])
						: [],
				}
			);

			// the pieceStr is trimed when creating Tspan, in case it has trailing space, that trailing space will go to next text
			if (pieceStr.endsWith(' ')) hasLeadingSpace = true;
			// reset hasLeadingSpace to false after consuming it
			else hasLeadingSpace = false;
			// ok, we have created linesInPiece for the piece string or embeded field value
			for (let lineNumInPiece in linesInPiece) {
				// lineNumInPiece is string of line index number, "0", "1", ...
				let lineInPiece = linesInPiece[lineNumInPiece]; // array of pieceTspanObj in a line. All items in the array are belonging to the same line
				// insert a line in this piece to the current line of original text in concat field
				for (let k = 0; k < lineInPiece.length; k++) {
					if (!tspansPerLine[lineNum]) tspansPerLine[lineNum] = [];
					tspansPerLine[lineNum].push({
						tspans: lineInPiece[k].tspans, // this tspans usually has only one item. It will have more than 1 item if the piece is number field
						strikeThroughStyle,
						tspanFontSize: fontsize, // we record the font size of this tspan, it is the font size of either of the concat field or the embedded field
						tspanLineHeight: lineHeight, // we record the lineHeight of this tspan, it is the line height of either of the concat field or the embedded field
						size: lineInPiece[k].size,
					});
				}
				// the comentted code below explains the logic in different cases, it does same thing as the code above
				// if (lineInPiece.length === 0) {
				// 	// nothing to be inserted to current line. We do nothing so that lineNum will be increased by 1
				// } else if (lineInPiece.length > 1) {
				// 	// the piece has multiple words. all words belong to current line
				// 	for (let k = 0; k < lineInPiece.length; k++) {
				// 		if (!tspansPerLine[lineNum]) tspansPerLine[lineNum] = [];
				// 		tspansPerLine[lineNum].push({
				// 			tspans: lineInPiece[k].tspans,
				// 			strikeThroughStyle,
				// 			size: lineInPiece[k].size,
				// 		});
				// 	}
				// } else {
				// 	// one piece string, but we need to check if it is number string (which has multiple parts - currency, euro, cent)
				// 	let pieceTspanObj = lineInPiece[0];
				// 	if (!tspansPerLine[lineNum]) tspansPerLine[lineNum] = [];
				// 	if (pieceTspanObj.tspans.length > 1 /* Array.isArray(pieceTspanObj.tspans) */) {
				// 		// number string with currency, euro, cent parts. We keep it as array and will use nested <tspan> inside <tspan> to render it
				// 		tspansPerLine[lineNum].push({
				// 			tspans: pieceTspanObj.tspans,
				// 			strikeThroughStyle,
				// 			size: pieceTspanObj.size,
				// 		});
				// 	} else {
				// 		tspansPerLine[lineNum].push({
				// 			tspans: pieceTspanObj.tspans,
				// 			strikeThroughStyle,
				// 			size: pieceTspanObj.size,
				// 		});
				// 	}
				// }
				// end of explaination of code logic for different cases

				// increase lineNum in original text of concat field by 1, so that the next line in piece can be inserted to the next line in original text.
				// first item (array) in linesInPiece still belongs to the current line, that is why we increase lineNum after pushing it
				lineNum += 1;
			}
			// we minus 1 to the lineNum, because there could be string (behind the piece) still in the same line
			lineNum -= 1;
		}
		// increase lineNum by 1 to reflect the level 1 ling-break in original text in concatenation field
		lineNum += 1;
	});
	return tspansPerLine;
};

/**
 * Set DOM element attributes
 * @param {Element} elem
 * @param {object} attrs
 */
const setDomAttrs = (elem, attrs) => {
	for (let attrKey in attrs) {
		elem.setAttribute(attrKey, attrs[attrKey]);
	}
};

/**
 * Get alignment attributes based on the field horizontal|vertical settings
 * @param {object} opts {horAlign: 'xxx', verAlign: 'xxx', field: OBJECT, isNumberField: BOOLEAN, isConcatTextField: BOOLEAN, scale: 0.1 | 1.4}
 * Note, if scale is specified, it is the scale for the element, not the field box.
 * 	If scale < 1, means the field box is increased to 1/scale
 * 	if scale > 1, means the field box is reduced to 1/scale
 * @return {object}. { attrName: attrValue, ... }
 */
const getAlignmentAttrs = (opts) => {
	let alignmentAttrs = {};
	if (opts.horAlign === 'left') {
		alignmentAttrs.x = 0;
		alignmentAttrs['text-anchor'] = 'start';
	} else if (opts.horAlign === 'center') {
		alignmentAttrs.x = (opts.field.position.width * (1 / (opts.scale || 1))) / 2;
		alignmentAttrs['text-anchor'] = 'middle';
	} else if (opts.horAlign === 'right') {
		alignmentAttrs.x = opts.field.position.width * (1 / (opts.scale || 1));
		alignmentAttrs['text-anchor'] = 'end';
	} else if (opts.horAlign === 'justified') {
		alignmentAttrs.x = 0;
		alignmentAttrs['text-anchor'] = 'start';
	}

	//dominant-baseline - "Alphabetic" is better suit for pdf generation, base on https://www.w3.org/Graphics/SVG/WG/wiki/How_to_determine_dominant_baseline
	if (opts.verAlign === 'top') {
		// VID-3499 No. 3, we scale up the whole text if currency/cent is scaled down.
		// 	when currency/cent is scaled down, dyForAlphabeticOnTopVerAlign is applied to the first <tspan> (usually the currency),
		//	so we need to pay back the offset, that is why "-1 * opts.field.position.height * (1 - dyForAlphabeticOnTopVerAlign)" is applied
		alignmentAttrs.y =
			opts.scale && opts.scale !== 1
				? -1 * opts.field.position.height * (1 - dyForAlphabeticOnTopVerAlign)
				: 0;
		alignmentAttrs['dominant-baseline'] = 'alphabetic'; //opts.isNumberField ? 'alphabetic' : 'alphabetic'; //'text-before-edge'; //'hanging';
	} else if (opts.verAlign === 'middle') {
		// VID-3499 No. 3, we scale up the whole text if currency/cent is scaled down.
		// 	when currency/cent is scaled down, dyForAlphabeticOnTopVerAlign is applied to the first <tspan> (usually the currency),
		//	so we need to pay back the offset, that is why "devide by (opts.scale && opts.scale !== 1 ? dyForAlphabeticOnTopVerAlign : 1)" is applied
		alignmentAttrs.y =
			(opts.field.position.height * (1 / (opts.scale || 1))) /
			2 /
			(opts.scale && opts.scale !== 1 ? dyForAlphabeticOnTopVerAlign : 1);
		// VID-3499, Bug No.8 (https://visualid.atlassian.net/browse/VID-3499).
		// VID-3499: Add isConcatTextField to opts and use alphabetic for middle verAlign in concatTextField
		alignmentAttrs['dominant-baseline'] = opts.isConcatTextField ? 'alphabetic' : 'middle';
	} else if (opts.verAlign === 'bottom') {
		alignmentAttrs.y = opts.field.position.height * (1 / (opts.scale || 1));
		// Some chars will go out of the bounds with "baseline" (e.g. ['y', 'j', 'g', 'q', 'p', 'Q']), so we will use "text-after-edge"
		alignmentAttrs['dominant-baseline'] = 'alphabetic'; //opts.isNumberField ? 'alphabetic' : 'alphabetic'; //'text-after-edge';
	}
	return alignmentAttrs;
};

// Function to create the entire <Text> element (the <Text> contains all lines (all Tspans))
const createTextElemInConcatField = ({
	tspansPerLine,
	_fontSize,
	horAlign,
	verAlign,
	gContentScale,
	field,
}) => {
	// Calc alignment.
	let alignmentAttrs = getAlignmentAttrs({
		horAlign,
		verAlign,
		field,
		isNumberField: false,
		isConcatTextField: true,
	});
	alignmentAttrs.x = alignmentAttrs.x / gContentScale;
	alignmentAttrs.y = alignmentAttrs.y / gContentScale;

	// get concatenation field <text> attributes
	let textAttrs = {
		style: `font-family: "${cleanupFontName(field.fontfaceName)}";`,
		// 'font-family': field.fontfaceName,	// somehow, it doesn't work in chrome|edge when putting font-family in attribute
		'font-size': `${_fontSize}pt`,
		fill: field.fontColor.hex,
		...alignmentAttrs,
	};

	let attrsPerLine = {}; // attrsPerLine object holds attributes per line. {[lineKey]: {x: 0, dy: '1em'), [lineKey]: {...}}}
	let totalDyInEm = 0; // because the dy is dynamically calculated for each line, we need to know the totalDyInEm of all lines
	let dyFirstLineInEm = 1; // dy of first line is special as it decides the vertical alignment
	let lineHeight = field.leadingLineHeight; //'1em';

	// get first lineKey, some cases the first line in tspansPerLine is not "0", but could be "1", "2", ..., etc
	const FIRST_LINE_KEY = Math.min(
		...Object.keys(tspansPerLine).map((key) => parseInt(key))
	).toString();

	// create <text> wrapper
	const text = document.createElementNS(NS.SVG, 'text');
	setDomAttrs(text, textAttrs);

	// strikeStyledTspans is an array contains (piece string) tspans that has strikeThrough styles.
	// format: [{tspanElem: DOM, strikeThroughStyle: {type: ''|'strike'|'strikeup'|'strikedown', lineWidth: 1}}]
	let strikeStyledTspans = [];

	// create <tspan> of each line and nested <tspan> of each piece string in each line
	for (let lineKey in tspansPerLine) {
		// NOTE. We can't use zero-width unicode (\u200B) for empty line as it will be a dot in the generated PDF
		const lineTspan = document.createElementNS(NS.SVG, 'tspan');

		// ok, at this point, line <tspan> is ready, let's build up piece <tspan>s in the line
		let attrsInThisLine = {
			x: alignmentAttrs.x,
		};
		let lineSpec = {
			// hold the spec of the line. It uses the max <tspan> in a line as the font-size & lineHeight of the line. Used only for line spacing "dy"
			maxTspanFontSize: parseFloat(tspansPerLine[lineKey][0].tspanFontSize || _fontSize), // default to the font size of the concat field
			maxTspanLineHeight: parseFloat(
				tspansPerLine[lineKey][0].tspanLineHeight || field.leadingLineHeight
			), // default to the line height of the concat field
		};

		let pieceTspans = tspansPerLine[lineKey]; // array contains the nested <tspan> of each piece string in the line
		for (let pieceIndex = 0; pieceIndex < pieceTspans.length; pieceIndex++) {
			// pieceObj is object of { tspans: [tspanObj, tspanObj, ...], strikeThroughStyle:{} }. In the object, tspanObj = { value: 'xxxx', attrs: { }}; strikeThroughStyle={type: ''|'strike'|'strikeup'|'strikedown', lineWidth: 1};
			// pieceObj.tspans is an array contains string parts, in most cases it contains one part, but for (price) number field, it contains multiple parts (euro, cent, currency)
			// NB: we apply strikeThroughStyle only to number field only at the moment on 04/03/2021
			let pieceObj = pieceTspans[pieceIndex];
			let pieceTextTspan = null; // <tspan> dom for this piece text
			if (pieceObj.tspans.length === 0) {
				continue;
			} else if (pieceObj.tspans.length === 1) {
				let tspanObj = pieceObj.tspans[0];
				pieceTextTspan = document.createElementNS(NS.SVG, 'tspan');
				setDomAttrs(pieceTextTspan, tspanObj.attrs);
				pieceTextTspan.textContent = tspanObj.value;
			} else {
				// number field, we use nested <tspan> for this string piece
				pieceTextTspan = document.createElementNS(NS.SVG, 'tspan');
				pieceObj.tspans.forEach((tspanObj) => {
					let strPartTspan = document.createElementNS(NS.SVG, 'tspan');
					setDomAttrs(strPartTspan, tspanObj.attrs);
					strPartTspan.textContent = tspanObj.value;
					pieceTextTspan.appendChild(strPartTspan);
				});
			}
			// update max fontsize
			lineSpec.maxTspanFontSize = Math.max(
				lineSpec.maxTspanFontSize,
				parseFloat(pieceObj.tspanFontSize || 0)
			);
			lineSpec.maxTspanLineHeight = Math.max(
				lineSpec.maxTspanLineHeight,
				parseFloat(pieceObj.tspanLineHeight || field.leadingLineHeight)
			);
			if (pieceTextTspan && pieceObj.strikeThroughStyle.type) {
				// this text piece has strikeThrough style
				strikeStyledTspans.push({
					tspanElem: pieceTextTspan,
					strikeThroughStyle: pieceObj.strikeThroughStyle,
				});
			}
			// add this piece text to the line
			if (pieceTextTspan) lineTspan.appendChild(pieceTextTspan);
		}

		/**
		 * VID-3499 - No. 8: line spacing in concatenated fields (see detail in the comments of the ticket)
		 * To correctly calculate the line spacing "dy" in concatenation field,
		 * the different font-size and line height setting of embedded field in each line-paragragh are applied:
		 * 	- the embedded field will obey its own setting if it is configured to keep "original"
		 * 	- In each line, it finds the font-size and line height of the first <tspan>, use them as the setting of the whole line
		 * 	- use the font-size and line height to divide the base font-size to get the dy of the current line
		 *
		 * 	* base font-size is the configured ones in concat field, not embedded field
		 */

		const dyInThisLine = (lineSpec.maxTspanFontSize * lineSpec.maxTspanLineHeight) / _fontSize;

		attrsInThisLine.dy = `${dyInThisLine}em`;
		if (lineKey === FIRST_LINE_KEY) dyFirstLineInEm = dyInThisLine; // get the dy for first line
		totalDyInEm += dyInThisLine;
		attrsPerLine[lineKey] = attrsInThisLine;

		// set attributes in line <tspan>
		setDomAttrs(lineTspan, attrsInThisLine);

		// the line <tspan> is built up, let's append it to <text> in its order
		text.appendChild(lineTspan);
	}

	let dyFirstLine = 0;
	if (verAlign === 'top') {
		dyFirstLine = dyFirstLineInEm;
	} else if (verAlign === 'middle') {
		// Note: when verAlign is "middle", the dominant-baseline is still using "alphabetic" in concat field,
		// that is why the whole (not half) "dyFirstLineInEm" is added
		dyFirstLine = -0.5 * totalDyInEm + dyFirstLineInEm;
	} else if (verAlign === 'bottom') {
		dyFirstLine = -1 * (totalDyInEm - dyFirstLineInEm);
	}

	// Now we got the proper dy for first line, need to update first line in text element & the attrsPerLine object
	text.children[0].setAttribute('dy', `${dyFirstLine}em`);
	attrsPerLine[FIRST_LINE_KEY].dy = `${dyFirstLine}em`;

	return {
		text,
		attrs: { textAttrs, attrsPerLine, lineHeight, dyFirstLine, strikeStyledTspans },
	};
};

/**
 *
 * @param {Object} param
 * {
 * 		textStr: String, // original string to be formated
 * 		field: Object, // field object
 * 		placeholderSameAsText: String	// Place holder string of "same" as field font
 * }
 *
 * @return {object}
 * {
 *	isNumberField: false,
		currencyFont: null, // when it is null, it uses same font as the field (field.fontfaceName)
		currencyStr: '',
		euroStr: '',
		centStr: '',
		formatedText: '',
 * }
 */
const formatNumText = ({ textStr, field, placeholderSameAsText }) => {
	let returnData = {
		isNumberField: false,
		currencyFont: null, // when it is null, it uses same font as the field (field.fontfaceName)
		currencyStr: '',
		euroStr: '',
		centStr: '',
		formatedText: '',
		displayOrder: '', // display order of currency-symbol(S), euro (E), cent (C), possible values: ECS=>euro,cent,symbol; SEC=>symbol,euro,cent; CS=>cent,symbol
	};
	if (!field.formatNumber || isConcatField(field)) return returnData;

	const formatNumberStyle = field.formatNumberStyle;
	const specifiedCurrencySymbol = formatNumberStyle.currencySymbol.trim() || '';
	const regexToFindNonDigit = new RegExp(
		`[^\\d|.|\\s${specifiedCurrencySymbol ? '|' + specifiedCurrencySymbol : ''}]+`,
		'gm'
	);
	// const regexToFindNonDigit = /[^\d|.|\s]+/gm; // If ignore space, you may add \s to regex: /[^\d|.|\s]+/gm
	let foundNonDigit = textStr.trim().match(regexToFindNonDigit);
	// some other regex to find number:  /[-|+]{0,1}[\d]*[.|,]{0,1}[\d]+/g, /[+|-]{0,1}[\d|,|.]+/g, /[\d|,|.]+/g
	let regexToFindNum = /[\d,.]+/g;
	let foundNums = textStr.match(regexToFindNum) || [];
	if (field.formatNumber && foundNums.length === 1 && !foundNonDigit) {
		// it is a number text field
		// there could be string before & after the number, NB, we don't trim strBeforeNum, strAfterNum
		// we need to insert these strings to the output, but only for price-calculation field
		let strBeforeNum = '',
			strAfterNum = '';
		if (
			field.type === 'text' &&
			field.calcValue.price &&
			field.calcValue.unit &&
			field.calcValue.qty
		) {
			// price-calculation field and it has value
			let regexToFindStrBesideNum = /^(\D*)([\d,.]+)(\D*)$/g;
			let m = regexToFindStrBesideNum.exec(textStr);
			if (m) {
				strBeforeNum = m[1];
				strAfterNum = m[3];
			}
		}
		returnData.isNumberField = true;
		returnData.currencyFont =
			formatNumberStyle.currencyFontName === placeholderSameAsText
				? null
				: formatNumberStyle.currencyFontName;

		let textNumber = roundDecimals(parseFloat(foundNums[0].replace(/,/g, '')), 2);
		let textNumberParts = textNumber.toString().split('.');
		let euroNum = parseInt(textNumberParts[0] || '0');
		// we treat €1.9 as €1.90, treat €1 as €1.00.
		textNumberParts[1] = textNumberParts[1] || '0'; // in case there is no cent part e.g. €1
		textNumberParts[1] =
			textNumberParts[1].length === 1 ? textNumberParts[1] + '0' : textNumberParts[1];
		let centNum = parseInt(textNumberParts[1] || '0');
		let currencySymbol = formatNumberStyle.currencySymbol.trim() || '';
		if (formatNumberStyle.displayCent && textNumber < 1) {
			// only cent part to be displayed
			returnData.currencyStr =
				currencySymbol === '£'
					? 'p'
					: currencySymbol === '$'
					? '¢' //'\u20b5'
					: currencySymbol === '€'
					? 'c'
					: '';
			returnData.euroStr = '';
			returnData.centStr =
				centNum === 0 && formatNumberStyle.doNotDisplayZeroCent
					? ''
					: centNum
							.toLocaleString(undefined, { minimumIntegerDigits: centNum < 10 ? 1 : 2 })
							.substring(0, 2);
			// insert strBeforeNum & strAfterNum. NB, output string is always "centStr then currencyStr" in this case
			returnData.centStr = strBeforeNum + returnData.centStr;
			returnData.currencyStr = returnData.currencyStr + strAfterNum;
			returnData.formatedText = `${returnData.centStr}${returnData.currencyStr}`;
			returnData.displayOrder = `CS`;
		} else {
			returnData.currencyStr = formatNumberStyle.currencySymbol;
			// Add commas as thousands seperator. Ref to stackoverflow: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
			returnData.euroStr = euroNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			returnData.centStr =
				centNum === 0 && formatNumberStyle.doNotDisplayZeroCent
					? ''
					: '.' + centNum.toLocaleString(undefined, { minimumIntegerDigits: 2 }).substring(0, 2);

			// insert strBeforeNum & strAfterNum.
			if (formatNumberStyle.currencyPosition === 'leading') {
				returnData.currencyStr = strBeforeNum + returnData.currencyStr;
				returnData.centStr = returnData.centStr + strAfterNum;
				returnData.displayOrder = `SEC`;
			} else {
				// currency is at trailing position
				returnData.currencyStr = returnData.currencyStr + strAfterNum;
				returnData.euroStr = strBeforeNum + returnData.euroStr;
				returnData.displayOrder = `ECS`;
			}
			returnData.formatedText =
				formatNumberStyle.currencyPosition === 'leading'
					? `${returnData.currencyStr}${returnData.euroStr}${returnData.centStr}`
					: `${returnData.euroStr}${returnData.centStr}${returnData.currencyStr}`;
		}
	}
	return returnData;
};

/**
		 * Create shadow image of text field for pdf generation
		 * NOTE: this is only apply blur shadow parameter, the shadow offset must be handled by caller
		 * @param {object} opts
			{
				text: DOM Elem, // the text dom to have shadow
				textBBox: {x: NUMBER, y: NUMBER, width: NUMBER, height: NUMBER}, 	// bounding box of the text element
				fontList: [{ name: font.font, fontUrl: font.path }, ...], // the font list used in text
				shadowBlurRadius: field.shadowBlurRadius || 0,
				shadowColor: field.textShadowColor.hex,
				highRes: true, // use high-resolution image or not
				filterOffset: NUMBER, // number of pixel applies to filter offset to prevent cut off
			}
		 */
const createTextShadowImageDataUrl = ({
	text,
	textBBox,
	fontList,
	shadowBlurRadius,
	shadowColor,
	highRes,
	filterOffset,
}) => {
	// To prevent the filter shadow gets cut off, we extent container svg (and filter) by filterOffset(px) in both hor & ver direction
	const containerWidth = textBBox.width + filterOffset * 2,
		containerHeight = textBBox.height + filterOffset * 2;
	const svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('viewBox', `0 0 ${containerWidth} ${containerHeight}`);
	svg.setAttribute('width', containerWidth);
	svg.setAttribute('height', containerHeight);
	document.body.append(svg);
	// const textClone = text.cloneNode(true);
	// shift <text> corresponding to filter offset
	text.setAttribute('x', text.getAttribute('x') - textBBox.x + filterOffset);
	text.setAttribute('y', text.getAttribute('y') - textBBox.y + filterOffset);
	text.childNodes.forEach((tspan) => {
		let x = tspan.getAttribute('x');
		if (x) {
			tspan.setAttribute('x', x - textBBox.x + filterOffset);
		}
	});

	// configure shadow of the text
	const filter = document.createElementNS(NS.SVG, 'filter');

	filter.setAttribute('x', -filterOffset);

	filter.setAttribute('y', -filterOffset);
	filter.setAttribute('width', containerWidth); //'100%');
	filter.setAttribute('height', containerHeight); //'100%');
	filter.setAttribute('id', 'shadowImgFilter');

	filter.innerHTML = `
					<feGaussianBlur stdDeviation="${shadowBlurRadius}" result="shadow"></feGaussianBlur>
				`;
	svg.append(filter);

	text.style.filter = 'url(#shadowImgFilter)';
	text.style.fill = shadowColor;
	svg.append(text);

	// let unitMap = pixelUnitMap();
	let dimensionRatio = highRes ? pdfPtToPixel / 2 : 1;
	let imgWidth = containerWidth * dimensionRatio;
	let imgHeight = containerHeight * dimensionRatio;
	return addFontStyleToSVG(svg, { full: true, fontList: fontList }).then((svg) => {
		const svgData = new XMLSerializer().serializeToString(svg);
		const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

		const svgBlobUrl = DOMURL.createObjectURL(svgBlob);

		let canvas = document.createElement('canvas');

		canvas.setAttribute('width', imgWidth);

		canvas.setAttribute('height', imgHeight);
		let ctx = canvas.getContext('2d');
		return new Promise((res, rej) => {
			var img = document.createElement('img');
			img.onerror = (err) => rej(err);
			img.onload = () => {
				ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
				const imgDataUrl = canvas.toDataURL('image/png');

				DOMURL.revokeObjectURL(svgBlobUrl);

				svg.remove();
				res(imgDataUrl);
			};

			img.setAttribute('width', imgWidth);

			img.setAttribute('height', imgHeight);
			img.setAttribute('src', svgBlobUrl);
		});
	});
};

/**
 * NB: concatenation field has seperate process, it never calls this function
 * @param {Object} param
 * {
 * 		textStr: String, // original string to be formated
 * 		field: Object, // field object
 * 		placeholderSameAsText: String	// Place holder string of "same" as field font
 * }
 * 
 * * @return {object}
 * {
 *  isSingleLine: false|true,
 *	isNumberField: false,
		currencyFont: null,
		currencyStr: '',
		euroStr: '',
		centStr: '',
		formatedText: Array, 	// we use array of text even for single line
 * }
 */
const formatTextFieldValue = ({ textStr, field, placeholderSameAsText }) => {
	let {
		isNumberField,
		currencyFont,
		currencyStr,
		euroStr,
		centStr,
		formatedText,
		displayOrder,
	} = formatNumText({
		textStr,
		field,
		placeholderSameAsText,
	});
	formatedText = formatedText || textStr;

	if (field.allCaps) formatedText = formatedText.toUpperCase();

	if (field.smartQuotes) {
		// "smart quote". Replace double/single quote by left|right quotation mark
		// - Ref: https://unicodelookup.com/#quo
		// - &lsquo; left single quote (u2018). &rsquo; right single quote (u2019)
		// - &ldquo; left double quote (u201C). &rsquo; right double quote (u201D)

		// check if only one " in the text
		const isOnlyOneDQ = (formatedText.match(/"/gm) || []).length === 1;
		if (!isOnlyOneDQ) {
			// format left double quote
			// formatedText = formatedText.replace(/(?<=^)"|(?<=\s)"/gm, `\u201C`); // use Lookbehind regex
			formatedText = formatedText.replace(/(^|\s)"/gm, `$1\u201C`);
			// format right double quote
			// formatedText = formatedText.replace(/"(?=\s)|"(?=$)/gm, `\u201D`);
			formatedText = formatedText.replace(/"(?=[\W])|"(?=$)/gm, `\u201D`);
		}

		// format left single quote
		// formatedText = formatedText.replace(/(?<=^)'|(?<=\s)'/gm, `\u2018`); // regex with Lookbehind
		formatedText = formatedText.replace(/(^|\s)'/gm, `$1\u2018`);
		// format right single quote
		formatedText = formatedText.replace(/'(?=\s)|'(?=$)/gm, `\u2019`);
		// use close (right) single quote for single quote inside a word
		// formatedText = formatedText.replace(/(?<=\S)'(?=\S)/gm, `\u2019`); // regex with Lookbehind
		formatedText = formatedText.replace(/(\w)'(?=\S)/gm, `$1\u2019`);
	}
	// prepare the lines of text
	if (field.wrapLinesContainingSlash) {
		// multiple lines by Slash
		formatedText = formatedText.split(/\//g).join(' / '); // Add whitespace to before and after "/"
		// textLines = textLines.split(/(?=\/)/g);	// split by "/"" and but keep "/" in the splited parts
	}
	// we use array of text even for single line
	if (typeof formatedText === 'string') formatedText = [formatedText];

	const isSingleLine = field.inputStyle === 'text' || isNumberField;
	// multiple lines by line breaker. NOTE: when is number field, we treat it as single line
	if (!isSingleLine) {
		// the space on two sides may be useful, hence we don't trim it after breaking lines
		// formatedText = formatedText.map(line => line.split(/\n/).map(item => item.trim())).flat();
		formatedText = formatedText.map((line) => line.split(/\n/)).flat();
	}

	return {
		isSingleLine,
		isNumberField,
		currencyFont,
		currencyStr,
		euroStr,
		centStr,
		formatedText,
		displayOrder,
	};
};
/**
 *	Get justified attribute ('word-spacing' or 'letter-spacing') for tspan
 * @param {string} textVal
 * @param {object} opts {textAttrs: {}, tspanAttrs: {}, containerWidth: NUMBER, containerHeight: NUMBER}
 *
 * @return {object} returnData
 * {
 * 	{ attrName:  attrValue} // attrName is in html format
 * }
 */
const getJustifedAttr = (textVal, opts) => {
	let returnData = {};
	if (!textVal) {
		return returnData;
	}

	const svg = document.createElementNS(NS.SVG, 'svg');
	document.body.append(svg);

	// let fontBase64Urls = opts.fontNames
	// 	.filter(item => item)
	// 	.reduce((accu, item) => {
	// 		accu[item] = getFontfaceBase64(item || '');
	// 		return accu;
	// 	}, {});
	// if (Object.keys(fontBase64Urls).length > 0) {
	// 	const style = document.createElementNS(NS.SVG, 'style');
	// 	style.type = 'text/css';
	// 	style.innerHTML = Object.keys(fontBase64Urls)
	// 		.map(
	// 			fontName => `@font-face {
	// 			font-family: "${fontName}";
	// 			src: url("${fontBase64Urls[fontName].fontUrl}")${
	// 				getFontfaceFormatByFileNameExtersion(fontName)
	// 					? ' format("' + getFontfaceFormatByFileNameExtersion(fontName) + '")'
	// 					: ''
	// 			};
	// 		}`
	// 		)
	// 		.join(`\n`);
	// 	svg.append(style);
	// }

	const text = document.createElementNS(NS.SVG, 'text');
	for (let key in opts.textAttrs) {
		// set attributes to text element
		text.setAttribute(key, opts.textAttrs[key]);
	}

	const tspan = document.createElementNS(NS.SVG, 'tspan');
	tspan.textContent = textVal;
	for (let key in opts.tspanAttrs) {
		// set attributes to tspan element
		tspan.setAttribute(key, opts.tspanAttrs[key]);
	}
	text.appendChild(tspan);
	svg.append(text);

	let spacingAttr = textVal.split(' ').length > 1 ? 'word-spacing' : 'letter-spacing';
	// text.setAttribute('font-size', `${opts.fontSize}pt`);

	// Binary Search (to upperBound) to find best spacing
	let low = 0.1,
		high = opts.containerWidth * 2; // the biggest spacing can't be bigger than container's width
	let mid = (low + high) / 2;
	while (low + 1 < high) {
		tspan.setAttribute(spacingAttr, mid);

		let bb = text.getBBox();
		if (bb.width > opts.containerWidth) {
			high = mid;
		} else {
			low = mid + 0.2;
		}
		mid = (low + high) / 2;
	}
	returnData = {
		[spacingAttr]: mid,
	};

	svg.remove();
	return returnData;
};

/**
 * Prepare tspan for text field with format number enabled
 *
 * @return {array}. [tspan_DOM_currency, tspan_DOM_euro, tspan_DOM_cent]
 */
const createTspanDOMOfNumberText = ({
	currencyStr,
	euroStr,
	centStr,
	currencyFont,
	formatNumberStyle,
	fieldVerAlign,
	tspanX,
	tspanAttrs,
}) => {
	return ['currency', 'euro', 'cent'].map((item) => {
		const tspan = document.createElementNS(NS.SVG, 'tspan');
		for (let key in tspanAttrs) {
			// set attributes to tspan element
			tspan.setAttribute(key, tspanAttrs[key]);
		}
		switch (item) {
			case 'currency':
				if (!currencyStr) return null;
				if (formatNumberStyle.currencyPosition === 'leading' && euroStr) {
					tspan.setAttribute('x', tspanX);
				}
				tspan.setAttribute(
					'baseline-shift',

					getFormatNumBaselineShift(
						fieldVerAlign,
						formatNumberStyle.currencyVerticalAlign,
						formatNumberStyle.currencyFontScale
					)
				);
				if (currencyFont)
					tspan.setAttribute('style', `font-family:"${cleanupFontName(currencyFont)}";`);
				tspan.setAttribute('font-size', formatNumberStyle.currencyFontScale + 'em');
				tspan.textContent = currencyStr;
				break;
			case 'euro':
				if (!euroStr) return null;
				if (formatNumberStyle.currencyPosition !== 'leading') {
					tspan.setAttribute('x', tspanX);
				}
				tspan.textContent = euroStr;
				break;
			case 'cent':
				if (!centStr) return null;
				if (!euroStr) {
					tspan.setAttribute('x', tspanX);
				}
				tspan.setAttribute(
					'baseline-shift',

					getFormatNumBaselineShift(
						fieldVerAlign,
						formatNumberStyle.centVerticalAlign,
						!euroStr ? 1 : formatNumberStyle.centFontScale // when there is only cent & currency, we ignore cent font scale and display cent at original size (100%)
					)
				);
				tspan.setAttribute('font-size', (!euroStr ? 1 : formatNumberStyle.centFontScale) + 'em');
				tspan.textContent = centStr;
				break;
			default:
				break;
		}
		return tspan;
	});
};

/**
 * Respect font size but wrap text
 * @param {array} paragraphs text to wrap. It could have a few lines
 * @param {object} opts {textAttrs: {}, tspanAttrs: {}, containerWidth: NUMBER, containerHeight: NUMBER}
 *
 * @return {object} returnData
 * {
 *  lines: Array of each line string
 * }
 */
const wrapText = (paragraphs = [], opts) => {
	let returnData = {
		lines: paragraphs,
	};
	if (!Array.isArray(paragraphs) || paragraphs.length < 1) {
		return returnData;
	}

	const svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('viewBox', `0 0 ${opts.containerWidth} ${opts.containerHeight}`);
	svg.setAttribute('width', opts.containerWidth);
	svg.setAttribute('height', opts.containerHeight);
	document.body.append(svg);

	const text = document.createElementNS(NS.SVG, 'text');
	for (let key in opts.textAttrs) {
		// set attributes to text element
		text.setAttribute(key, opts.textAttrs[key]);
	}
	svg.append(text);

	const isStringFitContainerWidth = (val) => {
		// clear text in case it has any child
		text.innerHTML = '';
		const tspan = document.createElementNS(NS.SVG, 'tspan');
		tspan.textContent = val || '\u200B';
		for (let key in opts.tspanAttrs) {
			// set attributes to tspan element
			tspan.setAttribute(key, opts.tspanAttrs[key]);
		}
		text.appendChild(tspan);

		const bb = text.getBBox();
		return bb.width <= opts.containerWidth;
	};

	const lines = [];
	paragraphs.forEach((lineTextStr) => {
		// split text by white-spaces. then remove empty str. NB: no space in each "word" after using this regex
		let words = lineTextStr.split(/\s+/gm).filter((w) => Boolean(w.trim()));
		let lineStr = '';

		words.forEach((word) => {
			if (!isStringFitContainerWidth(lineStr === '' ? word : `${lineStr} ${word}`)) {
				// the size with the new word is too big for current line, let's finish up the current line, and insert the "word" to next line.
				if (lineStr === '') {
					// we don't want empty line, so we will add the new word (which is the only word) to this line
					lines.push(word);
				} else {
					lines.push(lineStr);
					lineStr = '';
				}
			}
			// add new word to line string
			lineStr = lineStr === '' ? word : `${lineStr} ${word}`;
		});
		// all words in this paragragh are processed, let's add the last line string (if has value) to current line (before next paragraph)
		if (lineStr) lines.push(lineStr);
	});

	svg.remove();
	returnData.lines = lines;
	return returnData;
};

/**
 * Get font size and wrapped lines
 * @param {array} paragraphs
 * @param {object} opts {textAttrs: {}, tspanAttrs: {}, lineHeight: NUMBER, isSingleLine: BOOL, containerWidth: NUMBER, containerHeight: NUMBER}
 *
 * @return {object} returnData
 * {
 * 	fontSize: NUMBER,
 *  lines: Array of string
 * }
 */
const getFontsizeToFitTextareaWithAutoWrap = (paragraphs = [], opts) => {
	let returnData = {
		fontSize: parseInt(opts.textAttrs['font-size']) || DEFAULT_FONTSIZE,
		lines: paragraphs,
	};
	if (!Array.isArray(paragraphs) || paragraphs.length < 1) {
		return returnData;
	}

	const svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('viewBox', `0 0 ${opts.containerWidth} ${opts.containerHeight}`);
	svg.setAttribute('width', opts.containerWidth);
	svg.setAttribute('height', opts.containerHeight);
	document.body.append(svg);

	const text = document.createElementNS(NS.SVG, 'text');
	for (let key in opts.textAttrs) {
		// set attributes to text element
		text.setAttribute(key, opts.textAttrs[key]);
	}
	svg.append(text);

	const appendLinesToText = (lines) => {
		// clear text in case it has any child
		text.innerHTML = '';
		lines.forEach((lineStr, idx) => {
			const tspan = document.createElementNS(NS.SVG, 'tspan');
			tspan.textContent = lineStr || '\u200B';
			for (let key in opts.tspanAttrs) {
				// set attributes to tspan element
				tspan.setAttribute(key, opts.tspanAttrs[key]);
			}
			tspan.setAttribute('x', `0`);
			tspan.setAttribute('dy', `${idx === 0 ? 0 : opts.lineHeight}em`);
			text.appendChild(tspan);
		});
	};

	// const testStr = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"!£$%&*^()_+~@:?><|\`-=[]#';/.,\\1234567890`;
	const buildLines = (testFontSize) => {
		// if it is single line text, we don't break paragraph into lines
		if (opts.isSingleLine) return paragraphs.map((item) => item.trim());
		// clear text in case it has any child
		text.innerHTML = '';
		const tspanToBuildLines = document.createElementNS(NS.SVG, 'tspan');
		// tspan.textContent = testStr;
		for (let key in opts.tspanAttrs) {
			// set attributes to tspan element
			tspanToBuildLines.setAttribute(key, opts.tspanAttrs[key]);
		}
		text.appendChild(tspanToBuildLines);
		text.setAttribute('font-size', `${testFontSize}pt`);

		let bb = text.getBBox();
		// let letterWidth = bb.width / testStr.length;

		let current_line = -1,
			lines = [];

		paragraphs.forEach((paragraph) => {
			lines.push(''); // each paragraph starts a new line
			current_line++;
			let words = paragraph.split(' ');
			// each word in this paragraph
			words.forEach((word) => {
				// NOTE: WE DON'T BREAK LONG WORD (on 11/09/2020).
				// Following code was tried to break long word.
				// to check if the width of the one word is more than the container width
				// tspanToBuildLines.textContent = word;
				// bb = text.getBBox();
				// if (bb.width > opts.containerWidth) {
				// 	// the word itself is longer than container width.
				// 	// Break the word to max 2 lines, append some part of the word to this line, the rest goes to next line (no matter how long the rest is)
				// 	for (let i = 1; i < word.length; i++) {
				// 		tspanToBuildLines.textContent = lines[current_line] + word.substring(0, i);
				// 		bb = text.getBBox();
				// 		if (bb.width > opts.containerWidth) {
				// 			lines[current_line] += word.substring(0, i - 1);
				// 			lines.push(word.substring(i - 1) + ' '); // put the rest of the word to next line
				// 			current_line++;
				// 			break;
				// 		}
				// 	}
				// } else {
				let testCurrentLineStr = lines[current_line] + word; // To test if "the string in current line plus the next word" fit the container width
				tspanToBuildLines.textContent = testCurrentLineStr || '\u200B';

				bb = text.getBBox();
				if (bb.width > opts.containerWidth) {
					// the current line + next word is out of bound, the word needs go to next line
					lines.push('');
					current_line++;
				}
				lines[current_line] += word + ' ';
			});
		});
		return lines.map((line) => line.trim());
	};
	// bBox is the text.getBBox(). If true, the text is fitting to the container, otherwise not.
	const isFittedToBox = (bBox) => {
		if (opts.isSingleLine) {
			// In signleLine style, we only check the width for fitting
			return bBox.width <= opts.containerWidth;
		} else {
			return !(bBox.width > opts.containerWidth || bBox.height > opts.containerHeight);
		}
	};

	// Binary Search (to upperBound) to find best font-size
	let lowFontSize = 1, // we use "lowFontSize + 1" as the min font size (1pt font size difference can be ignored)
		// The user defined font size is the max font size. The highFontSize is "max - 1" because the lowFontSize is 1, this way will gurantee the final value is never higher than the max
		highFontSize = parseInt(opts.textAttrs['font-size']) - 1;
	let midFontSize = (lowFontSize + highFontSize) / 2;
	let builtLines = [];

	// check if the min font size works
	builtLines = buildLines(lowFontSize + 1);
	appendLinesToText(builtLines);
	text.setAttribute('font-size', `${lowFontSize + 1}pt`);

	let bb = text.getBBox();
	// if (bb.width > opts.containerWidth || bb.height > opts.containerHeight) {
	if (!isFittedToBox(bb)) {
		// the minFont size won't fit, we will use the min fontsize anyway
		returnData.fontSize = lowFontSize + 1;
		returnData.lines = builtLines;
	} else {
		// the min font size fits, let's find out the best font size to fit
		while (lowFontSize + 1 < highFontSize) {
			builtLines = buildLines(midFontSize);
			appendLinesToText(builtLines);
			text.setAttribute('font-size', `${midFontSize}pt`);

			let bb = text.getBBox();
			// if (bb.width > opts.containerWidth || bb.height > opts.containerHeight) {
			if (!isFittedToBox(bb)) {
				// not fitted, we continue
				highFontSize = midFontSize;
			} else {
				// fitted, we update the found font-size (so far), then continue search for better font-size
				lowFontSize = midFontSize + 1;
				returnData.fontSize = midFontSize;
				returnData.lines = builtLines;
			}
			midFontSize = (lowFontSize + highFontSize) / 2;
		}
	}

	svg.remove();
	return returnData;
};

/**
 * Font Scale to baselise-shift map to format number text field.
 * (The magic number is kind of try-trail result. We don't have a fomular to really calculate it [or is it possible to find that fomular??] because different font has different baseline)
 */
const formatNumBaselineShiftNewMap = {
	5: 1100,
	10: 580,
	15: 350,
	20: 250,
	25: 190,
	30: 145,
	35: 115,
	40: 90,
	45: 80,
	50: 55,
	55: 45,
	60: 37,
	65: 30,
	70: 23,
	75: 19,
	80: 14,
	85: 11,
	90: 6,
	95: 4,
	100: 0,
};

/**
 * get baseline-shift for the number in number text field
 * @param {String} fieldVerAlign Vertical alignment of the field
 * @param {String} numVerAlign Vertical alignment of the number
 * @param {Number} numScale number percentage of font scale. e.g. 0.1, 0.5.
 */
export const getFormatNumBaselineShift = (fieldVerAlign, numVerAlign, numScale) => {
	switch (fieldVerAlign) {
		// case 'top':
		// 	return numVerAlign === 'top'
		// 		? 0
		// 		: numVerAlign === 'middle'
		// 		? -(formatNumBaselineShiftNewMap[roundDecimals(numScale * 100)] || 0) / 2 + '%'
		// 		: -(formatNumBaselineShiftNewMap[roundDecimals(numScale * 100)] || 0) + '%';
		case 'middle':
			return numVerAlign === 'top'
				? (formatNumBaselineShiftNewMap[roundDecimals(numScale * 100)] || 0) / 2 + '%'
				: numVerAlign === 'middle'
				? 0
				: -(formatNumBaselineShiftNewMap[roundDecimals(numScale * 100)] || 0) / 2 + '%';
		case 'top': // dominant-baseline="alphabetic" when field verAlign is "top", hence baselineShift is same as bottom
		case 'bottom':
			return numVerAlign === 'top'
				? (formatNumBaselineShiftNewMap[roundDecimals(numScale * 100)] || 0) + '%'
				: numVerAlign === 'middle'
				? (formatNumBaselineShiftNewMap[roundDecimals(numScale * 100)] || 0) / 2 + '%'
				: 0;
		default:
			return 0;
	}
};

/**
 * Prepare text field for
 * 	- preview(react) [return Object]
 * 	- print (export to pdf) [return Promise]
 * 	- svg (export to svg) [return Promise]
 * 	- ARTWORK_SERVER_SIDE_PROCESS (create svg with image for shadow) [return Promise]
 * 		-  optional: create raw svg with filter for shadow
 *
 * This function is to keep the logic of text field preparation in one place so that you don't need to change the same code in multiple places
 * NOTE: keep in mind the render part is still different in React & pdf generation.
 * @param {Object} opts
 * {
 * 		mode: 'preview'|'print'|'svg'|ARTWORK_SERVER_SIDE_PROCESS,
 * 		inputData: {	// data for output. Combination of user input data and field default data
 * 			value: 'xxxx' | undefined,
 * 			fontsize: NUMBER | undefined,
 * 			horizontalAlign: 'left' | 'center' | 'right' | 'justified' | undefined,
 * 			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
 * 		},
 * 		field: Object, // the text field to be prepared
//  * 		dataForPrint:{
//   			rootSVG: DOM element, // Mandatary in "print" mode
// 				fontList: Array,	// [{name: 'xxx', fontUrl: 'xxxx'}, ...]. Mandatary in "print" mode
// 			},
			dataForExport: {
				rootSVG: DOM element, // Mandatary
				isPrintable: Boolean, // Mandatary
				fontList: Array,	// [{name: 'xxx', fontUrl: 'xxxx'}, ...]. Mandatary
				animationDelay: NUMBER, // Mandatary in 'svg' & ARTWORK_SERVER_SIDE_PROCESS mode
			}
			serverSideProcess: { // mandatary for ARTWORK_SERVER_SIDE_PROCESS mode
				rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
				s3Client: s3Client, // mandatary
				s3Params: {					// mandatary
					bucket: 'xxx',
					filePrefix: 'xyz/xyz/xyz/'
				},
			}
 * 		CONSTANTS: {
 * 			placeholderSameAsText: ART_VARIABLES.placeholderSameAsText, // Mandatary
 * 			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION	// Mandatary only in 'svg' mode
 * 			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY	// Mandatary only in 'svg' mode
 * 		}
 * }
 *
 * @return {Object|Promise|null}.
 */
export const prepareTextField = ({
	mode,
	inputData = {},
	field,
	dataForExport = {},
	serverSideProcess = {},
	CONSTANTS,
}) => {
	if (
		(mode === 'print' || mode === 'svg') &&
		(!dataForExport.rootSVG || !dataForExport.fontList || isNullish(dataForExport.isPrintable))
	)
		return null;
	//TODO: validate dataForExport.animationDelay
	if (mode === 'svg' && isNaN(dataForExport.animationDelay)) return null;
	if (
		mode === ARTWORK_SERVER_SIDE_PROCESS &&
		(!dataForExport.rootSVG ||
			!dataForExport.fontList ||
			isNaN(dataForExport.animationDelay) ||
			!serverSideProcess.s3Client ||
			!serverSideProcess.s3Params)
	)
		return null;
	// if (!rootSVG) return false;
	// const {mode, rootSVG,inputData,animationDelay,field,fields,CONSTANTS} = opts;
	// const field = opts.field;
	const horAlign = inputData.horizontalAlign; // || field.textHorizontalAlign;
	const verAlign = inputData.verticalAlign; // || field.textVerticalAlign;
	let _fontSize = inputData.fontsize; // || field.fontsize;
	let originalText = inputData.value.trim(); // (inputData.value || field.defaultValue /* || field.name */ || '').trim();
	if (!originalText) return null; // no text string, nothing to display
	// console.debug(`Render text field - ${field.name}`);
	// let textLines = originalText;

	// format text value
	let {
		isSingleLine,
		isNumberField,
		currencyFont,
		currencyStr,
		euroStr,
		centStr,
		formatedText, // this is array of lines of text
	} = formatTextFieldValue({
		textStr: originalText,
		field: field,
		placeholderSameAsText: CONSTANTS.placeholderSameAsText,
	});
	// Validate font list
	const _fontNames =
		isNumberField && currencyFont ? [currencyFont, field.fontfaceName] : [field.fontfaceName];
	const requiredFontList = (dataForExport.fontList || []).filter((artFont) =>
		_fontNames.includes(artFont.name)
	);
	if (
		(mode === 'print' || mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		_fontNames.length !== requiredFontList.length
	)
		throw new Error(`Missing font "${_fontNames.join(', ')}" in field "${field.name}"`);
	// return null; // font missing for pdf generation or svg exporting

	// Alignment.
	let alignmentAttrs = getAlignmentAttrs({ horAlign, verAlign, field, isNumberField });

	let textAttrs = {
			style: `font-family: "${cleanupFontName(field.fontfaceName)}";`,
			// 'font-family': field.fontfaceName,	// somehow, it doesn't work in chrome|edge when putting font-family in attribute
			'font-size': `${_fontSize}pt`,
			fill: field.fontColor.hex,
			...alignmentAttrs,
		},
		tspanAttrs = { 'letter-spacing': field.letterSpacing },
		lineHeight = field.leadingLineHeight,
		hasShadow = field.shadowHorOffset || field.shadowVerOffset || field.shadowBlurRadius,
		tspanArray = []; // format: [{value: TSPAN_STRING, attrs: OBJECT OF Attributes of this tspan}]

	if (!field.doNotSetToFit) {
		// fit text to the container box
		let fittedData = getFontsizeToFitTextareaWithAutoWrap(formatedText, {
			textAttrs,
			tspanAttrs,
			lineHeight,
			isSingleLine,
			containerWidth: field.position.width,
			containerHeight: field.position.height,
		});

		formatedText = fittedData.lines || formatedText; // use the fitted lines
		textAttrs['font-size'] = `${fittedData.fontSize}pt`;
	} else if (field.doNotSetToFit && field.inputStyle === 'textarea') {
		// in this case, we respect the fontsize but wrap the text
		let fittedData = wrapText(formatedText, {
			textAttrs,
			tspanAttrs,
			containerWidth: field.position.width,
			containerHeight: field.position.height,
		});

		formatedText = fittedData.lines || formatedText; // use the fitted lines
	}
	tspanArray = formatedText.map((textStr) => {
		let justifiedAttr =
			horAlign === 'justified'
				? getJustifedAttr(textStr, {
						textAttrs,
						tspanAttrs,
						containerWidth: field.position.width,
						containerHeight: field.position.height,
				  })
				: {};
		return {
			value: textStr,
			attrs: { ...tspanAttrs, ...justifiedAttr },
		};
	});

	// vertical shift of first line of <tspan> (used when formatNumber is false)
	let dyFirstLine = 0; // Number in em
	if (verAlign === 'top') {
		dyFirstLine = dyForAlphabeticOnTopVerAlign; //1; // we use alphabetic for non-number text field when verAlign is top, so we need to lower it down by 1em
	} else if (verAlign === 'middle') {
		dyFirstLine = -0.5 * (formatedText.length - 1) * lineHeight;
	} else if (verAlign === 'bottom') {
		dyFirstLine = -1 * (formatedText.length - 1) * lineHeight;
	}

	// create svg of this text field
	const svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('viewBox', `0 0 ${field.position.width} ${field.position.height}`);
	svg.setAttribute('x', field.position.left);
	svg.setAttribute('y', field.position.top);
	svg.setAttribute('width', field.position.width);
	svg.setAttribute('height', field.position.height);
	svg.setAttribute('overflow', 'visible');

	// append svg to document so that getBBox() works. it must be removed before return
	document.body.append(svg);
	// // In "preview" mode, svg must be removed before return data
	// if (mode === 'preview') document.body.append(svg);
	// else if (mode === 'print' || mode === 'svg') dataForExport.rootSVG.append(svg);

	const createTextElement = ({ textAttributes, tspanItems }) => {
		const text = document.createElementNS(NS.SVG, 'text');
		for (let key in textAttributes) {
			// set attributes to text element
			text.setAttribute(key, textAttributes[key]);
		}

		tspanItems.forEach((tspanItem, idx) => {
			if (!isNumberField) {
				// NOTE. We can't use zero-width unicode (\u200B) for empty line as it will be a dot in the generated PDF
				if (!tspanItem.value) return null;
				const tspan = document.createElementNS(NS.SVG, 'tspan');
				tspan.textContent = tspanItem.value; // || '\u200B';
				for (let key in tspanItem.attrs) {
					// set attributes to tspan element
					tspan.setAttribute(key, tspanItem.attrs[key]);
				}
				tspan.setAttribute('x', textAttributes.x);
				tspan.setAttribute(
					'dy',
					idx === 0
						? `${dyFirstLine}em`
						: `${
								lineHeight *
								tspanItems.reduce((accu, item, index) => {
									if (index < idx && item.value) {
										accu = 1;
									}
									if (index < idx && !item.value) {
										accu = accu + 1;
									}
									return accu;
								}, 1)
						  }em`
				);
				text.appendChild(tspan);
			} else {
				// numberTspans DOM element of ['currency', 'euro', 'cent']
				let numberTspans = createTspanDOMOfNumberText({
					currencyStr,
					euroStr,
					centStr,
					currencyFont,
					formatNumberStyle: field.formatNumberStyle,
					fieldVerAlign: verAlign,
					tspanX: textAttributes.x,
					tspanAttrs: tspanItem.attrs,
				});

				if (!euroStr) {
					// only cent & currency (cent symbol is alway behind the cent number)
					if (verAlign === 'top')
						numberTspans[2].setAttribute(
							'dy',
							`${1 /* / field.formatNumberStyle.centFontScale */ * dyForAlphabeticOnTopVerAlign}em` // when there is only cent & currency, we ignore cent font scale and display cent at original size (100%)
						);
					if (numberTspans[2]) text.appendChild(numberTspans[2]);
					if (numberTspans[0]) text.appendChild(numberTspans[0]);
					// text.append(numberTspans[2], numberTspans[0]);
				} else {
					// number field has currency, euro & cent
					if (field.formatNumberStyle.currencyPosition === 'leading') {
						if (verAlign === 'top')
							numberTspans[0].setAttribute(
								'dy',
								`${
									(1 / field.formatNumberStyle.currencyFontScale) * dyForAlphabeticOnTopVerAlign
								}em`
							);
						if (numberTspans[0]) text.appendChild(numberTspans[0]);
						if (numberTspans[1]) text.appendChild(numberTspans[1]);
						if (numberTspans[2]) text.appendChild(numberTspans[2]);
						// text.append(numberTspans[0], numberTspans[1], numberTspans[2]);
					} else {
						if (verAlign === 'top')
							numberTspans[1].setAttribute('dy', `${dyForAlphabeticOnTopVerAlign}em`);
						if (numberTspans[1]) text.appendChild(numberTspans[1]);
						if (numberTspans[2]) text.appendChild(numberTspans[2]);
						if (numberTspans[0]) text.appendChild(numberTspans[0]);
						// text.append(numberTspans[1], numberTspans[2], numberTspans[0]);
					}
				}
			}
		});
		return text;
	};

	let text = createTextElement({ textAttributes: textAttrs, tspanItems: tspanArray });

	// in case "fitting to container" is required AND there is currency scale or cent scale, we will calculate the best scale to fit
	// NOTE: following is only to calculate the scale when formatNumber is enabled with currency or cent scaled down.
	// 				Text fitting is already done above by finding fitted font-size
	let textScale = 1;
	if (
		!field.doNotSetToFit &&
		field.formatNumberStyle?.centFontScale &&
		field.formatNumberStyle?.currencyFontScale &&
		(field.formatNumberStyle.centFontScale !== 1 || field.formatNumberStyle.currencyFontScale !== 1)
	) {
		textScale = scaleUpToFitTextToContainer({
			text,
			maxScale:
				0.1 + // add 0.1 to ensure it can get best suitable scale
				1 /
					Math.min(
						field.formatNumberStyle.centFontScale,
						field.formatNumberStyle.currencyFontScale
					),
			minScale: 1,
			containerWidth: field.position.width,
			containerHeight: field.position.height,
		});

		// when scale is not 1, we re-calculate alignment attrs and create a new <text>
		alignmentAttrs = getAlignmentAttrs({
			horAlign,
			verAlign,
			field,
			isNumberField,
			scale: textScale,
		});
		textAttrs = { ...textAttrs, ...alignmentAttrs };
		text = createTextElement({ textAttributes: textAttrs, tspanItems: tspanArray });
	}

	// clone the <text> for its BBox and shadow image creation (if applicable)
	let textClone = text.cloneNode(true); // NB: textClone is appended directly to root <svg>, not to <g> gContent, and it is removed after creating shadow image

	textClone.setAttribute('transform', `scale(${textScale})`); // NB: the textScale is the scale only for number text field
	svg.append(textClone);

	// bounding box of <text> & its children <tspan>
	let bBox = {};

	let bb = textClone.getBBox();
	// let textX = Number(text.getAttribute('x'));
	// let textY = Number(text.getAttribute('y'));
	// let yOffset =
	bBox.textBBox = { x: bb.x, y: bb.y, width: bb.width, height: bb.height }; // used for creating shadow image for printing
	bBox.tspansBBox = []; // used for strikeThrough
	textClone.childNodes.forEach((tspan) => {
		let tspanBB = tspan.getBBox();
		bBox.tspansBBox.push({
			x: tspanBB.x,
			y: tspanBB.y,
			width: tspanBB.width,
			height: tspanBB.height,
		});
	});

	// (For printable ONLY) Shadow filter is not supported by PDFKIT, the work around is to create shadow image, and insert it before <text>
	// When creating shadow image, to prevent the shadow filter gets cut off, we extent filter region by _NUMBER(px) in both hor & ver direction
	const shadowImgFilterOffset = 50;

	// remove svg from document
	svg.remove();
	if (mode === 'preview') {
		// Enough data for preview.
		return {
			// isSingleLine,
			isNumberField,
			currencyFont: cleanupFontName(currencyFont),
			currencyStr,
			euroStr,
			centStr,
			// formatedText, // this is array of lines of text
			verAlign, // vertical alignment of the text field
			textAttrs, // attributes of <text>
			textScale, // scale of <text>, apply it to its parent <g> gContent
			tspanArray, // array of <tspan>. [{value: tspanString, attrs: { ...tspanAttrs }}, ...]
			alignmentAttrs, // alignment attributes of <text>. {x: NUMBER, y: NUMBER, 'text-anchor': 'xxx', 'dominant-baseline': 'xxxx'}
			dyFirstLine, // first line (<tspan>) vertical shift (used when formatNumber is false)
			lineHeight, // user desinged line height, in 'em'
			bBox, // bounding box of text and children tspan inside text
			hasShadow, // indicate the field has shadow or not
		};
	} else if (mode === 'svg') {
		// append svg to rootSVG
		dataForExport.rootSVG.append(svg);
		// return promise for exporting svg
		return Promise.resolve().then(async () => {
			// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
			const gAnimation = document.createElementNS(NS.SVG, 'g');
			const noAnimationStyle = `0s ease 0s 1 normal none running none`;
			const animationEntranceStyle = field.animation.entrance
				? `${field.animation.entrance} ${
						field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
				  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
						dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
				  }s both`
				: noAnimationStyle;
			gAnimation.setAttribute(
				'style',
				`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
			);
			svg.append(gAnimation);

			// <g> for content
			const g = document.createElementNS(NS.SVG, 'g');
			g.setAttribute(
				'transform',
				`rotate(${field.position.angle}, ${field.position.width / 2}, ${
					field.position.height / 2
				}) scale(${textScale})`
			);
			gAnimation.append(g);
			if (hasShadow) {
				if (dataForExport.isPrintable) {
					// In most cases, dataForExport.isPrintable is true,
					// the only case dataForExport.isPrintable is false is when the template has "video" field, in that case the exported SVG will use filter for shadow
					// when the template has "video" field, it is supposed NOT to be used for printing at all.
					// filter shadow is not supported by PDFKIT, the work around is to create shadow image, and insert it before <text>
					// To prevent the filter shadow gets cut off, we extent filter region by _NUMBER(px) in both hor & ver direction
					let shadowImgDataUrl = await createTextShadowImageDataUrl({
						text: textClone,
						textBBox: bBox.textBBox,
						fontList: requiredFontList,
						shadowBlurRadius: field.shadowBlurRadius || 0,
						shadowColor: field.textShadowColor.hex,
						highRes: true,
						filterOffset: shadowImgFilterOffset,
					});
					const textShadowImg = document.createElementNS(NS.SVG, 'image');
					textShadowImg.setAttribute('href', shadowImgDataUrl);
					// the shadow image is positioned by original text bbox + shadowImgFilterOffset
					textShadowImg.setAttribute(
						'x',

						bBox.textBBox.x - shadowImgFilterOffset + (field.shadowHorOffset || 0) * textScale
					);
					textShadowImg.setAttribute(
						'y',

						bBox.textBBox.y - shadowImgFilterOffset + (field.shadowVerOffset || 0) * textScale
					);
					textShadowImg.setAttribute('width', bBox.textBBox.width + shadowImgFilterOffset * 2);
					textShadowImg.setAttribute('height', bBox.textBBox.height + shadowImgFilterOffset * 2);
					g.append(textShadowImg);
				} else {
					// The only case the code reaches here is when the template has "video" field.
					// With "video" field, the exported svg is not for printing at all, will only be used in web browser
					// To prevent the filter shadow gets cut off, we extent filter region by 100% in both hor & ver direction
					const filter = document.createElementNS(NS.SVG, 'filter');
					filter.setAttribute('x', '-50%');
					filter.setAttribute('y', '-50%');
					filter.setAttribute('width', '200%');
					filter.setAttribute('height', '200%');
					filter.setAttribute('filterUnits', 'userSpaceOnUse');
					filter.setAttribute('id', 'shadow-' + field.id);

					filter.innerHTML = `
									<feGaussianBlur stdDeviation="${field.shadowBlurRadius || 0}" result="shadow" />
									<feOffset dx="${field.shadowHorOffset || 0}" dy="${field.shadowVerOffset || 0}" />
								`;
					svg.insertBefore(filter, svg.firstChild);
					// svg.append(filter);
					let textShadowClone = text.cloneNode(true);

					textShadowClone.style.filter = `url(#${'shadow-' + field.id})`;

					textShadowClone.style.fill = field.textShadowColor.hex;
					// append shadow <text>
					g.append(textShadowClone);
				}
			}
			// remove textClone, and append the actual <text> to <g>

			textClone.remove();
			textClone = null; // free memory. is it necessary?
			g.append(text);

			// following code is same as "print" mode on 25/09/2020
			const strikeThroughLineRotateFactor = 0.5;
			if (field.strikeThrough) {
				let offsetX = 5,
					lineBoxes = [];

				if (isNumberField) {
					// is number text field
					lineBoxes.push(bBox.textBBox);
				} else {
					lineBoxes = bBox.tspansBBox;
				}
				lineBoxes.forEach((bBox) => {
					let radRotate = Math.atan2(bBox.height, bBox.width),
						degRotate = (strikeThroughLineRotateFactor * (radRotate * 180)) / Math.PI;
					let lineLength =
						field.strikeThroughStyle.type === 'strike'
							? bBox.width
							: bBox.width / Math.cos(strikeThroughLineRotateFactor * radRotate);
					const strikeLine = document.createElementNS(NS.SVG, 'line');

					strikeLine.setAttribute('x1', bBox.x - offsetX);
					strikeLine.setAttribute('y1', bBox.y + bBox.height / 2);
					strikeLine.setAttribute('x2', bBox.x + lineLength + offsetX);
					strikeLine.setAttribute('y2', bBox.y + bBox.height / 2);
					strikeLine.setAttribute('stroke', field.fontColor?.hex || '#000000');
					strikeLine.setAttribute('stroke-width', field.strikeThroughStyle.lineWidth);
					strikeLine.setAttribute(
						'transform',
						`rotate(${
							field.strikeThroughStyle.type === 'strikedown'
								? degRotate //15
								: field.strikeThroughStyle.type === 'strikeup'
								? -1 * degRotate //-15
								: 0
						}, ${bBox.x + bBox.width / 2}, ${bBox.y + bBox.height / 2})`
					);
					g.append(strikeLine);
				});
			}
		});
	} else if (mode === 'print') {
		dataForExport.rootSVG.append(svg);
		// return promise for pdf generation
		return Promise.resolve().then(async () => {
			const g = document.createElementNS(NS.SVG, 'g');
			g.setAttribute(
				'transform',
				`rotate(${field.position.angle}, ${field.position.width / 2}, ${
					field.position.height / 2
				}) scale(${textScale})`
			);
			svg.append(g);
			if (hasShadow) {
				// filter shadow is not supported by PDFKIT, the work around is to create shadow image, and insert it before <text>
				// To prevent the filter shadow gets cut off, we extent filter region by _NUMBER(px) in both hor & ver direction
				let shadowImgDataUrl = await createTextShadowImageDataUrl({
					text: textClone,
					textBBox: bBox.textBBox,
					fontList: requiredFontList,
					shadowBlurRadius: field.shadowBlurRadius || 0,
					shadowColor: field.textShadowColor.hex,
					highRes: true,
					filterOffset: shadowImgFilterOffset,
				});
				const textShadowImg = document.createElementNS(NS.SVG, 'image');
				textShadowImg.setAttribute('href', shadowImgDataUrl);
				// the shadow image is positioned by original text bbox + shadowImgFilterOffset
				textShadowImg.setAttribute(
					'x',

					bBox.textBBox.x - shadowImgFilterOffset + (field.shadowHorOffset || 0) * textScale
				);
				textShadowImg.setAttribute(
					'y',

					bBox.textBBox.y - shadowImgFilterOffset + (field.shadowVerOffset || 0) * textScale
				);
				textShadowImg.setAttribute('width', bBox.textBBox.width + shadowImgFilterOffset * 2);
				textShadowImg.setAttribute('height', bBox.textBBox.height + shadowImgFilterOffset * 2);
				g.append(textShadowImg);
			}
			// remove textClone, and append the actual <text> to <g>

			textClone.remove();
			textClone = null; // free memory. is it necessary?
			g.append(text);

			const strikeThroughLineRotateFactor = 0.5;
			if (field.strikeThrough) {
				let offsetX = 5,
					lineBoxes = [];

				if (isNumberField) {
					// is number text field
					lineBoxes.push(bBox.textBBox);
				} else {
					lineBoxes = bBox.tspansBBox;
				}
				lineBoxes.forEach((bBox) => {
					let radRotate = Math.atan2(bBox.height, bBox.width),
						degRotate = (strikeThroughLineRotateFactor * (radRotate * 180)) / Math.PI;
					let lineLength =
						field.strikeThroughStyle.type === 'strike'
							? bBox.width
							: bBox.width / Math.cos(strikeThroughLineRotateFactor * radRotate);
					const strikeLine = document.createElementNS(NS.SVG, 'line');

					strikeLine.setAttribute('x1', bBox.x - offsetX);
					strikeLine.setAttribute('y1', bBox.y + bBox.height / 2);
					strikeLine.setAttribute('x2', bBox.x + lineLength + offsetX);
					strikeLine.setAttribute('y2', bBox.y + bBox.height / 2);
					strikeLine.setAttribute('stroke', field.fontColor?.hex || '#000000');
					strikeLine.setAttribute('stroke-width', field.strikeThroughStyle.lineWidth);
					strikeLine.setAttribute(
						'transform',
						`rotate(${
							field.strikeThroughStyle.type === 'strikedown'
								? degRotate //15
								: field.strikeThroughStyle.type === 'strikeup'
								? -1 * degRotate //-15
								: 0
						}, ${bBox.x + bBox.width / 2}, ${bBox.y + bBox.height / 2})`
					);
					g.append(strikeLine);
				});
			}
		});
	} else if (mode === ARTWORK_SERVER_SIDE_PROCESS) {
		// append svg to rootSVG
		dataForExport.rootSVG.append(svg);
		// return promise
		return Promise.resolve().then(async () => {
			// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
			const gAnimation = document.createElementNS(NS.SVG, 'g');
			const noAnimationStyle = `0s ease 0s 1 normal none running none`;
			const animationEntranceStyle = field.animation.entrance
				? `${field.animation.entrance} ${
						field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
				  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
						dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
				  }s both`
				: noAnimationStyle;
			gAnimation.setAttribute(
				'style',
				`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
			);

			// <g> for content
			const gContent = document.createElementNS(NS.SVG, 'g');
			gContent.setAttribute(
				'transform',
				`rotate(${field.position.angle}, ${field.position.width / 2}, ${
					field.position.height / 2
				}) scale(${textScale})`
			);
			svg.append(gAnimation);
			gAnimation.append(gContent);
			gContent.append(text);

			// following code is same as "print" mode on 25/09/2020
			const strikeThroughLineRotateFactor = 0.5;
			if (field.strikeThrough) {
				let offsetX = 5,
					lineBoxes = [];

				if (isNumberField) {
					// is number text field
					lineBoxes.push(bBox.textBBox);
				} else {
					lineBoxes = bBox.tspansBBox;
				}
				lineBoxes.forEach((bBox) => {
					let radRotate = Math.atan2(bBox.height, bBox.width),
						degRotate = (strikeThroughLineRotateFactor * (radRotate * 180)) / Math.PI;
					let lineLength =
						field.strikeThroughStyle.type === 'strike'
							? bBox.width
							: bBox.width / Math.cos(strikeThroughLineRotateFactor * radRotate);
					const strikeLine = document.createElementNS(NS.SVG, 'line');

					strikeLine.setAttribute('x1', bBox.x - offsetX);
					strikeLine.setAttribute('y1', bBox.y + bBox.height / 2);
					strikeLine.setAttribute('x2', bBox.x + lineLength + offsetX);
					strikeLine.setAttribute('y2', bBox.y + bBox.height / 2);
					strikeLine.setAttribute('stroke', field.fontColor?.hex || '#000000');
					strikeLine.setAttribute('stroke-width', field.strikeThroughStyle.lineWidth);
					strikeLine.setAttribute(
						'transform',
						`rotate(${
							field.strikeThroughStyle.type === 'strikedown'
								? degRotate //15
								: field.strikeThroughStyle.type === 'strikeup'
								? -1 * degRotate //-15
								: 0
						}, ${bBox.x + bBox.width / 2}, ${bBox.y + bBox.height / 2})`
					);
					gContent.append(strikeLine);
				});
			}

			if (hasShadow) {
				// in ARTWORK_SERVER_SIDE_PROCESS mode, we will use image for shadow by default.
				// filter shadow is not supported by PDFKIT, the work around is to create shadow image, and insert it before <text>
				// To prevent the filter shadow gets cut off, we extent filter region by _NUMBER(px) in both hor & ver direction
				let shadowImgDataUrl = await createTextShadowImageDataUrl({
					text: textClone,
					textBBox: bBox.textBBox,
					fontList: requiredFontList,
					shadowBlurRadius: field.shadowBlurRadius || 0,
					shadowColor: field.textShadowColor.hex,
					highRes: true,
					filterOffset: shadowImgFilterOffset,
				});

				let { s3Client, s3Params } = serverSideProcess;
				// upload shadow image to s3
				const fileKey = `${s3Params.filePrefix}${genRandomStr(12)}-${Date.now()}.png`;
				// base64str = shadowImgDataUrl.substr(22);
				const buf = Buffer.from(shadowImgDataUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64');
				let s3FileParams = {
					Bucket: s3Params.bucket,
					Key: fileKey,
					Body: buf,
					ContentEncoding: 'base64',
					ContentType: 'image/png',
				};
				await s3Client.upload(s3FileParams).promise();

				// update the image url with cloudfront url of the uploaded shadow image
				let shadowImageUrl = getCloudFrontUrlOfS3File({ Bucket: s3Params.bucket, Key: fileKey });

				const textShadowImg = document.createElementNS(NS.SVG, 'image');
				textShadowImg.setAttribute('href', shadowImageUrl);
				// the shadow image is positioned by original text bbox + shadowImgFilterOffset
				textShadowImg.setAttribute(
					'x',

					bBox.textBBox.x - shadowImgFilterOffset + (field.shadowHorOffset || 0) * textScale
				);
				textShadowImg.setAttribute(
					'y',

					bBox.textBBox.y - shadowImgFilterOffset + (field.shadowVerOffset || 0) * textScale
				);
				textShadowImg.setAttribute('width', bBox.textBBox.width + shadowImgFilterOffset * 2);
				textShadowImg.setAttribute('height', bBox.textBBox.height + shadowImgFilterOffset * 2);
				gContent.prepend(textShadowImg); // add the shadow image to first element in gContent <g>
			}

			// remove textClone from its parent (svg is the parent)

			textClone.remove();

			// create rawSVG
			let { rawSVG } = serverSideProcess;
			if (rawSVG) {
				let svgForRawSVG = svg.cloneNode(true);
				rawSVG.append(svgForRawSVG); // append (cloned) svg to rawSVG
				if (hasShadow) {
					// remove all children that are in gAnimation <g>

					svgForRawSVG.children[0].replaceChildren();

					// create gContent <g> for raw svg
					let gContentRawSVG = gContent.cloneNode(true);

					// append new gContent <g> to gAnimation <g>

					svgForRawSVG.children[0].append(gContentRawSVG);

					// remove the shadow image

					gContentRawSVG.children[0].remove();

					// create filter
					const filter = document.createElementNS(NS.SVG, 'filter');
					filter.setAttribute('x', '-50%');
					filter.setAttribute('y', '-50%');
					filter.setAttribute('width', '200%');
					filter.setAttribute('height', '200%');
					filter.setAttribute('filterUnits', 'userSpaceOnUse');
					filter.setAttribute('id', 'shadow-' + field.id);

					filter.innerHTML = `
								<feGaussianBlur stdDeviation="${field.shadowBlurRadius || 0}" result="shadow" />
								<feOffset dx="${field.shadowHorOffset || 0}" dy="${field.shadowVerOffset || 0}" />
							`;

					// add filter to top of the svg

					svgForRawSVG.prepend(filter);

					// create shadow text
					let textShadowClone = text.cloneNode(true);

					textShadowClone.setAttribute('filter', `url(#${'shadow-' + field.id})`);

					textShadowClone.setAttribute('fill', field.textShadowColor.hex);

					// add shadow text

					gContentRawSVG.prepend(textShadowClone);
				}
			}
		});
	}
};

/**
 * Prepare concatenation text-only field for
 * 	- preview(react) [return Object]
 * 	- print (export to pdf) [return Promise]
 * 	- svg (export to svg) [return Promise]
 * 	- ARTWORK_SERVER_SIDE_PROCESS (create svg with image for shadow) [return Promise]
 * 		-  optional: create raw svg with filter for shadow
 *
 * This function is to keep the logic of text field preparation in one place so that you don't need to change the same code in multiple places
 * NOTE: keep in mind the render part is still different in React & pdf generation.
 * @param {Object} opts
 * {
 * 		mode: 'preview'|'print'|'svg',
 * 		field: Object, // the text field to be prepared
 * 		templateFields: array, // array of template fields
 * 		fieldOutputData: object, // all fields output data. Combination of user input data and field default data
			dataForExport: {
				rootSVG: DOM element, // Mandatary. This is the root of the whole template, it contains all fields' svg
				isPrintable: Boolean, // Mandatary. this is for "hasShadow" option, "shadow" doesn't apply to concatenation field, but keep it here to make this func similar to prepareTextField func
				fontList: Array,	// [{name: 'xxx', fontUrl: 'xxxx'}, ...]. Mandatary
				animationDelay: NUMBER, // Mandatary in 'svg' & ARTWORK_SERVER_SIDE_PROCESS mode
			}
			serverSideProcess: { // mandatary for ARTWORK_SERVER_SIDE_PROCESS mode
				rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
				s3Client: s3Client, // mandatary
				s3Params: {					// mandatary
					bucket: 'xxx',
					filePrefix: 'xyz/xyz/xyz/'
				},
			}
 * 		CONSTANTS: {
 * 			placeholderSameAsText: ART_VARIABLES.placeholderSameAsText, // Mandatary
 * 			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION	// Mandatary only in 'svg' mode
 * 			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY	// Mandatary only in 'svg' mode
 * 		}
 * }
 *
 * @return {Object (preview mode) |Promise (svg/print mode) |null}.
	tspanObj = {
		value: 'xxxx',
		attrs: {
			fill: '#000000',
			'font-size': 60, // in pt
			'font-family': `"font-face-name"`,
		},
	};
	tspansPerLine = {
		LINE_INDEX: [
			{
				tspans: [tspanObj, tspanObj, ...], // it is array of tspanObj, if length > 1 (number field), we use nested <tspan> to render it, so that we can calculate its bBox in case for strikeThrough
				strikeThroughStyle: {
					type: '' | 'strike' | 'strikeup' | 'strikedown',
					lineWidth: 1,
				},
				size: {width: number, height: number}
			},
		],
	};
	textAttrs = {
		style: `font-family: "${field.fontfaceName}";`,
		'font-size': `${_fontSize}pt`,
		fill: field.fontColor.hex,
		...
	};
	lineHeight = field.leadingLineHeight; // number, use it as dy=`${lineHeight}em`
	attrsPerLine = {[lineKey]: {...}, [lineKey]: {...}}, // attributes for each line <tspan>
	dyFirstLine, // dy number for first line <tspan>, use it as `${dyFirstline}em`
	strikeLinesAttrs, // hold strike through line attributes. Format [{attr: '', attr: ''}, ...]. Each object is for one line
	gContentScale, // gContent scale number

	return: { tspansPerLine, textAttrs, lineHeight, attrsPerLine, dyFirstLine, strikeLinesAttrs, gContentScale }
 */
export const prepareConcatTextField = ({
	mode,
	field,
	templateFields,
	fieldOutputData,
	dataForExport = {},
	serverSideProcess = {},
	CONSTANTS,
}) => {
	if (
		(mode === 'print' || mode === 'svg') &&
		(!dataForExport.rootSVG || !dataForExport.fontList || isNullish(dataForExport.isPrintable))
	)
		return null;

	if (mode === 'svg' && isNaN(dataForExport.animationDelay)) return null;
	if (
		mode === ARTWORK_SERVER_SIDE_PROCESS &&
		(!dataForExport.rootSVG ||
			!dataForExport.fontList ||
			isNaN(dataForExport.animationDelay) ||
			!serverSideProcess.s3Client ||
			!serverSideProcess.s3Params)
	)
		return null;
	const inputData = fieldOutputData[field.id];
	const horAlign = inputData.horizontalAlign; // || field.textHorizontalAlign;
	const verAlign = inputData.verticalAlign; // || field.textVerticalAlign;
	const _fontSize = inputData.fontsize; // || field.fontsize;
	const originalText = inputData.value.trim(); // (inputData.value || field.defaultValue /* || field.name */ || '').trim();
	if (!originalText) return null; // no text string, nothing to display

	// Validate font list
	let _fontNames = [field.fontfaceName];
	if (field.embedStyle === 'original') {
		// include embedded fields' fontface
		const allEmbeddedFieldIds = getEmbeddedFieldIds(field);
		_fontNames = Array.from(
			new Set(
				_fontNames.concat(
					templateFields
						.filter((f) => allEmbeddedFieldIds.includes(f.id))
						.map((f) => f.fontfaceName)
				)
			)
		);
	}
	const requiredFontList = (dataForExport.fontList || []).filter((artFont) =>
		_fontNames.includes(artFont.name)
	);
	if (
		(mode === 'print' || mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		_fontNames.length !== requiredFontList.length
	) {
		throw new Error(`Missing font "${_fontNames.join(', ')}" in field "${field.name}"`);
	}
	const autoWrap = field.doNotSetToFit || field.inputStyle === 'textarea'; // if true, enable auto-wrap

	// initial creation of tspansPerLine
	let tspansPerLine = createTspansPerLineInConcatField({
		originalText,
		_fontSize,
		autoWrap,
		field,
		templateFields,
		fieldOutputData,
		CONSTANTS,
	});

	// if no content (text), nothing to render, return null;
	if (Object.keys(tspansPerLine).length === 0) return null;

	// at this point, we have concatenation string prepared. next work is to render it
	let gContentScale = 1;
	let previewAttrsObj = {
		// this object holds all attrs for "preview" mode
		textAttrs: null,
		attrsPerLine: null,
		lineHeight: null,
		dyFirstLine: null,
		strikeStyledTspans: null,
	};
	const hasShadow = field.shadowHorOffset || field.shadowVerOffset || field.shadowBlurRadius;

	// create svg of this field
	let svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('x', field.position.left);
	svg.setAttribute('y', field.position.top);
	svg.setAttribute('width', field.position.width);
	svg.setAttribute('height', field.position.height);
	svg.setAttribute('overflow', 'visible');

	// create (initial) text element. Use it to calculate scale if "fit to container"
	let textElem = createTextElemInConcatField({
		tspansPerLine,
		_fontSize,
		horAlign,
		verAlign,
		gContentScale,
		field,
	});
	let text = textElem.text;
	previewAttrsObj = { ...previewAttrsObj, ...textElem.attrs };

	let gAnimation = null; // to hold animation styles in "svg" mode

	if (mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) {
		// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
		gAnimation = document.createElementNS(NS.SVG, 'g');
		const noAnimationStyle = `0s ease 0s 1 normal none running none`;
		const animationEntranceStyle = field.animation.entrance
			? `${field.animation.entrance} ${
					field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
			  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
					dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
			  }s both`
			: noAnimationStyle;
		gAnimation.setAttribute(
			'style',
			`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
		);
	}

	// <g> for content. hold <text> in any mode
	const gContent = document.createElementNS(NS.SVG, 'g');
	//In case we need to fit "text" to its container, we set its attributes later
	gContent.append(text);

	if (gAnimation) {
		gAnimation.append(gContent);
		svg.append(gAnimation);
	} else {
		svg.append(gContent);
	}

	// we have svg ready, let's calculate "strikeThrough" & "fit to container"
	// we append original svg dom (not clone) to body, so that we can use referenced tspanElem to calculate strike through lines
	document.body.append(svg);

	/**
	 * fit <text> to its container by changing the fontSize of text
	 * Note: we only scale down, because the fontSize is max fontSize
	 */
	const containerSize = { width: field.position.width, height: field.position.height };

	let contentBBox = svg.getBBox();

	if (
		!field.doNotSetToFit &&
		text.childElementCount > 0 &&
		isTooBigInConcatField({ containerSize, actualBBoxSize: contentBBox })
	) {
		// we fit the <text> to its container Only when it is not empty,
		// otherwise the BBox from svg.getBBox() is always zero, the min/max scales could be infinity when dividing zero
		let lowFontSize = 1;
		let highFontSize = _fontSize;
		let fontStep = 1;
		let midFontSize = lowFontSize;

		// function to create tspans and text with the trail fontsize
		const fitText = (fitFontSize) => {
			// re-create <Text>
			text.remove(); // remove text elem from gContent,
			tspansPerLine = createTspansPerLineInConcatField({
				originalText,
				_fontSize: fitFontSize,
				autoWrap,
				field,
				templateFields,
				fieldOutputData,
				CONSTANTS,
			});
			textElem = createTextElemInConcatField({
				tspansPerLine,
				_fontSize: fitFontSize,
				horAlign,
				verAlign,
				gContentScale,
				field,
			}); // create new text elem
			text = textElem.text; // assign new text element
			gContent.append(text); // append new text elem
		};

		while (lowFontSize + fontStep < highFontSize) {
			midFontSize = (lowFontSize + highFontSize) / 2;
			fitText(midFontSize);

			let bb = svg.getBBox();
			if (isTooBigInConcatField({ containerSize, actualBBoxSize: bb })) {
				// "text" is too big,
				highFontSize = midFontSize;
			} else {
				// fitted, we update the found font-size (so far), then continue search for better font-size
				lowFontSize = midFontSize + fontStep;
			}
		}

		// To make the font really fit in the boundary box, we use the smaller value of lowFontSize & midFontSize as the fitted fontsize and re-create the Text
		// minus the fontStep is because "lowFontSize = midFontSize + fontStep;" (the "midFontSize" was the last known font-size that is really fitted)
		//
		// scenario:
		// - a fitted midFontSize was found, then applied "lowFontSize = midFontSize + fontStep"
		// - then "too big" happened, and applied "highFontSize = midFontSize"
		// - then "lowFontSize + fontStep >= highFontSize" happened, exit from while loop
		// In this scenario, the best fitted fontSize is the "lowFontSize - fontStep" (midFontSize is bigger than lowFontSize)
		// so lastKnownFitFontSize is "Math.min(lowFontSize, midFontSize) - fontStep"
		// but the lastKnownFitFontSize could be zero because in extreme case, it could be the initial lowFontSize value "1", so minus fontStep will make it be zero
		// that is why we need to have "Math.max(1, lastKnownFitFontSize)" to ensure we don't use zero as the final fontSize
		const lastKnownFitFontSize = Math.min(lowFontSize, midFontSize) - fontStep;
		fitText(Math.max(1, lastKnownFitFontSize));

		// the fitted "text" (<Text>) has already updated (also the alignment), and append to gContent
		// now we need previewAttrsObj
		previewAttrsObj = { ...previewAttrsObj, ...textElem.attrs }; // update preview attrs object
	}
	// set attribute in gContent. At this point we have the best scale factor (default scale 1 or best fit scale for "fit to container")
	gContent.setAttribute(
		'transform',
		`rotate(${field.position.angle}, ${field.position.width / 2}, ${
			field.position.height / 2
		}) scale(${gContentScale})`
	);

	// calculate strike through lines
	let strikeLinesAttrs = []; // hold strike through line attributes. Format [{attr: '', attr: ''}, ...]. Each object is for one line
	const strikeThroughLineRotateFactor = 0.5;
	const offsetX = 0; // don't apply offsetX as there could be the letters before and after it in concatenation field
	previewAttrsObj.strikeStyledTspans.forEach((strikeStyledTspan) => {
		let { tspanElem, strikeThroughStyle } = strikeStyledTspan;
		let tspanBBox = tspanElem.getBBox();
		let radRotate = Math.atan2(tspanBBox.height, tspanBBox.width),
			degRotate = (strikeThroughLineRotateFactor * (radRotate * 180)) / Math.PI;
		let lineLength =
			strikeThroughStyle.type === 'strike'
				? tspanBBox.width
				: tspanBBox.width / Math.cos(strikeThroughLineRotateFactor * radRotate);
		const strikeLine = document.createElementNS(NS.SVG, 'line');
		let lineAttrs = {
			x1: tspanBBox.x - offsetX,
			y1: tspanBBox.y + tspanBBox.height / 2,
			x2: tspanBBox.x + lineLength + offsetX,
			y2: tspanBBox.y + tspanBBox.height / 2,
			stroke: strikeThroughStyle.strikeColorInEmbeddedField || '#000000', // first try to use the one in embeddedField (if it has value, means we have embedded style "original" selected)
			'stroke-width': strikeThroughStyle.lineWidth,
			transform: `rotate(${
				strikeThroughStyle.type === 'strikedown'
					? degRotate //15
					: strikeThroughStyle.type === 'strikeup'
					? -1 * degRotate //-15
					: 0
			}, ${tspanBBox.x + tspanBBox.width / 2}, ${tspanBBox.y + tspanBBox.height / 2})`,
		};
		strikeLinesAttrs.push(lineAttrs);
		setDomAttrs(strikeLine, lineAttrs);

		gContent.append(strikeLine);
	});

	if (mode === 'preview') {
		svg.remove(); // remove it from document.body
		return {
			tspansPerLine,
			textAttrs: previewAttrsObj.textAttrs,
			lineHeight: previewAttrsObj.lineHeight,
			attrsPerLine: previewAttrsObj.attrsPerLine,
			dyFirstLine: previewAttrsObj.dyFirstLine,
			strikeLinesAttrs,
			gContentScale,
			hasShadow,
		};
	} else if (mode === 'print' || mode === 'svg') {
		return Promise.resolve().then(async () => {
			if (hasShadow) {
				let textShadowClone = text.cloneNode(true); // NB: with isPrintable=true, textShadowClone is appended directly to root <svg>, not to <g> gContent, and it is removed after creating shadow image

				// remove "fill" attributes from all children of the textShadowClone
				// because children fields in concatenation field may use "original" field setting
				// and so could have their own font & color

				const textAllChildren = textShadowClone.getElementsByTagName('*');
				for (let element of textAllChildren) {
					if (element.tagName === 'tspan') {
						element.removeAttribute('fill');
					}
				}

				if (dataForExport.isPrintable) {
					// (For printable ONLY) Shadow filter is not supported by PDFKIT, the work around is to create shadow image, and insert it before <text>
					// When creating shadow image, to prevent the shadow filter gets cut off, we extent filter region by _NUMBER(px) in both hor & ver direction
					const shadowImgFilterOffset = 50;

					svg.append(textShadowClone);

					const bb = textShadowClone.getBBox();
					const textBBox = { x: bb.x, y: bb.y, width: bb.width, height: bb.height }; // used for creating shadow image for printing

					// In most cases, dataForExport.isPrintable is true,
					// the only case dataForExport.isPrintable is false is when the template has "video" field, in that case the exported SVG will use filter for shadow
					// when the template has "video" field, it is supposed NOT to be used for printing at all.
					// filter shadow is not supported by PDFKIT, the work around is to create shadow image, and insert it before <text>
					// To prevent the filter shadow gets cut off, we extent filter region by _NUMBER(px) in both hor & ver direction
					let shadowImgDataUrl = await createTextShadowImageDataUrl({
						text: textShadowClone,
						textBBox: textBBox,
						fontList: requiredFontList,
						shadowBlurRadius: field.shadowBlurRadius || 0,
						shadowColor: field.textShadowColor.hex,
						highRes: true,
						filterOffset: shadowImgFilterOffset,
					});
					const textShadowImg = document.createElementNS(NS.SVG, 'image');
					textShadowImg.setAttribute('href', shadowImgDataUrl);
					// the shadow image is positioned by original text bbox + shadowImgFilterOffset
					textShadowImg.setAttribute(
						'x',

						textBBox.x - shadowImgFilterOffset + (field.shadowHorOffset || 0) * gContentScale
					);
					textShadowImg.setAttribute(
						'y',

						textBBox.y - shadowImgFilterOffset + (field.shadowVerOffset || 0) * gContentScale
					);
					textShadowImg.setAttribute('width', textBBox.width + shadowImgFilterOffset * 2);
					textShadowImg.setAttribute('height', textBBox.height + shadowImgFilterOffset * 2);
					gContent.prepend(textShadowImg); // prepending the element to beginning

					// no longer need the textShadowClone, remove it from any append parent

					textShadowClone.remove();
					textShadowClone = null;
				} else {
					// To prevent the filter shadow gets cut off, we extent filter region by 100% in both hor & ver direction
					const filter = document.createElementNS(NS.SVG, 'filter');
					filter.setAttribute('x', '-50%');
					filter.setAttribute('y', '-50%');
					filter.setAttribute('width', '200%');
					filter.setAttribute('height', '200%');
					filter.setAttribute('filterUnits', 'userSpaceOnUse');
					filter.setAttribute('id', 'shadow-' + field.id);

					filter.innerHTML = `
							<feGaussianBlur stdDeviation="${field.shadowBlurRadius || 0}" result="shadow" />
							<feOffset dx="${field.shadowHorOffset || 0}" dy="${field.shadowVerOffset || 0}" />
						`;
					svg.insertBefore(filter, svg.firstChild);

					textShadowClone.style.filter = `url(#${'shadow-' + field.id})`;

					textShadowClone.style.fill = field.textShadowColor.hex;
					// append shadow <text>
					gContent.prepend(textShadowClone);
				}
			}

			// ok, all done.
			svg.remove(); // remove it from document.body
			dataForExport.rootSVG.append(svg);
		});
	} else if (mode === ARTWORK_SERVER_SIDE_PROCESS) {
		// handle ARTWORK_SERVER_SIDE_PROCESS mode

		return Promise.resolve().then(async () => {
			if (hasShadow) {
				let textShadowClone = text.cloneNode(true); // NB: textShadowClone is appended directly to root <svg>, not to <g> gContent, and it is removed after creating shadow image

				// remove "fill" attributes from all children of the textShadowClone
				// because children fields in concatenation field may use "original" field setting
				// and so could have their own font & color

				const textAllChildren = textShadowClone.getElementsByTagName('*');
				for (let element of textAllChildren) {
					if (element.tagName === 'tspan') {
						element.removeAttribute('fill');
					}
				}

				// By default, we use image for shadow in ARTWORK_SERVER_SIDE_PROCESS mode
				// Shadow filter is not supported by PDFKIT, the work around is to create shadow image, and insert it before <text>
				// When creating shadow image, to prevent the shadow filter gets cut off, we extent filter region by _NUMBER(px) in both hor & ver direction
				const shadowImgFilterOffset = 50;

				svg.append(textShadowClone);

				const bb = textShadowClone.getBBox();
				const textBBox = { x: bb.x, y: bb.y, width: bb.width, height: bb.height }; // used for creating shadow image for printing

				// In most cases, dataForExport.isPrintable is true,
				// the only case dataForExport.isPrintable is false is when the template has "video" field, in that case the exported SVG will use filter for shadow
				// when the template has "video" field, it is supposed NOT to be used for printing at all.
				// filter shadow is not supported by PDFKIT, the work around is to create shadow image, and insert it before <text>
				// To prevent the filter shadow gets cut off, we extent filter region by _NUMBER(px) in both hor & ver direction
				let shadowImgDataUrl = await createTextShadowImageDataUrl({
					text: textShadowClone,
					textBBox: textBBox,
					fontList: requiredFontList,
					shadowBlurRadius: field.shadowBlurRadius || 0,
					shadowColor: field.textShadowColor.hex,
					highRes: true,
					filterOffset: shadowImgFilterOffset,
				});

				// it is for ARTWORK_SERVER_SIDE_PROCESS, we upload shadowImg to s3 and use its cloudfront url
				let { s3Client, s3Params } = serverSideProcess;
				// upload shadow image to s3
				const fileKey = `${s3Params.filePrefix}${genRandomStr(12)}-${Date.now()}.png`;
				// base64str = shadowImgDataUrl.substr(22);
				const buf = Buffer.from(shadowImgDataUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64');
				let s3FileParams = {
					Bucket: s3Params.bucket,
					Key: fileKey,
					Body: buf,
					ContentEncoding: 'base64',
					ContentType: 'image/png',
				};
				await s3Client.upload(s3FileParams).promise();

				// update the url with cloudfront url of the uploaded shadow image
				let shadowImageUrl = getCloudFrontUrlOfS3File({
					Bucket: s3Params.bucket,
					Key: fileKey,
				});

				const textShadowImg = document.createElementNS(NS.SVG, 'image');
				textShadowImg.setAttribute('href', shadowImageUrl);
				// the shadow image is positioned by original text bbox + shadowImgFilterOffset
				textShadowImg.setAttribute(
					'x',

					textBBox.x - shadowImgFilterOffset + (field.shadowHorOffset || 0) * gContentScale
				);
				textShadowImg.setAttribute(
					'y',

					textBBox.y - shadowImgFilterOffset + (field.shadowVerOffset || 0) * gContentScale
				);
				textShadowImg.setAttribute('width', textBBox.width + shadowImgFilterOffset * 2);
				textShadowImg.setAttribute('height', textBBox.height + shadowImgFilterOffset * 2);
				gContent.prepend(textShadowImg); // prepending the element to beginning

				// remove textShadowClone from any append parent, it might be appended to rawSVG

				textShadowClone.remove();
			}

			// remove svg from document.body & append to rootSVG
			svg.remove();
			dataForExport.rootSVG.append(svg);

			// create rawSVG
			let { rawSVG } = serverSideProcess;
			if (rawSVG) {
				let svgForRawSVG = svg.cloneNode(true);
				rawSVG.append(svgForRawSVG); // append (cloned) svg to rawSVG

				if (hasShadow) {
					// remove all children that are in gAnimation <g>

					svgForRawSVG.children[0].replaceChildren();

					// create gContent <g> for raw svg
					let gContentRawSVG = gContent.cloneNode(true);

					// append new gContent <g> to gAnimation <g>

					svgForRawSVG.children[0].append(gContentRawSVG);

					// remove the shadow image

					gContentRawSVG.children[0].remove();

					// create filter
					const filter = document.createElementNS(NS.SVG, 'filter');
					filter.setAttribute('x', '-50%');
					filter.setAttribute('y', '-50%');
					filter.setAttribute('width', '200%');
					filter.setAttribute('height', '200%');
					filter.setAttribute('filterUnits', 'userSpaceOnUse');
					filter.setAttribute('id', 'shadow-' + field.id);

					filter.innerHTML = `
							<feGaussianBlur stdDeviation="${field.shadowBlurRadius || 0}" result="shadow" />
							<feOffset dx="${field.shadowHorOffset || 0}" dy="${field.shadowVerOffset || 0}" />
						`;

					// add filter to top of the svg

					svgForRawSVG.prepend(filter);

					// create shadow text
					let textShadowClone = text.cloneNode(true);

					textShadowClone.setAttribute('filter', `url(#${'shadow-' + field.id})`);

					textShadowClone.setAttribute('fill', field.textShadowColor.hex);

					// add shadow text

					gContentRawSVG.prepend(textShadowClone);
				}
			}
		});
	}
};

/**
 * Prepare pdf field for preview(react) [return Object] | print (export to pdf) [return Promise] | svg (export to svg)
 * This function is to keep the logic of barcode field preparation in one place so that you don't need to change the same code in multiple places
 * NOTE: keep in mind the render is different between React, svg & pdf generation.
 * @param {Object} opts
 * {
 * 		mode: 'preview'|'print'|'svg',
 * 		inputData: {	// data for output, combination of user input data & field default data
				// pdf Preview Data
				horizontalAlign: 'left' | 'center' | 'right' | undefined,
				verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
				// svgUrl: 'xxxx'
				previewUrl: 'xxxxxxx', // preview url of this pdf/svg. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
				optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
				highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

 * 		},
 * 		field: Object, // the image field to be prepared
 * 		dataForExport:{ //
				rootSVG: DOM element, // Mandatary in "print" & "svg" & ARTWORK_SERVER_SIDE_PROCESS mode
				animationDelay: Number, // Mandatary in 'svg' && ARTWORK_SERVER_SIDE_PROCESS mode
			},
			serverSideProcess: { // optional, for ARTWORK_SERVER_SIDE_PROCESS mode only
				rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
			}
 * 		CONSTANTS: {
 * 			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION	// Mandatary only in 'svg' mode
 * 			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY	// Mandatary only in 'svg' mode
 * 		}
 * }
 *
 * @return {Object|Promise|null}.
 */
export const preparePdfField = ({
	mode,
	inputData = {},
	field,
	dataForExport = {},
	serverSideProcess = {},
	CONSTANTS = {},
}) => {
	// inputData.highResUrl has best quality of mediafiles used in its generation, we use it for "print"
	// use inputData.optimisedUrl for other mode "preview" & "svg"
	let SVGUrl = mode === 'print' ? inputData.highResUrl : inputData.optimisedUrl;
	if (!SVGUrl) return null;
	if (
		(mode === 'print' || mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		!dataForExport.rootSVG
	)
		return null;
	if (
		(mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		isNaN(dataForExport.animationDelay)
	)
		return null;

	const horAlign = inputData.horizontalAlign; // || field.horizontalAlign;
	const verAlign = inputData.verticalAlign; // || field.verticalAlign;

	let attrs = {};
	attrs.preserveAspectRatio = getPreserveAspectRatio(horAlign, verAlign); // `${xAlign}${yAlign} meet`;

	if (mode === 'preview') {
		return { attrs };
	} else {
		// svg & print mode
		const svg = document.createElementNS(NS.SVG, 'svg');
		svg.setAttribute('x', field.position.left);
		svg.setAttribute('y', field.position.top);
		svg.setAttribute('width', field.position.width);
		svg.setAttribute('height', field.position.height);
		svg.setAttribute('overflow', 'visible');
		dataForExport.rootSVG.append(svg);

		const gContainer = document.createElementNS(NS.SVG, 'g');
		gContainer.setAttribute(
			'transform',
			`rotate(${field.position.angle}, ${field.position.width / 2}, ${field.position.height / 2})`
		);

		if (mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) {
			// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
			const gAnimation = document.createElementNS(NS.SVG, 'g');
			const noAnimationStyle = `0s ease 0s 1 normal none running none`;
			const animationEntranceStyle = field.animation.entrance
				? `${field.animation.entrance} ${
						field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
				  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
						dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
				  }s both`
				: noAnimationStyle;
			gAnimation.setAttribute(
				'style',
				`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
			);
			gAnimation.append(gContainer);
			svg.append(gAnimation);
			// const g = document.createElementNS(NS.SVG, 'g');
		} else {
			// mode = 'print'
			svg.append(gContainer);
		}

		return Promise.resolve().then(async () => {
			let svgString = await fetch(SVGUrl).then((res) => res.text());
			gContainer.innerHTML = svgString;
			let pdfSVG = gContainer.children[0]; // g contains ONLY pdf SVG element at this point
			for (const attrKey in attrs) {
				pdfSVG.setAttribute(attrKey, attrs[attrKey]);
			}
			pdfSVG.setAttribute('width', '100%');
			pdfSVG.setAttribute('height', '100%');
			if (mode === 'print') {
				// retrieve image data for "print" mode (by Element.getElementsByTagNameNS(NS.SVG, "image") )
				let innerImages = pdfSVG.getElementsByTagNameNS(NS.SVG, 'image');
				for (let i = 0; i < innerImages.length; i++) {
					let innerImage = innerImages[i];
					let imageHref = innerImage.getAttribute('href');
					// check if it is http(s) url with regex: /^\s*https?:\/\/.*?$/gim
					if (new RegExp(/^\s*https?:\/\/.*?$/gim).test(imageHref)) {
						// it is http(s) url. we need to retrieve image data and use base64 data url for printing
						await retrieveBase64DataUrl(imageHref).then((base64Url) => {
							innerImage.setAttribute('href', base64Url);
						});
					}
				}
			}
			const returnData = {
				colorListObj: getColorListFromDataAttr(pdfSVG),
				fontListArray: getFontListFromDataAttr(pdfSVG),
			};
			// We get the enough data, let's remove data attributes to reduce the output size
			removeColorListDataAttr(pdfSVG);
			removeFontListDataAttr(pdfSVG);
			if (field.borderWidth > 0) {
				// border of the field
				const rect = createBorderRect(field);
				gContainer.append(rect);
			}

			// create raw SVG

			if (serverSideProcess.rawSVG && mode === ARTWORK_SERVER_SIDE_PROCESS) {
				serverSideProcess.rawSVG.append(svg.cloneNode(true)); // append (cloned) svg to rawSVG
			}

			return returnData;
		});
	}
};

/**
 * Prepare video field for preview(react) [return Object] | svg (export to svg)
 * This function is to keep the logic of barcode field preparation in one place so that you don't need to change the same code in multiple places
 * @param {Object} opts
 * {
 * 		mode: 'preview'|'svg',
 * 		inputData: {	// data for output, combination of user input data & field default data
				// Video Input Data
				previewUrl: 'xxxxxxx', // preview url of this video. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
				optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
				highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

			//	videoUrl: 'https://xxxxx', // undefined or original video url???
				horizontalAlign: 'left' | 'center' | 'right' | undefined,
				verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
				videoLoop: true | false | undefined,
 * 		},
 * 		field: Object, // the image field to be prepared
 * 		dataForExport:{
				rootSVG: DOM element, // Mandatary in "svg" & ARTWORK_SERVER_SIDE_PROCESS mode
				animationDelay: Number, // Mandatary in 'svg' & ARTWORK_SERVER_SIDE_PROCESS mode
			},
			serverSideProcess: { // optional, for ARTWORK_SERVER_SIDE_PROCESS mode only
				rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
			}
 * 		CONSTANTS: {
 * 			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION	// Mandatary only in 'svg' mode
 * 			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY	// Mandatary only in 'svg' mode
 * 		}
 * }
 *
 * @return {Object|Promise|null}.
 */
export const prepareVideoField = ({
	mode,
	inputData = {},
	field,
	dataForExport = {},
	serverSideProcess = {},
	CONSTANTS = {},
}) => {
	if (
		(mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		(!dataForExport.rootSVG || isNaN(dataForExport.animationDelay))
	)
		return null;

	const horAlign = inputData.horizontalAlign; // || field.horizontalAlign;
	const verAlign = inputData.verticalAlign; // || field.verticalAlign;

	let videoDivWrapperStyle = `display: flex; position: absolute; left: 0; right: 0; top: 0; bottom: 0;`;

	switch (horAlign) {
		case 'left':
			videoDivWrapperStyle += 'justify-content: flex-start;';
			break;
		case 'center':
			videoDivWrapperStyle += 'justify-content: center;';
			break;
		case 'right':
			videoDivWrapperStyle += 'justify-content: flex-end;';
			break;
		default:
			videoDivWrapperStyle += 'justify-content: center;';
			break;
	}
	switch (verAlign) {
		case 'top':
			videoDivWrapperStyle += 'align-items: flex-start;';
			break;
		case 'middle':
			videoDivWrapperStyle += 'align-items: center;';
			break;
		case 'bottom':
			videoDivWrapperStyle += 'align-items: flex-end;';
			break;
		default:
			videoDivWrapperStyle += 'align-items: center;';
			break;
	}
	// let attrs = {};
	// attrs.preserveAspectRatio = getPreserveAspectRatio(horAlign, verAlign); // `${xAlign}${yAlign} meet`;
	if (mode === 'preview') {
		return {
			videoDivWrapperStyle,
		};
	} else if (mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) {
		// svg || ARTWORK_SERVER_SIDE_PROCESS mode
		const videoSrcUrl = inputData.optimisedUrl || inputData.highResUrl;
		if (!videoSrcUrl) return Promise.resolve();
		const svg = document.createElementNS(NS.SVG, 'svg');
		svg.setAttribute('x', field.position.left);
		svg.setAttribute('y', field.position.top);
		svg.setAttribute('width', field.position.width);
		svg.setAttribute('height', field.position.height);
		svg.setAttribute('overflow', 'visible');
		dataForExport.rootSVG.append(svg);

		// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
		const gAnimation = document.createElementNS(NS.SVG, 'g');
		const noAnimationStyle = `0s ease 0s 1 normal none running none`;
		const animationEntranceStyle = field.animation.entrance
			? `${field.animation.entrance} ${
					field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
			  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
					dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
			  }s both`
			: noAnimationStyle;
		gAnimation.setAttribute(
			'style',
			`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
		);
		svg.append(gAnimation);

		const g = document.createElementNS(NS.SVG, 'g');
		g.setAttribute(
			'transform',
			`rotate(${field.position.angle}, ${field.position.width / 2}, ${field.position.height / 2})`
		);
		gAnimation.append(g);

		const foreignObjectElem = document.createElementNS(NS.SVG, 'foreignObject');
		foreignObjectElem.setAttribute('width', '100%');
		foreignObjectElem.setAttribute('height', '100%');
		g.append(foreignObjectElem);
		if (field.borderWidth > 0) {
			// border of the field
			const rect = createBorderRect(field);
			g.append(rect);
		}

		const videoDivWrapper = document.createElementNS(NS.HTML, 'div');
		videoDivWrapper.setAttribute('xmlns', NS.HTML);
		videoDivWrapper.setAttribute('style', videoDivWrapperStyle);
		foreignObjectElem.append(videoDivWrapper);

		return getVideoDimension(videoSrcUrl).then((videoDimension) => {
			const video = document.createElementNS(NS.HTML, 'video');
			video.setAttribute('xmlns', NS.HTML);
			video.setAttribute('autoPlay', '');
			video.setAttribute('muted', '');
			if (inputData.videoLoop) video.setAttribute('loop', '');
			if (
				videoDimension.width / videoDimension.height >
				field.position.width / field.position.height
			) {
				video.style.width = '100%';

				video.style.height = 'auto';
			} else {
				video.style.width = 'auto';

				video.style.height = '100%';
			}
			videoDivWrapper.append(video);
			const videoSrc = document.createElementNS(NS.HTML, 'source');
			videoSrc.setAttribute('xmlns', NS.HTML);
			videoSrc.setAttribute('src', videoSrcUrl);
			// videoSrc.setAttribute('type', 'video/mp4');
			video.appendChild(videoSrc);

			// create raw SVG

			if (serverSideProcess.rawSVG && mode === ARTWORK_SERVER_SIDE_PROCESS) {
				serverSideProcess.rawSVG.append(svg.cloneNode(true)); // append (cloned) svg to rawSVG
			}
		});
	}
};

/**
 * Prepare barcode field for preview(react) [return Object] | print (export to pdf) [return Promise] | svg (export to svg)
 * This function is to keep the logic of barcode field preparation in one place so that you don't need to change the same code in multiple places
 * NOTE: keep in mind the render is different between React, svg & pdf generation.
 * @param {Object} opts
 * {
 * 		mode: 'preview'|'print'|'svg',
 * 		inputData: {	// data for output, combination of user input data & field default data
				// Barcode Preview Data
				value: 'xxxxxxx',
				EAN13Value: 'xxx',
				EAN5Value: 'xxx',
				horizontalAlign: 'left' | 'center' | 'right' | undefined,
				verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
 * 		},
 * 		field: Object, // the image field to be prepared
 * 		dataForExport:{ //
				rootSVG: DOM element, // Mandatary in "print" & "svg" & ARTWORK_SERVER_SIDE_PROCESS mode
				animationDelay: Number, // Mandatary in 'svg' & ARTWORK_SERVER_SIDE_PROCESS mode
			},
			serverSideProcess: { // optional, for ARTWORK_SERVER_SIDE_PROCESS mode only
				rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
			}
 * 		CONSTANTS: {
 * 			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION	// Mandatary only in 'svg' mode
 * 			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY	// Mandatary only in 'svg' mode
 * 		}
 * }
 *
 * @return {Object|Promise|null}.
 */
export const prepareBarcodeField = ({
	mode,
	inputData = {},
	field,
	dataForExport = {},
	serverSideProcess = {},
	CONSTANTS = {},
}) => {
	if (
		(mode === 'print' || mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		!dataForExport.rootSVG
	)
		return null;
	if (
		(mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		isNaN(dataForExport.animationDelay)
	)
		return null;

	const horAlign = inputData.horizontalAlign; // || field.horizontalAlign;
	const verAlign = inputData.verticalAlign; // || field.verticalAlign;

	let attrs = {};
	let xAlign = 'xMin',
		yAlign = 'YMin';
	switch (horAlign) {
		case 'left':
			xAlign = 'xMin';
			break;
		case 'center':
			xAlign = 'xMid';
			break;
		case 'right':
			xAlign = 'xMax';
			break;
		default:
			break;
	}
	switch (verAlign) {
		case 'top':
			yAlign = 'YMin';
			break;
		case 'middle':
			yAlign = 'YMid';
			break;
		case 'bottom':
			yAlign = 'YMax';
			break;
		default:
			break;
	}
	attrs.preserveAspectRatio = `${xAlign}${yAlign} meet`;

	// JsBarcode(barcodeRef.current)
	// 	.options({ font: 'OCR-B', lineColor: field.color.hex || '#000000' }) // Will affect all barcodes
	// 	.EAN13(barcodeValue, { fontSize: 18, textMargin: 0 })
	// 	.blank(20) // Create space between the barcodes
	// 	.EAN5('12345', { height: 85, textPosition: 'top', fontSize: 16, marginTop: 15 })
	// 	.render();
	/**
	 * Base on EAN13 spec (https://internationalbarcodes.com/ean-13-specifications/),
	 * width of EAN13 barcode at 100% magnification is 31.35mm, which is (31.35mm * unitMap.mm) = standard_barcode_width_in_pixel.
	 * - if no ean5 addon: bar_width = field.position.width/standard_barcode_width_in_pixel
	 * - if has ean5 addon: there is blank space between ean13 and ean5, ean5 uses half of ean13 area, hence:
	 * 		- ean13_area = (field.position.width-blank)*2/3,
	 * 		- ean5_area = (field.position.width-blank)*1/3
	 * 		- bar_width_ean13 = ean13_area/standard_barcode_width_in_pixel
	 * 		- bar_width_ean5 = ean5_area/standard_barcode_width_in_pixel
	 */
	// let unitMap = pixelUnitMap();
	const jsBarcodeOpts = { font: 'OCR-B', lineColor: field.color.hex || '#000000' };
	// const jsBarcodeEan13Opts = { fontSize: 18, textMargin: 0 };
	// const jsBarcodeBlank = 20; // space between the barcodes (ean13 and ean5)
	// const jsBarcodeEan5Opts = { height: 85, textPosition: 'top', fontSize: 16, marginTop: 15 };
	const ean13StandardWidthInPx = 31.35 * PIXEL_UNIT_MAP.mm;
	// const hasEan5 = !!inputData.EAN5Value;
	const jsBarcodeBlank = 17; // space between the barcodes (ean13 and ean5)
	const totalBars = inputData.EAN5Value && field.EAN5Addon ? 18 : 13;
	const barWidth = (field.position.width * (13 / totalBars)) / ean13StandardWidthInPx;
	const jsBarcodeEan13Opts = {
		// fontSize: 18,
		// textMargin: 0,
		marginLeft: 0,
		margin: 0,
		displayValue: false,
		flat: true,
		height: field.position.height,
		// width: hasEan5
		// 	? ((field.position.width - jsBarcodeBlank) * (2 / 3)) / ean13StandardWidthInPx
		// 	: field.position.width / ean13StandardWidthInPx,
		width: barWidth,
		// hasEan5
		// 	? (field.position.width * (13 / 18)) / ean13StandardWidthInPx
		// 	: (field.position.width * (13 / 18)) / ean13StandardWidthInPx,
	};
	const jsBarcodeEan5Opts = {
		margin: 0,
		displayValue: false,
		flat: true,
		height: field.position.height,
		// width: ((field.position.width - jsBarcodeBlank) * (1 / 3)) / ean13StandardWidthInPx,
		width: barWidth,
	};

	if (mode === 'preview') {
		return {
			attrs,
			EAN13Value: inputData.EAN13Value,
			EAN5Value: inputData.EAN5Value,
			jsBarcodeOpts,
			jsBarcodeEan13Opts,
			jsBarcodeBlank,
			jsBarcodeEan5Opts,
		};
	} else if (mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) {
		// svg || ARTWORK_SERVER_SIDE_PROCESS mode
		if (!inputData.EAN13Value) return Promise.resolve();
		const svg = document.createElementNS(NS.SVG, 'svg');
		svg.setAttribute('x', field.position.left);
		svg.setAttribute('y', field.position.top);
		svg.setAttribute('width', field.position.width);
		svg.setAttribute('height', field.position.height);
		svg.setAttribute('overflow', 'visible');
		dataForExport.rootSVG.append(svg);

		// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
		const gAnimation = document.createElementNS(NS.SVG, 'g');
		const noAnimationStyle = `0s ease 0s 1 normal none running none`;
		const animationEntranceStyle = field.animation.entrance
			? `${field.animation.entrance} ${
					field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
			  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
					dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
			  }s both`
			: noAnimationStyle;
		gAnimation.setAttribute(
			'style',
			`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
		);
		svg.append(gAnimation);

		const g = document.createElementNS(NS.SVG, 'g');
		g.setAttribute(
			'transform',
			`rotate(${field.position.angle}, ${field.position.width / 2}, ${field.position.height / 2})`
		);
		gAnimation.append(g);

		const barcodeSvg = document.createElementNS(NS.SVG, 'svg');
		barcodeSvg.setAttribute('width', '100%');
		barcodeSvg.setAttribute('height', '100%');
		for (const attrKey in attrs) {
			barcodeSvg.setAttribute(attrKey, attrs[attrKey]);
		}
		g.append(barcodeSvg);

		let jsBarcodeInst = JsBarcode(barcodeSvg)
			.options(jsBarcodeOpts) // Will affect all barcodes
			.EAN13(inputData.EAN13Value, jsBarcodeEan13Opts);

		if (inputData.EAN5Value && field.EAN5Addon) {
			jsBarcodeInst = jsBarcodeInst
				.blank(jsBarcodeBlank) // Create space between the barcodes
				.EAN5(inputData.EAN5Value, jsBarcodeEan5Opts);
		}
		jsBarcodeInst.render();
		// JsBarcode changes the width/height to absolute value (e.g. 215px), we need to change it back to "100%"
		barcodeSvg.setAttribute('width', '100%');
		barcodeSvg.setAttribute('height', '100%');

		// create raw SVG

		if (serverSideProcess.rawSVG && mode === ARTWORK_SERVER_SIDE_PROCESS) {
			serverSideProcess.rawSVG.append(svg.cloneNode(true)); // append (cloned) svg to rawSVG
		}

		return Promise.resolve();

		// return {
		// 	attrs,
		// 	EAN13Value: inputData.EAN13Value,
		// 	EAN5Value: inputData.EAN5Value,
		// 	jsBarcodeOpts,
		// 	jsBarcodeEan13Opts,
		// 	jsBarcodeBlank,
		// 	jsBarcodeEan5Opts,
		// };
	} else {
		// print mode
		if (!inputData.EAN13Value) return Promise.resolve();
		const svg = document.createElementNS(NS.SVG, 'svg');
		svg.setAttribute('x', field.position.left);
		svg.setAttribute('y', field.position.top);
		svg.setAttribute('width', field.position.width);
		svg.setAttribute('height', field.position.height);
		svg.setAttribute('overflow', 'visible');
		dataForExport.rootSVG.append(svg);

		const g = document.createElementNS(NS.SVG, 'g');
		g.setAttribute(
			'transform',
			`rotate(${field.position.angle}, ${field.position.width / 2}, ${field.position.height / 2})`
		);
		svg.append(g);

		const barcodeSvg = document.createElementNS(NS.SVG, 'svg');
		barcodeSvg.setAttribute('width', '100%');
		barcodeSvg.setAttribute('height', '100%');
		for (const attrKey in attrs) {
			barcodeSvg.setAttribute(attrKey, attrs[attrKey]);
		}
		g.append(barcodeSvg);

		let jsBarcodeInst = JsBarcode(barcodeSvg)
			.options(jsBarcodeOpts) // Will affect all barcodes
			.EAN13(inputData.EAN13Value, jsBarcodeEan13Opts);

		if (inputData.EAN5Value && field.EAN5Addon) {
			jsBarcodeInst = jsBarcodeInst
				.blank(jsBarcodeBlank) // Create space between the barcodes
				.EAN5(inputData.EAN5Value, jsBarcodeEan5Opts);
		}
		jsBarcodeInst.render();
		// JsBarcode changes the width/height to absolute value (e.g. 215px), we need to change it back to "100%"
		barcodeSvg.setAttribute('width', '100%');
		barcodeSvg.setAttribute('height', '100%');
		return Promise.resolve();
	}
};

/**
 * Prepare image field for preview(react) [return Object] | print (export to pdf) [return Promise] | svg (export to svg)
 * This function is to keep the logic of image field preparation in one place so that you don't need to change the same code in multiple places
 * NOTE: keep in mind the render part is still different in React & pdf generation.
 * @param {Object} opts
 * {
 * 		mode: 'preview'|'print'|'svg',
 * 		inputData: {	// data for output, combination of user input data & field default data
				// IMAGE Preview Data
				previewUrl: 'xxxxxxx', // preview url of this image. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
				optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
				highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			
			//	imageUrl: 'https://xxxxx', // undefined or original image url???
				croppedImgUrl: 'xxx', // web url or blob url of the cropped image (png format). Possible values: null/undefined, '', 'blob:https//xxx | https://xxxx'
				clippedImgUrl: 'https://xxx', // web url of the image (png format). Possible values: null/undefined, '', ' https://xxxx'
				horizontalAlign: 'left' | 'center' | 'right' | undefined,
				verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
 * 		},
 * 		field: Object, // the image field to be prepared
			dataForExport: {
				rootSVG: DOM element, // Mandatary,
				isPrintable, // Mandatary only in "svg" mode
				animationDelay: Number, //	Mandatary in "svg" & ARTWORK_SERVER_SIDE_PROCESS mode
			},
			serverSideProcess: { // optional, for ARTWORK_SERVER_SIDE_PROCESS mode only
				rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
			}
 * 		CONSTANTS: {
 * 			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION	// Mandatary only in 'svg' mode
 * 			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY	// Mandatary only in 'svg' mode
 * 		}
 * }
 *
 * @return {Object|Promise|null}.
 */
export const prepareImageField = ({
	mode,
	inputData = {},
	field,
	dataForExport = {},
	serverSideProcess = {},
	CONSTANTS = {},
}) => {
	if ((mode === 'print' || mode === 'svg') && !dataForExport.rootSVG) return null;
	if (
		mode === 'svg' &&
		(isNullish(dataForExport.isPrintable) || isNaN(dataForExport.animationDelay))
	)
		return null;

	if (
		mode === ARTWORK_SERVER_SIDE_PROCESS &&
		(!dataForExport.rootSVG || isNaN(dataForExport.animationDelay))
	)
		return null;

	const horAlign = inputData.horizontalAlign; // || field.horizontalAlign;
	const verAlign = inputData.verticalAlign; // || field.verticalAlign;
	// const previewImgUrl = inputData.croppedImgUrl || inputData.previewUrl; //|| inputData.imageUrl
	// const printImgUrl = inputData.croppedImgUrl || inputData.previewUrl; //|| inputData.imageUrl
	// const svgExportImgUrl = inputData.croppedImgUrl || inputData.imageUrl; //|| inputData.imageUrl

	let attrs = {};
	if (field.sizing === 'autocrop') {
		attrs.preserveAspectRatio = 'xMidYMid slice';
	} else if (field.sizing === 'fit') {
		let xAlign = 'xMin',
			yAlign = 'YMin';
		switch (horAlign) {
			case 'left':
				xAlign = 'xMin';
				break;
			case 'center':
				xAlign = 'xMid';
				break;
			case 'right':
				xAlign = 'xMax';
				break;
			default:
				break;
		}
		switch (verAlign) {
			case 'top':
				yAlign = 'YMin';
				break;
			case 'middle':
				yAlign = 'YMid';
				break;
			case 'bottom':
				yAlign = 'YMax';
				break;
			default:
				break;
		}
		attrs.preserveAspectRatio = `${xAlign}${yAlign} meet`;
	}

	if (mode === 'preview') {
		return {
			attrs,
			imgUrl: inputData.croppedImgUrl || inputData.clippedImgUrl || inputData.previewUrl,
		};
	} else if (mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) {
		// svg || ARTWORK_SERVER_SIDE_PROCESS mode
		// if no image src, nothing to export
		if (!inputData.croppedImgUrl && !inputData.clippedImgUrl && !inputData.optimisedUrl)
			return Promise.resolve();
		const svg = document.createElementNS(NS.SVG, 'svg');
		svg.setAttribute('x', field.position.left);
		svg.setAttribute('y', field.position.top);
		svg.setAttribute('width', field.position.width);
		svg.setAttribute('height', field.position.height);
		svg.setAttribute('overflow', 'visible');
		dataForExport.rootSVG.append(svg);

		// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
		const gAnimation = document.createElementNS(NS.SVG, 'g');
		const noAnimationStyle = `0s ease 0s 1 normal none running none`;
		const animationEntranceStyle = field.animation.entrance
			? `${field.animation.entrance} ${
					field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
			  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
					dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
			  }s both`
			: noAnimationStyle;
		gAnimation.setAttribute(
			'style',
			`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
		);
		svg.append(gAnimation);

		const g = document.createElementNS(NS.SVG, 'g');
		g.setAttribute(
			'transform',
			`rotate(${field.position.angle}, ${field.position.width / 2}, ${field.position.height / 2})`
		);
		gAnimation.append(g);

		const image = document.createElementNS(NS.SVG, 'image');
		image.setAttribute('width', '100%');
		image.setAttribute('height', '100%');
		image.setAttribute('x', '0');
		image.setAttribute('y', '0');
		for (const attrKey in attrs) {
			image.setAttribute(attrKey, attrs[attrKey]);
		}
		g.append(image);
		if (field.borderWidth > 0) {
			// border of the field
			const rect = createBorderRect(field);
			g.append(rect);
		}

		return new Promise((res, _rej) => {
			// set image source. priority to use image: croppedImgUrl > clippedImgUrl > optimisedUrl
			// NB: We use web url (optimised image url) when exporting to SVG. The web url of the image can be converted to data url in nested SVG (pdf) in case of printing
			// 		 When using it as nested SVG, the quality loss of optimised image is hardly noticable as nested item is usually smaller
			image.setAttribute(
				'href',
				inputData.croppedImgUrl || inputData.clippedImgUrl || inputData.optimisedUrl
			);

			// create raw SVG

			if (serverSideProcess.rawSVG && mode === ARTWORK_SERVER_SIDE_PROCESS) {
				serverSideProcess.rawSVG.append(svg.cloneNode(true)); // append (cloned) svg to rawSVG
			}
			res();
			// if (inputData.croppedImgUrl) {
			// 	image.setAttribute('href', inputData.croppedImgUrl);
			// 	res();
			// } else if (inputData.clippedImgUrl) {
			// 	image.setAttribute('href', inputData.clippedImgUrl);
			// 	res();
			// } else if (inputData.optimisedUrl) {
			// 	// We use web url (optimised image url) when exporting to SVG. The web url of the image can be converted to data url in nested SVG (pdf) in case of printing
			// 	// In case using it as nested SVG, the quality loss of optimised image is hardly noticable as nested item is usually smaller
			// 	// if (dataForExport.isPrintable) {
			// 	// 	return retrieveBase64DataUrl(inputData.optimisedUrl)
			// 	// 		.then(base64Url => {
			// 	// 			image.setAttribute('href', base64Url);
			// 	// 			res();
			// 	// 		})
			// 	// 		.catch(err => rej(err));
			// 	// } else {
			// 	image.setAttribute('href', inputData.optimisedUrl);
			// 	res();
			// 	// }
			// } else {
			// 	res();
			// }
		});
	} else {
		// print mode
		// if no image src, nothing to be printed to PDF
		if (!inputData.croppedImgUrl && !inputData.clippedImgUrl && !inputData.highResUrl)
			return Promise.resolve();
		const svg = document.createElementNS(NS.SVG, 'svg');
		svg.setAttribute('x', field.position.left);
		svg.setAttribute('y', field.position.top);
		svg.setAttribute('width', field.position.width);
		svg.setAttribute('height', field.position.height);
		svg.setAttribute('overflow', 'visible');
		dataForExport.rootSVG.append(svg);

		const g = document.createElementNS(NS.SVG, 'g');
		g.setAttribute(
			'transform',
			`rotate(${field.position.angle}, ${field.position.width / 2}, ${field.position.height / 2})`
		);
		svg.append(g);

		const image = document.createElementNS(NS.SVG, 'image');
		image.setAttribute('width', '100%');
		image.setAttribute('height', '100%');
		image.setAttribute('x', '0');
		image.setAttribute('y', '0');
		for (const attrKey in attrs) {
			image.setAttribute(attrKey, attrs[attrKey]);
		}
		g.append(image);
		if (field.borderWidth > 0) {
			// border of the field
			const rect = createBorderRect(field);
			g.append(rect);
		}

		return new Promise((res, rej) => {
			// retrieve image data. priority of using image: croppedImgUrl > clippedImgUrl > optimisedUrl
			// NB: inputData.croppedImgUrl here could be blobUrl or webUrl
			retrieveBase64DataUrl(
				inputData.croppedImgUrl || inputData.clippedImgUrl || inputData.highResUrl
			)
				.then((base64Url) => {
					image.setAttribute('href', base64Url);
					res();
				})
				.catch((err) => rej(err));
		});
	}
};

/**
 * Prepare concatenation image-only field for preview(react) [return Object] | print (export to pdf) [return Promise] | svg (export to svg) [return Promise]
 * This function is to keep the logic of concatenation image-only field preparation in one place so that you don't need to change the same code in multiple places
 * NB1: keep in mind the render part is still different in React & pdf generation.
 * NB2: the caller to this func needs to handle error
 * NB3: We decided on 25/03/2021 that cropped image is not used in concatenation field, we always use the original image
 * @param {Object} opts
 * {
 * 		mode: 'preview'|'print'|'svg',
 * 		field: Object, // the text field to be prepared
 * 		templateFields: array, // array of template fields
 * 		fieldOutputData: object, // all fields output data. Combination of user input data and field default data
			dataForExport: {
				rootSVG: DOM element, // Mandatary. This is the root of the whole template, it contains all fields' svg
				isPrintable: Boolean, // Mandatary in print & svg mode. this is for "hasShadow" option, "shadow" doesn't apply to concatenation field, but keep it here to make this func similar to prepareTextField func
				fontList: Array,	// [{name: 'xxx', fontUrl: 'xxxx'}, ...]. Mandatary
				animationDelay: NUMBER, // Mandatary in 'svg' & ARTWORK_SERVER_SIDE_PROCESS mode
			}
			serverSideProcess: { // optional, for ARTWORK_SERVER_SIDE_PROCESS mode only
				rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
			}
 * 		CONSTANTS: {
 * 			placeholderSameAsText: ART_VARIABLES.placeholderSameAsText, // Mandatary
 * 			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION	// Mandatary only in 'svg' mode
 * 			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY	// Mandatary only in 'svg' mode
 * 		}
 * }
 *
 * @return {Promise}. object|null in preview mode, null in svg/print mode
 * NB (again): the caller to this func needs to catch (promise) error
	return object in preview mode:
	{
		images: [{ id: field_id, attrs: {x: 0, y: 0, href: 'xxx'} }, ...],
		gContentScale,
	}
 */
export const prepareConcatImageField = async ({
	mode,
	field,
	templateFields,
	fieldOutputData,
	dataForExport = {},
	serverSideProcess = {},
	CONSTANTS,
}) => {
	if (
		(mode === 'print' || mode === 'svg') &&
		(!dataForExport.rootSVG || !dataForExport.fontList || isNullish(dataForExport.isPrintable))
	)
		return null;

	if (mode === 'svg' && isNaN(dataForExport.animationDelay)) return null;
	if (
		mode === ARTWORK_SERVER_SIDE_PROCESS &&
		(!dataForExport.rootSVG || !dataForExport.fontList || isNaN(dataForExport.animationDelay))
	)
		return null;

	const inputData = fieldOutputData[field.id];
	const horAlign = inputData.horizontalAlign; // we treat "justified" as "left"
	const verAlign = inputData.verticalAlign;
	const originalText = inputData.value.trim();
	if (!originalText) return null; // no concatenation string, nothing to display

	// get all embeded image fields
	let embededFieldIds = (originalText.match(/<field:\s*(.*?)\s*>/gim) || []).map(
		(match) => match.substring(match.lastIndexOf('_') + 1).slice(0, -1)
		// match
		// 	.split('_')
		// 	.pop()
		// 	.slice(0, -1)
	);

	/**
		 * embededImageFields holds all image fields and image attrs
		 * format
			[
				{
					field: {},
					imageNaturalSize: {
						naturalWidth: num,
						naturalHeight: num
					},
					imageAttrs: {}
				},
				...
			]
		 */
	let embededImageFields = [];
	// find embeded image files by embeded field id
	embededFieldIds.forEach((fieldId) => {
		let foundField = templateFields.find((f) => f.id === fieldId);
		if (
			foundField &&
			foundField.type === 'image' &&
			fieldOutputData[foundField.id].previewUrl &&
			fieldOutputData[foundField.id].optimisedUrl &&
			fieldOutputData[foundField.id].highResUrl
		) {
			embededImageFields.push({
				field: foundField,
			});
		}
	});

	if (embededImageFields.length === 0) return null; // no embeded image field found, nothing to render

	// get imageNaturalSize in every embeded image field.
	// the natural size is used to calculate image width/height ratio, and use ratio to calculate the actual render size in the container box.
	// therefore, it doesn't matter what image url to use. We use (smaller) previewUrl for this purpose to slightly improve the performance
	embededImageFields = await Promise.all(
		embededImageFields.map(async (embededImageField) => {
			let imageNaturalSize = await getImageSize(
				fieldOutputData[embededImageField.field.id].previewUrl
			);
			return { ...embededImageField, imageNaturalSize };
		})
	);

	// create image attributes for each embeded image
	let fieldBoxPos = field.position.width > field.position.height ? 'landscape' : 'portrait',
		embededImageX = 0, // this is also the total width of all embeded images if field box is in landscape
		embededImageY = 0; // this is also the total height of all embeded images if field box is in portrait
	// embededImageFields = embededImageFields.map(embededImageField => {
	embededImageFields.forEach((embededImageField) => {
		let imageNaturalSize = embededImageField.imageNaturalSize;
		let imageAttrs = {
			preserveAspectRatio: `xMinYMin meet`,
		};
		if (fieldBoxPos === 'landscape') {
			// field box is in landscape, we use field height as image height
			imageAttrs.height = field.position.height;

			imageAttrs.width = roundDecimals(
				(imageNaturalSize.naturalWidth / imageNaturalSize.naturalHeight) * field.position.height
			);
			imageAttrs.x = embededImageX;
			imageAttrs.y = embededImageY;
			embededImageX += imageAttrs.width;
		} else {
			// field box is in portrait, we use field width as image width
			imageAttrs.width = field.position.width;

			imageAttrs.height = roundDecimals(
				(imageNaturalSize.naturalHeight / imageNaturalSize.naturalWidth) * field.position.width
			);
			imageAttrs.x = embededImageX;
			imageAttrs.y = embededImageY;
			embededImageY += imageAttrs.height;
		}
		// NB1: this is the place to decide which image to be used in different mode
		// NB2: We decided on 25/03/2021 that cropped image is not used in concatenation field, we always use the original image
		// NB3: we use highResUrl as default (for print) in ARTWORK_SERVER_SIDE_PROCESS mode
		imageAttrs.href =
			mode === 'print' || mode === ARTWORK_SERVER_SIDE_PROCESS
				? fieldOutputData[embededImageField.field.id].highResUrl
				: mode === 'svg'
				? fieldOutputData[embededImageField.field.id].optimisedUrl
				: fieldOutputData[embededImageField.field.id].previewUrl;

		embededImageField.imageAttrs = imageAttrs;
		// return { ...embededImageField, imageNaturalSize, imageAttrs };
	});

	let gContentScale = 1;
	if (!field.doNotSetToFit) {
		// fit images to the container
		// Note:  in previous step, we use field width or height (based on field shape is landscape or portrait) as the image height or width
		// 				that means the images are fit on either width or height already
		//				to fit the whole images, we will only scale down, NOT scale up
		// 				means gContentScale will be less or equal to 1
		gContentScale =
			embededImageX !== 0
				? roundDecimals(field.position.width / embededImageX, 2)
				: embededImageY !== 0
				? roundDecimals(field.position.height / embededImageY, 2)
				: gContentScale;
		gContentScale = gContentScale > 1 ? 1 : gContentScale;

		// set alignment of embeded images. We only consider alignment when fit=true
		let widthOfAllImages = embededImageX === 0 ? field.position.width : embededImageX;
		let heightOfAllImages = embededImageY === 0 ? field.position.height : embededImageY;
		let widthOfAllImagesAfterScale = widthOfAllImages * gContentScale;
		let heightOfAllImagesAfterScale = heightOfAllImages * gContentScale;
		let xAlignOffset = 0,
			yAlignOffset = 0;
		// Keep in mind, the preserveAspectRatio of image attribute is always `xMinYMin meet` (top-left), and we treat horAlign "justified" as "left"

		if (
			(fieldBoxPos === 'landscape' && gContentScale === 1) ||
			(fieldBoxPos === 'portrait' && gContentScale < 1)
		) {
			switch (horAlign) {
				case 'left':
					xAlignOffset = 0;
					break;
				case 'center':
					xAlignOffset =
						Math.abs(field.position.width - widthOfAllImagesAfterScale) / 2 / gContentScale;
					break;
				case 'right':
					xAlignOffset =
						Math.abs(field.position.width - widthOfAllImagesAfterScale) / gContentScale;
					break;
				default:
					break;
			}
		} else {
			switch (verAlign) {
				case 'top':
					yAlignOffset = 0;
					break;
				case 'middle':
					yAlignOffset =
						Math.abs(field.position.height - heightOfAllImagesAfterScale) / 2 / gContentScale;
					break;
				case 'bottom':
					yAlignOffset =
						Math.abs(field.position.height - heightOfAllImagesAfterScale) / gContentScale;
					break;
				default:
					break;
			}
		}
		// update x, y in image attrs to make the alignment
		embededImageFields.forEach((imgField) => {
			imgField.imageAttrs.x += xAlignOffset;
			imgField.imageAttrs.y += yAlignOffset;
		});
	}

	if (mode === 'preview') {
		return {
			images: embededImageFields.map((imgF) => ({ id: imgF.field.id, attrs: imgF.imageAttrs })),
			gContentScale,
		};
	}
	// create svg of this field for svg/print/ARTWORK_SERVER_SIDE_PROCESS mode
	let svg = document.createElementNS(NS.SVG, 'svg');
	svg.setAttribute('x', field.position.left);
	svg.setAttribute('y', field.position.top);
	svg.setAttribute('width', field.position.width);
	svg.setAttribute('height', field.position.height);
	svg.setAttribute('overflow', 'visible');
	// We don't append it to document, because we don't use any DOM elem
	// document.body.append(svg);

	let gAnimation = null; // to hold animation styles in "svg" & ARTWORK_SERVER_SIDE_PROCESS mode

	if (mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) {
		// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
		gAnimation = document.createElementNS(NS.SVG, 'g');
		const noAnimationStyle = `0s ease 0s 1 normal none running none`;
		const animationEntranceStyle = field.animation.entrance
			? `${field.animation.entrance} ${
					field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
			  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
					dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
			  }s both`
			: noAnimationStyle;
		gAnimation.setAttribute(
			'style',
			`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
		);
	}

	// <g> for content. hold <image> in any mode
	const gContent = document.createElementNS(NS.SVG, 'g');

	if (gAnimation) {
		gAnimation.append(gContent);
		svg.append(gAnimation);
	} else {
		svg.append(gContent);
	}

	// at this point, we have the best fit scale "gContentScale"
	gContent.setAttribute(
		'transform',
		`rotate(${field.position.angle}, ${field.position.width / 2}, ${
			field.position.height / 2
		}) scale(${gContentScale})`
	);

	if (mode === 'print') {
		// in print mode, we use image data url insead of web url
		await Promise.all(
			embededImageFields.map(async (embededImageField) => {
				// in print mode, we use base64 data url
				embededImageField.imageAttrs.href = await retrieveBase64DataUrl(
					embededImageField.imageAttrs.href
				);
			})
		);
	}

	if (mode === ARTWORK_SERVER_SIDE_PROCESS) {
		let { rawSVG } = serverSideProcess;
		let gContentRawSVG = undefined;
		if (rawSVG) {
			let svgForRawSVG = svg.cloneNode(true);

			gContentRawSVG = svgForRawSVG.children[0].children[0];
			rawSVG.append(svgForRawSVG); // append (cloned) svg to rawSVG
		}

		// append embeded images to gContent & gContentRawSVG
		// NB: the href in imageAttrs is using highResUrl at this point in ARTWORK_SERVER_SIDE_PROCESS mode
		//		 we will use optimisedUrl for rawSVG
		embededImageFields.forEach((embededImageField) => {
			const embededImageElem = document.createElementNS(NS.SVG, 'image');
			setDomAttrs(embededImageElem, embededImageField.imageAttrs);
			gContent.append(embededImageElem);
			// if we need rawSVG, use optimisedUrl
			if (gContentRawSVG) {
				const embededImageElemForRawSVG = document.createElementNS(NS.SVG, 'image');
				setDomAttrs(embededImageElemForRawSVG, {
					...embededImageField.imageAttrs,
					href: fieldOutputData[embededImageField.field.id].optimisedUrl,
				});
				gContentRawSVG.append(embededImageElemForRawSVG);
			}
		});
	} else {
		// append embeded images to gContent
		embededImageFields.forEach((embededImageField) => {
			const embededImageElem = document.createElementNS(NS.SVG, 'image');
			setDomAttrs(embededImageElem, embededImageField.imageAttrs);
			gContent.append(embededImageElem);
		});
	}

	// svg.remove(); // No need to remove it from document.body, as we didn't append it
	dataForExport.rootSVG.append(svg);
};

/**
 * Prepare Grid field for "preview" (react) [return Object] | "print" (export to pdf) [return Promise] | "svg" (export to svg) [return Promise]
 * This function is to keep the logic of barcode field preparation in one place so that you don't need to change the same code in multiple places
 * 
 * NB1: keep in mind the render is different between React, svg & pdf generation.
 * NB2: ??? (Maybe just use . for empty title cells???) In case title cells have no text (very rare case), using \u200B in title cells will print a dot in the generated PDF
 * 
 * @param {Object} opts
 * {
   		mode: 'preview'|'print'|'svg',
  		inputData: {	// data for output, combination of user input data & field default data
				// Grid Render Data
				// tableData format: [RowData, RowData, ...]; RowData: [cellText, cellText, ...]; cellText may be empty string, may contain multiple lines by '\n'. Get non-empty lines by `.split(/\r?\n|\r/g).filter((s) => s)`
				// sample data of tableData: [["Product","Small","Large"],["Cappuccino\n\nsubtitle","€1.99\n\nsubtitle","€2.99"],["Mocha","€2.49","€3.99"]]
			 	tableData: [] | [["Product","Small","Large"],["Cappuccino\n\ngghgh","€1.99\n\nnew line","€2.99"],["Mocha","€2.49","€3.99"]],
				hasHeader: true/false,
				// editorHtml: '' | '<table>xxxx</table>, // possible values: null/undefined, 'xxxx'
				fontsize:  null|undefined|20, // possible values: null/undefined, number. user defined font size
  		},
  		field: Object, // the grid field
  		dataForExport:{ // required in "print" & "svg" mode
				rootSVG: DOM element, // Mandatary in "print" & "svg" & ARTWORK_SERVER_SIDE_PROCESS mode
				animationDelay: Number, // Mandatary in 'svg' & ARTWORK_SERVER_SIDE_PROCESS mode
			},
			serverSideProcess: { // optional, for ARTWORK_SERVER_SIDE_PROCESS mode only
				rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
			}
  		CONSTANTS: {
				placeholderSameAsText: ART_VARIABLES.placeholderSameAsText // Mandatary in all modes
  			DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION	// Mandatary only in 'svg' mode
  			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY	// Mandatary only in 'svg' mode
  		}
  }
 *
 * @return {Object|Promise|null}.
 * object to return:
		{
			headerRowCells, // undefined or {}. See below for details
			contentRowCells, // undefined or []. See below for details
			ruleLines, // array. Could be empty array. See below for details
			scale,	// number, transform scale value for the field. 0.1 =< number <= 1
		}

		headerRowCells is undefined or object as below (attrs in cellsTspan contains x, text-anchor & dy)
	{
		rowText: { attrs: textAttrs },
		cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: tspan.textContent }, ...],
	}

	 contentRowCells is undefined or array as below (attrs in content & subtitle contains x & text-anchor; final positioned data [array of wrapped lines] (could be null/undefined) is added to content & subtitle )
	 [
		{
			rowText: { attrs: textAttrs },
			cellsTspan: [
				{
					content: { attrs: contentTspanAttrs, 	bBox: bBox,  cellWidth: cellWidth, textContent: contentTspan.textContent, positioned: null | [{ attrs: { ...contentData.attrs, dy: `Xem`, }, textContent: lineText, }, ...]},
					subtitle: { attrs: subtitleTspanAttrs, bBox: bBox,  cellWidth: cellWidth, textContent: subtitleTspan.textContent, positioned: null | [{ attrs: { ...subtitleData.attrs, dy: `Xem`, }, textContent: lineText, }, ...]},
				},
				...
			],
		},
		...
	]

	ruleLines:
	[
		{
			attrs: { x1: num, y1: num, x2: num, y2: num, stroke: color.hex, 'stroke-width': num_in_pixel}
		},
		...
	]
 */
export const prepareGridField = ({
	mode,
	inputData = {},
	field,
	dataForExport = {},
	serverSideProcess = {},
	CONSTANTS = {},
}) => {
	if (
		(mode === 'print' || mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		!dataForExport.rootSVG
	)
		return null;
	if (
		(mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) &&
		isNaN(dataForExport.animationDelay)
	)
		return null;
	const { tableData, hasHeader, fontsize: globalFontsize } = inputData;
	if (tableData.length === 0) return null;

	// prepare for grid input data
	// on 14/05/2021, Pier decidesthat the output should be exactly same as user's input, including empty row and rules.
	// that means, empty row will retain its space, if rule is enable, the rule should be draw even it is empty row.
	const fillingStr = 'a'; // used to fill up empty cell
	// headerRowData sample: ["Product","Small","Large"]. NB: value could be ''
	const headerRowData = hasHeader ? tableData[0] : []; // tableData[0].map(() => '') is to guarantee headerRowData is not empty array
	// fill dummy string to empty cell
	const headerRowDataWithFillingText = headerRowData.map((str) => (str ? str : fillingStr));
	// // (discard) following code ignores empty rows
	// let isHeaderRowEmpty = /* headerRowData.length > 0 && */ !headerRowData.find((str) => str);
	// const headerRowDataWithFillingText = isHeaderRowEmpty
	// 	? headerRowData
	// 	: headerRowData.map((str) => (str ? str : fillingStr));
	// // (discard) following code only fill dummy string in first & last cells
	// const headerRowDataWithFillingText = isHeaderRowEmpty
	// 	? headerRowData
	// 	: headerRowData.map((str, idx) =>
	// 			!str && (idx === 0 || idx === headerRowData.length - 1) ? fillingStr : str
	// 	  );

	// contentRowsData sample: [["Cappuccino\n\ngghgh","€1.99\n\nnew line","€2.99"],["Mocha","€2.49","€3.99"]],
	const contentRowsData = hasHeader ? tableData.slice(1) : tableData.slice(0);
	// fill dummy string to empty cell
	const contentRowsDataWithFillingText = contentRowsData.map((rowData) => {
		return rowData.map((str) => (str ? str : fillingStr));
		// // following code ignores empty rows
		// const isRowEmpty = /* rowData.length > 0 && */ !rowData.find((str) => str);
		// return isRowEmpty ? rowData : rowData.map((str) => (str ? str : fillingStr));
	});
	// // following code only fills dummy string in first & last cells
	// const contentRowsDataWithFillingText = contentRowsData.map((rowData) => {
	// 	const isRowEmpty = rowData.length > 0 && !rowData.find((str) => str);
	// 	return isRowEmpty
	// 		? rowData
	// 		: rowData.map((str, idx) =>
	// 				!str && (idx === 0 || idx === rowData.length - 1) ? fillingStr : str
	// 		  );
	// });

	// prepare for rule (underline) configuration
	const headerRuleConf =
		hasHeader && field.headerRule > 0
			? { lineWidth: field.headerRule, color: field.headerRuleColor }
			: null;
	const rowsRuleConf =
		field.bodyRowsRule > 0
			? { lineWidth: field.bodyRowsRule, color: field.bodyRowsRuleColor }
			: null;

	const pdfPtToWebPixel = pdfPtToPixel / PIXEL_UNIT_MAP.pt;
	// prepare area styles. fontsize is converted to web pt.
	const titleHeaderConf = {
		fontfaceName: field.titleHeaderFontfaceName,
		fontsize: (globalFontsize || field.titleHeaderFontsize) * pdfPtToWebPixel, // convert fontsize from pdf pt to web pt.
		horAlign: 'left', // possible value: 'left', 'center', 'right'
	};
	const titleCellConf = {
		fontfaceName:
			CONSTANTS.placeholderSameAsText === field.titleCellFontfaceName
				? titleHeaderConf.fontfaceName
				: field.titleCellFontfaceName,
		fontsize: globalFontsize
			? globalFontsize * pdfPtToWebPixel
			: CONSTANTS.placeholderSameAsText === field.titleCellFontsize
			? titleHeaderConf.fontsize
			: field.titleCellFontsize * pdfPtToWebPixel, // convert fontsize from pdf pt to web pt.
		subtitleFontsizeScale: field.titleCellSubtitleFontsizeScale,
		horAlign: 'left',
	};
	titleCellConf.subtitleFontsize = titleCellConf.fontsize * field.titleCellSubtitleFontsizeScale;
	const valueHeaderConf = {
		fontfaceName:
			CONSTANTS.placeholderSameAsText === field.valueHeaderFontfaceName
				? titleHeaderConf.fontfaceName
				: field.valueHeaderFontfaceName,
		fontsize: globalFontsize
			? globalFontsize * pdfPtToWebPixel
			: CONSTANTS.placeholderSameAsText === field.valueHeaderFontsize
			? titleHeaderConf.fontsize
			: field.valueHeaderFontsize * pdfPtToWebPixel, // convert fontsize from pdf pt to web pt.
		horAlign: 'right',
	};
	const valueCellConf = {
		fontfaceName:
			CONSTANTS.placeholderSameAsText === field.valueCellFontfaceName
				? titleHeaderConf.fontfaceName
				: field.valueCellFontfaceName,
		fontsize: globalFontsize
			? globalFontsize * pdfPtToWebPixel
			: CONSTANTS.placeholderSameAsText === field.valueCellFontsize
			? titleHeaderConf.fontsize
			: field.valueCellFontsize * pdfPtToWebPixel, // convert fontsize from pdf pt to web pt.
		subtitleFontsizeScale: field.valueCellSubtitleFontsizeScale,
		horAlign: 'right',
	};
	valueCellConf.subtitleFontsize = valueCellConf.fontsize * field.valueCellSubtitleFontsizeScale;

	// contants
	// horizontal padding between columns
	const paddingBetweenCellsEM = 0.5;
	// minimum padding between cells in pixel, in case of scaling down too much
	const minPaddingBetweenCells = 40;
	// padding (in pixel) to leftmost (first column) & rightmost (last column)
	const paddingLeftMost = 0,
		paddingRightMost = 0;
	// if the rule (underline) even or not
	const isEventRule = true;

	// use the "fill-up" dummy grid data to find the best scale & rowDyScale for the grid to fit into field container.
	// Also get the ruleLines for the fitted grid (ruleLines is array of lines, could be empty array)
	const { scale, rowDyScale, ruleLines } = fitGridToContainer({
		field,
		headerRowData: headerRowDataWithFillingText,
		contentRowsData: contentRowsDataWithFillingText,
		titleHeaderConf,
		valueHeaderConf,
		titleCellConf,
		valueCellConf,
		headerRuleConf,
		rowsRuleConf,
		isEventRule,
		paddingBetweenCellsEM, // number. Number of em, e.g. 1em or 0.5em
		minPaddingBetweenCells,
		paddingLeftMost,
		paddingRightMost,
	});

	// we found the best scale & rowDyScale, now let's use the actual grid data to build cells for final output
	// headerRowCells is undefined or {}, contentRowCells is undefined or [{}, ...]
	const { headerRowCells, contentRowCells } = buildGridCells({
		field,
		headerRowData,
		contentRowsData,
		scale,
		rowDyScale,
		titleHeaderConf,
		valueHeaderConf,
		titleCellConf,
		valueCellConf,
		paddingBetweenCellsEM, // number. Number of em, e.g. 1em or 0.5em. Horizontal only
		minPaddingBetweenCells, // in pixel
		paddingLeftMost,
		paddingRightMost,
	});

	if (mode === 'preview') {
		return { headerRowCells, contentRowCells, ruleLines, scale };
	} else {
		// svg & print mode
		const svg = document.createElementNS(NS.SVG, 'svg');
		svg.setAttribute('x', field.position.left);
		svg.setAttribute('y', field.position.top);
		svg.setAttribute('width', field.position.width);
		svg.setAttribute('height', field.position.height);
		svg.setAttribute('overflow', 'visible');
		dataForExport.rootSVG.append(svg);

		const gContent = document.createElementNS(NS.SVG, 'g');
		gContent.setAttribute(
			'transform',
			`rotate(${field.position.angle}, ${field.position.width / 2}, ${
				field.position.height / 2
			}) scale(${scale})`
		);

		if (mode === 'svg' || mode === ARTWORK_SERVER_SIDE_PROCESS) {
			// <g> for animation only. Somehow, "transform" not working when together with "style" in the same <g>
			const gAnimation = document.createElementNS(NS.SVG, 'g');
			const noAnimationStyle = `0s ease 0s 1 normal none running none`;
			const animationEntranceStyle = field.animation.entrance
				? `${field.animation.entrance} ${
						field.animation.duration || CONSTANTS.DEFAULT_ANIMATION_DURATION
				  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
						dataForExport.animationDelay || CONSTANTS.DEFAULT_ANIMATION_DELAY
				  }s both`
				: noAnimationStyle;
			gAnimation.setAttribute(
				'style',
				`animation: ${field.animation.entrance ? animationEntranceStyle : noAnimationStyle}`
			);
			gAnimation.append(gContent);
			svg.append(gAnimation);
		} else {
			// mode = 'print'
			svg.append(gContent);
		}

		return Promise.resolve().then(async () => {
			// append header to gContent
			if (headerRowCells) {
				const headerRowText = document.createElementNS(NS.SVG, 'text');
				setDomAttrs(headerRowText, headerRowCells.rowText.attrs);
				gContent.append(headerRowText);
				headerRowCells.cellsTspan.forEach((cell) => {
					const headerCellTspan = document.createElementNS(NS.SVG, 'tspan');
					headerCellTspan.textContent = cell.textContent;
					setDomAttrs(headerCellTspan, cell.attrs);
					headerRowText.appendChild(headerCellTspan);
				});
			}
			// append content body to gContent
			if (contentRowCells) {
				contentRowCells.forEach((contentRow) => {
					const contentRowText = document.createElementNS(NS.SVG, 'text');
					setDomAttrs(contentRowText, contentRow.rowText.attrs);
					gContent.append(contentRowText);
					contentRow.cellsTspan.forEach((cell) => {
						(cell.content.positioned || []).forEach((line) => {
							const contentCellTspan = document.createElementNS(NS.SVG, 'tspan');
							contentCellTspan.textContent = line.textContent;
							setDomAttrs(contentCellTspan, line.attrs);
							contentRowText.appendChild(contentCellTspan);
						});

						(cell.subtitle.positioned || []).forEach((line) => {
							const subtitleCellTspan = document.createElementNS(NS.SVG, 'tspan');
							subtitleCellTspan.textContent = line.textContent;
							setDomAttrs(subtitleCellTspan, line.attrs);
							contentRowText.appendChild(subtitleCellTspan);
						});
					});
				});
			}
			// append rule (underlines) to gContent
			ruleLines.forEach((line) => {
				const underline = document.createElementNS(NS.SVG, 'line');
				setDomAttrs(underline, line.attrs);
				gContent.append(underline);
			});

			// create raw SVG

			if (serverSideProcess.rawSVG && mode === ARTWORK_SERVER_SIDE_PROCESS) {
				serverSideProcess.rawSVG.append(svg.cloneNode(true)); // append (cloned) svg to rawSVG
			}
		});
	}

	// // alignment in areas: left, center, right
	// const titleHeaderHorAlign = 'left',
	// 	valueHeaderHorAlign = 'right',
	// 	titleCellHorAlign = 'left',
	// 	valueCellHorAlign = 'right';
	// let scale = 1;
	// // use 0.5em for the paddingRight: find the max font-size (in pt) in the (value) cells, convert pt to px
	// const maxFontSizeInValueColumns = Math.max(valueHeaderConf.fontsize, valueCellConf.fontsize); // area 3.2 & 3.4
	// const paddingBetweenCells = maxFontSizeInValueColumns * PIXEL_UNIT_MAP.pt * scale * 0.5; // 0.5em in pixel

	// let headerRowCells, contentRowCells;
	// if (headerRowData.length > 0) {
	// 	headerRowCells = buildGridHeaderCells({
	// 		field,
	// 		titleHeaderConf,
	// 		valueHeaderConf,
	// 		headerRowData,
	// 		scale,
	// 	});
	// }
	// if (contentRowsData.length > 0) {
	// 	contentRowCells = buildGridContentRowCells({
	// 		field,
	// 		titleCellConf,
	// 		valueCellConf,
	// 		contentRowsData,
	// 		scale,
	// 	});
	// }
	// /**
	//  * At this point.
	//  headerRowCells is null or object as below
	// {
	// 	rowText: { attrs: textAttrs },
	// 	cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, textContent: tspan.textContent }, ...],
	// }

	//  contentRowCells is null or array as below
	//  [
	// 	{
	// 		rowText: { attrs: textAttrs },
	// 		cellsTspan: [
	// 			{
	// 				content: { attrs: contentTspanAttrs, 	bBox: bBox, textContent: contentTspan.textContent,},
	// 				subtitle: { attrs: subtitleTspanAttrs, bBox: bBox, textContent: subtitleTspan.textContent,},
	// 			},
	// 			...
	// 		],
	// 	},
	// 	...
	// ]
	//  */

	// // calculate the horizontal padding between non-title cells

	// // update headerRowCells & contentRowCells with the horizontal position & size of cells,
	// // from right to left as we want each row to expand to end and give max space to the first column
	// setCellHorPosSize({
	// 	headerRowCells,
	// 	contentRowCells,
	// 	field,
	// 	paddingBetweenCells,
	//  paddingLeftMost,
	// 	paddingRightMost,
	// 	scale, // always <=1;
	// 	titleHeaderHorAlign,
	// 	valueHeaderHorAlign,
	// 	titleCellHorAlign,
	// 	valueCellHorAlign,
	// });

	// /**
	//  * At this point.
	//  headerRowCells is null or object as below (attrs now contains x & text-anchor)
	// {
	// 	rowText: { attrs: textAttrs },
	// 	cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: tspan.textContent }, ...],
	// }

	//  contentRowCells is null or array as below (attrs now contains x & text-anchor)
	//  [
	// 	{
	// 		rowText: { attrs: textAttrs },
	// 		cellsTspan: [
	// 			{
	// 				content: { attrs: contentTspanAttrs, 	bBox: bBox,  cellWidth: cellWidth, textContent: contentTspan.textContent,},
	// 				subtitle: { attrs: subtitleTspanAttrs, bBox: bBox,  cellWidth: cellWidth, textContent: subtitleTspan.textContent,},
	// 			},
	// 			...
	// 		],
	// 	},
	// 	...
	// ]
	//  */

	// // set the vertical y position of cells, including text-wrap
	// const rowDyScale = 2; // min 1, only scale up
	// setCellVerPos({
	// 	headerRowCells,
	// 	contentRowCells,
	// 	scale, // always <=1;
	// 	rowDyScale,
	// 	titleHeaderConf,
	// 	valueHeaderConf,
	// 	titleCellConf,
	// 	valueCellConf,
	// });

	// /**
	//  * at this point, we got final output
	//  headerRowCells is null or object as below (attrs now contains x, text-anchor & dy)
	// {
	// 	rowText: { attrs: textAttrs },
	// 	cellsTspan: [{ attrs: tspanAttrs, bBox: bBox, cellWidth: cellWidth, textContent: tspan.textContent }, ...],
	// }

	//  contentRowCells is null or array as below (attrs in now contains x & text-anchor, final positioned data (can be null/undefined) is added to content & subtitle )
	//  [
	// 	{
	// 		rowText: { attrs: textAttrs },
	// 		cellsTspan: [
	// 			{
	// 				content: { attrs: contentTspanAttrs, 	bBox: bBox,  cellWidth: cellWidth, textContent: contentTspan.textContent, positioned: null | [{ attrs: { ...contentData.attrs, dy: `Xem`, }, textContent: lineText, }, ...]},
	// 				subtitle: { attrs: subtitleTspanAttrs, bBox: bBox,  cellWidth: cellWidth, textContent: subtitleTspan.textContent, positioned: null | [{ attrs: { ...subtitleData.attrs, dy: `Xem`, }, textContent: lineText, }, ...]},
	// 			},
	// 			...
	// 		],
	// 	},
	// 	...
	// ]
	//  */

	// // // create svg of this field
	// // let svg = document.createElementNS(NS.SVG, 'svg');
	// // // svg.setAttribute('viewBox', `0 0 ${field.position.width} ${field.position.height}`);
	// // svg.setAttribute('x', field.position.left);
	// // svg.setAttribute('y', field.position.top);
	// // svg.setAttribute('width', field.position.width);
	// // svg.setAttribute('height', field.position.height);
	// // svg.setAttribute('overflow', 'visible');
	// return { headerRowCells, contentRowCells, scale };
};
