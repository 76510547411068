import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import { CircularProgress, Typography, Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	back: {
		color: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700],
		position: 'absolute',
	},
}));

function CircularProgressLabelled({ label, value, ...rest }) {
	const classes = useStyles();
	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress className={classes.back} variant="determinate" {...rest} value={100} />
			<CircularProgress variant="determinate" value={value} {...rest} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography variant="caption" component="div" color="textSecondary">
					{label}
				</Typography>
			</Box>
		</Box>
	);
}

CircularProgressLabelled.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 */
	value: PropTypes.number.isRequired,
	/**
	 * The label to be displayed inside circular progress.
	 */
	label: PropTypes.string.isRequired,

	// for other props, see document at: https://material-ui.com/api/circular-progress/
};

CircularProgressLabelled.defaultProps = {};

export default CircularProgressLabelled;
