/*
 *
 * LanguageProvider reducer
 *
 */
import { getLocale, setLocale } from 'utils/libHelper';
import { CHANGE_LOCALE } from 'redux/actions';
import { DEFAULT_LOCALE, AppLocales } from 'lang';

let initLocale = DEFAULT_LOCALE;
if (navigator) {
	let navLocale = (navigator.language || navigator.browserLanguage).substring(0, 2);
	if (AppLocales[navLocale]) {
		// ok, we support the browser locale, use it
		initLocale = navLocale;
	}
}
const initialState = {
	locale: getLocale() || initLocale,
};

function lang(state = initialState, action) {
	switch (action.type) {
		case CHANGE_LOCALE:
			setLocale(action.locale);
			return {
				...state,
				locale: action.locale,
			};
		default:
			return state;
	}
}

export { lang };

export default lang;
