import React, { useState } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import config from 'config';

import Grid from '@mui/material/Grid';
import LoginForm from './LoginForm/LoginForm';
import ForgetPWForm from './ForgetPWForm/ForgetPWForm';

// Utils
import { getDomainByHostname, getDomainConfig } from 'utils/appHelper';

// CSS
import { default as useStyles } from './styles.jsx';

// redux
import { connect } from 'react-redux';
import { userLoginAuth } from 'redux/actions'; // actions

// eslint-disable-next-line no-unused-vars
function Login({ userLoginAuth, location, history, ...rest }) {
	let domainConfig = getDomainConfig();
	const classes = useStyles({ BGImage: domainConfig.loginBG });
	let [forgetPW, setforgetPW] = useState(false);
	let [errorMsg, setErrorMsg] = useState('');
	let [errStatusCode, setErrStatusCode] = useState(null);
	const handleLoginClick = (opts) => {
		let domainNameByHost = getDomainByHostname();
		let reqData = {
			domain: domainNameByHost,
			username: opts.username,
			password: opts.password,
		};
		return userLoginAuth({ reqData }, ({ result, error }) => {
			if (error) {
				let errorMsgz = error.response
					? error.response.data.message || error.response.data.error || error.response.data.detail
					: error.message;
				let errorStatusCode = error.response
					? error.response.data.status || error.response.data.error || error.response.data.detail
					: error.status;
				setErrorMsg(errorMsgz);
				setErrStatusCode(errorStatusCode);
				return;
			}
			if (result.status === 200) {
				localStorage.setItem(config.authCookieKey, result.data.accessToken);
				let visitPath = location.state ? location.state[config.visitLocationKey] || '/' : '/';
				history.push(visitPath);
			}
		});
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.container}>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item xs={12} sm={12} md={forgetPW ? 8 : 6}>
						<LoginForm
							// Need to add Forgot password code in future
							// handleForgetPW={() => setforgetPW(true)}
							errorMessage={errorMsg}
							errorStatusCode={errStatusCode}
							handleLogin={handleLoginClick}
							logo={domainConfig.logo}
							className={cx({
								[classes.animSlideInEllipticTopFwd]: !forgetPW,
								[classes.formHidden]: forgetPW,
							})}
						/>
						<ForgetPWForm
							clickToLogin={() => setforgetPW(false)}
							logo={domainConfig.logo}
							className={cx({
								[classes.animSlideInEllipticTopFwd]: forgetPW,
								[classes.formHidden]: !forgetPW,
							})}
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

Login.propTypes = {
	userLoginAuth: PropTypes.func.isRequired,
};

Login.defaultProps = {};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
	return {
		// notifier: state.notifier
	};
};

export default connect(mapStateToProps, { userLoginAuth })(Login);
