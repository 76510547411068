// ##############################
// // // Constants
// #############################

export const Min_Exclusive_Userlevel_To_Edit_PlayerCode = 0; // user level greater than this one. (no equal)
export const MIN_USERLEVEL_MASTER_PLAYLIST_READ = 40; // (40 is the user level of Administrator) must be greater than or equal to this value
export const MIN_USERLEVEL_To_Synchronise_Playlist = 30; // (30 is the user level of "Download & Submit" or Store User) must be greater than or equal to this value
export const FILTER_GROUP_MIN_UL = 40; // min user-level to apply filters groups.
export const MIN_USERLEVEL_TO_ADD_LINUXBOX_SCREEN = 60; // because adding linuxbox could be a little technique, we need superAdmin to add linuxbox
// Feature tour status keys
export const TOUR_STATUS_KEY_SCHEDULES = 'schedules_tour';

// Redirecting route using domain, email and user Id
export const getRouteScreenPlayerUniverse = (domain, email, uid) =>
	`https://${domain}.visualid.com/admin/?e=${email}&id=${uid}`;
