/**
 * Library helper utils
 * NOTE: Do NEVER, NEVER import helper files or components that are created in our application.
 * 			 Otherwise it may cause circular dependency.
 */
import _axios from 'axios';
import _queryString from 'query-string';
import * as momentlib from 'moment';
import lodashlib from 'lodash';
import _cookies from 'js-cookie';
import _tinycolor from 'tinycolor2';
import _printJS from 'print-js';
import _deepClone from 'rfdc';
import _immutableUpdate from 'immutability-helper';
import * as _fileIcon from 'file-icons-js';
// load styles of "file-icons-js". It behaves as a global styles, but it is necessary
import 'file-icons-js/css/style.css';

// ##############################
// tinycolor lib
// #############################
export const tinycolor = _tinycolor;

// ##############################
// immutability-helper lib
// #############################
export const immutableUpdate = _immutableUpdate;

// ##############################
// js-cookie lib
// #############################
export const Cookies = _cookies;

// ##############################
// query string module
// #############################
export const qs = _queryString;

// ##############################
// moment library
// #############################
// var locale = window.navigator.userLanguage || window.navigator.language;
// momentlib.locale(locale);
export const moment = momentlib;

// ##############################
// lodash library
// #############################
export const _ = lodashlib;

// ##############################
// axios library
// #############################
/**
 * Send axios request.
 * NB: you can trigger some actions before sending the request. E.g. check authentication
 * @param {object} reqConfig
 * @returns {Promise} axios promise response
 */
export const axiosRequest = (reqConfig) => {
	// we can trigger some actions before sending the request. E.g. check authentication
	return _axios.request(reqConfig);
};
export const axios = _axios;

// ##############################
// Lang Helpers
// #############################
const localeStorageName = 'locale';
export const delLocale = () => {
	localStorage.removeItem(localeStorageName);
};
export const setLocale = (locale) => {
	localStorage.setItem(localeStorageName, locale);
};
export const getLocale = () => {
	return localStorage.getItem(localeStorageName);
};

/**
 *
 * @param {object|array|null} opts rfdc options. Default { proto: false, circles: false }
 */
export const deepClone = (JSONData = {}, opts) => {
	return _deepClone(opts)(JSONData);
};

/**
 * Convert file size to human-readable string
 * @param {Number} size file size in bytes
 * @param {Number} dp Number of decimal
 */
export const readableFileSize = (size, dp = 1) => {
	if (size === 0) return '0kB';

	let i = Math.floor(Math.log(size) / Math.log(1024));
	return (size / Math.pow(1024, i)).toFixed(dp) + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

/**
 * Get icon classname by the file type of a file
 * @param {string} filename
 * @return {string | null}. css class name of the file type icon, or null
 */
export const getFileIconClassName = (filename) => {
	return _fileIcon.getClassWithColor(filename);
};

/**
 * recursively apply Object.freeze() to an object
 * @param {object} object
 * @returns {object}
 */
export const deepFreeze = (object) => {
	// Retrieve the property names defined on object
	const propNames = Object.getOwnPropertyNames(object);

	// Freeze properties before freezing self
	for (const name of propNames) {
		const value = object[name];

		if (value && typeof value === 'object') {
			deepFreeze(value);
		}
	}

	return Object.freeze(object);
};

/**
 * Check if the given val is number or not
 * @param {any} val
 * @returns {boolean} if true, the given val is a number
 */
export const isNumber = (val) => {
	return typeof val === 'number' && !Number.isNaN(val);
};

/**
 * Test video DOM element to see if it is playing
 * @param {HTMLVideoElement} videoElem
 *
 * @return {Boolean}. True - Playing; False - Not Playing
 */
export const isVideoElemPlaying = (videoElem) =>
	!!(
		videoElem.currentTime > 0 &&
		!videoElem.paused &&
		!videoElem.ended &&
		videoElem.readyState > 2
	);

/**
 * Convert string to camelcase
 * @param {string} str
 */
export const converStringToCamelcase = (str) => {
	return str.replace(/-([a-z])/gi, function (s, group1) {
		return group1.toUpperCase();
	});
};

/**
 * Capitalize first letter of a string
 * @param {string} str
 * @returns {string}
 */
export const capitalizeString = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Convert html attributes to react-styled attributes
 * @param {Object} styles Object of HTML attributes
 * {
 * 	style: 'font-size: 12px; xxxx-xxx: xxxx',
 * 	transform: 'xxx',
 * }
 *
 * @return {Object}. Attribute object in React-Styled
 */
export const convertHtmlAttrToReact = (styles) => {
	return Object.entries(styles).reduce((accu, attrKeyValueArray) => {
		return {
			...accu,
			[converStringToCamelcase(attrKeyValueArray[0])]:
				attrKeyValueArray[0] !== 'style'
					? attrKeyValueArray[1]
					: attrKeyValueArray[1].split(';').reduce((accu, styleStr) => {
							let firstColonIndex = styleStr.indexOf(':');
							return {
								...accu,
								[converStringToCamelcase(
									styleStr.substring(0, firstColonIndex).trim()
								)]: styleStr.substring(firstColonIndex + 1).trim(),
							};
					  }, {}),
		};
	}, {});
};

/**
 *
 * @param {Buffer} buffer
 */
export const arrayBufferToBase64 = (buffer) => {
	var binary = '';
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
};

export const base64ToArrayBuffer = (base64) => {
	var binary_string = window.atob(base64);
	var len = binary_string.length;
	var bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes.buffer;
};

/**
 * trigger download of a file
 * NB: it only works with url that is from same origin or is a blobUrl
 * @param {string} uri
 * @param {array} aTagAttrs [{name: value}, ...]
 */
export const triggerDownload = (uri, aTagAttrs = []) => {
	let evt = new MouseEvent('click', {
		view: window,
		bubbles: false,
		cancelable: true,
	});

	let a = document.createElement('a');
	aTagAttrs.forEach((attr) => a.setAttribute(attr.name, attr.value));
	a.setAttribute('href', uri);
	a.dispatchEvent(evt);

	// // another approach
	// let a = document.createElement('a');
	// aTagAttrs.forEach((attr) => a.setAttribute(attr.name, attr.value));
	// a.setAttribute('href', uri);
	// // a.setAttribute('target', 'download');
	// // a.setAttribute('download', opts.filename || 'test.svg');
	// a.click();
};

/**
 * open file in new tab
 * NB: browser may block the popup tab because it is not a direct user action
 * @param {string} uri
 * @param {array} aTagAttrs Optional. [{name: value}, ...]
 */
export const openFileInTab = (uri, aTagAttrs = []) => {
	var evt = new MouseEvent('click', {
		view: window,
		bubbles: false,
		cancelable: true,
	});

	var a = document.createElement('a');
	aTagAttrs.forEach((attr) => a.setAttribute(attr.name, attr.value));
	a.setAttribute('href', uri);
	a.setAttribute('target', '_blank');

	a.dispatchEvent(evt);

	// another approach
	// let a = document.createElement('a');
	// a.setAttribute('href', svgBlobUrl);
	// // a.setAttribute('target', 'download');
	// a.setAttribute('download', opts.filename || 'test.svg');
	// a.click();
};

/**
 * open url in new window
 * NB, this way, it may avoid browser to block popup window
 * @param {string} uri
 * @param {string} windowName Optional.
 */
export const openUrlInWindow = (uri, windowName = '_blank') => {
	window.open(uri, windowName, 'scrollbars=yes,resizable=yes,width=800,height=600');
	// printWindow.print(); // print the open window
};

/**
 * Open print dialog to print pdf file
 * @param {string} url url of pdf file
 */
export const openPrintDialogForPdf = (url) => {
	_printJS({ printable: url, type: 'pdf' });
};
