import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';

import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

import { grey } from '@mui/material/colors';
// icons
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';

const SearchStyle = (theme) => ({
	container: {
		textAlign: 'center',
	},
	iconButton: {
		// padding: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	input: {
		marginLeft: theme.spacing(1),
		fontSize: 'inherit',
		flex: 1,
		position: 'relative',
		width: '50%',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: 4,
		borderColor: grey[500],
		backgroundColor: '#ffffff',
		paddingLeft: theme.spacing(1),
	},
});

class Search extends Component {
	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
	}
	render() {
		// let loadingStyle = {textAlign: 'center', margin: 'auto'};
		let {
			classes,
			className,
			placeholder,
			onChange,
			OnEnterKeyPressed,
			onSearchClick,
			onClearClick,
			inputValue,
		} = this.props;
		return (
			<div className={cx(classes.container, className)}>
				<InputBase
					className={classes.input}
					value={inputValue}
					placeholder={placeholder}
					inputRef={this.inputRef}
					inputProps={{
						'aria-label': 'Search',
						style: { border: 'unset', margin: 'auto', boxShadow: 'unset' },
					}}
					// style={inputStyle}
					onChange={(e) => {
						if (typeof onChange === 'function') onChange(e);
					}}
					onKeyPress={(e) => {
						if (e.key === 'Enter' && typeof OnEnterKeyPressed === 'function') {
							OnEnterKeyPressed(e);
						}
					}}
				/>
				<IconButton
					className={classes.iconButton}
					aria-label="Search"
					onClick={() => {
						if (typeof onSearchClick === 'function' && this.inputRef.current.value) {
							onSearchClick(this.inputRef.current.value);
						}
					}}
					title="Search"
					size="small"
				>
					<SearchIcon />
				</IconButton>
				{typeof onClearClick === 'function' && (
					<IconButton
						className={classes.iconButton}
						aria-label="Clear"
						onClick={() => {
							this.inputRef.current.value = '';
							onClearClick();
						}}
						title="Clear"
						size="small"
					>
						<ClearIcon />
					</IconButton>
				)}
			</div>
		);
	}
}

Search.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	inputValue: PropTypes.string,
	/**
	 * @param {Event}
	 */
	onChange: PropTypes.func,
	/**
	 * @param {Event}
	 */
	OnEnterKeyPressed: PropTypes.func,
	/**
	 * @param {string}. Search keywords
	 */
	onSearchClick: PropTypes.func,
	/**
	 * no parameter
	 */
	onClearClick: PropTypes.func,
};

Search.defaultProps = {
	classes: {},
	placeholder: 'Search...',
};

export default withStyles(SearchStyle)(Search);
