// ##############################
// // // Login styles
// #############################
import makeStyles from '@mui/styles/makeStyles';

const LoginFormStyle = makeStyles((theme) => ({
	form: {
		margin: 0,
	},
	card: {
		color: 'inherit',
	},
	cardHeader: {
		width: 'auto',
		textAlign: 'center',
		padding: '20px 0',
		marginBottom: '15px',
	},
	logoImg: {
		width: '60%',
		maxWidth: 300,
		paddingLeft: 20,
		paddingRight: 20,
		// verticalAlign: 'middle',
		border: '0',
	},
	divider: {
		color: 'inherit',
		width: '95%',
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: theme.spacing(3),
		// paddingRight: theme.spacing(3),
	},
	dividerLeft: {
		width: '75%',
		// float: 'left',
		// marginBottom: 20,
	},
	dividerRight: {
		width: '25%',
		// float: 'left',
		textAlign: 'right',
	},
	errorBlock: {
		color: 'inherit',
		width: '100%',
		paddingLeft: theme.spacing(3),
		// paddingRight: theme.spacing(3),
	},
	textField: {
		width: '95%',
		marginLeft: theme.spacing(2),
		// marginRight: theme.spacing(2),
		// paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(2),
	},
	textFieldFocus: {
		color: '#555 !important',
	},
	textFieldInputUnderline: {
		'&:after': {
			borderBottom: `2px solid #555`,
		},
	},
	cardActions: {
		flexDirection: 'column',
	},
	loginButton: {
		width: '90%',
		// margin: 'auto',
		// marginBottom: theme.spacing(5),
		margin: theme.spacing(3),
	},
	forgetPWLink: {
		// width: '90%',
		margin: theme.spacing(3),
		textAlign: 'center',
		fontSize: 18,
		color: theme.palette.primary.light,
		backgroundColor: 'unset',
		textTransform: 'none',
		'&:hover': {
			color: theme.palette.primary.dark,
			backgroundColor: 'unset',
			cursor: 'pointer',
		},
	},
}));

export default LoginFormStyle;
