import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';

import { CloudUpload as UploadIcon } from '@mui/icons-material';

import { ROUTES_PATH_FILEUPLOAD } from 'routes';

const FileUpload = loadable(() => import('./components/FileUpload/FileUpload'), {
	fallback: <Loading size={50} />,
});

const fileUploadRoutes = [
	{
		path: ROUTES_PATH_FILEUPLOAD,
		sidebarName: <FormattedMessage id="pages.FileUpload.Routes.FileUpload" />,
		sidebarIcon: UploadIcon,
		component: FileUpload,
		noBreadcrumbs: true,
	},
];
export default fileUploadRoutes;
