import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#464D69',
		// backgroundColor: '#fff', // theme.palette.primary.main,
		position: 'relative',
		padding: theme.spacing(8),
		top: 0,
		left: 0,
	},
	logotype: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(12),
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			display: 'none',
		},
	},
	logotypeText: {
		fontWeight: 500,
		color: 'white',
		marginLeft: theme.spacing(2),
	},
	logotypeIcon: {
		width: 70,
		marginRight: theme.spacing(2),
	},
	paperRoot: {
		boxShadow: theme.customShadows.widgetDark,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
		maxWidth: 404,
	},
	textRow: {
		marginBottom: theme.spacing(10),
		textAlign: 'center',
	},
	errorCode: {
		fontSize: 148,
		fontWeight: 600,
	},
	safetyText: {
		fontWeight: 300,
		color: theme.palette.text.hint,
	},
	backButton: {
		boxShadow: theme.customShadows.widget,
		textTransform: 'none',
		fontSize: 22,
	},
}));
