/** ##############################
 * 	Artwork Browser-Side Generator Helper
 *
 * ** Used only by browser-side **
 *
 *  It has functions that are used by artwrok generator in browser-side
 *
 * 	#############################
 */
import _uniqBy from 'lodash/uniqBy';
import {
	prepareConcatTextField,
	prepareConcatImageField,
	prepareTextField,
	prepareImageField,
	prepareBarcodeField,
	prepareVideoField,
	preparePdfField,
	prepareGridField,
} from './artFieldsCreator';
import {
	addAnimationStyleToSVG,
	addFontStyleToSVG,
	calcAnimationDelay,
	cleanupFontName,
	createSvgDomFromUrl,
	getColorListFromDataAttr,
	getConcatFieldType,
	getFontListFromDataAttr,
	hideFieldOutput,
	isConcatField,
	removeColorListDataAttr,
	removeFontListDataAttr,
	setColorListDataAttr,
	setFontListDataAttr,
} from './artUtilsCommon';
import { DOMURL, NS, ARTWORK_SERVER_SIDE_PROCESS } from './constants';

/**
 *  Conver svg to image JPEG or PNG by Canvas
 * @param {SVGElement} svg
 * @param {Object} opts
 * {
 * 	imgWidth: NUMBER,
 * 	imgHeight: NUMBER,
 * 	fontList: [{name: 'xxxx', fontUrl: 'xxxx}, ...],			// required
 * 	format: jpeg|png,
 * }
 *
 * @return {Promise} image dataUrl or download it
 */
export const svgToImageByCanvas = async (svg, opts = {}) => {
	const svgClone = await addFontStyleToSVG(svg.cloneNode(true), {
		full: true,
		fontList: opts.fontList,
	});

	const { imgWidth, imgHeight } = opts;

	let svgData = new XMLSerializer().serializeToString(svgClone);
	let svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
	let svgBlobUrl = DOMURL.createObjectURL(svgBlob);

	let canvas = document.createElement('canvas');
	canvas.setAttribute('width', imgWidth);
	canvas.setAttribute('height', imgHeight);
	let ctx = canvas.getContext('2d');
	return new Promise((res, rej) => {
		let img = document.createElement('img');
		const handleImgOnLoad = () => {
			ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
			canvas.toBlob(
				(blob) => {
					if (!blob) rej(new Error(`Failed to convert svg to image`));
					else res(blob);
				},
				opts.format === 'png' ? 'image/png' : 'image/jpg',
				1
			);
			// const imgDataUrl = canvas.toDataURL(opts.format === 'png' ? 'image/png' : 'image/jpeg');
			DOMURL.revokeObjectURL(svgBlobUrl);
			svgData = null;
			svgBlob = null;
			svgBlobUrl = null;
			svgClone.remove();
			// res(imgDataUrl);
		};

		img.onerror = (err) => {
			DOMURL.revokeObjectURL(svgBlobUrl);
			svgData = null;
			svgBlob = null;
			svgBlobUrl = null;
			rej(err);
		};
		img.onload = () => {
			// For issue "image in svg is randomly blank in Safari", it is related to old bug: https://bugs.webkit.org/show_bug.cgi?id=39059
			// Solution is using setTimeout to create image after image is fully loaded
			// Reference: https://stackoverflow.com/questions/69672178/when-converting-an-svg-to-png-in-the-browser-using-canvas-api-embedded-image-in
			setTimeout(() => {
				handleImgOnLoad();
			}, 100);
		};

		img.setAttribute('width', imgWidth);
		img.setAttribute('height', imgHeight);
		img.setAttribute('src', svgBlobUrl);
	});
};

/**
 * Generate image when rederring a single field on the artwork template
 * @param {object} field The field to be renderred on the artwork template
 * @param {object} fieldOutputData {FIELD_ID: {}, FIELD_ID: {}, ...} // including all fields. field output data, combination of user input data & field default data
 * @param {object} opts
  {
			templateSize: {width: xx, height: xx},
			allFields: [fieldObject, fieldObject, ...]
			imgFormat: 'jpeg'|'png',
			fontList: an array of font object. [{name: 'xxx', fontUrl: 'xxxx'}, ...],
			// colorList: OBJECT, {'R-G-B': [c,m,y,k], ...}
			// animations: ART_VARIABLES.animations,
  		CONSTANTS:{
  			placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
				DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
  			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY
  		},
  }

 * @returns {Promise} blob of generated image. Can be null if no image generated
 */
export const generateImageOfFieldInTemplate = async (field, fieldOutputData, opts = {}) => {
	const fields = opts.allFields; // all fields in template
	const rootSVG = document.createElementNS(NS.SVG, 'svg');
	rootSVG.setAttribute('viewBox', `0 0 ${opts.templateSize.width} ${opts.templateSize.height}`);
	rootSVG.setAttribute('width', opts.templateSize.width);
	rootSVG.setAttribute('height', opts.templateSize.height);
	rootSVG.setAttribute('xmlns', NS.SVG);
	rootSVG.setAttribute('overflow', 'hidden');

	const isPrintable = false;

	switch (field.type) {
		case 'text':
			isConcatField(field)
				? getConcatFieldType(field, fields) === 'TEXT_ONLY'
					? await prepareConcatTextField({
							mode: 'svg',
							field,
							templateFields: fields,
							fieldOutputData,
							dataForExport: {
								rootSVG,
								isPrintable,
								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
								fontList: opts.fontList,
							},
							CONSTANTS: opts.CONSTANTS,
					  })
					: await prepareConcatImageField({
							mode: 'svg',
							field,
							templateFields: fields,
							fieldOutputData,
							dataForExport: {
								rootSVG,
								isPrintable,
								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
								fontList: opts.fontList,
							},
							CONSTANTS: opts.CONSTANTS,
					  })
				: await prepareTextField({
						mode: 'svg',
						inputData: fieldOutputData[field.id],
						field,
						dataForExport: {
							rootSVG,
							isPrintable,
							animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
							fontList: opts.fontList,
						},
						CONSTANTS: opts.CONSTANTS,
				  });
			break;
		case 'image':
			if (
				!fieldOutputData[field.id]?.croppedImgUrl &&
				!fieldOutputData[field.id]?.clippedImgUrl &&
				!fieldOutputData[field.id]?.highResUrl
			) {
				// if no image, we don't generate image and return null
				return null;
			}
			await prepareImageField({
				mode: 'print',
				inputData: fieldOutputData[field.id],
				field,
				dataForExport: {
					rootSVG,
					isPrintable,
					animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
				},
				CONSTANTS: opts.CONSTANTS,
			});
			break;
		default:
			break;
	}

	const imgBlob = await svgToImageByCanvas(rootSVG, {
		imgWidth: opts.templateSize.width,
		imgHeight: opts.templateSize.height,
		fontList: opts.fontList,
		format: opts.imgFormat,
	});
	return imgBlob;
};

/**
 * validate field for videoArtwork
 * @param {object} field
 * @param {array} supportedFieldTypesInVideoArtwork
 *
 * @returns {boolean} true/false
 *
 */
export const isValidFieldForVideoArtwork = (field, supportedFieldTypesInVideoArtwork) => {
	let regex = new RegExp(/^(?:[\d]{2}):(?:[\d]{2}):(?:[\d]{2}):(?:[\d]{2})$/);
	if (
		!supportedFieldTypesInVideoArtwork.includes(field.type) ||
		!field.insertionOnVideo ||
		(field.insertionOnVideo.startTime && !regex.test(field.insertionOnVideo.startTime)) ||
		Number.isNaN(Number(field.insertionOnVideo.duration)) ||
		Number(field.insertionOnVideo.duration) <= 0
	) {
		return false;
	}
	return true;
};

/**
 *  create svg dom of artwork for server saving
 * @param {array} fields All fields in the artwork template
 * @param {object} fieldOutputData {FIELD_ID: {}, FIELD_ID: {}, ...} // field output data, combination of user input data & field default data
 * @param {object} opts
  {
			templateSize: {width: xx, height: xx},
			// templateBGUrl: String,
			templateMediaUrl: '',
			templatePdfUrl: '',
			templateSvgUrl: '',
			s3Params: {
				bucket: s3Bucket,
				filePrefix: basePath,
			},
			s3Client,
			fontList: an array of font object. [{name: 'xxx', fontUrl: 'xxxx'}, ...],
			colorList: OBJECT, {'R-G-B': [c,m,y,k], ...}
			animations: ART_VARIABLES.animations,
  		CONSTANTS:{
  			placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
				DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
  			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY
  		},
  }

	@returns {Promise<Object>} Promise. Blob of the exported SVG
 */
export const createArtworkSVGForServerSaving = async (
	fields,
	fieldOutputData /* fieldsInputData */,
	opts = {}
) => {
	let isPrintable = true; // isPrintable has no use in this function, having it is to keep it allign with other creatingArtwork function
	let pdfBGUrl = null; // if no svg BG, we will send pdfBGUrl to server to use it as background when generating pdf

	const rootSVG = document.createElementNS(NS.SVG, 'svg');
	rootSVG.setAttribute('viewBox', `0 0 ${opts.templateSize.width} ${opts.templateSize.height}`);
	rootSVG.setAttribute('width', opts.templateSize.width);
	rootSVG.setAttribute('height', opts.templateSize.height);
	rootSVG.setAttribute('xmlns', NS.SVG);
	rootSVG.setAttribute('overflow', 'hidden');
	addAnimationStyleToSVG(rootSVG, { fields, animations: opts.animations });
	/**
	 * Add fonts that are used in this artwork to output SVG by <style> tag except the ones in nested artwork.
	 * The fonts required in nested artwork is inside the itself
	 * As we use fontList data attribute to carry the font data, so no need to use dataUrl (base64) of the font
	 * the fontlist data attribute contains the fonts used in this artwork & the fonts in nested artwork
	 */
	await addFontStyleToSVG(rootSVG, { full: false, fontList: opts.fontList });

	const rawSVG = rootSVG.cloneNode(true);
	// add background of the template. (svg > pdf > image)
	if (opts.templateSvgUrl) {
		// template background is svg
		const svgBG = await createSvgDomFromUrl(opts.templateSvgUrl, opts.templateSize);
		rootSVG.append(svgBG);

		rawSVG.append(svgBG);
		let colorListObj = getColorListFromDataAttr(svgBG);
		if (colorListObj) {
			opts.colorList = { ...opts.colorList, ...colorListObj };
		}
		let fontListArray = getFontListFromDataAttr(svgBG);
		if (fontListArray) {
			opts.fontList = _uniqBy(opts.fontList.concat(fontListArray), 'name');
		}
		// We get the enough data, let's remove data attributes to reduce the output size
		removeColorListDataAttr(svgBG);
		removeFontListDataAttr(svgBG);
	} else {
		if (opts.templatePdfUrl) {
			// assign to pdfBGUrl for using it in server-side processing
			pdfBGUrl = opts.templatePdfUrl;
		}

		if (opts.templateMediaUrl) {
			// when no svgBackground, rawSVG uses only image background
			const backgroundImage = document.createElementNS(NS.SVG, 'image');
			backgroundImage.setAttribute('width', '100%');
			backgroundImage.setAttribute('height', '100%');
			backgroundImage.setAttribute('x', '0');
			backgroundImage.setAttribute('y', '0');
			backgroundImage.setAttribute('href', opts.templateMediaUrl);

			rawSVG.append(backgroundImage);

			// rootSVG uses image background only when no pdf background
			// because if there is pdf BG, the pdf BG will be added in server side
			!opts.templatePdfUrl && rootSVG.append(backgroundImage);
		}
	}

	const serverSideProcess = {
		rawSVG, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
		s3Client: opts.s3Client, // mandatary
		s3Params: {
			// mandatary
			bucket: opts.s3Params.bucket,
			filePrefix: opts.s3Params.filePrefix,
		},
	};

	document.body.append(rootSVG);

	// await Promise.all(
	await fields.reduce((promiseChain, field) => {
		return promiseChain.then(async () => {
			if (hideFieldOutput(field, fields, fieldOutputData)) return null;
			switch (field.type) {
				case 'text':
					return isConcatField(field)
						? getConcatFieldType(field, fields) === 'TEXT_ONLY'
							? prepareConcatTextField({
									mode: ARTWORK_SERVER_SIDE_PROCESS,
									field,
									templateFields: fields,
									fieldOutputData,
									dataForExport: {
										rootSVG,
										isPrintable,
										animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
										fontList: opts.fontList,
									},
									serverSideProcess,
									CONSTANTS: opts.CONSTANTS,
							  })
							: prepareConcatImageField({
									mode: ARTWORK_SERVER_SIDE_PROCESS,
									field,
									templateFields: fields,
									fieldOutputData,
									dataForExport: {
										rootSVG,
										isPrintable,
										animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
										fontList: opts.fontList,
									},
									serverSideProcess,
									CONSTANTS: opts.CONSTANTS,
							  })
						: prepareTextField({
								mode: ARTWORK_SERVER_SIDE_PROCESS,
								inputData: fieldOutputData[field.id],
								field,
								dataForExport: {
									rootSVG,
									isPrintable,
									animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
									fontList: opts.fontList,
								},
								serverSideProcess,
								CONSTANTS: opts.CONSTANTS,
						  });
				case 'image':
					return prepareImageField({
						mode: ARTWORK_SERVER_SIDE_PROCESS,
						inputData: fieldOutputData[field.id],
						field,
						dataForExport: {
							rootSVG,
							isPrintable,
							animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
						},
						serverSideProcess,
						CONSTANTS: opts.CONSTANTS,
					});
				case 'barcode':
					return prepareBarcodeField({
						mode: ARTWORK_SERVER_SIDE_PROCESS,
						inputData: fieldOutputData[field.id],
						field,
						dataForExport: {
							rootSVG,
							animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
						},
						serverSideProcess,
						CONSTANTS: opts.CONSTANTS,
					});
				case 'video':
					return prepareVideoField({
						mode: ARTWORK_SERVER_SIDE_PROCESS,
						inputData: fieldOutputData[field.id],
						field,
						dataForExport: {
							rootSVG,
							animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
						},
						serverSideProcess,
						CONSTANTS: opts.CONSTANTS,
					});
				case 'pdf': {
					const { colorListObj, fontListArray } =
						(await preparePdfField({
							mode: ARTWORK_SERVER_SIDE_PROCESS,
							inputData: fieldOutputData[field.id],
							field,
							dataForExport: {
								rootSVG,
								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
							},
							serverSideProcess,
							CONSTANTS: opts.CONSTANTS,
						})) || {};
					if (colorListObj) {
						opts.colorList = { ...opts.colorList, ...colorListObj };
					}
					if (fontListArray) {
						opts.fontList = _uniqBy(opts.fontList.concat(fontListArray), 'name');
					}
					return null;
				}
				case 'grid':
					return prepareGridField({
						mode: ARTWORK_SERVER_SIDE_PROCESS,
						inputData: fieldOutputData[field.id],
						field,
						dataForExport: {
							rootSVG,
							animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
						},
						serverSideProcess,
						CONSTANTS: opts.CONSTANTS,
					});
				default:
					return null;
			}
		});
	}, Promise.resolve());
	// remove rootSVG from document
	rootSVG.remove();
	// we keep color list in data attribute in root svg so that we can nest it in another artwork
	setColorListDataAttr(rootSVG, opts.colorList);
	// we keep font list in data attribute in root svg
	setFontListDataAttr(rootSVG, opts.fontList);

	// set width, height to 100% so that it fits to its container when using it as nested svg or in web
	rootSVG.setAttribute('width', '100%');
	rootSVG.setAttribute('height', '100%');

	rawSVG.setAttribute('width', '100%');

	rawSVG.setAttribute('height', '100%');

	let printSVG = rootSVG;
	if (pdfBGUrl && opts.templateMediaUrl) {
		// when pdfBGUrl has value, rootSVG doesn't have background at the moment
		// let's create a new printSVG from rootSVG with image background
		const backgroundImage = document.createElementNS(NS.SVG, 'image');
		backgroundImage.setAttribute('width', '100%');
		backgroundImage.setAttribute('height', '100%');
		backgroundImage.setAttribute('x', '0');
		backgroundImage.setAttribute('y', '0');
		backgroundImage.setAttribute('href', opts.templateMediaUrl);

		printSVG = rootSVG.cloneNode(true);
		printSVG.prepend(backgroundImage);
	}

	return {
		svgToPdf: rootSVG,
		printSVG,
		rawSVG,
		pdfBGUrl,
		printFontList: opts.fontList.map((font) => ({ ...font, name: cleanupFontName(font.name) })),
		printColorList: opts.colorList,
	};
};
