import {
	GET_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
	GET_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
	GET_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	RESET_SCREENMANAGER_SCREEN_SCHEDULE_GETSTATUS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
	EDIT_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
	EDIT_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	RESET_SCREENMANAGER_SCREEN_SCHEDULE_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
	ADD_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
	ADD_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	RESET_SCREENMANAGER_SCREEN_SCHEDULE_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
	DELETE_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
	DELETE_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	RESET_SCREENMANAGER_SCREEN_SCHEDULE_DELETESTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_REQUEST,
	ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_SUCCESS,
	ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_ADDSTATUS,
} from 'redux/actions';
import { RESET_SCREENMANAGER_SCREEN_SCHEDULE } from 'redux/actions';

const screenManagerScreenSchedulesInitState = {
	player: null, // if isGettingPlayer=true, it is for an individual player schedule, otherwise it is for scheduling to multiple screens (players)
	isGettingPlayer: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	addingScheduleToMultipleScreensStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	addingScheduleStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	editingScheduleStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	deleteScheduleStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
};

export const screenManagerScreenSchedule = (
	state = screenManagerScreenSchedulesInitState,
	action
) => {
	switch (action.type) {
		// PLAYLIST Slide
		case RESET_SCREENMANAGER_SCREEN_SCHEDULE:
			return {
				...state,
				player: null,
				isGettingPlayer: '',
				addingScheduleToMultipleScreensStatus: '',
				addingScheduleStatus: '',
				editingScheduleStatus: '',
				deleteScheduleStatus: '',
			};
		case GET_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST:
			return {
				...state,
				isGettingPlayer: 'PROCESSING',
			};
		case GET_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS:
			return {
				...state,
				player: action.result.data,
				isGettingPlayer: 'OK',
			};
		case GET_SCREENMANAGER_SCREEN_SCHEDULE_FAIL:
			return {
				...state,
				isGettingPlayer: 'FAILED',
			};
		case RESET_SCREENMANAGER_SCREEN_SCHEDULE_GETSTATUS:
			return {
				...state,
				isGettingPlayer: '',
			};

		case EDIT_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST:
			return {
				...state,
				editingScheduleStatus: 'PROCESSING',
			};
		case EDIT_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS:
			return {
				...state,
				player: {
					...state.player,
					schedules: state.player.schedules.map((schedule) => {
						if (schedule.scheduleId === action.newSchedule.scheduleId) {
							return action.newSchedule;
						}
						return schedule;
					}),
				},
				editingScheduleStatus: 'OK',
			};
		case EDIT_SCREENMANAGER_SCREEN_SCHEDULE_FAIL:
			return {
				...state,
				editingScheduleStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_SCREEN_SCHEDULE_EDITSTATUS:
			return {
				...state,
				editingScheduleStatus: '',
			};
		case ADD_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST:
			return {
				...state,
				addingScheduleStatus: 'PROCESSING',
			};
		case ADD_SCREENMANAGER_SCREEN_SCHEDULE_FAIL:
			return {
				...state,
				addingScheduleStatus: 'FAILED',
			};
		case ADD_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS:
			return {
				...state,
				addingScheduleStatus: 'OK',
			};
		case RESET_SCREENMANAGER_SCREEN_SCHEDULE_ADDSTATUS:
			return {
				...state,
				addingScheduleStatus: '',
			};
		case DELETE_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST:
			return {
				...state,
				deleteScheduleStatus: 'PROCESSING',
			};
		case DELETE_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS:
			return {
				...state,
				player: {
					...state.player,
					schedules: state.player.schedules.filter(
						(schedule) => schedule.scheduleId !== action.scheduleId
					),
				},
				deleteScheduleStatus: 'OK',
			};
		case DELETE_SCREENMANAGER_SCREEN_SCHEDULE_FAIL:
			return {
				...state,
				deleteScheduleStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_SCREEN_SCHEDULE_DELETESTATUS:
			return {
				...state,
				deleteScheduleStatus: '',
			};
		case ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_REQUEST:
			return {
				...state,
				addingScheduleToMultipleScreensStatus: 'PROCESSING',
			};
		case ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_SUCCESS:
			return {
				...state,
				addingScheduleToMultipleScreensStatus: 'OK',
			};
		case ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_FAIL:
			return {
				...state,
				addingScheduleToMultipleScreensStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_ADDSTATUS:
			return {
				...state,
				addingScheduleToMultipleScreensStatus: '',
			};
		default:
			return state;
	}
};
