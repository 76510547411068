import React from 'react';

import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';

import { MUIDatetimeNTimePickerPop } from 'components';

import { orange } from '@mui/material/colors';

import { _, moment } from 'utils/libHelper';

// icons
import {
	DateRange as DateRangeIcon,
	// AccessTime as AccessTimeIcon,
	// Edit as EditIcon,
} from '@mui/icons-material';

// redux
import { connect } from 'react-redux';
import { fetchScreenManagerScreens, resetScreenManagerScreens } from 'redux/actions'; // actions

let UserSelectionsStyles = (theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 240,
		// maxWidth: '100%',
		// width: '90%',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		backgroundColor: orange[500],
	},
	chipDatetime: {
		margin: 2,
		backgroundColor: orange[500],
		'&:hover': {
			backgroundColor: orange[800],
		},
		'&:focus': {
			backgroundColor: orange[800],
		},
	},
	screenSelectionWrapper: {
		flex: '0 1 auto',
		// maxWidth: '45%',
		margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
	},
	dateTimeWrapper: {
		flex: '0 1 auto',
		// maxWidth: '45%',
		margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
	},
	selectRoot: {
		height: 'auto',
	},
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
		alignContent: 'stretch',
		minHeight: 130,
		// paddingBottom: theme.spacing(2),
	},
});

class UserSelections extends React.Component {
	// function UserSelections({ screens, selectedScreens, handleScreenChange, }) {
	constructor(props) {
		super(props);
		this.state = {
			// openStartDatetime: false,
			// openEndDatetime: false,
			openDatetimePicker: false,
			dateTimePickerValue: new Date(),
			editingKey: '',
			disablePast: false,
		};
	}

	componentDidMount() {
		if (!this.props.disableScreenSelection) {
			this.props.fetchScreenManagerScreens();
		}
	}

	componentWillUnmount() {
		if (!this.props.disableScreenSelection) {
			this.props.resetScreenManagerScreens();
		}
	}
	render() {
		const {
			classes,
			screenManagerScreens,
			selectedScreens,
			handleScreenSelection,
			removeScreenSelection,
			startDatetime,
			handleStartDatetimeChange,
			endDatetime,
			handleEndDatetimeChange,
			selectedOrientation,
			disableScreenSelection,
			intl,
		} = this.props;
		// const [selectedScreens, setSelectedScreens] = React.useState([]);
		let { openDatetimePicker, editingKey, disablePast } = this.state;

		// let firstSelectedScreen = selectedScreens.length>0?_.find(screenManagerScreens.screens, screen=>(screen.id===selectedScreens[0])):null;

		return (
			<div className={classes.root}>
				<div className={classes.screenSelectionWrapper}>
					{!disableScreenSelection && (
						<FormControl variant="standard" className={classes.formControl}>
							<InputLabel htmlFor="selectScreens" required error={!selectedOrientation}>
								{intl.formatMessage({
									id: 'pages.ScreenManager.components.Schedules.UserSelections.SelectScreenText',
								})}
							</InputLabel>
							<Select
								multiple
								value={selectedScreens}
								classes={{ select: classes.selectRoot }}
								onChange={handleScreenSelection}
								aria-describedby="selectScreenHelperText"
								input={<Input id="selectScreens" />}
								// IconComponent={null}
								renderValue={(selected) => (
									<div className={classes.chips}>
										{selected.map((value) => (
											<Chip
												key={value}
												label={
													_.find(screenManagerScreens.screens, (screen) => screen.id === value).name
												}
												color="primary"
												className={classes.chip}
												onMouseDown={(e) => {
													e.stopPropagation();
												}}
												onDelete={() => {
													removeScreenSelection(value);
												}}
											/>
										))}
									</div>
								)}
							>
								{_.sortBy(screenManagerScreens.screens, [
									(screen) => screen.name.toLowerCase(),
								]).map((screen) => (
									<MenuItem
										key={screen.id}
										value={screen.id}
										data-orientation={screen.orientation}
										orientation={screen.orientation}
										disabled={
											selectedOrientation ? selectedOrientation !== screen.orientation : false
										}
									>
										<Checkbox checked={selectedScreens.indexOf(screen.id) > -1} />
										<ListItemText primary={`${screen.name} (${screen.orientation})`} />
									</MenuItem>
								))}
							</Select>
							{!selectedOrientation && (
								<FormHelperText id="selectScreenHelperText" required error>
									{intl.formatMessage({
										id:
											'pages.ScreenManager.components.Schedules.UserSelections.SelectScreenHelperText',
									})}
								</FormHelperText>
							)}
						</FormControl>
					)}
					{selectedOrientation && (
						<FormControl variant="standard" className={classes.formControl}>
							<InputLabel htmlFor="selectedOrientation">
								{intl.formatMessage({
									id:
										'pages.ScreenManager.components.Schedules.UserSelections.ScreenOrientationText',
								})}
							</InputLabel>
							<Select
								value="fake"
								classes={{ select: classes.selectRoot }}
								open={false}
								input={<Input id="selectedOrientation" />}
								IconComponent={() => null}
								renderValue={() => (
									<Chip label={selectedOrientation} color="primary" className={classes.chip} />
								)}
							></Select>
						</FormControl>
					)}
				</div>
				<div className={classes.dateTimeWrapper}>
					<FormControl variant="standard" className={classes.formControl}>
						<InputLabel htmlFor="scheduleStartDatetime">
							{intl.formatMessage({
								id: 'pages.ScreenManager.components.Schedules.UserSelections.ScheStartDTText',
							})}
						</InputLabel>
						<Select
							value="fake"
							classes={{ select: classes.selectRoot }}
							open={false}
							input={<Input id="scheduleStartDatetime" />}
							IconComponent={() => null}
							renderValue={() => (
								<Chip
									icon={<DateRangeIcon />}
									label={
										startDatetime
											? moment(startDatetime).format('YYYY-MM-DD HH:mm:00')
											: intl.formatMessage({ id: 'GENERAL.Now' })
									}
									className={classes.chipDatetime}
									color="primary"
									clickable
									onClick={() => {
										this.setState({
											openDatetimePicker: true,
											dateTimePickerValue: startDatetime ? startDatetime.toDate() : new Date(),
											editingKey: 'startDatetime',
											disablePast: false,
										});
									}}
									onDelete={() => {
										handleStartDatetimeChange();
									}}
								/>
							)}
						></Select>
					</FormControl>
					<FormControl variant="standard" className={classes.formControl}>
						<InputLabel htmlFor="scheduleEndDatetime">
							{intl.formatMessage({
								id: 'pages.ScreenManager.components.Schedules.UserSelections.ScheEndDTText',
							})}
						</InputLabel>
						<Select
							value="fake"
							open={false}
							classes={{ select: classes.selectRoot }}
							input={<Input id="scheduleEndDatetime" />}
							IconComponent={() => null}
							renderValue={() => (
								<Chip
									// id="scheduleStartDatetime"
									// size="small"
									icon={<DateRangeIcon />}
									// component={()=><input id="scheduleStartDatetime" />}
									label={
										endDatetime && (endDatetime.isValid ? endDatetime.isValid() : true)
											? moment(endDatetime).format('YYYY-MM-DD HH:mm:00')
											: intl.formatMessage({ id: 'GENERAL.Forever' })
									}
									className={classes.chipDatetime}
									color="primary"
									clickable
									onClick={() => {
										this.setState({
											openDatetimePicker: true,
											dateTimePickerValue:
												endDatetime && endDatetime.isValid && endDatetime.isValid()
													? endDatetime.toDate()
													: new Date(),
											editingKey: 'endDatetime',
											disablePast: true,
										});
									}}
									onDelete={() => {
										handleEndDatetimeChange();
									}}
								/>
							)}
						></Select>
					</FormControl>
					{/* Initializing the picker only when its needed. The picker has a bug which prevents it from updating otherwise */}
					{openDatetimePicker && (
						<MUIDatetimeNTimePickerPop
							isOpenDatetime={openDatetimePicker || false}
							initValue={this.state.dateTimePickerValue}
							disablePast={disablePast}
							onClose={() => this.setState({ openDatetimePicker: false })}
							onChange={(date) => {
								if (editingKey === 'startDatetime') {
									handleStartDatetimeChange(moment(date));
								} else if (editingKey === 'endDatetime') {
									handleEndDatetimeChange(moment(date));
								}
							}}
						/>
					)}
				</div>
			</div>
		);
	}
}

UserSelections.propTypes = {
	classes: PropTypes.object.isRequired,
	startDatetime: PropTypes.object,
	handleStartDatetimeChange: PropTypes.func.isRequired,
	endDatetime: PropTypes.object,
	handleEndDatetimeChange: PropTypes.func.isRequired,
	screenManagerScreens: PropTypes.object.isRequired,
	selectedScreens: PropTypes.array.isRequired,
	selectedOrientation: PropTypes.string,
	handleScreenSelection: PropTypes.func.isRequired,
	removeScreenSelection: PropTypes.func.isRequired,
	disableScreenSelection: PropTypes.bool,
	fetchScreenManagerScreens: PropTypes.func.isRequired,
	resetScreenManagerScreens: PropTypes.func.isRequired,
};

UserSelections.defaultProps = {
	disableScreenSelection: false,
};

const mapStateToProps = (state) => {
	return {
		screenManagerScreens: state.screenManagerScreens,
	};
};

export default connect(mapStateToProps, { fetchScreenManagerScreens, resetScreenManagerScreens })(
	withStyles(UserSelectionsStyles)(UserSelections)
);
