import React from 'react';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { preparePdfField } from 'utils/artwork/artFieldsCreator';
import { NS } from 'utils/artwork/constants';

const useStyles = makeStyles((theme) => ({
	pauseAnimation: {
		animationPlayState: 'paused !important',
	},
}));

function Pdf({
	field,
	fieldPreviewData,
	animationDelay,
	allowAnimation,
	pausingAnimation,
	...rest
}) {
	const classes = useStyles();
	// fieldPreviewData
	// PDF_FIELD_ID:{
	// 	// Artwork field Preview Data
	// 	horizontalAlign: 'left' | 'center' | 'right' | undefined,
	// 	verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
	// // 	svgUrl: 'xxxx',
	// previewUrl: 'xxxxxxx', // preview url of this pdf/svg. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
	// optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
	// highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

	// },
	const noAnimationStyle = `0s ease 0s 1 normal none running none`;
	const animationEntranceStyle = field.animation.entrance
		? `${field.animation.entrance} ${
				field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
		  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
				animationDelay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
		  }s both`
		: noAnimationStyle;
	// const animationExitStyle = field.animation.exit
	// 	? `${field.animation.exit} ${field.animation.duration ||
	// 			1}s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${0}s both`
	// 	: noAnimationStyle;
	const [svgLoaded, setSvgLoaded] = React.useState(false);
	const pdfContainerRef = React.useRef(null);
	const pdfSvgElemClassSelector = `dummyPdfSvgId-${field.id}`;
	const SVGUrl = fieldPreviewData.optimisedUrl || fieldPreviewData.highResUrl;
	React.useEffect(() => {
		if (!pdfContainerRef.current) return null;
		// load svg data
		if (SVGUrl) {
			setSvgLoaded(false);
			fetch(SVGUrl).then(async (res) => {
				let svgString = await res.text();
				const dummySvg = document.createElementNS(NS.SVG, 'svg');
				dummySvg.innerHTML = svgString;
				let pdfSVG = dummySvg.children[0]; // dummySvg contains ONLY pdf SVG element
				pdfSVG.setAttribute('preserveAspectRatio', 'xMidYMid meet');
				pdfSVG.setAttribute('width', '100%');
				pdfSVG.setAttribute('height', '100%');
				pdfSVG.setAttribute('class', pdfSvgElemClassSelector);
				// remove previous <svg> from container
				const prevSvgElems = pdfContainerRef.current.getElementsByTagName('svg');
				for (let i = 0; i < prevSvgElems.length; i++) {
					prevSvgElems[i].remove();
				}
				pdfContainerRef.current.insertBefore(pdfSVG, pdfContainerRef.current.firstChild);
				setSvgLoaded(true);
			});
		} else {
			// no svgUrl in the inputData
			let pdfSVG = pdfContainerRef.current.getElementsByClassName(pdfSvgElemClassSelector)[0];
			if (pdfSVG) pdfSVG.remove();
			setSvgLoaded(false);
		}
	}, [SVGUrl, pdfSvgElemClassSelector]);

	React.useEffect(() => {
		if (!pdfContainerRef.current) return null;
		// set attributes of svg
		let pdfSVG = pdfContainerRef.current.getElementsByClassName(pdfSvgElemClassSelector)[0];
		if (svgLoaded && pdfSVG) {
			const { attrs } =
				preparePdfField({
					mode: 'preview',
					inputData: fieldPreviewData,
					field,
				}) || {};
			for (const attrKey in attrs || {}) {
				pdfSVG.setAttribute(attrKey, attrs[attrKey]);
			}
			pdfSVG.setAttribute('width', '100%');
			pdfSVG.setAttribute('height', '100%');
		}
	}, [field, fieldPreviewData, pdfSvgElemClassSelector, svgLoaded]);

	return (
		<svg
			// ref={pdfContainerRef}
			x={field.position.left}
			y={field.position.top}
			width={field.position.width}
			height={field.position.height}
			overflow={'visible'}
		>
			<g
				className={cx({ [classes.pauseAnimation]: pausingAnimation })}
				style={{
					animation:
						allowAnimation && field.animation.entrance ? animationEntranceStyle : noAnimationStyle,
				}}
			>
				{/* Somehow, "transform" not working when together with "style" in the same <g> */}
				<g
					ref={pdfContainerRef}
					transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
						field.position.height / 2
					})`}
				>
					{svgLoaded && field.borderWidth > 0 && (
						<rect
							width={field.position.width + field.borderWidth}
							height={field.position.height + field.borderWidth}
							x={-field.borderWidth / 2}
							y={-field.borderWidth / 2}
							fill="none"
							stroke={field.borderColor.hex || '#000000'}
							strokeWidth={field.borderWidth}
						></rect>
					)}
				</g>
			</g>
		</svg>
	);
}

Pdf.propTypes = {
	field: PropTypes.object.isRequired, // it is text field
	fieldPreviewData: PropTypes.object.isRequired, // the preview data of a field
	animationDelay: PropTypes.number.isRequired, // animation delay. Relative to previous fields
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is aninimation paused (on video end in videoArtwork)
};

Pdf.defaultProps = {};
export default React.memo(Pdf);
// export default React.memo(Pdf, (prevProps, nextProps) => {
// 	return (
// 		prevProps.fieldPreviewData === nextProps.fieldPreviewData &&
// 		JSON.stringify(prevProps.field) === JSON.stringify(nextProps.field)
// 	);
// });
