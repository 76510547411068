import React from 'react';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';

import { TextField } from '@mui/material';

const NumInput = withStyles((theme) => ({
	textField: {
		// margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		// padding: theme.spacing(0.5),
		width: `100%`,
		color: 'inherit',
		minWidth: '5rem',
	},
	textFieldInput: {
		color: 'inherit',
		fontSize: 'inherit', //paletteFontSize,
	},
	inputMarginDense: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	textFieldLabel: {
		fontSize: 'inherit',
	},
	// highlightInput: {
	// 	backgroundColor: '#fff',
	// 	color: '#000',
	// },
}))(
	({
		classes,
		value,
		step,
		min,
		max,
		onCommitted,
		onChange,
		// isHighlight, // is the row highlighted
		...rest
	}) => {
		const [inputValue, setInputValue] = React.useState(value);
		React.useEffect(() => {
			setInputValue(value);
		}, [value]);
		return (
			<TextField
				className={classes.textField}
				size="small"
				// label={label}
				// required={required}
				variant="outlined"
				// color="secondary"
				value={inputValue}
				type="number"
				// size="small"
				inputProps={{ step: step, min: min, max: max }}
				onChange={(e) => {
					setInputValue(e.target.value);
					if (typeof onChange === 'function') onChange(Number(e.target.value));
				}}
				InputProps={{
					className: cx(classes.textFieldInput, {
						/* [classes.highlightInput]: isHighlight */
					}),
					classes: { inputSizeSmall: classes.inputMarginDense },
					onBlur: (e) => {
						let val = Number(e.target.value);
						if (typeof min === 'number' && val <= min) {
							val = min;
						} else if (typeof max === 'number' && val >= max) {
							val = max;
						}
						onCommitted(val);
						setInputValue(val);
					},
				}}
				InputLabelProps={{
					classes: {
						root: classes.textFieldLabel,
					},
				}}
				{...rest}
			/>
		);
	}
);

// const useStyles = makeStyles((theme) => ({}));

// const NumInput = ({ onCommitted, value, max, ...rest }) => {
// 	// const classes = useStyles();
// 	// const defaultRef = React.useRef();
// 	// const resolvedRef = ref || defaultRef;

// 	// React.useEffect(() => {
// 	// 	resolvedRef.current.indeterminate = indeterminate;
// 	// }, [resolvedRef, indeterminate]);

// 	return (
// 		<OutlinedNumberTextField
// 			// ref={resolvedRef}
// 			value={value}
// 			onCommitted={(val) => onCommitted(val)}
// 			min={0}
// 			step={1}
// 			max={max}
// 		/>
// 	);
// };
// NumInput.displayName = 'NumInput';

// NumInput.propTypes = {
// 	onCommitted: PropTypes.func.isRequired,
// 	value: PropTypes.any.isRequired,
// 	max: PropTypes.number.isRequired,
// };

// NumInput.defaultProps = {};

export default NumInput;
