import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';

// Constants
import {
	ROUTES_PATH_SCREENMANAGER,
	ROUTES_PATH_SCREENMANAGER_SCREENS,
	ROUTES_PATH_SCREENMANAGER_PLAYLISTS,
	ROUTES_PATH_SCREENMANAGER_DEPARTMENTS,
	// ROUTES_PATH_SCREENMANAGER_SCHEDULES,
	ROUTES_PATH_SCREENMANAGER_SCREENBOARD,
	ROUTES_PATH_SCREENMANAGER_PLAYER_UNIVERSE,
	ROUTES_PATH_SCREENMANAGER_SLIDES,
	ROUTES_PATH_SCREENMANAGER_SCREEN_SCHEDULE,
	ROUTES_PATH_SCREENMANAGER_PLAYLISTS_Synchronised,
} from 'routes';

import {
	Tv as ScreenManagerIcon,
	DevicesOther as ScreenIcon,
	LocalMovies as PlaylistIcon,
	LocationOn as DepartmentIcon,
	// Schedule as ScheduleIcon,
} from '@mui/icons-material';
import PublicIcon from '@mui/icons-material/Public';
const ScreenManager = loadable(() => import('./components/ScreenManager/ScreenManager'), {
	fallback: <Loading size={50} />,
});
const ScreenManagerScreens = loadable(() => import('./components/Screens/Screens'), {
	fallback: <Loading size={50} />,
});
const ScreenManagerPlaylists = loadable(() => import('./components/Playlists/Playlists'), {
	fallback: <Loading size={50} />,
});
const ScreenManagerSlides = loadable(() => import('./components/Slides/Slides'), {
	fallback: <Loading size={50} />,
});
const ScreenManagerDepartments = loadable(() => import('./components/Departments/Departments'), {
	fallback: <Loading size={50} />,
});
const ScreenManagerSchedules = loadable(() => import('./components/Schedules/Schedules'), {
	fallback: <Loading size={50} />,
});
const ScreenManagerScreenboard = loadable(() => import('./components/Screenboard/Screenboard'), {
	fallback: <Loading size={50} />,
});
const PlayerUniverse = loadable(() => import('./components/PlayerUniverse/PlayerUniverse'), {
	fallback: <Loading size={50} />,
});

const ScreenManagerPlaylistSynchronised = loadable(
	() => import('./components/Synchronised/Synchronised'),
	{
		fallback: <Loading size={50} />,
	}
);

const screenManagerRoutes = [
	{
		path: ROUTES_PATH_SCREENMANAGER, // unique key in routes
		sidebarName: <FormattedMessage id="pages.ScreenManager.Routes.ScreenManager" />, //'Screen Manager',
		sidebarIcon: ScreenManagerIcon,
		component: ScreenManager,
		// requiredFeatures: ['BronzeScreen'],
		children: [
			{
				path: ROUTES_PATH_SCREENMANAGER_SCREENS,
				sidebarName: <FormattedMessage id="pages.ScreenManager.Routes.Screen" />, //'Screens',
				sidebarIcon: ScreenIcon,
				component: ScreenManagerScreens,
				children: [
					{
						path: ROUTES_PATH_SCREENMANAGER_SCREEN_SCHEDULE,
						sidebarName: '',
						sidebarIcon: null,
						component: ScreenManagerSchedules,
					},
				],
			},
			{
				path: ROUTES_PATH_SCREENMANAGER_PLAYLISTS,
				sidebarName: <FormattedMessage id="pages.ScreenManager.Routes.Playlists" />, //'Playlists',
				sidebarIcon: PlaylistIcon,
				component: ScreenManagerPlaylists,
				children: [
					{
						path: ROUTES_PATH_SCREENMANAGER_SLIDES,
						sidebarName: '',
						sidebarIcon: null,
						component: ScreenManagerSlides,
					},
					{
						path: ROUTES_PATH_SCREENMANAGER_PLAYLISTS_Synchronised,
						sidebarName: '',
						sidebarIcon: null,
						component: ScreenManagerPlaylistSynchronised,
					},
				],
			},
			{
				path: ROUTES_PATH_SCREENMANAGER_DEPARTMENTS,
				sidebarName: <FormattedMessage id="pages.ScreenManager.Routes.Departments" />, //'Departments',
				sidebarIcon: DepartmentIcon,
				component: ScreenManagerDepartments,
				requiredUserLevel: { $gte: 40 },
			},
			// {
			// 	path: ROUTES_PATH_SCREENMANAGER_SCHEDULES,
			// 	sidebarName: <FormattedMessage id="pages.ScreenManager.Routes.Schedules" />, //'Schedules',
			// 	sidebarIcon: ScheduleIcon,
			// 	component: ScreenManagerSchedules,
			// 	requiredUserLevel: { $gte: 40 },
			// },
			{
				path: ROUTES_PATH_SCREENMANAGER_SCREENBOARD,
				sidebarName: <FormattedMessage id="pages.ScreenManager.Routes.Screenboard" />, //'Screenboard',
				sidebarIcon: ScreenIcon,
				component: ScreenManagerScreenboard,
				requiredUserLevel: { $gte: 50 },
			},
			{
				path: ROUTES_PATH_SCREENMANAGER_PLAYER_UNIVERSE,
				sidebarName: (
					<FormattedMessage id="pages.ScreenManager.components.ScreenManager.PlayerUniverseNavName" />
				), //'PlayerUniverse',
				sidebarIcon: PublicIcon,
				component: PlayerUniverse,
				requiredUserLevel: { $gte: 60 },
			},
		],
	},
];

let screenManagerRoutesByPath = {};
const setRoutesByPath = (routes = []) => {
	routes.forEach((route) => {
		screenManagerRoutesByPath[route.path] = route;
		if (route.children && route.children.length > 0) {
			setRoutesByPath(route.children);
		}
	});
};
setRoutesByPath(screenManagerRoutes);

export { screenManagerRoutesByPath };

export default screenManagerRoutes;
