import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';

// Intl Lang
import { useIntl } from 'react-intl';

// icons
import { Email as EmailIcon, LockOpen as LocakOpenIcon } from '@mui/icons-material';

// CSS
import { default as useStyles } from './ForgetPWFormStyle.jsx';

function ForgetPWForm({ clickToLogin, logo, className, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();

	return (
		<Card raised className={cx(classes.card, className)}>
			<CardHeader
				className={classes.cardHeader}
				title={<img src={logo} alt="logo" className={classes.logoImg} />}
			/>
			<CardContent>
				<div className={classes.divider}>
					<div className={classes.dividerLeft}>
						<Typography variant="h3" style={{ color: '#555' }} gutterBottom>
							{intl.formatMessage({ id: 'pages.Login.components.ForgetPWForm.TitleText' })}
						</Typography>
						<Typography variant="body1" gutterBottom>
							{intl.formatMessage({ id: 'pages.Login.components.ForgetPWForm.EnterEmailText' })}
						</Typography>
						<Typography variant="body2" style={{ fontWeight: 600 }}>
							{intl.formatMessage({ id: 'pages.Login.components.ForgetPWForm.DescriptionText' })}
						</Typography>
					</div>
					<div className={classes.dividerRight}>
						<LocakOpenIcon style={{ fontSize: '4rem', color: '#9A9897' }} />
					</div>
				</div>
				<TextField
					id="email-input"
					label={intl.formatMessage({ id: 'pages.Login.components.ForgetPWForm.EmailLabel' })}
					name="email"
					fullWidth
					autoFocus
					helperText=""
					className={classes.textField}
					type="text"
					autoComplete="email"
					margin="normal"
					// variant='filled'
					InputLabelProps={{
						classes: {
							focused: classes.textFieldFocus,
						},
					}}
					InputProps={{
						classes: {
							underline: classes.textFieldInputUnderline,
						},
						endAdornment: (
							<InputAdornment position="end">
								<EmailIcon />
							</InputAdornment>
						),
					}}
				/>
			</CardContent>
			<CardActions className={classes.cardActions}>
				<Button className={classes.loginButton} variant="contained" fullWidth color="primary">
					<Typography variant="h4" style={{ margin: 8, textTransform: 'none' }}>
						{intl.formatMessage({ id: 'pages.Login.components.ForgetPWForm.SubmitText' })}
					</Typography>
				</Button>
				<Link underline="none" className={classes.forgetPWLink} onClick={clickToLogin}>
					{intl.formatMessage({ id: 'pages.Login.components.ForgetPWForm.BackToLoginText' })}
				</Link>
			</CardActions>
		</Card>
	);
}

ForgetPWForm.propTypes = {
	clickToLogin: PropTypes.func.isRequired,
	className: PropTypes.string,
	logo: PropTypes.string,
};

ForgetPWForm.defaultProps = {};

export default ForgetPWForm;
