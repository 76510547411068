import React from 'react';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { prepareImageField } from 'utils/artwork/artFieldsCreator';
import { createAnimationOfFieldInVideoArtwork } from 'utils/artwork/artUtilsWebUI';

const useStyles = makeStyles((theme) => ({
	pauseAnimation: {
		animationPlayState: 'paused !important',
	},
}));

function Image({
	field,
	fieldPreviewData,
	animationDelay,
	allowAnimation,
	pausingAnimation,
	isVideoArtwork,
	...rest
}) {
	const classes = useStyles();

	const imageData = prepareImageField({
		mode: 'preview',
		inputData: fieldPreviewData,
		field,
	});
	if (!imageData) return null;
	const { attrs, imgUrl } = imageData;
	const noAnimationStyle = `0s ease 0s 1 normal none running none`;
	// in case of video artwork, invlid fields are blocked in SVGPreview Comp, so it is safe to use field.insertionOnVideo
	const animationEntranceStyle = isVideoArtwork
		? createAnimationOfFieldInVideoArtwork(field)
		: field.animation.entrance
		? `${field.animation.entrance} ${
				field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
		  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
				animationDelay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
		  }s both`
		: noAnimationStyle;
	// const animationExitStyle = field.animation.exit
	// 	? `${field.animation.exit} ${field.animation.duration ||
	// 			1}s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${0}s both`
	// 	: noAnimationStyle;

	return (
		<svg
			x={field.position.left}
			y={field.position.top}
			width={field.position.width}
			height={field.position.height}
			overflow={'visible'}
		>
			<g
				className={cx({ [classes.pauseAnimation]: pausingAnimation })}
				style={{
					animation: allowAnimation ? animationEntranceStyle : noAnimationStyle,
					opacity: isVideoArtwork && !allowAnimation ? 0 : undefined, // allowAnimation is used to control replay of animation/video, there is about 100ms delay when replay animation/video, so for videoArtwork, we hide the preview when replay was just clicked but before replay actually begins
				}}
			>
				{/* Somehow, "transform" not working when together with "style" in the same <g> */}
				<g
					transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
						field.position.height / 2
					})`}
				>
					<image href={imgUrl} width="100%" height="100%" x="0" y="0" {...attrs} />
					{imgUrl && field.borderWidth > 0 && (
						<rect
							width={field.position.width + field.borderWidth}
							height={field.position.height + field.borderWidth}
							x={-field.borderWidth / 2}
							y={-field.borderWidth / 2}
							fill="none"
							stroke={field.borderColor.hex || '#000000'}
							strokeWidth={field.borderWidth}
						></rect>
					)}
				</g>
			</g>
		</svg>
	);
}

Image.propTypes = {
	field: PropTypes.object.isRequired, // it is text field
	fieldPreviewData: PropTypes.object.isRequired, // the preview data of a field
	animationDelay: PropTypes.number.isRequired, // animation delay. Relative to previous fields
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is aninimation paused (on video end in videoArtwork)
	isVideoArtwork: PropTypes.bool.isRequired,
};

Image.defaultProps = {};
export default React.memo(Image);
