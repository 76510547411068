import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

// MUI components
import { Fab, Tooltip } from '@mui/material';

import { Play as PlayAnimationIcon } from 'mdi-material-ui';

// intl lang
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		borderRadius: '50%',
	},

	floatButton: {
		width: 48,
		height: 48,
		minHeight: 'unset',
		opacity: 0.9,
		'&:hover': {
			opacity: 1,
		},
	},
}));

function ReplayBtn({ replayHandler, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();
	return (
		<div className={classes.root}>
			<Tooltip
				title={intl.formatMessage({
					id: 'pages.Artwork.components.PreviewControlPanel.playAnimationTooltip',
				})}
				placement="top"
				arrow
			>
				<Fab
					color="secondary"
					aria-label="replay"
					className={classes.floatButton}
					onClick={replayHandler}
				>
					<PlayAnimationIcon fontSize="large" />
				</Fab>
			</Tooltip>
		</div>
	);
}

ReplayBtn.propTypes = {
	replayHandler: PropTypes.func.isRequired,
};

export default ReplayBtn;
