/*
 *
 * Artwork Designer
 *
 */
import undoable from 'redux-undo';
import config from 'config';
import {
	RESET_DESIGNER_TEMPLATE,
	// GET_DESIGNER_TEMPLATE,
	FETCH_ARTWORK_DESIGN_TEMPLATE,
	FETCH_ARTWORK_DESIGN_TEMPLATE_SUCCESS,
	FETCH_ARTWORK_DESIGN_TEMPLATE_FAIL,
	RESET_FETCH_ARTWORK_DESIGN_TEMPLATE,
	SET_LOCAL_DESIGN_TEMPLATE_BY_REMOTE_TEMPLATE,
	UPDATE_LOCAL_DESIGNER_TEMPLATE,
	SET_ARTWORK_DESIGN_OPERATION_STATE,
	SAVE_ARTWORK_DESIGN_TEMPLATE,
	SAVE_ARTWORK_DESIGN_TEMPLATE_SUCCESS,
	SAVE_ARTWORK_DESIGN_TEMPLATE_FAIL,
	// SAVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
	// SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
	// RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
	// RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
	UNDO_ARTWORK_DESIGN_TEMPLATE,
	REDO_ARTWORK_DESIGN_TEMPLATE,
	// ARTWORK_SAVE_FILE,
	// ARTWORK_SAVE_FILE_SUCCESS,
	// ARTWORK_SAVE_FILE_FAIL,
	// ARTWORK_RESET_SAVE_FILE_STATUS,
	ARTWORK_IMPORT_TEMPLATE,
} from 'redux/actions';
const initialState = {
	// name: 'UNTITLED',
	// pages: [
	// 	{
	// 		idx: 0, // we may support only 1 page for very long time
	// 		name: 'UNTITLED',
	// 		dimension: { width: 300, height: 150 },
	// 		mediaId: null,
	// 		mediaUrl: '',
	// 		groups: [],
	// 		fields: [],
	// 	},
	// ],

	name: 'UNTITLED',
	dimension: { width: 300, height: 150 }, // in pixel
	mediaId: null,
	mediaUrl: '', // this is the sample preview to show how the template looks like
	// templateMediaUrl: '',
	// // templateBGUrl: '',
	// templatePdfUrl: '',
	// templateSvgUrl: '',
	templateBG: {},
	groups: [],
	lastUsedGroupName: 'Group 1',
	fields: [],
	htmlArtwork: true,
	autoCreateArtwork: false,
	videoArtwork: false,
	videoPreviewFieldId: '',
	templateDuration: 0,
	isEsignTemplate: false,
	isEsignOnly: false,
	filenameFieldId: '',
	filenameFieldAppend: false,
	outputTemplate: null, // output template id
	alternativeTemplateId: '', // alterenative template id (may not be used at all)

	metadata: {},
	originalTemplate: null,

	// design states - undoable opetation states. [action.designStates]
	selectedFieldIds: [],
	paletteTabIndex: 0,
	designSettingOpen: false,
	// actions status
	getTemplateStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	saveTemplateStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	saveArtworkFileStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	// others
	hasUnsavedData: false,
	offlineDataStatus: '', // possible value: '', 'OK', 'FAILED'
};

function artworkDesignerTemplate(state = initialState, action) {
	switch (action.type) {
		case RESET_DESIGNER_TEMPLATE:
			return initialState;
		// case GET_DESIGNER_TEMPLATE:
		// 	return {
		// 		...state,
		// 		...action.template,
		// 	};
		case FETCH_ARTWORK_DESIGN_TEMPLATE:
			return {
				...state,
				getTemplateStatus: 'PROCESSING',
			};
		case FETCH_ARTWORK_DESIGN_TEMPLATE_SUCCESS:
			return {
				...state,
				getTemplateStatus: 'OK',
				originalTemplate: action.result.data,
			};
		case FETCH_ARTWORK_DESIGN_TEMPLATE_FAIL:
			return {
				...state,
				getTemplateStatus: 'FAILED',
			};
		case RESET_FETCH_ARTWORK_DESIGN_TEMPLATE:
			return {
				...state,
				getTemplateStatus: '',
			};
		case SET_LOCAL_DESIGN_TEMPLATE_BY_REMOTE_TEMPLATE:
			return {
				...state,
				...action.formatedDesignTemplate,
			};
		case UPDATE_LOCAL_DESIGNER_TEMPLATE:
			return {
				...state,
				...action.updatedTemplate,
				saveTemplateStatus: '', // reset template save status whenever the template is updated
				hasUnsavedData: true,
			};
		case SET_ARTWORK_DESIGN_OPERATION_STATE:
			return {
				...state,
				...action.designStates,
			};
		// case SAVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS:
		// 	// to prevent react re-render, we assign value directly here
		// 	state.offlineDataStatus = 'OK';
		// 	return state;
		// // return { ...state, offlineDataStatus: 'OK' };
		// case SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL:
		// 	// to prevent react re-render, we assign value directly here
		// 	state.offlineDataStatus = 'FAILED';
		// 	return state;
		// return { ...state, offlineDataStatus: 'FAILED' };
		// case RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS:
		// 	return {
		// 		...state,
		// 		...action.offlineTemplate,
		// 		saveTemplateStatus: '',
		// 		offlineDataStatus: '',
		// 		selectedFieldIds: [],
		// 		paletteTabIndex: 0,
		// 		designSettingOpen: false,
		// 	};
		// case RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL:
		// 	return { ...state, offlineDataStatus: 'FAILED' };
		case SAVE_ARTWORK_DESIGN_TEMPLATE:
			return {
				...state,
				saveTemplateStatus: 'PROCESSING',
			};
		case SAVE_ARTWORK_DESIGN_TEMPLATE_SUCCESS:
			return {
				...state,
				saveTemplateStatus: 'OK',
				hasUnsavedData: false,
			};
		case SAVE_ARTWORK_DESIGN_TEMPLATE_FAIL:
			return {
				...state,
				saveTemplateStatus: 'FAILED',
			};
		// case ARTWORK_SAVE_FILE:
		// 	return {
		// 		...state,
		// 		saveArtworkFileStatus: 'PROCESSING',
		// 	};
		// case ARTWORK_SAVE_FILE_SUCCESS:
		// 	return {
		// 		...state,
		// 		saveArtworkFileStatus: 'OK',
		// 	};
		// case ARTWORK_SAVE_FILE_FAIL:
		// 	return {
		// 		...state,
		// 		saveArtworkFileStatus: 'FAILED',
		// 	};
		// case ARTWORK_RESET_SAVE_FILE_STATUS:
		// 	return {
		// 		...state,
		// 		saveArtworkFileStatus: '',
		// 	};
		case ARTWORK_IMPORT_TEMPLATE:
			return {
				...state,
				...action.importTemplate,
				// reset other properties to initial state
				metadata: {},
				originalTemplate: null,
				selectedFieldIds: [],
				paletteTabIndex: 0,
				designSettingOpen: false,
				// actions status
				getTemplateStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
				saveTemplateStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
				saveArtworkFileStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
				// others
				hasUnsavedData: false,
				offlineDataStatus: '', // possible value: '', 'OK', 'FAILED'
			};
		default:
			return state;
	}
}

// export default artworkDesignerTemplate;
export default undoable(artworkDesignerTemplate, {
	limit: config.artworkDesignUndoLimit,
	ignoreInitialState: true,
	// neverSkipReducer: true,
	// initTypes: [
	// 	SET_LOCAL_DESIGN_TEMPLATE_BY_REMOTE_TEMPLATE,
	// 	RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
	// ],
	undoType: UNDO_ARTWORK_DESIGN_TEMPLATE,
	redoType: REDO_ARTWORK_DESIGN_TEMPLATE,
	// eslint-disable-next-line no-unused-vars
	filter: (action, currentState, previousHistory) => {
		if (
			[
				UPDATE_LOCAL_DESIGNER_TEMPLATE,
				SET_ARTWORK_DESIGN_OPERATION_STATE,
				SET_LOCAL_DESIGN_TEMPLATE_BY_REMOTE_TEMPLATE,
				// RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
				ARTWORK_IMPORT_TEMPLATE,
			].includes(action.type)
		) {
			return true;
		} else {
			return false;
		}
	},
});
