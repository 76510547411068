import React from 'react';

// MUI components
import { SvgIcon } from '@mui/material';

function EllipsisLoader({ ...rest }) {
	return (
		<SvgIcon viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" {...rest}>
			<circle cx="84" cy="50" r="10" fill="#e58f94">
				<animate
					attributeName="r"
					repeatCount="indefinite"
					dur="0.5s"
					calcMode="spline"
					keyTimes="0;1"
					values="10;0"
					keySplines="0 0.5 0.5 1"
					begin="0s"
				></animate>
				<animate
					attributeName="fill"
					repeatCount="indefinite"
					dur="2s"
					calcMode="discrete"
					keyTimes="0;0.25;0.5;0.75;1"
					values="#e58f94;#abbd81;#f8b26a;#f47e60;#e58f94"
					begin="0s"
				></animate>
			</circle>
			<circle cx="16" cy="50" r="10" fill="#e58f94">
				<animate
					attributeName="r"
					repeatCount="indefinite"
					dur="2s"
					calcMode="spline"
					keyTimes="0;0.25;0.5;0.75;1"
					values="0;0;10;10;10"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
					begin="0s"
				></animate>
				<animate
					attributeName="cx"
					repeatCount="indefinite"
					dur="2s"
					calcMode="spline"
					keyTimes="0;0.25;0.5;0.75;1"
					values="16;16;16;50;84"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
					begin="0s"
				></animate>
			</circle>
			<circle cx="50" cy="50" r="10" fill="#f47e60">
				<animate
					attributeName="r"
					repeatCount="indefinite"
					dur="2s"
					calcMode="spline"
					keyTimes="0;0.25;0.5;0.75;1"
					values="0;0;10;10;10"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-0.5s"
				></animate>
				<animate
					attributeName="cx"
					repeatCount="indefinite"
					dur="2s"
					calcMode="spline"
					keyTimes="0;0.25;0.5;0.75;1"
					values="16;16;16;50;84"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-0.5s"
				></animate>
			</circle>
			<circle cx="84" cy="50" r="10" fill="#f8b26a">
				<animate
					attributeName="r"
					repeatCount="indefinite"
					dur="2s"
					calcMode="spline"
					keyTimes="0;0.25;0.5;0.75;1"
					values="0;0;10;10;10"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-1s"
				></animate>
				<animate
					attributeName="cx"
					repeatCount="indefinite"
					dur="2s"
					calcMode="spline"
					keyTimes="0;0.25;0.5;0.75;1"
					values="16;16;16;50;84"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-1s"
				></animate>
			</circle>
			<circle cx="16" cy="50" r="10" fill="#abbd81">
				<animate
					attributeName="r"
					repeatCount="indefinite"
					dur="2s"
					calcMode="spline"
					keyTimes="0;0.25;0.5;0.75;1"
					values="0;0;10;10;10"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-1.5s"
				></animate>
				<animate
					attributeName="cx"
					repeatCount="indefinite"
					dur="2s"
					calcMode="spline"
					keyTimes="0;0.25;0.5;0.75;1"
					values="16;16;16;50;84"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-1.5s"
				></animate>
			</circle>
		</SvgIcon>
	);
}

EllipsisLoader.propTypes = {};

EllipsisLoader.defaultProps = {};
export default EllipsisLoader;
