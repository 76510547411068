import React from 'react';

// import config from 'config';

import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import { MenuItem, Paper, Select, Link, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { moment } from 'utils/libHelper';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
	Search,
	RTTrComp,
	ScreenManagerGridView,
	// Dialog
} from 'components';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

// icons
import {
	Report as InactiveIcon,
	Check as ActiveIcon,
	StayCurrentPortraitOutlined as PortraitIcon,
	StayCurrentLandscapeOutlined as LandscapeIcon,
	Refresh as ResetIcon,
} from '@mui/icons-material';

// CSS
import ScreenboardStyle from './ScreenboardStyle.jsx';

import { getRouteScreenActAs } from 'routes';

// redux
import { connect } from 'react-redux';
import {
	openGlobalDialog,
	resetGlobalDialog,
	fetchScreenManagerScreenboard,
	notifyError,
	notifyGeneral,
	fetchUserDataByIdGroup,
	resetUserDataByIdGroup,
} from 'redux/actions'; // actions

import { setCommandsToPlayer } from 'restful';

class Screenboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filtered: [],
			isResetDisable: false,
			resetScreenId: '',
			isDesktop: false,
		};
		this.updateScreenResolution = this.updateScreenResolution.bind(this);
		this.columns = [
			{
				accessor: (d) => d.userCompany || '',
				id: 'company',
				Header: 'Site', // props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.NameCol' }), //'Site Name',
				resizable: true,
				sortable: true,
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => {
					return (
						<Link
							variant="body1"
							// className={this.props.classes.hoverCursor}
							component="button"
							underline="always"
							onClick={() => {
								window.location.assign(
									getRouteScreenActAs(cellInfo.original.userEmail, cellInfo.original.createdBy.uid)
								);
							}}
						>
							<Typography title={cellInfo.original.userEmail}>{cellInfo.value}</Typography>
						</Link>
					);
				},
			},
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.NameCol' }), //'Screen Name',
				resizable: true,
				sortable: true,
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => cellInfo.value,
			},
			{
				accessor: (d) => d.playerCode || '',
				id: 'playerCode',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.CodeCol' }), //'Screen Name',
				resizable: true,
				sortable: true,
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => cellInfo.value,
			},
			{
				accessor: (d) => d.orientation || '',
				id: 'orientation',
				Header: (
					<div
						title={props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Screens.OrientationCol',
						})}
					>
						<PortraitIcon className={this.props.classes.portraitIcon} />
						<LandscapeIcon className={this.props.classes.landscapeIcon} />
					</div>
				),
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => (cellInfo.value === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />),
			},
			{
				accessor: (d) => d.currentContent,
				id: 'currentContent',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentContentCol',
				}), //'Current Content',
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => cellInfo.value.playlist.name || '-',
			},
			{
				accessor: (d) => d.status || '',
				id: 'status',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentStatusCol',
				}), //'Status',
				resizable: true,
				sortable: true,
				filterable: true,
				filterMethod: (filter, row) => {
					if (filter.value === 'all') {
						return true;
					}
					if (filter.value === 'active') {
						return row.status === 'ACTIVE';
					}
					if (filter.value === 'never') {
						return row.status === 'NEVER';
					}
					// Status === 1 means Inactive
					return row.status === 'INACTIVE';
				},
				// eslint-disable-next-line react/prop-types
				Filter: ({ filter, onChange }) => (
					<Select
						variant="standard"
						onChange={(event) => {
							onChange(event.target.value);
							this.setState({ filtered: [{ id: 'status', value: event.target.value }] });
						}}
						classes={{ select: props.classes.dropdown }}
						style={{ width: '100%', textAlign: 'left' }}
						// eslint-disable-next-line react/prop-types
						value={filter ? filter.value : 'all'}
					>
						<MenuItem value="all">Show All</MenuItem>
						<MenuItem value="active">Active</MenuItem>
						<MenuItem value="inactive">Not Active</MenuItem>
						<MenuItem value="never">Not Yet Activated</MenuItem>
					</Select>
				),
				Cell: (cellInfo) =>
					cellInfo.value === 'ACTIVE' ? (
						<ActiveIcon className={props.classes.success} style={{ fontSize: 30 }} />
					) : (
						<InactiveIcon color="error" className={props.classes.shake} style={{ fontSize: 30 }} />
					),
			},
			{
				accessor: (d) => d.lastConnectedAt || '',
				id: 'lastConnectedAt',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.lastConnectedAtCol',
				}), //'Last Seen',
				resizable: true,
				sortable: true,
				Cell: (cellInfo) => {
					return cellInfo.original.lastConnectedAt
						? moment(cellInfo.original.lastConnectedAt).fromNow()
						: 'Never';
				},
			},
			{
				// NOTE: hidden column used just for default sorting
				accessor: (d) => d.createdAt || '',
				id: 'createdAt',
				Header: 'Created At',
				sortable: true,
				getProps: () => Object.create({ style: { display: 'none' } }),
				getHeaderProps: () => Object.create({ style: { display: 'none' } }),
			},
			{
				// NOTE: hidden column used just for default sorting by Status(Inactive, active, never activated).
				accessor: (d) => d.sortByStatus || '',
				id: 'sortByStatus',
				Header: 'Sort by status',
				sortable: true,
				getProps: () => Object.create({ style: { display: 'none' } }),
				getHeaderProps: () => Object.create({ style: { display: 'none' } }),
			},
			{
				// NOTE - this is a "filter all" DUMMY column
				// you can't HIDE it because then it wont FILTER
				// but it has a size of ZERO with no RESIZE and the
				// FILTER component is NULL (it adds a little to the front)
				// You may possibly move it to the end of columns
				Header: 'All',
				id: 'all',
				width: 0,
				resizable: false,
				sortable: false,
				Filter: () => {},
				// eslint-disable-next-line no-unused-vars
				getProps: (state, rowInfo, column) => {
					return {
						style: { display: 'none' },
					};
				},
				getHeaderProps: () => {
					return {
						style: { display: 'none' },
					};
				},
				filterMethod: (filter, rows) => {
					// using match-sorter
					// it will take the content entered into the "filter"
					// and search for it in keys
					const result = matchSorter(rows, filter.value, {
						keys: ['name', 'playerCode', 'orientation', 'status', 'company'],
						threshold: matchSorter.rankings.CONTAINS,
					});
					// console.log('row[0]:', result[0]);
					return result;
				},
				filterAll: true,
			},
		];

		this.screenActions = [
			{
				shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given screen
				icon: <ResetIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.RestartPlayerDialogTitle',
				}),
				isDisabled: (screen) => {
					let isPlayerInActive =
						screen.status === 'INACTIVE' ||
						screen.status === 'NEVER' ||
						screen.sysExecCommands.some((item) => item.name === 'REBOOT_SYS')
							? true
							: false;
					let isResetStateDisabled =
						this.state.isResetDisable === true && screen.id === this.state.resetScreenId
							? this.state.isResetDisable
							: isPlayerInActive;
					return isResetStateDisabled;
				},
				clickHandler: this.editScreen,
			},
		];
		this.searchColumnIdForCardView = ['name', 'playerCode', 'orientation', 'status', 'userCompany'];
		this.columnsGridView = [
			{
				accessor: (d) => d.userCompany || '',
				id: 'userCompany',
				Header: 'Site', // props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.NameCol' }), //'Site Name',
				resizable: true,
				editable: false,
				CustomCell: (cellInfo) => {
					return (
						<Link
							variant="body1"
							// className={this.props.classes.hoverCursor}
							component="button"
							underline="always"
							onClick={() => {
								window.location.assign(
									getRouteScreenActAs(cellInfo.userEmail, cellInfo.createdBy.uid)
								);
							}}
						>
							<Typography title={cellInfo.userEmail}>{cellInfo.userCompany}</Typography>
						</Link>
					);
				},
			},
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.NameCol' }), //'Screen Name',
				editable: false,
				editableComp: 'text',
			},
			{
				accessor: (d) => d.playerCode || '',
				id: 'playerCode',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.CodeCol' }), //'Screen Name',
				editable: false,
				editableComp: 'text',
			},
			{
				accessor: (d) => d.orientation || '',
				id: 'orientation',
				Header: (
					<div
						title={props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Screens.OrientationCol',
						})}
					>
						<PortraitIcon className={this.props.classes.portraitIcon} />
						<LandscapeIcon className={this.props.classes.landscapeIcon} />
					</div>
				),
				editable: false,
				CustomCell: (cellInfo) =>
					cellInfo.orientation === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />,
			},
			{
				accessor: (d) => d.currentContent,
				id: 'currentContent',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentContentCol',
				}), //'Current Content',
				editable: false,
				CustomCell: (cellInfo) => cellInfo.currentContent.playlist.name || '-',
			},
			{
				accessor: (d) => d.status || '',
				id: 'status',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentStatusCol',
				}), //'Status',
				editable: false,
				CustomCell: (cellInfo) =>
					cellInfo.status === 'ACTIVE' ? (
						<ActiveIcon className={props.classes.success} style={{ fontSize: 30 }} />
					) : (
						<InactiveIcon color="error" className={props.classes.shake} style={{ fontSize: 30 }} />
					),
			},
			{
				accessor: (d) => d.lastConnectedAt || '',
				id: 'lastConnectedAt',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.lastConnectedAtCol',
				}), //'Last Seen',
				editable: false,
				CustomCell: (cellInfo) => {
					return cellInfo.lastConnectedAt ? moment(cellInfo.lastConnectedAt).fromNow() : 'Never';
				},
			},
		];
	}

	// inputFilter = ({ column, filter, onChange }) => {
	// 	return <Input
	// 		id='brand_name_keyword'
	// 		placeholder="keyword"
	// 		onKeyPress={event => {
	// 			if (event.key === "Enter") {
	// 				onChange(event.target.value)
	// 			}
	// 		}}
	// 		className={this.props.classes.filterInput}
	// 	/>
	// }

	filterAll = (e) => {
		const { value } = e.target;
		// const filterAll = value;
		const filtered = [{ id: 'all', value: value }];
		// NOTE: this completely clears any COLUMN filters
		this.setState({ filtered });
	};

	getTrComp = (props) => <RTTrComp {...props} />;

	componentDidMount() {
		var masterUserIds = [];
		this.updateScreenResolution();
		window.addEventListener('resize', this.updateScreenResolution);
		this.props.fetchScreenManagerScreenboard({}, ({ result, error }) => {
			if (!error) {
				if (result.data.length > 0) {
					result.data.forEach((items) => {
						if (items.createdBy) {
							let exists = masterUserIds.includes(items.createdBy.uid);
							if (!exists) {
								masterUserIds.push(items.createdBy.uid);
							}
						}
					});
					this.props.fetchUserDataByIdGroup({
						queryParams: {
							userIds: masterUserIds.join(','),
						},
					});
				}
			}
		});
	}
	componentWillUnmount() {
		this.props.resetUserDataByIdGroup();
		window.removeEventListener('resize', this.updateScreenResolution);
	}
	updateScreenResolution() {
		this.setState({ isDesktop: window.innerWidth > 955 });
	}
	// Restart the player confirmDialog
	restartPlayer = (screenboardData) => {
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Screens.RestartPlayerDialogTitle',
			}),
			content: (
				<Typography variant="body1" gutterBottom style={{ minHeight: 50, marginTop: 25 }}>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Screens.RestartPlayerDialogMessage"
							values={{
								playerName: (
									<Typography variant="h6" component="span" color="secondary">
										{screenboardData.name}
									</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				let reqData = {
					commands: [
						{
							name: 'REBOOT_SYS',
						},
					],
				};
				let playerId = screenboardData.id;
				setCommandsToPlayer({ playerId, bodyParams: reqData })
					.then(() => {
						this.setState({ isResetDisable: true });
						this.setState({ resetScreenId: playerId });
						notifyGeneral(`Player has been restarted successfully`, 'success');
					})
					.catch((err) => {
						notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
					});
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};

	render() {
		const { classes, screenManagerScreenboard, intl, usersDataByGroup } = this.props;
		const { isFetching, screens } = screenManagerScreenboard;
		let screensData = screens.map((item) => {
			item.status = item.lastConnectedAt === undefined ? 'NEVER' : item.status;
			// For sortByStatus -> Active is 2, Inactive is 1, and Never is 3. It will sort in this order.
			item.sortByStatus = item.status === 'ACTIVE' ? 2 : item.status === 'INACTIVE' ? 1 : 3;
			if (usersDataByGroup.results !== undefined) {
				var userDataObj = (usersDataByGroup.results || []).find(
					(userItem) => userItem.id.toString() === item.createdBy.uid
				);
				if (userDataObj !== undefined) {
					item.userCompany = userDataObj.company;
					item.userEmail = userDataObj.email;
				}
			}
			return item;
		});
		const isDesktop = this.state.isDesktop;

		return (
			<div className={classes.contentWrapper}>
				<Paper className={classes.header}>
					<Typography variant="h6" gutterBottom className={classes.title}>
						{intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.TableTitle' })}
					</Typography>
					<Search
						placeholder={intl.formatMessage({ id: 'GENERAL.Search' })}
						className={classes.search}
						onChange={this.filterAll.bind(this)}
						onClearClick={() => {
							if (this.state.filtered.length === 0) return;
							this.setState({ filtered: [] });
						}}
						// OnEnterKeyPressed={this.filterAll.bind(this)}
					/>
				</Paper>
				{!isDesktop ? (
					<ScreenManagerGridView
						cardViewData={screensData}
						columns={this.columnsGridView}
						previewHeight={130}
						screenActions={this.screenActions}
						searchFilter={this.state.filtered}
						searchColumnId={this.searchColumnIdForCardView}
						noDataMsg={
							isFetching
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'ReactTable.NoDataText',
								  })
						}
					/>
				) : (
					<ReactTable
						// ref="screensTable"
						// International Lang
						previousText={intl.formatMessage({ id: 'ReactTable.PreviousText' })}
						nextText={intl.formatMessage({ id: 'ReactTable.NextText' })}
						loadingText={intl.formatMessage({ id: 'ReactTable.LoadingText' })}
						noDataText={intl.formatMessage({ id: 'ReactTable.NoDataText' })}
						pageText={intl.formatMessage({ id: 'ReactTable.PageText' })}
						ofText={intl.formatMessage({ id: 'ReactTable.OfText' })}
						rowsText={intl.formatMessage({ id: 'ReactTable.RowsText' })}
						defaultFiltered={[]}
						defaultSorted={[
							{ id: 'sortByStatus' },
							{ id: 'lastConnectedAt' },
							{ id: 'createdAt', desc: true },
						]}
						filtered={this.state.filtered}
						// onFilteredChange={filtered => {
						// 	this.setState({ filtered });
						// }}
						TrComponent={this.getTrComp}
						// TdComponent={TdComponent}
						// manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={screensData}
						// pages={pages} // Display the total number of pages
						loading={isFetching} // Display the loading overlay when we need it
						// onFetchData={this.fetchBrandsData.bind(this)} // Request new data when things change
						// data={this.props.getProductsDataReducerState.productsData}
						multiSort={false}
						columns={this.columns}
						defaultPageSize={10}
						style={{
							height: '600px', // This will force the table body to overflow and scroll, since there is not enough room
						}}
						className="-striped -highlight"
						// eslint-disable-next-line no-unused-vars
						defaultFilterMethod={(filter, row, column) => {
							const id = filter.pivotId || filter.id;
							return row[id] !== undefined
								? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
								: true;
						}}
						// eslint-disable-next-line no-unused-vars
						getTheadTrProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.tableHeadCell + ' ' + state.className,
								style: { alignItems: 'center' },
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTableProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.table + ' ' + state.className,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTrProps={(state, rowInfo, column, instance) => {
							if (!rowInfo) return {};
							// Disable the reset player button if Inactive or it has command - REBOOT_SYS
							let isPlayerInActive =
								rowInfo.original.status === 'INACTIVE' ||
								rowInfo.original.status === 'NEVER' ||
								rowInfo.original.sysExecCommands.some((item) => item.name === 'REBOOT_SYS')
									? true
									: false;
							// let isResetStateDisabled =
							// 	this.state.isResetDisable === false ? isPlayerInActive : this.state.isResetDisable;
							let isResetStateDisabled =
								this.state.isResetDisable === true &&
								rowInfo.original.id === this.state.resetScreenId
									? this.state.isResetDisable
									: isPlayerInActive;
							return {
								style: {
									alignItems: 'center',
								},
								resetAction: this.restartPlayer.bind(this, rowInfo.original),
								isResetDisabled: isResetStateDisabled,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTdProps={(state, rowInfo, column, instance) => {
							// get TD (column) props
							// let editAction, editingUI;
							// if (rowInfo) {
							// 	switch (column.id) {
							// 		case 'name':
							// 		case 'restricted_to':
							// 		case 'metadata':
							// 			editingUI = <EditBrandMeta brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		case 'brand_logo':
							// 			editingUI = <EditingLogo brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		default:
							// 			break;
							// 	}
							// 	if (editingUI) editAction = () => this.setState({ editingUI: editingUI });
							// }
							return {
								// style: { whiteSpace: 'unset' },
								// className: classes.tableCell + ' ' + state.className,
								// editAction: editAction,
							};
						}}
					/>
				)}

				{
					// <Dialog open={this.state.addingScreenOpen} size='md' title='Enter the details for the new Screen:' content={this.state.addingScreenUI} onClose={this.onCloseAddingScreenDialog} />
				}
			</div>
		);
	}
}

Screenboard.propTypes = {
	classes: PropTypes.object.isRequired,
	screenManagerScreenboard: PropTypes.object.isRequired,
	fetchScreenManagerScreenboard: PropTypes.func.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	fetchUserDataByIdGroup: PropTypes.func.isRequired,
	resetUserDataByIdGroup: PropTypes.func.isRequired,
	usersDataByGroup: PropTypes.object.isRequired,
};

Screenboard.defaultProps = {
	// multiple: false,
	// allowZip: false,
	// allowPreview: true,
	// disableClick: false,
};

const mapStateToProps = (state) => {
	return {
		screenManagerScreenboard: state.screenManagerScreenboard,
		usersDataByGroup: state.usermanager.userDataByGroup,
	};
};

export default connect(mapStateToProps, {
	openGlobalDialog,
	resetGlobalDialog,
	fetchScreenManagerScreenboard,
	notifyError,
	notifyGeneral,
	fetchUserDataByIdGroup,
	resetUserDataByIdGroup,
})(injectIntl(withStyles(ScreenboardStyle)(Screenboard)));
