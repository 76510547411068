// React-Table-6 Tr Custom Component
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { lightBlue, lightGreen, orange, red, grey } from '@mui/material/colors';

import { injectIntl } from 'react-intl';

import {
	// FindReplace as FindReplaceIcon,
	Visibility as PreviewIcon,
	Edit as EditIcon,
	DeleteOutline as DeleteIcon,
	Cancel as CancelIcon,
	Save as SaveIcon,
	Reorder as ReorderIcon,
	FileCopy as DuplicateIcon,
	Lock as LockIcon,
	Refresh as ResetIcon,
} from '@mui/icons-material';

const RTTrCompStyle = (theme) => ({
	paper: {
		position: 'relative',
		width: '100%',
	},
	rowButtonDivRight: {
		position: 'absolute',
		bottom: 4,
		right: theme.spacing(2),
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			right: 2,
		},
	},
	rowButtonDivLeft: {
		position: 'absolute',
		// bottom: '5%',
		left: theme.spacing(2),
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			left: 2,
		},
	},
	rowDisabled: {
		backgroundColor: grey[500],
		opacity: 0.5,
	},
	iconButtons: {
		marginLeft: theme.spacing(1 / 2),
		marginRight: theme.spacing(1 / 2),
	},
	iconButtonRootSmaller: {
		fontSize: theme.typography.pxToRem(12),
		padding: theme.spacing(1 / 2),
	},
	iconButtonRootWithBGColor: {
		backgroundColor: lightGreen[300],
		'&:hover': {
			backgroundColor: lightGreen[500],
		},
		'&:disabled': {
			backgroundColor: grey[500],
		},
	},
	iconButtonRootWithRedBGColor: {
		backgroundColor: red[300],
		'&:hover': {
			backgroundColor: red[500],
		},
		'&:disabled': {
			backgroundColor: grey[500],
		},
	},
});

class RTTrComp extends React.Component {
	constructor() {
		super();
		this.state = {
			trStyle: {
				background: null,
			},
			mouseEntered: false,
		};
	}

	onMouseEnter = () => this.setState({ mouseEntered: true });
	onMouseLeave = () => this.setState({ mouseEntered: false });

	render() {
		let {
			children,
			intl,
			isHeaderRow,
			className,
			style,
			classes,
			previewAction,
			duplicateAction,
			editAction,
			deleteAction,
			saveAction,
			cancelAction,
			resetAction,
			dragHandleProps,
			isDragging,
			actionButtonsHidden,
			disabled,
			isResetDisabled,
			...rest
		} = this.props;
		let { mouseEntered } = this.state;
		// TODO: Find header row by classname of "-highlight" is not very accerate, but works so far
		isHeaderRow = isHeaderRow || (className || '').indexOf('-highlight') !== -1;

		// disabled is used to disable the whole row, whereas isResetDisabled is used to disable a cell(button)
		isResetDisabled = disabled === true ? disabled : isResetDisabled;
		return (
			<Paper
				elevation={mouseEntered ? 2 : 0}
				className={
					' rt-tr ' + className + ' ' + classes.paper + ' ' + (disabled ? classes.rowDisabled : '')
				}
				style={{
					...style,
					backgroundColor: isHeaderRow
						? undefined
						: !isDragging
						? saveAction
							? 'lightgreen'
							: mouseEntered
							? lightBlue[50]
							: undefined
						: orange[200], // '#f0f8ff'
					textAlign: isHeaderRow ? 'left' : undefined,
					// minHeight: isHeaderRow?undefined:50
				}}
				{...rest}
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
			>
				{children}
				{dragHandleProps && (
					<div className={classes.rowButtonDivLeft}>
						{
							<div {...dragHandleProps}>
								<Tooltip
									title={intl.formatMessage({ id: 'components.RTTrComp.DragToChangePosition' })}
								>
									{dragHandleProps.draggable ? (
										<ReorderIcon fontSize="small" color="action" />
									) : (
										<LockIcon />
									)}
								</Tooltip>
							</div>
						}
					</div>
				)}
				{(actionButtonsHidden ? mouseEntered : true) && (
					<div className={classes.rowButtonDivRight}>
						{previewAction && (
							<Tooltip title={intl.formatMessage({ id: 'GENERAL.Preview' })}>
								<IconButton
									classes={{
										root: cx(
											classes.iconButtonRootSmaller,
											classes.iconButtonRootWithBGColor,
											classes.iconButtons
										),
									}}
									color="primary"
									aria-label="preview"
									onClick={previewAction}
								>
									<PreviewIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						)}
						{duplicateAction && (
							<Tooltip title={intl.formatMessage({ id: 'GENERAL.Duplicate' })}>
								<IconButton
									component="span"
									classes={{
										root: cx(
											classes.iconButtonRootSmaller,
											classes.iconButtonRootWithBGColor,
											classes.iconButtons
										),
									}}
									color="primary"
									aria-label="duplicate"
									onClick={duplicateAction}
									disabled={disabled}
								>
									<DuplicateIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						)}
						{editAction && (
							<Tooltip title={intl.formatMessage({ id: 'GENERAL.Edit' })}>
								<IconButton
									component="span"
									classes={{
										root: cx(
											classes.iconButtonRootSmaller,
											classes.iconButtonRootWithBGColor,
											classes.iconButtons
										),
									}}
									color="primary"
									aria-label="edit"
									onClick={editAction}
									disabled={disabled}
								>
									<EditIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						)}
						{deleteAction && (
							<Tooltip title={intl.formatMessage({ id: 'GENERAL.Delete' })}>
								<IconButton
									component="span"
									classes={{
										root: cx(
											classes.iconButtonRootSmaller,
											classes.iconButtonRootWithRedBGColor,
											classes.iconButtons
										),
									}}
									// color="primary"
									aria-label="delete"
									// size='small'
									onClick={deleteAction}
									disabled={disabled}
								>
									<DeleteIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						)}
						{resetAction && (
							<Tooltip
								title={intl.formatMessage({
									id: 'pages.ScreenManager.components.Screens.RestartPlayerDialogTitle',
								})}
							>
								<IconButton
									component="span"
									classes={{
										root: cx(
											classes.iconButtonRootSmaller,
											classes.iconButtonRootWithRedBGColor,
											classes.iconButtons
										),
									}}
									// color="primary"
									aria-label="delete"
									// size='small'
									onClick={resetAction}
									disabled={isResetDisabled}
								>
									<ResetIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						)}
						{saveAction && (
							<Tooltip title={intl.formatMessage({ id: 'GENERAL.Save' })}>
								<IconButton
									classes={{
										root: cx(
											classes.iconButtonRootSmaller,
											classes.iconButtonRootWithBGColor,
											classes.iconButtons
										),
									}}
									color="primary"
									aria-label="save"
									onClick={saveAction}
								>
									<SaveIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						)}
						{cancelAction && (
							<Tooltip title={intl.formatMessage({ id: 'GENERAL.Cancel' })}>
								<IconButton
									classes={{
										root: cx(
											classes.iconButtonRootSmaller,
											classes.iconButtonRootWithBGColor,
											classes.iconButtons
										),
									}}
									color="primary"
									aria-label="cancel"
									onClick={cancelAction}
								>
									<CancelIcon fontSize="small" color="action" />
								</IconButton>
							</Tooltip>
						)}
					</div>
				)}
			</Paper>
		);
	}
}

RTTrComp.propTypes = {
	classes: PropTypes.object.isRequired,
	intl: PropTypes.any,
	editAction: PropTypes.func,
	deleteAction: PropTypes.func,
	resetAction: PropTypes.func,
	saveAction: PropTypes.func,
	cancelAction: PropTypes.func,
	duplicateAction: PropTypes.func,
	dragHandleProps: PropTypes.object, // draggable handle props
	isHeaderRow: PropTypes.bool,
	actionButtonsHidden: PropTypes.bool,
	previewAction: PropTypes.func,
	className: PropTypes.string,
	style: PropTypes.object,
	isDragging: PropTypes.bool,
	disabled: PropTypes.bool,
	isResetDisabled: PropTypes.bool,
};

RTTrComp.defaultProps = {
	// isRowSelected: false,
	editAction: undefined,
	deleteAction: undefined,
	resetAction: undefined,
	saveAction: undefined,
	cancelAction: undefined,
	duplicateAction: undefined,
	previewAction: undefined,
	isHeaderRow: undefined,
	actionButtonsHidden: false,
	disabled: false,
	isResetDisabled: false,
};

export default injectIntl(withStyles(RTTrCompStyle, { withTheme: true })(RTTrComp));
