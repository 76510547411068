// ##############################
// // // Screen Manager styles
// #############################
import { grey, cyan } from '@mui/material/colors';
const ScreenManagerStyle = (theme) => ({
	contentWrapper: {
		...theme.contentWrapper,
		maxWidth: 1200,
		top: 30,
	},
	paperContainer: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		// backgroundColor: 'yellow',
		margin: 'auto',
		// maxWidth: 140,
		// minWidth: 100,
		height: 260,
		width: 'auto',
		position: 'relative',
		'& img': {
			maxWidth: '100%',
			maxHeight: '100%',
			position: 'absolute',
			top: '0',
			left: '50%',
			transform: 'translateX(-50%)',
		},
		'&:hover': {
			backgroundColor: '#e2f2f3',
			textDecoration: 'underline',
			cursor: 'pointer',
			'& svg': {
				color: cyan[500],
			},
		},
	},
	routeLink: {
		textDecoration: 'none',
	},
	cardContainer: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		margin: 'auto',
		// maxWidth: 140,
		// minWidth: 100,
		height: 260,
		width: 500,
	},
	avatar: {
		margin: 10,
		width: 150,
		height: 150,
		// marginLeft: '60%',
		// marginTop: '-32%'
		// top: -245,
		// right: -260
		color: grey[500],
		backgroundColor: grey[300],
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-5%, -66%)',
	},
	icon: {
		// width: '30%',
		fontSize: 120,
		// '&:hover': {
		//   color: cyan[500],
		// },
	},
	sectionText: {
		textTransform: 'uppercase',
		position: 'absolute',
		bottom: 0,
		left: '50%',
		fontSize: '20px',
		whiteSpace: 'nowrap',
		transform: 'translateX(-50%)',
	},
});

export default ScreenManagerStyle;
