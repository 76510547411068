import React from 'react';

import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

// import Select from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
// import ListItemText from '@mui/material/ListItemText';
// import FormHelperText from '@mui/material/FormHelperText';

// import // MUIDatetimeNTimePickerPop
// 'components';

import { grey, green, orange } from '@mui/material/colors';

import { moment } from 'utils/libHelper';

// icons
import { DateRange as DateRangeIcon, Add as AddIcon } from '@mui/icons-material';

// redux
import { connect } from 'react-redux';
import {} from 'redux/actions'; // actions

let ScheduleQueueStyles = (theme) => ({
	// formControl: {
	//   margin: theme.spacing(1),
	//   minWidth: 240,
	// 	// maxWidth: '100%',
	// 	// width: '90%',
	// },
	// chips: {
	//   display: 'flex',
	//   flexWrap: 'wrap',
	// },

	// chip: {
	// 	margin: 2,
	// 	backgroundColor: orange[500],
	// },
	// chipDatetime: {
	// 	margin: 2,
	// 	backgroundColor: orange[500],
	// 	'&:hover':{
	// 		backgroundColor: orange[800]
	// 	},
	// 	'&:focus':{
	// 		backgroundColor: orange[800]
	// 	}
	// },
	// screenSelectionWrapper: {
	// 	flex: '0 1 auto',
	// 	// maxWidth: '45%',
	// 	margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
	// },
	// dateTimeWrapper: {
	// 	flex: '0 1 auto',
	// 	// maxWidth: '45%',
	// 	margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
	// },

	queueContainer: {
		display: 'flex',
		flexDirection: 'column',
		// flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
		alignContent: 'stretch',
		minHeight: 130,
		width: '100%',
		// paddingBottom: theme.spacing(2),
	},
	scheduleChip: {
		flex: '0 1 auto',
		minWidth: '85%',
		margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
		backgroundColor: grey[500],
		'&:hover': {
			backgroundColor: grey[800],
		},
		'&:focus': {
			backgroundColor: grey[800],
		},
	},
	scheduleValidButNotActiveChip: {
		flex: '0 1 auto',
		minWidth: '85%',
		margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
		backgroundColor: orange[800],
		'&:hover': {
			backgroundColor: orange[900],
		},
		'&:focus': {
			backgroundColor: orange[900],
		},
	},
	scheduleChipActive: {
		flex: '0 1 auto',
		minWidth: '85%',
		margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
		backgroundColor: green[800],
		'&:hover': {
			backgroundColor: green[800],
		},
		'&:focus': {
			backgroundColor: green[800],
		},
	},
	scheduleQueuePaper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: '100%',
		minHeight: 500,
	},
	scheduleQueueTitle: {
		width: '100%',
		margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
	},
	// titleContainer: {
	// 	position: 'relative',
	// 	width: '100%',
	// 	marginTop: theme.spacing(2),
	// 	marginBottom: theme.spacing(2),
	// },
	addButtonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing(2),
	},
	// addFab: {
	// 	position: 'absolute',
	// 	margin: theme.spacing(1),
	// 	top: 2,
	// 	left: theme.spacing(1),
	// },
});

class ScheduleQueue extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {}

	componentWillUnmount() {}
	render() {
		const {
			classes,
			schedules,
			currentSchedule,
			onScheduleClick,
			onScheduleDelete,
			onAddSchedule,
			intl,
		} = this.props;
		return (
			<Paper className={classes.scheduleQueuePaper}>
				<Typography
					align="center"
					color="primary"
					variant="h6"
					gutterBottom
					component="div"
					className={classes.scheduleQueueTitle}
				>
					{intl.formatMessage({
						id: 'pages.ScreenManager.components.Schedules.ScheduleQueue.Title',
					})}
				</Typography>

				<div className={classes.queueContainer}>
					{schedules.length > 0 ? (
						schedules.map((schedule, idx) => {
							return (
								<Chip
									key={idx}
									// size='small'
									icon={<DateRangeIcon />}
									label={`${moment(schedule.startDatetime).format('YYYY-MM-DD HH:mm')} To ${
										schedule.endDatetime && moment(schedule.endDatetime).isValid()
											? moment(schedule.endDatetime).format('YYYY-MM-DD HH:mm')
											: 'Forever'
									}`}
									className={
										schedule.scheduleId === currentSchedule.scheduleId
											? classes.scheduleChipActive
											: !moment(schedule.endDatetime).isValid() ||
											  moment(schedule.endDatetime).isAfter(moment())
											? classes.scheduleValidButNotActiveChip
											: classes.scheduleChip
									}
									color="primary"
									clickable
									onClick={() => {
										onScheduleClick(schedule);
									}}
									onDelete={() => {
										onScheduleDelete(schedule);
									}}
								/>
							);
						})
					) : (
						<div
							style={{
								display: 'inline-flex',
								flexDirection: 'column',
								alignItems: 'center',
								verticalAlign: 'middle',
								justifyContent: 'center',
								backgroundColor: grey[800],
								color: 'white',
								borderRadius: 25,
								height: 50,
								fontSize: 'medium',
								minWidth: '85%',
							}}
						>
							<span style={{ width: '100%', textAlign: 'center' }}>
								{intl.formatMessage({
									id: 'pages.ScreenManager.components.Schedules.ScheduleQueue.NoScheText',
								})}
							</span>
							<span style={{ width: '100%', textAlign: 'center' }}>
								{intl.formatMessage({
									id: 'pages.ScreenManager.components.Schedules.ScheduleQueue.AddScheFirstText',
								})}
							</span>
						</div>
					)}
					<div className={classes.addButtonContainer}>
						<Tooltip
							title={intl.formatMessage({
								id: 'pages.ScreenManager.components.Schedules.ScheduleQueue.AddScheButtonTooltip',
							})}
						>
							<Fab color="secondary" aria-label="Add" onClick={onAddSchedule}>
								<AddIcon fontSize="small" />
							</Fab>
						</Tooltip>
					</div>
				</div>
			</Paper>
		);
	}
}

ScheduleQueue.propTypes = {
	classes: PropTypes.object.isRequired,
	schedules: PropTypes.array.isRequired,
	currentSchedule: PropTypes.object,
	onScheduleClick: PropTypes.func.isRequired,
	onScheduleDelete: PropTypes.func.isRequired,
	onAddSchedule: PropTypes.func.isRequired,
};

ScheduleQueue.defaultProps = {
	currentSchedule: {},
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
	return {
		// screenManagerScreens: state.screenManagerScreens
	};
};

export default connect(mapStateToProps, {})(withStyles(ScheduleQueueStyles)(ScheduleQueue));
