import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { _ } from 'utils/libHelper';
import { isConcatField } from 'utils/artwork/artUtilsCommon';
// MUI components
import { FormControlLabel, Checkbox, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ToggleButton from '@mui/material/ToggleButton';

import {
	FormatAlignLeft as FormatAlignLeftIcon,
	FormatAlignCenter as FormatAlignCenterIcon,
	FormatAlignRight as FormatAlignRightIcon,
	ImageSearch as ImageSearchIcon,
	Clear as ClearIcon,
} from '@mui/icons-material';

import {
	FormatVerticalAlignTop as FormatVerticalAlignTopIcon,
	FormatVerticalAlignCenter as FormatVerticalAlignCenterIcon,
	FormatVerticalAlignBottom as FormatVerticalAlignBottomIcon,
} from 'mdi-material-ui';

// Constants
import { ART_VARIABLES } from '../../../Constants';
import {
	StyledToggleButtonGroup,
	StyledOutlinedTextFieldSelection,
	SimpleSwitchVer,
	SimpleNumberSlider,
	StyledOutlinedSelectionWithSubheader,
	DividerVer,
	DividerHor,
	StyledContainedButton,
	SimpleSwitchRow,
	// StyledOutlinedTextField,
} from '../../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

const useStylePaletteStyles = makeStyles((theme) => ({
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	twoColumnsRowDiv: {
		justifyContent: 'space-evenly',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	imageSizing: {
		width: '70%',
		margin: 0,
	},
	alignUserDefinedFormControlLabel: {
		margin: 0,
	},
	alignUserDefinedFormControlLabelText: {
		fontSize: 'inherit',
		textAlign: 'center',
	},
	alignUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
	toggleGroupSmall: {
		width: 30,
		height: 30,
	},
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
}));

export const ImagePaletteStyles = ({ field, updateField, setColorPicker, ...rest }) => {
	const classes = useStylePaletteStyles();
	const intl = useIntl();
	return (
		<div className={classes.tabWrapper}>
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.ImagePalette.imageSizingText',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledOutlinedTextFieldSelection
						className={classes.imageSizing}
						label=""
						value={field.sizing || ''}
						onChange={(e) => updateField({ sizing: e.target.value || '' })}
						smallMenu
						smallIcon
						// noneOption
						options={ART_VARIABLES.imageSizingOptions}
					/>
					<DividerVer />
					<SimpleSwitchVer
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.ImagePalette.clipImageText',
						})}
						style={{ padding: 0 }}
						checked={field.sizingClipImage}
						onChange={(e, checked) => updateField({ sizingClipImage: checked })}
					/>
				</div>
			</div>

			<DividerHor />
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.horAlignText',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledToggleButtonGroup
						value={field.horizontalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={(e, alignment) =>
							updateField({
								horizontalAlign: alignment || ART_VARIABLES.defaultFields.image.horizontalAlign,
							})
						}
						aria-label="image horizontal alignment"
					>
						<ToggleButton value="left" aria-label="left aligned">
							<FormatAlignLeftIcon />
						</ToggleButton>
						<ToggleButton value="center" aria-label="centered horizontal">
							<FormatAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="right" aria-label="right aligned">
							<FormatAlignRightIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<DividerVer />
					<FormControlLabel
						className={classes.alignUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={field.horizontalAlignUserDefined || false}
								onChange={(e) => updateField({ horizontalAlignUserDefined: e.target.checked })}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.alignUserDefinedFormControlLabelText,
						}}
					/>
				</div>
			</div>
			<DividerHor />
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.verAlignText',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledToggleButtonGroup
						value={field.verticalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={(e, alignment) =>
							updateField({
								verticalAlign: alignment || ART_VARIABLES.defaultFields.image.verticalAlign,
							})
						}
						aria-label="image vertical alignment"
					>
						<ToggleButton value="top" aria-label="top aligned">
							<FormatVerticalAlignTopIcon />
						</ToggleButton>
						<ToggleButton value="middle" aria-label="middle horizontal">
							<FormatVerticalAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="bottom" aria-label="bottpm aligned">
							<FormatVerticalAlignBottomIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<DividerVer />
					<FormControlLabel
						className={classes.alignUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={field.verticalAlignUserDefined || false}
								onChange={(e) => updateField({ verticalAlignUserDefined: e.target.checked })}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.alignUserDefinedFormControlLabelText,
						}}
					/>
				</div>
			</div>
			<DividerHor />
			<div className={classes.groupsDiv}>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<SimpleNumberSlider
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.borderWidthText',
						})}
						value={field.borderWidth}
						handleOnChange={(value) => updateField({ borderWidth: value })}
						step={ART_VARIABLES.borderWidthRange.step}
						min={ART_VARIABLES.borderWidthRange.min}
						max={ART_VARIABLES.borderWidthRange.max}
						unit={ART_VARIABLES.borderWidthRange.unit}
					/>
				</div>
			</div>
			<DividerHor />
			<div className={classes.groupsDiv}>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<span style={{ paddingRight: 8, width: 90, textAlign: 'left' }}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.borderColorText',
						})}
					</span>
					<div
						style={{ width: `calc(100% - 90px)`, display: 'inline-flex', justifyContent: 'center' }}
					>
						<div
							style={{
								backgroundColor: field.borderColor.hex || '#000',
								width: '80%',
								height: 40,
								margin: '0px 4px',
								cursor: 'pointer',
								borderRadius: 4,
							}}
							onClick={(e) =>
								setColorPicker({
									anchorEl: e.currentTarget,
									fieldKey: 'borderColor',
									disableAlpha: true,
									enablePantone: true,
									enableCMYK: true,
								})
							}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};

ImagePaletteStyles.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	setColorPicker: PropTypes.func.isRequired,
	// artworkExtraData: PropTypes.object.isRequired,
	// templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
};

ImagePaletteStyles.defaultProps = {};

/** ImagePaletteData Component */
const useDataPaletteStyles = makeStyles((theme) => ({
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
	defaultValuePreview: {
		width: '100%',
		// height: 120,
		padding: '8px 20%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export const ImagePaletteData = ({
	field,
	updateField,
	artworkExtraData,
	templateFields,
	openMediaFileChooser,
	...rest
}) => {
	const classes = useDataPaletteStyles();
	const intl = useIntl();
	// generate grouped template fields
	const groupedTemplateFieldsForPreSearch = {};
	const groupedFieldsWithPredefinedSSValue = {};
	const groupedGenerateAIPrompt = {};
	// const groupedBasedonFields = {};
	templateFields.forEach((f) => {
		// if (f.id !== field.id) {
		// if (!groupedTemplateFields[f.groupName]) {
		// 	groupedTemplateFields[f.groupName] = [];
		// }
		// groupedTemplateFields[f.groupName].push({
		// 	value: f.id,
		// 	label: f.name,
		// 	disabled: f.id === field.id,
		// });
		if (!groupedTemplateFieldsForPreSearch[f.groupName]) {
			groupedTemplateFieldsForPreSearch[f.groupName] = [];
		}
		groupedTemplateFieldsForPreSearch[f.groupName].push({
			value: f.id,
			label: f.name,
			/**
			 * disable following fields from selection as pre-search field:
			 * - concatenation field (no nest concatenation)
			 * - non-text field
			 */
			disabled: f.type !== 'text' || isConcatField(f),
		});

		if (f.predefinedValue && f.predefinedValue.type === 'spreadsheet' && f.predefinedValue.from) {
			if (!groupedFieldsWithPredefinedSSValue[f.groupName]) {
				groupedFieldsWithPredefinedSSValue[f.groupName] = [];
			}
			groupedFieldsWithPredefinedSSValue[f.groupName].push({
				value: f.id,
				label: f.name,
				disabled: f.id === field.id,
			});
		}

		if (!groupedGenerateAIPrompt[f.groupName]) {
			groupedGenerateAIPrompt[f.groupName] = [];
		}
		groupedGenerateAIPrompt[f.groupName].push({
			value: f.id,
			label: f.name,
			disabled: f.type !== 'text',
		});
		// }
	});
	const imageChoiceArray =
		field.imageOrigin === 'category'
			? field.imageOriginChoice.length === 0
				? [{ categories: artworkExtraData.categories }]
				: [{ categories: artworkExtraData.categories }].concat(field.imageOriginChoice)
			: [];

	let autoImportSpreadsheet = null;
	if (field.autoImportMeta.basedOn) {
		let basedOnField = _.find(templateFields, (f) => f.id === field.autoImportMeta.basedOn);
		if (basedOnField) {
			autoImportSpreadsheet = _.find(
				artworkExtraData.spreadsheets,
				(s) => s.id === basedOnField.predefinedValue.from
			);
		}
	}
	return (
		<div className={classes.tabWrapper}>
			<div
				className={classes.groupsDiv}
				style={field.autoImport ? {} : { margin: 0, width: '100%', border: 'none' }}
			>
				<StyledOutlinedSelectionWithSubheader
					fullWidth
					select
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.autoImportText',
					})}
					value={field.autoImport || ''}
					onChange={(e) =>
						updateField({
							autoImport: e.target.value || '',
							autoImportMeta: field.defaultAutoImportMeta,
						})
					}
					noneOption
					options={ART_VARIABLES.autoImportOtherForImage}
				/>
				{field.autoImport && (
					<StyledOutlinedSelectionWithSubheader
						fullWidth
						select
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.basedOnText',
						})}
						value={field.autoImportMeta.basedOn || ''}
						onChange={(e) =>
							updateField({
								autoImportMeta: { ...field.defaultAutoImportMeta, basedOn: e.target.value || '' },
							})
						}
						noneMsg={
							Object.keys(groupedFieldsWithPredefinedSSValue).length === 0
								? intl.formatMessage(
										{
											id: 'pages.Artwork.components.ElementPalette.GENERAL.no_NAME_AvailableText',
										},
										{ name: 'Fields' }
								  )
								: null
						}
						noneOption
						options={groupedFieldsWithPredefinedSSValue}
					/>
				)}
				{field.autoImportMeta.basedOn && field.autoImport === 'cellimg:' && (
					<StyledOutlinedTextFieldSelection
						fullWidth
						select
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.imageColumnText',
						})}
						value={field.autoImportMeta.spreadsheetImageColumn || ''}
						onChange={(e) =>
							updateField({
								autoImportMeta: {
									...field.autoImportMeta,
									spreadsheetImageColumn: e.target.value || null,
								},
							})
						}
						noneMsg={
							autoImportSpreadsheet && Array.isArray(autoImportSpreadsheet.image_column_names)
								? null
								: intl.formatMessage(
										{
											id: 'pages.Artwork.components.ElementPalette.GENERAL.no_NAME_AvailableText',
										},
										{ name: 'Image Column' }
								  )
						}
						noneOption
						options={
							autoImportSpreadsheet && Array.isArray(autoImportSpreadsheet.image_column_names)
								? autoImportSpreadsheet.image_column_names.map((img) => ({
										value: img.id,
										label: img.column_name,
								  }))
								: []
						}
					/>
				)}
				{field.autoImportMeta.basedOn && field.autoImport === 'cell:' && (
					<React.Fragment>
						<StyledOutlinedTextFieldSelection
							fullWidth
							select
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.chooseCellText',
							})}
							value={field.autoImportMeta.spreadsheetCell || ''}
							onChange={(e) =>
								updateField({
									autoImportMeta: {
										...field.autoImportMeta,
										spreadsheetCell: e.target.value || null,
									},
								})
							}
							noneMsg={
								autoImportSpreadsheet && Array.isArray(autoImportSpreadsheet.column_names)
									? null
									: intl.formatMessage({
											id: 'pages.Artwork.components.ElementPalette.GENERAL.notAvailableText',
									  })
							}
							noneOption
							options={
								autoImportSpreadsheet && Array.isArray(autoImportSpreadsheet.column_names)
									? autoImportSpreadsheet.column_names.map((c) => ({
											value: c.position,
											label: c.column_name,
									  }))
									: []
							}
						/>
						<div className={classes.groupRowDiv}>
							{[
								{
									label: intl.formatMessage({
										id: 'pages.Artwork.components.ElementPalette.GENERAL.esignEditableText',
									}),
									fieldKey: 'esignEditable',
								},
								{
									label: intl.formatMessage({
										id: 'pages.Artwork.components.ElementPalette.GENERAL.esignSearchableText',
									}),
									fieldKey: 'esignSearchable',
								},
								{
									label: intl.formatMessage({
										id: 'pages.Artwork.components.ElementPalette.GENERAL.esignFilterText',
									}),
									fieldKey: 'esignFilter',
								},
							]
								.map((item) => (
									<SimpleSwitchVer
										key={item.fieldKey}
										label={item.label}
										style={{ padding: 0 }}
										checked={field.autoImportMeta[item.fieldKey] || false}
										onChange={(e, checked) =>
											updateField({
												autoImportMeta: { ...field.autoImportMeta, [item.fieldKey]: checked },
											})
										}
									/>
								))
								.reduce((accu, elem, idx) => {
									return accu.length === 0 ? [elem] : [...accu, <DividerVer key={idx} />, elem];
								}, [])}
						</div>
					</React.Fragment>
				)}
			</div>
			<DividerHor />
			<div
				className={classes.groupsDiv}
				style={field.imageOrigin ? {} : { margin: 0, width: '100%', border: 'none' }}
			>
				<StyledOutlinedTextFieldSelection
					fullWidth
					select
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.ImagePalette.imageOriginText',
					})}
					value={field.imageOrigin || ''}
					onChange={(e) =>
						updateField({
							imageOrigin: e.target.value || '',
							// reset the options
							imageOriginChoice: [],
							imageOriginIncludeUserFiles: false,
						})
					}
					noneOption
					options={ART_VARIABLES.imageOriginOptions}
				/>
				{field.imageOrigin === 'category' && (
					<div className={classes.groupsDiv}>
						<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.ImagePalette.imageChoiceText',
							})}
						</InputLabel>
						{imageChoiceArray.map((item, idx) => {
							if (idx !== 0 && (!Array.isArray(item.categories) || item.categories.length === 0))
								return null;
							return (
								<StyledOutlinedTextFieldSelection
									key={idx}
									fullWidth
									select
									label=""
									value={imageChoiceArray[idx + 1] ? imageChoiceArray[idx + 1].id : ''}
									onChange={(e) => {
										let newImageChoice = field.imageOriginChoice.slice(0, idx);
										if (e.target.value)
											newImageChoice[idx] = _.find(item.categories, (c) => c.id === e.target.value);
										updateField({
											imageOriginChoice: newImageChoice,
										});
									}}
									smallMenu
									smallIcon
									noneMsg={
										artworkExtraData.categories.length === 0
											? intl.formatMessage({
													id:
														'pages.Artwork.components.ElementPalette.GENERAL.noCategoryAvailableText',
											  })
											: null
									}
									noneOption
									options={(item.categories || []).map((c) => ({
										label: c.name,
										value: c.id,
									}))}
								/>
							);
						})}
					</div>
				)}
				{field.imageOrigin === 'admin_lightbox' && (
					<StyledOutlinedTextFieldSelection
						fullWidth
						select
						label="Image Choice"
						value={field.imageOriginChoice[0] ? field.imageOriginChoice[0].id : ''}
						onChange={(e) => {
							updateField({
								imageOriginChoice: e.target.value ? [{ id: e.target.value }] : [],
							});
						}}
						smallMenu
						smallIcon
						noneMsg={artworkExtraData.lightboxes.length === 0 ? 'No lightbox available' : null}
						noneOption
						options={(artworkExtraData.lightboxes || []).map((lightbox) => ({
							label: lightbox.name,
							value: lightbox.id,
						}))}
					/>
				)}
				{field.imageOrigin && (
					<React.Fragment>
						<DividerHor />
						<SimpleSwitchRow
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.includeUserFilesText',
							})}
							SwitchProps={{
								disabled: field.imageOrigin === 'user_files' || field.generateAi === true,
							}}
							style={{ padding: '8px 24px' }}
							checked={field.imageOriginIncludeUserFiles}
							onChange={(e, checked) => updateField({ imageOriginIncludeUserFiles: checked })}
						/>
					</React.Fragment>
				)}
				<React.Fragment>
					<DividerHor />
					<SimpleSwitchRow
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.ImagePalette.generateAISwitch',
						})}
						// SwitchProps={{ disabled: field.imageOrigin === 'user_files' }}
						style={{ padding: '8px 24px' }}
						checked={field.generateAi}
						onChange={(e, checked) => {
							updateField({
								generateAi: checked,
								imageOriginIncludeUserFiles: checked,
								generateAiFieldId: '',
							});
						}}
					/>
				</React.Fragment>
				{field.generateAi && (
					<StyledOutlinedSelectionWithSubheader
						fullWidth
						select
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.ImagePalette.generateAIPromptLabel',
						})}
						value={field.generateAiFieldId || ''}
						onChange={(e) => {
							updateField({ generateAiFieldId: e.target.value || '' });
						}}
						noneOption
						options={groupedGenerateAIPrompt}
					/>
				)}
			</div>
			<DividerHor />
			{
				// <StyledOutlinedTextField
				// 	label={intl.formatMessage({
				// 		id: 'pages.Artwork.components.ElementPalette.ImagePalette.defaultImageMediaText',
				// 	})}
				// 	value={field.defaultMediafileId || ''}
				// 	onChange={e => updateField({ defaultMediafileId: e.target.value || '' })}
				// />
			}
			<StyledOutlinedSelectionWithSubheader
				fullWidth
				select
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.ImagePalette.preSearchFieldLabelText',
				})}
				value={field.preSearchFieldId || ''}
				onChange={(e) => {
					updateField({ preSearchFieldId: e.target.value || '' });
				}}
				noneOption
				options={groupedTemplateFieldsForPreSearch}
			/>

			<DividerHor />
			{
				// Select default image in popup
				<div className={classes.groupsDiv}>
					<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
						Default Image
					</InputLabel>
					{field.defaultMediafilePreviewUrl && (
						<div className={classes.defaultValuePreview}>
							<img
								src={field.defaultMediafilePreviewUrl}
								alt="preview"
								style={{ maxHeight: '100%', maxWidth: '100%' }}
							/>
						</div>
					)}
					<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
						<StyledContainedButton
							label="Choose"
							startIcon={<ImageSearchIcon fontSize="small" />}
							size="small"
							onClick={() => openMediaFileChooser()}
						/>
						<StyledContainedButton
							label="Clear"
							startIcon={<ClearIcon fontSize="small" />}
							size="small"
							onClick={() =>
								updateField({
									defaultMediafileId: '',
									defaultMediafilePreviewUrl: '',
									defaultMediafileHighResUrl: '',
									defaultMediafileOptimisedUrl: '',
								})
							}
						/>
					</div>
				</div>
			}
		</div>
	);
};

ImagePaletteData.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	artworkExtraData: PropTypes.object.isRequired,
	templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
	/**
	 * Function to open mediafile chooser
	 *
	 * @param {null} no parameters
	 */
	openMediaFileChooser: PropTypes.func.isRequired,
};

ImagePaletteData.defaultProps = {};
