export const ARTWORK_SERVER_SIDE_PROCESS = 'ARTWORK_SERVER_SIDE_PROCESS';
export const COLORLIST_DATA_ATTR_NAME = 'data-colorlist';
export const FONTLIST_DATA_ATTR_NAME = 'data-fontlist';
export const DEFAULT_FONTSIZE = 30;

// pdfkit (PDF GENERATION) library
export const PDFKIT = window.PDFDocument;

/**
 * Common namepaces constants in alpha order.
 * @enum {string}
 * @memberof module:namespaces
 */
export const NS = {
	HTML: 'http://www.w3.org/1999/xhtml',
	MATH: 'http://www.w3.org/1998/Math/MathML',
	SE: 'http://svg-edit.googlecode.com',
	SVG: 'http://www.w3.org/2000/svg',
	XLINK: 'http://www.w3.org/1999/xlink',
	XML: 'http://www.w3.org/XML/1998/namespace',
	XMLNS: 'http://www.w3.org/2000/xmlns/', // see http://www.w3.org/TR/REC-xml-names/#xmlReserved
};

const pixelUnitMap = () => {
	// Get correct em/ex values by creating a temporary SVG.
	const svg = document.createElementNS(NS.SVG, 'svg');
	document.body.append(svg);
	const rect = document.createElementNS(NS.SVG, 'rect');
	rect.setAttribute('width', '1em');
	rect.setAttribute('height', '1ex');
	rect.setAttribute('x', '1in');
	svg.append(rect);

	const bb = rect.getBBox();
	svg.remove();

	const inch = bb.x;
	const unitMap = {
		em: bb.width,
		ex: bb.height,
		in: inch,
		cm: inch / 2.54,
		mm: inch / 25.4,
		pt: inch / 72,
		pc: inch / 6,
		px: 1,
		'%': 0,
	};
	return unitMap;
};
export const PIXEL_UNIT_MAP = pixelUnitMap();

export const DOMURL = window.URL || window.webkitURL;
export const pixelToPdfPt = 72 / 300; // usage: pdfPt = pixel * pixelToPdfPt. the magic number, convert pixel to point for pdf in 300dpi
export const pdfPtToPixel = 300 / 72; // usage: pixel = pdfPt * pdfPtToPixel. the magic number, convert pixel to point for pdf in 300dpi
export const dyForAlphabeticOnTopVerAlign = 0.9; // when verAlign = 'top' and dominant-baseline='alphabetic', we need to lower the text down by 'dyForAlphabeticOnTopVerAlign'em
