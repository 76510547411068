import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import config from 'config';
import AppLayout from './AppLayout';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
// language
import { AppLocaleMessages } from 'lang';
import { LangProvider } from 'components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { getPublicRoutes } from './Routes';

// redux
import { connect } from 'react-redux';

const hist = createBrowserHistory({ basename: config.urlBaseName });
const publicRoutes = getPublicRoutes();

function App({ appTheme, isLoggedIn }) {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={appTheme}>
				<LangProvider messages={AppLocaleMessages}>
					<LocalizationProvider>
						<SnackbarProvider>
							<CssBaseline />
							<Router history={hist}>
								<Switch>
									{publicRoutes.map((publicRoute, idx) => {
										return (
											<PublicRoute
												key={`publicRoute-${idx}`}
												exact
												path={publicRoute.path}
												component={publicRoute.component}
											/>
										);
									})}
									<PrivateRoute path="/" component={AppLayout} />
								</Switch>
							</Router>
						</SnackbarProvider>
					</LocalizationProvider>
				</LangProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);

	// #######################################################################

	// Authentication (Only check if the user is logged in)
	// eslint-disable-next-line react/prop-types
	function PrivateRoute({ component: Component, ...rest }) {
		return (
			<Route
				{...rest}
				render={(props) =>
					isLoggedIn() ? (
						<Component {...props} />
					) : !config.isReactOnly ? (
						window.location.replace('/')
					) : (
						// eslint-disable-next-line react/prop-types
						<Redirect
							to={{
								pathname: config.loginPath,
								state: { [config.visitLocationKey]: props.location },
							}}
						/>
					)
				}
			/>
		);
	}

	// eslint-disable-next-line react/prop-types
	function PublicRoute({ component: Component, ...rest }) {
		return (
			<Route
				{...rest}
				render={(props) =>
					isLoggedIn() ? <Redirect to={{ pathname: '/' }} /> : <Component {...props} />
				}
			/>
		);
	}
}

App.propTypes = {
	appTheme: PropTypes.object.isRequired,
	isLoggedIn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		appTheme: state.authentication.appTheme,
		isLoggedIn: state.authentication.isLoggedIn,
	};
};

export default connect(mapStateToProps, {})(App);
