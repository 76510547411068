import {
	FETCH_SCREENMANAGER_DEPARTMENTS,
	FETCH_SCREENMANAGER_DEPARTMENTS_SUCCESS,
	FETCH_SCREENMANAGER_DEPARTMENTS_FAIL,
	RESET_SCREENMANAGER_DEPARTMENTS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_DEPARTMENTS_REQUEST,
	EDIT_SCREENMANAGER_DEPARTMENTS_SUCCESS,
	EDIT_SCREENMANAGER_DEPARTMENTS_FAIL,
	RESET_SCREENMANAGER_DEPARTMENTS_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_DEPARTMENTS_REQUEST,
	ADD_SCREENMANAGER_DEPARTMENTS_SUCCESS,
	ADD_SCREENMANAGER_DEPARTMENTS_FAIL,
	RESET_SCREENMANAGER_DEPARTMENTS_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_DEPARTMENTS_REQUEST,
	DELETE_SCREENMANAGER_DEPARTMENTS_SUCCESS,
	DELETE_SCREENMANAGER_DEPARTMENTS_FAIL,
	RESET_SCREENMANAGER_DEPARTMENTS_DELETESTATUS,
} from 'redux/actions';
import config from 'config';

const screenApiBaseUrl = config.screenAPI.baseUrl;
const authHeaderKey = config.screenAPI.authHeaderKey;

export const fetchScreenManagerDepartments = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${screenApiBaseUrl}/departments`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		timeout: 300000,
	};
	return dispatch({
		types: [
			FETCH_SCREENMANAGER_DEPARTMENTS,
			FETCH_SCREENMANAGER_DEPARTMENTS_SUCCESS,
			FETCH_SCREENMANAGER_DEPARTMENTS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the whole screens data store (including editing status, adding status, etc.)
export const resetScreenManagerDepartments = () => ({
	type: RESET_SCREENMANAGER_DEPARTMENTS,
});

// Editing Screen (player)
// opts: {
// 	departmentId: xxxxx,
// 	reqData: {}
// }
export const editScreenManagerDepartment = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/departments/${opts.departmentId}`,
		method: 'patch',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			EDIT_SCREENMANAGER_DEPARTMENTS_REQUEST,
			EDIT_SCREENMANAGER_DEPARTMENTS_SUCCESS,
			EDIT_SCREENMANAGER_DEPARTMENTS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// Reset editing status
export const resetScreenManagerDepartmentEditStatus = () => ({
	type: RESET_SCREENMANAGER_DEPARTMENTS_EDITSTATUS,
});

// Adding Screen (player)
// opts: {
// 	reqData: {}
// }
export const addScreenManagerDepartment = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/departments`,
		method: 'post',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			ADD_SCREENMANAGER_DEPARTMENTS_REQUEST,
			ADD_SCREENMANAGER_DEPARTMENTS_SUCCESS,
			ADD_SCREENMANAGER_DEPARTMENTS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

export const resetScreenManagerDepartmentAddStatus = () => ({
	type: RESET_SCREENMANAGER_DEPARTMENTS_ADDSTATUS,
});

// Deleting Screen (player)
// opts: {
// departmentId: xxxx
// }
export const deleteScreenManagerDepartment = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/departments/${opts.departmentId}`,
		method: 'delete',
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			DELETE_SCREENMANAGER_DEPARTMENTS_REQUEST,
			DELETE_SCREENMANAGER_DEPARTMENTS_SUCCESS,
			DELETE_SCREENMANAGER_DEPARTMENTS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		departmentId: opts.departmentId,
		afterSuccess: cb,
	});
};

export const resetScreenManagerDepartmentDeleteStatus = () => ({
	type: RESET_SCREENMANAGER_DEPARTMENTS_DELETESTATUS,
});
