import {
	FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE,
	FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS,
	FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL,
	EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_REQUEST,
	EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS,
	EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL,
} from 'redux/actions';

const screenManagerUniverseInitState = {
	isFetching: false,
	screens: [],
	editingStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
};

export const screenManagerUniverse = (state = screenManagerUniverseInitState, action) => {
	switch (action.type) {
		case FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS:
			return {
				...state,
				isFetching: false,
				screens: action.result.data, // this is axios response data, result is the name in middleware, data is response.data in axios request(url) function
			};
		case FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL:
			if (action.error.response && action.error.response.status === 404) {
				return {
					...state,
					isFetching: false,
					screens: [],
				};
			} else {
				return {
					...state,
					isFetching: false,
				};
			}
		case EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_REQUEST:
			return {
				...state,
				editingStatus: 'PROCESSING',
			};
		case EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS: {
			let updatedScreens = { ...action.result.data };
			return {
				...state,
				screens: state.screens.map((screen) => {
					if (screen.id === updatedScreens.id) {
						screen = updatedScreens;
					}
					return screen;
				}),
				editingStatus: 'OK',
			};
		}
		case EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL:
			return {
				...state,
				editingStatus: 'FAILED',
			};
		default:
			return state;
	}
};
