import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Card, CardHeader, CardMedia, CardActions, IconButton } from '@mui/material';
// intl lang
// import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	// root: {
	// 	position: 'relative',
	// 	width: '100%',
	// 	height: '100%',
	// },
	containerBox: {
		display: 'flex',
		position: 'relative',
		// overflow: 'hidden',
		width: '100%',
		height: '100%',
		// height: 'calc(100% - 100px)',
		minHeight: `max(120px, calc(100% - 100px))`, //120,
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		alignContent: 'flex-start',
		// [theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
		// 	justifyContent: 'flex-start',
		// },
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			justifyContent: 'space-evenly',
		},
	},
	itemBox: {
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		flex: '0 1 auto',
		alignSelf: 'auto',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid rgba(0,0,0,0.1)`,
		borderRadius: 4,
		height: 'auto',
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			width: ({ numItemsPerRow }) => {
				// minus theme.spacing(2) is because we use theme.spacing(1) for margin left & right
				return `calc(${(1 / numItemsPerRow) * 100}% - ${theme.spacing(2)})`;
			},
		},
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: '90%',
		},
		'&:hover': {
			borderColor: `rgba(0,0,0,0.3)`,
			boxShadow: theme.customBoxShadow.boxShadow,
		},
		'& img': {
			maxHeight: '100%',
			maxWidth: '100%',
		},
	},
	itemBoxNotLive: {
		// backgroundColor: `rgb(247 116 116)`,
		border: '8px solid rgb(247 116 116)',
		'&:hover': {
			borderColor: `rgb(247 116 116)`,
			boxShadow: theme.customBoxShadow.boxShadow,
		},
	},
	cardRoot: {
		width: '100%',
		boxShadow: 'unset',
	},
	cardHeader: {
		padding: 'unset',
		paddingLeft: theme.spacing(0.5),
		// fontSize: '1rem',
	},
	cardHeaderContent: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	cardMedia: {
		// height: 100,
		// paddingTop: '56.25%', // 16:9
		backgroundSize: 'contain',
		backgroundColor: `rgba(0,0,0,0.1)`,
	},
	cardAction: {
		padding: 'unset',
		paddingTop: theme.spacing(1),
		flexWrap: 'wrap',
		gap: theme.spacing(0.4),
	},
	actionIconButton: {
		padding: 4,
		border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
	},
}));

function MediefilesGridView({
	mediafiles,
	onMediaClick,
	previewHeight,
	mediafileActions,
	numItemsPerRow,
	// searchHandler,
	// clearSearchHandler,
	// currentPageNo,
	// totalPageNum,
	// pageChangeHandler,
	// isLoading,
	noDataMsg,
	showRedBorder,
	// searchKeywords,
	...rest
}) {
	const classes = useStyles({ numItemsPerRow });
	// const intl = useIntl();
	return (
		<div className={classes.containerBox}>
			{mediafiles.length > 0 ? (
				mediafiles.map((mediafile, idx) => {
					return (
						<div
							key={mediafile.id + '-' + idx}
							className={cx(classes.itemBox, {
								[classes.itemBoxNotLive]: mediafile.status !== 'live' && showRedBorder,
							})}
						>
							<Card raised className={classes.cardRoot}>
								<CardHeader
									className={classes.cardHeader}
									disableTypography
									title={mediafile.title}
									classes={{ content: classes.cardHeaderContent }}
								/>
								<CardMedia
									className={classes.cardMedia}
									image={mediafile.previewUrl}
									component="div"
									// include title as tooltip
									title={mediafile.title}
									onClick={(e) => (onMediaClick ? onMediaClick(mediafile, e) : null)}
									style={{ height: previewHeight, cursor: onMediaClick ? 'pointer' : 'inherit' }}
								/>
								{Array.isArray(mediafileActions) && mediafileActions.length > 0 && (
									<CardActions className={classes.cardAction} disableSpacing={true}>
										{mediafileActions.map((action, idx) => {
											return action.shouldDisplay(mediafile) ? (
												action.CustomComp ? (
													<action.CustomComp key={mediafile.id + '' + idx} mediafile={mediafile} />
												) : (
													<IconButton
														key={mediafile.id + '' + idx}
														className={classes.actionIconButton}
														size="small"
														// color="primary"
														// title={action.tooltip}
														title={
															action.CustomTooltip
																? action.CustomTooltip(mediafile)
																: action.tooltip
														}
														onClick={(e) =>
															typeof action.clickHandler === 'function'
																? action.clickHandler(mediafile, e)
																: null
														}
													>
														{action.icon}
													</IconButton>
												)
											) : null;
										})}
									</CardActions>
								)}
							</Card>
						</div>
					);
				})
			) : (
				<Typography
					color="error"
					style={{ textAlign: 'center', width: '100%', paddingTop: 50, minHeight: 120 }}
				>
					{/* isLoading ? null :  */ noDataMsg}
				</Typography>
			)}
		</div>
	);
}

MediefilesGridView.propTypes = {
	/**
	 * Array of media file.
	 	[
			 {
				id: 'xxxxx',
				title: 'xxxx',
				previewUrl: 'https://xxx.xxx.com/xxxx',
			 }
		 ]
	 */
	mediafiles: PropTypes.array.isRequired,
	// /**
	//  * search keywords
	//  */
	// searchKeywords: PropTypes.string,
	/**
	 * handle media click event
	 * @param {object} mediafile
	 * @param {event} event. click event
	 */
	onMediaClick: PropTypes.func,
	// height of preview image (in pixel)
	previewHeight: PropTypes.number,
	/**
			* Extra click actions to each media file
			 [
						 {
							 shouldDisplay: (mediafile)=>true/false, // (Mandatory) hook function to verify should the action be displayed for the mediafile

							 // Custom component, it overrides the default IconButton;
							 // If using CustomComp, no need to provide the others except shouldDisplay
							 // mediafile is passed in the props
							 CustomComp: <CustomComp mediafile={mediafile} />,

							 icon: <AddIcon fontSize="small" />,
							 tooltip: 'add',
							 clickHandler: (mediafile, event) => {},
						 },
						 {
							 shouldDisplay: (mediafile)=>true/false, // hook function to verify should the action be displayed for the mediafile

							 // Custom component, it overrides the default IconButton;
							 // If using CustomComp, no need to provide the others except shouldDisplay
							 // mediafile is passed in the props
							 CustomComp: <CustomComp mediafile={mediafile} />,

							 icon: <SaveIcon fontSize="small" />,
							 tooltip: 'Save',
							 clickHandler: (mediafile, event) => {},
						 },
					 ],
			*/
	mediafileActions: PropTypes.arrayOf(PropTypes.object),
	// numer items in a row
	numItemsPerRow: PropTypes.number,
	// // is loading data
	// isLoading: PropTypes.bool.isRequired,
	// error message when error happens
	noDataMsg: PropTypes.string,
	showRedBorder: PropTypes.bool,
};

MediefilesGridView.defaultProps = {
	previewHeight: 100,
	numItemsPerRow: 5,
	noDataMsg: 'No Mediafiles!!',
	showRedBorder: false,
};

export default MediefilesGridView;
