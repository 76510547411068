/* eslint-disable react/no-string-refs */
import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// import { withStyles } from '@mui/material/styles';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
let ps;

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	// perfectScrollWrapper: {
	// 	position: 'relative',
	// 	overflow: 'auto',
	// 	zIndex: '4',
	// 	// overflowScrolling: 'touch',
	// 	transitionProperty: 'top, bottom, width',
	// 	transitionDuration: '.2s, .2s, .35s',
	// 	transitionTimingFunction: 'linear, linear, ease',
	// 	color: 'inherit',
	// },
	perfectScrollbar: {
		position: 'relative',
		// overflow: 'auto',
		// zIndex: '4',
		// overflowScrolling: 'touch',
		transitionProperty: 'top, bottom, width',
		transitionDuration: '.2s, .2s, .35s',
		transitionTimingFunction: 'linear, linear, ease',
		color: 'inherit',
		overflow: 'hidden !important',
		width: '100%',
		height: '100%',
	},
}));

/***************** WARNNING *******************/
// NOTE: PerfectScrollWrapper must be wrapped in a normal element (its parent element should be a normal element or non-MUI component, e.g. <div>)
// Because MUI uses ForwardRef to its direct children, hence the error below:
// (Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?)

function PerfectScrollWrapper({ className, component, children, scrollX, setScrollRef, ...rest }) {
	const classes = useStyles();
	const psRef = useRef();
	useEffect(() => {
		// if (navigator.platform.indexOf('Win') > -1) {
		ps = new PerfectScrollbar(psRef.current, {
			suppressScrollX: !scrollX,
			suppressScrollY: false,
			handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch'],
		});
		if (setScrollRef) {
			setScrollRef({ ps, elemRef: psRef });
		}
		return () => ps.destroy();
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={cx(classes.perfectScrollbar, className)} ref={psRef}>
			{component}
			{children}
		</div>
	);
}

// class PerfectScrollWrapper extends React.Component {
// 	componentDidMount() {
// 		if (navigator.platform.indexOf('Win') > -1) {
// 			ps = new PerfectScrollbar(this.perfectScrollRef, {
// 				suppressScrollX: !this.props.scrollX,
// 				suppressScrollY: false,
// 			});
// 		}
// 	}
// 	componentWillUnmount() {
// 		if (navigator.platform.indexOf('Win') > -1) {
// 			ps.destroy();
// 		}
// 	}
// 	render() {
// 		const { classes, className, component: Component, children, scrollRef } = this.props;
// 		return (
// 			<div className={cx(classes.perfectScrollbar, className)} ref={(el) => this.perfectScrollRef = scrollRef || el}>
// 				{Component}
// 				{children}
// 			</div>
// 		);
// 	}
// }

PerfectScrollWrapper.propTypes = {
	children: PropTypes.any,
	component: PropTypes.any,
	className: PropTypes.string,
	scrollX: PropTypes.bool,
	setScrollRef: PropTypes.func, // set scroll ref in caller component so that the caller component can operate scroll bar, e.g. scrollTop, etc
};

PerfectScrollWrapper.defaultProps = {
	scrollX: false,
};
// export default withStyles(PerfectScrollWrapperStyle, { withTheme: true })(PerfectScrollWrapper);
export default PerfectScrollWrapper;
