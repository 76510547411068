import merge from 'lodash/merge';
import common from './common';
import devConf from './development';
import prodConf from './production';
import domains from './domains';
import { ENVIRONMENTS_ENUM, RUNNING_MODES_ENUM } from './constants';

/**
 * recursively apply Object.freeze() to an object
 * @param {object} object
 * @returns {object}
 */
const deepFreeze = (object) => {
	// Retrieve the property names defined on object
	const propNames = Object.getOwnPropertyNames(object);

	// Freeze properties before freezing self
	for (const name of propNames) {
		const value = object[name];

		if (value && typeof value === 'object') {
			deepFreeze(value);
		}
	}

	return Object.freeze(object);
};

const environment = Object.values(ENVIRONMENTS_ENUM).includes(process.env.REACT_APP_ENV)
	? process.env.REACT_APP_ENV
	: ENVIRONMENTS_ENUM.development;

const runningMode = Object.values(RUNNING_MODES_ENUM).includes(process.env.REACT_APP_MODE)
	? process.env.REACT_APP_MODE
	: RUNNING_MODES_ENUM.REACT_ONLY;

let config;
if (environment === 'development') {
	config = merge({}, common, devConf);
} else {
	config = merge({}, common, prodConf);
}

config.env = environment;
config.REACT_APP_MODE = runningMode;
config.isReactOnly = runningMode === RUNNING_MODES_ENUM.REACT_ONLY;

for (const domainName in domains) {
	// merge domain specific & default configuration. Specific conf overides default conf
	domains[domainName] = { ...domains['default'], ...domains[domainName] };
}
config.domains = domains;

export { ENVIRONMENTS_ENUM, RUNNING_MODES_ENUM };
export default deepFreeze(config);
