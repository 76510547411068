/* eslint-disable react/jsx-pascal-case */
import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import SlidePreview from '../Slides/SlidePreview';

/** STYLING */
const playlistStyle = () => ({
	playlistPreviewContainer: {
		position: 'relative',
	},
	controlsOverlay: {
		position: 'absolute',
		left: 0,
		top: 0,
		display: 'flex',
		height: '100%',
		width: '100%',
		zIndex: 1000,
	},
	controlsOverlayInfo: {
		position: 'absolute',
		height: 25,
		width: '100%',
		bottom: 0,
		opacity: '50%',
		backgroundColor: 'white',
	},
	controlsOverlayLeft: { width: '25%', cursor: 'pointer', margin: 'auto' },
	controlsOverlayRight: { width: '25%', cursor: 'pointer', margin: 'auto', textAlign: 'right' },
	controlsOverlayMid: { width: '50%' },
	controlsOverlayArrow: { position: 'relative', marginTop: 33, color: 'white' },
	controlsOverlayArrowBgLeft: { position: 'absolute' },
	controlsOverlayArrowBgRight: { position: 'absolute', right: 0 },
});

/** COMPONENT. Playlist Preview component that takes in a playlist and plays it sequentially
 * rendering each slide per slide duration using SlidePreview component. Read PropTypes to know more */
const PlaylistPreview = (props) => {
	const { playlist, showControls, showMasterSlides, classes, customStyle } = props;
	const [head, setHead] = useState(0);
	let slides = playlist.slides;
	// if master slides present, tag them and merge them with other slides
	if (showMasterSlides && playlist.masterSlides.length > 0) {
		playlist.masterSlides.forEach((sl) => (sl.xFromMaster = true));
		slides = [...slides, ...playlist.masterSlides];
	}

	const playNext = () => {
		let nextIdx = head + 1 <= slides.length - 1 ? head + 1 : 0;
		clearTimeout(timerId);
		setHead(nextIdx);
	};

	const playPrev = () => {
		let prevIdx = head - 1 >= 0 ? head - 1 : slides.length - 1;
		clearTimeout(timerId);
		setHead(prevIdx);
	};

	// Set timer to end the current slide and play the next one
	const timerId =
		slides.length !== 0 && slides[head] && setTimeout(playNext, slides[head].duration * 1000);

	// Clears the timer on component unmount
	useEffect(() => () => clearTimeout(timerId));
	useEffect(() => setHead(0), [props.showMasterSlides]);

	// Renders a slide using SlidePreview component. Renders an overlay of controls and info for navigation
	return (
		<div className={classes.playlistPreviewContainer} style={customStyle}>
			{slides.length === 0 && <div> Empty playlist </div>}
			{slides.length !== 0 && slides[head] && <SlidePreview {...slides[head]} />}
			{slides.length !== 0 && slides[head] && showControls && (
				<div className={classes.controlsOverlay}>
					<div className={classes.controlsOverlayLeft} onClick={playPrev}>
						<svg height="100" width="100" className={classes.controlsOverlayArrowBgLeft}>
							<circle cy="50" r="50%" opacity="0.375" fill="black" />
						</svg>
						<ArrowBackIcon fontSize="large" className={classes.controlsOverlayArrow} />
					</div>
					<div className={classes.controlsOverlayMid} />
					<div className={classes.controlsOverlayRight} onClick={playNext}>
						<svg height="100" width="100" className={classes.controlsOverlayArrowBgRight}>
							<circle cx="100" cy="50" r="50%" opacity="0.375" fill="black" />
						</svg>
						<ArrowForwardIcon fontSize="large" className={classes.controlsOverlayArrow} />
					</div>
					<div className={classes.controlsOverlayInfo}>
						Slide #{head + 1} {slides[head].xFromMaster && `(🔒 master)`}: {slides[head].name}
					</div>
				</div>
			)}
		</div>
	);
};

PlaylistPreview.propTypes = {
	/* Playlist object to be played by the component */
	playlist: PropTypes.object.isRequired,
	// show/hide the navigation overlay (prev/next etc.)
	showControls: PropTypes.bool,
	// show/hide master slides in the playlist
	showMasterSlides: PropTypes.bool,
	// from material-ui
	classes: PropTypes.object,
	customStyle: PropTypes.object,
};

PlaylistPreview.defaultProps = {
	playlist: {
		slides: [],
	},
	showControls: true,
	showMasterSlides: true,
	customStyle: {},
};

export default withStyles(playlistStyle)(PlaylistPreview);
