// ##############################
// // // Constants
// #############################
import config from 'config';

const envSuffix = config.env === 'development' ? '-beta' : '';

const BWGDomains = ['spar', 'eurospar', 'mace', 'xl', 'bwg', 'bwgacademy', 'valuecentre'];
export const BWG_BRAND_DOMAIN_MAPPER = BWGDomains.reduce((acc, domain) => {
	return {
		...acc,
		[domain.toLowerCase()]: `https://${domain.toLowerCase()}${envSuffix}.visualid.com`,
	};
}, {});
