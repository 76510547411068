import {
	FETCH_SCREENMANAGER_SCREENBOARD,
	FETCH_SCREENMANAGER_SCREENBOARD_SUCCESS,
	FETCH_SCREENMANAGER_SCREENBOARD_FAIL,
} from 'redux/actions';

const screenManagerScreenboardInitState = {
	isFetching: false,
	screens: [],
};

export const screenManagerScreenboard = (state = screenManagerScreenboardInitState, action) => {
	switch (action.type) {
		case FETCH_SCREENMANAGER_SCREENBOARD:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_SCREENMANAGER_SCREENBOARD_SUCCESS:
			return {
				...state,
				isFetching: false,
				screens: action.result.data, // this is axios response data, result is the name in middleware, data is response.data in axios request(url) function
			};
		case FETCH_SCREENMANAGER_SCREENBOARD_FAIL:
			if (action.error.response && action.error.response.status === 404) {
				return {
					...state,
					isFetching: false,
					screens: [],
				};
			} else {
				return {
					...state,
					isFetching: false,
				};
			}
		default:
			return state;
	}
};
