import {
	FILEMANAGER_SEARCH_MEDIAFILE_COUNT,
	FILEMANAGER_SEARCH_MEDIAFILES_COUNT_SUCCESS,
	FILEMANAGER_SEARCH_MEDIAFILES_COUNT_FAIL,
} from 'redux/actions';
const initState = {
	user: {
		countOfTodaysFile: 0,
	},
};

function generalData(state = initState, action) {
	switch (action.type) {
		case FILEMANAGER_SEARCH_MEDIAFILE_COUNT:
			return {
				...state,
			};
		case FILEMANAGER_SEARCH_MEDIAFILES_COUNT_SUCCESS:
			return {
				...state,
				user: { ...state.user, countOfTodaysFile: action.result.data.totalFound },
			};
		case FILEMANAGER_SEARCH_MEDIAFILES_COUNT_FAIL:
			return {
				...state,
			};
		default:
			return state;
	}
}

export default generalData;
