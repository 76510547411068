import React, { useState } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// import Link from '@mui/material/Link';

// Intl Lang
import { useIntl } from 'react-intl';

// icons
import { Email as EmailIcon, LockOutlined as PasswordIcon } from '@mui/icons-material';

import { KeyVariant as KeyIcon } from 'mdi-material-ui';

// CSS
import { default as useStyles } from './LoginFormStyle.jsx';

// Need to add Forgot password code in future
// function LoginForm({ handleForgetPW, handleLogin, className, logo, ...rest }) {
function LoginForm({ handleLogin, className, logo, errorMessage, errorStatusCode, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();
	let [username, setUsername] = useState('');
	let [password, setPassword] = useState('');
	if (errorStatusCode) {
		switch (errorStatusCode) {
			case 401:
				errorMessage = intl.formatMessage({
					id: 'pages.Login.components.LoginForm.ErrorStatusCode401',
				});
				break;
			case 404:
				errorMessage = intl.formatMessage({
					id: 'pages.Login.components.LoginForm.ErrorStatusCode404',
				});
				break;
			case 409:
				errorMessage = intl.formatMessage({
					id: 'pages.Login.components.LoginForm.ErrorStatusCode409',
				});
				break;
			case 440:
				errorMessage = intl.formatMessage({
					id: 'pages.Login.components.LoginForm.ErrorStatusCode440',
				});
				break;
			case 451:
				errorMessage = intl.formatMessage({
					id: 'pages.Login.components.LoginForm.ErrorStatusCode451',
				});
				break;
			default:
				break;
		}
	}

	return (
		<Card raised className={cx(classes.card, className)}>
			<form className={classes.form}>
				<CardHeader
					className={classes.cardHeader}
					title={<img src={logo} alt="logo" className={classes.logoImg} />}
				/>
				<CardContent>
					<div className={classes.divider}>
						<div className={classes.dividerLeft}>
							<Typography variant="h3" style={{ color: '#555' }} gutterBottom>
								{intl.formatMessage({ id: 'pages.Login.components.LoginForm.LoginText' })}
							</Typography>
							<Typography variant="body1">
								{intl.formatMessage({ id: 'pages.Login.components.LoginForm.EnterUNPWText' })}
							</Typography>
						</div>
						<div className={classes.dividerRight}>
							<KeyIcon style={{ fontSize: '4rem', color: '#9A9897' }} />
						</div>
					</div>
					{errorMessage && (
						<div className={classes.errorBlock}>
							<Typography variant="body1" style={{ color: '#f44336' }}>
								{/* errorMessage is used show the response(error message) from the API */}
								{errorMessage}
								{/* {intl.formatMessage({ id: 'pages.Login.components.LoginForm.ErrorMessage' })} */}
							</Typography>
						</div>
					)}

					<TextField
						id="username-input"
						label={intl.formatMessage({ id: 'pages.Login.components.LoginForm.UsernameLabel' })}
						name="username"
						fullWidth
						autoFocus
						helperText=""
						className={classes.textField}
						type="text"
						autoComplete="username email"
						margin="normal"
						onChange={(e) => setUsername(e.target.value)}
						variant="standard"
						InputLabelProps={{
							classes: {
								focused: classes.textFieldFocus,
							},
						}}
						InputProps={{
							classes: {
								underline: classes.textFieldInputUnderline,
							},
							endAdornment: (
								<InputAdornment position="end">
									<EmailIcon />
								</InputAdornment>
							),
						}}
					/>
					<TextField
						id="password-input"
						label={intl.formatMessage({ id: 'pages.Login.components.LoginForm.PWLabel' })}
						name="password"
						fullWidth
						className={classes.textField}
						type="password"
						autoComplete="current-password"
						margin="normal"
						onChange={(e) => setPassword(e.target.value)}
						variant="standard"
						InputLabelProps={{
							classes: {
								focused: classes.textFieldFocus,
							},
						}}
						InputProps={{
							classes: {
								underline: classes.textFieldInputUnderline,
							},
							endAdornment: (
								<InputAdornment position="end">
									<PasswordIcon />
								</InputAdornment>
							),
						}}
					/>
				</CardContent>
				<CardActions className={classes.cardActions}>
					<Button
						className={classes.loginButton}
						variant="contained"
						fullWidth
						color="primary"
						onClick={() => handleLogin({ username: username, password: password })}
					>
						<Typography variant="h4" style={{ margin: 8, textTransform: 'none' }}>
							{intl.formatMessage({ id: 'pages.Login.components.LoginForm.LoginText' })}
						</Typography>
					</Button>
					{/* <Link underline="none" className={classes.forgetPWLink} onClick={handleForgetPW}>
						{intl.formatMessage({ id: 'pages.Login.components.LoginForm.ForgotPWText' })}
					</Link> */}
				</CardActions>
			</form>
		</Card>
	);
}

LoginForm.propTypes = {
	// handleForgetPW: PropTypes.func.isRequired,
	handleLogin: PropTypes.func.isRequired,
	className: PropTypes.string,
	logo: PropTypes.string,
	errorMessage: PropTypes.string.isRequired,
	errorStatusCode: PropTypes.number,
};

LoginForm.defaultProps = {};
export default LoginForm;
