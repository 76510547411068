import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import SearchV2 from '../SearchV2/SearchV2.jsx';
// import Loading from '../Loading/Loading.jsx';
// MUI components
// import { grey } from '@mui/material/colors';
import {
	IconButton,
	Card,
	CardHeader,
	CardMedia,
	CardActions,
	Divider,
	Backdrop,
	CircularProgress,
	Typography,
} from '@mui/material';
// import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		position: 'relative',
	},
	searchPaginationWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end', //'space-between',
		padding: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		width: '100%',
		// position: 'fixed',
		// height: heightSearchPageination,
		// zIndex: 1,
		// left: 4,
		// right: 20,
		// top: '50%',
		// left: '50%',

		// transform: 'translateX(-50%)',
		// transition: 'color 0.5s, background-color 0.5s',
	},
	searchWrapper: {
		textAlign: 'left',
		// margin: `0px ${theme.spacing(1)}`,
		flex: '1 1 auto',
	},
	paginationWrapper: {
		display: 'flex',
		margin: `0px ${theme.spacing(1)}`,
		alignItems: 'center',
		justifyContent: 'flex-end',
		// flex: '1 1 auto',
	},
	containerBox: {
		display: 'flex',
		position: 'relative',
		// overflow: 'hidden',
		width: 'auto',
		// height: 'calc(100% - 100px)',
		minHeight: `max(120px, calc(100% - 100px))`, //120,
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		alignContent: 'flex-start',
		// [theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
		// 	justifyContent: 'flex-start',
		// },
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			justifyContent: 'space-evenly',
		},
	},
	itemBox: {
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		flex: '0 1 auto',
		alignSelf: 'auto',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid rgba(0,0,0,0.1)`,
		borderRadius: 4,
		height: 'auto',
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			width: ({ numItemsInRow }) => {
				// minus theme.spacing(2) is because we use theme.spacing(1) for margin left & right
				return `calc(${(1 / numItemsInRow) * 100}% - ${theme.spacing(2)})`;
			},
		},
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: '90%',
		},
		'&:hover': {
			borderColor: `rgba(0,0,0,0.3)`,
			boxShadow: theme.customBoxShadow.boxShadow,
		},
		'& img': {
			maxHeight: '100%',
			maxWidth: '100%',
		},
	},
	cardRoot: {
		width: '100%',
		boxShadow: 'unset',
	},
	cardHeader: {
		padding: 'unset',
		paddingLeft: theme.spacing(0.5),
		// fontSize: '1rem',
	},
	cardHeaderContent: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	cardMedia: {
		// height: 100,
		// paddingTop: '56.25%', // 16:9
		backgroundSize: 'contain',
		backgroundColor: `rgba(0,0,0,0.1)`,
	},
	cardAction: {
		padding: 'unset',
		paddingTop: theme.spacing(1),
	},
	actionIconButton: {
		padding: 2,
		border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
	},
	backdropLoading: {
		zIndex: 999,
		position: 'absolute',
		alignItems: 'flex-start',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		color: theme.palette.primary.main,
		borderRadius: 4,
	},
	loadingCircularProgress: {
		position: 'sticky',
		top: 'calc(50% - 20px)',
	},
}));
// max & min only apply to md breaker
// const maxItemsInRow = 6;
// const minItemsInRow = 4; // This is the default if no suitable number found
function MediafilePicker({
	mediafiles,
	onMediaClick,
	previewHeight,
	extraMediaActions,
	numItemsPerRow,
	searchHandler,
	clearSearchHandler,
	currentPageNo,
	totalPageNum,
	pageChangeHandler,
	isLoading,
	noDataMsg,
	searchKeywords,
	...rest
}) {
	// const [numItemsInRow, setNumItemsInRow] = React.useState(numItemsPerRow || minItemsInRow);
	const classes = useStyles({ numItemsInRow: numItemsPerRow });
	// const [searchKeywords, setSearchKeywords] = React.useState('');

	return (
		<div className={classes.root}>
			{
				<Backdrop className={classes.backdropLoading} open={isLoading}>
					<CircularProgress color="inherit" className={classes.loadingCircularProgress} />
				</Backdrop>
			}
			<div className={classes.searchPaginationWrapper}>
				{searchHandler && (
					<SearchV2
						className={classes.searchWrapper}
						placeholder="Search..."
						value={searchKeywords}
						triggerSearchOnBlur={false}
						OnEnterKeyPressed={(e) => {
							if (e.target.value) {
								// setSearchKeywords(e.target.value);
								searchHandler(e.target.value);
							}
						}}
						onSearchClick={(keywords) => {
							if (keywords) {
								// setSearchKeywords(keywords);
								searchHandler(keywords);
							}
						}}
						onClearClick={
							clearSearchHandler
								? () => {
										// setSearchKeywords('');
										clearSearchHandler();
								  }
								: null
						}
					/>
				)}
				<div className={classes.paginationWrapper}>
					<Pagination
						count={totalPageNum || 1}
						page={currentPageNo || 1}
						variant="outlined"
						color="primary"
						size="small"
						onChange={(e, page) => pageChangeHandler(page)}
					/>
				</div>
			</div>

			<Divider style={{ width: '100%' }} />
			{/** TODO: Show serch keywords only when there is no error. This component doesn't have isError info. So maybe best way is not showing it. The keywords are still in search input field anyway */}
			{
				// 	!isLoading && searchKeywords && mediafiles.length > 0 && (
				// 	<Typography
				// 		// display="inline"
				// 		color="textPrimary"
				// 		style={{ lineHeight: '2rem' }}
				// 		align="left"
				// 		gutterBottom
				// 		noWrap
				// 	>{`Result of "${searchKeywords}"`}</Typography>
				// )
			}
			<div className={classes.containerBox}>
				{mediafiles.length > 0 ? (
					mediafiles.map((mediafile, idx) => {
						return (
							<div key={mediafile.id + '-' + idx} className={classes.itemBox}>
								<Card raised className={classes.cardRoot}>
									<CardHeader
										className={classes.cardHeader}
										disableTypography
										title={mediafile.title}
										classes={{ content: classes.cardHeaderContent }}
									/>
									<CardMedia
										className={classes.cardMedia}
										image={mediafile.previewUrl}
										component="div"
										// include title as tooltip
										title={mediafile.title}
										onClick={() => (onMediaClick ? onMediaClick(mediafile.id) : null)}
										style={{ height: previewHeight, cursor: onMediaClick ? 'pointer' : 'inherit' }}
									/>
									{Array.isArray(extraMediaActions) && extraMediaActions.length > 0 && (
										<CardActions className={classes.cardAction}>
											{extraMediaActions.map((action, idx) => {
												return action.icon ? (
													<IconButton
														key={mediafile.id + '' + idx}
														className={classes.actionIconButton}
														size="small"
														// color="primary"
														title={action.tooltip}
														onClick={() =>
															typeof action.clickHandler === 'function'
																? action.clickHandler(mediafile.id)
																: null
														}
													>
														{action.icon}
													</IconButton>
												) : null;
											})}
										</CardActions>
									)}
								</Card>
							</div>
						);
					})
				) : (
					<Typography
						color="error"
						style={{ textAlign: 'center', width: '100%', paddingTop: 50, minHeight: 120 }}
					>
						{isLoading ? null : noDataMsg}
					</Typography>
				)}
			</div>
			<Divider style={{ width: '100%' }} />
			<div className={classes.paginationWrapper} style={{ padding: '8px 0px' }}>
				<Pagination
					count={totalPageNum || 1}
					page={currentPageNo || 1}
					variant="outlined"
					color="primary"
					size="small"
					onChange={(e, page) => pageChangeHandler(page)}
				/>
			</div>
		</div>
	);
}

MediafilePicker.propTypes = {
	/**
	 * Array of media file.
	 	[
			 {
				id: 'xxxxx',
				title: 'xxxx',
				previewUrl: 'https://xxx.xxx.com/xxxx',
			 }
		 ]
	 */
	mediafiles: PropTypes.array.isRequired,
	/**
	 * search keywords
	 */
	searchKeywords: PropTypes.string,
	// handle media click event
	onMediaClick: PropTypes.func,
	// height of preview image (in pixel)
	previewHeight: PropTypes.number,
	/**
	 * Extra click actions to each media file
	  [
					{
						icon: <AddIcon fontSize="small" />,
						tooltip: 'add',
						clickHandler: (mediafileId) => {},
					},
					{
						icon: <SaveIcon fontSize="small" />,
						tooltip: 'Save',
						clickHandler: (mediafileId) => {},
					},
				],
	 */
	extraMediaActions: PropTypes.arrayOf(PropTypes.object),
	// numer items in a row
	numItemsPerRow: PropTypes.number,

	/**
	 * Handler function of search
	 * @param {string}. search keywords
	 */
	searchHandler: PropTypes.func,

	/**
	 * Handler function of clearing (reset) search
	 * @param {null}. no parameter
	 */
	clearSearchHandler: PropTypes.func,

	// current page number. 1-index. First page number is 1
	currentPageNo: PropTypes.number.isRequired,
	// total number of pages
	totalPageNum: PropTypes.number.isRequired,
	/**
	 * function to handle page change
	 * @param {number} the selected page number. 1-indexed.
	 */
	pageChangeHandler: PropTypes.func.isRequired,
	// is loading data
	isLoading: PropTypes.bool.isRequired,
	// // was there error happend
	// isError: PropTypes.bool.isRequired,
	// error message when error happens
	noDataMsg: PropTypes.string,
};

MediafilePicker.defaultProps = {
	previewHeight: 100,
	numItemsPerRow: 5,
	noDataMsg: 'No Data!!',
	searchKeywords: '',
};
export default MediafilePicker;
