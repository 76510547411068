import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import MediafileFilters from '../MediafileFilters/MediafileFilters';
import MediafileTableView from '../MediafileTableView/MediafileTableView';
import { format as dateFormat } from 'date-fns';

import { VIDEO_FILE_TYPES } from '../../Constants';
import {
	MediafilesCarousel,
	MediefilesGridView,
	MUITablePagination,
	AddMediaToPlaylists,
} from 'components';

import { getLogger, getS3FileParamsFromCloudFrontUrl, getDomainConfig } from 'utils/appHelper';
import { genRandomStr } from 'utils/generalHelper';
import { triggerDownload, moment, _ } from 'utils/libHelper';
import config from 'config';
// MUI components
import { Backdrop, CircularProgress, Typography, Modal, Link, Chip, Badge } from '@mui/material';

import {
	Create as ArtworkCustomizeIcon,
	// AddToQueue as AddToScreenPlaylistIcon,
	Share as ShareIcon,
	AddShoppingCart as OrderIcon,
	ZoomIn as ZoomInIcon,
} from '@mui/icons-material';

import { Download as DownloadIcon } from 'mdi-material-ui';

// intl lang
import { useIntl } from 'react-intl';

// custom hooks
import useColumnsConfig from '../../Hooks/useColumnsConfig';

import {
	getRouteMediafileDetail,
	getRouteArtworkCreator,
	getRouteArtworkCreatorTemplate,
} from 'routes';

// restful
import {
	searchMediaFiles,
	getDownloadableUrl,
	getPreviewFileUrl,
	patchMediafile,
	artworkFetchTemplateById,
	downloadFileLogging,
} from 'restful';

// redux
import { connect } from 'react-redux';
import { notifyGeneral, reSyncCounter } from 'redux/actions'; // actions

// constants
import {
	GRID_VIEW,
	TABLE_VIEW,
	MIN_EDIT_UL,
	FILTER_GROUP_MIN_UL,
	CATEGORY_QUERY_PARAM,
	MEDIAFILEID_QUERY_PARAM,
} from '../../Constants';
import { ROUTES_PATH_MYFILES_MEDIAFILE_LIST } from 'routes';
import { convertRemoteFieldsToLocal } from 'utils/artwork/artTemplateConverter';
import {
	createArtworkAtServer,
	createVideoArtwork,
	fetchArtworkExtra,
} from 'utils/artwork/artworkGenerator';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		// paddingBottom: theme.spacing(1),
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateRows: 'minmax(30px, max-content) auto minmax(30px, max-content)',
		gridGap: theme.spacing(1, 1),
		gridTemplateAreas: `
		"filter"
		"content"
		"pagination"
		`,
	},
	filter: {
		gridArea: 'filter',
		// marginBottom: theme.spacing(1),
	},
	content: {
		gridArea: 'content',
		// position: 'relative',
		overflow: 'auto',
		// padding: theme.spacing(1, 0),
		// border: `1px solid rgba(0,0,0,0.2)`,
		// borderRight: 'none',
		// borderLeft: 'none',
		// borderRadius: 4,
		// '&::after': {
		// 	content: `""`,
		// 	height: 1,
		// 	backgroundColor: `rgba(0,0,0,0.2)`,
		// 	border: 'none',
		// 	margin: 0,
		// },
	},
	pagination: {
		gridArea: 'pagination',
		// marginTop0: theme.spacing(1),
	},
	backdropLoading: {
		zIndex: 999,
		position: 'absolute',
		// alignItems: 'flex-start',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		// color: theme.palette.primary.main,
		borderRadius: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	// loadingCircularProgress: {
	// 	position: 'sticky',
	// 	top: 'calc(50% - 20px)',
	// },
	autoCreateChipRoot: {
		'& .MuiChip-icon': {
			order: 1, // the label has a default order of 0, using 1 so this icon goes after the label
			marginRight: '10px', // add some space for icon
			cursor: 'pointer',
		},
	},
	autoCreateChipCreated: {
		marginLeft: 'auto',
		color: '#ffffff',
		backgroundColor: theme.palette.success.main, // '#4caf50',
		'&:hover, &:focus': {
			backgroundColor: theme.palette.success.dark, // '#388e3c',
		},
		paddingRight: theme.spacing(1),
	},
	autoCreateChipNotCreated: {
		marginLeft: 'auto',
		color: '#ffffff',
		backgroundColor: theme.palette.primary.main, // '#536DFE',
		'&:hover, &:focus': {
			backgroundColor: theme.palette.primary.dark, //'#2f4ffe',
		},
	},
	cardAction: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 2),
		gap: theme.spacing(1),
	},
	iconButton: {
		backgroundColor: theme.palette.primary.main,
		padding: 4,
		color: '#ffffff',
		'&:hover, &:focus': {
			backgroundColor: theme.palette.primary.dark, //'#2f4ffe',
		},
		cursor: 'pointer',
	},
	iconButtonCancel: {
		padding: 4,
		border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
		cursor: 'pointer',
	},
}));

// const defaultDate = new Date();
// defaultDate.setMonth(defaultDate.getMonth() - 12);
// const defaultFilter = { updatedDatetimeFrom: defaultDate };
const defaultFilter = { mostRecentNumber: 10000 };

function MediafileList({
	history,
	notifyGeneral,
	userLevel,
	userData,
	userId,
	domainName,
	domainSettings,
	location,
	reSyncCounter,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const fileDownloadingLogger = React.useMemo(() => getLogger('fileDownloading'), []);
	const fileSearchingLogger = React.useMemo(() => getLogger('fileSearching'), []);
	/**
	 * filters contains all filters selected by user
	 * format is compatable with the "body" param in API request:
	 	{
			[searchableKey]: [], // if type is checkbox
			[searchableKey]: true/false, // if type is switch
			[searchableKey]: { // if type is datetime (Only "updatedDatetime" is datetime). NB: the searchableKey is missing "From" and "To" which are corresponding to startDate & endData in the value object
				startDate: Date(),
				endDate: Date(),
			}
		}
	 */
	const [filters, setFilters] = React.useState({});
	const [searchKeywords, setSearchKeywords] = React.useState('');
	const [viewMode, setViewMode] = React.useState(GRID_VIEW);
	const [sortBy, setSortBy] = React.useState([]);
	// rTableInst is the instance of rtable
	const [rTableInst, setRTableInst] = React.useState(null);
	// mediafiles data
	const [mediafiles, setMediafiles] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const [mediafilePreview, setMediafilePreview] = React.useState(null);
	// const [selectedRowIds, setSelectedRowIds] = React.useState([]);
	// artwork extra state
	const [artworkExtra, setArtworkExtra] = React.useState(null);
	// pagination states
	const [pageIndex, setPageIndex] = React.useState(0);
	const [itemsPerPage, setItemsPerPage] = React.useState(25);
	const [totalNumItems, setTotalNumItems] = React.useState(-1);
	const [isURLParams, setIsURLParams] = React.useState(false);
	const [newAutoCreateTemplate, setNewAutoCreateTemplate] = React.useState([]);
	// handle setting search keywords
	const handleSearchKeywords = (keywords) => {
		setSearchKeywords(keywords);
		setPageIndex(0);
	};
	// handle page changes (prev or next page)
	const handleChangePage = (event, newPage) => {
		setPageIndex(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPageIndex(Math.ceil((pageIndex * itemsPerPage) / Number(event.target.value)));
		setItemsPerPage(Number(event.target.value));
	};

	const handleCloseMediafilePreview = () => {
		setMediafilePreview(null);
	};

	const handleUpdateFilters = (filters) => {
		setPageIndex(0);
		setFilters(filters);
	};

	const handleGotoDetail = React.useCallback((mediafile) => {
		window.location.assign(getRouteMediafileDetail(mediafile.id));
	}, []);

	const handleShowLargePreview = React.useCallback((mediafile, e) => {
		setMediafilePreview({ mediafile, anchorEl: e.target });
	}, []);
	// const handleRowsSelection = (selectedRowIds) => {
	// 	return setSelectedRowIds(selectedRowIds);
	// };

	// handle sortBy updates with delay
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSortByWithDelay = React.useCallback(
		_.debounce((newSortBy) => {
			// console.log(new Date(), `setSortBy in debounece is called`, newSortBy);
			setSortBy((prevSortBy) =>
				prevSortBy[0]?.id !== newSortBy[0]?.id || prevSortBy[0]?.desc !== newSortBy[0]?.desc
					? newSortBy
					: prevSortBy
			);
		}, 600),
		[]
	);
	// update sortBy immediately
	const handleSortByImmediately = (newSortBy) => {
		if (sortBy[0]?.id !== newSortBy[0]?.id || sortBy[0]?.desc !== newSortBy[0]?.desc) {
			setSortBy(newSortBy);
			if (viewMode === TABLE_VIEW && rTableInst) rTableInst.setSortBy(newSortBy); // reset internal sortBy state in rTable
		}
	};
	// React.useEffect(() => {
	// 	console.log(new Date(), `sortBy changed`, sortBy);
	// }, [sortBy]);
	const handlePatchMediafile = React.useCallback(
		(mediafileId, patchMediafileData) => {
			return patchMediafile({ mediafileId, bodyParams: patchMediafileData })
				.then(() => {
					notifyGeneral(`Mediafile has been updated successfully`, 'success');
					// now convert the patched data to proper mediafile data
					let updatedMediafileData = Object.keys(patchMediafileData).reduce((accu, key) => {
						let mfData = {};
						switch (key) {
							case 'title':
								mfData.title = patchMediafileData[key];
								break;
							case 'status':
								mfData.status = patchMediafileData[key];
								break;
							case 'allowScreen':
								mfData.allowScreen = patchMediafileData[key];
								break;
							case 'allowSocialMedia':
								mfData.allowSocialMedia = patchMediafileData[key];
								break;
							default:
								break;
						}
						return Object.keys(mfData).length === 0 ? accu : { ...accu, ...mfData };
					}, {});
					setMediafiles((prevMediafiles) =>
						prevMediafiles.map((mediafile) => {
							if (mediafile.id === mediafileId) {
								return { ...mediafile, ...updatedMediafileData };
							} else {
								return mediafile;
							}
						})
					);
				})
				.catch((err) => {
					notifyGeneral(
						`Failed to update mediafile. ${err.response ? err.response.data.message : err.message}`,
						'error'
					);
				});
		},
		[notifyGeneral]
	);

	const downloadPdfAsImgInDomainSettings = React.useMemo(() => {
		const keyValue = domainSettings.settings.find(({ key }) => key === 'downloadPdfAsImg');
		return keyValue?.value ?? false; // if domainSettings was not fetched we set this flag to FALSE
		// so that PDF Files marked for Screen or Social Media will download in the default format.
	}, [domainSettings]);

	const handleDownloadMediafile = React.useCallback(
		async (mediafile) => {
			let mediafileDownloadUrl = mediafile.originalUrl;
			let mediafileS3Params = getS3FileParamsFromCloudFrontUrl(mediafileDownloadUrl);
			let downloadPdfAsImg = downloadPdfAsImgInDomainSettings;
			let isLores = false;
			if (
				(mediafile.allowScreen || mediafile.allowSocialMedia) &&
				mediafile.fileType.toLowerCase() !== 'video' &&
				downloadPdfAsImg
			) {
				isLores = true;
				mediafileDownloadUrl = await getPreviewFileUrl({
					originalMediafileUrl: `s3://${mediafileS3Params.Bucket}/${mediafileS3Params.Key}`,
					size: 'lores',
				});
				mediafileS3Params = getS3FileParamsFromCloudFrontUrl(mediafileDownloadUrl.data.previewUrl);
			}
			if (!mediafileS3Params) {
				notifyGeneral(
					`Can't download the mediafile. Please report to Admin with mediafile id: ${mediafile.id}`,
					'error'
				);
			} else {
				getDownloadableUrl({
					queryParams: {
						bucket: mediafileS3Params.Bucket, // Required. the s3 bucket name where the file is.
						filePath: mediafileS3Params.Key, // Required. the file path in s3 bucket
						contentDisposition: `attachment;filename="${
							mediafile.title
						}.${mediafileS3Params.Key.split('.').pop()}"`, // optional. used in Content-Disposition in the reponse of the downloading url. Default is null
					},
				})
					.then((res) => {
						triggerDownload(res.data.downloadUrl);
						if (!config.isReactOnly) {
							let downloadLogObj = {
								logType: 'mediafiles',
								mediafileIds: [mediafile.id],
								action: 'download',
							};
							downloadFileLogging({ bodyParams: downloadLogObj });
						}
						fileDownloadingLogger.save({
							userId: userId,
							userCompany: userData.company,
							mediafileId: mediafile.id,
							mediafileTitle: mediafile.title,
							mediafileType: mediafile.fileType,
							mediafileWidth: mediafile.fileWidth,
							mediafileHeight: mediafile.fileHeight,
							mediafileSize: mediafile.fileSize,
							mediafileDuration: mediafile.duration,
							isOwnfile: mediafile.isOwnfile,
							status: mediafile.status,
							isArtworkTemplate: mediafile.isArtworkTemplate,
							isTemplateForAutoCreate: mediafile.isTemplateForAutoCreate,
							isPreprintedPaperTemplate: mediafile.isPreprintedPaperTemplate,
							isEsign: mediafile.isEsign,
							isOrderable: mediafile.isOrderable,
							mediafileCategoryName: mediafile.categoryName,
							mediafileCategoryId: mediafile.categoryId,
							createdFromTemplateId: mediafile.templateId,
							createdFromTemplateTitle: mediafile.templateTitle,
							createdFromTemplateCategoryId: mediafile.templateCategoryId,
							createdFromTemplateCategoryName: mediafile.templateCategoryName,
							downloadFile: isLores ? 'lores' : 'original', // possible values: original | lores
						});
					})
					.catch((err) => {
						notifyGeneral(
							`Failed to download the mediafile. ${
								err.response ? err.response.data.message : err.message
							}`,
							'error'
						);
					});
			}
		},
		[
			fileDownloadingLogger,
			notifyGeneral,
			userData.company,
			userId,
			downloadPdfAsImgInDomainSettings,
		]
	);
	const handleGotoCustomArtwork = React.useCallback((mediafile) => {
		if (mediafile.templateId) {
			// console.log(mediafile, getRouteArtworkCreatorTemplate(mediafile.id, mediafile.templateId));
			window.location.assign(getRouteArtworkCreatorTemplate(mediafile.id, mediafile.templateId));
		} else if (mediafile.isEsign) {
			window.location.assign(getRouteMediafileDetail(mediafile.id));
		} else {
			window.location.assign(getRouteArtworkCreator(mediafile.id));
		}
	}, []);
	const handleCreateArtwork = React.useCallback(
		async (mediafile, hasCreated) => {
			if (hasCreated) {
				setFilters({});
				if (
					newAutoCreateTemplate.length > 0 &&
					newAutoCreateTemplate.some((x) => x.id === (mediafile || {}).id)
				) {
					let newTemplateArr = newAutoCreateTemplate.filter((y) => y.id === (mediafile || {}).id);
					history.push({
						pathname: ROUTES_PATH_MYFILES_MEDIAFILE_LIST,
						search: `?mediafileIds=${window.btoa(
							JSON.stringify([newTemplateArr[0].savedMediafileIds.toString()])
						)}`,
					});
				} else if (mediafile.userCreations.length > 0) {
					history.push({
						pathname: ROUTES_PATH_MYFILES_MEDIAFILE_LIST,
						search: `?mediafileIds=${window.btoa(JSON.stringify(mediafile.userCreations))}`,
					});
				}
			} else {
				let isTemplateSaved = false;
				try {
					setIsLoading(true);
					const { id: templateId } = mediafile;

					let artworkExtraData = artworkExtra;
					if (!artworkExtraData) {
						artworkExtraData = await fetchArtworkExtra();
						setArtworkExtra(artworkExtraData);
					}

					const tempalteRes = await artworkFetchTemplateById({ templateId });
					const artworkTemplate = await convertRemoteFieldsToLocal(
						tempalteRes.data,
						artworkExtraData
					);

					let savedMediafileId;
					if (artworkTemplate.videoArtwork) {
						savedMediafileId = await createVideoArtwork({
							fileName: artworkTemplate.name + ` ${dateFormat(new Date(), 'yyyy-MM-dd')}`,
							fieldInputData: {},
							artworkTemplate,
							templateFields: artworkTemplate.fields,
							artworkAvailableFonts: artworkExtraData.fonts,
							domainName,
							allowFilenameOverridedByFilenameField: true,
							intl,
							userId,
						});
					} else {
						let s3Bucket = (getDomainConfig(domainName) || {})['s3Bucket'];
						if (!s3Bucket) {
							notifyGeneral(`Can't find the domain name`, 'error');
							return null;
						}
						let s3BasePath = `${domainName}/${moment.utc().format('YYYYMMDD')}/${genRandomStr(
							28
						)}/`;
						const { createdMediafieldId } = await createArtworkAtServer({
							fileName: artworkTemplate.name + ` ${dateFormat(new Date(), 'yyyy-MM-dd')}`,
							s3Bucket,
							s3BasePath,
							userId,
							fieldInputData: {},
							artworkTemplate,
							templateFields: artworkTemplate.fields,
							templateBGInputData: {},
							templateBGOutputDataWithSettings: artworkTemplate.templateBG,
							artworkAvailableFonts: artworkExtraData.fonts,
							allowFilenameOverridedByFilenameField: true,
							intl,
						});

						savedMediafileId = createdMediafieldId;
					}

					const msg = (
						<div>
							<Typography variant="body1" component="div">
								{intl.formatMessage({
									id: 'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessful',
								})}
							</Typography>
							<Typography variant="body2" component="div">
								{intl.formatMessage({
									id:
										'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessfulAside',
								})}

								<Link
									style={{ cursor: 'pointer' }}
									underline="always"
									color="inherit"
									variant="inherit"
									onClick={() => {
										window.location.assign(getRouteMediafileDetail(savedMediafileId));
									}}
								>
									{intl.formatMessage({
										id: 'pages.Artwork.components.Create.clickHereBtnText',
									})}
								</Link>
								{intl.formatMessage({
									id:
										'pages.Artwork.components.Create.savingProgressMsgSavingVideoArtSuccessfulLinkText2',
								})}
							</Typography>
						</div>
					);
					isTemplateSaved = true;
					reSyncCounter();
					setNewAutoCreateTemplate((prevTemplate) => [
						...prevTemplate,
						{ id: mediafile.id, savedMediafileIds: savedMediafileId },
					]);
					if (isTemplateSaved) {
						setTimeout(() => {
							notifyGeneral(msg, 'success', { autoHideDuration: null });
						}, 3000);
					}
				} catch (err) {
					isTemplateSaved = false;
					notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
				} finally {
					if (isTemplateSaved) {
						setTimeout(() => {
							setIsLoading(false);
							isTemplateSaved = false;
						}, 3000);
					} else {
						setIsLoading(false);
					}
				}
			}
		},
		[
			artworkExtra,
			domainName,
			history,
			intl,
			newAutoCreateTemplate,
			notifyGeneral,
			reSyncCounter,
			userId,
		]
	);
	const dummyHandler = () => null; // can be deleted once no longer needed

	// Get columns configure by use hook.
	// NB: parameter passed to useColumnsConfig must be memorized
	// it is used for sorting selection in filter component, and used as column configure in table view component
	const columnsConfigure = useColumnsConfig({
		handleShowLargePreview,
		handlePatchMediafile,
		handleGotoDetail,
		allowEdit: userLevel >= MIN_EDIT_UL,
		showRedBorder: userLevel >= MIN_EDIT_UL,
	});

	const mediafileActions = React.useMemo(() => {
		return [
			{
				shouldDisplay: () => viewMode === GRID_VIEW, // hook func to verify that should the action be displayed for the given mediafile
				icon: <ZoomInIcon fontSize="small" />,
				tooltip: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileList.ZoomInTooltip',
				}),
				clickHandler: handleShowLargePreview,
			},
			{
				shouldDisplay: (mediafile) =>
					Boolean((mediafile || {}).originalUrl && !(mediafile || {}).isArtworkTemplate), // hook func to verify that should the action be displayed for the given mediafile
				icon: <DownloadIcon fontSize="small" />,
				tooltip: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileList.DownloadFileTooltip',
				}),
				clickHandler: handleDownloadMediafile,
			},
			{
				shouldDisplay: (mediafile) =>
					(Boolean((mediafile || {}).isArtworkTemplate) &&
						!(mediafile || {}).isTemplateForAutoCreate) ||
					mediafile.templateId, // hook func to verify that should the action be displayed for the given mediafile
				icon: <ArtworkCustomizeIcon fontSize="small" />,
				CustomTooltip: (mediafile) =>
					mediafile.templateId
						? intl.formatMessage({
								id: 'pages.MediaFiles.components.MediafileList.EditTemplateTooltip',
						  })
						: intl.formatMessage({
								id: 'pages.MediaFiles.components.MediafileList.CustomizeArtworkTooltip',
						  }),
				clickHandler: handleGotoCustomArtwork,
			},
			{
				shouldDisplay: (mediafile) => (mediafile || {}).isTemplateForAutoCreate, // hook func to verify that should the action be displayed for the given mediafile
				CustomComp: function autocreateComp({ mediafile, ...restProps }) {
					const count = newAutoCreateTemplate.some((x) => x.id === mediafile?.id)
						? 1
						: mediafile?.userCreations?.length || 0;

					const hasCreated =
						(mediafile?.isTemplateForAutoCreate &&
							Array.isArray(mediafile?.userCreations) &&
							mediafile.userCreations.length > 0) ||
						newAutoCreateTemplate.some((x) => x.id === mediafile?.id);

					return (
						<Chip
							{...restProps}
							className={cx({
								[classes.autoCreateChipCreated]: hasCreated,
								[classes.autoCreateChipNotCreated]: !hasCreated,
							})}
							size="medium"
							component="span"
							title={intl.formatMessage(
								{
									id: 'pages.MediaFiles.components.MediafileList.AutoCreateChipTooltip',
								},
								{ count }
							)}
							label={
								hasCreated
									? intl.formatMessage({
											id: 'pages.MediaFiles.components.MediafileList.AutoCreateChipLabelCreated',
									  })
									: mediafile.fileType.toLowerCase() !== 'video'
									? intl.formatMessage({
											id:
												'pages.MediaFiles.components.MediafileList.AutoCreateChipLabelFileNotCreated',
									  })
									: intl.formatMessage({
											id:
												'pages.MediaFiles.components.MediafileList.AutoCreateChipLabelVideoNotCreated',
									  })
							}
							clickable={true}
							onClick={() => handleCreateArtwork(mediafile, hasCreated)}
							classes={{ root: classes.autoCreateChipRoot }}
							icon={
								hasCreated ? <Badge badgeContent={count} max={99} color="primary"></Badge> : null
							}
						/>
					);
				},
			},
			{
				shouldDisplay: (mediafile) => (mediafile || {}).allowScreen, // hook func to verify that should the action be displayed for the given mediafile
				CustomComp: function addMediaToPlaylists({ mediafile, ...restProps }) {
					return <AddMediaToPlaylists mediaFileValue={mediafile} />;
				},
				tooltip: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileList.AddToScreenPlaylistTooltip',
				}),
			},
			{
				shouldDisplay: () => false, // hook func to verify that should the action be displayed for the given mediafile
				icon: <ShareIcon fontSize="small" />,
				tooltip: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileList.ShareActionTooltip',
				}),
				clickHandler: dummyHandler,
			},
			{
				shouldDisplay: () => false, // hook func to verify that should the action be displayed for the given mediafile
				icon: <OrderIcon fontSize="small" />,
				tooltip: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileList.OrderActionTooltip',
				}),
				clickHandler: dummyHandler,
			},
		];
	}, [
		classes.autoCreateChipCreated,
		classes.autoCreateChipNotCreated,
		classes.autoCreateChipRoot,
		handleCreateArtwork,
		handleDownloadMediafile,
		handleGotoCustomArtwork,
		handleShowLargePreview,
		intl,
		newAutoCreateTemplate,
		viewMode,
	]);

	const isValidJSON = (str) => {
		if (typeof str !== 'string') {
			return false;
		}
		try {
			JSON.parse(str);
			return true;
		} catch (e) {
			return false;
		}
	};

	const disableFilterIfURLParamsInDomainSettings = React.useMemo(() => {
		const keyValue = domainSettings.settings.find(({ key }) => key === 'disableFilterIfURLParams');
		return keyValue?.value ?? false; // if domainSettings was not fetched we set this flag to FALSE
		// so that PDF Files marked for Screen or Social Media will download in the default format.
	}, [domainSettings]);

	React.useEffect(() => {
		if (Object.keys(userData).length === 0) return; // no user data yet, don't trigger search
		const query = new URLSearchParams(location.search);
		const categories = query.get(CATEGORY_QUERY_PARAM); // categories in URL query params
		const mediafileIds = query.get(MEDIAFILEID_QUERY_PARAM); // mediafileIds in URL query params
		const queryA = new URLSearchParams(location.search);
		const categoriesA = queryA.get(CATEGORY_QUERY_PARAM); // categories in URL query params
		let urlParam = categoriesA || mediafileIds ? true : false;
		setIsURLParams(urlParam && disableFilterIfURLParamsInDomainSettings ? false : true);
		setIsLoading(true);
		let formatedFilters = Object.keys(filters).reduce((accu, searchableKey) => {
			if (searchableKey === 'updatedDatetime') {
				// NB: "updatedDatetime" is hardcoded as datetime range is using different data structure
				// removing hardcoded "updatedDatetime" is possible, but require so much effort
				return {
					...accu,
					[`${searchableKey}From`]: filters[searchableKey].startDate.toISOString(),
					[`${searchableKey}To`]: filters[searchableKey].endDate.toISOString(),
				};
			} else {
				return { ...accu, [searchableKey]: filters[searchableKey] };
			}
		}, {});
		// const query = new URLSearchParams(location.search);
		// const categories = query.get(CATEGORY_QUERY_PARAM); // categories in URL query params
		var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
		if (categories) {
			if (base64regex.test(categories) && isValidJSON(window.atob(categories))) {
				formatedFilters.categories = JSON.parse(window.atob(categories));
			} else {
				notifyGeneral(
					intl.formatMessage({
						id: 'pages.MediaFiles.components.MediafileList.InvalidURLParams',
					}),
					'error'
				);
			}
		}
		// const mediafileIds = query.get(MEDIAFILEID_QUERY_PARAM); // mediafileIds in URL query params
		if (mediafileIds) {
			if (base64regex.test(mediafileIds) && isValidJSON(window.atob(mediafileIds))) {
				formatedFilters.mediafileIds = JSON.parse(window.atob(mediafileIds));
			} else {
				notifyGeneral(
					intl.formatMessage({
						id: 'pages.MediaFiles.components.MediafileList.InvalidURLParams',
					}),
					'error'
				);
			}
		}
		if (userData.accessibleFileCategories?.length > 0 && userLevel < FILTER_GROUP_MIN_UL) {
			formatedFilters.accessibleFileCategories = userData.accessibleFileCategories.map(
				(cate) => cate.id
			);
		}
		if (userData.userGroups?.length > 0 && userLevel < FILTER_GROUP_MIN_UL) {
			formatedFilters.accessibleUserGroups = userData.userGroups.map((group) => group.id);
		}
		// VID-3663 The following complicated conditional will limit the number of files to be retrieved,
		// if the user has not specified an search criteria at all. This is for performance reasons.
		if (
			Object.keys(formatedFilters).length === 2 &&
			formatedFilters.accessibleFileCategories !== undefined &&
			formatedFilters.accessibleUserGroups !== undefined &&
			!searchKeywords
		) {
			formatedFilters.mostRecentNumber = 10000;
		} else if (Object.keys(formatedFilters).length === 1 && !searchKeywords) {
			if (
				(formatedFilters.accessibleFileCategories !== undefined &&
					formatedFilters.accessibleUserGroups === undefined) ||
				(formatedFilters.accessibleFileCategories === undefined &&
					formatedFilters.accessibleUserGroups !== undefined)
			)
				formatedFilters.mostRecentNumber = 10000;
		}

		if (userLevel < FILTER_GROUP_MIN_UL) {
			formatedFilters.fileStatuses = ['live'];
			formatedFilters.checkAvailabilityDates = true;
		}
		searchMediaFiles({
			queryParams: {
				previewSize: 'small',
				offset: pageIndex * itemsPerPage,
				limit: itemsPerPage,
				...(sortBy.length > 0
					? { sortBy: sortBy[0].id, sortDirection: sortBy[0].desc ? 'desc' : 'asc' }
					: {}),
			},
			bodyParams: {
				filters:
					Object.keys(formatedFilters).length > 0 || searchKeywords
						? { ...formatedFilters, ...(searchKeywords ? { keywords: searchKeywords } : {}) }
						: defaultFilter,
			},
			// filters: Object.keys(filters).reduce((accu, searchableKey) => {
			// 	if (Array.isArray(filters[searchableKey]) && filters[searchableKey].length > 0) {
			// 		accu[searchableKey] = filters[searchableKey];
			// 	}
			// 	return accu;
			// }, {}),
			// }, // mandatary
		})
			.then((res) => {
				setMediafiles(res.data.mediafiles);
				setTotalNumItems(res.data.totalFound || -1);
				if (Object.keys(formatedFilters).length > 0 || searchKeywords) {
					fileSearchingLogger.save({
						userId: userId,
						userCompany: userData.company,
						keywords: searchKeywords,
						filters: Object.keys(formatedFilters),
					});
				}
			})
			.catch((err) => {
				notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [
		disableFilterIfURLParamsInDomainSettings,
		filters,
		intl,
		itemsPerPage,
		location.search,
		notifyGeneral,
		pageIndex,
		searchKeywords,
		sortBy,
		userData,
		userLevel,
		fileSearchingLogger,
		userData.company,
		userId,
	]);

	return (
		<div className={classes.root}>
			{isURLParams && (
				<div className={classes.filter}>
					<MediafileFilters
						searchKeywords={searchKeywords}
						setSearchKeywords={handleSearchKeywords}
						selectedFilters={filters}
						setSelectedFilters={handleUpdateFilters}
						viewMode={viewMode}
						handleViewSwitch={setViewMode}
						columnsConfigure={columnsConfigure}
						appliedSortBy={sortBy}
						setSortBy={handleSortByImmediately}
						domainName={domainName}
						domainSettings={domainSettings}
						userLevel={userLevel}
					/>
				</div>
			)}
			<div className={classes.content}>
				{
					// <Backdrop className={classes.backdropLoading} open={isLoading}>
					// 	<CircularProgress color="primary" size={65} thickness={5.5} />
					// </Backdrop>
				}
				{viewMode === GRID_VIEW ? (
					<MediefilesGridView
						mediafiles={mediafiles}
						// searchKeywords={searchOpts.keywords}
						onMediaClick={handleGotoDetail}
						previewHeight={200}
						mediafileActions={mediafileActions}
						// isLoading={isLoading}
						noDataMsg={
							isLoading
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'pages.MediaFiles.components.MediafileList.NoMediafileMsg',
								  })
						}
						// noDataMsg={intl.formatMessage({
						// 	id: 'pages.MediaFiles.components.MediafileList.NoMediafileMsg',
						// })}
						numItemsPerRow={5}
						showRedBorder={userLevel >= MIN_EDIT_UL}
					/>
				) : viewMode === TABLE_VIEW ? (
					<MediafileTableView
						mediafiles={mediafiles}
						columnsConfigure={columnsConfigure}
						handleGotoDetail={handleGotoDetail}
						handleShowLargePreview={handleShowLargePreview}
						handlePatchMediafile={handlePatchMediafile}
						appliedSortBy={sortBy}
						handleSortBy={handleSortByWithDelay}
						setRTableInst={setRTableInst}
						// selectedRowIds={selectedRowIds}
						// handleRowsSelection={handleRowsSelection}
						mediafileActions={mediafileActions}
						// noDataMsg={intl.formatMessage({
						// 	id: 'pages.MediaFiles.components.MediafileList.NoMediafileMsg',
						// })}
						noDataMsg={
							isLoading
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'pages.MediaFiles.components.MediafileList.NoMediafileMsg',
								  })
						}
					/>
				) : (
					<Typography
						color="error"
						variant="h6"
						style={{ textAlign: 'center', width: '100%', paddingTop: 50, minHeight: 120 }}
					>
						{intl.formatMessage({
							id: 'pages.MediaFiles.components.MediafileList.UnknowViewModeErrMsg',
						})}
					</Typography>
				)}
			</div>
			{mediafiles.length > 0 && (
				<div className={classes.pagination}>
					<MUITablePagination
						// pagination props
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						rowsPerPage={itemsPerPage}
						disableRowsPerPage={false}
						pageIndex={pageIndex}
						totalNumRows={totalNumItems}
						labelRowsPerPage={intl.formatMessage({
							id: 'pages.MediaFiles.components.MediafileList.ItemsPerPageLabel',
						})}
					/>
				</div>
			)}
			{
				/** large preview of mediafile */
				mediafilePreview && (
					<MediafilesCarousel
						open={true}
						onClose={handleCloseMediafilePreview}
						mediafiles={mediafiles.map((m) => {
							return {
								isSelected: mediafilePreview.mediafile.id === m.id,
								isVideo: VIDEO_FILE_TYPES.includes(m.fileType.toUpperCase()),
								previewUrl: m.optimisedUrl,
							};
						})}
						autoPlayVideo={true}
					/>
				)
			}
			{/** Circular progress loader */}
			{config.isReactOnly ? (
				<Modal // `disableBackdropClick` is removed by codemod.
					// You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

					className={classes.backdropLoading}
					BackdropComponent={Backdrop}
					open={isLoading}
					disableEscapeKeyDown
				>
					<CircularProgress color="primary" size={65} thickness={5.5} />
				</Modal>
			) : (
				<Backdrop className={classes.backdropLoading} open={isLoading}>
					<CircularProgress color="primary" size={65} thickness={5.5} />
				</Backdrop>
			)}
		</div>
	);
}
MediafileList.propTypes = {
	// below are redux state
	userLevel: PropTypes.number.isRequired,
	userData: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired,
	// below are redux actions
	notifyGeneral: PropTypes.func.isRequired,
	domainName: PropTypes.string.isRequired,
	domainSettings: PropTypes.object.isRequired,
	reSyncCounter: PropTypes.func.isRequired,
};

MediafileList.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		domainName: state.authentication.domainName,
		userId: state.authentication.userId,
		userLevel: state.authentication.userLevel,
		userData: state.usermanager.userData,
		domainSettings: state.domainSettings,
	};
};
// export default MediafileList;
export default connect(mapStateToProps, {
	notifyGeneral,
	reSyncCounter,
})(MediafileList);
