import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

// MUI components
import { TextField, IconButton, ClickAwayListener } from '@mui/material';
import { Done as OKIcon, Close as CancelIcon } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
	root: {
		padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
		width: `100%`,
		color: 'inherit',
		fontSize: 'inherit',
		position: 'relative',
		display: 'flex',
	},
	textField: {
		color: 'inherit',
		fontSize: 'inherit',
	},
	textFieldInput: {
		color: 'inherit',
		fontSize: 'inherit', //paletteFontSize,
		// '&:focus': {
		// 	borderColor: 'unset',
		// },
	},
	textFieldLabel: {
		fontSize: 'inherit !important',
		color: 'inherit !important',
		// marginLeft: -8,
		// paddingLeft: theme.spacing(1), // `${theme.spacing(0)} ${theme.spacing(2)}`,
	},
	// labelFocused: {
	// 	color: 'inherit',
	// },
	// focusedTextbox: {
	// 	borderColor: 'red',
	// },
	buttonWrapper: {
		position: 'absolute',
		display: 'flex',
		// width: `calc(100% - calc(${theme.spacing(1)} * 2))`,
		height: '100%',
		paddingRight: theme.spacing(2),
		right: 0,
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	icon: {
		margin: 2,
		fontSize: '1rem',
	},
}));
function InputBox({ children, label, value, handleConfirm, handleCancel, type, ...rest }) {
	const classes = useStyles();
	const [inputVal, setInputVal] = React.useState(value);
	return (
		<ClickAwayListener onClickAway={handleCancel}>
			<div className={classes.root}>
				<TextField
					className={classes.textField}
					margin="dense"
					autoFocus
					fullWidth
					label={label}
					type={type}
					variant="outlined"
					value={inputVal}
					onChange={(e) => setInputVal(e.target.value || '')}
					InputProps={{
						className: classes.textFieldInput,
						onBlur: (e) => setInputVal(e.target.value || ''),
						// classes: {
						// 	focused: classes.focusedTextbox,
						// },
					}}
					InputLabelProps={{
						classes: {
							root: classes.textFieldLabel,
							// focused: classes.focusedTextbox,
						},
						shrink: true,
					}}
					inputProps={{
						style: { marginRight: 46 },
					}}
					{...rest}
				>
					{children}
				</TextField>
				<div className={classes.buttonWrapper}>
					<IconButton
						edge="end"
						color="inherit"
						size="small"
						aria-label="ok"
						onClick={(e) => {
							e.stopPropagation();
							handleConfirm(inputVal);
						}}
					>
						<OKIcon fontSize="small" className={classes.icon} />
					</IconButton>
					<IconButton
						edge="end"
						color="inherit"
						size="small"
						aria-label="cancel"
						onClick={(e) => {
							e.stopPropagation();
							handleCancel();
						}}
					>
						<CancelIcon fontSize="small" className={classes.icon} />
					</IconButton>
				</div>
			</div>
		</ClickAwayListener>
	);
}

InputBox.propTypes = {
	label: PropTypes.string.isRequired,
	// value: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	type: PropTypes.string,
	handleConfirm: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
};

InputBox.defaultProps = { type: 'string' };
export default InputBox;
