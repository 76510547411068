// application specific utils
// Note: there could be libs (created in our application) imported in this file,
//			 try not import this file in these libs, otherwise it will cause circular dependencies
//
// ** DO NOT import other helper file here **

import config, { ENVIRONMENTS_ENUM } from 'config';
import cryptojs from 'crypto-js';
import S3 from 'aws-sdk/clients/s3';
import reduxStore from 'redux/store';
import createLogger from '@visualid/logger';

/**
 * Get Logger by logName
 *
 * @param {string} logName. Name of logger
 *
 * @returns {Logger | null}
 */
const loggers = {};
export const getLogger = (logName) => {
	if (!config.supportedLoggers[logName]) {
		// not supported logger
		return null;
	}

	if (!loggers[logName]) {
		const authState = reduxStore.getState().authentication;
		loggers[logName] = createLogger({
			env: config.env === ENVIRONMENTS_ENUM.development ? 'dev' : 'prod',
			accessToken: authState.accessToken,
			level: 'info',
			logToFile: {},
			shouldLogToConsole: false,
			shouldHandleException: false,
			context: {
				domain: authState.domainName,
				namespace: 'toolkit',
				logName: logName,
				labels: config.supportedLoggers[logName].labels,
			},
		});
	}
	return loggers[logName];
};

// ##############################
// Get domain name by hostname in URL
// #############################
export const getDomainByHostname = () => {
	let hostname = window.location?.hostname ?? '';
	let domain = '';

	if (hostname.indexOf('doddle') >= 0) {
		domain = 'doddle';
		return domain;
	}

	if (hostname.indexOf('digicel') >= 0) {
		domain = 'digicel';
		return domain;
	}

	domain = hostname.split('.')[0].split('-')[0];
	if (!domain || domain === 'www') domain = 'default';
	return domain;
};

export const encryptStr = (str) => {
	return encodeURIComponent(cryptojs.AES.encrypt(str, config.stringCryptoSecret).toString());
};
export const decryptStr = (str) => {
	return cryptojs.AES.decrypt(decodeURIComponent(str), config.stringCryptoSecret).toString(
		cryptojs.enc.Utf8
	);
};

export const decryptStrBase64 = (str) => {
	return cryptojs.enc.Base64.parse(str).toString(cryptojs.enc.Utf8); // base64 decrypt
};
// ##############################
// Get the configuration of the current domain
// #############################
export const getDomainConfig = (domain) => {
	domain = domain || getDomainByHostname();
	return config.domains[domain] || config.domains['default'];
};

// ##############################
// AWS Helpers
// #############################

/**
 * Get bucket name from s3 url
 * @param {string} s3Url
 * @returns {string} bucketName
 */
export const getBucketFromS3Url = (s3Url) => {
	return s3Url.split('://')[1].split('/').shift();
};

/**
 * Convert s3 file params to s3 url
 * @param {object} s3FileParams format
	 {
		Bucket: 'xxx,
		Key: 'xxxx/sss.pdf',
	}
	* @returns string
	*/
export const convertS3FileParamsToS3Url = (s3FileParams) => {
	return `s3://${s3FileParams.Bucket}/${s3FileParams.Key}`;
};
/**
 * create s3 client
 * @param {object} opts
{
	accesskey: 'xxx', // required
	secretkey: 'xxx', // required
	sessionToken: 'xxx', // optional
	domain: 'xxx', // optional
	useAccelerateEndpoint: true/false // optional
}
 */
export const createS3Client = (opts = {}) => {
	let domainCfg = getDomainConfig(opts.domain);

	return new S3({
		region: domainCfg.awsRegion,
		maxRetries: config.AWS.maxRetries,
		httpOptions: {
			timeout: config.AWS.httpTimeout,
		},
		accessKeyId: opts.accesskey,
		secretAccessKey: opts.secretkey,
		sessionToken: opts.sessionToken || null,
		apiVersion: config.AWS.resources.s3.apiVersion,
		useAccelerateEndpoint:
			opts.useAccelerateEndpoint ?? config.AWS.resources.s3.useAccelerateEndpoint,
		computeChecksums: config.AWS.resources.s3.computeChecksums,
	});
};

/**
 * Convert cloudfront url of mediafile to s3 parameter object
 * @param {string} cloudfrontUrl
 * @returns {object|null} could be null if no bucket/key is found
		{
			Bucket: 'bucketName',
			Key: 'domain/path/file.ext, // NB: no '/' at beginning
		}
 */
export const getS3FileParamsFromCloudFrontUrl = (cloudfrontUrl) => {
	let s3FileParams = null;
	Object.entries(config.AWS.s3BucketToCloudFront).forEach(([bucket, cloudfrontHost]) => {
		if (!s3FileParams && bucket !== 'default' && cloudfrontUrl.trim().startsWith(cloudfrontHost)) {
			s3FileParams = {
				Bucket: bucket,
				Key: cloudfrontUrl.trim().replace(cloudfrontHost, '').substring(1).split('?')[0],
			};
		}
	});
	return s3FileParams;
};

/**
 * Convert cloudfront url of mediafile to s3Url
 * @param {string} cloudfrontUrl
 * @returns {string|null} could be null if no bucket/key is found
 */
export const getS3UrlFromCloudFrontUrl = (cloudfrontUrl = '') => {
	let s3Url = null;
	Object.entries(config.AWS.s3BucketToCloudFront).forEach(([bucket, cloudfrontHost]) => {
		if (!s3Url && bucket !== 'default' && cloudfrontUrl.trim().startsWith(cloudfrontHost)) {
			s3Url = `s3://${bucket}${cloudfrontUrl.trim().replace(cloudfrontHost, '').split('?')[0]}`;
		}
	});
	return s3Url;
};

// ##############################
// Mediafile Helpers
// #############################
/**
 * construct preview url of a mediafile
 * @param {object} mediafileParams. 
		{
			originalMediafileUrl: 's3Url of original mediafile',
			redirect: true/false,
			processnow: true/false,
			size: ['small', 'medium', 'large', 'lores', 'original']
		}
 */
export const getPreviewUrl = ({ originalMediafileUrl, redirect, processnow, size }) => {
	let previewAPIConf = config.previewApi;
	return `${previewAPIConf.baseUrl}${previewAPIConf.getPreviewEP}?${previewAPIConf.apiKeyName}=${
		previewAPIConf.apiKeyValue
	}&redirect=${redirect ? 'true' : 'false'}&processnow=${
		processnow ? 'true' : 'false'
	}&fileurl=${originalMediafileUrl}&size=${size}`;
};
