import React from 'react';

import { /* DesignContext, */ ART_VARIABLES } from '../../Constants';
// import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { convertHtmlAttrToReact, isVideoElemPlaying } from 'utils/libHelper';
import { prepareVideoField } from 'utils/artwork/artFieldsCreator';
import { isNullish } from 'utils/generalHelper';

const useStyles = makeStyles((theme) => ({
	pauseAnimation: {
		animationPlayState: 'paused !important',
	},
}));

function Video({
	field,
	fieldPreviewData,
	animationDelay,
	allowAnimation,
	pausingAnimation,
	...rest
}) {
	// const designContext = React.useContext(DesignContext);
	const classes = useStyles();
	// const intl = useIntl();
	// VIDEO_FIELD_ID: {
	// 	// VIDEO Preview Data
	// previewUrl: 'xxxxxxx', // preview url of this video. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
	// optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
	// highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'

	// 	// videoUrl: 'https://xxxxx', // undefined or original video url???
	// 	horizontalAlign: 'left' | 'center' | 'right' | undefined,
	// 	verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
	// 	videoLoop: true | false | undefined,
	// },
	const videoData = prepareVideoField({
		mode: 'preview',
		inputData: fieldPreviewData,
		field,
	});
	const videoUrl = fieldPreviewData.optimisedUrl || fieldPreviewData.highResUrl; // fieldPreviewData.previewUrl;
	// 'https://images.visualid.com/demo/20191018/8MQh1itiUOKJ6CL3EvxMobCpPtbF/ukTHjxWXDJ7v.mp4';
	// Use ref to fix the "video muted attribute stripped" issue with React. https://github.com/facebook/react/issues/10389
	const videoRef = React.useRef(null);
	// React.useEffect(() => {
	// 	if (videoRef.current) videoRef.current.setAttribute('muted', '');
	// }, []);
	const videoSizeRef = React.useRef(null);

	React.useEffect(() => {
		if (videoRef.current) {
			videoRef.current.setAttribute('muted', '');

			videoRef.current.addEventListener('loadedmetadata', () => {
				videoSizeRef.current = {
					width: videoRef.current.videoWidth,
					height: videoRef.current.videoHeight,
				};
				if (
					videoRef.current.videoWidth / videoRef.current.videoHeight >
					field.position.width / field.position.height
				) {
					videoRef.current.style.width = '100%';
					videoRef.current.style.height = 'auto';
				} else {
					videoRef.current.style.width = 'auto';
					videoRef.current.style.height = '100%';
				}
			});
			if (videoSizeRef.current) {
				if (
					videoSizeRef.current.width / videoSizeRef.current.height >
					field.position.width / field.position.height
				) {
					videoRef.current.style.width = '100%';
					videoRef.current.style.height = 'auto';
				} else {
					videoRef.current.style.width = 'auto';
					videoRef.current.style.height = '100%';
				}
			}
		}
	}, [field.position.height, field.position.width, videoUrl]);

	React.useEffect(() => {
		if (videoRef.current) {
			let videoLoop = isNullish(fieldPreviewData.videoLoop)
				? field.videoLoop
				: fieldPreviewData.videoLoop;
			if (videoLoop) videoRef.current.setAttribute('loop', true);
			else videoRef.current.removeAttribute('loop');

			if (!isVideoElemPlaying(videoRef.current)) videoRef.current.play();
		}
	}, [field.videoLoop, fieldPreviewData.videoLoop]);

	const noAnimationStyle = `0s ease 0s 1 normal none running none`;
	const animationEntranceStyle = field.animation.entrance
		? `${field.animation.entrance} ${
				field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
		  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
				animationDelay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
		  }s both`
		: noAnimationStyle;
	// const animationExitStyle = field.animation.exit
	// 	? `${field.animation.exit} ${field.animation.duration ||
	// 			1}s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${0}s both`
	// 	: noAnimationStyle;

	return (
		<svg
			x={field.position.left}
			y={field.position.top}
			width={field.position.width}
			height={field.position.height}
			overflow={'visible'}
		>
			<g
				className={cx({ [classes.pauseAnimation]: pausingAnimation })}
				style={{
					animation:
						allowAnimation && field.animation.entrance ? animationEntranceStyle : noAnimationStyle,
				}}
			>
				{/* Somehow, "transform" not working when together with "style" in the same <g> */}
				<g
					transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
						field.position.height / 2
					})`}
				>
					{videoUrl && (
						<foreignObject width="100%" height="100%">
							<div {...convertHtmlAttrToReact({ style: videoData.videoDivWrapperStyle })}>
								<video
									key={videoUrl}
									ref={videoRef}
									autoPlay
									muted
									// loop={fieldPreviewData.videoLoop}
									/* poster  x="0" y="0" width="400" height="400"*/
									// style={{
									// 	// minWidth: '100%',
									// 	// minHeight: '100%',
									// 	maxWidth: '100%',
									// 	maxHeight: '100%',
									// 	// width:
									// 	// position: 'absolute',
									// 	// transform: `translate(-50%, -50%)`,
									// 	// left: '50%',
									// 	// top: '50%',
									// }}
								>
									<source src={videoUrl} type="video/mp4" />
								</video>
							</div>
						</foreignObject>
					)}
					{videoSizeRef.current && field.borderWidth > 0 && (
						<rect
							width={field.position.width + field.borderWidth}
							height={field.position.height + field.borderWidth}
							x={-field.borderWidth / 2}
							y={-field.borderWidth / 2}
							fill="none"
							stroke={field.borderColor.hex || '#000000'}
							strokeWidth={field.borderWidth}
						></rect>
					)}
				</g>
			</g>
		</svg>
	);
}

Video.propTypes = {
	field: PropTypes.object.isRequired, // it is text field
	fieldPreviewData: PropTypes.object.isRequired, // the preview data of a field
	animationDelay: PropTypes.number.isRequired, // animation delay. Relative to previous fields
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is aninimation paused (on video end in videoArtwork)
};

Video.defaultProps = {};
export default React.memo(Video);
