/**
 * App Language Provider
 * Add more locales here
 */
import config from 'config';
import enFlag from './flags/IE.png';
import zhFlag from './flags/CN.png';
import localesMessages from './locales.js';
// import enMessages from './locales/en';
// import zhMessages from './locales/zh';

if (!Intl.PluralRules) {
	// eslint-disable-next-line
	import('@formatjs/intl-pluralrules/polyfill');
	// eslint-disable-next-line
	import('@formatjs/intl-pluralrules/locale-data/en'); // Add locale data for en
	// eslint-disable-next-line
	import('@formatjs/intl-pluralrules/locale-data/zh'); // Add locale data for zh-Hans Chinese, Han (Simplified variant)
}
if (!Intl.RelativeTimeFormat) {
	// eslint-disable-next-line
	import('@formatjs/intl-relativetimeformat/polyfill');
	// eslint-disable-next-line
	import('@formatjs/intl-relativetimeformat/locale-data/en'); // Add locale data for en
	// eslint-disable-next-line
	import('@formatjs/intl-relativetimeformat/locale-data/zh'); // Add locale data for zh-Hans Chinese, Han (Simplified variant)
}

const DEFAULT_LOCALE = 'en';

// Format message. Use default locale message in case the user's locale is not supported, or some messages are missing in non-default locale
// In production, we use messages in default locale for any missing messages in other locales.
// In non-production, the missing messages in non-default locale remains undefined so that these missing messages can be printed in console error
const formatLocaleMessages = (locale) => {
	let translationMsg = {};
	for (let msgKey in localesMessages) {
		let message = localesMessages[msgKey];
		translationMsg[msgKey] =
			message[locale] || (config.env === 'production' ? message[DEFAULT_LOCALE] : undefined);
	}
	return translationMsg;
};

const AppLocales = {
	en: {
		name: 'English',
		flag: enFlag,
	},
	zh: {
		name: '中文简体',
		flag: zhFlag,
	},
};

const AppLocaleMessages = {
	en: formatLocaleMessages('en'),
	zh: formatLocaleMessages('zh'),
};

export { AppLocaleMessages, AppLocales, DEFAULT_LOCALE };
