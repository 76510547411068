import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { _ } from 'utils/libHelper';
// MUI components
import { FormControlLabel, Checkbox, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ToggleButton from '@mui/material/ToggleButton';

import {
	FormatAlignLeft as FormatAlignLeftIcon,
	FormatAlignCenter as FormatAlignCenterIcon,
	FormatAlignRight as FormatAlignRightIcon,
	// ImageSearch as ImageSearchIcon,
	// Clear as ClearIcon,
} from '@mui/icons-material';

import {
	FormatVerticalAlignTop as FormatVerticalAlignTopIcon,
	FormatVerticalAlignCenter as FormatVerticalAlignCenterIcon,
	FormatVerticalAlignBottom as FormatVerticalAlignBottomIcon,
} from 'mdi-material-ui';

// Constants
import { ART_VARIABLES } from '../../../Constants';
import {
	StyledToggleButtonGroup,
	StyledOutlinedTextFieldSelection,
	SimpleSwitchVer,
	// SimpleNumberSlider,
	StyledOutlinedTextField,
	StyledOutlinedSelectionWithSubheader,
	DividerVer,
	DividerHor,
	SimpleSwitchRow,
	// StyledContainedButton,
} from '../../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

const useStylePaletteStyles = makeStyles((theme) => ({
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	twoColumnsRowDiv: {
		justifyContent: 'space-evenly',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	imageSizing: {
		width: '70%',
		margin: 0,
	},
	alignUserDefinedFormControlLabel: {
		margin: 0,
	},
	alignUserDefinedFormControlLabelText: {
		fontSize: 'inherit',
		textAlign: 'center',
	},
	alignUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
	toggleGroupSmall: {
		width: 30,
		height: 30,
	},
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
}));

export const BarcodePaletteStyles = ({ field, updateField, setColorPicker, ...rest }) => {
	const classes = useStylePaletteStyles();
	const intl = useIntl();
	return (
		<div className={classes.tabWrapper}>
			<div className={classes.groupsDiv}>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<span style={{ paddingRight: 8, width: 90, textAlign: 'left' }}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.colorText',
						})}
					</span>
					<div
						style={{ width: `calc(100% - 90px)`, display: 'inline-flex', justifyContent: 'center' }}
					>
						<div
							style={{
								backgroundColor: field.color && field.color.hex ? field.color.hex : '#000',
								width: '80%',
								height: 40,
								margin: '0px 4px',
								cursor: 'pointer',
								borderRadius: 4,
							}}
							onClick={(e) =>
								setColorPicker({
									anchorEl: e.currentTarget,
									fieldKey: 'color',
									disableAlpha: true,
									enablePantone: true,
									enableCMYK: true,
								})
							}
						></div>
					</div>
				</div>
			</div>

			<DividerHor />
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.horAlignText',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledToggleButtonGroup
						value={field.horizontalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={(e, alignment) =>
							updateField({
								horizontalAlign: alignment || ART_VARIABLES.defaultFields.barcode.horizontalAlign,
							})
						}
						aria-label="image horizontal alignment"
					>
						<ToggleButton value="left" aria-label="left aligned">
							<FormatAlignLeftIcon />
						</ToggleButton>
						<ToggleButton value="center" aria-label="centered horizontal">
							<FormatAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="right" aria-label="right aligned">
							<FormatAlignRightIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<DividerVer />
					<FormControlLabel
						className={classes.alignUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={field.horizontalAlignUserDefined || false}
								onChange={(e) => updateField({ horizontalAlignUserDefined: e.target.checked })}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.alignUserDefinedFormControlLabelText,
						}}
					/>
				</div>
			</div>
			<DividerHor />
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.verAlignText',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledToggleButtonGroup
						value={field.verticalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={(e, alignment) =>
							updateField({
								verticalAlign: alignment || ART_VARIABLES.defaultFields.barcode.verticalAlign,
							})
						}
						aria-label="image vertical alignment"
					>
						<ToggleButton value="top" aria-label="top aligned">
							<FormatVerticalAlignTopIcon />
						</ToggleButton>
						<ToggleButton value="middle" aria-label="middle horizontal">
							<FormatVerticalAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="bottom" aria-label="bottpm aligned">
							<FormatVerticalAlignBottomIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<DividerVer />
					<FormControlLabel
						className={classes.alignUserDefinedFormControlLabel}
						value=""
						control={
							<Checkbox
								className={classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={field.verticalAlignUserDefined || false}
								onChange={(e) => updateField({ verticalAlignUserDefined: e.target.checked })}
							/>
						}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedText',
						})}
						labelPlacement="bottom"
						classes={{
							label: classes.alignUserDefinedFormControlLabelText,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

BarcodePaletteStyles.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	setColorPicker: PropTypes.func.isRequired,
	// artworkExtraData: PropTypes.object.isRequired,
	// templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
};

BarcodePaletteStyles.defaultProps = {};

/** BarcodePaletteData Component */
const useDataPaletteStyles = makeStyles((theme) => ({
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
}));

export const BarcodePaletteData = ({
	field,
	updateField,
	artworkExtraData,
	templateFields,
	...rest
}) => {
	const classes = useDataPaletteStyles();
	const intl = useIntl();
	// generate grouped template fields
	// const groupedTemplateFields = {};
	const groupedFieldsWithPredefinedSSValue = {};
	const groupedImageFields = {};
	const groupedTextFields = {};
	templateFields.forEach((f) => {
		// if (!groupedTemplateFields[f.groupName]) {
		// 	groupedTemplateFields[f.groupName] = [];
		// }
		// groupedTemplateFields[f.groupName].push({
		// 	value: f.id,
		// 	label: f.name,
		// 	disabled: f.id === field.id,
		// });

		if (f.predefinedValue && f.predefinedValue.type === 'spreadsheet' && f.predefinedValue.from) {
			if (!groupedFieldsWithPredefinedSSValue[f.groupName]) {
				groupedFieldsWithPredefinedSSValue[f.groupName] = [];
			}
			groupedFieldsWithPredefinedSSValue[f.groupName].push({
				value: f.id,
				label: f.name,
				disabled: f.id === field.id,
			});
		}
		if (f.type === 'image') {
			if (!groupedImageFields[f.groupName]) {
				groupedImageFields[f.groupName] = [];
			}
			groupedImageFields[f.groupName].push({
				value: f.id,
				label: f.name,
				disabled: f.id === field.id,
			});
		}
		if (f.type === 'text') {
			if (!groupedTextFields[f.groupName]) {
				groupedTextFields[f.groupName] = [];
			}
			groupedTextFields[f.groupName].push({
				value: f.id,
				label: f.name,
				disabled: f.id === field.id,
			});
		}
	});

	let autoImportSpreadsheet = null;
	if (field.autoImportMeta.basedOn && field.autoImport === 'cell:') {
		let basedOnField = _.find(templateFields, (f) => f.id === field.autoImportMeta.basedOn);
		if (basedOnField) {
			autoImportSpreadsheet = _.find(
				artworkExtraData.spreadsheets,
				(s) => s.id === basedOnField.predefinedValue.from
			);
		}
	}

	return (
		<div className={classes.tabWrapper}>
			<StyledOutlinedTextField
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GENERAL.defaultValText',
				})}
				multiline
				rows={3}
				value={field.defaultValue || ''}
				onChange={(e) => updateField({ defaultValue: e.target.value || '' })}
			/>
			{
				// 	<DividerHor />
				// <StyledOutlinedSelectionWithSubheader
				// 	fullWidth
				// 	select
				// 	label="Depends On"
				// 	value={field.dependsOn || ''}
				// 	onChange={e => updateField({ dependsOn: e.target.value || '' })}
				// 	noneOption
				// 	options={groupedTemplateFields}
				// />
			}
			<DividerHor />
			<StyledOutlinedSelectionWithSubheader
				fullWidth
				select
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.BarcodePalette.appendText',
				})}
				value={field.append || ''}
				onChange={(e) => updateField({ append: e.target.value || '' })}
				noneOption
				options={groupedTextFields}
			/>
			<DividerHor />
			<div className={classes.groupsDiv}>
				<SimpleSwitchRow
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.BarcodePalette.ean5AddonText',
					})}
					style={{ paddingTop: 8, paddingBottom: 8 }}
					checked={field.EAN5Addon || false}
					onChange={(e, checked) => updateField({ EAN5Addon: checked })}
				/>
			</div>
			<DividerHor />
			<div
				className={classes.groupsDiv}
				style={field.autoImport ? {} : { margin: 0, width: '100%', border: 'none' }}
			>
				<StyledOutlinedSelectionWithSubheader
					fullWidth
					select
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.autoImportText',
					})}
					value={field.autoImport || ''}
					onChange={(e) =>
						updateField({
							autoImport: e.target.value || '',
							// reset autoImportMeta as autoImport is changed
							autoImportMeta: field.defaultAutoImportMeta,
						})
					}
					noneOption
					options={{
						'Image Data': artworkExtraData.autoImport['Image Data'],
						...ART_VARIABLES.autoImportOtherForBarcode,
					}}
				/>
				{(field.autoImport.startsWith('image:') || field.autoImport === 'cell:') && (
					<StyledOutlinedSelectionWithSubheader
						fullWidth
						select
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.basedOnText',
						})}
						value={field.autoImportMeta.basedOn || ''}
						onChange={(e) =>
							updateField({
								autoImportMeta: {
									...field.defaultAutoImportMeta,
									basedOn: e.target.value || '',
									// spreadsheetCell: '',
								},
							})
						}
						noneMsg={
							field.autoImport === 'cell:' &&
							Object.keys(groupedFieldsWithPredefinedSSValue).length === 0
								? 'No Fields Available'
								: field.autoImport.startsWith('image:') &&
								  Object.keys(groupedImageFields).length === 0
								? 'No Image Fields'
								: null
						}
						noneOption
						options={
							field.autoImport === 'cell:'
								? groupedFieldsWithPredefinedSSValue
								: field.autoImport.startsWith('image:')
								? groupedImageFields
								: []
						}
					/>
				)}
				{field.autoImportMeta.basedOn && field.autoImport === 'cell:' && (
					<React.Fragment>
						<StyledOutlinedTextFieldSelection
							fullWidth
							select
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.chooseCellText',
							})}
							value={field.autoImportMeta.spreadsheetCell || ''}
							onChange={(e) =>
								updateField({
									autoImportMeta: {
										...field.autoImportMeta,
										spreadsheetCell: e.target.value || null,
									},
								})
							}
							noneMsg={
								autoImportSpreadsheet && Array.isArray(autoImportSpreadsheet.column_names)
									? null
									: intl.formatMessage({
											id: 'pages.Artwork.components.ElementPalette.GENERAL.notAvailableText',
									  })
							}
							noneOption
							options={
								autoImportSpreadsheet && Array.isArray(autoImportSpreadsheet.column_names)
									? autoImportSpreadsheet.column_names.map((c) => ({
											value: c.position,
											label: c.column_name,
									  }))
									: []
							}
						/>
						<div className={classes.groupRowDiv}>
							{[
								{
									label: intl.formatMessage({
										id: 'pages.Artwork.components.ElementPalette.GENERAL.esignEditableText',
									}),
									fieldKey: 'esignEditable',
								},
								{
									label: intl.formatMessage({
										id: 'pages.Artwork.components.ElementPalette.GENERAL.esignSearchableText',
									}),
									fieldKey: 'esignSearchable',
								},
								{
									label: intl.formatMessage({
										id: 'pages.Artwork.components.ElementPalette.GENERAL.esignFilterText',
									}),
									fieldKey: 'esignFilter',
								},
							]
								.map((item) => (
									<SimpleSwitchVer
										key={item.fieldKey}
										label={item.label}
										style={{ padding: 0 }}
										checked={field.autoImportMeta[item.fieldKey] || false}
										onChange={(e, checked) =>
											updateField({
												autoImportMeta: { ...field.autoImportMeta, [item.fieldKey]: checked },
											})
										}
									/>
								))
								.reduce((accu, elem, idx) => {
									return accu.length === 0 ? [elem] : [...accu, <DividerVer key={idx} />, elem];
								}, [])}
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

BarcodePaletteData.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	artworkExtraData: PropTypes.object.isRequired,
	templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
};

BarcodePaletteData.defaultProps = {};
