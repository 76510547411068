import {
	FETCH_AWS_RESOURCE_CREDENTIAL,
	FETCH_AWS_RESOURCE_CREDENTIAL_SUCCESS,
	FETCH_AWS_RESOURCE_CREDENTIAL_FAIL,
	RESET_AWS_RESOURCE_CREDENTIAL,
} from 'redux/actions';

import config from 'config';

const usermanagerAPIBaseUrl = config.usermanagerAPI.baseUrl;
const authHeaderKey = config.usermanagerAPI.authHeaderKey;

/**
 * fetch aws credential to access centain resource
 * @param {object} opts. 
 	{
	 	resource: 's3'	// mandatary.
	}
 * @param {func} cb
 */
// eslint-disable-next-line no-unused-vars
export const fetchAWSCredential = (opts = {}, cb) => (dispatch, getState) => {
	// validate the parameters
	if (!opts.resource) {
		if (typeof cb === 'function') cb(new Error('missing required parameters...'));
		return;
	}

	let reqUrl = `${usermanagerAPIBaseUrl}/awscred/${opts.resource}`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		timeout: 30000,
	};
	return dispatch({
		types: [
			FETCH_AWS_RESOURCE_CREDENTIAL,
			FETCH_AWS_RESOURCE_CREDENTIAL_SUCCESS,
			FETCH_AWS_RESOURCE_CREDENTIAL_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
		resource: opts.resource,
	});
};

/**
 * reset the aws credential of centain resource.
 * Note, if no resource specified, all credentials will be reseted
 * @param {object} opts.
 	{
	 	resource: 's3'	// optional. If no specified, all credentials will be reseted
	}
 */
export const resetAWSCredential = (opts = {}) => ({
	type: RESET_AWS_RESOURCE_CREDENTIAL,
	resource: opts.resource,
});
