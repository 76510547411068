import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import {
	Table as MuiTable,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableSortLabel,
	TableRow,
	Typography,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
	tableContainer: {
		width: '100%',
		height: '100%',
		position: 'relative',
	},
	table: {
		border: '1px solid rgba(0,0,0,0.1)',
		borderRadius: 4,
		width: '100%',
		height: '100%',
		// borderCollapse: 'collapse',
	},
	tableHeadCell: {
		fontWeight: 700,
		fontSize: '1.15em',
		padding: theme.spacing(1, 1),
		whiteSpace: 'nowrap',
	},
	noCursor: {
		cursor: 'unset',
	},
	tableBodyCell: {
		padding: theme.spacing(0.5, 1),
	},
	stickyHeaderCell: {
		backgroundColor: '#cbedfd', // theme.palette.primary.light,
	},
	noData: {
		textAlign: 'center',
		paddingTop: theme.spacing(3),
	},
}));

const ReactTableMUIView = ({
	className,
	isStickyHeader,
	showBorder,
	rowHighlightPicker,

	// table props
	getTableProps,
	headerGroups,
	prepareRow,
	rows,
	noDataMsg,
}) => {
	const classes = useStyles();
	return rows.length > 0 ? (
		<TableContainer className={cx(classes.tableContainer, { [className]: Boolean(className) })}>
			<MuiTable
				className={classes.table}
				stickyHeader={isStickyHeader}
				{...getTableProps()}
				{...(showBorder ? { border: 1 } : {})}
			>
				{/** Table Header */}
				<TableHead>
					{headerGroups.map((headerGroup, headerGroupIdx) => (
						<TableRow
							key={`rTable-headerRow-${headerGroupIdx}`}
							{...headerGroup.getHeaderGroupProps()}
						>
							{headerGroup.headers.map((column, columnInHeaderGroupIdx) => {
								return (
									<TableCell
										key={`rTable-headerCell-${columnInHeaderGroupIdx}`}
										className={cx(classes.tableHeadCell, {
											[classes.stickyHeaderCell]: isStickyHeader,
										})}
										{...column.getHeaderProps()}
										{...(column.canSort ? column.getSortByToggleProps() : {})}
									>
										<TableSortLabel
											active={column.isSorted}
											direction={column.isSortedDesc ? 'desc' : 'asc'}
											hideSortIcon={!column.canSort}
											className={cx({ [classes.noCursor]: !column.canSort })}
											sx={{
												width: '100%',
											}}
										>
											{column.render('Header')}
										</TableSortLabel>
										{
											// 	enableResizer && (
											// 	<div
											// 		{...column.getResizerProps()}
											// 		className={cx(classes.resizer, { [classes.isResizing]: column.isResizing })}
											// 	></div>
											// )
										}
									</TableCell>
								);
							})}
						</TableRow>
					))}
				</TableHead>
				{/** Table Body */}
				<TableBody>
					{/* rows is "An array of materialized row objects from the original data array and columns passed into the table options" */}
					{rows.map((row, rowInPageIdx) => {
						prepareRow(row);
						let isRowHighlight = rowHighlightPicker && rowHighlightPicker(row);
						return (
							<TableRow
								key={`rTable-rowInPage-${rowInPageIdx}`}
								{...row.getRowProps()}
								hover={true}
								selected={isRowHighlight}
							>
								{row.cells.map((cell, cellInRowIdx) => {
									return (
										<TableCell
											key={`rTable-cellInRow-${cellInRowIdx}`}
											className={cx(classes.tableBodyCell, {
												// [classes.inheritHighlight]: isRowHighlight,
											})}
											// padding="none"
											// size="small"
											{...cell.getCellProps()}
										>
											{cell.render('Cell')}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</MuiTable>
		</TableContainer>
	) : (
		<Typography className={classes.noData} color="error" variant="h4">
			{noDataMsg}
		</Typography>
	);
};

ReactTableMUIView.propTypes = {
	/**
	 * className applys to table container
	 */
	className: PropTypes.string,
	/**
	 * if true, the header of table is sticky
	 */
	isStickyHeader: PropTypes.bool,
	// enableResizer: PropTypes.bool,
	showBorder: PropTypes.bool,

	/**
	 * function to pick up the rows that will be highlight (e.g. row is selected)
	 * NB: if this function is not provided, row highlight is disabled
	 * @param {object} row. It is the row object returned by the table instance
	 * @returns {bool} if true, highlight the row, otherwise don't
	 *
	 * Example: (row) => row.isSelected
	 */
	rowHighlightPicker: PropTypes.func,

	// table props
	getTableProps: PropTypes.func.isRequired,
	headerGroups: PropTypes.array.isRequired,
	prepareRow: PropTypes.func.isRequired,
	rows: PropTypes.array.isRequired,

	// other props
	noDataMsg: PropTypes.string,
};

ReactTableMUIView.defaultProps = {
	isStickyHeader: false,
	showBorder: false,
	// enableResizer: false,
	noDataMsg: 'No Data!!',
};
/**
 * Notes when using this component:
 * - The "height" of the parent is required to make the position & scrolling working properly
 */

export default ReactTableMUIView;
