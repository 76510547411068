import {
	FETCH_SCREENMANAGER_PLAYLISTS,
	FETCH_SCREENMANAGER_PLAYLISTS_SUCCESS,
	FETCH_SCREENMANAGER_PLAYLISTS_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_PLAYLISTS_REQUEST,
	EDIT_SCREENMANAGER_PLAYLISTS_SUCCESS,
	EDIT_SCREENMANAGER_PLAYLISTS_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_PLAYLISTS_REQUEST,
	ADD_SCREENMANAGER_PLAYLISTS_SUCCESS,
	ADD_SCREENMANAGER_PLAYLISTS_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_PLAYLISTS_REQUEST,
	DELETE_SCREENMANAGER_PLAYLISTS_SUCCESS,
	DELETE_SCREENMANAGER_PLAYLISTS_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_DELETESTATUS,
} from 'redux/actions';

import {
	DUPLICATE_SCREENMANAGER_PLAYLIST_REQUEST,
	DUPLICATE_SCREENMANAGER_PLAYLIST_SUCCESS,
	DUPLICATE_SCREENMANAGER_PLAYLIST_FAIL,
} from 'redux/actions';

// import { GET_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST, GET_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS, GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL, RESET_SCREENMANAGER_PLAYLISTS_SLIDE_GETSTATUS } from 'redux/actions';
// import { EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST, EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS, EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL, RESET_SCREENMANAGER_PLAYLISTS_SLIDE_EDITSTATUS } from 'redux/actions';
// import { ADD_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST, ADD_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS, ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL, RESET_SCREENMANAGER_PLAYLISTS_SLIDE_ADDSTATUS } from 'redux/actions';
// import { DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST, DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS, DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL, RESET_SCREENMANAGER_PLAYLISTS_SLIDE_DELETESTATUS } from 'redux/actions';
// import {RESET_SCREENMANAGER_PLAYLIST_SLIDE} from 'redux/actions';

import config from 'config';

const screenApiBaseUrl = config.screenAPI.baseUrl;
const authHeaderKey = config.screenAPI.authHeaderKey;

export const fetchScreenManagerPlaylists = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${screenApiBaseUrl}/playlists`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		timeout: 300000,
	};
	return dispatch({
		types: [
			FETCH_SCREENMANAGER_PLAYLISTS,
			FETCH_SCREENMANAGER_PLAYLISTS_SUCCESS,
			FETCH_SCREENMANAGER_PLAYLISTS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the whole playlist data store (including editing status, adding status, etc.)
export const resetScreenManagerPlaylists = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS,
});

// Editing Playlist
// opts: {
// 	playlistId: xxxxx,
// 	reqData: {}
// }
export const editScreenManagerPlaylist = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/playlists/${opts.playlistId}`,
		method: 'patch',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			EDIT_SCREENMANAGER_PLAYLISTS_REQUEST,
			EDIT_SCREENMANAGER_PLAYLISTS_SUCCESS,
			EDIT_SCREENMANAGER_PLAYLISTS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// Reset editing status
export const resetScreenManagerPlaylistEditStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS_EDITSTATUS,
});

// Adding Playlist
// opts: {
// 	reqData: {}
// }
export const addScreenManagerPlaylist = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/playlists`,
		method: 'post',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			ADD_SCREENMANAGER_PLAYLISTS_REQUEST,
			ADD_SCREENMANAGER_PLAYLISTS_SUCCESS,
			ADD_SCREENMANAGER_PLAYLISTS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

export const resetScreenManagerPlaylistAddStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS_ADDSTATUS,
});

// Deleting Playlist
// opts: {
// playlistId: xxxx
// }
export const deleteScreenManagerPlaylist = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/playlists/${opts.playlistId}`,
		method: 'delete',
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			DELETE_SCREENMANAGER_PLAYLISTS_REQUEST,
			DELETE_SCREENMANAGER_PLAYLISTS_SUCCESS,
			DELETE_SCREENMANAGER_PLAYLISTS_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		playlistId: opts.playlistId,
		afterSuccess: cb,
	});
};

export const resetScreenManagerPlaylistDeleteStatus = () => ({
	type: RESET_SCREENMANAGER_PLAYLISTS_DELETESTATUS,
});

// duplicate Playlist
// @params {object} {srcPlaylistId}
export const duplicateScreenManagerPlaylist = (opts, cb) => (dispatch, getState) => {
	let { srcPlaylistId } = opts;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/playlists/${srcPlaylistId}/duplicate`,
		method: 'post',
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			DUPLICATE_SCREENMANAGER_PLAYLIST_REQUEST,
			DUPLICATE_SCREENMANAGER_PLAYLIST_SUCCESS,
			DUPLICATE_SCREENMANAGER_PLAYLIST_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		srcPlaylistId: srcPlaylistId,
		afterSuccess: cb,
	});
};

// ///////////////////////////// Playlist Slide /////////////////////////
// // Get playlist slides
// // opts: {
// // 	playlistId: xxxxx,
// // }
// export const getScreenManagerPlaylistSlide = (opts, cb) => (dispatch, getState) => {
// 	let reqUrl = `${screenApiBaseUrl}/playlist/${opts.playlistId}`;

// 	let reqHeader = {
// 		'Content-Type': 'application/json',
// 		[authHeaderKey]: getState().authentication.token
// 	};
// 	let axiosConfig = {
// 		url: reqUrl,
// 		method: 'get',
// 		headers: reqHeader,
// 		timeout: 300000
// 	};
// 	return dispatch({
// 		types: [GET_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST, GET_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS, GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL],
// 		promise: client => {
// 			return client.request(axiosConfig);
// 		},
// 		afterSuccess: cb
// 	});
// };

// // reset get playlist (slide) status
// export const resetScreenManagerPlaylistSlideGetStatus = () => ({
// 	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_GETSTATUS
// })

// // reset the whole playlist SLIDE data store (including editing status, adding status, etc.)
// export const resetScreenManagerPlaylistSlideAllStatus = () => ({
// 	type: RESET_SCREENMANAGER_PLAYLIST_SLIDE
// })

// // Editing playlist slide
// // opts: {
// // 	playlistId: xxxxx,
// //  slideId: xxxx,
// // 	reqData: {}
// // }
// export const editScreenManagerPlaylistSlide = (opts, cb) => (dispatch, getState) => {
// 	let reqHeader = {
// 		'Content-Type': 'application/json',
// 		[authHeaderKey]: getState().authentication.token
// 	};
// 	let axiosConfig = {
// 		url: `${screenApiBaseUrl}/playlist/${opts.playlistId}/slide/${opts.slideId}`,
// 		method: 'patch',
// 		data: opts.reqData,
// 		headers: reqHeader,
// 		timeout: 300000
// 	};

// 	return dispatch({
// 		types: [EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST, EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS, EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL],
// 		promise: client => {
// 			return client.request(axiosConfig);
// 		},
// 		afterSuccess: cb,
// 	});
// }

// // Reset slide editing status
// export const resetScreenManagerPlaylistSlideEditStatus = () => ({
// 	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_EDITSTATUS
// })

// // Adding playlist slide
// // opts: {
// //  playlistId: xxxx,
// // 	reqData: {}
// // }
// export const addScreenManagerPlaylistSlide = (opts, cb) => (dispatch, getState) => {
// 	let reqHeader = {
// 		'Content-Type': 'application/json',
// 		[authHeaderKey]: getState().authentication.token
// 	};
// 	let axiosConfig = {
// 		url: `${screenApiBaseUrl}/playlist/${opts.playlistId}/slide`,
// 		method: 'post',
// 		data: opts.reqData,
// 		headers: reqHeader,
// 		timeout: 300000
// 	};

// 	return dispatch({
// 		types: [ADD_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST, ADD_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS, ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL],
// 		promise: client => {
// 			return client.request(axiosConfig);
// 		},
// 		afterSuccess: cb,
// 	});
// }

// export const resetScreenManagerPlaylistSlideAddStatus = () => ({
// 	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_ADDSTATUS
// })

// // Deleting slide from playlist
// // opts: {
// // playlistId: xxxx,
// // slideId: xxxx
// // }
// export const deleteScreenManagerPlaylistSlide = (opts, cb) => (dispatch, getState) => {
// 	let reqHeader = {
// 		'Content-Type': 'application/json',
// 		[authHeaderKey]: getState().authentication.token
// 	};
// 	let axiosConfig = {
// 		url: `${screenApiBaseUrl}/playlist/${opts.playlistId}/slide/${opts.slideId}`,
// 		method: 'delete',
// 		headers: reqHeader,
// 		timeout: 300000
// 	};

// 	return dispatch({
// 		types: [DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST, DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS, DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL],
// 		promise: client => {
// 			return client.request(axiosConfig);
// 		},
// 		playlistId: opts.playlistId,
// 		afterSuccess: cb,
// 	});
// }

// export const resetScreenManagerPlaylistSlideDeleteStatus = () => ({
// 	type: RESET_SCREENMANAGER_PLAYLISTS_SLIDE_DELETESTATUS
// })
