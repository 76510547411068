/*
 *
 * LanguageProvider actions
 *
 */

import { CHANGE_LOCALE } from 'redux/actions';

export function changeLocale(languageLocale) {
	return {
		type: CHANGE_LOCALE,
		locale: languageLocale,
	};
}
