import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Draggable from 'react-draggable';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	IconButton,
	Paper,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	title: {
		margin: 0,
		padding: theme.spacing(2),
		cursor: 'move',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const titleId = 'mui-dialog-title';
function PaperComponent(props) {
	return (
		<Draggable handle={`[id="${titleId}"]`}>
			<Paper {...props} />
		</Draggable>
	);
}

function MuiDialog({ open, size, title, content, confirmCB, onClose, draggable }) {
	const classes = useStyles();
	const intl = useIntl();
	return (
		<Dialog
			disableEscapeKeyDown
			open={open}
			maxWidth={size}
			fullWidth={true}
			scroll="paper"
			aria-labelledby={titleId}
			PaperComponent={draggable ? PaperComponent : undefined}
		>
			<DialogTitle id={titleId} className={classes.title}>
				{title}
				<IconButton
					aria-label="Close"
					className={classes.closeButton}
					onClick={() => {
						onClose();
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>{content}</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onClose();
					}}
					size="small"
					variant="contained"
					// color="primary"
				>
					{confirmCB
						? intl.formatMessage({ id: 'GENERAL.Cancel' })
						: intl.formatMessage({ id: 'GENERAL.Close' })}
				</Button>
				{confirmCB && (
					<Button
						onClick={() => {
							confirmCB();
						}}
						size="small"
						variant="contained"
						color="primary"
					>
						{intl.formatMessage({ id: 'GENERAL.Confirm' })}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

MuiDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.node.isRequired,
	confirmCB: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	draggable: PropTypes.bool,
};

MuiDialog.defaultProps = { draggable: false };

export default MuiDialog;
