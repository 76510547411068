import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { toLocaleDate } from 'utils/generalHelper';

// intl lang
import { useIntl } from 'react-intl';

// CSS
const useStyles = makeStyles((theme) => ({
	unReadMsg: {
		fontWeight: 'bold',
		cursor: 'pointer',
	},
	readMsg: {
		fontWeight: 'normal',
		cursor: 'pointer',
	},
}));
function useColumnsConfig({
	handleShowLargePreview,
	// eslint-disable-next-line no-unused-vars
	// handlePatchMessageStatus,
}) {
	const classes = useStyles();
	const intl = useIntl();
	const columnsConfig = React.useMemo(() => {
		return [
			{
				Header: intl.formatMessage({
					id: 'pages.UserMessages.components.UserMessagesTable.TableHeaderSubject',
				}),
				accessor: 'subject',
				id: 'subject',
				Cell: function renderCell({ value, row }) {
					return row.original.isRead === false ? (
						<div
							className={classes.unReadMsg}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							{value}
						</div>
					) : (
						<div
							className={classes.readMsg}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							{value}
						</div>
					);
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.UserMessages.components.UserMessagesTable.TableHeaderSenderName',
				}),
				accessor: 'senderName',
				id: 'senderName',
				Cell: function renderCell({ value, row }) {
					return row.original.isRead === false ? (
						<div
							className={classes.unReadMsg}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							{value}
						</div>
					) : (
						<div
							className={classes.readMsg}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							{value}
						</div>
					);
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.UserMessages.components.UserMessagesTable.TableHeaderSenderEmail',
				}),
				accessor: 'senderEmail',
				id: 'senderEmail',
				Cell: function renderCell({ value, row }) {
					return row.original.isRead === false ? (
						<div
							className={classes.unReadMsg}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							{value}
						</div>
					) : (
						<div
							className={classes.readMsg}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							{value}
						</div>
					);
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.UserMessages.components.UserMessagesTable.TableHeaderDate',
				}),
				accessor: 'date',
				id: 'date',
				Cell: function renderCell({ value, row }) {
					return row.original.isRead === false ? (
						<div
							className={classes.unReadMsg}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							{value ? toLocaleDate(new Date(value)) : ''}
						</div>
					) : (
						<div
							className={classes.readMsg}
							onClick={(e) => handleShowLargePreview(row.original, e)}
						>
							{value ? toLocaleDate(new Date(value)) : ''}
						</div>
					);
				},
			},
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [classes.readMsg, classes.unReadMsg, handleShowLargePreview, intl]);

	return columnsConfig;
}
// parameters passed must be memorized
export default useColumnsConfig;
