import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Draggable from 'react-draggable';

// MUI components
import { Button, IconButton, Typography, Popper, Fade, Paper, Link } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

// intl lang
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	invalidFieldsPaper: {
		padding: theme.spacing(1),
		// position: 'relative',
		// margin: theme.spacing(1, 0),
		display: 'flex',
		flexDirection: 'column',
		// width: '100%',
		// height: '100%',
		maxHeight: 450,
		maxWidth: 750,
		minWidth: 500,
		// height: 450,
		// maxHeight: 'calc(100% - 64px)',
		// width: 'calc(100% - 64px)',
		overflow: 'auto',
	},
	invalidFieldsTitle: {
		flex: '0 0 auto',
		margin: 0,
		padding: theme.spacing(0.5, 1),
		paddingRight: 0,
		cursor: 'move',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	titleCloseButton: {
		// position: 'absolute',
		// right: theme.spacing(1),
		// top: theme.spacing(1),
		color: theme.palette.grey[500],
		flex: '0 0 auto',
	},
	invalidFieldsContent: {
		// display: 'flex',
		// overflow: 'auto',
		// width: '100%',
		// minHeight: 200,
		flex: '1 1 auto',
		padding: theme.spacing(1, 3),
		overflowY: 'auto',
	},
	invalidFieldContentDividers: {
		padding: theme.spacing(2, 3),
		borderTop: '1px solid rgba(0, 0, 0, 0.12)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	invalidFieldContentActions: {
		flex: '0 0 auto',
		display: 'flex',
		padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	// errorText: {
	// 	color: theme.palette.error.main,
	// },
	'@keyframes ring': theme.animations['@keyframes ring'],
	ring: {
		animation: '$ring 0.5s ease-in-out infinite both',
	},
}));

// following modifier is to make popper at center of screen,
// ref to: https://github.com/floating-ui/floating-ui/issues/642
const constPopperModifier = [
	{
		name: 'computeStyles',
		enabled: true,
		fn({ state }) {
			state.styles.popper = {
				...state.styles.popper,
				position: 'fixed',
				left: `${(window.innerWidth - state.rects.popper.width) / 2}px`,
				top: `${(window.innerHeight - state.rects.popper.height) / 2}px`,
			};

			return state;
		},
	},
];

function InvalidFieldsMUIPopper({
	handleClose,
	handleSelectField,
	handleOpenTemplateSettings,
	handleDeleteUnsupportedFieldsInVideoArtwork,
	invalidFields,
	popperProps,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	return (
		<Draggable handle={`.${classes.invalidFieldsTitle}`} bounds="parent">
			<Popper transition placement="bottom" {...popperProps} modifiers={constPopperModifier}>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper elevation={2} className={classes.invalidFieldsPaper}>
							<div className={classes.invalidFieldsTitle}>
								<Typography variant="h5">
									{intl.formatMessage({
										id: 'pages.Artwork.components.InvalidFieldsMUIPopper.InvalidFieldsTitleText',
									})}
								</Typography>
								<IconButton
									aria-label="Close"
									className={classes.titleCloseButton}
									// size="small"
									onClick={(e) => handleClose(e)}
								>
									<CloseIcon />
								</IconButton>
							</div>
							<div
								className={cx(classes.invalidFieldsContent, classes.invalidFieldContentDividers)}
							>
								{invalidFields.length > 0 ? (
									invalidFields.map((f) => (
										<div key={`invalid-field-${f.id}`}>
											<Link
												component="button"
												variant="body1"
												underline="always"
												color="primary"
												onClick={() => {
													if (f.id === 'templateSettings') {
														handleOpenTemplateSettings();
													} else if (f.id === 'unsupportedFieldsInVideoArtwork') {
														// delete unsupported fields in video artwork
														handleDeleteUnsupportedFieldsInVideoArtwork();
													} else {
														handleSelectField(f.id);
													}
												}}
											>
												{f.id === 'templateSettings'
													? `Template Settings`
													: f.id === 'unsupportedFieldsInVideoArtwork'
													? `Unsupported field(s) in video artwork. Click to delete`
													: `${f.name} (${f.groupName})`}
											</Link>
											<ul>
												{f.messages.map((msg, idx) => (
													<li key={`invalid-field-message-${idx}`}>
														<Typography variant="body2" component="span">
															{msg}
														</Typography>
													</li>
												))}
											</ul>
										</div>
									))
								) : (
									<Typography variant="body1" component={'div'}>
										{intl.formatMessage({
											id: 'pages.Artwork.components.InvalidFieldsMUIPopper.NoInvalidFieldsMsg',
										})}
									</Typography>
								)}
							</div>
							<div className={classes.invalidFieldContentActions}>
								<Button
									variant="contained"
									size="small"
									onClick={(e) => handleClose(e)}
									startIcon={<CloseIcon fontSize="small" />}
								>
									{intl.formatMessage({ id: 'GENERAL.Close' })}
								</Button>
							</div>
						</Paper>
					</Fade>
				)}
			</Popper>
		</Draggable>
	);
}

InvalidFieldsMUIPopper.propTypes = {
	popperProps: PropTypes.object.isRequired,
	invalidFields: PropTypes.array.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleSelectField: PropTypes.func.isRequired,
	handleOpenTemplateSettings: PropTypes.func.isRequired,
	handleDeleteUnsupportedFieldsInVideoArtwork: PropTypes.func.isRequired,
};

InvalidFieldsMUIPopper.defaultProps = {};
export default InvalidFieldsMUIPopper;
