import { tinycolor } from 'utils/libHelper';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as dateFnsLocales from 'date-fns/locale';

import { yellow } from '@mui/material/colors';

const primary = '#1976d2'; // '#536DFE'; //"#9c27b0";
const secondary = '#ed0c77'; // "#f50057" MUI DEFAULT; // #CE4976 // #DC004E
// const warning = '#ff9800';
const success = '#4caf50';
const info = '#00acc1';
const roseColor = '#e91e63';
const grayColor = '#999999';
const dangerColor = '#f44336';

const lightenRate = 7;
const darkenRate = 7;

const defaultStyles = {
	typography: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // '"Helvetica Neue",Helvetica,Arial,sans-serif',
		h1: {
			fontSize: '3rem',
		},
		h2: {
			fontSize: '2rem',
		},
		h3: {
			fontSize: '1.64rem',
		},
		h4: {
			fontSize: '1.5rem',
		},
		h5: {
			fontSize: '1.285rem',
		},
		h6: {
			fontSize: '1.142rem',
		},
	},
	palette: {
		primary: {
			main: primary,
			light: tinycolor(primary).lighten(lightenRate).toHexString(),
			dark: tinycolor(primary).darken(darkenRate).toHexString(),
		},
		secondary: {
			main: secondary,
			light: tinycolor(secondary).lighten(lightenRate).toHexString(),
			dark: tinycolor(secondary).darken(darkenRate).toHexString(),
			contrastText: '#FFFFFF',
		},
		warning: {
			main: yellow[500],
			light: yellow[300], //tinycolor(warning).lighten(lightenRate).toHexString(),
			dark: yellow[700], // tinycolor(warning).darken(darkenRate).toHexString(),
		},
		success: {
			main: success,
			light: tinycolor(success).lighten(lightenRate).toHexString(),
			dark: tinycolor(success).darken(darkenRate).toHexString(),
		},
		info: {
			main: info,
			light: tinycolor(info).lighten(lightenRate).toHexString(),
			dark: tinycolor(info).darken(darkenRate).toHexString(),
		},
		danger: {
			main: dangerColor,
			light: tinycolor(dangerColor).lighten(lightenRate).toHexString(),
			dark: tinycolor(dangerColor).darken(darkenRate).toHexString(),
		},
		gray: {
			main: grayColor,
			light: tinycolor(grayColor).lighten(lightenRate).toHexString(),
			dark: tinycolor(grayColor).darken(darkenRate).toHexString(),
		},
		lightgrey: {
			main: 'rgb(179,179,179)',
		},
		rose: {
			main: roseColor,
			light: tinycolor(roseColor).lighten(lightenRate).toHexString(),
			dark: tinycolor(roseColor).darken(darkenRate).toHexString(),
		},
		text: {
			primary: '#4A4A4A',
			secondary: '#6E6E6E',
			hint: '#B9B9B9',
		},
		background: {
			default: '#fafafa', // MUI default: #fafafa; other colors: #EEEEEE, #F6F7FF
			light: '#F3F5FF',
			paper: '#FFFFFF',
		},
	},
	primaryBoxShadow: {
		boxShadow:
			'0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
	},
	infoBoxShadow: {
		boxShadow:
			'0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)',
	},
	successBoxShadow: {
		boxShadow:
			'0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)',
	},
	warningBoxShadow: {
		boxShadow:
			'0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)',
	},
	dangerBoxShadow: {
		boxShadow:
			'0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)',
	},
	roseBoxShadow: {
		boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					fontSize: '0.875rem',
					lineHeight: 1.43,
					letterSpacing: '0.01071em',
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				input: {
					// TODO: remove it. Having it here is to fix conflict to bootstrap in lassp app
					left: 'auto',
				},
			},
		},
		MuiButton: {
			defaultProps: {
				// The props to change the default for.
				variant: 'contained',
			},
			styleOverrides: {
				root: ({ ownerState }) => {
					if (ownerState.color === 'inherit' && ownerState.variant === 'text')
						return {
							'&:hover': {
								backgroundColor: `rgba(74, 74, 74, 0.14)`,
							},
						};
					if (ownerState.color === 'lightgrey')
						return {
							color: 'rgb(51,51,51)',
							backgroundImage: 'linear-gradient(to bottom, #ffffff, #e6e6e6)',
							backgroundRepeat: 'repeat-x',
							boxShadow: 'inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05)',
							'&:hover': {
								backgroundImage: 'none',
								backgroundColor: '#e6e6e6',
							},
						};
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					fontSize: '0.9rem',
				},
				input: ({ ownerState }) => {
					if (!ownerState.multiline)
						return {
							padding: '9px 14px',
						};
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& input': { minHeight: 30 }, // this is to fix css conflict with Lasso app
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: () => {
					return {
						fontSize: 'inherit',
						'&:not([data-shrink="true"])': {
							transform: `translate(14px, 9px) scale(1)`,
							// transform: !inputValue
							// 	? `translate(14px, 9px) scale(1)`
							// 	: `translate(14px, -7px) scale(0.75)`,
						},
						'&.MuiInputLabel-outlined[data-shrink="true"]': {
							transform: `translate(14px, -7px) scale(0.75)`,
						},
					};
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: `rgba(74, 74, 74, 0.15)`,
					},
				},
			},
		},
		MuiLink: {
			defaultProps: {
				// The props to change the default for.
				underline: 'hover',
			},
			styleOverrides: {
				root: {
					cursor: 'pointer',
				},
			},
		},
		MuiLocalizationProvider: {
			defaultProps: {
				dateAdapter: AdapterDateFns,
				adapterLocale: dateFnsLocales[navigator.language?.replaceAll('-', '')],
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'& input': {
						width: '100% !important', // this is to fix css conflict with Lasso app because the index.css in public folder
					},
				},
			},
		},
	},
};

export default defaultStyles;
