const animations = {
	Entrance: {
		'@keyframes bounce-in-bck': {
			'0%': {
				transform: 'scale(7)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '0',
			},
			'38%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
				opacity: '1',
			},
			'55%': {
				transform: 'scale(1.5)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'72%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'81%': {
				transform: 'scale(1.24)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'89%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'95%': {
				transform: 'scale(1.04)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'100%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
		},
		'@keyframes bounce-in-bottom': {
			'0%': {
				transform: 'translateY(500px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '0',
			},
			'38%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
				opacity: '1',
			},
			'55%': {
				transform: 'translateY(65px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'72%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'81%': {
				transform: 'translateY(28px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'90%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'95%': {
				transform: 'translateY(8px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'100%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
		},
		'@keyframes bounce-in-fwd': {
			'0%': {
				transform: 'scale(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '0',
			},
			'38%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
				opacity: '1',
			},
			'55%': {
				transform: 'scale(.7)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'72%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'81%': {
				transform: 'scale(.84)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'89%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'95%': {
				transform: 'scale(.95)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'100%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
		},
		'@keyframes bounce-in-left': {
			'0%': {
				transform: 'translateX(-600px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '0',
			},
			'38%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
				opacity: '1',
			},
			'55%': {
				transform: 'translateX(-68px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'72%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'81%': {
				transform: 'translateX(-28px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'90%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'95%': {
				transform: 'translateX(-8px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'100%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
		},
		'@keyframes bounce-in-right': {
			'0%': {
				transform: 'translateX(600px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '0',
			},
			'38%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
				opacity: '1',
			},
			'55%': {
				transform: 'translateX(68px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'72%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'81%': {
				transform: 'translateX(32px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'90%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'95%': {
				transform: 'translateX(8px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'100%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
		},
		'@keyframes bounce-in-top': {
			'0%': {
				transform: 'translateY(-500px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '0',
			},
			'38%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
				opacity: '1',
			},
			'55%': {
				transform: 'translateY(-65px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'72%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'81%': {
				transform: 'translateY(-28px)',
				transformOrigin: 'center',
			},
			'90%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'95%': {
				transform: 'translateY(-8px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'100%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
		},
		'@keyframes fade-in': {
			'0%': {
				opacity: '0',
			},
			'100%': {
				opacity: '1',
			},
		},
		'@keyframes puff-in-center': {
			'0%': {
				transform: 'scale(2)',
				transformOrigin: 'center',
				filter: 'blur(2px)',
				opacity: '0',
			},
			'100%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				filter: 'blur(0)',
				opacity: '1',
			},
		},
		'@keyframes roll-in-left': {
			'0%': {
				transform: 'translateX(-800px) rotate(-540deg)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'translateX(0) rotate(0deg)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes roll-in-right': {
			'0%': {
				transform: 'translateX(800px) rotate(540deg)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'translateX(0) rotate(0deg)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes scale-in-center': {
			'0%': {
				transform: 'scale(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes slide-in-bck-center': {
			'0%': {
				transform: 'translateZ(600px)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes slide-in-bottom': {
			'0%': {
				transform: 'translateY(1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes slide-in-fwd-center': {
			'0%': {
				transform: 'translateZ(-1400px)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes slide-in-left': {
			'0%': {
				transform: 'translateX(-1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes slide-in-right': {
			'0%': {
				transform: 'translateX(1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes slide-in-top': {
			'0%': {
				transform: 'translateY(-1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes swing-in-top-fwd': {
			'0%': {
				transform: 'rotateX(-100deg)',
				transformOrigin: 'top',
				opacity: '0',
			},
			'100%': {
				transform: 'rotateX(0deg)',
				transformOrigin: 'top',
				opacity: '1',
			},
		},
		'@keyframes swirl-in-bck': {
			'0%': {
				transform: 'rotate(540deg) scale(5)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'rotate(0) scale(1)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes swirl-in-fwd': {
			'0%': {
				transform: 'rotate(-540deg) scale(0)',
				transformOrigin: 'center',
				opacity: '0',
			},
			'100%': {
				transform: 'rotate(0) scale(1)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
	},
	'Exit (Corresponding to above)': {
		'@keyframes bounce-out-bck': {
			'0%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'5%': {
				transform: 'translateZ(-100px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'15%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'25%': {
				transform: 'translateZ(-110px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'38%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'52%': {
				transform: 'translateZ(-200px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'70%': {
				transform: 'translateZ(0) scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'85%': {
				opacity: '1',
			},
			'100%': {
				transform: 'translateZ(-900px) scale(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '0',
			},
		},
		'@keyframes bounce-out-bottom': {
			'0%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'5%': {
				transform: 'translateY(30px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'15%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'25%': {
				transform: 'translateY(38px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'38%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'52%': {
				transform: 'translateY(75px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'70%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'85%': {
				opacity: '1',
			},
			'100%': {
				transform: 'translateY(800px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes bounce-out-fwd': {
			'0%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'5%': {
				transform: 'translateZ(90px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'15%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'25%': {
				transform: 'translateZ(95px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'38%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'52%': {
				transform: 'translateZ(150px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'70%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'85%': {
				opacity: '1',
			},
			'100%': {
				transform: 'translateZ(500px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '0',
			},
		},
		'@keyframes bounce-out-left': {
			'0%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'5%': {
				transform: 'translateX(-30px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'15%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'25%': {
				transform: 'translateX(-38px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'38%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'52%': {
				transform: 'translateX(-80px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'70%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'85%': {
				opacity: '1',
			},
			'100%': {
				transform: 'translateX(-1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes bounce-out-right': {
			'0%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'5%': {
				transform: 'translateX(30px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'15%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'25%': {
				transform: 'translateX(38px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'38%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'52%': {
				transform: 'translateX(80px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'65%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'85%': {
				opacity: '1',
			},
			'100%': {
				transform: 'translateX(1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes bounce-out-top': {
			'0%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'5%': {
				transform: 'translateY(-30px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'15%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'25%': {
				transform: 'translateY(-38px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'38%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'52%': {
				transform: 'translateY(-75px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'70%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'85%': {
				opacity: '1',
			},
			'100%': {
				transform: 'translateY(-800px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes fade-out': {
			'0%': {
				opacity: '1',
			},
			'100%': {
				opacity: '0',
			},
		},
		'@keyframes puff-out-center': {
			'0%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				filter: 'blur(0)',
				opacity: '1',
			},
			'100%': {
				transform: 'scale(2)',
				transformOrigin: 'center',
				filter: 'blur(2px)',
				opacity: '0',
			},
		},
		'@keyframes roll-out-left': {
			'0%': {
				transform: 'translateX(0) rotate(0deg)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'translateX(-1000px) rotate(-540deg)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes roll-out-right': {
			'0%': {
				transform: 'translateX(0) rotate(0deg)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'translateX(1000px) rotate(540deg)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes scale-out-center': {
			'0%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'scale(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
		},
		'@keyframes slide-out-bck-center': {
			'0%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'translateZ(-1100px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes slide-out-bottom': {
			'0%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'translateY(1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes slide-out-fwd-center': {
			'0%': {
				transform: 'translateZ(1)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'translateZ(600px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes slide-out-left': {
			'0%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'translateX(-1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes slide-out-right': {
			'0%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'translateX(1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes slide-out-top': {
			'0%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'translateY(-1000px)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes swing-out-top-fwd': {
			'0%': {
				transform: 'rotateX(0deg)',
				transformOrigin: 'top',
				opacity: '1',
			},
			'100%': {
				transform: 'rotateX(70deg)',
				transformOrigin: 'top',
				opacity: '0',
			},
		},
		'@keyframes swirl-out-bck': {
			'0%': {
				transform: 'rotate(0) scale(1)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'rotate(-540deg) scale(0)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
		'@keyframes swirl-out-fwd': {
			'0%': {
				transform: 'rotate(0) scale(1)',
				transformOrigin: 'center',
				opacity: '1',
			},
			'100%': {
				transform: 'rotate(540deg) scale(5)',
				transformOrigin: 'center',
				opacity: '0',
			},
		},
	},
	'Text Entrance': {
		'@keyframes focus-in-contract-bck': {
			'0%': {
				letterSpacing: '1em',
				transform: 'translateZ(300px)',
				transformOrigin: 'center',
				filter: 'blur(12px)',
				opacity: '0',
			},
			'100%': {
				transform: 'translateZ(12px)',
				transformOrigin: 'center',
				filter: 'blur(0)',
				opacity: '1',
			},
		},
		'@keyframes focus-in-contract': {
			'0%': {
				letterSpacing: '1em',
				filter: 'blur(12px)',
				opacity: '0',
			},
			'100%': {
				filter: 'blur(0)',
				opacity: '1',
			},
		},
		'@keyframes text-focus-in': {
			'0%': {
				filter: 'blur(12px)',
				opacity: '0',
			},
			'100%': {
				filter: 'blur(0)',
				opacity: '1',
			},
		},
		'@keyframes tracking-in-contract': {
			'0%': {
				letterSpacing: '1em',
				opacity: '0',
			},
			'40%': {
				opacity: '.6',
			},
			'100%': {
				letterSpacing: 'normal',
				opacity: '1',
			},
		},
		'@keyframes tracking-in-expand': {
			'0%': {
				letterSpacing: '-.5em',
				opacity: '0',
			},
			'40%': {
				opacity: '.6',
			},
			'100%': {
				opacity: '1',
			},
		},
	},
	'Text Exit (Corresponding to Above)': {
		'@keyframes blur-out-expand-fwd': {
			'0%': {
				transform: 'translateZ(0)',
				transformOrigin: 'center',
				filter: 'blur(.01)',
			},
			'100%': {
				letterSpacing: '1em',
				transform: 'translateZ(300px)',
				transformOrigin: 'center',
				filter: 'blur(12px) opacity(0)',
			},
		},
		'@keyframes blur-out-expand': {
			'0%': {
				filter: 'blur(.01)',
			},
			'100%': {
				letterSpacing: '1em',
				filter: 'blur(12px) opacity(0)',
			},
		},
		'@keyframes text-blur-out': {
			'0%': {
				filter: 'blur(.01)',
			},
			'100%': {
				filter: 'blur(12px) opacity(0)',
			},
		},
		'@keyframes tracking-out-expand': {
			'0%': {
				opacity: '1',
			},
			'60%': {
				opacity: '.8',
			},
			'100%': {
				letterSpacing: '1em',
				opacity: '0',
			},
		},
		'@keyframes tracking-out-contract': {
			'0%': {
				opacity: '1',
			},
			'50%': {
				opacity: '1',
			},
			'100%': {
				letterSpacing: '-.5em',
				opacity: '0',
			},
		},
	},
	Attention: {
		'@keyframes bounce-top': {
			'0%': {
				transform: 'translateY(-45px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
				opacity: '1',
			},
			'24%': {
				opacity: '1',
			},
			'40%': {
				transform: 'translateY(-24px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'65%': {
				transform: 'translateY(-12px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'82%': {
				transform: 'translateY(-6px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'93%': {
				transform: 'translateY(-4px)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'25%, 55%, 75%, 87%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'100%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
				opacity: '1',
			},
		},
		'@keyframes heartbeat': {
			from: {
				transform: 'scale(1)',
				transformOrigin: 'center center',
				animationTimingFunction: 'ease-out',
			},
			'10%': {
				transform: 'scale(.91)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'17%': {
				transform: 'scale(.98)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
			'33%': {
				transform: 'scale(.87)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-in',
			},
			'45%': {
				transform: 'scale(1)',
				transformOrigin: 'center',
				animationTimingFunction: 'ease-out',
			},
		},
		'@keyframes jello-diagonal-1': {
			'0%': {
				transform: 'skew(0deg 0deg)',
				transformOrigin: 'center',
			},
			'30%': {
				transform: 'skew(25deg 25deg)',
				transformOrigin: 'center',
			},
			'40%': {
				transform: 'skew(-15deg,-15deg)',
				transformOrigin: 'center',
			},
			'50%': {
				transform: 'skew(15deg,15deg)',
				transformOrigin: 'center',
			},
			'65%': {
				transform: 'skew(-5deg,-5deg)',
				transformOrigin: 'center',
			},
			'75%': {
				transform: 'skew(5deg,5deg)',
				transformOrigin: 'center',
			},
			'100%': {
				transform: 'skew(0deg 0deg)',
				transformOrigin: 'center',
			},
		},
		'@keyframes jello-diagonal-2': {
			'0%': {
				transform: 'skew(0deg 0deg)',
				transformOrigin: 'center',
			},
			'30%': {
				transform: 'skew(-25deg -25deg)',
				transformOrigin: 'center',
			},
			'40%': {
				transform: 'skew(15deg,15deg)',
				transformOrigin: 'center',
			},
			'50%': {
				transform: 'skew(-15deg,-15deg)',
				transformOrigin: 'center',
			},
			'65%': {
				transform: 'skew(5deg,5deg)',
				transformOrigin: 'center',
			},
			'75%': {
				transform: 'skew(-5deg,-5deg)',
				transformOrigin: 'center',
			},
			'100%': {
				transform: 'skew(0deg 0deg)',
				transformOrigin: 'center',
			},
		},
		'@keyframes jello-horizontal': {
			'0%': {
				transform: 'scale3d(1,1,1)',
				transformOrigin: 'center',
			},
			'30%': {
				transform: 'scale3d(1.25,.75,1)',
				transformOrigin: 'center',
			},
			'40%': {
				transform: 'scale3d(.75,1.25,1)',
				transformOrigin: 'center',
			},
			'50%': {
				transform: 'scale3d(1.15,.85,1)',
				transformOrigin: 'center',
			},
			'65%': {
				transform: 'scale3d(.95,1.05,1)',
				transformOrigin: 'center',
			},
			'75%': {
				transform: 'scale3d(1.05,.95,1)',
				transformOrigin: 'center',
			},
			'100%': {
				transform: 'scale3d(1,1,1)',
				transformOrigin: 'center',
			},
		},
		'@keyframes jello-vertical': {
			'0%': {
				transform: 'scale3d(1,1,1)',
				transformOrigin: 'center',
			},
			'30%': {
				transform: 'scale3d(.75,1.25,1)',
				transformOrigin: 'center',
			},
			'40%': {
				transform: 'scale3d(1.25,.75,1)',
				transformOrigin: 'center',
			},
			'50%': {
				transform: 'scale3d(.85,1.15,1)',
				transformOrigin: 'center',
			},
			'65%': {
				transform: 'scale3d(1.05,.95,1)',
				transformOrigin: 'center',
			},
			'75%': {
				transform: 'scale3d(.95,1.05,1)',
				transformOrigin: 'center',
			},
			'100%': {
				transform: 'scale3d(1,1,1)',
				transformOrigin: 'center',
			},
		},
		'@keyframes shake-horizontal': {
			'0%, 100%': {
				transform: 'translateX(0)',
				transformOrigin: 'center',
			},
			'10%, 30%, 50%, 70%': {
				transform: 'translateX(-10px)',
				transformOrigin: 'center',
			},
			'20%, 40%, 60%': {
				transform: 'translateX(10px)',
				transformOrigin: 'center',
			},
			'80%': {
				transform: 'translateX(8px)',
				transformOrigin: 'center',
			},
			'90%': {
				transform: 'translateX(-8px)',
				transformOrigin: 'center',
			},
		},
		'@keyframes shake-vertical': {
			'0%, 100%': {
				transform: 'translateY(0)',
				transformOrigin: 'center',
			},
			'10%, 30%, 50%, 70%': {
				transform: 'translateY(-8px)',
				transformOrigin: 'center',
			},
			'20%, 40%, 60%': {
				transform: 'translateY(8px)',
				transformOrigin: 'center',
			},
			'80%': {
				transform: 'translateY(6.4px)',
				transformOrigin: 'center',
			},
			'90%': {
				transform: 'translateY(-6.4px)',
				transformOrigin: 'center',
			},
		},
		'@keyframes vibrate-1': {
			'0%': {
				transform: 'translate(0)',
				transformOrigin: 'center',
			},
			'20%': {
				transform: 'translate(-2px,2px)',
				transformOrigin: 'center',
			},
			'40%': {
				transform: 'translate(-2px,-2px)',
				transformOrigin: 'center',
			},
			'60%': {
				transform: 'translate(2px,2px)',
				transformOrigin: 'center',
			},
			'80%': {
				transform: 'translate(2px,-2px)',
				transformOrigin: 'center',
			},
			'100%': {
				transform: 'translate(0)',
				transformOrigin: 'center',
			},
		},
		'@keyframes kenburns-top': {
			'0%': {
				transform: 'scale(1) translateY(0)',
				transformOrigin: '50% 16%',
			},
			'100%': {
				transform: 'scale(1.25) translateY(-15px)',
				transformOrigin: 'top',
			},
		},
	},
	'Text Attention': {
		'@keyframes text-pop-up-top': {
			'0%': {
				transform: 'translateY(0)',
				transformOrigin: '50% 50%',
				textShadow: 'none',
			},
			'100%': {
				transform: 'translateY(-50px)',
				transformOrigin: '50% 50%',
				textShadow:
					'0 1px 0 #ccc,0 2px 0 #ccc,0 3px 0 #ccc,0 4px 0 #ccc,0 5px 0 #ccc,0 6px 0 #ccc,0 7px 0 #ccc,0 8px 0 #ccc,0 9px 0 #ccc,0 50px 30px rgba(0,0,0,.3)',
			},
		},
		'@keyframes text-shadow-drop-center': {
			'0%': {
				textShadow: '0 0 0 transparent',
			},
			'100%': {
				textShadow: '0 0 18px rgba(0,0,0,.35)',
			},
		},
	},
};

export default animations;
