import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';

import { Folder as FolderIcon } from '@mui/icons-material';

import { ROUTES_PATH_MYFILES, ROUTES_PATH_MYFILES_MEDIAFILE_LIST } from 'routes';
const MyFilesList = loadable(() => import('./components/MyFiles/MyFilesList/MyFilesList'), {
	fallback: <Loading size={50} />,
});
const MyFilesMediaFileList = loadable(
	() => import('./components/MyFilesMediaFile/MediafileList/MediafileList'),
	{
		fallback: <Loading size={50} />,
	}
);

const MyFilesRoutes = [
	{
		path: ROUTES_PATH_MYFILES,
		sidebarName: <FormattedMessage id="pages.MyFiles.Routes.MyFilesTitle" />,
		sidebarIcon: FolderIcon,
		component: MyFilesList,
		noBreadcrumbs: false,
		showCounter: true,
		disabledDomains: [''], // domains that are disabled to access the route
		children: [
			{
				path: ROUTES_PATH_MYFILES_MEDIAFILE_LIST,
				sidebarName: '',
				sidebarIcon: null,
				component: MyFilesMediaFileList,
			},
		],
	},
];
export default MyFilesRoutes;
