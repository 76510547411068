import { v1 as _uuidv1 } from 'uuid';
// ##############################
// General Helpers
//
// You may import npm modules, but
// ** DO NOT import another helper file here **
//
// Add following functions in this file:
// 	- the util functions that do not need to import another library
//  - a function that are used for general purpose (can import npm package if that package is small)
//
// #############################

export const genUUID = () => {
	// timestamp-based uuid as we only need it to be unique locally
	return _uuidv1();
};

// round decimals. the function returns the value of a number rounded to the nearest decimal
export const roundDecimals = (value, decimals = 0) => {
	if (decimals <= 0) {
		return Math.round(value);
	} else {
		return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
	}
};

// floor decimals. The function returns the largest decimal less than or equal to a given number
export const floorDecimals = (value, decimals = 0) => {
	if (decimals <= 0) {
		return Math.floor(value);
	} else {
		return Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
	}
};

/**
 * get date format by locale
 * @param {string} locale Optional. If not specified, use navigator.language as locale
 * @returns {string} e.g. dd/MM/yyyy, yyyy-MM-dd
 */
export const getDateFormatString = (locale = navigator.language) => {
	const formatObj = new Intl.DateTimeFormat(locale || navigator.language).formatToParts(new Date());

	return formatObj
		.map((obj) => {
			switch (obj.type) {
				case 'day':
					return 'dd';
				case 'month':
					return 'MM';
				case 'year':
					return 'yyyy';
				default:
					return obj.value;
			}
		})
		.join('');
};

/**
 * convert Date to locale date string
 * @param {Date} date
 * @param {object} options DateTimeFormat#options. ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#options
 * @returns {string}
 */
export const toLocaleDate = (date, options = {}) => {
	return date.toLocaleDateString(window.navigator.language, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		...options,
	});
};

/**
 * convert Date to locale date-time string
 * @param {Date} date
 * @param {object} options DateTimeFormat#options. ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#options
 * @returns {string}
 */
export const toLocaleDateTime = (date, options = {}) => {
	return date.toLocaleString(window.navigator.language, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		...options,
	});
};

// function to get random string
export const genRandomStr = (number) => {
	var text = '';
	var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (var i = 0; i < number; i++)
		text += possible.charAt(Math.floor(Math.random() * possible.length));

	return text;
};

/**
 * check whether or not the given url is a web url
 * @param {string} url
 * @returns true/false
 */
export const isWebUrl = (url) => {
	return new RegExp(/^\s*https?:\/\/.*?$/gim).test(url);
};

/**
 * check whether or not the given url is a blob url
 * @param {string} url
 * @returns true/false
 */
export const isBlobUrl = (url) => {
	return new RegExp(/^\s*blob:https?:\/\/.*?$/gim).test(url || '');
};

/**
 * Whether a value is `null` or `undefined`.
 * @param {any} val
 * @returns {boolean}
 */
export const isNullish = (val) => {
	return val === null || val === undefined;
};
