import config from '../../config';
import {
	FILEMANAGER_SEARCH_MEDIAFILE_COUNT,
	FILEMANAGER_SEARCH_MEDIAFILES_COUNT_SUCCESS,
	FILEMANAGER_SEARCH_MEDIAFILES_COUNT_FAIL,
} from 'redux/actions';
import { endOfDay, startOfDay } from 'date-fns';
const filemanagerAPIBaseUrl = config.filemanagerAPI.baseUrl;
const authHeaderKey = config.filemanagerAPI.authHeaderKey;

export const reSyncCounter = () => (dispatch, getState) => {
	let reqUrl = `${filemanagerAPIBaseUrl}/mediafiles`;
	const authState = getState().authentication;
	let dateObj = {
		startDate: startOfDay(new Date()),
		endDate: endOfDay(new Date()),
	};
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let filters = {
		createdDatetimeFrom: dateObj.startDate.toISOString(),
		createdDatetimeTo: dateObj.endDate.toISOString(),
		createdByUIDs: [getState().authentication.userId],
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: {
			domain: authState.domainName,
			uid: authState.userId,
			// ...(opts.queryParams || {}),
		},
		data: { filters: filters },
		timeout: 90000,
	};

	return new Promise(() => {
		dispatch({
			types: [
				FILEMANAGER_SEARCH_MEDIAFILE_COUNT,
				FILEMANAGER_SEARCH_MEDIAFILES_COUNT_SUCCESS,
				FILEMANAGER_SEARCH_MEDIAFILES_COUNT_FAIL,
			],
			promise: (client) => {
				return client.request(axiosConfig);
			},
			// afterSuccess: ({ dispatch, getState, result, error }) => {
			// 	if (error) return rej(error);
			// 	else {
			// 		res(result);
			// 	}
			// },
		});
	});
};
