import React from 'react';

import { /* DesignContext, */ ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { convertHtmlAttrToReact } from 'utils/libHelper';
import { prepareTextField, getFormatNumBaselineShift } from 'utils/artwork/artFieldsCreator';
import { createAnimationOfFieldInVideoArtwork } from 'utils/artwork/artUtilsWebUI';
import { dyForAlphabeticOnTopVerAlign } from 'utils/artwork/constants';

const useStyles = makeStyles((theme) => ({
	pauseAnimation: {
		animationPlayState: 'paused !important',
	},
}));

function Text({
	field,
	fieldPreviewData,
	animationDelay,
	allowAnimation,
	pausingAnimation,
	isVideoArtwork,
	...rest
}) {
	// const designContext = React.useContext(DesignContext);
	const classes = useStyles();
	// const intl = useIntl();
	// const horAlign = fieldPreviewData.horizontalAlign || field.textHorizontalAlign;
	// const verAlign = fieldPreviewData.verticalAlign || field.textVerticalAlign;
	// // TODO: don't use field.name as the output text (using it now is for easy testing)
	// let originalText = (fieldPreviewData.value || field.defaultValue || field.name || '').trim();
	// console.log(`Render text field - ${field.name}`);
	// // let textLines = originalText;
	// if (!originalText) return null; // no text string, nothing to display

	const textData = prepareTextField({
		mode: 'preview',
		inputData: fieldPreviewData,
		field,
		dataForExport: {},
		CONSTANTS: { placeholderSameAsText: ART_VARIABLES.placeholderSameAsText },
	});
	if (!textData) return null;
	let {
		// isSingleLine,
		isNumberField,
		// numberTextFontname,
		currencyFont,
		currencyStr,
		euroStr,
		centStr,
		// formatedText, // this is array of lines of text
		verAlign, // vertical alignment of the text field
		textAttrs, // attributes of <text>
		textScale, // scale of <text>, apply it to its parent <g> gContent
		tspanArray, // array of <tspan>. [{value: tspanString, attrs: { ...tspanAttrs }}, ...]
		alignmentAttrs, // alignment attributes of <text>. {x: NUMBER, y: NUMBER, 'text-anchor': 'xxx', 'dominant-baseline': 'xxxx'}
		dyFirstLine, // first line (<tspan>) vertical shift (used when formatNumber is false)
		lineHeight, // user desinged line height, in 'em'
		bBox, // bounding box of text and children tspan inside text
		hasShadow, // indicate the field has shadow or not
	} = textData;

	const noAnimationStyle = `0s ease 0s 1 normal none running none`;
	// in case of video artwork, invlid fields are blocked in SVGPreview Comp, so it is safe to use field.insertionOnVideo
	const animationEntranceStyle = isVideoArtwork
		? createAnimationOfFieldInVideoArtwork(field)
		: field.animation.entrance
		? `${field.animation.entrance} ${
				field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
		  }s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${
				animationDelay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
		  }s both`
		: noAnimationStyle;
	// const animationExitStyle = field.animation.exit
	// 	? `${field.animation.exit} ${field.animation.duration ||
	// 			1}s cubic-bezier(0.250, 0.460, 0.450, 0.940) ${0}s both`
	// 	: noAnimationStyle;
	const strikeThroughLineRotateFactor = 0.5;

	const TextComp = ({ ...rest }) => (
		<text {...convertHtmlAttrToReact(textAttrs)} {...rest}>
			{tspanArray.map((tspanItem, idx, array) => {
				if (!isNumberField) {
					if (!tspanItem.value) return null;
					// NOTE. We can't use zero-width unicode (\u200B) for empty line as it will be a dot in the generated PDF
					return (
						<tspan
							key={`${tspanItem.value}${idx}`}
							{...convertHtmlAttrToReact(tspanItem.attrs)}
							x={alignmentAttrs.x}
							dy={
								idx === 0
									? `${dyFirstLine}em`
									: `${
											lineHeight *
											array.reduce((accu, item, index) => {
												if (index < idx && item.value) {
													accu = 1;
												}
												if (index < idx && !item.value) {
													accu = accu + 1;
												}
												return accu;
											}, 1)
									  }em`
							}
						>
							{tspanItem.value}
						</tspan>
					);
				} else {
					// it is number text field, single line text
					let currencyComp = (
						<tspan
							// currency part
							x={
								field.formatNumberStyle.currencyPosition === 'leading' && euroStr
									? alignmentAttrs.x
									: undefined
							}
							dy={
								// Because we use Alphabetic in dominant-baseline when verAlign = 'top', hence, we need to move down the text by using dy=Xem
								verAlign === 'top' &&
								euroStr &&
								field.formatNumberStyle.currencyPosition === 'leading'
									? `${
											(1 / field.formatNumberStyle.currencyFontScale) * dyForAlphabeticOnTopVerAlign
									  }em`
									: undefined
							}
							{...convertHtmlAttrToReact(tspanItem.attrs)}
							// alignmentBaseline="baseline"
							// alignmentBaseline={
							// 	field.formatNumberStyle.currencyVerticalAlign === 'top'
							// 		? 'hanging'
							// 		: field.formatNumberStyle.currencyVerticalAlign === 'middle'
							// 		? 'middle'
							// 		: 'baseline'
							// }
							baselineShift={getFormatNumBaselineShift(
								verAlign,
								field.formatNumberStyle.currencyVerticalAlign,
								field.formatNumberStyle.currencyFontScale
							)}
							fontSize={field.formatNumberStyle.currencyFontScale + 'em'}
							style={currencyFont ? { fontFamily: `"${currencyFont}"` } : undefined}
							// {...props}
						>
							{currencyStr}
						</tspan>
					);
					let euroComp = (
						<tspan
							// euro part
							{...convertHtmlAttrToReact(tspanItem.attrs)}
							x={
								field.formatNumberStyle.currencyPosition !== 'leading'
									? alignmentAttrs.x
									: undefined
							}
							dy={
								// Because we use Alphabetic in dominant-baseline when verAlign = 'top', hence, we need to move down the text by using dy=Xem
								verAlign === 'top' &&
								euroStr &&
								field.formatNumberStyle.currencyPosition !== 'leading'
									? `${dyForAlphabeticOnTopVerAlign}em`
									: undefined
							}
							// alignmentBaseline={
							// 	verAlign === 'top'
							// 		? 'hanging'
							// 		: verAlign === 'middle'
							// 		? 'middle'
							// 		: 'baseline'
							// }
							// alignmentBaseline="auto"
							// fontSize={_fontSize + 'pt'}
							// {...props}
						>
							{euroStr}
						</tspan>
					);
					let centComp = (
						<tspan
							// cent part
							{...convertHtmlAttrToReact(tspanItem.attrs)}
							x={!euroStr ? alignmentAttrs.x : undefined}
							dy={
								// Because we use Alphabetic in dominant-baseline when verAlign = 'top', hence, we need to move down the text by using dy=Xem
								// NB: when there is only cent & currency, we ignore cent font scale and display cent at original size (100%)
								verAlign === 'top' && !euroStr
									? `${
											(1 / (!euroStr ? 1 : field.formatNumberStyle.centFontScale)) *
											dyForAlphabeticOnTopVerAlign
									  }em`
									: undefined
							}
							// alignmentBaseline="baseline"
							// alignmentBaseline={
							// 	field.formatNumberStyle.centVerticalAlign === 'top'
							// 		? 'hanging'
							// 		: field.formatNumberStyle.centVerticalAlign === 'middle'
							// 		? 'middle'
							// 		: 'baseline'
							// }
							baselineShift={getFormatNumBaselineShift(
								verAlign,
								field.formatNumberStyle.centVerticalAlign,
								!euroStr ? 1 : field.formatNumberStyle.centFontScale
							)}
							fontSize={(!euroStr ? 1 : field.formatNumberStyle.centFontScale) + 'em'}
							// {...props}
						>
							{centStr}
						</tspan>
					);
					if (!euroStr) {
						// only cent to display
						return (
							<React.Fragment
								key={`${tspanItem.value}${field.formatNumberStyle.currencyVerticalAlign}${field.formatNumberStyle.centVerticalAlign}${idx}`}
							>
								{centComp}
								{currencyComp}
							</React.Fragment>
						);
					} else {
						return (
							<React.Fragment
								key={`${tspanItem.value}${field.formatNumberStyle.currencyVerticalAlign}${field.formatNumberStyle.centVerticalAlign}${idx}`}
							>
								{field.formatNumberStyle.currencyPosition === 'leading' ? currencyComp : null}
								{euroComp}
								{centComp}
								{field.formatNumberStyle.currencyPosition !== 'leading' ? currencyComp : null}
							</React.Fragment>
						);
					}
				}
			})}
		</text>
	);

	return (
		<svg
			x={field.position.left}
			y={field.position.top}
			width={field.position.width}
			height={field.position.height}
			overflow={'visible'}
		>
			{/** To prevent the filter shadow gets cut off, we extent filter region by 50% hor & ver */}
			{hasShadow && (
				<filter
					id={'shadow-' + field.id}
					x="-50%"
					y="-50%"
					width="200%"
					height="200%"
					filterUnits="userSpaceOnUse"
				>
					<feGaussianBlur stdDeviation={field.shadowBlurRadius || 0} result="shadow" />
					<feOffset dx={field.shadowHorOffset || 0} dy={field.shadowVerOffset || 0} />
				</filter>
			)}
			<g
				className={cx({ [classes.pauseAnimation]: pausingAnimation })}
				style={{
					animation: allowAnimation ? animationEntranceStyle : noAnimationStyle,
					opacity: isVideoArtwork && !allowAnimation ? 0 : undefined, // allowAnimation is used to control replay of animation/video, there is about 100ms delay when replay animation/video, so for videoArtwork, we hide text preview when replay was just clicked but before replay actually begins
				}}
			>
				{/* Somehow, "transform" not working when together with "style" in the same <g> */}
				<g
					transform={`rotate(${field.position.angle}, ${field.position.width / 2}, ${
						field.position.height / 2
					}) scale(${textScale})`}
				>
					{hasShadow && (
						<TextComp
							filter={`url(#${'shadow-' + field.id})`}
							fill={field.textShadowColor.hex || '#000000'}
						/>
					)}
					<TextComp />
					{field.strikeThrough
						? (isNumberField ? [bBox.textBBox] : bBox.tspansBBox).map((bb, idx) => {
								let offsetX = 5;
								return (
									<line
										key={JSON.stringify(bb) + idx}
										x1={bb.x - offsetX}
										y1={bb.y + bb.height / 2}
										x2={
											bb.x +
											bb.width /
												Math.cos(strikeThroughLineRotateFactor * Math.atan2(bb.height, bb.width)) +
											offsetX
										}
										y2={bb.y + bb.height / 2}
										stroke={field.fontColor?.hex || '#000000'}
										strokeWidth={field.strikeThroughStyle.lineWidth}
										transform={`rotate(${
											field.strikeThroughStyle.type === 'strikedown'
												? (strikeThroughLineRotateFactor *
														(Math.atan2(bb.height, bb.width) * 180)) /
												  Math.PI
												: field.strikeThroughStyle.type === 'strikeup'
												? (-1 *
														strikeThroughLineRotateFactor *
														(Math.atan2(bb.height, bb.width) * 180)) /
												  Math.PI
												: 0
										}, ${bb.x + bb.width / 2}, ${bb.y + bb.height / 2})`}
									/>
								);
						  })
						: null}
				</g>
			</g>
		</svg>
	);
}

Text.propTypes = {
	field: PropTypes.object.isRequired, // it is text field
	fieldPreviewData: PropTypes.object.isRequired, // the preview data of a field
	animationDelay: PropTypes.number.isRequired, // animation delay. Relative to previous fields
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is aninimation paused (on video end in videoArtwork)
	isVideoArtwork: PropTypes.bool.isRequired,
};

Text.defaultProps = {};
export default React.memo(Text);
