import React from 'react';
// import cx from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

// import { DateTime } from 'luxon';
import randomColor from 'randomcolor';

import { Button, Grid, Paper, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import UserSelections from './UserSelections';

import { Loading, ErrorComp, PageTour } from 'components';

// CSS
import SchedulesStyle from './SchedulesStyle.jsx';

// Scheduler
import WeeklyScheduler from './WeeklyScheduler';
import ScheduleQueue from './ScheduleQueue';

// utils
import { _, moment, qs } from 'utils/libHelper';
import { decryptStr, encryptStr } from 'utils/appHelper';
import { injectIntl, FormattedMessage } from 'react-intl';

// redux
import { connect } from 'react-redux';
import {
	openGlobalDialog,
	notifyGeneral,
	notifyError,
	getScreenManagerScreenSchedules,
	addScreenManagerScreenSchedule,
	editScreenManagerScreenSchedule,
	addScreenManagerSchedulesToMultipleScreens,
	deleteScreenManagerScheduleFromScreen,
	resetScreenManagerScreenSchedule,
} from 'redux/actions'; // actions

// Constants
import {
	ROUTES_PATH_SCREENMANAGER_SCREENS,
	ROUTES_PATH_SCREENMANAGER_SCREEN_SCHEDULE,
	ROUTES_PATH_SCREENMANAGER_SLIDES,
} from 'routes';

class Schedules extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedScreens: [],
			selectedOrientation: null,
			startDatetime: null, // moment (lib) instance
			endDatetime: null, // moment (lib) instance
			scheduleEvents: [],
			currentSchedule: {},
			playlistColors: {},
			disableSaveButton: true,
			editingSchedule: null, // the schedule (in schedule queue) to be edit
			calSlotDuration: 60,
			showUnsavedDataDialog: false,
			pageTitle: '',
			// mode: 'ADD', // possible value: 'ADD', 'EDIT', 'PUBLISH'  ('PUBLISH' means scheduling to multiple screens)
		};
		this.currentSchedule = {};
	}

	handleScreenSelection = (event, child) => {
		let newState = { selectedScreens: event.target.value };
		if (this.state.selectedScreens.length === 0 && event.target.value.length > 0) {
			// user selected the first screen, we can decide the screen orientation
			newState.selectedOrientation = child.props.orientation;
		}
		if (event.target.value.length === 0) {
			// user unselected all screens, we need to reset orientation to null (unknown)
			newState.selectedOrientation = null;
		}
		newState.disableSaveButton = false;
		this.setState(newState);
		// this.setState({selectedScreens: {playerId: event.target.value, orientation: event.currentTarget.dataset.orientation}})
	};
	removeScreenSelection = (playerId) => {
		let newSelectedScreens = this.state.selectedScreens.filter(
			(selectedPlayerId) => selectedPlayerId !== playerId
		);
		let newState = { selectedScreens: newSelectedScreens };
		if (newSelectedScreens.length === 0) {
			newState.selectedOrientation = null;
		}
		newState.disableSaveButton = false;
		this.setState(newState);
	};

	handleScheduleStartDatetime = (date = null) => {
		// date is moment instance
		const minStartEndDTDiffInMinutes = 30;
		if (
			date &&
			this.state.endDatetime &&
			this.state.endDatetime.diff(date, 'minutes') < minStartEndDTDiffInMinutes
		) {
			this.props.notifyError(
				new Error(
					this.props.intl.formatMessage(
						{ id: 'pages.ScreenManager.components.Schedules.handleScheduleStartDTError' },
						{ minutes: minStartEndDTDiffInMinutes }
					)
				)
			);
			return null;
		}
		if (date && date.diff(moment(), 'seconds') <= 0) {
			this.props.notifyGeneral(
				this.props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Schedules.handleScheduleStartDTWarning',
				}),
				'warning'
			);
		}
		this.setState({ startDatetime: date, disableSaveButton: false });
	};
	handleScheduleEndDatetime = (date = null) => {
		// date is moment instance
		const minStartEndDTDiffInMinutes = 30;
		if (
			date &&
			this.state.startDatetime &&
			date.diff(this.state.startDatetime, 'minutes') < minStartEndDTDiffInMinutes
		) {
			this.props.notifyError(
				new Error(
					this.props.intl.formatMessage(
						{ id: 'pages.ScreenManager.components.Schedules.handleScheduleEndDTError' },
						{ minutes: minStartEndDTDiffInMinutes }
					)
				)
			);
			return null;
		}
		if (date && date.diff(moment(), 'seconds') <= 0) {
			this.props.notifyGeneral(
				this.props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Schedules.handleScheduleEndDTWarning',
				}),
				'warning'
			);
		}

		this.setState({ endDatetime: date, disableSaveButton: false });
	};

	// handler for fullcalendar events got updated/created...
	updateScheduleEvents = (scheduleEvents, playlistColors) => {
		var unSavedData = scheduleEvents.filter(
			(item) =>
				!this.state.scheduleEvents.some(
					(prevItems) =>
						prevItems.id === item.id &&
						prevItems.playlistId === item.playlistId &&
						scheduleEvents.length === this.state.scheduleEvents.length &&
						this.state.disableSaveButton
				)
		);
		let isDataChanged = unSavedData.length === 0 ? false : true;
		this.setState({
			scheduleEvents,
			playlistColors,
			disableSaveButton: !isDataChanged,
		});
	};

	// return true if valid, otherwise false
	isValidSchedules = (weeklySchedules) => {
		if (weeklySchedules.length === 0) {
			return false;
		}
		// validate if the schedules covers the whole week
		let schedulesStart = moment(weeklySchedules[0].start);
		let schedulesEnd = moment(weeklySchedules[weeklySchedules.length - 1].end);
		if (
			schedulesStart.format('HH:mm (ddd)') !== '00:00 (Mon)' ||
			!['00:00 (Mon)', '23:59 (Sun)'].includes(schedulesEnd.format('HH:mm (ddd)'))
		) {
			return false;
		}

		for (let i = 0; i < weeklySchedules.length; i++) {
			if (i === weeklySchedules.length - 1) {
				return true;
			}
			if (weeklySchedules[i].end.getTime() !== weeklySchedules[i + 1].start.getTime()) {
				return false;
			}
		}
	};

	onPublish = () => {
		let { intl } = this.props;
		let {
			selectedScreens,
			selectedOrientation,
			startDatetime,
			endDatetime,
			scheduleEvents,
		} = this.state;
		let weeklySchedules = _.sortBy(scheduleEvents, (schedule) => schedule.start);
		if (!this.isValidSchedules(weeklySchedules)) {
			return this.props.notifyError(
				new Error(
					intl.formatMessage({
						id: 'pages.ScreenManager.components.Schedules.onPublishErrorWholeWeekCoverage',
					})
				)
			);
		} else if (selectedScreens.length === 0) {
			return this.props.notifyError(
				new Error(
					intl.formatMessage({
						id: 'pages.ScreenManager.components.Schedules.onPublishErrorAtLeastOneScreen',
					})
				)
			);
		}

		let mode = '',
			editTimetableWithPLName = undefined;
		let isSingleSchedule = !!this.props.screenManagerScreenSchedule.player;
		if (isSingleSchedule && !this.state.editingSchedule) {
			mode = 'ADD';
		} else if (isSingleSchedule && this.state.editingSchedule) {
			mode = 'EDIT';
			editTimetableWithPLName = [];
		} else if (!isSingleSchedule) {
			mode = 'PUBLISH'; // schedule to multiple screens (players)
		}

		startDatetime = startDatetime || moment();
		let timetable = weeklySchedules.map((schedule) => {
			let timeSlot = {
				playlistId: schedule.playlistId,
				startTimeInDOW: `${schedule.start.getDay()}-${moment(schedule.start).format('HH:mm')}`,
				endTimeInDOW: `${schedule.end.getDay()}-${moment(schedule.end).format('HH:mm')}`,
			};
			if (mode === 'EDIT') {
				editTimetableWithPLName.push({ ...timeSlot, playlistName: schedule.title });
			}
			return timeSlot;
		});
		let opts = {
			// playerIds: selectedScreens,
			reqData: {
				orientation: selectedOrientation,
				startDatetime: startDatetime.toISOString(), //startDatetime?startDatetime.toISOString():(new Date()).toISOString(),
				timetable: timetable,
			},
		};
		if (endDatetime && endDatetime.isValid()) {
			opts.reqData.endDatetime = endDatetime.toISOString();
		}

		if (mode === 'ADD') {
			// Add
			opts.playerId = this.props.screenManagerScreenSchedule.player.id;
			this.props.addScreenManagerScreenSchedule(opts, ({ error }) => {
				if (!error) {
					this.props.getScreenManagerScreenSchedules({ playerId: opts.playerId });
					this.props.notifyGeneral(
						intl.formatMessage({
							id: 'pages.ScreenManager.components.Schedules.onPublishNotifyAddScheDone',
						}),
						'success'
					);
					this.setState({
						editingSchedule: null,
						scheduleEvents: [],
						playlistColors: {},
						startDatetime: null,
						endDatetime: null,
						disableSaveButton: false,
					});
				}
			});
		} else if (mode === 'EDIT') {
			// edit
			delete opts.reqData.orientation; // we don't modify orientation
			opts.playerId = this.props.screenManagerScreenSchedule.player.id;
			opts.scheduleId = this.state.editingSchedule.scheduleId;
			opts.newTimetableWithPLName = editTimetableWithPLName;
			this.props.editScreenManagerScreenSchedule(opts, ({ error }) => {
				if (!error) {
					this.props.notifyGeneral(
						intl.formatMessage({
							id: 'pages.ScreenManager.components.Schedules.onPublishNotifyEditScheDone',
						}),
						'success'
					);
					this.setState({ disableSaveButton: true });
				}
			});
		} else if (mode === 'PUBLISH') {
			// publish
			opts.playerIds = selectedScreens;
			this.props.addScreenManagerSchedulesToMultipleScreens(opts, ({ error }) => {
				if (!error) {
					this.props.notifyGeneral(
						intl.formatMessage({
							id: 'pages.ScreenManager.components.Schedules.onPublishNotifyPublishScheDone',
						}),
						'success'
					);
					this.setState({ disableSaveButton: true });
				}
			});
		}
	};

	loadSchedule = (schedule) => {
		let playlistColors = {};
		let scheduleEvents = schedule.timetable.map((timeSlot, idx, timetable) => {
			if (!playlistColors[timeSlot.playlistId]) {
				playlistColors[timeSlot.playlistId] = randomColor({
					luminosity: 'dark',
					hue: 'random',
				});
			}

			let startDOWSplits = timeSlot.startTimeInDOW.split('-');
			let startDOW = parseInt(startDOWSplits[0]);
			startDOW = startDOW === 0 ? 7 : startDOW;
			let startTime = startDOWSplits[1];
			let startDatetime = moment(startTime, 'HH:mm').isoWeekday(startDOW).toDate();

			let endDOWSplits = timeSlot.endTimeInDOW.split('-');
			let endDOW = parseInt(endDOWSplits[0]);
			endDOW = endDOW === 0 ? 7 : idx === timetable.length - 1 && endDOW === 1 ? 8 : endDOW;
			let endTime = endDOWSplits[1];
			let endDatetime = moment(endTime, 'HH:mm').isoWeekday(endDOW).toDate();

			return {
				id: startDatetime.toString() + endDatetime.toString(),
				allDay: false,
				title: timeSlot.playlistName,
				playlistId: timeSlot.playlistId,
				start: startDatetime,
				end: endDatetime,
				backgroundColor: playlistColors[timeSlot.playlistId],
			};
		});

		this.setState({
			editingSchedule: schedule,
			scheduleEvents,
			playlistColors,
			startDatetime: moment(schedule.startDatetime),
			endDatetime: schedule.endDatetime ? moment(schedule.endDatetime) : null,
		});
	};
	handleScheduleClick = (schedule, isIntialLoad) => {
		// change to edit mode
		// scheduleEvents: [],
		if (isIntialLoad) {
			this.setState({
				disableSaveButton: true,
			});
			this.loadSchedule(schedule);
		}
		if (!this.state.disableSaveButton && isIntialLoad === undefined) {
			let confirmDialog = {
				size: 'sm',
				title: this.props.intl.formatMessage({
					id: 'pages.Artwork.components.Esign.TooManySelectionsDialogTitle',
				}),
				content: (
					<Typography
						variant="body1"
						gutterBottom
						style={{ minHeight: 50, marginTop: 25, overflow: 'hidden', textOverflow: 'ellipsis' }}
					>
						{
							<FormattedMessage id="pages.ScreenManager.components.Schedules.unsavedScheduleChangesMsg" />
						}
					</Typography>
				),
				confirmCB: () => {
					this.loadSchedule(schedule);
					this.setState({
						disableSaveButton: true,
					});
				},
			};
			this.props.openGlobalDialog(confirmDialog);
		} else {
			this.loadSchedule(schedule);
		}
	};

	switchToAddMode = () => {
		if (!this.state.disableSaveButton) {
			let confirmDialog = {
				size: 'sm',
				title: this.props.intl.formatMessage({
					id: 'pages.Artwork.components.Esign.TooManySelectionsDialogTitle',
				}),
				content: (
					<Typography
						variant="body1"
						gutterBottom
						style={{ minHeight: 50, marginTop: 25, overflow: 'hidden', textOverflow: 'ellipsis' }}
					>
						{
							<FormattedMessage id="pages.ScreenManager.components.Schedules.unsavedScheduleChangesMsg" />
						}
					</Typography>
				),
				confirmCB: () => {
					this.setState({
						editingSchedule: null,
						scheduleEvents: [],
						playlistColors: {},
						startDatetime: null,
						endDatetime: null,
						// disableSaveButton: false,
					});
				},
			};
			this.props.openGlobalDialog(confirmDialog);
		} else {
			this.setState({
				editingSchedule: null,
				scheduleEvents: [],
				playlistColors: {},
				startDatetime: null,
				endDatetime: null,
			});
		}
	};

	handleScheduleDelete = (schedule) => {
		let dialogConf = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Schedules.DelScheduleDialogTitle',
			}), //'Delete Schedule',
			content: (
				<Typography variant="body1" gutterBottom style={{ minHeight: 50, marginTop: 25 }}>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Schedules.DelScheduleDialogMessage"
							values={{
								// bold: (...chunks) => <b>{chunks}</b>,
								schedule: (
									<Typography variant="body1" component="span" color="secondary">{`${moment(
										schedule.startDatetime
									).format('YYYY-MM-DD HH:mm:00')} - ${
										schedule.endDatetime && moment(schedule.endDatetime).isValid()
											? moment(schedule.endDatetime).format('YYYY-MM-DD HH:mm:00')
											: 'Forever'
									}`}</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				this.props.deleteScreenManagerScheduleFromScreen(
					{
						playerId: this.props.screenManagerScreenSchedule.player.id,
						scheduleId: schedule.scheduleId,
					},
					({ error }) => {
						if (!error) {
							if (
								this.state.editingSchedule &&
								this.state.editingSchedule.scheduleId === schedule.scheduleId
							) {
								this.switchToAddMode();
							}
							this.props.notifyGeneral(
								this.props.intl.formatMessage({
									id: 'pages.ScreenManager.components.Schedules.handleScheDelNotifyDelDone',
								}),
								'success'
							);
						}
					}
				);
			},
		};
		this.props.openGlobalDialog(dialogConf);
	};

	findCurrentSchedule = (schedules = [], nowDateTime = moment()) => {
		let currSch = {};

		nowDateTime = moment(nowDateTime).isValid() ? nowDateTime : moment();
		// filters out all the past schedules, also converts the datetime strings to momentjs objects
		schedules = schedules.filter((x) => {
			// NaN marks them invalid, use .isValid() before usage
			x.startDatetime = moment(x.startDatetime || NaN);
			x.endDatetime = moment(x.endDatetime || NaN);
			// filters in invalid end dates. filters out only valid and past dates
			return !x.endDatetime.isValid() || x.endDatetime > nowDateTime;
		});
		// sort by ascending start date time.
		schedules.sort((a, b) => a.startDatetime - b.startDatetime);

		// Find out the current schedule
		for (let i = 0; i < schedules.length; i++) {
			// choosing the latest starting sch among all the already started ones as the current sch.
			if (schedules[i].startDatetime <= nowDateTime) {
				currSch = schedules[i];
			} else {
				// as soon as we find the first future sch, exit the loop
				break;
			}
		}
		return currSch;
	};

	handleGoToPlaylist = (playlistId) => {
		this.props.history.push({
			pathname: ROUTES_PATH_SCREENMANAGER_SLIDES,
			search: `?playlistId=${encryptStr(playlistId)}`,
		});
	};

	componentDidMount() {
		let parsedQueryParams = qs.parse(this.props.location.search);

		if (parsedQueryParams.playerId) {
			this.props.getScreenManagerScreenSchedules(
				{ playerId: decryptStr(parsedQueryParams.playerId) },
				({ error }) => {
					if (!error) {
						this.setState({
							selectedScreens: [this.props.screenManagerScreenSchedule.player.id],
							selectedOrientation: this.props.screenManagerScreenSchedule.player.orientation,
							pageTitle: this.props.intl.formatMessage(
								{ id: 'pages.ScreenManager.components.Schedules.PageTitle' },
								{ playerName: this.props.screenManagerScreenSchedule.player.name }
							),
						});
						this.currentSchedule = this.findCurrentSchedule(
							this.props.screenManagerScreenSchedule.player.schedules
						);
						this.currentSchedule.scheduleId && this.handleScheduleClick(this.currentSchedule, true);
					}
				}
			);
		} else if (ROUTES_PATH_SCREENMANAGER_SCREEN_SCHEDULE === this.props.location.pathname) {
			// invalid url, redirect to screens page
			this.props.notifyError(
				new Error(this.props.intl.formatMessage({ id: 'GENERAL.InvalidUrl' }))
			);
			this.props.history.replace(ROUTES_PATH_SCREENMANAGER_SCREENS);
		} else {
			// Bulk Scheduler title
			this.setState({
				pageTitle: this.props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Schedules.BulkSchedulerPageTitle',
				}),
			});
		}
		this.block = this.props.history.block(this.onBlock);
	}

	onBlock = (tx) => {
		// this.navigation = () => this.props.history.goBack();
		this.navigation = () => this.props.history.push(tx.pathname);
		if (!this.state.disableSaveButton && !this.state.showUnsavedDataDialog) {
			this.setState({ showUnsavedDataDialog: true });
			return this.props.intl.formatMessage({
				id: 'pages.Artwork.components.General.unsavedChangesMsg',
			});
		}
		if (this.state.disableSaveButton || this.state.showUnsavedDataDialog) {
			this.block();
			return true;
		}
		return false;
	};

	// eslint-disable-next-line no-unused-vars
	componentDidUpdate(prevProps) {
		if (this.props.screenManagerScreenSchedule.player) {
			let currSch;
			currSch = this.findCurrentSchedule(this.props.screenManagerScreenSchedule.player.schedules);
			if (currSch.scheduleId !== this.currentSchedule.scheduleId) {
				this.currentSchedule = currSch;
				this.currentSchedule.scheduleId && this.handleScheduleClick(this.currentSchedule, true);
			}
		}
	}

	componentWillUnmount() {
		this.props.resetScreenManagerScreenSchedule();
		this.block && this.block();
	}

	render() {
		const {
			classes,
			screenManagerScreenSchedule,
			intl, // language international, injected by injectIntl in react-intl
		} = this.props;
		const {
			selectedScreens,
			selectedOrientation,
			startDatetime,
			endDatetime,
			disableSaveButton,
			editingSchedule,
			calSlotDuration,
		} = this.state;

		const { player, isGettingPlayer } = screenManagerScreenSchedule;
		let isSingleSchedule = !!isGettingPlayer;
		let scheduleListGridSpace = isSingleSchedule ? 3 : 0;

		let saveButtonText = '';
		if (isSingleSchedule && !editingSchedule) {
			saveButtonText = intl.formatMessage({ id: 'GENERAL.Add' });
		} else if (isSingleSchedule && editingSchedule) {
			saveButtonText = intl.formatMessage({ id: 'GENERAL.Save' });
		} else if (!isSingleSchedule) {
			saveButtonText = intl.formatMessage({
				id: 'pages.ScreenManager.components.Schedules.PublishButtonText',
			});
		}

		return (
			<div className={classes.contentWrapper}>
				<Paper className={classes.header}>
					<Typography variant="h6" gutterBottom className={classes.title}>
						{this.state && this.state.pageTitle}
					</Typography>
					<HelpIcon
						onClick={() => this.setState({ runPageTour: true })}
						className={classes.helpIcon + ' step1'}
					/>
				</Paper>
				{isSingleSchedule && isGettingPlayer === 'PROCESSING' ? (
					<Loading />
				) : isSingleSchedule && isGettingPlayer === 'FAILED' ? (
					<ErrorComp />
				) : (
					<Grid container spacing={3} className={classes.content}>
						{isSingleSchedule && (
							<Grid item xs={scheduleListGridSpace} className="step2">
								<ScheduleQueue
									schedules={_.orderBy(
										player.schedules,
										(schedule) => Date.parse(schedule.startDatetime),
										'desc'
									)}
									currentSchedule={this.currentSchedule}
									onScheduleClick={this.handleScheduleClick}
									onScheduleDelete={this.handleScheduleDelete}
									onAddSchedule={this.switchToAddMode}
									intl={intl}
								/>
							</Grid>
						)}
						<Grid item xs={12 - scheduleListGridSpace}>
							<div className={classes.scheduleContainer}>
								<Paper className={'step3 ' + classes.schedulePaper}>
									<UserSelections
										handleScreenSelection={this.handleScreenSelection}
										selectedScreens={selectedScreens}
										selectedOrientation={selectedOrientation}
										removeScreenSelection={this.removeScreenSelection}
										startDatetime={startDatetime}
										handleStartDatetimeChange={this.handleScheduleStartDatetime}
										endDatetime={endDatetime}
										handleEndDatetimeChange={this.handleScheduleEndDatetime}
										disableScreenSelection={isSingleSchedule}
										intl={intl}
									/>
								</Paper>
								<Paper className={'step4 ' + classes.schedulePaper}>
									<WeeklyScheduler
										scheduleEvents={this.state.scheduleEvents}
										playlistColors={this.state.playlistColors}
										updateScheduleEvents={this.updateScheduleEvents}
										handleGoToPlaylist={this.handleGoToPlaylist}
										screenOrientation={selectedOrientation}
										disabled={!selectedOrientation}
										intl={intl}
										slotDuration={calSlotDuration}
									/>
								</Paper>
								<Paper className={classes.buttonWrapper}>
									<span>
										{[15, 30, 60].map((slotOptn) => (
											<Button
												value={slotOptn}
												style={{ borderRadius: 16, marginRight: 4 }}
												color="primary"
												size="small"
												disableElevation
												key={slotOptn}
												variant={slotOptn === calSlotDuration ? 'contained' : 'outlined'}
												onClick={() =>
													slotOptn !== calSlotDuration &&
													this.setState({ calSlotDuration: slotOptn })
												}
											>
												{slotOptn} Min
											</Button>
										))}
									</span>
									<Button
										variant="contained"
										/*color="secondary"*/ className={classes.button}
										onClick={this.onPublish}
										disabled={disableSaveButton}
									>
										{saveButtonText}
									</Button>
									{
										// Temporarily comment out the "Save to Template" button until we actually implement this feature
										// <Button variant="contained" /*color="secondary"*/ className={classes.button}>
										// 	Save to Template
										// </Button>
									}
								</Paper>
							</div>
						</Grid>
						<PageTour
							run={this.state.runPageTour}
							runIfNotDone={true}
							steps={pageTourSteps}
							disableScrollParentFix={true}
							tourId="ScreenManager-Schedules"
							onClose={this.setState.bind(this, { runPageTour: false })}
						/>
					</Grid>
				)}
			</div>
		);
	}
}

Schedules.propTypes = {
	classes: PropTypes.object.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	notifyError: PropTypes.func.isRequired,
	getScreenManagerScreenSchedules: PropTypes.func.isRequired,
	addScreenManagerScreenSchedule: PropTypes.func.isRequired,
	editScreenManagerScreenSchedule: PropTypes.func.isRequired,
	addScreenManagerSchedulesToMultipleScreens: PropTypes.func.isRequired,
	deleteScreenManagerScheduleFromScreen: PropTypes.func.isRequired,
	resetScreenManagerScreenSchedule: PropTypes.func.isRequired,
	screenManagerScreenSchedule: PropTypes.object.isRequired,
};

Schedules.defaultProps = {
	// multiple: false,
	// allowZip: false,
	// allowPreview: true,
	// disableClick: false,
};

const pageTourSteps = [
	{
		target: '.step1',
		content: (
			<FormattedMessage
				id="pages.ScreenManager.components.Schedules.PageTourStep1"
				values={{
					br: <br />,
				}}
			/>
		),
		placement: 'bottom',
		disableBeacon: true,
		disableOverlayClose: true,
	},
	{
		target: '.step2',
		content: (
			<FormattedMessage
				id="pages.ScreenManager.components.Schedules.PageTourStep2"
				values={{
					br: <br />,
				}}
			/>
		),
		placement: 'right',
		disableBeacon: true,
		disableOverlayClose: true,
	},
	{
		target: '.step3',
		content: (
			<FormattedMessage
				id="pages.ScreenManager.components.Schedules.PageTourStep3"
				values={{
					br: <br />,
				}}
			/>
		),
		placement: 'bottom',
		disableBeacon: true,
		disableOverlayClose: true,
	},
	{
		target: '.step4',
		content: (
			<FormattedMessage
				id="pages.ScreenManager.components.Schedules.PageTourStep4"
				values={{
					br: <br />,
				}}
			/>
		),
		placement: 'left',
		disableBeacon: true,
		disableOverlayClose: true,
	},
];

const mapStateToProps = (state) => {
	return {
		screenManagerScreenSchedule: state.screenManagerScreenSchedule,
	};
};

export default connect(mapStateToProps, {
	openGlobalDialog,
	notifyGeneral,
	notifyError,
	getScreenManagerScreenSchedules,
	addScreenManagerScreenSchedule,
	editScreenManagerScreenSchedule,
	addScreenManagerSchedulesToMultipleScreens,
	deleteScreenManagerScheduleFromScreen,
	resetScreenManagerScreenSchedule,
})(injectIntl(withStyles(SchedulesStyle)(Schedules)));
