/*
 * this is a customised middleware like redux-thunk that allows dispatching a function
 */
import { axios } from 'utils/libHelper';
// import {authLogin,  AUTH_LOGIN, AUTH_HANDLEAUTH_SUCCESS, AUTH_HANDLEAUTH_FAILED} from '../actions'; // actions
// import config from '../../config';

const promiseRequest = (store) => (next) => (action) => {
	const { dispatch, getState } = store;
	if (typeof action === 'function') {
		action(dispatch, getState);
		return;
	}

	// // check is the user session still valid
	// let authState = getState().authentication;
	// if (!authState.isLoggedIn && [ AUTH_LOGIN, AUTH_HANDLEAUTH_SUCCESS, AUTH_HANDLEAUTH_FAILED].indexOf(action.type) === -1 ){ // && !authState.isAuthenticated()){
	// 	// alert('Your session is expired, please login again!');
	// 	// dispatch(authLogin(window.location.pathname+window.location.search+window.location.hash));
	// 	window.location.replace(config.loginPath);
	// 	return null;
	// }

	const { promise, types, afterSuccess, ...rest } = action;

	if (!action.promise) {
		return next(action);
	}

	const [REQUEST, SUCCESS, FAILURE] = types;

	next({
		...rest,
		type: REQUEST,
	});
	const onFulfilled = (result) => {
		next({
			...rest,
			result,
			type: SUCCESS,
		});
		if (afterSuccess) {
			afterSuccess({ dispatch, getState, result, error: null });
		}
	};

	const onRejected = (error) => {
		next({
			...rest,
			error,
			type: FAILURE,
		});
		if (afterSuccess) {
			afterSuccess({ dispatch, getState, result: null, error });
		}
	};

	return promise(axios)
		.then(onFulfilled, onRejected)
		.catch((error) => {
			console.debug('MIDDLEWARE ERROR:', error);
			onRejected(error);
		});
};
export default promiseRequest;
