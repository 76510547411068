import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { Rnd } from 'react-rnd';

import { getUploadFileTypesByFieldType } from 'utils/artwork/artUtilsWebUI';

// field palette
import { TextPaletteStyles, TextPaletteData } from './TextPalette/TextPalette';
import { ImagePaletteStyles, ImagePaletteData } from './ImagePalette/ImagePalette';
import { PdfPaletteStyles, PdfPaletteData } from './PdfPalette/PdfPalette';
import { BarcodePaletteStyles, BarcodePaletteData } from './BarcodePalette/BarcodePalette';
import { VideoPaletteStyles, VideoPaletteData } from './VideoPalette/VideoPalette';
import { GridPaletteStyles, GridPaletteData } from './GridPalette/GridPalette';
import GroupPalette from './GroupPalette/GroupPalette';

import { PerfectScrollWrapper, ColorPicker, RnDDialog, MediafileMultiChoosers } from 'components';
import { Popover, Typography } from '@mui/material';
import { StyledTabs, StyledTab, StyledContainedButton, DividerHor } from '../CustomMUI/CustomMUI';

import GeneralTab from './GeneralTab/GeneralTab';

// intl lang
import { useIntl } from 'react-intl';

// CSS
import { default as useStyles } from './ElementPaletteStyle.jsx';

const defaultColorPicker = {
	anchorEl: null,
	fieldKey: '',
	disableAlpha: true,
	enablePantone: false,
	enableCMYK: false,
};

function ElementPalette({
	draggableBounds,
	open,
	onClose,
	userData,
	selectedFields,
	groups,
	handleFieldsUpdate,
	handleNewGroup,
	// handleDelGroup,
	artworkExtraData,
	templateFields,
	zoom,
	paletteTabIndex,
	setPaletteState,
	isVideoArtwork,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	// artwork field to use mediafileChooser
	const [fieldToChooseMediafile, setFieldToChooseMediafile] = React.useState(null);
	const [colorPicker, setColorPicker] = React.useState(defaultColorPicker);
	const [psWrapper, setPsWrapper] = React.useState({});
	React.useEffect(() => {
		if (psWrapper.elemRef && psWrapper.elemRef.current) psWrapper.elemRef.current.scrollTop = 0;
	}, [psWrapper.elemRef, paletteTabIndex /* tabIndex */]);

	const selectedFieldsIdsStr = selectedFields.map((f) => f.id).join('-');
	React.useEffect(() => {
		if (psWrapper.elemRef && psWrapper.elemRef.current) psWrapper.elemRef.current.scrollTop = 0;
		// if (tabIndex !== 0) setTabIndex(0);
	}, [psWrapper.elemRef, selectedFieldsIdsStr]);
	/**
	 *	Update a single field (Only use it when it is a single field)
	 * @param {object} dataField
	 * Example: {
	 * 	fieldKey1: 'xxxx',
	 * 	fieldKey2: 'xxx',
	 * }
	 */
	const updateField = (dataField) => {
		handleFieldsUpdate([{ ...selectedFields[0], ...dataField }]);
	};
	const updateFields = (updatedFields) => {
		handleFieldsUpdate(updatedFields);
	};

	/**
	 * Get mediafile chooser filters by artwork field for calling /mediafiles endpoint in filemanager api
	 * NB: when designer chooses default mediafile for a field, we ONLY use filters when mediafile origin is from 'category' or 'admin_lightbox'
	 * @param {object} field. The field object.
	 * @param {string} filterCase. enum ['', 'filelibrary', 'myfiles']. if '', for all cases
	 *
	 * @return {object} filters. Filters that are defined in POST /mediafiles endpoint, except "keywords"
	 */
	const getMediafileChooserFiltersForFilemanager = (field, filterCase = '') => {
		let filters = {};
		switch (field.type) {
			case 'image':
			case 'pdf':
			case 'video': {
				if (field.type === 'pdf') {
					filters.isCreatedArtworkSVG = true;
				} else {
					filters.fileTypeGroup = field.type === 'image' ? ['image'] : ['video'];
				}
				// originchoice can only be one of the three
				let originChoice =
					field.imageOriginChoice || field.pdfOriginChoice || field.videoOriginChoice;
				let choiceId =
					originChoice.length > 0 ? originChoice[originChoice.length - 1].id || '0' : '0';
				choiceId = choiceId.toString();
				switch (field.imageOrigin || field.pdfOrigin || field.videoOrigin) {
					case 'category':
						if (!filterCase || filterCase === 'filelibrary') {
							filters.categories = [choiceId];
						}
						break;
					case 'admin_lightbox':
						if (!filterCase || filterCase === 'filelibrary') {
							filters.lightboxes = [choiceId];
						}
						break;
					// case 'user_files':
					// 	if (!filterCase || filterCase === 'myfiles') {
					// 		filters.createdByUIDs = [userData.uid];
					// 	}
					// 	break;
					case 'user_files':
					case 'user_avatar':
					default:
						break;
				}
				// if (
				// 	(field.type === 'image' && field.imageOriginIncludeUserFiles) ||
				// 	(field.type === 'pdf' && field.pdfOriginIncludeUserFiles) ||
				// 	(field.type === 'video' && field.videoOriginIncludeUserFiles)
				// ) {
				// 	if (!filterCase || filterCase === 'myfiles') {
				// 		filters.createdByUIDs = [userData.uid];
				// 	}
				// }
				break;
			}
			default:
				break;
		}
		return filters;
	};

	// let PaletteComp = <div>Not Ready</div>;
	let GeneralComp =
		selectedFields.length === 1 ? (
			<GeneralTab
				{...{
					field: selectedFields[0],
					updateField,
					handleNewGroup,
					// handleDelGroup,
					groups,
					zoom,
					templateFields,
					isVideoArtwork,
				}}
			/>
		) : null;
	let StylesComp = null;
	let DataComp = null;
	let titleText = null;

	if (selectedFields.length === 0) {
		open = false;
	} else if (selectedFields.length === 1) {
		// single file selection
		switch (selectedFields[0].type) {
			case 'text':
				titleText = intl.formatMessage({
					id: 'pages.Artwork.GENERAL.typeText',
				});
				StylesComp = (
					<TextPaletteStyles
						{...{
							field: selectedFields[0],
							updateField,
							setColorPicker,
							artworkExtraData,
							// templateFields,
						}}
					/>
				);
				DataComp = (
					<TextPaletteData
						{...{
							field: selectedFields[0],
							updateField,
							updateFields,
							artworkExtraData,
							templateFields,
						}}
					/>
				);
				break;
			case 'image':
				titleText = intl.formatMessage({
					id: 'pages.Artwork.GENERAL.typeImage',
				});
				StylesComp = (
					<ImagePaletteStyles {...{ field: selectedFields[0], updateField, setColorPicker }} />
				);
				DataComp = (
					<ImagePaletteData
						{...{
							field: selectedFields[0],
							updateField,
							templateFields,
							artworkExtraData,
							openMediaFileChooser: () => {
								setFieldToChooseMediafile(selectedFields[0]);
							},
						}}
					/>
				);
				break;
			case 'pdf':
				titleText = intl.formatMessage({
					id: 'pages.Artwork.GENERAL.typePdf',
				});
				StylesComp = (
					<PdfPaletteStyles {...{ field: selectedFields[0], updateField, setColorPicker }} />
				);
				DataComp = (
					<PdfPaletteData
						{...{
							field: selectedFields[0],
							updateField,
							artworkExtraData,
							openMediaFileChooser: () => {
								setFieldToChooseMediafile(selectedFields[0]);
							},
						}}
					/>
				);
				break;
			case 'barcode':
				titleText = intl.formatMessage({
					id: 'pages.Artwork.GENERAL.typeBarcode',
				});
				StylesComp = (
					<BarcodePaletteStyles {...{ field: selectedFields[0], updateField, setColorPicker }} />
				);
				DataComp = (
					<BarcodePaletteData
						{...{ field: selectedFields[0], updateField, artworkExtraData, templateFields }}
					/>
				);
				break;
			case 'video':
				titleText = intl.formatMessage({
					id: 'pages.Artwork.GENERAL.typeVideo',
				});
				StylesComp = (
					<VideoPaletteStyles {...{ field: selectedFields[0], updateField, setColorPicker }} />
				);
				DataComp = (
					<VideoPaletteData
						{...{
							field: selectedFields[0],
							updateField,
							templateFields,
							artworkExtraData,
							openMediaFileChooser: () => {
								setFieldToChooseMediafile(selectedFields[0]);
							},
						}}
					/>
				);
				break;
			case 'grid':
				titleText = intl.formatMessage({
					id: 'pages.Artwork.GENERAL.typeGrid',
				});
				StylesComp = (
					<GridPaletteStyles
						{...{ field: selectedFields[0], updateField, setColorPicker, artworkExtraData }}
					/>
				);
				DataComp = (
					<GridPaletteData
						{...{ field: selectedFields[0], updateField, setColorPicker, artworkExtraData }}
					/>
				);
				break;
			default:
				break;
		}
	} else {
		// it is multiple selection (grouping)
		// PaletteComp = <GroupPalette selectedFields={selectedFields} />;
	}
	const dragHandleClassName = 'contextualPaletteTitleDragHandler'; // Not for CSS style, only act as a selector for dragging

	return (
		<Rnd
			default={{
				x: document.body.clientWidth - 360, // 150
				y: 20,
				width: 300,
				height: '60%',
			}}
			minWidth={320}
			minHeight={370}
			maxHeight="85%"
			maxWidth={1000}
			dragHandleClassName={dragHandleClassName}
			enableResizing={{
				top: false,
				right: true,
				bottom: true,
				left: true,
				topRight: false,
				bottomRight: true,
				bottomLeft: true,
				topLeft: false,
			}}
			bounds={draggableBounds}
			className={cx({ [classes.contextualPaletteClosed]: !open })}
		>
			<div className={classes.contextualPaletteWrapper}>
				{/* palette title */}
				<div
					className={cx(classes.contextualPaletteTitle, dragHandleClassName)}
					id="contextualPaletteTitle"
				>
					{selectedFields.length === 1
						? titleText
						: intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.titleMultipleSelection',
						  })}
					{
						/** Append field name in title */
						selectedFields.length === 1 && (
							<Typography
								component="span"
								variant="body2"
								noWrap
								style={{ textTransform: 'none', marginLeft: 8 }}
							>
								{selectedFields[0].name}
							</Typography>
						)
					}
					<div className={classes.contextualPaletteCloseButton} onClick={onClose}>
						X
					</div>
				</div>
				{/* palette content */}
				<div className={classes.contextualPaletteContent}>
					{selectedFields.length === 1 && (
						<div className={classes.tabsWrapper}>
							<StyledTabs
								variant="fullWidth"
								value={paletteTabIndex /* tabIndex */}
								centered
								onChange={
									(e, newValue) =>
										setPaletteState({ paletteTabIndex: newValue }) /* setTabIndex(newValue) */
								}
							>
								<StyledTab label="General" />
								<StyledTab label="Style" />
								<StyledTab label="Data" />
							</StyledTabs>
						</div>
					)}
					<PerfectScrollWrapper className={classes.paletteWrapper} setScrollRef={setPsWrapper}>
						{
							selectedFields.length === 1 ? (
								paletteTabIndex === 0 ? (
									GeneralComp
								) : paletteTabIndex === 1 ? (
									StylesComp
								) : paletteTabIndex === 2 ? (
									DataComp
								) : null // if paletteTabIndex is not 0, 1, or 2, we give it null
							) : (
								<GroupPalette
									key={selectedFields.map((f) => f.id).join('-')}
									{...{
										fields: selectedFields,
										updateFields,
										handleNewGroup,
										// handleDelGroup,
										groups,
									}}
								/>
							) // group tabs here
						}
					</PerfectScrollWrapper>

					{
						/** color picker popup - single field selection only */
						selectedFields.length === 1 && (
							<Popover
								open={Boolean(colorPicker.anchorEl)}
								anchorEl={colorPicker.anchorEl}
								onClose={() => setColorPicker(defaultColorPicker)}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<ColorPicker
									color={
										colorPicker.fieldKey && selectedFields[0][colorPicker.fieldKey]
											? selectedFields[0][colorPicker.fieldKey]
											: { hex: '#000', rgb: { r: 0, g: 0, b: 0, a: 1 } }
									}
									disableAlpha={colorPicker.disableAlpha}
									enablePantone={colorPicker.enablePantone}
									enableCMYK={colorPicker.enableCMYK}
									onColorChangeComplete={(color) => updateField({ [colorPicker.fieldKey]: color })}
								/>
								<DividerHor />
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<StyledContainedButton
										label={intl.formatMessage({ id: 'GENERAL.Close' })}
										size="small"
										onClick={() => setColorPicker(defaultColorPicker)}
									/>
								</div>
							</Popover>
						)
					}
					{fieldToChooseMediafile && (
						<RnDDialog
							open={true}
							size="md"
							title="Select Media File"
							resizable={true}
							onClose={() => {
								setFieldToChooseMediafile(null);
							}}
						>
							<MediafileMultiChoosers
								/** designer always selectes default mediafile from following providers */
								enabledProviders={[
									'TOOLKIT_FILELIBRARY',
									'TOOLKIT_MYFILES',
									'TOOLKIT_FILEUPLOADER',
								]}
								// filtersToolkitFileLibrary={
								// 	// designer selects mediafile from the whole (filemanager) library
								// 	{
								// 		fileTypeGroup:
								// 			fieldToChooseMediafile.type === 'image'
								// 				? ['image']
								// 				: fieldToChooseMediafile.type === 'pdf'
								// 				? ['svg']
								// 				: ['video'],
								// 	}
								// }
								filtersToolkitFileLibrary={getMediafileChooserFiltersForFilemanager(
									fieldToChooseMediafile,
									'filelibrary'
								)}
								filtersToolkitMyFiles={
									// designer selects mediafile from own files
									{
										createdByUIDs: [userData.uid],
										...(fieldToChooseMediafile.type === 'pdf' ? { isCreatedArtworkSVG: true } : {}),
										...(fieldToChooseMediafile.type === 'image'
											? { fileTypeGroup: ['image'] }
											: fieldToChooseMediafile.type === 'video'
											? { fileTypeGroup: ['video'] }
											: {}),
									}
								}
								S3UploaderProps={{ accept: getUploadFileTypesByFieldType(fieldToChooseMediafile) }} // use default accept file types if null
								onMediafileSelect={(selectedMediafile) => {
									updateField({
										defaultMediafileId: selectedMediafile.id,
										defaultMediafilePreviewUrl:
											fieldToChooseMediafile.type === 'pdf'
												? selectedMediafile.svgUrl || ''
												: selectedMediafile.previewUrl,
										defaultMediafileHighResUrl:
											fieldToChooseMediafile.type === 'pdf'
												? selectedMediafile.svgUrl || ''
												: selectedMediafile.highResUrl,
										defaultMediafileOptimisedUrl:
											fieldToChooseMediafile.type === 'pdf'
												? selectedMediafile.svgUrl || ''
												: selectedMediafile.optimisedUrl,
									});

									setFieldToChooseMediafile(null);
								}}
							/>
						</RnDDialog>
					)}
				</div>
			</div>
		</Rnd>
	);
}

ElementPalette.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	userData: PropTypes.object.isRequired,
	draggableBounds: PropTypes.string, // selector of bounds in react-draggable
	selectedFields: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	handleFieldsUpdate: PropTypes.func.isRequired,
	handleNewGroup: PropTypes.func.isRequired,
	// handleDelGroup: PropTypes.func.isRequired,
	artworkExtraData: PropTypes.object.isRequired,
	templateFields: PropTypes.array.isRequired, // all fields in the template (current page only)
	zoom: PropTypes.number.isRequired, // the current zoom. e.g. 0.1, 0.25, 1, 1.35, 2, etc.
	paletteTabIndex: PropTypes.number.isRequired,
	setPaletteState: PropTypes.func.isRequired,
	isVideoArtwork: PropTypes.bool.isRequired,
};

ElementPalette.defaultProps = {};
export default ElementPalette;
