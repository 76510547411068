import React from 'react';
import PropTypes from 'prop-types';

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { Route, Switch, Redirect, Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import config from 'config';
import { getRoutes, getRoutesByPath } from './Routes.jsx';
import withStyles from '@mui/styles/withStyles';
import AppLayoutStyle from './AppLayoutStyle.jsx';

import { NotFound } from 'pages';

// custom components
import { Notifier, GlobalDialog, Sidebar, HeaderBar } from 'components';

// redux
import { connect } from 'react-redux';
// import { notifyGeneral } from 'redux/actions'; // actions

import { qs } from 'utils/libHelper';
import { getDomainConfig } from 'utils/appHelper';
import { isWindows } from 'react-device-detect';

/**
 * TODO: remove wrapperHeightOffset after replacing lasso app (Shuaijun Zhang)
 * We are inserting react page into lasso page while refactoring the whole app gradully,
 * the react page doesn't know the height of header bar in lasso app, so if using "100vh" as height, there could be two scroll bar
 * hence we hard-code the header bar in lasso app to calculate the root wrapper height in react
 *
 * But be aware of that some react pages are not inserting into lasso page but running independently, e.g. /toolkit/artdesign?mediaId=000000.
 * So we still use "100vh" for these react pages in the style of the root wrapper <div> as below
 * style={{ height: `calc(100vh - ${ rest.location.pathname.indexOf('artdesign') !== -1 ? 0 : wrapperHeightOffset }px)`, }}
 */
let wrapperHeightOffset = 0;
if (config.isReactOnly) {
	wrapperHeightOffset = 0;
} else if (config.env === 'development') {
	wrapperHeightOffset = 110;
} else if (config.env === 'production') {
	wrapperHeightOffset = 50;
}

const basePath = config.basePath;

const breadcrumbHeight = 50;

const AppBreadcrumbs = ({ routesByPath }) => {
	const classes = makeStyles((theme) => ({
		paperContainer: {
			padding: theme.spacing(1),
			textAlign: 'left',
			color: theme.palette.text.secondary,
			margin: 'auto',
			height: breadcrumbHeight,
			display: 'flex',
			alignItems: 'center',
		},
		breadcrumbs: {
			marginLeft: theme.spacing(2),
		},
	}))();

	return (
		<Route
			path="*"
			exact
			render={(props) => {
				let pathNameWithoutTrailingSlash =
					props.location.pathname.substr(-1) === '/'
						? props.location.pathname.substr(0, props.location.pathname.length - 1)
						: props.location.pathname; // remove trailing slash from pathname
				let routeSetting = routesByPath[pathNameWithoutTrailingSlash];
				let parsedQueryParams = qs.parse(props.location.search);
				let customLastBreadCrumbName = parsedQueryParams[config.customlastPartBreadCrumbKey]
					? decodeURIComponent(parsedQueryParams[config.customlastPartBreadCrumbKey])
					: undefined;
				if (
					(!routeSetting && !customLastBreadCrumbName) ||
					(routeSetting && (routeSetting.redirect || routeSetting.noBreadcrumbs))
				)
					return null;
				let urlParts = props.location.pathname.split('/').filter((val) => val); // get url parts and filter out empty parts
				const rootPathIndex = urlParts.indexOf(basePath.replace('/', ''));
				urlParts = urlParts.slice(rootPathIndex + 1, urlParts.length);
				return (
					<Paper elevation={0} className={classes.paperContainer}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="Breadcrumb"
							className={classes.breadcrumbs}
						>
							<Link color="inherit" href="/">
								Home
							</Link>
							{urlParts.map((part, idx, parts) => {
								let name = part
									.split('_')
									.map((val) => val.charAt(0).toUpperCase() + val.slice(1))
									.join(' ');
								if (idx === parts.length - 1) {
									return (
										<Typography color="textPrimary" key={idx}>
											{customLastBreadCrumbName || name}
										</Typography>
									);
								} else {
									const path = [basePath, ...parts.slice(0, idx + 1)].join('/');
									return (
										<RouterLink to={path} key={idx}>
											{name}
										</RouterLink>
									);
								}
							})}
						</Breadcrumbs>
					</Paper>
				);
			}}
		/>
	);
};

// Authorization route (Check if user has permission to visit the route/page)
// const AuthorizeRoute = ({ component: Component, appProps, ...rest }) => (
// 	// rest is props of Route; appProps is props from AppLayout (class)
// 	<Route {...rest} render={(props) => {
// 		let routeSetting = routesByPath[props.location.pathname.replace(/\/$/, '')];
// 		// Check the required user level (permission) of the routing page
// 		if (appProps.authentication.isAuthorized(routeSetting.requiredUserLevel, routeSetting.requiredFeatures)) {
// 			return <Component {...props} />;
// 		} else {
// 			// the user level doesn't match the required condition, redirect to home page and notify error
// 			appProps.notifyGeneral(`You have no permission to visit the page`, 'error');
// 			// TODO: Use <Redirect /> to redirect to home page after replacing Lasso VID APP with React
// 			return window.location.replace('/');
// 			// return <Redirect to={{ pathname: config.homePath }} />;
// 		}

// 	}} />
// );

let ps; // PerfectScrollbar instance

class AppLayout extends React.Component {
	constructor(props) {
		super(props);
		this.mainPanelRef = React.createRef();
		this.state = {
			mobileOpen: false,
			miniActive: false,
		};
		this.routes = getRoutes({ domain: props.authentication.domainName });
		this.routesByPath = getRoutesByPath({ domain: props.authentication.domainName });

		const setRoutes = (routes = []) => {
			let routeComps = routes
				.map((route) => {
					if (route.redirect)
						return <Redirect exact from={route.path} to={route.to} key={route.path} />;
					if (!props.authentication.isAuthorized(route.requiredUserLevel, route.requiredFeatures))
						// ignore the route and all child routes
						return null;

					let compArray = route.component
						? [<Route exact path={route.path} component={route.component} key={route.path} />]
						: [];

					if (route.children && route.children.length > 0) {
						compArray = compArray.concat(setRoutes(route.children));
					}
					return compArray;
				})
				.flat(Infinity);

			return routeComps;
		};

		this.routeComps = setRoutes(this.routes);
	}

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	sidebarMinimize = () => {
		this.setState({ miniActive: !this.state.miniActive });
	};

	showRoute(route) {
		if (
			route.redirect ||
			!route.sidebarName ||
			!route.sidebarIcon ||
			!this.props.authentication.isAuthorized(route.requiredUserLevel, route.requiredFeatures)
		) {
			return false;
		}
		return true;
	}

	hasBreadCrumb(props) {
		let pathNameWithoutTrailingSlash =
			props.location.pathname.substr(-1) === '/'
				? props.location.pathname.substr(0, props.location.pathname.length - 1)
				: props.location.pathname; // remove trailing slash from pathname
		let routeSetting = this.routesByPath[pathNameWithoutTrailingSlash];
		let parsedQueryParams = qs.parse(props.location.search);
		let customLastBreadCrumbName = parsedQueryParams[config.customlastPartBreadCrumbKey]
			? decodeURIComponent(parsedQueryParams[config.customlastPartBreadCrumbKey])
			: undefined;
		if (
			(!routeSetting && !customLastBreadCrumbName) ||
			(routeSetting && (routeSetting.redirect || routeSetting.noBreadcrumbs))
		) {
			return false;
		}
		return true;
	}

	componentDidMount() {
		if (config.isReactOnly) {
			// && navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(this.mainPanelRef.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
			document.body.style.overflow = 'hidden';
		}
	}

	// componentWillReceiveProps(e) {
	// 	if (e.history.location.pathname !== e.location.pathname) {
	// 		this.refs.mainPanel.scrollTop = 0;
	// 		if (this.state.mobileOpen) {
	// 			this.setState({ mobileOpen: false });
	// 		}
	// 	}
	// }

	componentWillUnmount() {
		if (config.isReactOnly) {
			// && navigator.platform.indexOf('Win') > -1) {
			ps.destroy();
		}
	}

	render() {
		// eslint-disable-next-line no-unused-vars
		const {
			classes,
			authentication,
			// notifyGeneral,
			sidebar,
			generalData,
			...rest
		} = this.props;
		const mainPanelClasses = cx(classes.mainPanel, {
			[classes.mainPanelSidebarMini]: this.state.miniActive,
			[classes.mainPanelWithPerfectScrollbar]: isWindows, // navigator.platform.indexOf('Win') > -1,
			[classes.mainPanelSidebarHidden]: sidebar.hidden,
		});
		const domainConf = getDomainConfig(authentication.domainName);
		return (
			<div
				className={cx(classes.wrapper, { [classes.fixForMobileView]: config.isReactOnly })}
				style={{
					height: `calc(100vh - ${
						rest.location.pathname.indexOf('artdesign') !== -1 ? 0 : wrapperHeightOffset
					}px)`,
				}}
			>
				<Notifier />
				<GlobalDialog />
				{
					// placeholder for Sidebar
					config.isReactOnly && (
						<Sidebar
							routes={this.routes}
							handleDrawerToggle={this.handleDrawerToggle}
							open={this.props.isMobileView ? this.state.mobileOpen : false}
							color="domain"
							bgColor="domain"
							hidden={sidebar.hidden}
							mediaFileCount={generalData.user.countOfTodaysFile}
							miniActive={this.state.miniActive}
							domainConfig={domainConf}
							showRoute={this.showRoute.bind(this)}
							{...rest}
						/>
					)
				}
				<div
					className={mainPanelClasses}
					style={{
						width: !config.isReactOnly ? '100%' : undefined,
					}}
					// ref={this.mainPanelRef}
				>
					{
						// placeholder for Header
						config.isReactOnly && (
							<HeaderBar
								sidebarMinimize={this.sidebarMinimize}
								handleDrawerToggle={this.handleDrawerToggle}
								miniActive={this.state.miniActive}
								title={domainConf.headerBarTitle}
								disableSidebarToggle={sidebar.hidden}
								{...rest}
							/>
						)
					}

					<div className={classes.content} ref={this.mainPanelRef}>
						{/* {!config.isReactOnly ? <AppBreadcrumbs routesByPath={this.routesByPath} /> : null} */}
						{!config.isReactOnly && !domainConf.disableBreadCrumbs ? (
							<AppBreadcrumbs routesByPath={this.routesByPath} />
						) : null}
						<div
							className={classes.container}
							style={{
								// if config.REACT_APP_MODE (Running_Mode) is not React_Only, there will be breadcrumb, then the height of the container needs to subtract "breadcrumbHeight"
								height: `calc(100% - ${
									!config.isReactOnly && this.hasBreadCrumb(this.props) ? breadcrumbHeight : 0
								}px)`,
							}}
						>
							<Switch>
								{this.routeComps}
								<Route component={NotFound} /> {/* No route found. Show NotFound */}
							</Switch>
						</div>
					</div>
					{
						// placeholder for Footer
					}
				</div>
			</div>
		);
	}
}

// export default AppLayout;
AppLayout.propTypes = {
	classes: PropTypes.object.isRequired,
	authentication: PropTypes.object.isRequired,
	// notifyGeneral: PropTypes.func.isRequired,
	sidebar: PropTypes.object.isRequired,
	generalData: PropTypes.object.isRequired,
	isMobileView: PropTypes.bool.isRequired,
};

AppLayout.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication,
		sidebar: state.sidebar,
		generalData: state.generalData,
	};
};

// export default withStyles(AppLayoutStyle)(AppLayout);
export default connect(
	mapStateToProps,
	{}
)(
	withStyles(AppLayoutStyle, { withTheme: true })(
		withMediaQuery([
			['isMobileView', (theme) => theme.breakpoints.down(theme.mobileViewBreakpoint)],
		])(AppLayout)
	)
);

function withMediaQuery(queries = []) {
	return (Component) =>
		function mediaQuery(props) {
			const mediaProps = {};
			queries.forEach((q) => {
				mediaProps[q[0]] = useMediaQuery(q[1]);
			});
			return <Component {...mediaProps} {...props} />;
		};
}
