// ##############################
// // // Artwork Element Palette styles
// #############################

import makeStyles from '@mui/styles/makeStyles';
import { ART_VARIABLES } from '../../Constants';

const ElementPaletteStyle = makeStyles((theme) => ({
	contextualPaletteWrapper: {
		// position: 'absolute',
		// top: 20,
		// right: 60,
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignContent: 'flex-start',
		alignItems: 'flex-start',
		userSelect: 'none',
		// transition: 'opacity 150ms',
		borderRadius: 5,
		width: '100%', //300,
		// height: 400,
		// minHeight: 300,
		height: '100%',
		// maxHeight: 540,
		backgroundColor: '#fff',
		boxShadow: '2px 0px 5px rgba(0,0,0,0.33), -2px -2px 5px rgba(0,0,0,0.33)',
		overflow: 'hidden',
		// opacity: 1,
		zIndex: 0,
	},
	contextualPaletteClosed: {
		// opacity: 0,
		visibility: 'hidden',
	},
	contextualPaletteTitle: {
		width: '100%',
		display: 'flex',
		alignItems: 'baseline',
		position: 'relative',
		color: ART_VARIABLES.cssStyles.paletteTitleColor,
		backgroundColor: ART_VARIABLES.cssStyles.paletteTitleBGColor,
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 16,
		// lineHeight: 20,
		textTransform: 'uppercase',
		userSelect: 'none',
		whiteSpace: 'nowrap',
		// overflow: 'hidden',
		cursor: 'move',
		height: 40,
		// borderTopLeftRadius: 4,
		// borderTopRightRadius: 4,
		padding: '10px 15px',
		paddingRight: 30,
	},
	contextualPaletteCloseButton: {
		position: 'absolute',
		top: 10,
		right: 15,
		height: 20,
		cursor: 'pointer',
	},
	contextualPaletteContent: {
		width: '100%',
		// minHeight: 260,
		height: `calc(100% - 40px)`,
		color: ART_VARIABLES.cssStyles.paletteTitleColor,
		backgroundColor: ART_VARIABLES.cssStyles.paletteTitleBGColor,
		// maxHeight: 500,
		// overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		alignItems: 'flex-start',
		// justifyContent: 'center',

		justifyContent: 'flex-start',
		flexDirection: 'column',
		fontSize: ART_VARIABLES.cssStyles.paletteFontSize,
	},
	// textPaletteWrapper: {
	// 	width: '100%',
	// 	height: '100%',
	// 	color: 'inherit',
	// 	backgroundColor: 'inherit',
	// 	position: 'relative',
	// 	display: 'flex',
	// 	alignItems: 'flex-start',
	// 	justifyContent: 'flex-start',
	// 	flexDirection: 'column',
	// 	fontSize: ART_VARIABLES.cssStyles.paletteFontSize,
	// 	// paddingBottom: theme.spacing(3),
	// 	// overflow: 'hidden',
	// 	// '&:before': {
	// 	// 	content: '""',
	// 	// 	paddingTop: theme.spacing(1),
	// 	// },
	// },
	tabsWrapper: {
		width: '100%',
		backgroundColor: 'inherit',
		height: 48,
		color: 'inherit',
		// flex: '0 0 auto',
		// '&:before': {
		// 	content: '""',
		// },
		// '&:after': {
		// 	content: '""',
		// 	paddingTop: theme.spacing(1),
		// },
	},
	paletteWrapper: {
		width: '100%',
		minHeight: `calc(100% - 48px)`,
		color: ART_VARIABLES.cssStyles.paletteContentColor,
		backgroundColor: ART_VARIABLES.cssStyles.paletteContentBGColor,
		// flex: '1 0 auto',
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(2),
		// '&:before': {
		// 	content: '""',
		// },
		// '&:after': {
		// 	content: '""',
		// 	paddingTop: theme.spacing(1),
		// },
	},
}));

export default ElementPaletteStyle;
