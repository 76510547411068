import React from 'react';
import PropTypes from 'prop-types';
import { ART_VARIABLES } from '../../Constants';
const { keyCodes } = ART_VARIABLES;
function KeyboardListener({ onSelectionBump, onSelectionDelete, onUndo, onRedo, ...rest }) {
	let bumpRef = React.useRef();
	let deleteRef = React.useRef();
	let onUndoRef = React.useRef();
	let onRedoRef = React.useRef();
	React.useEffect(() => {
		bumpRef.current = onSelectionBump;
		deleteRef.current = onSelectionDelete;
		onUndoRef.current = onUndo;
		onRedoRef.current = onRedo;
	});
	const handleKeydown = (e) => {
		// if (e.target !== document.body && e.target.tagName.toUpperCase() === 'INPUT') {
		if (
			e.target !== document.body &&
			!(e.target instanceof HTMLButtonElement || e.target instanceof HTMLDivElement)
		) {
			return;
		}
		switch (e.keyCode) {
			case keyCodes.backspaceKey:
			case keyCodes.deleteKey:
				e.preventDefault();
				deleteRef.current();
				break;

			case keyCodes.upKey:
			case keyCodes.downKey:
			case keyCodes.leftKey:
			case keyCodes.rightKey:
				e.preventDefault();
				bumpRef.current(e);
				break;
			case keyCodes.zKey:
				if ((e.ctrlKey || e.metaKey) && !e.shiftKey) {
					// undo
					onUndoRef.current(e);
				} else if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
					// redo
					onRedoRef.current(e);
				}
				break;
			case keyCodes.yKey:
				if (e.ctrlKey || e.metaKey) {
					// redo
					onRedoRef.current(e);
				}
				break;
			default:
				break;
		}
	};

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeydown, true);
		return () => {
			document.removeEventListener('keydown', handleKeydown, true);
			// console.log(`remove keydown listener`);
		};
	}, []);

	return null;
}

KeyboardListener.propTypes = {
	onSelectionDelete: PropTypes.func.isRequired,
	onSelectionBump: PropTypes.func.isRequired,
	onUndo: PropTypes.func.isRequired,
	onRedo: PropTypes.func.isRequired,
};

export default KeyboardListener;
