import React from 'react';

import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';

import { MUIDatetimeNTimePickerPop } from 'components';
import { moment } from 'utils/libHelper';
import { format as dateFnsFormat } from 'date-fns';

// icons
import {
	DateRange as DateRangeIcon,
	AccessTime as AccessTimeIcon,
	// Edit as EditIcon,
} from '@mui/icons-material';

// redux
import { connect } from 'react-redux';
import { notifyError } from 'redux/actions'; // actions
import { FormattedMessage } from 'react-intl';

const SlideScheduleStyle = (theme) => ({
	wrapper: {
		whiteSpace: 'normal',
	},
	chip: {
		margin: theme.spacing(1),
	},
	datetimeInput: {
		padding: 0,
	},
	datetimeIcon: {
		paddingBottom: theme.spacing(1),
	},
	datetime: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		maxWidth: 180,
	},
	time: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		maxWidth: 100,
	},
});

class SlideSchedule extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openDatetimePicker: false,
			openTimePicker: false, // the editing data of a screen
			dateTimePickerValue: new Date(),
			editingKey: '', // the adding data of a screen
			editingOutputFormat: '',
		};
	}

	isValidateSchedule = (schedule) => {
		if (schedule.availableStart && schedule.availableEnd) {
			let availableStart = moment(schedule.availableStart);
			let availableEnd = moment(schedule.availableEnd);
			if (availableEnd.isBefore(availableStart)) {
				this.props.notifyError(new Error('End Date can not before the Start Date!'));
				return false;
			}
		}
		if (schedule.visibleStartInADay && schedule.visibleEndInADay) {
			let visibleStartInADay = moment(schedule.visibleStartInADay, 'HH:mm:00');
			let visibleEndInADay = moment(schedule.visibleEndInADay, 'HH:mm:00');
			if (visibleEndInADay.isBefore(visibleStartInADay)) {
				this.props.notifyError(new Error('Daily End Time can not before the Start Time!'));
				return false;
			}
		}
		return true;
	};

	render() {
		const { classes, schedule, isEditing, onScheduleChange, intl } = this.props;
		const { openDatetimePicker, openTimePicker, dateTimePickerValue, editingKey } = this.state;
		const DoWSuperSet = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

		return Object.keys(schedule).length === 0 && !isEditing ? (
			<div>{intl.formatMessage({ id: 'GENERAL.N/A' })}</div>
		) : (
			<div className={classes.wrapper}>
				{
					<FormattedMessage
						id="pages.ScreenManager.components.Slides.SlideSchedule.DateSchedule"
						values={{
							startDT: (
								<Chip
									size="small"
									icon={<DateRangeIcon />}
									label={
										schedule.availableStart ? (
											moment(schedule.availableStart).format('YYYY-MM-DD HH:mm:00')
										) : (
											<FormattedMessage id="GENERAL.Now" />
										)
									}
									className={classes.chip}
									color="primary"
									clickable={isEditing}
									onClick={
										isEditing
											? () => {
													this.setState({
														openDatetimePicker: true,
														dateTimePickerValue: schedule.availableStart
															? new Date(schedule.availableStart)
															: new Date(),
														editingKey: 'availableStart',
													});
											  }
											: undefined
									}
									onDelete={
										isEditing
											? () => {
													let currentSchedule = { ...schedule };
													delete currentSchedule.availableStart;
													onScheduleChange(currentSchedule);
											  }
											: undefined
									}
									// deleteIcon={<EditIcon />}
								/>
							),
							endDT: (
								<Chip
									size="small"
									icon={<DateRangeIcon />}
									label={
										schedule.availableEnd ? (
											moment(schedule.availableEnd).format('YYYY-MM-DD HH:mm:00')
										) : (
											<FormattedMessage id="GENERAL.Forever" />
										)
									}
									className={classes.chip}
									color="primary"
									clickable={isEditing}
									onClick={
										isEditing
											? () => {
													this.setState({
														openDatetimePicker: true,
														dateTimePickerValue: schedule.availableEnd
															? new Date(schedule.availableEnd)
															: new Date(),
														editingKey: 'availableEnd',
													});
											  }
											: undefined
									}
									onDelete={
										isEditing
											? () => {
													let currentSchedule = { ...schedule };
													delete currentSchedule.availableEnd;
													onScheduleChange(currentSchedule);
											  }
											: undefined
									}
									// deleteIcon={<EditIcon />}
								/>
							),
						}}
					/>
				}
				<br />
				<FormattedMessage id="pages.ScreenManager.components.Slides.SlideSchedule.DayOfWeekSchedule" />
				{
					<span>
						{DoWSuperSet.map((slotOptn) => (
							<Chip
								label={
									!isEditing && schedule.dayOfWeek && schedule.dayOfWeek.indexOf(slotOptn) === -1
										? '---'
										: slotOptn.substr(0, 2)
								}
								style={{ padding: 0 }}
								color="primary"
								size="small"
								// disableElevation
								key={slotOptn}
								clickable={isEditing}
								variant={
									schedule.dayOfWeek && schedule.dayOfWeek.indexOf(slotOptn) === -1
										? 'outlined'
										: 'default'
								}
								onClick={() => {
									let newDayOfWeek = schedule.dayOfWeek ? [...schedule.dayOfWeek] : DoWSuperSet;
									// add option if its absent, remove if it exists
									if (newDayOfWeek.indexOf(slotOptn) === -1) {
										newDayOfWeek.splice(newDayOfWeek.length, 0, slotOptn);
										// sort the array again based on the super set.
										newDayOfWeek = DoWSuperSet.filter((day) => newDayOfWeek.indexOf(day) !== -1);
									} else {
										newDayOfWeek.splice(newDayOfWeek.indexOf(slotOptn), 1);
									}
									// Reset back the empty list to undefined
									newDayOfWeek = newDayOfWeek.length === 0 ? undefined : newDayOfWeek;
									let newSchedule = { ...schedule, dayOfWeek: newDayOfWeek };
									onScheduleChange(newSchedule);
								}}
							/>
						))}
					</span>
				}
				<br />
				{!schedule.visibleStartInADay && !schedule.visibleEndInADay && !isEditing ? (
					<div>
						{intl.formatMessage({
							id: 'pages.ScreenManager.components.Slides.SlideSchedule.AllDayText',
						})}
					</div>
				) : (
					<div>
						{
							<FormattedMessage
								id="pages.ScreenManager.components.Slides.SlideSchedule.TimeSchedule"
								values={{
									startTime: (
										<Chip
											size="small"
											icon={<AccessTimeIcon />}
											label={schedule.visibleStartInADay || '00:00:00'}
											className={classes.chip}
											color="secondary"
											clickable={isEditing}
											onClick={() => {
												if (!isEditing) return;
												let [hh, mm, ss] = (schedule.visibleStartInADay || '00:00:00').split(':');
												this.setState({
													openTimePicker: true,
													dateTimePickerValue: moment()
														.set({ hour: hh, minute: mm, second: ss })
														.toDate(),
													editingKey: 'visibleStartInADay',
												});
											}}
											onDelete={
												isEditing
													? () => {
															let currentSchedule = { ...schedule };
															delete currentSchedule.visibleStartInADay;
															onScheduleChange(currentSchedule);
													  }
													: undefined
											}
											// deleteIcon={<EditIcon />}
										/>
									),
									endTime: (
										<Chip
											size="small"
											icon={<AccessTimeIcon />}
											label={schedule.visibleEndInADay || '23:59:59'}
											className={classes.chip}
											color="secondary"
											clickable={isEditing}
											onClick={() => {
												if (!isEditing) return;
												let [hh, mm, ss] = (schedule.visibleEndInADay || '00:00:00').split(':');
												this.setState({
													openTimePicker: true,
													dateTimePickerValue: moment()
														.set({ hour: hh, minute: mm, second: ss })
														.toDate(),
													editingKey: 'visibleEndInADay',
												});
											}}
											onDelete={
												isEditing
													? () => {
															let currentSchedule = { ...schedule };
															delete currentSchedule.visibleEndInADay;
															onScheduleChange(currentSchedule);
													  }
													: undefined
											}
											// deleteIcon={<EditIcon />}
										/>
									),
								}}
							/>
						}
					</div>
				)}
				{/* Initializing the picker only when its needed. The picker has a bug which prevents it from updating otherwise */}
				{(openDatetimePicker || openTimePicker) && (
					<MUIDatetimeNTimePickerPop
						isOpenDatetime={openDatetimePicker}
						isOpenTime={openTimePicker}
						initValue={dateTimePickerValue}
						onChange={(date) => {
							// this.setState({tempSchedule: {...tempSchedule, [editingKey]: date.utc().format('HH:mm:00')}})
							let newSchedule = {};
							if (['visibleEndInADay', 'visibleStartInADay'].includes(editingKey)) {
								// let initTimeData = {visibleStartInADay: '00:00:00', visibleEndInADay:'23.59.59'};
								// newSchedule = {...initTimeData, ...schedule, [editingKey]: date.utc().format('HH:mm:00')}
								newSchedule = { ...schedule, [editingKey]: dateFnsFormat(date, 'HH:mm:00') };
							} else {
								newSchedule = { ...schedule, [editingKey]: date.toISOString() };
							}

							if (this.isValidateSchedule(newSchedule)) {
								onScheduleChange(newSchedule);
							}
						}}
						onClose={() => {
							this.setState({ openDatetimePicker: false, openTimePicker: false });
						}}
					/>
				)}
			</div>
		);
	}
}

SlideSchedule.propTypes = {
	classes: PropTypes.object.isRequired,
	schedule: PropTypes.object.isRequired,
	isEditing: PropTypes.bool,
	onScheduleChange: PropTypes.func.isRequired,
	notifyError: PropTypes.func.isRequired,
};

SlideSchedule.defaultProps = {
	isEditing: false,
};

// export default withStyles(SlideScheduleStyle)(SlideSchedule);
// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { notifyError })(
	withStyles(SlideScheduleStyle)(SlideSchedule)
);
