import React from 'react';
import { create } from 'jss'; // jss lib is used by MUI, we have MUI, hence we also have jss lib installed. (it doesn't have to be in package.json)
import jssPreset from '@mui/styles/jssPreset';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';

import {
	hideFieldOutput,
	calcAnimationDelay,
	isConcatField,
	getConcatFieldType,
} from 'utils/artwork/artUtilsCommon';
import { isValidFieldForVideoArtwork } from 'utils/artwork/artUtilsGenerator';
import { createAnimationFadeInOutKeyframesInVideoArtwork } from 'utils/artwork/artUtilsWebUI';
import { NS } from 'utils/artwork/constants';

import Text from './Text';
import Video from './Video';
import Image from './Image';
import Barcode from './Barcode';
import Pdf from './Pdf';
import ConcatText from './ConcatText';
import ConcatImageOnly from './ConcatImageOnly';
import Grid from './Grid';

const fieldTypePreviewCompMap = {
	text: Text,
	video: Video,
	image: Image,
	barcode: Barcode,
	pdf: Pdf,
	grid: Grid,
};
// const useStyles = makeStyles(theme => ({
// 	previewWrapper: {
// 		position: 'absolute',
// 		top: 0,
// 		left: 0,
// 		// bottom: 0,
// 		// right: 0,
// 		width: '100%',
// 		height: '100%',
// 		backfaceVisibility: 'hidden',
// 	},
// }));

const svgPreviewJssInst = create({
	plugins: [...jssPreset().plugins],
	// eslint-disable-next-line no-unused-vars
	createGenerateId: () => (rule, sheet) => rule.name,
});

function SVGPreview({
	fields,
	// innerRef,
	// fieldsPreviewValue,
	fieldOutputData,
	allowAnimation,
	pausingAnimation,
	width,
	height,
	isVideoArtwork,
	...rest
}) {
	// const classes = useStyles();
	// const intl = useIntl();

	// const findTextFontSize = (text, initFontSize) => {
	// 	const svg = document.createElementNS(NS.SVG, 'svg');
	// 	document.body.append(svg);
	// 	const rect = document.createElementNS(NS.SVG, 'rect');
	// 	rect.setAttribute('width', '1em');
	// 	rect.setAttribute('height', '1ex');
	// 	rect.setAttribute('x', '1in');
	// 	svg.append(rect);
	// 	const bb = rect.getBBox();
	// 	svg.remove();
	// };
	// let animitionJSSStyles = fields
	// 	.map(field => {
	// 		let animStyles = {};
	// 		if (field.animation.entrance) {
	// 			animStyles[`@keyframes ${field.animation.entrance}`] =
	// 				ART_VARIABLES.animations[`@keyframes ${field.animation.entrance}`];
	// 		}

	// 		if (field.animation.exit) {
	// 			animStyles[`@keyframes ${field.animation.exit}`] =
	// 				ART_VARIABLES.animations[`@keyframes ${field.animation.exit}`];
	// 		}

	// 		return animStyles;
	// 	})
	// 	.reduce((accu, animStyle) => {
	// 		return { ...accu, ...animStyle };
	// 	}, {});
	// let sheet = svgPreviewJssInst.createStyleSheet(animitionJSSStyles);

	// const animitionCss = sheet.toString();

	return (
		<svg
			// ref={innerRef}
			viewBox={`0 0 ${width} ${height}`}
			preserveAspectRatio="xMidYMin meet" //??? is it necessary?default
			overflow="hidden"
			// style={{ border: '1px solid black' }}
			// width={width * zoom}	// no width set here, so the width is default "auto", treat as 100% (to its parent)
			// height={height * zoom} // no height set here, so the height is default "auto", treat as 100% (to its parent)
			// style={{ border: '1px solid black' }}
			// fontFamily='"Roboto", "Helvetica", "Arial", sans-serif'
			xmlns={NS.SVG} //"http://www.w3.org/2000/svg"
		>
			{
				// 	<style type="text/css">
				// 	{Array.from(
				// 		new Set(
				// 			fields
				// 				.filter(f => f.type === 'text')
				// 				.map(f =>
				// 					f.formatNumberStyle.currencyFontName === ART_VARIABLES.placeholderSameAsText
				// 						? [f.fontfaceName]
				// 						: [f.fontfaceName, f.currencyFontName]
				// 				)
				// 				.flat()
				// 		)
				// 	).map(fontName => {
				// 		let fontBase64Url = getFontfaceBase64(fontName);
				// 		return fontBase64Url
				// 			? `
				// 				@font-face {
				// 					font-family: "${fontName}";
				// 					src: url("${fontBase64Url.fontUrl}")${
				// 					getFontfaceFormatByFileNameExtersion(fontName)
				// 						? ' format("' + getFontfaceFormatByFileNameExtersion(fontName) + '")'
				// 						: ''
				// 			  };
				// 				}
				// 			`
				// 			: null;
				// 	})}
				// </style>
			}

			<style type="text/css" id="animationStyles">
				{
					// Generate animation css styles used in fields
					svgPreviewJssInst
						.createStyleSheet(
							fields
								.map((field) => {
									let animStyles = {};
									if (isVideoArtwork) {
										// isValidFieldForVideoArtwork "if" statement should not be moved to its parent
										if (
											isValidFieldForVideoArtwork(
												field,
												ART_VARIABLES.supportedFieldTypesInVideoArtwork
											)
										) {
											animStyles[
												`@keyframes fadeInOutVideoArtwork-${field.id}`
											] = createAnimationFadeInOutKeyframesInVideoArtwork(field.insertionOnVideo);
										}
										// else {
										// 	// do nothing, return empty object {} - animStyles
										// }
									} else {
										if (field.animation.entrance) {
											animStyles[`@keyframes ${field.animation.entrance}`] =
												ART_VARIABLES.animations[`@keyframes ${field.animation.entrance}`];
										}

										if (field.animation.exit) {
											animStyles[`@keyframes ${field.animation.exit}`] =
												ART_VARIABLES.animations[`@keyframes ${field.animation.exit}`];
										}
									}

									return animStyles;
								})
								.reduce((accu, animStyle) => {
									return { ...accu, ...animStyle };
								}, {})
						)
						.toString()
				}
			</style>

			{/* the linearGradient & rect are for testing only, can be deleted */}
			{
				// 	<defs>
				// 	<linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
				// 		<stop offset="0%" stopColor="#dee2d7" />
				// 		<stop offset="50%" stopColor="#e35b43" stopOpacity="0" />
				// 		<stop offset="100%" stopColor="#dbc5b1" />
				// 	</linearGradient>
				// </defs>
				// <rect width="100%" height="100%" fill="url(#Gradient2)" />
			}

			{fields.map((f, idx) => {
				// Verify output setting of this field. TODO: Need to revise the logic to make sure it works
				if (hideFieldOutput(f, fields, fieldOutputData)) return null;
				// remove unsupported or mis-configured field from the preview on video artwork
				if (
					isVideoArtwork &&
					!isValidFieldForVideoArtwork(f, ART_VARIABLES.supportedFieldTypesInVideoArtwork)
				)
					return null;
				// if (f.hideOutput) return null;
				// if (f.outputDependsOn) {
				// 	let dependOnField = fields.find(f => f.id === f.outputDependsOn);
				// 	if (!dependOnField) return null;

				// 	// the behaviour on dependOn field for output on the event of displaying preview
				// 	if (
				// 		!fieldOutputData[dependOnField.id].value || // check the value in depend-on text/barcode field to see if it has value
				// 		!fieldOutputData[dependOnField.id].previewUrl // check the previewUrl in depend-on image/pdf/video field to see if it has value
				// 	) {
				// 		// the depend on field doesn't has value (neither default value), hence this field will not be redendered
				// 		return null;
				// 	}
				// }
				let animationDelay = calcAnimationDelay(fields, f, fieldOutputData);
				if (isConcatField(f)) {
					if (getConcatFieldType(f, fields) === 'IMAGE_ONLY')
						return (
							<ConcatImageOnly
								key={f.id + idx + f.groupName}
								animationDelay={animationDelay}
								allowAnimation={allowAnimation}
								pausingAnimation={pausingAnimation}
								field={f}
								isVideoArtwork={isVideoArtwork}
								templateFields={fields}
								fieldOutputData={fieldOutputData}
								// fieldPreviewData={fieldOutputData[f.id]} // fieldOutputData is guaranteed to have value for each field, hence not using fallback value: fieldOutputData[f.id] || {}
							/>
						);
					else
						return (
							<ConcatText
								key={f.id + idx + f.groupName}
								animationDelay={animationDelay}
								allowAnimation={allowAnimation}
								pausingAnimation={pausingAnimation}
								field={f}
								isVideoArtwork={isVideoArtwork}
								templateFields={fields}
								fieldOutputData={fieldOutputData}
								// fieldPreviewData={fieldOutputData[f.id]} // fieldOutputData is guaranteed to have value for each field, hence not using fallback value: fieldOutputData[f.id] || {}
							/>
						);
				}
				const PreviewComp = fieldTypePreviewCompMap[f.type] || null;
				return PreviewComp ? (
					<PreviewComp
						key={f.id + idx + f.groupName}
						animationDelay={animationDelay}
						allowAnimation={allowAnimation}
						pausingAnimation={pausingAnimation}
						field={f}
						isVideoArtwork={isVideoArtwork}
						fieldPreviewData={fieldOutputData[f.id]} // fieldOutputData is guaranteed to have value for each field, hence not using fallback value: fieldOutputData[f.id] || {}
					/>
				) : null;
			})}
		</svg>
	);
}

SVGPreview.propTypes = {
	// innerRef: PropTypes.any.isRequired, // ref of svg
	fields: PropTypes.array.isRequired, // all fields in artwork design template
	// fieldsPreviewValue: PropTypes.object.isRequired, // the preview data of each field
	/**
	 * field output data, combination of user input data & field default data
	 * guaranteed any value is not null|undefined, it will be '', true/false or "actual value"
	 */
	fieldOutputData: PropTypes.object.isRequired,
	// zoom: PropTypes.number.isRequired, // double/float. the current zoom. e.g. 0.1, 0.25, 1, 1.35, 2, etc.
	// zoomedWidth: PropTypes.number.isRequired, // display/render width
	// zoomedHeight: PropTypes.number.isRequired, // display/render height
	width: PropTypes.number.isRequired, // actual/viewbox width
	height: PropTypes.number.isRequired, // actual/viewbox height
	allowAnimation: PropTypes.bool.isRequired, // is annimation enabled
	pausingAnimation: PropTypes.bool.isRequired, // is annimation paused. (most likely on video end)

	isVideoArtwork: PropTypes.bool.isRequired,
};

SVGPreview.defaultProps = {};
export default SVGPreview;
