/**
 * Retrieve units from string
 * @param {string} unitStr
 * 
 * @return {array}.
 	[
		 {
			 unitText: '1.2534kg', // full unit text including number. e.g. '2.345kg', '1.99 ml', etc.
			 unitNum: 1.2534, // unit decimal number. e.g. 2.345, 1.99
			 unitName: 'kg'	// unit text. e.g. 'kg', 'ml', etc.
		 },
		 ...
	]
 */
const retrieveUnits = (unitStr) => {
	// let unitStr = 'avc1.234 kg / 2.34kilog erf';
	let matches = [],
		reg = /(\d+(?:\.\d+)?)\s?(\w+)/gim,
		m = null;
	do {
		m = reg.exec(unitStr);
		// console.log(m);
		if (m) {
			matches.push({ unitText: m[0], unitNum: parseFloat(m[1]), unitName: m[2] });
		}
	} while (m);
	return matches;
};

/**
 * International System of Units (SI)
 */
const MASS_UNIT = {
	siUnitName: 'g', // SI unit name in mass unit
	siPrefix: 'k', // SI preview in mass unit
	synonyms: ['g', 'gr', 'gram', 'grams', 'gramme', 'grammes'],
	pseudo: {
		kilo: {
			siPrefix: 'k',
			siUnitName: 'g',
		},
		k: {
			siPrefix: 'k',
			siUnitName: 'g',
		},
	},
};
const VOLUME_UNIT = {
	siUnitName: 'L', // NB: m^3 is actually the official SI unit
	siPrefix: '',
	synonyms: ['l', 'litre', 'liter', 'liters', 'litres', 'lt', 'ltr'],
	pseudo: {
		mil: {
			siPrefix: 'm',
			siUnitName: 'l',
		},
	},
};

const VALID_SI_PREFIXES = {
	shorthand: {
		y: 1e-24,
		z: 1e-21,
		a: 1e-18,
		f: 1e-15,
		p: 1e-12,
		n: 1e-9,
		u: 1e-6,
		μ: 1e-6, // mew, μ, \u03BC
		// `\u03BC`: 1e-6,
		m: 1e-3,
		c: 1e-2,
		d: 1e-1,
		'': 1,
		da: 1e1,
		K: 1e3, // HACK: people often mistakenly write KG
		k: 1e3,
		//"M"       :1e+06,   // HACK: retail people tend to use SI prefixes pretty liberally (e.g. M means 'Milli').
		M: 1e-3, // Over-writing 'M' to represent 1e-03 until we find a clever way of getting around this (subclassing unit to RetailUnit I guess?)
		G: 1e9,
		T: 1e12,
		P: 1e15,
		E: 1e18,
		Z: 1e21,
		Y: 1e24,
	},
	// NB:  longhand keys lowercase!
	longhand: {
		yotta: 1e24,
		zetta: 1e21,
		exa: 1e18,
		peta: 1e15,
		tera: 1e12,
		giga: 1e9,
		mega: 1e6,
		kilo: 1e3,
		hecto: 1e2,
		deca: 1e1,
		deci: 1e-1,
		'': 1,
		centi: 1e-2,
		milli: 1e-3,
		micro: 1e-6,
		nano: 1e-9,
		pico: 1e-12,
		femto: 1e-15,
		atto: 1e-18,
		zepto: 1e-21,
		yocto: 1e-24,
	},
};

const supportedUnits = [MASS_UNIT, VOLUME_UNIT];

/**
 *
 * @param {string} unitTextStr
 *
 * @return {object|null}. If no unit or multiple units found, return null, otherwise return
	{
		unitText: '1.2534kg', // (original) full unit text including number. e.g. '2.345kg', '1.99 ml', etc.
		unitNum: 1.2534, // (original) unit decimal number. e.g. 2.345, 1.99
		unitName: 'kg'	// (original) unit text. e.g. 'kg', 'ml', etc.
		numOfUnits: 0|1|2|..., // number of unit, could be 0, 1, multiple units
		siPrefix: 'Prefix_unit'|null|undefined; // e.g. 'm', 'k', '', etc.
		siUnitName: 'SI_UnitName'|null|undefined; // e.g. 'litre', 'gram', etc.
		siNotation: 'longhand'|'shorthand'|null|undefined; // indicate the siPrefix is "shorthand" or "longhand" if there is value;
		coefficient: 1e-3,
		type: {
			siPrefix: 'k|""', // SI prefix in this unit type (mass | volumn)
			siUnitName: 'g|L', // SI unit name in this unit type (mass | volumn)
		};
	},
 */
const buildUnit = (unitTextStr) => {
	let units = retrieveUnits(unitTextStr.trim());
	if (units.length === 0 || units.length > 1) {
		// NB: when there is no number in unitTextStr, units.length is 0
		return { unitNum: 1, coefficient: 1, numOfUnits: units.length };
	}
	let unit = units[0];
	unit.numOfUnits = 1;
	// get the unit prefix, unit name
	// First, check for pseudo-units (e.g. kilo, mil, k)
	for (let i = 0; i < supportedUnits.length; i++) {
		if (Object.keys(supportedUnits[i].pseudo).includes(unit['unitName'])) {
			let pseudo = supportedUnits[i].pseudo[unit['unitName']];
			unit = {
				...unit,
				...pseudo,
				siNotation: 'shorthand',
				coefficient: VALID_SI_PREFIXES.shorthand[pseudo.siPrefix],
				type: {
					siPrefix: supportedUnits[i].siPrefix,
					siUnitName: supportedUnits[i].siUnitName,
				},
			}; // append {siPrefix: 'XXXXX', siUnitName: 'XXXXXX', siNotation: 'shorthand', coefficient: 1e-3, type: {}}
		}
	}

	if (!unit.siPrefix || !unit.siUnitName) {
		// we haven't matched a pseudo-unit, check for real units
		// Iterate over all the character splits in the supplied unit, trying to find a (siPrefix, siUnit) pair
		let suppliedUnitName = unit['unitName'];
		for (let i = 0; i < suppliedUnitName.length; i++) {
			let potentialPrefix = suppliedUnitName.substring(0, i).trim();
			let potentialUnitName = suppliedUnitName.substring(i).trim();
			for (let j = 0; j < supportedUnits.length; j++) {
				if (
					Object.keys(VALID_SI_PREFIXES.shorthand).includes(potentialPrefix) &&
					supportedUnits[j].synonyms.includes(potentialUnitName.toLowerCase())
				) {
					unit.siPrefix = potentialPrefix;
					unit.siUnitName = potentialUnitName;
					unit.siNotation = 'shorthand';
					unit.coefficient = VALID_SI_PREFIXES.shorthand[potentialPrefix];
					unit.type = {
						siPrefix: supportedUnits[j].siPrefix,
						siUnitName: supportedUnits[j].siUnitName,
					};
					break;
				} else if (
					Object.keys(VALID_SI_PREFIXES.longhand).includes(potentialPrefix.toLowerCase()) &&
					supportedUnits[j].synonyms.includes(potentialUnitName.toLowerCase())
				) {
					unit.siPrefix = potentialPrefix;
					unit.siUnitName = potentialUnitName;
					unit.siNotation = 'longhand';
					unit.coefficient = VALID_SI_PREFIXES.longhand[potentialPrefix];
					unit.type = {
						siPrefix: supportedUnits[j].siPrefix,
						siUnitName: supportedUnits[j].siUnitName,
					};
					break;
				}
			}

			if (unit.siPrefix && unit.siUnitName && unit.coefficient) break;
		}
	}

	if (!unit.siUnitName) {
		// can't retrieve unit, the supplied unit may be not supported. We treat it as no unit
		console.debug(`Can't build unit with supplied text "${unitTextStr}"`);
		return { unitNum: 1, coefficient: 1, numOfUnits: 0 };
	} else {
		return unit;
	}
};

/**
 * build price object
 * @param {string} priceStr
 *
 * @return {array}
	[
		{
			priceStr: priceStr,
			currency: '',
			priceNum: 1.0,
		}
	]
 */
const buildPrice = (priceStr) => {
	// define default price object
	let priceObject = {
		priceStr: priceStr,
		currency: '',
		priceNum: 1.0,
	};
	/**
	 * Regex to extract price number
	 * Ref: https://stackoverflow.com/questions/31399501/regex-extracting-price-or-number-value-from-string-in-javascript
	 *
	 * extract only positive price number: /\d+(?:,\d{3})*(?:\.\d+)?/g
	 * extract any price number (positive & negitive): /-?\d+(?:,\d{3})*(?:\.\d+)?/g
	 *
	 * test string: "RegExr was crea£1.23ted b-3.45y gskin1,090.99ner.com, and is proudly hosted by Media Tem" => "1.23", "3.45", "1,090.99"
	 */
	let matches = [],
		reg = /\d+(?:,\d{3})*(?:\.\d+)?/gm,
		m = null;
	do {
		m = reg.exec(priceStr);
		// console.log(m);
		if (m) {
			matches.push({ ...priceObject, priceNum: parseFloat(m[0].replace(',', '')) });
		}
	} while (m);
	if (matches.length === 1) {
		// get currency. the currency is whatever the alphabeta letters in the priceStr. It could be wrong currency, e.g. ABC2.99, currency is "ABC"
		matches[0].currency = matches[0].priceStr.replace(/[\d.,\s]+/g, '');
	}
	return matches;
};

/**
 * build price object
 * @param {string} qtyStr
 *
 * @return {array}. array of quantity number (decimal). mostly it has one item, but in some cases, it could be empty or multiple item
	[
		1.2,
		...
	]
 */
const buildQty = (qtyStr) => {
	let matches = [],
		reg = /\d+(?:\.\d+)?/gm,
		m = null;
	do {
		m = reg.exec(qtyStr);
		if (m && !isNaN(parseFloat(m[0]))) {
			matches.push(parseFloat(m[0]));
		}
	} while (m);
	return matches;
};

/**
 *
 * @param {string} priceStr
 * @param {string} unitStr
 * @param {string} qtyStr
 * @param {string} outputUnitStr optional
 * @param {object} opts
	{
		currencySymbol: '', // currency symbol in the output. If no currency specified, no currency in the output
		currencyPosition: 'leading'|'trailing', // currency position. default leading
		perStr: 'XXXXX', // per string. default '/'
		multiUnitMsg: 'XXXX', // display text when there are multiple units
		multiPriceMsg: 'xxxx', // display text when there are multiple prices
	}

	@returns {string}. Calculated price string. can be "" empty string
 */
const PricePerUnit = (priceStr, unitStr = '', qtyStr = '1', outputUnitStr = '', opts = {}) => {
	if (!priceStr) return '';
	let currencySymbol = opts.currencySymbol || '',
		currencyPosition = opts.currencyPosition || 'leading',
		perStr = opts.perStr || '/',
		multiUnitMsg = opts.multiUnitMsg || 'Various Packs Available',
		multiPriceMsg = opts.multiPriceMsg || 'Various Price Available';

	// TODO: handle if unitStr has no number

	let inputUnit = buildUnit(unitStr),
		outputUnit = {},
		priceNum = 0,
		qtyNum = 0,
		noUnit = false; // if true, there is no unit

	if (inputUnit.numOfUnits > 1) {
		// no unit or multiple units
		return multiUnitMsg;
	} else if (inputUnit.numOfUnits === 0 || !inputUnit.unitNum) {
		// if unitStr has no number, we treat it as noUnit and use "PRICE each" in output
		noUnit = true;
		outputUnit = inputUnit;
	} else {
		if (!outputUnitStr) {
			// outputUnitStr has no value, we use SI prefix & unitname in the unit type of the inputUnit with unit number 1 by default
			outputUnit = buildUnit(`1${inputUnit.type.siPrefix}${inputUnit.type.siUnitName}`);
		} else if (isNaN(parseFloat(outputUnitStr))) {
			// outputUnitStr has value, but no number, we add number 1 to its beginning
			outputUnit = buildUnit('1' + outputUnitStr);
		} else {
			// outputUnitStr is a valid unit string
			outputUnit = buildUnit(outputUnitStr);
		}
		if (outputUnit.numOfUnits === 0) {
			// unsupported output unit. we use SI prefix & unitname in the unit type of the inputUnit with number 1 by default
			outputUnit = buildUnit(`1${inputUnit.type.siPrefix}${inputUnit.type.siUnitName}`);
		} else {
			// at this point, inputUnit & outputUnit both have value
			noUnit = Boolean(!inputUnit.unitName);
		}
	}

	// get price
	let prices = buildPrice(priceStr);
	if (prices.length === 1) {
		priceNum = prices[0].priceNum;
	} else {
		// multiple prices
		return multiPriceMsg;
	}

	// get qty
	let qty = buildQty(qtyStr);
	if (qty.length === 0) {
		qtyNum = 1;
	} else if (qty.length === 1) {
		qtyNum = qty[0];
	} else {
		// multiple quantities, return ""
		return '';
	}

	let coefficient = outputUnit.coefficient / inputUnit.coefficient;
	let outputPriceVal =
		priceNum * (coefficient / inputUnit.unitNum) * qtyNum ** -1 * outputUnit.unitNum;

	// self.coefficient = output_prefix_coefficient/input_prefix_coefficient
	//       self.ppu = self.price.numeric_val * (self.coefficient/self.input_quantity.numeric_val) * float(self.number_of_items)**-1 * self.output_quantity.numeric_val
	// NOTE: we add comma as thousand separator, but Safari doesn't support Lookbehind regex /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
	//			 so we use the simplified regex /\B(?=(\d{3})+(?!\d))/g; but it has flaw where it adds comma after the decimal point if there are more than 3 digits (wouldn't cause problem in our case)
	//			 Ref: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
	let outputPriceStr = '';
	if (currencySymbol) {
		outputPriceStr = `${
			currencyPosition === 'leading' ? currencySymbol : ''
		}${outputPriceVal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
			currencyPosition === 'trailing' ? currencySymbol : ''
		}`;
	} else {
		outputPriceStr = outputPriceVal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas as thousands seperator
	}

	if (noUnit) {
		return `${outputPriceStr} each`;
	} else {
		return `${outputPriceStr}${perStr}${outputUnit.unitNum === 1 ? '' : outputUnit.unitNum}${
			outputUnit.siPrefix
		}${outputUnit.siUnitName}`;
	}
};

// eslint-disable-next-line no-unused-vars
const test = () => {
	console.log(`\n############ Test optional parameters ###############`);
	let pricePerUnit = PricePerUnit('€300', '500g', '1', '2kg');
	console.log(
		'€300',
		'500g',
		'1',
		'2kg',
		' ==> ',
		pricePerUnit,
		`\t exp "1,200.00/2kg", match? ${pricePerUnit === '1,200.00/2kg'}`
	);

	pricePerUnit = PricePerUnit('€1.50', '275ml', '1.2', '750ml');
	console.log(
		'€1.50',
		'275ml',
		'1.2',
		'750ml',
		' ==> ',
		pricePerUnit,
		`\t exp "3.41/750ml", match? ${pricePerUnit === '3.41/750ml'}`
	);

	pricePerUnit = PricePerUnit('€1.50', '500ml', '1', null, { currencySymbol: '£' });
	console.log(
		'€1.50',
		'500ml',
		'1',
		' ==> ',
		pricePerUnit,
		`\t exp "£3.00/L", match? ${pricePerUnit === '£3.00/L'}`
	);

	pricePerUnit = PricePerUnit('£1.50', '275ml', '2', 'l', {
		currencySymbol: '£',
		currencyPosition: 'trailing',
	});
	console.log(
		'£1.50',
		'275ml',
		'2',
		'l',
		' ==> ',
		pricePerUnit,
		`\t exp "2.73£/l", match? ${pricePerUnit === '2.73£/l'}`
	);

	pricePerUnit = PricePerUnit('£1.50', '275ml', '2', 'l', {
		currencySymbol: '£',
		currencyPosition: 'trailing',
		perStr: ' PER ',
	});
	console.log(
		'£1.50',
		'275ml',
		'2',
		'l',
		' ==> ',
		pricePerUnit,
		`\t exp "2.73£ PER l", match? ${pricePerUnit === '2.73£ PER l'}`
	);

	console.log(`\n############ Test Mass Unit ###############`);
	// self.mass_units
	let massUnits = ['g', 'gram', 'grams', 'gramme', 'grammes'];
	// self.volume_units
	let volumeUnits = ['l', 'litre', 'liter', 'liters', 'litres', 'lt', 'ltr'];

	// test mass units
	massUnits.forEach((massUnit) => {
		pricePerUnit = PricePerUnit('€1.99', `500${massUnit}`, '1');
		console.log(
			'€1.99',
			`500${massUnit}`,
			'1',
			' ==> ',
			pricePerUnit,
			`\t exp "3.98/kg", match? ${pricePerUnit === '3.98/kg'}`
		);
	});

	console.log('');
	massUnits.forEach((massUnit) => {
		pricePerUnit = PricePerUnit('€500', `500${massUnit}`, '1', '2g');
		console.log(
			'€500',
			`500${massUnit}`,
			'1',
			'2g',
			' ==> ',
			pricePerUnit,
			`\t exp "2.00/2g", match? ${pricePerUnit === '2.00/2g'}`
		);
	});
	console.log('');
	massUnits.forEach((massUnit) => {
		pricePerUnit = PricePerUnit('€1,000', `500${massUnit}`, '2', '2g');
		console.log(
			'€1,000',
			`500${massUnit}`,
			'2',
			'2g',
			' ==> ',
			pricePerUnit,
			`\t exp "2.00/2g", match? ${pricePerUnit === '2.00/2g'}`
		);
	});

	// test volume units
	console.log(`\n################ Test Volume Units #################`);
	volumeUnits.forEach((volUnit) => {
		pricePerUnit = PricePerUnit('€500, $450', `500${volUnit}`, '1');
		console.log(
			'"€500, $450"',
			`500${volUnit}`,
			'1',
			' ==> ',
			pricePerUnit,
			`\t exp "Various Price Available", match? ${pricePerUnit === 'Various Price Available'}`
		);
	});
	console.log('');
	volumeUnits.forEach((volUnit) => {
		pricePerUnit = PricePerUnit('€500', `500${volUnit}`, '1');
		console.log(
			'€500',
			`500${volUnit}`,
			'1',
			' ==> ',
			pricePerUnit,
			`\t exp "1.00/L", match? ${pricePerUnit === '1.00/L'}`
		);
	});
	console.log('');
	volumeUnits.forEach((volUnit) => {
		pricePerUnit = PricePerUnit('€500', `500${volUnit}`, '2');
		console.log(
			'€500',
			`500${volUnit}`,
			'2',
			' ==> ',
			pricePerUnit,
			`\t exp "0.50/L", match? ${pricePerUnit === '0.50/L'}`
		);
	});
	console.log('');
	volumeUnits.forEach((volUnit) => {
		pricePerUnit = PricePerUnit('€500,000', `500${volUnit}`, '2', 'ml');
		console.log(
			'€500,000',
			`500${volUnit}`,
			'2',
			'ml',
			' ==> ',
			pricePerUnit,
			`\t exp "1.50/3ml", match? ${pricePerUnit === '0.50/ml'}`
		);
	});
	console.log('');
	volumeUnits.forEach((volUnit) => {
		pricePerUnit = PricePerUnit('€500000', `500${volUnit}`, '2', '3ml');
		console.log(
			'€500000',
			`500${volUnit}`,
			'2',
			'3ml',
			' ==> ',
			pricePerUnit,
			`\t exp "1.50/3ml", match? ${pricePerUnit === '1.50/3ml'}`
		);
	});
	console.log('');
	volumeUnits.forEach((volUnit) => {
		pricePerUnit = PricePerUnit('€500000', `500${volUnit}, 750${volUnit}`, '2', '3ml');
		console.log(
			'€500000',
			`"500${volUnit}, 750${volUnit}"`,
			'2',
			'3ml',
			' ==> ',
			pricePerUnit,
			`\t exp "Various Packs Available", match? ${pricePerUnit === 'Various Packs Available'}`
		);
	});

	console.log('');
	volumeUnits.forEach((volUnit) => {
		pricePerUnit = PricePerUnit('€500000', `500${volUnit}`, '2, 3', '3ml');
		console.log(
			'€500000',
			`500${volUnit}`,
			'2, 3',
			'3ml',
			' ==> ',
			pricePerUnit,
			`\t exp "", match? ${pricePerUnit === ''}`
		);
	});

	console.log(`\n############ test some prefixes ##########`);
	let ppu = PricePerUnit('€1.90', '160cl', '1');
	console.log('€1.90', '160cl', '1', '==>', ppu, `\t exp 1.19/L, match? ${ppu === '1.19/L'}`);
	ppu = PricePerUnit('€1.90', '1600ml', '1');
	console.log('€1.90', '1600ml', '1', '==>', ppu, `\t exp 1.19/L, match? ${ppu === '1.19/L'}`);
	ppu = PricePerUnit('€1.90', '1600 mil', '1');
	console.log('€1.90', '"1600 mil"', '1', '==>', ppu, `\t exp 1.19/L, match? ${ppu === '1.19/L'}`);

	ppu = PricePerUnit('€2.34', '0.5k', '1');
	console.log('€2.34', '0.5k', '1', '==>', ppu, `\t exp 4.68/kg, match? ${ppu === '4.68/kg'}`);
	ppu = PricePerUnit('€2.34', '0.5 kg', '1');
	console.log('€2.34', '"0.5 kg"', '1', '==>', ppu, `\t exp 4.68/kg, match? ${ppu === '4.68/kg'}`);
	ppu = PricePerUnit('€2.34', '0.5kilo', '1');
	console.log('€2.34', '0.5kilo', '1', '==>', ppu, `\t exp 4.68/kg, match? ${ppu === '4.68/kg'}`);
	ppu = PricePerUnit('€2.34', '0.5kilogram', '1');
	console.log(
		'€2.34',
		'0.5kilogram',
		'1',
		'==>',
		ppu,
		`\t exp 4.68/kg, match? ${ppu === '4.68/kg'}`
	);
	ppu = PricePerUnit('€2.34', '0.5kilogramme', '1');
	console.log(
		'€2.34',
		'0.5kilogramme',
		'1',
		'==>',
		ppu,
		`\t exp 4.68/kg, match? ${ppu === '4.68/kg'}`
	);

	console.log(`\n############ test qty ##########`);
	ppu = PricePerUnit('€1.90', '160cl', '3');
	console.log('€1.90', '160cl', '3', '==>', ppu, `\t exp 0.40/L, match? ${ppu === '0.40/L'}`);
	ppu = PricePerUnit('€1.90', '160cl', '6');
	console.log('€1.90', '160cl', '6', '==>', ppu, `\t exp 0.20/L, match? ${ppu === '0.20/L'}`);

	console.log(`\n############ test different output unit ##########`);
	ppu = PricePerUnit('€1.80', '70cl', '3', '70cl');
	console.log(
		'€1.80',
		'70cl',
		'3',
		'70cl',
		'==>',
		ppu,
		`\t exp 0.60/70cl, match? ${ppu === '0.60/70cl'}`
	);
	ppu = PricePerUnit('€1.80', '70cl', '6', '140cl');
	console.log(
		'€1.80',
		'70cl',
		'6',
		'140cl',
		'==>',
		ppu,
		`\t exp 0.60/140cl, match? ${ppu === '0.60/140cl'}`
	);

	console.log(`\n############ Special cases with Price ##########`);
	ppu = PricePerUnit('');
	console.log('empty price: "" ', '==>', ppu, `\t exp "", match? ${ppu === ''}`);
	ppu = PricePerUnit('2.99, 3.55');
	console.log(
		'Multiple prices: "2.99, 3.55" ',
		'==>',
		ppu,
		`\t exp "", match? ${ppu === 'Various Price Available'}`
	);

	console.log(`\n############ Special cases with Unit ##########`);
	ppu = PricePerUnit('2.99', '500ml, 750cl');
	console.log(
		`multiple units: '2.99', '500ml, 750cl' `,
		'==>',
		ppu,
		`\t exp "Various Packs Available", match? ${ppu === 'Various Packs Available'}`
	);
	ppu = PricePerUnit('€500', '500ml', '2', 'mb');
	console.log(
		`wrong output units: '€500', '500ml', '2', 'mb' `,
		'==>',
		ppu,
		`\t exp "500.00/L", match? ${ppu === '500.00/L'}`
	);

	ppu = PricePerUnit('3.00');
	console.log(
		`wrong units: '3.00', null `,
		'==>',
		ppu,
		`\t exp "3.00 each", match? ${ppu === '3.00 each'}`
	);
	ppu = PricePerUnit('3.00', '', '2');
	console.log(
		`wrong units: '3.00', '', '2' `,
		'==>',
		ppu,
		`\t exp "1.50 each", match? ${ppu === '1.50 each'}`
	);
	ppu = PricePerUnit('3.00', 'abc', '2');
	console.log(
		`wrong units: '3.00', 'abc', '2' `,
		'==>',
		ppu,
		`\t exp "1.50 each", match? ${ppu === '1.50 each'}`
	);

	ppu = PricePerUnit('3.00', 'abc', '2', '3g');
	console.log(
		`wrong units: '3.00', 'abc', '2', '3g' `,
		'==>',
		ppu,
		`\t exp "1.50 each", match? ${ppu === '1.50 each'}`
	);

	ppu = PricePerUnit('3.00', '2MB', '2', '3g');
	console.log(
		`unsupported units: '3.00', '2MB', '2', '3g' `,
		'==>',
		ppu,
		`\t exp "1.50 each", match? ${ppu === '1.50 each'}`
	);

	console.log(`\n############ Special cases with QTY ##########`);
	ppu = PricePerUnit('2.99', '500ml', '3, 2');
	console.log(
		`multiple qty: '2.99', '500ml', '3, 2' `,
		'==>',
		ppu,
		`\t exp "", match? ${ppu === ''}`
	);
	ppu = PricePerUnit('2.99', '500ml', 'abc');
	console.log(
		`wrong qty (make it to default 1): '2.99', '500ml', 'abc' `,
		'==>',
		ppu,
		`\t exp "5.98/L", match? ${ppu === '5.98/L'}`
	);
	ppu = PricePerUnit('2.99', '500ml', '');
	console.log(
		`wrong qty (make it to default 1): '2.99', '500ml', '' `,
		'==>',
		ppu,
		`\t exp "5.98/L", match? ${ppu === '5.98/L'}`
	);
	ppu = PricePerUnit('2.99', '500ml', null);
	console.log(
		`wrong qty (make it to default 1): '2.99', '500ml', null `,
		'==>',
		ppu,
		`\t exp "5.98/L", match? ${ppu === '5.98/L'}`
	);
	ppu = PricePerUnit('3.00', '500ml', null, '250ml');
	console.log(
		`wrong qty (make it to default 1): '3.00', '500ml', null, '250ml' `,
		'==>',
		ppu,
		`\t exp "1.50/250ml", match? ${ppu === '1.50/250ml'}`
	);
};

// test();
const priceCalcUtils = { PricePerUnit };
export default priceCalcUtils;
