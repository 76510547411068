// ##############################
// // // Screen Manager styles
// #############################
import { grey } from '@mui/material/colors';
// import { Grid, BottomNavigation } from '@mui/material';

const ScreenboardStyle = (theme) => ({
	contentWrapper: {
		...theme.contentWrapper,
	},
	header: {
		height: 50,
		width: '100%',
		display: 'flex',
		marginBottom: 15,
		// justifyContent: 'center',
		alignItems: 'center',
		// position: 'relative',
		backgroundColor: grey[100],
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			height: 'unset',
			flexWrap: 'wrap',
			justifyContent: 'flex-start',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	title: {
		width: '60%',
		marginLeft: 20,
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: 'unset',
			marginLeft: 8,
		},
		// marginTop: 'auto',
		// marginBottom: 'auto'
		// position: 'absolute',
		// top: '50%',
		// transform: 'translateY(-50%)'
	},
	search: {
		width: '40%',
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%',
			marginBottom: 8,
		},
	},
	filterInput: {
		margin: theme.spacing(1),
		overflow: 'unset',
	},
	dropdown: {
		boxSizing: 'content-box !important',
	},
	// textField: {
	// 	marginLeft: theme.spacing(1),
	// 	marginRight: theme.spacing(1),
	// 	width: '90%',
	// },
	table: {
		marginBottom: '0',
		width: '100%',
		maxWidth: '100%',
		maxHeight: '90%',
		color: 'black',
		backgroundColor: 'transparent',
		borderSpacing: '0',
		borderCollapse: 'collapse',
		overflow: 'inherit !important',
	},
	tableHeadCell: {
		color: 'inherit',
		// ...defaultFont,
		fontWeight: 600,
		height: 50,
		fontSize: '1.1em',
		alignItems: 'center',
	},
	// tableCell: {
	// 	// ...defaultFont,
	// 	lineHeight: '1.42857143',
	// 	// padding: '12px 8px',
	// 	// verticalAlign: 'middle',
	// 	// textAligh: 'center',
	// 	margin: 'auto',
	// 	// maxWidth: '100px',
	// 	maxHeight: '145px',
	// 	minHeight: '135px',
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	whiteSpace: 'unset',
	// 	// height: '120px',
	// 	wordWrap: 'break-word',
	// 	fontSize: '13px'
	// },
	// tableResponsive: {
	// 	width: '100%',
	// 	marginTop: theme.spacing(1) * 3,
	// 	overflowX: 'auto'
	// },

	rightIconInButton: {
		marginLeft: theme.spacing(1),
	},
	radioGroup: {
		marginLeft: theme.spacing(1),
	},
	// landscapeIcon: {
	// 	'vertical-align': 'bottom',
	// },
	portraitIcon: {
		// 'vertical-align': 'bottom'
	},
	// hoverCursor: {
	// 	'&:hover': {
	// 		cursor: 'pointer',
	// 	},
	// },
	success: {
		color: theme.palette.success.main,
	},
	/**
	 * ----------------------------------------
	 * animation shake (ring)
	 * ----------------------------------------
	 */
	'@keyframes shake': theme.animations['@keyframes ring'],
	shake: {
		animation: '$shake .3s infinite',
	},
});

export default ScreenboardStyle;
