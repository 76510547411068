import {
	POST_PROCESS_UPLOADED_FILES,
	POST_PROCESS_UPLOADED_FILES_SUCCESS,
	POST_PROCESS_UPLOADED_FILES_FAIL,
	RESET_POST_PROCESS_UPLOADED_FILES,
	FETCH_PROGRESS_UPLOADED_FILES,
	FETCH_PROGRESS_UPLOADED_SUCCESS,
	FETCH_PROGRESS_UPLOADED_FAIL,
	RESET_PROGRESS_UPLOADED_FILES,
	FETCH_FILE_CATEGORIES,
	FETCH_FILE_CATEGORIES_SUCCESS,
	FETCH_FILE_CATEGORIES_FAIL,
	RESET_FILE_CATEGORIES,
	FILEMANAGER_SEARCH_MEDIAFILES,
	FILEMANAGER_SEARCH_MEDIAFILES_SUCCESS,
	FILEMANAGER_SEARCH_MEDIAFILES_FAIL,
	FILEMANAGER_RESET_SEARCHED_MEDIAFILES,
	FILEMANAGER_FETCH_LIGHTBOXES,
	FILEMANAGER_FETCH_LIGHTBOXES_SUCCESS,
	FILEMANAGER_FETCH_LIGHTBOXES_FAIL,
	FILEMANAGER_RESET_LIGHTBOXES,
	FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID,
	FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_SUCCESS,
	FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_FAIL,
	FILEMANAGER_RESET_SPREADSHEET_CONTENT_BYID,
} from 'redux/actions';

import config from 'config';

const filemanagerAPIBaseUrl = config.filemanagerAPI.baseUrl;
const authHeaderKey = config.filemanagerAPI.authHeaderKey;

/**
 * Post-process (upload) uploaded files in filemanager api
 * @param {object} opts. 
 	{
		reqData: {Object} // object defined in endpoint POST "/upload/"
	}
 * @param {func} cb
 */
// eslint-disable-next-line no-unused-vars
export const postProcessUploadedFiles = (opts = {}, cb) => (dispatch, getState) => {
	let reqUrl = `${filemanagerAPIBaseUrl}/upload/`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 180000,
	};
	return dispatch({
		types: [
			POST_PROCESS_UPLOADED_FILES,
			POST_PROCESS_UPLOADED_FILES_SUCCESS,
			POST_PROCESS_UPLOADED_FILES_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

/**
 * reset post-process of uploaded files.
 */
export const resetPostProcessUploadedFiles = () => ({
	type: RESET_POST_PROCESS_UPLOADED_FILES,
});

/**
 * Fetch processing progress of the uploaded files from filemanager api
 * @param {object} opts.
 	{
		queueID: {integer}
	}
 * @param {func} cb
 */
// eslint-disable-next-line no-unused-vars
export const fetchProgressUploadedFiles = (opts = {}, cb) => (dispatch, getState) => {
	let reqUrl = `${filemanagerAPIBaseUrl}/upload/`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		params: { queueID: opts.queueID },
		headers: reqHeader,
		timeout: 30000,
	};
	return dispatch({
		types: [
			FETCH_PROGRESS_UPLOADED_FILES,
			FETCH_PROGRESS_UPLOADED_SUCCESS,
			FETCH_PROGRESS_UPLOADED_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

/**
 * reset processing progress of uploaded files.
 */
export const resetProgressUploadedFiles = () => ({
	type: RESET_PROGRESS_UPLOADED_FILES,
});

// fetch (file) categories
export const fetchFileCategories = (cb) => (dispatch, getState) => {
	let reqUrl = `${filemanagerAPIBaseUrl}/categories`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token, //`Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName },
		timeout: 30000,
	};

	return dispatch({
		types: [FETCH_FILE_CATEGORIES, FETCH_FILE_CATEGORIES_SUCCESS, FETCH_FILE_CATEGORIES_FAIL],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the categories in filemanager data store
export const resetFileCategories = () => ({
	type: RESET_FILE_CATEGORIES,
});

/**
 * search mediafiles
 * @param {Object} opts
	{
		queryParams: {
			previewSize: 'xxxx',
			offset: Number,
			limit: Number,
			domain: 'xxx',
			uid: 'xxxx',
		},
		filters: {},
	}
 * @param {func} cb
 */
// eslint-disable-next-line no-unused-vars
export const searchFileManagerMediafiles = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${filemanagerAPIBaseUrl}/mediafiles`;
	const authState = getState().authentication;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: { domain: authState.domainName, uid: authState.userId, ...(opts.queryParams || {}) },
		data: { filters: opts.filters },
		timeout: 90000,
	};

	return dispatch({
		types: [
			FILEMANAGER_SEARCH_MEDIAFILES,
			FILEMANAGER_SEARCH_MEDIAFILES_SUCCESS,
			FILEMANAGER_SEARCH_MEDIAFILES_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset the searched mediafiles in filemanager data store
export const resetFileManagerSearchedMediafiles = () => ({
	type: FILEMANAGER_RESET_SEARCHED_MEDIAFILES,
});

/**
 * Get lightboxes
 *
 * @param {object} opts. Request options. Format {queryParams: {}}
 * @param {func} cb. Callback function after action is completed.
 */
export const fetchLightboxes = (opts = {}, cb) => (dispatch, getState) => {
	let reqUrl = `${filemanagerAPIBaseUrl}/lightboxes`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName, ...(opts.queryParams || {}) },
		timeout: 30000,
	};

	return dispatch({
		types: [
			FILEMANAGER_FETCH_LIGHTBOXES,
			FILEMANAGER_FETCH_LIGHTBOXES_SUCCESS,
			FILEMANAGER_FETCH_LIGHTBOXES_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

// reset lightboxes in filemanager data store
export const resetLightboxes = () => ({
	type: FILEMANAGER_RESET_LIGHTBOXES,
});

/**
 * Fetch spreadsheet content by id
 *
 * @param {object} opts. Request options. Format 
 	{
		spreadsheetId: 'xxxx',
		queryParams: { // the query parameters defined in /spreadsheets/{spreadsheetId} in filemanager api
			columnIndices: '1, 2, 3',
			rows: '1, 2, 3',
			searchColumnIndices: '1, 2, 3',
			searchString: 'keywords',
			sortBy: 1, // column index
			sortDirection:'asc/desc',
			offset: 0,
			limit: 20,
			domain: 'xxxx',
		},
		append: true/false,	//  Default false. if true, the result will be appended to existing data, otherwise, replace existing data with new data
	}
 * @param {func} cb. Callback function after action is completed.
 */
export const fetchSpreadsheetContentById = (opts = {}, cb) => (dispatch, getState) => {
	let { spreadsheetId, queryParams = {}, append = false } = opts;
	if (!spreadsheetId) throw new Error('Missing spreadsheet ID');
	let reqUrl = `${filemanagerAPIBaseUrl}/spreadsheets/${spreadsheetId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName, ...queryParams },
		timeout: 30000,
	};

	return dispatch({
		types: [
			FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID,
			FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_SUCCESS,
			FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
		append: append,
	});
};

// reset spreadsheet content by id
export const resetSpreadsheetContentById = () => ({
	type: FILEMANAGER_RESET_SPREADSHEET_CONTENT_BYID,
});
