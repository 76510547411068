import packageJson from '../../package.json';
let commonConf = {
	appVersion: packageJson.version,
	basePath: '', // the basePath we want our real routes to have. Note: we are not using it, so keep it empty
	urlBaseName: '/toolkit', // fake basename in url. it is not used by react-router, it is used as basename in router history config in index.js
	loginPath: '/login',
	notFoundPath: '/notfound',
	visitLocationKey: 'visitPage', // key value of the location the user attempt to visit. Used to set location state in case missing authentication or something else.
	// customlastPartBreadCrumbKey: 'lastPartBreadCrumbName',	// NOTE: we use sidebar to replace breadcrumb on 8 Oct 2019. Special treats for custom breadcrumb name in the last piece of breadcrumb nav. Set this by props.history.push(path, {config.customlastPartBreadCrumbKey: 'xxxx'})
	authCookieKey: '_jwtauth', // Should be removed when we have our own auth api
	lassoApiCookieKey: '_SessionTracker_Site', // VID-3668 temporary solution to log download and print activity using Lasso
	stringCryptoSecret: 'keep_it_secret', // it is used to temporarily encrypt non-important string in browser side. The secret here is not so important
	artworkOfflineStorage: {
		designTemplateKey: 'ADT',
	},
	artworkDesignUndoLimit: 200, // used ONLY by artwork design template reducer
	// supported file extensions for uploading
	supportedFileTypes:
		'.gif, .jpeg, .jpg, .png, .bmp, .tif, .tiff, .pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx, .odt, .eps, .epsf, .3gp, .ai, .aif, .avi, .m4v, .mov, .mp3, .mp4, .mpeg, .mpg, .ps, .psd, .swf, .wav, .wmv, .zip',
	// different artwork field type will have different file types that are allowed to upload when designing/creating artwork
	supportedUploadFileTypesByArtworkFieldType: {
		image: '.jpeg, .jpg, .png, .tif, .tiff',
		pdf: '.svg',
		video: '.m4v, .mov, .mp4, .mpeg, .mpg',
	},
	supportedVideoTypesOnVideoEditing: '.mov, .mp4', // supported video file types that can be uploaded for video editing
	thresholdToCompressArtworkPdf: {
		// any one of them reaches the configured value will trigger pdf compression
		size: 150 * 1024 * 1024, // 150MB. size of pdf chunk
		// numPages: 30, // number of pages
	},
	AWS: {
		maxRetries: 3,
		maxSockets: 50,
		httpTimeout: 600000,
		resources: {
			s3: {
				useAccelerateEndpoint: true,
				computeChecksums: true, // compute md5
				apiVersion: '2006-03-01',
			},
		},
		s3BucketToCloudFront: {
			default: 'https://images.visualid.com',
			'visualid-mediafiles': 'https://images.visualid.com',
			'vid-mediafiles-ie': 'https://d18bwdr8c9yer4.cloudfront.net',
			'vid-mediafiles-usa': 'https://vid-mediafiles-usa.s3.amazonaws.com',
			'visualid-digicel': 'https://images.digicelbrand.com',
		},
	},
	supportedLoggers: {
		fileDownloading: {
			logName: 'fileDownloading',
			labels: ['download', 'file', 'mediafile'],
		},
		fileSearching: {
			logName: 'fileSearching',
			labels: ['search', 'mediafile'],
		},
	},
	googleAPI: {
		apiKey: 'AIzaSyBjSuLyncjjVSt1IjiGATwCI9R8EaYXWLU',
		mapId: '6ae010c4df58bbdd',
	},
};

export default commonConf;
