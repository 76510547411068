import { makeStyles } from '@mui/styles';
import { tinycolor } from 'utils/libHelper';

export default makeStyles((theme) => {
	return {
		headerMenuButton: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			fontSize: theme.typography.pxToRem(20),
			padding: 8,
			boxShadow:
				'0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
			backgroundColor: tinycolor(theme.palette.domain.main).brighten(3).toHexString(),
		},
		headerIcon: {
			width: '20px',
			height: '20px',
		},
		profileWrapper: {
			minWidth: 160,
			maxWidth: 260,
			// minHeight: '10vh',
			maxHeight: '50vh',
		},
		categoryWrapper: {
			minHeight: '10vh',
			maxHeight: '70vh',
			minWidth: 260,
			maxWidth: 320,
		},
		langWrapper: {
			minHeight: '10vh',
			maxHeight: '50vh',
			minWidth: 160,
			maxWidth: 220,
		},

		list: {
			// marginTop: '15px',
			paddingLeft: '0',
			paddingTop: '0',
			paddingBottom: '0',
			marginBottom: '0',
			listStyle: 'none',
			color: 'inherit',
			'&:before,&:after': {
				display: 'table',
				content: '" "',
			},
			'&:after': {
				clear: 'both',
			},
		},
		item: {
			color: 'inherit',
			position: 'relative',
			display: 'block',
			textDecoration: 'none',
			margin: '0',
			padding: '0',
		},
		itemLink: {
			// paddingLeft: '10px',
			// paddingRight: '10px',
			transition: 'all 300ms linear',
			// margin: '10px 15px 0',
			borderRadius: '3px',
			position: 'relative',
			display: 'block',
			textDecoration: 'none',
			// padding: '10px 15px',
			padding: '10px 0px',
			backgroundColor: 'transparent',
			// ...defaultFont,
			width: 'auto',
			'&:hover': {
				outline: 'none',
				// backgroundColor: 'rgba(200, 200, 200, 0.2)',
				// backgroundColor: domainDarkColor,
				boxShadow: 'none',
			},
			'&,&:hover,&:focus': {
				color: 'inherit',
			},
		},
		itemIcon: {
			color: 'inherit',
			width: '30px',
			height: '24px',
			float: 'left',
			margin: '0px 15px 0px 15px',
			minWidth: 'auto',
			position: 'inherit',
			top: '3px',
			// marginRight: '15px',
			textAlign: 'center',
			verticalAlign: 'middle',
			opacity: '0.8',
		},
		itemText: {
			color: 'inherit',
			// ...defaultFont,
			margin: '0',
			lineHeight: '30px',
			fontSize: '14px',
			transform: 'translate3d(0px, 0, 0)',
			opacity: '1',
			transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
			position: 'relative',
			display: 'block',
			height: 'auto',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},

		collapseList: {
			marginTop: '0',
		},
		collapsesListNormal: {
			// sz
			paddingLeft: theme.spacing(2),
		},
		collapseItem: {
			position: 'relative',
			display: 'block',
			textDecoration: 'none',
			// margin: '10px 0 0 0',
			margin: 0,
			padding: 0,
		},
		collapseItemLink: {
			transition: 'all 300ms linear',
			textDecoration: 'none',
			// margin: '0 15px',
			margin: 0,
			borderRadius: '3px',
			position: 'relative',
			display: 'block',
			padding: '12px 0px 12px 0px',
			backgroundColor: 'transparent',
			// ...defaultFont,
			width: 'auto',
			'&:hover': {
				outline: 'none',
				// backgroundColor: 'rgba(200, 200, 200, 0.2)',
				// backgroundColor: domainDarkColor,
				boxShadow: 'none',
			},
			'&,&:hover,&:focus': {
				color: 'inherit',
			},
		},
		collapseItemIcon: {
			color: 'inherit',
			// ...defaultFont,
			textTransform: 'uppercase',
			width: '30px',
			marginRight: '15px',
			textAlign: 'center',
			letterSpacing: '1px',
			position: 'relative',
			float: 'left',
			display: 'inherit',
			transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
			fontSize: '14px',
			opacity: 0.8,
		},
		collapseItemText: {
			color: 'inherit',
			// ...defaultFont,
			margin: '0',
			position: 'relative',
			transform: 'translateX(0px)',
			opacity: '1',
			whiteSpace: 'nowrap',
			display: 'block',
			transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
			fontSize: '14px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		caret: {
			marginTop: '13px',
			position: 'absolute',
			right: '18px',
			transition: 'all 150ms ease-in',
			display: 'inline-block',
			width: '0',
			height: '0',
			marginLeft: '2px',
			verticalAlign: 'middle',
			borderTop: '4px solid',
			borderRight: '4px solid transparent',
			borderLeft: '4px solid transparent',
		},
		caretActive: {
			transform: 'rotate(180deg)',
		},
	};
});
