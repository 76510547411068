import React from 'react';

// import cx from 'classnames';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from '@mui/material';

import { red, grey } from '@mui/material/colors';

// custom components
import { ReactTableMUIView } from 'components';

// react-table hooks
import { useSortBy, useTable } from 'react-table';
import { Checkbox } from '@mui/material';

// intl lang
import { useIntl } from 'react-intl';

// CSS
const useStyles = makeStyles((theme) => ({
	actionsWrapper: {
		display: 'flex',
		gap: theme.spacing(0.5),
		flexWrap: 'wrap',
		maxWidth: 160,
		justifyContent: 'center',
	},
	checkboxInputHeader: {
		display: 'flex',
		gap: theme.spacing(0.5),
		flexWrap: 'wrap',
		maxWidth: 160,
		justifyContent: 'center',
	},
	actionIconButton: {
		border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
	},
	iconButtonRootWithRedBGColor: {
		backgroundColor: red[300],
		'&:hover': {
			backgroundColor: red[500],
		},
		'&:disabled': {
			backgroundColor: grey[500],
		},
	},
	iconButtons: {
		marginLeft: theme.spacing(1 / 2),
		marginRight: theme.spacing(1 / 2),
	},
	iconButtonRootSmaller: {
		fontSize: theme.typography.pxToRem(12),
		padding: theme.spacing(1 / 2),
	},
	alignUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
}));

function UserMessagesTableView({
	userMessages,
	columnsConfigure,
	// handleShowLargePreview,
	// handlePatchMessageStatus,
	appliedSortBy,
	handleSortBy,
	// setRTableInst,
	userMessageActions,
	noDataMsg,
	handleCheckboxOnChange,
	handleSelectAllCheckbox,
	selected,
	isSelectAllForPage,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();

	// ##############################
	// React-Table (treat it as part of local states)
	// #############################
	// function to select rows that require highlight
	const rowHighlightPicker = (row) => row.isSelected;

	const tableInst = useTable(
		{
			columns: columnsConfigure,
			data: userMessages,
			initialState: { sortBy: appliedSortBy },
			manualSortBy: true,
			disableMultiSort: true,
			autoResetSortBy: false,
			getRowId: (rowData) => rowData.messageId,
			// props from parent component that are used in Header/Cells to handle user operations
			handleSelectAllCheckbox,
			isSelectAllForPage,
			handleCheckboxOnChange,
			selected,
			userMessageActions,
		},
		useSortBy,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				let actionsCol = {
					id: 'actions',
					disableSortBy: true,
					Header: intl.formatMessage({
						id: 'pages.UserMessages.components.UserMessagesTable.TableHeaderActions',
					}),
					Cell: function rowAction({ row, userMessageActions }) {
						let userMsg = row.original;
						return (
							<div className={classes.actionsWrapper} aria-label="table-row-actions">
								{userMessageActions.map((action, idx) => {
									return action.shouldDisplay(userMsg.isRead)
										? !action.CustomComp && (
												<IconButton
													key={userMsg.messageId + '' + idx}
													className={[
														classes.iconButtonRootSmaller,
														classes.iconButtonRootWithRedBGColor,
														classes.iconButtons,
													].join(' ')}
													size="small"
													// color="primary"
													title={action.tooltip}
													onClick={(e) =>
														typeof action.clickHandler === 'function'
															? action.clickHandler(userMsg, e)
															: null
													}
												>
													{action.icon}
												</IconButton>
										  )
										: null;
								})}
							</div>
						);
					},
				};
				let actionsColCheckbox = {
					id: 'id',
					accessor: 'id',
					disableSortBy: true,
					// Header: '22',
					Header: function renderCell({
						handleSelectAllCheckbox,
						isSelectAllForPage,
						selected,
						data,
					}) {
						// If every item on the page is selected
						let isEveryItemSelected = false;
						// If any of the item on the page is selected
						let isSomeItemSelected = false;
						let isIndeterminate = false;
						let allDataOnPage = (data || []).map((x) => x.messageId);
						if (!isSelectAllForPage) {
							isEveryItemSelected = allDataOnPage.every((elem) => selected.indexOf(elem) > -1);
							isSomeItemSelected = allDataOnPage.some((elem) => selected.indexOf(elem) > -1);
						}
						let isSelectedMainCheckbox = isSelectAllForPage
							? isSelectAllForPage
							: isEveryItemSelected;
						if (selected.length > 0 && !isSelectedMainCheckbox) {
							if (!isSomeItemSelected) {
								isIndeterminate = false;
							} else {
								isIndeterminate = true;
							}
						}
						return (
							<div className={classes.checkboxInputHeader}>
								<Checkbox
									className={classes.alignUserDefinedFormControlCheckbox}
									color="primary"
									size="small"
									disableRipple
									indeterminate={isIndeterminate}
									checked={isSelectedMainCheckbox}
									onChange={(e) => {
										handleSelectAllCheckbox(e);
									}}
								/>
							</div>
						);
					},
					Cell: function rowAction({ row, isSelectAllForPage, handleCheckboxOnChange, selected }) {
						let isSelectedCheckbox = isSelectAllForPage
							? isSelectAllForPage
							: selected.includes(row.id);
						return (
							<div className={classes.actionsWrapper} aria-label="table-row-actions">
								<Checkbox
									className={classes.alignUserDefinedFormControlCheckbox}
									color="primary"
									size="small"
									disableRipple
									checked={isSelectedCheckbox}
									onChange={(e) => handleCheckboxOnChange(row, e)}
								/>
							</div>
						);
					},
				};
				return [actionsCol, actionsColCheckbox, ...columns];
			});
		}
	);
	React.useEffect(() => {
		handleSortBy(tableInst.state.sortBy);
	}, [handleSortBy, tableInst.state.sortBy]);

	return (
		<ReactTableMUIView
			isStickyHeader={true}
			showBorder={true}
			// enableResizer={true}
			rowHighlightPicker={rowHighlightPicker}
			// table props
			getTableProps={tableInst.getTableProps}
			headerGroups={tableInst.headerGroups}
			prepareRow={tableInst.prepareRow}
			rows={tableInst.rows}
			noDataMsg={noDataMsg}
		/>
	);
}

UserMessagesTableView.propTypes = {
	/**
	 * Array of user message.
	 	[
			 {
				isRead: true,
				messageId: "xxxx",
				senderEmail: "xxx@xxx.com",
				senderName: "xxxx xxxxx",
				subject: "xxxxxxxxxxxx",
			 }
		 ]
	 */
	userMessages: PropTypes.array.isRequired,
	selected: PropTypes.array,
	isSelectAllForPage: PropTypes.bool,

	/**
	 * Array of table columns configure
	 */
	columnsConfigure: PropTypes.array.isRequired,
	/**
	 * sortBy array
	 * NB: it is used as initial state in rTable for sorting
	 */
	appliedSortBy: PropTypes.array.isRequired,
	/**
	 * handle sorting message
	 * @param {array} sortBy_in_rTableInst. it is tableInst.state.sortBy
	 */
	handleSortBy: PropTypes.func.isRequired,
	handleCheckboxOnChange: PropTypes.func.isRequired,
	handleSelectAllCheckbox: PropTypes.func.isRequired,
	/**
	 * set rTable instance
	 */
	// setRTableInst: PropTypes.func.isRequired,

	userMessageActions: PropTypes.arrayOf(PropTypes.object),
	//  message when no User messages found
	noDataMsg: PropTypes.string,
};
UserMessagesTableView.defaultProps = { /* selectedRowIds: [], */ noDataMsg: 'No Messages!!' };
export default UserMessagesTableView;
