import React from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
// import { Link } from "react-router-dom";
import cx from 'classnames';

// styles
import useStyles from './styles';

// intl lang
import { useIntl } from 'react-intl';

export default function NotFound() {
	var classes = useStyles();
	let intl = useIntl();

	return (
		<Grid container className={classes.container}>
			{
				// 	<div className={classes.logotype}>
				//   <img className={classes.logotypeIcon} src={logo} alt="logo" />
				//   <Typography variant="h3" color="white" className={classes.logotypeText}>
				//     Toolkit
				//   </Typography>
				// </div>
			}
			<Paper classes={{ root: classes.paperRoot }}>
				<Typography variant="h1" color="inherit" className={cx(classes.textRow, classes.errorCode)}>
					404
				</Typography>
				<Typography variant="h5" color="inherit" className={classes.textRow}>
					{intl.formatMessage({ id: 'pages.NotFound.OopsMsg' })}
				</Typography>
				<Typography
					variant="h6"
					color="textPrimary"
					className={cx(classes.textRow, classes.safetyText)}
				>
					{intl.formatMessage({ id: 'pages.NotFound.BackSafeMsg' })}
				</Typography>
				<Button
					variant="contained"
					color="secondary"
					// component={Link}
					to="/"
					className={classes.backButton}
					onClick={() => window.location.replace('/')} // TODO: Use Link when we replace Lasso entirely.
				>
					{intl.formatMessage({ id: 'pages.NotFound.BackHomeButtonText' })}
				</Button>
			</Paper>
		</Grid>
	);
}
