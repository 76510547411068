import React from 'react';

import withStyles from '@mui/styles/withStyles';
// MUI components
import { Divider } from '@mui/material';

export const DividerVer = withStyles((theme) => ({
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
}))(({ classes, ...rest }) => {
	return <Divider orientation="vertical" className={classes.dividerVertical} {...rest} />;
});

export const DividerHor = withStyles((theme) => ({
	fullWidth: {
		width: '100%',
	},
}))(({ classes, ...rest }) => {
	return <Divider className={classes.fullWidth} {...rest} />;
});
