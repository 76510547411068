import React, { useState } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// MUI components
import { IconButton, Button, Tooltip, Menu } from '@mui/material';

// custom components
import { PerfectScrollWrapper } from 'components';

// artwork components
import SidePanelField from './SidePanelField/SidePanelField.jsx';
import SidePanelLayers from './SidePanelLayers/SidePanelLayers.jsx';
import SidePanelTemplateInfo from './SidePanelTemplateInfo/SidePanelTemplateInfo.jsx';
import ImportTemplate from './ImportTemplate/ImportTemplate.jsx';

// Icons
import {
	TextFields as TextFieldIcon,
	Photo as ImageFieldIcon,
	MovieCreation as VideoFieldIcon,
	MoreHoriz as MoreButtonIcon,
	Reply as GoBackIcon,
	ArrowLeft as CloseMenuPanelIcon,
	ArrowRight as OpenMenuPanelIcon,
	Info as InfoIcon,
	ViewList as GridFieldIcon,
} from '@mui/icons-material';
import {
	FilePdfBox as PdfFieldIcon,
	Barcode as BarcodeIcon,
	LayersTriple as GroupIcon,
	Import as ImportIcon,
	// Import as ImportIcon,
	InformationVariant as ShortcutIcon,
} from 'mdi-material-ui';

// intl lang
import { useIntl, FormattedMessage } from 'react-intl';

// CSS
import { default as useStyles } from './SidemenuStyle.jsx';
import { ART_VARIABLES } from '../../Constants';

// // redux
// import { connect } from 'react-redux';
// import {} from 'redux/actions'; // actions

const menuButtons = [
	{
		name: <FormattedMessage id="pages.Artwork.GENERAL.typeText" />,
		type: 'text',
		Icon: TextFieldIcon,
	},
	{
		name: <FormattedMessage id="pages.Artwork.GENERAL.typeImage" />,
		type: 'image',
		Icon: ImageFieldIcon,
	},
	{
		name: <FormattedMessage id="pages.Artwork.GENERAL.typePdf" />,
		type: 'pdf',
		Icon: PdfFieldIcon,
	},
	{
		name: <FormattedMessage id="pages.Artwork.GENERAL.typeBarcode" />,
		type: 'barcode',
		Icon: BarcodeIcon,
	},
	{
		name: <FormattedMessage id="pages.Artwork.GENERAL.typeVideo" />,
		type: 'video',
		Icon: VideoFieldIcon,
	},
	{
		name: <FormattedMessage id="pages.Artwork.GENERAL.typeGrid" />,
		type: 'grid',
		Icon: GridFieldIcon,
	},
];

function Sidemenu({
	handleGoback,
	templateGroups,
	designerTemplate,
	handleNewGroup,
	// handleDelGroup,
	fieldDropzoneRef,
	handleNewField,
	handleEleSelect,
	handleGroupSelect,
	selectedFieldIds,
	handleReorderFields,
	handleReorderGroups,
	handleDuplicateFields,
	handleDelFields,
	handleDelGroupWithFields,
	handleRenameGroup,
	handleDuplicateGroupWithFields,
	// handleTemplateInfoUpdate,
	handleDisplayShortcuts,
	templateList,
	fetchTemplateListStatus,
	getTemplateList,
	importTemplateById,
	enableImportTemplate,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	/*
		sidePanelContent format:
		{
			type: '',	// possible values: field type, "layers", "info"
		}
	*/
	let [sidePanelContent, setSidePanelContent] = useState({});
	let [extraMenu, setExtraMenu] = useState(null);
	let extraMenuComp = (
		<div className={classes.extraMenuWrapper}>
			<Button
				onClick={() => {
					handleDisplayShortcuts();
				}}
				fullWidth
				startIcon={<ShortcutIcon />}
			>
				{intl.formatMessage({ id: 'pages.Artwork.components.Sidemenu.shortcutText' })}
			</Button>
		</div>
	);

	let menuButtonComps = menuButtons.map((button) => {
		return (
			<Button
				key={button.type}
				disabled={
					designerTemplate.videoArtwork &&
					!ART_VARIABLES.supportedFieldTypesInVideoArtwork.includes(button.type)
				}
				className={cx(classes.menuBarFieldButton, {
					[classes.menuBarFieldActive]: sidePanelContent.type === button.type, // 'NEW_FIELD' && sidePanelContent.data.type === button.type,
				})}
				variant="contained"
				classes={{ label: classes.buttonLabel }}
				onClick={() => {
					setSidePanelContent({
						type: button.type,
						name: button.name /* , data: { type: button.type } */,
					});
				}}
			>
				<button.Icon className={classes.menuBarFieldIcon} />
				<div className={classes.menuBarFieldText}>{button.name}</div>
			</Button>
		);
	});

	const handleChildUnmount = () => {
		setSidePanelContent({});
	};

	// sidepanel comp
	let sidePanelContentComp = null;
	switch (sidePanelContent.type) {
		case 'text':
		case 'image':
		case 'pdf':
		case 'barcode':
		case 'video':
		case 'grid':
			// Don't use key in SidePanelField, but if required, you may give random key to force renew the SidePanelField compoment
			sidePanelContentComp = (
				<SidePanelField
					type={sidePanelContent.type}
					name={sidePanelContent.name}
					templateGroups={templateGroups}
					lastUsedGroupName={designerTemplate.lastUsedGroupName}
					handleNewGroup={handleNewGroup}
					// handleDelGroup={handleDelGroup}
					handleUnmount={handleChildUnmount}
					fieldDropzoneRef={fieldDropzoneRef}
					handleNewField={handleNewField}
				/>
			);
			break;
		case 'layers':
			sidePanelContentComp = (
				<DndProvider backend={HTML5Backend}>
					<SidePanelLayers
						{...{
							designerTemplate,
							handleEleSelect,
							handleGroupSelect,
							selectedFieldIds,
							handleReorderFields,
							handleReorderGroups,
							handleDuplicateFields,
							handleDelFields,
							handleDelGroupWithFields,
							handleRenameGroup,
							handleDuplicateGroupWithFields,
						}}
					/>
				</DndProvider>
			);
			break;
		case 'info':
			sidePanelContentComp = (
				<SidePanelTemplateInfo {...{ designerTemplate /* handleTemplateInfoUpdate */ }} />
			);
			break;
		case 'import':
			sidePanelContentComp = (
				<ImportTemplate {...{ templateList, importTemplateById, fetchTemplateListStatus }} />
			);
			break;
		default:
			break;
	}

	return (
		<div
			className={cx(classes.sideMenuWrapper, {
				// [classes.closeMenuPanel]: !sidePanelContent.type, //!menuPanelOpen
			})}
		>
			{/* side menu */}
			<div className={classes.menuBar}>
				<div className={classes.menuBarFieldGroup}>
					<Tooltip
						title={intl.formatMessage({ id: 'pages.Artwork.components.Sidemenu.gobackText' })}
					>
						<IconButton onClick={handleGoback}>
							<GoBackIcon />
						</IconButton>
					</Tooltip>
					<PerfectScrollWrapper
						component={menuButtonComps}
						className={classes.menuBarFieldGroupWrapper}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column' /* paddingBottom: 8 */ }}>
					{enableImportTemplate && (
						<IconButton
							className={cx(classes.extraButton, {
								[classes.menuBarFieldActive]: sidePanelContent.type === 'import',
							})}
							color="inherit"
							onClick={() => {
								if (sidePanelContent.type !== 'import' && templateList.length === 0) {
									getTemplateList();
								}
								setSidePanelContent(
									sidePanelContent.type === 'import' ? {} : { type: 'import', name: 'import' }
								);
							}}
						>
							<ImportIcon className={classes.extraButtonIcon} />
						</IconButton>
					)}
					<IconButton
						className={cx(classes.extraButton, {
							[classes.menuBarFieldActive]: sidePanelContent.type === 'layers',
						})}
						color="inherit"
						onClick={() =>
							setSidePanelContent(
								sidePanelContent.type === 'layers' ? {} : { type: 'layers', name: 'layers' }
							)
						}
					>
						<GroupIcon className={classes.extraButtonIcon} />
					</IconButton>
					<IconButton
						className={cx(classes.extraButton, {
							[classes.menuBarFieldActive]: sidePanelContent.type === 'info',
						})}
						color="inherit"
						onClick={() =>
							setSidePanelContent(
								sidePanelContent.type === 'info' ? {} : { type: 'info', name: 'info' }
							)
						}
					>
						<InfoIcon className={classes.extraButtonIcon} />
					</IconButton>
					{
						// extra button
						<IconButton
							className={classes.extraButton}
							color="inherit"
							onClick={(e) => setExtraMenu(e.currentTarget)}
						>
							<MoreButtonIcon className={classes.extraButtonIcon} />
						</IconButton>
					}
				</div>
			</div>

			{/* side panel */}
			<div
				className={cx(classes.sidePanel, {
					[classes.openSidePanel]: Boolean(sidePanelContent.type),
				})}
			>
				<div className={classes.sidePanelContent}>
					{
						// TODO: Render default template info if no content
						sidePanelContentComp
					}
				</div>
				<div
					className={classes.sidePanelControl}
					onClick={() =>
						setSidePanelContent(sidePanelContent.type ? {} : { type: 'info', name: 'info' })
					}
				>
					{sidePanelContent.type ? <CloseMenuPanelIcon /> : <OpenMenuPanelIcon />}
				</div>
			</div>
			{/* Extra menu (popup) */}
			<Menu
				open={Boolean(extraMenu)}
				anchorEl={extraMenu}
				onClose={() => setExtraMenu(null)}
				// getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				disableAutoFocusItem
			>
				{
					// menu of extra buttons
					<div>
						<PerfectScrollWrapper component={extraMenuComp} />
					</div>
				}
			</Menu>
		</div>
	);
}

Sidemenu.propTypes = {
	handleGoback: PropTypes.func.isRequired,
	templateGroups: PropTypes.array.isRequired,
	designerTemplate: PropTypes.object.isRequired,
	handleNewGroup: PropTypes.func.isRequired,
	// handleDelGroup: PropTypes.func.isRequired,
	fieldDropzoneRef: PropTypes.object.isRequired,
	handleNewField: PropTypes.func.isRequired,
	handleEleSelect: PropTypes.func.isRequired,
	handleGroupSelect: PropTypes.func.isRequired,
	selectedFieldIds: PropTypes.array.isRequired,
	handleReorderFields: PropTypes.func.isRequired,
	handleReorderGroups: PropTypes.func.isRequired,
	handleDuplicateFields: PropTypes.func.isRequired,
	handleDelFields: PropTypes.func.isRequired,
	handleDelGroupWithFields: PropTypes.func.isRequired,
	handleRenameGroup: PropTypes.func.isRequired,
	handleDuplicateGroupWithFields: PropTypes.func.isRequired,
	// handleTemplateInfoUpdate: PropTypes.func.isRequired,
	handleDisplayShortcuts: PropTypes.func.isRequired,
	/**
	 * A list of templates for user to select
	 * Format:
	  [
			{
				"id": "string",
				"name": "string",
				"width": 0, // template width in pixel
				"height": 0 // template height in pixel
			},
			...
		]
	 */
	templateList: PropTypes.array.isRequired,
	// status of GET similar templates API request
	fetchTemplateListStatus: PropTypes.oneOf(['', 'PROCESSING', 'OK', 'FAILED']).isRequired,
	/**
	 * Fetch all template list for user to import
	 * @param {null/undefined} no param
	 */
	getTemplateList: PropTypes.func.isRequired,
	/**
	 * trigger importing template process
	 * @param {string} templateId. template id that to be imported
	 */
	importTemplateById: PropTypes.func.isRequired,
	// enable import template or not
	enableImportTemplate: PropTypes.bool,
};

Sidemenu.defaultProps = { enableImportTemplate: false };

// // eslint-disable-next-line no-unused-vars
// const mapStateToProps = state => {
// 	return {
// 		// notifier: state.notifier
// 	};
// };

// export default connect(mapStateToProps, {})(Sidemenu);
export default Sidemenu;
