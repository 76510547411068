import {
	// PLAYLIST UNIVERSE
	FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE,
	FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS,
	FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL,
	EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_REQUEST,
	EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS,
	EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL,
} from 'redux/actions';
import config from 'config';

const screenApiBaseUrl = config.screenAPI.baseUrl;
const authHeaderKey = config.screenAPI.authHeaderKey;

export const fetchScreenManagerPlaylistUniverse = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${screenApiBaseUrl}/report/players`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { crossdomain: true },
		timeout: 300000,
	};
	return dispatch({
		types: [
			FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE,
			FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS,
			FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

export const editPlaylerUniverseComment = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/report/players/${opts.playerId}`,
		method: 'patch',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_REQUEST,
			EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS,
			EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};
