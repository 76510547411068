import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';

// Constants
import { ROUTES_PATH_ADMIN, ROUTES_PATH_ADMIN_PLAYER_UNIVERSE } from 'routes';

// Icons
import { Tv as ScreenIcon, AdminPanelSettings as AdminIcon } from '@mui/icons-material';

// components
const PlayerUniverse = loadable(() => import('./components/PlayerUniverse/PlayerUniverse'), {
	fallback: <Loading size={50} />,
});

const AdminHome = loadable(() => import('./components/AdminHome/AdminHome'), {
	fallback: <Loading size={50} />,
});

const AdminRoutes = [
	{
		path: ROUTES_PATH_ADMIN, // unique key in routes
		sidebarName: <FormattedMessage id="pages.Admin.Routes.Admin" />,
		sidebarIcon: AdminIcon,
		component: AdminHome,
		noBreadcrumbs: true,
		requiredUserLevel: { $gte: 60 },
		children: [
			{
				path: ROUTES_PATH_ADMIN_PLAYER_UNIVERSE,
				sidebarName: <FormattedMessage id="pages.Admin.Routes.PlayerUniverse" />, //'PlayerUniverse',
				sidebarIcon: ScreenIcon,
				noBreadcrumbs: true,
				component: PlayerUniverse,
				requiredUserLevel: { $gte: 60 },
			},
		],
	},
];

let adminRoutesByPath = {};
const setRoutesByPath = (routes = []) => {
	routes.forEach((route) => {
		adminRoutesByPath[route.path] = route;
		if (route.children && route.children.length > 0) {
			setRoutesByPath(route.children);
		}
	});
};
setRoutesByPath(AdminRoutes);

export { adminRoutesByPath };

export default AdminRoutes;
