// ##############################
// // // Variables - Styles that are used on more than one component
// #############################
import config from 'config';

const drawerWidth = 260;
const drawerMiniWidth = 80;
const headerBarHeight = config.isReactOnly ? 60 : 0;
// const breadcrumbHeight = 50; // as this is a temparary solution, we move it to AppLayout.jsx
const mobileViewBreakpoint = 'md';

const contentWrapper = {
	position: 'relative',
	top: '0',
	minHeight: '90%',
	flexGrow: 1,
	maxWidth: '100%',
	margin: 'auto',
	padding: `24px 16px`,
};

// recommend to use pageWrapper in the root styles in a page (NOT have to)
const pageWrapper = {
	position: 'relative',
	width: '100%',
	height: '100%',
	margin: 'auto',
	padding: 16,
};

const customTransition = {
	transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const customContainer = {
	paddingRight: '16px',
	paddingLeft: '16px',
	marginRight: 'auto',
	marginLeft: 'auto',
	'&:before,&:after': {
		display: 'table',
		content: '" "',
	},
	'&:after': {
		clear: 'both',
	},
};

const customBoxShadow = {
	boxShadow:
		'0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
};

const customShadows = {
	widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
	widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
	widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
};

const customDefaultBoxShadow = {
	border: '0',
	borderRadius: '3px',
	boxShadow:
		'0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
	padding: '10px 0',
	transition: 'all 150ms ease 0s',
};

const customStyles = {
	//variables
	contentWrapper,
	pageWrapper,
	drawerWidth,
	drawerMiniWidth,
	headerBarHeight,
	// breadcrumbHeight,
	mobileViewBreakpoint,
	customTransition,
	customContainer,
	customBoxShadow,
	customShadows,
	customDefaultBoxShadow,
};

export default customStyles;
