/**
 * Constants used for React Toolkit Build
 * the contants in this file are used as contants (hard-coded value) in application
 */

// Using Object.freeze() to create immutable object
// ref:
//		- https://www.sohamkamani.com/javascript/enums/,
//		- https://masteringjs.io/tutorials/fundamentals/enum
export const ENVIRONMENTS_ENUM = Object.freeze({
	development: 'development',
	production: 'production',
});
export const RUNNING_MODES_ENUM = Object.freeze({
	REACT_ONLY: 'REACT_ONLY',
	REACT_IN_LASSO: 'REACT_IN_LASSO',
});
