// ##############################
// // // Artwork Workspace styles
// #############################

import makeStyles from '@mui/styles/makeStyles';
import { blueGrey, grey, blue } from '@mui/material/colors';

const titleHeight = 74;
// const overallColor = grey[200];
const borderColor = blueGrey[300];
const dragIconColor = blueGrey[300];
const textFieldLineColor = grey[300];
const textFieldLineHoverColor = grey[500];
const textFieldLineFocusColor = grey[400];
const textFieldLabelColor = grey[200];

const SidePanelFieldStyle = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
		width: '100%',
		height: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		// color: overallColor,
		color: 'inherit',
	},
	title: {
		width: '100%',
		position: 'relative',
		color: 'inherit',
		backgroundColor: 'inherit',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(16),
		textTransform: 'capitalize',
		userSelect: 'none',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		height: titleHeight,
		padding: '10px 2px',
		margin: 'auto',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'&:after': {
			content: '""',
			margin: '0 auto',
			width: '100%',
			// padding: '4px 0',
			opacity: 0.8,
			borderBottom: `1px solid ${borderColor}`,
		},
	},
	titleDisabled: {
		color: grey[500],
	},
	dragText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
	},
	dragIcon: {
		marginRight: theme.spacing(0.5),
		color: dragIconColor,
	},
	draggablePlaceholder: {
		zIndex: 9999,
		height: titleHeight,
		width: '100%',
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cursorNotDraggable: {
		cursor: 'not-allowed',
	},
	cursorDraggable: {
		cursor: 'pointer',
	},
	cursorDroppable: {
		cursor: 'grabbing',
	},
	cursorNoDroppable: {
		cursor: 'no-drop',
	},
	draggablePlaceholderSquare: {
		width: 100,
		height: 100,
		border: `1px solid ${blue[500]}`,
		// display: 'none',
	},
	draggablePlaceholderRectangleProtrait: {
		width: 60,
		height: 100,
		border: `1px solid ${blue[500]}`,
		// display: 'none',
	},
	draggablePlaceholderRectangleLandscape: {
		width: 100,
		height: 30,
		border: `1px solid ${blue[500]}`,
		// display: 'none',
	},
	draggablePlaceholderHidden: {
		display: 'none',
	},
	content: {
		width: '100%',
		height: `calc(100% - ${titleHeight}px)`,
		color: 'inherit',
		backgroundColor: 'inherit',
		position: 'relative',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		'&:before': {
			content: '""',
			paddingTop: theme.spacing(1),
		},
	},
	groupSelectContainer: {
		margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
	},
	rsOptionWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	rsOptionActions: {
		margin: `0 ${theme.spacing(1)}`,
	},
	rsOptionActionIcon: {
		fontSize: 14,
	},
}));

export const artInputStyles = makeStyles((theme) => ({
	textField: {
		margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
	},
	textFieldLabelRoot: {
		color: `${textFieldLabelColor} !important`,
		// fontSize: 12,
	},
	textFieldInputUnderline: {
		'&:before': {
			borderBottom: `1px solid ${textFieldLineColor}`,
		},
		'&:after': {
			borderBottom: `2px solid ${textFieldLineFocusColor}`,
		},
		'&:hover:not(.Mui-disabled):before': {
			borderBottom: `2px solid ${textFieldLineHoverColor}`,
		},
	},
	textFieldInput: {
		color: 'inherit',
		// fontSize: 12,
	},
}));

export const reactSelectStyles = {
	// eslint-disable-next-line no-unused-vars
	menu: (provided, state) => ({
		...provided,
		color: 'inherit',
		background: blueGrey[400],
	}),
	// eslint-disable-next-line no-unused-vars
	option: (provided, state) => ({
		...provided,
		color: 'inherit',
		backgroundColor: 'inherit',
		'&:active': {
			backgroundColor: blueGrey[500],
		},
	}),
	// eslint-disable-next-line no-unused-vars
	singleValue: (provided, state) => {
		return {
			...provided,
			color: 'inherit',
			padding: 5,
			borderRadius: 5,
			background: blueGrey[700],
			// color: 'white',
			display: 'flex',
		};
	},
};

export default SidePanelFieldStyle;
