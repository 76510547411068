import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { S3Uploader, EllipsisLoader } from 'components';
import { moment, _, readableFileSize } from 'utils/libHelper';
import { genRandomStr } from 'utils/generalHelper';
import { getDomainConfig } from 'utils/appHelper';

// MUI components
import {
	TextField,
	MenuItem,
	Switch,
	Divider,
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Paper,
	Checkbox,
} from '@mui/material';

import { PhotoLibrary as SeeFileIcon, AddCircle as UploadIcon } from '@mui/icons-material';

// intl lang
import { useIntl } from 'react-intl';

import { getRouteFileUploaderSeeFiles } from 'routes';

// restful
import { userManagerFetchUserGroups } from 'restful';

// redux
import { connect } from 'react-redux';
import {
	postProcessUploadedFiles,
	resetPostProcessUploadedFiles,
	fetchProgressUploadedFiles,
	resetProgressUploadedFiles,
	fetchFileCategories,
	resetFileCategories,
	notifyGeneral,
	reSyncCounter,
} from 'redux/actions'; // actions

// constants
const INTERVAL_FETCH_PROGRESS = 3000; // in ms

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	uploadingWrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			flexDirection: 'row',
		},
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			flexDirection: 'column',
		},
	},
	messageWrapper: {
		height: '100%',
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			width: '75%',
		},
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: 'auto',
		},
	},
	messageContent: {
		width: '100%',
		backgroundColor: '#f3f3f3',
		borderRadius: 8,
		minHeight: 300,
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
		margin: theme.spacing(2, 0),
	},
	processProgressMessage: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(2, 1),
		minHeight: 100,
		border: `1px solid rgba(0,0,0,0.2)`,
		borderRadius: 4,
		display: 'flex',
		flexDirection: 'column',
	},
	messageFilelist: {
		overflow: 'auto',
		maxHeight: 200,
		paddingLeft: theme.spacing(2.5), // TODO: remove it. Having this style is to fix conflict to bootstrap in lasso app
	},
	messageButtons: {
		// height: messageButtonsHeight,
		// display: 'flex',
		// alignItems: 'center',
	},
	uploader: {
		flex: '1 0 auto',
		padding: theme.spacing(3),
		paddingTop: 0,
		height: '100%',
		overflowX: 'hidden',
		overflowY: 'auto',
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			height: 'auto',
		},
	},
	formData: {
		flex: '0 1 auto',
		padding: theme.spacing(2),
		paddingTop: 0,
		margin: theme.spacing(3),
		marginTop: 0,
		flexDirection: 'column',
		height: 'fit-content',
		border: '1px solid rgba(0,0,0,0.2)',
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			marginTop: theme.spacing(3),
		},
	},
	formRow: {
		width: '100%',
		padding: theme.spacing(1, 1), //`${theme.spacing(1)} ${theme.spacing(1)}`,
		display: 'flex',
		alignItems: 'center',
	},
	marginTopBottom: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(0.5),
	},
	selectMenuItem: {
		fontSize: 'inherit',
	},
	formLeftCell: {
		width: 200,
		fontWeight: 600,
	},
	formRightCell: {
		flex: '1 0 auto',
	},
	dialogContainer: {
		alignItems: 'flex-start',
		transform: 'translateY(150px)',
	},
	'@keyframes ellipsis': {
		to: { width: '1.25rem' },
	},
	progressLoading: {
		'&:after': {
			overflow: 'hidden',
			display: 'inline-block',
			verticalAlign: 'bottom',
			animation: '$ellipsis steps(4,end) 1200ms infinite',
			content: '"\\2026"' /* ascii code for the ellipsis character */,
			width: 0,
		},
	},
}));

const DividerHor = withStyles({
	root: {
		width: '100%',
	},
})(Divider);

const StyledOutlinedTextField = withStyles((theme) => ({
	textField: {
		color: 'inherit',
	},
	textFieldInput: {
		color: 'inherit',
		fontSize: 'inherit',
	},
	textFieldLabel: {
		fontSize: 'inherit',
		marginLeft: -5,
	},
	inputMarginDense: {
		paddingTop: 7,
		paddingBottom: 7,
	},
}))(({ classes, className, children, ...rest }) => {
	return (
		<TextField
			className={cx(classes.textField, { [className]: !!className })}
			size="small"
			variant="outlined"
			InputProps={{
				className: classes.textFieldInput,
				classes: { inputSizeSmall: classes.inputMarginDense },
			}}
			InputLabelProps={{
				classes: { root: classes.textFieldLabel },
			}}
			{...rest}
		>
			{children}
		</TextField>
	);
});

function FileUpload({
	domainName,
	domainSettings,
	userId,
	userLevel,
	// redux state
	uploadRequestStatus, // 'PROCESSING', 'OK', 'FAILED', ''
	uploadRequestErrMsg,
	processQueueId,
	processingProgress, //{status: 'queueing' || 'processing' || 'completed' || 'failed', sequenceNO: Number, uploadedFiles: [ { "originalName": "string" }, ... ] }
	// redux actions
	postProcessUploadedFiles,
	resetPostProcessUploadedFiles,
	fetchProgressUploadedFiles,
	resetProgressUploadedFiles,
	fetchFileCategories,
	resetFileCategories,
	notifyGeneral,
	fileCategories,
	contactAdminList,
	reSyncCounter,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const [fetchProgressTimer, setFetchProgressTimer] = React.useState(null);
	// isZipFileTooBig is true if at least one of zip files' size exceeds the limit size to unzip; if false, no zip file exceeds the limit size
	const [isZipFileTooBig, setIsZipFileTooBig] = React.useState(false);
	/**
	 * uploaded files
	 {
		 datetime: Date, // datetime of when they are uploaded completely
		 files: [{ name: 'xxx', path: 'xxx', size: Number, s3Url: 'xxxx' }, ...]
	 }
	 */
	const [uploadedFiles, setUploadedFiles] = React.useState({});
	const [cateSelections, setCateSelections] = React.useState([]);
	// user groups in the domain for user to select. format (by api spec): [{ "name": "string", "id": "string", "userCount": 0 }, ...]
	const [userGroups, setUserGroups] = React.useState([]);

	const categoryChoiceArray =
		cateSelections.length === 0
			? [{ categories: fileCategories }]
			: [{ categories: fileCategories }].concat(cateSelections);

	const artDesignUserLevelInDomainSettings = React.useMemo(() => {
		const keyValue = domainSettings.settings.find(({ key }) => key === 'artDesignUserLevel');
		return keyValue?.value ?? 100; // if domainSettings was not fetched we set the required userlevel to 100
		// so that the switch will never be shown (the max userlevel is 60 at the time of writing)
	}, [domainSettings]);

	const [formData, setFormData] = React.useState({
		unzip: true,
		fileStatus: 'live',
		groupIDs: [],
		allowSocialMedia: false,
		allowScreen: false,
		isArtworkTemplate: false,
		keywords: '',
		// approveDate: '', // optional
		// expiryDate: '', // optional
		// adminContactID: '', // optional
		previewnow: true,
		// below is fixed value
		ownerID: userId,
		userID: userId,
		dbName: domainName,
		domainName: domainName,
		domainUrl: window.location.origin,
		// uploadDatetime: new Date().toISOString(),
	});

	const domainConf = React.useMemo(() => {
		return getDomainConfig(domainName) || {};
	}, [domainName]);

	React.useEffect(() => {
		// initialize redux data
		resetPostProcessUploadedFiles();
		resetProgressUploadedFiles();
		fetchFileCategories();
		userManagerFetchUserGroups()
			.then((response) =>
				setUserGroups(
					response.data.map((userGroup) => ({ ...userGroup, id: userGroup.id.toString() }))
				)
			)
			// we ignore this error, the user groups will still be empty array []
			.catch(() => null);
		return () => {
			resetPostProcessUploadedFiles();
			resetProgressUploadedFiles();
			resetFileCategories();
			clearTimeout(fetchProgressTimer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className={classes.root}>
			{processQueueId !== 0 ? (
				<div className={classes.messageWrapper}>
					<Paper className={classes.messageContent} elevation={1}>
						<Typography variant="subtitle2">
							{intl.formatMessage({ id: 'pages.FileUpload.components.FileUpload.ProcessMsgTitle' })}
						</Typography>
						<div className={classes.processProgressMessage}>
							<Typography variant="body1">
								{`${new Intl.DateTimeFormat([], { dateStyle: 'full', timeStyle: 'long' }).format(
									uploadedFiles.datetime || new Date()
								)} (@${processQueueId})`}
							</Typography>
							<Typography
								color={processingProgress.status === 'failed' ? 'error' : 'inherit'}
								variant="h5"
								className={cx({
									[classes.progressLoading]: ['queueing', 'processing'].includes(
										processingProgress.status
									),
								})}
								style={{ padding: `8px 0px`, alignSelf: 'center' }}
							>
								{processingProgress.status === 'queueing'
									? intl.formatMessage(
											{ id: 'pages.FileUpload.components.FileUpload.ProcessMsgQueueing' },
											{ sequenceNO: processingProgress.sequenceNO }
									  )
									: processingProgress.status === 'processing'
									? intl.formatMessage({
											id: 'pages.FileUpload.components.FileUpload.ProcessMsgProcessing',
									  })
									: processingProgress.status === 'completed'
									? intl.formatMessage({
											id: 'pages.FileUpload.components.FileUpload.ProcessMsgCompleted',
									  })
									: processingProgress.status === 'failed'
									? intl.formatMessage({
											id: 'pages.FileUpload.components.FileUpload.ProcessMsgFailed',
									  })
									: ''}
							</Typography>
							{
								<ul className={classes.messageFilelist}>
									{(uploadedFiles.files || []).map((file, idx) => (
										<li key={file.path + '-' + idx}>
											<span>{file.path}</span>
										</li>
									))}
								</ul>
							}
						</div>
						<div className={classes.messageButtons}>
							<Button
								color="primary"
								size="small"
								variant="contained"
								startIcon={<SeeFileIcon fontSize="small" />}
								onClick={() => {
									window.location.assign(getRouteFileUploaderSeeFiles());
								}}
							>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.ActionSeeFiles',
								})}
							</Button>{' '}
							<Button
								color="primary"
								size="small"
								variant="contained"
								startIcon={<UploadIcon fontSize="small" />}
								onClick={() => {
									resetPostProcessUploadedFiles();
									resetProgressUploadedFiles();
									// resetFileCategories(); // we don't reset categories as we still require it in continued upload
									clearTimeout(fetchProgressTimer);
									// reset local states
									setUploadedFiles({});
									setCateSelections([]);
									setFormData({
										unzip: true,
										fileStatus: 'live',
										groupIDs: [],
										allowSocialMedia: false,
										allowScreen: false,
										isArtworkTemplate: false,
										keywords: '',
										// approveDate: '',
										// expiryDate: '',
										// adminContactID: '',
										previewnow: true,
										// below is fixed value
										ownerID: userId,
										userID: userId,
										dbName: domainName,
										domainName: domainName,
										domainUrl: window.location.origin,
										// uploadDatetime: new Date().toISOString(),
									});
								}}
							>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.ActionContinueUploading',
								})}
							</Button>
						</div>
					</Paper>
				</div>
			) : (
				// when processQueueId === 0
				<div className={classes.uploadingWrapper}>
					<div className={classes.uploader}>
						<S3Uploader
							// title="Uploading Files"
							actionOnFailure="CONFIRM"
							handleLargestZipFile={(newLargestZipFile) => {
								if (
									!isZipFileTooBig &&
									(newLargestZipFile?.size ?? 0) > domainConf.maxFileUploaderZipfileSizeToUnzip
								) {
									notifyGeneral(
										intl.formatMessage(
											{
												id: 'pages.FileUpload.components.FileUpload.DisableUnzipWarningMsg',
											},
											{
												maxSizeToUnzip: readableFileSize(
													domainConf.maxFileUploaderZipfileSizeToUnzip
												),
											}
										),
										'warning'
									);
									setIsZipFileTooBig(true);
								} else if (
									isZipFileTooBig &&
									(newLargestZipFile?.size ?? 0) <= domainConf.maxFileUploaderZipfileSizeToUnzip
								) {
									setIsZipFileTooBig(false);
								}
							}}
							maxSize={domainConf.maxFileUploaderSingleFileSize}
							maxTotalSize={domainConf.maxFileUploaderTotalFilesSize}
							s3Bucket={domainConf.s3Bucket}
							s3FilepathBase={`/${domainName}/${moment.utc().format('YYYYMMDD')}/${genRandomStr(
								28
							)}/`}
							handleFileUploadComplete={(result) => {
								let now = new Date();
								setUploadedFiles({
									datetime: now,
									files: result.completedFiles,
								});
								let reqData = {
									...formData,
									unzip: isZipFileTooBig ? false : formData.unzip,
									uploadDatetime: now.toISOString(),
									files: result.completedFiles.map((f) => ({
										originalName: f.name,
										size: f.size,
										type: f.type,
										url: f.s3Url,
									})),
								};
								if (reqData.groupIDs.length === 0) delete reqData.groupIDs;
								if (cateSelections.length > 0) {
									reqData.categoryID = cateSelections[cateSelections.length - 1]['id'].toString();
								}

								postProcessUploadedFiles({ reqData }, ({ result, error }) => {
									// when error, no need to fetch progress
									if (error) return;
									// use setInterval to fetch progress. (Problem with setInterval is that it may start a new fetch before previous fetch finishes)
									// (Keeping it as comment is only for reference. We are using setTimeout to continously fetch processing progress)
									// let timerId = setInterval(() => {
									// 	fetchProgressUploadedFiles(
									// 		{ queueID: result.data.queueID },
									// 		({ result, error }) => {
									// 			if (!error && ['completed', 'failed'].includes(result.data.status)) {
									// 				clearInterval(timerId);
									// 			}
									// 		}
									// 	);
									// }, INTERVAL_FETCH_PROGRESS);
									// setFetchProgressTimer(timerId);

									// use setTimeout to fetch progress
									const fetchProgress = () => {
										let timeoutTimer = setTimeout(() => {
											fetchProgressUploadedFiles(
												{ queueID: result.data.queueID },
												({ result, error }) => {
													if (!error && ['completed', 'failed'].includes(result.data.status)) {
														clearTimeout(timeoutTimer);
														reSyncCounter();
													} else {
														fetchProgress();
													}
												}
											);
										}, INTERVAL_FETCH_PROGRESS);
										setFetchProgressTimer(timeoutTimer);
									};
									fetchProgress();
								});
							}}
						/>
					</div>
					<Paper className={classes.formData} elevation={3}>
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.FormStatusText',
								})}
							</span>
							<div className={classes.formRightCell}>
								<StyledOutlinedTextField
									variant="outlined"
									select
									fullWidth
									value={formData.fileStatus}
									onChange={(e) => setFormData({ ...formData, fileStatus: e.target.value })}
								>
									{[
										{ label: 'Downloadable', value: 'live' },
										{ label: 'Newly Added', value: 'new' },
									].map((option) => (
										<MenuItem
											key={option.value}
											value={option.value}
											className={classes.selectMenuItem}
										>
											{option.label}
										</MenuItem>
									))}
								</StyledOutlinedTextField>
							</div>
						</div>
						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.UploadFormUnzip',
								})}
							</span>
							<div className={classes.formRightCell}>
								{intl.formatMessage({ id: 'GENERAL.No' })}
								<Switch
									disabled={isZipFileTooBig}
									checked={isZipFileTooBig ? false : formData.unzip}
									onChange={(e) => setFormData({ ...formData, unzip: e.target.checked })}
									color="primary"
								/>
								{intl.formatMessage({ id: 'GENERAL.Yes' })}
							</div>
						</div>
						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.UploadFormUserGroups',
								})}
							</span>
							<div className={classes.formRightCell}>
								<StyledOutlinedTextField
									variant="outlined"
									select
									fullWidth
									value={formData.groupIDs || []}
									SelectProps={{
										multiple: true,
										renderValue: (value) => {
											return value.length > 1
												? `${value.length} selected`
												: value.length === 1
												? userGroups.find((g) => g.id === value[0])?.name || ''
												: '';
										},
									}}
									onChange={(e) => {
										setFormData({
											...formData,
											groupIDs: e.target.value,
										});
									}}
								>
									{userGroups.map((userGroup) => (
										<MenuItem
											key={userGroup.id}
											value={userGroup.id}
											className={classes.selectMenuItem}
										>
											<Checkbox checked={formData.groupIDs.includes(userGroup.id)} size="small" />
											{userGroup.name}
										</MenuItem>
									))}
								</StyledOutlinedTextField>
							</div>
						</div>

						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.UploadFormSocialMedia',
								})}
							</span>
							<div className={classes.formRightCell}>
								{intl.formatMessage({ id: 'GENERAL.No' })}
								<Switch
									checked={formData.allowSocialMedia}
									onChange={(e) => setFormData({ ...formData, allowSocialMedia: e.target.checked })}
									color="primary"
								/>
								{intl.formatMessage({ id: 'GENERAL.Yes' })}
							</div>
						</div>
						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.UploadFormScreenEnabled',
								})}
							</span>
							<div className={classes.formRightCell}>
								{intl.formatMessage({ id: 'GENERAL.No' })}
								<Switch
									checked={formData.allowScreen}
									onChange={(e) => setFormData({ ...formData, allowScreen: e.target.checked })}
									color="primary"
								/>
								{intl.formatMessage({ id: 'GENERAL.Yes' })}
							</div>
						</div>
						{userLevel >= artDesignUserLevelInDomainSettings && (
							<React.Fragment>
								<DividerHor />
								<div className={classes.formRow}>
									<span className={classes.formLeftCell}>
										{intl.formatMessage({
											id: 'pages.FileUpload.components.FileUpload.UploadFormArtworkEnabled',
										})}
									</span>
									<div className={classes.formRightCell}>
										{intl.formatMessage({ id: 'GENERAL.No' })}
										<Switch
											checked={formData.isArtworkTemplate}
											onChange={(e) =>
												setFormData({ ...formData, isArtworkTemplate: e.target.checked })
											}
											color="primary"
										/>
										{intl.formatMessage({ id: 'GENERAL.Yes' })}
									</div>
								</div>
							</React.Fragment>
						)}
						{fileCategories.length > 0 && (
							<React.Fragment>
								<DividerHor />
								<div className={classes.formRow}>
									<span className={classes.formLeftCell}>
										{intl.formatMessage({
											id: 'pages.FileUpload.components.FileUpload.UploadFormCategory',
										})}
									</span>
									<div
										className={classes.formRightCell}
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										{categoryChoiceArray.map((item, idx) => {
											if (
												idx !== 0 &&
												(!Array.isArray(item.categories) || item.categories.length === 0)
											)
												return null;
											return (
												<StyledOutlinedTextField
													key={idx}
													variant="outlined"
													className={classes.marginTopBottom}
													select
													fullWidth
													value={
														categoryChoiceArray[idx + 1] ? categoryChoiceArray[idx + 1].id : ''
													}
													onChange={(e) => {
														let newCateSelection = cateSelections.slice(0, idx);
														if (e.target.value)
															newCateSelection[idx] = _.find(
																item.categories,
																(c) => c.id === e.target.value
															);
														setCateSelections(newCateSelection);
													}}
												>
													<MenuItem value="" className={classes.selectMenuItem}>
														<em>{intl.formatMessage({ id: 'GENERAL.None' })}</em>
													</MenuItem>
													{(item.categories || []).map((cate) => (
														<MenuItem
															key={cate.id}
															value={cate.id}
															className={classes.selectMenuItem}
														>
															{cate.name}
														</MenuItem>
													))}
												</StyledOutlinedTextField>
											);
										})}
									</div>
								</div>
							</React.Fragment>
						)}
						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.UploadFormKeywords',
								})}
							</span>

							<div className={classes.formRightCell}>
								<StyledOutlinedTextField
									variant="outlined"
									fullWidth
									// value={formData.keywords}
									onBlur={(e) => setFormData({ ...formData, keywords: e.target.value || '' })}
								/>
							</div>
						</div>
						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.UploadFormStartDate',
								})}
							</span>
							<div className={classes.formRightCell}>
								<StyledOutlinedTextField
									variant="outlined"
									fullWidth
									type="datetime-local"
									onBlur={(e) => {
										setFormData({
											...formData,
											approveDate: e.target.value
												? new Date(e.target.value).toISOString()
												: undefined,
										});
									}}
								/>
							</div>
						</div>
						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.UploadFormPostingDate',
								})}
							</span>
							<div className={classes.formRightCell}>
								<StyledOutlinedTextField
									variant="outlined"
									fullWidth
									type="datetime-local"
									onBlur={(e) => {
										setFormData({
											...formData,
											postingDate: e.target.value
												? new Date(e.target.value).toISOString()
												: undefined,
										});
									}}
								/>
							</div>
						</div>
						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.UploadFormExpiryDate',
								})}
							</span>
							<div className={classes.formRightCell}>
								<StyledOutlinedTextField
									variant="outlined"
									fullWidth
									type="datetime-local"
									onBlur={(e) => {
										setFormData({
											...formData,
											expiryDate: e.target.value
												? new Date(e.target.value).toISOString()
												: undefined,
										});
									}}
								/>
							</div>
						</div>
						<DividerHor />
						<div className={classes.formRow}>
							<span className={classes.formLeftCell}>
								{intl.formatMessage({
									id: 'pages.FileUpload.components.FileUpload.ContactAdminListText',
								})}
							</span>
							<div className={classes.formRightCell}>
								<StyledOutlinedTextField
									variant="outlined"
									select
									fullWidth
									value={formData.adminContactID || ''}
									onChange={(e) => {
										if (!e.target.value) {
											let currentFormData = { ...formData };
											delete currentFormData.adminContactID;
											setFormData(currentFormData);
										} else {
											setFormData({ ...formData, adminContactID: e.target.value });
										}
									}}
								>
									<MenuItem value="" className={classes.selectMenuItem}>
										<em>{intl.formatMessage({ id: 'GENERAL.None' })}</em>
									</MenuItem>
									{contactAdminList.map((adminUser) => (
										<MenuItem
											key={adminUser.id}
											value={adminUser.id}
											className={classes.selectMenuItem}
										>
											{`${adminUser.firstname} ${adminUser.lastname}`}
										</MenuItem>
									))}
								</StyledOutlinedTextField>
							</div>
						</div>
						<DividerHor />
					</Paper>
				</div>
			)}

			<Dialog
				open={['PROCESSING', 'FAILED'].includes(uploadRequestStatus)}
				fullWidth
				maxWidth="xs"
				classes={{ container: classes.dialogContainer }}
			>
				{uploadRequestStatus === 'PROCESSING' && (
					<DialogContent
						style={{
							alignItems: 'center',
							justifyContent: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Typography variant="h3">
							{intl.formatMessage({
								id: 'pages.FileUpload.components.FileUpload.ValidateFilesTitle',
							})}
						</Typography>
						<Typography variant="subtitle2">
							{intl.formatMessage({
								id: 'pages.FileUpload.components.FileUpload.ValidateFilesMsg',
							})}
						</Typography>
						<EllipsisLoader style={{ fontSize: '4rem' }} />
					</DialogContent>
				)}
				{uploadRequestStatus === 'FAILED' && (
					<DialogContent>
						<Typography
							color="error"
							variant="h4"
							style={{
								paddingBottom: 16,
							}}
						>
							{intl.formatMessage({ id: 'GENERAL.Error' })}
						</Typography>
						<Typography
							color="error"
							style={{
								paddingBottom: 16,
							}}
						>
							{intl.formatMessage(
								{
									id: 'pages.FileUpload.components.FileUpload.ValidateFilesErrorMsg',
								},
								{ uploadRequestErrMsg }
							)}
						</Typography>
					</DialogContent>
				)}
				{uploadRequestStatus === 'FAILED' && (
					<DialogActions>
						<Button variant="contained" onClick={() => resetPostProcessUploadedFiles()}>
							{intl.formatMessage({ id: 'GENERAL.Close' })}
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
}

FileUpload.propTypes = {
	/**
	 * Redux props
	 */
	domainName: PropTypes.string.isRequired,
	domainSettings: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired,
	userLevel: PropTypes.number.isRequired,
	uploadRequestStatus: PropTypes.string.isRequired,
	uploadRequestErrMsg: PropTypes.string.isRequired,
	processQueueId: PropTypes.number.isRequired,
	processingProgress: PropTypes.object.isRequired,
	postProcessUploadedFiles: PropTypes.func.isRequired,
	resetPostProcessUploadedFiles: PropTypes.func.isRequired,
	fetchProgressUploadedFiles: PropTypes.func.isRequired,
	resetProgressUploadedFiles: PropTypes.func.isRequired,
	fetchFileCategories: PropTypes.func.isRequired,
	resetFileCategories: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	fileCategories: PropTypes.array.isRequired,
	contactAdminList: PropTypes.array.isRequired,
	reSyncCounter: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		domainName: state.authentication.domainName,
		domainSettings: state.domainSettings,
		userId: state.authentication.userId,
		userLevel: state.authentication.userLevel,
		uploadRequestStatus: state.filemanager.postProcessUploadedFilesStatus,
		uploadRequestErrMsg: state.filemanager.postProcessUploadedFilesErrMsg,
		processQueueId: state.filemanager.postProcessQueueId,
		processingProgress: state.filemanager.progressOfUploadedFiles,
		fileCategories: state.filemanager.categories,
		contactAdminList: state.usermanager.userData.adminUsers || [],
	};
};

export default connect(mapStateToProps, {
	postProcessUploadedFiles,
	resetPostProcessUploadedFiles,
	fetchProgressUploadedFiles,
	resetProgressUploadedFiles,
	fetchFileCategories,
	resetFileCategories,
	notifyGeneral,
	reSyncCounter,
})(FileUpload);
