import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';

import { Message as MessageIcon } from '@mui/icons-material';

import { ROUTES_PATH_USERMESSAGES_LIST } from 'routes';

const UserMessagesList = loadable(() => import('./components/UserMessagesList/UserMessageList'), {
	fallback: <Loading size={50} />,
});

const UserMessagesRoutes = [
	{
		path: ROUTES_PATH_USERMESSAGES_LIST,
		sidebarName: <FormattedMessage id="pages.UserMessages.Routes.UserMessages" />,
		sidebarIcon: MessageIcon,
		component: UserMessagesList,
		noBreadcrumbs: false,
	},
];
export default UserMessagesRoutes;
