// playlist slide
import {
	GET_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	GET_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_GETSTATUS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	ADD_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_DELETESTATUS,
} from 'redux/actions';
import {
	REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST,
	REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS,
	REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL,
	RESET_SCREENMANAGER_PLAYLISTS_SLIDE_REORDERSTATUS,
} from 'redux/actions';
import { RESET_SCREENMANAGER_PLAYLIST_SLIDE } from 'redux/actions';

const screenManagerPlaylistsInitState = {
	playlist: null,
	isGettingPlaylist: false,
	addingSlideStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	editingSlideStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	deleteSlideStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	reorderSlideStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
};

export const screenManagerPlaylistSlide = (state = screenManagerPlaylistsInitState, action) => {
	switch (action.type) {
		// PLAYLIST Slide
		case RESET_SCREENMANAGER_PLAYLIST_SLIDE:
			return {
				...state,
				playlist: null,
				isGettingPlaylist: false,
				addingSlideStatus: '',
				editingSlideStatus: '',
				deleteSlideStatus: '',
				reorderSlideStatus: '',
			};
		case GET_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST:
			return {
				...state,
				isGettingPlaylist: true,
			};
		case GET_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS:
			return {
				...state,
				playlist: action.result.data,
				isGettingPlaylist: false,
			};
		case GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
			return {
				...state,
				isGettingPlaylist: false,
			};
		case RESET_SCREENMANAGER_PLAYLISTS_SLIDE_GETSTATUS:
			return {
				...state,
				isGettingPlaylist: false,
			};

		case EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST:
			return {
				...state,
				editingSlideStatus: 'PROCESSING',
			};
		case EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS:
			return {
				...state,
				playlist: action.result.data,
				editingSlideStatus: 'OK',
			};
		case EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
			return {
				...state,
				editingSlideStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_PLAYLISTS_SLIDE_EDITSTATUS:
			return {
				...state,
				editingSlideStatus: '',
			};
		case ADD_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST:
			return {
				...state,
				addingSlideStatus: 'PROCESSING',
			};
		case ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
			return {
				...state,
				addingSlideStatus: 'FAILED',
			};
		case ADD_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS:
			return {
				...state,
				playlist: action.result.data,
				addingSlideStatus: 'OK',
			};
		case RESET_SCREENMANAGER_PLAYLISTS_SLIDE_ADDSTATUS:
			return {
				...state,
				addingSlideStatus: '',
			};
		case DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST:
			return {
				...state,
				deleteSlideStatus: 'PROCESSING',
			};
		case DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS:
			return {
				...state,
				playlist: action.result.data,
				deleteSlideStatus: 'OK',
			};
		case DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
			return {
				...state,
				deleteSlideStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_PLAYLISTS_SLIDE_DELETESTATUS:
			return {
				...state,
				deleteSlideStatus: '',
			};
		case REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST:
			return {
				...state,
				reorderSlideStatus: 'PROCESSING',
			};
		case REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS:
			return {
				...state,
				playlist: action.result.data,
				reorderSlideStatus: 'OK',
			};
		case REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL:
			return {
				...state,
				reorderSlideStatus: 'FAILED',
			};
		case RESET_SCREENMANAGER_PLAYLISTS_SLIDE_REORDERSTATUS:
			return {
				...state,
				reorderSlideStatus: '',
			};
		default:
			return state;
	}
};
