/*
 *
 * LangProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and lang messages (loaded from `lang`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';

import { makeSelectLocale } from './selectors';

export class LangProvider extends React.PureComponent {
	render() {
		return (
			<IntlProvider
				locale={this.props.locale}
				key={this.props.locale}
				messages={this.props.messages[this.props.locale]}
			>
				{React.Children.only(this.props.children)}
			</IntlProvider>
		);
	}
}

LangProvider.propTypes = {
	locale: PropTypes.string.isRequired, // from selector redux store "lang" state
	messages: PropTypes.object.isRequired, // passed in prop
};

LangProvider.defaultProps = {};

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
	locale,
}));

export default connect(mapStateToProps, {})(LangProvider);
