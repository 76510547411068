// ##############################
// // // App styles
// #############################
const AppLayoutStyle = (theme) => ({
	// wrapper: {
	// 	position: "relative",
	// 	top: "0",
	// 	minHeight: '100vh',
	// 	// height: "100%",
	// 	width: '100%',
	// },
	wrapper: {
		position: 'relative',
		top: '0',
		height: '100vh',
		// [theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
		// 	width: '100%',
		// 	height: '100% !important',
		// 	position: 'fixed',
		// },
		'&:after': {
			display: 'table',
			clear: 'both',
			content: '" "',
		},
	},
	fixForMobileView: {
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: '100%',
			height: '100% !important',
			position: 'fixed',
		},
	},
	mainPanel: {
		transitionProperty: 'top, bottom, width',
		transitionDuration: '.2s, .2s, .35s',
		transitionTimingFunction: 'linear, linear, ease',
		overflow: 'auto',
		position: 'relative',
		float: 'right',
		...theme.customTransition,
		minHeight: '100%',
		maxHeight: '100%',
		height: '100%',
		width: '100%',
		overflowScrolling: 'touch',
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			width: `calc(100% - ${theme.drawerWidth}px)`,
		},
	},
	// mainPanel: {
	// 	// overflow: "auto",
	// 	position: "relative",
	// 	float: "right",
	// 	...theme.customTransition,
	// 	// maxHeight: "100%",
	// 	minHeight: '100vh',
	// 	width: "100%",
	// 	overflowScrolling: 'touch',
	// 	[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
	// 		width: `calc(100% - ${theme.drawerWidth}px)`
	// 	},
	// 	// [theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
	// 	// 	transition: theme.transitions.create(['margin', 'width'], {
	// 	// 		easing: theme.transitions.easing.sharp,
	// 	// 		duration: theme.transitions.duration.leavingScreen,
	// 	// 	}),
	// 	// }
	// },
	// mainPanelShift: {
	// 	[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
	// 		width: `calc(100% - ${theme.drawerWidth}px)`,
	// 		marginLeft: theme.drawerWidth,
	// 		transition: theme.transitions.create(['margin', 'width'], {
	// 			easing: theme.transitions.easing.easeOut,
	// 			duration: theme.transitions.duration.enteringScreen,
	// 		}),
	// 	}
	// },
	// content: {
	// 	// marginTop: "20px",
	// 	// padding: "50px 15px",
	// 	padding: theme.spacing(1),
	// 	minHeight: `calc(100% - ${theme.headerBarHeight}px)`
	// },
	content: {
		// marginTop: '70px',
		// padding: '15px 15px',  // '30px 15px' maybe better after replacing lasso
		// minHeight: `calc(100vh - ${theme.headerBarHeight}px)`,
		top: theme.headerBarHeight,
		left: 0,
		bottom: 0,
		right: 0,
		position: 'absolute',
		overflow: 'auto',
	},
	// container: {
	// 	paddingRight: theme.spacing(2),
	// 	paddingLeft: theme.spacing(2),
	// 	marginRight: 'auto',
	// 	marginLeft: 'auto'
	// },
	container: {
		...theme.customContainer,
		paddingLeft: 'inherit',
		paddingRight: 'inherit',
		position: 'relative',
		width: '100%',
		// height: `calc(100% - ${theme.breadcrumbHeight}px)`,
	},
	mainPanelSidebarMini: {
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			width: `calc(100% - ${theme.drawerMiniWidth}px)`,
		},
	},
	mainPanelSidebarHidden: {
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			width: '100%',
		},
	},
	mainPanelWithPerfectScrollbar: {
		overflow: 'hidden !important',
	},
});

export default AppLayoutStyle;
