import React from 'react';
import PropTypes from 'prop-types';

import { FILTERS, FILTERS_SETTINGS_MAP } from '../../Constants.js';
import { makeStyles } from '@mui/styles';
import { amber, blue, orange } from '@mui/material/colors';
import {
	IconButton,
	Button,
	Menu,
	MenuItem,
	Typography,
	Checkbox,
	ListItemText,
	Switch,
	Chip,
	useMediaQuery,
	Tooltip,
	TextField,
	// RadioGroup,
	// FormControlLabel,
	Select,
	FormControl,
	// Radio,
} from '@mui/material';
import { Autocomplete } from '@mui/material';

import {
	FilterList as FilterIcon,
	ViewModule as GridViewIcon,
	ViewList as TableViewIcon,
	Done as ApplyIcon,
	Refresh as ResetIcon,
	Close as CloseIcon,
	Cancel as CancelIcon,
	Search as SearchIcon,
	// Clear as ClearIcon,
} from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { useIntl } from 'react-intl';
import { DateRangeSelector, PageTour } from 'components';

// constants
import { TABLE_VIEW, GRID_VIEW } from '../../Constants';

import _without from 'lodash/without';

import { getCatagoryTags } from 'restful';
import { FormattedMessage } from 'react-intl';

const FONTSIZE = '11px';
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	dateRangeRoot: {
		width: '100%',
	},
	button: {
		padding: theme.spacing(0.5, 1),
		whiteSpace: 'nowrap',
	},
	interactionSection: {
		display: 'flex',
	},
	filter: {
		flex: `0 1 auto`,
	},
	search: {
		flex: `1 0 auto`,
		// display: 'flex',
	},
	viewSwitchBtns: {
		flex: `0 1 auto`,
		display: 'inline-flex',
		gap: theme.spacing(0, 0.5),
	},
	helpButton: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '8px',
		// gap: theme.spacing(0.5, 0),
	},
	filtersDisplaySection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		gap: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	selectedFilterChipsContainer: {
		...theme.customBoxShadow,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5),
		padding: theme.spacing(0.5, 1),
		border: `1px solid rgba(0,0,0,0.2)`,
		borderRadius: 16,
		backgroundColor: amber[200],
	},
	filterPaper: {
		marginTop: theme.spacing(1),
		maxHeight: 300,
	},
	filtersContainer: {
		position: 'relative',
		maxWidth: 530,
		// maxHeight: 300,
		padding: theme.spacing(0.5, 2),
		// marginTop: theme.spacing(1),
		// overflowY: 'auto',
		// overflowX: 'hidden',
	},
	filterSection: {
		display: 'flex',
		flexDirection: 'column',
		'&::after': {
			content: `""`,
			// width: '100%',
			border: 'none',
			height: 1,
			margin: 0,
			// flexShrink: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.12)',
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	datetimeFilterContainer: {
		display: 'flex',
		gap: theme.spacing(1),
		alignItems: 'center',
	},
	filterMenuItemContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	filterMenuItem: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	switchFilterContainer: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},
	switchFilterName: {
		minWidth: 140,
	},
	filterSwitch: {
		marginLeft: theme.spacing(1),
	},
	filtersBtnContainer: {
		display: 'flex',
		gap: theme.spacing(1),
		padding: theme.spacing(0.5, 1),
		alignItems: 'center',
		justifyContent: 'flex-end',
		position: 'sticky',
		bottom: 0,
		zIndex: 1,
		float: 'right',
	},
	sortByWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},
	sortBySelectWrapper: {
		flex: '1 0 auto',
		maxWidth: '80%',
	},
	sortBySelect: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	sortByDirection: {
		flex: '0 0 auto',
		marginLeft: theme.spacing(2),
	},

	// Multi-Select dropdown, chips filter CSS
	screenSelectionWrapper: {
		// width: '400px',
		flex: '0 1 auto',
		// margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(
		// 	2
		// )}`,
	},
	selectRoot: {
		height: 'auto',
		padding: '8px 32px 8px 14px',
		width: '100%',
	},
	formControl: {
		// margin: theme.spacing(1),
		// minWidth: 314,
		width: '100%',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		backgroundColor: orange[500],
	},
	// PAGE TOUR CSS
	helpIcon: {
		marginLeft: 'auto',
		marginRight: 10,
		cursor: 'pointer',
		color: blue[700],
	},
	searchWrapper: {
		// textAlign: 'center',
		flex: '1 0 auto',
		fontSize: 'inherit',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		// '&::after': {
		// 	content: `""`,
		// 	// width: '100%',
		// 	border: 'none',
		// 	height: 1,
		// 	margin: 0,
		// 	// flexShrink: 0,
		// 	backgroundColor: 'rgba(0, 0, 0, 0.12)',
		// 	marginTop: theme.spacing(1),
		// 	marginBottom: theme.spacing(1),
		// },
	},
	autoCompleteSearch: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '50%',
		// width: '100%',
	},
	input: {
		marginLeft: theme.spacing(1),
		fontSize: 'inherit',
		flex: '1 0 auto',
		backgroundColor: '#ffffff',
		paddingLeft: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	iconButton: {
		marginLeft: '8px',
	},
	autoCompleteInputRoot: {
		fontSize: 'inherit',
		padding: '4px !important',
		paddingRight: '40px !important',
	},
	autoCompleteClearIndicator: {
		padding: 0,
		margin: 0,
	},
	autoCompletePopupIndicator: {
		padding: 0,
		margin: 0,
	},
	autoCompleteEndAdornment: {
		top: 'unset',
		right: '0px !important',
	},
	autoCompleteListbox: {
		fontSize: FONTSIZE,
	},
	autoCompleteOption: {
		'&.MuiAutocomplete-option.Mui-focused': {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
	},
}));

function MediafileFilters({
	searchKeywords,
	selectedFilters,
	setSearchKeywords,
	setSelectedFilters,
	viewMode,
	handleViewSwitch,
	columnsConfigure,
	appliedSortBy,
	setSortBy,
	// domainName,
	domainSettings,
	userLevel,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const isMobileView = !useMediaQuery((theme) => theme.breakpoints.up(theme.mobileViewBreakpoint));

	const [filterMenuAnchor, setFilterMenuAnchor] = React.useState(null);
	// localFilters holds all filters, including the previously selected and the newly selected filters
	const [localFilters, setLocalFilters] = React.useState({});
	// localSortBy contains local sortBy,  it is applied only when "Apply" button is clicked (handled by handleApplyFilters)
	const [localSortBy, setLocalSortBy] = React.useState([]);

	const [localSelectFilter, setLocalSelectFilter] = React.useState({});
	// openFilterDropdown contains searchableKey of Select dropdown, the value is set to it when Dropdown is opened(handleOpenFilterdropdown event) and initialize on Closed(handleCloseFilterdropdown event)
	const [openFilterDropdown, setOpenFilterDropdown] = React.useState('');
	const [inputValue, setInputValue] = React.useState(searchKeywords);
	const [categoryTags, setCategoryTags] = React.useState([]);
	const [runPageTour, setRunPageTour] = React.useState();

	const sortableFields = React.useMemo(() => {
		return columnsConfigure.reduce((accu, column) => {
			return [
				...accu,
				...(column.disableSortBy ? [] : [{ label: column.Header, value: column.id }]),
			];
		}, []);
	}, [columnsConfigure]);
	const handleCloseFilterMenu = () => setFilterMenuAnchor(null);
	const handleResetFilter = () => {
		if (Object.keys(selectedFilters).length !== 0) setSelectedFilters({});
		if (appliedSortBy.length !== 0) setSortBy([]);
		setFilterMenuAnchor(null);
	};
	const handleApplyFilters = () => {
		setSelectedFilters(localFilters);
		setSortBy(localSortBy);
		setFilterMenuAnchor(null);
	};
	// Multi Select dropdown Close button
	const handleCloseFilterdropdown = () => {
		setOpenFilterDropdown('');
	};
	// It will set value(searchableKey) onOpen event if dropdown.
	const handleOpenFilterdropdown = (searchableKey) => {
		setOpenFilterDropdown(searchableKey);
	};
	const handleChangeFilterdropdown = (event, searchableKey) => {
		if (event.target.value.length > 0) {
			var filtered = event.target.value.filter((item) => {
				return item !== undefined;
			});
			if (filtered.length > 0) {
				setLocalFilters({
					...localFilters,
					[searchableKey]: filtered,
				});
				setLocalSelectFilter({
					...localSelectFilter,
					[searchableKey]: filtered,
				});
			}
		} else {
			let currFilters = { ...localFilters };
			delete currFilters[searchableKey];
			setLocalFilters(currFilters);
			setLocalSelectFilter(currFilters);
		}
	};
	// Delete the chip inside the select dropdown
	const handleDeleteChip = (e, value, searchableKey) => {
		e.preventDefault();
		let selectedDataDelete = [...(localSelectFilter[searchableKey] || [])];

		selectedDataDelete.splice(selectedDataDelete.indexOf(value), 1);
		if (selectedDataDelete.length > 0) {
			setLocalSelectFilter({
				...localSelectFilter,
				[searchableKey]: selectedDataDelete,
			});

			setLocalFilters({
				...localFilters,
				[searchableKey]: selectedDataDelete,
			});
		} else {
			let currFilters = { ...localFilters };
			delete currFilters[searchableKey];
			setLocalFilters(currFilters);
			setLocalSelectFilter(currFilters);
		}
	};

	const getDomainSettings = (searchableKey) => {
		let value;
		domainSettings.settings.forEach((el) => {
			if (el.key === searchableKey) {
				value = el.value && el.settingMinUserLevel <= userLevel;
				return value;
			}
		});
		return value;
	};
	React.useEffect(() => {
		getCatagoryTags()
			.then((response) => {
				setCategoryTags(response.data.results);
			})
			.catch((err) => {
				let msg = err.response?.data.message || err.message;
				console.log('ERROR ->', msg);
				// notifyGeneral(`Can not fetch filters. [${msg}]`, 'error');
			});
	}, []);

	return (
		<div className={classes.root}>
			<section className={classes.interactionSection}>
				<div className={'step2 ' + classes.filter}>
					<Button
						endIcon={<FilterIcon />}
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={(e) => {
							if (FILTERS.length > 0 && !filterMenuAnchor) {
								setLocalSelectFilter(selectedFilters); // reset local multi-select(dropdown) when opening up the filters menu
								setLocalFilters(selectedFilters); // reset local filters by user selected filters when open up the filters menu
								setLocalSortBy(appliedSortBy); // reset local sortBy when opening up the filters menu
								setFilterMenuAnchor(e.currentTarget);
							}
						}}
						aria-controls="filters-menu"
						aria-haspopup="true"
					>
						{intl.formatMessage({
							id: 'pages.MediaFiles.components.MediafileFilters.FilterBtnText',
						})}
					</Button>
				</div>
				<div className={classes.search}>
					<div className={classes.searchWrapper}>
						<Autocomplete
							id="free-solo-demo"
							className={'step3 ' + classes.autoCompleteSearch}
							size="small"
							// disableClearable={true}
							// value={inputValue}
							selectOnFocus={true}
							blurOnSelect={true}
							// popupIcon={<DropDownIcon fontSize="small" />}
							// 		closeIcon={<ClearIcon fontSize="small" />}
							classes={{
								inputRoot: classes.autoCompleteInputRoot,
								clearIndicator: classes.autoCompleteClearIndicator,
								popupIndicator: classes.autoCompletePopupIndicator,
								endAdornment: classes.autoCompleteEndAdornment,
								listbox: classes.autoCompleteListbox,
								option: classes.autoCompleteOption,
							}}
							freeSolo
							options={categoryTags.map((option) => option)}
							// options={_.sortBy(categoryTags, [(pl) => pl.toLowerCase()])}
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.input}
									variant="outlined"
									// value={inputValue || ''}
									// size="small"
									placeholder={intl.formatMessage({
										id: 'pages.MediaFiles.components.MediafileFilters.SearchFieldText',
									})}
								/>
							)}
							onInputChange={(e, value, reason) => {
								// reset reason is by user selection, we don't take any action by that reason
								if (reason === 'clear') {
									setInputValue('');
									setSearchKeywords('');
									// return null;
								}
							}}
							// getOptionLabel={(option) => option}
							// inputValue={inputValue}
							onChange={(event, newValue, reason) => {
								if (reason === 'selectOption' || reason === 'createOption') {
									setInputValue(newValue);
									setSearchKeywords(newValue);
								} else if (reason === 'clear') {
									// delete it from user input data onClear
									setInputValue('');
									setSearchKeywords('');
								}
							}}
							onBlur={(e) => {
								setInputValue(e.target.value);
							}}
						/>
						<IconButton
							className={classes.iconButton}
							aria-label="Search"
							onClick={() => {
								setSearchKeywords(inputValue);
							}}
							title="Search"
							size="small"
						>
							<SearchIcon />
						</IconButton>
						{/* <IconButton
							className={classes.iconButton}
							aria-label="Clear"
							onClick={() => {
								setInputValue('');
								setSearchKeywords('');
							}}
							title="Clear"
							size="small"
						>
							<ClearIcon />
						</IconButton> */}
					</div>
				</div>
				{!isMobileView && (
					<React.Fragment>
						<div className={'step4 ' + classes.viewSwitchBtns}>
							{/* <HelpIcon onClick={() => setRunPageTour(true)} className={classes.helpIcon + ' step1'} /> */}
							{viewMode === GRID_VIEW ? (
								<Tooltip
									title={intl.formatMessage({
										id: 'pages.MediaFiles.components.MediafileFilters.TableViewTooltip',
									})}
								>
									<IconButton size="small" onClick={() => handleViewSwitch(TABLE_VIEW)}>
										<TableViewIcon />
									</IconButton>
								</Tooltip>
							) : viewMode === TABLE_VIEW ? (
								<Tooltip
									title={intl.formatMessage({
										id: 'pages.MediaFiles.components.MediafileFilters.GridViewTooltip',
									})}
								>
									<IconButton size="small" onClick={() => handleViewSwitch(GRID_VIEW)}>
										<GridViewIcon />
									</IconButton>
								</Tooltip>
							) : null}
						</div>
						<div className={classes.helpButton}>
							<HelpIcon
								onClick={() => setRunPageTour(true)}
								className={classes.helpIcon + ' step1'}
							/>
						</div>
					</React.Fragment>
				)}
			</section>

			<section className={classes.filtersDisplaySection}>
				{
					/** sortby chip display */
					appliedSortBy[0] && (
						<div className={classes.selectedFilterChipsContainer}>
							<Typography variant="body2" component="span">{`${intl.formatMessage({
								id: 'GENERAL.SortBy',
							})}: `}</Typography>
							<Chip
								color="secondary"
								size="small"
								component="span"
								label={`${
									(sortableFields.find((item) => item.value === appliedSortBy[0].id) || {}).label
								} (${
									appliedSortBy[0].desc
										? intl.formatMessage({ id: 'GENERAL.DescShort' }).toUpperCase()
										: intl.formatMessage({ id: 'GENERAL.AscShort' }).toUpperCase()
								})`}
								onDelete={() => {
									setSortBy([]);
								}}
							/>
						</div>
					)
				}
				{FILTERS.map((filter, idx) => {
					if (filter.type === 'checkbox') {
						let selectedFiltersInSection = [...(selectedFilters[filter.searchableKey] || [])];
						return selectedFiltersInSection.length === 0 ? null : (
							<div
								key={`${filter.searchableKey}-filters-${idx}`}
								className={classes.selectedFilterChipsContainer}
							>
								<Typography variant="body2" component="span">{`${filter.title}: `}</Typography>
								{(filter.options || []).map((filterOption, filterOptIdx) => {
									return !selectedFiltersInSection.includes(filterOption.value) ? null : (
										<Chip
											key={`${filter.searchableKey}-filter-chip-${filterOptIdx}`}
											color="secondary"
											size="small"
											component="span"
											label={filterOption.label}
											onDelete={() => {
												selectedFiltersInSection.splice(
													selectedFiltersInSection.indexOf(filterOption.value),
													1
												);
												// setSelectedFilters({
												// 	...selectedFilters,
												// 	[filter.searchableKey]: selectedFiltersInSection,
												// });
												if (selectedFiltersInSection.length > 0) {
													setSelectedFilters({
														...selectedFilters,
														[filter.searchableKey]: selectedFiltersInSection,
													});
												} else {
													let currFilters = { ...selectedFilters };
													delete currFilters[filter.searchableKey];
													setSelectedFilters(currFilters);
												}
											}}
										/>
									);
								})}
							</div>
						);
					} else if (filter.type === 'select') {
						let selectedFiltersInSection = [...(selectedFilters[filter.searchableKey] || [])];

						let selectedFiltersInDropdown = [...(localSelectFilter[filter.searchableKey] || [])];

						return selectedFiltersInSection.length === 0 ? null : (
							<div
								key={`${filter.searchableKey}-filters-${idx}`}
								className={classes.selectedFilterChipsContainer}
							>
								<Typography variant="body2" component="span">{`${filter.title}: `}</Typography>
								{(filter.options || []).map((filterOption, filterOptIdx) => {
									return !selectedFiltersInSection.includes(filterOption.value) ? null : (
										<Chip
											key={`${filter.searchableKey}-filter-chip-${filterOptIdx}`}
											color="secondary"
											size="small"
											component="span"
											label={filterOption.label}
											onDelete={() => {
												selectedFiltersInSection.splice(
													selectedFiltersInSection.indexOf(filterOption.value),
													1
												);
												selectedFiltersInDropdown.splice(
													selectedFiltersInDropdown.indexOf(filterOption.value),
													1
												);
												if (selectedFiltersInDropdown.length > 0) {
													setLocalSelectFilter({
														...selectedFilters,
														[filter.searchableKey]: selectedFiltersInDropdown,
													});
												} else {
													let currFilters = { ...selectedFilters };
													delete currFilters[filter.searchableKey];
													setLocalSelectFilter(currFilters);
												}

												if (selectedFiltersInSection.length > 0) {
													setSelectedFilters({
														...selectedFilters,
														[filter.searchableKey]: selectedFiltersInSection,
													});
												} else {
													let currFilters = { ...selectedFilters };
													delete currFilters[filter.searchableKey];
													setSelectedFilters(currFilters);
												}
											}}
										/>
									);
								})}
							</div>
						);
					} else if (filter.type === 'switch') {
						return !selectedFilters[filter.searchableKey] ? null : (
							<div
								key={`${filter.searchableKey}-filters-${idx}`}
								className={classes.selectedFilterChipsContainer}
							>
								<Chip
									color="secondary"
									size="small"
									component="span"
									label={filter.title}
									onDelete={() => {
										// setSelectedFilters({
										// 	...selectedFilters,
										// 	[filter.searchableKey]: false,
										// });
										let currFilters = { ...selectedFilters };
										delete currFilters[filter.searchableKey];
										setSelectedFilters(currFilters);
									}}
								/>
							</div>
						);
					} else if (filter.type === 'datetime') {
						return selectedFilters[filter.searchableKey] ? (
							<div
								key={`${filter.searchableKey}-filters-${idx}`}
								className={classes.selectedFilterChipsContainer}
							>
								<Typography variant="body2" component="span">{`${filter.title}: `}</Typography>
								<Chip
									color="secondary"
									size="small"
									component="span"
									label={`${selectedFilters[
										filter.searchableKey
									].startDate.toLocaleDateString()} ~ ${selectedFilters[
										filter.searchableKey
									].endDate.toLocaleDateString()}`}
									onDelete={() => {
										let currFilters = { ...selectedFilters };
										delete currFilters[filter.searchableKey];
										setSelectedFilters(currFilters);
									}}
								/>
							</div>
						) : null;
					} else {
						return null;
					}
				})}
			</section>
			{/** filter menu popover */}
			<Menu
				id="filters-menu"
				// className={classes.filterMenu}
				classes={{ paper: classes.filterPaper }}
				// keepMounted
				anchorEl={filterMenuAnchor}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				// getContentAnchorEl={null}
				open={Boolean(filterMenuAnchor)}
				onClose={() => setFilterMenuAnchor(null)}
			>
				<div className={classes.filtersContainer}>
					{/** sortable properties/columns */}
					{FILTERS.map((filter, idx) => {
						let filterEnabled = getDomainSettings(FILTERS_SETTINGS_MAP[filter.searchableKey]);
						if (
							!filterEnabled &&
							filterEnabled !== undefined
							// (!filterEnabled && filterEnabled !== undefined) ||
							// filter.searchableKey === 'fileStatuses'
						) {
							return null;
						} else {
							if (filter.type === 'checkbox') {
								let selectedOptions = [...(localFilters[filter.searchableKey] || [])];
								return (
									<section key={`${filter.searchableKey}-${idx}`} className={classes.filterSection}>
										<Typography variant="subtitle1">{`${filter.title}`}</Typography>
										<div className={classes.filterMenuItemContainer}>
											{(filter.options || []).map((option, optIdx) => (
												<MenuItem
													key={`${filter.searchableKey}-option-${optIdx}`}
													className={classes.filterMenuItem}
													style={filter.width ? { width: filter.width } : {}}
													dense
													onClick={() => {
														if (selectedOptions.includes(option.value)) {
															selectedOptions.splice(selectedOptions.indexOf(option.value), 1);
														} else {
															selectedOptions.push(option.value);
														}
														// setLocalFilters({
														// 	...localFilters,
														// 	[filter.searchableKey]: selectedOptions,
														// });

														if (selectedOptions.length > 0) {
															setLocalFilters({
																...localFilters,
																[filter.searchableKey]: selectedOptions,
															});
														} else {
															let currFilters = { ...localFilters };
															delete currFilters[filter.searchableKey];
															setLocalFilters(currFilters);
														}
													}}
												>
													<Checkbox
														checked={selectedOptions.includes(option.value)}
														size="small"
														style={{ padding: 4, marginRight: 4 }}
													/>
													<ListItemText
														primary={option.label}
														primaryTypographyProps={{ variant: 'body2' }}
													/>
												</MenuItem>
											))}
										</div>
									</section>
								);
							} else if (filter.type === 'switch') {
								return (
									<section key={`${filter.searchableKey}-${idx}`} className={classes.filterSection}>
										<div className={classes.switchFilterContainer}>
											<Typography
												component="span"
												variant="subtitle1"
												className={classes.switchFilterName}
											>{`${filter.title}`}</Typography>
											<Switch
												color="primary"
												size="small"
												className={classes.filterSwitch}
												checked={Boolean(localFilters[filter.searchableKey])}
												onChange={(e) => {
													// setLocalFilters({
													// 	...localFilters,
													// 	[filter.searchableKey]: e.target.checked,
													// });

													if (!e.target.checked) {
														let currFilters = { ...localFilters };
														delete currFilters[filter.searchableKey];
														setLocalFilters(currFilters);
													} else {
														setLocalFilters({
															...localFilters,
															[filter.searchableKey]: e.target.checked,
														});
													}
												}}
											/>
										</div>
									</section>
								);
							} else if (filter.type === 'datetime') {
								return (
									<section key={`${filter.searchableKey}-${idx}`} className={classes.filterSection}>
										<Typography
											component="span"
											variant="subtitle1"
											style={{ flex: `0 0 auto` }}
										>{`${filter.title}:`}</Typography>
										<div className={classes.datetimeFilterContainer}>
											<DateRangeSelector
												className={classes.dateRangeRoot}
												customStaticRanges={filter.options}
												selectedRange={localFilters[filter.searchableKey]}
												handleRangeChange={(dateRange) => {
													if (!dateRange) {
														let currFilters = { ...localFilters };
														delete currFilters[filter.searchableKey];
														setLocalFilters(currFilters);
													} else {
														setLocalFilters({
															...localFilters,
															[filter.searchableKey]: dateRange,
														});
													}
												}}
											/>
										</div>
									</section>
								);
							} else if (filter.type === 'select') {
								let selectedChipsInDropdown = [...(localSelectFilter[filter.searchableKey] || [])];
								return (
									<section key={`${filter.searchableKey}-${idx}`} className={classes.filterSection}>
										<div className={classes.screenSelectionWrapper}>
											<Typography variant="subtitle1">{`${filter.title}:`}</Typography>
											<FormControl className={classes.formControl}>
												<Select
													// labelId="filter-label"
													id={`${filter.searchableKey}-select-${idx}`}
													multiple
													value={selectedChipsInDropdown}
													size="small"
													// classes={{ root: classes.selectRoot }}
													SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
													open={openFilterDropdown === filter.searchableKey}
													onClose={handleCloseFilterdropdown}
													onOpen={() => handleOpenFilterdropdown(filter.searchableKey)}
													onChange={(e) => handleChangeFilterdropdown(e, filter.searchableKey)}
													variant="outlined"
													renderValue={(selected) => (
														<div className={classes.chips}>
															{selected.map((value, id) => (
																<Chip
																	// key={value}
																	// label={
																	// 	_.find(filter.options, (filter) => filter.value === value).label
																	// }
																	key={id}
																	label={value}
																	clickable
																	deleteIcon={
																		<CancelIcon onMouseDown={(event) => event.stopPropagation()} />
																	}
																	className={classes.chip}
																	color="primary"
																	onDelete={(e) => handleDeleteChip(e, value, filter.searchableKey)}
																/>
															))}
														</div>
													)}
												>
													{(filter.options || []).map((filterOptions, optIdx) => (
														<MenuItem
															key={`${filter.searchableKey}-option-${optIdx}`}
															className={classes.filterMenuItem}
															dense
															value={filterOptions.value}
														>
															<Checkbox
																checked={selectedChipsInDropdown.includes(filterOptions.value)}
															/>
															<ListItemText primary={filterOptions.label} />
														</MenuItem>
													))}
													<div className={classes.filtersBtnContainer}>
														<Button
															variant="contained"
															size="small"
															color="primary"
															startIcon={<ApplyIcon />}
															onClick={() => handleCloseFilterdropdown()}
														>
															{intl.formatMessage({
																id: 'pages.MediaFiles.components.MediafileFilters.OkBtnText',
															})}
														</Button>
													</div>
												</Select>
											</FormControl>
										</div>
									</section>
								);
							} else {
								return null;
							}
						}
					})}
					{/*
					<section className={classes.filterSection}>
						<Typography variant="subtitle1">{`${intl.formatMessage({
							id: 'GENERAL.SortBy',
						})}:`}</Typography>
						<div className={classes.sortByWrapper}>
							<TextField
								className={classes.sortBySelectWrapper}
								SelectProps={{ classes: { root: classes.sortBySelect } }}
								select
								label=""
								value={(localSortBy[0] || {}).id || ''}
								onChange={(e) => {
									if (!e.target.value) setLocalSortBy([]);
									else
										setLocalSortBy([
											{ id: e.target.value, desc: localSortBy[0] ? localSortBy[0].desc : true },
										]);
								}}
								variant="outlined"
							>
								<MenuItem value={''}>
									<em>{intl.formatMessage({ id: 'GENERAL.None' })}</em>
								</MenuItem>
								{sortableFields.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
							{Boolean(localSortBy[0]) && (
								<RadioGroup
									className={classes.sortByDirection}
									row
									aria-label="sort direction"
									name="sortByDirection"
									value={localSortBy[0].desc ? 'desc' : 'asc'}
									onChange={(e) => {
										setLocalSortBy([{ id: localSortBy[0].id, desc: e.target.value === 'desc' }]);
									}}
								>
									<FormControlLabel
										value="asc"
										control={<Radio />}
										label={capitalizeString(intl.formatMessage({ id: 'GENERAL.AscLong' }))}
									/>
									<FormControlLabel
										value="desc"
										control={<Radio />}
										label={capitalizeString(intl.formatMessage({ id: 'GENERAL.DescLong' }))}
									/>
								</RadioGroup>
							)}
						</div>
					</section>
					*/}
				</div>
				<div className={classes.filtersBtnContainer}>
					<Button
						variant="contained"
						size="small"
						color="primary"
						startIcon={<ApplyIcon />}
						onClick={handleApplyFilters}
					>
						{intl.formatMessage({
							id: 'pages.MediaFiles.components.MediafileFilters.ApplyBtnText',
						})}
					</Button>
					<Button
						variant="contained"
						size="small"
						color="secondary"
						startIcon={<ResetIcon />}
						onClick={handleResetFilter}
					>
						{intl.formatMessage({ id: 'GENERAL.Reset' })}
					</Button>
					<Button
						variant="contained"
						size="small"
						startIcon={<CloseIcon />}
						onClick={handleCloseFilterMenu}
					>
						{intl.formatMessage({ id: 'GENERAL.Close' })}
					</Button>
				</div>
			</Menu>
			<PageTour
				run={runPageTour}
				runIfNotDone={true}
				steps={pageTourSteps}
				disableScrollParentFix={true}
				tourId="MediaFilterExplore"
				onClose={() => setRunPageTour(false)}
			/>
		</div>
	);
}

MediafileFilters.propTypes = {
	/**
	 * search keywords from user
	 */
	searchKeywords: PropTypes.string,
	/**
	 * handler of changing of search keywords
	 * @param {string}
	 */
	setSearchKeywords: PropTypes.func.isRequired,
	/**
	 * current view mode
	 */
	viewMode: PropTypes.oneOf([GRID_VIEW, TABLE_VIEW]).isRequired,
	/**
	 * Handle switching between grid view & table view
	 */
	handleViewSwitch: PropTypes.func.isRequired,
	/**
	 * All filters selected by user
	 * format:
	 	{
			 [searchableKey]: [], // if type is checkbox
			 [searchableKey]: true/false, // if type is switch
			 [searchableKey]: { // if type is datetime. NB: the searchableKey is missing "From" and "To" which are corresponding to startDate & endData in the value object
				  startDate: Date(),
					endDate: Date(),
			 }
		 }
	 */
	selectedFilters: PropTypes.object.isRequired,
	/**
	 * handler of setting user selected filters
	 * @param {object} filters. The updated filters
	 */
	setSelectedFilters: PropTypes.func.isRequired,
	/**
	 * Array of table columns configure (used for sortable columns)
	 */
	columnsConfigure: PropTypes.array.isRequired,
	/**
	 * sortBy array
	 * NB: it is used as initial state in rTable for sorting
	 */
	appliedSortBy: PropTypes.array.isRequired,
	/**
	 * set sortBy for searching mediafiles
	 * @param {array} sortBy_in_rTableInst. format: [{id: 'xxx', desc: true/false}]. it is same format as tableInst.state.sortBy.
	 */
	setSortBy: PropTypes.func.isRequired,
	domainName: PropTypes.string,
	domainSettings: PropTypes.object.isRequired,
	userLevel: PropTypes.number.isRequired,
	// getCatagoryTags: PropTypes.func.isRequired,
	// categoryTags: PropTypes.object.isRequired,
};
MediafileFilters.defaultProps = { searchKeywords: '' };
const pageTourSteps = [
	{
		target: '.step1',
		content: (
			<div>
				<h3>
					<FormattedMessage
						id="pages.MediaFiles.components.MediafileFilters.PageTourStep1.h3"
						values={{
							br: <br />,
						}}
					/>
				</h3>
				<p>
					<FormattedMessage
						id="pages.MediaFiles.components.MediafileFilters.PageTourStep1.p"
						values={{
							br: <br />,
						}}
					/>
				</p>
			</div>
		),
		placement: 'bottom',
		disableBeacon: true,
		disableOverlayClose: true,
	},
	{
		target: '.step2',
		content: (
			<div>
				<h3>
					<FormattedMessage
						id="pages.MediaFiles.components.MediafileFilters.PageTourStep2.h3"
						values={{
							br: <br />,
						}}
					/>
				</h3>
				<p>
					<FormattedMessage
						id="pages.MediaFiles.components.MediafileFilters.PageTourStep2.p"
						values={{
							br: <br />,
						}}
					/>
				</p>
			</div>
		),
		placement: 'bottom',
		disableBeacon: true,
		disableOverlayClose: true,
	},
	{
		target: '.step3',
		content: (
			<div>
				<h3>
					<FormattedMessage
						id="pages.MediaFiles.components.MediafileFilters.PageTourStep3.h3"
						values={{
							br: <br />,
						}}
					/>
				</h3>
				<p>
					<FormattedMessage
						id="pages.MediaFiles.components.MediafileFilters.PageTourStep3.p"
						values={{
							br: <br />,
						}}
					/>
				</p>
			</div>
		),
		placement: 'bottom',
		disableBeacon: true,
		disableOverlayClose: true,
	},
	{
		target: '.step4',
		content: (
			<div>
				<h3>
					<FormattedMessage
						id="pages.MediaFiles.components.MediafileFilters.PageTourStep4.h3"
						values={{
							br: <br />,
						}}
					/>
				</h3>
				<p>
					<FormattedMessage
						id="pages.MediaFiles.components.MediafileFilters.PageTourStep4.p"
						values={{
							br: <br />,
						}}
					/>
				</p>
			</div>
		),
		placement: 'bottom',
		disableBeacon: true,
		disableOverlayClose: true,
	},
];
export default MediafileFilters;
