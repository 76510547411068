import React, { Component } from 'react';
// import Image from 'components/Image/Image';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
// import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';

// import {
// 	Autorenew
// } from "@mui/icons-material";

// redux
import { connect } from 'react-redux';
import {} from 'redux/actions'; // actions

const styles = (theme) => ({
	button: {
		margin: theme.spacing(0),
		marginTop: theme.spacing(1),
	},
	root: {
		height: '100%',
		// textAlign: 'center',
		// verticalAlign: 'middle',
		// wordWrap: 'break-word',
		margin: 'auto',
		display: 'block',
		textAlign: 'center',
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
		height: '18px',
	},
	imageStyle: {
		maxWidth: '100%',
		// 'maxHeight': '60%',
		maxHeight: '90px',
	},
	popoverImage: {
		// maxWidth: '100%',
		// maxHeight: '100%',
		// maxWidth: 400,
		// maxHeight: 400,
	},
	popover: {
		// minWidth: 20,
		// minHeight: 20,
		// maxWidth: 400,
		// maxHeight: 400,
		width: 500,
		height: 500,
		// width: 400+theme.spacing.unit*4,
		// maxHeight: 220,
	},
	popoverPaper: {
		width: '100%',
		height: '100%',
		display: 'block',
		// backgroundColor: 'black',
		// minWidth: 20,
		// minHeight: 20,
		// maxWidth: 400,
		// maxHeight: 400,
		// padding: theme.spacing.unit,
		// width: 400+theme.spacing.unit*2,
		// maxHeight: 200,

		// maxWidth: '100%',
		// maxHeight: '100%',

		'& img': {
			width: 'unset',
			maxHeight: '100%',
			maxWidth: '100%',
			// margin: 'auto',
			position: 'relative',
			verticalAlign: 'middle',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
	},
});

class Image extends Component {
	constructor(props) {
		super(props);
		this.state = {
			popoverAnchorEl: null,
		};
	}

	// generatePreview(fileUrl, cb) {
	// 	let previewAPIGen = `${_CONFIG.previewAPIBaseUrl}/preview/?processnow=true&redirect=false&fileicon=false&fileurl=${fileUrl}&size=small`;
	// 	let genPreviewReqHeader = { 'Content-Type': 'application/json' };
	// 	genPreviewReqHeader[_CONFIG.previewAPIKey] = _CONFIG.preivewAPISecret;
	// 	let genAxiosConfig = {
	// 		url: previewAPIGen,
	// 		method: 'get',
	// 		headers: genPreviewReqHeader,
	// 		timeout: 300000,
	// 		maxRedirects: 0
	// 	};
	// 	// let a = this.props.generalRequestResult;
	// 	return axiosRequest(genAxiosConfig).then(()=>(typeof cb === 'function')?cb():null).catch(err=>(typeof cb === 'function')?cb(err):null);
	// 	// return axiosWrapper.request(genAxiosConfig).then(()=>(typeof cb === 'function')?cb():null);
	// 	// return this.props.sendRequest(genAxiosConfig, cb);
	// }

	// deletePreview(fileUrl, cb) {
	// 	let previewAPIGen = `${_CONFIG.previewAPIBaseUrl}/preview/?fileurl=${fileUrl}`;
	// 	let delPreviewReqHeader = { 'Content-Type': 'application/json' };
	// 	delPreviewReqHeader[_CONFIG.previewAPIKey] = _CONFIG.preivewAPISecret;
	// 	let delAxiosConfig = {
	// 		url: previewAPIGen,
	// 		method: 'delete',
	// 		headers: delPreviewReqHeader,
	// 		timeout: 30000
	// 	};
	// 	// return this.props.sendRequest(delAxiosConfig, cb);
	// 	return axiosRequest(delAxiosConfig).then(()=>(typeof cb === 'function')?cb():null);
	// 	// return axiosWrapper.request(delAxiosConfig).then(()=>(typeof cb === 'function')?cb():null);
	// }

	// onReGenerate(originalUrl) {
	// 	let {classes, notifyGeneral} = this.props;
	// 	this.deletePreview(originalUrl, () => {
	// 		this.generatePreview(originalUrl, (err)=>{
	// 			if(err){
	// 				return notifyGeneral(`Can not re-generate preview!!!`, 'error');
	// 			}
	// 			let previewUrlSmall = `${_CONFIG.previewAPIBaseUrl}/preview/?fileicon=false&fileurl=${originalUrl}&size=small&redirect=true&${_CONFIG.previewAPIKey}=${_CONFIG.preivewAPISecret}`;
	// 			let dialogContent = <div>
	// 			<Typography variant="subtitle2" gutterBottom>Re-generated Preview</Typography>
	// 			<div className={classes.popoverPaper} style={{height: 200, width: 300}}>
	// 			<img src={previewUrlSmall}
	// 			/>
	// 			</div></div>;
	// 			notifyGeneral(dialogContent, 'white');
	// 		});
	// 	});
	// }

	onImageClick = (event) => {
		if (this.props.popoverLargePreview) {
			this.setState({ popoverAnchorEl: event.currentTarget });
		}
	};

	onPopoverClose = () => {
		if (this.props.popoverLargePreview) {
			this.setState({ popoverAnchorEl: null });
		}
	};

	render() {
		// let errStyle = {
		// 	color: 'red'
		// };
		// let aTagStyle = {
		// 	/* font-size: large; */
		// 	color: 'blue'
		// };

		const { classes, styles, srcHttpUrl } = this.props;

		return (
			<span className={classes.root} style={styles.rootStyle}>
				<img
					src={srcHttpUrl}
					style={styles.imageStyle}
					className={classes.imageStyle}
					onClick={this.onImageClick}
					alt=""
				/>
				<Popover
					// ref={this.popOverRef}
					className={classes.popover}
					classes={{
						paper: classes.popoverPaper,
					}}
					onClose={this.onPopoverClose}
					open={!!this.state.popoverAnchorEl}
					anchorEl={this.state.popoverAnchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
					disableRestoreFocus
				>
					<img src={srcHttpUrl} className={classes.popoverImage} alt="" />
				</Popover>
			</span>
		);
	}
}

Image.propTypes = {
	classes: PropTypes.object.isRequired,
	// notifyGeneral: PropTypes.func.isRequired,
	// showFilePath: PropTypes.bool,
	// largePreview: PropTypes.bool,
	// regenButton: PropTypes.bool,
	popoverLargePreview: PropTypes.bool,
	styles: PropTypes.object,
	srcHttpUrl: PropTypes.string,
};

Image.defaultProps = {
	// showFilePath: false, // if show image url
	// largePreview: false, // if show button to see the large preview
	// regenButton: false, // if show button to regenerate preview of this image
	popoverLargePreview: false, // if enable popover preview
	styles: {}, // custom styles
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
	return {
		// generalRequestResult: state.generalRequestResult
	};
};

export default connect(mapStateToProps, {})(withStyles(styles)(Image));
