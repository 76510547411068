/**
 * We use tinymce for end user to input "grid" data
 */
import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useResizeDetector } from 'react-resize-detector';

import { Settings as ConfigIcon } from '@mui/icons-material';
// import { Fade } from '@mui/material';

// import { StyledContainedButton } from '../../CustomMUI/CustomMUI';

import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Theme. A theme is also required
import 'tinymce/themes/silver';
// Toolbar icons. Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js. Any plugins you want to use has to be imported
// import 'tinymce/plugins/advlist';
// import 'tinymce/plugins/autolink';
// import 'tinymce/plugins/link';
// import 'tinymce/plugins/image';
// import 'tinymce/plugins/lists';
// import 'tinymce/plugins/charmap';
// import 'tinymce/plugins/hr';
// import 'tinymce/plugins/anchor';
// import 'tinymce/plugins/spellchecker';
// import 'tinymce/plugins/searchreplace';
// import 'tinymce/plugins/wordcount';
// import 'tinymce/plugins/code';
// import 'tinymce/plugins/fullscreen';
// import 'tinymce/plugins/insertdatetime';
// import 'tinymce/plugins/media';
// import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/template';
// import 'tinymce/plugins/help';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
// import contentCss from 'tinymce/skins/content/default/content.min.css';
// import contentUiCss from 'tinymce/skins/ui/oxide/content.min.css';

// intl lang
// import { useIntl } from 'react-intl';

const controlSpacer = 16; // space (size) of control area
// const minTableWidth = 250;
// const sampleTableText = `
// <table style="border-collapse: collapse; width: 100%; min-width: ${minTableWidth}px;" border="1">
// 	<thead>
// 		<tr>
// 			<th>Product</th>
// 			<th>Small</th>
// 			<th>Large</th>
// 		</tr>
// 	</thead>
// 	<tbody>
// 		<tr>
// 			<td>Cappuccino</td>
// 			<td>€1.99</td>
// 			<td>€2.99</td>
// 		</tr>
// 		<tr>
// 			<td>Mocha</td>
// 			<td>€2.49</td>
// 			<td>€3.99</td>
// 		</tr>
// 	</tbody>
// </table>`;

/**
 * GridInputTable Component
 */
const useStyles = makeStyles((theme) => ({
	/**
	table configuration view layout
	  ____________________________________
	 |_1.1__|__1.2__|______1.3____________|
	 | 2.1  |  3.1  |      3.2            |
	 |______|_______|_____________________|
	 |      |       |                     |
	 | 2.2  |  3.3  |      3.4            |
	 |______|_______|_____________________|

	 Area 1: column controls & whole table control
		1.1: WHOLE_TABLE control
		1.2: TITLE_COL control
		1.3: CONTENT_COL control
	 Area 2: row controls
		2.1: HEADER_ROW control
		2.2: CONTENT_ROW control
	 Area 3: table decoration & sample table
	 	3.1 header cell of title (e.g. Product)
		3.2 header cells of content (e.g. Price)
		3.3 title cells (text, e.g. mocha)
		3.4 content cells (value text, e.g. €2.99)
	 */
	GridConfigViewWrapper: {
		// width: '100%',
		// maxWidth: tableViewMaxWidth,
		// minWidth: minTableWidth + controlSpacer,
		display: 'flex',
		flexDirection: 'column',
	},
	tableViewColControlWrapper: {
		// area 1 (column controls & whole table control)
		// width: '100%',
		flex: '0 1 auto',
		height: controlSpacer,
		display: 'flex',
	},
	tableViewTableWrapper: {
		// area 2 & 3
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'row',
	},
	tableViewRowControlWrapper: {
		// area 2 (row controls)
		width: controlSpacer,
		display: 'flex',
		flexDirection: 'column',
		flex: '0 0 auto',
	},
	tableViewSampleTableWrapper: {
		// area 3 (table decoration)
		flex: '1 1 auto',
		position: 'relative',
		// '& tr:first-child td>p:nth-child(n+2)': {
		// 	display: 'none', // only allow one line in table header cell
		// },
		'& td>p:not(:first-child)': {
			fontSize: '0.7rem', // non-first line in table cell will have smaller font
		},
		'& td>p:nth-child(n+3)': {
			display: 'none', // only allow two lines in table content cell
		},
		'& th>p:nth-child(n+2)': {
			display: 'none', // only allow one line in table header cell
		},
		// '&:before': {
		// 	content: '""',
		// },
	},
	// disableTinymceTableResizing: {
	// 	'& .mce-resizehandle': {
	// 		visibility: 'hidden',
	// 	},
	// },
	tableViewTableControl: {
		// area 1.1
		width: controlSpacer,
		border: `1px solid rgb(193, 199, 208)`,
		borderTopLeftRadius: 4,
		// borderRight: 'unset',
		// borderBottom: 'unset',
	},
	tableViewHeaderColControl: {
		// area 1.2
		width: ({ headerColWidth }) => headerColWidth + 1, // plus 1px table border
		border: `1px solid rgb(193, 199, 208)`,
		// borderRight: 'unset',
	},
	tableViewContentColControl: {
		// area 1.3
		// width: ({ headerColWidth }) => `calc(100% - ${controlSpacer + headerColWidth}px)`,
		flex: '1 1 auto',
		border: `1px solid rgb(193, 199, 208)`,
		// borderLeft: 'unset',
	},
	tableViewHeaderRowControl: {
		// area 2.1
		width: '100%',
		height: ({ headerRowHeight }) => headerRowHeight + 1, // plus 1px table border
		border: `1px solid rgb(193, 199, 208)`,
		// borderBottom: 'unset',
	},
	tableViewContentRowControl: {
		// area 2.2
		// width: '100%',
		flex: '1 1 auto',
		border: `1px solid rgb(193, 199, 208)`,
		// borderTop: 'unset',
		borderBottomLeftRadius: 4,
	},
	tableViewDecoration31: {
		// area 3.1
		width: ({ headerColWidth }) => headerColWidth + 1, // plus 1px table border
		height: ({ headerRowHeight }) => headerRowHeight + 1, // plus 1px table border
		position: 'absolute',
		top: 0,
		left: 0,
	},
	tableViewDecoration32: {
		// area 3.2
		height: ({ headerRowHeight }) => headerRowHeight + 1, // plus 1px table border
		position: 'absolute',
		top: 0,
		left: ({ headerColWidth }) => headerColWidth,
		right: 0,
	},
	tableViewDecoration33: {
		// area 3.3
		width: ({ headerColWidth }) => headerColWidth + 1, // plus 1px table border
		position: 'absolute',
		top: ({ headerRowHeight }) => headerRowHeight,
		left: 0,
		bottom: 0,
	},
	tableViewDecoration34: {
		// area 3.4
		position: 'absolute',
		top: ({ headerRowHeight }) => headerRowHeight,
		left: ({ headerColWidth }) => headerColWidth,
		bottom: 0,
		right: 0,
	},

	tableControlGeneral: {
		'&:hover': {
			backgroundColor: `rgba(195,214,246, 1)`,
		},
		backgroundColor: `rgba(195,214,246, 0.5)`,
		position: 'relative',
		cursor: 'pointer',
	},
	tableDecorationColor: {
		backgroundColor: `rgba(181,204,244, 1)`, // theme.palette.secondary.light,
	},
	configIcon: {
		fontSize: '0.75rem',
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
	},
	configIndicator: {
		backgroundColor: 'rgb(0,0,0,0.3)',
		position: 'absolute',
		height: 4,
		width: 4,
		borderRadius: '50%',
		pointerEvents: 'none',
		// bottom: '-1px',
		// left: '-11px',
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
	},
}));

const GridInputTable = ({
	handleEditorOnBlur,
	initialTableHtmlText,
	disableEditing,
	disableControls,
	// disableTableResizing,
	handleClickOnControlArea,
	...rest
}) => {
	// const intl = useIntl();
	const tinymceEditorRef = React.useRef(null);
	const tableWrapperRef = React.useRef(null);
	// the handleEditorOnBlur func used in tinymce Editor is not renewed after the Editor is initialized.
	// hence we use the ref of the handler func
	const handleEditorOnBlurRef = React.useRef(handleEditorOnBlur);
	const [tableHeaderSize, setTableHeaderSize] = React.useState({
		headerRowHeight: 31,
		headerColWidth: 124,
	});
	const classes = useStyles(tableHeaderSize);
	React.useEffect(() => {
		handleEditorOnBlurRef.current = handleEditorOnBlur;
	}, [handleEditorOnBlur]);

	const onTableWrapperResize = React.useCallback(() => {
		if (tableWrapperRef.current) {
			let sampleTableEle = tableWrapperRef.current.getElementsByTagName('table').item(0);
			if (sampleTableEle) {
				let firstCell = sampleTableEle.rows[0].cells[0];
				setTableHeaderSize({
					headerRowHeight: firstCell.clientHeight,
					headerColWidth: firstCell.clientWidth,
				});
			}
		}
	}, []);
	useResizeDetector({
		targetRef: tableWrapperRef,
		refreshMode: 'debounce',
		refreshRate: 300,
		onResize: onTableWrapperResize,
	});

	// control area. Enum: ['WHOLE_TABLE', 'TITLE_COL', 'CONTENT_COL', 'HEADER_ROW', 'CONTENT_ROW']
	const [tableControlArea, setTableControlArea] = React.useState(null);

	// // React.useEffect(() => {
	// // 	// if (tinymceEditorRef.current) {
	// // 	// 	let table = tinymceEditorRef.current.plugins.table.insertTable(3, 3, {
	// // 	// 		headerRows: 1,
	// // 	// 		headerColumns: 3,
	// // 	// 	});
	// // 	// }
	// // }, []);
	return (
		<div className={classes.GridConfigViewWrapper}>
			{!disableControls && (
				<div
					className={classes.tableViewColControlWrapper}
					onClick={() => handleClickOnControlArea(tableControlArea)}
				>
					{/** Area 1: Whole Table control & column controls */}
					{/* area 1.1 */}
					<div
						className={cx(classes.tableViewTableControl, classes.tableControlGeneral)}
						onMouseEnter={() => setTableControlArea('WHOLE_TABLE')}
						onMouseLeave={() => setTableControlArea(null)}
					>
						{tableControlArea === 'WHOLE_TABLE' ? (
							<ConfigIcon className={classes.configIcon} />
						) : (
							<div className={classes.configIndicator}></div>
						)}
					</div>
					{/* area 1.2 */}
					<div
						className={cx(classes.tableViewHeaderColControl, classes.tableControlGeneral)}
						onMouseEnter={() => setTableControlArea('TITLE_COL')}
						onMouseLeave={() => setTableControlArea(null)}
					>
						{tableControlArea === 'TITLE_COL' ? (
							<ConfigIcon className={classes.configIcon} />
						) : (
							<div className={classes.configIndicator}></div>
						)}
					</div>
					{/* area 1.3 */}
					<div
						className={cx(classes.tableViewContentColControl, classes.tableControlGeneral)}
						onMouseEnter={() => setTableControlArea('CONTENT_COL')}
						onMouseLeave={() => setTableControlArea(null)}
					>
						{tableControlArea === 'CONTENT_COL' ? (
							<ConfigIcon className={classes.configIcon} />
						) : (
							<div className={classes.configIndicator}></div>
						)}
					</div>
				</div>
			)}
			<div className={classes.tableViewTableWrapper}>
				{/** Area 2: Row controls */}
				{!disableControls && (
					<div
						className={classes.tableViewRowControlWrapper}
						onClick={() => handleClickOnControlArea(tableControlArea)}
					>
						{/* area 2.1 */}
						<div
							className={cx(classes.tableViewHeaderRowControl, classes.tableControlGeneral)}
							onMouseEnter={() => setTableControlArea('HEADER_ROW')}
							onMouseLeave={() => setTableControlArea(null)}
						>
							{tableControlArea === 'HEADER_ROW' ? (
								<ConfigIcon className={classes.configIcon} />
							) : (
								<div className={classes.configIndicator}></div>
							)}
						</div>
						{/* area 2.2 */}
						<div
							className={cx(classes.tableViewContentRowControl, classes.tableControlGeneral)}
							onMouseEnter={() => setTableControlArea('CONTENT_ROW')}
							onMouseLeave={() => setTableControlArea(null)}
						>
							{tableControlArea === 'CONTENT_ROW' ? (
								<ConfigIcon className={classes.configIcon} />
							) : (
								<div className={classes.configIndicator}></div>
							)}
						</div>
					</div>
				)}
				<div
					className={cx(classes.tableViewSampleTableWrapper, {
						// [classes.disableTinymceTableResizing]: disableTableResizing,
					})}
					ref={tableWrapperRef}
				>
					{/** Area 3: sample table */}
					<div
						className={cx(classes.tableViewDecoration31, {
							[classes.tableDecorationColor]: ['WHOLE_TABLE', 'HEADER_ROW', 'TITLE_COL'].includes(
								tableControlArea
							),
						})}
					></div>
					<div
						className={cx(classes.tableViewDecoration32, {
							[classes.tableDecorationColor]: ['WHOLE_TABLE', 'HEADER_ROW', 'CONTENT_COL'].includes(
								tableControlArea
							),
						})}
					></div>
					<div
						className={cx(classes.tableViewDecoration33, {
							[classes.tableDecorationColor]: ['WHOLE_TABLE', 'CONTENT_ROW', 'TITLE_COL'].includes(
								tableControlArea
							),
						})}
					></div>
					<div
						className={cx(classes.tableViewDecoration34, {
							[classes.tableDecorationColor]: [
								'WHOLE_TABLE',
								'CONTENT_ROW',
								'CONTENT_COL',
							].includes(tableControlArea),
						})}
					></div>
					{/** tinymce table here */}
					<Editor
						// id="tinyMCESelectorId"
						tagName="section"
						inline={true}
						init={{
							skin: false,
							content_css: false,
							content_style: [contentCss, contentUiCss]
								.join('\n')
								.replaceAll(/body\s*\{.*?\}/gim, ''), // remove any body styles from contentCss for "inline" mode
							// body_class: classes.tinymceRoot,
							// selector: 'div#basic-example',
							// height: '100%',
							// forced_root_block: 'p',
							forced_root_block_attrs: {
								style: 'margin: 0px', //4px 0px', // force new lines (by Enter) has less margin
							},
							menubar: false,

							contextmenu:
								'TableToggleHeaderRowMenu | tablerowheader | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolumnbefore tableinsertcolumnafter tabledeletecolumn',
							// table_toolbar:
							// 	'TableToggleHeaderRow | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
							table_toolbar: '',
							table_header_type: 'sectionCells',
							table_grid: false,
							table_appearance_options: false,
							object_resizing: false,
							resize: false,
							table_resize_bars: false,
							table_sizing_mode: 'relative',
							custom_undo_redo_levels: 50,
							// contextmenu: false,
							branding: false,
							statusbar: false,
							// readonly: true,
							// noneditable_noneditable_class: 'mceNonEditable',
							// table_default_attributes: {
							//   border: '5'
							// },
							plugins: [
								// 'table noneditable'
								'table',
							],
							toolbar: '',
							setup: function (editor) {
								// editor.on('TableModified', function (table, structureChanged, styleChanged) {
								// 	console.log('table was modified');
								// });
								// editor.on('keydown', (e) => {
								// 	// e.stopPropagation();
								// 	// e.preventDefault();
								// 	console.log('keydown happened');
								// });
								// editor.ui.registry.addButton('TableToggleHeaderRow', {
								// 	tooltip: 'Toggle header row',
								// 	icon: 'table-top-header',
								// 	onAction: function () {
								// 		const rowType = editor.queryCommandValue('mceTableRowType');
								// 		const editorContainer = editor.getContentAreaContainer();
								// 		const tableElem = editorContainer.getElementsByTagName('table').item(0);
								// 		const hasHeaderRow =
								// 			tableElem.tHead && tableElem.tHead.rows.length > 0 ? true : false;
								// 		if (rowType !== 'header' && hasHeaderRow) {
								// 			// there is already a header row
								// 			return null;
								// 		}
								// 		editor.execCommand('mceTableRowType', false, {
								// 			type: rowType === 'header' ? 'body' : 'header',
								// 		});
								// 	},
								// });

								editor.ui.registry.addMenuItem('TableToggleHeaderRowMenu', {
									text: 'Toggle header row',
									icon: 'table-top-header',
									onAction: function () {
										const rowType = editor.queryCommandValue('mceTableRowType');
										const editorContainer = editor.getContentAreaContainer();
										const tableElem = editorContainer.getElementsByTagName('table').item(0);
										const hasHeaderRow = tableElem?.tHead?.rows?.length > 0 ? true : false;
										if (rowType !== 'header' && hasHeaderRow) {
											// there is already a header row
											return null;
										}
										editor.execCommand('mceTableRowType', false, {
											type: rowType === 'header' ? 'body' : 'header',
										});
									},
								});

								editor.on('blur', (e) => {
									// console.log(`editor on blur event`);
									// console.log(e.target.getContent({ format: 'html' }));
									// e.target is the editor instance
									// const editorContainer = e.target.getContentAreaContainer();
									// const tableElem = editorContainer.getElementsByTagName('table').item(0);
									const editorHtml = e.target.getContent({ format: 'html' });
									// const editorTextContent = e.target.getContent({ format: 'text' });
									// console.log(editorContainer);
									// console.log(tableElem);
									// console.log(editorHtml);
									// console.log('==============');
									// console.log(editorTextContent);
									// console.log('editor on blur - end');
									if (typeof handleEditorOnBlurRef.current === 'function')
										handleEditorOnBlurRef.current(/* tableElem, */ editorHtml);
								});
								editor.on('mouseleave', () => {
									// Problem: in inline mode, the resizer-bar in table is clickable even when it is not in view
									// following is a hack to remove resizer-bar in table.
									Array.prototype.forEach.call(
										document.getElementsByClassName('ephox-snooker-resizer-bar'),
										(resizerBar) => {
											resizerBar.style.height = 0;
										}
									);
								});
							},
						}}
						onInit={(e, editor) => {
							tinymceEditorRef.current = editor;
							editor.bodyElement.style.position = 'relative';
							// editor.bodyElement.style.width = '100%';
							// editor.bodyElement.style.height = '100%';
							// editor.bodyElement.style.padding = 'unset';
							// editor.bodyElement.style.margin = 'unset';
							// editor.plugins.table.insertTable(3, 3, { headerRows: 1, headerColumns: 1 });
						}}
						// onEditorChange={() => {
						// 	console.log(`table changed`);
						// }}
						disabled={disableEditing}
						initialValue={initialTableHtmlText}
					/>
				</div>
			</div>
		</div>
	);
};

GridInputTable.propTypes = {
	/**
	 * func to handle editor onBlur event
	 * @param {string} editorHtml. html content string in editor
	 */
	handleEditorOnBlur: PropTypes.func.isRequired,
	/**
	 * Initial <table> html string. Used as initialValue in tinymce
	 */
	initialTableHtmlText: PropTypes.string.isRequired,
	/**
	 * if true, table is not editable,
	 */
	disableEditing: PropTypes.bool,
	/**
	 * if true, no controls (rows & columns) area
	 */
	disableControls: PropTypes.bool,
	// /**
	//  * if true, disable resize on table
	//  * NB: row & column are still resizable
	//  */
	// disableTableResizing: PropTypes.bool,
	/**
	 * handle click for WHOLE_TABLE control
	 * @param {string} area.  Enum: ['WHOLE_TABLE', 'TITLE_COL', 'CONTENT_COL', 'HEADER_ROW', 'CONTENT_ROW']
	 */
	handleClickOnControlArea: PropTypes.func,
};

GridInputTable.defaultProps = {
	disableEditing: false,
	disableControls: false,
	// disableTableResizing: false,
	handleClickOnControlArea: () => null,
};

export default GridInputTable;
