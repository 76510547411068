// ##############################
// // // Login styles
// #############################
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme) => ({
	wrapper: {
		minHeight: '100vh',
		width: '100vw',
		height: '100vh',
		display: 'inherit',
		position: 'relative',
		margin: '0',
		padding: '0',
		border: '0',
		alignItems: 'center',
		top: '0',
		backgroundImage: ({ BGImage }) => `url(${BGImage})`,
		backgroundSize: 'cover',
		backgroundPosition: 'top center',
	},
	container: {
		paddingRight: '15px',
		paddingLeft: '15px',
		marginRight: 'auto',
		marginLeft: 'auto',
		width: '100%',
		zIndex: '2',
		position: 'relative',
		paddingTop: '10vh',
		color: '#888',
		opacity: 0.9,
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			maxWidth: '1170px', //`calc(100% - ${theme.drawerMiniWidth}px)`,
		},
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			maxWidth: '550px', //`calc(100% - ${theme.drawerMiniWidth}px)`,
		},
		// '@media (min-width: 576px)': {
		// 	maxWidth: '550px'
		// },
		// '@media (min-width: 768px)': {
		// 	maxWidth: '750px'
		// },
		// '@media (min-width: 992px)': {
		// 	maxWidth: '970px'
		// },
		// '@media (min-width: 1200px)': {
		// 	maxWidth: '1170px'
		// },
	},
	formWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	formHidden: {
		display: 'none',
	},
	'@keyframes slideInEllipticTopFwd': theme.animations['@keyframes slideInEllipticTopFwd'],
	animSlideInEllipticTopFwd: {
		animation: '$slideInEllipticTopFwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
	},
}));

export default styles;
