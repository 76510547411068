/*
 *
 * Artwork Helper
 *
 */
import {
	FETCH_ARTWORK_FONTS,
	FETCH_ARTWORK_FONTS_SUCCESS,
	FETCH_ARTWORK_FONTS_FAIL,
	RESET_ARTWORK_FONTS,
	FETCH_ARTWORK_SPREADSHEETS,
	FETCH_ARTWORK_SPREADSHEETS_SUCCESS,
	FETCH_ARTWORK_SPREADSHEETS_FAIL,
	RESET_ARTWORK_SPREADSHEETS,
	FETCH_ARTWORK_LISTS,
	FETCH_ARTWORK_LISTS_SUCCESS,
	FETCH_ARTWORK_LISTS_FAIL,
	RESET_ARTWORK_LISTS,
	FETCH_ARTWORK_AUTOIMPORT,
	FETCH_ARTWORK_AUTOIMPORT_SUCCESS,
	FETCH_ARTWORK_AUTOIMPORT_FAIL,
	RESET_ARTWORK_AUTOIMPORT,
	FETCH_ARTWORK_OUTPUTTEMPLATES,
	FETCH_ARTWORK_OUTPUTTEMPLATES_SUCCESS,
	FETCH_ARTWORK_OUTPUTTEMPLATES_FAIL,
	RESET_ARTWORK_OUTPUTTEMPLATES,
	FETCH_ARTWORK_CATEGORIES,
	FETCH_ARTWORK_CATEGORIES_SUCCESS,
	FETCH_ARTWORK_CATEGORIES_FAIL,
	RESET_ARTWORK_CATEGORIES,
} from 'redux/actions';
const initialState = {
	fetchingFontsStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	fonts: [],
	totalFoundFonts: 0,

	fetchingSpreadsheetsStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	spreadsheets: [],
	totalFoundSpreadsheets: 0,

	fetchingListsStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	lists: [],
	totalFoundLists: 0,

	fetchingAutoImportStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	autoImport: {},
	originalAutoImportData: {},

	fetchingOutTemplatesStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	outputTemplates: [],
	totalFoundOutputTemplates: 0,

	fetchingCategoriesStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
	categories: [],
	// totalFoundCategories: 0,
};

function artworkExtra(state = initialState, action) {
	switch (action.type) {
		case FETCH_ARTWORK_FONTS:
			return {
				...state,
				fetchingFontsStatus: 'PROCESSING',
			};
		case FETCH_ARTWORK_FONTS_SUCCESS:
			return {
				...state,
				fetchingFontsStatus: 'OK',
				totalFoundFonts: action.result.data.totalFound,
				fonts: action.result.data.results,
			};
		case FETCH_ARTWORK_FONTS_FAIL:
			return {
				...state,
				fetchingFontsStatus: 'FAILED',
				fonts: [],
				totalFoundFonts: 0,
			};
		case RESET_ARTWORK_FONTS:
			return {
				...state,
				fetchingFontsStatus: '',
				fonts: [],
				totalFoundFonts: 0,
			};

		case FETCH_ARTWORK_SPREADSHEETS:
			return {
				...state,
				fetchingSpreadsheetsStatus: 'PROCESSING',
			};
		case FETCH_ARTWORK_SPREADSHEETS_SUCCESS:
			return {
				...state,
				fetchingSpreadsheetsStatus: 'OK',
				spreadsheets: action.result.data.results,
				totalFoundSpreadsheets: action.result.data.totalFound,
			};
		case FETCH_ARTWORK_SPREADSHEETS_FAIL:
			return {
				...state,
				fetchingSpreadsheetsStatus: 'FAILED',
				spreadsheets: [],
				totalFoundSpreadsheets: 0,
			};
		case RESET_ARTWORK_SPREADSHEETS:
			return {
				...state,
				fetchingSpreadsheetsStatus: '',
				spreadsheets: [],
				totalFoundSpreadsheets: 0,
			};
		case FETCH_ARTWORK_LISTS:
			return {
				...state,
				fetchingListsStatus: 'PROCESSING',
			};
		case FETCH_ARTWORK_LISTS_SUCCESS:
			return {
				...state,
				fetchingListsStatus: 'OK',
				lists: action.result.data.results,
				totalFoundLists: action.result.data.totalFound,
			};
		case FETCH_ARTWORK_LISTS_FAIL:
			return {
				...state,
				fetchingListsStatus: 'FAILED',
				lists: [],
				totalFoundLists: 0,
			};
		case RESET_ARTWORK_LISTS:
			return {
				...state,
				fetchingListsStatus: '',
				lists: [],
				totalFoundLists: 0,
			};
		case FETCH_ARTWORK_AUTOIMPORT:
			return {
				...state,
				fetchingAutoImportStatus: 'PROCESSING',
			};
		case FETCH_ARTWORK_AUTOIMPORT_SUCCESS:
			var originalAutoImportData = action.result.data;
			var autoImportData = {};
			autoImportData['User Standard Data'] = originalAutoImportData.userStandardData.map(
				(item) => ({
					value: `userstd:${item.id}`,
					label: item.label,
				})
			);
			autoImportData['User Data'] = originalAutoImportData.userData.map((item) => ({
				value: `user:${item.id}`,
				label: item.label,
			}));
			autoImportData['Image Data'] = originalAutoImportData.imageData.map((item) => ({
				value: `image:${item.id}`,
				label: item.label,
			}));
			return {
				...state,
				fetchingAutoImportStatus: 'OK',
				autoImport: autoImportData,
				originalAutoImportData: originalAutoImportData,
			};
		case FETCH_ARTWORK_AUTOIMPORT_FAIL:
			return {
				...state,
				fetchingAutoImportStatus: 'FAILED',
				autoImport: {},
				originalAutoImportData: {},
			};
		case RESET_ARTWORK_AUTOIMPORT:
			return {
				...state,
				fetchingAutoImportStatus: '',
				autoImport: {},
				originalAutoImportData: {},
			};
		case FETCH_ARTWORK_OUTPUTTEMPLATES:
			return {
				...state,
				fetchingOutTemplatesStatus: 'PROCESSING',
			};
		case FETCH_ARTWORK_OUTPUTTEMPLATES_SUCCESS:
			return {
				...state,
				fetchingOutTemplatesStatus: 'OK',
				outputTemplates: action.result.data.results,
				totalFoundOutputTemplates: action.result.data.totalFound,
			};
		case FETCH_ARTWORK_OUTPUTTEMPLATES_FAIL:
			return {
				...state,
				fetchingOutTemplatesStatus: 'FAILED',
				outputTemplates: [],
				totalFoundOutputTemplates: 0,
			};
		case RESET_ARTWORK_OUTPUTTEMPLATES:
			return {
				...state,
				fetchingOutTemplatesStatus: '',
				outputTemplates: [],
				totalFoundOutputTemplates: 0,
			};
		case FETCH_ARTWORK_CATEGORIES:
			return {
				...state,
				fetchingCategoriesStatus: 'PROCESSING',
			};
		case FETCH_ARTWORK_CATEGORIES_SUCCESS:
			return {
				...state,
				fetchingCategoriesStatus: 'OK',
				categories: action.result.data.categories,
			};
		case FETCH_ARTWORK_CATEGORIES_FAIL:
			return {
				...state,
				fetchingCategoriesStatus: 'FAILED',
				categories: [],
			};
		case RESET_ARTWORK_CATEGORIES:
			return {
				...state,
				fetchingCategoriesStatus: '',
				categories: [],
			};
		default:
			return state;
	}
}

export default artworkExtra;
