import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';

import { Settings as SettingIcon } from '@mui/icons-material';

import { ROUTES_PATH_EDIT_DOMAIN_SETTINGS } from 'routes';

const EditDomainSettingsList = loadable(
	() => import('./components/EditDomainSettingsList/EditDomainSettingsList'),
	{
		fallback: <Loading size={50} />,
	}
);

const EditDomainSettingsRoutes = [
	{
		path: ROUTES_PATH_EDIT_DOMAIN_SETTINGS,
		sidebarName: <FormattedMessage id="pages.EditDomainSettings.Routes.EditDomainSettings" />,
		sidebarIcon: SettingIcon,
		component: EditDomainSettingsList,
		noBreadcrumbs: false,
		requiredUserLevel: { $gte: 40 },
	},
];
export default EditDomainSettingsRoutes;
