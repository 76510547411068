import React from 'react';

// import cx from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import randomColor from 'randomcolor';

import Select from '@mui/material/Select';
// import Popover from '@mui/material/Popover';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Calendar
import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
// // import "@fullcalendar/react/main.css";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
import './fullcalendar.css';

// icons
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	ColorLens as ColorLensIcon,
	Reply as GoBackIcon,
} from '@mui/icons-material';

// custom components
import { SpeedDialPopover } from 'components';

import { moment, _ } from 'utils/libHelper';

// redux
import { connect } from 'react-redux';
import {
	openGlobalDialog,
	fetchScreenManagerPlaylists,
	resetScreenManagerPlaylists,
} from 'redux/actions'; // actions
import { FormattedMessage } from 'react-intl';

let WeeklySchedulerStyles = (theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '90%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	// Selected events highlight
	eventSelected: {
		boxShadow: '0px 0px 5px 5px rgba(0,0,0,0.28)',
		padding: '3px 0px 3px 3px',
		margin: '5px 1px 3px 0px',
		border: `2px solid rgba(0,0,0,0.5) !important`, // TODO: remove !important after replacing lasso application
		opacity: `0.6 !important`, // TODO: remove !important after replacing lasso application
	},
});

const TEMP_EVENT_ID_ON_SELECTION = 'CHUNKED_HELPER';

class WeeklyScheduler extends React.Component {
	constructor(props) {
		super(props);
		this.playlistScheduleRef = React.createRef();
		// to save if CTRL pressed for multiple events selection is pressed
		this.multiSelect = false;
		this.state = {
			openPlaylistDialog: false,
			playlistDialogConfirmHandler: null,
			playlistDialogCloseHandler: null,
			selectedPlaylist: {},
			// scheduleEvents: [],
			// playlistColors: {},
			popoverPosition: null, // the popover position when event is clicked
			editingEvents: {}, // the calendar event being edit
		};
	}

	handlePlaylistSelect = (event, child) => {
		this.setState({
			selectedPlaylist: { id: event.target.value, name: child.props.name },
		});
	};

	isEventOverlapping = (event) => {
		let allEvents = this.playlistScheduleRef.current.getApi().getEvents();
		// let eventStartMoment = moment(event.start);
		// let eventEndMoment = moment(event.end);
		for (let i = 0; i < allEvents.length; i++) {
			let evt = allEvents[i];
			if (evt.id === TEMP_EVENT_ID_ON_SELECTION) {
				continue;
			}
			// let existEvtStartMoment = moment(evt.start);
			// let existEvtEndMoment = moment(evt.end);
			if (
				(event.start > evt.start && event.start < evt.end) ||
				(event.end > evt.start && event.end < evt.end) ||
				(evt.start >= event.start /*&& evt.start < event.end && evt.end > event.start*/ &&
					evt.end <= event.end)
			) {
				return true;
			}
		}
		return false;
	};

	isValidEvent = (event) => {
		let startMoment = moment(event.start);
		let endMoment = moment(event.end);
		let startTimeHHmm = startMoment.format('HHmm');
		let endTimeHHmm = endMoment.format('HHmm');
		if (
			(startTimeHHmm >= endTimeHHmm && endTimeHHmm !== '0000') ||
			startMoment.isAfter(endMoment)
		) {
			// if(event.end <= event.start){
			return false;
		}
		return true;
	};

	chunk = (event, id) => {
		var chunked = [];
		let startMoment = moment(event.start);
		let endMoment = moment(event.end);
		if (!this.isValidEvent(event)) {
			return chunked;
		}
		for (var day = startMoment.clone(); day.isBefore(endMoment); day.add(1, 'day')) {
			let start = day.clone().toDate();
			let end =
				endMoment.clone().format('HHmm') !== '0000'
					? day.clone().hour(endMoment.hour()).minute(endMoment.minute()).toDate()
					: day.clone().add(1, 'days').hour(endMoment.hour()).minute(endMoment.minute()).toDate();
			chunked.push({
				...event.extendedProps,
				title: event.title,
				backgroundColor: event.backgroundColor,
				start: start,
				end: end,
				id: id || start.toString() + end.toString() || '', //Used as a flag in the render function
			});
		}
		// console.log(`chunked array: ${JSON.stringify(chunked, null, 2)}`);
		return chunked;
	};

	clearEventChunksById = (id) => {
		let self = this;
		self.playlistScheduleRef.current.getApi().batchRendering(() => {
			self.playlistScheduleRef.current
				.getApi()
				.getEvents()
				.forEach((event) => {
					if (event.id === id) {
						event.remove();
					}
				});
		});
	};
	// Takes an event and renders it chunked. Also remove the previous chunked-helper.
	// Runs after a timeout and only once.
	renderChunkedHelper = (() => {
		let self = this;
		let timerId = 0;
		return function (event) {
			// console.log('clear timeout id: ' + timerId)
			// VID-3488, No. 7, fullCalendar "select" callback doesn't trigger if user mouse up outside of calendar view
			// hence we manually add "mouseup" event handler on document body, it must be removed once "onUserSelect" is triggerred (inside "onUserSelect" handler)
			document.addEventListener('mouseup', self.onUserSelect);
			window.clearTimeout(timerId);
			timerId = window.setTimeout(function () {
				var chunked = self.chunk(event, TEMP_EVENT_ID_ON_SELECTION);
				if (chunked.length === 0) {
					// Empty chunked events. Event maybe invalid
					return null;
				}
				// Check if any chunked event is overlapping
				for (let i = 0; i < chunked.length; i++) {
					if (self.isEventOverlapping(chunked[i])) {
						return null;
					}
				}
				// remove temp helper events (that were rendered when user selects time)
				self.clearEventChunksById(TEMP_EVENT_ID_ON_SELECTION);
				// Add new temp helper events
				self.playlistScheduleRef.current.getApi().batchRendering(() => {
					for (var i = 0; i < chunked.length; i++) {
						// console.log(`Adding event ${chunked[i]}`);
						self.playlistScheduleRef.current.getApi().addEvent(chunked[i]); //Manually render each chunk
					}
				});
			}, 50); //delay in ms. Could be tweaked for optimal perfomance
			// console.log('after create new timeout id: ' + timerId);
		};
	})();

	createEvent = () => {
		let currentEvents = this.props.scheduleEvents;
		let selectedPlaylist = this.state.selectedPlaylist;
		if (!selectedPlaylist.id) {
			this.clearEventChunksById(TEMP_EVENT_ID_ON_SELECTION);
			return null;
		}
		let playlistColors = { ...this.props.playlistColors };
		if (!playlistColors[selectedPlaylist.id]) {
			playlistColors[selectedPlaylist.id] = randomColor({
				luminosity: 'dark',
				hue: 'random',
			});
		}

		let newEvents = this.playlistScheduleRef.current
			.getApi()
			.getEvents()
			.filter((event) => event.id === TEMP_EVENT_ID_ON_SELECTION)
			.map((event) => {
				return {
					id: event.start.toString() + event.end.toString(),
					allDay: false,
					title: selectedPlaylist.name,
					playlistId: selectedPlaylist.id,
					start: event.start,
					end: event.end,
					backgroundColor: playlistColors[selectedPlaylist.id],
				};
			});
		this.clearEventChunksById(TEMP_EVENT_ID_ON_SELECTION);

		// this.setState({ /*scheduleEvents: currentEvents.concat(newEvents),*/ playlistColors: playlistColors });
		this.props.updateScheduleEvents(currentEvents.concat(newEvents), playlistColors);
	};

	handleEventDrop = (eventDropInfo) => {
		// console.log(eventDropInfo);
		let newEvents = this.props.scheduleEvents.map((scheduleEvent) => {
			if (scheduleEvent.id === eventDropInfo.oldEvent.id) {
				return {
					id: eventDropInfo.event.start.toString() + eventDropInfo.event.end.toString(),
					allDay: eventDropInfo.event.allDay,
					title: eventDropInfo.event.title,
					playlistId: eventDropInfo.event.extendedProps.playlistId,
					start: eventDropInfo.event.start,
					end: eventDropInfo.event.end,
					backgroundColor: eventDropInfo.event.backgroundColor,
				};
			}
			return scheduleEvent;
		});
		// this.setState({ scheduleEvents: newEvents });
		this.props.updateScheduleEvents(newEvents, this.props.playlistColors);
	};

	handleEventResize = (eventResizeInfo) => {
		// console.log(eventResizeInfo);
		let newEvents = this.props.scheduleEvents.map((scheduleEvent) => {
			if (scheduleEvent.id === eventResizeInfo.event.id) {
				return {
					id: eventResizeInfo.event.start.toString() + eventResizeInfo.event.end.toString(),
					allDay: eventResizeInfo.event.allDay,
					title: eventResizeInfo.event.title,
					playlistId: eventResizeInfo.event.extendedProps.playlistId,
					start: eventResizeInfo.event.start,
					end: eventResizeInfo.event.end,
					backgroundColor: eventResizeInfo.event.backgroundColor,
				};
			}
			return scheduleEvent;
		});
		// this.setState({ scheduleEvents: newEvents });
		this.props.updateScheduleEvents(newEvents, this.props.playlistColors);
	};

	onUserSelect = () => {
		// VID-3488 remove "mouseup" handler once this "onUserSelect" is triggerred
		document.removeEventListener('mouseup', this.onUserSelect);
		const closeDialogPLSelection = () => {
			this.setState({
				openPlaylistDialog: false,
				playlistDialogConfirmHandler: null,
				playlistDialogCloseHandler: null,
				/* currentSelection: {},*/
				selectedPlaylist: {},
			});
			this.clearEventChunksById(TEMP_EVENT_ID_ON_SELECTION);
		};

		this.setState({
			openPlaylistDialog: true,
			playlistDialogConfirmHandler: () => {
				this.createEvent();
				closeDialogPLSelection();
			},
			playlistDialogCloseHandler: closeDialogPLSelection,
		});
		this.playlistScheduleRef.current.getApi().unselect();
	};

	onEventRender = (info) => {
		// console.log(info);
		if (info.isMirror && !info.event.id) {
			this.renderChunkedHelper(info.event);
			return false; //don't render the select helper event
		}
	};

	onEventClick = (clickInfo) => {
		const { editingEvents } = this.state;
		const { scheduleEvents } = this.props;
		// Function to add a new event to the editingEvents object
		const addEditingEvent = (editingEvents, clickInfo) =>
			(editingEvents[clickInfo.event.id] = {
				id: clickInfo.event.id,
				title: clickInfo.event.title,
				playlistId: clickInfo.event.extendedProps.playlistId,
				start: clickInfo.event.start,
				end: clickInfo.event.end,
				backgroundColor: clickInfo.event.backgroundColor,
				// saving the position for each event. Last event position is used for pop up.
				popoverPosition: { left: clickInfo.jsEvent.x, top: clickInfo.jsEvent.y },
			});

		// If multiple selection is active, enable multiple events selection
		if (this.multiSelect) {
			if (!editingEvents[clickInfo.event.id]) {
				addEditingEvent(editingEvents, clickInfo);
			} else {
				delete editingEvents[clickInfo.event.id];
			}
			this.setState({ editingEvents });
		} else {
			// Single event selection
			addEditingEvent(editingEvents, clickInfo);
			this.setState({
				popoverPosition: { left: clickInfo.jsEvent.x, top: clickInfo.jsEvent.y },
				editingEvents,
			});
		}
		// Assign class for the selected events to highlight them
		let updatedEvents = scheduleEvents.map((sEv) => {
			return {
				...sEv,
				classNames: editingEvents[sEv.id]
					? /* ['selected'] */ [this.props.classes.eventSelected]
					: [],
			};
		});
		this.props.updateScheduleEvents(updatedEvents, this.props.playlistColors);
	};

	getEventActions = () => {
		let { editingEvents } = this.state;
		let { intl, screenManagerPlaylists } = this.props;
		const closePlaylistDialog = (extra = {}) => {
			this.setState({
				...extra,
				openPlaylistDialog: false,
				playlistDialogConfirmHandler: null,
				playlistDialogCloseHandler: null /* currentSelection: {},*/,
				selectedPlaylist: {},
				popoverPosition: null,
				editingEvents: {},
			});
		};

		const eventActions = [
			{
				icon: <GoBackIcon />,
				name: intl.formatMessage({
					id: 'pages.ScreenManager.components.Schedules.WeeklyScheduler.EventActionGoToPlaylist',
				}),
				onClick: () => {
					let { editingEvents } = this.state;
					let playlisttId = editingEvents[Object.keys(editingEvents)[0]].playlistId;
					this.props.handleGoToPlaylist(playlisttId);
				},
			},
			{
				icon: <EditIcon />,
				name: intl.formatMessage({ id: 'GENERAL.Edit' }),
				onClick: () => {
					this.setState({
						openPlaylistDialog: true,
						selectedPlaylist:
							// find playlist for a single selection, ignore for multiple as most likely they have different playlists
							Object.keys(editingEvents).length === 1
								? screenManagerPlaylists.playlists.filter(
										(pl) => pl.id === editingEvents[Object.keys(editingEvents)[0]].playlistId
								  )[0] || {}
								: {},
						playlistDialogConfirmHandler: () => {
							// this.state.selectedPlaylist;

							let selectedPlaylist = this.state.selectedPlaylist;
							if (!selectedPlaylist.id) {
								return closePlaylistDialog();
							}
							let playlistColors = { ...this.props.playlistColors };
							if (!playlistColors[selectedPlaylist.id]) {
								playlistColors[selectedPlaylist.id] = randomColor({
									luminosity: 'dark',
									hue: 'random',
								});
							}
							Object.keys(editingEvents).forEach((key) => {
								editingEvents[key].title = selectedPlaylist.name;
								editingEvents[key].playlistId = selectedPlaylist.id;
								editingEvents[key].backgroundColor = playlistColors[selectedPlaylist.id];
							});
							let newEvents = this.props.scheduleEvents.map((scheduleEvent) => {
								return { ...(editingEvents[scheduleEvent.id] || scheduleEvent) };
							});

							closePlaylistDialog({
								// scheduleEvents: newEvents,
								// playlistColors: playlistColors,
								editingEvents,
							});
							this.props.updateScheduleEvents(newEvents, playlistColors);
						},
						playlistDialogCloseHandler: () => {
							closePlaylistDialog();
						},
					});
				},
			},
			{
				icon: <ColorLensIcon />,
				name: intl.formatMessage({
					id: 'pages.ScreenManager.components.Schedules.WeeklyScheduler.EventActionChangeColor',
				}),
				onClick: () => {
					let { editingEvents } = this.state;
					let { playlistColors } = this.props;
					let playlistId = editingEvents[Object.keys(editingEvents)[0]].playlistId;
					let newColor = randomColor({
						luminosity: 'dark',
						hue: 'random',
					});
					let newPlaylistColors = { ...playlistColors, [playlistId]: newColor };
					let newEvents = this.props.scheduleEvents.map((scheduleEvent) => {
						if (
							scheduleEvent.playlistId === editingEvents[Object.keys(editingEvents)[0]].playlistId
						) {
							return { ...scheduleEvent, backgroundColor: newColor };
						}
						return scheduleEvent;
					});
					// this.setState({ /*scheduleEvents: newEvents,*/ playlistColors: newPlaylistColors });
					this.props.updateScheduleEvents(newEvents, newPlaylistColors);
				},
			},
			{
				icon: <DeleteIcon />,
				name: intl.formatMessage({ id: 'GENERAL.Delete' }),
				onClick: () => {
					let confirmDialog = {
						size: 'sm',
						title: intl.formatMessage({
							id: 'pages.ScreenManager.components.Schedules.WeeklyScheduler.EventActionDeleteTitle',
						}),
						content: (
							<Typography
								variant="body1"
								component="div"
								gutterBottom
								style={{
									minHeight: 50,
									marginTop: 25,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								<FormattedMessage
									id="pages.ScreenManager.components.Schedules.WeeklyScheduler.EventActionDeleteMessage"
									values={{
										event: Object.keys(editingEvents).map((key, idx) => (
											<Typography key={idx} variant="body1" component="div" color="secondary">
												{editingEvents[key].title} from{' '}
												{moment(editingEvents[key].start).format('HH:mm (ddd)')} to{' '}
												{moment(editingEvents[key].end).format('HH:mm (ddd)')}
											</Typography>
										)),
									}}
								/>
							</Typography>
						),
						confirmCB: () => {
							let newEvents = this.props.scheduleEvents.filter((scheduleEvent) => {
								return !editingEvents[scheduleEvent.id];
							});
							let playlistColors = { ...this.props.playlistColors };
							Object.keys(editingEvents).forEach((key) => {
								delete playlistColors[key.playlistId];
							});
							this.setState({
								/*scheduleEvents: newEvents,*/ popoverPosition: null,
								editingEvents: {},
							});
							this.props.updateScheduleEvents(newEvents, playlistColors);
						},
					};
					this.props.openGlobalDialog(confirmDialog);
				},
			},
		];
		// removes the playlist color changer, and GoTo playlist button for multiple event selections as most likely they will have different playlists
		if (Object.keys(editingEvents).length > 1) {
			delete eventActions[0];
			delete eventActions[2];
		}
		return eventActions;
	};

	// Handler for the edit event popver closure
	onSpeedDialPopoverClose = () => {
		let updatedEvents = this.props.scheduleEvents.map((sEv) => {
			if (this.state.editingEvents[sEv.id]) {
				return {
					...sEv,
					classNames: [],
				};
			}
			return sEv;
		});
		this.setState({ popoverPosition: null, editingEvents: {} }, () =>
			this.props.updateScheduleEvents(updatedEvents, this.props.playlistColors)
		);
	};

	keyDownEventHandler = (event) => {
		if (event.key === 'Control' || event.key === 'Meta') {
			this.multiSelect = true;
		}
	};

	keyUpEventHandler = (event) => {
		if (event.key === 'Control' || event.key === 'Meta') {
			this.multiSelect = false;
			let editingEventsIDs = Object.keys(this.state.editingEvents);
			// Triggers the popover on the key-up when some events are selected
			if (editingEventsIDs.length) {
				this.setState({
					popoverPosition: this.state.editingEvents[editingEventsIDs[editingEventsIDs.length - 1]]
						.popoverPosition,
				});
			}
		}
	};

	componentDidMount() {
		this.props.fetchScreenManagerPlaylists();

		// Event listeners for CTRL or CMD key press to handle multiple event selection.
		document.addEventListener('keydown', this.keyDownEventHandler);
		document.addEventListener('keyup', this.keyUpEventHandler);
	}

	componentWillUnmount() {
		this.props.resetScreenManagerPlaylists();
		document.removeEventListener('keydown', this.keyDownEventHandler);
		document.removeEventListener('keydown', this.keyUpEventHandler);
	}
	render() {
		const {
			classes,
			screenManagerPlaylists,
			scheduleEvents,
			disabled,
			screenOrientation,
			intl,
			slotDuration,
		} = this.props;
		// const [selectedScreens, setSelectedScreens] = React.useState([]);
		let {
			openPlaylistDialog,
			playlistDialogConfirmHandler,
			playlistDialogCloseHandler,
			/*scheduleEvents,*/ selectedPlaylist,
		} = this.state;

		return (
			<React.Fragment>
				<FullCalendar
					ref={this.playlistScheduleRef}
					plugins={[/*dayGridPlugin,*/ timeGridPlugin, interactionPlugin, momentPlugin]}
					initialView="timeGridWeek"
					nowIndicator={true}
					validRange={{
						start: moment().isoWeekday(1).format('YYYY-MM-DD'), //disabled?moment().subtract(1, 'months').weekday(1).format('YYYY-MM-DD'):moment().weekday(1).format('YYYY-MM-DD'),
						end: moment().isoWeekday(7).add(1, 'days').format('YYYY-MM-DD'), //disabled?moment().subtract(1, 'months').weekday(7).add(1, 'days').format('YYYY-MM-DD'):moment().weekday(7).add(1, 'days').format('YYYY-MM-DD'),
					}}
					allDaySlot={false}
					slotEventOverlap={false}
					headerToolbar={false}
					// header={{
					// 	left:'"Select time to schedule playlist"'
					// }}
					eventOverlap={false}
					footerToolbar={false}
					eventTimeFormat={{
						hour: '2-digit',
						minute: '2-digit',
						hour12: false,
						omitZeroMinute: false,
						meridiem: 'short',
					}}
					slotDuration={`00:${slotDuration}:00`}
					scrollTimeReset={false}
					expandRows={true}
					// slotLabelInterval='00:30:00'
					scrollTime="00:00:00"
					// slotLabelInterval='00:15'
					// slotLabelFormat="HH:mm"
					slotLabelFormat={{
						hour: '2-digit',
						minute: '2-digit',
						hour12: false,
						omitZeroMinute: false,
						meridiem: 'short',
					}}
					firstDay={1}
					height={550}
					// contentHeight={500}
					// height="parent"
					dayHeaderFormat={{ weekday: 'short' }}
					// dragging/resizing
					selectMirror={!disabled}
					selectable={!disabled}
					// selectOverlap={false}
					select={this.onUserSelect}
					editable={!disabled}
					// eventResizableFromStart={true}
					// event
					events={scheduleEvents}
					eventContent={this.onEventRender}
					eventDrop={this.handleEventDrop}
					eventResize={this.handleEventResize}
					eventClick={this.onEventClick}
				/>
				<Dialog
					maxWidth="xs"
					fullWidth
					onClose={playlistDialogCloseHandler}
					aria-labelledby="Playlist-Selection"
					open={openPlaylistDialog}
				>
					<DialogTitle id="Playlist-Selection">
						{intl.formatMessage({
							id: 'pages.ScreenManager.components.Schedules.WeeklyScheduler.SelectPLDialogTitle',
						})}
					</DialogTitle>

					<DialogContent dividers>
						<FormControl variant="standard" className={classes.formControl}>
							<Select
								value={selectedPlaylist.id || ''}
								onChange={this.handlePlaylistSelect}
								displayEmpty
								// name="playlist"
								className={classes.selectEmpty}
								title={intl.formatMessage({
									id:
										'pages.ScreenManager.components.Schedules.WeeklyScheduler.SelectPLDialogDDTooltip',
								})}
							>
								<MenuItem value="">
									<em>{intl.formatMessage({ id: 'GENERAL.None' })}</em>
								</MenuItem>
								{screenManagerPlaylists &&
									_.sortBy(screenManagerPlaylists.playlists, [
										(pl) => pl.name.toLowerCase(),
									]).map((playlist) => (
										<MenuItem
											key={playlist.id}
											value={playlist.id}
											data-name={playlist.name}
											name={playlist.name}
											disabled={
												playlist.isMaster ||
												(screenOrientation && playlist.layout !== screenOrientation) ||
												false
											}
										>{`${playlist.name} (${playlist.layout})`}</MenuItem>
									))}
							</Select>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button onClick={playlistDialogCloseHandler} color="primary">
							{intl.formatMessage({ id: 'GENERAL.Cancel' })}
						</Button>
						<Button onClick={playlistDialogConfirmHandler} color="primary">
							{intl.formatMessage({ id: 'GENERAL.Ok' })}
						</Button>
					</DialogActions>
				</Dialog>
				<SpeedDialPopover
					speedDialActions={this.getEventActions()}
					isOpen={!!this.state.popoverPosition}
					anchorPosition={this.state.popoverPosition}
					onClose={this.onSpeedDialPopoverClose}
				/>
			</React.Fragment>
		);
	}
}

WeeklyScheduler.propTypes = {
	classes: PropTypes.object.isRequired,
	screenManagerPlaylists: PropTypes.object.isRequired,
	scheduleEvents: PropTypes.array.isRequired,
	updateScheduleEvents: PropTypes.func.isRequired,
	handleGoToPlaylist: PropTypes.func.isRequired,
	playlistColors: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	screenOrientation: PropTypes.string,
	slotDuration: PropTypes.number,
	intl: PropTypes.any,
	openGlobalDialog: PropTypes.func.isRequired,
	fetchScreenManagerPlaylists: PropTypes.func.isRequired,
	resetScreenManagerPlaylists: PropTypes.func.isRequired,
};

WeeklyScheduler.defaultProps = {
	disabled: false,
	screenOrientation: null,
	slotDuration: 60,
};

const mapStateToProps = (state) => {
	return {
		screenManagerPlaylists: state.screenManagerPlaylists,
	};
};

export default connect(mapStateToProps, {
	openGlobalDialog,
	fetchScreenManagerPlaylists,
	resetScreenManagerPlaylists,
})(withStyles(WeeklySchedulerStyles)(WeeklyScheduler));
