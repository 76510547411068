import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';

// MUI components
import {
	// TextField,
	Button,
	Card,
	// CardHeader,
	// CardContent,
	CardMedia,
	// CardActions,
	Divider,
	Backdrop,
	CircularProgress,
	// Typography,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';

// import { Done as OKIcon } from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Configuration, OpenAIApi } from 'openai';
import { grey } from '@mui/material/colors';
import config from 'config';

import { connect } from 'react-redux';
import { openGlobalDialog, notifyGeneral } from 'redux/actions'; // actions

const useStyles = makeStyles((theme) => ({
	// root: {
	// 	padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
	// 	width: `100%`,
	// 	color: 'inherit',
	// 	fontSize: 'inherit',
	// 	position: 'relative',
	// 	display: 'flex',
	// },
	// addingTextField: {
	// 	marginLeft: theme.spacing(1),
	// 	marginRight: theme.spacing(1),
	// 	width: '90%',
	// },
	// addingButton: {
	// 	margin: theme.spacing(1),
	// 	left: '35%',
	// },
	// resultImage: {
	// 	marginTop: '20px',
	// 	width: '75%',
	// },
	root: {
		width: '100%',
		height: '100%',
		position: 'relative',
	},
	searchPaginationWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between', //'space-between',
		padding: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		width: '100%',
		// position: 'fixed',
		// height: heightSearchPageination,
		// zIndex: 1,
		// left: 4,
		// right: 20,
		// top: '50%',
		// left: '50%',

		// transform: 'translateX(-50%)',
		// transition: 'color 0.5s, background-color 0.5s',
	},
	searchWrapper: {
		textAlign: 'left',
		// margin: `0px ${theme.spacing(1)}`,
		flex: '1 1 auto',
	},
	paginationWrapper: {
		display: 'flex',
		margin: `0px ${theme.spacing(1)}`,
		alignItems: 'center',
		justifyContent: 'flex-end',
		// flex: '1 1 auto',
	},
	containerBox: {
		display: 'flex',
		position: 'relative',
		// overflow: 'hidden',
		width: 'auto',
		// height: 'calc(100% - 100px)',
		// minHeight: `max(120px, calc(100% - 100px))`, //120,
		minHeight: 150,
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'flex-start',
		alignContent: 'flex-start',
		// [theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
		// 	justifyContent: 'flex-start',
		// },
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			justifyContent: 'space-evenly',
		},
	},
	itemBox: {
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		flex: '0 1 auto',
		alignSelf: 'auto',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid rgba(0,0,0,0.1)`,
		borderRadius: 4,
		height: 'auto',
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			width: () => {
				// minus theme.spacing(2) is because we use theme.spacing(1) for margin left & right
				return `calc(${(1 / 3) * 100}% - ${theme.spacing(2)})`;
			},
		},
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: '90%',
		},
		'&:hover': {
			borderColor: `rgba(0,0,0,0.3)`,
			boxShadow: theme.customBoxShadow.boxShadow,
		},
		'& img': {
			maxHeight: '100%',
			maxWidth: '100%',
		},
	},
	cardRoot: {
		width: '100%',
		boxShadow: 'unset',
	},
	cardHeader: {
		padding: 'unset',
		paddingLeft: theme.spacing(0.5),
		// fontSize: '1rem',
	},
	cardHeaderContent: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	cardMedia: {
		// height: 100,
		// paddingTop: '56.25%', // 16:9
		backgroundSize: 'contain',
		backgroundColor: `rgba(0,0,0,0.1)`,
	},
	cardAction: {
		padding: 'unset',
		paddingTop: theme.spacing(1),
	},
	actionIconButton: {
		padding: 2,
		border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
	},
	backdropLoading: {
		zIndex: 999,
		position: 'absolute',
		alignItems: 'flex-start',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		color: theme.palette.primary.main,
		borderRadius: 4,
	},
	loadingCircularProgress: {
		position: 'sticky',
		top: 'calc(50% - 20px)',
	},
	input: {
		marginLeft: theme.spacing(1),
		fontSize: 'inherit',
		flex: 1,
		position: 'relative',
		width: '50%',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: 4,
		borderColor: grey[500],
		backgroundColor: '#ffffff',
		paddingLeft: theme.spacing(1),
	},
	rightIconInButton: {
		marginLeft: theme.spacing(1),
	},

	loading: {
		height: '100%',
		display: 'flex',
		alignItems: 'baseline',
	},
	hidden: {
		visibility: 'hidden',
	},
}));
function GenerateImage({
	promptKeywords,
	onMediaClick,
	children,
	openGlobalDialog,
	notifyGeneral,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();

	const [prompt, setPrompt] = React.useState('');
	const [result, setResult] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const dalleAPIKey = config.dalleAPI.apiKey;

	const configuration = React.useMemo(() => {
		return new Configuration({
			apiKey: dalleAPIKey,
		});
	}, [dalleAPIKey]);
	const openai = React.useMemo(() => {
		return new OpenAIApi(configuration);
	}, [configuration]);

	const generateImageConfirm = () => {
		let confirmDialog = {
			size: 'sm',
			title: intl.formatMessage({
				id: 'components.GenerateImage.GenerateImageDialogTitle',
			}),
			content: intl.formatMessage({
				id: 'components.GenerateImage.GenerateImageDialogContent',
			}),
			confirmCB: () => generateImage(),
		};
		openGlobalDialog(confirmDialog);
	};

	const generateImage = React.useCallback(
		async (text) => {
			setResult([]);
			setIsLoading(true);
			await openai
				.createImage({
					prompt: prompt === '' ? text : prompt,
					n: 3,
					size: '1024x1024',
				})
				.then((resultt) => {
					setResult(resultt.data.data);
					setIsLoading(false);
				})
				.catch((err) => {
					console.log(err);
					notifyGeneral(
						intl.formatMessage({
							id: 'components.GenerateImage.OpenAIError',
						}),
						'error'
					);
					setIsLoading(false);
				});
		},
		[intl, notifyGeneral, openai, prompt]
	);

	const onNewMediaClick = (res, pp) => {
		let filename = '';
		try {
			filename = new URL(res.url).pathname.split('/').pop();
		} catch (e) {
			console.error(e);
		}
		let fileNameWithExtension = pp + '.' + filename.split('.').pop();
		onMediaClick(res, fileNameWithExtension);
	};
	React.useEffect(() => {
		if (promptKeywords !== '' && prompt === '') {
			setPrompt(promptKeywords);
			generateImage(promptKeywords);
		}
	}, [generateImage, promptKeywords, prompt]);
	return (
		<div className={classes.root}>
			{
				<Backdrop className={classes.backdropLoading} open={isLoading}>
					<CircularProgress color="inherit" className={classes.loadingCircularProgress} />
				</Backdrop>
			}
			<div className={classes.searchPaginationWrapper}>
				<div className={classes.searchWrapper}>
					<InputBase
						className={promptKeywords !== '' ? classes.hidden : classes.input}
						value={prompt}
						readOnly={promptKeywords === '' ? false : true}
						placeholder={intl.formatMessage({ id: 'components.GenerateImage.GenerateImageText' })}
						inputProps={{
							'aria-label': 'Generate Image',
							style: { border: 'unset', margin: 'auto', boxShadow: 'unset' },
						}}
						onChange={(e) => setPrompt(e.target.value)}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								if (prompt === '') {
									notifyGeneral(
										intl.formatMessage({
											id: 'components.GenerateImage.NoPromptError',
										}),
										'error'
									);
								} else {
									result.length > 0 ? generateImageConfirm() : generateImage();
								}
							}
						}}
					/>
				</div>

				<Button
					variant="contained"
					color="primary"
					className={classes.addingButton}
					onClick={() => {
						if (prompt === '') {
							notifyGeneral(
								intl.formatMessage({
									id: 'components.GenerateImage.NoPromptError',
								}),
								'error'
							);
						} else {
							result.length > 0 ? generateImageConfirm() : generateImage();
						}
					}}
				>
					{intl.formatMessage({
						id: 'components.GenerateImage.GenerateButton',
					})}
					<AutoAwesomeIcon className={classes.rightIconInButton} />
				</Button>
			</div>

			<Divider style={{ width: '100%' }} />
			<div className={classes.containerBox}>
				{isLoading && (
					<div className={classes.loading}>
						{intl.formatMessage({
							id: 'components.GenerateImage.LoadingText',
						})}
					</div>
				)}
				{result.length > 0 ? (
					result.map((res, idx) => {
						return (
							<div key={idx + '-' + idx} className={classes.itemBox}>
								<Card raised className={classes.cardRoot}>
									<CardMedia
										component="div"
										className={classes.cardMedia}
										image={res.url}
										onClick={() => onNewMediaClick(res, prompt)}
										alt={'alt'}
										style={{ height: 130, cursor: 'pointer' }}
									/>
								</Card>
							</div>
						);
					})
				) : (
					<></>
				)}
			</div>

			<Divider style={{ width: '100%' }} />
		</div>
	);
}

GenerateImage.propTypes = {
	openGlobalDialog: PropTypes.func.isRequired,
	promptKeywords: PropTypes.string,
	onMediaClick: PropTypes.func,
	notifyGeneral: PropTypes.func.isRequired,
};

const mapStateToProps = () => {
	return {};
};

GenerateImage.defaultProps = { type: 'string', promptKeywords: '' };
export default connect(mapStateToProps, {
	openGlobalDialog,
	notifyGeneral,
})(GenerateImage);
