import React from 'react';

import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
// MUI components
import {
	TextField,
	Switch,
	MenuItem,
	Radio,
	Tabs,
	Tab,
	Slider,
	Input,
	ListSubheader,
	Divider,
	Button,
	OutlinedInput,
	InputAdornment,
	Select,
	FormControl,
	InputLabel,
} from '@mui/material';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { blueGrey, grey } from '@mui/material/colors';

// intl lang
import { FormattedMessage } from 'react-intl';

export const StyledTabs = withStyles({
	indicator: {
		display: 'flex',
		justifyContent: 'center',
		// backgroundColor: 'transparent',
		'& > div': {
			backgroundColor: blueGrey[600], // '#635ee7',
		},
	},
})((props) => (
	<Tabs
		indicatorColor="secondary"
		textColor="inherit"
		TabIndicatorProps={{ children: <div /> }}
		{...props}
	/>
));

export const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		// color: '#fff',
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(15),
		marginRight: theme.spacing(1),
		minWidth: 'unset',
		'&:focus': {
			opacity: 1,
		},
	},
}))((props) => <Tab disableRipple {...props} />);

export const StyledToggleButtonGroup = withStyles((theme) => ({
	grouped: {
		// margin: theme.spacing(0.5),
		// border: 'none',
		padding: theme.spacing(0, 1),
		// '&:not(:first-child)': {
		// 	borderRadius: theme.shape.borderRadius,
		// },
		// '&:first-child': {
		// 	borderRadius: theme.shape.borderRadius,
		// },
	},
}))(ToggleButtonGroup);

export const OutlinedInputWithSelect = withStyles((theme) => ({
	input: {
		'& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]': {
			'-webkit-appearance': 'textfield',
			'-moz-appearance': 'textfield',
		},
	},
	selectMenuPaperOverride: {
		maxHeight: 300,
		// minWidth: 100,
	},
	selectOverride: {
		paddingRight: '0px !important',
		paddingLeft: 4,
	},
	selectMenuItem: {
		fontSize: 'inherit',
	},
}))(
	({
		classes,
		className, // className apply to root OutlineInput
		label, // label of the input field
		InputLabelProps, // InputLabel props
		value, // value of the field
		// onChange, // onChange handler
		onCommitted, // onCommitted handler (onBlur & onChange in Select)
		inputSuffix, // Suffix of the input value
		options, // options array of dropdown list
		inputProps, // props passed to native input (used by OutlineInput)
		...rest
	}) => {
		delete rest.onChange;
		delete rest.onBlur;
		const [inputValue, setInputValue] = React.useState(value);
		React.useEffect(() => {
			setInputValue(value);
		}, [value]);
		return (
			<FormControl variant="outlined" className={className}>
				{label && (
					<InputLabel
						className={classes.label}
						sx={{
							'&:not([data-shrink="true"])': {
								transform: !inputValue
									? `translate(14px, 9px) scale(1)`
									: `translate(14px, -7px) scale(0.75)`,
							},
							'&.MuiInputLabel-outlined[data-shrink="true"]': {
								transform: `translate(14px, -7px) scale(0.75)`,
							},
							fontSize: 'inherit',
						}}
						htmlFor="outlinedInputWithSelect"
						{...(InputLabelProps || {})}
					>
						{label}
					</InputLabel>
				)}
				<OutlinedInput
					id="outlinedInputWithSelect"
					className={cx(classes.input)}
					sx={{
						fontSize: 'inherit',
						color: 'inherit',
					}}
					margin="dense"
					notched={true}
					label={label || ''}
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value || '')}
					onBlur={onCommitted}
					endAdornment={
						<InputAdornment position="end">
							{inputSuffix}
							<Select
								value={inputValue}
								variant="standard"
								onChange={onCommitted}
								displayEmpty
								disableUnderline
								MenuProps={{
									anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
									transformOrigin: { vertical: 'top', horizontal: 'right' },
									// getContentAnchorEl: null,
									PopoverClasses: { root: classes.selectMenuPaperOverride },
								}}
								renderValue={() => ''}
								classes={{
									select: classes.selectOverride,
								}}
								// inputProps={{ tabIndex: null }}
							>
								{options.map((option, idx) => {
									return (
										<MenuItem
											key={`${option.label}-${idx}`}
											value={option.value}
											className={classes.selectMenuItem}
										>
											{option.label}
										</MenuItem>
									);
								})}
							</Select>
						</InputAdornment>
					}
					inputProps={inputProps}
					{...rest}
				/>
			</FormControl>
		);
	}
);

export const OutlinedNumberTextField = withStyles((theme) => ({
	textField: {
		margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
	},
	textFieldInput: {
		color: 'inherit',
		fontSize: 'inherit', //paletteFontSize,
	},
	textFieldLabel: {
		fontSize: 'inherit',
		// marginLeft: -8,
	},
}))(({ classes, children, required, label, value, step, min, max, handleOnChange, ...rest }) => {
	const [inputValue, setInputValue] = React.useState(value);
	React.useEffect(() => {
		setInputValue(value);
	}, [value]);
	return (
		<TextField
			className={classes.textField}
			margin="dense"
			label={label}
			required={required}
			variant="outlined"
			value={inputValue}
			type="number"
			inputProps={{ step: step, min: min, max: max }}
			onChange={(e) => {
				setInputValue(e.target.value);
			}}
			InputProps={{
				className: classes.textFieldInput,
				onBlur: (e) => {
					let val = Number(e.target.value);
					if (typeof min === 'number' && val <= min) {
						val = min;
					} else if (typeof max === 'number' && val >= max) {
						val = max;
					}
					// else if (typeof value !== 'number') {
					// 	// in case the value is not number, we set it to a number of whatever value in current input
					// 	handleOnChange(val);
					// }
					handleOnChange(val);
					setInputValue(val);
				},
			}}
			InputLabelProps={{
				sx: {
					fontSize: 'inherit',
					'&:not([data-shrink="true"])': {
						transform: !inputValue
							? `translate(14px, 9px) scale(1)`
							: `translate(14px, -7px) scale(0.75)`,
					},
					'&.MuiInputLabel-outlined[data-shrink="true"]': {
						transform: `translate(14px, -7px) scale(0.75)`,
					},
				},
			}}
			{...rest}
		>
			{children}
		</TextField>
	);
});

/**
 * Styled Outlined TextField with controlled value from props
 */
export const ControlledStyledOutlinedTextField = withStyles((theme) => ({
	textField: {
		margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
	},
	textFieldInput: {
		color: 'inherit',
		fontSize: 'inherit',
	},
}))(({ classes, children, inputRef, value, ...rest }) => {
	return (
		<TextField
			className={classes.textField}
			margin="dense"
			variant="outlined"
			value={value}
			InputProps={{
				className: classes.textFieldInput,
				inputRef: inputRef,
			}}
			InputLabelProps={{
				sx: {
					fontSize: 'inherit',
					width: 'inherit',
					'&:not([data-shrink="true"])': {
						transform: !value
							? `translate(14px, 9px) scale(1)`
							: `translate(14px, -7px) scale(0.75)`,
					},
					'&.MuiInputLabel-outlined[data-shrink="true"]': {
						transform: `translate(14px, -7px) scale(0.75)`,
					},
				},
			}}
			{...rest}
		>
			{children}
		</TextField>
	);
});

// const paletteFontSize = '0.8rem';
export const StyledOutlinedTextField = withStyles((theme) => ({
	textField: {
		margin: theme.spacing(1, 2), // `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		fontSize: 'inherit',
	},
	textFieldInput: {
		'& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]': {
			'-webkit-appearance': 'textfield',
			'-moz-appearance': 'textfield',
		},
	},
}))(
	({
		classes,
		className,
		children,
		value,
		/* inputRef, */ onChange,
		onBlur,
		InputProps = {},
		InputLabelProps = {},
		...rest
	}) => {
		const [inputValue, setInputValue] = React.useState(value);
		React.useEffect(() => {
			setInputValue(value);
		}, [value]);
		return (
			<TextField
				className={cx(classes.textField, { [className]: !!className })}
				margin="dense"
				// label="Label"
				// required
				variant="outlined"
				value={inputValue}
				onChange={rest.select ? onChange : (e) => setInputValue(e.target.value || '')}
				onBlur={(e, child) => {
					// both onChange & onBlur handler are triggered by onBlur event
					if (!rest.select && onChange) onChange(e, child);
					if (onBlur) onBlur(e, child);
				}}
				InputProps={{
					className: classes.textFieldInput,
					sx: {
						color: 'inherit',
						fontSize: 'inherit',
						input: {
							color: 'inherit',
							fontSize: 'inherit',
						},
					},
					...InputProps,
					// inputRef: inputRef,
				}}
				InputLabelProps={{
					sx: {
						fontSize: 'inherit',
						width: 'inherit',
						'&:not([data-shrink="true"])': {
							transform: !inputValue
								? `translate(14px, 9px) scale(1)`
								: `translate(14px, -7px) scale(0.75)`,
						},
						'&.MuiInputLabel-outlined[data-shrink="true"]': {
							transform: `translate(14px, -7px) scale(0.75)`,
						},
					},
					...InputLabelProps,
				}}
				{...rest}
			>
				{children}
			</TextField>
		);
	}
);

/**
 * Simplified TextField
 */
export const SimplifiedOutlinedTextField = withStyles((theme) => ({
	textField: {
		margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
	},
	textFieldInput: {
		color: 'inherit',
		fontSize: 'inherit', //paletteFontSize,
	},
}))(
	({
		classes,
		className,
		children,
		value,
		onChange,
		onBlur,
		InputProps,
		InputLabelProps,
		...rest
	}) => {
		const [inputValue, setInputValue] = React.useState(value);
		React.useEffect(() => {
			setInputValue(value);
		}, [value]);
		return (
			<TextField
				className={cx(classes.textField, { [className]: !!className })}
				margin="dense"
				variant="outlined"
				value={inputValue}
				onChange={(e) => {
					setInputValue(e.target.value || '');
					if (typeof onChange === 'function') onChange(e);
				}}
				onBlur={onBlur}
				InputProps={{
					className: classes.textFieldInput,
					...InputProps,
				}}
				InputLabelProps={{
					sx: {
						fontSize: 'inherit',
						width: 'inherit',
						'&:not([data-shrink="true"])': {
							transform: !inputValue
								? `translate(14px, 9px) scale(1)`
								: `translate(14px, -7px) scale(0.75)`,
						},
						'&.MuiInputLabel-outlined[data-shrink="true"]': {
							transform: `translate(14px, -7px) scale(0.75)`,
						},
					},
					...InputLabelProps,
				}}
				{...rest}
			>
				{children}
			</TextField>
		);
	}
);

/**
 * options structure
 * [
 * 		{value: 'val', label: 'label', disable: (undefined|false)/true},
 * 		{value: 'val', label: 'label', disable: (undefined|false)/true},
 * 		...
 * ]
 */
export const StyledOutlinedTextFieldSelection = withStyles((theme) => ({
	selectMenuItem: {
		fontSize: 'inherit',
	},
	textFieldSelectMenu: {
		paddingTop: 12,
		paddingBottom: 12,
		paddingLeft: 4,
		paddingRight: '12px !important',
	},
	MUISelectIconOutlined: {
		right: 0,
	},
}))(
	({
		classes,
		label,
		value,
		onChange,
		noneOption,
		noneMsg,
		options = [], // [{value: 'xxx', label: 'xxx', id: 'xxx', disabled: BOOL|undefined, rest: OBJECT|undefined }, ...]. rest is the "rest" prop in MenuItem
		smallMenu,
		smallIcon,
		SelectProps = {},
		...rest
	}) => {
		return (
			<StyledOutlinedTextField
				select
				label={label}
				value={value}
				onChange={onChange}
				SelectProps={{
					classes: {
						selectMenu: smallMenu ? classes.textFieldSelectMenu : null,
						iconOutlined: smallIcon ? classes.MUISelectIconOutlined : null,
					},
					...SelectProps,
					// inputProps: { tabIndex: null },
				}}
				{...rest}
			>
				{noneOption && (
					<MenuItem value="" className={classes.selectMenuItem}>
						<em>
							{noneMsg ? (
								noneMsg
							) : (
								<FormattedMessage id="pages.Artwork.components.CustomMUI.noneMsg" />
							)}
						</em>
					</MenuItem>
				)}
				{options.map(({ value, label, id, disabled, ...rest }, idx) => (
					<MenuItem
						key={`${value || id}-${idx}`}
						value={value || id}
						className={classes.selectMenuItem}
						disabled={Boolean(disabled)}
						{...rest}
					>
						{label}
					</MenuItem>
				))}
			</StyledOutlinedTextField>
		);
	}
);

/**
 * options structure:
 * {
 * 		header1: [{value: 'val', label: 'label', disable: (undefined|false)/true}, {value: 'val', label: 'label', disable: (undefined|false)/true}],
 * 		header2: [{value: 'val', label: 'label', disable: (undefined|false)/true}, {value: 'val', label: 'label', disable: (undefined|false)/true}],
 * 		...
 * }
 */
export const StyledOutlinedSelectionWithSubheader = withStyles((theme) => ({
	selectMenuItem: {
		fontSize: 'inherit',
	},
	textFieldSelectMenu: {
		paddingTop: 12,
		paddingBottom: 12,
		paddingLeft: 4,
		paddingRight: '12px !important',
	},
	MUISelectIconOutlined: {
		right: 0,
	},
	listSubheaderGrouping: {
		fontWeight: 800,
		// fontSize: '1rem',
		paddingLeft: theme.spacing(1),
	},
}))(
	({
		classes,
		label,
		value,
		onChange,
		noneOption,
		noneMsg,
		options = {},
		smallMenu,
		smallIcon,
		...rest
	}) => {
		return (
			<StyledOutlinedTextField
				select
				label={label}
				value={value}
				onChange={onChange}
				SelectProps={{
					classes: {
						selectMenu: smallMenu ? classes.textFieldSelectMenu : null,
						iconOutlined: smallIcon ? classes.MUISelectIconOutlined : null,
					},
					// inputProps: { tabIndex: null },
				}}
				{...rest}
			>
				{noneOption && (
					<MenuItem value="" className={classes.selectMenuItem}>
						<em>
							{noneMsg ? (
								noneMsg
							) : (
								<FormattedMessage id="pages.Artwork.components.CustomMUI.noneMsg" />
							)}
						</em>
					</MenuItem>
				)}
				{Object.keys(options)
					.map((headerName) => [
						<ListSubheader
							className={classes.listSubheaderGrouping}
							disableSticky
							disableGutters
							key={`header-${headerName}`}
						>
							{headerName}
						</ListSubheader>,
						...(options[headerName] || []).map((option) => (
							<MenuItem
								key={option.value || option.id}
								value={option.value || option.id}
								className={classes.selectMenuItem}
								disabled={Boolean(option.disabled)}
							>
								{option.label}
							</MenuItem>
						)),
					])
					.flat()}
			</StyledOutlinedTextField>
		);
	}
);

export const SimpleSwitchRow = withStyles((theme) => ({
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
	},
	groupRowLabel: {
		width: '70%',
	},
}))(({ classes, style, label, checked, onChange, SwitchProps, ...rest }) => {
	return (
		<div className={classes.groupRowDiv} style={style} {...rest}>
			<span className={classes.groupRowLabel}>{label}</span>
			<Switch
				color="primary"
				size="small"
				checked={checked}
				onChange={(e) => (onChange ? onChange(e, e.target.checked) : null)}
				onMouseDown={(e) => e.preventDefault()}
				{...SwitchProps}
			/>
		</div>
	);
});

export const SimpleSwitchVer = withStyles((theme) => ({
	MUISwitchVerDiv: {
		color: 'inherit',
		fontSize: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0px 2px',
	},
	MUIRadioNoPadding: {
		color: 'inherit',
		fontSize: 'inherit',
		padding: 0,
	},
	label: {
		textAlign: 'center',
	},
}))(({ classes, textClassName, style, label, checked, onChange, SwitchProps, ...rest }) => {
	return (
		<div className={classes.MUISwitchVerDiv} style={style} {...rest}>
			<Switch
				color="primary"
				size="small"
				checked={checked}
				onChange={(e) => (onChange ? onChange(e, e.target.checked) : null)}
				onMouseDown={(e) => e.preventDefault()}
				{...SwitchProps}
			/>
			<span className={cx(classes.label, textClassName)}>{label}</span>
		</div>
	);
});

export const SimpleRadioVer = withStyles((theme) => ({
	MUIRadioVerDiv: {
		color: 'inherit',
		fontSize: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0px 2px',
	},
	MUIRadioNoPadding: {
		color: 'inherit',
		fontSize: 'inherit',
		padding: 0,
	},
}))(({ classes, textClassName, checked, onChange, value, label }) => {
	return (
		<div className={classes.MUIRadioVerDiv}>
			<Radio
				className={classes.MUIRadioNoPadding}
				checked={checked}
				onChange={onChange}
				value={value}
				name={`radio-button-${value}`}
				inputProps={{ 'aria-label': `radio-button-${value}` }}
				size="small"
			/>
			<span className={textClassName}>{label}</span>
		</div>
	);
});

// export const SimpleNumberSlider = React.memo(
export const SimpleNumberSlider = withStyles((theme) => ({
	label: { paddingRight: 8, width: 90, textAlign: 'left' },
	sliderWrapper: { width: `calc(100% - 90px)`, display: 'inline-flex', alignItems: 'center' },
	sliderInput: {
		margin: `0px ${theme.spacing(1)}`,
		width: '30%',
		fontSize: 'inherit',
		flex: '1 0 auto',
	},
	inputTextAlign: {
		textAlign: 'center',
	},
}))(({ classes, labelStyle, label, value, handleOnChange, step, min, max, unit, disableInput }) => {
	const [inputValue, setInputValue] = React.useState(value);
	React.useEffect(() => {
		setInputValue(value);
	}, [value]);
	return (
		<React.Fragment>
			<span className={classes.label} style={labelStyle}>
				{label}
			</span>
			<div className={classes.sliderWrapper}>
				<Slider
					value={Number(inputValue || 0)}
					size="small"
					onChange={(e, spacing) => setInputValue(spacing)}
					onChangeCommitted={(e, spacing) => handleOnChange(spacing)}
					// aria-labelledby="spacing-slider"
					step={step}
					min={min}
					max={max}
				/>
				<Input
					className={classes.sliderInput}
					disabled={disableInput}
					value={inputValue}
					margin="dense"
					classes={{
						input: classes.inputTextAlign,
					}}
					onBlur={
						!disableInput
							? (e) => {
									let val = Number(e.target.value || 0);
									if (val < min) {
										val = min;
									} else if (val > max) {
										val = max;
									}
									handleOnChange(val);
									setInputValue(val);
							  }
							: null
					}
					inputProps={!disableInput ? { step: step, min: min, max: max, type: 'number' } : {}}
					// onChange={!disableInput ? e => handleOnChange(Number(e.target.value)) : null}
					onChange={!disableInput ? (e) => setInputValue(e.target.value) : null}
				/>
				{unit}
			</div>
		</React.Fragment>
	);
});
// );

export const DividerVer = withStyles((theme) => ({
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
}))(({ classes, ...rest }) => {
	return <Divider orientation="vertical" className={classes.dividerVertical} {...rest} />;
});

export const DividerHor = withStyles((theme) => ({
	fullWidth: {
		width: '100%',
	},
}))(({ classes, ...rest }) => {
	return <Divider className={classes.fullWidth} {...rest} />;
});

export const StyledContainedButton = withStyles((theme) => ({
	button: {
		margin: theme.spacing(1),
		backgroundColor: blueGrey[600],
		color: grey[200], //'#ffffff',
		'&:hover': {
			backgroundColor: blueGrey[700],
		},
	},
}))(({ classes, /* startIcon, endIcon, */ label, ...rest }) => {
	return (
		<Button
			variant="contained"
			// color="primary"
			className={classes.button}
			// startIcon={startIcon}
			// endIcon={endIcon}
			{...rest}
		>
			{label}
		</Button>
	);
});

const customMUIComps = {
	StyledTabs,
	StyledTab,
	StyledToggleButtonGroup,
	ControlledStyledOutlinedTextField,
	StyledOutlinedTextField,
	SimplifiedOutlinedTextField,
	StyledOutlinedTextFieldSelection,
	SimpleSwitchRow,
	SimpleSwitchVer,
	SimpleRadioVer,
	SimpleNumberSlider,
	StyledOutlinedSelectionWithSubheader,
	OutlinedNumberTextField,
	OutlinedInputWithSelect,
	DividerVer,
	DividerHor,
	StyledContainedButton,
};

export default customMUIComps;
