import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import { CreatableSelection } from 'components';
import { _ } from 'utils/libHelper';
import { roundDecimals } from 'utils/generalHelper';
// MUI components
import { InputLabel } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';

import {
	FormatAlignLeft as FormatAlignLeftIcon,
	FormatAlignCenter as FormatAlignCenterIcon,
	FormatAlignRight as FormatAlignRightIcon,
} from '@mui/icons-material';

import {
	FormatVerticalAlignTop as FormatVerticalAlignTopIcon,
	FormatVerticalAlignCenter as FormatVerticalAlignCenterIcon,
	FormatVerticalAlignBottom as FormatVerticalAlignBottomIcon,
} from 'mdi-material-ui';
// Constants
// import { ART_VARIABLES } from '../../../Constants';

import {
	StyledToggleButtonGroup,
	// StyledOutlinedTextFieldSelection,
	// StyledOutlinedSelectionWithSubheader,
	DividerVer,
	DividerHor,
	// StyledContainedButton,
} from '../../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

// CSS
const useStyles = makeStyles((theme) => ({
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	twoColumnsRowDiv: {
		justifyContent: 'space-evenly',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	dividerVertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
	},
	imageSizing: {
		width: '70%',
		margin: 0,
	},
	alignUserDefinedFormControlLabel: {
		margin: 0,
	},
	alignUserDefinedFormControlLabelText: {
		fontSize: 'inherit',
		textAlign: 'center',
	},
	alignUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
	toggleGroupSmall: {
		width: 30,
		height: 30,
	},
	creatableSelectionContainer: {
		margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
	},
}));

const updateFieldsPosition = (fields, alignment) => {
	switch (alignment) {
		case 'left': {
			// horizontal left. find the most left position
			let mostLeftField = fields.reduce((accu, elem) => {
				if (elem.position.left < accu.left) {
					return elem.position;
				} else {
					return accu;
				}
			}, fields[0].position);
			return fields.map((f) => {
				f.position.left = mostLeftField.left;
				return f;
			});
		}
		case 'center': {
			// horizontal center. find the bound position of the fields
			let boundPosition = fields.reduce(
				(accu, elem) => {
					return {
						leftX: Math.min(accu.leftX, elem.position.left),
						rightX: Math.max(accu.rightX, elem.position.left + elem.position.width),
					};
				},
				{ leftX: fields[0].position.left, rightX: 0 }
			);
			// find x coordination of the middle point of the bound box
			let middleXOfBound = boundPosition.leftX + (boundPosition.rightX - boundPosition.leftX) / 2;
			return fields.map((f) => {
				f.position.left = roundDecimals(
					f.position.left + (middleXOfBound - (f.position.left + f.position.width / 2)),
					2
				);
				return f;
			});
		}
		case 'right': {
			// horizontal right. find the most right position
			let mostRightField = fields.reduce((accu, elem) => {
				if (elem.position.left + elem.position.width > accu.left + accu.width) {
					return elem.position;
				} else {
					return accu;
				}
			}, fields[0].position);
			return fields.map((f) => {
				f.position.left = mostRightField.left + mostRightField.width - f.position.width;
				return f;
			});
		}

		case 'top': {
			// vertical top. find the most top field position
			let mostLeftField = fields.reduce((accu, elem) => {
				if (elem.position.top < accu.top) {
					return elem.position;
				} else {
					return accu;
				}
			}, fields[0].position);
			return fields.map((f) => {
				f.position.top = mostLeftField.top;
				return f;
			});
		}
		case 'middle': {
			// vertical middle. find the bound position of the fields
			let boundPosition = fields.reduce(
				(accu, elem) => {
					return {
						topY: Math.min(accu.topY, elem.position.top),
						bottomY: Math.max(accu.bottomY, elem.position.top + elem.position.height),
					};
				},
				{ topY: fields[0].position.top, bottomY: 0 }
			);
			// find y coordination of the middle point of the bound box
			let middleYOfBound = boundPosition.topY + (boundPosition.bottomY - boundPosition.topY) / 2;
			return fields.map((f) => {
				f.position.top = roundDecimals(
					f.position.top + (middleYOfBound - (f.position.top + f.position.height / 2)),
					2
				);
				return f;
			});
		}
		case 'bottom': {
			// vertical bottom. find the most bottom field's position
			let mostBottomField = fields.reduce((accu, elem) => {
				if (elem.position.top + elem.position.height > accu.top + accu.height) {
					return elem.position;
				} else {
					return accu;
				}
			}, fields[0].position);
			return fields.map((f) => {
				f.position.top = mostBottomField.top + mostBottomField.height - f.position.height;
				return f;
			});
		}
		default:
			return fields;
	}
};

function GroupPalette({
	fields,
	updateFields,
	handleNewGroup,
	/* handleDelGroup, */ groups,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const [groupedFieldsData, setGroupedFieldsData] = React.useState({
		// groupName: '',
		horizontalAlign: '',
		verticalAlign: '',
		animation: {
			entrance: '',
			delay: null,
			duration: null,
			exit: '',
		},
	});
	return (
		<div className={classes.tabWrapper}>
			<CreatableSelection
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GENERAL.groupText',
				})}
				value={groupedFieldsData.groupName || ''}
				options={groups.map((groupName) => ({ value: groupName, label: groupName }))}
				isClearable={false}
				outlined
				className={classes.creatableSelectionContainer}
				// handleDelOption={handleDelGroup}
				handleCreation={(groupName) => {
					handleNewGroup(groupName);
					updateFields(
						fields.map((f) => {
							f.groupName = groupName;
							return f;
						})
					);
					setGroupedFieldsData({ ...groupedFieldsData, groupName: groupName });
				}}
				handleSelection={(groupName) => {
					updateFields(
						fields.map((f) => {
							f.groupName = groupName;
							return f;
						})
					);
					setGroupedFieldsData({ ...groupedFieldsData, groupName: groupName });
				}}
			/>
			<DividerHor />

			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.alignmentText',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledToggleButtonGroup
						value={groupedFieldsData.horizontalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={(e, alignment) => {
							if (!alignment) return;
							updateFields(updateFieldsPosition(fields, alignment));
							setGroupedFieldsData({ ...groupedFieldsData, horizontalAlign: alignment });
						}}
						aria-label="image horizontal alignment"
					>
						<ToggleButton value="left" aria-label="left aligned">
							<FormatAlignLeftIcon />
						</ToggleButton>
						<ToggleButton value="center" aria-label="centered horizontal">
							<FormatAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="right" aria-label="right aligned">
							<FormatAlignRightIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
					<DividerVer />
					<StyledToggleButtonGroup
						value={groupedFieldsData.verticalAlign || null}
						exclusive
						classes={{
							grouped: classes.toggleGroupSmall,
						}}
						size="small"
						onChange={(e, alignment) => {
							if (!alignment) return;
							updateFields(updateFieldsPosition(fields, alignment));
							setGroupedFieldsData({ ...groupedFieldsData, verticalAlign: alignment });
						}}
						aria-label="image vertical alignment"
					>
						<ToggleButton value="top" aria-label="top aligned">
							<FormatVerticalAlignTopIcon />
						</ToggleButton>
						<ToggleButton value="middle" aria-label="middle horizontal">
							<FormatVerticalAlignCenterIcon />
						</ToggleButton>
						<ToggleButton value="bottom" aria-label="bottpm aligned">
							<FormatVerticalAlignBottomIcon />
						</ToggleButton>
					</StyledToggleButtonGroup>
				</div>
			</div>
			{
				// 	<DividerHor />
				// 	<div className={classes.groupsDiv}>
				// 	<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
				// 		{intl.formatMessage({
				// 			id: 'pages.Artwork.components.ElementPalette.GENERAL.animationTitle',
				// 		})}
				// 	</InputLabel>
				// 	{[
				// 		{
				// 			// value: groupedFieldsData.animation.entrance || '',
				// 			label: intl.formatMessage({
				// 				id: 'pages.Artwork.components.ElementPalette.GENERAL.animationEntranceText',
				// 			}),
				// 			noneOption: true,
				// 			options: ART_VARIABLES.animationEntranceOptions,
				// 			Comp: StyledOutlinedSelectionWithSubheader,
				// 			animationKey: 'entrance',
				// 			isNumber: false,
				// 		},
				// 		{
				// 			// value: groupedFieldsData.animation.delay || '',
				// 			label: intl.formatMessage({
				// 				id: 'pages.Artwork.components.ElementPalette.GENERAL.animationDelayAfterText',
				// 			}),
				// 			noneOption: false,
				// 			options: _.range(0.0, 10.1, 0.1).map(delay => ({
				// 				label: `${delay.toFixed(1)}s`,
				// 				value: delay.toFixed(1),
				// 			})),
				// 			Comp: StyledOutlinedTextFieldSelection,
				// 			animationKey: 'delay',
				// 			isNumber: true,
				// 		},
				// 		{
				// 			// value: groupedFieldsData.animation.duration || '',
				// 			label: intl.formatMessage({
				// 				id: 'pages.Artwork.components.ElementPalette.GENERAL.animationDurationText',
				// 			}),
				// 			noneOption: false,
				// 			options: _.range(0.0, 5.1, 0.1).map(duration => ({
				// 				label: `${duration.toFixed(1)}${intl.formatMessage({
				// 					id: 'pages.Artwork.components.GENERAL.secondShort',
				// 				})}`,
				// 				value: duration.toFixed(1),
				// 			})),
				// 			Comp: StyledOutlinedTextFieldSelection,
				// 			animationKey: 'duration',
				// 			isNumber: true,
				// 		},
				// 		{
				// 			// value: groupedFieldsData.animation.exit || '',
				// 			label: intl.formatMessage({
				// 				id: 'pages.Artwork.components.ElementPalette.GENERAL.animationExitText',
				// 			}),
				// 			noneOption: true,
				// 			options: ART_VARIABLES.animationExitOptions,
				// 			Comp: StyledOutlinedSelectionWithSubheader,
				// 			animationKey: 'exit',
				// 			isNumber: false,
				// 		},
				// 	]
				// 		.map(item => {
				// 			return (
				// 				<div className={cx(classes.groupRowDiv)} key={item.label}>
				// 					<span style={{ paddingRight: 8, width: 90, textAlign: 'left' }}>{item.label}</span>
				// 					<div style={{ width: `calc(100% - 90px)`, display: 'inline-flex' }}>
				// 						<item.Comp
				// 							fullWidth
				// 							select
				// 							label=""
				// 							value={groupedFieldsData.animation[item.animationKey] || ''}
				// 							onChange={e => {
				// 								setGroupedFieldsData({
				// 									...groupedFieldsData,
				// 									animation: {
				// 										...groupedFieldsData.animation,
				// 										[item.animationKey]: e.target.value || '',
				// 									},
				// 								});
				// 								updateFields(
				// 									fields.map(f => {
				// 										f.animation = {
				// 											...f.animation,
				// 											[item.animationKey]: item.isNumber
				// 												? Number(e.target.value)
				// 												: e.target.value || '',
				// 										};
				// 										return f;
				// 									})
				// 								);
				// 							}}
				// 							noneOption={item.noneOption}
				// 							options={item.options}
				// 						/>
				// 					</div>
				// 				</div>
				// 			);
				// 		})
				// 		.reduce((accu, elem, idx) => {
				// 			return accu.length === 0 ? [elem] : [...accu, <DividerHor key={idx} />, elem];
				// 		}, [])}
				// </div>
			}
		</div>
	);
}

GroupPalette.propTypes = {
	fields: PropTypes.array.isRequired,
	updateFields: PropTypes.func.isRequired,
	handleNewGroup: PropTypes.func.isRequired,
	// handleDelGroup: PropTypes.func.isRequired,
	groups: PropTypes.array.isRequired,
};

GroupPalette.defaultProps = {};
export default GroupPalette;
