// ##############################
// // // Constants
// #############################
import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	startOfWeek,
	subMonths,
	subYears,
} from 'date-fns';

export const VIDEO_FILE_TYPES = ['MP4', 'MOV', 'AVI', 'M4V', 'MPEG', 'MPG', 'VIDEO'];
export const TABLE_VIEW = 'TABLE';
export const GRID_VIEW = 'GRID';
export const MIN_EDIT_UL = 35; // minimum user-level that is allowed to edit data file (inclusive)
export const FILTER_GROUP_MIN_UL = 40; // min user-level to apply filters groups.
export const CATEGORY_QUERY_PARAM = 'categories';
export const MEDIAFILEID_QUERY_PARAM = 'mediafileIds';
export const LOW_RES_IMAGE_DOWNLOAD_SIZE = 'lores'; // used to download the low-res-image in mediafile detail page

const datetimeRanges = [
	{
		label: 'Today',
		range: () => ({
			startDate: startOfDay(new Date()),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Yesterday',
		range: () => ({
			startDate: startOfDay(addDays(new Date(), -1)),
			endDate: endOfDay(addDays(new Date(), -1)),
		}),
	},
	{
		label: 'This Week',
		range: () => ({
			startDate: startOfWeek(new Date()),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'This Month',
		range: () => ({
			startDate: startOfMonth(new Date()),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 3 Months',
		range: () => ({
			startDate: subMonths(new Date(), 3),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 6 Months',
		range: () => ({
			startDate: subMonths(new Date(), 6),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 1 year',
		range: () => ({
			startDate: subYears(new Date(), 1),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 3 years',
		range: () => ({
			startDate: subYears(new Date(), 3),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 5 years',
		range: () => ({
			startDate: subYears(new Date(), 5),
			endDate: endOfDay(new Date()),
		}),
	},
];
/**
 * Applicable filters.
 * NB:
 * 	- Each section coresponds to a filter in the API endpoint
 * 	- title acts as the key of each section, must be unique
 * Format:
	[
		{title: 'SECTION_TITLE', type: 'checkbox|switch', options: [{label: 'xxx', value: 'xxx'}]},
		{title: 'SECTION_TITLE', type: 'checkbox|switch', options: [{label: 'xxx', value: 'xxx'}]},
	]
 */
export const FILTERS = [
	{
		title: 'Editable Template',
		searchableKey: 'isArtworkTemplate',
		type: 'switch',
		options: [],
	},
	{
		title: 'Social Media',
		searchableKey: 'isSocialMediaEnabled',
		type: 'switch',
		options: [],
	},
	{
		title: 'Digital Screen',
		searchableKey: 'isScreenEnabled',
		type: 'switch',
		options: [],
	},
	{
		title: 'Orderable',
		searchableKey: 'isOrderable',
		type: 'switch',
		options: [],
	},
	{
		title: 'Has Animation',
		searchableKey: 'hasAnimation',
		type: 'switch',
		options: [],
	},
	{
		title: 'Esign',
		searchableKey: 'isEsign',
		type: 'switch',
		options: [],
	},
	{
		title: 'File types',
		searchableKey: 'fileTypeGroup',
		type: 'checkbox',
		width: 130, // to make the alignment of each option item
		options: [
			{
				label: 'Image',
				value: 'image',
			},
			{
				label: 'Video',
				value: 'video',
			},
			{
				label: 'Animation',
				value: 'animation',
			},
			{
				label: 'Audio',
				value: 'audio',
			},
			{
				label: 'MS Office',
				value: 'msoffice',
			},
			{
				label: 'PDF',
				value: 'pdf',
			},
			// {
			// 	label: 'Artwork Template',
			// 	value: 'template',
			// },
		],
	},
	{
		title: 'File status',
		searchableKey: 'fileStatuses',
		type: 'checkbox',
		width: 150, // to make the alignment of each option item
		options: [
			{
				label: 'Downloadable',
				value: 'live',
			},
			{
				label: 'Not Downloadable',
				value: 'notlive',
			},
			{
				label: 'Archived',
				value: 'archived',
			},
			{
				label: 'Pending',
				value: 'pending',
			},
			{
				label: 'Newly Added',
				value: 'new',
			},
			{
				label: 'Not Set',
				value: 'notset',
			},
			{
				label: 'Rejected',
				value: 'rejected',
			},
		],
	},
	{
		title: 'Social Media File types',
		searchableKey: 'fileTypeGroupSocialMedia',
		type: 'checkbox',
		width: 130, // to make the alignment of each option item
		options: [
			{
				label: 'Image',
				value: 'image',
			},
			{
				label: 'Video',
				value: 'video',
			},
		],
	},
	// {
	// 	title: 'Extensions',
	// 	searchableKey: 'fileExt',
	// 	type: 'select',
	// 	width: 110, // to make the alignment of each option item
	// 	options: config.supportedFileTypes.split(',').map((type) => {
	// 		let typeExt = type.trim().substring(1).toUpperCase();
	// 		return {
	// 			label: typeExt,
	// 			value: typeExt,
	// 		};
	// 	}),
	// },
	{
		title: 'Orientation',
		searchableKey: 'orientation',
		type: 'checkbox',
		width: 140, // to make the alignment of each option item
		options: [
			{
				label: 'Landscape',
				value: 'landscape',
			},
			{
				label: 'Portrait',
				value: 'portrait',
			},
			{
				label: 'Square',
				value: 'square',
			},
		],
	},
	{
		title: 'Print Dimensions',
		searchableKey: 'paperSize',
		type: 'select',
		width: 160, // to make the alignment of each option item
		options: [
			{
				label: 'A0',
				value: 'A0',
			},
			{
				label: 'A1',
				value: 'A1',
			},
			{
				label: 'A2',
				value: 'A2',
			},
			{
				label: 'A3',
				value: 'A3',
			},
			{
				label: 'A4',
				value: 'A4',
			},
			{
				label: 'A5',
				value: 'A5',
			},
			{
				label: 'A6',
				value: 'A6',
			},
			{
				label: 'A7',
				value: 'A7',
			},
			{
				label: 'SEL',
				value: 'SEL',
			},
			{
				label: 'Business Card',
				value: 'businessCard',
			},
			{
				label: 'Shelf Talker',
				value: 'shelfTalker',
			},
			{
				label: 'DL',
				value: 'DL',
			},
			{
				label: 'US Legal',
				value: 'USlegal',
			},
			{
				label: 'US Letter',
				value: 'USletter',
			},
			{
				label: '10x15',
				value: '10x15',
			},
			{
				label: '20x30',
				value: '20x30',
			},
			{
				label: '30x40',
				value: '30x40',
			},
		],
	},
	{
		title: 'Screen Dimensions',
		searchableKey: 'screenSize',
		type: 'select',
		width: 160, // to make the alignment of each option item
		options: [
			{
				label: 'HD Landscape',
				value: 'HD Landscape',
			},
			{
				label: 'HD Portrait',
				value: 'HD Portrait',
			},
			{
				label: 'Stretch Landscape',
				value: 'Stretch Landscape',
			},
			{
				label: 'Stretch Portrait',
				value: 'Stretch Portrait',
			},
			{
				label: 'Square',
				value: 'Square',
			},
		],
	},
	{
		title: 'Social Media Dimensions',
		searchableKey: 'socialMediaSize',
		type: 'select',
		width: 160, // to make the alignment of each option item
		options: [
			{
				label: 'Instagram Landscape',
				value: 'Instagram Landscape',
			},
			{
				label: 'Instagram Portrait',
				value: 'Instagram Portrait',
			},
			{
				label: 'Instagram Square',
				value: 'Instagram Square',
			},
			{
				label: 'Instagram Stories and Reels',
				value: 'Instagram Stories and Reels',
			},
			{
				label: 'Facebook Landscape',
				value: 'Facebook Landscape',
			},
			{
				label: 'Facebook Portrait',
				value: 'Facebook Portrait',
			},
			{
				label: 'Facebook Square',
				value: 'Facebook Square',
			},
			{
				label: 'Facebook Stories and Reels',
				value: 'Facebook Stories and Reels',
			},
			{
				label: 'X Landscape',
				value: 'X Landscape',
			},
			{
				label: 'X Portrait',
				value: 'X Portrait',
			},
			{
				label: 'X Square',
				value: 'X Square',
			},
			{
				label: 'LinkedIn Landscape',
				value: 'LinkedIn Landscape',
			},
			{
				label: 'LinkedIn Portrait',
				value: 'LinkedIn Portrait',
			},
			{
				label: 'LinkedIn Square',
				value: 'LinkedIn Square',
			},
			{
				label: 'TikTok Landscape',
				value: 'TikTok Landscape',
			},
			{
				label: 'TikTok Portrait',
				value: 'TikTok Portrait',
			},
			{
				label: 'TikTok Square',
				value: 'TikTok Square',
			},
			{
				label: 'TikTok Stories',
				value: 'TikTok Stories',
			},
			{
				label: 'Landscape 4:5',
				value: 'Landscape 4:5',
			},
			{
				label: 'Portrait 5:4',
				value: 'Portrait 5:4',
			},
			{
				label: 'Square 1:1',
				value: 'Square 1:1',
			},
			{
				label: 'Landscape 16:9',
				value: 'Landscape 16:9',
			},
			{
				label: 'Portrait 9:16',
				value: 'Portrait 9:16',
			},
		],
	},
	{
		title: 'Social Media Channels',
		searchableKey: 'socialMediaType',
		type: 'checkbox',
		width: 160, // to make the alignment of each option item
		options: [
			{
				label: 'Facebook',
				value: 'Facebook',
			},
			{
				label: 'Instagram',
				value: 'Instagram',
			},
		],
	},
	{
		title: 'Last Modified',
		searchableKey: 'updatedDatetime',
		type: 'datetime',
		options: datetimeRanges,
	},
];

export const FILTERS_SETTINGS_MAP = {
	bulkSearchField: 'bulkSearchField',
	isArtworkTemplate: 'enableArtwork',
	isScreenEnabled: 'enableScreens',
	isOrderable: 'enableShoppingCart',
	isSocialMediaEnabled: 'enableSocialMedia',
	isEsign: 'enableEsign',
	// paperSize: 'hasDimensions',
	paperSize: 'hasPrintDimensionsFilter',
	screenSize: 'hasScreenDimensionsFilter',
	socialMediaSize: 'hasSocialMediaDimensionsFilter',
	socialMediaType: 'hasSocialMediaTypeFilter',
	fileTypeGroupSocialMedia: 'hasFileTypeGroupSocialMediaFilter',
	fileTypeGroup: 'hasFileTypeGroupFilter',
	hasAnimation: 'enableScreens',
	fileStatuses: 'hasFileStatusFilter',
	updatedDatetime: 'hasLastModifiedFilter',
	orientation: 'hasOrientationFilter',
};
