// ##############################
// // // Screen Slides styles
// #############################
import { grey } from '@mui/material/colors';

const SlidesStyle = (theme) => ({
	contentWrapper: {
		...theme.contentWrapper,
	},
	header: {
		height: 50,
		width: '100%',
		display: 'flex',
		marginBottom: 15,
		// justifyContent: 'center',
		alignItems: 'center',
		// position: 'relative',
		backgroundColor: grey[100],
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			height: 'unset',
			flexWrap: 'wrap',
			justifyContent: 'flex-start',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	title: {
		width: '60%',
		marginLeft: 20,
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			width: 'unset',
			marginLeft: 8,
		},
		// marginTop: 'auto',
		// marginBottom: 'auto'
		// position: 'absolute',
		// top: '50%',
		// transform: 'translateY(-50%)'
	},
	search: {
		width: '40%',
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%',
			marginBottom: 8,
		},
	},
	filterInput: {
		margin: theme.spacing(1),
		overflow: 'unset',
	},
	addFab: {
		margin: theme.spacing(1),
	},
	// textField: {
	// 	marginLeft: theme.spacing(1),
	// 	marginRight: theme.spacing(1),
	// 	width: '90%',
	// },
	table: {
		marginBottom: '0',
		width: '100%',
		maxWidth: '100%',
		maxHeight: '90%',
		color: 'black',
		backgroundColor: 'transparent',
		borderSpacing: '0',
		borderCollapse: 'collapse',
		overflow: 'inherit !important',
	},
	tableHeadCell: {
		color: 'inherit',
		// ...defaultFont,
		fontWeight: 600,
		height: 50,
		fontSize: '1.1em',
		alignItems: 'center',
	},
	// tableCell: {
	// 	// ...defaultFont,
	// 	lineHeight: '1.42857143',
	// 	// padding: '12px 8px',
	// 	// verticalAlign: 'middle',
	// 	// textAligh: 'center',
	// 	margin: 'auto',
	// 	// maxWidth: '100px',
	// 	maxHeight: '145px',
	// 	minHeight: '135px',
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	whiteSpace: 'unset',
	// 	// height: '120px',
	// 	wordWrap: 'break-word',
	// 	fontSize: '13px'
	// },
	// tableResponsive: {
	// 	width: '100%',
	// 	marginTop: theme.spacing(1) * 3,
	// 	overflowX: 'auto'
	// },
	previewDialogContent: {
		position: 'relative',
		height: '75vh',
	},
	editingInput: {
		margin: theme.spacing(1),
		width: '90%',
	},
	addingTextField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '90%',
	},
	addingFormControl: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(1 * 2),
		width: '90%',
	},
	addingButton: {
		margin: theme.spacing(1),
		left: '35%',
	},
	rightIconInButton: {
		marginLeft: theme.spacing(1),
	},
	radioGroup: {
		marginLeft: theme.spacing(1),
	},
	checkboxInput: {
		textAlign: 'center',
	},
	alignUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
	scheduleButton: {
		marginLeft: theme.spacing(0.5),
		height: 36,
		width: 36,
	},
	bulkScheduleDialog: {
		display: 'flex',
		// marginBottom: 15,
		justifyContent: 'center',
		alignItems: 'center',
	},
	dialogTitle: {
		margin: 0,
		padding: theme.spacing(2),
		cursor: 'move',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

export default SlidesStyle;
