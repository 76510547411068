import React from 'react';

import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { TablePagination } from '@mui/material';
import TablePaginationActions from './TablePaginationActions';

const useStyles = makeStyles((theme) => ({
	// pagination: {
	// 	// height: '100%',
	// },
	paginationToleft: {
		// set flex to "none" so that the whole pagination flows to left
		flex: 'none',
	},
}));

const DEFAULT_ROW_PER_PAGE_OPTIONS = [10, 25, 50, 100];

function MUITablePagination({
	rowsPerPage,
	totalNumRows,
	pageIndex,
	disableRowsPerPage,
	handleChangePage,
	handleChangeRowsPerPage,
	labelRowsPerPage,
	...rest
}) {
	const classes = useStyles();
	return (
		<TablePagination
			// className={classes.pagination}
			classes={{ spacer: classes.paginationToleft }}
			rowsPerPageOptions={
				Array.from(new Set(DEFAULT_ROW_PER_PAGE_OPTIONS).add(rowsPerPage))
				// { label: rowsPerPage.toString(), value: rowsPerPage },
			}
			labelRowsPerPage={labelRowsPerPage}
			component="div"
			count={totalNumRows}
			rowsPerPage={rowsPerPage}
			page={pageIndex}
			SelectProps={{ disabled: disableRowsPerPage }}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
			ActionsComponent={TablePaginationActions}
		/>
	);
}

MUITablePagination.propTypes = {
	/**
	 * function(event: object, page: number [0-indexed]) => void
	 */
	handleChangePage: PropTypes.func.isRequired,
	handleChangeRowsPerPage: PropTypes.func.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	disableRowsPerPage: PropTypes.bool,
	pageIndex: PropTypes.number.isRequired,
	/**
	 * Total number of rows
	 * NB: this component only supports server-side pagination
	 */
	totalNumRows: PropTypes.number.isRequired,
	labelRowsPerPage: PropTypes.node,
};

MUITablePagination.defaultProps = { labelRowsPerPage: 'Rows per page:' };

export default MUITablePagination;
