// ##############################
// // // Constants
// #############################
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { blueGrey, grey } from '@mui/material/colors';
import { default as artworkAnimations } from './animista.js';
import { default as keyCodes } from './keyCodes.js';
export const DESIGN_MIN_UL = 30; // exclusive, should be > 30
export const ESIGN_PROOF_MIN_UL = 50; // inclusive, >= 50. min user level to access Esign proof btn
export const ESIGN_ADMIN_MIN_UL = 40; // inclusive, >=40. min user level to access Esign Admin funcs
export const DesignContext = React.createContext({});

/**
 * ARTWORK_ANIMATIONS structure
 * {
 * 	'@keyframes bounce-in-bck': {...},
 * 	'@keyframes bounce-in-bottom': {...},
 * 	...
 * }
 */
let ARTWORK_ANIMATIONS = {};
for (let key in artworkAnimations) {
	ARTWORK_ANIMATIONS = { ...ARTWORK_ANIMATIONS, ...artworkAnimations[key] };
}

/**
 * ANIMATION_OPTIONS structure
 * {
 * animationGroupName1: [{value: animationName, label: animationName}, {value: animationName, label: animationName}, ...],
 * animationGroupName2: [{value: animationName, label: animationName}, {value: animationName, label: animationName}, ...]
 * ...
 * }
 */
let ANIMATION_ENTRANCE_OPTIONS = {},
	ANIMATION_EXIT_OPTIONS = {};
Object.keys(artworkAnimations).forEach((animationGroupName) => {
	if (animationGroupName.indexOf('Exit') === -1) {
		// Entrance animation
		ANIMATION_ENTRANCE_OPTIONS[animationGroupName] = Object.keys(
			artworkAnimations[animationGroupName]
		).map((animationName) => ({
			value: animationName.replace('@keyframes ', '').trim(),
			label: animationName.replace('@keyframes ', '').trim(),
		}));
	} else {
		// Exit animation
		ANIMATION_EXIT_OPTIONS[animationGroupName] = Object.keys(
			artworkAnimations[animationGroupName]
		).map((animationName) => ({
			value: animationName.replace('@keyframes ', '').trim(),
			label: animationName.replace('@keyframes ', '').trim(),
		}));
	}
});

const DEFAULT_FONTSIZE = 30; // in pdf pt (300dpi)
const defaultFont = 'HelveticaNeueLTStd-Lt.otf';
const defaultFontpath = 'https://animations.visualid.com/_fonts/HelveticaNeueLTStd-Lt.otf';
const generalFieldProps = {
	id: '',
	name: '',
	position: { left: 0, top: 0, width: 10, height: 10, angle: 0 },
	imageRepositioning: true, // only for image field
	groupName: '',
	// zIndex: 0, // We use the order in the field array as the display order
	helperText: '',
	hideInput: false,
	hideOutput: false,
	outputDependsOn: '', // value is field ID
	animation: {
		entrance: '',
		delay: null,
		duration: null,
		exit: '',
	},
	// we include insertionOnVideo in all fields as all fields in theory can be supported (except video),
	// but weather or not using it is controlled by supportedFieldTypesInVideoArtwork
	insertionOnVideo: {
		// The timecode (from the beginning of the video, ZEROBASED) of the insertion that you want the overlay to first appear on
		// HH:MM:SS:FF (hour:min:sec:NoOfFrames). if '', it starts from beginning of video (zero).
		startTime: '',
		// the time, in milliseconds, for this field to remain on the output video.
		// This duration includes entrance time but not exit time.
		// if 0, it will never exit
		// if fadeIn is greater than duration, duration time is used for fade-in
		duration: 0,
		// the length of time, in milliseconds, between startTime to it fully appears on the video.
		// if 0, it appears immediately after startTime
		fadeIn: 0,
		// the length of time, in milliseconds, between the end of time to it fully exits on the video.
		// if 0, it exists immediately
		fadeOut: 0,
	},
};
const defaultTextAutoImportMeta = {
	basedOn: '', // field id
	// spreadsheetImageColumn: '',
	spreadsheetCell: null, // spreadsheet column index. Number (integer) type
	esignEditable: false,
	esignSearchable: false,
	esignFilter: false,
};
const DEFAULT_CALC_VALUE = {
	price: '', // field Id
	unit: '', // field Id
	qty: '', // field Id
	per: '',
	hide: false,
	multipleWeightsMsg: '',
};
const defaultTextPredefinedValue = {
	type: '', // options from "predefinedValueOptions". possible values: list, spreadsheet
	from: null, // list id if type is "list", or spreadsheet id if type is "spreadsheet"
	fromColumn: null, // spreadsheet column id (Note, no "fromColumn" if type is "list")
	// isEsignTemplate: false,
	// isEsignOnly: false,
	listAllowOther: false, // for list only. allow "other" or not
	// outputTemplate: null, // for spreadsheet only.
	// alternativeTemplateId: '',
};
const defaultTextField = {
	// general
	...generalFieldProps,
	// id: '',
	// name: '',
	type: 'text',
	// position: { left: 0, top: 0, width: 10, height: 10, angle: 0 },
	// groupName: '',
	// // zIndex: 0, // We use the order in the field array as the display order
	// helperText: '',
	// hideInput: false,
	// hideOutput: false,
	// outputDependsOn: '',
	// animation: {
	// 	entrance: '',
	// 	delay: null,
	// 	duration: null,
	// 	exit: '',
	// },

	// styles
	inputStyle: 'text',
	doNotSetToFit: false,
	smartQuotes: false,
	removeLineBreaks: false,
	wrapLinesContainingSlash: false,
	strikeThrough: false,
	strikeThroughStyle: {}, // {lineWidth: NUMBER|'', type: 'strike'|'strikeup'|'strikedown'|''}
	allCaps: false,
	formatNumber: false,
	formatNumberStyle: {}, // { currencySymbol: '€', currencyFontScale: 1, currencyFontName: placeholderSameAsText, currencyVerticalAlign: 'top', currencyPosition: 'leading', displayCent: false, doNotDisplayZeroCent: false, centFontScale: 1, centVerticalAlign: 'bottom', }
	batchRepeatingField: false,
	batchRepeatingFieldStyle: {},
	tableColumns: 'fixed',
	fontfaceName: defaultFont,
	fontsize: DEFAULT_FONTSIZE,
	fontsizeUserDefined: false,
	fontsizeUDStart: null,
	fontsizeUDStep: null,
	fontsizeUDEnd: null,
	// textFormat: [],	// we are not using it at the moment...
	fontColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },
	textHorizontalAlign: 'center',
	textHorizontalAlignUserDefined: false,
	textVerticalAlign: 'bottom',
	textVerticalAlignUserDefined: false,
	letterSpacing: 1,
	leadingLineHeight: 1,
	shadowHorOffset: null,
	shadowVerOffset: null,
	shadowBlurRadius: null,
	textShadowColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },

	// data association
	defaultValue: '',
	// dependsOn: '',
	autoImport: '', // possible values: '', 'image:XXXXX', 'cell:', 'user:3', 'date:'
	autoImportMeta: defaultTextAutoImportMeta,
	defaultAutoImportMeta: defaultTextAutoImportMeta,
	// autoImportBasedOn: defaultTextAutoImportMeta,
	// metadataLink: '',
	calcValue: DEFAULT_CALC_VALUE,
	// {
	// 	price: '', // field Id
	// 	unit: '', // field Id
	// 	qty: '', // field Id
	// 	per: '',
	// 	hide: false,
	// 	multipleWeightsMsg: '',
	// },
	predefinedValue: defaultTextPredefinedValue,
	defaultTextPredefinedValue: defaultTextPredefinedValue,
	embedStyle: '', // options from "embedStyleOptions", possible values: "original", "inherit"
	embedLeadingStyle: '',
};

const placeholderSameAsText = 'same';
const defaultFormatNumber = {
	currencySymbol: '€',
	currencyFontScale: 1,
	currencyFontName: placeholderSameAsText,
	currencyVerticalAlign: 'top', // top|middle|bottom
	currencyPosition: 'leading', // leading | trailing
	displayCent: false,
	doNotDisplayZeroCent: false,
	centFontScale: 1,
	centVerticalAlign: 'bottom', // top|middle|bottom
};
const defaultStrikeThrough = {
	type: 'strike',
	lineWidth: 1,
};
const defaultBatchRepeating = {
	NoOfRepetitions: 1,
	delimiter: '\\n',
};

const autoImportOtherForText = {
	Other: [
		// {
		// 	value: 'date:',
		// 	label: (
		// 		<FormattedMessage id="pages.Artwork.Constants.autoImportOtherOptionDateLabel" />
		// 	) /* `Today's Date` */, // we decide to remove "date" for the time being on 23/03/2021
		// },
		// { value: 'counter:', label: 'Counter' },
		{
			value: 'cell:',
			label: (
				<FormattedMessage id="pages.Artwork.Constants.autoImportOtherOptionCellLabel" />
			) /* 'Spreadsheet Cell' */,
		},
		// { value: 'cellimg:', label: 'Spreadsheet Image' },
	],
};

//TODO: Might require localization
const predefinedValueOptions = [
	{
		value: 'list',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.predefinedValOptionListLabel" />
		) /* 'From List' */,
	},
	{
		value: 'spreadsheet',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.predefinedValOptionSpreadsheetLabel" />
		) /* 'From Spreadsheet' */,
	},
];

const embedStyleOptions = [
	{
		value: 'inherit',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.embedStyleOptionsInheritLabel" />
		) /* 'Adapt to this field' */,
	},
	{
		value: 'original',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.embedStyleOptionsOriginalLabel" />
		) /* 'Keep Original' */,
	},
];

// const textFieldMetadataLinks = [
// 	{ value: 2, label: 'Dimensions' },
// 	{ value: 3, label: 'Period' },
// 	{ value: 14, label: 'Agency Name' },
// 	{ value: 17, label: 'Region' },
// 	{ value: 19, label: 'Quantity' },
// 	{ value: 4, label: 'Price' },
// 	{ value: 20, label: 'Order Date' },
// 	{ value: 30, label: 'Pre printed paper' },
// ];

/** Image Field */
const defaultImageAutoImportMeta = {
	basedOn: '',
	spreadsheetImageColumn: null,
	spreadsheetCell: null,
	esignEditable: false,
	esignSearchable: false,
	esignFilter: false,
};
const defaultImageField = {
	// general
	...generalFieldProps,
	// id: '',
	// name: '',
	type: 'image',
	// position: { left: 0, top: 0, width: 10, height: 10, angle: 0 },
	// groupName: '',
	// // zIndex: 0, // 0-based index
	// helperText: '',
	// hideInput: false,
	// hideOutput: false,
	// outputDependsOn: '',
	// animation: {
	// 	entrance: '',
	// 	delay: null,
	// 	duration: null,
	// 	exit: '',
	// },

	// styles
	sizing: 'fit', // fit|crop|autocrop
	sizingClipImage: false,
	horizontalAlign: 'center',
	horizontalAlignUserDefined: false,
	verticalAlign: 'middle',
	verticalAlignUserDefined: false,
	borderWidth: 0,
	borderColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },

	// data
	autoImport: '',
	autoImportMeta: defaultImageAutoImportMeta,
	defaultAutoImportMeta: defaultImageAutoImportMeta,
	imageOrigin: 'user_files', // it uses imageOriginOptions as its option choices. possible values: 'category', 'admin_lightbox', 'user_files', 'user_avatar'
	imageOriginChoice: [], // format: [{id: 'xxx', ...rest}, ...]. "id" in the object is mandatory, value of "id" is integer (for category) or string (for lightbox)
	imageOriginIncludeUserFiles: false,
	imageOriginChoiceStyle: 'popup',
	preSearchFieldId: '', // field ID. The first 5 characters of the field input text value is used for pre-search
	defaultMediafileId: '', // default image mediafile id
	defaultMediafilePreviewUrl: '', // default mediafile preview url
	defaultMediafileHighResUrl: '', // high-resolution url of default mediafile (for generating high quality pdf)
	defaultMediafileOptimisedUrl: '', // optimised url of default mediafile (fast loading & good quality for webpage)
	generateAi: false,
};

const autoImportOtherForImage = {
	Other: [
		{
			value: 'cell:',
			label: (
				<FormattedMessage id="pages.Artwork.Constants.autoImportOtherOptionCellLabel" />
			) /* 'Spreadsheet Cell' */,
		},
		{
			value: 'cellimg:',
			label: (
				<FormattedMessage id="pages.Artwork.Constants.autoImportOtherOptionCellimgLabel" />
			) /* 'Spreadsheet Image' */,
		},
	],
};

const imageOriginOptions = [
	{
		value: 'category',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.imageOriginOptionsCategoryLabel" />
		) /* 'Category' */,
	},
	{
		value: 'admin_lightbox',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.imageOriginOptionsLightboxLabel" />
		) /* 'Global Lightbox' */,
	},
	{
		value: 'user_files',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.imageOriginOptionsUserFilesLabel" />
		) /* `User's Own Files` */,
	},
	{
		value: 'user_avatar',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.imageOriginOptionsUserAvatarLabel" />
		) /* `User's Avatar` */,
	},
];

const imageSizingOptions = [
	{
		value: 'fit',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.imageSizingOptionsFitLabel" />
		) /* 'Fit to Bounding Box' */,
	},
	{
		value: 'crop',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.imageSizingOptionsCropLabel" />
		) /* 'Crop' */,
	},
	{
		value: 'autocrop',
		label: (
			<FormattedMessage id="pages.Artwork.Constants.imageSizingOptionsAutoCropLabel" />
		) /* 'Auto-Crop' */,
	},
];

const borderWidthRange = {
	step: 1,
	min: 0,
	max: 20,
	unit: 'px',
};

/** Pdf Field */
const defaultPdfField = {
	// general
	...generalFieldProps,
	// id: '',
	// name: '',
	type: 'pdf',
	// position: { left: 0, top: 0, width: 10, height: 10, angle: 0 },
	// groupName: '',
	// // zIndex: 0, // 0-based index
	// helperText: '',
	// hideInput: false,
	// hideOutput: false,
	// outputDependsOn: '',
	// animation: {
	// 	entrance: '',
	// 	delay: null,
	// 	duration: null,
	// 	exit: '',
	// },

	// styles
	anchor: 'bottom',
	// sizing: 'fit',
	// sizingClipImage: true,
	horizontalAlign: 'left',
	horizontalAlignUserDefined: false,
	verticalAlign: 'top',
	verticalAlignUserDefined: false,
	borderWidth: 0,
	borderColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },

	// data
	pdfOrigin: 'user_files', // it uses imageOriginOptions as its option choices. possible values: 'category', 'admin_lightbox', 'user_files', 'user_avatar'
	pdfOriginChoice: [], // format: [{id: 'xxx', ...rest}, ...]. "id" in the object is mandatory, value of "id" is integer (for category) or string (for lightbox)
	pdfOriginIncludeUserFiles: false,
	pdfOriginChoiceStyle: 'popup',
	defaultMediafileId: '', // default pdf mediafile id
	defaultMediafilePreviewUrl: '', // default mediafile preview url. It is a svg file
	defaultMediafileHighResUrl: '', // high-resolution url of default mediafile (for generating high quality pdf). It is a svg file
	defaultMediafileOptimisedUrl: '', // optimised url of default mediafile (fast loading & good quality for webpage). It is a svg file
};

/** Barcode Field */
const defaultBarcodeAutoImportMeta = {
	basedOn: '',
	// spreadsheetImageColumn: '',
	spreadsheetCell: null,
	esignEditable: false,
	esignSearchable: false,
	esignFilter: false,
};
const defaultBarcodeField = {
	// general
	...generalFieldProps,
	// id: '',
	// name: '',
	type: 'barcode',
	// position: { left: 0, top: 0, width: 10, height: 10, angle: 0 },
	// groupName: '',
	// // zIndex: 0, // 0-based index
	// helperText: '',
	// hideInput: false,
	// hideOutput: false,
	// outputDependsOn: '',
	// animation: {
	// 	entrance: '',
	// 	delay: null,
	// 	duration: null,
	// 	exit: '',
	// },

	// styles
	color: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },
	horizontalAlign: 'left',
	horizontalAlignUserDefined: false,
	verticalAlign: 'top',
	verticalAlignUserDefined: false,

	// data
	// dependsOn: '',
	append: '', // field id. field that is holding the ean5 code for this barcode field
	EAN5Addon: false,
	autoImport: '',
	autoImportMeta: defaultBarcodeAutoImportMeta,
	defaultAutoImportMeta: defaultBarcodeAutoImportMeta,
	defaultValue: '',
};

const autoImportOtherForBarcode = {
	Other: [
		{
			value: 'cell:',
			label: (
				<FormattedMessage id="pages.Artwork.Constants.autoImportOtherOptionCellLabel" />
			) /* 'Spreadsheet Cell' */,
		},
	],
};

/** Video Field */
const defaultVideoField = {
	// general
	...generalFieldProps,
	// id: '',
	// name: '',
	type: 'video',
	// position: { left: 0, top: 0, width: 10, height: 10, angle: 0 },
	// groupName: '',
	// // zIndex: 0, // 0-based index
	// helperText: '',
	// hideInput: false,
	// outputDependsOn: '',
	// hideOutput: false,
	// animation: {
	// 	entrance: '',
	// 	delay: null,
	// 	duration: null,
	// 	exit: '',
	// },

	// styles
	// sizing: 'fit',
	// sizingClipImage: true,
	horizontalAlign: 'left',
	horizontalAlignUserDefined: false,
	verticalAlign: 'top',
	verticalAlignUserDefined: false,
	borderWidth: 0,
	borderColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },

	// data
	videoOrigin: 'user_files', // it uses imageOriginOptions as its option choices. possible values: 'category', 'admin_lightbox', 'user_files', 'user_avatar'
	videoOriginChoice: [], // format: [{id: 'xxx', ...rest}, ...]. "id" in the object is mandatory, value of "id" is integer (for category) or string (for lightbox)
	videoOriginIncludeUserFiles: false,
	videoOriginChoiceStyle: 'popup',
	defaultMediafileId: '', // default video mediafile id
	defaultMediafilePreviewUrl: '', // default mediafile preview url
	defaultMediafileHighResUrl: '', // high-resolution url of default mediafile (for generating high quality pdf)
	defaultMediafileOptimisedUrl: '', // optimised url of default mediafile (fast loading & good quality for webpage)
	videoLoop: false,
	videoLoopUserDefined: false,
};

/** Grid Field */
const sampleGridTableText = `<table style="border-collapse: collapse; width: 100%;" border="1">
	<thead>
		<tr>
			<th>Product</th>
			<th>Small</th>
			<th>Large</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>Cappuccino</td>
			<td>€1.99</td>
			<td>€2.99</td>
		</tr>
		<tr>
			<td>Mocha</td>
			<td>€2.49</td>
			<td>€3.99</td>
		</tr>
	</tbody>
</table>`;
const defaultGridSubtitleFontsizeScale = 0.7;
const defaultGridField = {
	// general
	...generalFieldProps,
	type: 'grid',

	// styles
	fontColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },
	allCap: false,
	fontsizeUserDefined: false,
	fontsizeUDStart: null,
	fontsizeUDStep: null,
	fontsizeUDEnd: null,
	headerRule: 0, // underline in header row. line height in pixel
	headerRuleColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },
	bodyRowsRule: 0, // underline in body rows. line height in pixel
	bodyRowsRuleColor: { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } },

	// area 3.1
	titleHeaderFontfaceName: defaultFont,
	titleHeaderFontsize: DEFAULT_FONTSIZE, // NB: this is pdf pt at 300dpi. You need to conver it to web pt (92dpi) or web pixel (72dpi)

	// area 3.3
	titleCellFontfaceName: placeholderSameAsText,
	titleCellFontsize: placeholderSameAsText,
	titleCellSubtitleFontsizeScale: defaultGridSubtitleFontsizeScale,

	// area 3.2
	valueHeaderFontfaceName: placeholderSameAsText,
	valueHeaderFontsize: placeholderSameAsText,

	// area 3.4
	valueCellFontfaceName: placeholderSameAsText,
	valueCellFontsize: placeholderSameAsText,
	valueCellSubtitleFontsizeScale: defaultGridSubtitleFontsizeScale,

	// data
	// defaultValue: sampleGridTableText,
	defaultEditorHtml: sampleGridTableText,
};

export const ART_VARIABLES = {
	cssStyles: {
		// design work space styles
		designWrapperBGColor: '#edf0f2',
		// palette styles
		paletteTitleColor: grey[200],
		paletteTitleBGColor: blueGrey[600],
		paletteFontSize: '0.8rem',
		paletteContentColor: blueGrey[800],
		paletteContentBGColor: grey[100],
		// preview control panel styles
		previewControlPanelTitleColor: grey[200],
		previewControlPanelTitleBGColor: blueGrey[600],
		previewControlPanelFontSize: '0.8rem',
		previewControlPanelContentColor: blueGrey[800],
		previewControlPanelContentBGColor: grey[100],

		bottomControlsBGColor: blueGrey[600],
		bottomControlsColor: grey[200],

		// side menu styles
		menuBarWidth: 70,
		menuPanelWidth: 340,
		sideMenuColor: grey[300],
		sideMenuBGColor: blueGrey[500],
		menuBarHoverColor: '#ffffff',
		menuBarHoverBGColor: blueGrey[700],
		sideMenuExtraButtonHeight: 30,

		sidePanelBGColor: blueGrey[600],
		sidePanelColor: grey[300], //'#fff';

		// mobile create action styles (create page)
		mobileCreateActionHeight: 50,
		mobileCreateActionColor: blueGrey[800],
		mobileCreateActionBGColor: grey[200],

		// action ribbon styles
		actionRibbonHeight: 38, // 30px content + 4px padding at top & bottom
		actionRibbonColor: blueGrey[800],
		actionRibbonBGColor: grey[200],
		actionRibbonFontSize: '0.8rem',
		zoomBorderColor: blueGrey[100],

		// template setting styles
		settingButtonWidth: 48,
		settingButtonHeight: 48,

		// create actions styles
		createActionButtonWidth: 48,
		createActionButtonHeight: 48,
		createActionMinHeight: 300,

		// esign page styles
		nonTableAreaHeight: 120,
		minItemWidthInProofView: 430,
	},
	zoomStep: 0.05,
	minZoom: 0.05,
	maxZoom: 2,
	zoomOptions: [
		// { value: 0.1, label: '10%' },
		// { value: 0.25, label: '25%' },
		// { value: 0.5, label: '50%' },
		// { value: 0.75, label: '75%' },
		// { value: 1.0, label: '100%' },
		// { value: 1.25, label: '125%' },
		// { value: 2.0, label: '200%' },
		{ value: 0, label: <FormattedMessage id="pages.Artwork.Constants.zoomFitText" /> },
	],
	WSBorderH: 48, // in pixel. Border in horizontal
	WSBorderV: 48, // in pixel. Border in vertical
	minWidthResizeBox: 15, // in pixel
	minHeightResizeBox: 15, // in pixel
	ZINDEX_RESIZEBOX: 1000,
	ZINDEX_FIELDPREVIEW: 100,
	DEFAULT_ANIMATION_DELAY: 0, // in second
	DEFAULT_ANIMATION_DURATION: 1, // in second

	DEFAULT_FONT: defaultFont,
	DEFAULT_FONTPATH: defaultFontpath,
	// status: {
	// 	resizing: 'RESIZING',
	// },
	textFieldLetterSpacingMax: 200.0,
	textFieldLetterSpacingMin: 1,
	textFieldLetterSpacingStep: 1,
	textFieldLeadingLineHeightStep: 0.1,
	textFieldLeadingLineHeightMin: 0.5,
	textFieldLeadingLineHeightMax: 3,
	textFieldFontSizeMin: 2,
	textFieldFontSizeMax: 400,
	// textFieldFontSizeUnit: 'pt', // we use point for font size at the moment
	textFieldFontSizeDefault: DEFAULT_FONTSIZE,
	textFieldShadowOffsetMin: -100,
	textFieldShadowOffsetMax: 100,
	textFieldShadowOffsetStep: 1,
	textFieldShadowOffsetDefault: 0,
	textFieldShadowBlurMin: 0,
	textFieldShadowBlurMax: 30,
	textFieldShadowBlurStep: 1,
	textFieldShadowBlurDefault: 0,
	currencySymbol: ['€', '£', '$'],
	defaultFormatNumber: defaultFormatNumber,
	minStrikeLineWidth: 1, //px
	stepStrikeLineWidth: 1, // px
	maxStrikeLineWidth: 30, // px
	defaultStrikeThrough: defaultStrikeThrough,
	defaultBatchRepeating: defaultBatchRepeating,
	placeholderSameAsText: placeholderSameAsText,
	gridFieldRuleMin: 0,
	gridFieldRuleMax: 20,
	gridFieldRuleStep: 1,
	gridFieldFontSizeMin: 2,
	gridFieldFontSizeMax: 400,
	fieldFontSizeUnit: 'pt', // for any field. it is pdf pt (in 300dpi), to use fontsize in web, need to convert it to web pt (in 92dpi) or web pixel (in 72dpi)
	batchRepeatingFieldDelimiters: [
		// { value: `\n`, label: `\n` },
		{
			value: `\n`,
			label: (
				<FormattedMessage id="pages.Artwork.Constants.fieldDelimiterReturnLabel" />
			) /* 'Return' */,
		},
		{
			value: ' ',
			label: (
				<FormattedMessage id="pages.Artwork.Constants.fieldDelimiterSpaceLabel" />
			) /* 'Space' */,
		},
		{ value: '/', label: '/' },
		{ value: '\\', label: '\\' },
		{ value: '|', label: '|' },
		{ value: '-', label: '-' },
	],
	// animations
	animations: ARTWORK_ANIMATIONS,
	animationEntranceOptions: ANIMATION_ENTRANCE_OPTIONS,
	animationExitOptions: ANIMATION_EXIT_OPTIONS,
	DEFAULT_CALC_VALUE: DEFAULT_CALC_VALUE,
	// default field value
	defaultFields: {
		text: defaultTextField,
		image: defaultImageField,
		pdf: defaultPdfField,
		barcode: defaultBarcodeField,
		video: defaultVideoField,
		grid: defaultGridField,
	},
	// artwork extra data
	autoImportOtherForText: autoImportOtherForText,
	predefinedValueOptions: predefinedValueOptions,
	embedStyleOptions: embedStyleOptions,
	sampleGridTableText: sampleGridTableText,
	defaultGridSubtitleFontsizeScale: defaultGridSubtitleFontsizeScale,
	// textFieldMetadataLinks: textFieldMetadataLinks,
	imageSizingOptions: imageSizingOptions,
	borderWidthRange: borderWidthRange,
	imageOriginOptions: imageOriginOptions,
	autoImportOtherForImage: autoImportOtherForImage,
	autoImportOtherForBarcode: autoImportOtherForBarcode,

	// esign
	minAdjustOutput: -10,
	maxAdjustOutput: 10,
	stepAdjustOutput: 1,
	// adjustOutputLocalStorageKey: 'esignAdjustOutput',
	maxSelectableArtTemplate: 5,
	maxSelectableItems: 200,

	// keycodes
	keyCodes: keyCodes,
	bumpSelectionShiftValue: 10,
	bumpSelectionValue: 1,

	// video artwork
	supportedFieldTypesInVideoArtwork: ['text', 'image'], // field types that can be in video artwork
};
