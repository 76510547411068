import React from 'react';

import { ART_VARIABLES } from '../../Constants';
import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

// MUI components
import {
	Button,
	IconButton,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	Input,
	// Fab,
	CircularProgress,
	Backdrop,
	Typography,
	Tooltip,
} from '@mui/material';
import {
	Undo as UndoIcon,
	Redo as RedoIcon,
	ZoomOut as ZoomOutIcon,
	ZoomIn as ZoomInIcon,
	CloudDone as CloudSaveDoneIcon,
	Close as CloseIcon,
	Visibility as PreviewIcon,
	PhotoTwoTone as BackgroundImageIcon,
	Highlight as FieldHighlightIcon,
	NotificationImportant as AlertIcon,
} from '@mui/icons-material';
import { CloudAlert as CloudSaveFailedIcon, CloudSync as CloudSaveIcon } from 'mdi-material-ui';

import { green, red, grey } from '@mui/material/colors';
import { DividerVer } from '../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	ribbonWrapper: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: ART_VARIABLES.cssStyles.actionRibbonColor,
		backgroundColor: ART_VARIABLES.cssStyles.actionRibbonBGColor,
		fontSize: ART_VARIABLES.cssStyles.actionRibbonFontSize,
	},
	zoneWrapper: {
		// for left, middle, right zone
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		color: 'inherit',
		fontSize: 'inherit',
		width: '33%',
	},
	// undo/redo styles
	undoRedoButtonsWrapper: {
		margin: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		color: 'inherit',
		fontSize: 'inherit',
	},
	button: {
		color: 'inherit',
		textTransform: 'none',
		fontSize: 'inherit',
	},

	// action buttons styles
	actionButtonsWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
		fontSize: 'inherit',
		color: 'inherit',
	},
	saveButton: {
		borderRadius: 15,
		margin: `0px ${theme.spacing(1.5)} 0px ${theme.spacing(1.5)}`,
	},
	saveSuccess: {
		backgroundColor: green[700],
		'&:hover': {
			backgroundColor: green[800],
		},
	},
	saveFailed: {
		backgroundColor: red[500],
		'&:hover': {
			backgroundColor: red[600],
		},
	},
	backdrop: {
		zIndex: 9999,
		color: grey[200],
		display: 'flex',
		flexDirection: 'column',
	},
	closeButton: {
		position: 'absolute',
		right: '40%',
		top: '30%',
	},
	extraActionButton: {
		margin: theme.spacing(0.5),
		padding: 0,
		fontSize: 'inherit',
	},

	// preview switch
	// previewWrapper: {
	// 	margin: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
	// 	fontSize: 'inherit',
	// 	color: 'inherit',
	// 	alignItems: 'center',
	// 	paddingRight: theme.spacing(2),
	// },
	controlButtonWrapper: {
		margin: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
		fontSize: 'inherit',
		color: 'inherit',
		alignItems: 'center',
		paddingRight: theme.spacing(2),
		// flex: `.5 0 auto`,
	},
	controlButton: {
		padding: 2,
		// color: 'inherit',
		margin: '0px 2px',
		borderRadius: 4,
		border: `1px solid rgba(0,0,0,0.15)`,
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
			border: `1px solid rgba(0,0,0,0.3)`,
		},
	},
	constrolButtonActive: {
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
	},

	// zoom styles
	zoomWrapper: {
		// left: '50%',
		// transform: 'translateX(-50%)',
		display: 'flex',
		margin: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
		transition: 'all .4s',
		fontSize: 'inherit',
		color: 'inherit',
		border: `1px solid ${ART_VARIABLES.cssStyles.zoomBorderColor}`,
		borderRadius: 16,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	zoomButtons: {
		padding: 2,
		color: 'inherit',
		margin: '0px 2px',
	},
	zoomButtonsIcon: {
		fontSize: 'inherit',
		backgroundColor: 'transparent',
	},
	zoomPercent: {
		border: `1px solid ${ART_VARIABLES.cssStyles.zoomBorderColor}`,
		borderRadius: 15,
		width: 80,
		color: 'inherit',
		fontSize: 'inherit',
		textAlign: 'center',
	},
	zoomSelectInputRoot: {
		color: 'inherit',
		fontSize: 'inherit',
		paddingLeft: theme.spacing(1),
	},
	zoomSelectIcon: {
		color: 'inherit',
	},
	alertIconButton: {
		color: theme.palette.error.main,
	},
	'@keyframes ring': theme.animations['@keyframes ring'],
	ring: {
		animation: '$ring 0.5s ease-in-out infinite both',
	},
}));

function BottomActionRibbon({
	// undo/redo
	canUndo,
	canRedo,
	undoHandler,
	redoHandler,
	// action buttons
	closableBackdrop,
	saveDesignerTemplate,
	saveTemplateStatus,
	handleTestTemplate,
	handleEsignTemplate,
	// zoom
	handleZoomOut,
	handleZoomIn,
	handleZoomSelect,
	zoom,
	// preview control
	isPreviewEnabled,
	togglePreview,
	// background image control
	isBGImageEnabled,
	toggleBGImage,
	// field highlight control
	isFieldHighlightEnabled,
	toggleFieldHighlight,
	// invalid fields control
	handleClickInvalidFieldsBtn,
	showInvalidFieldsBtn,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	// states for action buttons
	const [backdropOpen, setBackdropOpen] = React.useState(false);
	React.useEffect(() => {
		if (!backdropOpen && saveTemplateStatus === 'PROCESSING') {
			setBackdropOpen(true);
		}
		if (backdropOpen && saveTemplateStatus !== 'PROCESSING') {
			setBackdropOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveTemplateStatus]);
	let SaveIcon = CloudSaveIcon;
	switch (saveTemplateStatus) {
		case 'OK':
			SaveIcon = CloudSaveDoneIcon;
			break;
		case 'FAILED':
			SaveIcon = CloudSaveFailedIcon;
			break;
		default:
			break;
	}

	// states for zoom
	const [selectedZoom, setSelectedZoom] = React.useState({ value: 0 /* , label: 'fit' */ });
	return (
		<div className={classes.ribbonWrapper}>
			{/** undo/redo */}
			<div className={classes.zoneWrapper} style={{ justifyContent: 'flex-start' }}>
				<div className={classes.undoRedoButtonsWrapper}>
					<Button
						size="small"
						disabled={!canUndo}
						onClick={undoHandler}
						className={classes.button}
						startIcon={<UndoIcon fontSize="small" />}
					>
						{intl.formatMessage({
							id: 'pages.Artwork.components.BottomActionRibbon.undoText',
						})}
					</Button>
					<DividerVer />
					<Button
						size="small"
						disabled={!canRedo}
						onClick={redoHandler}
						className={classes.button}
						endIcon={<RedoIcon fontSize="small" />}
					>
						{intl.formatMessage({
							id: 'pages.Artwork.components.BottomActionRibbon.redoText',
						})}
					</Button>
				</div>
			</div>
			{/** Action Buttons */}
			<div className={classes.zoneWrapper} style={{ justifyContent: 'center' }}>
				<div className={classes.actionButtonsWrapper}>
					{showInvalidFieldsBtn && (
						<IconButton
							className={classes.alertIconButton}
							size="small"
							onClick={(e) => {
								handleClickInvalidFieldsBtn(e);
							}}
						>
							<AlertIcon className={classes.ring} />
						</IconButton>
					)}
					{
						<Button
							variant="outlined"
							color="secondary"
							className={classes.extraActionButton}
							onClick={handleTestTemplate}
						>
							{intl.formatMessage({
								id: 'pages.Artwork.components.BottomActionRibbon.testButtonText',
							})}
						</Button>
					}
					<Button
						variant="contained"
						size="small"
						className={cx(classes.saveButton, {
							[classes.saveSuccess]: saveTemplateStatus === 'OK',
							[classes.saveFailed]: saveTemplateStatus === 'FAILED',
						})}
						color="primary"
						onClick={() => saveDesignerTemplate()}
						startIcon={<SaveIcon fontSize="small" />}
					>
						{intl.formatMessage({
							id: 'pages.Artwork.components.BottomActionRibbon.saveButtonText',
						})}
					</Button>
					{handleEsignTemplate && (
						<Button
							variant="outlined"
							color="secondary"
							className={classes.extraActionButton}
							onClick={handleEsignTemplate}
						>
							{intl.formatMessage({
								id: 'pages.Artwork.components.BottomActionRibbon.esignButtonText',
							})}
						</Button>
					)}

					<Backdrop className={classes.backdrop} open={backdropOpen}>
						{closableBackdrop && (
							<IconButton
								className={classes.closeButton}
								color="inherit"
								onClick={() => setBackdropOpen(false)}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
						)}
						<CircularProgress color="inherit" />
						<Typography variant="body2">
							{intl.formatMessage({
								id: 'pages.Artwork.components.BottomActionRibbon.savingTemplateMsg',
							})}
						</Typography>
					</Backdrop>
				</div>
			</div>
			{/** right zone */}
			<div className={classes.zoneWrapper} style={{ justifyContent: 'flex-end' }}>
				<div className={classes.controlButtonWrapper}>
					<Tooltip
						title={intl.formatMessage({
							id: 'pages.Artwork.components.BottomActionRibbon.previewTooltip',
						})}
						placement="top"
						arrow
					>
						<IconButton
							className={cx(classes.controlButton, {
								[classes.constrolButtonActive]: isPreviewEnabled,
							})}
							onClick={togglePreview}
						>
							<PreviewIcon />
						</IconButton>
					</Tooltip>
					<Tooltip
						title={intl.formatMessage({
							id: 'pages.Artwork.components.BottomActionRibbon.backgroundTooltip',
						})}
						placement="top"
						arrow
					>
						<IconButton
							className={cx(classes.controlButton, {
								[classes.constrolButtonActive]: isBGImageEnabled,
							})}
							onClick={toggleBGImage}
						>
							<BackgroundImageIcon />
						</IconButton>
					</Tooltip>
					<Tooltip
						title={intl.formatMessage({
							id: 'pages.Artwork.components.BottomActionRibbon.fieldsHighlightTooltip',
						})}
						placement="top"
						arrow
					>
						<IconButton
							className={cx(classes.controlButton, {
								[classes.constrolButtonActive]: isFieldHighlightEnabled,
							})}
							onClick={toggleFieldHighlight}
						>
							<FieldHighlightIcon />
						</IconButton>
					</Tooltip>
				</div>
				{/** zoom controls */}
				<div className={classes.zoomWrapper}>
					<IconButton
						className={classes.zoomButtons}
						onClick={() => {
							if (zoom <= ART_VARIABLES.minZoom) return;
							setSelectedZoom({}); // set selectedZoom to empty so that no option is selected
							handleZoomOut(Math.max(zoom - ART_VARIABLES.zoomStep, ART_VARIABLES.minZoom));
						}}
					>
						<ZoomOutIcon className={classes.zoomButtonsIcon} />
					</IconButton>
					<div className={classes.zoomPercent}>
						<Select
							value={typeof selectedZoom.value === 'number' ? selectedZoom.value : ''}
							variant="standard"
							disableUnderline
							classes={{
								icon: classes.zoomSelectIcon,
							}}
							onChange={(e) => {
								setSelectedZoom({
									value: Number(e.target.value),
									// label: e.currentTarget.dataset.label,
								});
								handleZoomSelect(Number(e.target.value));
							}}
							renderValue={() => `${Math.round(zoom * 100)}%`}
							input={<Input classes={{ root: classes.zoomSelectInputRoot }} />}
							displayEmpty
						>
							{ART_VARIABLES.zoomOptions.map((option) => (
								<MenuItem key={option.value} value={option.value} /* data-label={option.label} */>
									<Checkbox checked={selectedZoom.value === option.value} />
									<ListItemText primary={option.label} />
								</MenuItem>
							))}
						</Select>
					</div>
					<IconButton
						className={classes.zoomButtons}
						onClick={() => {
							if (zoom >= ART_VARIABLES.maxZoom) return;
							setSelectedZoom({}); // set selectedZoom to empty so that no option is selected
							handleZoomIn(Math.min(zoom + ART_VARIABLES.zoomStep, ART_VARIABLES.maxZoom));
						}}
					>
						<ZoomInIcon className={classes.zoomButtonsIcon} />
					</IconButton>
				</div>
			</div>
		</div>
	);
}

BottomActionRibbon.propTypes = {
	// undo/redo
	canUndo: PropTypes.bool.isRequired,
	canRedo: PropTypes.bool.isRequired,
	undoHandler: PropTypes.func.isRequired,
	redoHandler: PropTypes.func.isRequired,

	// action buttons
	saveDesignerTemplate: PropTypes.func.isRequired,
	saveTemplateStatus: PropTypes.string.isRequired,
	handleTestTemplate: PropTypes.func.isRequired,
	handleEsignTemplate: PropTypes.func,
	closableBackdrop: PropTypes.bool, // if display "close" button in Backdrop

	// zoom controls
	handleZoomOut: PropTypes.func.isRequired,
	handleZoomIn: PropTypes.func.isRequired,
	handleZoomSelect: PropTypes.func.isRequired,
	zoom: PropTypes.number.isRequired,

	// preview control
	isPreviewEnabled: PropTypes.bool.isRequired,
	togglePreview: PropTypes.func.isRequired,

	// Background image control
	isBGImageEnabled: PropTypes.bool.isRequired,
	toggleBGImage: PropTypes.func.isRequired,

	// Field highlight control
	isFieldHighlightEnabled: PropTypes.bool.isRequired,
	toggleFieldHighlight: PropTypes.func.isRequired,

	// Invalide Fields control
	handleClickInvalidFieldsBtn: PropTypes.func.isRequired,
	// invalidFieldsButtonRef: PropTypes.oneOfType([
	// 	PropTypes.func,
	// 	PropTypes.shape({ current: PropTypes.any }),
	// ]),
	showInvalidFieldsBtn: PropTypes.bool,
};

BottomActionRibbon.defaultProps = { showInvalidFieldsBtn: false };
export default BottomActionRibbon;
