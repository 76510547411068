const keyCodes = {
	deleteKey: 46,
	backspaceKey: 8,
	// tabKey: 9,
	// enterKey: 13,
	// escapeKey: 27,
	leftKey: 37,
	upKey: 38,
	rightKey: 39,
	downKey: 40,
	zKey: 90,
	yKey: 89,
	// isBlocked: keyCode => blocked.includes(keyCode), // indicate if the key can be handled by child component
};

// Following keys should not be handled by the child components
// const blocked = [
// 	keyCodes.deleteKey,
// 	keyCodes.backspaceKey,
// 	keyCodes.leftKey,
// 	keyCodes.upKey,
// 	keyCodes.rightKey,
// 	keyCodes.downKey,
// ];

export default keyCodes;
