import {
	RESET_DESIGNER_TEMPLATE,
	// GET_DESIGNER_TEMPLATE,
	FETCH_ARTWORK_DESIGN_TEMPLATE,
	FETCH_ARTWORK_DESIGN_TEMPLATE_SUCCESS,
	FETCH_ARTWORK_DESIGN_TEMPLATE_FAIL,
	RESET_FETCH_ARTWORK_DESIGN_TEMPLATE,
	SET_LOCAL_DESIGN_TEMPLATE_BY_REMOTE_TEMPLATE,
	UPDATE_LOCAL_DESIGNER_TEMPLATE,
	SET_ARTWORK_DESIGN_OPERATION_STATE,
	SAVE_ARTWORK_DESIGN_TEMPLATE,
	SAVE_ARTWORK_DESIGN_TEMPLATE_SUCCESS,
	SAVE_ARTWORK_DESIGN_TEMPLATE_FAIL,
	// SAVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
	// SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
	// RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
	// RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
	UNDO_ARTWORK_DESIGN_TEMPLATE,
	REDO_ARTWORK_DESIGN_TEMPLATE,
	// ARTWORK_SAVE_FILE,
	// ARTWORK_SAVE_FILE_SUCCESS,
	// ARTWORK_SAVE_FILE_FAIL,
	// ARTWORK_RESET_SAVE_FILE_STATUS,
	ARTWORK_IMPORT_TEMPLATE,
} from 'redux/actions';
import { _ } from 'utils/libHelper';
import {
	// artworkStorage,
	convertRemoteFieldsToLocal,
	convertLocalFieldsToRemote,
} from 'utils/artwork/artTemplateConverter';
import config from 'config';

const artworkAPIBaseUrl = config.filemanagerAPI.baseUrl;
const authHeaderKey = config.filemanagerAPI.authHeaderKey;

export const resetDesignerTemplate = () => ({
	type: RESET_DESIGNER_TEMPLATE,
});

/**
 * Import artwork template
 * @param {object} opts. Format:
 {
	 	importTemplate: {
			groups: [],
			lastUsedGroupName: 'Group 1',
			fields: [],
			templateDuration: 0,
		}
 }
 * @param {function} cb
 * @returns null
 */
// eslint-disable-next-line no-unused-vars
export const importArtworkTemplate = (opts, cb) => (dispatch, getState) => {
	dispatch({
		type: ARTWORK_IMPORT_TEMPLATE,
		importTemplate: opts.importTemplate,
	});
	if (typeof cb === 'function') cb();
};

// eslint-disable-next-line no-unused-vars
export const getDesignerTemplate = (opts, cb) => (dispatch, getState) => {
	// let sessionKey = Cookies.get(config.lassoAPI.sessionKeyInCookie);
	let reqUrl = `${artworkAPIBaseUrl}/artwork/${opts.mediaId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token, // `Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: getState().authentication.domainName, uid: getState().authentication.userId },
		timeout: 30000,
	};
	return dispatch({
		types: [
			FETCH_ARTWORK_DESIGN_TEMPLATE,
			FETCH_ARTWORK_DESIGN_TEMPLATE_SUCCESS,
			FETCH_ARTWORK_DESIGN_TEMPLATE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: ({ dispatch, getState, result, error }) => {
			if (!error) {
				const state = getState();
				convertRemoteFieldsToLocal(
					state.artworkDesignerTemplate.present.originalTemplate,
					state.artworkExtra
				)
					.then((formatedDesignTemplate) => {
						dispatch({
							type: SET_LOCAL_DESIGN_TEMPLATE_BY_REMOTE_TEMPLATE,
							formatedDesignTemplate,
						});
						if (typeof cb === 'function') cb({ dispatch, getState, result, error });
					})
					.catch((err) => {
						if (typeof cb === 'function') cb({ dispatch, getState, result: null, error: err });
					});
			} else {
				if (typeof cb === 'function') cb({ dispatch, getState, result, error });
			}
		},
	});
};

// reset the status of getting designer templater
export const resetGetDesignerTemplate = () => ({
	type: RESET_FETCH_ARTWORK_DESIGN_TEMPLATE,
});

// undo design templater
export const undoDesignTemplate = () => ({
	type: UNDO_ARTWORK_DESIGN_TEMPLATE,
});

// redo design templater
export const redoDesignTemplate = () => ({
	type: REDO_ARTWORK_DESIGN_TEMPLATE,
});

// export const getDesignerTemplate = () => ({
// 	type: GET_DESIGNER_TEMPLATE,
// 	template: {
// 		// TODO: This is a sample data. Call api to get the real template data
// 		// name: 'Test Artwork',
// 		// pages: [
// 		// 	{
// 		// 		idx: 0, // we may support only 1 page for very long time
// 		// 		name: 'Page 1',
// 		// 		// use sample artwork from: https://centra-beta.visualid.com/pos_creator/design/?id=139176&groupsq=0&-action=New
// 		// 		dimension: { width: 2481, height: 3508 }, // in pixels (as in filemanager database)
// 		// 		mediaId: 139176,
// 		// 		mediaUrl:
// 		// 			'https://apigw.visualid.com/preview/v1/preview/?redirect=true&prekey=XTEh1eydHiEv6nKxfj1u4HjCHw1Y5X16&fileurl=s3://visualid-mediafiles/centra/20170609/7F0000010edf52315BuQ9AE527D5/nK1k87625irZ.pdf&size=lores',
// 		// 		// dimension: { width: 1500, height: 792 },
// 		// 		// mediaUrl: 'https://image.shutterstock.com/z/stock-photo-colorful-hot-air-balloons-flying-over-mountain-at-dot-inthanon-in-chiang-mai-thailand-1033306540.jpg',
// 		// 		groups: [
// 		// 			// {
// 		// 			// 	name: 'group1',	// must be unique
// 		// 			// }
// 		// 		],
// 		// 		fields: [
// 		// 			// 	{
// 		// 			// 	id: 'UUID',
// 		// 			// 	name: 'field1',
// 		// 			// 	type: 'text',
// 		// 			// 	position: { left: 1, top: 1, width: 1, height: 1 },
// 		// 			// 	groupName: 'groupname',
// 		// 			//	zIndex: 1,	// 0-based index
// 		// 			// },
// 		// 			// {
// 		// 			// 	id: 'UUID',
// 		// 			// 	name: 'field1',
// 		// 			// 	type: 'text',
// 		// 			// 	position: { left: 1, top: 1, width: 1, height: 1 },
// 		// 			// 	groupName: 'groupname',
// 		// 			//	zIndex: 1,	// 0-based index
// 		// 			// }
// 		// 		],
// 		// 	},
// 		// ],

// 		// idx: 0, // we may support only 1 page for very long time
// 		name: 'Page 1',
// 		// use sample artwork from: https://centra-beta.visualid.com/pos_creator/design/?id=139176&groupsq=0&-action=New
// 		dimension: { width: 2481, height: 3508 }, // in pixels (as in filemanager database)
// 		mediaId: 139176,
// 		mediaUrl:
// 			'https://apigw.visualid.com/preview/v1/preview/?redirect=true&prekey=XTEh1eydHiEv6nKxfj1u4HjCHw1Y5X16&fileurl=s3://visualid-mediafiles/centra/20170609/7F0000010edf52315BuQ9AE527D5/nK1k87625irZ.pdf&size=lores',
// 		// dimension: { width: 1500, height: 792 },
// 		// mediaUrl: 'https://image.shutterstock.com/z/stock-photo-colorful-hot-air-balloons-flying-over-mountain-at-dot-inthanon-in-chiang-mai-thailand-1033306540.jpg',
// 		groups: [
// 			// {
// 			// 	name: 'group1',	// must be unique
// 			// }
// 		],
// 		fields: [
// 			// 	{
// 			// 	id: 'UUID',
// 			// 	name: 'field1',
// 			// 	type: 'text',
// 			// 	position: { left: 1, top: 1, width: 1, height: 1 },
// 			// 	groupName: 'groupname',
// 			//	zIndex: 1,	// 0-based index
// 			// },
// 			// {
// 			// 	id: 'UUID',
// 			// 	name: 'field1',
// 			// 	type: 'text',
// 			// 	position: { left: 1, top: 1, width: 1, height: 1 },
// 			// 	groupName: 'groupname',
// 			//	zIndex: 1,	// 0-based index
// 			// }
// 		],
// 		htmlArtwork: true, // if it is html artwork
// 		templateDuration: 0, // total duration of the template (slide) if it is animation template
// 		isEsignTemplate: false,
// 		isEsignOnly: false,

// 		metadata: {},
// 	},
// });

// const throttledStorageSave = _.throttle(
// 	(key, value, callback) => artworkStorage.setItem(key, value, callback),
// 	1000,
// 	{ leading: false, trailing: true }
// );
// eslint-disable-next-line no-unused-vars
export const updateLocalDesignerTemplate = (updatedTemplate = {}, cb) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_LOCAL_DESIGNER_TEMPLATE,
		updatedTemplate,
	});
	if (typeof cb === 'function') cb();
	// // save offline data
	// throttledStorageSave(
	// 	config.artworkOfflineStorage.designTemplateKey,
	// 	getState().artworkDesignerTemplate.present,
	// 	(err) => {
	// 		if (err) {
	// 			dispatch({
	// 				type: SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
	// 				error: err,
	// 			});
	// 		} else {
	// 			dispatch({
	// 				type: SAVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
	// 			});
	// 		}
	// 	}
	// );
};

// set current design editing states, e.g. selected fileds, palette tab index, etc.
// eslint-disable-next-line no-unused-vars
export const setArtDesignState = (designStates = {}, cb) => (dispatch, getState) => {
	dispatch({
		type: SET_ARTWORK_DESIGN_OPERATION_STATE,
		designStates,
	});
	if (typeof cb === 'function') cb();
};

// // eslint-disable-next-line no-unused-vars
// export const retrieveDraftDesignTemplate = (cb) => (dispatch, getState) => {
// 	artworkStorage
// 		.getItem(config.artworkOfflineStorage.designTemplateKey)
// 		.then((data) => {
// 			if (data) {
// 				dispatch({
// 					type: RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
// 					offlineTemplate: data || {},
// 				});
// 				if (typeof cb === 'function') cb({ dispatch, getState });
// 			} else {
// 				let err = new Error('No draft template available.');
// 				dispatch({
// 					type: RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
// 					error: err,
// 				});
// 				if (typeof cb === 'function') cb({ error: err });
// 			}
// 		})
// 		.catch((err) => {
// 			dispatch({
// 				type: RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
// 				error: err,
// 			});
// 			if (typeof cb === 'function') cb({ error: err });
// 		});
// };

export const saveDesignerTemplate = (cb) => (dispatch, getState) => {
	// get current template data from redux state
	const template = getState().artworkDesignerTemplate.present;
	// let sessionKey = Cookies.get(config.lassoAPI.sessionKeyInCookie);
	let reqUrl = `${artworkAPIBaseUrl}/artwork/${template.mediaId}`;
	let remoteTemplate = convertLocalFieldsToRemote(template);
	let authState = getState().authentication;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token, //`Bearer ${sessionKey}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		data: remoteTemplate,
		headers: reqHeader,
		params: { domain: authState.domainName, uid: authState.userId },
		timeout: 30000,
	};
	return dispatch({
		types: [
			SAVE_ARTWORK_DESIGN_TEMPLATE,
			SAVE_ARTWORK_DESIGN_TEMPLATE_SUCCESS,
			SAVE_ARTWORK_DESIGN_TEMPLATE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: (args) => {
			if (typeof cb === 'function') cb(args);
			// save offline data
			// artworkStorage.setItem(
			// 	config.artworkOfflineStorage.designTemplateKey,
			// 	getState().artworkDesignerTemplate.present,
			// 	(err) => {
			// 		if (err) {
			// 			dispatch({
			// 				type: SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL,
			// 				error: err,
			// 			});
			// 		} else {
			// 			dispatch({
			// 				type: SAVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS,
			// 			});
			// 		}
			// 	}
			// );
		},
	});
};

// /**
//  * Save generated artwork file
//  * @param {object} opts. format: {artworkTemplateId: 'xxx', reqData: {}}
//  * @param {func} cb
//  * @returns null
//  */
// export const saveArtworkFile = (opts = {}, cb) => (dispatch, getState) => {
// 	let reqUrl = `${artworkAPIBaseUrl}/artwork/save/${opts.artworkTemplateId}`;
// 	let reqHeader = {
// 		'Content-Type': 'application/json',
// 		[authHeaderKey]: getState().authentication.token,
// 	};
// 	let axiosConfig = {
// 		url: reqUrl,
// 		method: 'POST',
// 		data: opts.reqData,
// 		headers: reqHeader,
// 		timeout: 30000,
// 	};
// 	return dispatch({
// 		types: [ARTWORK_SAVE_FILE, ARTWORK_SAVE_FILE_SUCCESS, ARTWORK_SAVE_FILE_FAIL],
// 		promise: (client) => {
// 			return client.request(axiosConfig);
// 		},
// 		afterSuccess: cb,
// 	});
// };

// export const resetSaveArtworkFileStatus = () => ({
// 	type: ARTWORK_RESET_SAVE_FILE_STATUS,
// });
