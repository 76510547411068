export const NOTIFY_SNACKBAR = 'NOTIFY_SNACKBAR';
export const NOTIFY_DETAIL = 'NOTIFY_DETAIL';
export const REMOVE_NOTIFY_SNACKBAR = 'REMOVE_NOTIFY_SNACKBAR';
export const RESET_NOTIFY_DETAIL = 'RESET_NOTIFY_DETAIL';

export const NOTIFY_DETAIL_TYPE_FILEUPLOADER = 'NOTIFY_DETAIL_TYPE_FILEUPLOADER';

export const GENERAL_ERROR_MESSAGE = 'GENERAL_ERROR_MESSAGE';
export const GENERAL_NOTIFY_MESSAGE = 'GENERAL_NOTIFY_MESSAGE';

export const notifySnackbar = (notification) => ({
	type: NOTIFY_SNACKBAR,
	notification: {
		key: new Date().getTime() + Math.random(),
		...notification,
	},
});

export const notifyDetail = (notificationDetail) => ({
	type: NOTIFY_DETAIL,
	notificationDetail: notificationDetail,
});

export const removeSnackbar = (key) => ({
	type: REMOVE_NOTIFY_SNACKBAR,
	key,
});

export const resetNotifyDetail = () => ({
	type: RESET_NOTIFY_DETAIL,
});

export const notifyError = (err) => ({
	type: GENERAL_ERROR_MESSAGE,
	error: err,
});

export const notifyGeneral = (message, level, options = {}) => ({
	type: GENERAL_NOTIFY_MESSAGE,
	message: message,
	level: level, // possible level: 'info', 'warning', 'error', 'success', 'default', 'white'. Note: 'white' is default layout with white background
	options: options,
});
