let domains = {
	default: {
		mainColor: '#30739a',
	},
	careplus: {
		mainColor: '#0054a9',
		font: {
			fontFace: [
				{
					fontFamily: 'Museo500',
					src: `url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/careplus-font-toolkit/Museo500-Regular.eot'),
							url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/careplus-font-toolkit/Museo500-Regular.eot?#iefix') format('embedded-opentype'),
							url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/careplus-font-toolkit/Museo500-Regular.woff') format('woff'),
							url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/careplus-font-toolkit/Museo500-Regular.ttf') format('truetype'),
							url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/careplus-font-toolkit/Museo500-Regular.svg#Museo500-Regular') format('svg')`,
					fontStyle: 'normal',
					fontWeight: 'normal',
					textRendering: 'optimizeLegibility',
				},
			],
			fontFamily: 'Museo500',
		},
	},
	centra: {
		mainColor: '#008b8e',
		// font: {
		// 	fontFamily: '"Impact", "Charcoal", sans-serif',
		// }
	},
	heineken: {
		mainColor: '#13670b',
	},
	spar: {
		mainColor: '#ee030c',
	},
	digicel: {
		mainColor: '#e5002b',
		font: {
			fontFace: [
				{
					fontFamily: 'Poppins',
					src: `url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins-Regular.eot'),
								url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
								url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins-Regular.woff') format('woff'),
								url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins-Regular.ttf') format('truetype'),
								url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins-Regular.svg#Poppins-Regular') format('svg')`,
					fontStyle: 'normal',
					fontWeight: 'normal',
					textRendering: 'optimizeLegibility',
				},
				{
					fontFamily: 'PoppinsBold',
					src: `url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins.eot'),
								url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins.eot?#iefix') format('embedded-opentype'),
								url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins.woff') format('woff'),
								url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins.ttf') format('truetype'),
								url('https://d18bwdr8c9yer4.cloudfront.net/public/domains/digicel-font-toolkit/Poppins.svg#Poppins') format('svg')`,
					fontStyle: 'normal',
					fontWeight: 'normal',
					textRendering: 'optimizeLegibility',
				},
			],
			fontFamily: 'Poppins',
		},
	},
};

export default domains;
