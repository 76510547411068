import React from 'react';
import PropTypes from 'prop-types';

import { FILTERS } from './filterSettings';
import { makeStyles } from '@mui/styles';
import { orange } from '@mui/material/colors';
import {
	Button,
	Menu,
	MenuItem,
	Typography,
	Checkbox,
	ListItemText,
	Chip,
	Select,
	FormControl,
	Box,
} from '@mui/material';

import {
	FilterList as FilterIcon,
	Done as ApplyIcon,
	Refresh as ResetIcon,
	Close as CloseIcon,
	Cancel as CancelIcon,
} from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { DateRangeSelector } from 'components';

const useStyles = makeStyles((theme) => ({
	dateRangeRoot: {
		width: '100%',
	},
	button: {
		padding: theme.spacing(0.5, 1),
		whiteSpace: 'nowrap',
	},

	filterPaper: {
		marginTop: theme.spacing(1),
		maxHeight: 300,
	},
	filtersContainer: {
		position: 'relative',
		maxWidth: 530,
		padding: theme.spacing(0.5, 2),
	},
	filterSection: {
		display: 'flex',
		flexDirection: 'column',
		'&::after': {
			content: `""`,
			border: 'none',
			height: 1,
			margin: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.12)',
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	datetimeFilterContainer: {
		display: 'flex',
		gap: theme.spacing(1),
		alignItems: 'center',
	},
	filterMenuItem: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	filtersBtnContainer: {
		display: 'flex',
		gap: theme.spacing(1),
		padding: theme.spacing(0.5, 1),
		alignItems: 'center',
		justifyContent: 'flex-end',
		position: 'sticky',
		bottom: 0,
		zIndex: 1,
		float: 'right',
	},
	// Multi-Select dropdown, chips filter CSS
	selectionWrapper: {
		flex: '0 1 auto',
	},
	formControl: {
		width: '100%',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		backgroundColor: orange[500],
	},
}));

function Filters({ selectedFilters, setSelectedFilters, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();
	const [filterMenuAnchor, setFilterMenuAnchor] = React.useState(null);
	// localFilters holds all filters that currently selected
	const [localFilters, setLocalFilters] = React.useState({});

	// openFilterDropdown contains searchableKey of Select dropdown, the value is set to it when Dropdown is opened(handleOpenFilterdropdown event) and initialize on Closed(handleCloseFilterdropdown event)
	const [openFilterDropdown, setOpenFilterDropdown] = React.useState('');

	const handleCloseFilterMenu = React.useCallback(() => setFilterMenuAnchor(null), []);
	const handleResetFilter = React.useCallback(() => {
		setSelectedFilters({});
		setFilterMenuAnchor(null);
	}, [setSelectedFilters]);

	const handleApplyFilters = React.useCallback(() => {
		setSelectedFilters(localFilters);
		setFilterMenuAnchor(null);
	}, [localFilters, setSelectedFilters]);

	// Multi Select dropdown Close button
	const handleCloseFilterdropdown = React.useCallback(() => {
		setOpenFilterDropdown('');
	}, []);

	// It will set which searchableKey is onOpen event.
	const handleOpenFilterdropdown = React.useCallback((searchableKey) => {
		setOpenFilterDropdown(searchableKey);
	}, []);

	const handleChangeFilterdropdown = React.useCallback(
		(event, searchableKey) => {
			const values = Array.isArray(event.target.value)
				? event.target.value
				: event.target.value
				? [event.target.value]
				: [];
			if (values.length > 0) {
				var filtered = values.filter((item) => {
					return item !== undefined;
				});
				if (filtered.length > 0) {
					setLocalFilters({
						...localFilters,
						[searchableKey]: filtered,
					});
				}
			} else {
				let currFilters = { ...localFilters };
				delete currFilters[searchableKey];
				setLocalFilters(currFilters);
			}
		},
		[localFilters]
	);

	// Delete the chip inside the select dropdown
	const handleDeleteChip = React.useCallback(
		(e, value, searchableKey) => {
			e.preventDefault();
			let selectedDataDelete = [...(localFilters[searchableKey] || [])];

			selectedDataDelete.splice(selectedDataDelete.indexOf(value), 1);
			if (selectedDataDelete.length > 0) {
				setLocalFilters({
					...localFilters,
					[searchableKey]: selectedDataDelete,
				});
			} else {
				let currFilters = { ...localFilters };
				delete currFilters[searchableKey];
				setLocalFilters(currFilters);
			}
		},
		[localFilters]
	);

	return (
		<Box>
			<Button
				endIcon={<FilterIcon />}
				className={classes.button}
				variant="contained"
				color="primary"
				onClick={(e) => {
					if (FILTERS.length > 0 && !filterMenuAnchor) {
						setLocalFilters(selectedFilters); // reset local filters by user selected filters when open up the filters menu
						setFilterMenuAnchor(e.currentTarget);
					}
				}}
				aria-controls="filters-menu"
				aria-haspopup="true"
			>
				{intl.formatMessage({
					id: 'pages.Admin.components.PlayerUniverse.FiltersBtnText',
				})}
			</Button>
			{/** filter menu popover */}
			<Menu
				id="filters-menu"
				classes={{ paper: classes.filterPaper }}
				// keepMounted
				anchorEl={filterMenuAnchor}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				// getContentAnchorEl={null}
				open={Boolean(filterMenuAnchor)}
				onClose={() => setFilterMenuAnchor(null)}
			>
				<div className={classes.filtersContainer}>
					{FILTERS.map((filter, idx) => {
						if (filter.type === 'datetime' || filter.type === 'date') {
							return (
								<section key={`${filter.searchableKey}-${idx}`} className={classes.filterSection}>
									<Typography
										component="span"
										variant="subtitle1"
										style={{ flex: `0 0 auto` }}
									>{`${filter.title}:`}</Typography>
									<div className={classes.datetimeFilterContainer}>
										<DateRangeSelector
											className={classes.dateRangeRoot}
											customStaticRanges={filter.options}
											selectedRange={localFilters[filter.searchableKey]}
											handleRangeChange={(dateRange) => {
												if (!dateRange) {
													// remove it from local filters
													let currFilters = { ...localFilters };
													delete currFilters[filter.searchableKey];
													setLocalFilters(currFilters);
												} else {
													setLocalFilters({
														...localFilters,
														[filter.searchableKey]: dateRange,
													});
												}
											}}
										/>
									</div>
								</section>
							);
						} else if (filter.type === 'select') {
							let selectedChipsInDropdown = [...(localFilters[filter.searchableKey] || [])];
							return (
								<section key={`${filter.searchableKey}-${idx}`} className={classes.filterSection}>
									<div className={classes.selectionWrapper}>
										<Typography variant="subtitle1">{`${filter.title}:`}</Typography>
										<FormControl className={classes.formControl}>
											<Select
												id={`${filter.searchableKey}-select-${idx}`}
												multiple={filter.multiple ?? true}
												value={
													filter.multiple
														? selectedChipsInDropdown
														: selectedChipsInDropdown[0] ?? ''
												}
												size="small"
												SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
												open={openFilterDropdown === filter.searchableKey}
												onClose={handleCloseFilterdropdown}
												onOpen={() => handleOpenFilterdropdown(filter.searchableKey)}
												onChange={(e) => handleChangeFilterdropdown(e, filter.searchableKey)}
												variant="outlined"
												renderValue={(selected) => (
													<div className={classes.chips}>
														{(Array.isArray(selected) ? selected : [selected]).map((value, id) => (
															<Chip
																key={id}
																label={value}
																clickable
																deleteIcon={
																	<CancelIcon onMouseDown={(event) => event.stopPropagation()} />
																}
																className={classes.chip}
																color="primary"
																onDelete={(e) => handleDeleteChip(e, value, filter.searchableKey)}
															/>
														))}
													</div>
												)}
											>
												{(filter.options || []).map((filterOptions, optIdx) => (
													<MenuItem
														key={`${filter.searchableKey}-option-${optIdx}`}
														className={classes.filterMenuItem}
														dense
														value={filterOptions.value}
													>
														<Checkbox
															checked={selectedChipsInDropdown.includes(filterOptions.value)}
														/>
														<ListItemText primary={filterOptions.label} />
													</MenuItem>
												))}
												{filter.multiple && (
													<div className={classes.filtersBtnContainer}>
														<Button
															variant="contained"
															size="small"
															color="primary"
															startIcon={<ApplyIcon />}
															onClick={() => handleCloseFilterdropdown()}
														>
															{intl.formatMessage({
																id: 'pages.MediaFiles.components.MediafileFilters.OkBtnText',
															})}
														</Button>
													</div>
												)}
											</Select>
										</FormControl>
									</div>
								</section>
							);
						} else {
							return null;
						}
					})}
				</div>
				<div className={classes.filtersBtnContainer}>
					<Button
						variant="contained"
						size="small"
						color="primary"
						startIcon={<ApplyIcon />}
						onClick={handleApplyFilters}
					>
						{intl.formatMessage({
							id: 'pages.MediaFiles.components.MediafileFilters.ApplyBtnText',
						})}
					</Button>
					<Button
						variant="contained"
						size="small"
						color="secondary"
						startIcon={<ResetIcon />}
						onClick={handleResetFilter}
					>
						{intl.formatMessage({ id: 'GENERAL.Reset' })}
					</Button>
					<Button
						variant="contained"
						size="small"
						startIcon={<CloseIcon />}
						onClick={handleCloseFilterMenu}
					>
						{intl.formatMessage({ id: 'GENERAL.Close' })}
					</Button>
				</div>
			</Menu>
		</Box>
	);
}

Filters.propTypes = {
	/**
	 * All filters selected by user
	 * format:
	 	{
			 [searchableKey]: [], // if type is checkbox
			 [searchableKey]: true/false, // if type is switch
			 [searchableKey]: [], // if type is select. item is value in the options
			 [searchableKey]: { // if type is datetime or date. NB: the searchableKey is missing "From" and "To" which are corresponding to startDate & endData in the value object
				  startDate: Date(),
					endDate: Date(),
			 }
		 }
	 */
	selectedFilters: PropTypes.object.isRequired,
	/**
	 * handler of setting user selected filters
	 * @param {object} filters. The updated filters
	 */
	setSelectedFilters: PropTypes.func.isRequired,
};
Filters.defaultProps = {};
export default Filters;
