import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

const TablePaginationActions = (props) => {
	const classes = useStyles();
	// page in props is 0-indexed, onPageChange in props requires 0-indexed number
	const { count, page, rowsPerPage, onPageChange } = props;

	return (
		<div className={classes.root}>
			<Pagination
				count={Math.ceil(count / rowsPerPage)}
				page={page + 1}
				showFirstButton
				showLastButton
				onChange={(e, newPage) => onPageChange(e, newPage - 1)}
			/>
		</div>
	);
};

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
