import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { ROUTES_PATH_SSO_LOGIN } from 'routes';

const SSOLogin = loadable(() => import('./components/SSOLogin/SSOLogin'), {
	fallback: <Loading size={50} />,
});

const SSOLoginRoutes = [
	{
		path: ROUTES_PATH_SSO_LOGIN,
		component: SSOLogin,
		isPublic: true,
	},
];
export default SSOLoginRoutes;
