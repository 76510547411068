import {
	GET_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
	GET_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
	GET_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	RESET_SCREENMANAGER_SCREEN_SCHEDULE_GETSTATUS,
} from 'redux/actions';
import {
	EDIT_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
	EDIT_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
	EDIT_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	RESET_SCREENMANAGER_SCREEN_SCHEDULE_EDITSTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
	ADD_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
	ADD_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	RESET_SCREENMANAGER_SCREEN_SCHEDULE_ADDSTATUS,
} from 'redux/actions';
import {
	DELETE_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
	DELETE_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
	DELETE_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
	RESET_SCREENMANAGER_SCREEN_SCHEDULE_DELETESTATUS,
} from 'redux/actions';
import {
	ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_REQUEST,
	ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_SUCCESS,
	ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_FAIL,
	RESET_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_ADDSTATUS,
} from 'redux/actions';
import { RESET_SCREENMANAGER_SCREEN_SCHEDULE } from 'redux/actions';

import config from 'config';

const screenApiBaseUrl = config.screenAPI.baseUrl;
const authHeaderKey = config.screenAPI.authHeaderKey;

// Get Screen (player) detail
// opts: {
// 	playerId: xxxxx,
// }
export const getScreenManagerScreenSchedules = (opts, cb) => (dispatch, getState) => {
	let reqUrl = `${screenApiBaseUrl}/players/${opts.playerId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		timeout: 300000,
	};
	return dispatch({
		types: [
			GET_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
			GET_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
			GET_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

export const resetScreenManagerScreenScheduleGetStatus = () => ({
	type: RESET_SCREENMANAGER_SCREEN_SCHEDULE_GETSTATUS,
});

// reset the whole screen schedule data store (including editing status, adding status, etc.)
export const resetScreenManagerScreenSchedule = () => ({
	type: RESET_SCREENMANAGER_SCREEN_SCHEDULE,
});

// Editing Screen (player) Schedule
/*
opts: {
	playerId: xxxxx,
	scheduleId: xxxx,
	reqData: {
		"startDatetime": "2019-01-01T10:05:10.874Z",
		"endDatetime": "2019-01-01T10:05:10.874Z",
		"schedules": [
			{
				"playlistId": "xxx123xxx123",
				"playlistName": "xxxxxxxxx",
				"recurrence": [
					{
						"DTSTART": "string",
						"RRULE": "string"
					}
				]
			}
		]
	}
}
*/
export const editScreenManagerScreenSchedule = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/players/${opts.playerId}/schedules/${opts.scheduleId}`,
		method: 'put',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			EDIT_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
			EDIT_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
			EDIT_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		newSchedule: {
			scheduleId: opts.scheduleId,
			...opts.reqData,
			timetable: opts.newTimetableWithPLName,
		},
		afterSuccess: cb,
	});
};

// Reset editing status
export const resetScreenManagerScreenScheduleEditStatus = () => ({
	type: RESET_SCREENMANAGER_SCREEN_SCHEDULE_EDITSTATUS,
});

// Adding Schedules to multiple Screens (player)
/*
opts: {
	playerIds: ['xxxxx', 'yyyy'],
	reqData: {
		"startDatetime": "2019-01-01T10:05:10.874Z",
		"endDatetime": "2019-01-01T10:05:10.874Z",
		"schedules": [
			{
				"playlistId": "xxx123xxx123",
				"recurrence": [
					{
						"DTSTART": "string",
						"RRULE": "string"
					}
				]
			}
		]
	}
}
*/
export const addScreenManagerSchedulesToMultipleScreens = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};

	return dispatch({
		types: [
			ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_REQUEST,
			ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_SUCCESS,
			ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_FAIL,
		],
		promise: (client) => {
			return opts.playerIds.reduce((promiseChain, playerId) => {
				return promiseChain.then(() => {
					let axiosConfig = {
						url: `${screenApiBaseUrl}/players/${playerId}/schedules`,
						method: 'post',
						data: opts.reqData,
						headers: reqHeader,
						timeout: 300000,
					};
					return client.request(axiosConfig);
				});
			}, Promise.resolve());
		},
		afterSuccess: cb,
	});
};

export const resetScreenManagerSchedulesToMultipleScreensAddStatus = () => ({
	type: RESET_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_ADDSTATUS,
});

// Adding Schedule to Screen (player)
/*
opts: {
	playerId: xxxxx,
	reqData: {
		"startDatetime": "2019-01-01T10:05:10.874Z",
		"endDatetime": "2019-01-01T10:05:10.874Z",
		"schedules": [
			{
				"playlistId": "xxx123xxx123",
				"playlistName": "xxxxxxxxx",
				"recurrence": [
					{
						"DTSTART": "string",
						"RRULE": "string"
					}
				]
			}
		]
	}
}
*/
export const addScreenManagerScreenSchedule = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};

	let axiosConfig = {
		url: `${screenApiBaseUrl}/players/${opts.playerId}/schedules`,
		method: 'post',
		data: opts.reqData,
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			ADD_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
			ADD_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
			ADD_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

export const resetScreenManagerScreenScheduleAddStatus = () => ({
	type: RESET_SCREENMANAGER_SCREEN_SCHEDULE_ADDSTATUS,
});

// Deleting schedule from Screen (player)
// opts: {
// playerId: xxxx,
// scheduleId: xxxx,
// }
export const deleteScreenManagerScheduleFromScreen = (opts, cb) => (dispatch, getState) => {
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: getState().authentication.token,
	};
	let axiosConfig = {
		url: `${screenApiBaseUrl}/players/${opts.playerId}/schedules/${opts.scheduleId}`,
		method: 'delete',
		headers: reqHeader,
		timeout: 300000,
	};

	return dispatch({
		types: [
			DELETE_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST,
			DELETE_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS,
			DELETE_SCREENMANAGER_SCREEN_SCHEDULE_FAIL,
		],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		// playerId: opts.playerId,
		scheduleId: opts.scheduleId,
		afterSuccess: cb,
	});
};

export const resetScreenManagerScreenScheduleDeleteStatus = () => ({
	type: RESET_SCREENMANAGER_SCREEN_SCHEDULE_DELETESTATUS,
});
