import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { _ } from 'utils/libHelper';
import { roundDecimals } from 'utils/generalHelper';
import { loadFontfaceToDocument } from 'utils/artwork/artUtilsCommon';
import GridInputTable from '../../GridInputTable/GridInputTable';

import { InputLabel, Popper, Paper, Tooltip, IconButton } from '@mui/material';

import { Replay as SameAsIcon } from '@mui/icons-material';

import {
	// StyledToggleButtonGroup,
	// ControlledStyledOutlinedTextField,
	// StyledOutlinedTextField,
	StyledOutlinedTextFieldSelection,
	SimpleSwitchRow,
	// SimpleSwitchVer,
	// SimpleRadioVer,
	SimpleNumberSlider,
	// StyledOutlinedSelectionWithSubheader,
	DividerVer,
	DividerHor,
	OutlinedNumberTextField,
	OutlinedInputWithSelect,
} from '../../CustomMUI/CustomMUI';

import { ART_VARIABLES, DesignContext } from '../../../Constants';

// import GridTableView from '../../GridTableView/GridTableView';

// intl lang
import { useIntl } from 'react-intl';

// constants
// const tableViewMaxWidth = 400;
// const minTableWidth = 250;
/**
 * GridPaletteStyles Component
 */
const useStylePaletteStyles = makeStyles((theme) => ({
	gridPaletteWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
		// width: `calc(100% - ${theme.spacing(2)})`,
		// margin: `0px ${theme.spacing(2)} 0px ${theme.spacing(1)}`,
		width: '100%',
		padding: theme.spacing(1, 2),
	},
	tableWrapper: {
		// width: '100%',
		// maxWidth: tableViewMaxWidth,
		// width: '100%',
		// position: 'relative',
		// position: 'sticky',
		// top: 0,
		// left: -30,
		// marginleft: 30,
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		background: '#fffeee',
		userSelect: 'none',
		// zIndex: 2,
	},
	// sampleTableSticky: {
	// 	left: '-100%',
	// 	// position: 'sticky',
	// 	// top: -1,
	// 	// paddingTop: 1,
	// },
	sampleTable: {
		borderCollapse: 'collapse',
		width: '100%' /*  minWidth: `${minTableWidth}px` */,
		border: `1px solid #cccccc`,
		'& td, th': {
			padding: `0.4rem`,
		},
		'& td>p:not(:first-child)': {
			fontSize: '0.7rem', // non-first line in table cell will have smaller font
		},
		'& td>p': {
			margin: 0, //`4px 0px`,
		},
	},
	tableDecorationColor: {
		backgroundColor: `rgba(181,204,244, 1)`, // theme.palette.secondary.light,
	},
	resetToSameAsBtn: {
		margin: theme.spacing(0, 1),
	},
	// // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
	// arrow: {
	// 	overflow: 'hidden',
	// 	position: 'absolute',
	// 	width: '1em',
	// 	height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
	// 	boxSizing: 'border-box',
	// 	color: theme.palette.background.paper,
	// 	'&::before': {
	// 		content: '""',
	// 		margin: 'auto',
	// 		display: 'block',
	// 		width: '100%',
	// 		height: '100%',
	// 		boxShadow: theme.shadows[1],
	// 		backgroundColor: 'currentColor',
	// 		transform: 'rotate(45deg)',
	// 	},
	// },
	groupsDiv: {
		// margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		// width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		width: '100%',
		marginTop: theme.spacing(2), // theme.spacing(2, 0),
		color: 'inherit',
		display: 'flex',
		fontSize: 'inherit',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	// itemBorder: {
	// 	marginTop: theme.spacing(2), // theme.spacing(2, 0),
	// 	borderColor: 'rgba(0, 0, 0, 0.23)',
	// 	border: '1px solid',
	// 	borderRadius: 4,
	// 	'&:hover': {
	// 		borderColor: 'rgba(0, 0, 0, 1)',
	// 	},
	// },
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	// groupRowLabel: {
	// 	width: '70%',
	// },
	fontSelect: {
		// paddingLeft: theme.spacing(1), // `0px ${theme.spacing(1)}`,
		// width: '100%', //`calc(60% - calc(${theme.spacing(1)} * 2))`,
		color: 'inherit',
		margin: 'inherit',
		flex: '1 1 auto',
	},
	fontsizeSelect: {
		// paddingRight: theme.spacing(1), //`0px ${theme.spacing(1)}`,
		// width: '60%', //'`calc(40% - calc(${theme.spacing(1)} * 2))`,
		margin: 'inherit',
		flex: '1 1 auto',
		color: 'inherit',
		fontSize: 'inherit',
		padding: 0,
		'& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]': {
			'-webkit-appearance': 'textfield',
			'-moz-appearance': 'textfield',
		},
	},
	subtitleFontSizeWrapper: {
		width: '100%',
		margin: 'inherit',
	},
	fontSizeUDInput: {
		width: '30%',
	},
	twoColumnsRowDiv: {
		justifyContent: 'space-between',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	fontsizeUserDefinedFormControlLabel: {
		margin: 0,
	},
	fontsizeUserDefinedFormControlLabelText: {
		fontSize: 'inherit',
		textAlign: 'center',
	},
	fontsizeUserDefinedFormControlCheckbox: {
		padding: 'unset',
	},
}));

export const GridPaletteStyles = ({
	field,
	artworkExtraData,
	updateField,
	setColorPicker,
	...rest
}) => {
	const intl = useIntl();
	const classes = useStylePaletteStyles();
	const designContext = React.useContext(DesignContext);
	// const tableWrapperRef = React.useRef(null);
	const paletteWrapperRef = React.useRef(null);
	// const arrowRef = React.useRef(null);
	// const [isTableSticky, setIsTableSticky] = React.useState(false);
	// user's configuration area. Enum: ['WHOLE_TABLE', 'HEADER_ROW', 'BODY_ROWS', 'TITLE_HEADER', 'TITLE_CELL', 'VALUE_HEADER', 'VALUE_CELL'] // ['WHOLE_TABLE', 'TITLE_COL', 'CONTENT_COL', 'HEADER_ROW', 'CONTENT_ROW']
	const [configArea, setConfigArea] = React.useState(null);
	const artworkFonts = artworkExtraData.fonts || [];

	// React.useEffect(() => {
	// 	const tableWrapperElem = tableWrapperRef.current;
	// 	const observer = new IntersectionObserver(
	// 		([e]) => {
	// 			console.log(
	// 				`is intersecting ${e.isIntersecting}, e.intersectionRatio ${e.intersectionRatio}`
	// 			);
	// 			if (e.intersectionRatio < 1) {
	// 				setIsTableSticky(true);
	// 			} else {
	// 				setIsTableSticky(false);
	// 			}
	// 		},
	// 		//  e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
	// 		{ threshold: [1] }
	// 	);
	// 	if (tableWrapperElem) {
	// 		observer.observe(tableWrapperElem);
	// 	}
	// 	return () => {
	// 		if (tableWrapperElem) observer.unobserve(tableWrapperElem);
	// 	};
	// }, [tableWrapperRef]);

	const fontsizeOptions = React.useMemo(() => {
		let fontsizeOptions = [];
		for (
			var size = ART_VARIABLES.gridFieldFontSizeMin, step = 2;
			size <= ART_VARIABLES.gridFieldFontSizeMax;

		) {
			fontsizeOptions.push({
				label: `${size} ${ART_VARIABLES.fieldFontSizeUnit}`,
				value: size,
			});
			if (size === 20) {
				step = 10;
			}
			size += step;
		}
		return fontsizeOptions;
	}, []);

	/**
	 * return config component by configuration area
	 * @param {string} area. ['TITLE_HEADER', 'TITLE_CELL', 'VALUE_HEADER', 'VALUE_CELL',] // ['WHOLE_TABLE', 'TITLE_HEADER', 'TITLE_CELL', 'VALUE_HEADER', 'VALUE_CELL', 'HEADER_ROW', 'CONTENT_ROW']
	 * @returns {React component}
	 */
	const getFontSectionCompByArea = (area) => {
		let sectionLabel = '',
			sameAsText = intl.formatMessage({
				id: 'pages.Artwork.components.ElementPalette.GridPalette.sameAsTitleHeaderText',
			}),
			fontfaceKey = '',
			fontsizeKey = '',
			subtitleFontsizeScaleKey = '',
			enableResetToSameAs = true;
		switch (area) {
			case 'TITLE_HEADER':
				sectionLabel = intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GridPalette.titleHeaderSectionLabel',
				});
				fontfaceKey = 'titleHeaderFontfaceName';
				fontsizeKey = 'titleHeaderFontsize';
				subtitleFontsizeScaleKey = '';
				enableResetToSameAs = false;
				break;
			case 'TITLE_CELL':
				sectionLabel = intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GridPalette.titleCellsSectionLabel',
				});
				fontfaceKey = 'titleCellFontfaceName';
				fontsizeKey = 'titleCellFontsize';
				subtitleFontsizeScaleKey = 'titleCellSubtitleFontsizeScale';
				break;
			case 'VALUE_HEADER':
				sectionLabel = intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GridPalette.valueHeaderSectionLabel',
				});
				fontfaceKey = 'valueHeaderFontfaceName';
				fontsizeKey = 'valueHeaderFontsize';
				subtitleFontsizeScaleKey = '';
				break;
			case 'VALUE_CELL':
				sectionLabel = intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GridPalette.valueCellsSectionLabel',
				});
				fontfaceKey = 'valueCellFontfaceName';
				fontsizeKey = 'valueCellFontsize';
				subtitleFontsizeScaleKey = 'valueCellSubtitleFontsizeScale';
				break;
			default:
				break;
		}

		const FontSection = (
			<div
				className={classes.groupsDiv}
				onMouseEnter={() => setConfigArea(area)}
				onMouseLeave={() => setConfigArea(null)}
			>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{
						// intl.formatMessage({ id: 'pages.Artwork.components.ElementPalette.GENERAL.fontText' })
						sectionLabel
					}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<StyledOutlinedTextFieldSelection
						className={classes.fontSelect}
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.fontFaceText',
						})}
						value={artworkFonts.length > 0 ? field[fontfaceKey] : ''}
						onChange={(e, child) => {
							if (e.target.value === ART_VARIABLES.placeholderSameAsText) {
								// reset to "same as"
								updateField({
									[fontfaceKey]: ART_VARIABLES.placeholderSameAsText,
								});
								return null;
							}
							loadFontfaceToDocument({
								name: e.target.value || ART_VARIABLES.DEFAULT_FONT,
								fontUrl: child.props.fontpath || ART_VARIABLES.DEFAULT_FONTPATH,
							}).then(({ error }) => {
								if (error) {
									designContext.notifyGeneral &&
										designContext.notifyGeneral(
											`${intl.formatMessage({
												id:
													'pages.Artwork.components.ElementPalette.GENERAL.cannotLoadFontPrefixText',
											})}: ${e.target.value || ART_VARIABLES.DEFAULT_FONT}`,
											'error'
										);
								} else {
									updateField({
										[fontfaceKey]: e.target.value || ART_VARIABLES.DEFAULT_FONT,
									});
								}
							});
						}}
						smallMenu
						smallIcon
						noneOption={artworkFonts.length === 0}
						noneMsg={artworkFonts.length === 0 ? 'Font list is not available' : 'None'}
						options={(enableResetToSameAs && artworkFonts.length > 0
							? [
									{
										label: (
											<em>
												<strong>{sameAsText}</strong>
											</em>
										),
										value: ART_VARIABLES.placeholderSameAsText,
										'data-fontpath': '',
										fontpath: '',
									},
							  ]
							: []
						).concat(
							artworkFonts.map((font) => ({
								label: font.font,
								value: font.path.substring(font.path.lastIndexOf('/') + 1),
								'data-fontpath': font.path,
								fontpath: font.path,
							}))
						)}
					/>
				</div>
				<DividerHor />
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<OutlinedInputWithSelect
						className={classes.fontsizeSelect}
						autoComplete="off"
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.fontSizeText',
						})}
						InputLabelProps={{ shrink: true }}
						inputSuffix={ART_VARIABLES.fieldFontSizeUnit}
						inputProps={{
							min: ART_VARIABLES.gridFieldFontSizeMin,
							max: ART_VARIABLES.gridFieldFontSizeMax,
							step: 1,
							type: 'number',
						}}
						value={
							(field[fontsizeKey] === ART_VARIABLES.placeholderSameAsText
								? ``
								: field[fontsizeKey]) || ''
						}
						placeholder={
							field[fontsizeKey] === ART_VARIABLES.placeholderSameAsText ? sameAsText : ''
						}
						onCommitted={(e) => {
							if (!e.target.value)
								return updateField({ [fontsizeKey]: ART_VARIABLES.placeholderSameAsText });
							// let val = Number(e.target.value || ART_VARIABLES.textFieldFontSizeDefault);
							let val = Number(e.target.value);
							if (val < ART_VARIABLES.gridFieldFontSizeMin) {
								updateField({
									[fontsizeKey]: ART_VARIABLES.gridFieldFontSizeMin,
								});
							} else if (val > ART_VARIABLES.gridFieldFontSizeMax) {
								updateField({
									[fontsizeKey]: ART_VARIABLES.gridFieldFontSizeMax,
								});
							} else {
								updateField({ [fontsizeKey]: val });
							}
						}}
						options={fontsizeOptions}
					/>
					{enableResetToSameAs && (
						<Tooltip title={sameAsText} placement="bottom" arrow>
							<IconButton
								className={classes.resetToSameAsBtn}
								aria-label={sameAsText}
								size="small"
								onClick={() => updateField({ [fontsizeKey]: ART_VARIABLES.placeholderSameAsText })}
							>
								<SameAsIcon />
							</IconButton>
						</Tooltip>
					)}
				</div>
				{subtitleFontsizeScaleKey && (
					<React.Fragment>
						<DividerHor />
						<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
							<StyledOutlinedTextFieldSelection
								select
								className={classes.subtitleFontSizeWrapper}
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GridPalette.subtitleFontsizeLabel',
								})}
								value={
									field[subtitleFontsizeScaleKey]
										? roundDecimals(field[subtitleFontsizeScaleKey] * 100, 0)
										: ''
								}
								onChange={(e) =>
									updateField({
										[subtitleFontsizeScaleKey]: e.target.value
											? roundDecimals(Number(e.target.value) / 100, 2)
											: ART_VARIABLES.defaultGridSubtitleFontsizeScale,
									})
								}
								options={_.range(10, 101, 5).map((displayAtPercentageNum) => ({
									label: `${displayAtPercentageNum}%`,
									value: displayAtPercentageNum,
								}))}
							/>
						</div>
					</React.Fragment>
				)}
			</div>
		);
		return FontSection;
	};

	return (
		<div className={classes.gridPaletteWrapper} ref={paletteWrapperRef}>
			<Popper
				placement="left-start"
				open={Boolean((paletteWrapperRef.current || {}).parentElement)}
				anchorEl={(paletteWrapperRef.current || {}).parentElement}
				// disablePortal={true}
				// modifiers={{
				// 	flip: {
				// 		enabled: true,
				// 	},
				// 	// preventOverflow: {
				// 	// 	enabled: true,
				// 	// 	boundariesElement: 'scrollParent',
				// 	// },
				// 	arrow: {
				// 		enabled: true,
				// 		element: arrowRef.current,
				// 	},
				// }}
			>
				<Paper className={classes.tableWrapper} elevation={3}>
					{
						// <span className={classes.arrow} ref={arrowRef} />
					}
					<table className={classes.sampleTable} border="1">
						<thead>
							<tr>
								<th
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'HEADER_ROW',
											'TITLE_HEADER',
										].includes(configArea),
									})}
								>
									Product
								</th>
								<th
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'HEADER_ROW',
											'VALUE_HEADER',
										].includes(configArea),
									})}
								>
									Small
								</th>
								<th
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'HEADER_ROW',
											'VALUE_HEADER',
										].includes(configArea),
									})}
								>
									Large
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'TITLE_CELL',
											'BODY_ROWS',
										].includes(configArea),
									})}
								>
									<p>Cappuccino</p>
									<p>subtitle</p>
								</td>
								<td
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'VALUE_CELL',
											'BODY_ROWS',
										].includes(configArea),
									})}
								>
									<p>€1.99</p>
									<p>subtitle</p>
								</td>
								<td
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'VALUE_CELL',
											'BODY_ROWS',
										].includes(configArea),
									})}
								>
									<p>€2.99</p>
									<p>subtitle</p>
								</td>
							</tr>
							<tr>
								<td
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'TITLE_CELL',
											'BODY_ROWS',
										].includes(configArea),
									})}
								>
									<p>Mocha</p>
									<p>subtitle</p>
								</td>
								<td
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'VALUE_CELL',
											'BODY_ROWS',
										].includes(configArea),
									})}
								>
									<p>€2.49</p>
									<p>subtitle</p>
								</td>
								<td
									className={cx({
										[classes.tableDecorationColor]: [
											'WHOLE_TABLE',
											'VALUE_CELL',
											'BODY_ROWS',
										].includes(configArea),
									})}
								>
									<p>€3.99</p>
									<p>subtitle</p>
								</td>
							</tr>
						</tbody>
					</table>
				</Paper>
			</Popper>

			<div
				className={classes.groupsDiv}
				style={{ marginTop: 0 }}
				onMouseEnter={() => setConfigArea('WHOLE_TABLE')}
				onMouseLeave={() => setConfigArea(null)}
			>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GridPalette.globalSectionLabel',
					})}
				</InputLabel>
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<span style={{ margin: '0px 4px', width: 90 }}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.fontColorText',
						})}
					</span>
					<div
						style={{
							display: 'inline-flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: `calc(100% - 90px)`,
						}}
					>
						<div
							style={{
								backgroundColor:
									field.fontColor && field.fontColor.hex ? field.fontColor.hex : '#000',
								width: '80%',
								// height: 25,
								minHeight: 25,
								margin: '0px 4px',
								cursor: 'pointer',
								borderRadius: 4,
							}}
							onClick={(e) =>
								setColorPicker({
									anchorEl: e.currentTarget,
									fieldKey: 'fontColor',
									disableAlpha: true,
									enablePantone: true,
									enableCMYK: true,
								})
							}
						></div>
					</div>
				</div>
				<DividerHor />
				<div
					className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv /* , classes.itemBorder */)}
				>
					<SimpleSwitchRow
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GridPalette.allCapLabel',
						})}
						style={{ padding: '4px 0px' }}
						checked={Boolean(field.allCap)}
						onChange={(e, checked) => updateField({ allCap: checked })}
					/>
				</div>
				<DividerHor />
				<div
					className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}
					style={{ flexDirection: 'column' }}
				>
					<SimpleSwitchRow
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GridPalette.userDefinedFontsizeLabel',
						})}
						style={{ padding: '4px 0px' }}
						checked={Boolean(field.fontsizeUserDefined)}
						onChange={(e, checked) =>
							updateField({
								fontsizeUserDefined: checked,
								fontsizeUDStart: !checked ? null : field.fontsizeUDStart,
								fontsizeUDStep: !checked ? null : field.fontsizeUDStep,
								fontsizeUDEnd: !checked ? null : field.fontsizeUDEnd,
							})
						}
					/>

					{field.fontsizeUserDefined && (
						<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
							<OutlinedNumberTextField
								className={classes.fontSizeUDInput}
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedStartText',
								})}
								value={field.fontsizeUDStart || ''}
								step={1}
								min={1}
								max={field.fontsizeUDEnd ? field.fontsizeUDEnd - 1 : undefined}
								handleOnChange={(num) => updateField({ fontsizeUDStart: num })}
							/>
							<OutlinedNumberTextField
								className={classes.fontSizeUDInput}
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedIncrementText',
								})}
								value={field.fontsizeUDStep || ''}
								step={1}
								min={1}
								handleOnChange={(num) => updateField({ fontsizeUDStep: num })}
							/>
							<OutlinedNumberTextField
								className={classes.fontSizeUDInput}
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.GENERAL.userDefinedEndText',
								})}
								value={field.fontsizeUDEnd || ''}
								step={1}
								min={field.fontsizeUDStart ? field.fontsizeUDStart + 1 : 1}
								handleOnChange={(num) => updateField({ fontsizeUDEnd: num })}
							/>
						</div>
					)}
				</div>
			</div>
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GridPalette.ruleSectionLabel',
					})}
				</InputLabel>
				<div
					className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv /* , classes.itemBorder */)}
					onMouseEnter={() => setConfigArea('HEADER_ROW')}
					onMouseLeave={() => setConfigArea(null)}
				>
					<SimpleNumberSlider
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GridPalette.headerRuleLabel',
						})}
						value={field.headerRule}
						handleOnChange={(value) => updateField({ headerRule: value })}
						step={ART_VARIABLES.gridFieldRuleStep}
						min={ART_VARIABLES.gridFieldRuleMin}
						max={ART_VARIABLES.gridFieldRuleMax}
					/>
					px
					<DividerVer />
					<div
						style={{
							display: 'inline-flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: `25%`,
							padding: `0px 4px`,
						}}
					>
						<div
							style={{
								backgroundColor:
									field.headerRuleColor && field.headerRuleColor.hex
										? field.headerRuleColor.hex
										: '#000',
								width: '100%',
								minHeight: 25,
								cursor: 'pointer',
								borderRadius: 4,
							}}
							onClick={(e) =>
								setColorPicker({
									anchorEl: e.currentTarget,
									fieldKey: 'headerRuleColor',
									disableAlpha: true,
									enablePantone: true,
									enableCMYK: true,
								})
							}
						></div>
					</div>
				</div>
				<DividerHor />
				<div
					className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv /* , classes.itemBorder */)}
					onMouseEnter={() => setConfigArea('BODY_ROWS')}
					onMouseLeave={() => setConfigArea(null)}
				>
					<SimpleNumberSlider
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GridPalette.rowsInBodyRuleLabel',
						})}
						value={field.bodyRowsRule}
						handleOnChange={(value) => updateField({ bodyRowsRule: value })}
						step={ART_VARIABLES.gridFieldRuleStep}
						min={ART_VARIABLES.gridFieldRuleMin}
						max={ART_VARIABLES.gridFieldRuleMax}
					/>
					px
					<DividerVer />
					<div
						style={{
							display: 'inline-flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: `25%`,
							padding: `0px 4px`,
						}}
					>
						<div
							style={{
								backgroundColor:
									field.bodyRowsRuleColor && field.bodyRowsRuleColor.hex
										? field.bodyRowsRuleColor.hex
										: '#000',
								width: '100%',
								minHeight: 25,
								cursor: 'pointer',
								borderRadius: 4,
							}}
							onClick={(e) =>
								setColorPicker({
									anchorEl: e.currentTarget,
									fieldKey: 'bodyRowsRuleColor',
									disableAlpha: true,
									enablePantone: true,
									enableCMYK: true,
								})
							}
						></div>
					</div>
				</div>
			</div>

			{getFontSectionCompByArea('TITLE_HEADER')}
			{getFontSectionCompByArea('TITLE_CELL')}
			{getFontSectionCompByArea('VALUE_HEADER')}
			{getFontSectionCompByArea('VALUE_CELL')}
		</div>
	);
};

GridPaletteStyles.propTypes = {
	field: PropTypes.object.isRequired,
	/**
	 * artwork extra data
	 */
	artworkExtraData: PropTypes.object.isRequired,
	/**
	 * Function to update grid field data
	 */
	updateField: PropTypes.func.isRequired,
	/**
	 * Handle open color picker
	 * @param {object} colorPickerOptions
	 */
	setColorPicker: PropTypes.func.isRequired,
};

GridPaletteStyles.defaultProps = {};

/**
 * GridPaletteData Component
 */
const useDataPaletteStyles = makeStyles((theme) => ({
	tabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
		width: `calc(100% - calc(${theme.spacing(1.5)} * 2))`,
		// width: '100%',
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	gridInputTableWrapper: {
		width: '100%',
		padding: theme.spacing(1),
	},

	// groupRowDiv: {
	// 	width: '100%',
	// 	padding: `0px ${theme.spacing(2)}`,
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'space-evenly',
	// },
}));
export const GridPaletteData = ({ field, updateField, ...rest }) => {
	const classes = useDataPaletteStyles();
	const intl = useIntl();
	return (
		<div className={classes.tabWrapper}>
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GridPalette.defaultGridDataLabel',
					})}
				</InputLabel>
				<div className={classes.gridInputTableWrapper}>
					<GridInputTable
						disableEditing={false}
						disableControls={true}
						initialTableHtmlText={field.defaultEditorHtml}
						handleEditorOnBlur={(editorHtml) => {
							if (editorHtml) updateField({ defaultEditorHtml: editorHtml });
							else updateField({ defaultEditorHtml: ART_VARIABLES.sampleGridTableText });
						}}
					/>
				</div>
			</div>
		</div>
	);
};

GridPaletteData.propTypes = {
	/**
	 * The grid field object
	 */
	field: PropTypes.object.isRequired,
	/**
	 * Function to update grid field data
	 */
	updateField: PropTypes.func.isRequired,
};

GridPaletteData.defaultProps = {};
