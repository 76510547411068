// screen manager screens
export const FETCH_SCREENMANAGER_SCREENS = 'FETCH_SCREENMANAGER_SCREENS';
export const FETCH_SCREENMANAGER_SCREENS_SUCCESS = 'FETCH_SCREENMANAGER_SCREENS_SUCCESS';
export const FETCH_SCREENMANAGER_SCREENS_FAIL = 'FETCH_SCREENMANAGER_SCREENS_FAIL';
export const RESET_SCREENMANAGER_SCREENS = 'RESET_SCREENMANAGER_SCREENS';
export const EDIT_SCREENMANAGER_SCREENS_REQUEST = 'EDIT_SCREENMANAGER_SCREENS_REQUEST';
export const EDIT_SCREENMANAGER_SCREENS_SUCCESS = 'EDIT_SCREENMANAGER_SCREENS_SUCCESS';
export const EDIT_SCREENMANAGER_SCREENS_FAIL = 'EDIT_SCREENMANAGER_SCREENS_FAIL';
export const RESET_SCREENMANAGER_SCREENS_EDITSTATUS = 'RESET_SCREENMANAGER_SCREENS_EDITSTATUS';
export const ADD_SCREENMANAGER_SCREENS_REQUEST = 'ADD_SCREENMANAGER_SCREENS_REQUEST';
export const ADD_SCREENMANAGER_SCREENS_FAIL = 'ADD_SCREENMANAGER_SCREENS_FAIL';
export const ADD_SCREENMANAGER_SCREENS_SUCCESS = 'ADD_SCREENMANAGER_SCREENS_SUCCESS';
export const RESET_SCREENMANAGER_SCREENS_ADDSTATUS = 'RESET_SCREENMANAGER_SCREENS_ADDSTATUS';
export const DELETE_SCREENMANAGER_SCREENS_REQUEST = 'DELETE_SCREENMANAGER_SCREENS_REQUEST';
export const DELETE_SCREENMANAGER_SCREENS_SUCCESS = 'DELETE_SCREENMANAGER_SCREENS_SUCCESS';
export const DELETE_SCREENMANAGER_SCREENS_FAIL = 'DELETE_SCREENMANAGER_SCREENS_FAIL';
export const RESET_SCREENMANAGER_SCREENS_DELETESTATUS = 'RESET_SCREENMANAGER_SCREENS_DELETESTATUS';

// screen manager playlists
export const FETCH_SCREENMANAGER_PLAYLISTS = 'FETCH_SCREENMANAGER_PLAYLISTS';
export const FETCH_SCREENMANAGER_PLAYLISTS_SUCCESS = 'FETCH_SCREENMANAGER_PLAYLISTS_SUCCESS';
export const FETCH_SCREENMANAGER_PLAYLISTS_FAIL = 'FETCH_SCREENMANAGER_PLAYLISTS_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS = 'RESET_SCREENMANAGER_PLAYLISTS';
export const EDIT_SCREENMANAGER_PLAYLISTS_REQUEST = 'EDIT_SCREENMANAGER_PLAYLISTS_REQUEST';
export const EDIT_SCREENMANAGER_PLAYLISTS_SUCCESS = 'EDIT_SCREENMANAGER_PLAYLISTS_SUCCESS';
export const EDIT_SCREENMANAGER_PLAYLISTS_FAIL = 'EDIT_SCREENMANAGER_PLAYLISTS_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS_EDITSTATUS = 'RESET_SCREENMANAGER_PLAYLISTS_EDITSTATUS';
export const ADD_SCREENMANAGER_PLAYLISTS_REQUEST = 'ADD_SCREENMANAGER_PLAYLISTS_REQUEST';
export const ADD_SCREENMANAGER_PLAYLISTS_SUCCESS = 'ADD_SCREENMANAGER_PLAYLISTS_SUCCESS';
export const ADD_SCREENMANAGER_PLAYLISTS_FAIL = 'ADD_SCREENMANAGER_PLAYLISTS_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS_ADDSTATUS = 'RESET_SCREENMANAGER_PLAYLISTS_ADDSTATUS';
export const DELETE_SCREENMANAGER_PLAYLISTS_REQUEST = 'DELETE_SCREENMANAGER_PLAYLISTS_REQUEST';
export const DELETE_SCREENMANAGER_PLAYLISTS_SUCCESS = 'DELETE_SCREENMANAGER_PLAYLISTS_SUCCESS';
export const DELETE_SCREENMANAGER_PLAYLISTS_FAIL = 'DELETE_SCREENMANAGER_PLAYLISTS_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS_DELETESTATUS =
	'RESET_SCREENMANAGER_PLAYLISTS_DELETESTATUS';
export const DUPLICATE_SCREENMANAGER_PLAYLIST_REQUEST = 'DUPLICATE_SCREENMANAGER_PLAYLIST_REQUEST';
export const DUPLICATE_SCREENMANAGER_PLAYLIST_SUCCESS = 'DUPLICATE_SCREENMANAGER_PLAYLIST_SUCCESS';
export const DUPLICATE_SCREENMANAGER_PLAYLIST_FAIL = 'DUPLICATE_SCREENMANAGER_PLAYLIST_FAIL';
// screen manager playlist slides
export const GET_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST =
	'GET_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST';
export const GET_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS =
	'GET_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS';
export const GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL = 'GET_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS_SLIDE_GETSTATUS =
	'RESET_SCREENMANAGER_PLAYLISTS_SLIDE_GETSTATUS';

export const EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST =
	'EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST';
export const EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS =
	'EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS';
export const EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL = 'EDIT_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS_SLIDE_EDITSTATUS =
	'RESET_SCREENMANAGER_PLAYLISTS_SLIDE_EDITSTATUS';

export const ADD_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST =
	'ADD_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST';
export const ADD_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS =
	'ADD_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS';
export const ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL = 'ADD_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS_SLIDE_ADDSTATUS =
	'RESET_SCREENMANAGER_PLAYLISTS_SLIDE_ADDSTATUS';

export const DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST =
	'DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST';
export const DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS =
	'DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS';
export const DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL =
	'DELETE_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS_SLIDE_DELETESTATUS =
	'RESET_SCREENMANAGER_PLAYLISTS_SLIDE_DELETESTATUS';

export const REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST =
	'REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_REQUEST';
export const REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS =
	'REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_SUCCESS';
export const REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL =
	'REORDER_SCREENMANAGER_PLAYLISTS_SLIDE_FAIL';
export const RESET_SCREENMANAGER_PLAYLISTS_SLIDE_REORDERSTATUS =
	'RESET_SCREENMANAGER_PLAYLISTS_SLIDE_REORDERSTATUS';

export const RESET_SCREENMANAGER_PLAYLIST_SLIDE = 'RESET_SCREENMANAGER_PLAYLIST_SLIDE';

// screen manager player schedules
export const GET_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST =
	'GET_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST';
export const GET_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS =
	'GET_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS';
export const GET_SCREENMANAGER_SCREEN_SCHEDULE_FAIL = 'GET_SCREENMANAGER_SCREEN_SCHEDULE_FAIL';
export const RESET_SCREENMANAGER_SCREEN_SCHEDULE_GETSTATUS =
	'RESET_SCREENMANAGER_SCREEN_SCHEDULE_GETSTATUS';

export const RESET_SCREENMANAGER_SCREEN_SCHEDULE = 'RESET_SCREENMANAGER_SCREEN_SCHEDULE';

export const EDIT_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST =
	'EDIT_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST';
export const EDIT_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS =
	'EDIT_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS';
export const EDIT_SCREENMANAGER_SCREEN_SCHEDULE_FAIL = 'EDIT_SCREENMANAGER_SCREEN_SCHEDULE_FAIL';
export const RESET_SCREENMANAGER_SCREEN_SCHEDULE_EDITSTATUS =
	'RESET_SCREENMANAGER_SCREEN_SCHEDULE_EDITSTATUS';

export const ADD_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST =
	'ADD_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST';
export const ADD_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS =
	'ADD_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS';
export const ADD_SCREENMANAGER_SCREEN_SCHEDULE_FAIL = 'ADD_SCREENMANAGER_SCREEN_SCHEDULE_FAIL';
export const RESET_SCREENMANAGER_SCREEN_SCHEDULE_ADDSTATUS =
	'RESET_SCREENMANAGER_SCREEN_SCHEDULE_ADDSTATUS';

export const DELETE_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST =
	'DELETE_SCREENMANAGER_SCREEN_SCHEDULE_REQUEST';
export const DELETE_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS =
	'DELETE_SCREENMANAGER_SCREEN_SCHEDULE_SUCCESS';
export const DELETE_SCREENMANAGER_SCREEN_SCHEDULE_FAIL =
	'DELETE_SCREENMANAGER_SCREEN_SCHEDULE_FAIL';
export const RESET_SCREENMANAGER_SCREEN_SCHEDULE_DELETESTATUS =
	'RESET_SCREENMANAGER_SCREEN_SCHEDULE_DELETESTATUS';

export const ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_REQUEST =
	'ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_REQUEST';
export const ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_SUCCESS =
	'ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_SUCCESS';
export const ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_FAIL =
	'ADD_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_FAIL';
export const RESET_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_ADDSTATUS =
	'RESET_SCREENMANAGER_SCHEDULE_TO_MULTIPLE_SCREENS_ADDSTATUS';

// screen manager departments
export const FETCH_SCREENMANAGER_DEPARTMENTS = 'FETCH_SCREENMANAGER_DEPARTMENTS';
export const FETCH_SCREENMANAGER_DEPARTMENTS_SUCCESS = 'FETCH_SCREENMANAGER_DEPARTMENTS_SUCCESS';
export const FETCH_SCREENMANAGER_DEPARTMENTS_FAIL = 'FETCH_SCREENMANAGER_DEPARTMENTS_FAIL';
export const RESET_SCREENMANAGER_DEPARTMENTS = 'RESET_SCREENMANAGER_DEPARTMENTS';
export const EDIT_SCREENMANAGER_DEPARTMENTS_REQUEST = 'EDIT_SCREENMANAGER_DEPARTMENTS_REQUEST';
export const EDIT_SCREENMANAGER_DEPARTMENTS_SUCCESS = 'EDIT_SCREENMANAGER_DEPARTMENTS_SUCCESS';
export const EDIT_SCREENMANAGER_DEPARTMENTS_FAIL = 'EDIT_SCREENMANAGER_DEPARTMENTS_FAIL';
export const RESET_SCREENMANAGER_DEPARTMENTS_EDITSTATUS =
	'RESET_SCREENMANAGER_DEPARTMENTS_EDITSTATUS';
export const ADD_SCREENMANAGER_DEPARTMENTS_REQUEST = 'ADD_SCREENMANAGER_DEPARTMENTS_REQUEST';
export const ADD_SCREENMANAGER_DEPARTMENTS_SUCCESS = 'ADD_SCREENMANAGER_DEPARTMENTS_SUCCESS';
export const ADD_SCREENMANAGER_DEPARTMENTS_FAIL = 'ADD_SCREENMANAGER_DEPARTMENTS_FAIL';
export const RESET_SCREENMANAGER_DEPARTMENTS_ADDSTATUS =
	'RESET_SCREENMANAGER_DEPARTMENTS_ADDSTATUS';
export const DELETE_SCREENMANAGER_DEPARTMENTS_REQUEST = 'DELETE_SCREENMANAGER_DEPARTMENTS_REQUEST';
export const DELETE_SCREENMANAGER_DEPARTMENTS_SUCCESS = 'DELETE_SCREENMANAGER_DEPARTMENTS_SUCCESS';
export const DELETE_SCREENMANAGER_DEPARTMENTS_FAIL = 'DELETE_SCREENMANAGER_DEPARTMENTS_FAIL';
export const RESET_SCREENMANAGER_DEPARTMENTS_DELETESTATUS =
	'RESET_SCREENMANAGER_DEPARTMENTS_DELETESTATUS';

// screen manager screenboard
export const FETCH_SCREENMANAGER_SCREENBOARD = 'FETCH_SCREENMANAGER_SCREENBOARD';
export const FETCH_SCREENMANAGER_SCREENBOARD_SUCCESS = 'FETCH_SCREENMANAGER_SCREENBOARD_SUCCESS';
export const FETCH_SCREENMANAGER_SCREENBOARD_FAIL = 'FETCH_SCREENMANAGER_SCREENBOARD_FAIL';

// screen manager Playlist Universe
export const FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE = 'FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE';
export const FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS =
	'FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS';
export const FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL =
	'FETCH_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL';
export const EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_REQUEST =
	'EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_REQUEST';
export const EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS =
	'EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_SUCCESS';
export const EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL =
	'EDIT_SCREENMANAGER_PLAYLIST_UNIVERSE_FAIL';

// authentication
export const AUTH_HANDLEAUTH_SUCCESS = 'AUTH_HANDLEAUTH_SUCCESS';
export const AUTH_HANDLEAUTH_FAILED = 'AUTH_HANDLEAUTH_FAILED';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// lang
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

// sidebar
export const TURN_ON_SIDEBAR = 'TURN_ON_SIDEBAR';
export const TURN_OFF_SIDEBAR = 'TURN_OFF_SIDEBAR';

// artwork designer
export const RESET_DESIGNER_TEMPLATE = 'RESET_DESIGNER_TEMPLATE';
// export const GET_DESIGNER_TEMPLATE = 'GET_DESIGNER_TEMPLATE';
export const FETCH_ARTWORK_DESIGN_TEMPLATE = 'FETCH_ARTWORK_DESIGN_TEMPLATE';
export const FETCH_ARTWORK_DESIGN_TEMPLATE_SUCCESS = 'FETCH_ARTWORK_DESIGN_TEMPLATE_SUCCESS';
export const FETCH_ARTWORK_DESIGN_TEMPLATE_FAIL = 'FETCH_ARTWORK_DESIGN_TEMPLATE_FAIL';
export const RESET_FETCH_ARTWORK_DESIGN_TEMPLATE = 'RESET_FETCH_ARTWORK_DESIGN_TEMPLATE';
export const SET_LOCAL_DESIGN_TEMPLATE_BY_REMOTE_TEMPLATE =
	'SET_LOCAL_DESIGN_TEMPLATE_BY_REMOTE_TEMPLATE';
export const UPDATE_LOCAL_DESIGNER_TEMPLATE = 'UPDATE_LOCAL_DESIGNER_TEMPLATE';
export const SET_ARTWORK_DESIGN_OPERATION_STATE = 'SET_ARTWORK_DESIGN_OPERATION_STATE';
// export const SAVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS = 'SAVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS';
// export const SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL = 'SAVE_OFFLINE_DESIGNER_TEMPLATE_FAIL';
// export const RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS =
// 	'RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_SUCCESS';
// export const RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL = 'RETRIEVE_OFFLINE_DESIGNER_TEMPLATE_FAIL';

// export const ARTWORK_SAVE_FILE = 'ARTWORK_SAVE_FILE';
// export const ARTWORK_SAVE_FILE_SUCCESS = 'ARTWORK_SAVE_FILE_SUCCESS';
// export const ARTWORK_SAVE_FILE_FAIL = 'ARTWORK_SAVE_FILE_FAIL';
// export const ARTWORK_RESET_SAVE_FILE_STATUS = 'ARTWORK_RESET_SAVE_FILE_STATUS';

export const ARTWORK_IMPORT_TEMPLATE = 'ARTWORK_IMPORT_TEMPLATE';

// artwork helper
export const SAVE_ARTWORK_DESIGN_TEMPLATE = 'SAVE_ARTWORK_DESIGN_TEMPLATE';
export const SAVE_ARTWORK_DESIGN_TEMPLATE_SUCCESS = 'SAVE_ARTWORK_DESIGN_TEMPLATE_SUCCESS';
export const SAVE_ARTWORK_DESIGN_TEMPLATE_FAIL = 'SAVE_ARTWORK_DESIGN_TEMPLATE_FAIL';

export const FETCH_ARTWORK_FONTS = 'FETCH_ARTWORK_FONTS';
export const FETCH_ARTWORK_FONTS_SUCCESS = 'FETCH_ARTWORK_FONTS_SUCCESS';
export const FETCH_ARTWORK_FONTS_FAIL = 'FETCH_ARTWORK_FONTS_FAIL';
export const RESET_ARTWORK_FONTS = 'RESET_ARTWORK_FONTS';

export const FETCH_ARTWORK_SPREADSHEETS = 'FETCH_ARTWORK_SPREADSHEETS';
export const FETCH_ARTWORK_SPREADSHEETS_SUCCESS = 'FETCH_ARTWORK_SPREADSHEETS_SUCCESS';
export const FETCH_ARTWORK_SPREADSHEETS_FAIL = 'FETCH_ARTWORK_SPREADSHEETS_FAIL';
export const RESET_ARTWORK_SPREADSHEETS = 'RESET_ARTWORK_SPREADSHEETS';

export const FETCH_ARTWORK_LISTS = 'FETCH_ARTWORK_LISTS';
export const FETCH_ARTWORK_LISTS_SUCCESS = 'FETCH_ARTWORK_LISTS_SUCCESS';
export const FETCH_ARTWORK_LISTS_FAIL = 'FETCH_ARTWORK_LISTS_FAIL';
export const RESET_ARTWORK_LISTS = 'RESET_ARTWORK_LISTS';

export const FETCH_ARTWORK_AUTOIMPORT = 'FETCH_ARTWORK_AUTOIMPORT';
export const FETCH_ARTWORK_AUTOIMPORT_SUCCESS = 'FETCH_ARTWORK_AUTOIMPORT_SUCCESS';
export const FETCH_ARTWORK_AUTOIMPORT_FAIL = 'FETCH_ARTWORK_AUTOIMPORT_FAIL';
export const RESET_ARTWORK_AUTOIMPORT = 'RESET_ARTWORK_AUTOIMPORT';

export const FETCH_ARTWORK_OUTPUTTEMPLATES = 'FETCH_ARTWORK_OUTPUTTEMPLATES';
export const FETCH_ARTWORK_OUTPUTTEMPLATES_SUCCESS = 'FETCH_ARTWORK_OUTPUTTEMPLATES_SUCCESS';
export const FETCH_ARTWORK_OUTPUTTEMPLATES_FAIL = 'FETCH_ARTWORK_OUTPUTTEMPLATES_FAIL';
export const RESET_ARTWORK_OUTPUTTEMPLATES = 'RESET_ARTWORK_OUTPUTTEMPLATES';

export const FETCH_ARTWORK_CATEGORIES = 'FETCH_ARTWORK_CATEGORIES';
export const FETCH_ARTWORK_CATEGORIES_SUCCESS = 'FETCH_ARTWORK_CATEGORIES_SUCCESS';
export const FETCH_ARTWORK_CATEGORIES_FAIL = 'FETCH_ARTWORK_CATEGORIES_FAIL';
export const RESET_ARTWORK_CATEGORIES = 'RESET_ARTWORK_CATEGORIES';

export const UNDO_ARTWORK_DESIGN_TEMPLATE = 'UNDO_ARTWORK_DESIGN_TEMPLATE';
export const REDO_ARTWORK_DESIGN_TEMPLATE = 'REDO_ARTWORK_DESIGN_TEMPLATE';

// AWS Resource
export const FETCH_AWS_RESOURCE_CREDENTIAL = 'FETCH_AWS_RESOURCE_CREDENTIAL';
export const FETCH_AWS_RESOURCE_CREDENTIAL_SUCCESS = 'FETCH_AWS_RESOURCE_CREDENTIAL_SUCCESS';
export const FETCH_AWS_RESOURCE_CREDENTIAL_FAIL = 'FETCH_AWS_RESOURCE_CREDENTIAL_FAIL';
export const RESET_AWS_RESOURCE_CREDENTIAL = 'RESET_AWS_RESOURCE_CREDENTIAL';

// File Manager
export const POST_PROCESS_UPLOADED_FILES = 'POST_PROCESS_UPLOADED_FILES';
export const POST_PROCESS_UPLOADED_FILES_SUCCESS = 'POST_PROCESS_UPLOADED_FILES_SUCCESS';
export const POST_PROCESS_UPLOADED_FILES_FAIL = 'POST_PROCESS_UPLOADED_FILES_FAIL';
export const RESET_POST_PROCESS_UPLOADED_FILES = 'RESET_POST_PROCESS_UPLOADED_FILES';
export const FETCH_PROGRESS_UPLOADED_FILES = 'FETCH_PROGRESS_UPLOADED_FILES';
export const FETCH_PROGRESS_UPLOADED_SUCCESS = 'FETCH_PROGRESS_UPLOADED_SUCCESS';
export const FETCH_PROGRESS_UPLOADED_FAIL = 'FETCH_PROGRESS_UPLOADED_FAIL';
export const RESET_PROGRESS_UPLOADED_FILES = 'RESET_PROGRESS_UPLOADED_FILES';

export const FETCH_FILE_CATEGORIES = 'FETCH_FILE_CATEGORIES';
export const FETCH_FILE_CATEGORIES_SUCCESS = 'FETCH_FILE_CATEGORIES_SUCCESS';
export const FETCH_FILE_CATEGORIES_FAIL = 'FETCH_FILE_CATEGORIES_FAIL';
export const RESET_FILE_CATEGORIES = 'RESET_FILE_CATEGORIES';

export const FILEMANAGER_SEARCH_MEDIAFILES = 'FILEMANAGER_SEARCH_MEDIAFILES';
export const FILEMANAGER_SEARCH_MEDIAFILES_SUCCESS = 'FILEMANAGER_SEARCH_MEDIAFILES_SUCCESS';
export const FILEMANAGER_SEARCH_MEDIAFILES_FAIL = 'FILEMANAGER_SEARCH_MEDIAFILES_FAIL';
export const FILEMANAGER_RESET_SEARCHED_MEDIAFILES = 'FILEMANAGER_RESET_SEARCHED_MEDIAFILES';

export const FILEMANAGER_FETCH_LIGHTBOXES = 'FILEMANAGER_FETCH_LIGHTBOXES';
export const FILEMANAGER_FETCH_LIGHTBOXES_SUCCESS = 'FILEMANAGER_FETCH_LIGHTBOXES_SUCCESS';
export const FILEMANAGER_FETCH_LIGHTBOXES_FAIL = 'FILEMANAGER_FETCH_LIGHTBOXES_FAIL';
export const FILEMANAGER_RESET_LIGHTBOXES = 'FILEMANAGER_RESET_LIGHTBOXES';

export const FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID =
	'FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID';
export const FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_SUCCESS =
	'FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_SUCCESS';
export const FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_FAIL =
	'FILEMANAGER_FETCH_SPREADSHEET_CONTENT_BYID_FAIL';
export const FILEMANAGER_RESET_SPREADSHEET_CONTENT_BYID =
	'FILEMANAGER_RESET_SPREADSHEET_CONTENT_BYID';

export const FILEMANAGER_SEARCH_MEDIAFILE_COUNT = 'FILEMANAGER_SEARCH_MEDIAFILE_COUNT';
export const FILEMANAGER_SEARCH_MEDIAFILES_COUNT_SUCCESS =
	'FILEMANAGER_SEARCH_MEDIAFILES_COUNT_SUCCESS';
export const FILEMANAGER_SEARCH_MEDIAFILES_COUNT_FAIL = 'FILEMANAGER_SEARCH_MEDIAFILES_COUNT_FAIL';

// User Manager
export const FETCH_USERDATA = 'FETCH_USERDATA';
export const FETCH_USERDATA_SUCCESS = 'FETCH_USERDATA_SUCCESS';
export const FETCH_USERDATA_FAILED = 'FETCH_USERDATA_FAILED';
export const FETCH_USERDATABYGROUP_SUCCESS = 'FETCH_USERDATABYGROUP_SUCCESS';
export const RESET_USERDATABYGROUP = 'RESET_USERDATABYGROUP';

export const DOMAIN_SETTINGS = 'DOMAIN_SETTINGS';
export const DOMAIN_SETTINGS_SUCCESS = 'DOMAIN_SETTINGS_SUCCESS';
export const DOMAIN_SETTINGS_FAIL = 'DOMAIN_SETTINGS_FAIL';
export const EDIT_DOMAIN_SETTINGS = 'EDIT_DOMAIN_SETTINGS';
export const EDIT_DOMAIN_SETTINGS_SUCCESS = 'EDIT_DOMAIN_SETTINGS_SUCCESS';
export const EDIT_DOMAIN_SETTINGS_FAIL = 'EDIT_DOMAIN_SETTINGS_FAIL';
