import React from 'react';

// import config from 'config';

import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import {
	Button,
	Fab,
	FormControl,
	FormControlLabel,
	FormLabel,
	Input,
	InputLabel,
	Link,
	MenuItem,
	Paper,
	Select,
	Radio,
	RadioGroup,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { moment, _ } from 'utils/libHelper';
import { encryptStr } from 'utils/appHelper';
import { toLocaleDateTime } from 'utils/generalHelper.js';
import { injectIntl, FormattedMessage } from 'react-intl';
import { fetchBoxServiceReleases } from 'restful';

import {
	Search,
	RTTrComp,
	ScreenManagerGridView,
	// Dialog
} from 'components';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

// icons
import {
	Add as AddIcon,
	Save as SaveIcon,
	Report as InactiveIcon,
	Check as ActiveIcon,
	StayCurrentPortraitOutlined as PortraitIcon,
	StayCurrentLandscapeOutlined as LandscapeIcon,
	Edit as EditIcon,
	DeleteOutline as DeleteIcon,
	Cancel as CancelIcon,
} from '@mui/icons-material';

// CSS
import ScreensStyle from './ScreensStyle.jsx';

// redux
import { connect } from 'react-redux';
import {
	openGlobalDialog,
	resetGlobalDialog,
	fetchScreenManagerScreens,
	deleteScreenManagerScreen,
	notifyError,
	notifyGeneral,
	editScreenManagerScreen,
	addScreenManagerScreen,
	resetScreenManagerScreens,
	fetchScreenManagerPlaylists,
	fetchScreenManagerDepartments,
	addScreenManagerPlaylist,
	addScreenManagerScreenSchedule,
} from 'redux/actions'; // actions

// Constants
import {
	Min_Exclusive_Userlevel_To_Edit_PlayerCode,
	MIN_USERLEVEL_TO_ADD_LINUXBOX_SCREEN,
} from 'pages/ScreenManager/Constants';
import {
	ROUTES_PATH_SCREENMANAGER_SCREEN_SCHEDULE,
	ROUTES_PATH_SCREENMANAGER_SLIDES,
} from 'routes';

class Screens extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filtered: [],
			editing: {}, // the editing data of a screen
			adding: {}, // the adding data of a screen
			isDesktop: false,
		};
		this.enableAddingLinuxBox =
			this.props.authentication.userLevel >= MIN_USERLEVEL_TO_ADD_LINUXBOX_SCREEN;
		this.updateScreenResolution = this.updateScreenResolution.bind(this);
		this.columns = [
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.NameCol' }), //'Name',
				resizable: true,
				sortable: true,
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					return !editing ? (
						cellInfo.value
					) : (
						<Input
							className={this.props.classes.editingInput}
							inputProps={{
								'aria-label': 'screenName',
								style: {
									border: 'unset',
									boxShadow: 'unset',
									height: 34,
									backgroundColor: '#ffffff',
								},
							}}
							onChange={(event) => {
								this.setState({ editing: { ...currentEditing, name: event.target.value } });
							}}
							value={
								typeof currentEditing.name === 'undefined'
									? currentEditing.original.name
									: currentEditing.name
							}
						/>
					);
				},
			},
			{
				accessor: (d) => d.playerCode || '',
				id: 'playerCode',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.CodeCol' }), //'Code',
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => {
					if (
						!props.authentication.userLevel ||
						props.authentication.userLevel <= Min_Exclusive_Userlevel_To_Edit_PlayerCode
					) {
						return cellInfo.value;
					}
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					return !editing ? (
						cellInfo.value
					) : (
						<Input
							className={this.props.classes.editingInput}
							inputProps={{
								'aria-label': 'screenCode',
								style: {
									border: 'unset',
									boxShadow: 'unset',
									height: 34,
									backgroundColor: '#ffffff',
								},
							}}
							onChange={(event) => {
								this.setState({ editing: { ...currentEditing, playerCode: event.target.value } });
							}}
							value={
								typeof currentEditing.playerCode === 'undefined'
									? currentEditing.original.playerCode
									: currentEditing.playerCode
							}
						/>
					);
				},
			},
			{
				accessor: (d) => d.orientation || '',
				id: 'orientation',
				Header: (
					<div
						title={props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Playlists.LayoutCol',
						})}
					>
						<PortraitIcon className={this.props.classes.portraitIcon} />
						<LandscapeIcon className={this.props.classes.landscapeIcon} />
					</div>
				),
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					const getOrientationIcon = (orientation) =>
						orientation === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />;

					if (!editing) {
						return getOrientationIcon(cellInfo.value);
					} else {
						return (
							<RadioGroup
								aria-label="orientation"
								// name="orientation"
								className={this.props.classes.radioGroup}
								value={currentEditing.orientation || currentEditing.original.orientation}
								onChange={(event) =>
									this.setState({ editing: { ...currentEditing, orientation: event.target.value } })
								}
								row
							>
								{['landscape', 'portrait'].map((val) => (
									<FormControlLabel
										key={val}
										value={val}
										control={<Radio />}
										label={getOrientationIcon(val)}
									/>
								))}
							</RadioGroup>
						);
					}
				},
			},
			{
				accessor: (d) => d.currentContent,
				id: 'currentContent',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentContentCol',
				}), //'Current Content',
				resizable: true,
				sortable: true,
				filterable: false,
				Cell: (cellInfo) => {
					let startDT = cellInfo.value.schedule.startDatetime
						? moment(cellInfo.value.schedule.startDatetime).format('YYYY-MM-DD HH:mm:00')
						: null;
					let endDT = cellInfo.value.schedule.endDatetime
						? moment(cellInfo.value.schedule.endDatetime).format('YYYY-MM-DD HH:mm:00')
						: null;
					return (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							{startDT && (
								<div>
									{props.intl.formatMessage(
										{ id: 'pages.ScreenManager.components.Screens.CurrentScheduleText' },
										{ scheduleText: startDT + (endDT ? ` - ${endDT}` : '') }
									)}
								</div>
							)}
							{cellInfo.value.playlist.name && (
								<div>
									<FormattedMessage
										id="pages.ScreenManager.components.Screens.CurrentPlaylistText"
										values={{
											PLName: (
												<Link
													variant="body1"
													className={this.props.classes.hoverCursor}
													style={{ marginTop: 8 }}
													underline="always"
													onClick={() => {
														this.props.history.push({
															pathname: ROUTES_PATH_SCREENMANAGER_SLIDES,
															search: `?playlistId=${encryptStr(cellInfo.value.playlist.id)}`,
														});
													}}
												>
													{cellInfo.value.playlist.name}
												</Link>
											),
										}}
									/>
								</div>
							)}
							{cellInfo.value.playlist.updatedAt && (
								<div>
									<FormattedMessage
										id="pages.ScreenManager.components.Screens.CurrentPlaylistLastUpdatedText"
										values={{
											updatedAt: moment(cellInfo.value.playlist.updatedAt).format(
												'YYYY-MM-DD HH:mm:ss'
											),
										}}
									/>
								</div>
							)}
							<Link
								variant="body1"
								className={this.props.classes.hoverCursor}
								style={{ marginTop: 8 }}
								underline="always"
								onClick={() => {
									this.props.history.push({
										pathname: ROUTES_PATH_SCREENMANAGER_SCREEN_SCHEDULE,
										search: `?playerId=${encryptStr(cellInfo.original.id)}`,
									});
								}}
							>
								{props.intl.formatMessage({
									id: 'pages.ScreenManager.components.Screens.ViewSchedulesLinkText',
								})}
							</Link>
						</div>
					);
				},
			},
			{
				accessor: (d) => d.status || '',
				id: 'status',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentStatusCol',
				}), //'Status',
				resizable: true,
				sortable: true,
				Footer: 'NOTE: Do NOT delete this line!',
				filterable: true,
				filterMethod: (filter, row) => {
					if (filter.value === 'all') {
						return true;
					}
					if (filter.value === 'active') {
						return row.status === 'ACTIVE';
					}
					return row.status === 'INACTIVE';
				},
				// eslint-disable-next-line react/prop-types
				Filter: ({ filter, onChange }) => (
					<Select
						variant="standard"
						onChange={(event) => {
							onChange(event.target.value);
							this.setState({ filtered: [{ id: 'status', value: event.target.value }] });
						}}
						classes={{ select: props.classes.dropdown }}
						style={{ width: '100%', textAlign: 'left' }}
						// eslint-disable-next-line react/prop-types
						value={filter ? filter.value : 'all'}
					>
						<MenuItem value="all">Show All</MenuItem>
						<MenuItem value="active">Active</MenuItem>
						<MenuItem value="inactive">Not Active</MenuItem>
					</Select>
				),
				Cell: (cellInfo) =>
					cellInfo.value === 'ACTIVE' ? (
						<ActiveIcon className={props.classes.success} style={{ fontSize: 30 }} />
					) : (
						<InactiveIcon color="error" className={props.classes.shake} style={{ fontSize: 30 }} />
					),
			},
			{
				accessor: (d) => d.lastConnectedAt || '',
				id: 'lastConnectedAt',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.lastConnectedAtCol',
				}), //'Last Seen',
				resizable: true,
				sortable: true,
				Cell: (cellInfo) => {
					return cellInfo.original.lastConnectedAt
						? moment(cellInfo.original.lastConnectedAt).fromNow()
						: 'Never';
				},
			},
			{
				// NOTE: hidden column used just for default sorting
				accessor: (d) => d.createdAt || '',
				id: 'createdAt',
				Header: 'Created At',
				sortable: true,
				getProps: () => Object.create({ style: { display: 'none' } }),
				getHeaderProps: () => Object.create({ style: { display: 'none' } }),
			},
			{
				// NOTE - this is a "filter all" DUMMY column
				// you can't HIDE it because then it wont FILTER
				// but it has a size of ZERO with no RESIZE and the
				// FILTER component is NULL (it adds a little to the front)
				// You may possibly move it to the end of columns
				Header: 'All',
				id: 'all',
				width: 0,
				resizable: false,
				sortable: false,
				Filter: () => {},
				// eslint-disable-next-line no-unused-vars
				getProps: (state, rowInfo, column) => {
					return {
						style: { display: 'none' },
					};
				},
				getHeaderProps: () => {
					return {
						style: { display: 'none' },
					};
				},
				filterMethod: (filter, rows) => {
					// using match-sorter
					// it will take the content entered into the "filter"
					// and search for it in keys
					const result = matchSorter(rows, filter.value, {
						keys: ['name', 'playerCode', 'orientation', 'status'],
						threshold: matchSorter.rankings.CONTAINS,
					});
					// console.log('row[0]:', result, filter, rows);
					return result;
				},
				filterAll: true,
			},
		];

		this.screenActions = [
			{
				shouldDisplay: (screen) => {
					let currentEditing = this.state.editing;
					// const editing = currentEditing.original === cellInfo.original;
					if (currentEditing.original !== undefined) {
						if (screen.id === currentEditing.original.id) {
							return false;
						}
					}
					return true;
				}, // hook func to verify that should the action be displayed for the given screen
				// shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given screen
				icon: <EditIcon fontSize="small" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Edit',
				}),
				clickHandler: this.editScreen,
			},
			{
				// shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given screen
				shouldDisplay: (x) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (x.id === currentEditing.original.id) {
							return false;
						}
					}
					return true;
				},
				icon: <DeleteIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Delete',
				}),
				clickHandler: this.deleteScreen,
				color: 'red',
			},
			{
				shouldDisplay: (screen) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (screen.id === currentEditing.original.id) {
							return true;
						}
					}
					return false;
				}, // hook func to verify that should the action be displayed for the given screen
				icon: <SaveIcon fontSize="small" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Save',
				}),
				clickHandler: this.cardViewSave,
			},
			{
				shouldDisplay: (screen) => {
					let currentEditing = this.state.editing;
					// const editing = currentEditing.original === cellInfo.original;
					if (currentEditing.original !== undefined) {
						if (screen.id === currentEditing.original.id) {
							return true;
						}
					}
					return false;
				}, // hook func to verify that should the action be displayed for the given screen
				// shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given screen
				icon: <CancelIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Cancel',
				}),
				clickHandler: this.cancelEditing,
			},
		];
		this.searchColumnIdForCardView = ['name', 'playerCode', 'orientation', 'status'];
		this.columnsGridView = [
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.NameCol' }), //'Name',
				editable: true,
				editableComp: 'text',
			},
			{
				accessor: (d) => d.playerCode || '',
				id: 'playerCode',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.CodeCol' }), //'Code',
				editable: true,
				editableComp: 'text',
			},
			{
				accessor: (d) => d.orientation || '',
				id: 'orientation',
				Header: (
					<div
						title={props.intl.formatMessage({
							id: 'pages.ScreenManager.components.Screens.OrientationCol',
						})}
					>
						<PortraitIcon className={this.props.classes.portraitIcon} />
						<LandscapeIcon className={this.props.classes.landscapeIcon} />
					</div>
				),
				editable: true,
				editableComp: 'radio',
				CustomCell: (cellInfo) =>
					cellInfo.orientation === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />,
				onEditRadioValues: ['landscape', 'portrait'],
			},
			{
				accessor: (d) => d.currentContent,
				id: 'currentContent',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentContentCol',
				}), //'Current Content',
				editable: false,
				CustomCell: (cellInfo) => {
					let startDT = cellInfo.currentContent.schedule.startDatetime
						? moment(cellInfo.currentContent.schedule.startDatetime).format('YYYY-MM-DD HH:mm:00')
						: null;
					let endDT = cellInfo.currentContent.schedule.endDatetime
						? moment(cellInfo.currentContent.schedule.endDatetime).format('YYYY-MM-DD HH:mm:00')
						: null;
					return (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							{startDT && (
								<div>
									{props.intl.formatMessage(
										{ id: 'pages.ScreenManager.components.Screens.CurrentScheduleText' },
										{ scheduleText: startDT + (endDT ? ` - ${endDT}` : '') }
									)}
								</div>
							)}
							{cellInfo.currentContent.playlist.name ? (
								<div>
									<FormattedMessage
										id="pages.ScreenManager.components.Screens.CurrentPlaylistText"
										values={{
											PLName: (
												<Link
													variant="body1"
													className={this.props.classes.hoverCursor}
													style={{ marginTop: 8 }}
													underline="always"
													onClick={() => {
														this.props.history.push({
															pathname: ROUTES_PATH_SCREENMANAGER_SLIDES,
															search: `?playlistId=${encryptStr(
																cellInfo.currentContent.playlist.id
															)}`,
														});
													}}
												>
													{cellInfo.currentContent.playlist.name}
												</Link>
											),
										}}
									/>
								</div>
							) : (
								<div>
									{props.intl.formatMessage({
										id: 'pages.ScreenManager.components.Screens.CurrentContentUnavailable',
									})}
								</div>
							)}
						</div>
					);
				},
			},
			{
				accessor: (d) => d.status || '',
				id: 'status',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.CurrentStatusCol',
				}), //'Status',
				editable: false,
				CustomCell: (cellInfo) =>
					cellInfo.status === 'ACTIVE' ? (
						<ActiveIcon className={props.classes.success} style={{ fontSize: 30 }} />
					) : (
						<InactiveIcon color="error" className={props.classes.shake} style={{ fontSize: 30 }} />
					),
			},
			{
				accessor: (d) => d.lastConnectedAt || '',
				id: 'lastConnectedAt',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.lastConnectedAtCol',
				}), //'Last Seen',
				editable: false,
				CustomCell: (cellInfo) => {
					return cellInfo.lastConnectedAt ? moment(cellInfo.lastConnectedAt).fromNow() : 'Never';
				},
			},
		];
	}

	// inputFilter = ({ column, filter, onChange }) => {
	// 	return <Input
	// 		id='brand_name_keyword'
	// 		placeholder="keyword"
	// 		onKeyPress={event => {
	// 			if (event.key === "Enter") {
	// 				onChange(event.target.value)
	// 			}
	// 		}}
	// 		className={this.props.classes.filterInput}
	// 	/>
	// }

	filterAll = (e) => {
		const { value } = e.target;
		// const filterAll = value;
		const filtered = [{ id: 'all', value: value }];
		// NOTE: this completely clears any COLUMN filters
		this.setState({ filtered });
	};

	editScreen = (screenData) => {
		this.setState({ editing: { original: screenData } });
	};

	saveEditing = () => {
		let newScreenData = { ...this.state.editing };
		delete newScreenData.original;
		// if (!newScreenData.playerCode) delete newScreenData.playerCode;
		if (Object.keys(newScreenData).length === 0) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Screens.NotifyErrorNoDataChange',
					})
				)
			);
		}

		return this.props.editScreenManagerScreen(
			{ playerId: this.state.editing.original.id, reqData: newScreenData },
			({ error }) => {
				if (!error) {
					this.setState({ editing: {} });
				}
			}
		);
	};

	cancelEditing = () => {
		this.setState({ editing: {} });
	};

	addScreen = async () => {
		let {
			name,
			playerCode,
			orientation,
			playlistId,
			playlistName,
			newPlaylistDepartmentId,
			isLinuxbox,
			boxServiceVersion,
			boxServiceZipUrl,
			boxChromiumVersion,
			boxChromiumFullVersionName,
		} = {
			...this.state.adding,
		};
		if (
			!name ||
			!orientation ||
			(playlistId === '+' && (!playlistName || !newPlaylistDepartmentId)) ||
			(boxChromiumVersion && !boxChromiumFullVersionName) ||
			(!boxChromiumVersion && boxChromiumFullVersionName)
		) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Screens.NotifyErrorMissingRequiredData',
					})
				)
			);
		}

		const appversion =
			isLinuxbox === 'No'
				? undefined
				: {
						boxServiceVersion,
						boxServiceZipUrl,
						boxChromiumVersion,
						boxChromiumFullVersionName,
				  };

		let screenReqData = {
			name,
			orientation,
			activated: true,
			appversion,
		};

		if (playerCode) screenReqData.playerCode = playerCode;

		let addNewScreen = () =>
			new Promise((resolve, reject) => {
				this.props.addScreenManagerScreen(
					{
						reqData: screenReqData,
					},
					(res) => (res.error ? reject(res.error) : resolve(res.result.data))
				);
			});

		let addNewPlaylist = () =>
			new Promise((resolve, reject) => {
				this.props.addScreenManagerPlaylist(
					{
						reqData: {
							name: playlistName,
							departmentId: newPlaylistDepartmentId,
							layout: orientation,
							isMaster: false,
						},
					},
					(res) => (res.error ? reject(res.error) : resolve(res.result.data))
				);
			});
		let addNewSchedule = (playerId, playlistID) =>
			new Promise((resolve, reject) => {
				this.props.addScreenManagerScreenSchedule(
					{
						playerId,
						reqData: {
							orientation,
							startDatetime: moment().toISOString(),
							// The array to be circular which starts with 1-00:00 to 2-00:00 and ends with 0-00:00 to 1-00:00
							timetable: [...Array(7).keys()].map((i) => ({
								playlistId: playlistID,
								startTimeInDOW: (i + 1 < 7 ? i + 1 : i - 7 + 1) + '-00:00',
								endTimeInDOW: (i + 2 < 7 ? i + 2 : i - 7 + 2) + '-00:00',
							})),
						},
					},
					(res) => (res.error ? reject(res.error) : resolve(res.result.data))
				);
			});
		try {
			let newPlayerData = await addNewScreen();
			this.props.notifyGeneral(
				`(${name}) ${this.props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Screens.NotifyGenAddScreenSuccess',
				})}`,
				'success'
			);
			if (playlistId === '+') {
				let createdPlaylist = await addNewPlaylist();
				this.props.notifyGeneral(
					`(${playlistName}) ${this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Playlists.NotifyGenAddPLSuccess',
					})}`,
					'success'
				);
				await addNewSchedule(newPlayerData.id, createdPlaylist.id);
			} else if (playlistId) {
				await addNewSchedule(newPlayerData.id, playlistId);
			} else {
				// user doesn't want to assign any playlist to the screen
			}
			this.setState({ adding: {} });
			this.props.resetGlobalDialog();
		} catch (e) {
			this.props.notifyError(e.message);
		}
	};

	openAddingScreenDialog = () => {
		// window.alert('add new screen');
		// size, title, content, confirmCB
		const { classes, intl, screenManagerPlaylists, screenManagerDepartments } = this.props;
		const { adding, boxServiceVersions } = this.state;
		const screenNameIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogScreenNameLabel',
		});
		const screenCodeIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogScreenCodeLabel',
		});
		const screenOrientationIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogScreenOrientationLabel',
		});
		const isLinuxBoxCheckoutIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogIsLinuxboxLabel',
		});
		const setPLLabelIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogSetPLLabel',
		});
		const setPLMenuItemNoneIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogSetPLMenuItemNone',
		});
		const setPLMenuItemNewIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogSetPLMenuItemNew',
		});
		const newPLLabelIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogNewPLLabel',
		});
		const newPLErrDupIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogNewPLErrDup',
		});
		const newPLSetDeptLabelIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogNewPLSetDeptLabel',
		});
		const saveTextIntl = intl.formatMessage({ id: 'GENERAL.Save' });
		const dialogTitleIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Screens.AddScreenDialogTitle',
		});

		let content = (
			<div>
				<TextField
					required
					variant="standard"
					id="screenName"
					label={screenNameIntl}
					className={classes.addScreenTextField}
					margin="normal"
					inputProps={{
						'aria-label': 'screenName',
						style: { border: 'unset', boxShadow: 'unset', height: 34, backgroundColor: 'unset' },
					}}
					onChange={(event) => {
						this.setState(
							{ adding: { ...adding, name: event.target.value, playlistName: event.target.value } },
							this.openAddingScreenDialog
						);
					}}
					placeholder={screenNameIntl}
				/>
				<TextField
					// required
					id="screenCode"
					variant="standard"
					label={screenCodeIntl}
					className={classes.addScreenTextField}
					margin="normal"
					inputProps={{
						'aria-label': 'screenCode',
						style: { border: 'unset', boxShadow: 'unset', height: 34, backgroundColor: 'unset' },
					}}
					onChange={(event) => {
						this.setState(
							{ adding: { ...adding, playerCode: event.target.value } },
							this.openAddingScreenDialog
						);
					}}
					placeholder={screenCodeIntl}
				/>
				<FormControl className={classes.addScreenFormControl}>
					<FormLabel required>{screenOrientationIntl}</FormLabel>
					<RadioGroup
						aria-label="orientation"
						// name="orientation"
						className={classes.radioGroup}
						onChange={(event) => {
							this.setState(
								{ adding: { ...adding, orientation: event.target.value } },
								this.openAddingScreenDialog
							);
						}}
						row
					>
						{['landscape', 'portrait'].map((val) => (
							<FormControlLabel key={val} value={val} control={<Radio />} label={val} />
						))}
					</RadioGroup>
				</FormControl>
				<FormControl variant="standard" className={classes.addScreenFormControl}>
					<InputLabel shrink id="setPLLabel">
						{setPLLabelIntl}
					</InputLabel>
					<Select
						labelId={'setPLLabel'}
						value={adding.playlistId || ''}
						onChange={(event) => {
							this.setState(
								{
									adding: {
										...adding,
										playlistId: event.target.value,
										// playlistName: event.currentTarget.textContent
									},
								},
								this.openAddingScreenDialog
							);
						}}
						displayEmpty
						// className={classes.selectEmpty}
					>
						<MenuItem value="">{setPLMenuItemNoneIntl}</MenuItem>
						<MenuItem value={'+'}>{setPLMenuItemNewIntl}</MenuItem>
						<hr />
						{screenManagerPlaylists &&
							_.sortBy(screenManagerPlaylists.playlists, [(pl) => pl.name.toLowerCase()]).map(
								(pl) => {
									let disabled = pl.layout !== adding.orientation;
									return (
										<MenuItem value={pl.id} disabled={disabled} key={pl.id}>
											{`${pl.name} (${pl.layout})`}
										</MenuItem>
									);
								}
							)}
					</Select>
				</FormControl>
				{adding.playlistId === '+' && (
					<div>
						<FormControl className={classes.addScreenFormControl}>
							<TextField
								required
								label={newPLLabelIntl}
								variant="standard"
								value={adding.playlistName || adding.name || ''}
								error={adding.playlistNameError}
								helperText={adding.playlistNameError}
								// className={classes.addScreenTextField}
								margin="normal"
								inputProps={{
									'aria-label': 'playlistName',
									style: {
										border: 'unset',
										boxShadow: 'unset',
										height: 34,
										backgroundColor: 'unset',
									},
								}}
								onChange={(event) => {
									let playlistNameError;
									screenManagerPlaylists &&
										screenManagerPlaylists.playlists.forEach((playlist) => {
											if (event.target.value === playlist.name) {
												playlistNameError = newPLErrDupIntl;
												return;
											}
										});
									this.setState(
										{ adding: { ...adding, playlistNameError, playlistName: event.target.value } },
										this.openAddingScreenDialog
									);
								}}
								placeholder={newPLLabelIntl}
							/>
						</FormControl>
						<FormControl variant="standard" className={classes.addScreenFormControl}>
							<InputLabel required id="newPLSetDeptLabel">
								{newPLSetDeptLabelIntl}
							</InputLabel>
							<Select
								required
								labelId="newPLSetDeptLabel"
								value={adding.newPlaylistDepartmentId || ''}
								// className={classes.addScreenFullWidthControl}
								onChange={(event) => {
									this.setState(
										{ adding: { ...adding, newPlaylistDepartmentId: event.target.value } },
										this.openAddingScreenDialog
									);
								}}
								displayEmpty
								// className={classes.selectEmpty}
							>
								{screenManagerDepartments &&
									_.sortBy(screenManagerDepartments.departments, [
										(dept) => dept.name.toLowerCase(),
									]).map((val) => (
										<MenuItem key={val.id} value={val.id}>
											{val.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</div>
				)}
				{this.enableAddingLinuxBox && (
					<FormControl className={classes.addScreenFormControl}>
						<FormLabel>{isLinuxBoxCheckoutIntl}</FormLabel>
						<RadioGroup
							aria-label="isLinuxbox"
							// name="orientation"
							className={classes.radioGroup}
							onChange={(event) => {
								this.setState(
									{ adding: { ...adding, isLinuxbox: event.target.value } },
									this.openAddingScreenDialog
								);
							}}
							row
						>
							{['Yes', 'No'].map((val) => (
								<FormControlLabel key={val} value={val} control={<Radio />} label={val} />
							))}
						</RadioGroup>
					</FormControl>
				)}
				{adding.isLinuxbox === 'Yes' && (
					<FormControl className={classes.addScreenFormControl}>
						<InputLabel id="boxServiceVersionSelect">
							{intl.formatMessage({
								id: 'pages.Admin.components.PlayerUniverse.LinuxBoxSysVersionText',
							})}
						</InputLabel>
						<Select
							label={intl.formatMessage({
								id: 'pages.Admin.components.PlayerUniverse.LinuxBoxSysVersionText',
							})}
							labelId="boxServiceVersionSelect"
							// value={boxServiceVersion}
							defaultValue={''}
							onChange={(e) => {
								this.setState(
									{
										adding: {
											...adding,
											boxServiceVersion: e.target.value,
											boxServiceZipUrl: boxServiceVersions.find(
												(v) => v.boxServiceVersion === e.target.value
											)?.boxServiceZipUrl,
										},
									},
									this.openAddingScreenDialog
								);
							}}
						>
							{boxServiceVersions.map((ver, idx) => {
								return (
									<MenuItem key={idx} sx={{ fontSize: '0.9rem' }} value={ver.boxServiceVersion}>
										{ver.boxServiceVersion}
										<Typography sx={{ ml: 0.5 }} variant="caption">{`(on ${toLocaleDateTime(
											new Date(ver.createdAt)
										)})`}</Typography>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				)}
				{adding.isLinuxbox === 'Yes' && (
					<FormControl className={classes.addScreenFormControl}>
						<TextField
							label={intl.formatMessage({
								id: 'pages.Admin.components.PlayerUniverse.LinuxBoxChromiumVerLabel',
							})}
							onChange={(e) => {
								this.setState(
									{ adding: { ...adding, boxChromiumVersion: e.target.value } },
									this.openAddingScreenDialog
								);
							}}
						/>
					</FormControl>
				)}
				{adding.isLinuxbox === 'Yes' && (
					<FormControl className={classes.addScreenFormControl}>
						<TextField
							sx={{ minWidth: 300 }}
							label={intl.formatMessage({
								id: 'pages.Admin.components.PlayerUniverse.LinuxBoxChromiumLinuxVerLabel',
							})}
							onChange={(e) => {
								this.setState(
									{ adding: { ...adding, boxChromiumFullVersionName: e.target.value } },
									this.openAddingScreenDialog
								);
							}}
						/>
					</FormControl>
				)}
				<Button
					variant="contained"
					color="primary"
					className={classes.addScreenButton}
					onClick={this.addScreen}
				>
					{saveTextIntl}
					<SaveIcon className={classes.rightIconInButton} />
				</Button>
			</div>
		);
		let addingScreenDialog = {
			size: 'sm',
			title: dialogTitleIntl,
			content: content,
			onClose: this.setState.bind(this, { adding: {} }),
		};
		this.props.openGlobalDialog(addingScreenDialog);
	};

	cancelAddingScreen = () => {
		this.setState({ adding: {} });
	};

	deleteScreen = (screenData) => {
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Screens.DelScreenDialogTitle',
			}),
			content: (
				<Typography variant="body1" gutterBottom style={{ minHeight: 50, marginTop: 25 }}>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Screens.DelScreenDialogMessage"
							values={{
								screenName: (
									<Typography variant="h6" component="span" color="secondary">
										{screenData.name}
									</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				this.props.deleteScreenManagerScreen({ playerId: screenData.id });
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};
	saveEditOnCardView = (newScreenData) => {
		if (Object.keys(newScreenData).length === 0) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Screens.NotifyErrorNoDataChange',
					})
				)
			);
		}

		return this.props.editScreenManagerScreen(
			{ playerId: this.state.editing.original.id, reqData: newScreenData },
			({ error }) => {
				if (!error) {
					this.setState({ editing: {} });
				}
			}
		);
	};
	// TO DO
	cardViewSave = (playlistData, e, screenData) => {
		// let currentEditing = this.state.editing;
		this.setState({
			editing: {
				...playlistData,
				...screenData,
			},
		});
		this.saveEditOnCardView(screenData);
	};
	getTrComp = (props) => <RTTrComp {...props} />;

	componentDidMount() {
		this.props.fetchScreenManagerScreens();
		this.props.fetchScreenManagerPlaylists();
		this.props.fetchScreenManagerDepartments();
		if (this.enableAddingLinuxBox) {
			fetchBoxServiceReleases()
				.then((res) => {
					this.setState({ boxServiceVersions: res.data });
				})
				.catch((err) => {
					this.props.notifyGeneral(
						`Failed to get boxService released versions - ${
							err.response ? err.response.data.message : err.message
						}`,
						'error'
					);
				});
		}
		this.updateScreenResolution();
		window.addEventListener('resize', this.updateScreenResolution);
	}

	componentWillUnmount() {
		this.props.resetScreenManagerScreens();
		window.removeEventListener('resize', this.updateScreenResolution);
	}
	updateScreenResolution() {
		this.setState({ isDesktop: window.innerWidth > 955 });
	}
	render() {
		const { classes, screenManagerScreens, intl } = this.props;
		const { isFetching, screens } = screenManagerScreens;

		const isDesktop = this.state.isDesktop;
		return (
			<div className={classes.contentWrapper}>
				<Paper className={classes.header}>
					<Typography variant="h6" gutterBottom className={classes.title}>
						{intl.formatMessage({ id: 'pages.ScreenManager.components.Screens.TableTitle' })}
						<Tooltip
							title={intl.formatMessage({
								id: 'pages.ScreenManager.components.Screens.AddScreenTooltipTitle',
							})}
						>
							<Fab
								color="secondary"
								aria-label="Add"
								className={classes.addFab}
								onClick={this.openAddingScreenDialog}
							>
								<AddIcon />
							</Fab>
						</Tooltip>
					</Typography>
					<Search
						placeholder={intl.formatMessage({ id: 'GENERAL.Search' })}
						className={classes.search}
						onChange={this.filterAll.bind(this)}
						onClearClick={() => {
							if (this.state.filtered.length === 0) return;
							this.setState({ filtered: [] });
						}}
						// OnEnterKeyPressed={this.filterAll.bind(this)}
					/>
				</Paper>
				{!isDesktop ? (
					<ScreenManagerGridView
						cardViewData={screens}
						columns={this.columnsGridView}
						previewHeight={130}
						screenActions={this.screenActions}
						editMode={this.state.editing}
						searchFilter={this.state.filtered}
						searchColumnId={this.searchColumnIdForCardView}
						noDataMsg={
							isFetching
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'ReactTable.NoDataText',
								  })
						}
					/>
				) : (
					<ReactTable
						// ref="screensTable"
						// International Lang
						previousText={intl.formatMessage({ id: 'ReactTable.PreviousText' })}
						nextText={intl.formatMessage({ id: 'ReactTable.NextText' })}
						loadingText={intl.formatMessage({ id: 'ReactTable.LoadingText' })}
						noDataText={intl.formatMessage({ id: 'ReactTable.NoDataText' })}
						pageText={intl.formatMessage({ id: 'ReactTable.PageText' })}
						ofText={intl.formatMessage({ id: 'ReactTable.OfText' })}
						rowsText={intl.formatMessage({ id: 'ReactTable.RowsText' })}
						defaultFiltered={[]}
						defaultSorted={[{ id: 'lastConnectedAt' }, { id: 'createdAt', desc: true }]}
						filtered={this.state.filtered}
						// onFilteredChange={filtered => {
						// 	this.setState({ filtered });
						// }}
						TrComponent={this.getTrComp}
						// TdComponent={TdComponent}
						// manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={screens}
						// pages={pages} // Display the total number of pages
						loading={isFetching} // Display the loading overlay when we need it
						// onFetchData={this.fetchBrandsData.bind(this)} // Request new data when things change
						// data={this.props.getProductsDataReducerState.productsData}
						multiSort={false}
						columns={this.columns}
						defaultPageSize={10}
						style={{
							height: '600px', // This will force the table body to overflow and scroll, since there is not enough room
						}}
						className="-striped -highlight"
						// eslint-disable-next-line no-unused-vars
						defaultFilterMethod={(filter, row, column) => {
							const id = filter.pivotId || filter.id;
							return row[id] !== undefined
								? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
								: true;
						}}
						TfootComponent={({ children, className, ...rest }) => (
							<div className={className} {...rest} style={{ width: '100%' }}></div>
						)}
						// eslint-disable-next-line no-unused-vars
						getTheadTrProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.tableHeadCell + ' ' + state.className,
								style: { alignItems: 'center' },
							};
						}}
						getTableProps={(state) => {
							return {
								className: classes.table + ' ' + state.className,
							};
						}}
						getTrProps={(state, rowInfo) => {
							if (!rowInfo) return {};
							let isOnEditing = rowInfo.original === this.state.editing.original;
							return {
								style: {
									alignItems: 'center',
								},
								deleteAction: !isOnEditing
									? this.deleteScreen.bind(this, rowInfo.original)
									: undefined,
								editAction: !isOnEditing ? this.editScreen.bind(this, rowInfo.original) : undefined,
								saveAction: isOnEditing ? this.saveEditing : undefined,
								cancelAction: isOnEditing ? this.cancelEditing : undefined,
							};
						}}
						getTdProps={() => {
							// get TD (column) props
							// let editAction, editingUI;
							// if (rowInfo) {
							// 	switch (column.id) {
							// 		case 'name':
							// 		case 'restricted_to':
							// 		case 'metadata':
							// 			editingUI = <EditBrandMeta brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		case 'brand_logo':
							// 			editingUI = <EditingLogo brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		default:
							// 			break;
							// 	}
							// 	if (editingUI) editAction = () => this.setState({ editingUI: editingUI });
							// }
							return {
								// style: { whiteSpace: 'unset' },
								// className: classes.tableCell + ' ' + state.className,
								// editAction: editAction,
							};
						}}
					/>
				)}
				{
					// <Dialog open={this.state.addingScreenOpen} size='md' title='Enter the details for the new Screen:' content={this.state.addingScreenUI} onClose={this.onCloseAddingScreenDialog} />
				}
			</div>
		);
	}
}

Screens.propTypes = {
	classes: PropTypes.object.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	resetGlobalDialog: PropTypes.func.isRequired,
	fetchScreenManagerScreens: PropTypes.func.isRequired,
	deleteScreenManagerScreen: PropTypes.func.isRequired,
	notifyError: PropTypes.func.isRequired,
	editScreenManagerScreen: PropTypes.func.isRequired,
	addScreenManagerScreen: PropTypes.func.isRequired,
	resetScreenManagerScreens: PropTypes.func.isRequired,
	screenManagerScreens: PropTypes.object.isRequired,
	authentication: PropTypes.object.isRequired,
	screenManagerPlaylists: PropTypes.object.isRequired,
	screenManagerDepartments: PropTypes.object.isRequired,
	addScreenManagerPlaylist: PropTypes.func.isRequired,
	addScreenManagerScreenSchedule: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	fetchScreenManagerPlaylists: PropTypes.func.isRequired,
	fetchScreenManagerDepartments: PropTypes.func.isRequired,
};

Screens.defaultProps = {
	// multiple: false,
	// allowZip: false,
	// allowPreview: true,
	// disableClick: false,
};

const mapStateToProps = (state) => {
	return {
		screenManagerScreens: state.screenManagerScreens,
		authentication: state.authentication,
		screenManagerPlaylists: state.screenManagerPlaylists,
		screenManagerDepartments: state.screenManagerDepartments,
	};
};

export default connect(mapStateToProps, {
	openGlobalDialog,
	resetGlobalDialog,
	fetchScreenManagerScreens,
	deleteScreenManagerScreen,
	notifyError,
	notifyGeneral,
	editScreenManagerScreen,
	addScreenManagerScreen,
	resetScreenManagerScreens,
	fetchScreenManagerPlaylists,
	fetchScreenManagerDepartments,
	addScreenManagerPlaylist,
	addScreenManagerScreenSchedule,
})(injectIntl(withStyles(ScreensStyle)(Screens)));
