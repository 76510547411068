import React from 'react';

// import config from 'config';

import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import withStyles from '@mui/styles/withStyles';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { Search, RTTrComp, ScreenManagerGridView } from 'components';

import { injectIntl, FormattedMessage } from 'react-intl';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import { encryptStr } from 'utils/appHelper';

// icons
import {
	Add as AddIcon,
	Save as SaveIcon,
	Edit as EditIcon,
	DeleteOutline as DeleteIcon,
	Cancel as CancelIcon,
} from '@mui/icons-material';

// CSS
import DepartmentsStyle from './DepartmentsStyle.jsx';

// redux
import { connect } from 'react-redux';
import {
	openGlobalDialog,
	resetGlobalDialog,
	notifyError,
	fetchScreenManagerDepartments,
	editScreenManagerDepartment,
	addScreenManagerDepartment,
	deleteScreenManagerDepartment,
	resetScreenManagerDepartments,
	fetchUserDataByIdGroup,
	resetUserDataByIdGroup,
} from 'redux/actions'; // actions

// Constants
import { ROUTES_PATH_SCREENMANAGER_SLIDES } from 'routes';

class Departments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filtered: [],
			editing: {}, // the editing data of a screen
			adding: {}, // the adding data of a screen
			isDesktop: false,
		};
		this.updateScreenResolution = this.updateScreenResolution.bind(this);
		this.columns = [
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Departments.NameCol',
				}),
				resizable: true,
				sortable: true,
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					return !editing ? (
						cellInfo.value
					) : (
						<Input
							className={this.props.classes.editingInput}
							inputProps={{
								'aria-label': 'departmentName',
								style: {
									border: 'unset',
									boxShadow: 'unset',
									height: 34,
									backgroundColor: '#ffffff',
								},
							}}
							onChange={(event) => {
								this.setState({ editing: { ...currentEditing, name: event.target.value } });
							}}
							value={
								typeof currentEditing.name === 'undefined'
									? currentEditing.original.name
									: currentEditing.name
							}
						/>
					);
				},
			},
			{
				accessor: (d) => d.masterPlaylists || [],
				id: 'masterPlaylists',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Departments.MasterPLCol',
				}),
				resizable: true,
				sortable: true,
				filterable: false,
				Footer: 'NOTE: Do NOT delete this line!',
				Cell: (cellInfo) => {
					return cellInfo.value.length === 0 ? (
						<div>
							{props.intl.formatMessage({
								id: 'pages.ScreenManager.components.Departments.NoMasterPLText',
							})}
						</div>
					) : (
						<div>
							{cellInfo.value.map((masterPL, idx) => {
								return (
									<div key={idx}>
										{`${masterPL.layout.charAt(0).toUpperCase() + masterPL.layout.slice(1)}`}:{' '}
										<Link
											key={masterPL.id}
											className={this.props.classes.hoverCursor}
											underline="always"
											onClick={() => {
												this.props.history.push({
													pathname: ROUTES_PATH_SCREENMANAGER_SLIDES,
													search: `?playlistId=${encryptStr(masterPL.id)}`,
												});
											}}
										>
											{masterPL.name}
										</Link>
										{idx < cellInfo.value.length - 1 ? <br /> : null}
									</div>
								);
							})}
						</div>
					);
				},
			},
			{
				accessor: (d) => d.masterPlaylists || [],
				id: 'masterPlaylists',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Departments.CreatedByCol',
				}),
				resizable: true,
				sortable: true,
				Cell: (cellInfo) => {
					return cellInfo.value.length === 0 ? null : (
						<div>
							{cellInfo.value.map((masterPL, idx) => {
								const { usersDataByGroup } = this.props;
								if (usersDataByGroup.results && masterPL.createdByUID) {
									const userDataObj = (usersDataByGroup.results || []).find(
										(item) => item.id.toString() === masterPL.createdByUID
									);
									return (
										<div key={idx}>
											{`${userDataObj.firstname + ' ' + userDataObj.lastname}`}
											{idx < cellInfo.value.length - 1 ? <br /> : null}
										</div>
									);
								} else {
									return null;
								}
							})}
						</div>
					);
				},
			},
			{
				// NOTE - this is a "filter all" DUMMY column
				// you can't HIDE it because then it wont FILTER
				// but it has a size of ZERO with no RESIZE and the
				// FILTER component is NULL (it adds a little to the front)
				// You may possibly move it to the end of columns
				Header: 'All',
				id: 'all',
				width: 0,
				resizable: false,
				sortable: false,
				Filter: () => {},
				// eslint-disable-next-line no-unused-vars
				getProps: (state, rowInfo, column) => {
					return {
						style: { display: 'none' },
					};
				},
				getHeaderProps: () => {
					return {
						style: { display: 'none' },
					};
				},
				filterMethod: (filter, rows) => {
					// using match-sorter
					// it will take the content entered into the "filter"
					// and search for it in keys
					const result = matchSorter(rows, filter.value, {
						keys: ['name'],
						threshold: matchSorter.rankings.CONTAINS,
					});
					// console.log('row[0]:', result[0]);
					return result;
				},
				filterAll: true,
			},
		];

		// **************************************************************
		this.screenActions = [
			{
				shouldDisplay: (screen) => {
					let currentEditing = this.state.editing;
					// const editing = currentEditing.original === cellInfo.original;
					if (currentEditing.original !== undefined) {
						if (screen.id === currentEditing.original.id) {
							return false;
						}
					}
					return true;
				}, // hook func to verify that should the action be displayed for the given screen
				// shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given screen
				icon: <EditIcon fontSize="small" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Edit',
				}),
				clickHandler: this.editDepartment,
			},
			{
				shouldDisplay: (x) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (x.id === currentEditing.original.id) {
							return false;
						}
					}
					return true;
				},
				icon: <DeleteIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Delete',
				}),
				clickHandler: this.deleteDepartment,
				color: 'red',
			},
			{
				shouldDisplay: (screen) => {
					let currentEditing = this.state.editing;
					if (currentEditing.original !== undefined) {
						if (screen.id === currentEditing.original.id) {
							return true;
						}
					}
					return false;
				}, // hook func to verify that should the action be displayed for the given screen
				icon: <SaveIcon fontSize="small" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Save',
				}),
				clickHandler: this.cardViewSave,
			},
			{
				shouldDisplay: (screen) => {
					let currentEditing = this.state.editing;
					// const editing = currentEditing.original === cellInfo.original;
					if (currentEditing.original !== undefined) {
						if (screen.id === currentEditing.original.id) {
							return true;
						}
					}
					return false;
				}, // hook func to verify that should the action be displayed for the given screen
				// shouldDisplay: () => true, // hook func to verify that should the action be displayed for the given screen
				icon: <CancelIcon fontSize="small" color="action" />,
				tooltip: this.props.intl.formatMessage({
					id: 'GENERAL.Cancel',
				}),
				clickHandler: this.cancelEditing,
			},
		];
		this.searchColumnIdForCardView = ['name'];
		this.columnsGridView = [
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Departments.NameCol',
				}),
				editable: true,
				editableComp: 'text',
			},
			{
				accessor: (d) => d.masterPlaylists || '',
				id: 'masterPlaylists',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Departments.MasterPLCol',
				}),
				editable: false,
				CustomCell: (cellInfo) => {
					return cellInfo.masterPlaylists.length === 0 ? (
						<div>
							{props.intl.formatMessage({
								id: 'pages.ScreenManager.components.Departments.NoMasterPLText',
							})}
						</div>
					) : (
						<div>
							{cellInfo.masterPlaylists.map((masterPL, idx) => {
								return (
									<div key={idx}>
										{`${masterPL.layout.charAt(0).toUpperCase() + masterPL.layout.slice(1)}`}:{' '}
										<Link
											key={masterPL.id}
											className={this.props.classes.hoverCursor}
											underline="always"
											onClick={() => {
												this.props.history.push({
													pathname: ROUTES_PATH_SCREENMANAGER_SLIDES,
													search: `?playlistId=${encryptStr(masterPL.id)}`,
												});
											}}
										>
											{masterPL.name}
										</Link>
										{idx < cellInfo.masterPlaylists.length - 1 ? <br /> : null}
									</div>
								);
							})}
						</div>
					);
				},
			},
			{
				accessor: (d) => d.masterPlaylists || [],
				id: 'createdByUID',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Departments.CreatedByCol',
				}),
				editable: false,
				CustomCell: (cellInfo) => {
					return cellInfo.masterPlaylists.length === 0 ? null : (
						<div>
							{cellInfo.masterPlaylists.map((masterPL, idx) => {
								const { usersDataByGroup } = this.props;
								if (usersDataByGroup.results && masterPL.createdByUID) {
									const userDataObj = (usersDataByGroup.results || []).find(
										(item) => item.id.toString() === masterPL.createdByUID
									);
									return (
										<div key={idx}>
											{`${userDataObj.firstname + ' ' + userDataObj.lastname}`}
											{idx < cellInfo.masterPlaylists.length - 1 ? <br /> : null}
										</div>
									);
								} else {
									return null;
								}
							})}
						</div>
					);
				},
			},
		];
	}

	filterAll = (e) => {
		const { value } = e.target;
		// const filterAll = value;
		const filtered = [{ id: 'all', value: value }];
		// NOTE: this completely clears any COLUMN filters
		this.setState({ filtered });
	};

	editDepartment = (deparmentData) => {
		this.setState({ editing: { original: deparmentData } });
	};

	saveEditing = () => {
		let newDepartmentData = { ...this.state.editing };
		delete newDepartmentData.original;
		if (Object.keys(newDepartmentData).length === 0) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Departments.NotifyErrorNoDataChange',
					})
				)
			);
		}

		return this.props.editScreenManagerDepartment(
			{ departmentId: this.state.editing.original.id, reqData: newDepartmentData },
			({ error }) => {
				if (!error) {
					this.setState({ editing: {} });
				}
			}
		);
	};

	cancelEditing = () => {
		this.setState({ editing: {} });
	};

	addDepartment = () => {
		let { name } = { ...this.state.adding };
		if (!name) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Departments.NotifyErrorMissingRequiredData',
					})
				)
			);
		}
		let reqData = {
			name,
		};
		return this.props.addScreenManagerDepartment({ reqData }, ({ error }) => {
			if (!error) {
				this.setState({ adding: {} });
				this.props.resetGlobalDialog();
			}
		});
	};

	openAddingDepartmentDialog = () => {
		const { classes, intl } = this.props;
		const deptDialogNameIntl = intl.formatMessage({
			id: 'pages.ScreenManager.components.Departments.AddDepartmentDialogNameLabel',
		});
		const deptDialogTitle = intl.formatMessage({
			id: 'pages.ScreenManager.components.Departments.AddDepartmentDialogTitle',
		});
		let content = (
			<div style={{ minWidth: 250 }}>
				<TextField
					required
					id="departmentName"
					label={deptDialogNameIntl}
					className={classes.addingTextField}
					margin="normal"
					inputProps={{
						'aria-label': 'departmentName',
						style: { border: 'unset', boxShadow: 'unset', height: 34, backgroundColor: 'unset' },
					}}
					onChange={(event) => {
						this.setState({ adding: { ...this.state.adding, name: event.target.value } });
					}}
					placeholder={deptDialogNameIntl}
				/>
				<Button
					variant="contained"
					color="primary"
					className={classes.addingButton}
					onClick={this.addDepartment}
				>
					{intl.formatMessage({ id: 'GENERAL.Save' })}
					<SaveIcon className={classes.rightIconInButton} />
				</Button>
			</div>
		);
		let addingPlaylistDialog = {
			size: 'sm',
			title: deptDialogTitle,
			content: content,
		};
		this.props.openGlobalDialog(addingPlaylistDialog);
	};

	deleteDepartment = (deparmentData) => {
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Departments.DelDepartmentDialogTitle',
			}),
			content: (
				<Typography variant="body1" gutterBottom style={{ minHeight: 50, marginTop: 25 }}>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Departments.DelDepartmentDialogMessage"
							values={{
								deptName: (
									<Typography variant="h6" component="span" color="secondary">
										{deparmentData.name}
									</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				this.props.deleteScreenManagerDepartment({ departmentId: deparmentData.id });
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};
	saveEditOnCardView = (newDepartmentData) => {
		if (Object.keys(newDepartmentData).length === 0) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Departments.NotifyErrorNoDataChange',
					})
				)
			);
		}

		return this.props.editScreenManagerDepartment(
			{ departmentId: this.state.editing.original.id, reqData: newDepartmentData },
			({ error }) => {
				if (!error) {
					this.setState({ editing: {} });
				}
			}
		);
	};
	// TO DO
	cardViewSave = (playlistData, e, screenData) => {
		// let currentEditing = this.state.editing;
		this.setState({
			editing: {
				...playlistData,
				...screenData,
			},
		});
		this.saveEditOnCardView(screenData);
	};
	componentDidMount() {
		var masterUserIds = [];
		this.props.fetchScreenManagerDepartments({}, ({ result, error }) => {
			if (!error) {
				if (result.data.length > 0) {
					result.data.forEach((items) => {
						if (items.masterPlaylists.length > 0) {
							items.masterPlaylists.forEach((x) => {
								if (x.createdByUID) {
									masterUserIds.push(x.createdByUID);
								}
							});
						}
					});
					this.props.fetchUserDataByIdGroup({
						queryParams: {
							userIds: masterUserIds.join(','),
						},
					});
				}
			}
		});
		this.updateScreenResolution();
		window.addEventListener('resize', this.updateScreenResolution);
	}

	componentWillUnmount() {
		this.props.resetScreenManagerDepartments();
		this.props.resetUserDataByIdGroup();
		window.removeEventListener('resize', this.updateScreenResolution);
	}
	updateScreenResolution() {
		this.setState({ isDesktop: window.innerWidth > 955 });
	}
	getTrComp = (props) => <RTTrComp {...props} />;

	render() {
		const { classes, screenManagerDepartments, intl } = this.props;
		const { isFetching, departments } = screenManagerDepartments;
		const isDesktop = this.state.isDesktop;
		return (
			<div className={classes.contentWrapper}>
				<Paper className={classes.header}>
					<Typography variant="h6" gutterBottom className={classes.title}>
						{intl.formatMessage({ id: 'pages.ScreenManager.components.Departments.TableTitle' })}
						<Tooltip
							title={intl.formatMessage({
								id: 'pages.ScreenManager.components.Departments.AddDepartmentTooltipTitle',
							})}
						>
							<Fab
								color="secondary"
								aria-label="Add"
								className={classes.addFab}
								onClick={this.openAddingDepartmentDialog}
							>
								<AddIcon />
							</Fab>
						</Tooltip>
					</Typography>
					<Search
						placeholder={intl.formatMessage({ id: 'GENERAL.Search' })}
						className={classes.search}
						onChange={this.filterAll.bind(this)}
						onClearClick={() => {
							if (this.state.filtered.length === 0) return;
							this.setState({ filtered: [] });
						}}
						// OnEnterKeyPressed={this.filterAll.bind(this)}
					/>
				</Paper>
				{!isDesktop ? (
					<ScreenManagerGridView
						cardViewData={departments}
						columns={this.columnsGridView}
						previewHeight={130}
						screenActions={this.screenActions}
						editMode={this.state.editing}
						searchFilter={this.state.filtered}
						searchColumnId={this.searchColumnIdForCardView}
						noDataMsg={
							isFetching
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'ReactTable.NoDataText',
								  })
						}
					/>
				) : (
					<ReactTable
						// ref="departmentTable"
						// International Lang
						previousText={intl.formatMessage({ id: 'ReactTable.PreviousText' })}
						nextText={intl.formatMessage({ id: 'ReactTable.NextText' })}
						loadingText={intl.formatMessage({ id: 'ReactTable.LoadingText' })}
						noDataText={intl.formatMessage({ id: 'ReactTable.NoDataText' })}
						pageText={intl.formatMessage({ id: 'ReactTable.PageText' })}
						ofText={intl.formatMessage({ id: 'ReactTable.OfText' })}
						rowsText={intl.formatMessage({ id: 'ReactTable.RowsText' })}
						defaultFiltered={[]}
						filtered={this.state.filtered}
						// onFilteredChange={filtered => {
						// 	this.setState({ filtered });
						// }}
						TrComponent={this.getTrComp}
						// TdComponent={TdComponent}
						// manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={departments}
						// pages={pages} // Display the total number of pages
						loading={isFetching} // Display the loading overlay when we need it
						// onFetchData={this.fetchBrandsData.bind(this)} // Request new data when things change
						// data={this.props.getProductsDataReducerState.productsData}
						multiSort={false}
						columns={this.columns}
						defaultPageSize={10}
						style={{
							height: '600px', // This will force the table body to overflow and scroll, since there is not enough room
						}}
						className="-striped -highlight"
						// eslint-disable-next-line no-unused-vars
						defaultFilterMethod={(filter, row, column) => {
							const id = filter.pivotId || filter.id;
							return row[id] !== undefined
								? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
								: true;
						}}
						TfootComponent={({ children, className, ...rest }) => (
							<div className={className} {...rest} style={{ width: '100%' }}></div>
						)}
						// eslint-disable-next-line no-unused-vars
						getTheadTrProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.tableHeadCell + ' ' + state.className,
								style: { alignItems: 'center' },
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTableProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.table + ' ' + state.className,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTrProps={(state, rowInfo, column, instance) => {
							if (!rowInfo) return {};
							let isOnEditing = rowInfo.original === this.state.editing.original;
							return {
								style: {
									alignItems: 'center',
								},
								deleteAction: !isOnEditing
									? this.deleteDepartment.bind(this, rowInfo.original)
									: undefined,
								editAction: !isOnEditing
									? this.editDepartment.bind(this, rowInfo.original)
									: undefined,
								saveAction: isOnEditing ? this.saveEditing : undefined,
								cancelAction: isOnEditing ? this.cancelEditing : undefined,
							};
						}}
						// getTdProps={(state, rowInfo, column, instance) => {
						// 	// get TD (column) props
						// 	return {
						// 	};
						// }
						// }
					/>
				)}
			</div>
		);
	}
}

Departments.propTypes = {
	classes: PropTypes.object.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	resetGlobalDialog: PropTypes.func.isRequired,
	notifyError: PropTypes.func.isRequired,
	fetchScreenManagerDepartments: PropTypes.func.isRequired,
	editScreenManagerDepartment: PropTypes.func.isRequired,
	addScreenManagerDepartment: PropTypes.func.isRequired,
	deleteScreenManagerDepartment: PropTypes.func.isRequired,
	resetScreenManagerDepartments: PropTypes.func.isRequired,
	screenManagerDepartments: PropTypes.object.isRequired,
	fetchUserDataByIdGroup: PropTypes.func.isRequired,
	resetUserDataByIdGroup: PropTypes.func.isRequired,
	usersDataByGroup: PropTypes.object.isRequired,
};

Departments.defaultProps = {
	// multiple: false,
	// allowZip: false,
	// allowPreview: true,
	// disableClick: false,
};

const mapStateToProps = (state) => {
	return {
		screenManagerDepartments: state.screenManagerDepartments,
		usersDataByGroup: state.usermanager.userDataByGroup,
	};
};

export default connect(mapStateToProps, {
	openGlobalDialog,
	resetGlobalDialog,
	notifyError,
	fetchScreenManagerDepartments,
	editScreenManagerDepartment,
	addScreenManagerDepartment,
	deleteScreenManagerDepartment,
	resetScreenManagerDepartments,
	fetchUserDataByIdGroup,
	resetUserDataByIdGroup,
})(injectIntl(withStyles(DepartmentsStyle)(Departments)));
