import config from 'config';
import reduxStore from 'redux/store';
import { axiosRequest } from 'utils/libHelper';

const fileMgrAPIBaseUrl = config.filemanagerAPI.baseUrl;
const authHeaderKey = config.filemanagerAPI.authHeaderKey;

/**
	* Get spreadsheet data by id
	*
	* @param {object} opts. Request options. Format:
	 {
		 ssId: 'xxx', // mandatary. spreadsheet id
		 queryParams: {
			 domain: 'xxxx' // optional. Default will be the domain from Redux store (by user logged in). If specified, it overrides the default
			 ... // other query parameters defined by api
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const fileMgrFetchSSById = (opts = {}) => {
	const { ssId, queryParams = {} } = opts;
	let reqUrl = `${fileMgrAPIBaseUrl}/spreadsheets/${ssId}`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authenticationState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Get spreadsheet Esign filters
	*
	* @param {object} opts. Request options. Format:
	 {
		 ssId: 'xxx', // mandatary. spreadsheet id
		 queryParams: {
			 domain: 'xxxx' // optional. Default will be the domain from Redux store (by user logged in). If specified, it overrides the default
			 ... // other query parameters defined by api
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const fileMgrFetchSSEsignFilters = (opts = {}) => {
	const { ssId, queryParams = {} } = opts;
	let reqUrl = `${fileMgrAPIBaseUrl}/spreadsheets/${ssId}/esignfilters`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authenticationState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Fetch artwork template list that use the spreadsheet
	*
	* @param {object} opts. Request options. Format:
	 {
		 ssId: 'xxx', // mandatary. spreadsheet id
		 queryParams: {
			 domain: 'xxxx' // optional. Default will be the domain from Redux store (by user logged in). If specified, it overrides the default
			 ... // other query parameters defined by api
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const fileMgrFetchSSArtTemplateList = (opts = {}) => {
	const { ssId, queryParams = {} } = opts;
	let reqUrl = `${fileMgrAPIBaseUrl}/spreadsheets/${ssId}/templates`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authenticationState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * Following are a list of exported functions
 */
// const defaultExport = {
// 	fileMgrFetchSSById, // Get spreadsheet data by id
// 	fileMgrFetchSSEsignFilters, // Get spreadsheet Esign filters
// 	fileMgrFetchSSArtTemplateList, // Fetch artwork template list that use the spreadsheet
// };
