import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import MediafilePicker from '../MediafilePicker/MediafilePicker.jsx';
import GenerateImage from '../GenerateImage/GenerateImage.jsx';
import S3Uploader from '../S3Uploader/S3Uploader.jsx';
import EllipsisLoader from '../EllipsisLoader/EllipsisLoader.jsx';
import { _, moment } from 'utils/libHelper';
import { genRandomStr } from 'utils/generalHelper.js';
// import { getDomainConfig, getPreviewUrl } from 'utils/appHelper';
import { getDomainConfig } from 'utils/appHelper';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
// import { Rnd } from 'react-rnd';
// MUI components
import { grey } from '@mui/material/colors';
import {
	AppBar,
	Tabs,
	Tab,
	Typography,
	Tooltip,
	IconButton,
	Divider,
	Backdrop,
	CircularProgress,
} from '@mui/material';
import {
	Close as CloseIcon,
	CloudUpload as UploadIcon,
	PhotoLibrary as FilemanagerIcon,
	FolderShared as MyfilesIcon,
} from '@mui/icons-material';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
// import { endOfDay, startOfDay } from 'date-fns';

// intl lang
import { useIntl } from 'react-intl';
// import { uploadFileFromAI } from 'restful';
// import { uploadFileFromAI, searchMediaFiles } from 'restful';

// redux
import { connect } from 'react-redux';
import {
	searchFileManagerMediafiles,
	resetFileManagerSearchedMediafiles,
	postProcessUploadedFiles,
	resetPostProcessUploadedFiles,
	reSyncCounter,
	fetchProgressUploadedFiles,
} from 'redux/actions'; // actions

// variables
const DEFAULT_SEARCH_OPTS = {
	keywords: '',
	currentPageNo: 1, // 1-indexed
};
const DEFAULT_SEARCH_OPTS_AI = {
	keywords: '',
};
const TOOLKIT_FILELIBRARY_PROVIDER = 'TOOLKIT_FILELIBRARY';
const TOOLKIT_FILEUPLOADER_PROVIDER = 'TOOLKIT_FILEUPLOADER';
const TOOLKIT_MYFILES_PROVIDER = 'TOOLKIT_MYFILES';
const TOOLKIT_GENERATE_IMAGE = 'TOOLKIT_GENERATE_IMAGE';
const SUPPORTED_PROVIDERS = [
	TOOLKIT_FILELIBRARY_PROVIDER,
	TOOLKIT_FILEUPLOADER_PROVIDER,
	TOOLKIT_MYFILES_PROVIDER,
	TOOLKIT_GENERATE_IMAGE,
];
const INTERVAL_FETCH_PROGRESS = 3000; // in ms

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// height: '100%',
	},
	tabsWrapper: {
		marginBottom: theme.spacing(1),
		borderRadius: 4,
		// width: '100%',
		// backgroundColor: 'inherit',
		// height: 48,
		// color: 'inherit',
		// flex: '0 0 auto',
		// '&:before': {
		// 	content: '""',
		// },
		// '&:after': {
		// 	content: '""',
		// 	paddingTop: theme.spacing(1),
		// },
	},
	uploaderWrapper: {
		position: 'relative',
	},
	uploadMsgWrapper: {
		position: 'sticky',
		top: 0,
		zIndex: 999,
		padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'space-between',
		display: 'flex',
		opacity: 1,
		transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
	},
	uploadMsgHidden: {
		opacity: 0,
		position: 'absolute',
	},
	uploadValidate: {
		backgroundColor: 'rgba(0,0,0,0.5)',
		color: grey[300],
		justifyContent: 'center',
	},
	validateMsg: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	uploadError: {
		backgroundColor: theme.palette.danger.main,
		color: grey[300],
	},
	uploadSuccess: {
		backgroundColor: theme.palette.success.main,
		color: grey[300],
	},
	uploadingWrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		[theme.breakpoints.up(theme.mobileViewBreakpoint)]: {
			flexDirection: 'row',
		},
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			flexDirection: 'column',
		},
	},
	backdropLoading: {
		zIndex: 999,
		position: 'absolute',
		alignItems: 'flex-start',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		color: theme.palette.primary.main,
		borderRadius: 4,
	},
	loadingCircularProgress: {
		position: 'sticky',
		top: 'calc(50% - 20px)',
	},
}));

const StyledTab = withStyles({
	root: {
		minWidth: 40,
		minHeight: 48,
	},
	wrapper: {
		flexDirection: 'row',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		// // maxWidth: '100%',
		// justifyContent: 'flex-start',
	},
})(Tab);

export const StyledDividerVer = withStyles((theme) => ({
	vertical: {
		alignSelf: 'stretch',
		height: 'auto',
		margin: theme.spacing(1, 0.5),
		borderColor: 'rgba(255,255,255,0.3)',
	},
}))(({ classes }) => {
	return <Divider orientation="vertical" className={classes.vertical} />;
});

function MediafileMultiChoosers({
	// open,
	enabledProviders,
	filtersToolkitMyFiles,
	filtersToolkitFileLibrary,
	initSearchableKeywords,
	initGenerateAIPrompt,
	onMediafileSelect,
	numItemsPerPage,
	numItemsPerRow,
	S3UploaderProps,
	// redux props
	FILEMANAGER_SearchingStatus,
	FILEMANAGER_FoundMediafiles,
	// RESYNC_FoundMediafiles,
	FILEMANAGER_TotalFound,

	domainName,
	userId,
	uploadRequestStatus,
	uploadRequestErrMsg,
	postProcessUploadedFiles,
	resetPostProcessUploadedFiles,

	searchFileManagerMediafiles,
	resetFileManagerSearchedMediafiles,
	reSyncCounter,
	fetchProgressUploadedFiles,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const providerConf = {
		[TOOLKIT_FILELIBRARY_PROVIDER]: {
			label: intl.formatMessage({
				id: 'components.MediafileMultiChoosers.ToolkitFileLibraryProviderLabel',
			}),
			icon: <FilemanagerIcon style={{ marginRight: 6 }} />,
		},
		[TOOLKIT_MYFILES_PROVIDER]: {
			label: intl.formatMessage({
				id: 'components.MediafileMultiChoosers.ToolkitMyfilesProviderLabel',
			}),
			icon: <MyfilesIcon style={{ marginRight: 6 }} />,
		},
		[TOOLKIT_FILEUPLOADER_PROVIDER]: {
			label: intl.formatMessage({
				id: 'components.MediafileMultiChoosers.ToolkitFileUploaderProviderLabel',
			}),
			icon: <UploadIcon style={{ marginRight: 6 }} />,
		},
		[TOOLKIT_GENERATE_IMAGE]: {
			label: 'GENERATE IMAGE',
			icon: <PhotoFilterIcon style={{ marginRight: 6 }} />,
		},
	};
	const [promptOpts, setPromptOpts] = React.useState({
		...DEFAULT_SEARCH_OPTS_AI,
		keywords: initGenerateAIPrompt,
	});
	const [searchOpts, setSearchOpts] = React.useState({
		...DEFAULT_SEARCH_OPTS,
		keywords: initSearchableKeywords,
	});
	// provider of mediafiles, e.g. "TOOLKIT_FILELIBRARY", "SHUTTERSTOCK", etc.
	const [activeProvider, setActiveProvider] = React.useState(enabledProviders[0]);
	const [isFetchingProgress, setIsFetchingProgress] = React.useState(false);
	const [uploadStatus, setUploadStatus] = React.useState(false);

	const domainConf = React.useMemo(() => {
		return getDomainConfig(domainName) || {};
	}, [domainName]);

	const cleanData = () => {
		// reset search results in all providers, in case there was uncleaned data
		resetFileManagerSearchedMediafiles();
		// reset other redux props
		resetPostProcessUploadedFiles();
	};
	React.useEffect(() => {
		// // reset search results in all providers (and other redux data), in case there was uncleaned data
		// cleanData();
		// // do initial search (activeProvider is the first provider)
		// // let filters = {},
		// // 	searchFunc = () => null;
		// // switch (activeProvider) {
		// // 	case TOOLKIT_FILELIBRARY_PROVIDER:
		// // 		filters = filtersFilemanager;
		// // 		searchFunc = searchFileManagerMediafiles;
		// // 		break;
		// // 	default:
		// // 		break;
		// // }
		// // searchFunc({ queryParams: { offset: 0, limit: numItemsPerPage }, filters: { ...filters } });

		// let activeProviderObject = getProviderObject(activeProvider);
		// activeProviderObject.searchFunc({
		// 	queryParams: activeProviderObject.queryParams,
		// 	filters: { ...activeProviderObject.filters },
		// });

		return () => {
			// reset search results in all providers and other redux data used in this component
			cleanData();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		// when changing tab, we reset search results in all providers (and other redux data), in case there was uncleaned data
		cleanData();
		// after resetting any previous data, we initialize the data set for the new tab
		let activeProviderObject = getProviderObject(activeProvider);
		activeProviderObject.searchFunc({
			queryParams: activeProviderObject.queryParams,
			filters: {
				...activeProviderObject.filters,
				...(searchOpts.keywords ? { keywords: searchOpts.keywords } : {}),
			},
		});
		// Don't reset the data in this side effect, the dataset will be reset "at beginning of this side effect after changing to another tab" or on unmount
		// return () => {
		// 	activeProviderObject.resetResultFunc();
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeProvider]);

	const getProviderObject = (provider) => {
		// data of active mediafiles provider
		let providerObject = {
			// tabName: 'Mediafiles',
			searchingStatus: '', // possible value: 'PROCESSING', 'OK', 'FAILED', ''
			totalFound: 0,
			foundMediafiles: [],
			queryParams: {},
			filters: {},
			searchFunc: () => null,
			resetResultFunc: () => null,
		};
		switch (provider) {
			case TOOLKIT_FILELIBRARY_PROVIDER:
			case TOOLKIT_MYFILES_PROVIDER:
				providerObject = {
					// tabName: 'Mediafiles',
					searchingStatus: FILEMANAGER_SearchingStatus,
					totalFound: FILEMANAGER_TotalFound,
					foundMediafiles: FILEMANAGER_FoundMediafiles.map((mediafile) => {
						return {
							id: mediafile.id,
							title: mediafile.title,
							previewUrl: mediafile.previewUrl,
						};
					}),
					queryParams: { previewSize: 'small', offset: 0, limit: numItemsPerPage },
					filters:
						provider === TOOLKIT_FILELIBRARY_PROVIDER
							? filtersToolkitFileLibrary
							: filtersToolkitMyFiles,
					searchFunc: searchFileManagerMediafiles,
					resetResultFunc: resetFileManagerSearchedMediafiles,
				};
				break;
			default:
				break;
		}
		return providerObject;
	};
	const activeProviderObject = getProviderObject(activeProvider);

	return (
		<div className={classes.root}>
			<AppBar position="static" className={classes.tabsWrapper}>
				<Tabs
					variant="scrollable"
					indicatorColor="secondary"
					textColor="inherit"
					value={activeProvider}
					scrollButtons="auto"
					onChange={(e, provider) => {
						if (provider === activeProvider) return;
						// // reset all used data when changing tab (moved "reset" to useEffect on changing tab)
						// cleanData();
						// reset search options
						setSearchOpts(DEFAULT_SEARCH_OPTS);
						setPromptOpts(promptOpts);
						// set active provider
						setActiveProvider(provider);
					}}
				>
					{enabledProviders
						.map((provider) => {
							return (
								<StyledTab
									key={provider}
									value={provider}
									icon={providerConf[provider].icon || null}
									label={providerConf[provider].label || provider}
									disabled={activeProviderObject.searchingStatus === 'PROCESSING'}
								/>
							);
						})
						.reduce(
							(accu, ele, idx) =>
								accu.length === 0 ? [ele] : [...accu, <StyledDividerVer key={idx} />, ele],
							[]
						)}
				</Tabs>
			</AppBar>

			{activeProvider === TOOLKIT_GENERATE_IMAGE ? (
				<GenerateImage
					promptKeywords={promptOpts.keywords}
					onMediaClick={(res, filePromptName) => {
						// 1. format unified output mediafile data from different provider (different mediafile data strature)
						let formattedMediafile = {};
						formattedMediafile = {
							previewUrl: res.url,
							optimisedUrl: res.url,
							highResUrl: res.url,
						};
						onMediafileSelect(formattedMediafile, filePromptName);
					}}
				></GenerateImage>
			) : activeProvider !== TOOLKIT_FILEUPLOADER_PROVIDER ? (
				<div className={classes.uploaderWrapper}>
					{uploadStatus && (
						<div className={cx(classes.uploadMsgWrapper, classes.uploadSuccess)}>
							<Typography component="span">
								{intl.formatMessage({
									id: 'components.MediafileMultiChoosers.UploadSuccessText',
								})}
							</Typography>
							<Tooltip title={intl.formatMessage({ id: 'GENERAL.Close' })}>
								<IconButton
									edge="start"
									aria-label="close"
									size="small"
									style={{ marginLeft: 8, alignSelf: 'baseline' }}
									onClick={() => {
										setUploadStatus(false);
										resetPostProcessUploadedFiles();
									}}
								>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</div>
					)}
					<MediafilePicker
						mediafiles={activeProviderObject.foundMediafiles}
						searchKeywords={searchOpts.keywords}
						onMediaClick={(mediafileId) => {
							// console.log(`Selected image ${mediafileId}`);
							// 1. format unified output mediafile data from different provider (different mediafile data strature)
							let formattedMediafile = {};
							switch (activeProvider) {
								case TOOLKIT_MYFILES_PROVIDER:
								case TOOLKIT_FILELIBRARY_PROVIDER: {
									let selectedMediafile = _.find(
										FILEMANAGER_FoundMediafiles,
										(mediafile) => mediafile.id === mediafileId
									);
									if (!selectedMediafile) {
										// selected mediafile can't be found ??? Shall we throw error ???
										console.debug(`can't find selected mediafile ${mediafileId}`);
										return null;
									}
									formattedMediafile = {
										id: selectedMediafile.id,
										previewUrl: selectedMediafile.previewUrl || null,
										optimisedUrl: selectedMediafile.optimisedUrl || null,
										highResUrl: selectedMediafile.highResUrl || null,
										originalUrl: selectedMediafile.originalUrl || null,
										svgUrl: selectedMediafile.svgUrl || null,
										fileType: selectedMediafile.fileType || null,
										title: selectedMediafile.title || null,
										duration: selectedMediafile.duration || null,
										postingDate: selectedMediafile.postingDate || null,
										expiryDate: selectedMediafile.expiryDate || null,
									};
									break;
								}
								default:
									break;
							}
							// 2. reset search options (NOTE:  we don't reset the data & search options, but leave the "reset" to the caller to unmount this component)
							// setSearchOpts(DEFAULT_SEARCH_OPTS);
							// 3. reset searched mediafiles result
							// activeProviderObject.resetResultFunc();
							// 4. output the selected mediafile by handler from caller of this component
							onMediafileSelect(formattedMediafile);
						}}
						previewHeight={130}
						// extraMediaActions={[
						// 	{
						// 		icon: <AddIcon style={{ fontSize: '1.1rem' }} />,
						// 		tooltip: 'add',
						// 		clickHandler: mediafileId => console.log(`Add media ${mediafileId}`),
						// 	},
						// 	{
						// 		icon: <SaveIcon style={{ fontSize: '1.1rem' }} />,
						// 		tooltip: 'Save',
						// 		clickHandler: mediafileId => console.log(`Save media ${mediafileId}`),
						// 	},
						// ]}
						isLoading={activeProviderObject.searchingStatus === 'PROCESSING'}
						noDataMsg="No Media Files!!"
						numItemsPerRow={numItemsPerRow || 4}
						searchHandler={(keywords) => {
							// console.log(`Search for "${keywords}"`);

							activeProviderObject.searchFunc(
								{
									queryParams: activeProviderObject.queryParams,
									filters: { ...activeProviderObject.filters, keywords },
								},
								({ error }) => {
									if (error) return;
									setSearchOpts({ ...searchOpts, keywords: keywords || '', currentPageNo: 1 });
								}
							);
						}}
						clearSearchHandler={() => {
							// console.log('Clear search');
							activeProviderObject.searchFunc(
								{
									queryParams: activeProviderObject.queryParams, //{ offset: 0, limit: numItemsPerPage },
									filters: activeProviderObject.filters,
								},
								({ error }) => {
									if (error) return;
									setSearchOpts({ ...searchOpts, keywords: '', currentPageNo: 1 });
								}
							);
						}}
						currentPageNo={searchOpts.currentPageNo || 1}
						totalPageNum={Math.ceil(activeProviderObject.totalFound / numItemsPerPage)}
						pageChangeHandler={(pageNo) => {
							// console.log(`mediafile chooser page number is changed to ${pageNo}`);
							activeProviderObject.searchFunc(
								{
									queryParams: {
										...activeProviderObject.queryParams,
										offset: (pageNo - 1) * numItemsPerPage,
									},
									filters: { ...activeProviderObject.filters, keywords: searchOpts.keywords },
								},
								({ error }) => {
									if (error) return;
									setSearchOpts({ ...searchOpts, currentPageNo: pageNo });
								}
							);
						}}
					/>
				</div>
			) : (
				<div className={classes.uploaderWrapper}>
					<div
						className={cx(classes.uploadMsgWrapper, {
							[classes.uploadValidate]: uploadRequestStatus === 'PROCESSING',
							[classes.uploadError]: uploadRequestStatus === 'FAILED',
							// [classes.uploadSuccess]: uploadRequestStatus === 'OK',
							[classes.uploadMsgHidden]: uploadRequestStatus === '',
						})}
					>
						{uploadRequestStatus === 'PROCESSING' && (
							<div className={classes.validateMsg}>
								<Typography variant="h5">
									{intl.formatMessage({
										id: 'components.MediafileMultiChoosers.ValidateFilesTitle',
									})}
								</Typography>
								<Typography variant="subtitle2">
									{intl.formatMessage({
										id: 'components.MediafileMultiChoosers.ValidateFilesMsg',
									})}
								</Typography>
								<EllipsisLoader style={{ fontSize: '4rem' }} />
							</div>
						)}
						{uploadRequestStatus === 'FAILED' && (
							<Typography component="span">
								{intl.formatMessage(
									{
										id: 'components.MediafileMultiChoosers.ValidateFilesErrorMsg',
									},
									{ errMsg: uploadRequestErrMsg }
								)}
							</Typography>
						)}
						{/* {uploadRequestStatus === 'OK' && (
							<Typography component="span">
								{intl.formatMessage({
									id: 'components.MediafileMultiChoosers.UploadSuccessText',
								})}
							</Typography>
						)} */}
						{['FAILED'].includes(uploadRequestStatus) && (
							<Tooltip title={intl.formatMessage({ id: 'GENERAL.Close' })}>
								<IconButton
									edge="start"
									aria-label="close"
									size="small"
									style={{ marginLeft: 8, alignSelf: 'baseline' }}
									onClick={() => resetPostProcessUploadedFiles()}
								>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						)}
					</div>
					{
						<Backdrop className={classes.backdropLoading} open={isFetchingProgress}>
							<CircularProgress color="inherit" className={classes.loadingCircularProgress} />
						</Backdrop>
					}
					<S3Uploader
						// title="Uploading Files"
						maxFiles={3}
						maxSize={domainConf.maxFileUploaderSingleFileSize}
						maxTotalSize={domainConf.maxFileUploaderTotalFilesSize}
						actionOnFailure="CONFIRM"
						s3Bucket={domainConf.s3Bucket}
						s3FilepathBase={`/${domainName}/${moment.utc().format('YYYYMMDD')}/${genRandomStr(
							28
						)}/`}
						handleFileUploadComplete={(result) => {
							// let now = new Date();
							let reqData = {
								unzip: false,
								fileStatus: 'new',
								allowSocialMedia: false,
								allowScreen: false,
								isArtworkTemplate: false,
								keywords: '',
								previewnow: true,
								// below is fixed value
								notifiable: false,
								// adminContactID: userId,
								ownerID: userId,
								userID: userId,
								// dbName: domainName,
								domainName: domainName,
								domainUrl: window.location.origin,

								// uploadDatetime: now.toISOString(),
								files: result.completedFiles.map((f) => ({
									originalName: f.name,
									size: f.size,
									type: f.type,
									url: f.s3Url,
								})),
							};

							postProcessUploadedFiles({ reqData }, ({ result, error }) => {
								// when error, no need to fetch progress
								if (error) return;
								// use setInterval to fetch progress. (Problem with setInterval is that it may start a new fetch before previous fetch finishes)
								// (Keeping it as comment is only for reference. We are using setTimeout to continously fetch processing progress)
								setIsFetchingProgress(true);
								// use setTimeout to fetch progress
								const fetchProgress = () => {
									let timeoutTimer = setTimeout(() => {
										fetchProgressUploadedFiles(
											{ queueID: result.data.queueID },
											({ result, error }) => {
												if (!error && ['completed', 'failed'].includes(result.data.status)) {
													clearTimeout(timeoutTimer);
													reSyncCounter();
													setIsFetchingProgress(false);
													setUploadStatus(true);
													// setActiveProvider('TOOLKIT_MYFILES');
													setActiveProvider(TOOLKIT_MYFILES_PROVIDER);
												} else {
													fetchProgress();
												}
											}
										);
									}, INTERVAL_FETCH_PROGRESS);
								};
								fetchProgress();
							});
						}}
						{...S3UploaderProps}
					/>
				</div>
			)}
		</div>
	);
}

MediafileMultiChoosers.propTypes = {
	// /**
	//  * if true, open this dialog. Default false
	//  */
	// open: PropTypes.bool,

	/**
	 * An array of mediafiles provider to be enabled (Required)
	 * Supported providers are ['TOOLKIT_FILELIBRARY', 'TOOLKIT_MYFILES', 'TOOLKIT_FILEUPLOADER']
	 * Note: First provider in the array will be the initial active provider. The order in the array is the order of the provider tabs
	 * Type: array
	 */
	enabledProviders: (props, propName, componentName) => {
		let prop = props[propName],
			expectedProviders = SUPPORTED_PROVIDERS;
		if (!Array.isArray(prop)) {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}. Must be an array.`);
		} else if (prop.length === 0) {
			return new Error(
				`Invalid prop ${propName} supplied to ${componentName}. Cannot be an empty array.`
			);
		} else if (prop.length !== new Set(prop).size) {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}. Duplicate values.`);
		} else if (!prop.every((val) => expectedProviders.includes(val))) {
			return new Error(
				`Invalid prop ${propName} supplied to ${componentName}. Value must be one of ${expectedProviders.join(
					', '
				)}.`
			);
		}
	},

	/**
	 * filters for TOOLKIT_MYFILES_PROVIDER
	 * The filters are used when calling "/mediafiles"" endpoint (POST) in filemanager api.
	 * It can be any filters defined in the endpoint except "keywords".
	 * For details of the defined filters, see the spec of the endpoint
	 */
	filtersToolkitMyFiles: PropTypes.object,

	/**
	 * filters for TOOLKIT_FILELIBRARY_PROVIDER
	 * The filters are used when calling "/mediafiles"" endpoint (POST) in filemanager api.
	 * It can be any filters defined in the endpoint except "keywords".
	 * For details of the defined filters, see the spec of the endpoint
	 */
	filtersToolkitFileLibrary: PropTypes.object,

	initGenerateAIPrompt: PropTypes.string,

	/**
	 * search keywords
	 */
	initSearchableKeywords: PropTypes.string,

	/**
	 * Handle mediafile selection
	 * It is the only output from this component
	 * NOTE:
	 * 		- Different provider may have different data structure, e.g. data in our filemanager provider may be different from shutterstock provider
	 * 		- The output data structure is formatted to unify the different data structure from different provider
	 * @return {object} unified mediafile data object. 
	 * the output mediafile data is always formatted as below (no matter which provider is the selected mediafile from):
	 	{
				id: 'xxxx', // mediafile id (what if the mediafile provider doesn't have a mediafile Id?)
				previewUrl: 'preview mediafile url' || null,
				optimisedUrl: 'original/optimised(processed) mediafile url' || null,
				highResUrl: 'original/best-quality mediafile url' || null,
				originalUrl: 'original mediafile url' || null,
				svgUrl: 'svg version of the original mediafile' || null,
				fileType: 'file type' || null // e.g. 'pdf', 'svg', 'jpeg', etc.
				title: 'xxxx' || null, // title of the media file
				duration: 'xx' || null, // duration of the media file in number e.g 9, 10, 0, etc
				postingDate: 'xxxx' || null, // posting Date of the media file
				expiryDate: 'xxxx' || null, // expiry Date of the media file
		}
	 */
	onMediafileSelect: PropTypes.func.isRequired,

	/**
	 * number of mediafiles to be loaded in one page
	 */
	numItemsPerPage: PropTypes.number, // 20,

	/**
	 * number of mediafiles per row
	 */
	numItemsPerRow: PropTypes.number, //4,

	/**
	 * Props to S3Uploader components
	 */
	S3UploaderProps: PropTypes.object,

	// redux props
	FILEMANAGER_SearchingStatus: PropTypes.string.isRequired,
	FILEMANAGER_FoundMediafiles: PropTypes.array.isRequired,
	// RESYNC_FoundMediafiles: PropTypes.array.isRequired,
	FILEMANAGER_TotalFound: PropTypes.number.isRequired,

	domainName: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	uploadRequestStatus: PropTypes.string.isRequired,
	uploadRequestErrMsg: PropTypes.string.isRequired,

	postProcessUploadedFiles: PropTypes.func.isRequired,
	resetPostProcessUploadedFiles: PropTypes.func.isRequired,

	searchFileManagerMediafiles: PropTypes.func.isRequired,
	resetFileManagerSearchedMediafiles: PropTypes.func.isRequired,
	reSyncCounter: PropTypes.func.isRequired,
	fetchProgressUploadedFiles: PropTypes.func.isRequired,
};

/**
 * Requirements for adding new provider
 *
 		- Params in search function of any provider:
			{
				queryParams: {
					previewSize: 'small',
					offset: 0,
					limit: 20,
				},
				filters: { keywords, OTHER_SPECIFIC_FILTERS_USED_BY_PROVIDER }, // "keywords" is the search keywords that user enters in search bar
			}
 */

MediafileMultiChoosers.defaultProps = {
	filtersFilemanager: {},
	filtersMyToolkitFiles: {},
	initSearchableKeywords: '',
	initGenerateAIPrompt: '',
	numItemsPerPage: 20,
	numItemsPerRow: 4,
	S3UploaderProps: {},
};

const mapStateToProps = (state) => {
	return {
		FILEMANAGER_SearchingStatus: state.filemanager.searchingMediafilesStatus, // possible value: 'PROCESSING', 'OK', 'FAILED', ''
		FILEMANAGER_FoundMediafiles: state.filemanager.searchingFoundMediafiles,
		FILEMANAGER_TotalFound: state.filemanager.totalFoundMediafiles,

		domainName: state.authentication.domainName,
		userId: state.authentication.userId,
		uploadRequestStatus: state.filemanager.postProcessUploadedFilesStatus,
		uploadRequestErrMsg: state.filemanager.postProcessUploadedFilesErrMsg,
		// TODO
		// RESYNC_FoundMediafiles: state.generalData.searchingFoundMediafiles,
	};
};

export default connect(mapStateToProps, {
	searchFileManagerMediafiles,
	resetFileManagerSearchedMediafiles,

	postProcessUploadedFiles,
	resetPostProcessUploadedFiles,
	reSyncCounter,
	fetchProgressUploadedFiles,
})(MediafileMultiChoosers);
