// ##############################
// // // Artwork Design styles
// #############################

import makeStyles from '@mui/styles/makeStyles';
import { ART_VARIABLES } from '../../Constants';

const DesignStyle = makeStyles((theme) => ({
	// load artwork animation as @global to make it available in Artwork scope, so that you may preview the user configured artwork animation (in fields) in any "artwork" component without reload it
	// NOTE: it is only the artwork animations, not theme animations
	// '@global': { ...ART_VARIABLES.animations },
	designWrapper: {
		// ...theme.contentWrapper,
		// padding: 0,
		display: 'flex',
		top: 0, //theme.headerBarHeight,
		bottom: 0,
		left: 0,
		right: 0,
		// minWidth: '100%',
		// maxWidth: '100%',
		position: 'absolute',
		// minHeight: `calc(100vh - ${theme.headerBarHeight}px)`,
		// maxHeight: `calc(100vh - ${theme.headerBarHeight}px)`,
		backgroundColor: ART_VARIABLES.cssStyles.designWrapperBGColor, //'#edf0f2',
		// boxSizing: 'border-box',
	},
	workspaceWrapper: {
		position: 'absolute',
		left: ART_VARIABLES.cssStyles.menuBarWidth,
		top: 0,
		bottom: ART_VARIABLES.cssStyles.actionRibbonHeight,
		right: 0,
		// flex: '1 0 100%',
		// flexGrow: 1,
		// width: '100%',
		// minHeight: '100%',
		// maxHeight: '100%',
		// boxSizing: 'border-box',
		zIndex: 0,
		// overflow: 'hidden',
		margin: 'auto',
		display: 'block',
		userSelect: 'none',
	},
	bottomActionRibbonWrapper: {
		position: 'absolute',
		left: ART_VARIABLES.cssStyles.menuBarWidth,
		// top: `calc(100% - ${ART_VARIABLES.cssStyles.actionRibbonHeight}px)`,
		height: ART_VARIABLES.cssStyles.actionRibbonHeight,
		bottom: 0,
		right: 0,
		backgroundColor: 'inherit',
		...theme.customBoxShadow,
		zIndex: 1,
		margin: 'auto',
		display: 'block',
		userSelect: 'none',
	},
	workspaceSection: {
		// width: '100%',
		// height: '100%',
		// overflow: 'hidden',
		// // zIndex: 0,
		// display: 'block',
		// // alignItems: 'center',
		// // justifyContent: 'center',
		// userSelect: 'none',
		// // transition: 'transform .3s ease-in-out',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		position: 'absolute',
		zIndex: 0,
	},

	designBorder: {
		overflow: 'hidden',
		position: 'relative',
	},
	designAssit: {
		position: 'absolute',
		border: `1px solid ${theme.palette.gray.main}`,
	},
	warnText: {
		color: theme.palette.warning.main,
	},
	outputTemplateLink: {
		textAlign: 'left',
	},
	videoBGWrapperForVideoArtwork: {
		// width: '100%',
		// height: '100%',
		position: 'absolute',
		// zIndex: -1,
	},
	videoControlBar: {
		position: 'absolute !important',
		bottom: '-30px !important',
		height: '30px !important',
	},
	dataGridHeader: {
		fontWeight: 700,
		fontSize: '1rem',
	},
	dataGridAutoWrapCell: {
		whiteSpace: 'normal',
	},
}));

export default DesignStyle;
