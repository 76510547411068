import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Link, Typography } from '@mui/material';

import { toLocaleDate } from 'utils/generalHelper';

// intl lang
import { useIntl } from 'react-intl';

// CSS
const useStyles = makeStyles((theme) => ({
	dateLink: {
		cursor: 'pointer',
	},
}));
function useColumnsConfig(handleGotoFileExplored) {
	const classes = useStyles();
	const intl = useIntl();
	const columnsConfig = React.useMemo(() => {
		return [
			{
				Header: intl.formatMessage({
					id: 'pages.MyFiles.components.MyFilesTable.TableHeaderDate',
				}),
				accessor: 'dateCreated',
				id: 'dateCreated',
				Cell: function renderCell({ value, row }) {
					return (
						<Link
							underline="always"
							className={classes.dateLink}
							onClick={() => handleGotoFileExplored(row.original)}
						>
							{value ? toLocaleDate(new Date(value)) : ''}
						</Link>
					);
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.MyFiles.components.MyFilesTable.Files',
				}),
				accessor: 'numberOfFiles',
				id: 'numberOfFiles',
				Cell: function renderCell({ value }) {
					return <Typography variant="inherit">{value}</Typography>;
				},
			},
		];
	}, [intl, classes.dateLink, handleGotoFileExplored]);

	return columnsConfig;
}
// parameters passed must be memorized
export default useColumnsConfig;
