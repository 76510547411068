import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { _, moment } from 'utils/libHelper';

// intl lang
import { useIntl } from 'react-intl';
import { getRouteMediafileDetail, getRouteArtworkCreator } from 'routes';

// MUI components
import { MenuItem, Checkbox, ListItemText, Menu, IconButton } from '@mui/material';
// import { IconButton } from '@mui/material';

import { Done as ApplyIcon, Close as CloseIcon } from '@mui/icons-material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
// restful
import { fetchSelectedPlaylist, addScreenPlaylist } from 'restful';

// redux
import { connect } from 'react-redux';
import {
	notifyGeneral,
	fetchScreenManagerPlaylists,
	openGlobalDialog,
	resetScreenManagerPlaylists,
} from 'redux/actions'; // actions
const useStyles = makeStyles((theme) => ({
	root: {
		padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
		width: `100%`,
		color: 'inherit',
		fontSize: 'inherit',
		position: 'relative',
		display: 'flex',
	},
	cardAction: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 2),
		gap: theme.spacing(1),
	},
	actionIconButton: {
		padding: 4,
		border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
	},
	iconButton: {
		backgroundColor: theme.palette.primary.main,
		padding: 4,
		color: '#ffffff',
		'&:hover, &:focus': {
			backgroundColor: theme.palette.primary.dark, //'#2f4ffe',
		},
		cursor: 'pointer',
	},
	iconButtonCancel: {
		padding: 4,
		border: `1px solid`,
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
		cursor: 'pointer',
	},
}));
function AddMediaToPlaylists({
	mediaFileValue,
	fetchScreenManagerPlaylists,
	screenManagerPlaylists,
	notifyGeneral,
	openGlobalDialog,
	resetScreenManagerPlaylists,
	// handleGoToArtWork,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const [screenPlaylistSelect, setScreenPlaylistSelect] = React.useState([]);
	const [mediaFileTemplate, setMediaFileTemplate] = React.useState(null);
	const [anchorPosition, setAnchorPosition] = React.useState(null);
	const handleGotoCustomArtwork = (mediafile) => {
		if (mediafile.isEsign) {
			window.location.assign(getRouteMediafileDetail(mediafile.id));
		} else {
			window.location.assign(getRouteArtworkCreator(mediafile.id));
		}
	};
	const getFileType = (type) => {
		let newType = '';
		if (['video', 'mpeg-4', 'mpg', 'mp4'].indexOf(type.toLowerCase()) > -1) {
			newType = 'video';
			return newType;
		} else if (type.toLowerCase() === 'svg') {
			newType = 'svg';
			return newType;
		} else if (type.toLowerCase() === 'html') {
			newType = 'html';
			return newType;
		} else if (type.toLowerCase() === 'weburl') {
			newType = 'weburl';
			return newType;
		} else {
			newType = 'image';
			return newType;
		}
	};
	const createScheduleObj = (start, end) => {
		let schedule = {};
		if (moment(start).isValid()) {
			schedule.availableStart = start;
		}
		if (moment(end).isValid()) {
			schedule.availableEnd = end;
		}
		return schedule;
	};
	const handleSaveSelectedScreenPlaylist = (selectedPlaylist, mediafile) => {
		let fileType = getFileType(mediafile.mediafile.fileType);
		let newSchedule = createScheduleObj(
			mediafile.mediafile.goLiveDate,
			mediafile.mediafile.expiryDate
		);
		let reqData = {
			playlists: selectedPlaylist,
			name: mediafile.mediafile.title,
			duration:
				mediafile.mediafile.fileType.toUpperCase() === 'VIDEO' ? mediafile.mediafile.duration : 10,
			schedule: {
				...newSchedule,
			},
			type: fileType,
			mediaId: mediafile.mediafile.id,
			mediaUrl: fileType === 'svg' ? mediafile.mediafile.svgUrl : mediafile.mediafile.optimisedUrl,
			mediaPreviewUrl: mediafile.mediafile.previewUrl,
		};
		addScreenPlaylist({ bodyParams: reqData })
			.then(() => {
				handleMenuListClose();
			})
			.catch((err) => {
				notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
			});
	};
	const handleMenuListClose = () => {
		setMediaFileTemplate(null);
		setAnchorPosition(null);
	};
	const handleOpenAddScreenPlaylist = (mediafile, e) => {
		if (mediafile.isArtworkTemplate) {
			let confirmDialog = {
				size: 'sm',
				title: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileList.ScreensMenuListDialogTitle',
				}),
				content: intl.formatMessage({
					id: 'pages.MediaFiles.components.MediafileList.ScreensMenuListDialogContent',
				}),
				confirmCB: () => handleGotoCustomArtwork(mediafile),
			};
			openGlobalDialog(confirmDialog);
		} else {
			if (screenManagerPlaylists.playlists.length === 0) {
				fetchScreenManagerPlaylists();
			}
			fetchSelectedPlaylist({ mediafileId: mediafile.id })
				.then((response) => {
					let playlistData = response.data.playlists;
					setScreenPlaylistSelect(
						playlistData.length > 0 ? playlistData.map((item) => item.id) : []
					);
				})
				.catch((err) => {
					console.log('ERR', err);
					// let errMsg = err.response ? err.response.data.message : err.message;
					// notifyGeneral(`Failed to fetch playlist. ${errMsg}`, 'error');
				});
			setMediaFileTemplate({
				mediafile,
			});
			setAnchorPosition({
				anchorEl: e.currentTarget,
			});
		}
	};

	React.useEffect(() => {
		return () => {
			// reset ScreenManagerPlaylists on unmount
			resetScreenManagerPlaylists();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let menuListPlaylist = [];
	if (anchorPosition) {
		menuListPlaylist = (screenManagerPlaylists.playlists || []).map((m) => {
			if (mediaFileTemplate) {
				let orientation = '';
				if (mediaFileTemplate.mediafile.fileWidth > mediaFileTemplate.mediafile.fileHeight) {
					//it's a landscape
					orientation = 'landscape';
				} else if (mediaFileTemplate.mediafile.fileWidth < mediaFileTemplate.mediafile.fileHeight) {
					//it's a portrait
					orientation = 'portrait';
				} else {
					//image width and height are equal, therefore it is square.
					orientation = 'square';
				}
				return {
					...m,
					disabled: m.layout !== orientation,
				};
			} else {
				return {
					...m,
				};
			}
		});
	}
	return (
		<div>
			<IconButton
				aria-label="AddScreenPlaylist"
				className={classes.actionIconButton}
				onClick={(e) => handleOpenAddScreenPlaylist(mediaFileValue, e)}
				title="AddScreenPlaylist"
				size="small"
			>
				<PlayCircleFilledWhiteIcon fontSize="small" />
			</IconButton>
			<Menu
				id="action-menu"
				anchorEl={anchorPosition ? anchorPosition.anchorEl : null}
				keepMounted
				open={Boolean(anchorPosition ? anchorPosition.anchorEl : null)}
				onClose={handleMenuListClose}
			>
				{(menuListPlaylist || []).map((item) => (
					<MenuItem
						key={item.id}
						value={item.id}
						disabled={item.disabled || false}
						dense
						onClick={() => {
							if (screenPlaylistSelect.includes(item.id)) {
								let newScreenPlaylistSelect = screenPlaylistSelect.filter(
									(items) => items !== item.id
								);
								setScreenPlaylistSelect(newScreenPlaylistSelect);
							} else {
								setScreenPlaylistSelect(screenPlaylistSelect.concat([item.id]));
							}
						}}
					>
						<Checkbox
							checked={screenPlaylistSelect.includes(item.id)}
							size="small"
							style={{ padding: 4, marginRight: 4 }}
						/>
						<ListItemText primary={item.name} primaryTypographyProps={{ variant: 'body2' }} />
					</MenuItem>
				))}
				<div className={classes.cardAction}>
					<IconButton
						className={classes.iconButton}
						aria-label="Ok"
						onClick={() => {
							handleSaveSelectedScreenPlaylist(screenPlaylistSelect, mediaFileTemplate);
						}}
						title="Ok"
						size="small"
					>
						<ApplyIcon fontSize="small" />
					</IconButton>
					<IconButton
						className={classes.iconButtonCancel}
						aria-label="Cancel"
						onClick={() => {
							// e.stopPropagation();
							handleMenuListClose();
						}}
						title="Cancel"
						size="small"
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>
			</Menu>
		</div>
	);
}

AddMediaToPlaylists.propTypes = {
	fetchScreenManagerPlaylists: PropTypes.func.isRequired,
	// handleGoToArtWork: PropTypes.func.isRequired,
	/**
	 * An object which contains mediaFileValue (eg. Mediafiles, myFiles etc) i.e value from
	 *  where menulist is opened
	 */
	mediaFileValue: PropTypes.object,
	screenManagerPlaylists: PropTypes.object.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	resetScreenManagerPlaylists: PropTypes.func.isRequired,
};

AddMediaToPlaylists.defaultProps = {};
// export default AddMediaToPlaylists;

const mapStateToProps = (state) => {
	return {
		screenManagerPlaylists: state.screenManagerPlaylists,
	};
};

export default connect(mapStateToProps, {
	fetchScreenManagerPlaylists,
	resetScreenManagerPlaylists,
	notifyGeneral,
	openGlobalDialog,
})(AddMediaToPlaylists);
