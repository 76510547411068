let config = {
	screenAPI: {
		authHeaderKey: 'Authorization',
		baseUrl: 'https://apigw.visualid.com/screen/v1',
	},
	lassoAPI: {
		baseUrl: 'https://apigw.visualid.com/lassoapi',
		sessionKeyInCookie: '_SessionTracker_Site',
		authHeaderKey: 'Authorization',
	},
	filemanagerAPI: {
		authHeaderKey: 'Authorization',
		baseUrl: 'https://apigw.visualid.com/filemanager/v1',
	},
	usermanagerAPI: {
		authHeaderKey: 'Authorization',
		baseUrl: 'https://apigw.visualid.com/usermanager/v1',
		apiKeyName: 'userkey',
		apiKeyValue: 'DGY330MKwjghpMsaeHd7vpTQFmahUjkn',
	},
	externalSSOAPI: {
		authHeaderKey: 'Authorization',
		baseUrl: 'https://apigw.visualid.com',
	},
	previewApi: {
		baseUrl: 'https://apigw.visualid.com/preview/v1',
		apiKeyName: 'prekey',
		apiKeyValue: 'XTEh1eydHiEv6nKxfj1u4HjCHw1Y5X16',
		getPreviewEP: '/preview/',
	},
	fileZipperAPI: {
		authHeaderKey: 'auth',
		baseUrl: 'https://exapi.visualid.com/zf/v1',
		authHeaderValue: '_SessionTracker_Loggedin_AC1E02B11b7e903B0EoLF79B19B5',
		authHeaderKeyForLogging: 'Authorization',
	},
	dalleAPI: {
		apiKey: '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',
	},
};

export default config;
