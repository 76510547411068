import { tinycolor, _ } from 'utils/libHelper';
import defaultTheme from './default';
import customStyles from './customStyles';
import domains from './domains';
import animations from './animations';
import { createTheme } from '@mui/material/styles';
import { getDomainByHostname } from 'utils/appHelper';

// theme modes: dark, light.
// Note: only light theme is designed,
//			 if want to support dark theme, need to design color for dark theme
//			 reference for dark theme: https://mui.com/material-ui/customization/dark-mode/
export const getAppTheme = (domain, mode = 'light') => {
	if (!domain) {
		domain = getDomainByHostname();
	}

	const lightenRate = 7;
	const darkenRate = 7;
	const MUIDefaultTheme = createTheme({});

	const domainThemeConf = domains[domain] || domains['default'];
	const domainColor = domainThemeConf.mainColor;
	const font = domainThemeConf.font || {};
	const domainTheme = {
		palette: {
			domain: {
				main: domainColor,
				light: tinycolor(domainColor).lighten(lightenRate).toHexString(),
				dark: tinycolor(domainColor).darken(darkenRate).toHexString(),
				contrastText: MUIDefaultTheme.palette.getContrastText(domainColor),
			},
		},
		typography: {
			fontFamily: font.fontFamily,
		},
	};

	if (Array.isArray(font.fontFace) && font.fontFace.length > 0) {
		domainTheme.components = {
			MuiCssBaseline: {
				styleOverrides: {
					'@font-face': font.fontFace,
				},
			},
		};
	}

	const appTheme = createTheme(
		_.merge({}, defaultTheme, { animations }, customStyles, domainTheme, { palette: { mode } })
	);
	return {
		default: appTheme,
	};
};
