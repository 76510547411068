import config from '../../config';
import {
	FETCH_USERDATA,
	FETCH_USERDATA_SUCCESS,
	FETCH_USERDATA_FAILED,
	FETCH_USERDATABYGROUP_SUCCESS,
	RESET_USERDATABYGROUP,
} from 'redux/actions';

const usermanagerAPIBaseUrl = config.usermanagerAPI.baseUrl;
const authHeaderKey = config.usermanagerAPI.authHeaderKey;

/**
 * Fetch user data from usermanager api
 * Note, we don't reset user data during the user session
 * @param {object} opts. {}
 * @param {func} cb
 */
// eslint-disable-next-line no-unused-vars
export const fetchUserData = (opts = {}, cb) => (dispatch, getState) => {
	let reduxState = getState();
	let reqUrl = `${usermanagerAPIBaseUrl}/users/${reduxState.authentication.userId}`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: reduxState.authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		params: { domain: reduxState.authentication.domainName },
		headers: reqHeader,
		timeout: 30000,
	};
	return dispatch({
		types: [FETCH_USERDATA, FETCH_USERDATA_SUCCESS, FETCH_USERDATA_FAILED],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

/**
 * Fetch User data in group
 * Note, we don't reset user data during the user session
 * @param {object} opts. {}
 * @param {func} cb
 */
export const fetchUserDataByIdGroup = (opts = {}, cb) => (dispatch, getState) => {
	let { queryParams = {} } = opts;
	let reduxState = getState();
	let reqUrl = `${usermanagerAPIBaseUrl}/users`;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: reduxState.authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		params: {
			domain: reduxState.authentication.domainName,
			userAdminId: reduxState.authentication.userId,
			...queryParams,
		},
		headers: reqHeader,
		timeout: 30000,
	};
	return dispatch({
		types: [FETCH_USERDATA, FETCH_USERDATABYGROUP_SUCCESS, FETCH_USERDATA_FAILED],
		promise: (client) => {
			return client.request(axiosConfig);
		},
		afterSuccess: cb,
	});
};

/**
 * reset userdata.
 */
export const resetUserDataByIdGroup = () => ({ type: RESET_USERDATABYGROUP });
