// ##############################
// // // Artwork Sidemenu styles
// #############################

import makeStyles from '@mui/styles/makeStyles';
import { ART_VARIABLES } from '../../Constants';
const sidePanelControlWidth = 12;
const sidePanelControlHeight = 45;

const SidemenuStyle = makeStyles((theme) => ({
	sideMenuWrapper: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: ART_VARIABLES.cssStyles.menuBarWidth,
		display: 'flex',
		// flex: `0 0 ${ART_VARIABLES.cssStyles.menuBarWidth + ART_VARIABLES.cssStyles.menuPanelWidth}px`,
		// flex: `0 0 ${ART_VARIABLES.cssStyles.menuBarWidth}px`,
		transition: 'flex .3s cubic-bezier(.86,0,.07,1)',
		// boxSizing: 'border-box',
		userSelect: 'text',
		color: ART_VARIABLES.cssStyles.sideMenuColor,
		// [theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
		// 	flex: `0 0 ${ART_VARIABLES.cssStyles.menuBarWidth}px`,
		// },
	},
	// closeMenuPanel: {
	// 	flex: `0 0 ${ART_VARIABLES.cssStyles.menuBarWidth}px`,
	// },
	menuBar: {
		zIndex: 2,
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: ART_VARIABLES.cssStyles.menuBarWidth,
		backgroundColor: ART_VARIABLES.cssStyles.sideMenuBGColor,
		display: 'flex',
		position: 'relative',
		height: '100%',
		// boxSizing: 'border-box',
		// userSelect: 'none',
	},
	menuBarFieldGroupWrapper: {
		width: '100%',
	},
	menuBarFieldGroup: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		// maxHeight: '60%',
		height: `calc(100% - ${
			(ART_VARIABLES.cssStyles.sideMenuExtraButtonHeight + theme.spacing(1)) * 3
		}px)`,
		width: '100%',
	},
	menuBarFieldButton: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'inherit',
		boxShadow: 'unset',
		color: 'inherit',
		width: '100%',
		// lineHeight: 1.15,
		// fontSize: theme.typography.pxToRem(20),
		padding: 4,
		// boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
		// top: "8px"
		// backgroundColor: tinycolor(theme.palette.domain.main).brighten(3).toHexString(),
		'&:hover': {
			backgroundColor: ART_VARIABLES.cssStyles.menuBarHoverBGColor,
			color: ART_VARIABLES.cssStyles.menuBarHoverColor,
			// padding: 0,
			// boxShadow: 'unset',
		},
		'&:after': {
			content: '""',
			margin: '0 auto',
			width: (ART_VARIABLES.cssStyles.menuBarWidth * 75) / 100,
			paddingTop: 1,
			borderBottom: `1px solid ${ART_VARIABLES.cssStyles.sidePanelBGColor}`,
		},
	},
	menuBarFieldActive: {
		backgroundColor: ART_VARIABLES.cssStyles.sidePanelBGColor, // same color as menu panel
		// padding: 0,
		boxShadow: 'unset',
		width: '100%',
		borderRadius: 'unset',
	},
	menuBarFieldIcon: {
		width: '35px',
		height: '35px',
	},
	menuBarFieldText: {
		fontSize: theme.typography.pxToRem(10),
		// letterSpacing: 1.5,
	},
	buttonLabel: {
		// Aligns the content of the button vertically.
		flexDirection: 'column',
	},
	extraButton: {
		flexShrink: 0,
		cursor: 'pointer',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		borderRadius: 0,
		width: '100%',
		height: ART_VARIABLES.cssStyles.sideMenuExtraButtonHeight,
		margin: theme.spacing(0.5, 0),
	},
	extraButtonIcon: {
		fontSize: 30,
	},
	extraMenuWrapper: {
		// minHeight: '10vh',
		maxHeight: '50vh',
		maxWidth: 220,
		minWidth: 150,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		// justifyContent: 'space-between',
	},
	sidePanel: {
		position: 'absolute',
		height: `calc(100% - ${ART_VARIABLES.cssStyles.actionRibbonHeight}px)`, //'100%',
		right: 0,
		width: ART_VARIABLES.cssStyles.menuPanelWidth,
		padding: '0 0 0 12px',
		backgroundColor: ART_VARIABLES.cssStyles.sidePanelBGColor,
		transition: 'transform .3s cubic-bezier(.86,0,.07,1)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		color: ART_VARIABLES.cssStyles.sidePanelColor,
		zIndex: 1,
		transform: 'translateX(0)',
		// boxSizing: 'border-box',
		// userSelect: 'none',
	},
	openSidePanel: {
		transform: 'translateX(100%)',
	},
	sidePanelContent: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		// height: `calc(100vh - ${theme.headerBarHeight}px)`,
		height: '100%',
		width: '100%',
		background: 'inherit',
		color: 'inherit',
	},
	sidePanelControl: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		// top: '50%',
		right: 0,
		zIndex: 1,
		transform: 'translateX(100%)',
		transformOrigin: 'left',
		cursor: 'pointer',
		backgroundColor: ART_VARIABLES.cssStyles.sidePanelBGColor,
		width: sidePanelControlWidth,
		height: sidePanelControlHeight,
		'&:before': {
			position: 'absolute',
			content: '" "',
			width: 0,
			height: 0,
			borderRight: `${sidePanelControlWidth}px solid transparent`,
			top: sidePanelControlWidth * -1,
			borderBottom: `${sidePanelControlWidth}px solid ${ART_VARIABLES.cssStyles.sidePanelBGColor}`,
		},
		'&:after': {
			position: 'absolute',
			content: '" "',
			width: 0,
			height: 0,
			borderRight: `${sidePanelControlWidth}px solid transparent`,
			bottom: sidePanelControlWidth * -1,
			left: 0,
			borderTop: `${sidePanelControlWidth}px solid ${ART_VARIABLES.cssStyles.sidePanelBGColor}`,
		},
		'&:hover': {
			color: ART_VARIABLES.cssStyles.menuBarHoverColor,
		},
	},
}));

export default SidemenuStyle;
