/** ##############################
 * 	Artwork Server-Side Browser Generator
 *
 * ** Used only by Server-Side Browser **
 * This file will be the entry to build JS lib script that is used by server-side browser
 * Try NOT import too many libraries,
 * Particularly DO NOT import another utils that have too many imports, e.g. libHelper.js
 *
 *  Generator functions that are used in Server-Side Browser
 *
 * 	#############################
 */
// TODO: How to avoid use the s3Client
import { createS3Client, getDomainConfig } from '../appHelper';
import blobStream from 'blob-stream';
import SVGtoPDF from 'svg-to-pdfkit';
import { PDFDocument as PDFLIBDocument, PDFName, PDFPage } from 'pdf-lib';
import { NS, PDFKIT, pixelToPdfPt, ARTWORK_SERVER_SIDE_PROCESS } from './constants';
import _uniqBy from 'lodash/uniqBy';
import { isNullish, floorDecimals, genRandomStr } from '../generalHelper';
import {
	prepareConcatTextField,
	prepareConcatImageField,
	prepareTextField,
	prepareImageField,
	prepareBarcodeField,
	preparePdfField,
	prepareGridField,
} from './artFieldsCreator';
import {
	loadFontface,
	outputTemplatePlan,
	addAnimationStyleToSVG,
	addFontStyleToSVG,
	createSvgDomFromUrl,
	getColorListFromDataAttr,
	getFontListFromDataAttr,
	removeColorListDataAttr,
	removeFontListDataAttr,
	hideFieldOutput,
	isConcatField,
	getConcatFieldType,
	calcAnimationDelay,
	cleanupFontName,
	loadFontToArrayBuffer,
	loadUrlToArrayBuffer,
	retrieveBase64DataUrl,
	convertS3UrlToS3Params,
	getCloudFrontUrlOfS3File,
} from './artUtilsCommon';

/**
 * For both server-side & browser-side artwork pdf generation
 * Generate multiple artwork to pdf (multiple pages) with/without output template file
 *
 * This is function to be used in server-side browser to generate artwork SVG pages, then filemanager api will continue to generate PDF from the SVG pages
 * It must be paired with "serverGenMultipleArtworkPdf" function in `utils/artwork-utils.js` in filemanager api
 *
 * @param {array} fields All fields in the artwork template design
 * @param {object} fieldOutputDataArray // fieldsInputDataArray. [{data: {fieldOutputData}, templateBGData:{}, num: 17}, ...] // fieldOutputData is field output data, combination of user input data and field default data. format: [{FIELD_ID: {}, FIELD_ID: {}, ...}, ...]
 * templateBGData is Optional. Format of templateBGData in fieldOutputDataArray:
  {
		mediafileId: 'xxx',
		mediafilePreviewUrl:'xxx'|''|null,
		mediafileHighResUrl:'xxx'|''|null,
		mediafileOptimisedUrl:'xxx'|''|null,
		mediafilePdfUrl: 'xxx'|''|null,
		mediafileSvgUrl: 'xxx'|''|null,
	}
 * @param {object} opts
  {
			outputTemplate: { "id": 2, "title": "A4 14-Up", "mediafile_id": 0, "page_width": 595, "page_height": 844, "margin_top": 25, "margin_bottom": 0, "margin_left": 0, "margin_right": 0, "gutter_hor": 3, "gutter_ver": 0, "created_by": 0, "created_date": "0000-00-00", "mediafile_url": null },,
			templateSize: {width: xx, height: xx},
			outputFileS3Url: string, // optional. the s3 url where the output pdf file should be saved
			fontList: an array of font object. [{name: 'xxx', fontUrl: 'xxxx'}, ...],
			colorList: OBJECT, {'R-G-B': [c,m,y,k], ...}
			animations: ART_VARIABLES.animations, // for print, hence no animation required
  		CONSTANTS:{
  			placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
				DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
  			DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY
  		},
			reportProgress: func, // function to report progress. param {object}. {status: 'OK'|'DONE'|'FAILED', message: ['xxx', 'yyy', ...]} (NB: message is an array)
			domain: string, // required

			////// below are for server-side only
			fullServerProcess: true/false,
			awsS3: { // required for fullServerProcess. aws s3 credential
				accesskey, // required
				secretkey, // required
			},
			////// below are for browser-side only
			s3Client,
  }
 */
export const generateMultipleArtworkToPdfPagesWithServer = async (
	fields,
	fieldOutputDataArray,
	opts = {}
) => {
	if (fieldOutputDataArray.length === 0) {
		throw new Error('No print data');
	}

	const isFullServerProcess = opts.fullServerProcess === true;

	let domainConf = getDomainConfig(opts.domain);
	let pageCreationReportMsg = '';
	const reportProgress = opts.reportProgress
		? () => {
				opts.reportProgress({
					status: 'OK',
					message: [pageCreationReportMsg],
				});
		  }
		: () => null;

	let s3Client = isFullServerProcess
		? createS3Client({
				accesskey: opts.awsS3.accesskey, // required
				secretkey: opts.awsS3.secretkey, // required
				domain: opts.domain, // optional
				useAccelerateEndpoint: false, // server side s3Client does not use acceleration, so that it can use endpoint within VPC
		  })
		: opts.s3Client;

	// make sure we have loaded all necessary fonts before processing
	if (isFullServerProcess) await loadFontface(opts.fontList);

	const sleep = (timeout) => new Promise((res) => setTimeout(res, timeout || 0));
	const sleepTimeout = 5; // in ms

	let hasOutputTemplate = !isNullish(opts.outputTemplate?.id); // Object.keys(opts.outputTemplate || {}).length > 0;
	let isPrintable = true;
	let outputTemplate = { width: opts.templateSize.width, height: opts.templateSize.height }, // default, we use item size in outputTemplate
		numOfItemsInRow = 1,
		numOfRowsInPage = 1,
		numOfItemsInPage = 1;
	if (hasOutputTemplate) {
		let outputPlan = outputTemplatePlan(opts.templateSize, opts.outputTemplate);
		numOfItemsInRow = outputPlan.numOfItemsInRow;
		numOfRowsInPage = outputPlan.numOfRowsInPage;
		numOfItemsInPage = outputPlan.numOfItemsInPage;
		outputTemplate = outputPlan.formatedOutputTemplate;
	}

	// outputTemplateSVG is the root svg of output (pdf) page. Clone it when using
	const outputTemplateSVG = document.createElementNS(NS.SVG, 'svg');
	outputTemplateSVG.setAttribute('viewBox', `0 0 ${outputTemplate.width} ${outputTemplate.height}`);
	outputTemplateSVG.setAttribute('xmlns', NS.SVG);
	outputTemplateSVG.setAttribute('overflow', 'hidden');
	// no harm to add animation css style here, just in case we need to export svg. It will be ignored when generating pdf
	addAnimationStyleToSVG(outputTemplateSVG, { fields, animations: opts.animations });
	// Create fonts style required in this artwork except the ones in nested artwork. The fonts style required in nested artwork is inside the itself
	// As we use fontList data attribute to carry the font data (including the fonts in nested artwork), so no need to use dataUrl (base64) of the font
	await addFontStyleToSVG(outputTemplateSVG, { full: false, fontList: opts.fontList });

	// rootItemSVG is the root svg of the item ([esign] ticket/card)
	let rootItemSVG = document.createElementNS(NS.SVG, 'svg');
	rootItemSVG.setAttribute('viewBox', `0 0 ${opts.templateSize.width} ${opts.templateSize.height}`);
	rootItemSVG.setAttribute('width', opts.templateSize.width);
	rootItemSVG.setAttribute('height', opts.templateSize.height);
	rootItemSVG.setAttribute('xmlns', NS.SVG);
	rootItemSVG.setAttribute('overflow', 'hidden');

	/**
	 * add background of the item svg (not output svg page). (svg background overrides image background)
	 * Note: we can't use templatePdfUrl for item background here, because item must be a svg dom so that it can be converted to pdf by pdfkit (pdf background can't be inserted to svg dom)
	 * Only one case we use templatePdfUrl for item background is when no outputTemplate and the template (item) has templatePdfUrl, in this case, it is loaded by pdflib in the later stage
	 */
	const insertItemBG = async ({ itemSVG, svgUrl, imageUrl }) => {
		if (svgUrl) {
			// template background is svg
			const svgBG = await createSvgDomFromUrl(svgUrl, opts.templateSize);

			let colorListObj = getColorListFromDataAttr(svgBG); // retrieve the color list from this svg (only when the svg was generated by our artwork tool)
			if (colorListObj) {
				opts.colorList = { ...opts.colorList, ...colorListObj };
			}
			let fontListArray = getFontListFromDataAttr(svgBG); // retrieve the font list from this svg (only when the svg was generated by our artwork tool)
			if (fontListArray) {
				// // find any new fonts and load them in the document
				// // Note: actually we don't need to load the new fonts because they are in background,
				// //       and is not used to calculate the item/artwork boundary
				// //				and those fonts can be loaded by itself when rendering the svg background in page
				// fontListArray.forEach((font) => {
				// 	const newFonts = [];
				// 	if (!opts.fontList.find((item) => item.name === font.name)) {
				// 		// this font was not loaded to document
				// 		newFonts.push(font);
				// 	}
				// });
				// if (newFonts.length > 0) {
				// 	await loadFontface(newFonts);
				// }
				opts.fontList = _uniqBy(opts.fontList.concat(fontListArray), 'name');
			}
			// We get the enough data, let's remove data attributes to reduce the output size
			removeColorListDataAttr(svgBG);
			removeFontListDataAttr(svgBG);
			itemSVG.append(svgBG);
		} else if (imageUrl) {
			// background is image
			const backgroundImage = document.createElementNS(NS.SVG, 'image');
			backgroundImage.setAttribute('width', '100%');
			backgroundImage.setAttribute('height', '100%');
			backgroundImage.setAttribute('x', '0');
			backgroundImage.setAttribute('y', '0');
			backgroundImage.setAttribute('href', imageUrl);

			itemSVG.append(backgroundImage);
		}
	};

	// page dimension [width, height] in pdf point
	const pageDimension = [outputTemplate.width * pixelToPdfPt, outputTemplate.height * pixelToPdfPt];
	let pdfBGUrl = null;

	///////////////////////
	// create page svg of each page
	//////////////////////
	let totalItems = fieldOutputDataArray.reduce((accu, outputData) => {
		return accu + outputData.num;
	}, 0);
	let totalPages = Math.ceil(totalItems / numOfItemsInPage);
	let itemIndex = 0,
		pageIndex = 0,
		currentPageSVG = outputTemplateSVG.cloneNode(true); // initialize page svg
	let svgPages = []; // array of svg page

	const serverSideProcess = {
		// rawSVG: DOM element, // optional. If available, will create raw svg. Raw svg is the pure svg (e.g. shadow is created by filter)
		s3Client: s3Client, // mandatary
		s3Params: {
			// mandatary
			bucket: domainConf.s3ArtworkTempStorage.bucket,
			filePrefix:
				domainConf.s3ArtworkTempStorage.basePath + new Date().toISOString().split('T')[0] + '/',
		},
	};

	// assign pdf page background for this page
	if (hasOutputTemplate && outputTemplate.mediafile_url) {
		// The output template has a pdf background. Note: outputTemplate.mediafile_url is a pdf file
		pdfBGUrl = outputTemplate.mediafile_url;
	}

	for (let i = 0; i < fieldOutputDataArray.length; i++) {
		// 1. clone the item svg and add the cloned item svg to page svg by the number in its data object
		const fieldOutputData = fieldOutputDataArray[i].data;
		const templateBGData = fieldOutputDataArray[i].templateBGData || {};

		let rootSVG = rootItemSVG.cloneNode(true);
		// 2. insert item background (we prefer item background to be svg > pdf > image),
		// in case svg item BG is available, we will insert it regardless the output template background is available or not
		await insertItemBG({
			itemSVG: rootSVG,
			svgUrl: templateBGData.mediafileSvgUrl,
			imageUrl:
				!hasOutputTemplate && templateBGData.mediafilePdfUrl // in this case, we insert pdf background at later stage, hence use null for image
					? null
					: templateBGData.mediafileHighResUrl,
		});

		// creating shadow image in prepareTextField requires root svg to be appended to document
		document.body.append(rootSVG);

		// create itemSVG by appending all field elements to rootSVG
		await fields.reduce((promiseChain, field) => {
			return promiseChain.then(async () => {
				if (hideFieldOutput(field, fields, fieldOutputData)) return null;
				switch (field.type) {
					case 'text':
						return isConcatField(field)
							? getConcatFieldType(field, fields) === 'TEXT_ONLY'
								? prepareConcatTextField({
										mode: ARTWORK_SERVER_SIDE_PROCESS,
										field,
										templateFields: fields,
										fieldOutputData,
										dataForExport: {
											rootSVG,
											isPrintable,
											fontList: opts.fontList,
											animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
										},
										serverSideProcess,
										CONSTANTS: opts.CONSTANTS,
								  })
								: prepareConcatImageField({
										mode: ARTWORK_SERVER_SIDE_PROCESS,
										field,
										templateFields: fields,
										fieldOutputData,
										dataForExport: {
											rootSVG,
											isPrintable,
											fontList: opts.fontList,
											animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
										},
										serverSideProcess: {},
										CONSTANTS: opts.CONSTANTS,
								  })
							: prepareTextField({
									mode: ARTWORK_SERVER_SIDE_PROCESS,
									inputData: fieldOutputData[field.id],
									field,
									dataForExport: {
										rootSVG,
										isPrintable,
										fontList: opts.fontList,
										animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
									},
									serverSideProcess,
									CONSTANTS: opts.CONSTANTS,
							  });

					case 'image':
						return prepareImageField({
							mode: ARTWORK_SERVER_SIDE_PROCESS,
							inputData: fieldOutputData[field.id],
							field,
							dataForExport: {
								rootSVG,
								isPrintable,
								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
							},
							serverSideProcess: {},
						});
					case 'barcode':
						return prepareBarcodeField({
							mode: ARTWORK_SERVER_SIDE_PROCESS,
							inputData: fieldOutputData[field.id],
							field,
							dataForExport: {
								rootSVG,
								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
							},
							serverSideProcess: {},
						});
					// case 'video':
					// 	return prepareVideoField({
					// 		mode: ARTWORK_SERVER_SIDE_PROCESS,
					// 		inputData: fieldOutputData[field.id],
					// 		field,
					// 		dataForExport: { rootSVG, animationDelay: calcAnimationDelay(fields, field, fieldOutputData) },
					// 		serverSideProcess: {},
					// 		CONSTANTS: opts.CONSTANTS,
					// 	});
					case 'pdf': {
						const { colorListObj, fontListArray } =
							(await preparePdfField({
								mode: ARTWORK_SERVER_SIDE_PROCESS,
								inputData: fieldOutputData[field.id],
								field,
								dataForExport: {
									rootSVG,
									animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
								},
								serverSideProcess: {},
							})) || {};
						if (colorListObj) {
							opts.colorList = { ...opts.colorList, ...colorListObj };
						}
						if (fontListArray) {
							opts.fontList = _uniqBy(opts.fontList.concat(fontListArray), 'name');
						}
						return null;
					}
					case 'grid':
						return prepareGridField({
							mode: ARTWORK_SERVER_SIDE_PROCESS,
							inputData: fieldOutputData[field.id],
							field,
							dataForExport: {
								rootSVG,
								animationDelay: calcAnimationDelay(fields, field, fieldOutputData),
							},
							serverSideProcess: {},
							CONSTANTS: opts.CONSTANTS,
						});
					default:
						return null; //Promise.resolve();
				}
			});
		}, Promise.resolve());

		// NOTE: opts.colorList & opts.fontList will NOT be used as data attribute because we do not reuse it in nested svg in future
		// at this point, item is created (as rootSVG), we will add it to page with its quantity

		// 2. clone the item svg and add the cloned item svg to page svg by the number in its data object
		for (let idx = 0; idx < fieldOutputDataArray[i].num; idx++) {
			// find|create svg page
			let outputPageIndex = floorDecimals(itemIndex / numOfItemsInPage); // 0-indexed. 1st page is 0, 2nd page is 1, etc.
			if (outputPageIndex !== pageIndex) {
				// page is full, let's add it to all svgPages and start a new page

				// to prevent potential "page not responding" dialog, let it sleep for a little while
				// NB: When tab is inactive, browser sets minimal setTimeout period to 1000ms, hence "sleep" actually takes min 1s to finish
				// so we only apply the "sleep" for each page not for each item
				if (!isFullServerProcess) await sleep(sleepTimeout);

				// page background:
				//		- if there is output template, we will use the background (it is pdf) in all pages
				//		- if no outputTemplate, each svg item is a page, the background could be customisable by user,
				// 			- if custom BG is SVG, it's been insterted by insertItemBG() at early stage
				// 			- if custom BG is PDF (in fieldOutputDataArray[i]), we will keep it with the SVG page for server-side process
				svgPages.push({
					svg: currentPageSVG.cloneNode(true),
					pagePdfBGUrl: pdfBGUrl
						? pdfBGUrl // using the background url in output template
						: !hasOutputTemplate &&
						  !templateBGData.mediafileSvgUrl &&
						  templateBGData.mediafilePdfUrl
						? templateBGData.mediafilePdfUrl // using custom BG
						: undefined, // SVG/image BG that's already inserted to itemSVG, or no BG at all
				});
				// start to process new page
				currentPageSVG = null; // free memory.
				currentPageSVG = outputTemplateSVG.cloneNode(true); // create a new page svg
				pageIndex = outputPageIndex;
			}

			// calculate (x, y) of this item in a page (which row and which item in a row of this item)
			let rowIndex = floorDecimals(itemIndex / numOfItemsInRow) % numOfRowsInPage; // zero-indexed number. 1st row as 0, 2nd row as 1, etc.
			let positionInRow = (itemIndex % numOfItemsInPage) - rowIndex * numOfItemsInRow; // zero-indexed number. 1st position in a row as 0, 2nd position in a row as 1, etc.
			let itemX = hasOutputTemplate
				? outputTemplate.margin_left +
				  positionInRow * (opts.templateSize.width + outputTemplate.gutter_ver) // first item in a row doesn't have gutter to the left
				: 0; // x position of this item (in pixel)
			let itemY = hasOutputTemplate
				? outputTemplate.margin_top +
				  rowIndex * (opts.templateSize.height + outputTemplate.gutter_hor) // first row in a page doesn't have gutter to the top
				: 0; // y position of this item (in pixel)

			let itemSVG = rootSVG.cloneNode(true);

			itemSVG.setAttribute('x', itemX);
			itemSVG.setAttribute('y', itemY);
			currentPageSVG.append(itemSVG);
			itemSVG = null;
			itemIndex += 1;
		}

		rootSVG.remove();
		rootSVG = null;
	}

	// add last page to array
	const templateBGData = fieldOutputDataArray[fieldOutputDataArray.length - 1].templateBGData || {};
	svgPages.push({
		svg: currentPageSVG.cloneNode(true),
		pagePdfBGUrl: pdfBGUrl
			? pdfBGUrl
			: !hasOutputTemplate && !templateBGData.mediafileSvgUrl && templateBGData.mediafilePdfUrl
			? templateBGData.mediafilePdfUrl
			: undefined,
	});

	pageCreationReportMsg = `Generating print PDF - ${totalPages} pages`;
	reportProgress();

	return {
		pageSize: {
			width: pageDimension[0],
			height: pageDimension[1],
		},
		fontList: opts.fontList.map((font) => ({ ...font, name: cleanupFontName(font.name) })),
		colorList: opts.colorList,
		outputFileS3Url: opts.outputFileS3Url,
		svgPages: opts.isBrowserServerPdfGen
			? svgPages
			: svgPages.map((svgPage) => ({
					svg: new XMLSerializer().serializeToString(svgPage.svg),
					pagePdfBGUrl: svgPage.pagePdfBGUrl,
			  })),
	};
};

/**
 * this is function to be used in server-side artwork generation
 * It must be paired with "multipleArtworkPdfGenerationInBrowser" function in `utils/artwork-utils.js` in filemanager api
 * @param {*} fields
 * @param {*} fieldOutputDataArray
 * @param {*} opts
 * @returns
 */
export const multipleArtworkPagesPdfGenerationInBrowserServer = async (
	fields,
	fieldOutputDataArray,
	opts = {}
) => {
	// calling this one means using server-side browser to generate both svgPages and artwork pdf
	// so set "isBrowserServerPdfGen" to true
	opts.isBrowserServerPdfGen = true;

	// generate artwork pages in svg format (svg pages)
	// format of artworkData: {pageSize,fontList,colorList,outputFileS3Url,svgPages,}
	const artworkData = await generateMultipleArtworkToPdfPagesWithServer(
		fields,
		fieldOutputDataArray,
		opts
	);

	// generate pdf from the svg pages
	const pdfBuffer = await createPdfFromArtworkDataInBrowser(artworkData);
	// save pdf to s3
	const pdfWebUrl = await saveArtworkPdf(pdfBuffer, opts);

	return { pdfWebUrl, pdfSize: pdfBuffer.byteLength };

	// // below code is used if we use filemanager api to save pdf to s3 (server-side)
	// const artworkPdfBuffer = await createPdfFromArtworkDataInBrowser(artworkData);
	// const pdfBlob = new Blob([artworkPdfBuffer], { type: 'application/pdf' });
	// const DOMURL = window.URL || window.webkitURL || window;
	// let pdfBlobUrl = DOMURL.createObjectURL(pdfBlob);
	// return pdfBlobUrl;
	// // return await new Promise((resolve, reject) => {
	// // 	const reader = new FileReader();
	// // 	reader.onload = () => resolve(reader.result);
	// // 	reader.onerror = reject;
	// // 	reader.readAsBinaryString(pdfBlob); // use binaryString to send back to filemanager api from playwright browser-server
	// // });
};

// upload pdf to s3
async function saveArtworkPdf(pdfBuffer, opts) {
	let s3Client = opts.fullServerProcess
		? createS3Client({
				accesskey: opts.awsS3.accesskey, // required
				secretkey: opts.awsS3.secretkey, // required
				domain: opts.domain, // optional
				useAccelerateEndpoint: false, // server side s3Client does not use acceleration, so that it can use endpoint within VPC
		  })
		: opts.s3Client;
	let domainConf = getDomainConfig(opts.domain);

	const s3Params = opts.outputFileS3Url
		? convertS3UrlToS3Params(opts.outputFileS3Url)
		: {
				Bucket: domainConf.s3ArtworkTempStorage.bucket,
				Key: `${domainConf.s3ArtworkTempStorage.basePath}${
					new Date().toISOString().split('T')[0]
				}/${genRandomStr(12)}.pdf`,
		  };
	let s3FileParams = {
		// Bucket: bucket,
		// Key: fileKey,
		...s3Params,
		Body: pdfBuffer,
		ContentType: 'application/pdf',
	};
	await s3Client.upload(s3FileParams).promise();
	const pdfWebUrl = getCloudFrontUrlOfS3File(s3Params);
	return pdfWebUrl;
}

async function createPdfFromArtworkDataInBrowser(artworkData) {
	// convert svgPages to pdf file by svg-to-pdf and pdfkit lib
	const pdfKitBlob = await createPdfFromSVGPages(artworkData);

	// get the background - every page has same background
	const backgroundUrl = artworkData.svgPages[0].pagePdfBGUrl;

	// using pdf-lib to load the pdf may reduce the pdf file size
	const pdflibDoc = await PDFLIBDocument.load(await pdfKitBlob.arrayBuffer());
	pdflibDoc.setProducer('Visual ID ToolKit (http://www.visualid.com)');
	pdflibDoc.setCreator('Visual ID ToolKit (http://www.visualid.com)');

	if (backgroundUrl) {
		// there is background, we need to load the background in every page
		const BGBuffer = await loadUrlToArrayBuffer(backgroundUrl);
		let pageBGDoc = await PDFLIBDocument.load(BGBuffer, {
			ignoreEncryption: true,
			throwOnInvalidObject: false,
		});
		const [backgroundPage] = await pdflibDoc.copyPages(pageBGDoc, [0]);

		for (let i = 0; i < pdflibDoc.getPageCount(); i++) {
			// new page must be created from background, otherwise the background will be in foreground
			let newCurrentPage = copyPage(backgroundPage);
			const originalPage = pdflibDoc.getPage(i);
			let embeddedOriginalPage = await pdflibDoc.embedPage(originalPage);
			newCurrentPage.drawPage(embeddedOriginalPage, {
				x: 0,
				y: 0,
				xScale: 1,
				yScale: 1,
				opacity: 1,
			});
			// Add the modified page back to the document
			pdflibDoc.removePage(i);
			pdflibDoc.insertPage(i, newCurrentPage);
		}
	}

	let pdflibBuffer = await pdflibDoc.save();
	// let pdfBlob = new Blob([pdflibBuffer], { type: 'application/pdf' });
	return pdflibBuffer;
}

const createPdfFromSVGPages = async (artworkData) => {
	const { pageSize, fontList, colorList, svgPages } = artworkData;
	const pageDimension = [pageSize.width, pageSize.height];
	const fontBuffers = await Promise.all(fontList.map((font) => loadFontToArrayBuffer(font)));
	const pdfkitDoc = new PDFKIT({
		margins: { top: 0, left: 0, right: 0, bottom: 0 }, // margin is always be { top: 0, left: 0, right: 0, bottom: 0 }, because the margin is handled by output template
		size: pageDimension,
		layout: 'portrait', // it must be 'portrait' because we manually specify the size
		autoFirstPage: false,
	});
	fontBuffers.forEach((fontBuffer) => {
		pdfkitDoc.registerFont(`"${fontBuffer.name}"`, fontBuffer.arrayBuffer);
	});

	return await new Promise((res, rej) => {
		const stream = pdfkitDoc.pipe(blobStream());

		stream.on('finish', function () {
			res(stream.toBlob('application/pdf'));
		});
		stream.on('error', (error) => {
			rej(error);
		});

		addSvgPagesToPdf(colorList, svgPages, pdfkitDoc)
			.then(() => {
				pdfkitDoc.end();
			})
			.catch((error) => {
				rej(error);
			});
	});
};

const addSvgPagesToPdf = async (colorList, svgPages, pdfkitDoc) => {
	for (let svgPage of svgPages) {
		const { svg } = svgPage;
		const imageBuffers = await getImageBuffersInSVGPageInBrowser(svg);
		const pdfKitPage = pdfkitDoc.addPage();

		SVGtoPDF(pdfKitPage, svg, 0, 0, {
			colorCallback: (parsed) => {
				let [[r, g, b], a] = parsed;
				if (colorList[`${r}-${g}-${b}`]) return [colorList[`${r}-${g}-${b}`], a];
				return parsed;
			},
			imageCallback: (link) => {
				return imageBuffers.find((item) => item.link === link)?.buffer || link;
			},
		});
	}
};

/**
 * retrieve images from svg and get image buffers
 * @param {string} svg svg string
 * @returns array of image buffer
 */
async function getImageBuffersInSVGPageInBrowser(svg) {
	// get image buffers
	let innerImages = svg.getElementsByTagNameNS(NS.SVG, 'image');
	const imageBuffers = await Promise.all(
		Array.from(innerImages).map(async (innerImage) => {
			let imageHref = innerImage.getAttribute('href');
			// check if it is http(s) url with regex: /^\s*https?:\/\/.*?$/gim
			if (new RegExp(/^\s*https?:\/\/.*?$/gim).test(imageHref)) {
				// it is http(s) url. we need to retrieve image to arraybuffer
				const buffer = await retrieveBase64DataUrl(imageHref).catch(() => undefined);
				return {
					link: imageHref,
					buffer,
				};
			}
			return { link: imageHref, buffer: undefined };
		})
	);
	return imageBuffers;
}

/**
 * Copies a PDFPage object to avoid duplicate page in final pdf
 * ref:
 * 	- https://github.com/Hopding/pdf-lib/issues/157
 * 	- https://github.com/Hopding/pdf-lib/issues/169
 * 	- https://github.com/Hopding/pdf-lib/issues/47
 * 	- https://github.com/Hopding/pdf-lib/issues/9 (there is good comparison between Acrobat Pro & pdf-lib)
 * @param {object} originalPage page in pdf-lib
 * @returns
 */
const copyPage = (originalPage) => {
	const cloneNode = originalPage.node.clone();

	const { Contents } = originalPage.node.normalizedEntries();
	if (Contents) cloneNode.set(PDFName.of('Contents'), Contents.clone());

	const cloneRef = originalPage.doc.context.register(cloneNode);
	const clonePage = PDFPage.of(cloneNode, cloneRef, originalPage.doc);
	return clonePage;
};
