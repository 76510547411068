import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';

import { Explore as ExploreIcon } from '@mui/icons-material';

import { ROUTES_PATH_MEDIAFILE_LIST, ROUTES_PATH_MEDIAFILE_DETAILS } from 'routes';

const MediafileList = loadable(() => import('./components/MediafileList/MediafileList'), {
	fallback: <Loading size={50} />,
});
const MediafileDetails = loadable(() => import('./components/MediafileDetails/MediafileDetails'), {
	fallback: <Loading size={50} />,
});

const MediafilesRoutes = [
	{
		path: ROUTES_PATH_MEDIAFILE_LIST,
		sidebarName: <FormattedMessage id="pages.FileExplore.Routes.FileExplore" />,
		sidebarIcon: ExploreIcon,
		component: MediafileList,
		noBreadcrumbs: false,
	},
	{
		path: ROUTES_PATH_MEDIAFILE_DETAILS,
		component: MediafileDetails,
		noBreadcrumbs: false,
	},
];
export default MediafilesRoutes;
