import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { ART_VARIABLES } from '../../Constants';
import { isConcatField } from 'utils/artwork/artUtilsCommon';
import { getSupportedVideoTypesOnVideoEditing } from 'utils/artwork/artUtilsWebUI';

// custom components
import { RnDDialog, MediafileMultiChoosers } from 'components';

import { getRouteMediafileDetail } from 'routes';

// MUI components
import {
	/* Fab, CircularProgress, Backdrop, Typography,  */ IconButton,
	Popover,
	InputLabel,
	Link,
	Typography,
} from '@mui/material';
import {
	// Cloud as CloudSaveIcon,
	// CloudDone as CloudSaveDoneIcon,
	// Close as CloseIcon,
	Settings as SettingIcon,
	// ImageSearch as ImageSearchIcon,
	// Clear as ClearIcon,
} from '@mui/icons-material';
// import { CloudAlert as CloudSaveFailedIcon } from 'mdi-material-ui';
import { blueGrey, grey } from '@mui/material/colors';
import {
	DividerVer,
	DividerHor,
	SimpleSwitchVer,
	// OutlinedNumberTextField,
	StyledOutlinedTextFieldSelection,
	StyledOutlinedTextField,
	SimpleSwitchRow,
	StyledContainedButton,
} from '../CustomMUI/CustomMUI';

// intl lang
import { useIntl } from 'react-intl';

const wrapperBGColor = blueGrey[600];
const wrapperColor = grey[200];
const useStyles = makeStyles((theme) => ({
	designSettingWrapper: {
		position: 'absolute',
		// top: '50%',
		// transform: 'translateY(-50%)',
		bottom: ART_VARIABLES.cssStyles.actionRibbonHeight + 10,
		right: 10,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		color: wrapperColor,
		backgroundColor: wrapperBGColor,
		borderRadius: ART_VARIABLES.cssStyles.settingButtonWidth / 2,
		'&:hover': {
			backgroundColor: blueGrey[700],
		},
	},
	settingButton: {
		// position: 'absolute',
		// right: '40%',
		// top: '30%',
		// borderRadius: 'unset',
		width: ART_VARIABLES.cssStyles.settingButtonWidth,
		height: ART_VARIABLES.cssStyles.settingButtonHeight,
		padding: 0,
	},
	// popover: {
	// 	marginRight: 18,
	// },
	paper: {
		padding: theme.spacing(1),
		width: 300,
		maxHeight: '70vh',
		color: wrapperColor,
		backgroundColor: wrapperBGColor,
		// fontSize: '0.825rem',
		// display: 'flex',
		// flexDirection: 'column',
		// marginRight: 18,
		// transform: 'translateX(8px)',
	},
	titleText: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(16),
		padding: '8px 0px 16px 0px',
	},
	contentWrapper: {
		// margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: '100%', //`calc(100% - calc(${theme.spacing(2)} * 2))`,
		height: '100%', // `calc(100% - ${titleHeight}px)`,
		color: 'inherit',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		fontSize: '0.8rem',
	},
	groupRowDiv: {
		width: '100%',
		// padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	infoName: {
		width: '25%',
		textAlign: 'right',
	},
	infoValue: {
		width: '50%',
		// textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	groupsDiv: {
		margin: theme.spacing(1), //`${theme.spacing(1.5)} ${theme.spacing(2)}`,
		// padding: theme.spacing(1),
		width: `calc(100% - calc(${theme.spacing(1)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupsDivTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		// background: 'white',
		// backgroundColor: 'inherit',
		backgroundColor: wrapperBGColor,
		color: 'inherit',
		marginBottom: -theme.spacing(1),
	},
	inputLabelRoot: {
		color: 'inherit !important',
		fontSize: 'inherit',
		marginLeft: -5,
		// '&:focused': {
		// 	color: 'inherit',
		// },
	},
	BGPreview: {
		width: '100%',
		height: 120,
		padding: '8px 20%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	/**
	 * ----------------------------------------
	 * animation rotate
	 * ----------------------------------------
	 */
	'@keyframes rotate': theme.animations['@keyframes rotateCenter'],
	rotate: {
		animation: '$rotate 4s linear infinite',
	},
}));

function DesignSetting({
	designerTemplate,
	artworkExtraData,
	handleTemplateInfoUpdate,
	setDesignSettingOpen,
	displayOutputTemplate,
	open,
	userData,
	// workspaceContainerRef,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	// const [anchorEl, setAnchorEl] = React.useState(null);
	const anchorRef = React.useRef(null);
	const [openMFChooser, setOpenMFChooser] = React.useState(false);
	const templateBG = React.useMemo(() => {
		return designerTemplate.templateBG;
	}, [designerTemplate.templateBG]);

	const templateBGChoiceArray = React.useMemo(() => {
		return designerTemplate.templateBG.mediafileOrigin === 'category'
			? designerTemplate.templateBG.mediafileOriginChoice.length === 0
				? [{ categories: artworkExtraData.categories }]
				: [{ categories: artworkExtraData.categories }].concat(
						designerTemplate.templateBG.mediafileOriginChoice
				  )
			: [];
	}, [
		artworkExtraData.categories,
		designerTemplate.templateBG.mediafileOrigin,
		designerTemplate.templateBG.mediafileOriginChoice,
	]);

	/**
	 * Get mediafile chooser filters for template background
	 * NB: when designer chooses default mediafile for a field, we ONLY use filters when mediafile origin is from 'category' or 'admin_lightbox'
	 * @param {object} templateBG. The template background object.
	 *
	 * @return {object} filters. Filters that are defined in POST /mediafiles endpoint, except "keywords"
	 */
	const getMediafileChooserFiltersForFilemanager = (templateBG) => {
		let filters = {};
		if (designerTemplate.videoArtwork) {
			filters.fileTypeGroup = ['video'];
		} else {
			filters.fileExt = ['PDF', 'SVG'];
		}

		filters.fileWidth = designerTemplate.dimension.width;
		filters.fileHeight = designerTemplate.dimension.height;
		let originChoiceId =
			templateBG.mediafileOriginChoice.length > 0
				? templateBG.mediafileOriginChoice[
						templateBG.mediafileOriginChoice.length - 1
				  ].id.toString() || null
				: null;
		if (templateBG.mediafileOrigin === 'category' && originChoiceId) {
			filters.categories = [originChoiceId];
		} else if (templateBG.mediafileOrigin === 'admin_lightbox' && originChoiceId) {
			filters.lightboxes = [originChoiceId];
		}

		return filters;
	};

	const shouldEnableOutputTemplate = React.useMemo(() => {
		return designerTemplate.fields.some(
			(f) => f.type === 'text' && f.predefinedValue.type === 'spreadsheet' && f.predefinedValue.from
		);
	}, [designerTemplate.fields]);

	const handleGotoDetail = React.useCallback((mediafileId) => {
		window.location.assign(getRouteMediafileDetail(mediafileId));
	}, []);

	// reset output Template if no product picker field
	React.useEffect(() => {
		if (!shouldEnableOutputTemplate && designerTemplate.outputTemplate !== null) {
			handleTemplateInfoUpdate({
				outputTemplate: null,
				alternativeTemplateId: '',
			});
		}
	}, [designerTemplate.outputTemplate, handleTemplateInfoUpdate, shouldEnableOutputTemplate]);

	// const handlePopoverOpen = e => {
	// 	setAnchorEl(e.currentTarget);
	// };

	// const handlePopoverClose = () => {
	// 	setAnchorEl(null);
	// };
	// const open = Boolean(anchorEl);
	return (
		<div className={classes.designSettingWrapper} ref={anchorRef} {...rest}>
			<IconButton
				className={classes.settingButton}
				color="inherit"
				onClick={
					() => setDesignSettingOpen(!open) /* open ? handlePopoverClose : handlePopoverOpen */
				}
				aria-label="setting"
			>
				<SettingIcon fontSize="large" color="inherit" className={classes.rotate} />
			</IconButton>
			<Popover
				// className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorRef.current || null}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				// container={workspaceContainerRef.current}
				onClose={() => setDesignSettingOpen(false)}
				disableRestoreFocus
			>
				<div className={classes.titleText}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.DesignSetting.titleText',
					})}
				</div>
				<DividerHor />
				<div className={classes.contentWrapper}>
					<div className={classes.groupRowDiv}>
						{[
							// {
							// 	label: intl.formatMessage({
							// 		id: 'pages.Artwork.components.DesignSetting.htmlArtworkSwitchLabel',
							// 	}),
							// 	checked: designerTemplate.htmlArtwork,
							// 	handleChange: (checked) =>
							// 		handleTemplateInfoUpdate({
							// 			htmlArtwork: checked,
							// 			...(checked ? { videoArtwork: false } : {}),
							// 		}),
							// },
							{
								label: intl.formatMessage({
									id: 'pages.Artwork.components.DesignSetting.autoCreateArtworkSwitchLabel',
								}),
								checked: designerTemplate.autoCreateArtwork,
								handleChange: (checked) =>
									handleTemplateInfoUpdate({
										autoCreateArtwork: checked,
									}),
							},
							{
								label: intl.formatMessage({
									id: 'pages.Artwork.components.DesignSetting.videoArtworkSwitchLabel',
								}),
								checked: designerTemplate.videoArtwork,
								handleChange: (checked) =>
									handleTemplateInfoUpdate({
										videoArtwork: checked,
										...(checked
											? {
													htmlArtwork: false,
													// we also reset templateBG when switching to video artwork
													templateBG: {
														mediafileOrigin: templateBG.mediafileOrigin,
														mediafileOriginChoice: templateBG.mediafileOriginChoice,
														includeUserFiles: templateBG.includeUserFiles,
														isCustomisable: false,
													},
											  }
											: { videoPreviewFieldId: '' }),
									}),
							},
							{
								label: intl.formatMessage({
									id: 'pages.Artwork.components.DesignSetting.esignTemplateSwitchLabel',
								}),
								checked: designerTemplate.isEsignTemplate,
								handleChange: (checked) => handleTemplateInfoUpdate({ isEsignTemplate: checked }),
							},
							{
								label: intl.formatMessage({
									id: 'pages.Artwork.components.DesignSetting.esignOnlySwitchLabel',
								}),
								checked: designerTemplate.isEsignOnly,
								handleChange: (checked) => handleTemplateInfoUpdate({ isEsignOnly: checked }),
							},
						]
							.map((item) => (
								<SimpleSwitchVer
									key={item.label}
									SwitchProps={{
										color: 'secondary',
										size: 'small',
									}}
									label={item.label}
									style={{ padding: 0 }}
									checked={item.checked}
									onChange={(e, checked) => item.handleChange(checked)}
								/>
							))
							.reduce((accu, elem, idx) => {
								return accu.length === 0 ? [elem] : [...accu, <DividerVer key={idx} />, elem];
							}, [])}
					</div>
					{
						/** TODO: we hide animation duration for the moment (on 25/03/2021) as it is not used at all. We will show it when we know how to use it */
						// <DividerHor />
						// <div className={classes.groupRowDiv}>
						// 	<span className={classes.infoName}>
						// 		{intl.formatMessage({
						// 			id: 'pages.Artwork.components.DesignSetting.animationDurationText',
						// 		})}
						// 	</span>
						// 		<DividerVer />
						// 	<span className={classes.infoValue}>
						// 		{designerTemplate.fields.filter(f => f.animation.entrance).length > 0 ? (
						// 			<div
						// 				style={{
						// 					display: 'flex',
						// 					alignItems: 'center',
						// 					justifyContent: 'center',
						// 					width: '100%',
						// 				}}
						// 			>
						// 				<OutlinedNumberTextField
						// 					// className={classes.denseTextbox}
						// 					style={{ margin: 0, marginRight: 8 }}
						// 					label=""
						// 					value={designerTemplate.templateDuration}
						// 					step={1}
						// 					min={0}
						// 					max={30}
						// 					handleOnChange={num => handleTemplateInfoUpdate({ templateDuration: num })}
						// 				/>
						// 				{intl.formatMessage({
						// 					id: 'pages.Artwork.components.GENERAL.secondShort',
						// 				})}
						// 			</div>
						// 		) : (
						// 			<span>{intl.formatMessage({ id: 'GENERAL.N/A' })}</span>
						// 		)}
						// 	</span>
						// </div>
					}
					{/** general settings */}
					<div className={classes.groupsDiv}>
						<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.DesignSetting.GeneralSettingTitle',
							})}
						</InputLabel>
						<StyledOutlinedTextFieldSelection
							fullWidth
							select
							label={intl.formatMessage({
								id: 'pages.Artwork.components.DesignSetting.FilenameFieldLabel',
							})}
							InputLabelProps={{
								classes: { root: classes.inputLabelRoot },
							}}
							value={designerTemplate.filenameFieldId || ''}
							onChange={(e) =>
								handleTemplateInfoUpdate({
									filenameFieldId: e.target.value || '',
									...(e.target.value ? {} : { filenameFieldAppend: false }), // reset filenameFieldAppend if no filename field selected
								})
							}
							noneOption
							options={designerTemplate.fields.map((field) => {
								return {
									value: field.id,
									label: field.name,
									disabled: field.type !== 'text' || isConcatField(field),
								};
							})}
						/>

						<DividerHor />
						<SimpleSwitchRow
							label={intl.formatMessage({
								id: 'pages.Artwork.components.DesignSetting.AppendFilenameFieldLabel',
							})}
							SwitchProps={{
								color: 'secondary',
								size: 'small',
								disabled: !designerTemplate.filenameFieldId,
							}}
							style={{ padding: '8px 24px' }}
							checked={designerTemplate.filenameFieldAppend}
							onChange={(e, checked) =>
								handleTemplateInfoUpdate({
									filenameFieldAppend: checked,
								})
							}
						/>

						{designerTemplate.videoArtwork && (
							<React.Fragment>
								<DividerHor />
								<StyledOutlinedTextFieldSelection
									fullWidth
									select
									label={intl.formatMessage({
										id: 'pages.Artwork.components.DesignSetting.VideoPreviewFieldLabel',
									})}
									InputLabelProps={{
										classes: { root: classes.inputLabelRoot },
									}}
									value={designerTemplate.videoPreviewFieldId || ''}
									onChange={(e) =>
										handleTemplateInfoUpdate({ videoPreviewFieldId: e.target.value || '' })
									}
									noneOption
									options={designerTemplate.fields.map((field) => {
										return {
											value: field.id,
											label: field.name,
											disabled: !ART_VARIABLES.supportedFieldTypesInVideoArtwork.includes(
												field.type
											),
										};
									})}
								/>
							</React.Fragment>
						)}
					</div>
					{/** output template setting */}
					<div className={classes.groupsDiv}>
						<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.DesignSetting.OutputTemplateSettingTitle',
							})}
						</InputLabel>
						{!shouldEnableOutputTemplate && (
							<Typography
								variant="caption"
								display="block"
								align="left"
								noWrap
								color="warning.main"
								sx={{ fontStyle: 'italic' }}
							>
								{intl.formatMessage({
									id: 'pages.Artwork.components.DesignSetting.OutputTemplateSettingDisableMsg',
								})}
							</Typography>
						)}
						<div className={classes.groupRowDiv} style={{ justifyContent: 'space-evenly' }}>
							<StyledOutlinedTextFieldSelection
								fullWidth
								select
								disabled={!shouldEnableOutputTemplate}
								label={intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.TextPalette.outputTemplateText',
								})}
								InputLabelProps={{
									classes: { root: classes.inputLabelRoot },
								}}
								value={designerTemplate.outputTemplate || ''}
								onChange={(e) =>
									handleTemplateInfoUpdate({
										outputTemplate: e.target.value || null,
									})
								}
								style={{ width: '100%', marginLeft: 4, marginRight: 4 }}
								noneOption
								options={artworkExtraData.outputTemplates.map((item) => ({
									value: item.id,
									label: item.title,
								}))}
							/>
							<DividerVer />
							<Link
								component="button"
								variant="body2"
								onClick={() => {
									displayOutputTemplate(designerTemplate.outputTemplate);
								}}
								style={{ fontSize: 'inherit', color: 'inherit' }}
							>
								{intl.formatMessage({
									id: 'pages.Artwork.components.ElementPalette.TextPalette.viewOutputTemplateText',
								})}
							</Link>
						</div>
						<DividerHor />
						<StyledOutlinedTextField
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.TextPalette.altTemplateIDText',
							})}
							disabled={!shouldEnableOutputTemplate}
							InputLabelProps={{
								classes: { root: classes.inputLabelRoot },
							}}
							value={designerTemplate.alternativeTemplateId || ''}
							onChange={(e) =>
								handleTemplateInfoUpdate({
									alternativeTemplateId: e.target.value,
								})
							}
						/>
					</div>
					{/** background selector */}
					<div
						className={classes.groupsDiv}
						// style={field.pdfOrigin ? {} : { margin: 0, width: '100%', border: 'none' }}
					>
						<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.DesignSetting.TemplateBGTitle',
							})}
						</InputLabel>
						<StyledOutlinedTextFieldSelection
							fullWidth
							select
							label={intl.formatMessage({
								id: 'pages.Artwork.components.DesignSetting.TemplateBGOringinLabel',
							})}
							InputLabelProps={{
								classes: { root: classes.inputLabelRoot },
							}}
							value={templateBG.mediafileOrigin || ''}
							onChange={
								(e) =>
									handleTemplateInfoUpdate({
										templateBG: {
											...templateBG,
											mediafileOrigin: e.target.value || '',
											mediafileOriginChoice: [],
											includeUserFiles: false,
										},
									})
								// updateField({
								// 	pdfOrigin: e.target.value || '',
								// 	// reset the options
								// 	pdfOriginChoice: [],
								// 	pdfOriginIncludeUserFiles: false,
								// })
							}
							noneOption
							options={ART_VARIABLES.imageOriginOptions}
						/>
						{templateBG.mediafileOrigin === 'category' && (
							<div className={classes.groupsDiv}>
								<InputLabel shrink variant="outlined" className={classes.groupsDivTitle}>
									{intl.formatMessage({
										id: 'pages.Artwork.components.DesignSetting.TemplateBGChoiceText',
									})}
								</InputLabel>
								{templateBGChoiceArray.map((item, idx) => {
									if (
										idx !== 0 &&
										(!Array.isArray(item.categories) || item.categories.length === 0)
									)
										return null;
									return (
										<StyledOutlinedTextFieldSelection
											key={idx}
											fullWidth
											select
											label=""
											value={
												templateBGChoiceArray[idx + 1] ? templateBGChoiceArray[idx + 1].id : ''
											}
											onChange={(e) => {
												// let newPdfChoice = field.pdfOriginChoice.slice(0, idx);
												// if (e.target.value)
												// 	newPdfChoice[idx] = _.find(
												// 		item.categories,
												// 		(c) => c.id === e.target.value
												// 	);
												// updateField({
												// 	pdfOriginChoice: newPdfChoice,
												// });

												let newBGChoice = templateBG.mediafileOriginChoice.slice(0, idx);
												if (e.target.value)
													newBGChoice[idx] = item.categories.find((c) => c.id === e.target.value);
												handleTemplateInfoUpdate({
													templateBG: {
														...templateBG,
														mediafileOriginChoice: newBGChoice,
													},
												});
											}}
											smallMenu
											smallIcon
											noneMsg={
												artworkExtraData.categories.length === 0
													? intl.formatMessage({
															id:
																'pages.Artwork.components.ElementPalette.GENERAL.noCategoryAvailableText',
													  })
													: null
											}
											noneOption
											options={(item.categories || []).map((c) => ({
												label: c.name,
												value: c.id,
											}))}
										/>
									);
								})}
							</div>
						)}

						{templateBG.mediafileOrigin === 'admin_lightbox' && (
							<StyledOutlinedTextFieldSelection
								fullWidth
								select
								label={intl.formatMessage({
									id: 'pages.Artwork.components.DesignSetting.TemplateBGChoiceText',
								})}
								InputLabelProps={{
									classes: { root: classes.inputLabelRoot },
								}}
								value={
									templateBG.mediafileOriginChoice[0] ? templateBG.mediafileOriginChoice[0].id : ''
								}
								onChange={(e) => {
									// updateField({
									// 	pdfOriginChoice: e.target.value ? [{ id: e.target.value }] : [],
									// });
									handleTemplateInfoUpdate({
										templateBG: {
											...templateBG,
											mediafileOriginChoice: e.target.value ? [{ id: e.target.value }] : [],
										},
									});
								}}
								smallMenu
								smallIcon
								noneMsg={artworkExtraData.lightboxes.length === 0 ? 'No lightbox available' : null}
								noneOption
								options={(artworkExtraData.lightboxes || []).map((lightbox) => ({
									label: lightbox.name,
									value: lightbox.id,
								}))}
							/>
						)}
						{templateBG.mediafileOrigin && (
							<React.Fragment>
								<DividerHor />
								<SimpleSwitchRow
									label={intl.formatMessage({
										id: 'pages.Artwork.components.ElementPalette.GENERAL.includeUserFilesText',
									})}
									// color="secondary"
									SwitchProps={{
										color: 'secondary',
										size: 'small',
									}}
									style={{ padding: '8px 24px' }}
									checked={templateBG.includeUserFiles}
									onChange={
										(e, checked) =>
											handleTemplateInfoUpdate({
												templateBG: {
													...templateBG,
													includeUserFiles: checked,
												},
											}) /* updateField({ pdfOriginIncludeUserFiles: checked }) */
									}
								/>
							</React.Fragment>
						)}
						<DividerHor />
						{templateBG.mediafilePreviewUrl && (
							<div className={classes.BGPreview}>
								<img
									src={templateBG.mediafilePreviewUrl}
									alt="preview"
									style={{ maxHeight: '100%', maxWidth: '100%' }}
								/>
							</div>
						)}
						<div className={cx(classes.groupRowDiv, classes.threeColumnsRowDiv)}>
							<StyledContainedButton
								label="Pick"
								size="small"
								onClick={() => setOpenMFChooser(true)}
							/>
							<StyledContainedButton
								label="Clear"
								size="small"
								onClick={() =>
									handleTemplateInfoUpdate({
										templateBG: {
											...templateBG,
											mediafileId: null,
											mediafilePreviewUrl: null,
											mediafileHighResUrl: null,
											mediafileOptimisedUrl: null,
											mediafilePdfUrl: null,
											mediafileSvgUrl: null,
										},
									})
								}
							/>
							{templateBG?.mediafileId && (
								<StyledContainedButton
									label="Show"
									size="small"
									onClick={() => handleGotoDetail(templateBG.mediafileId)}
								/>
							)}
						</div>
						<DividerHor />
						<SimpleSwitchRow
							label={intl.formatMessage({
								id: 'pages.Artwork.components.DesignSetting.TemplateBGCustomisableText',
							})}
							SwitchProps={{
								color: 'secondary',
								size: 'small',
								disabled: designerTemplate.videoArtwork,
							}}
							// color="secondary"
							style={{ padding: '8px 24px' }}
							checked={templateBG.isCustomisable}
							onChange={(e, checked) =>
								handleTemplateInfoUpdate({
									templateBG: {
										...templateBG,
										isCustomisable: checked,
									},
								})
							}
						/>
					</div>
					{/** mediafile chooser */}
					<RnDDialog
						open={openMFChooser}
						size="md"
						title="Select Media File"
						resizable={true}
						onClose={() => {
							setOpenMFChooser(false);
						}}
					>
						<MediafileMultiChoosers
							/** the default template background defined by designer is always from following providers */
							enabledProviders={['TOOLKIT_FILELIBRARY', 'TOOLKIT_MYFILES', 'TOOLKIT_FILEUPLOADER']}
							filtersToolkitFileLibrary={getMediafileChooserFiltersForFilemanager(templateBG)}
							filtersToolkitMyFiles={
								// designer selects mediafile from own files
								{
									createdByUIDs: [userData.uid],
									...(designerTemplate.videoArtwork
										? { fileTypeGroup: ['video'] }
										: { fileExt: ['PDF', 'SVG'] }),
									fileWidth: designerTemplate.dimension.width,
									fileHeight: designerTemplate.dimension.height,
								}
							}
							S3UploaderProps={{
								accept: designerTemplate.videoArtwork
									? getSupportedVideoTypesOnVideoEditing()
									: '.pdf, .svg',
							}} // use default accept file types if null
							onMediafileSelect={(selectedMediafile) => {
								handleTemplateInfoUpdate({
									templateBG: {
										...templateBG,
										mediafileId: selectedMediafile.id,
										mediafilePreviewUrl: selectedMediafile.previewUrl,
										mediafileHighResUrl: selectedMediafile.highResUrl,
										mediafileOptimisedUrl: selectedMediafile.optimisedUrl,
										mediafilePdfUrl:
											selectedMediafile.fileType.toUpperCase() === 'PDF'
												? selectedMediafile.originalUrl
												: null,
										mediafileSvgUrl: selectedMediafile.svgUrl,
									},
								});
								setOpenMFChooser(false);
							}}
						/>
					</RnDDialog>
				</div>
			</Popover>
		</div>
	);
}

DesignSetting.propTypes = {
	designerTemplate: PropTypes.object.isRequired,
	artworkExtraData: PropTypes.object.isRequired,
	handleTemplateInfoUpdate: PropTypes.func.isRequired,
	setDesignSettingOpen: PropTypes.func.isRequired,
	displayOutputTemplate: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	userData: PropTypes.object.isRequired,
	// workspaceContainerRef: PropTypes.object.isRequired,
};

DesignSetting.defaultProps = {};
export default DesignSetting;
