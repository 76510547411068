import React from 'react';

import PropTypes from 'prop-types';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';

// custom components
// import {
// 	// FileUploader,
// } from 'components';

import { Close as CloseIcon } from '@mui/icons-material';

// CSS
import notifierStyle from './NotifierStyle.jsx';

// redux
import { connect } from 'react-redux';
import { removeSnackbar /* , resetNotifyDetail */ } from 'redux/actions'; // actions

const MAX_HISTORY_NOTIRY = 10;
class Notifier extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			historyNotifications: [],
		};
	}

	// componentWillReceiveProps(nextProps){

	// }

	storeDisplayed = (key) => {
		this.setState(({ historyNotifications }) => ({
			historyNotifications: [
				...historyNotifications.slice(
					Math.max(historyNotifications.length - MAX_HISTORY_NOTIRY - 1, 0)
				),
				key,
			],
		}));
	};

	render() {
		const {
			classes,
			notifier,
			removeSnackbar,
			// resetNotifyDetail,
		} = this.props;

		// const { historyNotifications } = this.state;

		notifier.notifications.forEach((notification) => {
			setTimeout(() => {
				// If notification already displayed, abort
				if (this.state.historyNotifications.includes(notification.key)) {
					return null;
				}
				// Display notification using notistack
				if (notification.options.variant === 'white') {
					// 	enqueueSnackbar(notification.message, notification.options);
					// }else{
					notification.options.variant = 'default';
					// NB, break change in notistack@1.0.0, we need to use ariaAttributes to replace ContentProps. ref: https://github.com/iamhosseindhv/notistack/blob/master/CHANGELOG.md
					// notification.options.ContentProps = { classes: { root: classes.whiteBGColor } };
					notification.options.ariaAttributes = { classes: { root: classes.whiteBGColor } };
					// enqueueSnackbar(notification.message, notification.options);
				}

				if (!notification.options.action) {
					notification.options.action = (key) => (
						<IconButton
							size="small"
							key="close"
							aria-label="Close"
							color="inherit"
							onClick={() => {
								closeSnackbar(key);
							}}
						>
							<CloseIcon className={classes.icon} />
						</IconButton>
					);
				}

				enqueueSnackbar(notification.message, notification.options);
				// Add notification's key to the local state
				this.storeDisplayed(notification.key);
				// Dispatch action to remove the notification from the redux store
				removeSnackbar(notification.key);
			}, 1);
		});

		// var {type, ...detailProps} = notifier.notificationDetail;
		// var notifyDetailComp, notifyTitle;
		// // console.log(`notify type - ${type}, NOTIFY_DETAIL_TYPE_FILEUPLOADER: ${NOTIFY_DETAIL_TYPE_FILEUPLOADER}`);
		// switch(type){
		// 	// case NOTIFY_DETAIL_TYPE_FILEUPLOADER:
		// 	// 	notifyDetailComp=<FileUploader {...detailProps} />;
		// 	// 	notifyTitle = 'File Uploads';
		// 	// 	break;
		// 	default:
		// 		break;
		// }

		// return notifyDetailComp?(<Dialog
		// 	open={true}
		// 	onClose={()=>resetNotifyDetail()}
		// 	maxWidth='sm'
		// 	fullWidth={true}
		// 	aria-labelledby="notify-dialog-title"
		// 	aria-describedby="notify-dialog-description"
		// >
		// 	<DialogTitle id="alert-dialog-title" onClose={()=>resetNotifyDetail()}>{notifyTitle}</DialogTitle>
		// 	<DialogContent>
		// 		{notifyDetailComp}
		// 	</DialogContent>
		// 	<DialogActions>
		// 		<Button onClick={()=>resetNotifyDetail()} color="primary">
		// 			CLOSE
		// 		</Button>
		// 	</DialogActions>
		// </Dialog>):null;
		return null;
	}
}

Notifier.propTypes = {
	classes: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	removeSnackbar: PropTypes.func.isRequired,
};

Notifier.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		notifier: state.notifier,
	};
};

export default connect(mapStateToProps, { removeSnackbar })(withStyles(notifierStyle)(Notifier));
