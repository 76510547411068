// ##############################
// // // HeaderBar styles
// #############################
import { tinycolor } from 'utils/libHelper';
import makeStyles from '@mui/styles/makeStyles';

const useStylesHeaderbar = makeStyles((theme) => {
	return {
		appBar: {
			backgroundColor: 'transparent',
			boxShadow: 'none',
			borderBottom: '0',
			marginBottom: '0',
			position: 'relative',
			width: '100%',
			zIndex: '1029',
			color: theme.palette.domain.contrastText, // domainContrastTextColor,
			border: '0',
			borderRadius: '0px',
			padding: '5px 0 !important',
			transition: 'all 150ms ease 0s',
			minHeight: '50px',
			display: 'block',
			height: theme.headerBarHeight,
		},
		container: {
			...theme.customContainer,
			minHeight: '50px',
		},
		flex: {
			flex: 1,
		},
		title: {
			lineHeight: '30px',
			fontSize: '18px',
			borderRadius: '3px',
			textTransform: 'none',
			color: 'inherit',
			paddingTop: '0.625rem',
			paddingBottom: '0.625rem',
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			'&:hover,&:focus': {
				background: 'transparent',
			},
		},
		menuButton: {
			fontSize: theme.typography.pxToRem(20),
			padding: 8,
			boxShadow:
				'0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
			// top: "8px"
			backgroundColor: tinycolor(theme.palette.domain.main).brighten(3).toHexString(),
			// '&:hover': {
			// 	backgroundColor: tinycolor(theme.palette.domain.main).darken(10).toHexString(),
			// }
		},
		domain: {
			backgroundColor: theme.palette.domain.main,
			color: theme.palette.domain.contrastText,
			opacity: '.8',
			...theme.customDefaultBoxShadow,
			borderRadius: '0px',
		},
		primary: {
			backgroundColor: theme.palette.primary.main,
			color: '#FFFFFF',
			...theme.customDefaultBoxShadow,
		},
		info: {
			backgroundColor: theme.palette.info.main,
			color: '#FFFFFF',
			...theme.customDefaultBoxShadow,
		},
		transparent: {
			'&,&:focus,&:hover': {
				color: 'inherit',
				background: 'transparent',
				boxShadow: 'none',
			},
		},
		sidebarMinimize: {
			float: 'left',
			// padding: '0 0 0 15px',
			paddingLeft: theme.spacing(1),
			display: 'block',
			color: 'inherit',
		},
		sidebarMenuIcon: {
			width: '20px',
			height: '20px',
		},
	};
});

export default useStylesHeaderbar;
