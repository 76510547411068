import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { IconButton } from '@mui/material';
import { ArrowBackIos as LeftIcon, ArrowForwardIos as RightIcon } from '@mui/icons-material';

const VideoSlide = ({ url, isSelected, autoPlay, ...rest }) => {
	const playerRef = React.useRef();
	React.useEffect(() => {
		if (!isSelected && playerRef.current) {
			// videoArtworkVideoRef.current.currentTime = 0;
			playerRef.current.pause();
		} else if (isSelected && playerRef.current && autoPlay) {
			playerRef.current.play();
		}
	}, [autoPlay, isSelected]);
	return <video ref={playerRef} controls muted preload="metadata" src={url} />;
};

const Slide = ({ url, isSelected, isVideo, className, autoPlayVideo, ...rest }) => (
	<div className={className}>
		{isVideo ? (
			<VideoSlide url={url} isSelected={isSelected} autoPlay={autoPlayVideo} />
		) : (
			<img src={url} alt="" />
		)}
	</div>
);

const useStyles = makeStyles((theme) => ({
	carouselWrapper: {
		// below is to override the default css style class in react-responsive-carousel/lib/styles/carousel.min.css
		'& .control-dots': {
			pointerEvents: 'all',
		},
		'& .carousel img': {
			pointerEvents: 'all',
		},
	},
	slideWrap: {
		display: 'flex',
		width: '100%',
		height: '100vh',
		position: 'relative',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(10, 0),
		'& img': {
			width: 'unset !important',
			maxHeight: '100%',
			maxWidth: '100%',
			pointerEvents: 'all',
			border: '2px solid #eeeeee !important',
			borderRadius: 2,
		},
		'& video': {
			maxWidth: '100%',
			maxHeight: '100%',
			pointerEvents: 'all',
			border: '2px solid #eeeeee ',
			borderRadius: 2,
		},
	},
	arrow: {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(50% - 15px)',
		cursor: 'pointer',
		backgroundColor: theme.palette.secondary.light,
		color: theme.palette.secondary.contrastText,
		pointerEvents: 'all',
		opacity: 0.6,
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
			opacity: 1,
		},
	},
}));

function ContentView({ autoPlayVideo, showDots, mediafiles, ...rest }) {
	const classes = useStyles();
	const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

	const selectedIndex = mediafiles.findIndex((m) => m.isSelected);
	return (
		<Carousel
			className={classes.carouselWrapper}
			autoPlay={false}
			autoFocus={true}
			useKeyboardArrows={true}
			showIndicators={showDots}
			renderItem={customRenderItem}
			showThumbs={false}
			selectedItem={selectedIndex === -1 ? 0 : selectedIndex}
			renderArrowPrev={(onClickHandler, hasPrev, label) =>
				hasPrev && (
					<IconButton
						className={classes.arrow}
						style={{ left: 15 }}
						title={label}
						onClick={onClickHandler}
					>
						<LeftIcon fontSize="large" />
					</IconButton>
				)
			}
			renderArrowNext={(onClickHandler, hasNext, label) =>
				hasNext && (
					<IconButton
						className={classes.arrow}
						style={{ right: 15 }}
						title={label}
						onClick={onClickHandler}
					>
						<RightIcon fontSize="large" />
					</IconButton>
				)
			}
		>
			{mediafiles.map((mediafile, idx) => {
				return (
					<Slide
						key={`mediafile-carousel-${idx}`}
						className={classes.slideWrap}
						isVideo={mediafile.isVideo}
						url={mediafile.previewUrl}
						autoPlayVideo={autoPlayVideo}
					/>
				);
			})}
		</Carousel>
	);
}

ContentView.propTypes = {
	autoPlayVideo: PropTypes.bool,
	showDots: PropTypes.bool,
	mediafiles: PropTypes.arrayOf(
		PropTypes.shape({
			isSelected: PropTypes.bool,
			isVideo: PropTypes.bool,
			previewUrl: PropTypes.string.isRequired,
		})
	),
};

ContentView.defaultProps = {
	autoPlayVideo: false,
	showDots: false,
};
export default ContentView;
