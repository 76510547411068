import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Rnd } from 'react-rnd';
// // MUI components
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	IconButton,
	Paper,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
	title: {
		margin: 0,
		padding: theme.spacing(2),
		cursor: 'move',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const dragHandleClassName = 'RnDDragHandler';
function PaperComponent({ size, resizable, ...rest }) {
	const theme = useTheme();
	const initWidth = theme.breakpoints.values[size];
	return (
		<Rnd
			default={{
				x: (document.body.clientWidth - initWidth) / 2,
				y: 100,
				width: initWidth,
				height: '60%',
			}}
			minWidth={300}
			minHeight={350}
			maxHeight="85%"
			maxWidth="85%"
			dragHandleClassName={dragHandleClassName}
			enableResizing={{
				top: false,
				topRight: false,
				topLeft: false,
				right: resizable,
				bottom: resizable,
				left: resizable,
				bottomRight: resizable,
				bottomLeft: resizable,
			}}
		>
			<Paper
				style={{
					padding: 'unset',
					margin: 'unset',
					width: '100%',
					height: '100%',
					maxHeight: 'unset',
					maxWidth: 'unset',
					position: 'absolute',
				}}
				{...rest}
			/>
		</Rnd>
	);
}

function RnDDialog({ children, open, size, title, resizable, onClose, ...rest }) {
	const classes = useStyles();

	return (
		<Dialog
			disableEscapeKeyDown
			open={open}
			maxWidth={size}
			fullWidth={true}
			scroll="paper"
			PaperProps={{ size, resizable }}
			PaperComponent={PaperComponent}
		>
			<DialogTitle className={cx(dragHandleClassName, classes.title)}>
				{title}
				{onClose && (
					<IconButton
						aria-label="Close"
						className={classes.closeButton}
						onClick={(e) => onClose(e)}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent dividers>{children}</DialogContent>
			{onClose && (
				<DialogActions>
					<Button variant="contained" onClick={(e) => onClose(e)} color="primary">
						Close
					</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}

RnDDialog.propTypes = {
	open: PropTypes.bool,
	size: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']),
	title: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
	onClose: PropTypes.func,
	resizable: PropTypes.bool,
};

RnDDialog.defaultProps = { open: false, size: 'md', resizable: true };

export default RnDDialog;
