import React, { useState } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import Draggable from 'react-draggable';

// MUI components
import { TextField } from '@mui/material';

// custom components
import { CreatableSelection } from 'components';

// Icons
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material';

// intl lang
import { useIntl } from 'react-intl';

// CSS
import { default as useStyles, artInputStyles, reactSelectStyles } from './SidePanelFieldStyle.jsx';

// // redux
// import { connect } from 'react-redux';
// import {} from 'redux/actions'; // actions

// helper component of text input
const SidemenuFieldInput = ({ children, ...rest }) => {
	const classes = artInputStyles();
	return (
		<TextField
			className={classes.textField}
			margin="normal"
			autoComplete="off"
			InputLabelProps={{
				// focused: true,
				shrink: true,
				classes: {
					root: classes.textFieldLabelRoot,
					focused: classes.textFieldFocus,
				},
			}}
			InputProps={{
				classes: {
					underline: classes.textFieldInputUnderline,
				},
				className: classes.textFieldInput,
			}}
			{...rest}
		>
			{children}
		</TextField>
	);
};

// // JSON schema for field object validation by ajv library
// const fieldSchema = {
// 	type: 'object',
// 	required: ['name', 'groupName'],
// 	properties: {
// 		name: {
// 			type: 'string',
// 			minLength: 1,
// 		},
// 		helperText: {
// 			type: 'string',
// 		},
// 		groupName: {
// 			type: 'string',
// 			minLength: 1,
// 		},
// 	},
// };

function SidePanelField({
	type,
	name,
	templateGroups,
	handleNewGroup,
	// handleDelGroup,
	handleUnmount,
	fieldDropzoneRef,
	handleNewField,
	lastUsedGroupName,
}) {
	const classes = useStyles();
	const intl = useIntl();
	let [isDragging, setIsDragging] = useState(false);
	let [isDroppable, setIsDroppable] = useState(false);
	let [fieldProps, setFieldProps] = useState({
		name: '',
		helperText: '',
		groupName: lastUsedGroupName,
	});

	// let isFieldValid = ajvInst.validate(fieldSchema, fieldProps);
	let isFieldValid = Boolean(fieldProps.name && fieldProps.groupName);

	return (
		<div className={classes.wrapper}>
			{/* draggable placeholder */}
			<Draggable
				handle={'[id="draggableFieldPlaceholder"]'}
				onStart={() => {}}
				onDrag={(e, data) => {
					if (!isDragging) {
						setIsDragging(true);
					}
					let fieldPlaceholderRect = data.node.children[0].getBoundingClientRect();
					let dropzonRect = fieldDropzoneRef.current.getBoundingClientRect();
					let isDroppableRT = !(
						fieldPlaceholderRect.right < dropzonRect.left ||
						fieldPlaceholderRect.left > dropzonRect.right ||
						fieldPlaceholderRect.bottom < dropzonRect.top ||
						fieldPlaceholderRect.top > dropzonRect.bottom
					);
					if (isDroppableRT !== isDroppable) {
						setIsDroppable(isDroppableRT);
					}
				}}
				onStop={(e, data) => {
					if (isDroppable) {
						let fieldPlaceholderRect = data.node.children[0].getBoundingClientRect();
						let dropzonRect = fieldDropzoneRef.current.getBoundingClientRect();

						handleNewField({
							type,
							position: {
								left: fieldPlaceholderRect.left - dropzonRect.left,
								top: fieldPlaceholderRect.top - dropzonRect.top,
								width: fieldPlaceholderRect.width,
								height: fieldPlaceholderRect.height,
							},
							...fieldProps,
						});
						handleUnmount();
						return false;
					} else {
						setIsDragging(false);
					}
				}}
				disabled={!isFieldValid}
				position={{ x: 0, y: 0 }}
			>
				<div
					id="draggableFieldPlaceholder"
					className={cx(classes.draggablePlaceholder, {
						[classes.cursorNotDraggable]: !isDragging && !isFieldValid,
						[classes.cursorDraggable]: !isDragging && isFieldValid,
						[classes.cursorDroppable]: isDragging && isDroppable,
						[classes.cursorNoDroppable]: isDragging && !isDroppable,
					})}
					// onDoubleClick={() => {
					// 	if (!isFieldValid) return;
					// 	handleUnmount();
					// }}
				>
					<div
						// ref={fieldPlaceholderRef}
						className={cx({
							[classes.draggablePlaceholderSquare]: ['image', 'video', 'grid'].includes(type),
							[classes.draggablePlaceholderRectangleProtrait]: ['pdf'].includes(type),
							[classes.draggablePlaceholderRectangleLandscape]: ['text', 'barcode'].includes(type),
							[classes.draggablePlaceholderHidden]: !isDragging,
						})}
					></div>
				</div>
			</Draggable>
			{/* title */}
			<div
				className={cx(classes.title, {
					[classes.titleDisabled]: !isFieldValid,
				})}
			>
				<div className={classes.dragText}>
					<DragIndicatorIcon className={classes.dragIcon} />
					<div>
						{intl.formatMessage(
							{
								id: 'pages.Artwork.components.Sidemenu.SidePanelField.dragText',
							},
							{
								name: name,
							}
						)}
					</div>
				</div>
			</div>

			{/* content */}
			<div className={classes.content}>
				<SidemenuFieldInput
					label={intl.formatMessage({
						id: 'pages.Artwork.components.Sidemenu.SidePanelField.fieldNameText',
					})}
					autoFocus
					required
					variant="standard"
					value={fieldProps.name}
					onChange={(e) => setFieldProps({ ...fieldProps, name: e.target.value || '' })}
				/>
				<SidemenuFieldInput
					label={intl.formatMessage({
						id: 'pages.Artwork.components.Sidemenu.SidePanelField.helpText',
					})}
					multiline
					variant="standard"
					value={fieldProps.helperText}
					onChange={(e) => setFieldProps({ ...fieldProps, helperText: e.target.value || '' })}
				/>
				<CreatableSelection
					label={intl.formatMessage({
						id: 'pages.Artwork.components.Sidemenu.SidePanelField.groupText',
					})}
					value={fieldProps.groupName}
					options={templateGroups.map((groupName) => ({ value: groupName, label: groupName }))}
					isClearable={true}
					required
					className={classes.groupSelectContainer}
					RSStyles={reactSelectStyles}
					// handleDelOption={groupName => {
					// 	if (fieldProps.groupName && groupName === fieldProps.groupName) {
					// 		setFieldProps({ ...fieldProps, groupName: '' });
					// 	}
					// 	handleDelGroup(groupName);
					// }}
					handleCreation={(groupName) => {
						handleNewGroup(groupName);
						setFieldProps({ ...fieldProps, groupName: groupName || '' });
					}}
					handleSelection={(groupName) => {
						setFieldProps({ ...fieldProps, groupName: groupName || '' });
					}}
				/>
			</div>
		</div>
	);
}

SidePanelField.propTypes = {
	type: PropTypes.oneOf(['text', 'video', 'image', 'pdf', 'barcode', 'grid']).isRequired,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // Allow PropTypes.object is in case it is intl message object
	templateGroups: PropTypes.array.isRequired, // the group names in current page in the template
	handleNewGroup: PropTypes.func.isRequired,
	// handleDelGroup: PropTypes.func.isRequired,
	handleUnmount: PropTypes.func.isRequired,
	fieldDropzoneRef: PropTypes.object.isRequired,
	handleNewField: PropTypes.func.isRequired,
	lastUsedGroupName: PropTypes.string.isRequired,
};

SidePanelField.defaultProps = {};

// // eslint-disable-next-line no-unused-vars
// const mapStateToProps = () => {
// 	return {
// 		// notifier: state.notifier
// 	};
// };

// export default connect(mapStateToProps, {})(SidePanelField);
export default SidePanelField;
