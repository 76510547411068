import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import { getCursor, getLength, degToRadian, getNewStyle } from './utils';
import { getOuterBoundsSelection } from 'utils/artwork/artUtilsWebUI';

import makeStyles from '@mui/styles/makeStyles';
import { blueGrey, blue } from '@mui/material/colors';
import { ClickAwayListener } from '@mui/material';

import { ART_VARIABLES } from '../../Constants';

import { _, deepClone } from 'utils/libHelper';
import { roundDecimals } from 'utils/generalHelper';

const dotBGColor = blueGrey[700];
const dotBGHoverColor = blue[500];
const borderColor = blueGrey[700];
const useStyles = makeStyles((theme) => ({
	overlayWrapper: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		backfaceVisibility: 'hidden',
	},
	fieldBoxWrapper: {
		position: 'absolute',
		overflow: 'hidden',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
	resizeBoxWrapper: {
		backfaceVisibility: 'hidden',
		position: 'absolute',
		// zIndex: 999,

		// fontSmoothing: 'antialiased',
		// letterSpacing: 0,
		// display: 'inline-block',
		// verticalAlign: 'baseline',
		border: `1px dashed ${borderColor}`,
	},
	solidBorder: {
		border: `1px solid ${borderColor}`,
	},
	circleDot: {
		position: 'absolute',
		width: 10,
		height: 10,
		// visibility: visible;
		// left: -5,
		// top: -5,
		borderRadius: 5,
		backgroundColor: dotBGColor,
		boxShadow: `rgb(255, 255, 255) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 0px 4px 2px`,
		'&:hover': {
			backgroundColor: dotBGHoverColor,
		},
	},
	nwCircleDot: {
		left: -5,
		top: -5,
	},
	neCircleDot: {
		// right: -5,
		left: ({ bounds }) => bounds.position.width - 5,
		top: -5,
	},
	swCircleDot: {
		left: -5,
		// bottom: -5,
		top: ({ bounds }) => bounds.position.height - 5,
	},
	seCircleDot: {
		// right: -5,
		left: ({ bounds }) => bounds.position.width - 5,
		// bottom: -5,
		top: ({ bounds }) => bounds.position.height - 5,
	},
	squareDot: {
		position: 'absolute',
		width: 10,
		height: 10,
		// left: '50%',
		//  top: 0,
		//  marginLeft: -5,
		//  marginTop: -5,
		backgroundColor: dotBGColor,
		boxShadow: `rgb(255, 255, 255) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 0px 4px 2px`,
		'&:hover': {
			backgroundColor: dotBGHoverColor,
		},
	},
	nSquareDot: {
		left: ({ bounds }) => bounds.position.width * 0.5, //'50%',
		top: 0,
		marginLeft: -5,
		marginTop: -5,
	},
	eSquareDot: {
		// right: 0,
		left: ({ bounds }) => bounds.position.width, //0,
		top: ({ bounds }) => bounds.position.height * 0.5, //'50%',
		// marginRight: -5,
		marginLeft: -5,
		marginTop: -5,
	},
	sSquareDot: {
		// bottom: 0,
		top: ({ bounds }) => bounds.position.height,
		left: ({ bounds }) => bounds.position.width * 0.5, //'50%',
		marginLeft: -5,
		marginTop: -5,
		// marginBottom: -5,
	},
	wSquareDot: {
		left: 0,
		// top: '50%',
		top: ({ bounds }) => bounds.position.height * 0.5,
		marginLeft: -5,
		marginTop: -5,
	},
	visiable: {
		visibility: 'visible',
	},
	hidden: {
		visibility: 'hidden',
	},

	// rotateTextContainer: {
	// 	position: 'absolute',
	// 	width: 40,
	// 	height: 20,
	// 	// left: '50%',
	// 	// top: -65,
	// 	backgroundColor: '#000',
	// 	color: '#fff',
	// 	marginLeft: -20,
	// 	borderRadius: 10,
	// 	background: 'rgb(255, 255, 255)',
	// 	boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 0px 4px 2px',
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// },

	// rotateContainer: {
	// 	position: 'absolute',
	// 	width: 20,
	// 	height: 20,
	// 	// left: '50%',
	// 	// top: -35,
	// 	marginLeft: -10,
	// 	borderRadius: 10,
	// 	background: 'rgb(255, 255, 255)',
	// 	boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 0px 4px 2px',
	// 	'&:hover': {
	// 		// cursor: 'url(\'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18	" x="0px" y="0px" viewBox="0 0 214.367 214.367" fill="%23039be5"%3E%3Cpath d="M202.403,95.22c0,46.312-33.237,85.002-77.109,93.484v25.663l-69.76-40l69.76-40v23.494 c27.176-7.87,47.109-32.964,47.109-62.642c0-35.962-29.258-65.22-65.22-65.22s-65.22,29.258-65.22,65.22 c0,9.686,2.068,19.001,6.148,27.688l-27.154,12.754c-5.968-12.707-8.994-26.313-8.994-40.441C11.964,42.716,54.68,0,107.184,0 S202.403,42.716,202.403,95.22z"%3E%3C/path%3E%3C/svg%3E\') 10 10, pointer'
	// 		cursor: `url(${rotateIco}) 10 10, pointer`,
	// 	},
	// },
	// rotateIcon: {
	// 	position: 'absolute',
	// 	left: '50%',
	// 	top: '50%',
	// 	marginLeft: -7,
	// 	marginTop: -7,
	// 	fill: dotBGColor,
	// 	'&:hover': {
	// 		fill: dotBGHoverColor,
	// 	},
	// },
	// svgRotateLine: {
	// 	// width: 1,
	// 	// height: 25,
	// 	// left: '50%',
	// 	// top: -25,
	// 	position: 'absolute',
	// 	width: '100%',
	// 	height: '100%',
	// 	fill: 'none',
	// 	stroke: borderColor,
	// 	strokeWidth: '2',
	// 	strokeDasharray: '5, 2',
	// },
	// svgRectBorder: {
	// 	position: 'absolute',
	// 	width: '100%',
	// 	height: '100%',
	// 	fill: 'none',
	// 	stroke: borderColor,
	// 	strokeWidth: '2',
	// 	strokeDasharray: '5, 2',
	// },
	// svgRectBorderSolid: {
	// 	strokeDasharray: '5, 0',
	// },
	// actionsWrapper: {
	// 	position: 'absolute',
	// 	top: 0,
	// 	// left: '100%',
	// 	// padding: theme.spacing(0.5),
	// 	paddingLeft: theme.spacing(1.5),
	// 	display: 'flex',
	// 	flexDirection: 'column',
	// },
	// actionButton: {
	// 	margin: '2px 0px',
	// 	padding: 2,
	// 	// color: 'inherit',
	// 	backgroundColor: '#ffffff',
	// 	'&:hover': {
	// 		backgroundColor: '#ffffff',
	// 	},
	// },
	// actionButtonIcon: {
	// 	margin: 2,
	// 	// color: actionButtonBGColor,
	// 	fontSize: ({  bounds }) => {
	// 		let fontSize = Math.round((bounds.position.height ) / 4);
	// 		return Math.max(Math.min(fontSize, maxActionFontSize), minActionFontSize);
	// 	},
	// },
	fieldBox: {
		position: 'absolute',
		// zIndex: 1,
		backgroundColor: 'rgba(96, 125, 139, .2)',
		'&:hover': {
			// opacity: 0.2,
			backgroundColor: 'rgba(96, 125, 139, .3)',
			border: `1px dashed ${borderColor}`,
		},
	},
	fieldBoxNoBGColor: {
		backgroundColor: 'unset',
	},
	fieldSelected: {
		backgroundColor: 'rgba(255, 213, 79, .2)',
		'&:hover': {
			// opacity: 0.2,
			backgroundColor: 'rgba(255, 213, 79, .3)',
		},
	},
	// fieldBoxRectBorder: {
	// 	position: 'absolute',
	// 	width: '100%',
	// 	height: '100%',
	// 	fill: 'none',
	// 	stroke: borderColor,
	// 	strokeWidth: '1',
	// 	strokeDasharray: '3, 2',
	// },
}));
// get previous value
// function usePrevious(value) {
// 	const ref = useRef();
// 	useEffect(() => {
// 		ref.current = value;
// 	});
// 	return ref.current;
// }

// function RotateIcon(props) {
// 	return (
// 		<svg {...props}>
// 			<path d="M12.378,10.467L15,6.489h-1.384c-0.26-3.254-2.961-5.752-6.297-5.752c-1.688,0-3.276,0.651-4.47,1.834 C1.657,3.754,1,5.327,1,7c0,3.453,2.835,6.263,6.32,6.263c1.425,0,2.77-0.461,3.891-1.33l0.315-0.244l-1.521-1.609l-0.262,0.191 c-0.706,0.516-1.546,0.787-2.423,0.787c-2.257,0-4.096-1.822-4.096-4.061c0-2.24,1.837-4.063,4.096-4.063 c2.094,0,3.811,1.532,4.063,3.551H9.759L12.378,10.467z"></path>
// 		</svg>
// 	);
// }

const positions = ['n', 's', 'w', 'e', 'nw', 'ne', 'sw', 'se']; // using lowercase as for cursor in css

function CreateOverlay({
	// children,
	// bounds,
	// selectedFields,
	selectedFieldIds,
	fields,
	zoom,
	showDot,
	// showBorder,
	solidLine,
	// resizable,
	onResizeStart,
	onResizeStop,
	onResizing,

	// rotatable,
	// onRotateStart,
	// onRotateStop,
	// onRotating,

	// draggable,
	onDragStart,
	onDragStop,
	onDragging,

	// showActions,
	// handleDuplicateFields,
	// handleDelFields,

	handleFieldSelect,
	handleFieldSelectClickAway,
	highlightField,

	// enablePreview,
}) {
	const [allFields, setAllFields] = useState([]);
	React.useEffect(() => {
		setAllFields(fields);
	}, [fields]);
	// console.debug(`selectedFieldIds length ${selectedFieldIds.length}`);
	const selectedFields = allFields.filter((f) => selectedFieldIds.includes(f.id));
	const isSelectedDraggable =
		selectedFields.length > 0
			? selectedFields[0].type === 'image' && selectedFields[0].imageRepositioning
			: false;
	const isSelectedResizable =
		selectedFields.length > 0
			? selectedFields[0].type === 'image' && selectedFields[0].imageRepositioning
			: false;

	let bounds = getOuterBoundsSelection(selectedFields);
	if (bounds) {
		bounds = {
			position: {
				width: bounds.position.width * zoom,
				height: bounds.position.height * zoom,
				left: bounds.position.left * zoom,
				top: bounds.position.top * zoom,
				angle: bounds.position.angle,
			},
		};
	}
	// console.debug(`${bounds ? 'bounds has value' : 'bounds is null'}`);
	const classes = useStyles({
		bounds: bounds || { position: { width: 0, height: 0, left: 0, top: 0, angle: 0 } },
	}); // actualHeight: bounds ? bounds.position.height  : 0 });

	// const [rotateAngle, setRotateAngle] = useState(field.position.angle || 0);
	// const selectionWrapperRef = useRef();
	// const draggableHandlerId = genUUID();
	const isMultiSelection = selectedFields.length > 1;
	let _isMouseDown = false;

	// Rotate
	// const startRotate = e => {
	// 	e.stopPropagation();
	// 	e.preventDefault();

	// 	if (e.button !== 0) return; // only left click allowed
	// 	const { clientX, clientY } = e;
	// 	// const startAngle = bounds.position.angle;
	// 	const rect = selectionWrapperRef.current.getBoundingClientRect();
	// 	const center = {
	// 		x: rect.left + rect.width / 2,
	// 		y: rect.top + rect.height / 2,
	// 	};
	// 	const startVector = {
	// 		x: clientX - center.x,
	// 		y: clientY - center.y,
	// 	};
	// 	onRotateStart && onRotateStart();
	// 	_isMouseDown = true;
	// 	const onMove = _.throttle(
	// 		e => {
	// 			if (!_isMouseDown) return; // fix windows press win key during mouseup issue
	// 			e.stopImmediatePropagation();
	// 			e.preventDefault();
	// 			const { clientX, clientY } = e;
	// 			const rotateVector = {
	// 				x: clientX - center.x,
	// 				y: clientY - center.y,
	// 			};
	// 			const deltaAngle = Math.round(getAngle(startVector, rotateVector)); // angle diff to the original angle
	// 			setAllFields(
	// 				allFields.map(f =>
	// 					selectedFieldIds.includes(f.id)
	// 						? {
	// 								...f,
	// 								position: {
	// 									...f.position,
	// 									angle: (f.position.angle + deltaAngle + 360) % 360,
	// 								},
	// 						  }
	// 						: f
	// 				)
	// 			);
	// 			onRotating && onRotating(selectedFieldIds, deltaAngle);
	// 		},
	// 		50,
	// 		{ leading: false, trailing: true }
	// 	);
	// 	const onUp = e => {
	// 		e.stopPropagation();
	// 		e.preventDefault();
	// 		document.removeEventListener('mousemove', onMove, true);
	// 		document.removeEventListener('mouseup', onUp, true);
	// 		if (!_isMouseDown) return;
	// 		_isMouseDown = false;
	// 		const { clientX, clientY } = e;
	// 		const rotateVector = {
	// 			x: clientX - center.x,
	// 			y: clientY - center.y,
	// 		};
	// 		const deltaAngle = Math.round(getAngle(startVector, rotateVector)); // angle diff to the original angle
	// 		setAllFields(
	// 			allFields.map(f =>
	// 				selectedFieldIds.includes(f.id)
	// 					? {
	// 							...f,
	// 							position: {
	// 								...f.position,
	// 								angle: (f.position.angle + deltaAngle + 360) % 360,
	// 							},
	// 					  }
	// 					: f
	// 			)
	// 		);
	// 		onRotateStop && onRotateStop(selectedFieldIds, deltaAngle);
	// 	};
	// 	document.addEventListener('mousemove', onMove, true);
	// 	document.addEventListener('mouseup', onUp, true);
	// };

	// resize
	const startResize = (e, cursor, position) => {
		e.stopPropagation();
		e.preventDefault();
		if (e.button !== 0) return; // only allow left click on mouse
		document.body.style.cursor = cursor; // set the body cursor to the same cursor in case the mouse is not over the resize handler

		const { clientX: startX, clientY: startY } = e;
		const originalSelectedFields = deepClone(selectedFields);
		// outer bounds rect
		const rect = {
			width: bounds.position.width,
			height: bounds.position.height,
			centerX: bounds.position.left + bounds.position.width / 2,
			centerY: bounds.position.top + bounds.position.height / 2,
			rotateAngle: bounds.position.angle,
		};
		// function to get resized fields
		const updatedSelectedFields = (e) => {
			// Note: following variables are based on current zoom
			const { clientX, clientY } = e;
			const deltaX = clientX - startX;
			const deltaY = clientY - startY;
			const alpha = Math.atan2(deltaY, deltaX);
			const deltaL = getLength(deltaX, deltaY);
			const isShiftKey = e.shiftKey;

			// Note: all variables are based on current zoom, inclucing ART_VARIABLES.minWidthResizeBox & ART_VARIABLES.minHeightResizeBox
			const beta = alpha - degToRadian(bounds.position.angle);
			const deltaW = deltaL * Math.cos(beta);
			const deltaH = deltaL * Math.sin(beta);
			const ratio = isMultiSelection || isShiftKey ? rect.width / rect.height : null;
			const newPosition = getNewStyle(
				position,
				{ ...rect },
				deltaW,
				deltaH,
				ratio,
				ART_VARIABLES.minWidthResizeBox,
				ART_VARIABLES.minHeightResizeBox
			);
			let resizedFields = originalSelectedFields.map((field) => {
				let {
					left: newFieldLeft,
					top: newFieldTop,
					width: newFieldWidth,
					height: newFieldHeight,
				} = newPosition;
				if (isMultiSelection) {
					const scale = newPosition.width / rect.width;
					newFieldWidth = field.position.width * zoom * scale;
					newFieldHeight = field.position.height * zoom * scale;
					newFieldLeft =
						newPosition.left + (field.position.left * zoom - bounds.position.left) * scale;
					newFieldTop = newPosition.top + (field.position.top * zoom - bounds.position.top) * scale;
					// we also scale the font size (only for text field) on multiple selection
					if (field.type === 'text' && field.fontsize) {
						field.fontsize = roundDecimals(Number(field.fontsize) * scale, 0);
					}
				}

				let newField = {
					...field,
					position: {
						top: newFieldTop / zoom,
						left: newFieldLeft / zoom,
						width: newFieldWidth / zoom,
						height: newFieldHeight / zoom,
						angle: field.position.angle, // the angle of the field is not changed during resizing
					},
				};
				return newField;
			});
			return resizedFields;
		};

		onResizeStart && onResizeStart(selectedFieldIds);
		_isMouseDown = true;
		// console.debug('_isMouseDown is set to true on resize up');
		const onMove = _.throttle(
			(e) => {
				if (!_isMouseDown) return; // patch: fix windows press win key during mouseup issue
				e.stopImmediatePropagation();
				e.preventDefault();
				const resizedFields = updatedSelectedFields(e);
				setAllFields(
					allFields.map((field) => _.find(resizedFields, (f) => f.id === field.id) || field)
				);

				onResizing && onResizing(resizedFields);
			},
			200,
			{ leading: false, trailing: true }
		);

		const onUp = (e) => {
			e.stopPropagation();
			e.preventDefault();
			document.body.style.cursor = 'auto';
			document.removeEventListener('mousemove', onMove);
			document.removeEventListener('mouseup', onUp);
			if (!_isMouseDown) return;
			_isMouseDown = false;
			// console.debug('_isMouseDown is set to false on resize up');
			const resizedFields = updatedSelectedFields(e);

			setAllFields(
				allFields.map((field) => _.find(resizedFields, (f) => f.id === field.id) || field)
			);
			onResizeStop && onResizeStop(resizedFields);
		};
		document.addEventListener('mousemove', onMove);
		document.addEventListener('mouseup', onUp);
		// console.debug('add event listener in resize start');
	};

	// Drag
	const startDrag = (e) => {
		e.stopPropagation();
		e.preventDefault();
		let { clientX: startX, clientY: startY } = e;
		onDragStart && onDragStart(selectedFieldIds);
		_isMouseDown = true;
		// console.debug('_isMouseDown is set to true on drag start');
		const onMove = _.throttle(
			(e) => {
				if (!_isMouseDown) return; // patch: fix windows press win key during mouseup issue
				e.stopImmediatePropagation();
				const { clientX, clientY } = e;
				const deltaX = clientX - startX; // X coordination diff to the original position
				const deltaY = clientY - startY; // Y coordination diff to the original position
				setAllFields(
					allFields.map((f) =>
						selectedFieldIds.includes(f.id)
							? {
									...f,
									position: {
										...f.position,
										left: f.position.left + deltaX / zoom,
										top: f.position.top + deltaY / zoom,
									},
							  }
							: f
					)
				);
				onDragging && onDragging(selectedFieldIds, deltaX, deltaY);
				// startX = clientX;
				// startY = clientY;
			},
			100,
			{ leading: false, trailing: true }
		);
		const onUp = (e) => {
			e.stopPropagation();
			e.preventDefault();
			document.removeEventListener('mousemove', onMove);
			document.removeEventListener('mouseup', onUp);
			if (!_isMouseDown) return;
			_isMouseDown = false;
			// console.debug('_isMouseDown is set to false on drag up');
			const { clientX, clientY } = e;
			const deltaX = clientX - startX; // X coordination diff to the original position
			const deltaY = clientY - startY; // Y coordination diff to the original position
			setAllFields(
				allFields.map((f) =>
					selectedFieldIds.includes(f.id)
						? {
								...f,
								position: {
									...f.position,
									left: f.position.left + deltaX / zoom,
									top: f.position.top + deltaY / zoom,
								},
						  }
						: f
				)
			);
			onDragStop && onDragStop(selectedFieldIds, deltaX, deltaY);
		};
		document.addEventListener('mousemove', onMove);
		document.addEventListener('mouseup', onUp);
		// console.debug('add event listener in drag start');
	};

	return (
		<ClickAwayListener onClickAway={handleFieldSelectClickAway} /* mouseEvent="onMouseDown" */>
			<div className={classes.overlayWrapper} style={{ zIndex: ART_VARIABLES.ZINDEX_RESIZEBOX }}>
				<div className={classes.fieldBoxWrapper}>
					{allFields.map((f, idx) => {
						if (f.hideInput) return null; // no output in this field
						let isSelected = selectedFieldIds.includes(f.id);
						// let isDraggable = f.draggable || false;
						return (
							<div
								key={f.id}
								style={{
									zIndex: idx + 1,
									width: f.position.width * zoom,
									height: f.position.height * zoom,
									left: f.position.left * zoom,
									top: f.position.top * zoom,
									transform: `rotate(${f.position.angle}deg)`,
									cursor: isSelected ? 'move' : null,
								}}
								className={cx(classes.fieldBox, {
									[classes.fieldSelected]: isSelected,
									[classes.fieldBoxNoBGColor]: !isSelected && !highlightField,
								})}
								onMouseDown={(e) => {
									e.nativeEvent.stopImmediatePropagation();
									// console.debug('selecting field');
									handleFieldSelect(f.id);
								}}
							></div>
						);
					})}
				</div>

				{bounds && (
					<div
						className={cx(classes.resizeBoxWrapper, { [classes.solidBorder]: solidLine })}
						style={{
							width: bounds.position.width,
							height: bounds.position.height,
							left: bounds.position.left,
							top: bounds.position.top,
							transform: `rotate(${bounds.position.angle}deg)`,
							transformOrigin: `${bounds.position.width * 0.5}px ${bounds.position.height * 0.5}px`,
							zIndex: 999,
							cursor: isSelectedDraggable ? 'move' : null,
						}}
						onMouseDown={(e) => {
							if (!isSelectedDraggable) return null;
							// e.stopPropagation();
							// e.preventDefault();
							// e.nativeEvent.stopImmediatePropagation();
							// if (isSelectedDraggable)
							startDrag(e);
						}}
						// onMouseUp={() => (_isMouseDown ? (_isMouseDown = false) : null)}
						onMouseUp={() => {
							// e.stopPropagation();
							// e.preventDefault();
							if (_isMouseDown) {
								// there was no dragging, as _isMouseDown was set to true onMouseDown and wasn't reset to false
								// console.debug('_isMouseDown is set to false in drag selected field');
								_isMouseDown = false;
							}
						}}
					>
						{isSelectedResizable &&
							positions.map((pos) => {
								if (isMultiSelection && pos.length === 1) {
									// hide side handler for multiple selections
									return null;
								}
								const cursor = `${getCursor(bounds.position.angle, pos)}-resize`;
								return (
									<div
										key={pos}
										className={cx(
											classes[pos.length === 1 ? `${pos}SquareDot` : `${pos}CircleDot`],
											{
												[classes.squareDot]: pos.length === 1,
												[classes.circleDot]: pos.length === 2,
												[classes.visiable]: showDot,
												[classes.hidden]: !showDot,
											}
										)}
										style={{ cursor }}
										onMouseDown={(e) => startResize(e, cursor, pos)}
										onMouseUp={() => {
											// e.stopPropagation();
											// e.preventDefault();
											if (_isMouseDown) {
												// there was no resizing, as _isMouseDown was set to true onMouseDown and wasn't reset to false
												// console.debug('_isMouseDown is set to false in resize selected field');
												_isMouseDown = false;
											}
										}}
									></div>
								);
							})}
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
}

CreateOverlay.propTypes = {
	// type: PropTypes.oneOf(['text', 'video', 'image', 'pdf', 'barcode']).isRequired,
	// bounds: PropTypes.object,
	// selectedFields: PropTypes.array.isRequired,
	selectedFieldIds: PropTypes.array.isRequired,
	fields: PropTypes.array.isRequired,
	zoom: PropTypes.number.isRequired, // double/float. the current zoom. e.g. 0.1, 0.25, 1, 1.35, 2, etc.
	showDot: PropTypes.bool,
	showBorder: PropTypes.bool,
	solidLine: PropTypes.bool,

	// resizable: PropTypes.bool,
	/**
	 *	usage: onResizeStart(fieldIds)
	 * @param {array of field ID string} fieldIds. ID of fields that will be resized (selected field IDs)
	 */
	onResizeStart: PropTypes.func,
	/**
	 *	usage: onResizeStop(resizedFields)
	 * @param {array of field object} resizedFields. The full dataset of the updated resized fields that were resized (selected fields)
	 */
	onResizeStop: PropTypes.func,
	/**
	 *	usage: onResizing(resizedFields)
	 * @param {array of field object} resizedFields. The full dataset of the updated resized fields that are being resized (selected fields)
	 */
	onResizing: PropTypes.func,

	// rotatable: PropTypes.bool,
	// /**
	//  *	usage: onRotateStart(fieldIds)
	//  * @param {array of field ID string} fieldIds. ID of fields that will be rotated (selected field IDs)
	//  */
	// onRotateStart: PropTypes.func,
	// /**
	//  *	usage: onRotateStop(fieldIds, deltaAngle)
	//  * @param {array of field ID string} fieldIds. ID of fields that were rotated (selected field IDs)
	//  * @param {Number} deltaAngle. The angle diff to oroginal angle under the current zoom
	//  */
	// onRotateStop: PropTypes.func,
	// /**
	//  *	usage: onRotating(fieldIds, deltaAngle)
	//  * @param {array of field ID string} fieldIds. ID of fields that are being rotated (selected field IDs)
	//  * @param {Number} deltaAngle. The angle diff to oroginal angle under the current zoom
	//  */
	// onRotating: PropTypes.func,

	// draggable: PropTypes.bool,
	/**
	 *	usage: onDragStop(fieldIds)
	 * @param {array of field ID string} fieldIds. ID of fields that will be dragged (selected field IDs)
	 */
	onDragStart: PropTypes.func,
	/**
	 *	usage: onDragStop(fieldIds, deltaX, deltaY)
	 * @param {array of field ID string} fieldIds. ID of fields that were dragged (selected field IDs)
	 * @param {Number in pixel} deltaX. X coordination diff to oroginal position in the current zoom
	 * @param {Number in pixel} deltaY. Y coordination diff to oroginal position in the current zoom
	 */
	onDragStop: PropTypes.func,
	/**
	 *	usage: onDragging(fieldIds, deltaX, deltaY)
	 * @param {array of field ID string} fieldIds. ID of fields that are being dragged (selected field IDs)
	 * @param {Number in pixel} deltaX. X coordination diff to oroginal position in the current zoom
	 * @param {Number in pixel} deltaY. Y coordination diff to oroginal position in the current zoom
	 */
	onDragging: PropTypes.func,

	// showActions: PropTypes.bool,
	// handleDuplicateFields: PropTypes.func,
	// handleDelFields: PropTypes.func,

	handleFieldSelect: PropTypes.func.isRequired,
	handleFieldSelectClickAway: PropTypes.func.isRequired,
	highlightField: PropTypes.bool,
	// enablePreview: PropTypes.bool.isRequired,
};

CreateOverlay.defaultProps = {
	// bounds: null,
	showDot: false,
	showBorder: false,
	solidLine: false,

	// resizable: false,
	// rotatable: false,
	// draggable: false,

	// showActions: false,
	highlightField: false,
};

export default CreateOverlay;
