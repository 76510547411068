import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import UserMessagesFilters from '../UserMessagesFilters/UserMessagesFilters';
import UserMessagesPreview from '../UserMessagesPreview/UserMessagesPreview';
import UserMessagesTable from '../UserMessagesTable/UserMessagesTable';

import { MUITablePagination } from 'components';
import { _ } from 'utils/libHelper';

// MUI components
import { Backdrop, CircularProgress, Typography } from '@mui/material';

// import {
// 	Create as ArtworkCustomizeIcon,
// 	AddToQueue as AddToScreenPlaylistIcon,
// 	Share as ShareIcon,
// 	AddShoppingCart as OrderIcon,
// } from '@mui/icons-material';
// import { Download as DownloadIcon } from 'mdi-material-ui';
import {
	// FindReplace as FindReplaceIcon,
	DeleteOutline as DeleteIcon,
} from '@mui/icons-material';

// intl lang
import { useIntl, FormattedMessage } from 'react-intl';

// custom hooks
import useColumnsConfig from '../../Hooks/useColumnsConfig';

// restful
import {
	// getDownloadableUrl,
	userMessageSetReadStatus,
	userManagerFetchMessages,
	userManagerPreviewMessages,
	userManagerDeleteMessage,
	userMessageBulkReadStatus,
	userMessageBulkDeleteMessages,
} from 'restful';

// redux
import { connect } from 'react-redux';
import { notifyGeneral, openGlobalDialog } from 'redux/actions'; // actions

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		// paddingBottom: theme.spacing(1),
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateRows:
			'minmax(30px, max-content) minmax(30px, min-content) minmax(30px, max-content)',
		gridGap: theme.spacing(1, 1),
		gridTemplateAreas: `
		"filter"
		"content"
		"pagination"
		`,
	},
	filter: {
		gridArea: 'filter',
		// marginBottom: theme.spacing(1),
	},
	content: {
		gridArea: 'content',
		// position: 'relative',
		overflow: 'auto',
	},
	pagination: {
		gridArea: 'pagination',
		// marginTop0: theme.spacing(1),
	},
	backdropLoading: {
		zIndex: 999,
		position: 'absolute',
		// alignItems: 'flex-start',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		// color: theme.palette.primary.main,
		borderRadius: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	// loadingCircularProgress: {
	// 	position: 'sticky',
	// 	top: 'calc(50% - 20px)',
	// },
}));

function UserMessagesList({
	// history,
	notifyGeneral,
	openGlobalDialog,
	userData,
	domainSettings,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const defaultSenderName = React.useMemo(() => {
		return domainSettings.settings.length
			? domainSettings.settings.find((item) => item.key === 'domainToolkitName')?.value ?? ''
			: '';
	}, [domainSettings.settings]);
	const defaultSenderEmail = React.useMemo(() => {
		return domainSettings.settings.length
			? domainSettings.settings.find((item) => item.key === 'toolkitEmailFrom')?.value ?? ''
			: '';
	}, [domainSettings.settings]);
	const [filters, setFilters] = React.useState({});
	const [searchKeywords, setSearchKeywords] = React.useState('');
	const [sortBy, setSortBy] = React.useState([]);
	// userMessages data
	const [userMessages, setUserMessages] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const [userMsgPreview, setUserMsgPreview] = React.useState(null);
	// pagination states
	const [pageIndex, setPageIndex] = React.useState(0);
	const [itemsPerPage, setItemsPerPage] = React.useState(10);
	const [totalNumItems, setTotalNumItems] = React.useState(-1);
	const [selected, setSelected] = React.useState([]);
	const [isSelectAllForPage, setIsSelectAllForPage] = React.useState(false);
	const [showActionsIfSelected, setShowActionsIfSelected] = React.useState(false);
	// handle setting search keywords
	const handleSearchKeywords = (keywords) => {
		setSearchKeywords(keywords);
		setPageIndex(0);
	};

	const handleChangePage = (event, newPage) => {
		setPageIndex(newPage);
		// If page change remove select all
		if (isSelectAllForPage) {
			setIsSelectAllForPage(false);
		}
	};
	const handleChangeRowsPerPage = (event) => {
		setPageIndex(
			event.target.value > totalNumItems
				? 0
				: Math.ceil((pageIndex * itemsPerPage) / Number(event.target.value))
		);
		setItemsPerPage(Number(event.target.value));
	};

	// handle close preview message popup
	const handleCloseUserMsgPreview = () => {
		setUserMsgPreview(null);
	};

	const handleUpdateFilters = (filters) => {
		setPageIndex(0);
		setFilters(filters);
	};

	// handle patch/update Mesage status (i.e Read/Unread)
	const handlePatchMessageStatus = React.useCallback(
		(userMsgId, patchUserMsgData) => {
			return userMessageSetReadStatus({
				msgId: userMsgId,
				queryParams: {
					isRead: patchUserMsgData.isRead,
				},
			})
				.then(() => {
					// notifyGeneral(`Message has been updated successfully`, 'success');
					// now convert the patched data to proper message data
					let updatedMsgStatusData = Object.keys(patchUserMsgData).reduce((accu) => {
						let uMsgData = {};
						uMsgData.isRead = patchUserMsgData.isRead;
						return Object.keys(uMsgData).length === 0 ? accu : { ...accu, ...uMsgData };
					}, {});
					setUserMessages((prevMsg) =>
						prevMsg.map((userMessage) => {
							if (userMessage.messageId === userMsgId) {
								return { ...userMessage, ...updatedMsgStatusData };
							} else {
								return userMessage;
							}
						})
					);
				})
				.catch((err) => {
					notifyGeneral(
						`Failed to update message. ${err.response ? err.response.data.message : err.message}`,
						'error'
					);
				});
		},
		[notifyGeneral]
	);
	// handle open preview message popup
	const handleShowLargePreview = React.useCallback(
		(userMsg, e) => {
			userManagerPreviewMessages({
				msgId: userMsg.messageId,
			})
				.then((res) => {
					const userMsgData = res.data;
					if (!userMsgData.senderName) {
						userMsgData.senderName = defaultSenderName;
					}
					if (!userMsgData.senderEmail) {
						userMsgData.senderEmail = defaultSenderEmail;
					}
					setUserMsgPreview({
						msgId: userMsg.messageId,
						userMsgData,
						anchorEl: e.target,
					});
					if (!userMsg.isRead) {
						handlePatchMessageStatus(userMsg.messageId, { isRead: true });
					}
				})
				.catch((err) => {
					notifyGeneral(
						`Failed to load the preview message. ${
							err.response ? err.response.data.message : err.message
						}`,
						'error'
					);
				});
		},
		[handlePatchMessageStatus, notifyGeneral, defaultSenderEmail, defaultSenderName]
	);

	const handleCheckboxOnChange = (userMsg, e) => {
		if (isSelectAllForPage) {
			let allSelect = (userMessages || []).map((x) => x.messageId);
			let selectedUserMessages = [...selected].length > 0 ? [...selected] : [...allSelect];
			if (e.target.checked) {
				selectedUserMessages.push(userMsg.id);
			} else {
				selectedUserMessages = selectedUserMessages.filter((x) => x !== userMsg.id);
			}
			setIsSelectAllForPage(false);
			setSelected(selectedUserMessages);
		} else {
			let newUserMessages = [...selected];
			if (e.target.checked) {
				newUserMessages.push(userMsg.id);
			} else {
				newUserMessages = newUserMessages.filter((x) => x !== userMsg.id);
			}
			setSelected(newUserMessages);
		}
	};

	const handleSelectAllCheckbox = React.useCallback(
		(e) => {
			// All select contains all the ids of the messages on the page
			let allSelect = (userMessages || []).map((x) => x.messageId);
			if (e.target.checked) {
				let currentSelected = [...selected];
				let mergeSelected = [...new Set([...currentSelected, ...allSelect])];
				setSelected(mergeSelected);
				setIsSelectAllForPage(true);
			} else {
				setIsSelectAllForPage(false);
				let newSelected = [...selected];
				newSelected = newSelected.filter((item) => !allSelect.includes(item));
				setSelected(newSelected);
			}
		},
		[selected, userMessages]
	);

	// Bulk delete action
	const handleBulkDeleteAction = () => {
		let confirmDialog = {
			size: 'sm',
			title: intl.formatMessage(
				{
					id: 'pages.UserMessages.components.UserMessages.BulkDeleteMsgTitle',
				},
				{ noOfMessages: selected.length }
			),
			content: (
				<Typography variant="body1" gutterBottom>
					{<FormattedMessage id="pages.UserMessages.components.UserMessages.BulkDeleteMsg" />}
				</Typography>
			),
			confirmCB: () => {
				let params = {
					itemIdList: selected,
				};
				return userMessageBulkDeleteMessages({ bodyParams: params })
					.then(() => {
						setUserMessages((userMsgData) => {
							// make a Set to hold values from itemsToDelete
							const itemsToDelete = new Set(selected);
							const msgData = userMsgData.filter((msg) => {
								// return those elements not in the itemsToDelete
								return !itemsToDelete.has(msg.messageId);
							});
							return msgData;
						});
						setTotalNumItems((totalItems) => {
							return totalItems > 0 ? totalItems - selected.length : -1;
						});
					})
					.catch((err) => {
						notifyGeneral(
							`Failed to update message. ${err.response ? err.response.data.message : err.message}`,
							'error'
						);
					});
			},
		};
		openGlobalDialog(confirmDialog);
	};
	// Bulk Mark as UnRead Action
	const handleBulkUnreadAction = () => {
		let params = {
			itemProperties: {
				isRead: false,
			},
			itemIdList: selected,
		};
		return userMessageBulkReadStatus({ bodyParams: params })
			.then(() => {
				setUserMessages(
					userMessages.map((userVal) => {
						if (selected.some((selectedID) => selectedID === userVal.messageId)) {
							userVal.isRead = false;
							return userVal;
						}
						return userVal;
					})
				);
			})
			.catch((err) => {
				notifyGeneral(
					`Failed to update message. ${err.response ? err.response.data.message : err.message}`,
					'error'
				);
			});
	};
	// Bulk Mark as Read Action
	const handleBulkReadAction = () => {
		let params = {
			itemProperties: {
				isRead: true,
			},
			itemIdList: selected,
		};
		return userMessageBulkReadStatus({ bodyParams: params })
			.then(() => {
				setUserMessages(
					userMessages.map((userVal) => {
						if (selected.some((selectedID) => selectedID === userVal.messageId)) {
							userVal.isRead = true;
							return userVal;
						}
						return userVal;
					})
				);
			})
			.catch((err) => {
				notifyGeneral(
					`Failed to update message. ${err.response ? err.response.data.message : err.message}`,
					'error'
				);
			});
	};
	const handleSelectAction = (action) => {
		if (action === 'delete') {
			handleBulkDeleteAction();
		} else if (action === 'unread') {
			handleBulkUnreadAction();
		} else {
			handleBulkReadAction();
		}
	};
	// handle sortBy updates with delay
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSortByWithDelay = React.useCallback(
		_.debounce((newSortBy) => {
			setSortBy((prevSortBy) =>
				prevSortBy[0]?.id !== newSortBy[0]?.id || prevSortBy[0]?.desc !== newSortBy[0]?.desc
					? newSortBy
					: prevSortBy
			);
			setPageIndex(0);
		}, 600),
		[]
	);

	// handle delete message
	const handleDeleteUserMessage = React.useCallback(
		(userMsg) => {
			let confirmDialog = {
				size: 'sm',
				title: intl.formatMessage({
					id: 'pages.UserMessages.components.UserMessages.DeleteMsgTitle',
				}),
				content: (
					<Typography variant="body1" gutterBottom>
						{<FormattedMessage id="pages.UserMessages.components.UserMessages.DeleteMsgContent" />}
					</Typography>
				),
				confirmCB: () => {
					return userManagerDeleteMessage({
						msgId: userMsg.messageId,
					})
						.then(() => {
							notifyGeneral(`Message has been deleted successfully`, 'success');
							setUserMessages((userMsgData) => {
								const msgData = userMsgData.filter(
									(result) => result.messageId !== userMsg.messageId
								);
								return msgData;
							});
							setTotalNumItems((totalItems) => {
								return totalItems > 0 ? totalItems - 1 : -1;
							});
						})
						.catch((err) => {
							notifyGeneral(
								`Failed to delete the message. ${
									err.response ? err.response.data.message : err.message
								}`,
								'error'
							);
						});
				},
			};
			openGlobalDialog(confirmDialog);
		},
		[intl, notifyGeneral, openGlobalDialog]
	);

	// Get columns configure by use hook.
	// NB: parameter passed to useColumnsConfig must be memorized
	// it is used for sorting selection in filter component, and used as column configure in table view component
	const columnsConfigure = useColumnsConfig({
		handleShowLargePreview,
	});

	// Delete icon in action column
	const userMessageActions = React.useMemo(() => {
		return [
			{
				shouldDisplay: (readValue) => readValue === true, // hook func to verify that should the action be displayed for the given message
				icon: <DeleteIcon fontSize="small" color="action" />,
				tooltip: intl.formatMessage({
					id: 'pages.UserMessages.components.UserMessages.DeleteMsg',
				}),
				clickHandler: handleDeleteUserMessage,
			},
		];
	}, [handleDeleteUserMessage, intl]);

	React.useEffect(() => {
		if (isSelectAllForPage || selected.length > 0) {
			setShowActionsIfSelected(true);
		} else {
			setShowActionsIfSelected(false);
		}
	}, [isSelectAllForPage, selected.length, pageIndex, userMessages]);

	// GET api integration.
	React.useEffect(() => {
		setIsLoading(true);
		userManagerFetchMessages({
			queryParams: {
				// offset: pageIndex * itemsPerPage,
				offset: pageIndex * itemsPerPage > totalNumItems ? 0 : pageIndex * itemsPerPage,
				limit: itemsPerPage,
				...(searchKeywords.length > 0 ? { filter: searchKeywords } : {}),
				...(sortBy.length > 0
					? { sortBy: sortBy[0].id, sortDirection: sortBy[0].desc ? 'desc' : 'asc' }
					: {}),
			},
		})
			.then((res) => {
				let formattedMessages = (res.data.messages || []).map((msg) => {
					if (!msg.senderName) {
						msg.senderName = defaultSenderName;
					}
					if (!msg.senderEmail) {
						msg.senderEmail = defaultSenderEmail;
					}
					return msg;
				});
				setUserMessages(formattedMessages);
				// setUserMessages(res.data.messages);
				setTotalNumItems(res.data.totalFound || -1);
			})
			.catch((err) => {
				notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [
		defaultSenderEmail,
		defaultSenderName,
		filters,
		itemsPerPage,
		notifyGeneral,
		pageIndex,
		searchKeywords,
		sortBy,
		totalNumItems,
		userData,
	]);

	return (
		<div className={classes.root}>
			<div className={classes.filter}>
				<UserMessagesFilters
					searchKeywords={searchKeywords}
					setSearchKeywords={handleSearchKeywords}
					setSelectAction={handleSelectAction}
					showActionsIfSelected={showActionsIfSelected}
					selectedFilters={filters}
					setSelectedFilters={handleUpdateFilters}
					columnsConfigure={columnsConfigure}
					appliedSortBy={sortBy}
				/>
			</div>
			<div className={classes.content}>
				{
					// <Backdrop className={classes.backdropLoading} open={isLoading}>
					// 	<CircularProgress color="primary" size={65} thickness={5.5} />
					// </Backdrop>
				}
				<UserMessagesTable
					userMessages={userMessages}
					selected={selected}
					columnsConfigure={columnsConfigure}
					handleShowLargePreview={handleShowLargePreview}
					handlePatchMessageStatus={handlePatchMessageStatus}
					appliedSortBy={sortBy}
					handleSortBy={handleSortByWithDelay}
					handleCheckboxOnChange={handleCheckboxOnChange}
					handleSelectAllCheckbox={handleSelectAllCheckbox}
					isSelectAllForPage={isSelectAllForPage}
					// setRTableInst={setRTableInst}
					// selectedRowIds={selectedRowIds}
					// handleRowsSelection={handleRowsSelection}
					userMessageActions={userMessageActions}
					noDataMsg={intl.formatMessage({
						id: 'pages.UserMessages.components.UserMessagesList.NoUserMsg',
					})}
				/>
			</div>
			{userMessages.length > 0 && (
				<div className={classes.pagination}>
					<MUITablePagination
						// pagination props
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						rowsPerPage={itemsPerPage}
						disableRowsPerPage={false}
						pageIndex={pageIndex}
						totalNumRows={totalNumItems}
						labelRowsPerPage={intl.formatMessage({
							id: 'pages.UserMessages.components.UserMessages.ItemsPerPageLabel',
						})}
					/>
				</div>
			)}
			{
				/** large preview of message */
				userMsgPreview && (
					<UserMessagesPreview
						{...userMsgPreview}
						onClose={handleCloseUserMsgPreview}
						onSetMsgUnread={handlePatchMessageStatus}
					/>
				)
			}
			{/** Circular progress loader */}
			<Backdrop className={classes.backdropLoading} open={isLoading}>
				<CircularProgress color="primary" size={65} thickness={5.5} />
			</Backdrop>
		</div>
	);
}

UserMessagesList.propTypes = {
	// below are redux state
	userData: PropTypes.object.isRequired,
	// below are redux actions
	notifyGeneral: PropTypes.func.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	domainSettings: PropTypes.object.isRequired,
};

UserMessagesList.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		// domainName: state.authentication.domainName,
		// userId: state.authentication.userId,
		userData: state.usermanager.userData,
		domainSettings: state.domainSettings,
	};
};
// export default UserMessagesList;
export default connect(mapStateToProps, {
	notifyGeneral,
	openGlobalDialog,
})(UserMessagesList);
