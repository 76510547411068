import config, { RUNNING_MODES_ENUM } from 'config';
import {
	ROUTES_PATH_ARTWORK_DESIGNER,
	ROUTES_PATH_ARTWORK_CREATOR,
	ROUTES_PATH_ARTWORK_ESIGN,
	ROUTES_PATH_MEDIAFILE_DETAILS,
} from './constants';

const { urlBaseName, REACT_APP_MODE } = config;

// get-routes
// export const getRouteMediafileDetail = (mediafileId) => `/search/?id=${mediafileId}`;
export const getRouteMediafileDetail = (mediafileId) =>
	REACT_APP_MODE === RUNNING_MODES_ENUM.REACT_ONLY
		? `${urlBaseName}${ROUTES_PATH_MEDIAFILE_DETAILS}?m=${mediafileId}`
		: `/search/?id=${mediafileId}`;
export const getRouteArtworkDesigner = (mediafileId) =>
	`${urlBaseName}${ROUTES_PATH_ARTWORK_DESIGNER}?t=${mediafileId}`;
export const getRouteArtworkCreator = (mediafileId) =>
	REACT_APP_MODE === RUNNING_MODES_ENUM.REACT_ONLY
		? `${urlBaseName}${ROUTES_PATH_ARTWORK_CREATOR}?t=${mediafileId}`
		: `/poscreate/?id=${mediafileId}`;
export const getRouteArtworkCreatorTemplate = (mediafileId, templateId) =>
	REACT_APP_MODE === RUNNING_MODES_ENUM.REACT_ONLY
		? `${urlBaseName}${ROUTES_PATH_ARTWORK_CREATOR}?m=${mediafileId}&t=${templateId}`
		: `/poscreate/?id=${mediafileId}`;
export const getRouteArtworkEsign =
	REACT_APP_MODE === RUNNING_MODES_ENUM.REACT_IN_LASSO
		? (mediafileId, spreadsheetId, columnId) =>
				`/posuserbatch/?id=${mediafileId}&ss=${spreadsheetId}&col=${columnId}`
		: (mediafileId) => `${urlBaseName}${ROUTES_PATH_ARTWORK_ESIGN}?t=${mediafileId}`;
export const getRouteManageSpreadsheet = (spreadsheetId) =>
	`/manage_spreadsheets/?id=${spreadsheetId}`;
export const getRouteFileUploaderSeeFiles = () => `/admin/uploads/?search=false`;
export const getRouteScreenActAs = (email, uid) => `/admin/?e=${email}&id=${uid}`;
export const getRouteUserDetails = (userId) => `/admin/users/details/?id=${userId}`;

// go-to-routes
export const goToRouteArtworkCreate = (templateId, mediafileId, history) =>
	// if history is defined, it always goes to new artwork
	// if mediafileId is defined, it is to continue editing the mediafile (the mediafile was generated from a template)
	history
		? history.push(
				`${ROUTES_PATH_ARTWORK_CREATOR}?t=${templateId}${mediafileId ? '&m=' + mediafileId : ''}`
		  )
		: window.location.assign(getRouteArtworkCreator(mediafileId));
export const goToRouteArtworkDesign = (mediafileId, history) =>
	// if history is defined, it always goes to new artwork
	history
		? history.push(`${ROUTES_PATH_ARTWORK_DESIGNER}?t=${mediafileId}`)
		: window.location.assign(getRouteArtworkDesigner(mediafileId));

export const goToRoutePricingCategory = (priceCateId) =>
	window.location.assign(`/admin/pricing/?search=false&id=${priceCateId}`);
